import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCablecardLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m1 14c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V6c0-.6.4-1 1-1h20c.6 0 1 .4 1 1zM7.5 9.5h-4c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5h4c.3 0 .5-.2.5-.5v-4c0-.3-.2-.5-.5-.5m-.5 4H4v-3h3zm14 3c0 .3-.2.5-.5.5h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.3 0 .5.2.5.5"/></svg>)}
    />
  );
}
  