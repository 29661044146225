// @flow
import * as R from 'ramda';
import type {
  StateT,
  ActionT,
  ImportAnalysisDepartmentsStateT,
  ImportAnalysisSheetStateT
} from './userImportUiTypes';

export const initialState: StateT = {
  importDepartments: {},
  importAnalysis: {
    sheets: [],
    departments: undefined,
    __metadata: {}
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: StateT = initialState, action: ActionT): StateT => {
  switch (action.type) {
    case 'ring/ui/userImport/SET_DEPARTMENT_ITEM': {
      const {
        payload: { importDepartmentItem }
      } = action;
      return {
        ...state,
        importDepartments: R.assoc(
          importDepartmentItem.path,
          importDepartmentItem,
          state.importDepartments
        )
      };
    }
    case 'ring/ui/userImport/UNSET_DEPARTMENT_ITEM': {
      const {
        payload: { path }
      } = action;
      return {
        ...state,
        importDepartments: R.dissoc(path, state.importDepartments)
      };
    }
    case 'ring/ui/userImport/CLEAR_DEPARTMENT_ITEMS': {
      return {
        ...state,
        importDepartments: {}
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_REQUEST': {
      return {
        ...state,
        importAnalysis: {
          ...state.importAnalysis,
          fileName: state.importAnalysis.fileName,
          __metadata: {
            importing: true
          }
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_STARTED_SUCCESS': {
      return {
        ...state,
        importAnalysis: {
          ...state.importAnalysis,
          fileName: state.importAnalysis.fileName,
          __metadata: {
            ...state.importAnalysis.__metadata,
            importUUID: action.payload
          }
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_SUCCESS': {
      return {
        ...state,
        importAnalysis: {
          // eslint-disable-next-line flowtype/no-weak-types
          sheets: ((action.payload.sheets: any): ImportAnalysisSheetStateT[]),
          // eslint-disable-next-line flowtype/no-weak-types
          departments: ((action.payload.departments: any): ImportAnalysisDepartmentsStateT),
          fileName: state.importAnalysis.fileName,
          __metadata: {}
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_FAILURE': {
      return {
        ...state,
        importAnalysis: {
          sheets: [],
          departments: undefined,
          __metadata: {
            error: action.payload
          }
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_CANCEL': {
      return {
        ...state,
        importAnalysis: {
          sheets: [],
          departments: undefined,
          __metadata: {
            wasCancelled: true
          }
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_SET_NAME': {
      return {
        ...state,
        importAnalysis: {
          ...state.importAnalysis,
          fileName: action.payload
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_RESET': {
      return {
        ...state,
        importAnalysis: initialState.importAnalysis
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_SELECT_SHEET': {
      const { index, selected } = action.payload;
      return {
        ...state,
        importAnalysis: {
          ...state.importAnalysis,
          // $FlowFixMe
          sheets: state.importAnalysis.sheets.map((sheet, i) => ({
            ...sheet,
            ...(i === index ? { selected } : {})
          }))
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_REQUEST': {
      return {
        ...state
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_CANCEL':
      return {
        ...state,
        importAnalysis: {
          sheets: [],
          departments: undefined,
          __metadata: {
            wasCancelled: true
          }
        }
      };
    case 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_SUCCESS': {
      return {
        ...state,
        importAnalysis: {
          ...state.importAnalysis,
          __metadata: {
            ...state.importAnalysis.__metadata,
            imported: action.payload ? action.payload.imported : 0,
            toBeImported: action.payload ? action.payload.toBeImported : 0,
            isDone: action.payload.done
          }
        }
      };
    }
    case 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_FAILURE': {
      return {
        ...state,
        importAnalysis: {
          ...state.importAnalysis,
          __metadata: {
            error: action.payload
          }
        }
      };
    }
    default:
      return state;
  }
};
