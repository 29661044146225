// @flow

import axios, { type CancelToken, type AxiosPromise } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import type { UpdatePlayMusicActionT } from '../callFlow/callFlowTypes';
import {
  createUpdatePlayMusicCancel,
  createUpdatePlayMusicFailure,
  createUpdatePlayMusicRequest,
  createUpdatePlayMusicSuccess
} from './playMusicActions';
import type { UpdatePropertyT } from '../callFlow/commonCallFlowTypes';

export type PlayMusicUpdateEntityT = {
  label?: string,
  hiddenDirectory?: boolean,
  ivrProperties?: {
    playMusic: {
      maxDuration: UpdatePropertyT<number>,
      musicWait: UpdatePropertyT<string>
    }
  }
};

export type UpdatePlayMusicT = (
  string,
  string,
  PlayMusicUpdateEntityT,
  CancelToken,
  headers: {}
) => ThunkActionT<UpdatePlayMusicActionT>;

export const updatePlayMusic: UpdatePlayMusicT = (
  enterpriseId,
  playMusicId,
  playMusicData,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createUpdatePlayMusicRequest(enterpriseId, playMusicId));
  try {
    const response: AxiosPromise<typeof playMusicData> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/services/playmusics/${playMusicId}`,
      cancelToken,
      data: playMusicData,
      headers
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createUpdatePlayMusicSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdatePlayMusicCancel(enterpriseId, playMusicId));
    } else {
      dispatch(createUpdatePlayMusicFailure(enterpriseId, playMusicId, error));
    }
    return undefined;
  }
};

const playMusicOperations = {
  updatePlayMusic
};

export default playMusicOperations;
