// @flow

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { ExactPropsT } from '../../../commonTypes';
import ResultView from '../../../components/ResultView';

export type OwnPropsT = {
  onConfirm: void => void,
  onCancel: void => void,
  numberOfUsersImported: number,
  maxNumberOfUsersImported: number
};

type StatePropsT = {};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

// eslint-disable-next-line
export class ErrorStep extends Component<PropsT> {
  render(): Element<typeof ResultView> {
    const { t, onConfirm, onCancel, numberOfUsersImported, maxNumberOfUsersImported } = this.props;
    const users = `${numberOfUsersImported}/${maxNumberOfUsersImported}`;
    return (
      <ResultView
        onConfirm={onConfirm}
        onCancel={onCancel}
        viewType="error view"
        secondaryInfos={[
          t('importUsers.errorStep.usersImportError'),
          t('importUsers.errorStep.usersImported', { usersImported: users })
        ]}
        title={t('importUsers.errorStep.title')}
        cancelButtonLabel={t('importUsers.return')}
        confirmButtonLabel={t('importUsers.errorStep.confirm')}
      />
    );
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)
)(ErrorStep);
