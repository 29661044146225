// @flow
import React, { type Element } from 'react';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import uuid from 'uuid';
import { useFormContext } from 'react-hook-form';
import styles from './RadioButton.module.scss';

export type OptionsT = {|
  label: string,
  value: string | boolean,
  children?: Element<*>
|}[];

export type PropsT = {|
  field: string,
  options: OptionsT,
  title?: string,
  disabled?: boolean,
  tooltip?: string | Element<*>,
  onChange?: () => void
|};

export const RadioButton = (props: PropsT): Element<'div'> => {
  const { field, title, options, disabled, tooltip, onChange } = props;

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  return (
    <div className={styles['wide-detail']}>
      {title && (
        <div className={styles['title-area']}>
          <div className={styles.title}>{title}</div>
          {tooltip && <div className={styles.tooltip}>{tooltip}</div>}
        </div>
      )}
      <div role="group" aria-label="Message type" data-cy={`radio-button-${field}`}>
        <RadioGroup value={String(values[field])}>
          {options.map(option => (
            <Radio
              key={uuid()}
              name={`${field}_options`}
              label={option.label}
              value={String(option.value)}
              onChange={() => {
                if (onChange) {
                  onChange();
                }
                setValue(field, option.value, {
                  shouldValidate: false,
                  shouldDirty: true
                });
              }}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
        {options.map(option => {
          if (option.children) {
            return (
              <div key={uuid()} className={styles['radio-children']}>
                {values[field] === option.value && option.children}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default RadioButton;
