import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInstagramRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.37 0H6.63C2.833.006.006 2.833 0 6.63v10.49c.006 3.797 2.833 6.874 6.63 6.88h10.74c3.797-.006 6.624-3.083 6.63-6.88V6.63C23.994 2.833 21.167.006 17.37 0m5.13 17.12c-.006 2.969-2.161 5.374-5.13 5.38H6.63c-2.969-.006-5.124-2.411-5.13-5.38V6.63c.006-2.969 2.161-5.124 5.13-5.13h10.74c2.969.006 5.124 2.161 5.13 5.13zM12 6.25a5.75 5.75 0 1 0 0 11.5A5.75 5.75 0 0 0 17.75 12 5.76 5.76 0 0 0 12 6.25m0 10A4.25 4.25 0 1 1 16.25 12 4.26 4.26 0 0 1 12 16.25m8-11a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0"/></svg>)}
    />
  );
}
  