import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSimAddLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.5 11c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5m0 12c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5m3.5-5.5c0 .3-.2.5-.5.5H18v2.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5V18h-2.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H17v-2.5c0-.3.2-.5.5-.5s.5.2.5.5V17h2.5c.3 0 .5.2.5.5M10.5 13c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-5C4.1 12 3 13.1 3 14.5v4C3 19.9 4.1 21 5.5 21h4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H7v-7zm-5 7c-.8 0-1.5-.7-1.5-1.5V17h2v3zm.5-4H4v-1.5c0-.8.7-1.5 1.5-1.5H6zm6 7.5c0 .3-.2.5-.5.5H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h9.6c.6 0 1.1.3 1.5.7L17.5 5c.3.4.5.8.5 1.3V9c0 .3-.2.5-.5.5S17 9.3 17 9V6.3c0-.2-.1-.5-.2-.7l-4.4-4.3c-.2-.2-.5-.3-.8-.3H10v3.5c0 .3-.2.5-.5.5S9 4.8 9 4.5V1H6v3.5c0 .3-.2.5-.5.5S5 4.8 5 4.5V1H2c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h9.5c.3 0 .5.2.5.5"/></svg>)}
    />
  );
}
  