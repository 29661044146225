import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAnnouncementLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.723 3.057a.5.5 0 0 0-.517.032l-1.105.767C14.142 6.605 10.239 7.999 6.5 8H3.987A4 4 0 0 0 0 12c0 2.206 1.789 4 3.987 4H6a6.63 6.63 0 0 0 2.162 4.868.5.5 0 0 0 .707-.03.5.5 0 0 0-.031-.706 5.63 5.63 0 0 1-1.837-4.109c3.595.118 7.326 1.489 11.1 4.111l1.105.767a.5.5 0 0 0 .516.032.5.5 0 0 0 .269-.443V3.5a.5.5 0 0 0-.268-.443M3.987 15C2.34 15 1 13.654 1 12s1.34-3 2.987-3H6v6zm15.004 4.533-.319-.222c-3.947-2.74-7.871-4.187-11.672-4.304V8.992c3.792-.118 7.716-1.568 11.672-4.315l.319-.222zm4.95-6.93a3.02 3.02 0 0 1-2.342 2.386.5.5 0 0 1-.203-.978 2.03 2.03 0 0 0 1.564-1.602c.22-1.109-.482-2.195-1.564-2.419a.5.5 0 1 1 .203-.979c1.617.335 2.668 1.947 2.342 3.592"/></svg>)}
    />
  );
}
  