// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';

import type {
  CreateUserActionT,
  DeleteUserActionT,
  RetrieveUserActionT,
  RetrieveUserCollectionActionT,
  UpdateUserActionT,
  UserActionT,
  UserEntityT,
  UserStateEntityCollectionT,
  UserStateT,
  ExportUserCollectionActionT,
  RetrieveUserCompleteCollectionActionT,
  RetrieveUserForwardingsActionT,
  ResetUserPasswordActionT,
  RemoveLocationFromUsersActionT,
  ExportUsersProcessActionT
} from './userTypes';

const initialState: UserStateT = {
  byId: {},
  allIds: [],
  searchIds: [],
  sortIds: [],
  __metadata: {}
};

const retrieveForwardingsReducer = (
  state: UserStateT,
  action: RetrieveUserForwardingsActionT
): UserStateT => {
  const {
    meta: { id }
  } = action;
  switch (action.type) {
    case 'ring/entity/user/RETRIEVE_FORWARDINGS_REQUEST': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          isRetrieving: true
        },
        state
      );
    }
    case 'ring/entity/user/RETRIEVE_FORWARDINGS_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            forwardings: action.payload || [],
            __metadata: {}
          }
        },
        allIds: R.union(state.allIds, [id]),
        sortIds: R.union(state.sortIds, [id])
      };
    }
    case 'ring/entity/user/RETRIEVE_FORWARDINGS_FAILURE': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          error: action.payload
        },
        state
      );
    }
    default:
      return state;
  }
};

const retrieveReducer = (state: UserStateT, action: RetrieveUserActionT): UserStateT => {
  const {
    meta: { id }
  } = action;
  switch (action.type) {
    case 'ring/entity/user/RETRIEVE_REQUEST': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          isRetrieving: true
        },
        state
      );
    }
    case 'ring/entity/user/RETRIEVE_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...R.mergeDeepRight(state.byId[id], action.payload),
            __metadata: {}
          }
        },
        allIds: R.union(state.allIds, [id]),
        sortIds: R.union(state.sortIds, [id])
      };
    }
    case 'ring/entity/user/RETRIEVE_FAILURE': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          error: action.payload
        },
        state
      );
    }
    case 'ring/entity/user/RETRIEVE_CANCEL': {
      return R.assocPath(['byId', id, '__metadata'], {}, state);
    }
    default:
      return state;
  }
};

export const removeUndefinedListPropertiesFromUser = (user: UserEntityT) => {
  if (user.userType === 'internalUser') {
    return {
      id: user.id,
      telephonicState: user.telephonicState,
      presenceState: user.presenceState,
      enterpriseId: user.enterpriseId,
      phoneNumbers: user.phoneNumbers,
      emails: user.emails,
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      userType: user.userType,
      addressNumber: user.addressNumber,
      userName: user.userName,
      department: user.department,
      servicePacks: user.servicePacks,
      urlPhoto: user.urlPhoto,
      personId: user.personId,
      language: user.language,
      login: user.login,
      locked: user.locked,
      isAcdAgent: user.isAcdAgent
    };
  }
  return user;
};

const retrieveCollectionReducer = (
  state: UserStateT,
  action: RetrieveUserCollectionActionT | RetrieveUserCompleteCollectionActionT
): UserStateT => {
  switch (action.type) {
    case 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_REQUEST':
    case 'ring/entity/user/RETRIEVE_COLLECTION_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_SUCCESS':
    case 'ring/entity/user/RETRIEVE_COLLECTION_SUCCESS': {
      const ids = R.map(R.prop('id'), action.payload);
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.reduce(
            (
              collection: UserStateEntityCollectionT,
              user: UserEntityT
            ): UserStateEntityCollectionT => ({
              ...collection,
              [user.id]: {
                ...R.mergeDeepRight(
                  state.byId[user.id],
                  removeUndefinedListPropertiesFromUser(user)
                ),
                __metadata: {}
              }
            }),
            {}
          )
        },
        allIds: R.union(state.allIds, ids),
        sortIds: ids,
        __metadata: {}
      };
    }
    case 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_FAILURE':
    case 'ring/entity/user/RETRIEVE_COLLECTION_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/user/RETRIEVE_COLLECTION_CANCEL': {
      return state;
    }
    default:
      return state;
  }
};

const exportReducer = (state: UserStateT, action: ExportUserCollectionActionT): UserStateT => {
  switch (action.type) {
    case 'ring/entity/user/EXPORT_USERS_REQUEST': {
      return {
        ...state,
        __metadata: {
          isExporting: true
        }
      };
    }
    case 'ring/entity/user/EXPORT_USERS_STARTED_SUCCESS': {
      return {
        ...state,
        __metadata: {
          ...state.__metadata,
          exportUUID: action.payload
        }
      };
    }
    case 'ring/entity/user/EXPORT_USERS_CANCEL':
    case 'ring/entity/user/EXPORT_USERS_SUCCESS': {
      return {
        ...state,
        __metadata: {}
      };
    }
    case 'ring/entity/user/EXPORT_USERS_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    default:
      return state;
  }
};

const exportProcessReducer = (state: UserStateT, action: ExportUsersProcessActionT): UserStateT => {
  switch (action.type) {
    case 'ring/entity/user/EXPORT_USERS_PROCESS_REQUEST': {
      return {
        ...state,
        __metadata: {
          ...state.__metadata
        }
      };
    }
    case 'ring/entity/user/EXPORT_USERS_PROCESS_CANCEL':
      return {
        ...state,
        __metadata: {}
      };
    case 'ring/entity/user/EXPORT_USERS_PROCESS_SUCCESS': {
      return {
        ...state,
        __metadata: {
          ...state.__metadata,
          exported: action.payload ? action.payload.exported : 0,
          toBeExported: action.payload ? action.payload.toBeExported : 0
        }
      };
    }
    case 'ring/entity/user/EXPORT_USERS_PROCESS_FAILURE': {
      return {
        ...state,
        __metadata: {
          ...state.__metadata,
          error: action.payload
        }
      };
    }
    default:
      return state;
  }
};

const updateLocationReducer = (
  state: UserStateT,
  action: RemoveLocationFromUsersActionT
): UserStateT => {
  switch (action.type) {
    case 'ring/entity/user/REMOVE_LOCATION_FROM_USERS': {
      const byIds = Object.keys(state.byId).forEach(id => {
        if (state.byId[id].professionalPostalAddress === action.meta.locationName) {
          // eslint-disable-next-line no-param-reassign
          state.byId[id].professionalPostalAddress = null;
        }
        return id;
      });
      return {
        ...state,
        byId: byIds || state.byId
      };
    }
    default:
      return state;
  }
};

const updateReducer = (state: UserStateT, action: UpdateUserActionT): UserStateT => {
  const {
    meta: { id }
  } = action;
  switch (action.type) {
    case 'ring/entity/user/UPDATE_REQUEST': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          isUpdating: true
        },
        state
      );
    }
    case 'ring/entity/user/UPDATE_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...R.mergeDeepRight(state.byId[id], action.payload),
            __metadata: {}
          }
        },
        allIds: R.union(state.allIds, [id]),
        sortIds: R.union(state.sortIds, [id])
      };
    }
    case 'ring/entity/user/UPDATE_FAILURE': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          error: action.payload
        },
        state
      );
    }
    case 'ring/entity/user/UPDATE_CANCEL': {
      return R.assocPath(['byId', id, '__metadata'], {}, state);
    }
    default:
      return state;
  }
};

const createReducer = (state: UserStateT, action: CreateUserActionT): UserStateT => {
  switch (action.type) {
    case 'ring/entity/user/CREATE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isCreating: true
        }
      };
    }
    case 'ring/entity/user/CREATE_SUCCESS': {
      const {
        payload: { id }
      } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...action.payload,
            __metadata: {}
          }
        },
        allIds: R.union(state.allIds, [id]),
        sortIds: R.union(state.sortIds, [id]),
        __metadata: {}
      };
    }
    case 'ring/entity/user/CREATE_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/user/CREATE_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const removeReducer = (state: UserStateT, action: DeleteUserActionT): UserStateT => {
  const {
    meta: { id }
  } = action;
  switch (action.type) {
    case 'ring/entity/user/DELETE_REQUEST': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          isRemoving: true
        },
        state
      );
    }
    case 'ring/entity/user/DELETE_SUCCESS': {
      return {
        ...state,
        byId: R.omit([id], state.byId),
        allIds: R.without([id], state.allIds),
        sortIds: R.without([id], state.sortIds),
        searchIds: R.without([id], state.searchIds)
      };
    }
    case 'ring/entity/user/DELETE_FAILURE': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          error: action.payload,
          deleting: true
        },
        state
      );
    }
    case 'ring/entity/user/DELETE_CANCEL': {
      return R.assocPath(['byId', id, '__metadata'], {}, state);
    }
    default:
      return state;
  }
};

const resetPasswordReducer = (state: UserStateT, action: ResetUserPasswordActionT): UserStateT => {
  const {
    meta: { id }
  } = action;
  switch (action.type) {
    case 'ring/entity/user/RESET_USER_PASSWORD_REQUEST': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          isResettingPassword: true,
          resetError: undefined
        },
        state
      );
    }
    case 'ring/entity/user/RESET_USER_PASSWORD_SUCCESS': {
      return R.assocPath(['byId', id, '__metadata'], {}, state);
    }
    case 'ring/entity/user/RESET_USER_PASSWORD_FAILURE': {
      return R.assocPath(
        ['byId', id, '__metadata'],
        {
          resetError: action.payload
        },
        state
      );
    }
    case 'ring/entity/user/RESET_USER_PASSWORD_CANCEL': {
      // $FlowFixMe: TODO: fix
      return R.omit(['isResettingPassword'], R.assocPath(['byId', id, '__metadata']));
    }
    default:
      return state;
  }
};

const rootReducer = (state: UserStateT = initialState, action: UserActionT): UserStateT => {
  switch (action.type) {
    case 'ring/entity/user/RETRIEVE_FORWARDINGS_REQUEST':
    case 'ring/entity/user/RETRIEVE_FORWARDINGS_SUCCESS':
    case 'ring/entity/user/RETRIEVE_FORWARDINGS_FAILURE': {
      return userReducer.retrieveForwardingsReducer(state, action);
    }
    case 'ring/entity/user/RETRIEVE_REQUEST':
    case 'ring/entity/user/RETRIEVE_SUCCESS':
    case 'ring/entity/user/RETRIEVE_FAILURE':
    case 'ring/entity/user/RETRIEVE_CANCEL': {
      return userReducer.retrieveReducer(state, action);
    }
    case 'ring/entity/user/RETRIEVE_COLLECTION_REQUEST':
    case 'ring/entity/user/RETRIEVE_COLLECTION_SUCCESS':
    case 'ring/entity/user/RETRIEVE_COLLECTION_FAILURE':
    case 'ring/entity/user/RETRIEVE_COLLECTION_CANCEL': {
      return userReducer.retrieveCollectionReducer(state, action);
    }
    case 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_REQUEST':
    case 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_SUCCESS':
    case 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_FAILURE':
    case 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_CANCEL': {
      return userReducer.retrieveCollectionReducer(state, action);
    }
    case 'ring/entity/user/UPDATE_REQUEST':
    case 'ring/entity/user/UPDATE_SUCCESS':
    case 'ring/entity/user/UPDATE_FAILURE':
    case 'ring/entity/user/UPDATE_CANCEL': {
      return userReducer.updateReducer(state, action);
    }
    case 'ring/entity/user/REMOVE_LOCATION_FROM_USERS': {
      return userReducer.updateLocationReducer(state, action);
    }
    case 'ring/entity/user/CREATE_REQUEST':
    case 'ring/entity/user/CREATE_SUCCESS':
    case 'ring/entity/user/CREATE_FAILURE':
    case 'ring/entity/user/CREATE_CANCEL': {
      return userReducer.createReducer(state, action);
    }
    case 'ring/entity/user/DELETE_REQUEST':
    case 'ring/entity/user/DELETE_SUCCESS':
    case 'ring/entity/user/DELETE_FAILURE':
    case 'ring/entity/user/DELETE_CANCEL': {
      return userReducer.removeReducer(state, action);
    }
    case 'ring/entity/user/EXPORT_USERS_REQUEST':
    case 'ring/entity/user/EXPORT_USERS_SUCCESS':
    case 'ring/entity/user/EXPORT_USERS_STARTED_SUCCESS':
    case 'ring/entity/user/EXPORT_USERS_FAILURE':
    case 'ring/entity/user/EXPORT_USERS_CANCEL':
      return userReducer.exportReducer(state, action);
    case 'ring/entity/user/EXPORT_USERS_PROCESS_REQUEST':
    case 'ring/entity/user/EXPORT_USERS_PROCESS_SUCCESS':
    case 'ring/entity/user/EXPORT_USERS_PROCESS_FAILURE':
    case 'ring/entity/user/EXPORT_USERS_PROCESS_CANCEL':
      return userReducer.exportProcessReducer(state, action);
    case 'ring/entity/user/RESET_USER_PASSWORD_REQUEST':
    case 'ring/entity/user/RESET_USER_PASSWORD_SUCCESS':
    case 'ring/entity/user/RESET_USER_PASSWORD_FAILURE':
    case 'ring/entity/user/RESET_USER_PASSWORD_CANCEL':
      return userReducer.resetPasswordReducer(state, action);
    default:
      return state;
  }
};

const userReducer = {
  initialState,
  retrieveForwardingsReducer,
  retrieveReducer,
  retrieveCollectionReducer,
  updateReducer,
  updateLocationReducer,
  createReducer,
  removeReducer,
  exportReducer,
  exportProcessReducer,
  resetPasswordReducer,
  rootReducer
};

export default userReducer;
