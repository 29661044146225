// @flow strict-local

import axios, { type AxiosPromise, type CancelToken } from 'axios';
import * as R from 'ramda';

import HTTP from 'http-status-codes';
import {
  createRetrieveCallFlowCollectionRequest,
  createRetrieveCallFlowCollectionSuccess,
  createRetrieveCallFlowCollectionFailure,
  createRetrieveCallFlowCollectionCancel,
  createRetrieveCallFlowRequest,
  createRetrieveCallFlowSuccess,
  createRetrieveCallFlowFailure,
  createRetrieveCallFlowCancel
} from './callFlowActions';
import { type ThunkActionT } from '../../../commonTypes';
import type {
  RetrieveCallFlowCollectionActionT,
  RetrieveCallFlowActionT,
  CallFlowEntityT,
  CallFlowTypeT,
  CallFlowUrlNameT,
  AudioPropertyT
} from './callFlowTypes';
import { asyncPool } from '../../../helpers';
import type { VoiceMailAudioTypesT, VoiceMailLevelT } from '../voicemailTypes';

const DEFAULT_CALL_FLOW_BATCH_RETRIEVE_POOL_SIZE = 3;
const URL_SEGMENTS = {
  ACD_CUSTOMER_SERVICE: 'acds',
  ACD_SWITCHBOARD: 'acds',
  PLAY_MUSIC: 'playmusics',
  EXTENSION_GROUP: 'groups',
  SPEED_DIAL: 'speeddials',
  OC: 'speeddials',
  WELCOME_ATTENDANT: 'welcomeattendants'
};

type RetrieveFnT = (
  string,
  string,
  CallFlowTypeT,
  CancelToken,
  boolean
) => ThunkActionT<RetrieveCallFlowActionT>;

// eslint-disable-next-line arrow-body-style
export const retrieve: RetrieveFnT = (
  entepriseId,
  callFlowId,
  callFlowType,
  cancelToken,
  retrying = false
) => async dispatch => {
  dispatch(createRetrieveCallFlowRequest(entepriseId, callFlowId, callFlowType, retrying));
  const serviceUrlSegment = R.pathOr('invalid', [callFlowType], URL_SEGMENTS);

  try {
    const response: AxiosPromise<CallFlowEntityT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${entepriseId}/services/${serviceUrlSegment}/${callFlowId}`,
      cancelToken
    });

    // $FlowFixMe
    const { data } = await response;
    dispatch(createRetrieveCallFlowSuccess(entepriseId, callFlowId, callFlowType, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveCallFlowCancel(entepriseId, callFlowId, callFlowType));
    } else {
      dispatch(createRetrieveCallFlowFailure(entepriseId, callFlowId, callFlowType, error));
    }
    return undefined;
  }
};

type PooledBatchRetrieveFnT = (
  string,
  cancelToken: CancelToken,
  Array<{
    callFlowId: string,
    callFlowType: CallFlowTypeT
  }>,
  number
) => ThunkActionT<RetrieveCallFlowActionT>;

export const pooledBatchRetrieve: PooledBatchRetrieveFnT = (
  enterpriseId,
  cancelToken,
  batchData,
  batchSize = DEFAULT_CALL_FLOW_BATCH_RETRIEVE_POOL_SIZE
) => async dispatch => {
  await asyncPool(
    batchSize,
    batchData.map(data => [enterpriseId, data.callFlowId, data.callFlowType, cancelToken]),
    // $FlowFixMe
    R.compose(dispatch, R.apply(retrieve))
  );
};

type RetrieveCollectionFnT = (
  string,
  CancelToken,
  CallFlowTypeT,
  ?{}
) => AxiosPromise<
  RetrieveCallFlowCollectionActionT,
  { results: CallFlowEntityT[] } | CallFlowEntityT[] | void
>;

// eslint-disable-next-line arrow-body-style
export const retrieveCollection: RetrieveCollectionFnT = (
  id,
  cancelToken,
  callFlowType,
  params = {}
) => async dispatch => {
  dispatch(createRetrieveCallFlowCollectionRequest(id));
  try {
    const serviceUrlSegment = R.pathOr('', [callFlowType], URL_SEGMENTS);
    const response = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${id}/services/${serviceUrlSegment}`,
      cancelToken,
      params
    });
    const { data } = await response;
    dispatch(
      createRetrieveCallFlowCollectionSuccess(id, data.results, {
        totalCount: data.totalCount,
        offset: data.offset,
        lenght: data.lenght
      })
    );
    return data.results;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveCallFlowCollectionCancel());
    } else {
      dispatch(createRetrieveCallFlowCollectionFailure(id, error));
    }
    return undefined;
  }
};

type RetrieveAudioFileFnT = (
  string,
  CallFlowUrlNameT,
  string,
  AudioPropertyT,
  CancelToken
) => Promise<ArrayBuffer | void>;

// eslint-disable-next-line arrow-body-style
export const retrieveAudioFile: RetrieveAudioFileFnT = async (
  enterpriseId,
  serviceName,
  callflowId,
  audioProperty,
  cancelToken
) => {
  try {
    const response: AxiosPromise<ArrayBuffer> = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/api/v1/enterprises/${enterpriseId}/services/${serviceName}/${callflowId}/audios`,
      cancelToken,
      params: audioProperty
    });
    const { data } = await response;
    return data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error;
    }
  }
  return undefined;
};

type RetrieveIvrAudioFileFnT = (
  string,
  CallFlowUrlNameT,
  string,
  string,
  CancelToken
) => Promise<?ArrayBuffer>;

// eslint-disable-next-line arrow-body-style
export const retrieveIvrAudioFile: RetrieveIvrAudioFileFnT = async (
  enterpriseId,
  serviceName,
  callflowId,
  audioFilename,
  cancelToken
) => {
  try {
    const response: AxiosPromise<ArrayBuffer> = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/api/v1/enterprises/${enterpriseId}/services/${serviceName}/${callflowId}/audios/${audioFilename}`,
      cancelToken
    });
    const { data } = await response;
    return data;
  } catch (error) {
    return undefined;
  }
};

type RetrieveVoiceMailAudioFnT = (
  string,
  string,
  VoiceMailLevelT,
  VoiceMailAudioTypesT,
  CancelToken
) => Promise<?ArrayBuffer>;
export const retrieveVoiceMailAudioFile: RetrieveVoiceMailAudioFnT = async (
  enterpriseId,
  entityId,
  audioLevel,
  audioType,
  cancelToken
) => {
  try {
    const response: AxiosPromise<ArrayBuffer> = axios({
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/api/v1/enterprises/${enterpriseId}/voicemail/audios/${audioLevel}?entityId=${entityId}&type=${audioType}`,
      cancelToken
    });
    const { data } = await response;
    return data;
  } catch (error) {
    return undefined;
  }
};

export type AudioCreateT = {
  service?: 'ACDCallCenter' | 'PlayMusic' | 'CallQueuingService' | 'AnnoucementService',
  field?: string,
  level?: 'EXTENSION',
  filename?: string
};

type UploadVoiceMailAudioFnT = (
  string,
  string,
  VoiceMailLevelT,
  VoiceMailAudioTypesT,
  File,
  CancelToken,
  headers: {}
) => Promise<?string>;
export const uploadVoiceMailAudio: UploadVoiceMailAudioFnT = async (
  enterpriseId,
  entityId,
  audioLevel,
  entityType,
  audioFile,
  cancelToken,
  headers
) => {
  try {
    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('level', audioLevel);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    await axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/voicemail/audios/${audioLevel}?entityId=${entityId}&type=${entityType}`,
      data: formData,
      config,
      cancelToken,
      headers
    });
  } catch (error) {
    console.warn('Import audio error', error);
    return audioFile.name;
  }
  return undefined;
};

type UploadAudioT = (
  string,
  CallFlowUrlNameT,
  string,
  AudioCreateT,
  File,
  CancelToken,
  headers: {}
) => Promise<?string>;

export const uploadAudio: UploadAudioT = async (
  enterpriseId,
  serviceName,
  serviceId,
  audioProperty,
  audioFile,
  cancelToken,
  headers
) => {
  try {
    const formData = new FormData();
    formData.append('file', audioFile);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    await axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/services/${serviceName}/${serviceId}/audios`,
      data: formData,
      params: audioProperty,
      config,
      cancelToken,
      headers
    });
    // eslint-disable-next-line no-empty
  } catch (error) {
    if (error.response.status === HTTP.UNPROCESSABLE_ENTITY) {
      return audioProperty.filename;
    }
  }
  return undefined;
};

const callFlowOperations = {
  retrieve,
  pooledBatchRetrieve,
  retrieveCollection,
  retrieveAudioFile,
  uploadAudio,
  retrieveVoiceMailAudioFile,
  uploadVoiceMailAudio
};

export default callFlowOperations;
