import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmartphoneFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 1.5v14.75a.25.25 0 0 1-.25.25H5.25a.25.25 0 0 1-.25-.25V1.5A1.5 1.5 0 0 1 6.5 0h11A1.5 1.5 0 0 1 19 1.5M19 18v4.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 5 22.5V18a.25.25 0 0 1 .25-.25h13.5A.25.25 0 0 1 19 18m-6.125 2.615c0-.231-.1-.463-.258-.624a.91.91 0 0 0-1.233 0c-.159.161-.258.392-.258.624s.099.452.258.624c.159.16.387.261.616.261a.9.9 0 0 0 .617-.261.93.93 0 0 0 .258-.624"/></svg>)}
    />
  );
}
  