import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSustainabilityFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.73 2.84a1.2 1.2 0 0 0-.16-.41 1 1 0 0 0-.66-.43 1 1 0 0 0-.72.21 13.6 13.6 0 0 1-7.84 2c-6.11 0-8.75 2.43-9 2.69a8.41 8.41 0 0 0-2.774 9.767L.258 18.684a.75.75 0 1 0 .984 1.131L4.9 16.63h.009a24.7 24.7 0 0 1 8.61-4.39l.026-.008a.61.61 0 0 1 .743.438.6.6 0 0 1-.05.47.66.66 0 0 1-.38.3 23.2 23.2 0 0 0-8.15 4.15l-1.53 1.33a.26.26 0 0 0-.09.17.24.24 0 0 0 .07.18 8.13 8.13 0 0 0 6.07 2.73 12.2 12.2 0 0 0 5.89-1.64C25.07 15.33 24.21 5.69 23.73 2.84"/></svg>)}
    />
  );
}
  