import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUpwardRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m19.53 7-6-5.63a2.28 2.28 0 0 0-3.08 0L4.48 7a2.06 2.06 0 0 0-.73 1.64 2.29 2.29 0 0 0 2.32 2.13h2.18V21.5c0 .966.784 1.75 1.75 1.75h4a1.75 1.75 0 0 0 1.75-1.75V10.75H18a2.19 2.19 0 0 0 2.25-2.25 2.23 2.23 0 0 0-.72-1.5m-1 2.08a.66.66 0 0 1-.54.19h-2.88a.85.85 0 0 0-.86.84V21.5a.25.25 0 0 1-.25.25h-4a.25.25 0 0 1-.25-.25V10.11a.85.85 0 0 0-.84-.86H6.07a.79.79 0 0 1-.82-.68.57.57 0 0 1 .25-.49l6-5.63a.78.78 0 0 1 1 0l6 5.63a.7.7 0 0 1 .25.48.64.64 0 0 1-.21.5z"/></svg>)}
    />
  );
}
  