import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSimAddFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.25 18.25c0-3.619 2.763-6.598 6.288-6.956a.5.5 0 0 0 .462-.493V6.305c0-.478-.171-.94-.514-1.335L13.08.705A2 2 0 0 0 11.56 0h-1.185a.25.25 0 0 0-.25.25V4.5a.625.625 0 0 1-1.25 0V.25a.25.25 0 0 0-.25-.25h-2.25a.25.25 0 0 0-.25.25V4.5a.625.625 0 0 1-1.25 0V.25a.25.25 0 0 0-.25-.25H2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h10.92c.449 0 .65-.535.336-.855a6.97 6.97 0 0 1-2.006-4.895m-5.5 2H5.5c-.965 0-1.75-.785-1.75-1.75v-1.25h2zm0-4.5h-2V14.5c0-.965.785-1.75 1.75-1.75h.25zm4 4.5h-2.5v-7.5h2.5zm8.5-7.75a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 1 0 0-11.5m3 6.375h-2.375v2.375a.625.625 0 0 1-1.25 0v-2.375H15.25a.625.625 0 0 1 0-1.25h2.375V15.25a.625.625 0 0 1 1.25 0v2.375h2.375a.625.625 0 0 1 0 1.25"/></svg>)}
    />
  );
}
  