import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEuroLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11M7.35 10h6.15a.5.5 0 0 1 0 1H7.09A7 7 0 0 0 7 12q.008.503.09 1h6.41a.5.5 0 0 1 0 1H7.35a6 6 0 0 0 8.88 3.06.5.5 0 1 1 .54.84A7 7 0 0 1 6.29 14H4.5a.5.5 0 0 1 0-1h1.58a6.3 6.3 0 0 1 0-2H4.5a.5.5 0 0 1 0-1h1.79a7 7 0 0 1 10.48-3.9.5.5 0 1 1-.54.84A6 6 0 0 0 7.35 10"/></svg>)}
    />
  );
}
  