import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMoreFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.8 12c0 1.5-1.2 2.8-2.8 2.8S.2 13.5.2 12 1.5 9.2 3 9.2s2.8 1.3 2.8 2.8M12 9.2c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8m9 0c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8 2.8-1.2 2.8-2.8-1.3-2.8-2.8-2.8"/></svg>)}
    />
  );
}
  