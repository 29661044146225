import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPilvilinnaFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.5 22h-1.8v-2.5c0-.3-.2-.5-.5-.5h-2.5c-.3 0-.5.2-.5.5V22H16v-9.6l-3.5-11c-.1-.3-.4-.4-.6-.4s-.3.2-.4.4L8 12.4V22H5.8v-2.5c0-.3-.2-.5-.5-.5H2.8c-.3 0-.5.2-.5.5V22H.5c-.3 0-.5.2-.5.5s.2.5.5.5h23c.3 0 .5-.2.5-.5s-.2-.5-.5-.5M13 18.5c0 .3-.2.5-.5.5h-1c-.3 0-.5-.2-.5-.5V15c0-.6.4-1 1-1s1 .4 1 1zm11-1c0 .4-.3.8-.8.8s-.8-.3-.8-.8c0-1.2-1-2.2-2.2-2.2-.4 0-.7-.3-.8-.8 0-2.1-1.2-3.9-3.1-4.8-.4-.2-.5-.6-.4-1 .2-.4.6-.5 1-.4 2.2 1 3.7 3.1 3.9 5.5 1.9.4 3.2 1.9 3.2 3.7m-23.2.7c-.4 0-.7-.3-.8-.8 0-1.8 1.3-3.3 3.1-3.7.2-1.7 1.4-3.1 3.1-3.7.4-.1.8.1.9.4.1.4-.1.8-.5.9-1.3.4-2.2 1.7-2.2 3.1 0 .4-.3.7-.8.8h-.3c-1.1.2-1.9 1.1-1.9 2.2.1.5-.2.8-.6.8"/></svg>)}
    />
  );
}
  