import React from 'react';
import IconContainer from '../IconContainer';

export default function IconKeyFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.028 7.029-1.914-1.917.702-.7c.778-.778.78-2.044.003-2.824A1.97 1.97 0 0 0 20.409 1h-.003c-.532 0-1.033.207-1.41.584L8.522 12.062c-2.428-1.111-5.372-.379-6.985 1.787-1.688 2.267-1.455 5.484.544 7.482A5.74 5.74 0 0 0 6.142 23a5.7 5.7 0 0 0 3.419-1.128c2.164-1.614 2.894-4.56 1.783-6.987l2.721-2.719 1.913 1.92a2 2 0 0 0 1.928.516 2 2 0 0 0 1.411-1.412 2 2 0 0 0-.483-1.892q-.015-.02-.034-.038l-1.916-1.915 1.407-1.409 1.922 1.924a2.004 2.004 0 0 0 2.797-.025 2.01 2.01 0 0 0 .018-2.806M6.143 19.384c-1.168 0-2.119-.951-2.119-2.12s.951-2.12 2.119-2.12 2.119.951 2.119 2.12-.951 2.12-2.119 2.12"/></svg>)}
    />
  );
}
  