import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFolderLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 5H10.8L9.1 2.8c-.4-.5-1-.8-1.6-.8H2C.9 2 0 2.9 0 4v16c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m1 15c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h5.5c.3 0 .6.1.8.4l1.8 2.4c.1.1.2.2.4.2H22c.6 0 1 .4 1 1z"/></svg>)}
    />
  );
}
  