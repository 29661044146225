// @flow

import * as R from 'ramda';
import React, { Component, type Element } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  Field,
  FormSection,
  reduxForm,
  type FormProps as FormPropsT,
  getFormValues,
  change
} from 'redux-form';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { ContextRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import type { StoreStateT } from '../../../../commonTypes';
import { selectors as userSelectors } from '../../../../ducks/entities/user';
import { selectors as selectConfig } from '../../../../ducks/config';
import type {
  ExternalUserEntityT,
  ExternalUserStateEntityT,
  InternalUserEntityT
} from '../../../../ducks/entities/user/userTypes';
import {
  goToEditUser,
  goToEnterpriseUsers,
  type GoToEnterpriseUsersFnT,
  goToUserDetails
} from '../../../../navigationOperations';
import fieldValidators, {
  EXT_ADDRESS_MAX_LENGTH,
  EXT_COMPANY_MAX_LENGTH,
  EXT_EMAIL_MAX_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  EXT_FIRSTNAME_MAX_LENGTH,
  EXT_LASTNAME_MAX_LENGTH,
  EXT_NICKNAME_MAX_LENGTH,
  EXT_TITLE_MAX_LENGTH,
  EXT_WEBSITE_MAX_LENGTH,
  ADDITIONAL_EXPLANATIONS_MAX_LENGTH,
  ADDITIONAL_INFO_MAX_LENGTH,
  CORPORATE_USER_ID_MAX_LENGTH,
  COST_CENTER_MAX_LENGTH,
  ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH
} from '../../../../fieldValidators';
import Section from '../../../../components/Section/Section';
import TextError from '../../../../components/Error/TextError';
import ErrorBoundary from '../../../../components/Error/ErrorBoundary';
import FormInput from '../../../../components/InputComponent/FormInput';
import ConfirmButton from '../../../../components/Button/ConfirmButton';
import { categoryHasData } from '../../../../helpers';
import FixedTitle from '../FixedTitle';
import Title from '../Title';
import EditTitle from '../EditTitle';
import Avatar from '../../../../components/Avatar/Avatar';
import CancelButton from '../../../../components/Button/CancelButton';
import ActionButton from '../../../../components/Button/ActionButton';
import UserLoadingError from '../UserLoadingError';
import { MAX_FAVOURITES_RESULTS } from '../../../../constants';
import { getNumbers } from '../../../../utils/validationUtils';
import { DUTIES_MAX_LENGTH } from '../FormHelpers';
import { KeywordInputField } from '../KeywordInputField';
import styles from './ExternalUserDetails.module.scss';

export type InitialFormValuesT = {
  +externalUserEdit: {
    +contactInfo: {
      +firstName: ?string,
      +lastName: ?string,
      +title: ?string,
      +nickName: ?string,
      +mobileNumber: ?string,
      +homeNumber: ?string,
      +professionalPhoneNumber: ?string,
      +defaultPhoneNumber: ?{ value: string, label: string },
      +emails: ?string,
      +company: ?string,
      +website: ?string,
      +address: ?string,
      +faxAddress: ?string
    },
    +additionalInformation: {
      +additionalExplanations: ?string,
      +additionalInfo: ?string,
      +corporateUserId: ?string,
      +costCenter: ?string,
      +accessCtrlSystemPersonId: ?string,
      +duties: ?string
    }
  }
};

type OwnPropsT = {
  onSave: InternalUserEntityT => Promise<void>,
  onClose: () => void,
  userId: string,
  showLoadingSpinner: boolean,
  onRemoveExternalUser: string => void,
  showFixedTitle: boolean,
  onToggleFavourite: (selected: boolean) => *,
  showLoadingError?: boolean,
  reloadFunc: () => void,
  alwaysEdit?: boolean
};

type StatePropsT = {
  initialValues: InitialFormValuesT,
  currentValues: InitialFormValuesT,
  user: ExternalUserStateEntityT,
  isUpdating: boolean,
  hasError: boolean,
  hasDeleteError: boolean,
  isFavourite: boolean,
  formInitialValues: InitialFormValuesT,
  numberOfFavourites: number
};

type DispatchPropsT = {
  goToEditUser: (string, string, string) => *,
  goToUserDetails: (string, string, string) => *,
  goToEnterpriseUsers: GoToEnterpriseUsersFnT,
  changeFormValue: *
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<ContextRouter>,
  // $FlowFixMe
  ...FormPropsT,
  ...$Exact<WithTranslationProps>
|};

type StateT = {
  successIndicatorToggled: boolean
};

export const CONTACT_INFO_SECTION = 'external-user-details-contact-info-section';
const SUCCESS_INDICATOR_DURATION = 1500;

export class ExternalUserDetails extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.disableEditMode = this.disableEditMode.bind(this);
    this.validateNickName = this.validateNickName.bind(this);
    this.validateTitle = this.validateTitle.bind(this);
    this.validateLastName = this.validateLastName.bind(this);
    this.validateFirstName = this.validateFirstName.bind(this);
    this.validateMobileNumber = this.validateMobileNumber.bind(this);
    this.validateHomeNumber = this.validateHomeNumber.bind(this);
    this.validateProfessionalPhoneNumber = this.validateProfessionalPhoneNumber.bind(this);
    this.validateDefaultPhoneNumber = this.validateDefaultPhoneNumber.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateWebsite = this.validateWebsite.bind(this);
    this.validateAddress = this.validateAddress.bind(this);
    this.confirmRemoveExternalUser = this.confirmRemoveExternalUser.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validateCompany = this.validateCompany.bind(this);
    this.validateFaxAddress = this.validateFaxAddress.bind(this);
    this.createContactInfoSectionUpdateUserPayload = this.createContactInfoSectionUpdateUserPayload.bind(
      this
    );
    this.wasSuccessFullyUpdated = this.wasSuccessFullyUpdated.bind(this);
    this.wasUnsuccessFullyUpdated = this.wasUnsuccessFullyUpdated.bind(this);
    this.scheduleSuccessIndicator = this.scheduleSuccessIndicator.bind(this);
    this.stopEditing = this.stopEditing.bind(this);
    this.startEditing = this.startEditing.bind(this);
    this.showUser = this.showUser.bind(this);
    this.hasFieldError = this.hasFieldError.bind(this);
    this.getPhoneNumberOptions = this.getPhoneNumberOptions.bind(this);
    this.validateDuties = this.validateDuties.bind(this);
    this.validateAdditionalExplanations = this.validateAdditionalExplanations.bind(this);
    this.validateAdditionalInfo = this.validateAdditionalInfo.bind(this);
    this.validateCorporateUserId = this.validateCorporateUserId.bind(this);
    this.validateCostCenter = this.validateCostCenter.bind(this);
    this.validateAccessCtrlSystemPersonId = this.validateAccessCtrlSystemPersonId.bind(this);
  }

  state = {
    successIndicatorToggled: false
  };

  componentDidMount() {
    const { formInitialValues } = this.props;
    this.props.destroy();
    this.props.initialize(formInitialValues);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PropsT) {
    const { user: newUser, isUpdating } = nextProps;
    if (!isUpdating) {
      if (newUser && this.props.user.id !== newUser.id) {
        this.disableEditMode();
      }

      if (this.wasSuccessFullyUpdated(nextProps)) {
        this.scheduleSuccessIndicator();
      }
    }
  }

  showUser: *;

  showUser(userId: ?string): void {
    const {
      goToUserDetails: goToUserPage,
      goToEnterpriseUsers: goToUsers,
      match: {
        params: { id: enterpriseId }
      }
    } = this.props;
    if (enterpriseId != null && userId != null) {
      goToUserPage(enterpriseId, userId, 'externalUser');
    } else if (enterpriseId != null) {
      goToUsers(enterpriseId);
    }
  }

  stopEditing: *;

  stopEditing() {
    const { user } = this.props;
    this.showUser(user ? user.id : null);
  }

  scheduleSuccessIndicator: *;

  scheduleSuccessIndicator(): void {
    this.setState(
      {
        successIndicatorToggled: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            successIndicatorToggled: false
          });
        }, SUCCESS_INDICATOR_DURATION);
      }
    );
  }

  wasSuccessFullyUpdated: *;

  wasSuccessFullyUpdated(nextProps: PropsT): boolean {
    return this.props.isUpdating && !nextProps.isUpdating && !nextProps.hasError;
  }

  wasUnsuccessFullyUpdated: *;

  wasUnsuccessFullyUpdated(nextProps: PropsT): boolean {
    return this.props.isUpdating && !nextProps.isUpdating && nextProps.hasError;
  }

  disableEditMode: *;

  disableEditMode(): void {
    this.props.reset();
  }

  validateNickName: *;

  validateNickName(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      EXT_NICKNAME_MAX_LENGTH,
      this.props.t('externalUsers.validators.nickName')
    );
  }

  validateTitle: *;

  validateTitle(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      EXT_TITLE_MAX_LENGTH,
      this.props.t('externalUsers.validators.title')
    );
  }

  validateMobileNumber: *;

  validateMobileNumber(value: string): ?string {
    return fieldValidators.externalContactPhoneNumberValidator(
      value,
      this.props.t('externalUsers.validators.mobileNumber')
    );
  }

  validateHomeNumber: *;

  validateHomeNumber(value: string): ?string {
    return fieldValidators.externalContactPhoneNumberValidator(
      value,
      this.props.t('externalUsers.validators.homeNumber')
    );
  }

  validateProfessionalPhoneNumber: *;

  validateProfessionalPhoneNumber(value: string): ?string {
    return fieldValidators.externalContactPhoneNumberValidator(
      value,
      this.props.t('externalUsers.validators.professionalPhoneNumber')
    );
  }

  validateDefaultPhoneNumber: *;

  validateDefaultPhoneNumber(value: { value: string }): ?string {
    return fieldValidators.externalContactPhoneNumberValidator(
      value ? value.value : '',
      this.props.t('externalUsers.validators.defaultPhoneNumber')
    );
  }

  validateEmail: *;

  validateEmail(value: string): ?string {
    return fieldValidators.commaSeparatedUniqueEmailsValidator(
      value,
      this.props.t('generic.validators.email')
    );
  }

  validateFaxAddress: *;

  validateFaxAddress(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      PHONE_NUMBER_MAX_LENGTH,
      this.props.t('generic.validators.faxAddress')
    );
  }

  validateCompany: *;

  validateCompany(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      EXT_COMPANY_MAX_LENGTH,
      this.props.t('generic.validators.company')
    );
  }

  validateFirstName: *;

  validateFirstName(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      EXT_FIRSTNAME_MAX_LENGTH,
      this.props.t('generic.validators.firstName')
    );
  }

  validateLastName: *;

  validateLastName(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      EXT_LASTNAME_MAX_LENGTH,
      this.props.t('generic.validators.lastName')
    );
  }

  validateWebsite: *;

  validateWebsite(value: string): ?string {
    return fieldValidators.urlValidator(value, this.props.t('generic.validators.website'));
  }

  validateAddress: *;

  validateAddress(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      EXT_ADDRESS_MAX_LENGTH,
      this.props.t('generic.validators.address')
    );
  }

  validateDuties: *;

  validateDuties(value: string): ?string {
    const { t } = this.props;
    return fieldValidators.maxLengthValidator(
      value,
      DUTIES_MAX_LENGTH,
      t('generic.validators.duties')
    );
  }

  validateAdditionalExplanations: *;

  validateAdditionalExplanations(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      ADDITIONAL_EXPLANATIONS_MAX_LENGTH,
      this.props.t('generic.validators.additionalExplanations')
    );
  }

  validateCorporateUserId: *;

  validateCorporateUserId(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      CORPORATE_USER_ID_MAX_LENGTH,
      this.props.t('generic.validators.corporateUserId')
    );
  }

  validateCostCenter: *;

  validateCostCenter(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      COST_CENTER_MAX_LENGTH,
      this.props.t('generic.validators.costCenter')
    );
  }

  validateAccessCtrlSystemPersonId: *;

  validateAccessCtrlSystemPersonId(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH,
      this.props.t('generic.validators.accessCtrlSystemPersonId')
    );
  }

  validateAdditionalInfo: *;

  validateAdditionalInfo(value: string): ?string {
    return fieldValidators.maxLengthValidator(
      value,
      ADDITIONAL_INFO_MAX_LENGTH,
      this.props.t('generic.validators.additionalInfo')
    );
  }

  confirmRemoveExternalUser: *;

  confirmRemoveExternalUser(e: SyntheticEvent<HTMLButtonElement>) {
    const {
      user: { firstName = '', lastName = '' },
      onRemoveExternalUser
    } = this.props;

    const fullName = [firstName, lastName].join(' ').trim();

    if (e) e.preventDefault();
    onRemoveExternalUser(fullName);
  }

  createContactInfoSectionUpdateUserPayload: *;

  createContactInfoSectionUpdateUserPayload(
    values: InitialFormValuesT
  ): { updated: boolean, user: ExternalUserEntityT } {
    const {
      user,
      initialValues,
      match: {
        params: { id: enterpriseId }
      }
    } = this.props;

    const updateUserBase: ExternalUserEntityT = {
      id: user ? user.id : '1',
      enterpriseId,
      userType: 'externalUser'
    };

    const initialContactInfo = initialValues.externalUserEdit.contactInfo;
    const newContactInfo = values.externalUserEdit.contactInfo;
    const initialAdditionalInfo = initialValues.externalUserEdit.additionalInformation;
    const newAdditionalInfo = values.externalUserEdit.additionalInformation;

    return {
      updated:
        initialContactInfo.firstName !== newContactInfo.firstName ||
        initialContactInfo.lastName !== newContactInfo.lastName ||
        initialContactInfo.nickName !== newContactInfo.nickName ||
        initialContactInfo.title !== newContactInfo.title ||
        initialContactInfo.mobileNumber !== newContactInfo.mobileNumber ||
        initialContactInfo.homeNumber !== newContactInfo.homeNumber ||
        initialContactInfo.professionalPhoneNumber !== newContactInfo.professionalPhoneNumber ||
        (newContactInfo.defaultPhoneNumber &&
          initialContactInfo.defaultPhoneNumber.value !==
            newContactInfo.defaultPhoneNumber.value) ||
        !R.equals(initialContactInfo.emails, newContactInfo.emails) ||
        initialContactInfo.company !== newContactInfo.company ||
        initialContactInfo.website !== newContactInfo.website ||
        initialContactInfo.address !== newContactInfo.address ||
        initialContactInfo.faxAddress !== newContactInfo.faxAddress ||
        initialAdditionalInfo.additionalExplanations !== newAdditionalInfo.additionalExplanations ||
        initialAdditionalInfo.additionalInfo !== newAdditionalInfo.additionalInfo ||
        initialAdditionalInfo.corporateUserId !== newAdditionalInfo.corporateUserId ||
        initialAdditionalInfo.costCenter !== newAdditionalInfo.costCenter ||
        initialAdditionalInfo.accessCtrlSystemPersonId !==
          newAdditionalInfo.accessCtrlSystemPersonId ||
        initialAdditionalInfo.duties !== newAdditionalInfo.duties,
      user: {
        ...updateUserBase,
        ...(initialContactInfo.nickName !== newContactInfo.nickName &&
        newContactInfo.nickName != null
          ? { nickName: newContactInfo.nickName }
          : {}),
        ...(initialContactInfo.title !== newContactInfo.title && newContactInfo.title != null
          ? { title: newContactInfo.title }
          : {}),
        ...(initialContactInfo.firstName !== newContactInfo.firstName &&
        newContactInfo.firstName != null
          ? { firstName: newContactInfo.firstName }
          : {}),
        ...(initialContactInfo.lastName !== newContactInfo.lastName &&
        newContactInfo.lastName != null
          ? { lastName: newContactInfo.lastName }
          : {}),
        ...(initialContactInfo.mobileNumber !== newContactInfo.mobileNumber &&
        newContactInfo.mobileNumber != null
          ? { mobileNumber: newContactInfo.mobileNumber }
          : {}),
        ...(initialContactInfo.homeNumber !== newContactInfo.homeNumber &&
        newContactInfo.homeNumber != null
          ? { homeNumber: newContactInfo.homeNumber }
          : {}),
        ...(initialContactInfo.professionalPhoneNumber !== newContactInfo.professionalPhoneNumber &&
        newContactInfo.professionalPhoneNumber != null
          ? { professionalPhoneNumber: newContactInfo.professionalPhoneNumber }
          : {}),
        ...(newContactInfo.defaultPhoneNumber &&
        initialContactInfo.defaultPhoneNumber.value !== newContactInfo.defaultPhoneNumber.value &&
        newContactInfo.defaultPhoneNumber.value != null
          ? { defaultPhoneNumber: newContactInfo.defaultPhoneNumber.value }
          : {}),
        ...(!R.equals(initialContactInfo.emails, newContactInfo.emails)
          ? {
              emails: (newContactInfo.emails || '')
                .split(',')
                .map(email => email.trim())
                .join(';')
            }
          : {}),
        ...(initialContactInfo.company !== newContactInfo.company && newContactInfo.company != null
          ? { company: newContactInfo.company }
          : {}),
        ...(initialContactInfo.website !== newContactInfo.website && newContactInfo.website != null
          ? { website: newContactInfo.website }
          : {}),
        // $FlowFixMe
        ...(initialContactInfo.address !== newContactInfo.address && newContactInfo.address != null
          ? { address: newContactInfo.address }
          : {}),
        ...(initialContactInfo.faxAddress !== newContactInfo.faxAddress &&
        newContactInfo.faxAddress != null
          ? { faxAddress: newContactInfo.faxAddress }
          : {}),
        ...(initialAdditionalInfo.additionalExplanations !==
          newAdditionalInfo.additionalExplanations &&
        newAdditionalInfo.additionalExplanations != null
          ? { additionalExplanations: newAdditionalInfo.additionalExplanations }
          : {}),
        ...(initialAdditionalInfo.additionalInfo !== newAdditionalInfo.additionalInfo &&
        newAdditionalInfo.additionalInfo != null
          ? { additionalInfo: newAdditionalInfo.additionalInfo }
          : {}),
        ...(initialAdditionalInfo.corporateUserId !== newAdditionalInfo.corporateUserId &&
        newAdditionalInfo.corporateUserId != null
          ? { corporateUserId: newAdditionalInfo.corporateUserId }
          : {}),
        ...(initialAdditionalInfo.costCenter !== newAdditionalInfo.costCenter &&
        newAdditionalInfo.costCenter != null
          ? { costCenter: newAdditionalInfo.costCenter }
          : {}),
        ...(initialAdditionalInfo.accessCtrlSystemPersonId !==
          newAdditionalInfo.accessCtrlSystemPersonId &&
        newAdditionalInfo.accessCtrlSystemPersonId != null
          ? { accessCtrlSystemPersonId: newAdditionalInfo.accessCtrlSystemPersonId }
          : {}),
        // $FlowFixMe
        ...(initialAdditionalInfo.duties !== newAdditionalInfo.duties &&
        newAdditionalInfo.duties != null
          ? { duties: newAdditionalInfo.duties }
          : {})
      }
    };
  }

  hasFieldError: *;

  hasFieldError(currentValues: InitialFormValuesT) {
    return (
      currentValues &&
      (this.validateNickName(currentValues.externalUserEdit.contactInfo.nickName) ||
        this.validateTitle(currentValues.externalUserEdit.contactInfo.title) ||
        this.validateMobileNumber(currentValues.externalUserEdit.contactInfo.mobileNumber) ||
        this.validateHomeNumber(currentValues.externalUserEdit.contactInfo.homeNumber) ||
        this.validateProfessionalPhoneNumber(
          currentValues.externalUserEdit.contactInfo.professionalPhoneNumber
        ) ||
        this.validateDefaultPhoneNumber(
          currentValues.externalUserEdit.contactInfo.defaultPhoneNumber
        ) ||
        this.validateEmail(currentValues.externalUserEdit.contactInfo.emails) ||
        this.validateFaxAddress(currentValues.externalUserEdit.contactInfo.faxAddress) ||
        this.validateCompany(currentValues.externalUserEdit.contactInfo.company) ||
        this.validateFirstName(currentValues.externalUserEdit.contactInfo.firstName) ||
        this.validateLastName(currentValues.externalUserEdit.contactInfo.lastName) ||
        this.validateWebsite(currentValues.externalUserEdit.contactInfo.website) ||
        this.validateAddress(currentValues.externalUserEdit.contactInfo.address) ||
        this.validateDuties(currentValues.externalUserEdit.additionalInformation.duties) ||
        this.validateAdditionalExplanations(
          currentValues.externalUserEdit.additionalInformation.additionalExplanations
        ) ||
        this.validateAdditionalInfo(
          currentValues.externalUserEdit.additionalInformation.additionalInfo
        ) ||
        this.validateCorporateUserId(
          currentValues.externalUserEdit.additionalInformation.corporateUserId
        ) ||
        this.validateCostCenter(currentValues.externalUserEdit.additionalInformation.costCenter) ||
        this.validateAccessCtrlSystemPersonId(
          currentValues.externalUserEdit.additionalInformation.accessCtrlSystemPersonId
        ))
    );
  }

  handleSave: *;

  async handleSave(e: ?Event, values: InitialFormValuesT): Promise<void> {
    const { onSave, user } = this.props;
    if (e) e.preventDefault();

    const editedUser = this.createContactInfoSectionUpdateUserPayload(values);
    if (editedUser.updated) {
      await onSave(editedUser.user);
      if (user) {
        this.stopEditing();
      }
    }
  }

  startEditing: *;

  startEditing() {
    const {
      // eslint-disable-next-line no-shadow
      goToEditUser,
      user: { enterpriseId, id }
    } = this.props;
    goToEditUser(enterpriseId, id, 'externalUser');
  }

  getPhoneNumberOptions: *;

  getPhoneNumberOptions() {
    const { currentValues } = this.props;
    if (currentValues) {
      // $FlowFixMe
      const returnValues = getNumbers(currentValues.externalUserEdit.contactInfo.homeNumber)
        .concat(getNumbers(currentValues.externalUserEdit.contactInfo.mobileNumber))
        .concat(getNumbers(currentValues.externalUserEdit.contactInfo.professionalPhoneNumber));
      if (
        !returnValues.find(
          number =>
            number &&
            currentValues.externalUserEdit.contactInfo.defaultPhoneNumber &&
            number.value === currentValues.externalUserEdit.contactInfo.defaultPhoneNumber.value
        ) &&
        currentValues.externalUserEdit.contactInfo.defaultPhoneNumber != null
      ) {
        returnValues.push(currentValues.externalUserEdit.contactInfo.defaultPhoneNumber);
      }
      return returnValues;
    }
    return [];
  }

  renderUserInfo(): Element<'div'> {
    const {
      user,
      t,
      isUpdating,
      dirty,
      hasError,
      hasDeleteError,
      reset,
      alwaysEdit,
      currentValues,
      initialValues,
      changeFormValue,
      showLoadingError
    } = this.props;

    const { successIndicatorToggled } = this.state;

    let errorMessage;
    if (!showLoadingError) {
      if (hasDeleteError) errorMessage = t('users.personalDetails.deleteError');
      else if (hasError) errorMessage = t('users.personalDetails.saveError');
    }
    const fullName = user ? [user.firstName, user.lastName].join(' ').trim() : '';
    const phoneNumberOptions = this.getPhoneNumberOptions();
    return (
      <div
        className={classnames(styles.info, {
          [`${styles['info--open']}`]: alwaysEdit
        })}
      >
        <div className={styles.content}>
          {categoryHasData(initialValues.externalUserEdit.contactInfo) ? (
            <Section
              key={`${user ? user.id : 'create'}-${CONTACT_INFO_SECTION}`}
              id={CONTACT_INFO_SECTION}
              title={t('externalUsers.contactInfoTitle')}
              isSaving={isUpdating}
              touched={dirty}
              wasSaved={successIndicatorToggled}
              errorMessage={errorMessage}
              onReset={reset}
              sectionId="contactInfo"
              showButtonsBottom={alwaysEdit}
              hideEdit
              alwaysEdit={alwaysEdit}
            >
              <div className={classnames({ [`${styles['edit-area']}`]: alwaysEdit })}>
                <div className={styles['avatar-container']}>
                  <Avatar
                    name={fullName}
                    hideAvailability
                    color="#002447"
                    shadow
                    size="large"
                    hideWhenViewing
                  />
                </div>
                <div className={classnames({ [`${styles['field-area']}`]: alwaysEdit })}>
                  <FormSection
                    name="externalUserEdit.contactInfo"
                    className={styles['form-section']}
                  >
                    <Field
                      id="firstName"
                      key="firstName"
                      hideWhenViewing
                      label={`${t('externalUsers.personalDetails.firstName')}`}
                      name="firstName"
                      component={FormInput}
                      validate={this.validateFirstName}
                      maxlength={EXT_FIRSTNAME_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="lastName"
                      key="lastName"
                      hideWhenViewing
                      label={`${t('externalUsers.personalDetails.lastName')}`}
                      name="lastName"
                      component={FormInput}
                      validate={this.validateLastName}
                      maxlength={EXT_LASTNAME_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="company"
                      key="company"
                      hideWhenViewing
                      label={`${t('externalUsers.personalDetails.company')}`}
                      name="company"
                      component={FormInput}
                      validate={this.validateCompany}
                      maxlength={EXT_COMPANY_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="title"
                      key="title"
                      label={`${t('externalUsers.personalDetails.title')}`}
                      name="title"
                      component={FormInput}
                      validate={this.validateTitle}
                      maxlength={EXT_TITLE_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="nickName"
                      key="nickName"
                      label={`${t('externalUsers.personalDetails.nickName')}`}
                      name="nickName"
                      component={FormInput}
                      validate={this.validateNickName}
                      maxlength={EXT_NICKNAME_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="mobileNumber"
                      key="mobileNumber"
                      label={`${t('externalUsers.personalDetails.mobilePhoneNumber')}`}
                      name="mobileNumber"
                      component={FormInput}
                      validate={this.validateMobileNumber}
                      maxlength={PHONE_NUMBER_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="homeNumber"
                      key="homeNumber"
                      label={`${t('externalUsers.personalDetails.personalPhoneNumber')}`}
                      name="homeNumber"
                      component={FormInput}
                      validate={this.validateHomeNumber}
                      maxlength={PHONE_NUMBER_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="professionalPhoneNumber"
                      key="professionalPhoneNumber"
                      label={`${t('externalUsers.personalDetails.professionalPhoneNumber')}`}
                      name="professionalPhoneNumber"
                      component={FormInput}
                      validate={this.validateProfessionalPhoneNumber}
                      maxlength={PHONE_NUMBER_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="defaultPhoneNumber"
                      key="defaultPhoneNumber"
                      label={`${t('externalUsers.personalDetails.defaultPhoneNumber')}`}
                      name="defaultPhoneNumber"
                      options={phoneNumberOptions}
                      component={FormInput}
                      updateDropdownValue={option =>
                        changeFormValue(
                          'externalUserEdit',
                          'externalUserEdit.contactInfo.defaultPhoneNumber',
                          {
                            value: option.dataset.value,
                            label: option.dataset.value
                          }
                        )
                      }
                      validate={this.validateDefaultPhoneNumber}
                      maxlength={PHONE_NUMBER_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="emails"
                      key="emails"
                      label={`${t('externalUsers.personalDetails.emails')}`}
                      name="emails"
                      component={FormInput}
                      validate={this.validateEmail}
                      maxlength={EXT_EMAIL_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="faxAddress"
                      key="faxAddress"
                      label={`${t('externalUsers.personalDetails.faxAddress')}`}
                      name="faxAddress"
                      component={FormInput}
                      validate={this.validateFaxAddress}
                      maxlength={PHONE_NUMBER_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="website"
                      key="website"
                      label={`${t('externalUsers.personalDetails.website')}`}
                      name="website"
                      component={FormInput}
                      validate={this.validateWebsite}
                      maxlength={EXT_WEBSITE_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="address"
                      key="address"
                      label={`${t('externalUsers.personalDetails.address')}`}
                      name="address"
                      component={FormInput}
                      validate={this.validateAddress}
                      maxlength={EXT_ADDRESS_MAX_LENGTH}
                      showLength
                      type="textarea"
                      minrows="5"
                      maxrows="5"
                    />
                  </FormSection>
                  <FormSection
                    name="externalUserEdit.additionalInformation"
                    className={styles['form-section']}
                  >
                    <Field
                      id="additionalExplanations"
                      key="additionalExplanations"
                      label={t('users.additionalInformation.additionalExplanations')}
                      name="additionalExplanations"
                      component={FormInput}
                      validate={this.validateAdditionalExplanations}
                      maxlength={ADDITIONAL_EXPLANATIONS_MAX_LENGTH}
                      showLength
                      type="textarea"
                      minrows="5"
                      maxrows="5"
                    />
                    <Field
                      id="additionalInfo"
                      key="additionalInfo"
                      label={t('users.additionalInformation.additionalInfo')}
                      name="additionalInfo"
                      component={FormInput}
                      validate={this.validateAdditionalInfo}
                      maxlength={ADDITIONAL_INFO_MAX_LENGTH}
                      showLength
                      type="textarea"
                      minrows="5"
                      maxrows="5"
                    />
                    <Field
                      id="corporateUserId"
                      key="corporateUserId"
                      label={t('users.additionalInformation.corporateUserId')}
                      name="corporateUserId"
                      component={FormInput}
                      validate={this.validateCorporateUserId}
                      maxlength={CORPORATE_USER_ID_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="costCenter"
                      key="costCenter"
                      label={t('users.additionalInformation.costCenter')}
                      name="costCenter"
                      component={FormInput}
                      validate={this.validateCostCenter}
                      maxlength={COST_CENTER_MAX_LENGTH}
                      showLength
                    />
                    <Field
                      id="accessCtrlSystemPersonId"
                      key="accessCtrlSystemPersonId"
                      label={t('users.additionalInformation.accessCtrlSystemPersonId')}
                      name="accessCtrlSystemPersonId"
                      component={FormInput}
                      validate={this.validateAccessCtrlSystemPersonId}
                      maxlength={ACCESS_CTRL_SYSTEM_PERSON_ID_MAX_LENGTH}
                      showLength
                    />
                    <KeywordInputField
                      id="duties"
                      title={t('users.departmentAndColleagues.duties')}
                      form="externalUserEdit"
                      formValueParameter="externalUserEdit.additionalInformation.duties"
                      validate={this.validateDuties}
                      changeFormValue={changeFormValue}
                      isSectionInEditMode={alwaysEdit}
                    />
                    {user && (
                      <ConfirmButton
                        className={styles['remove-button']}
                        id="delete-external-button"
                        hideWhenViewing
                        label={`${t('externalUsers.deleteButton')}`}
                        onClickAction={this.confirmRemoveExternalUser}
                      />
                    )}
                  </FormSection>
                  <div className={styles['button-area']}>
                    <CancelButton
                      id="cancel-external-user-edit-button"
                      className={styles['cancel-button']}
                      label={t('users.personalDetails.cancel')}
                      onClickAction={this.stopEditing}
                      hideWhenViewing
                    />
                    <ActionButton
                      id="save-external-user-changes-button"
                      className={styles['save-button']}
                      label={t('users.personalDetails.save')}
                      loading={isUpdating}
                      hideWhenViewing
                      disabled={
                        !dirty || isUpdating || this.hasFieldError(currentValues) !== undefined
                      }
                      onClickAction={(e: ?Event): void => {
                        this.handleSave(e, currentValues);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Section>
          ) : null}
        </div>
      </div>
    );
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  render(): Element<'div'> {
    const {
      t,
      onClose,
      user,
      hasError,
      showLoadingSpinner,
      showFixedTitle,
      isFavourite,
      onToggleFavourite,
      alwaysEdit,
      showLoadingError,
      reloadFunc,
      numberOfFavourites
    } = this.props;
    const fullName = user ? [user.firstName, user.lastName].join(' ').trim() : '';

    return (
      <div>
        <form>
          <div
            className={classnames(styles.container, {
              [`${styles['container--open']}`]: alwaysEdit
            })}
          >
            {!alwaysEdit && showFixedTitle && (
              <FixedTitle
                fullName={fullName}
                onClose={onClose}
                isFavourite={isFavourite}
                onToggleFavourite={onToggleFavourite}
                handleOnEditClick={this.startEditing}
                disableButtons={showLoadingSpinner}
                disableFavouriteButton={numberOfFavourites >= MAX_FAVOURITES_RESULTS}
              />
            )}
            {!alwaysEdit && (
              <Title
                isInternalUser={false}
                onClose={onClose}
                fullName={fullName}
                showFixedTitle={showFixedTitle}
                showLoadingSpinner={showLoadingSpinner}
                wasUnsuccessFullyUpdated={this.wasUnsuccessFullyUpdated}
                hasError={hasError}
                dataFetchFailedErrorMessage={t(
                  'users.personalDetails.error.dataFetchFailedErrorMessage'
                )}
                defaultUserName={t('generic.defaultUserName')}
                title={user.company}
                isFavourite={isFavourite}
                onToggleFavourite={onToggleFavourite}
                handleOnEditClick={this.startEditing}
                disableButtons={showLoadingSpinner}
                disableFavouriteButton={numberOfFavourites >= MAX_FAVOURITES_RESULTS}
                translate={t}
                user={user}
              />
            )}
            {alwaysEdit && (
              <EditTitle
                onClose={() => {
                  this.stopEditing();
                  onClose();
                }}
                title={
                  user
                    ? t('users.personalDetails.editTitle', {
                        name: fullName || t('generic.defaultUserName')
                      })
                    : t('users.personalDetails.createNewContact')
                }
              />
            )}
            {showLoadingError && <UserLoadingError reloadFunc={reloadFunc} translate={t} />}
            {(!hasError || this.wasUnsuccessFullyUpdated) && (
              <ErrorBoundary
                errorElement={
                  <TextError message={t('users.personalDetails.error.genericErrorMessage')} />
                }
              >
                {this.renderUserInfo()}
              </ErrorBoundary>
            )}
          </div>
        </form>
      </div>
    );
  }
}

type MapUserDataToFieldValuesFnT = ExternalUserStateEntityT => InitialFormValuesT;
const mapUserDataToFieldValues: MapUserDataToFieldValuesFnT = ({
  nickName,
  title,
  mobileNumber,
  homeNumber,
  professionalPhoneNumber,
  defaultPhoneNumber,
  emails,
  company,
  website,
  address,
  firstName,
  lastName,
  faxAddress,
  additionalExplanations,
  additionalInfo,
  corporateUserId,
  costCenter,
  accessCtrlSystemPersonId,
  duties
}) => ({
  externalUserEdit: {
    contactInfo: {
      firstName: firstName || '',
      lastName: lastName || '',
      title: title || '',
      nickName: nickName || '',
      mobileNumber: mobileNumber || '',
      homeNumber: homeNumber || '',
      professionalPhoneNumber: professionalPhoneNumber || '',
      defaultPhoneNumber: defaultPhoneNumber
        ? { value: defaultPhoneNumber, label: defaultPhoneNumber }
        : null,
      emails: emails
        ? emails
            .split(';')
            .join(', ')
            .replace(/(^[,\s]+)|([,\s]+$)/g, '') // trims leading and trailing commas
        : '',
      company: company || '',
      website: website || '',
      address: address || '',
      faxAddress: faxAddress || ''
    },
    additionalInformation: {
      additionalExplanations: additionalExplanations || '',
      additionalInfo: additionalInfo || '',
      corporateUserId: corporateUserId || '',
      costCenter: costCenter || '',
      accessCtrlSystemPersonId: accessCtrlSystemPersonId || '',
      duties
    }
  }
});

const mapStateToProps = (state: StoreStateT, { userId }: OwnPropsT) => {
  const user: ExternalUserStateEntityT = (state.entities.user.byId[userId]: any); // eslint-disable-line flowtype/no-weak-types
  return {
    // $FlowFixMe
    initialValues: mapUserDataToFieldValues(user || {}),
    currentValues: getFormValues('externalUserEdit')(state),
    user,
    isUpdating: user ? userSelectors.isUpdating(state, user.id) : false,
    hasError: userSelectors.hasError(user),
    hasDeleteError: userSelectors.hasDeleteError(user),
    isFavourite: user
      ? selectConfig.hasFavouriteConfig(state, 'external', user.enterpriseId, user.id)
      : false,
    numberOfFavourites: user
      ? selectConfig.selectFavouriteConfigsAsUsers(state, user.enterpriseId).length
      : 0,
    currentSectionActive: state.ui.forms.activeSection,
    // $FlowFixMe
    formInitialValues: mapUserDataToFieldValues(user || {})
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToEditUser,
      goToUserDetails,
      goToEnterpriseUsers,
      changeFormValue: change
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<$Diff<PropsT, ContextRouter>, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter,
  reduxForm({ form: 'externalUserEdit', enableReinitialize: true })
)(ExternalUserDetails);
