// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Canceler } from 'axios';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import Input from '@design-system/component-library/src/components/Input';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { getEnterprises } from '../../ducks/entities/enterprise/enterpriseOperations';
import { selectors as enterpriseSelect } from '../../ducks/entities/enterprise';
import ActionButton from '../../components/Button/ActionButton';
import DeleteButton from '../../components/Button/DeleteButton';
import ServicePackModal from './ServicePackModal';
import styles from './ServicePlansEditor.module.scss';

let requestEnterprisesCancelTokenSource: CancelTokenSource;

export const ServicePlansEditor = () => {
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState();
  const [selectedEnterprises, setSelectedEnterprises] = useState([]);
  const [selectedServicePacks, setSelectedServicePacks] = useState([]);
  const [restrictedServicePacks, setRestrictedServicePacks] = useState([]);
  const [acdServicePacks, setAcdServicePacks] = useState([]);
  const [servicePacks, setServicePacks] = useState([]);
  const [enterpriseSearchTerm, setEnterpriseSearchTerm] = useState('');
  const MAX_ENTERPRISES = 10000;
  const RESTRICTED_SERVICE_PACKS_TO_ACD = ['func_agent_smschannel', 'func_acd-history'];
  const ACD_SERVICE_PACKS = ['role_acdagent'];
  const enterprises = useSelector(state =>
    state.entities.enterprise
      ? enterpriseSelect.enterprisesById(state, state.entities.enterprise.allIds)
      : []
  );
  const cancelServicePackRequest = React.useRef<Canceler>();

  const getServicePacks = async ents => {
    if (ents && ents.length > 0 && ents[0].entID) {
      const servicePacksReturned: AxiosPromise<> = await axios({
        method: 'GET',
        url: `/api/v1/enterprises/${ents[0].entID}/servicepacks`,
        cancelToken: new CancelToken(canceler => {
          cancelServicePackRequest.current = canceler;
        })
      });
      if (servicePacksReturned) {
        setServicePacks(servicePacksReturned.data.results);
        setRestrictedServicePacks(
          // $FlowFixMe
          RESTRICTED_SERVICE_PACKS_TO_ACD.map(sp => {
            const foundSP = servicePacksReturned.data.results.find(s => s.name === sp);
            return foundSP ? foundSP.id : null;
          })
        );
        setAcdServicePacks(
          // $FlowFixMe
          ACD_SERVICE_PACKS.map(sp => {
            const foundSP = servicePacksReturned.data.results.find(s => s.name === sp);
            return foundSP ? foundSP.id : null;
          })
        );
      }
    }
  };

  const fetchEnterprises = async () => {
    const params = {};
    params.size = MAX_ENTERPRISES;
    const enterprisesReturned = await dispatch(
      getEnterprises(requestEnterprisesCancelTokenSource.token, params)
    );
    if (enterprisesReturned && enterprisesReturned.length > 0) {
      getServicePacks(enterprisesReturned);
    }
  };

  const getEnterpriseName = (entId: string) => {
    const foundEnterprise = enterprises.find(ent => ent.entID === entId);
    return foundEnterprise ? foundEnterprise.fullName : '';
  };

  useEffect(() => {
    requestEnterprisesCancelTokenSource = CancelToken.source();
    fetchEnterprises();
    return () => {
      requestEnterprisesCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <ServicePackModal
        showDialog={showDialog}
        selectedEnterprises={selectedEnterprises}
        selectedServicePacks={selectedServicePacks}
        restrictedServicePacks={restrictedServicePacks}
        acdServicePacks={acdServicePacks}
        closeDialog={() => setShowDialog()}
      />
      <Input
        id="search-enterprises-input"
        placeholder={t('adminui.searchEnterprises')}
        onValueChange={e => setEnterpriseSearchTerm(e.currentTarget.value)}
        type="search"
        optional
        i18n_input_optionalText=""
        defaultValue={enterpriseSearchTerm}
        maxlength={50}
        className={styles.search}
      />
      <div className={styles['select-enterprises-area']}>
        <div className={styles['enterprises-area']}>
          {enterprises
            .filter(
              enterprise =>
                enterprise.fullName &&
                enterprise.fullName.toLowerCase().includes(enterpriseSearchTerm.toLowerCase())
            )
            .map(enterprise => (
              <Checkbox
                id={`enterprise-checkbox-${enterprise.entID}`}
                key={`enterprise-checkbox-${enterprise.entID}`}
                name="selectedEnterprisesCheck"
                value={enterprise.entID}
                checked={selectedEnterprises.includes(enterprise.entID)}
                onChange={() => {
                  if (selectedEnterprises.includes(enterprise.entID)) {
                    setSelectedEnterprises(
                      selectedEnterprises.filter(item => item !== enterprise.entID) ?? []
                    );
                  } else {
                    setSelectedEnterprises(() => [...selectedEnterprises, enterprise.entID]);
                  }
                }}
                label={`${enterprise.fullName || ''} (${enterprise.name})`}
              />
            ))}
        </div>
        <div>
          <h4>{t('adminui.selectedEnterprises')}:</h4>
          <div className={styles['enterprise-list']}>
            {selectedEnterprises.map(entId => (
              <div key={`ent_${entId}`}>{getEnterpriseName(entId)}</div>
            ))}
          </div>
        </div>
      </div>
      <h2>{t('adminui.servicePacksTitle')}</h2>
      <div className={styles['servicepack-area']}>
        {servicePacks
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(servicePack => (
            <Checkbox
              id={`servicepacks-checkbox-${servicePack.id}`}
              key={`servicepacks-checkbox-${servicePack.id}`}
              name="selectedServicePacksCheck"
              value={servicePack.entID}
              checked={selectedServicePacks.includes(servicePack.id)}
              onChange={() => {
                if (selectedServicePacks.includes(servicePack.id)) {
                  setSelectedServicePacks(
                    selectedServicePacks.filter(item => item !== servicePack.id) ?? []
                  );
                } else {
                  setSelectedServicePacks(() => [...selectedServicePacks, servicePack.id]);
                }
              }}
              label={servicePack.name}
            />
          ))}
      </div>
      <div className={styles['buttons-area']}>
        <ActionButton
          id="add-servicepacks-button"
          label={t('adminui.addServicePacks')}
          onClickAction={() => {
            setShowDialog('add');
          }}
          disabled={selectedEnterprises.length === 0 || selectedServicePacks.length === 0}
          className={styles['add-servicepacks-button']}
        />
        <DeleteButton
          id="remove-servicepacks-button"
          label={t('adminui.removeServicePacks')}
          onClickAction={() => {
            setShowDialog('remove');
          }}
          disabled={selectedEnterprises.length === 0 || selectedServicePacks.length === 0}
          className={styles['remove-servicepacks-button']}
        />
      </div>
    </div>
  );
};

export default ServicePlansEditor;
