import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAttachmentRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 23c-1.9 0-3.7-.7-5-2.1C.3 18.2.3 13.7 3 11l8.4-8.4c.9-1 2.1-1.5 3.4-1.6 1.3 0 2.6.4 3.6 1.3s1.5 2.1 1.6 3.4-.4 2.6-1.3 3.6l-.1.2L10 18c-1.1 1.1-2.9 1.1-4-.1-1.1-1.1-1.1-2.9 0-4l8.4-8.4c.3-.3.8-.3 1.1 0s.3.8 0 1.1L7.1 15c-.5.5-.5 1.3 0 1.9.5.5 1.4.5 1.9 0l8.6-8.6c.6-.7 1-1.6.9-2.5 0-.9-.4-1.8-1.1-2.4s-1.5-1-2.5-.9c-.9 0-1.8.4-2.4 1.1L4.1 12c-2.1 2.2-2.1 5.7 0 7.8 1 1.1 2.4 1.6 3.9 1.6s2.9-.6 3.9-1.6L22.2 9.6c.3-.3.8-.3 1.1 0s.3.8 0 1.1L13 20.9C11.7 22.3 9.9 23 8 23"/></svg>)}
    />
  );
}
  