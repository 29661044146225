import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOfficeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 23H20V2c0-1.1-.9-2-2-2H6C4.9 0 4 .9 4 2v21H1.5c-.3 0-.5.2-.5.5s.2.5.5.5h21c.3 0 .5-.2.5-.5s-.2-.5-.5-.5m-9 0h-3v-3.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5zm1 0v-3.5c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5V23H5V2c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v21zm-4-20h-3c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-4c0-.3-.2-.5-.5-.5M10 7H8V4h2zm6.5-4h-3c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-4c0-.3-.2-.5-.5-.5M16 7h-2V4h2zm-5.5 3h-3c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-4c0-.3-.2-.5-.5-.5m-.5 4H8v-3h2zm6.5-4h-3c-.3 0-.5.2-.5.5v4c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-4c0-.3-.2-.5-.5-.5m-.5 4h-2v-3h2z"/></svg>)}
    />
  );
}
  