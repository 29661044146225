// @flow

import React, { type Element } from 'react';
import { Field } from 'redux-form';
import { InputField as ReduxInput } from '../InputComponent/ReduxFormField';

type OwnPropsT = {
  id: string,
  label: string,
  icon?: string,
  searchInputChanged: string => void,
  searchInputCleared?: () => *,
  isLoading?: boolean,
  inputFieldRef?: *
};

export type PropsT = OwnPropsT;

const SearchInput = (props: PropsT): Element<'div'> => {
  const {
    id,
    isLoading,
    label,
    icon,
    searchInputChanged,
    searchInputCleared,
    inputFieldRef,
    ...rest
  } = props;
  return (
    <div className="search-input-container">
      <Field
        {...rest}
        id={`search-input-${id}`}
        name={id}
        type="search"
        label={label}
        placeholder={label}
        onChange={e => {
          const {
            // $FlowFixMe
            currentTarget: { value: searchTerm = '' }
          } = e;
          searchInputChanged(searchTerm);
        }}
        onClearInput={searchInputCleared}
        component={ReduxInput}
        maxlength={50}
        pending={isLoading}
        autocomplete="off"
        optional
        i18n_input_optionalText=""
        inputFieldRef={inputFieldRef}
      />
    </div>
  );
};

export default SearchInput;
