// @flow

import axios, { type CancelToken, type AxiosPromise } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import type { UpdateSpeedDialActionT } from '../callFlow/callFlowTypes';
import type { SpeedDialUpdateEntityT } from './speedDialTypes';
import {
  createUpdateSpeedDialCancel,
  createUpdateSpeedDialFailure,
  createUpdateSpeedDialRequest,
  createUpdateSpeedDialSuccess
} from './speedDialActions';

export type UpdateSpeedDialAgentT = (
  string,
  string,
  SpeedDialUpdateEntityT,
  CancelToken,
  headers: {}
) => ThunkActionT<UpdateSpeedDialActionT>;

export const updateSpeedDial: UpdateSpeedDialAgentT = (
  enterpriseId,
  speedDialId,
  speedDial,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createUpdateSpeedDialRequest(enterpriseId, speedDialId));
  try {
    const response: AxiosPromise<typeof speedDial> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/services/speeddials/${speedDialId}`,
      cancelToken,
      data: speedDial,
      headers
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createUpdateSpeedDialSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateSpeedDialCancel(enterpriseId, speedDialId));
    } else {
      dispatch(createUpdateSpeedDialFailure(enterpriseId, speedDialId, error));
    }
    return undefined;
  }
};

const speedDialOperations = {
  updateSpeedDial
};

export default speedDialOperations;
