// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@design-system/component-library/src/components/Input';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import Checkbox, { CheckboxGroup } from '@design-system/component-library/src/components/Checkbox';
import uuid from 'uuid/v4';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import { RadioV2 } from '@design-system/component-library';
import ActionButton from '../../components/Button/ActionButton';
import { isIntProd, isProd, isTest } from '../../helpers';
import DeleteButton from '../../components/Button/DeleteButton';
import configOperations from '../../ducks/config/configOperations';
import { actions as notificationActions } from '../../ducks/ui/notification';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import styles from './BulletinEditor.module.scss';

let cancelResellersRequest: CancelTokenSource;

export const BulletinEditor = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();
  const prodPlatformOptions = [
    { label: 'mob1, mob2, mob3', value: 'mob1, mob2, mob3' },
    { label: 'mob1', value: 'mob1' },
    { label: 'mob2', value: 'mob2' },
    { label: 'mob3', value: 'mob3' }
  ];
  const devPlatformOptions = [
    { label: 'lab1, lab2, lab3', value: 'lab1, lab2, lab3' },
    { label: 'lab1', value: 'lab1' },
    { label: 'lab2', value: 'lab2' },
    { label: 'lab3', value: 'lab3' }
  ];
  const platformSelectorOptions =
    isProd() || isIntProd() || isTest() ? prodPlatformOptions : devPlatformOptions;
  const [currentBulletin, setCurrentBulletin] = useState();
  const [selectedBulletinVersion, setSelectedBulletinVersion] = useState('');
  const [selectedEnvironment, setSelectedEnvironment] = useState(platformSelectorOptions[0].value);
  const [activationDate, setActivationDate] = useState(new Date().toISOString().split('T')[0]);
  const [messageFi, setMessageFi] = useState();
  const [messageEn, setMessageEn] = useState();
  const [messageSv, setMessageSv] = useState();
  const [fileSizeError, setFileSizeError] = useState();
  const [fileFormatError, setFileFormatError] = useState();
  const [attachments, setAttachments] = useState([]);
  const [selectedResellers, setSelectedResellers] = useState([]);
  const [resellersVisibility, setResellersVisibility] = useState('default');
  const [resellers, setResellers] = useState([]);
  const bulletins = useSelector(state => state.config.bulletins);
  const reactQuillRefFi = React.useRef();
  const reactQuillRefEn = React.useRef();
  const reactQuillRefSv = React.useRef();

  const MAX_LENGTH = 2000;

  const getResellers = async environment => {
    const resellersReturned: AxiosPromise<> = await axios({
      method: 'GET',
      url: `/api/v1/resellers/${environment}`,
      cancelToken: cancelResellersRequest.token
    });
    setResellers(resellersReturned.data.results);
  };

  useEffect(() => {
    dispatch(configOperations.getBulletins());
  }, [selectedBulletinVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    cancelResellersRequest = CancelToken.source();
    setMessageFi('');
    setMessageEn('');
    setMessageSv('');
    setActivationDate('');
    setSelectedBulletinVersion('');
    setCurrentBulletin(null);
    setAttachments([]);
    setResellersVisibility('default');
    getResellers(selectedEnvironment);
  }, [selectedEnvironment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const foundBulletin = bulletins.filter(b => b.id.toString() === selectedBulletinVersion);
    if (foundBulletin && foundBulletin.length === 1) {
      setCurrentBulletin(null);
      setMessageFi(foundBulletin[0].messageFi);
      setMessageEn(foundBulletin[0].messageEn);
      setMessageSv(foundBulletin[0].messageSv);
      if (foundBulletin[0].activationDate) {
        setActivationDate(foundBulletin[0].activationDate.split('T')[0]);
      }
      setCurrentBulletin(foundBulletin[0]);
      setAttachments(foundBulletin[0].attachments || []);
      setSelectedResellers(foundBulletin[0].resellerIds || []);
      setResellersVisibility(foundBulletin[0].resellersVisibility || 'default');
    } else {
      setMessageFi('');
      setMessageEn('');
      setMessageSv('');
      setActivationDate('');
      setSelectedBulletinVersion('');
      setAttachments([]);
      setResellersVisibility('default');
      setSelectedResellers([]);
    }
  }, [selectedBulletinVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  const createBulletin = async () => {
    setMessageFi('');
    setMessageEn('');
    setMessageSv('');
    setActivationDate('');
    setSelectedBulletinVersion('');
    setAttachments([]);
    setResellersVisibility('default');
    setSelectedResellers([]);
    const returnedBulletin = await dispatch(
      configOperations.createBulletin(
        {
          environment: selectedEnvironment,
          messageFi: '',
          messageEn: '',
          messageSv: '',
          activationDate: '',
          resellerIds: [],
          resellersVisibility: 'default'
        },
        createCsrfHeader(currentUser)
      )
    );
    if (returnedBulletin) {
      setCurrentBulletin(returnedBulletin);
      setSelectedBulletinVersion(returnedBulletin.id.toString());
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'bulletin-create-success',
          duration: 15000,
          type: 'info',
          message: t('adminui.createSuccess')
        })
      );
    } else {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'bulletin-create-failure',
          duration: 15000,
          type: 'error',
          message: t('adminui.createFailure')
        })
      );
    }
  };

  const saveBulletin = async () => {
    if (currentBulletin) {
      const returnedBulletin = await dispatch(
        configOperations.updateBulletin(
          currentBulletin.id,
          {
            ...currentBulletin,
            messageFi,
            messageEn,
            messageSv,
            activationDate,
            resellersVisibility,
            resellerIds: selectedResellers
          },
          createCsrfHeader(currentUser)
        )
      );
      let attachmentsUploadedSuccessfully = true;

      if (returnedBulletin) {
        if (attachments && attachments.length > 0) {
          attachments
            .filter(attachment => attachment.attachmentId)
            .every(async attachment => {
              attachmentsUploadedSuccessfully = await configOperations.uploadBulletinAttachment(
                currentBulletin.id,
                attachment,
                createCsrfHeader(currentUser)
              );
              return attachmentsUploadedSuccessfully;
            });
        }
      }
      if (returnedBulletin && attachmentsUploadedSuccessfully) {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-update-success',
            duration: 15000,
            type: 'info',
            message: t('adminui.saveSuccess')
          })
        );
      } else {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-update-failure',
            duration: 15000,
            type: 'error',
            message: t('adminui.saveFailure')
          })
        );
      }
    }
  };

  const deleteBulletin = () => {
    if (currentBulletin) {
      const returned = dispatch(
        configOperations.deleteBulletin(currentBulletin.id, createCsrfHeader(currentUser))
      );
      if (returned) {
        setMessageFi('');
        setMessageEn('');
        setMessageSv('');
        setActivationDate('');
        setSelectedBulletinVersion('');
        setCurrentBulletin(null);
        setAttachments([]);
        setResellersVisibility('default');
        setSelectedResellers([]);
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-delete-success',
            duration: 15000,
            type: 'info',
            message: t('adminui.deleteSuccess')
          })
        );
      } else {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'bulletin-delete-failure',
            duration: 15000,
            type: 'error',
            message: t('adminui.deleteFailure')
          })
        );
      }
    }
  };

  const checkCharacterCount = (event, ref) => {
    if (ref && ref.current) {
      if (ref.current.getEditor().getLength() > MAX_LENGTH && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
  };

  const areTextEditorsFilled = () => {
    const msgFi = messageFi ? messageFi.replaceAll('<p><br></p>', '') : '';
    const msgEn = messageEn ? messageEn.replaceAll('<p><br></p>', '') : '';
    const msgSv = messageSv ? messageSv.replaceAll('<p><br></p>', '') : '';

    return msgFi && msgEn && msgSv;
  };

  const isAttachmentWrongFormat = (files: File[]): boolean => {
    const acceptedFormats = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint'
    ];
    return !acceptedFormats.some(acceptedFormat => acceptedFormat === files[0].type);
  };

  const isAttachmentTooLarge = (files: File[]): boolean => {
    const MAX_IMAGE_FILE_SIZE = 10000000; // 100 Mb
    return files[0].size > MAX_IMAGE_FILE_SIZE;
  };

  const uploadAttachment = (e: File[]) => {
    if (isAttachmentTooLarge(e)) {
      setFileSizeError(true);
      return;
    }
    if (isAttachmentWrongFormat(e)) {
      setFileFormatError(true);
      return;
    }
    setFileSizeError(false);
    setFileFormatError(false);
    const url = URL.createObjectURL(e[0]);
    setAttachments([...attachments, { id: null, name: e[0].name, url, attachmentId: uuid() }]);
  };

  const removeAttachment = async (attachmentId, removeFromDatabase) => {
    if (currentBulletin && attachmentId) {
      if (removeFromDatabase) {
        await configOperations.deleteBulletinAttachment(
          currentBulletin.id,
          attachmentId,
          createCsrfHeader(currentUser)
        );
      }
      setAttachments(
        attachments.filter(
          attachment =>
            (attachment.id && attachment.id !== attachmentId) ||
            (attachment.attachmentId && attachment.attachmentId !== attachmentId)
        )
      );
    }
  };

  let bulletinsToEdit = [{ value: '-', label: '' }];
  bulletinsToEdit = bulletinsToEdit.concat(
    bulletins
      .filter(b => b.environment === selectedEnvironment)
      .map(b => ({ label: b.id.toString(), value: b.id.toString() }))
  );

  return (
    <div>
      <div className={!currentBulletin && styles['upper-area']}>
        <div className={styles['selector-area']}>
          <Dropdown
            id="environment-selector"
            items={platformSelectorOptions}
            selectedValue={selectedEnvironment}
            onValueChange={element => {
              setSelectedEnvironment(element.dataset.value);
            }}
            label={t('adminui.environmentTitle')}
          />
          <Dropdown
            id="bulletin-selector"
            items={bulletinsToEdit}
            selectedValue={selectedBulletinVersion}
            style={{ paddingLeft: '15px' }}
            onValueChange={element => {
              setSelectedBulletinVersion(element.dataset.value);
            }}
            label={t('adminui.versionTitle')}
          />
        </div>
        <div className={styles['selector-area']}>
          <RadioGroup
            name="resellerSelect"
            value={resellersVisibility}
            onChange={e => {
              setSelectedResellers([]);
              setResellersVisibility(e);
            }}
          >
            <RadioV2 value="default" label={t('adminui.defaultResellersSelect')} />
            <RadioV2 value="only" label={t('adminui.onlyResellersSelect')} />
            <RadioV2 value="exclude" label={t('adminui.excludeResellersSelect')} />
          </RadioGroup>
          {resellersVisibility !== 'default' && (
            <CheckboxGroup
              key={resellersVisibility}
              name="resellers"
              label={t('adminui.resellersSelect')}
            >
              {resellers.map(reseller => (
                <Checkbox
                  id={reseller.id}
                  value={reseller.id}
                  name="resellers"
                  label={reseller.domainName}
                  checked={selectedResellers.includes(reseller.id)}
                  onChange={() => {
                    if (selectedResellers.includes(reseller.id)) {
                      setSelectedResellers(
                        selectedResellers.filter(item => item !== reseller.id) ?? []
                      );
                    } else {
                      setSelectedResellers(() => [...selectedResellers, reseller.id]);
                    }
                  }}
                />
              ))}
            </CheckboxGroup>
          )}
        </div>
        <ActionButton
          id="new-bulletin-button"
          label={t('adminui.newBulletinButton')}
          onClickAction={() => {
            createBulletin();
          }}
        />
      </div>
      {currentBulletin && (
        <div>
          <div id="bulletin-area" className={styles['bulletin-area']}>
            <h4>FI</h4>
            <ReactQuill
              onKeyDown={event => checkCharacterCount(event, reactQuillRefFi)}
              ref={reactQuillRefFi}
              onChange={msg => setMessageFi(msg)}
              value={messageFi}
            />
            {t('adminui.currentChars', {
              currentChars: reactQuillRefFi.current
                ? reactQuillRefFi.current.getEditor().getLength() - 1
                : 0,
              maxChars: MAX_LENGTH
            })}
            <h4>EN</h4>
            <ReactQuill
              onKeyDown={event => checkCharacterCount(event, reactQuillRefEn)}
              ref={reactQuillRefEn}
              onChange={msg => setMessageEn(msg)}
              value={messageEn}
            />
            {t('adminui.currentChars', {
              currentChars: reactQuillRefEn.current
                ? reactQuillRefEn.current.getEditor().getLength() - 1
                : 0,
              maxChars: MAX_LENGTH
            })}
            <h4>SV</h4>
            <ReactQuill
              onKeyDown={event => checkCharacterCount(event, reactQuillRefSv)}
              ref={reactQuillRefSv}
              onChange={msg => setMessageSv(msg)}
              value={messageSv}
            />
            {t('adminui.currentChars', {
              currentChars: reactQuillRefSv.current
                ? reactQuillRefSv.current.getEditor().getLength() - 1
                : 0,
              maxChars: MAX_LENGTH
            })}
            <h4>{t('adminui.attachmentsTitle')}</h4>
            {attachments &&
              attachments.map(attachment => (
                <div key={attachment.attachmentId || attachment.id}>
                  <span className={styles['attachment-div']}>{attachment.name}</span>
                  <span className={styles['attachment-div']}>
                    /api/v1/config/bulletin/{currentBulletin.id}/attachment/
                    {attachment.attachmentId || attachment.id}
                  </span>
                  <button
                    className={styles.inline}
                    type="button"
                    onClick={() => {
                      removeAttachment(attachment.attachmentId || attachment.id, !!attachment.id);
                    }}
                  >
                    <div>{t('adminui.removeAttachment')}</div>
                  </button>
                </div>
              ))}
            {fileSizeError && <div className={styles.error}>{t('adminui.fileTooLargeError')}</div>}
            {fileFormatError && <div className={styles.error}>{t('adminui.fileFormatError')}</div>}
            <Dropzone onDrop={uploadAttachment}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <div className={styles['button-container']}>
                    <button className={styles.inline} type="button">
                      <div className={styles.element}>{t('adminui.uploadAttachment')}</div>
                    </button>
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            <Input
              id="time-begin"
              data-cy="time-begin-input"
              label={t('adminui.activationDate')}
              className={styles['time-input']}
              defaultValue={activationDate}
              type="date"
              onValueChange={event => {
                setActivationDate(event.target.value);
              }}
            />
          </div>
          <ActionButton
            id="save-bulletin-button"
            label={t('adminui.saveBulletin')}
            onClickAction={() => {
              saveBulletin();
            }}
            disabled={!selectedEnvironment || !activationDate || !areTextEditorsFilled()}
            className={styles['save-bulletin-button']}
          />
          <DeleteButton
            id="delete-bulletin-button"
            label={t('adminui.deleteBulletin')}
            onClickAction={() => {
              deleteBulletin();
            }}
            disabled={!selectedEnvironment || !currentBulletin || !currentBulletin.id}
            className={styles['delete-bulletin-button']}
          />
        </div>
      )}
    </div>
  );
};

export default BulletinEditor;
