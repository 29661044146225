// @flow strict-local

import React, { type Element } from 'react';
import { ReactComponent as GreenStatus } from '../../../assets/callflow/grid/green.svg';
import { ReactComponent as RedStatus } from '../../../assets/callflow/grid/red.svg';
import type { ExactPropsT, TranslateT } from '../../../commonTypes';
import type { LoadGroupStatusT } from '../../../ducks/entities/acd/acdTypes';
import styles from './CallFlowActivity.module.scss';

type OwnPropsT = {|
  loadGroupStatus: ?LoadGroupStatusT,
  translate: TranslateT<>,
  isSmall?: boolean
|};

export type PropsT = ExactPropsT<OwnPropsT>;

const CallFlowActivity = (props: PropsT): Element<'div'> => {
  const { translate, loadGroupStatus, isSmall } = props;

  return loadGroupStatus ? (
    <div className={styles['activity-status']} data-cy="service-status">
      {!isSmall && loadGroupStatus && (
        <div className={styles['activity-status-text']}>
          {translate(`callflows.callFlowActivity.${loadGroupStatus}`)}
        </div>
      )}
      {loadGroupStatus === 'OPEN' ? <GreenStatus /> : <RedStatus />}
    </div>
  ) : (
    <div data-cy="service-status" />
  );
};

export default CallFlowActivity;
