import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAddLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 12c0 .3-.2.5-.5.5h-7v7c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-7h-7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7v-7c0-.3.2-.5.5-.5s.5.2.5.5v7h7c.3 0 .5.2.5.5"/></svg>)}
    />
  );
}
  