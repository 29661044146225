import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHomeFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.6 12.5c-.1.3-.4.5-.7.5h-1.6c-.1 0-.2.1-.2.2v8.3c0 .8-.7 1.5-1.5 1.5h-4c-.6 0-1-.4-1-1v-4c0-1.4-1.1-2.5-2.5-2.5h-.2c-1.3.2-2.3 1.3-2.2 2.6V22c0 .6-.4 1-1 1h-4c-1 0-1.7-.7-1.7-1.5v-8.3c0-.1-.1-.2-.2-.2H1.1c-.3 0-.6-.2-.7-.5s-.1-.6.2-.8l10-10.2c.8-.7 2-.7 2.8 0l10 10.2c.2.2.3.5.2.8"/></svg>)}
    />
  );
}
  