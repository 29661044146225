import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSwitchRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.193 15.787a.8.8 0 0 1-.162.244l-3 3a.75.75 0 0 1-1.06 0 .75.75 0 0 1 0-1.061l1.72-1.72H9.5a.75.75 0 0 1 0-1.5h11.19l-1.72-1.72a.75.75 0 1 1 1.061-1.061l3 3a.75.75 0 0 1 .162.818M3.31 9.25H14.5a.75.75 0 0 0 0-1.5H3.31l1.72-1.72a.75.75 0 1 0-1.061-1.061l-3 3a.75.75 0 0 0 0 1.061l3 3a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.061z"/></svg>)}
    />
  );
}
  