// @flow
import React, { type Element } from 'react';
import classNames from 'classnames';
import type { CallForwardingT } from '../../../ducks/entities/callForwarding/callForwardingTypes';
import Reason from '../../../components/ForwardingsTable/Reason';
import Destination from '../../../components/ForwardingsTable/Destination';
import styles from './ForwardingTableCell.module.scss';

export type PropsT = {
  userForwarding: CallForwardingT,
  children: Element<typeof Reason | typeof Destination>,
  className?: string
};

export const ForwardingTableCell = (props: PropsT) => {
  const { userForwarding, children, className } = props;

  return (
    <div
      className={classNames(
        styles['forwarding-row'],
        userForwarding && userForwarding.isActive ? '' : styles['disabled-row'],
        className
      )}
    >
      {children}
    </div>
  );
};

export default ForwardingTableCell;
