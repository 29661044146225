import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailSentRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.248 15.992c0-.05-.019-.098-.03-.148-.01-.048-.013-.099-.032-.143l-.002-.008-3.173-7.07A1.75 1.75 0 0 0 18.5 7.75h-2.953a.75.75 0 0 0 0 1.5h2.951c.089 0 .172.049.181.057l2.663 5.943h-4.795a.75.75 0 0 0-.75.75v.998a1.26 1.26 0 0 1-1.25 1.252H9.548A1.26 1.26 0 0 1 8.296 17v-1a.75.75 0 0 0-.75-.75H2.735l3.052-5.875A.25.25 0 0 1 6 9.25h2.546a.75.75 0 0 0 0-1.5H5.998c-.621.002-1.2.337-1.526.904l-3.637 7c-.005.009-.004.019-.008.028-.021.045-.029.094-.041.143s-.028.097-.03.146c0 .01-.006.018-.006.029v5.5c0 .965.785 1.75 1.75 1.75h19c.965 0 1.75-.785 1.75-1.75V16zM21.75 21.5a.25.25 0 0 1-.25.25h-19a.25.25 0 0 1-.25-.25v-4.75h4.546v.252a2.76 2.76 0 0 0 2.75 2.748h5.002A2.76 2.76 0 0 0 17.296 17v-.25h4.453v4.75zM7.47 6.03a.75.75 0 0 1 0-1.061l4-4a.75.75 0 0 1 .525-.219L12 .749a.75.75 0 0 1 .531.22l4 4A.75.75 0 1 1 15.47 6.03l-2.72-2.72v9.19a.75.75 0 0 1-1.5 0V3.311L8.53 6.03a.75.75 0 0 1-1.06 0"/></svg>)}
    />
  );
}
  