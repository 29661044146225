import React from 'react';
import IconContainer from '../IconContainer';

export default function IconClockFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24m5.2 17.22a1 1 0 0 1-1.42.06l-5-4.54a1 1 0 0 1-.28-.74V6.5a1 1 0 0 1 2 0v5.06l4.63 4.25a1 1 0 0 1 .07 1.41"/></svg>)}
    />
  );
}
  