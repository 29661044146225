import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBookFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.988 3.071C9.368 2.165 6.327 1.07 1.037 1a1.1 1.1 0 0 0-.735.3A1.04 1.04 0 0 0 0 2.031v17.036A1.03 1.03 0 0 0 1.004 20.1c5.093.069 7.975 1.097 9.496 1.945a.5.5 0 0 0 .497-.005.5.5 0 0 0 .247-.432V3.507a.5.5 0 0 0-.256-.436M3.167 9.31a22.4 22.4 0 0 1 5.532 1.109.625.625 0 0 1-.398 1.185 21.2 21.2 0 0 0-5.222-1.047.625.625 0 0 1-.579-.668.63.63 0 0 1 .667-.579m5.925 6.189a.625.625 0 0 1-.791.394 21 21 0 0 0-5.222-1.048.625.625 0 1 1 .089-1.246c1.882.134 3.743.507 5.532 1.108.326.11.503.465.392.792M23.7 1.301A1.02 1.02 0 0 0 22.978 1h-.014c-5.3.07-8.344 1.168-9.964 2.078a.5.5 0 0 0-.256.436v18.102a.5.5 0 0 0 .744.437c1.522-.853 4.407-1.883 9.515-1.952a1.03 1.03 0 0 0 .997-1.03V2.031c0-.277-.106-.536-.3-.73m-2.778 13.544a21 21 0 0 0-5.223 1.048.627.627 0 0 1-.398-1.186c1.79-.602 3.65-.975 5.531-1.108a.633.633 0 0 1 .668.578.625.625 0 0 1-.578.668m0-4.288a21 21 0 0 0-5.223 1.047.627.627 0 0 1-.398-1.185 22.4 22.4 0 0 1 5.531-1.109.63.63 0 0 1 .668.579.625.625 0 0 1-.578.668"/></svg>)}
    />
  );
}
  