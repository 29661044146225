import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNonWatchableLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 .3 9.8 3v1.1L4.5 1.2c-.2-.1-.3-.1-.5-.1s-.3 0-.5.1-.3.2-.4.4c-.1.1-.1.3-.1.5V22c0 .2 0 .3.1.5s.2.3.4.4c.1.1.3.1.5.1s.3 0 .5-.1l4.8-2.7v.4c0 .3 0 .5.1.7L5 23.7c-1.3.7-3-.2-3-1.8V2.1C2 .5 3.6-.4 5 .3m8.3 18.8 9.6-5.3c1.4-.8 1.4-2.7 0-3.5L13.2 5v1l9.2 5.1c.2.1.3.2.4.4s.1.3.1.5 0 .4-.1.5c-.1.2-.2.3-.4.4L13.2 18zM11.5 1.5c.3 0 .5.2.5.5v15.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5V2c0-.3.2-.5.5-.5m0 20c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1"/></svg>)}
    />
  );
}
  