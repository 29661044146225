// @flow
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CallflowTreeNode from '../CallflowTreeNode';

export type PropsT = {|
  serviceId: string,
  enterpriseId: string
|};

export const SpeedDialNode = (props: PropsT): Element<typeof CallflowTreeNode> => {
  const { serviceId, enterpriseId } = props;
  const { t } = useTranslation();
  // redux
  const callflowId = `${enterpriseId}-SPEED_DIAL-${serviceId}`;
  const speedDialData = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const targetNumber =
    speedDialData && speedDialData.type === 'SPEED_DIAL' ? speedDialData.externalDestination : ' ';

  return (
    <CallflowTreeNode
      serviceId={serviceId}
      nodeId="SPEED_DIAL"
      type="SPEED_DIAL"
      image="/callflow_unhandled.svg"
      selectedImage="/callflow_selected_unhandled.svg"
      title={t('callflows.treeLayout.speedDial.speedDial')}
      description={<div>{targetNumber}</div>}
    />
  );
};

export default SpeedDialNode;
