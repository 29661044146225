// @flow local-strict

import React from 'react';
import UserAvatar from './UserAvatar';
import type { AvailabilityT } from '../../ducks/entities/user/userTypes';
import AvailabilityIndicator from './AvailabilityIndicator';
import CameraIcon from '../CameraIcon/CameraIcon';

import styles from './Avatar.module.scss';

export type PropsT = {
  id?: string,
  name: ?string,
  hideAvailability?: boolean,
  showEdit?: ?boolean,
  onEditClick?: ?() => *,
  availability?: AvailabilityT,
  src?: ?string,
  color: string,
  className: string,
  innerClassName?: string,
  shadow?: boolean,
  onClick?: () => *,
  size: 'xsmall' | 'small' | 'medium' | 'large'
};

const Avatar = (props: PropsT) => {
  let size;
  const {
    className,
    availability,
    src,
    name,
    color,
    size: sizeString,
    hideAvailability,
    onClick,
    id,
    innerClassName,
    showEdit,
    onEditClick
  } = props;
  const shadow = props.shadow ? ` ${styles.shadow}` : '';
  switch (sizeString) {
    case 'xsmall':
      size = 32;
      break;
    case 'small':
      size = 42;
      break;
    case 'medium':
      size = 75;
      break;
    default:
      size = 150;
  }
  return (
    <div
      id={id}
      className={`${styles.avatar} ${styles[sizeString]}${shadow} ${className}`}
      onClick={onClick}
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
    >
      <UserAvatar
        size={size}
        src={src}
        name={name}
        backgroundColor={color}
        innerClassName={innerClassName}
      />
      {!hideAvailability && (
        <div className={`${styles['availability-indicator-container']} ${styles[sizeString]}`}>
          <AvailabilityIndicator status={availability} size={sizeString} />
        </div>
      )}
      {showEdit && (
        <div className={`${styles['edit-button-container']} ${styles[sizeString]}`}>
          <CameraIcon onClick={onEditClick || (() => null)} disabled={false} displayAsBlock />
        </div>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  id: undefined,
  src: undefined,
  className: '',
  innerClassName: '',
  shadow: false,
  hideAvailability: false,
  onClick: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  availability: 'green'
};

export default Avatar;
