import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileDocRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.4 6.5c-.1 0-.1-.1 0 0-.1-.2-.2-.4-.4-.5L15.6.6c-.2-.2-.3-.3-.5-.4-.3-.1-.5-.2-.8-.2H5.2C4 0 3 1 3 2.2v19.5C3 23 4 24 5.2 24h14c1.2 0 2.2-1 2.2-2.2V7.2c.1-.3 0-.5 0-.7M18.9 6h-3.2c-.1 0-.2-.1-.2-.2V2.6zm.3 16.5h-14c-.4 0-.7-.3-.7-.8V2.2c0-.4.3-.8.7-.8H14v4.2c0 1 .8 1.8 1.8 1.8H20v14.3c0 .5-.3.8-.8.8m-9.3-4.7c0-1.3-.9-2.3-2.7-2.3-.4 0-.8 0-1.3.1V20c.5 0 .8.1 1.4.1 1.7 0 2.6-1 2.6-2.3m-1.1 0c0 .8-.4 1.4-1.4 1.4h-.5v-2.7h.4c1 0 1.5.5 1.5 1.3m5.9 0c0-1.3-.9-2.3-2.3-2.3s-2.3 1-2.3 2.3.9 2.3 2.3 2.3c1.3.1 2.3-.9 2.3-2.3m-1.1 0c0 .8-.5 1.4-1.2 1.4s-1.2-.6-1.2-1.4.5-1.4 1.2-1.4 1.2.6 1.2 1.4m5.2 2-.3-.9c-.3.2-.7.3-1.1.3-.8 0-1.3-.5-1.3-1.4s.5-1.4 1.3-1.4c.4 0 .7.1 1.1.3l.3-.9c-.4-.2-.9-.4-1.4-.4-1.4 0-2.4 1-2.4 2.3s1 2.3 2.4 2.3c.5.2 1 0 1.4-.2" className="st0"/></svg>)}
    />
  );
}
  