import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvoiceRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.25 1.8v21c0 .7-.6 1.2-1.2 1.2-.3 0-.6-.1-.8-.3l-3.2-2.4-3.25 2.45c-.4.3-1.1.3-1.5 0L8.05 21.3l-3.2 2.4c-.2.2-.5.3-.8.3-.7 0-1.3-.5-1.3-1.2v-21c0-1 .8-1.7 1.8-1.8h15c1 .1 1.7.8 1.7 1.8m-1.4 0c0-.1-.1-.2-.2-.2h-15c-.1 0-.2.1-.2.2v20.4l3.3-2.5c.3-.2.7-.2.9 0l3.45 2.75 3.55-2.75c.3-.2.7-.2.9 0l3.3 2.6zm-2.6 2.7a1 1 0 1 0 0 2 1 1 0 0 0 0-2m0 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2m0 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-5-7a.75.75 0 0 0-.75-.75h-5a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 .75-.75m2 4a.75.75 0 0 0-.75-.75h-7a.75.75 0 0 0 0 1.5h7a.75.75 0 0 0 .75-.75m-2 4a.75.75 0 0 0-.75-.75h-5a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 .75-.75"/></svg>)}
    />
  );
}
  