// @flow
import { useFormContext } from 'react-hook-form';
import type { Element } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UsersSearch from './UsersSearch';
import ErrorBoundary from '../../components/Error/ErrorBoundary';
import GenericError from '../../components/Error/GenericError';
import type { DirectoryStateEntityT } from '../../ducks/entities/directory';
import { UsersOrServicesTable } from './UsersOrServicesTable';
import { EfaxTable } from '../efax/EfaxTable';
import type { EfaxStateEntityT } from '../../ducks/entities/efax';

type PropsT = {|
  enterpriseId: string,
  users: DirectoryStateEntityT[],
  efaxes: EfaxStateEntityT[],
  setImportUsersActive: boolean => void,
  setDisplayError: boolean => void
|};

export const UsersForm = (props: PropsT): Element<'div'> => {
  const { enterpriseId, users, efaxes, setImportUsersActive, setDisplayError } = props;
  const { t } = useTranslation();
  const directory = useSelector(state => state.entities.directory);
  const efax = useSelector(state => state.entities.efax);
  const [pageToSearch, setPageToSearch] = useState(1);
  const [sortTerm, setSortTerm] = useState('phoneNumbers,asc');

  // form
  const { watch } = useFormContext();
  const values = watch();
  return values.categoryField ? (
    <div>
      <UsersSearch
        currentEnterpriseId={enterpriseId}
        sortTerm={sortTerm}
        pageToSearch={pageToSearch}
      />
      <ErrorBoundary errorElement={<GenericError message={t('users.userListFailedMsg')} />}>
        {values.categoryField.value === 'EFAXES' ? (
          <EfaxTable
            efaxes={efaxes}
            setSortTerm={setSortTerm}
            setPageToSearch={setPageToSearch}
            numberOfEfaxes={efax.totalCount}
          />
        ) : (
          <UsersOrServicesTable
            data={users}
            setImportUsersActive={setImportUsersActive}
            setDisplayError={setDisplayError}
            setSortTerm={setSortTerm}
            setPageToSearch={setPageToSearch}
            numberOfUsers={directory.totalCount}
            numberOfSearchedUsers={directory.searchTotalCount}
            isServicesTable={values.categoryField.value === 'SERVICES'}
          />
        )}
      </ErrorBoundary>
    </div>
  ) : (
    <div />
  );
};

export default UsersForm;
