import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSendFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.939 1.675-6.817 20.921a2.055 2.055 0 0 1-2.593 1.3 2.04 2.04 0 0 1-1.326-1.393l-1.851-7.683a.5.5 0 0 1 .097-.429l6.406-7.939a.25.25 0 0 0-.352-.352L9.53 12.528c-.12.097-.279.133-.429.097l-7.59-1.82A2.05 2.05 0 0 1 .076 8.272a2.05 2.05 0 0 1 1.322-1.387L22.32.064c.323-.105.671-.081.977.075.062.031.104.085.159.125a.5.5 0 0 1 .168.11c.013.013.018.03.029.043.125.134.226.289.285.468a1.3 1.3 0 0 1 .001.79"/></svg>)}
    />
  );
}
  