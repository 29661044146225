// @flow

// eslint-disable-next-line import/prefer-default-export
export const convertSecondsToMinutes = (seconds: number) => Math.round(seconds / 60);

export const convertMinutesToSeconds = (minutes: number) => minutes * 60;

export const DEFAULT_DEBOUNCE_DURATION = 300; // ms

export const sleepDelay = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
