// @flow

import React, { type Element } from 'react';
import classnames from 'classnames';
import Dismiss from './Button/Dismiss';
import ActionButton from './Button/ActionButton';
import CancelButton from './Button/CancelButton';
import { ReactComponent as ErrorIcon } from '../assets/public/error-red.svg';
import styles from './Dialog.module.scss';

export type PropsT = {
  onCancel: (*) => *,
  onConfirm: (*) => *,
  onClose?: (*) => *,
  title: string,
  description: string | Element<'span'>,
  confirmLabel: string,
  cancelLabel: string,
  disabled?: boolean,
  loading?: boolean,
  descriptionExtended?: string,
  descriptionClassName?: string,
  descriptionExtendedClassName?: string,
  errorMessage?: string
};

const Dialog = ({
  loading,
  onCancel,
  onConfirm,
  onClose,
  title,
  description,
  confirmLabel,
  cancelLabel,
  disabled,
  descriptionExtended,
  descriptionClassName,
  descriptionExtendedClassName,
  errorMessage
}: PropsT): Element<'div'> => (
  <div
    className={`ea-modal ea-modal--open styleguide-dialog-position ${styles.container}`}
    role="dialog"
    aria-modal="true"
  >
    <div className="ea-modal__overlay" />
    <div id="dialog-content" className={`ea-modal__content ${styles.content}`}>
      {onClose && <Dismiss id="close-button" onClose={onClose} />}
      <div className={styles.box}>
        <h2 id="dialog-title" className="ea-h3 ea-h3--thick">
          {title}
        </h2>
        <p
          id="dialog-body-text"
          className={classnames(!descriptionClassName && 'ea-bodytext', descriptionClassName)}
        >
          {description}
        </p>
        {descriptionExtended && (
          <p
            id="dialog-body-text-extended"
            className={classnames(
              !descriptionExtendedClassName && 'ea-bodytext-extended',
              descriptionExtendedClassName
            )}
          >
            {descriptionExtended}
          </p>
        )}
        {errorMessage && (
          <div id="dialog-error" className={styles.error}>
            <ErrorIcon className={styles['error-icon']} />
            {errorMessage}
          </div>
        )}
        <div className={styles.buttons}>
          <ActionButton
            id="modal-confirm-button"
            label={confirmLabel}
            onClickAction={onConfirm}
            disabled={disabled}
            loading={loading}
          />
          <CancelButton id="modal-cancel-button" label={cancelLabel} onClickAction={onCancel} />
        </div>
      </div>
    </div>
  </div>
);

export default Dialog;
