import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDisturbanceFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 .5a5.5 5.5 0 1 0 .001 11.001A5.5 5.5 0 0 0 18 .5m2.033 6.649a.627.627 0 0 1-.442 1.067.62.62 0 0 1-.442-.183L18 6.884l-1.148 1.148a.623.623 0 0 1-.884 0 .627.627 0 0 1 0-.884L17.116 6l-1.149-1.149a.626.626 0 0 1 .885-.884L18 5.116l1.148-1.148a.626.626 0 0 1 .885.884L18.884 6zm2.433 6.854a45 45 0 0 0-1.142-1.878.244.244 0 0 0-.316-.085 6.7 6.7 0 0 1-3.008.71c-3.023 0-5.582-2-6.438-4.744a.25.25 0 0 0-.261-.173c-.773.081-1.325.165-1.381.173C4.128 8.949-.044 13.89 0 19.75V22a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-2.076a11.84 11.84 0 0 0-1.534-5.921M6.25 16.75a1 1 0 1 1 0-2 1 1 0 0 1 0 2m3-5a1 1 0 1 1 0 2 1 1 0 0 1 0-2m5.1 10.75a2.37 2.37 0 0 0-2.338-2.124l-.012.002-.011-.002A2.37 2.37 0 0 0 9.65 22.5H8.4a3.61 3.61 0 0 1 3.141-3.33l1.869-5.375a.624.624 0 1 1 1.18.41l-1.749 5.03a3.62 3.62 0 0 1 2.758 3.264zm2.4-6.75a1 1 0 1 1 2 0 1 1 0 0 1-2 0m2.5 4.75a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/></svg>)}
    />
  );
}
  