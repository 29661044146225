import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBulletsRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 6.75C1.483 6.75.25 5.517.25 4S1.483 1.25 3 1.25 5.75 2.483 5.75 4 4.517 6.75 3 6.75m0-4c-.689 0-1.25.561-1.25 1.25S2.311 5.25 3 5.25 4.25 4.689 4.25 4 3.689 2.75 3 2.75M24 4a.75.75 0 0 0-.75-.75H9a.75.75 0 0 0 0 1.5h14.25A.75.75 0 0 0 24 4M3 14.75C1.483 14.75.25 13.517.25 12S1.483 9.25 3 9.25 5.75 10.483 5.75 12 4.517 14.75 3 14.75m0-4c-.689 0-1.25.561-1.25 1.25s.561 1.25 1.25 1.25 1.25-.561 1.25-1.25-.561-1.25-1.25-1.25M24 12a.75.75 0 0 0-.75-.75H9a.75.75 0 0 0 0 1.5h14.25A.75.75 0 0 0 24 12M3 22.75C1.483 22.75.25 21.517.25 20S1.483 17.25 3 17.25 5.75 18.483 5.75 20 4.517 22.75 3 22.75m0-4c-.689 0-1.25.561-1.25 1.25s.561 1.25 1.25 1.25 1.25-.561 1.25-1.25-.561-1.25-1.25-1.25M24 20a.75.75 0 0 0-.75-.75H9a.75.75 0 0 0 0 1.5h14.25A.75.75 0 0 0 24 20"/></svg>)}
    />
  );
}
  