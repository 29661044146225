// @flow strict-local

import React, { type Element, useState } from 'react';
import type Moment from 'moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import TimePicker from 'react-time-picker';
import styles from './TimeRangePicker.module.scss';

type PropsT = {
  initialStartTime?: ?Moment,
  initialEndTime?: ?Moment,
  validationMessage?: string,
  onChange: ({ start: Moment, end: Moment }) => void,
  disabled?: boolean
};

export function TimeRangePicker(props: PropsT): Element<'div'> {
  const { initialStartTime, initialEndTime, onChange, validationMessage, disabled } = props;
  const { t } = useTranslation();
  const [showValidationError, setShowValidationError] = useState(false);
  const [startTime, setStartTime] = useState(
    initialStartTime ? initialStartTime.format('HH:mm') : ''
  );
  const [endTime, setEndTime] = useState(initialEndTime ? initialEndTime.format('HH:mm') : '');

  const updateRangeStartOrEnd = (startOrEndValue: string): Moment => {
    return moment(startOrEndValue, 'HH:mm');
  };

  const handleOnChange = (start, end) => {
    if (start.isBefore(end)) {
      setShowValidationError(false);
    } else {
      setShowValidationError(true);
    }

    onChange({ start, end });
  };

  const handleStartOnChange = value => {
    setStartTime(value);
    const start = updateRangeStartOrEnd(value);
    const end = updateRangeStartOrEnd(endTime);
    handleOnChange(start, end);
  };

  const handleEndOnChange = value => {
    setEndTime(value);
    const start = updateRangeStartOrEnd(startTime);
    const end = updateRangeStartOrEnd(value);
    handleOnChange(start, end);
  };

  return (
    <div className={styles['time-area']}>
      <div className={styles['field-area']}>
        <div className={styles['field-section']}>
          <div className={styles['input-description']}>{t('generic.dateTimePicker.starts')}</div>
          <TimePicker
            id="time-begin"
            data-cy="time-begin-input"
            name="time-begin"
            onChange={handleStartOnChange}
            className={styles['time-input']}
            value={startTime}
            disabled={disabled}
            disableClock
            required
            locale="fi-FI"
            format="HH:mm"
          />
        </div>
        <div className={styles['input-mid-text']}>-</div>
        <div>
          <div className={styles['input-description']}>{t('generic.dateTimePicker.ends')}</div>
          <TimePicker
            id="time-end"
            data-cy="time-end"
            name="time-end"
            disableClock
            onChange={handleEndOnChange}
            value={endTime}
            disabled={disabled}
            required
            locale="fi-FI"
            format="HH:mm"
          />
        </div>
      </div>
      <div className={styles.error}>{showValidationError && validationMessage}</div>
    </div>
  );
}

export default TimeRangePicker;
