import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUserLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0M5.5 20.85v-.6c0-2.44 3.35-3.75 6.5-3.75s6.5 1.17 6.5 3.75v.6a10.9 10.9 0 0 1-13 0m14-.81c-.15-2.72-3.12-4.54-7.49-4.54-3.64 0-7.31 1.56-7.48 4.54C.086 15.898-.159 8.937 3.983 4.493S15.086-.196 19.53 3.947s4.689 11.103.547 15.547q-.264.283-.547.547h-.03zM12 5a4.5 4.5 0 1 0 4.5 4.5A4.51 4.51 0 0 0 12 5m0 8a3.5 3.5 0 1 1 3.5-3.5A3.5 3.5 0 0 1 12 13"/></svg>)}
    />
  );
}
  