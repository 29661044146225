// @flow

import React, { type Element } from 'react';
import { Button } from '@design-system/component-library';
import IconLockRegular from '@design-system/component-library/src/components/Icon/lib/IconLockRegular';
import { useTranslation } from 'react-i18next';
import Footer from '../../login/Footer';
import BaseContainer from '../../BaseContainer/BaseContainer';
import Header from '../Header/Header';

import styles from './Login.module.scss';

export const PBLogin = (): Element<'div'> => {
  const gotoEntraLogin = () => {
    window.location.href = '/api/oauth2/authorization/azure';
  };

  const { t } = useTranslation();

  return (
    <BaseContainer header={<Header />}>
      <div className="login-page">
        <div className={styles['page-container']}>
          <div className={styles['content-container']}>
            <div className={styles['form-container']}>
              <h2 className={styles['info-text']}>{t('phonebook.sharedPhonebookTitle')}</h2>
              <Button
                size="l"
                className={styles['entra-login-button']}
                icon={<IconLockRegular />}
                onClick={gotoEntraLogin}
              >
                <span>{t('login.ssoLogin')}</span>
              </Button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </BaseContainer>
  );
};
export default PBLogin;
