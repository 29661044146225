import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLocationUnknownRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.75 9.25v-.36a2.62 2.62 0 1 0-3.37-2.51c0 .409.331.74.74.74a.75.75 0 0 0 .76-.74A1.12 1.12 0 1 1 12 7.5a.76.76 0 0 0-.75.75v1a.75.75 0 0 0 1.5 0M12 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2m-.002 7.74a1.12 1.12 0 0 1-.902-.453C9.108 17.549 4.5 10.843 4.5 7.5 4.5 3.364 7.864 0 12 0s7.5 3.364 7.5 7.5c0 3.345-4.608 10.05-6.59 12.777a1.13 1.13 0 0 1-.912.463M12 1.5c-3.309 0-6 2.691-6 6 0 2.292 3.165 7.515 6 11.477 2.834-3.959 6-9.182 6-11.477 0-3.309-2.691-6-6-6M12 24C6.582 24 .75 22.826.75 20.25c0-1.803 2.775-2.734 5.104-3.198a.75.75 0 0 1 .293 1.471c-2.916.581-3.896 1.43-3.896 1.728 0 .673 3.317 2.25 9.75 2.25s9.75-1.577 9.75-2.25c0-.299-.986-1.149-3.917-1.73a.75.75 0 1 1 .291-1.471c2.339.463 5.126 1.394 5.126 3.201C23.25 22.826 17.418 24 12 24"/></svg>)}
    />
  );
}
  