import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPinFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C7.589 0 4 3.651 4 8.14c0 3.611 5.004 11.967 7.159 15.397.08.124.182.227.297.3a1 1 0 0 0 .758.14 1 1 0 0 0 .631-.445C14.996 20.105 20 11.747 20 8.14 20 3.651 16.411 0 12 0m0 11.25c-1.792 0-3.25-1.458-3.25-3.25S10.208 4.75 12 4.75 15.25 6.208 15.25 8s-1.458 3.25-3.25 3.25"/></svg>)}
    />
  );
}
  