import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGroupRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.75 12.25C5.131 12.25 3 10.119 3 7.5s2.131-4.75 4.75-4.75S12.5 4.881 12.5 7.5s-2.131 4.75-4.75 4.75m0-8C5.958 4.25 4.5 5.708 4.5 7.5s1.458 3.25 3.25 3.25S11 9.292 11 7.5 9.542 4.25 7.75 4.25m7.744 16.747c0-4.271-3.476-7.747-7.747-7.747C3.475 13.25 0 16.726 0 20.997a.75.75 0 0 0 1.5 0c0-3.444 2.802-6.247 6.247-6.247s6.247 2.803 6.247 6.247a.75.75 0 0 0 1.5 0m2.256-7.747c-2.344 0-4.25-1.907-4.25-4.25s1.906-4.25 4.25-4.25S22 6.657 22 9s-1.906 4.25-4.25 4.25m0-7C16.233 6.25 15 7.483 15 9s1.233 2.75 2.75 2.75S20.5 10.517 20.5 9s-1.233-2.75-2.75-2.75M24 21a6.758 6.758 0 0 0-8.718-6.459.75.75 0 0 0 .436 1.436A5.256 5.256 0 0 1 22.5 21a.75.75 0 0 0 1.5 0"/></svg>)}
    />
  );
}
  