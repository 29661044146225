const ALL_COLORS = [
  'primary',
  'highlight',
  'invert',
  'brand-blue',
  'brand-blue--dark',
  'white',
  'white--dark',
  'black',
  'blue-800',
  'blue-600',
  'blue-400',
  'blue-300',
  'blue-200',
  'blue-100',
  'primary-blue-800',
  'secondary-blue-800',
  'secondary-blue-600',
  'secondary-blue-400',
  'secondary-blue-300',
  'secondary-blue-200',
  'secondary-blue-100',
  'blue-800--light',
  'blue-600--light',
  'blue-400--light',
  'blue-300--light',
  'blue-200--light',
  'blue-800--dark',
  'blue-600--dark',
  'blue-400--dark',
  'blue-200--dark',
  'salmon-700',
  'salmon-600',
  'salmon-400',
  'turquoise-400',
  'turquoise-200',
  'neutral-900',
  'neutral-700',
  'neutral-600',
  'neutral-500',
  'neutral-400',
  'neutral-300',
  'neutral-200',
  'neutral-100',
  'neutral-900--light',
  'neutral-700--light',
  'neutral-600--light',
  'neutral-500--light',
  'neutral-400--light',
  'neutral-300--light',
  'neutral-200--light',
  'neutral-100--light',
  'neutral-900--dark',
  'neutral-700--dark',
  'neutral-600--dark',
  'neutral-500--dark',
  'neutral-400--dark',
  'neutral-300--dark',
  'neutral-200--dark',
  'neutral-100--dark',
  'green-600',
  'green-500',
  'green-100',
  'red-600',
  'red-100',
  'secondary-orange',
  'orange',
  'orange-light',
  'orange-700',
  'orange-600',
  'orange-300',
  'orange-100',
  'transparent',
  'success',
  'ok',
  'warning',
  'danger',
  'error',
  'notification_alert',
  'notification_warning',
  'notification_default',
  'notification_info',
  'notification_success',
];

export default ALL_COLORS;
