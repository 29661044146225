// @flow

import React, { type Element } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { DestinationFieldT } from './DestinationFieldUtils';
import styles from './PhoneNumberInputField.module.scss';

export type PropsT = {|
  field: string
|};

export const PhoneNumberInputField = (props: PropsT): Element<'div'> => {
  const { field } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const value: DestinationFieldT = values[field];

  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['input-wrapper']} data-cy={`input-field-${field}`}>
        <Input
          id="acd-queue-size-input"
          data-cy="input"
          className={styles['acd-input']}
          onValueChange={event => {
            setValue(
              field,
              {
                ...value,
                value: event.target.value
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            );
          }}
          defaultValue={value.value}
          touched
          i18n_input_errorMessage={errors[field] && (errors[field].message || 'error')}
          placeholder={t('callflows.forwardingField.longNumberPlaceholder')}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInputField;
