import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAnnouncementFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.94 12.603a3.04 3.04 0 0 1-2.038 2.306.5.5 0 0 1-.652-.477V9.565a.5.5 0 0 1 .652-.476c1.466.47 2.342 1.981 2.038 3.514M4.25 7.998h-.264c-2.198 0-3.986 1.795-3.986 4s1.788 4 3.986 4h.264a.25.25 0 0 0 .25-.25v-7.5a.25.25 0 0 0-.25-.25m15.472-4.943a.51.51 0 0 0-.517.032l-1.104.767c-3.96 2.75-7.864 4.144-11.601 4.144H6a.25.25 0 0 0-.25.25v7.75a6.88 6.88 0 0 0 2.243 5.054.749.749 0 0 0 1.014-1.105 5.37 5.37 0 0 1-1.755-3.913c3.519.173 7.164 1.539 10.849 4.098l1.104.768a.49.49 0 0 0 .517.032.5.5 0 0 0 .268-.442V3.498a.49.49 0 0 0-.268-.443"/></svg>)}
    />
  );
}
  