// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import Input from '@design-system/component-library/src/components/Input';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import * as R from 'ramda';
import Combobox from '@design-system/component-library/src/components/Combobox';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import { operations as userOps } from '../../ducks/entities/user';
import { fetchExtensionIdWithAddressNumber, getAvailability } from '../../helpers';
import Avatar from '../../components/Avatar/Avatar';
import {
  ADDITIONAL_EXPLANATIONS_MAX_LENGTH,
  ADDITIONAL_INFO_MAX_LENGTH,
  CONTACT_INFORMATION_MAX_LENGTH,
  COST_CENTER_MAX_LENGTH,
  EMAILS_MAX_LENGTH,
  NICKNAME_MAX_LENGTH,
  OFFICE_REFERENCE_ID_MAX_LENGTH,
  TITLE_MAX_LENGTH
} from '../../fieldValidators';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import IdTreeCombobox from '../../components/InputComponent/IdTreeCombobox';
import DepartmentComboboxItem from '../enterprises/DepartmentDetails/DepartmentComboboxItem';
import { operations as departmentOps } from '../../ducks/entities/department';
import KeywordInput from '../../components/InputComponent/KeywordInput';
import {
  DUTIES_MAX_LENGTH,
  renderUploadAvatarDialog,
  TAG_NAME_MAX_LENGTH,
  TAG_NAMES_MAX_LENGTH
} from '../users/UserDetails/FormHelpers';
import CancelButton from '../../components/Button/CancelButton';
import ActionButton from '../../components/Button/ActionButton';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import { USERS_PAGE_SIZE } from '../../constants';
import { goTo } from '../../navigationOperations';
import Notifications from '../../components/Notifications/Notifications';
import { actions as notificationActions } from '../../ducks/ui/notification';

import styles from './PhonebookEntry.module.scss';

let requestEntryCancelTokenSource: CancelTokenSource;

export const PhonebookEntry = (): Element<'div'> => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showAvatarUploadDialog, setShowAvatarUploadDialog] = useState(false);
  const [uploadDialogLoading, setUploadDialogLoading] = useState(false);
  const [selectedSuperior, setSelectedSuperior] = useState();
  const [selectedAssistant, setSelectedAssistant] = useState();
  const [avatarToUpload, setAvatarToUpload] = useState();
  const [superiorResults, setSuperiorResults] = useState([]);
  const [assistantResults, setAssistantResults] = useState([]);
  const [superiors, setSuperiors] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const currentUser = useSelector(state => state.currentUser);
  const { phonebookId } = useParams();
  const location = useLocation();
  const [directoryUser] = useState(location.state ? location.state.directoryUser : {});
  const [phonebook] = useState(location.state ? location.state.phonebook : {});
  const [user, setUser] = useState();
  const [urlPhoto, setUrlPhoto] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [duties, setDuties] = useState();
  const [tagNames, setTagNames] = useState();
  const [title, setTitle] = useState('');
  const [nickName, setNickName] = useState('');
  const [emails, setEmails] = useState('');
  const [additionalExplanations, setAdditionalExplanations] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [contactInformation, setContactInformation] = useState('');
  const [internalAddress, setInternalAddress] = useState('');
  const [costCenter, setCostCenter] = useState('');
  const [homeNumber, setHomeNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [substitutes, setSubstitutes] = useState([]);
  const [officeAddress, setOfficeAddress] = useState('');
  const departmentIdTree = useSelector(
    state => state.entities.department.idTrees[directoryUser.enterpriseId] || []
  );
  const departmentName = useSelector(state =>
    user && user.department ? state.entities.department.byId[user.department.id].name : ''
  );
  const dispatch = useDispatch();

  const fetchPhonebookEntry = async () => {
    setIsLoading(true);
    try {
      // $FlowFixMe
      const extensionId = await fetchExtensionIdWithAddressNumber(
        directoryUser.enterpriseId,
        phonebookId,
        requestEntryCancelTokenSource.token
      );
      await dispatch(
        departmentOps.retrieveCollection(
          directoryUser.enterpriseId,
          requestEntryCancelTokenSource.token
        )
      );
      setUser(
        await dispatch(
          userOps.retrieve(
            directoryUser.enterpriseId,
            extensionId,
            null,
            requestEntryCancelTokenSource.token,
            {
              type: 'internalUser'
            }
          )
        )
      );
    } catch (error) {
      //
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      setTitle(user.title);
      setNickName(user.nickName);
      setEmails(user.emails);
      setAdditionalExplanations(user.additionalExplanations);
      setAdditionalInformation(user.additionalInfo);
      setInternalAddress(user.internalAddress);
      setCostCenter(user.costCenter);
      setContactInformation(user.contactInformation);
      setOfficeAddress();
      setUrlPhoto(user.urlPhoto);
      setSuperiors(user.superiors);
      setAssistants(user.assistants);
      setSubstitutes(user.substitutes);
      setDuties(user.duties);
      setHomeNumber(user.homeNumber);
      setMobileNumber(user.mobileNumber);
      setTagNames(user.tagNames);
      setDepartmentId(user.department ? user.department.id : '');
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    requestEntryCancelTokenSource = CancelToken.source();
    fetchPhonebookEntry();
    return () => {
      requestEntryCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fullName = user ? [user.firstName, user.lastName].join(' ').trim() : '';
  const availability = user ? getAvailability(user, t) : null;
  const mapIndexed = R.addIndex(R.map);
  const dutiesKeywords = R.filter(
    entry => !!entry.text,
    mapIndexed((tag, index) => ({ id: `${tag}-${index}`, text: tag }), (duties || '').split(',')) ||
      []
  );
  const tagNamesKeywords = R.filter(
    entry => !!entry.text,
    mapIndexed((tag, index) => ({ id: `${tag}-${index}`, text: tag }), tagNames || []) || []
  );

  const handleUploadAvatar = async (e: ?Event) => {
    if (e) e.preventDefault();
    if (user) {
      if (avatarToUpload) {
        setUploadDialogLoading(!uploadDialogLoading);
        const response = await dispatch(
          userOps.uploadAvatar(
            directoryUser.enterpriseId,
            user.id,
            avatarToUpload,
            requestEntryCancelTokenSource.token,
            createCsrfHeader(currentUser)
          )
        );
        if (response !== undefined) {
          setUrlPhoto(response.urlPhoto);
        }
        setUploadDialogLoading(!uploadDialogLoading);
      }
      setShowAvatarUploadDialog(!showAvatarUploadDialog);
    }
  };

  const handleDeleteAvatar = async () => {
    if (user) {
      setUploadDialogLoading(!uploadDialogLoading);
      const response = await dispatch(
        userOps.deleteAvatar(
          directoryUser.enterpriseId,
          user.id,
          requestEntryCancelTokenSource.token,
          createCsrfHeader(currentUser)
        )
      );
      if (response !== undefined) {
        setUrlPhoto('');
      }
      setUploadDialogLoading(!uploadDialogLoading);
      setShowAvatarUploadDialog(!showAvatarUploadDialog);
    }
  };

  const searchUsers = async (searchTerm, results, setResults) => {
    if (requestEntryCancelTokenSource) {
      requestEntryCancelTokenSource.cancel();
      requestEntryCancelTokenSource = CancelToken.source();
    }
    const params = {};
    params.sort = 'userName,asc';
    params.page = 1;
    params.size = USERS_PAGE_SIZE;
    params.queryBy = 'userName';
    params.queryTerm = `*${searchTerm}*`;
    const foundUsers = await dispatch(
      userOps.getUsers(directoryUser.enterpriseId, requestEntryCancelTokenSource.token, params)
    );
    if (foundUsers && foundUsers.results) {
      setResults(
        foundUsers.results
          .filter(res => !results.find(r => r.addressNumber === res.addressNumber))
          .map(res => ({
            id: res.id,
            label: `${res.userName} (${res.addressNumber})`,
            value: res.addressNumber
          }))
      );
    }
  };

  const renderResults = (results, setResults, disabled) => {
    return (
      <div className={styles['results-area']}>
        {results.length > 0
          ? results.map(
              res =>
                res.userName && (
                  <div key={`${typeof results}_${res.addressNumber}`}>
                    {res.userName.includes(`(${res.addressNumber})`)
                      ? res.userName
                      : // $FlowFixMe: already null checked
                        `${res.userName} (${res.addressNumber})`}
                    {!disabled ? (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                      <span
                        onClick={() =>
                          // $FlowFixMe
                          setResults(results.filter(s => s.addressNumber !== res.addressNumber))
                        }
                        className={styles['delete-icon']}
                      >
                        <IconDeleteRegular size="s" color="brand-blue" />
                      </span>
                    ) : null}
                  </div>
                )
            )
          : '-'}
      </div>
    );
  };

  const createPatchPayload = () => {
    return {
      environment: phonebook.environment,
      // $FlowFixMe: already null checked
      extensionId: user.id,
      title,
      nickName,
      duties,
      emails,
      departmentId: departmentId === 'empty' ? '' : departmentId,
      tagNames: !tagNames || (tagNames.length === 1 && !tagNames[0]) ? [] : tagNames,
      additionalExplanations,
      additionalInfo: additionalInformation,
      internalAddress,
      costCenter,
      contactInformation,
      officeAddress,
      superiors: superiors.map(s => s.id),
      assistants: assistants.map(a => a.id)
    };
  };

  const saveChanges = async () => {
    if (user) {
      setIsUpdating(true);
      let response;
      try {
        response = await axios({
          method: 'PATCH',
          url: `/api/v1/enterprises/${directoryUser.enterpriseId}/phonebook/${user.personId}`,
          data: createPatchPayload(),
          cancelToken: CancelToken.source().token,
          headers: createCsrfHeader(currentUser)
        });
      } catch (error) {
        //
      }
      setIsUpdating(false);
      if (response) {
        const notification = {
          tag: 'update-user-success',
          duration: 15000,
          type: 'info',
          message: t('phonebook.successNotification')
        };

        dispatch(notificationActions.createCreateNotificationAction(notification));
      } else {
        const notification = {
          tag: 'update-user-failure',
          duration: 15000,
          type: 'error',
          message: t('phonebook.failedNotification')
        };

        dispatch(notificationActions.createCreateNotificationAction(notification));
      }
    }
  };

  return isLoading ? (
    <div>
      <CenterHorizontally>
        <LoadingSpinner />
      </CenterHorizontally>
    </div>
  ) : user ? (
    <div>
      <Notifications tags={['update-user-success', 'update-user-failure']} />
      <div className={styles['phonebook-details-area']}>
        {showAvatarUploadDialog &&
          renderUploadAvatarDialog(
            t,
            uploadDialogLoading,
            false,
            () => setShowAvatarUploadDialog(!showAvatarUploadDialog),
            handleUploadAvatar,
            handleDeleteAvatar,
            file => setAvatarToUpload(file),
            urlPhoto || null
          )}
        <Avatar
          src={urlPhoto || null}
          name={fullName}
          availability={availability ? availability.icon : 'green'}
          color="#0019AF"
          shadow
          size="large"
          hideWhenViewing
          showEdit={phonebook.editableFields.includes('avatar')}
          onEditClick={() => {
            setShowAvatarUploadDialog(!showAvatarUploadDialog);
          }}
        />
        <Input
          id="title-input"
          label={t('phonebook.fieldLabels.title')}
          onValueChange={e => {
            setTitle(e.target.value);
          }}
          optional
          defaultValue={title}
          i18n_input_optionalText=""
          maxlength={TITLE_MAX_LENGTH}
          disabled={!phonebook.editableFields.includes('title')}
        />
        <Input
          id="nickName-input"
          label={t('phonebook.fieldLabels.nickName')}
          onValueChange={e => {
            setNickName(e.target.value);
          }}
          optional
          defaultValue={nickName}
          i18n_input_optionalText=""
          maxlength={NICKNAME_MAX_LENGTH}
          disabled={!phonebook.editableFields.includes('nickName')}
        />
        <Input
          id="email-input"
          label={t('phonebook.fieldLabels.email')}
          onValueChange={e => {
            setEmails(e.target.value);
          }}
          optional
          defaultValue={emails}
          i18n_input_optionalText=""
          maxlength={EMAILS_MAX_LENGTH}
          disabled={!phonebook.editableFields.includes('email')}
        />
        <IdTreeCombobox
          options={departmentIdTree}
          disabled={
            departmentIdTree.length === 0 || !phonebook.editableFields.includes('department')
          }
          label={t('phonebook.fieldLabels.department')}
          onChange={option => {
            // $FlowFixMe
            setDepartmentId(option.value);
          }}
          renderItem={p => (
            <DepartmentComboboxItem
              {...p}
              emptySelection={{
                key: t('users.departmentAndColleagues.noDepartmentOption'),
                value: null
              }}
            />
          )}
          hasEmptyOption
          hasRootOption={false}
          rootOptionKey={null}
          hideKeyAndChildren={null}
          value={{ label: departmentName, value: user.department ? user.department.id : '' }}
        />
        <div className={styles['tags-area']}>
          <label className="ds-input--labelarea-label">{t('phonebook.fieldLabels.duties')}</label>
          <KeywordInput
            placeholder=""
            initialValues={dutiesKeywords}
            maxLength={DUTIES_MAX_LENGTH}
            disabled={!phonebook.editableFields.includes('duties')}
            onChange={keywordList => {
              setDuties(
                R.join(
                  ',',
                  keywordList.map(entry => entry.text)
                )
              );
            }}
          />
        </div>
        <div className={styles['tags-area']}>
          <label className="ds-input--labelarea-label">{t('phonebook.fieldLabels.tagNames')}</label>
          <KeywordInput
            placeholder=""
            initialValues={tagNamesKeywords}
            maxLength={TAG_NAMES_MAX_LENGTH}
            maxWordLength={TAG_NAME_MAX_LENGTH}
            disabled={!phonebook.editableFields.includes('tagNames')}
            onChange={keywordList => {
              setTagNames(keywordList.map(entry => entry.text));
            }}
          />
        </div>
        <Input
          id="additionalExplanations-input"
          label={t('phonebook.fieldLabels.additionalExplanations')}
          onValueChange={e => {
            setAdditionalExplanations(e.target.value);
          }}
          optional
          type="textarea"
          defaultValue={additionalExplanations}
          i18n_input_optionalText=""
          maxlength={ADDITIONAL_EXPLANATIONS_MAX_LENGTH}
          i18n_input_helpText={`${
            additionalExplanations ? additionalExplanations.length : 0
          } / ${ADDITIONAL_EXPLANATIONS_MAX_LENGTH}`}
          disabled={!phonebook.editableFields.includes('additionalExplanations')}
        />
        <Input
          id="additionalInformation-input"
          label={t('phonebook.fieldLabels.additionalInformation')}
          onValueChange={e => {
            setAdditionalInformation(e.target.value);
          }}
          optional
          type="textarea"
          defaultValue={additionalInformation}
          i18n_input_optionalText=""
          maxlength={ADDITIONAL_INFO_MAX_LENGTH}
          disabled={!phonebook.editableFields.includes('additionalInformation')}
          i18n_input_helpText={`${
            additionalInformation ? additionalInformation.length : 0
          } / ${ADDITIONAL_INFO_MAX_LENGTH}`}
        />
        <Input
          id="internalAddress-input"
          label={t('phonebook.fieldLabels.internalAddress')}
          onValueChange={e => {
            setInternalAddress(e.target.value);
          }}
          disabled={!phonebook.editableFields.includes('internalAddress')}
          optional
          defaultValue={internalAddress}
          i18n_input_optionalText=""
          maxlength={TITLE_MAX_LENGTH}
        />
        <Input
          id="costCenter-input"
          label={t('phonebook.fieldLabels.costCenter')}
          onValueChange={e => {
            setCostCenter(e.target.value);
          }}
          disabled={!phonebook.editableFields.includes('costCenter')}
          optional
          defaultValue={costCenter}
          i18n_input_optionalText=""
          maxlength={COST_CENTER_MAX_LENGTH}
        />
        <Input
          id="contactInformation-input"
          label={t('phonebook.fieldLabels.contactInformation')}
          onValueChange={e => {
            setContactInformation(e.target.value);
          }}
          disabled={!phonebook.editableFields.includes('contactInformation')}
          optional
          type="textarea"
          defaultValue={contactInformation}
          i18n_input_optionalText=""
          maxlength={CONTACT_INFORMATION_MAX_LENGTH}
          i18n_input_helpText={`${
            contactInformation ? contactInformation.length : 0
          } / ${CONTACT_INFORMATION_MAX_LENGTH}`}
        />
        <Input
          id="officeAddress-input"
          label={t('phonebook.fieldLabels.officeAddress')}
          onValueChange={e => {
            setOfficeAddress(e.target.value);
          }}
          disabled={!phonebook.editableFields.includes('officeAddress')}
          optional
          defaultValue={officeAddress}
          i18n_input_optionalText=""
          maxlength={OFFICE_REFERENCE_ID_MAX_LENGTH}
        />
        <Combobox
          id="superiors-input"
          label={t('phonebook.fieldLabels.superiors')}
          items={superiorResults}
          i18n_combobox_errorMessage=""
          selectedValue={selectedSuperior}
          disabled={!phonebook.editableFields.includes('superiors')}
          onValueSelect={async element => {
            setSuperiorResults([]);
            setSelectedSuperior('');
            const foundUser = superiorResults.find(res => res.value === element.dataset.value);
            if (foundUser) {
              setSuperiors([
                ...superiors,
                { id: foundUser.id, userName: foundUser.label, addressNumber: foundUser.value }
              ]);
            }
          }}
          onValueChange={value => {
            searchUsers(value, superiors, setSuperiorResults);
          }}
        />
        {renderResults(superiors, setSuperiors, !phonebook.editableFields.includes('superiors'))}
        <Combobox
          id="assistants-input"
          label={t('phonebook.fieldLabels.assistants')}
          i18n_combobox_errorMessage=""
          items={assistantResults}
          selectedValue={selectedAssistant}
          disabled={!phonebook.editableFields.includes('assistants')}
          onValueSelect={async element => {
            setAssistantResults([]);
            setSelectedAssistant('');
            const foundUser = assistantResults.find(res => res.value === element.dataset.value);
            if (foundUser) {
              setAssistants([
                ...assistants,
                { id: foundUser.id, userName: foundUser.label, addressNumber: foundUser.value }
              ]);
            }
          }}
          onValueChange={value => {
            searchUsers(value, assistants, setAssistantResults);
          }}
        />
        {renderResults(assistants, setAssistants, !phonebook.editableFields.includes('assistants'))}
        <Input
          id="homeNumber-input"
          label={t('phonebook.fieldLabels.homeNumber')}
          disabled
          optional
          defaultValue={homeNumber}
          i18n_input_optionalText=""
        />
        <Input
          id="mobileNumber-input"
          label={t('phonebook.fieldLabels.mobileNumber')}
          disabled
          optional
          defaultValue={mobileNumber}
          i18n_input_optionalText=""
        />
        <div>
          <label className="ds-input--labelarea-label">
            {t('phonebook.fieldLabels.substitutes')}
          </label>
          {renderResults(substitutes, () => {}, true)}
        </div>
        <div>
          <ActionButton
            id="forwarding-save-button"
            label={t('phonebook.save')}
            onClickAction={() => saveChanges()}
            type="submit"
            loading={isUpdating}
          />
          <CancelButton
            id="cancel-button"
            data-cy="cancel-button"
            className={styles['cancel-button']}
            label={t('phonebook.cancel')}
            onClickAction={() => dispatch(goTo(`/phonebook`))}
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default PhonebookEntry;
