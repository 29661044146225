// @flow

/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios';
import HTTP from 'http-status-codes';
import * as R from 'ramda';
import classnames from 'classnames';
import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, type FormProps, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { InputField } from '../../components/InputComponent/ReduxFormField';
import fieldValidators from '../../fieldValidators';
import type { StoreStateT } from '../../commonTypes';
import type { CreateCreateNotificationActionFnT } from '../../ducks/ui/notification/notificationUiActions';
import LoginBase from '../login/LoginBase';
import { actions as notificationActions } from '../../ducks/ui/notification';
import ActionButton from '../../components/Button/ActionButton';
import Notifications from '../../components/Notifications/Notifications';
import ContactUserSelector from './ContactUserSelector';
import Notification from '../../components/Notifications/Notification';
import { setActiveLang } from '../../utils/languageutils';
import { goTo } from '../../navigationOperations';
import styles from '../login/LoginBase.module.scss';

type OwnPropsT = {|
  location: {
    search: {
      invalidToken?: ?boolean
    }
  }
|};

type StatePropsT = {|
  identifier: string,
  invalidToken: boolean
|};

type DispatchPropsT = {|
  notify: CreateCreateNotificationActionFnT
|};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  // $FlowFixMe
  ...FormProps,
  ...$Exact<ContextRouter>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {
  sending: boolean,
  error: boolean,
  contactType: 'sms' | 'email',
  errorMsg: ?string,
  inputErrorMsg: ?string
};

export class UserPasswordMissing extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.handleOnClickSendButton = this.handleOnClickSendButton.bind(this);
    this.validateNumber = this.validateNumber.bind(this);
  }

  state = {
    sending: false, // eslint-disable-line react/no-unused-state
    error: false,
    errorMsg: null,
    contactType: 'sms',
    inputErrorMsg: null
  };

  componentDidMount() {
    const {
      location: { pathname },
      i18n
    } = this.props;
    setActiveLang(pathname, '/salasana', '/password', '/lösenord', '/parool', i18n.changeLanguage);
  }

  validateNumber: *;

  validateNumber(value: string) {
    const { t } = this.props;
    this.setState({
      inputErrorMsg: fieldValidators.phoneNumberValidator(value, t('generic.validators.phone'))
    });
  }

  handleOnClickSendButton: *;

  async handleOnClickSendButton() {
    const { identifier, notify, t, i18n } = this.props; // eslint-disable-line no-shadow
    const { contactType } = this.state;
    this.setState({ error: false, sending: true });
    try {
      const data = await axios({
        method: 'GET',
        headers: { type: 'user' },
        url: `/api/v1/restore/${identifier}?contactType=${contactType}&language=${i18n.language}`,
        validateStatus: status => status < 500 // never show error if server is working
      });
      if (data.status === HTTP.UNPROCESSABLE_ENTITY) {
        this.setState({
          error: true,
          sending: false,
          errorMsg: t('password.user.passwordMissing.errorNotification')
        });
      } else {
        notify({
          tag: 'check-temporary-pw',
          duration: 15000,
          type: 'info',
          message: t('password.user.passwordMissing.successNotification')
        });
        this.setState({ error: false, sending: false, errorMsg: null });
      }
    } catch (error) {
      this.setState({ error: true, sending: false, errorMsg: null });
    }
  }

  render(): Element<typeof LoginBase> {
    const { t, invalidToken } = this.props; // eslint-disable-line no-shadow
    const { error, sending, errorMsg, contactType, inputErrorMsg } = this.state;
    const identifierInput = (
      <Field
        key="identifier"
        id="password-missing-identifier-input"
        name="identifier"
        placeholder={t('password.user.passwordMissing.numberPlaceholder')}
        component={InputField}
        className={styles['input-container']}
        type="text"
        onChange={e => {
          // $FlowFixMe
          if (e && e.currentTarget) {
            this.validateNumber(e.currentTarget.value);
          }
        }}
        i18n_input_errorMessage={inputErrorMsg}
      />
    );

    const loginButton = (
      <ActionButton
        id="user-pw-missing-button"
        label={t('password.user.passwordMissing.sendButton')}
        onClickAction={() => {
          if (!this.props.invalid) this.handleOnClickSendButton();
        }}
        loading={sending}
        disabled={inputErrorMsg !== undefined}
      />
    );

    return (
      <LoginBase>
        <div>
          <h3 className="ea-h3 ea-pad ea-pad--pad-bottom-1" id="login-page-title">
            {t('password.user.passwordMissing.title')}
          </h3>
          <Notifications
            tags={['check-temporary-pw']}
            render={({ message, keyId, tag }) => (
              <div
                className={classnames(
                  'ea-notification',
                  tag === 'user-token-validation-fail' ? styles['notif-error'] : ''
                )}
                key={keyId}
                role="status"
              >
                {message}
              </div>
            )}
          />
          {invalidToken && (
            <Notification
              tag="user-token-validation-fail"
              keyId="user-token-validation-fail-id"
              duration={0}
              type="error"
              message={t('password.user.passwordReset.tokenValidationFailureNotification')}
            />
          )}
          <p>{t('password.user.passwordMissing.description')}</p>
          {identifierInput}
          <ContactUserSelector
            contactType={contactType}
            handleChange={type => this.setState({ contactType: type })}
          />
          <div className="ea-pad ea-pad--pad-top-1">{loginButton}</div>
          {error && <p role="status">{errorMsg || t('password.user.passwordMissing.error')}</p>}
        </div>
      </LoginBase>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps) => {
  const queryParams = queryString.parse(R.path(['location', 'search'], ownProps) || '');
  return {
    invalidToken: !!queryParams.invalidToken,
    initialValues: { identifier: '' },
    identifier: formValueSelector('missingPassword')(state, 'identifier')
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goTo,
      notify: notificationActions.createCreateNotificationAction
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter,
  reduxForm({ form: 'missingPassword', enableReinitialize: true })
)(UserPasswordMissing);
