import React from 'react';
import IconContainer from '../IconContainer';

export default function IconForwardLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.41 20a2 2 0 0 1-1.32-.54A1.9 1.9 0 0 1 13.5 18v-2.5h-11A1.5 1.5 0 0 1 1 14v-4a1.5 1.5 0 0 1 1.5-1.5h11V6.07A2 2 0 0 1 15.39 4c.558-.02 1.094.22 1.45.65l5.62 6a2 2 0 0 1 0 2.74l-5.62 6a2 2 0 0 1-1.43.61M2.5 9.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h11.39a.61.61 0 0 1 .61.61V18a1 1 0 0 0 .27.73.89.89 0 0 0 1.33-.06l5.63-6a1 1 0 0 0 0-1.38l-5.62-6a.85.85 0 0 0-.69-.29 1 1 0 0 0-.92 1.07v2.82a.61.61 0 0 1-.61.61z"/></svg>)}
    />
  );
}
  