import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUpdateRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.25 14.5v4a.75.75 0 0 1-.75.75h-4a.75.75 0 0 1 0-1.5h2.026A10.23 10.23 0 0 1 4.921 4.597a10.25 10.25 0 0 1 11.025-2.061.75.75 0 0 1-.576 1.385 8.76 8.76 0 0 0-9.412 1.76A8.73 8.73 0 0 0 4.75 16.884V14.5a.75.75 0 0 1 1.5 0M22.5 4.75h-4a.75.75 0 0 0-.75.75v4a.75.75 0 0 0 1.5 0V7.106a8.74 8.74 0 0 1-1.191 11.206 8.76 8.76 0 0 1-9.428 1.765.749.749 0 1 0-.577 1.384 10.25 10.25 0 0 0 11.045-2.067 10.23 10.23 0 0 0 1.383-13.143h2.02A.75.75 0 1 0 22.5 4.75"/></svg>)}
    />
  );
}
  