// @flow

import { CancelToken, type CancelTokenSource } from 'axios';
import React, { Component } from 'react';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import {
  operations as departmentOps,
  selectors as selectDepartments
} from '../../../ducks/entities/department/index';
import AddDepartmentPeopleForm from './AddDepartmentPeopleForm';
import type { StoreStateT, KeyValuePairT } from '../../../commonTypes';
import type { IdTreeT } from '../../../ducks/entities/department/departmentTypes';
import type { CreateDepartmentFnT } from '../../../ducks/entities/department/departmentOperations';
import { operations as userOps, selectors as userSelect } from '../../../ducks/entities/user';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';
import { asyncPool, fetchExtensionIdsWithAddressNumbers } from '../../../helpers';
import DepartmentComboboxItem from './DepartmentComboboxItem';
import { IdTreeCombobox, InputField } from '../../../components/InputComponent/ReduxFormField';
import ActionButton from '../../../components/Button/ActionButton';
import CancelButton from '../../../components/Button/CancelButton';
import fieldValidators from '../../../fieldValidators';
import { BaseModal } from '../../../components/BaseModal';
import type { MoveStatusT } from './MoveUserIndicator';
import MoveUserIndicator from './MoveUserIndicator';
import {
  actionCreators as departmentUiActions,
  selectors as departmentUiSelect
} from '../../../ducks/ui/department';
import type { DirectoryStateEntityT } from '../../../ducks/entities/directory';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import styles from './CreateDepartmentModal.module.scss';

export type OwnPropsT = {
  onClose: () => *,
  enterpriseId: ?string,
  initialParentDepartmentId: ?string
};

type StatePropsT = {
  currentUser: CurrentUserStateT,
  updatedDepartmentName: string,
  departmentCreated: boolean,
  departmentIdTree: IdTreeT,
  selectedParentDepartment: KeyValuePairT<>,
  initialValues: {
    createDepartment: {
      nameField: string,
      departmentSelection: KeyValuePairT<>
    }
  },
  enterpriseUsers: InternalUserStateEntityT[],
  selectedUsers: *
};

type DispatchPropsT = {
  createDepartment: CreateDepartmentFnT,
  retrieveCompleteCollection: typeof userOps.retrieveCompleteCollection,
  updateUser: typeof userOps.update,
  selectDepartmentUsers: typeof departmentUiActions.createSelectDepartmentUsers
};

type StateT = {
  isSaving: boolean,
  saveError: boolean,
  isMovingUsers: boolean,
  totalNumberOfPeopleToBeMoved: number,
  successfullyMovedPeople: number,
  targetDepartmentId: string,
  unSuccessfullyMovedPeople: DirectoryStateEntityT[],
  moveStatus: ?MoveStatusT
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export const ROOT_DEPARTMENT_KEY = '__root';

export class CreateDepartmentModal extends Component<PropsT, StateT> {
  static disableBackgroundScrolling() {
    // $FlowFixMe  disable background scrolling
    document.body.style.overflow = 'hidden';
  }

  static restoreBackgroundScrolling() {
    // $FlowFixMe  restore background scrolling
    document.body.style.overflow = 'auto';
  }

  constructor(props: PropsT) {
    super(props);
    this.createDepartment = this.createDepartment.bind(this);
    this.validateDepartmentName = this.validateDepartmentName.bind(this);
    this.updateUsers = this.updateUsers.bind(this);
    this.cancelMovingUsers = this.cancelMovingUsers.bind(this);
    this.createUpdateUsersPayload = this.createUpdateUsersPayload.bind(this);
    this.requestCancelTokenSource = CancelToken.source();
    this.updateUserCancelTokenSource = CancelToken.source();
  }

  state = {
    isSaving: false,
    saveError: false,
    isMovingUsers: false,
    totalNumberOfPeopleToBeMoved: 0,
    successfullyMovedPeople: 0,
    unSuccessfullyMovedPeople: [],
    moveStatus: undefined,
    targetDepartmentId: ''
  };

  componentDidMount() {
    const { enterpriseId, retrieveCompleteCollection } = this.props;
    if (enterpriseId) {
      retrieveCompleteCollection({ enterpriseId }, this.requestCancelTokenSource.token);
    }
    CreateDepartmentModal.disableBackgroundScrolling();
  }

  componentWillUnmount() {
    this.requestCancelTokenSource.cancel();
    this.updateUserCancelTokenSource.cancel();
    CreateDepartmentModal.restoreBackgroundScrolling();
  }

  createUpdateUsersPayload: (?string) => *;

  async createUpdateUsersPayload(departmentId: ?string) {
    const { selectedUsers, enterpriseId, currentUser } = this.props;

    const { targetDepartmentId } = this.state;
    if (enterpriseId) {
      const extensionIds = await fetchExtensionIdsWithAddressNumbers(
        enterpriseId,
        selectedUsers.map(user => (user && user.publicInfo ? user.publicInfo.addressNumber : '')),
        this.requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      );
      if (extensionIds.length > 0 && !extensionIds.includes('')) {
        // $FlowFixMe
        return extensionIds.map((extensionId, index) => ({
          id: extensionId,
          enterpriseId,
          personId: selectedUsers[index].internalAddressId,
          userType: 'internalUser',
          department: { id: departmentId || targetDepartmentId },
          departmentId: departmentId || targetDepartmentId
        }));
      }
    }
    return null;
  }

  updateUsers: (?string) => void;

  async updateUsers(departmentId: ?string) {
    const { unSuccessfullyMovedPeople, targetDepartmentId } = this.state;
    const {
      updateUser,
      selectedUsers,
      enterpriseId,
      selectDepartmentUsers,
      currentUser
    } = this.props;

    let fetchingExtensionIdsFailed = false;
    let notMovedPeople = [];
    this.setState({
      isMovingUsers: true,
      totalNumberOfPeopleToBeMoved: selectedUsers.length,
      moveStatus: undefined
    });
    if (departmentId) {
      this.setState({
        targetDepartmentId: departmentId
      });
    }
    const countAndUpdateUser = async user => {
      const response = await updateUser(
        user,
        this.updateUserCancelTokenSource.token,
        createCsrfHeader(currentUser)
      );
      if (!response) {
        notMovedPeople = [...notMovedPeople, user];
      } else {
        this.setState(state => ({
          successfullyMovedPeople: state.successfullyMovedPeople + 1
        }));
      }
    };
    if (unSuccessfullyMovedPeople.length === 0) {
      const updateUsersPayload = await this.createUpdateUsersPayload(departmentId);
      if (updateUsersPayload) {
        await asyncPool(4, updateUsersPayload, countAndUpdateUser);
      } else {
        fetchingExtensionIdsFailed = true;
      }
    } else {
      await asyncPool(4, unSuccessfullyMovedPeople, countAndUpdateUser);
    }
    this.setState({
      unSuccessfullyMovedPeople: notMovedPeople
    });
    if (!fetchingExtensionIdsFailed && notMovedPeople.length === 0) {
      this.setState({
        isMovingUsers: false,
        moveStatus: 'success'
      });
    } else {
      if (!fetchingExtensionIdsFailed && enterpriseId) {
        selectDepartmentUsers(
          enterpriseId,
          departmentId || targetDepartmentId,
          unSuccessfullyMovedPeople
        );
      }
      this.setState({
        isMovingUsers: false,
        moveStatus: 'failed'
      });
    }
  }

  createDepartment: () => Promise<void>;

  async createDepartment(): Promise<void> {
    const {
      updatedDepartmentName,
      enterpriseId,
      createDepartment,
      selectedParentDepartment,
      onClose,
      selectedUsers,
      currentUser
    } = this.props;
    if (enterpriseId) {
      this.setState({ isSaving: true, saveError: false });

      const createdDepartment = await createDepartment(
        enterpriseId,
        updatedDepartmentName,
        selectedParentDepartment.value || ROOT_DEPARTMENT_KEY,
        createCsrfHeader(currentUser)
      );

      if (createdDepartment) {
        this.setState({ isSaving: false, saveError: false });
        if (selectedUsers.length > 0) {
          this.updateUsers(createdDepartment.id);
        } else {
          onClose();
        }
      } else {
        this.setState({ isSaving: false, saveError: true });
      }
    }
  }

  validateDepartmentName: string => ?string;

  validateDepartmentName(value: string): ?string {
    return (
      fieldValidators.requiredValidator(value, this.props.t('generic.validators.required')) ||
      fieldValidators.departmentNameFieldValidator(
        value,
        this.props.t('generic.validators.departmentName')
      )
    );
  }

  requestCancelTokenSource: CancelTokenSource;

  updateUserCancelTokenSource: CancelTokenSource;

  cancelMovingUsers: () => void;

  cancelMovingUsers() {
    this.requestCancelTokenSource.cancel();
    this.updateUserCancelTokenSource.cancel();
    this.props.onClose();
  }

  render() {
    const { onClose, t, updatedDepartmentName, enterpriseId } = this.props;
    const {
      isSaving,
      saveError,
      isMovingUsers,
      successfullyMovedPeople,
      moveStatus,
      totalNumberOfPeopleToBeMoved
    } = this.state;

    const title = (
      <h4 className={styles['section-title']}>{t('enterprise.createDepartment.topic')}</h4>
    );
    const moveUserIndicator = (
      <div className={styles['move-user-indicator']}>
        <MoveUserIndicator
          isMovingUsers={isMovingUsers}
          moveStatus={moveStatus}
          successfullyMovedPeople={successfullyMovedPeople}
          totalNumberOfPeopleToBeMoved={totalNumberOfPeopleToBeMoved}
          movePeopleSuccessfulAction={onClose}
          movePeopleTryAgainAction={() => this.updateUsers()}
          createDepartment
          cancelMovingUsers={this.cancelMovingUsers}
        />
      </div>
    );
    const createDepartment = (
      <div>
        <div className={styles.input}>
          <FormSection name="createDepartment">
            <Field
              id="create-department-name-field"
              label={t('enterprise.createDepartment.nameLabel')}
              name="nameField"
              autoFocus
              className={styles['name-field']}
              component={InputField}
              validate={this.validateDepartmentName}
            />
            <Field
              id="create-department-department-selection"
              label={t('enterprise.createDepartment.selectParentTitle')}
              name="departmentSelection"
              style={{ paddingTop: '20px', width: '395px' }}
              options={this.props.departmentIdTree}
              // $FlowFixMe
              component={props => (
                <IdTreeCombobox
                  {...props}
                  disabled={this.props.departmentIdTree.length === 0}
                  hasRootOption
                  rootOptionKey={ROOT_DEPARTMENT_KEY}
                  renderItem={p => <DepartmentComboboxItem {...p} />}
                />
              )}
            />
          </FormSection>
        </div>
      </div>
    );
    const userSelector = (
      <div className={styles['user-selector-container']} id="user-selector">
        <div className={styles['user-selector-title']}>
          {t('enterprise.createDepartment.selectUsers')}
        </div>
        <AddDepartmentPeopleForm
          enterpriseId={enterpriseId || ''}
          initialSelectedUsers={[]}
          departmentId="new_department"
        />
      </div>
    );
    const buttons = (
      <div className={styles['button-container']}>
        <ActionButton
          id="create-department-save-button"
          label={t('enterprise.createDepartment.saveButtonLabel')}
          onClickAction={this.createDepartment}
          disabled={this.validateDepartmentName(updatedDepartmentName) !== undefined}
          loading={isSaving}
        />
        <CancelButton
          id="create-department-close-button"
          className={styles['close-button']}
          label={t('enterprise.createDepartment.closeButtonLabel')}
          onClickAction={onClose}
          disabled={this.validateDepartmentName(updatedDepartmentName) !== undefined}
        />
      </div>
    );

    const departmentSaveError = (
      <div className={styles['save-error']} id="department-create-error" role="status">
        {t('enterprise.createDepartment.departmentCreateError')}
      </div>
    );

    const showMoveIndicator = isMovingUsers || moveStatus !== undefined;

    return (
      <BaseModal modalStyles={[styles.modal]} onClose={onClose}>
        <div>
          {title}
          {showMoveIndicator && moveUserIndicator}
          {!showMoveIndicator && (
            <>
              {createDepartment}
              {userSelector}
              {buttons}
              {saveError && departmentSaveError}
            </>
          )}
        </div>
      </BaseModal>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: PropsT) => {
  const { t } = ownProps;
  const selectFromForm = formValueSelector('departmentCreate');
  const selectedUsers = departmentUiSelect.selectedUsersByEnterpriseAndDepartment(
    state,
    ownProps.enterpriseId || '',
    'new_department'
  );
  return {
    currentUser: state.currentUser,
    initialValues: {
      createDepartment: {
        nameField: '',
        departmentSelection: {
          value: ownProps.initialParentDepartmentId || ROOT_DEPARTMENT_KEY
        }
      }
    },
    t,
    departmentCreated: selectDepartments.createdDepartmentSuccessfully(state),
    updatedDepartmentName: selectFromForm(state, 'createDepartment.nameField'),
    departmentIdTree: ownProps.enterpriseId
      ? state.entities.department.idTrees[ownProps.enterpriseId]
      : [],
    selectedParentDepartment: selectFromForm(state, 'createDepartment.departmentSelection'),
    enterpriseUsers: ownProps.enterpriseId
      ? userSelect.byEnterpriseId(state, ownProps.enterpriseId)
      : [],
    selectedUsers
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createDepartment: departmentOps.createDepartment,
      retrieveCompleteCollection: userOps.retrieveCompleteCollection,
      updateUser: userOps.update,
      selectDepartmentUsers: departmentUiActions.createSelectDepartmentUsers
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(
    // $FlowFixMe
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({ form: 'departmentCreate', enableReinitialize: true })
)(CreateDepartmentModal);
