import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLockRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 8.8h-.7V6.2C18.3 2.8 15.5 0 12 0S5.8 2.8 5.8 6.2v2.5H5c-1.2 0-2.2 1-2.2 2.2v10.8C2.8 23 3.8 24 5 24h14c1.2 0 2.2-1 2.2-2.2V11c0-1.2-1-2.2-2.2-2.2M7.2 6.2c0-2.6 2.1-4.8 4.8-4.8s4.8 2.1 4.8 4.8v2.5H7.2zm12.6 15.6c0 .4-.3.8-.8.8H5c-.4 0-.8-.4-.8-.8V11c0-.4.3-.7.8-.8h14c.4 0 .8.3.8.8zm-7-6.8v3c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-3c0-.4.3-.8.8-.8s.8.4.8.8"/></svg>)}
    />
  );
}
  