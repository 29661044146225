// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Requirement from './Requirement';
import fieldValidators from '../../fieldValidators';
import styles from './UserPasswordRequirements.module.scss';

type PropsT = {|
  password: string
|};

export const UserPasswordRequirements = (props: PropsT) => {
  const { password } = props;
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('password.user.passwordReset.requirement.title')}</h4>
      <div className={styles.requirements}>
        <Requirement
          text={t('password.user.passwordReset.requirement.lengthRule')}
          checked={(password || '').length >= 6 && (password || '').length <= 20}
          isEmpty={!password}
        />
        <Requirement
          text={t('password.user.passwordReset.requirement.onlyNumbersRule')}
          checked={fieldValidators.containsOnlyNumbers(password)}
          isEmpty={!password}
        />
        <Requirement
          text={parse(
            `${t(
              'password.user.passwordReset.requirement.sequenceNumberRule1'
            )}<br/>&nbsp;&nbsp;${t('password.user.passwordReset.requirement.sequenceNumberRule2')}`
          )}
          checked={!!password && !fieldValidators.isSequentialNumber(password)}
          isEmpty={!password}
        />
        <Requirement
          text={parse(
            `${t(
              'password.user.passwordReset.requirement.repeatingNumberRule1'
            )}<br/>&nbsp;&nbsp;${t('password.user.passwordReset.requirement.repeatingNumberRule2')}`
          )}
          checked={!!password && !fieldValidators.isContinuingSameNumberMaxTimes(password, 6)}
          isEmpty={!password}
        />
      </div>
    </>
  );
};

export default UserPasswordRequirements;
