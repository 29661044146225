import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWebsiteRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 1.75H2.25A2.25 2.25 0 0 0 0 4v16a2.25 2.25 0 0 0 2.25 2.25h19.5A2.25 2.25 0 0 0 24 20V4a2.25 2.25 0 0 0-2.25-2.25m-19.5 1.5h19.5a.76.76 0 0 1 .75.75v3.75h-21V4a.76.76 0 0 1 .75-.75m19.5 17.5H2.25A.76.76 0 0 1 1.5 20V9.25h21V20a.76.76 0 0 1-.75.75M3.75 6.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/></svg>)}
    />
  );
}
  