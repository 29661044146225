// @flow strict-local

import * as R from 'ramda';
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from '../../../../../assets/callflow/details/cf-acd-or-ext-group-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../components/view/children/CallflowItem';
import styles from './OverflowDetails.module.scss';

export type PropsT = {|
  callflowId: string
|};

export const OverflowDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_overflow_${acdData.id}`}
      nodeType="ACD_OVERFLOW"
      icon={<Icon />}
      title={t('callflows.viewAcdOverflow.title')}
      active
      hideButton={acdData.ringPattern === 'unisson'}
    >
      {acdData.ringPattern !== 'unisson' ? (
        <CallflowDetailsColumn id="acd-overflow-column-1">
          <CallflowItem
            id="overflowGroup"
            title={t('callflows.viewAcdOverflow.overflowGroup')}
            value={
              acdData.overflowGroup
                ? // $FlowFixMe no typing support for pathOr-function
                  `${R.pathOr(
                    t('callflows.unnamedGroup'),
                    ['overflowGroup', 'label'],
                    acdData
                  ).replace(/^kutsu:/, '')}${
                    acdData.overflowGroup ? ` (${acdData.overflowGroup.addressNumber})` : ''
                  }`
                : t('callflows.viewAcdOverflow.noValue')
            }
          />
        </CallflowDetailsColumn>
      ) : (
        <div className={styles['description-area']}>
          {t('callflows.viewAcdOverflow.disabledBecauseUnisson')}
        </div>
      )}
    </CallflowDetails>
  );
};

export default OverflowDetails;
