// @flow

/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */

import type { LoginStatusT } from './currentUserTypes';
import type { StoreStateT } from '../../commonTypes';

type GetLoginStatusFnT = StoreStateT => LoginStatusT;
export const getLoginStatus: GetLoginStatusFnT = ({ currentUser }) => currentUser.__metadata.status;

type HasSessionFnT = StoreStateT => boolean;
export const hasSession: HasSessionFnT = state =>
  typeof state.currentUser.login !== 'undefined' &&
  typeof state.currentUser.sessionId !== 'undefined' &&
  (getLoginStatus(state) === 'logged in' || getLoginStatus(state) === 'logging out');
