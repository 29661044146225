import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCameraRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m21.505 21.25-19.122-.004c-1.43-.1-2.472-1.298-2.376-2.674L.005 8.202c0-.924.357-2.473 2.747-2.473h5.755c.114 0 .184-.056.458-.447l.179-.252c1.04-1.437 1.666-2.301 2.866-2.301h5c1.547 0 2.251 1.484 2.872 2.794.051.129.147.202.266.206h1.047C23.636 5.729 24 7.278 24 8.202c-.001 10.76-.01 10.82-.02 10.888a2.51 2.51 0 0 1-2.475 2.16M2.752 7.229c-1.158 0-1.247.403-1.247.973l-.001 10.422c-.04.604.378 1.085.932 1.124l19.069.002c.497 0 .923-.372.99-.866.002-.229.005-7.793.005-10.682 0-.551-.096-.973-1.306-.973H20.12c-.74-.026-1.38-.478-1.619-1.124-.609-1.278-.979-1.875-1.491-1.875h-5c-.38 0-.693.357-1.647 1.675l-.171.241c-.3.427-.757 1.083-1.685 1.083zM4 11c-.689 0-1.25-.561-1.25-1.25S3.311 8.5 4 8.5s1.25.561 1.25 1.25S4.689 11 4 11m11.004 7.739a5.756 5.756 0 0 1-5.749-5.75c0-3.17 2.579-5.75 5.749-5.75 3.171 0 5.75 2.58 5.75 5.75a5.756 5.756 0 0 1-5.75 5.75m0-10a4.255 4.255 0 0 0-4.249 4.25 4.255 4.255 0 0 0 4.249 4.25 4.254 4.254 0 0 0 4.25-4.25 4.255 4.255 0 0 0-4.25-4.25"/></svg>)}
    />
  );
}
  