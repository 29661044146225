import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEuroFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m1.5 9.875a.625.625 0 0 1 0 1.25H7.198c-.044.286-.073.577-.073.875s.029.589.073.875H13.5a.625.625 0 0 1 0 1.25H7.529c.854 2.191 2.982 3.75 5.471 3.75a5.86 5.86 0 0 0 3.163-.923.626.626 0 0 1 .674 1.052A7.1 7.1 0 0 1 13 19.124c-3.189 0-5.895-2.106-6.801-5H4.5a.625.625 0 0 1 0-1.25h1.434c-.035-.286-.059-.577-.059-.874s.024-.588.059-.875H4.5a.625.625 0 0 1 0-1.25h1.699c.906-2.894 3.612-5 6.801-5 1.365 0 2.691.388 3.837 1.121a.624.624 0 1 1-.674 1.052A5.85 5.85 0 0 0 13 6.125c-2.489 0-4.617 1.559-5.471 3.75z"/></svg>)}
    />
  );
}
  