import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDraftRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.25 2.25A2.25 2.25 0 0 0 19 0H5a2.25 2.25 0 0 0-2.25 2.25v19.5A2.25 2.25 0 0 0 5 24h9.09c.238.001.474-.05.69-.15.197-.082.377-.201.53-.35l5.41-5.42c.331-.327.515-.775.51-1.24zm-17 19.5V2.25A.75.75 0 0 1 5 1.5h14a.76.76 0 0 1 .75.75V16.5H15.5a1.75 1.75 0 0 0-1.75 1.75v4.25H5a.75.75 0 0 1-.75-.75m11-.3v-3.2a.25.25 0 0 1 .25-.25h3.19z"/></svg>)}
    />
  );
}
  