import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCartFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 20.5c0 1.378-1.121 2.5-2.5 2.5s-2.5-1.122-2.5-2.5S15.621 18 17 18s2.5 1.122 2.5 2.5M10 18c-1.379 0-2.5 1.122-2.5 2.5S8.621 23 10 23s2.5-1.122 2.5-2.5S11.379 18 10 18M23.103 6.053a.75.75 0 0 0-.603-.303H5.693a.5.5 0 0 1-.479-.358l-.706-2.376A3.05 3.05 0 0 0 1.563.75H.75a.75.75 0 0 0 0 1.5h.813c.701 0 1.315.473 1.502 1.171l3.308 11.133A2.286 2.286 0 0 0 8.58 16.25h10.058a2.28 2.28 0 0 0 2.2-1.671l2.381-7.861a.76.76 0 0 0-.116-.665"/></svg>)}
    />
  );
}
  