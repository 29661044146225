import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import UsernamePassword, { validate as validateUsernamePassword } from './views/UsernamePassword';
import Auth2Factor, { validate as validateAuth2Factor } from './views/Auth2Factor';
import MobilePhone, { validate as validateMobilePhone } from './views/MobilePhone';
import MobilePhonePolling from './views/MobilePhonePolling';
import StrongIdentification, { validate as validateStrongIdentification } from './views/StrongIdentification';

import LoginTypesSwitcher from './elements/LoginTypesSwitcher';
import LoginHelp from './elements/LoginHelp';
import LoginCreate from './elements/LoginCreate';
import ErrorMessage from './elements/ErrorMessage';
import translations from './translations';

import { classNames } from '../../utils/css';

import styles from './Login.module.scss';

const MOBILE_AUTH_TIMEOUT_SECONDS = 240;

const loginViews = {
  AUTH_USERNAME_PASSWORD: UsernamePassword,
  AUTH_2_FACTOR: Auth2Factor,
  AUTH_MOBILE_PHONE: MobilePhone,
  AUTH_MOBILE_PHONE_POLLING: MobilePhonePolling,
  AUTH_STRONG_IDENTIFICATION: StrongIdentification,
};

const loginTypes = {
  AUTH_USERNAME_PASSWORD: 'AUTH_USERNAME_PASSWORD',
  AUTH_2_FACTOR: 'AUTH_2_FACTOR',
  AUTH_MOBILE_PHONE: 'AUTH_MOBILE_PHONE',
  AUTH_MOBILE_PHONE_POLLING: 'AUTH_MOBILE_PHONE_POLLING',
  AUTH_STRONG_IDENTIFICATION: 'AUTH_STRONG_IDENTIFICATION',
};

const defaultFtnRedirectUrl = () => {
  let { protocol, hostname, port } = document.location;

  if (+port === 80 || +port === 443) {
    port = '';
  }
  if (port) {
    port = `:${`${port}`.replace(/:/g, '')}`;
  }
  protocol = protocol.replace(/[:\/]/g, '');
  return `${protocol}://${hostname}${port}`;
};

class Login extends React.Component {
  static propTypes = {
    /** Element's id */
    id: PropTypes.string,
    /** Any CSS classes for the component */
    className: PropTypes.string,
    /** Configuration object with handlers */
    config: PropTypes.shape({
      request2fa: PropTypes.func.isRequired,
      resolve2fa: PropTypes.func.isRequired,
      authUsernamePassword: PropTypes.func.isRequired,
      cancelMobileAuthentication: PropTypes.func.isRequired,
      pollMobileAuthentication: PropTypes.func.isRequired,
      authMobilePhone: PropTypes.func.isRequired,
      authFtn: PropTypes.func.isRequired,
      onLoginSuccess: PropTypes.func.isRequired,
    }).isRequired,
    /** Whether the first input field is automatically focused or not */
    autofocus: PropTypes.bool,
    /** Initial error message for login */
    errorMessageText: PropTypes.string,
    /** First visible view after the login is created */
    firstView: PropTypes.oneOf(Object.keys(loginTypes)),
    /** Should the business ID be asked when user uses bank authentication. This ensures that the user has signatory rights to the company. */
    enableYTunnus: PropTypes.bool,
    /** Login types that are available for the user */
    enabledLoginTypes: PropTypes.array,
    /** Initial value for username field */
    usernameValue: PropTypes.string,
    /** Initial value for 2-factor code field */
    auth2FactorValue: PropTypes.string,
    /** Initial value for 2-factor device should be remembered checkbox */
    deviceShouldBeRememberedValue: PropTypes.bool,
    /** Initial value for password field */
    passwordValue: PropTypes.string,
    /** URL for upper link below the authentication view */
    loginHelpUrl: PropTypes.string,
    /** Text for upper link below the authentication view */
    loginHelpHandler: PropTypes.func,
    /** URL for lower link below the authentication view */
    createLoginUrl: PropTypes.string,
    /** Callback for lower link below the authentication view */
    createLoginHandler: PropTypes.func,

    /** Banks enabled for bank authentication. Each array inside the main array creates a new line on the bank grid. */
    banks: PropTypes.arrayOf(PropTypes.array),

    /** Where to redirect after bank authentication is successful and Elisa Id
        sso session has been created based on it. The redirect url should
        be able to detect sso session and create service based session from it
        Default: Origin url of the page */
    ftnRedirectUrl: PropTypes.string,

    /**  Mobiilivarmenne auth view props */
    /** Url to register Mobile ID. Shown to user when he tries to login without Mobile ID */
    registerMobileIdUrl: PropTypes.string,

    /** Value for phone number field */
    phoneNumberValue: PropTypes.string,
    /** Analytics callback that is called when various events happen */
    trackEventCallback: PropTypes.func,
    /** Flag to show spam-code after mobile phone logging in or not */
    showSpamCode: PropTypes.bool,
    /** Initial value for spam code field */
    spamCodeValue: PropTypes.string,
    mobileAuthEventId: PropTypes.string,
    /** Callback for calceling mobile polling */
    cancelMobileAuthPolling: PropTypes.func.isRequired,
    /** Value for Y-Tunnus field */
    ytunnusValue: PropTypes.string,

    /** For testing only */
    censoredMsisdnDefaultValue: PropTypes.string,
    twoFactorAuthenticationIdDefaultValue: PropTypes.string,

    /** i18n properties */
    /** Component default language */
    language: PropTypes.string,

    /** Header text above the login form */
    i18n_login_header: PropTypes.string,
    /** Header above the form for username/password login */
    i18n_login_header_usernamePassword: PropTypes.string,

    /** Label for username field */
    i18n_login_usernameLabel: PropTypes.string,
    /** Placeholder for username field */
    i18n_login_usernamePlaceholder: PropTypes.string,
    /** Label for password field */
    i18n_login_passwordLabel: PropTypes.string,
    /** Placeholder for password field */
    i18n_login_passwordPlaceholder: PropTypes.string,
    /** Text for login button */
    i18n_login_loginButtonLabel: PropTypes.string,

    /** Text for alternative login/authentication methods. This text is located between the view and buttons that change the view. */
    i18n_login_altLoginText: PropTypes.string,
    /** Text for button that takes the user to Elisa Tunnus authentication view. */
    i18n_login_elisatunnusMethodButtonText: PropTypes.string,
    /** Aria label text for button that takes the user to Elisa Tunnus authentication view. Used by screen readers. */
    i18n_login_elisatunnusMethodButtonAltText: PropTypes.string,
    /** Text for button that takes the user to Mobiilivarmenne authentication view. */
    i18n_login_mobiilivarmenneMethodButtonText: PropTypes.string,
    /** Aria label text for button that takes the user to Mobiilivarmenne authentication view. Used by screen readers. */
    i18n_login_mobiilivarmenneMethodButtonAltText: PropTypes.string,
    /** Text for button that takes the user to bank authentication view. */
    i18n_login_bankauthMethodButtonText: PropTypes.string,
    /** Aria label text for button that takes the user to bank authentication view. Used by screen readers. */
    i18n_login_bankauthMethodButtonAltText: PropTypes.string,

    /** Text exaplaining 2-factor login */
    i18n_login_auth2FactorText: PropTypes.string,
    /** Placeholder for 2-factor code field */
    i18n_login_auth2FactorPlaceholder: PropTypes.string,
    /** Label for 2-factor code field */
    i18n_login_auth2FactorLabel: PropTypes.string,
    /** Text on link for seinding 2-factor code again */
    i18n_login_auth2FactorNewCodeText: PropTypes.string,
    /** Label for 2-factor "device should be remembered" checkbox */
    i18n_login_auth2FactorDeviceShouldBeRememberedLabel: PropTypes.string,
    /** Text for invalid 2-factor code */
    i18n_login_errorAuth2FactorInvalidCode: PropTypes.string,

    /** Header above the form for mobile-phone login */
    i18n_login_header_mobilePhone: PropTypes.string,
    /** Label for a phone number field */
    i18n_login_phoneNumberLabel: PropTypes.string,
    /** Placeholder for a phone number field */
    i18n_login_phoneNumberPlaceholder: PropTypes.string,
    /** Text for login button on mobile-phone login second screen */
    i18n_login_loginButtonLabel_mobilePhone: PropTypes.string,
    /** Label for a spam code field */
    i18n_login_spamCodeLabel: PropTypes.string,
    /** Placeholder for a spam code field */
    i18n_login_spamCodePlaceholder: PropTypes.string,
    /** Text on the mobile polling view */
    i18n_login_mobilePollingText: PropTypes.string,
    /** Text on link to cancel login */
    i18n_login_mobilePollingCancelLogin: PropTypes.string,
    /** Text on link to register Mobile ID */
    i18n_login_mobileRegisterLinkText: PropTypes.string,

    /** Header above the form for mobile-phone logingit */
    i18n_login_header_strongIdentification: PropTypes.string,
    /** Label for Y-Tunnus field */
    i18n_login_ytunnusLabel: PropTypes.string,
    /** Placeholder for Y-Tunnus field */
    i18n_login_ytunnusPlaceholder: PropTypes.string,
    /** Text for a link to change Y-Tunnus */
    i18n_login_ytunnusChangeText: PropTypes.string,
    /** Text on a button to continue strong identification */
    i18n_login_buttonContinue: PropTypes.string,
    /** Text before business id */
    i18n_login_ytynnysText: PropTypes.string,

    /** Text for the forgotten password link */
    i18n_login_loginHelpText: PropTypes.string,
    /** Text for lower link below the authentication view */
    i18n_login_createLoginText: PropTypes.string,

    /** Text for required username error */
    i18n_login_errorUsernameRequired: PropTypes.string,
    /** Text for required password error */
    i18n_login_errorPasswordRequired: PropTypes.string,
    /** Text for required 2-factor code error */
    i18n_login_errorAuth2FactorRequired: PropTypes.string,
    /** Text for required phone number error */
    i18n_login_errorPhoneNumberRequired: PropTypes.string,
    /** Text for invalid format of phone number */
    i18n_login_errorPhoneNumberInvalid: PropTypes.string,
    /** Text for required Y-Tunnus error */
    i18n_login_errorYTunnusRequired: PropTypes.string,
    /** Text for invalid format of Y-Tunnus */
    i18n_login_errorYTunnusInvalid: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    className: null,
    errorMessageText: null,
    firstView: 'AUTH_USERNAME_PASSWORD',
    autofocus: true,
    enableYTunnus: false,
    enabledLoginTypes: Object.keys(loginTypes),
    usernameValue: '',
    auth2FactorValue: '',
    deviceShouldBeRememberedValue: false,
    passwordValue: '',
    loginHelpUrl: '#',
    loginHelpHandler: () => {},
    createLoginUrl: '#',
    registerMobileIdUrl: 'https://varmenne.elisa.fi',
    banks: [['op', 'nordea', 'danskebank', 'handelsbanken', 'alandsbanken', 's-pankki', 'aktia', 'pop-pankki', 'saastopankki', 'omasp']],
    censoredMsisdnDefaultValue: null,
    twoFactorAuthenticationIdDefaultValue: null,
    trackEventCallback: (action, description, nonInteraction) => {},
    /* Mobiilivarmenne auth view props */
    phoneNumberValue: '',
    showSpamCode: false,
    spamCodeValue: '',
    mobileAuthEventId: '',
    ytunnusValue: '',
    cancelMobileAuthPolling: () => {},
    ftnRedirectUrl: '',
    /** default language */
    language: 'fi',
  };

  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`ds${this.constructor.name}`);

    this.state = {
      values: {
        username: props.usernameValue,
        password: props.passwordValue,
        authcode: props.auth2FactorValue,
        ytunnus: props.ytunnusValue,
        spamcode: props.spamCodeValue,
        phonenumber: props.phoneNumberValue,
        deviceshouldberemembered: props.deviceShouldBeRememberedValue,
        bank: null,
        useYTunnus: false,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      username: '',
      password: '',
      usernameActive: false,
      passwordActive: false,
      inputType: 'password',
      currentView: props.firstView,
      spamcode: '',
      phonenumber: '',
      showSpamCode: props.showSpamCode,
      mobileAuthEventId: props.mobileAuthEventId,
      mobileAuthToken: '',
      twoFactorAuthenticationId: this.props.twoFactorAuthenticationIdDefaultValue,
      censoredMsisdn: this.props.censoredMsisdnDefaultValue,
    };

    this.translations = this.getTranslationsFromPropertiesOrDefault(this.props.language);
    this.pollingSessionId = 1;
    this.switchView = this.switchView.bind(this);
    this.cancelMobileAuthPolling = this.cancelMobileAuthPolling.bind(this);
    this.auth2FactorNewCodeClick = this.auth2FactorNewCodeClick.bind(this);
  }

  cancelMobileAuthPolling() {
    this.props.trackEventCallback('Mobile login cancel button clicked');
    this.pollingSessionId++;
    if (this.state.mobileAuthToken) {
      this.props.config.cancelMobileAuthentication(this.state.mobileAuthToken);
    }
    this.setState({
      currentView: 'AUTH_MOBILE_PHONE',
      mobileAuthEventId: '',
      mobileAuthToken: '',
    });
  }

  /* gets component texts from i18n properties or from default translations */
  getTranslationsFromPropertiesOrDefault(language) {
    const translation = translations[language];

    return Object.keys(translation).reduce((texts, tKey) => {
      // eslint-disable-next-line no-param-reassign
      texts[tKey] = this.props[tKey] || translation[tKey];
      return texts;
    }, {});
  }

  switchView(nextView) {
    this.setState({
      errorMessageText: '',
      currentView: nextView,
      isSubmitting: false,
    });
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  loginHelpClick(e) {
    e.preventDefault();
    this.props.loginHelpHandler();
    if (this.props.loginHelpUrl !== '' && this.props.loginHelpUrl !== '#') {
      window.location.href = this.props.loginHelpUrl;
    }
  }

  async auth2FactorNewCodeClick(e, username) {
    e.preventDefault();
    const { config, trackEventCallback } = this.props;
    const twoFactorAuthRes = await config.request2fa(username, trackEventCallback);

    const { twoFactorAuthenticationId, censoredMsisdn } = twoFactorAuthRes;
    this.setState({
      censoredMsisdn,
      twoFactorAuthenticationId,
    });
  }

  createLoginClick(e) {
    e.preventDefault();
    if (this.props.createLoginUrl !== '' && this.props.createLoginUrl !== '#') {
      window.location.href = this.props.createLoginUrl;
    }
  }

  handleChange(e) {
    const stateTouched = this.state.touched;
    if (this.state.values[e.target.name] !== e.target.value) {
      stateTouched[e.target.name] = true;
    }

    const stateValues = this.state.values;

    switch (e.target.type) {
      case 'checkbox':
        stateValues[e.target.name] = e.target.checked;
        break;
      default:
        stateValues[e.target.name] = e.target.value;
        break;
    }

    this.setState({ values: stateValues, touched: stateTouched });

    this.validate();
  }

  handleBlur(e) {}

  handleSubmit(e) {
    e.preventDefault();

    const errors = this.validate();

    // Set the fields with errors touched to make the error texts visible
    const touched = Object.keys(errors).reduce((touched, errKey) => {
      touched[errKey] = true;
      return touched;
    }, {});

    this.setState({ errors, touched });
    if (Object.keys(errors).length === 0) {
      return this.onSubmit();
    }
  }

  submitForm() {
    this.onSubmit();
  }

  setFieldValue(field, value) {
    const stateValues = this.state.values;
    stateValues[field] = value;
    this.setState({
      values: stateValues,
    });
  }

  onSubmit = async () => {
    const { config, trackEventCallback, ftnRedirectUrl } = this.props;
    const { values } = this.state;

    let res = {};

    switch (this.state.currentView) {
      case loginTypes.AUTH_USERNAME_PASSWORD:
        try {
          this.setSubmitting(true);
          res = await config.authUsernamePassword(values.username, values.password, this.state.twoFactorAuthenticationId, trackEventCallback);
        } catch (e) {
          res = e;
        }
        try {
          if (res.code === 'TWO_FACTOR_AUTH_REQUIRED') {
            const twoFactorAuthRes = await config.request2fa(values.username, trackEventCallback);
            const { twoFactorAuthenticationId, censoredMsisdn } = twoFactorAuthRes;
            this.setState({
              censoredMsisdn,
              twoFactorAuthenticationId,
            });
            this.switchView('AUTH_2_FACTOR');
            return;
          }
        } catch (e) {
          res = e;
        }
        break;
      case loginTypes.AUTH_2_FACTOR:
        try {
          this.setSubmitting(true);
          res = await config.resolve2fa(values.username, values.password, values.authcode, this.state.twoFactorAuthenticationId, values.deviceshouldberemembered, trackEventCallback);
        } catch (e) {
          res = e;
        }
        break;
      case loginTypes.AUTH_MOBILE_PHONE:
        try {
          this.setSubmitting(true);
          res = await config.authMobilePhone(values.phonenumber, values.spamcode, trackEventCallback);
        } catch (e) {
          res = e;
        }
        if (res.code === 'SPAM_PREVENTION_CODE_MISSING') {
          this.setState({
            errorMessageText: res.message,
            // We don't show spam code by default because 99.9%
            // users never need it.
            showSpamCode: true,
          });
        } else if (res.code === 'NO_CERTIFICATE') {
          this.setState({
            errorMessageText: res.message,
            errorLink: {
              url: this.props.registerMobileIdUrl,
              text: this.translations.i18n_login_mobileRegisterLinkText,
            },
          });
          this.setSubmitting(false);
          return;
        } else if (res.success && res.token && res.eventId) {
          this.switchView('AUTH_MOBILE_PHONE_POLLING');
          this.setState({
            mobileAuthEventId: res.eventId,
            mobileAuthToken: res.token,
          });
          let pollingResponse;
          try {
            pollingResponse = await config.pollMobileAuthentication(
              {
                eventId: res.eventId,
                token: res.token,
              },
              MOBILE_AUTH_TIMEOUT_SECONDS,
              this.pollingSessionId
            );
          } catch (e) {
            pollingResponse = e;
          }
          if (pollingResponse.pollingSessionId !== this.pollingSessionId) {
            break;
          }
          if (pollingResponse.success) {
            trackEventCallback('Mobile login successful');
            config.onLoginSuccess(pollingResponse);
          } else {
            this.switchView('AUTH_MOBILE_PHONE');
            trackEventCallback('Mobile login poll failed', pollingResponse.message);
            this.setState({
              mobileAuthEventId: '',
              mobileAuthToken: '',
              errorMessageText: pollingResponse.message,
            });
          }
        }
        break;
      case loginTypes.AUTH_STRONG_IDENTIFICATION: {
        try {
          res = await config.authFtn(ftnRedirectUrl || defaultFtnRedirectUrl(), values.bank, values.useYTunnus ? values.ytunnus : null);
        } catch (e) {
          res = e;
        }
        break;
      }
    }

    if (res.code === 'AUTHENTICATED') {
      config.onLoginSuccess(res);
      return;
    }

    if (res.message) {
      this.setState({
        errorMessageText: res.message,
      });
    }
    this.setSubmitting(false);
  };

  validate() {
    const { values } = this.state;

    const errors = {};

    if (this.state.errorMessageText) {
      this.setState({ errorMessageText: null });
    }

    if (this.state.currentView === loginTypes.AUTH_USERNAME_PASSWORD) {
      validateUsernamePassword(values, errors, this.translations);
    }
    if (this.state.currentView === loginTypes.AUTH_2_FACTOR) {
      validateAuth2Factor(values, errors, this.translations);
    }
    if (this.state.currentView === loginTypes.AUTH_STRONG_IDENTIFICATION) {
      validateStrongIdentification(values, errors, this.translations);
    }
    if (this.state.currentView === loginTypes.AUTH_MOBILE_PHONE) {
      validateMobilePhone(values, errors, this.translations);
    }

    this.setState({ errors });
    return errors;
  }

  render() {
    const {
      id,
      config,
      autofocus,
      enabledLoginTypes,
      usernameValue,
      passwordValue,
      auth2FactorValue,
      deviceShouldBeRememberedValue,
      phoneNumberValue,
      spamCodeValue,
      ytunnusValue,
      loginHelpUrl,
      createLoginUrl,
      enableYTunnus,
      banks,
      trackEventCallback,
      ftnRedirectUrl,
      className,
    } = this.props;
    const { values, errors, touched, isSubmitting } = this.state;

    const allClasses = classNames([styles.login, className]);

    const View = loginViews[this.state.currentView];

    return (
      <View
        id={this.htmlId}
        className={allClasses}
        values={values}
        autofocus={autofocus}
        errors={errors}
        touched={touched}
        handleSubmit={this.handleSubmit.bind(this)}
        submitForm={this.submitForm.bind(this)}
        handleChange={this.handleChange.bind(this)}
        handleBlur={this.handleBlur.bind(this)}
        isSubmitting={isSubmitting}
        auth2FactorNewCodeClick={this.auth2FactorNewCodeClick}
        showSpamCode={this.state.showSpamCode}
        mobileAuthEventId={this.state.mobileAuthEventId}
        cancelMobileAuthPolling={this.cancelMobileAuthPolling}
        ytunnusValue={ytunnusValue}
        censoredMsisdn={this.state.censoredMsisdn}
        trackEventCallback={trackEventCallback}
        setFieldValue={this.setFieldValue.bind(this)}
        loginHelpUrl={loginHelpUrl}
        createLoginUrl={createLoginUrl}
        errorMessage={<ErrorMessage id={this.htmlId} errorMessageText={this.state.errorMessageText} errorLink={this.state.errorLink} />}
        loginTypesSwitcher={<LoginTypesSwitcher enabledLoginTypes={enabledLoginTypes} currentView={this.state.currentView} switchView={this.switchView} translations={this.translations} trackEventCallback={trackEventCallback} />}
        loginHelp={<LoginHelp loginHelpUrl={loginHelpUrl} loginHelpClick={this.loginHelpClick.bind(this)} translations={this.translations} />}
        loginCreate={<LoginCreate createLoginUrl={createLoginUrl} createLoginClick={this.createLoginClick.bind(this)} translations={this.translations} />}
        enableYTunnus={enableYTunnus}
        banks={banks}
        translations={this.translations}
      />
    );
  }
}

export default Login;
