import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHomeApplianceFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 10.2h-17c-.3 0-.5.2-.5.5v10.5c0 .9.7 1.5 1.5 1.5h1.2v.5c0 .4.3.8.8.8s.8-.3.8-.8v-.5h9.5v.5c0 .4.3.8.8.8s.8-.3.8-.8v-.5h1.2c.9 0 1.5-.7 1.5-1.5V10.8q-.15-.45-.6-.6M7.1 16.5c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-4c0-.3.3-.6.6-.6s.6.3.6.6zM19.5 1H4.6C3.7 1 3 1.7 3 2.5v6c0 .3.2.5.5.5h17c.3 0 .5-.2.5-.5v-6c0-.8-.7-1.5-1.5-1.5M7.1 6.5c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-2c0-.3.3-.6.6-.6s.6.3.6.6z"/></svg>)}
    />
  );
}
  