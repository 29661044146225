// @flow

import React from 'react';

export type PropsT = {
  name: string, // eslint-disable-line react/no-unused-prop-types
  active?: boolean,
  onClick?: *,
  render: (*) => *,
  disabled?: boolean
};

export const Option = ({ active, onClick, render, disabled, ...rest }: PropsT) => (
  <div
    {...rest}
    style={{ display: 'inline-block' }}
    role="button"
    onClick={onClick}
    onKeyPress={onClick}
    tabIndex={0}
  >
    {render({ active, disabled })}
  </div>
);

Option.defaultProps = {
  active: false,
  disabled: false,
  onClick: () => {}
};

export default Option;
