// @flow

import React, { Component } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import ActionButton from './Button/ActionButton';
import CancelButton from './Button/CancelButton';
import styles from './ResultView.module.scss';
import CenterHorizontally from './CenterHorizontally/CenterHorizontally';

export type ViewTypeT = 'success view' | 'error view' | 'process view';

export type PropsT = {|
  onConfirm: void => void,
  onCancel: void => void,
  viewType: ViewTypeT,
  mainInfos?: string[],
  secondaryInfos?: string[],
  title: string,
  confirmButtonLabel: string,
  cancelButtonLabel: string
|};

// eslint-disable-next-line
export class ResultView extends Component<PropsT> {
  static defaultProps = {
    confirmButtonLabel: '',
    cancelButtonLabel: '',
    onConfirm: () => {},
    onCancel: () => {},
    mainInfos: [],
    secondaryInfos: []
  };

  render() {
    const {
      confirmButtonLabel,
      cancelButtonLabel,
      viewType,
      onConfirm,
      onCancel,
      mainInfos,
      secondaryInfos,
      title
    } = this.props;

    const errorIcon = <img src="/error-red.svg" alt="error" />;
    const successIcon = <img src="/checkmark green.svg" alt="successful" />;
    const processIcon = (
      <CenterHorizontally>
        <LoadingSpinner id="processing-indicator" />
      </CenterHorizontally>
    );

    const errorButtons = (
      <div>
        <div>
          <ActionButton
            id="import-confirm-button"
            label={confirmButtonLabel}
            onClickAction={onConfirm}
            className={styles['confirm-button']}
          />
        </div>
        <div>
          <CancelButton
            id="import-cancel-button"
            label={cancelButtonLabel}
            onClickAction={onCancel}
            className={styles['cancel-button']}
          />
        </div>
      </div>
    );
    const successButtons = (
      <ActionButton
        id="result-view-confirm-button"
        label={confirmButtonLabel}
        onClickAction={onConfirm}
        className={styles['confirm-button']}
      />
    );
    const processButtons = (
      <CancelButton
        id="import-cancel-button"
        label={cancelButtonLabel}
        onClickAction={onCancel}
        className={styles['cancel-button']}
      />
    );

    /* eslint-disable react/no-array-index-key */
    return (
      <div id="result-view" className={styles['result-view-content']}>
        {(viewType === 'error view' && errorIcon) ||
          (viewType === 'success view' && successIcon) ||
          (viewType === 'process view' && processIcon)}
        <div>
          <h1>{title}</h1>
        </div>
        {mainInfos &&
          mainInfos.map((info, index) => (
            <div key={`main-${index}`}>
              <h2>{info}</h2>
            </div>
          ))}
        {secondaryInfos &&
          secondaryInfos.map((info, index) => (
            <div key={`secondary-${index}`} className={styles['secondary-info']}>
              <h3>{info}</h3>
            </div>
          ))}
        {(viewType === 'error view' && errorButtons) ||
          (viewType === 'success view' && successButtons) ||
          (viewType === 'process view' && processButtons)}
      </div>
    );
    /* eslint-enable react/no-array-index-key */
  }
}

export default ResultView;
