import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoAppleRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.588 5.568a4.35 4.35 0 0 1 1.056-3.456A4.98 4.98 0 0 1 16.388 0a5.36 5.36 0 0 1-1.248 3.84 4.09 4.09 0 0 1-3.552 1.728m6.624 7.104c0-1.858.987-3.576 2.592-4.512a5.4 5.4 0 0 0-4.704-2.4c-2.016 0-3.552 1.152-4.32 1.152-.96 0-2.4-1.056-4.224-1.056-2.88 0-5.856 2.496-5.856 7.296S5.252 24 8.036 24c1.056 0 2.496-.96 4.032-.96S14.66 24 16.1 24c3.072 0 5.376-6.432 5.376-6.432a5.35 5.35 0 0 1-3.264-4.896"/></svg>)}
    />
  );
}
  