import React from 'react';
import IconContainer from '../IconContainer';

export default function IconYoutubeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.55 8.43 15.82 12l-6.27 3.57zM23.999 12a32 32 0 0 1-.51 5.91 3.27 3.27 0 0 1-2.3 2.32c-1.88.51-8.879.52-9.189.52s-7.31 0-9.189-.52a3.27 3.27 0 0 1-2.3-2.32A32 32 0 0 1 .001 12c-.013-1.982.157-3.96.51-5.91a3.27 3.27 0 0 1 2.3-2.32C4.69 3.26 11.69 3.25 12 3.25s7.31 0 9.189.52a3.27 3.27 0 0 1 2.3 2.32c.353 1.95.524 3.928.51 5.91m-1.5 0a29.5 29.5 0 0 0-.46-5.53 1.79 1.79 0 0 0-1.24-1.26c-1.4-.38-6.75-.46-8.749-.46s-7.4.08-8.749.46a1.79 1.79 0 0 0-1.34 1.26 29.3 29.3 0 0 0-.46 5.53 29.5 29.5 0 0 0 .46 5.53 1.79 1.79 0 0 0 1.29 1.26c1.4.38 6.75.46 8.799.46s7.4-.08 8.799-.46a1.79 1.79 0 0 0 1.24-1.26c.311-1.826.448-3.678.41-5.53"/></svg>)}
    />
  );
}
  