import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilmCameraFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.375 9.75a4.375 4.375 0 1 0 0-8.75 4.375 4.375 0 0 0 0 8.75M19.75 5.375a4.375 4.375 0 1 1-8.75 0 4.375 4.375 0 0 1 8.75 0M2 13a2 2 0 0 1 2-2h11.5a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm16.75 1 2.824-1.329a1 1 0 0 1 1.426.905v6.848a1 1 0 0 1-1.426.905L18.75 20z"/></svg>)}
    />
  );
}
  