// @flow

import { type $AxiosError } from 'axios';
import * as R from 'ramda';
import type {
  HistoryActionsPayloadT,
  RetrieveHistoryActionsRequestT,
  RetrieveHistoryActionsSuccessT,
  RetrieveHistoryActionsFailureT,
  RetrieveHistoryActionsCancelT,
  EmptyHistoryActionsRequestT
} from './historyActionTypes';

type CreateRetrieveHistoryCollectionRequestFnT = (
  string,
  number,
  ?{}
) => RetrieveHistoryActionsRequestT;

type CreateEmptyHistoryCollectionRequestFnT = () => EmptyHistoryActionsRequestT;

export const createRetrieveHistoryCollectionRequest: CreateRetrieveHistoryCollectionRequestFnT = (
  enterpriseId,
  page,
  meta = {}
) => ({
  type: 'ring/entity/history/RETRIEVE_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId, page }
});

export const createEmptyHistoryCollectionRequest: CreateEmptyHistoryCollectionRequestFnT = () => ({
  type: 'ring/entity/history/EMPTY_COLLECTION_REQUEST'
});

type CreateRetrieveHistoryCollectionSuccessFnT = (
  string,
  HistoryActionsPayloadT,
  ?{}
) => RetrieveHistoryActionsSuccessT;

export const createRetrieveHistoryCollectionSuccess: CreateRetrieveHistoryCollectionSuccessFnT = (
  enterpriseId,
  historyCollection,
  meta = {}
  // $FlowFixMe: TODO: fix
) => ({
  type: 'ring/entity/history/RETRIEVE_COLLECTION_SUCCESS',
  error: false,
  // $FlowFixMe: TODO: fix
  meta: {
    enterpriseId,
    ...R.omit(['results'], historyCollection),
    ...meta
  },
  payload: R.map(R.assoc('enterpriseId', enterpriseId), historyCollection.results)
});

type CreateRetrieveHistoryCollectionFailureFnT = (
  string,
  $AxiosError<*>,
  ?{}
) => RetrieveHistoryActionsFailureT;

export const createRetrieveHistoryCollectionFailure: CreateRetrieveHistoryCollectionFailureFnT = (
  enterpriseId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/history/RETRIEVE_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});

type CreateRetrieveHistoryCollectionCancelFnT = () => RetrieveHistoryActionsCancelT;
export const createRetrieveHistoryCollectionCancel: CreateRetrieveHistoryCollectionCancelFnT = () => ({
  type: 'ring/entity/history/RETRIEVE_COLLECTION_CANCEL'
});
