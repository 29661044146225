import React from 'react';
import IconContainer from '../IconContainer';

export default function IconErrorRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m0 22.5C6.2 22.5 1.5 17.8 1.5 12S6.2 1.5 12 1.5 22.5 6.2 22.5 12 17.8 22.5 12 22.5m-.8-9.3V6.8c0-.5.4-.8.8-.8s.8.3.8.8v6.5c0 .4-.3.8-.8.8-.4-.1-.8-.4-.8-.9M13 17c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1"/></svg>)}
    />
  );
}
  