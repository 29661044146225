import React from 'react';
import IconContainer from '../IconContainer';

export default function IconClockLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1M0 12C0 5.4 5.4 0 12 0s12 5.4 12 12-5.4 12-12 12S0 18.6 0 12m12-6c.3 0 .5.2.5.5v5.3l5.8 5.4c.2.2.2.5 0 .7s-.5.2-.7 0l-6-5.5c-.1-.1-.2-.2-.2-.4V6.5c.1-.3.3-.5.6-.5"/></svg>)}
    />
  );
}
  