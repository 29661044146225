// @flow

import React, { type Element } from 'react';
import IconArrowLeftRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowLeftRegular';
import IconArrowRightRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowRightRegular';
import styles from './CarouselScrollerButton.module.scss';

export type PropsT = {
  onSelect: () => *,
  direction: 'left' | 'right',
  classNames: string
};

const CarouselScrollerButton = (props: PropsT): Element<'div'> => {
  const { onSelect, direction, classNames } = props;
  return (
    <div
      className={`${classNames} ${styles.container}`}
      onClick={onSelect}
      tabIndex="0"
      role="button"
      onKeyPress={onSelect}
    >
      {direction === 'left' ? (
        <IconArrowLeftRegular color="white" size="l" className={styles.arrow} />
      ) : (
        <IconArrowRightRegular color="white" size="l" className={styles.arrow} />
      )}
    </div>
  );
};

export default CarouselScrollerButton;
