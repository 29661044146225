import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowUpRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.004 16.282a.74.74 0 0 1-.539-.229L12 9.36l-6.46 6.69a.751.751 0 0 1-1.08-1.043l6.64-6.875c.235-.243.563-.382.9-.382h.001c.337 0 .664.14.898.382l6.644 6.878a.75.75 0 0 1-.539 1.272"/></svg>)}
    />
  );
}
  