import React from 'react';
import IconContainer from '../IconContainer';

export default function IconReplyAllRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.3 17c.3.3.3.8 0 1.1-.1.1-.3.2-.5.2s-.4-.1-.5-.3L.7 12c-.8-.9-.8-2.2 0-3.1L6.2 3c.3-.3.7-.3 1 0s.3.7.1 1l-5.6 6c-.3.3-.3.7 0 1zM24 15v6c0 .4-.3.8-.8.8s-.8-.3-.8-.8c0-3.7-3-6.7-6.8-6.8H14v2.2c0 1.2-.9 2.2-2.1 2.2h-.2c-.6 0-1.1-.3-1.5-.8l-5.6-6c-.8-.9-.8-2.2 0-3.1L10.2 3c.4-.5 1-.7 1.6-.7 1.2 0 2.2 1 2.2 2.2v2.3h1.8c4.5 0 8.2 3.6 8.2 8.2m-1.5 0c0-3.7-3-6.8-6.8-6.8h-2.5c-.4 0-.7-.3-.8-.8v-3c0-.4-.3-.7-.7-.8-.2 0-.4.1-.5.2l-5.6 6c-.2.3-.2.7 0 1l5.6 6c.2.3.6.3.9.1l.1-.1c.1-.1.2-.3.2-.5v-3c0-.4.3-.7.8-.8h2.5c2.7 0 5.2 1.3 6.8 3.5z"/></svg>)}
    />
  );
}
  