// @flow

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const escapeRegExp = (string: string) => {
  // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const cutByWordBoundary = (input: string, searchWord: string) => {
  const search = escapeRegExp(searchWord);
  const re = new RegExp(`\\b\\S*${search}\\S*\\b`, 'gi');
  const match = input.match(re);
  if (match && match[0]) {
    return match[0];
  }
  return undefined;
};

export const cutBySequence = (input: string, searchWord: string, before: number, after: number) => {
  const matchIndex = input.indexOf(searchWord);
  if (matchIndex !== -1) {
    return input.substring(
      Math.max(0, matchIndex - before),
      Math.min(input.length, matchIndex + searchWord.length + after)
    );
  }

  return undefined;
};

export const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
};
