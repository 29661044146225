import React from 'react';
import IconContainer from '../IconContainer';

export default function IconChatLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.49 15.008a.49.49 0 0 1-.578.383l-.012-.003a8 8 0 0 1-.78-.2l-4.27 1.751a.9.9 0 0 1-.37.07 1 1 0 0 1-.87-1.501l1.51-2.671A7.17 7.17 0 0 1 0 7.844C0 3.522 4.15 0 9.25 0s9.25 3.522 9.25 7.834a.5.5 0 0 1-1 0C17.5 4.072 13.8 1 9.25 1S1 4.072 1 7.834a6.14 6.14 0 0 0 2.08 4.532.5.5 0 0 1 .1.62l-1.7 3.002 4.43-1.781a.45.45 0 0 1 .34 0c.29.09.57.16.86.23a.49.49 0 0 1 .38.571m16 7.584c.141.366.055.781-.22 1.061a1 1 0 0 1-1.09.29l-3.46-1.361a7.73 7.73 0 0 1-7-.73 6.73 6.73 0 0 1-3.21-5.603c0-3.672 3.55-6.764 7.75-6.764S24 12.597 24 16.259a5.66 5.66 0 0 1-1.33 3.652zm-1 .27-.84-3.002a.48.48 0 0 1 .11-.47A4.68 4.68 0 0 0 23 16.258c0-3.122-3.09-5.753-6.75-5.753S9.5 13.137 9.5 16.259a5.7 5.7 0 0 0 2.74 4.753 6.74 6.74 0 0 0 6.24.58.6.6 0 0 1 .19 0q.09-.015.18 0l3.61 1.421s.02-.11.02-.13z"/></svg>)}
    />
  );
}
  