import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDisturbanceRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.75 12.5a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5m0-11a4.75 4.75 0 1 1 0 9.5 4.75 4.75 0 0 1 0-9.5m-2 5.75 1-1-1-1a.75.75 0 0 1 1-1l1 1 1-1a.75.75 0 0 1 1 1l-1 1 1 1a.75.75 0 0 1 0 1 .75.75 0 0 1-1 0l-1-1-1 1a.75.75 0 0 1-1 0 .75.75 0 0 1 0-1m5.661 6.348a.75.75 0 0 0-.282 1.022 10.6 10.6 0 0 1 1.371 5.3v1.83c0 .413-.337.75-.75.75h-6.025a3.74 3.74 0 0 0-2.723-3.347l1.706-4.907a.75.75 0 0 0-.462-.954.75.75 0 0 0-.954.462l-1.844 5.302A3.735 3.735 0 0 0 8.275 22.5H2.25a.75.75 0 0 1-.75-.75v-2.006c-.038-5.118 3.315-9.402 8.154-10.417a.75.75 0 1 0-.308-1.468C3.8 9.022-.043 13.915 0 19.75v2C0 22.99 1.009 24 2.25 24h19.5c1.24 0 2.25-1.01 2.25-2.25v-1.824a12.1 12.1 0 0 0-1.566-6.046.75.75 0 0 0-1.023-.282m-9.422 6.903.011.002.011-.002a2.246 2.246 0 0 1 2.214 1.999h-4.45a2.246 2.246 0 0 1 2.214-1.999M18 19.375a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0m-.375-2.625a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25M10.5 12.625a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0M6.375 16.75a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25m-1.5 1.5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25"/></svg>)}
    />
  );
}
  