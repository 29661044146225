import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPinterestRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.706 22.999c-.107-.931-.194-2.365.039-3.383.213-.921 1.376-5.855 1.376-5.855s-.349-.708-.349-1.745c0-1.638.95-2.859 2.132-2.859 1.008 0 1.493.756 1.493 1.657 0 1.008-.64 2.52-.979 3.926-.281 1.173.591 2.132 1.745 2.132 2.094 0 3.703-2.21 3.703-5.389 0-2.821-2.026-4.788-4.924-4.788-3.354 0-5.321 2.51-5.321 5.108 0 1.008.388 2.094.872 2.685a.35.35 0 0 1 .078.339c-.087.368-.291 1.173-.33 1.338-.048.213-.174.262-.397.155-1.473-.688-2.394-2.83-2.394-4.565 0-3.712 2.695-7.124 7.783-7.124 4.081 0 7.26 2.908 7.26 6.804 0 4.061-2.559 7.328-6.107 7.328-1.192 0-2.317-.62-2.695-1.357l-.737 2.801c-.262 1.027-.979 2.307-1.464 3.092 0 0-.163.305-.489.163-.23-.101-.295-.463-.295-.463M24 12c0-6.617-5.383-12-12-12S0 5.383 0 12c0 3.988 1.974 7.706 5.281 9.944a.75.75 0 1 0 .841-1.242A10.5 10.5 0 0 1 1.5 12C1.5 6.21 6.21 1.5 12 1.5S22.5 6.21 22.5 12 17.79 22.5 12 22.5q-.5 0-.986-.046a.75.75 0 1 0-.139 1.494q.556.051 1.125.052c6.617 0 12-5.383 12-12"/></svg>)}
    />
  );
}
  