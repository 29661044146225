import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmarthomeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 23H5c-1.103 0-2-.897-2-2v-7.5a.5.5 0 0 1 1 0V21c0 .552.449 1 1 1h14a1 1 0 0 0 1-1v-7.477a.5.5 0 0 1 1 0V21c0 1.103-.897 2-2 2M.855 12.852 11.286 2.3A1 1 0 0 1 12 2c.271 0 .525.106.717.302l10.428 10.549a.5.5 0 0 0 .711-.703L13.43 1.601A2 2 0 0 0 12 1h-.001c-.542 0-1.049.213-1.426.599L.145 12.148a.5.5 0 0 0 .71.704m8.686 3.178c1.313-1.314 3.605-1.314 4.918 0a.5.5 0 0 0 .707-.707A4.45 4.45 0 0 0 12 14.011c-1.196 0-2.32.466-3.166 1.312a.5.5 0 0 0 .707.707m7.313-2.312a.5.5 0 0 0 0-.707A6.82 6.82 0 0 0 12 11a6.82 6.82 0 0 0-4.854 2.011.5.5 0 0 0 .707.707C8.961 12.61 10.434 12 12 12s3.039.61 4.146 1.718a.5.5 0 0 0 .708 0m-3.979 4.157c0-.482-.393-.875-.875-.875s-.875.393-.875.875.393.875.875.875.875-.393.875-.875"/></svg>)}
    />
  );
}
  