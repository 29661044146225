import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNonWatchableRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m5.3.3 3.9 2.2v1.7L4.6 1.6c-.1-.1-.2-.1-.4-.1-.1 0-.3 0-.4.1 0 .1-.1.2-.2.3s-.1.2-.1.4v19.4c0 .1 0 .3.1.4l.3.3c.1.1.2.1.4.1.1 0 .3 0 .4-.1L9 19.9c-.1.3-.2.7-.2 1.1 0 .2 0 .5.1.7l-3.5 1.9c-1.5.8-3.3-.2-3.3-2V2.3C2 .5 3.8-.5 5.3.3m16.8 12.3-8.3 4.6V19l9.1-5c1.5-.9 1.5-3.1 0-3.9l-9.1-5v1.7l8.3 4.6.3.3c.1.1.1.2.1.4 0 .1 0 .3-.1.4-.1 0-.2.1-.3.1M11.5 1.5c.6 0 1 .4 1 1v14.8c0 .6-.4 1-1 1s-1-.4-1-1V2.5c0-.6.4-1 1-1m0 21c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5-1.5.7-1.5 1.5.7 1.5 1.5 1.5"/></svg>)}
    />
  );
}
  