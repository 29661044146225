import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHomeApplianceRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.45.75H4.55a1.81 1.81 0 0 0-1.8 1.8V21.2a1.81 1.81 0 0 0 1.8 1.8h1.2v.25a.75.75 0 0 0 1.5 0V23h9.5v.25a.75.75 0 0 0 1.5 0V23h1.2a1.81 1.81 0 0 0 1.8-1.8V2.55a1.81 1.81 0 0 0-1.8-1.8m-14.9 1.5h14.9a.29.29 0 0 1 .3.28v6.22H4.25v-6.2a.29.29 0 0 1 .28-.3zm14.9 19.25H4.55a.29.29 0 0 1-.3-.28V10.25h15.5V21.2a.29.29 0 0 1-.28.3zm-12.2-9v4a.75.75 0 0 1-1.5 0v-4a.75.75 0 0 1 1.5 0m-1.5-6v-2a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-1.5 0"/></svg>)}
    />
  );
}
  