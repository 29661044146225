// @flow
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Dialog from '../../../../components/Dialog';
import { getTextRepresentation } from '../../../../helpers';
import type { ForwardingFormT } from './forwardingFormUtils';
import { convertForwardingFormToForwardingData } from './forwardingFormUtils';

import styles from './ForwardingConfirmDialog.module.scss';

type PropsT = {
  forwardingForm: ForwardingFormT,
  isLoading: boolean,
  onClose: (reload: ?boolean) => Promise<void> | void,
  onConfirm: () => Promise<void> | void,
  membersToRemove: *,
  membersToAdd: *,
  membersToModify: *,
  presenceStates: *
};

const ForwardingConfirmDialog = (props: PropsT) => {
  const {
    forwardingForm,
    isLoading,
    onConfirm,
    onClose,
    membersToRemove,
    membersToAdd,
    membersToModify,
    presenceStates
  } = props;

  const forwardingType = forwardingForm.isEnterpriseForwarding ? 'enterprise' : 'user';

  const location = useLocation();
  const selectedUser = location.state ? location.state.selectedUser : null;

  const { t } = useTranslation();
  const getSingleFullName = user => {
    if (user.publicInfo) {
      return user.publicInfo.firstName && user.publicInfo.lastName
        ? `${user.publicInfo.firstName} ${user.publicInfo.lastName}`
        : user.displayName;
    }
    return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.login;
  };

  const renderMultipleNamesAdd = () => {
    return membersToAdd.length === 1 // eslint-disable-line no-nested-ternary
      ? parse(
          `${t('forwardings.forwardingConfirm.descriptionAdd1')}<b>${getSingleFullName(
            membersToAdd[0]
          )}</b>${t('forwardings.forwardingConfirm.descriptionAdd2')}`
        )
      : membersToAdd.length > 3
      ? parse(
          `${t('forwardings.forwardingConfirm.descriptionTooManyNamesAdd1')}<b>${
            membersToAdd.length
          }</b>${t('forwardings.forwardingConfirm.descriptionTooManyNamesAdd2')}`
        )
      : parse(
          `${t(
            'forwardings.forwardingConfirm.descriptionManyNamesAdd1'
          )}<b>${membersToAdd.map(user =>
            user.publicInfo && user.publicInfo.firstName && user.publicInfo.lastName
              ? ` ${user.publicInfo.firstName} ${user.publicInfo.lastName}`
              : user.displayName
          )}</b>${t('forwardings.forwardingConfirm.descriptionManyNamesAdd2')}`
        );
  };

  const renderMultipleNamesDelete = () => {
    return membersToRemove.length === 1 // eslint-disable-line no-nested-ternary
      ? parse(
          `${t('forwardings.forwardingConfirm.descriptionDelete1')}<b>${getSingleFullName(
            membersToRemove[0]
          )}</b>${t('forwardings.forwardingConfirm.descriptionDelete2')}`
        )
      : membersToRemove.length > 3
      ? parse(
          `${t('forwardings.forwardingConfirm.descriptionTooManyNamesDelete1')}<b>${
            membersToRemove.length
          }</b>${t('forwardings.forwardingConfirm.descriptionTooManyNamesDelete2')}`
        )
      : parse(
          `${t(
            'forwardings.forwardingConfirm.descriptionManyNamesDelete1'
          )}<b>${membersToRemove.map(user =>
            user.publicInfo && user.publicInfo.firstName && user.publicInfo.lastName
              ? ` ${user.publicInfo.firstName} ${user.publicInfo.lastName}`
              : user.displayName
          )}</b>${t('forwardings.forwardingConfirm.descriptionManyNamesDelete2')}`
        );
  };

  const renderMultipleNamesModify = () => {
    return membersToModify.length === 1 // eslint-disable-line no-nested-ternary
      ? parse(
          `${t('forwardings.forwardingConfirm.descriptionModify1')}<b>${getSingleFullName(
            membersToModify[0]
          )}</b>${t('forwardings.forwardingConfirm.descriptionModify2')}`
        )
      : membersToModify.length > 3
      ? parse(
          `${t('forwardings.forwardingConfirm.descriptionTooManyNamesModify1')}<b>${
            membersToModify.length
          }</b>${t('forwardings.forwardingConfirm.descriptionTooManyNamesModify2')}`
        )
      : parse(
          `${t(
            'forwardings.forwardingConfirm.descriptionManyNamesModify1'
          )}<b>${membersToModify.map(user =>
            user.publicInfo && user.publicInfo.firstName && user.publicInfo.lastName
              ? ` ${user.publicInfo.firstName} ${user.publicInfo.lastName}`
              : user.displayName
          )}</b>${t('forwardings.forwardingConfirm.descriptionManyNamesModify2')}`
        );
  };

  const fullName = selectedUser ? selectedUser.fullName || '' : '';

  const descriptionAdd = fullName
    ? parse(
        `${t('forwardings.forwardingConfirm.descriptionAdd1')}<b>${fullName}</b>${t(
          'forwardings.forwardingConfirm.descriptionAdd2'
        )}`
      )
    : renderMultipleNamesAdd();

  const descriptionDelete = fullName
    ? parse(
        `${t('forwardings.forwardingConfirm.descriptionDelete1')}<b>${fullName}</b>${t(
          'forwardings.forwardingConfirm.descriptionDelete2'
        )}`
      )
    : renderMultipleNamesDelete();

  const descriptionModify = fullName
    ? parse(
        `${t('forwardings.forwardingConfirm.descriptionModify1')}<b>${fullName}</b>${t(
          'forwardings.forwardingConfirm.descriptionModify2'
        )}`
      )
    : renderMultipleNamesModify();

  const textEnterprisePrefix =
    forwardingType === 'enterprise' ? `${t('forwardings.popup.headerCompanySetting')}: ` : '';

  const newForwarding = convertForwardingFormToForwardingData(forwardingForm, presenceStates);
  // $FlowFixMe
  const textRepresentation = getTextRepresentation(newForwarding, 'long', t).toString();

  const description = (
    <span>
      {membersToAdd.length > 0 && (
        <span>
          {descriptionAdd}
          <br />
        </span>
      )}
      {membersToModify.length > 0 && (
        <span>
          {descriptionModify}
          <br />
        </span>
      )}
      {membersToRemove.length > 0 && (
        <span>
          {descriptionDelete}
          <br />
        </span>
      )}
    </span>
  );
  return (
    <Dialog
      onCancel={() => onClose()}
      onConfirm={() => onConfirm()}
      title={
        forwardingType === 'enterprise'
          ? t('forwardingDetails.smallHeaderCreateEnterprise')
          : t('forwardings.forwardingConfirm.title')
      }
      description={
        forwardingType === 'enterprise'
          ? t('forwardings.forwardingConfirm.enterpriseDescription')
          : description
      }
      descriptionExtended={`${t(
        'forwardings.forwardingConfirm.rule'
      )} ${textEnterprisePrefix}${textRepresentation}`}
      descriptionClassName={styles['confirm-description']}
      descriptionExtendedClassName={styles['confirm-description-extended']}
      confirmLabel={t('forwardings.forwardingConfirm.confirm')}
      cancelLabel={t('forwardings.forwardingConfirm.cancel')}
      loading={isLoading}
      disabled={false}
    />
  );
};

export default ForwardingConfirmDialog;
