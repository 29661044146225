// @flow

import React, { Component } from 'react';
import Favourite from '../../../components/Favourite/Favourite';
import { ReactComponent as MeatballIcon } from '../../../assets/meatball.svg';
import EditIcon from '../../../components/EditIcon/EditIcon';
import Dismiss from '../../../components/Button/Dismiss';
import styles from './FixedTitle.module.scss';

export type PropsT = {
  onClose: () => *,
  fullName: string,
  isFavourite: boolean,
  onToggleFavourite: (selected: boolean) => *,
  handleOnEditClick: () => void,
  disableButtons: boolean,
  disableFavouriteButton: boolean
};

export type StateT = {
  isButtonsVisible: boolean
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
export class FixedTitle extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      isButtonsVisible: false
    };
  }

  render() {
    const {
      onClose,
      fullName,
      isFavourite,
      onToggleFavourite,
      handleOnEditClick,
      disableButtons,
      disableFavouriteButton
    } = this.props;
    const { isButtonsVisible } = this.state;

    return (
      <div className={styles.top}>
        <div className={styles['buttons-bar']}>
          <Dismiss
            id="close-user-details-button"
            onClose={onClose}
            tabIndex="0"
            onKeyPress={onClose}
            dismissStyle={styles['close-button']}
            color="white"
          />
        </div>
        <div className={styles.background} />
        <div className={styles['user-name']}>{fullName}</div>
        <span
          id="title-menu"
          className={styles['title-menu']}
          onClick={() => {
            this.setState({ isButtonsVisible: !isButtonsVisible });
          }}
        >
          <MeatballIcon />
        </span>
        {isButtonsVisible && (
          <div id="button-area" className={styles['button-area']}>
            <Favourite
              isSelected={isFavourite}
              onToggle={onToggleFavourite}
              disabled={disableButtons || (!isFavourite && disableFavouriteButton)}
            />
            <EditIcon
              className={styles['edit-button']}
              onClick={handleOnEditClick}
              disabled={disableButtons}
            />
          </div>
        )}
      </div>
    );
  }
}
export default FixedTitle;
