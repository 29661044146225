import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilterLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.5 10.1V1.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v8.6c-1.7.2-3 1.7-3 3.4s1.3 3.2 3 3.4v5.6c0 .3.2.5.5.5s.5-.2.5-.5v-5.6c1.7-.2 3-1.7 3-3.4s-1.3-3.2-3-3.4M4 16c-1.4 0-2.5-1.1-2.5-2.5S2.6 11 4 11s2.5 1.1 2.5 2.5S5.4 16 4 16m8.5-12.9V1.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v1.6c-1.7.2-3 1.7-3 3.4s1.3 3.2 3 3.4v12.6c0 .3.2.5.5.5s.5-.2.5-.5V9.9c1.7-.2 3-1.7 3-3.4s-1.3-3.2-3-3.4M12 9c-1.4 0-2.5-1.1-2.5-2.5S10.6 4 12 4s2.5 1.1 2.5 2.5S13.4 9 12 9m11.5 8.5c0-1.8-1.3-3.2-3-3.4V1.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v12.6c-1.7.2-3 1.7-3 3.4s1.3 3.2 3 3.4v1.6c0 .3.2.5.5.5s.5-.2.5-.5v-1.6c1.7-.2 3-1.6 3-3.4M20 20c-1.4 0-2.5-1.1-2.5-2.5S18.6 15 20 15s2.5 1.1 2.5 2.5S21.4 20 20 20"/></svg>)}
    />
  );
}
  