// @flow

import * as R from 'ramda';
import React, { type Element, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import type {
  InteractionEntityT,
  WelcomeAttendantEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';

import { updateWelcomeAttendant } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantOperations';
import { ReactComponent as DialPadSmall } from '../../../../../../assets/callflow/details/dialpad-small.svg';
import EditCallflowDetails from '../../../../components/edit/EditCallflowDetails';
import { MultiAudiosSelection } from '../../../../components/edit/children/audio/MultiAudiosSelection';
import WelcomeAttendantStepField from '../../../../components/edit/children/welcomeAttendantStepField/WelcomeAttendantStepField';
import type { MultiAudioFieldT } from '../../../../components/edit/children/audio/MultiAudiosSelection';
import IntegerField from '../../../../components/edit/children/IntegerField';
import InteractionNextStepsField from './InteractionNextStepsField';
import type {
  InteractionNextStepFieldT,
  InteractionNextStepsFieldT
} from './InteractionNextStepsField';
import {
  convertAudioNamesToAudioFields,
  getInitialNextStep,
  getNewStepData,
  getNewStepOptions,
  getNextStepPayload,
  getWelcomeAttendantStepOptions,
  renameCommandName
} from '../WelcomeAttendantUtils';
import { uploadAudio } from '../../../../../../ducks/entities/callFlow/callFlowOperations';
import { InputField } from '../../../../components/edit/children/InputField';
import type { NextStepT } from '../WelcomeAttendantUtils';
import {
  getCommandNames,
  getIntegerFieldLimits,
  validateDialOptions,
  validateStepName
} from '../../../../../../utils/validationUtils';
import { createUpdateSelectedStatus } from '../../../../../../ducks/ui/callflow/callflowUiActions';
import type { WelcomeAttendantUpdateEntityT } from '../../../../../../ducks/entities/callFlow/callFlowTypes';
import { sanitizeAudioFilename } from '../../../../components/edit/children/audio/CallflowAudioUtils';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';

type PropsT = {|
  callflowId: string,
  commandId: string
|};

export type FormT = {
  stepName: string,
  audios: MultiAudioFieldT,
  dialOptions: InteractionNextStepsFieldT,
  retryStep: ?NextStepT,
  retryCount: number
};

const getNextStepType = (nextState: ?string, stepOptions: NextStepT[]) => {
  const newStepOptions = getNewStepOptions(e => e, '');
  const result = newStepOptions.find(e => e.value === nextState);
  if (result) {
    return result.type;
  }
  const stepResult = stepOptions.find(s => s.name === nextState);
  if (stepResult) {
    return stepResult.type;
  }

  return null;
};

export const convertToNewSteps = (dialOptions: InteractionNextStepsFieldT) => {
  const newOptions = [
    ...dialOptions
      .filter(
        (e: InteractionNextStepFieldT) =>
          e.isNew && e.newFieldName && typeof e.newFieldName === 'string'
      )
      .map(step => ({
        type: getNextStepType(step.nextState, []),
        name: step.newFieldName ? step.newFieldName.toUpperCase() : ''
      }))
      .filter(step => step.type && step.name)
  ];
  // $FlowFixMe Ramda types missing
  return R.mergeAll(newOptions.map(r => r && r.name && { [r.name]: r }));
};

export const buildWelcomeAttendantInteractionNodeUpdatePayload = (
  welcomeAttendantData: WelcomeAttendantEntityT,
  formData: FormT,
  commandName: string
) => {
  const audioNames = R.values(formData.audios)
    .filter(a => a.filename)
    .map(a => sanitizeAudioFilename(a.filename))
    .map(a => a.replace(/\.[^/.]+$/, ''));
  const newSteps = convertToNewSteps(formData.dialOptions);
  const convertChoice = choice => {
    const stepName = choice.newFieldName || choice.stepName || choice.nextState;
    return {
      choice: choice.choice,
      nextState: {
        state: stepName ? stepName.toUpperCase() : null,
        endState: choice.stepName === 'END' || choice.nextState === 'END'
      }
    };
  };
  const patchPayload = {
    commands: {
      ...welcomeAttendantData.commands,
      ...getNewStepData(formData.retryStep),
      ...newSteps,
      [commandName]: {
        ...welcomeAttendantData.commands[commandName],
        choices: formData.dialOptions.map(choice => convertChoice(choice)),
        retryCount: parseInt(formData.retryCount, 10),
        retryState: getNextStepPayload(formData.retryStep),
        audios: audioNames
      }
    }
  };
  return renameCommandName(patchPayload, commandName, formData.stepName.toUpperCase());
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

const EditInteractionDetails = (props: PropsT): Element<typeof EditCallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const welcomeAttendantData: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { enterpriseId } = welcomeAttendantData;
  const interactionNode: ?InteractionEntityT = R.path(
    ['commands', commandId],
    welcomeAttendantData
  );
  if (!interactionNode) {
    return null;
  }

  const stepOptions: NextStepT[] = getWelcomeAttendantStepOptions(welcomeAttendantData);
  const initialFormValues: FormT = {
    stepName: interactionNode.name,
    audios: convertAudioNamesToAudioFields(interactionNode.audios),
    dialOptions: (interactionNode.choices || []).map(c => ({
      ...c,
      stepName: c.nextState.state,
      type: getNextStepType(c.nextState.state, stepOptions),
      nextState: c.nextState.state,
      isNew: false
    })),
    retryCount: interactionNode.retryCount === undefined ? 2 : interactionNode.retryCount,
    // $FlowFixMe R-path does not work with typing
    retryStep: getInitialNextStep(stepOptions, R.path(['retryState', 'state'], interactionNode))
  };

  const waInteractionSchema = yup.object().shape({
    stepName: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, interactionNode.name),
      [['dialOptions']]
    ),
    retryStep: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, interactionNode.name),
      [['stepName'], ['dialOptions']],
      ['newStepName']
    ),
    retryCount: yup
      .number()
      .integer()
      .min(0)
      .max(999)
      .default(2)
      .required(),
    dialOptions: yup.array().of(
      yup.object({
        newFieldName: yup.string().test(
          'nameUniqueWithParent',
          t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
          // $FlowFixMe
          function(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            const parent = this.from[1].value;
            return validateDialOptions(
              t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
              t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
              parent,
              value,
              // eslint-disable-next-line react/no-this-in-sfc
              this.parent,
              // eslint-disable-next-line react/no-this-in-sfc
              this.createError,
              getCommandNames(welcomeAttendantData.commands, interactionNode.name)
            );
            // return value ? value.toUpperCase() !== parentName.toUpperCase() : false;
          }
        )
      })
    )
  });

  const uploadAudioByName = async (audioFile: File) => {
    await uploadAudio(
      enterpriseId,
      'welcomeattendants',
      welcomeAttendantData.id,
      { filename: sanitizeAudioFilename(audioFile.name) },
      audioFile,
      audioRequestCancelTokenSource.token,
      createCsrfHeader(currentUser)
    );
  };

  // update
  const onSubmit = async (formData: FormT): Promise<WelcomeAttendantEntityT> => {
    // Upload audios
    const promises = [];
    const audiosToBeUploaded = R.values(formData.audios).filter(audio => audio.file);
    audiosToBeUploaded.forEach(audio => audio.file && promises.push(uploadAudioByName(audio.file)));
    await Promise.all(promises);

    const patchPayload: WelcomeAttendantUpdateEntityT = buildWelcomeAttendantInteractionNodeUpdatePayload(
      welcomeAttendantData,
      formData,
      interactionNode.name
    );

    const returnValue = await dispatch(
      updateWelcomeAttendant(
        enterpriseId,
        welcomeAttendantData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
    dispatch(
      createUpdateSelectedStatus(
        welcomeAttendantData.id,
        'INTERACTION',
        formData.stepName.toUpperCase()
      )
    );
    return returnValue;
  };

  const numberOptionArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '#'];
  const allDialOptions = numberOptionArr.map(e => ({
    value: e,
    label: e
  }));

  return (
    <EditCallflowDetails
      nodeId={welcomeAttendantData.id}
      icon={<DialPadSmall />}
      title={t('callflows.editWelcomeAttendantInteraction.title')}
      description={t('callflows.editWelcomeAttendantInteraction.description')}
      defaultValues={initialFormValues}
      validationSchema={waInteractionSchema}
      onSaveForm={onSubmit}
    >
      <InputField
        field="stepName"
        title={t('callflows.welcomeAttendantGeneric.stepName')}
        shouldValidate
        tooltip={t('callflows.editWelcomeAttendantInteraction.stepNameTooltip')}
      />
      <MultiAudiosSelection
        field="audios"
        description={t('callflows.editWelcomeAttendantInteraction.audios')}
        enterpriseId={welcomeAttendantData.enterpriseId}
        callflowId={welcomeAttendantData.id}
      />

      <InteractionNextStepsField
        field="dialOptions"
        title={t('callflows.editWelcomeAttendantInteraction.dialOptionsTitle')}
        description={t('callflows.editWelcomeAttendantInteraction.dialOptionsDescription')}
        allDialOptions={allDialOptions}
        stepOptions={stepOptions}
      />

      <WelcomeAttendantStepField
        field="retryStep"
        title={t('callflows.editWelcomeAttendantInteraction.retryStep')}
        stepOptions={stepOptions}
        noStartStep
        tooltip={{
          title: t('callflows.editWelcomeAttendantInteraction.retryStepTooltipTitle'),
          text: t('callflows.editWelcomeAttendantInteraction.retryStepTooltipText')
        }}
      />

      <IntegerField
        field="retryCount"
        title={t('callflows.editWelcomeAttendantInteraction.retryCount')}
        inputDescription={t('callflows.editWelcomeAttendantInteraction.retryCount')}
        postFixDescription={t('callflows.editWelcomeAttendantInteraction.retryCountPostFix')}
        shouldValidate
        tooltip={t('callflows.editWelcomeAttendantInteraction.retryCountTooltip')}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(waInteractionSchema.fields.retryCount)
        )}
      />
    </EditCallflowDetails>
  );
};

export default EditInteractionDetails;
