import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPinLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 24a1 1 0 0 1-.84-.46C9 20.11 4 11.75 4 8.14A8.08 8.08 0 0 1 12 0a8.08 8.08 0 0 1 8 8.14c0 3.61-5 12-7.16 15.39a1 1 0 0 1-.63.45zm0-23a7.08 7.08 0 0 0-7 7.14c0 2.74 3.62 9.47 7 14.86 1.64-2.62 7-11.42 7-14.86A7.08 7.08 0 0 0 12 1m0 10.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7m0-6a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5"/></svg>)}
    />
  );
}
  