// @flow
import React, { Component, type Element } from 'react';
import { WithOutContext as ReactTags } from 'react-tag-input';
import IconCloseRegular from '@design-system/component-library/src/components/Icon/lib/IconCloseRegular';
import classNames from 'classnames';
import styles from './KeywordInput.module.scss';

type StatePropsT = {};
export type KeywordT = {| +id: string, +text: string |};

export type PropsT = {
  initialValues: KeywordT[],
  onChange: (KeywordT[]) => void,
  maxLength: number,
  maxWordLength?: number,
  placeholder: string,
  disabled?: boolean,
  className?: string
} & StatePropsT;

type StateT = {
  keywords: KeywordT[]
};

export class KeywordInput extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      keywords: this.props.initialValues
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  handleDelete: *;

  handleDelete(removeId: string) {
    this.setState(
      {
        keywords: this.state.keywords.filter(tag => tag.id !== removeId)
      },
      () => this.props.onChange(this.state.keywords)
    );
  }

  handleAddition: KeywordT => void;

  handleAddition(keywordObj: KeywordT) {
    this.setState(
      state => ({
        keywords: [...state.keywords, { id: `${keywordObj.id}`, text: keywordObj.text }]
      }),
      () => this.props.onChange(this.state.keywords)
    );
  }

  handleDrag: (KeywordT, number, number) => void;

  handleDrag(keywordObj: KeywordT, currPos: number, newPos: number) {
    const keywords = [...this.state.keywords];

    // mutate array
    keywords.splice(currPos, 1);
    keywords.splice(newPos, 0, keywordObj);

    this.setState({ keywords }, () => this.props.onChange(this.state.keywords));
  }

  render(): Element<*> {
    const { maxLength, placeholder, maxWordLength, disabled } = this.props;
    const { keywords } = this.state;

    const RemoveComponent = props => {
      // eslint-disable-next-line react/no-this-in-sfc
      const onClick = () => this.handleDelete(props.tag.id);
      return (
        <IconCloseRegular
          color="white"
          size="s"
          className={styles['close-icon']}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={onClick.bind(this)}
          role="button"
          tabIndex="0"
          // eslint-disable-next-line react/jsx-no-bind
          onKeyPress={onClick.bind(this)}
        />
      );
    };

    const currentLength = keywords.map(k => k.text).join(',').length;
    let charsLeftLength =
      currentLength < maxLength ? maxLength - (currentLength + (keywords.length ? 1 : 0)) : 0;

    if (charsLeftLength && maxWordLength) {
      if (charsLeftLength >= maxWordLength) {
        charsLeftLength = maxWordLength;
      }
    }

    return (
      <div
        className={classNames(styles['tags-container'], this.props.className, {
          [styles['tags-container--disabled']]: disabled
        })}
      >
        <ReactTags
          placeholder={placeholder}
          autofocus={false}
          inline={false}
          tags={keywords}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          removeComponent={RemoveComponent}
          maxLength={charsLeftLength}
          readOnly={disabled}
        />
        <div className={styles['length-info']}>{`${currentLength} / ${maxLength}`}</div>
      </div>
    );
  }
}

export default KeywordInput;
