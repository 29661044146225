// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CalendarField from '../../../components/edit/children/calendar/CalendarField';
import RadioButton from '../../../components/edit/children/RadioButton';
import type { OptionsT } from '../../../components/edit/children/RadioButton';

export type PropsT = {|
  typeField: string,
  calendarField: string
|};

export const CalendarWithRadio = (props: PropsT): Element<'div'> => {
  const { typeField, calendarField } = props;
  const { t } = useTranslation();

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  const calendarTypeOptions: OptionsT = [
    {
      label: t('callflows.editExtensionGroupCalendar.calendarTypeAlways'),
      value: 'always'
    },
    {
      label: t('callflows.editExtensionGroupCalendar.calendarTypeByCalendar'),
      value: 'byCalendar'
    }
  ];

  return (
    <div>
      <RadioButton
        field={typeField}
        title={t('callflows.editExtensionGroupCalendar.calendarType')}
        options={calendarTypeOptions}
        onChange={() =>
          setValue(calendarField, [], {
            shouldValidate: true,
            shouldDirty: true
          })
        }
      />

      {values[typeField] === 'byCalendar' ? (
        <CalendarField
          field={calendarField}
          title={t('callflows.editExtensionGroupCalendar.calendarTitle')}
          singleSlotPerDay
        />
      ) : null}
    </div>
  );
};

export default CalendarWithRadio;
