// @flow

import classNames from 'classnames';
import React, { type Element } from 'react';
import * as R from 'ramda';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow.svg';
import type { TranslateT } from '../../../commonTypes';
import type { EnterpriseStateEntityT } from '../../../ducks/entities/enterprise';
import type { TwoPartForwardingTextRepresentationTypeT } from '../../../helpers';
import { getTextRepresentation } from '../../../helpers';
import { Phone, PhoneLarge, TabletAndUp } from '../../../Responsive';
import { ModifyLink } from './ModifyLink';
import type { CallForwardingT } from '../../../ducks/entities/callForwarding/callForwardingTypes';
import { compareForwardingPriority } from '../forwardingPriorityUtils';

import styles from './EnterpriseForwardingsTable.module.scss';

export type RowPropsT = {
  enterpriseId: string,
  forwarding: CallForwardingT,
  translate: TranslateT<>,
  type: 'phone' | 'phoneLarge' | 'tabletAndUp',
  onForwardingActivityChange: () => Promise<void>,
  onDeleteForwarding: () => void
};

export const Row = (props: RowPropsT): Element<'tr'> | null => {
  const {
    forwarding,
    translate,
    type,
    enterpriseId,
    onForwardingActivityChange,
    onDeleteForwarding
  } = props;
  // $FlowFixMe
  const texts: TwoPartForwardingTextRepresentationTypeT = getTextRepresentation(
    // $FlowFixMe
    forwarding,
    'twoPart',
    translate
  );
  const isDisabled = forwarding.isActive ? '' : styles.disabled;
  const label = forwarding.label ? forwarding.label : translate('forwardings.noLabel');
  const enterpriseLabel = (
    <span
      className={classNames(styles['text-label'], {
        [styles['text-label--missing']]: !forwarding.label
      })}
    >
      {label}
    </span>
  );
  switch (type) {
    case 'phone':
      return (
        <tr key={forwarding.id}>
          <td className={classNames(styles.reason, isDisabled)}>
            {enterpriseLabel}
            {texts.conditions}
            <br />
            <ArrowIcon className={styles.arrow} />
            {texts.destination}
          </td>
          <td>
            <ModifyLink
              enterpriseId={enterpriseId}
              forwarding={{ ...forwarding, isEditable: false }}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
            />
          </td>
        </tr>
      );
    case 'phoneLarge':
      return (
        <tr key={forwarding.id}>
          <td className={classNames(styles.avatar, isDisabled)}>
            <img
              className={styles['forwarding-icon']}
              src="/call_forwardings.svg"
              alt="call-forwarding"
            />
          </td>
          <td className={classNames(styles.reason, isDisabled)}>
            {enterpriseLabel}
            {texts.conditions}
            <br />
            <ArrowIcon className={styles.arrow} />
            {texts.destination}
          </td>
          <td>
            <ModifyLink
              enterpriseId={enterpriseId}
              forwarding={{ ...forwarding, isEditable: false }}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
            />
          </td>
        </tr>
      );
    case 'tabletAndUp':
      return (
        <tr key={forwarding.id} id={`enterpise-forwarding-${forwarding.id}`}>
          <td className={styles.avatar}>
            <img
              className={styles['forwarding-icon']}
              src="/call_forwardings.svg"
              alt="call-forwarding"
            />
          </td>
          <td className={classNames(styles.forwarding)}>
            <span className={classNames(styles.reason, isDisabled)}>
              {enterpriseLabel}
              {texts.conditions}
            </span>
            <span className={classNames(styles.arrow, isDisabled)}>
              <ArrowIcon className={styles.arrow} />
            </span>
            <span className={classNames(styles.destination, isDisabled)}>{texts.destination}</span>
          </td>
          <td className={styles.modify}>
            <ModifyLink
              enterpriseId={enterpriseId}
              forwarding={{ ...forwarding, isEditable: false }}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
            />
          </td>
        </tr>
      );
    default:
      return null;
  }
};

export type PropsT = {
  enterpriseId: string,
  forwardings: $PropertyType<EnterpriseStateEntityT, 'forwardings'>,
  translate: TranslateT<>,
  onForwardingActivityChange: () => Promise<void>,
  onDeleteForwarding: () => void
};

export const EnterpriseForwardingsTable = (props: PropsT): Element<'table'> => {
  const {
    forwardings = [],
    translate,
    enterpriseId,
    onForwardingActivityChange,
    onDeleteForwarding
  } = props;
  const entFwds = R.sort(compareForwardingPriority, forwardings);

  return (
    <table className={styles['forwarding-table']}>
      <tbody>
        <Phone>
          {entFwds.map(forwarding => (
            <Row
              enterpriseId={enterpriseId}
              forwarding={forwarding}
              translate={translate}
              type="phone"
              key={forwarding.id}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
            />
          ))}
        </Phone>
        <PhoneLarge>
          {entFwds.map(forwarding => (
            <Row
              enterpriseId={enterpriseId}
              forwarding={forwarding}
              translate={translate}
              type="phoneLarge"
              key={forwarding.id}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
            />
          ))}
        </PhoneLarge>
        <TabletAndUp>
          {entFwds.map(forwarding => (
            <Row
              enterpriseId={enterpriseId}
              forwarding={forwarding}
              translate={translate}
              type="tabletAndUp"
              key={forwarding.id}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
            />
          ))}
        </TabletAndUp>
      </tbody>
    </table>
  );
};

export default EnterpriseForwardingsTable;
