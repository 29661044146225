// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UnhandledIcon } from '../../../../../assets/callflow/details/speeddialling-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';

export type PropsT = {|
  callflowId: string
|};

export const CalendarClosedDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};

  const acdAudioFieldConverter = new AudioFieldConverter(
    acdData.enterpriseId,
    acdData.id,
    'acds',
    'ACDCallCenter'
  );

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_calendar_closed_${acdData.id}`}
      nodeType="ACD_CALENDAR_CLOSED"
      icon={<UnhandledIcon />}
      title={t('callflows.viewAcdCalendarClosed.title')}
      active
    >
      <CallflowDetailsColumn id="calendar-closed-column-1">
        <CallflowProperty
          id="closingFwd"
          title={t('callflows.viewAcdCalendarClosed.closingFwd')}
          property={ivrAcdCallCenter.closingFwd}
        />
        <CallflowAudioProperty
          title={t('callflows.viewAcdCalendarClosed.closingMsg')}
          audioField={acdAudioFieldConverter.convertToAudioPropertyField(
            'ClosingMsg',
            R.path(['audios', 'closingMsg', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['closingMsg'], ivrAcdCallCenter)
          )}
          isActive
        />

        <CallflowProperty
          id="exceptionalClosing"
          title={t('callflows.viewAcdCalendarClosed.exceptionalClosing')}
          booleanInUseMsg={t('callflows.viewAcdCalendarClosed.exceptionalClosingTrue')}
          booleanNotInUseMsg={t('callflows.viewAcdCalendarClosed.exceptionalClosingFalse')}
          property={acdCallCenter.exceptionalClosing}
          basicValue
        />
        {acdCallCenter.exceptionalClosing && (
          <CallflowAudioProperty
            title={t('callflows.viewAcdCalendarClosed.exceptionalClosingMsg')}
            audioField={acdAudioFieldConverter.convertToAudioPropertyField(
              'ExceptionalClosingMsg',
              R.path(['audios', 'exceptionalClosingMsg', 'extensionAudio', 'name'], acdCallCenter),
              R.path(['exceptionalClosingMsg'], ivrAcdCallCenter)
            )}
            isActive
          />
        )}
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default CalendarClosedDetails;
