import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailSentLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.999 15.995c0-.029-.012-.058-.018-.087-.007-.037-.01-.075-.025-.108l-.001-.004-3.16-7.047A1.51 1.51 0 0 0 18.5 8h-2.953a.5.5 0 0 0 0 1h2.952a.5.5 0 0 1 .408.205l2.821 6.295h-5.182a.5.5 0 0 0-.5.5v.999a1.51 1.51 0 0 1-1.5 1.501H9.548A1.51 1.51 0 0 1 8.047 17v-1a.5.5 0 0 0-.5-.5H2.323L5.57 9.25A.5.5 0 0 1 6 9h2.546a.5.5 0 0 0 0-1H5.999a1.51 1.51 0 0 0-1.306.77l-3.637 7-.005.019c-.014.03-.019.063-.027.096s-.018.064-.02.097c0 .006-.004.011-.004.018v5.5c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5V16zM22 21.5c0 .275-.225.5-.5.5h-19a.5.5 0 0 1-.5-.5v-5h5.046v.502a2.51 2.51 0 0 0 2.5 2.498h5.001a2.51 2.51 0 0 0 2.499-2.5v-.5H22zM7.646 5.854a.5.5 0 0 1 0-.707l3.999-3.999a.496.496 0 0 1 .708 0l3.999 3.999a.5.5 0 0 1-.706.707L12.5 2.707V12.5a.5.5 0 0 1-1 0V2.707L8.354 5.854a.5.5 0 0 1-.708 0"/></svg>)}
    />
  );
}
  