// @flow

import * as R from 'ramda';
import type { RetrieveUserCollectionActionT } from '../../entities/user/userTypes';
import type { StateT, ActionT } from './userUiTypes';

const defaultColumnsToShow = {
  avatar: { show: true },
  availability: { show: true },
  firstName: { show: true },
  lastName: { show: true },
  title: { show: false },
  phoneNumbers: { show: true },
  addressNumber: { show: true },
  emails: { show: false }
};

export const initialState: StateT = {
  userColumnsToShow: defaultColumnsToShow,
  recentIds: {},
  selectedCategory: 'INTERNAL_CONTACTS',
  userList: {
    allIds: [],
    __metadata: {}
  },
  searchTerm: ''
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: StateT = initialState,
  action: ActionT | RetrieveUserCollectionActionT
): StateT => {
  switch (action.type) {
    case 'ring/ui/user/UPDATE_SELECTED_CATEGORY': {
      return {
        ...state,
        selectedCategory: action.payload.selectedCategory
      };
    }
    case 'ring/ui/user/UPDATE_USER_COLUMNS': {
      const {
        payload: { userColumnsToShow }
      } = action;
      return {
        ...state,
        userColumnsToShow
      };
    }
    case 'ring/entity/user/RETRIEVE_COLLECTION_REQUEST': {
      // page 1 means a new user list has been requested and the old one gets removed
      if (action.meta.page === 1) {
        return {
          ...state,
          userList: {
            allIds: [],
            __metadata: {
              isRetrieving: true
            }
          }
        };
      }
      return {
        ...state,
        userList: {
          ...state.userList,
          __metadata: {
            isRetrieving: true
          }
        }
      };
    }
    case 'ring/entity/user/RETRIEVE_COLLECTION_SUCCESS': {
      const ids = R.map(R.prop('id'), action.payload);
      return {
        ...state,
        userList: {
          allIds: R.union(state.userList.allIds, ids),
          __metadata: {
            hasMore: action.meta.length === action.payload.length
          }
        }
      };
    }
    case 'ring/entity/user/RETRIEVE_COLLECTION_FAILURE': {
      return {
        ...state,
        userList: {
          ...state.userList,
          __metadata: {
            error: action.payload
          }
        }
      };
    }
    case 'ring/ui/user/UPDATE_SEARCH_TERM': {
      const {
        payload: { searchTerm }
      } = action;
      return {
        ...state,
        searchTerm
      };
    }
    default:
      return state;
  }
};
