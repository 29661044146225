// @flow

import React, { type Element } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import TableHeader, { type TableHeaderColumnT, type TableSortT } from './TableHeader';
import TableRow, {
  type HoverButtonT,
  type OnSelectFnT,
  type TableCellT,
  type TableRowItemT
} from './TableRow';
import { LaptopAndUp, Phone, PhoneLargeAndTablet, PhoneLargeAndUp } from '../../Responsive';
import SmallTableRow from './SmallTableRow';
import MediumTableRow from './MediumTableRow';
import LargeTableRow from './LargeTableRow';
import styles from './Table.module.scss';

export type MapItemToCellFnT = (TableHeaderColumnT, TableRowItemT) => TableCellT;
type GetTableRowsFnT = (
  TableRowItemT[],
  MapItemToCellFnT,
  TableHeaderColumnT[],
  boolean,
  ?HoverButtonT,
  ?OnSelectFnT,
  boolean
) => Element<'div'>[];

export type PropsT = {
  id: string,
  columns: TableHeaderColumnT[],
  rowHoverEnabled?: boolean,
  hoverButton?: HoverButtonT,
  allowLoadMore?: boolean,
  items: TableRowItemT[],
  mapItemToCell?: MapItemToCellFnT,
  onInfiniteScrollLoadMore?: (page: number, sort?: TableSortT) => void | Promise<void>,
  onSelectRow?: OnSelectFnT,
  onSort?: (sort: TableSortT) => void,
  showIfEmpty?: Element<*>,
  cornerElement?: Element<*>,
  sort?: TableSortT,
  tableStyle?: string,
  infiniteScrollInitialLoad?: boolean
};

const getTableRows: GetTableRowsFnT = (
  rows,
  mapCell,
  columns,
  hoverEnabled,
  hoverButton,
  onSelectRow,
  isSmallRow
) =>
  rows.map((row, index) => {
    const headerColumns: TableHeaderColumnT[] = [
      {
        columnId: 'avatar',
        text: '',
        size: 'x-small'
      },
      {
        columnId: 'name',
        text: '',
        size: 'medium'
      }
    ];
    const headerRow = (
      <TableRow
        id={`${row.rowId}_header`}
        cells={headerColumns.map(column => mapCell(column, row))}
        headerCells={headerColumns}
        key={
          `cl_${row.rowId}_${index}_header` // eslint-disable-line react/no-array-index-key
        }
        onSelect={onSelectRow}
        hoverEnabled={hoverEnabled}
        hoverButton={hoverButton}
        rowItem={row}
      />
    );

    const insideRow = (
      <div id={row.rowId} key={row.rowId}>
        <Phone>
          <SmallTableRow
            id={row.rowId}
            key={
              `cl_${row.rowId}_${index}` // eslint-disable-line react/no-array-index-key
            }
            onSelect={onSelectRow}
            rowItem={row}
            columns={['rules', 'destination']}
          />
        </Phone>
        <PhoneLargeAndTablet>
          <MediumTableRow
            id={row.rowId}
            key={
              `cl_${row.rowId}_${index}` // eslint-disable-line react/no-array-index-key
            }
            onSelect={onSelectRow}
            rowItem={row}
            columns={['rules', 'destination']}
          />
        </PhoneLargeAndTablet>
        <LaptopAndUp>
          <div key={`${row.rowId}_avatar`} className={styles['user-avatar']}>
            {row.avatar}
          </div>
          <LargeTableRow
            id={`row-${row.rowId}-large-table-row-container`}
            key={
              `cl_${row.rowId}_${index}` // eslint-disable-line react/no-array-index-key
            }
            onSelect={onSelectRow}
            rowItem={row}
            columns={['rules', 'destination']}
          />
        </LaptopAndUp>
      </div>
    );

    const smallRowData = (
      <AccordionItem id={row.rowId} key={row.rowId} heading={headerRow}>
        {insideRow}
      </AccordionItem>
    );

    return isSmallRow ? smallRowData : insideRow;
  });

const defaultMapItemToCell: MapItemToCellFnT = ({ columnId, size }, item) => ({
  value: item[columnId],
  size,
  valueClasses: item.rowClasses
});

const Table = ({
  id,
  columns = [],
  cornerElement,
  rowHoverEnabled,
  hoverButton,
  allowLoadMore,
  items = [],
  mapItemToCell,
  onInfiniteScrollLoadMore,
  onSelectRow,
  onSort,
  showIfEmpty,
  sort,
  tableStyle,
  infiniteScrollInitialLoad
}: PropsT): Element<'div'> => {
  if (!items.length && showIfEmpty) {
    return showIfEmpty;
  }
  const rowComponents = getTableRows(
    items,
    mapItemToCell || defaultMapItemToCell,
    columns,
    rowHoverEnabled || false,
    hoverButton,
    onSelectRow,
    false
  );

  const smallRowComponents = getTableRows(
    items,
    mapItemToCell || defaultMapItemToCell,
    columns,
    rowHoverEnabled || false,
    hoverButton,
    onSelectRow,
    true
  );

  const showRows = rowComponents.length > 0;
  const extendedRowComponents = (
    <div>
      <Phone>
        <Accordion className={styles.accordion}>{smallRowComponents}</Accordion>
      </Phone>
      <PhoneLargeAndUp>{rowComponents}</PhoneLargeAndUp>
    </div>
  );

  const rowsElement = onInfiniteScrollLoadMore ? (
    <InfiniteScroll
      // This component started to fetch more pages than necessary when hasMore
      // was passed as true unless we know it's false. Hence allowLoadMore.
      hasMore={allowLoadMore}
      loadMore={onInfiniteScrollLoadMore}
      initialLoad={infiniteScrollInitialLoad}
      useWindow
      pageStart={1}
    >
      {extendedRowComponents}
    </InfiniteScroll>
  ) : (
    extendedRowComponents
  );
  return (
    <div className={classNames(styles.container, tableStyle)}>
      {cornerElement && (
        <div className={styles.corner}>
          <div className={styles['corner-element']}>{cornerElement}</div>
        </div>
      )}
      <div className={styles.table} id={id}>
        <TableHeader columns={columns} onSort={onSort} sort={sort} />
        {showRows && rowsElement}
      </div>
    </div>
  );
};

Table.defaultProps = {
  rowHoverEnabled: false,
  hoverButton: undefined,
  allowLoadMore: false,
  mapItemToCell: undefined,
  onInfiniteScrollLoadMore: undefined,
  onSelectRow: undefined,
  onSort: undefined,
  showIfEmpty: undefined,
  cornerElement: undefined,
  sort: undefined,
  tableStyle: '',
  infiniteScrollInitialLoad: true
};

export default Table;
