import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailAddressFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.691 5.355C18.025.012 10.697-1.354 5.355 2.311s-6.708 10.992-3.044 16.334c2.275 3.316 5.961 5.101 9.708 5.101 2.289 0 4.601-.666 6.627-2.055a1.251 1.251 0 0 0-1.414-2.062c-4.205 2.885-9.975 1.809-12.859-2.397-2.885-4.207-1.81-9.975 2.397-12.86 4.207-2.882 9.975-1.808 12.859 2.397a9.2 9.2 0 0 1 1.621 5.224v.912c0 1.071-.872 1.943-1.943 1.943s-1.942-.872-1.942-1.943v-.912a5.36 5.36 0 0 0-5.355-5.355 5.36 5.36 0 0 0-5.355 5.355 5.36 5.36 0 0 0 5.355 5.355c1.52 0 2.886-.643 3.861-1.663.815 1.006 2.044 1.663 3.437 1.663a4.45 4.45 0 0 0 4.443-4.443v-.913a11.7 11.7 0 0 0-2.06-6.637m-9.682 9.493c-1.574 0-2.855-1.28-2.855-2.855s1.281-2.855 2.855-2.855 2.855 1.281 2.855 2.855-1.281 2.855-2.855 2.855"/></svg>)}
    />
  );
}
  