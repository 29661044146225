"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VISIBLE = exports.HIDDEN = exports.stopEvent = exports.onlyVisible = exports.isVisible = exports.isValidSearchKey = void 0;
// Checks that only the single key is pressed with no modifiers, and that it's a normal alphanumeric key
var isValidSearchKey = function (e) { return !e.altKey && !e.ctrlKey && !e.metaKey && !!e.key.match(/^[a-zA-Z\däöåÄÖÅ]$/); };
exports.isValidSearchKey = isValidSearchKey;
var isVisible = function (elem) { return !!(elem === null || elem === void 0 ? void 0 : elem.getClientRects().length); };
exports.isVisible = isVisible;
// Filter out elements that are not visible on the page (i.e. they or their parent have a display: none or such)
// This still returns elements that are just out of the viewport
var onlyVisible = function (elements) { return [].slice.call((elements !== null && elements !== void 0 ? elements : [])).filter(exports.isVisible); };
exports.onlyVisible = onlyVisible;
var stopEvent = function (e) {
    e.preventDefault();
    e.stopPropagation();
};
exports.stopEvent = stopEvent;
exports.HIDDEN = 'hidden';
exports.VISIBLE = 'visible';
