// @flow

import type {
  UpdateEditStatusT,
  UpdateOpenStatusT,
  UpdateSelectedStatusT,
  SetViewModeT,
  ViewModeT,
  ResetGridViewT,
  SetCategoryT,
  SetSearchTermT
} from './callflowUiTypes';
import type { CallFlowTypeT } from '../../entities/callFlow/callFlowTypes';

type CreateUpdateOpenStatusFnT = (string, boolean) => UpdateOpenStatusT;
export const createUpdateOpenStatus: CreateUpdateOpenStatusFnT = (nodeId, isOpen) => ({
  type: 'ring/ui/callflow/UPDATE_OPEN_STATUS',
  payload: {
    nodeId,
    isOpen
  }
});

type CreateUpdateSelectedStatusFnT = (string, ?string, ?string, ?boolean) => UpdateSelectedStatusT;
export const createUpdateSelectedStatus: CreateUpdateSelectedStatusFnT = (
  nodeId,
  selectedType,
  selectedId,
  isEditMode
) => ({
  type: 'ring/ui/callflow/UPDATE_SELECTED_STATUS',
  payload: {
    nodeId,
    selectedType,
    selectedId,
    isEditMode
  }
});

type CreateUpdateEditStatusFnT = (string, boolean) => UpdateEditStatusT;
export const createUpdateEditStatus: CreateUpdateEditStatusFnT = (nodeId, editStatus) => ({
  type: 'ring/ui/callflow/UPDATE_EDIT_STATUS',
  payload: {
    nodeId,
    editStatus
  }
});

export type CreateSetViewModeFnT = ViewModeT => SetViewModeT;
export const createSetViewMode: CreateSetViewModeFnT = viewMode => ({
  type: 'ring/ui/callflow/SET_VIEW_MODE',
  payload: {
    viewMode
  }
});

export type CreateResetGridViewFnT = () => ResetGridViewT;
export const createResetGridView: CreateResetGridViewFnT = () => ({
  type: 'ring/ui/callflow/RESET_GRID_VIEW',
  payload: {}
});

export type CreateSetCategoryFnT = CallFlowTypeT => SetCategoryT;
export const createSetCategory: CreateSetCategoryFnT = category => ({
  type: 'ring/ui/callflow/SET_CATEGORY',
  payload: {
    category
  }
});

export type CreateSetSearchTermFnT = string => SetSearchTermT;
export const createSetSearchTerm: CreateSetSearchTermFnT = searchTerm => ({
  type: 'ring/ui/callflow/SET_SEARCH_TERM',
  payload: {
    searchTerm
  }
});

export default createUpdateEditStatus;
