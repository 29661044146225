import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAccessoryFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.227 14.25c-1.351 0-2.425-1.222-2.196-2.63.18-1.106 1.211-1.87 2.32-1.87h3.092a.56.56 0 0 1 .557.562v3.375a.56.56 0 0 1-.557.562h-3.216zm-3.429-2.833a3.5 3.5 0 0 0 .784 2.846 3.5 3.5 0 0 0 2.668 1.236h2.25a.5.5 0 0 1 .5.5v4.5a3.5 3.5 0 0 1-3.5 3.5H6.497A1.495 1.495 0 0 1 5 22.503V1.497C5 .67 5.67 0 6.497 0H15.5A3.5 3.5 0 0 1 19 3.5V8a.5.5 0 0 1-.5.5h-2.124c-1.766 0-3.304 1.254-3.578 2.917m.702-7.792a.625.625 0 0 0 0-1.25h-3a.625.625 0 0 0 0 1.25z"/></svg>)}
    />
  );
}
  