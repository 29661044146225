import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPinterestLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.672 17.47s-.61 2.29-.75 2.86a13 13 0 0 1-1.5 3.15.37.37 0 0 1-.49.16c-.24-.1-.31-.47-.31-.47a12.2 12.2 0 0 1 0-3.45l1.41-6a4.35 4.35 0 0 1-.32-1.72c0-1.67 1-2.91 2.17-2.91a1.51 1.51 0 0 1 1.53 1.69 24.7 24.7 0 0 1-1 4 1.74 1.74 0 0 0 1.78 2.17c2.13 0 3.769-2.25 3.769-5.49a4.74 4.74 0 0 0-4.999-4.88 5.19 5.19 0 0 0-5.419 5.2c-.002.985.31 1.944.89 2.74a.35.35 0 0 1 .08.34c-.09.38-.3 1.2-.34 1.37s-.18.26-.4.16c-1.51-.71-2.44-2.89-2.44-4.66 0-3.78 2.74-7.26 7.929-7.26 4.159 0 7.389 3 7.389 6.94 0 4.14-2.6 7.46-6.219 7.46a3.18 3.18 0 0 1-2.76-1.4M12.002 0C5.375-.002.002 5.369 0 11.996A12 12 0 0 0 5.743 22.24a.51.51 0 0 0 .69-.17.49.49 0 0 0-.17-.68c-5.185-3.164-6.824-9.934-3.66-15.12S12.535-.555 17.72 2.61s6.824 9.934 3.66 15.12A11 11 0 0 1 12.002 23q-.722 0-1.44-.09a.5.5 0 0 0-.56.43.51.51 0 0 0 .44.56 12 12 0 0 0 1.56.1C18.628 24 24 18.627 24 12S18.628 0 12.002 0"/></svg>)}
    />
  );
}
  