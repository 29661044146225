import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNetworkRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 14.75c-1.347 0-2.536.642-3.315 1.623l-6.661-3.568c.134-.414.226-.847.226-1.305a4.2 4.2 0 0 0-.155-1.074l6.434-2.988A4.24 4.24 0 0 0 19 9.25c2.344 0 4.25-1.907 4.25-4.25S21.344.75 19 .75 14.75 2.657 14.75 5c0 .373.064.729.155 1.075L8.471 9.062A4.24 4.24 0 0 0 5 7.25 4.255 4.255 0 0 0 .75 11.5 4.255 4.255 0 0 0 5 15.75c1.347 0 2.536-.642 3.315-1.623l6.661 3.568A4.2 4.2 0 0 0 14.75 19c0 2.344 1.906 4.25 4.25 4.25s4.25-1.906 4.25-4.25-1.906-4.25-4.25-4.25m0-12.5c1.517 0 2.75 1.233 2.75 2.75S20.517 7.75 19 7.75 16.25 6.517 16.25 5 17.483 2.25 19 2.25m-14 12c-1.517 0-2.75-1.233-2.75-2.75S3.483 8.75 5 8.75s2.75 1.233 2.75 2.75S6.517 14.25 5 14.25m14 7.5c-1.517 0-2.75-1.233-2.75-2.75s1.233-2.75 2.75-2.75 2.75 1.233 2.75 2.75-1.233 2.75-2.75 2.75"/></svg>)}
    />
  );
}
  