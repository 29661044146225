// @flow strict-local

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarSection from './CalendarSection/CalendarSection';
import type { BaseCalendarEntityT } from '../../../../../../ducks/entities/calendar/calendarTypes';
import { enterpriseCalendarRegExp } from '../../../../../../helpers';

import styles from './WeekDayCalendar.module.scss';

type PropsT = {|
  title: string,
  selectedCalendar: ?BaseCalendarEntityT,
  // eslint-disable-next-line react/no-unused-prop-types
  active?: boolean,
  hideCalendarName?: boolean
|};

export const WeekDayCalendar = (props: PropsT): Element<'div'> => {
  const { title, selectedCalendar, hideCalendarName } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.container} data-cy="weekday-calendar">
      <div className={styles['calendar-details']}>
        <div className={styles.header} data-cy="weekday-calendar-title">
          {title}
        </div>
        {!hideCalendarName && (
          <div className={styles.value} data-cy="weekday-calendar-name">
            {selectedCalendar && selectedCalendar.name
              ? selectedCalendar.name.replace(enterpriseCalendarRegExp, ' ')
              : t('callflows.valueNotSet')}
          </div>
        )}
      </div>
      {selectedCalendar && <CalendarSection calendar={selectedCalendar} />}
    </div>
  );
};

export default WeekDayCalendar;
