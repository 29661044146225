// @flow
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import IconLogoutRegular from '@design-system/component-library/src/components/Icon/lib/IconLogoutRegular';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router-for-react18';
import useInterval from '../../utils/useInterval';
import ModalPopOver from '../ModalPopOver';
import { actionCreators as action } from '../../ducks/currentUser';
import { createRedirectParam } from '../../helpers';

import styles from './IdleTimeOutModal.module.scss';

const IdleTimeOutModal = () => {
  const FIVE_MINUTES = 300000;
  const SECOND = 1000;
  const [timeout, setTimeout] = useState(FIVE_MINUTES);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  useInterval(() => {
    setTimeout(timeout - SECOND);
    if (timeout === 0) {
      dispatch(action.createLogoutSuccess());
      dispatch(push(`/login${createRedirectParam()}`));
    }
  }, SECOND);

  useEffect(() => {
    setTimeout(FIVE_MINUTES);
    return () => {};
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalPopOver
      isOpen
      location={{ x: window.innerWidth / 2 - 100, y: window.innerHeight / 2 - 200 }}
      locationType="popOver"
      contentLabel="popOver"
      withArrow={false}
    >
      <div className={styles.dialog}>
        <IconLogoutRegular className={styles['dropdown-icon']} />
        {t('idleTimeout.timeOutLeft', { timeoutLeft: moment.utc(timeout).format('mm:ss') })}
      </div>
    </ModalPopOver>
  );
};

export default IdleTimeOutModal;
