// @flow

import React from 'react';
import type { AvailabilityT } from '../../ducks/entities/user/userTypes';
import { ReactComponent as Dnd } from '../../assets/dnd.svg';
import styles from './AvailabilityIndicator.module.scss';

type PropsT = {
  status: AvailabilityT,
  size: 'xsmall' | 'small' | 'medium' | 'large',
  className?: string
};

const AvailabilityIndicator = (props: PropsT) => {
  const classNames = [
    styles['availability-indicator'],
    styles[`${props.status.toLowerCase()}`],
    styles[`${props.size}`],
    props.className
  ];
  return <div className={classNames.join(' ')}>{props.status === 'dnd' && <Dnd />}</div>;
};

AvailabilityIndicator.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  status: 'grey',
  // eslint-disable-next-line react/default-props-match-prop-types
  size: 'small'
};

export default AvailabilityIndicator;
