import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCartRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 23.25c-1.517 0-2.75-1.233-2.75-2.75s1.233-2.75 2.75-2.75 2.75 1.233 2.75 2.75-1.233 2.75-2.75 2.75m0-4c-.689 0-1.25.561-1.25 1.25s.561 1.25 1.25 1.25 1.25-.561 1.25-1.25-.561-1.25-1.25-1.25m-7 4c-1.517 0-2.75-1.233-2.75-2.75s1.233-2.75 2.75-2.75 2.75 1.233 2.75 2.75-1.233 2.75-2.75 2.75m0-4c-.689 0-1.25.561-1.25 1.25s.561 1.25 1.25 1.25 1.25-.561 1.25-1.25-.561-1.25-1.25-1.25m10.837-4.671 2.381-7.862a.752.752 0 0 0-.718-.967H8.25a.75.75 0 0 0 0 1.5h13.239l-2.095 6.919a.784.784 0 0 1-.758.581H8.579a.79.79 0 0 1-.764-.603L4.508 3.016A3.05 3.05 0 0 0 1.563.75H.75a.75.75 0 0 0 0 1.5h.812c.701 0 1.316.473 1.502 1.171l3.307 11.133a2.29 2.29 0 0 0 2.207 1.695h10.058a2.276 2.276 0 0 0 2.201-1.67"/></svg>)}
    />
  );
}
  