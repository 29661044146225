import React from 'react';
import IconContainer from '../IconContainer';

export default function IconViewRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.831 19.75c-4.32.001-8.622-3.138-11.217-6.103a2.52 2.52 0 0 1-.001-3.294C3.239 7.355 7.599 4.138 12 4.253l.144-.001c4.343 0 8.646 3.136 11.243 6.099.818.941.818 2.356.003 3.295-2.6 2.967-6.902 6.105-11.247 6.105zm.02-13.999c-3.842 0-7.732 2.874-10.109 5.588-.32.369-.32.951.002 1.322 2.376 2.714 6.267 5.589 10.109 5.589h.267c3.867 0 7.76-2.876 10.139-5.591a1.025 1.025 0 0 0-.002-1.322c-2.378-2.712-6.271-5.586-10.11-5.586L12 5.752c-.046.001-.1-.001-.149-.001m.15 10.749h-.002A4.505 4.505 0 0 1 7.5 11.999 4.506 4.506 0 0 1 12 7.501h.002a4.47 4.47 0 0 1 3.18 1.316 4.47 4.47 0 0 1 1.318 3.181v.003a4.47 4.47 0 0 1-1.319 3.181 4.46 4.46 0 0 1-3.18 1.318m.001-7.499H12c-1.653 0-2.999 1.346-3 2.999s1.345 2.999 3 3h.001A3.003 3.003 0 0 0 15 12.001v-.003c0-.801-.313-1.555-.879-2.12a2.98 2.98 0 0 0-2.119-.877m3.748 3.001h.01z"/></svg>)}
    />
  );
}
  