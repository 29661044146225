import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGamingRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.21 6.75H4.75A4.75 4.75 0 0 0 0 11.5v10.17A2.34 2.34 0 0 0 2.33 24h.37c1.51 0 1.71-.36 3-2.68.43-.77 1-1.81 1.81-3.2a2.28 2.28 0 0 1 2-1.12h5.04a2.27 2.27 0 0 1 1.94 1.11c.81 1.4 1.39 2.44 1.81 3.22 1.27 2.31 1.47 2.67 3.08 2.67h.23a2.33 2.33 0 0 0 1.69-.69 2.36 2.36 0 0 0 .71-1.68V11.5a4.75 4.75 0 0 0-4.75-4.75zm3.25 14.88a.9.9 0 0 1-.26.62.9.9 0 0 1-.62.25h-.23c-.77 0-.74 0-1.78-1.89-.43-.79-1-1.84-1.84-3.26a3.75 3.75 0 0 0-3.23-1.85H9.46a3.78 3.78 0 0 0-3.21 1.86c-.82 1.4-1.4 2.45-1.84 3.23-1 1.91-1.08 1.91-1.69 1.91h-.36a.76.76 0 0 1-.61-.24.82.82 0 0 1-.25-.59V11.5a3.26 3.26 0 0 1 3.25-3.25h14.46a3.26 3.26 0 0 1 3.25 3.25zM8.5 12.5a.76.76 0 0 1-.75.75H6.5v1.25a.75.75 0 0 1-1.5 0v-1.25H3.75a.75.75 0 0 1 0-1.5H5V10.5a.75.75 0 0 1 1.5 0v1.25h1.25a.75.75 0 0 1 .75.75M5.75.75A.76.76 0 0 1 6.5 0h3a3.26 3.26 0 0 1 3.25 3.25V5a.75.75 0 0 1-1.5 0V3.25A1.76 1.76 0 0 0 9.5 1.5h-3a.76.76 0 0 1-.75-.75m12.25 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0m2.5-2.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/></svg>)}
    />
  );
}
  