// @flow

import * as R from 'ramda';
import React, { type Element, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { createUpdateSelectedStatus } from '../../../../../../ducks/ui/callflow/callflowUiActions';

import type {
  WelcomeAttendantEntityT,
  AudioEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import { updateWelcomeAttendant } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantOperations';
import { ReactComponent as WelcomeAnnouncementIcon } from '../../../../../../assets/callflow/details/welcome-announcement-small.svg';
import { uploadAudio } from '../../../../../../ducks/entities/callFlow/callFlowOperations';
import EditCallflowDetails from '../../../../components/edit/EditCallflowDetails';
import { MultiAudiosSelection } from '../../../../components/edit/children/audio/MultiAudiosSelection';
import type { MultiAudioFieldT } from '../../../../components/edit/children/audio/MultiAudiosSelection';
import WelcomeAttendantStepField from '../../../../components/edit/children/welcomeAttendantStepField/WelcomeAttendantStepField';
import {
  convertAudioNamesToAudioFields,
  getInitialNextStep,
  getNewStepData,
  getNextStepPayload,
  getWelcomeAttendantStepOptions,
  renameCommandName
} from '../WelcomeAttendantUtils';
import { InputField } from '../../../../components/edit/children/InputField';
import type { NextStepT } from '../WelcomeAttendantUtils';
import { getCommandNames, validateStepName } from '../../../../../../utils/validationUtils';
import type { WelcomeAttendantUpdateEntityT } from '../../../../../../ducks/entities/callFlow/callFlowTypes';
import { sanitizeAudioFilename } from '../../../../components/edit/children/audio/CallflowAudioUtils';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';

type PropsT = {|
  callflowId: string,
  commandId: string
|};

export type FormT = {
  stepName: string,
  audios: MultiAudioFieldT,
  nextStep: ?NextStepT
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

export const buildWelcomeAttendantMessageNodeUpdatePayload = (
  welcomeAttendantData: WelcomeAttendantEntityT,
  formData: FormT,
  commandName: string,
  audioNames?: (string | typeof undefined)[]
) => ({
  commands: {
    ...welcomeAttendantData.commands,
    ...getNewStepData(formData.nextStep),
    [commandName]: {
      ...welcomeAttendantData.commands[commandName],
      nextState: getNextStepPayload(formData.nextStep),
      audios: audioNames
    }
  }
});

const EditMessageDetails = (props: PropsT): Element<typeof EditCallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const welcomeAttendantData: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { enterpriseId } = welcomeAttendantData;
  const audioNode: ?AudioEntityT = R.path(['commands', commandId], welcomeAttendantData);
  if (!audioNode) {
    return null;
  }

  const stepOptions: NextStepT[] = getWelcomeAttendantStepOptions(welcomeAttendantData);
  const initialFormValues: FormT = {
    stepName: audioNode.name,
    audios: convertAudioNamesToAudioFields(audioNode.audios),
    nextStep: getInitialNextStep(stepOptions, audioNode.nextState.state)
  };

  const waMessageSchema = yup.object().shape({
    stepName: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, audioNode.name),
      [['nextStep', 'newStepName']]
    ),
    nextStep: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, audioNode.name),
      [['stepName']],
      ['newStepName']
    )
  });

  const uploadAudioByName = async (audioFile: File) => {
    await uploadAudio(
      enterpriseId,
      'welcomeattendants',
      welcomeAttendantData.id,
      { filename: sanitizeAudioFilename(audioFile.name) },
      audioFile,
      audioRequestCancelTokenSource.token,
      createCsrfHeader(currentUser)
    );
  };

  // update
  const onSubmit = async (formData: FormT): Promise<WelcomeAttendantEntityT> => {
    // Upload audios
    const promises = [];
    const audiosToBeUploaded = R.values(formData.audios).filter(audio => audio.file);
    audiosToBeUploaded.forEach(audio => audio.file && promises.push(uploadAudioByName(audio.file)));
    await Promise.all(promises);

    // Update WelcomeAttendant
    const commandName = audioNode.name;
    const audioNames = R.values(formData.audios)
      .filter(a => a.filename)
      .map(a => sanitizeAudioFilename(a.filename));

    const patchPayload: WelcomeAttendantUpdateEntityT = buildWelcomeAttendantMessageNodeUpdatePayload(
      welcomeAttendantData,
      formData,
      commandName,
      audioNames
    );

    const modifiedPayload = renameCommandName(
      patchPayload,
      commandName,
      formData.stepName.toUpperCase()
    );

    const returnValue = await dispatch(
      updateWelcomeAttendant(
        enterpriseId,
        welcomeAttendantData.id,
        modifiedPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
    dispatch(
      createUpdateSelectedStatus(
        welcomeAttendantData.id,
        'AUDIO',
        formData.stepName.toUpperCase(),
        false
      )
    );
    return returnValue;
  };

  return (
    <EditCallflowDetails
      nodeId={welcomeAttendantData.id}
      icon={<WelcomeAnnouncementIcon />}
      title={t('callflows.editWelcomeAttendantMessage.title')}
      description={t('callflows.editWelcomeAttendantMessage.description')}
      defaultValues={initialFormValues}
      validationSchema={waMessageSchema}
      onSaveForm={onSubmit}
    >
      <InputField
        field="stepName"
        title={t('callflows.welcomeAttendantGeneric.stepName')}
        shouldValidate
        tooltip={t('callflows.editWelcomeAttendantMessage.stepNameTooltip')}
      />
      <MultiAudiosSelection
        field="audios"
        description={t('callflows.editWelcomeAttendantMessage.musicWaitDescription')}
        enterpriseId={welcomeAttendantData.enterpriseId}
        callflowId={welcomeAttendantData.id}
      />

      <WelcomeAttendantStepField
        field="nextStep"
        title={t('callflows.editWelcomeAttendantMessage.nextStep')}
        stepOptions={stepOptions}
        noStartStep
        tooltip={{
          title: t('callflows.editWelcomeAttendantMessage.nextStepTooltipTitle'),
          text: t('callflows.editWelcomeAttendantMessage.nextStepTooltipText')
        }}
      />
    </EditCallflowDetails>
  );
};

export default EditMessageDetails;
