// @flow

import { type $AxiosError } from 'axios';
import type {
  LanguageRequestT,
  LanguageSuccessT,
  LanguageFailureT,
  LanguagesT,
  UserConfigRequestT,
  UserConfigSuccessT,
  UserConfigFailureT,
  UpdateUserConfigRequestT,
  UpdateUserConfigSuccessT,
  UpdateUserConfigFailureT,
  RemoveFavouriteRequestT,
  RemoveFavouriteSuccessT,
  RemoveFavouriteFailureT,
  UserConfigT,
  UpdateBulletinSuccessT,
  UpdateBulletinRequestT,
  UpdateBulletinFailureT,
  BulletinT,
  GetBulletinsFailureT,
  GetBulletinsSuccessT,
  GetBulletinsRequestT,
  CreateBulletinFailureT,
  CreateBulletinSuccessT,
  CreateBulletinRequestT,
  DeleteBulletinSuccessT,
  DeleteBulletinFailureT,
  DeleteBulletinRequestT,
  UpdateEnterpriseSettingsRequestT,
  UpdateEnterpriseSettingsSuccessT,
  UpdateEnterpriseSettingsFailureT,
  GetEnterpriseSettingsRequestT,
  GetEnterpriseSettingsSuccessT,
  GetEnterpriseSettingsFailureT,
  EnterpriseSettingsT
} from './configTypes';

export const createLanguageRequest = (): LanguageRequestT => ({
  type: 'ring/config/GET_LANGUAGE_REQUEST',
  error: false,
  meta: {}
});

export const createLanguageSuccess = (languages: LanguagesT): LanguageSuccessT => ({
  type: 'ring/config/GET_LANGUAGE_SUCCESS',
  payload: languages,
  error: false,
  meta: {}
});

export const createLanguageFailure = (error: $AxiosError<*>): LanguageFailureT => ({
  type: 'ring/config/GET_LANGUAGE_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createUserConfigRequest = (): UserConfigRequestT => ({
  type: 'ring/config/GET_USER_CONFIG_REQUEST',
  error: false,
  meta: {}
});

export const createUserConfigSuccess = (userConfig: UserConfigT): UserConfigSuccessT => ({
  type: 'ring/config/GET_USER_CONFIG_SUCCESS',
  payload: userConfig,
  error: false,
  meta: {}
});

export const createUserConfigFailure = (error: $AxiosError<*>): UserConfigFailureT => ({
  type: 'ring/config/GET_USER_CONFIG_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createUpdateUserConfigRequest = (
  userConfig: UserConfigT
): UpdateUserConfigRequestT => ({
  type: 'ring/config/UPDATE_USER_CONFIG_REQUEST',
  error: false,
  meta: userConfig
});

export const createUpdateUserConfigSuccess = (
  userConfig: UserConfigT
): UpdateUserConfigSuccessT => ({
  type: 'ring/config/UPDATE_USER_CONFIG_SUCCESS',
  payload: userConfig,
  error: false,
  meta: {}
});

export const createUpdateUserConfigFailure = (error: $AxiosError<*>): UpdateUserConfigFailureT => ({
  type: 'ring/config/UPDATE_USER_CONFIG_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createRemoveFavouriteRequest = (userConfig: UserConfigT): RemoveFavouriteRequestT => ({
  type: 'ring/config/REMOVE_FAVOURITE_REQUEST',
  error: false,
  meta: userConfig
});

export const createRemoveFavouriteSuccess = (userConfig: UserConfigT): RemoveFavouriteSuccessT => ({
  type: 'ring/config/REMOVE_FAVOURITE_SUCCESS',
  payload: userConfig,
  error: false,
  meta: {}
});

export const createRemoveFavouriteFailure = (error: $AxiosError<*>): RemoveFavouriteFailureT => ({
  type: 'ring/config/REMOVE_FAVOURITE_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createDeleteBulletinSuccess = (bulletinId: number): DeleteBulletinSuccessT => ({
  type: 'ring/config/DELETE_BULLETIN_SUCCESS',
  payload: bulletinId,
  error: false,
  meta: {}
});

export const createDeleteBulletinFailure = (error: $AxiosError<*>): DeleteBulletinFailureT => ({
  type: 'ring/config/DELETE_BULLETIN_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createDeleteBulletinRequest = (): DeleteBulletinRequestT => ({
  type: 'ring/config/DELETE_BULLETIN_REQUEST',
  error: false,
  meta: {}
});

export const createCreateBulletinRequest = (): CreateBulletinRequestT => ({
  type: 'ring/config/CREATE_BULLETIN_REQUEST',
  error: false,
  meta: {}
});

export const createCreateBulletinSuccess = (bulletin: BulletinT): CreateBulletinSuccessT => ({
  type: 'ring/config/CREATE_BULLETIN_SUCCESS',
  payload: bulletin,
  error: false,
  meta: {}
});

export const createCreateBulletinFailure = (error: $AxiosError<*>): CreateBulletinFailureT => ({
  type: 'ring/config/CREATE_BULLETIN_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createUpdateBulletinRequest = (): UpdateBulletinRequestT => ({
  type: 'ring/config/UPDATE_BULLETIN_REQUEST',
  error: false,
  meta: {}
});

export const createUpdateBulletinSuccess = (bulletin: BulletinT): UpdateBulletinSuccessT => ({
  type: 'ring/config/UPDATE_BULLETIN_SUCCESS',
  payload: bulletin,
  error: false,
  meta: {}
});

export const createUpdateBulletinFailure = (error: $AxiosError<*>): UpdateBulletinFailureT => ({
  type: 'ring/config/UPDATE_BULLETIN_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createGetBulletinsRequest = (): GetBulletinsRequestT => ({
  type: 'ring/config/GET_BULLETINS_REQUEST',
  error: false,
  meta: {}
});

export const createGetBulletinsSuccess = (bulletins: BulletinT[]): GetBulletinsSuccessT => ({
  type: 'ring/config/GET_BULLETINS_SUCCESS',
  payload: bulletins,
  error: false,
  meta: {}
});

export const createGetBulletinsFailure = (error: $AxiosError<*>): GetBulletinsFailureT => ({
  type: 'ring/config/GET_BULLETINS_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createUpdateEnterpriseSettingsRequest = (): UpdateEnterpriseSettingsRequestT => ({
  type: 'ring/config/UPDATE_ENTERPRISE_SETTINGS_REQUEST',
  error: false,
  meta: {}
});

export const createUpdateEnterpriseSettingsSuccess = (
  enterpriseSettings: EnterpriseSettingsT
): UpdateEnterpriseSettingsSuccessT => ({
  type: 'ring/config/UPDATE_ENTERPRISE_SETTINGS_SUCCESS',
  payload: enterpriseSettings,
  error: false,
  meta: {}
});

export const createUpdateEnterpriseSettingsFailure = (
  error: $AxiosError<*>
): UpdateEnterpriseSettingsFailureT => ({
  type: 'ring/config/UPDATE_ENTERPRISE_SETTINGS_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createGetEnterpriseSettingsRequest = (): GetEnterpriseSettingsRequestT => ({
  type: 'ring/config/GET_ENTERPRISE_SETTINGS_REQUEST',
  error: false,
  meta: {}
});

export const createGetEnterpriseSettingsSuccess = (
  enterpriseSettings: EnterpriseSettingsT
): GetEnterpriseSettingsSuccessT => ({
  type: 'ring/config/GET_ENTERPRISE_SETTINGS_SUCCESS',
  payload: enterpriseSettings,
  error: false,
  meta: {}
});

export const createGetEnterpriseSettingsFailure = (
  error: $AxiosError<*>
): GetEnterpriseSettingsFailureT => ({
  type: 'ring/config/GET_ENTERPRISE_SETTINGS_FAILURE',
  payload: error,
  error: true,
  meta: {}
});
