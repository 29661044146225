// @flow

/* eslint-disable no-use-before-define */

import { type SetActiveFormsSectionT, type StateT } from './formsUiTypes';

export const initialState: StateT = {
  activeSection: null
};

const formsSetActiveSectionReducer = (
  state: StateT = initialState,
  action: SetActiveFormsSectionT
): StateT => {
  switch (action.type) {
    case 'ring/ui/forms/SET_FORMS_ACTIVE_SECTION': {
      return {
        ...state,
        activeSection: action.payload.activeSection
      };
    }
    default:
      return state;
  }
};

const formsReducer = {
  initialState,
  formsSetActiveSectionReducer
};

export default formsReducer;
