import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWifiOffLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 16.5c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3m0 5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2m1.821-14.871a.5.5 0 0 1 .424.566.507.507 0 0 1-.566.424 11.69 11.69 0 0 0-9.999 3.327.5.5 0 0 1-.708 0 .5.5 0 0 1 0-.707c2.872-2.871 6.827-4.185 10.849-3.61m-12.98.266a.5.5 0 0 1-.707-.025.5.5 0 0 1 .025-.707A17.3 17.3 0 0 1 17.92 2.537a.5.5 0 1 1-.34.941A16.3 16.3 0 0 0 .841 6.895m23.024-.025a.497.497 0 0 1-.706.025 16.4 16.4 0 0 0-3.085-2.262l-2.955 2.955a12.6 12.6 0 0 1 3.908 2.652.5.5 0 0 1-.708.707 11.7 11.7 0 0 0-3.961-2.599l-3.234 3.234a8.14 8.14 0 0 1 4.646 2.31.5.5 0 0 1-.708.707 7.14 7.14 0 0 0-4.831-2.095c-.009 0-.016-.005-.024-.006l-9.355 9.355a.5.5 0 0 1-.706.001.5.5 0 0 1 0-.707l20-20a.5.5 0 0 1 .707.707L20.797 3.91a17.4 17.4 0 0 1 3.044 2.253.5.5 0 0 1 .024.707"/></svg>)}
    />
  );
}
  