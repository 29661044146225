// @flow strict-local

import React, { type Element, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { actions as notificationActions } from '../../ducks/ui/notification';

import styles from './AudioFileDropzone.module.scss';

type PropsT = {
  onChange: File => void,
  disabled?: boolean,
  forceSelectFile?: boolean
};

export type FileErrorT = 'invalid' | 'oversize' | 'invalidFormat';
const STRICT_AUDIO_FILE_TYPES = [
  'audio/basic',
  'audio/ogg',
  'video/ogg',
  'audio/mpeg',
  'audio/mp3',
  'audio/wav',
  'audio/x-wav'
];
const AUDIO_FILE_TYPES = ['audio/', 'video/'];
const MAX_AUDIO_FILE_SIZE = 100000000; // 100 Mb

export function AudioFileDropzone(props: PropsT): Element<'div'> {
  const { onChange, disabled, forceSelectFile } = props;
  const [fileImportError, setFileImportError] = useState();
  const [fileToImport, setFileToImport] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validateAudioFileFormat = (files: ?(File[])): ?FileErrorT => {
    if (!files || files.length !== 1) {
      return 'invalid';
    }
    if (files[0].size > MAX_AUDIO_FILE_SIZE) {
      return 'oversize';
    }
    if (
      files[0].type &&
      files[0].type !== '' &&
      !R.any(v => files[0].type.includes(v))(AUDIO_FILE_TYPES)
    ) {
      return 'invalidFormat';
    }
    if (!files[0].type || !R.any(v => files[0].type.includes(v))(STRICT_AUDIO_FILE_TYPES)) {
      const notification = {
        tag: 'callflow-edit-success',
        duration: 15000,
        type: 'info',
        message: t('audioFileDropzone.tryConversion')
      };

      dispatch(notificationActions.createCreateNotificationAction(notification));
    }
    return null;
  };

  const handleOnDrop = (acceptedFiles: File[]) => {
    const errorType: ?FileErrorT = validateAudioFileFormat(acceptedFiles);
    setFileImportError(errorType);
    if (!errorType) {
      setFileToImport(acceptedFiles[0]);
      onChange(acceptedFiles[0]);
    }
  };

  const selectFileView = (
    <div id="audio-upload-select-file" data-cy="audio-upload-select-file" className={styles.choose}>
      <div
        className={classnames(styles['choose-header'], {
          [styles['choose-header--disabled']]: disabled
        })}
      >
        {t('audioFileDropzone.startHeader')}
      </div>
      <div
        className={classnames(styles['choose-text'], {
          [styles['choose-text--disabled']]: disabled
        })}
      >
        {parse(`${t('audioFileDropzone.startText1')}
        <span style="text-decoration: underline;">${t('audioFileDropzone.startText2')}</span>`)}
      </div>
    </div>
  );

  const errorView = (
    <div id="audio-upload-error" className={styles.error}>
      <div className={styles['error-header']}>{t('audioFileDropzone.errorHeader')}</div>
      <div className={styles['error-text']}>
        {t(`audioFileDropzone.${fileImportError || 'invalid'}`)}
      </div>
      <div className={styles['error-link']}>
        {parse(
          `<span style="text-decoration: underline;">t('audioFileDropzone.generalErrorLink')</span>`
        )}
      </div>
    </div>
  );

  const showSelectedFileView = () => {
    const fileName = fileToImport ? fileToImport.name : '';
    return (
      <div className={styles.detail}>
        <div className={styles.value} data-cy="filename">
          {fileName}
        </div>
        <div className={styles['delay-warning']}>{t('audioFileDropzone.delayWarning')}</div>
      </div>
    );
  };

  const selectFile = fileImportError ? errorView : selectFileView;
  const dropBoxContent =
    fileToImport && !fileImportError && !forceSelectFile ? showSelectedFileView() : selectFile;

  return (
    <div className={styles['audio-upload-container']}>
      <Dropzone
        id="audio-dropzone"
        data-cy="audio-dropzone"
        onDrop={!disabled ? handleOnDrop : () => {}}
        accept={AUDIO_FILE_TYPES.join(',')}
        className={classnames(styles.dropzone, { [styles['dropzone--disabled']]: disabled })}
        activeClassName={styles['dropzone-active']}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              className={classnames(styles.dropzone, { [styles['dropzone--disabled']]: disabled })}
            >
              <input {...getInputProps()} />
              {dropBoxContent}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

export default AudioFileDropzone;
