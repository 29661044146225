// @flow strict-local

import React, { type Element, type Node } from 'react';
import classNames from 'classnames';
import { ReactComponent as DragHandle } from '../../assets/drag-handle.svg';

import styles from './OrderableItem.module.scss';

export type PropsT = {
  ordinal: number,
  children: Node,
  dragHandleProps?: mixed,
  className?: string
};

export const UserItem = ({
  ordinal,
  children,
  dragHandleProps = {},
  className
}: PropsT): Element<'div'> => (
  <div className={classNames(styles.container, className)}>
    <div className={styles['drag-handle']} {...dragHandleProps}>
      <DragHandle />
    </div>
    <div className={styles.ordinal}>{ordinal}</div>
    <div className={styles.children}>{children}</div>
  </div>
);

export default UserItem;
