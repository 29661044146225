// @flow strict-local

import React, { type Element } from 'react';

import { useTranslation } from 'react-i18next';
import OmaRingAvatarEditor from './OmaRingAvatarEditor';

import styles from './ImageDropzone.module.scss';

type PropsT = {
  onChange: string => void,
  onDelete: () => void,
  initialImageUrl: ?string
};

export function ImageDropzone(props: PropsT): Element<'div'> {
  const { onChange, initialImageUrl, onDelete } = props;

  const { t } = useTranslation();

  return (
    <div className={styles['image-upload-container']}>
      <OmaRingAvatarEditor
        onChange={onChange}
        onDelete={onDelete}
        initialImageUrl={initialImageUrl}
        translate={t}
      />
    </div>
  );
}

export default ImageDropzone;
