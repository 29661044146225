import React from 'react';
import IconContainer from '../IconContainer';

export default function IconThumbUpRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.249 9.241h-1.501a.25.25 0 0 0-.25.25v12c0 .138.112.25.25.25h1.501a.75.75 0 1 1 0 1.5h-1.501a1.75 1.75 0 0 1-1.751-1.75v-.78c-5.474 2.75-6.285 2.83-10.608 2.78H7.468c-2.682 0-4.463-1.56-5.454-4.76v-.08l-1.911-6.35v-.07a2.75 2.75 0 0 1 .43-2.37 2.82 2.82 0 0 1 2.202-1.12h4.904c.391.006.761-.172 1.001-.48.231-.309.308-.707.21-1.08l-.891-3.35A2.46 2.46 0 0 1 9.31.991a2.44 2.44 0 0 1 3.002.77l4.934 6.95c.226.33.6.528 1.001.53h1.741a1.76 1.76 0 0 1 1.731-1.5h1.501a.75.75 0 1 1 0 1.5zm-7.225.34-4.894-6.93a1 1 0 0 0-1.191-.32 1 1 0 0 0-.53 1.12l.881 3.34a2.75 2.75 0 0 1-2.652 3.45H2.734a1.3 1.3 0 0 0-1.001.51c-.23.318-.3.724-.19 1.1v.07l1.891 6.35v.07c.791 2.52 2.001 3.64 4.003 3.64h1.941c4.203.05 4.653 0 10.588-3v-8.24h-1.721a2.76 2.76 0 0 1-2.221-1.16"/></svg>)}
    />
  );
}
  