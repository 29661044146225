import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWifiRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.75 7.05a.75.75 0 0 1-.501-1.309 17.587 17.587 0 0 1 23.501 0 .75.75 0 0 1-1.002 1.117 16.09 16.09 0 0 0-21.499 0 .74.74 0 0 1-.499.192m14.5 12.45c0-1.792-1.458-3.25-3.25-3.25s-3.25 1.458-3.25 3.25 1.458 3.25 3.25 3.25 3.25-1.458 3.25-3.25m-1.5 0c0 .965-.785 1.75-1.75 1.75s-1.75-.785-1.75-1.75.785-1.75 1.75-1.75 1.75.785 1.75 1.75m-9.302-8.91c4.307-3.755 10.801-3.754 15.105-.001a.75.75 0 0 0 .987-1.131c-4.868-4.244-12.209-4.244-17.078.001a.75.75 0 0 0 .986 1.131m13.008 3.643a.75.75 0 0 0-.1-1.057 8.415 8.415 0 0 0-10.713-.002.751.751 0 0 0 .955 1.157 6.92 6.92 0 0 1 8.801.002.75.75 0 0 0 1.057-.1"/></svg>)}
    />
  );
}
  