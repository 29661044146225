// @flow strict-local

import React, { type Element } from 'react';
import MediaQuery from 'react-responsive';
import styles from './Responsive.module.scss';

type ResponsivefnT = mixed => Element<typeof MediaQuery>;

export const Phone: ResponsivefnT = props => <MediaQuery {...props} query={styles.phoneQuery} />;

export const PhoneLarge: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.phoneLargeQuery} />
);

export const PhoneLargeAndUp: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.phoneLargeAndUpQuery} />
);

export const PhoneLargeAndDown: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.phoneLargeAndDownQuery} />
);

export const PhoneLargeAndTablet: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.phoneLargeAndTabletQuery} />
);

export const Tablet: ResponsivefnT = props => <MediaQuery {...props} query={styles.tabletQuery} />;

export const TabletAndUp: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.tabletAndUpQuery} />
);

export const TabletAndDown: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.tabletAndDownQuery} />
);

export const Laptop: ResponsivefnT = props => <MediaQuery {...props} query={styles.laptopQuery} />;

export const LaptopAndUp: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.laptopAndUpQuery} />
);

export const LaptopAndDown: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.laptopAndDownQuery} />
);

export const Desktop: ResponsivefnT = props => (
  <MediaQuery {...props} query={styles.desktopQuery} />
);
