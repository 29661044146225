// @flow

import React, { type Element } from 'react';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useFormContext } from 'react-hook-form';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import TooltipExtension from '../../../../components/TooltipExtension';
import styles from './GroupDisplayedLabels.module.scss';

export type PropsT = {|
  field: string
|};

export const GroupDisplayedLabels = (props: PropsT): Element<'div'> | null => {
  const { field } = props;
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const values = getValues();

  const groupDisplayedLabelsOptions = [
    { value: t('callflows.labelsToDisplay.ORIGINAL_CALLER'), key: 'ORIGINAL_CALLER' },
    { value: t('callflows.labelsToDisplay.ORIGINAL_CALLED'), key: 'ORIGINAL_CALLED' },
    { value: t('callflows.labelsToDisplay.CALLED_GROUP'), key: 'CALLED_GROUP' },
    {
      value: t('callflows.labelsToDisplay.ORIGINAL_CALLER_NUMBER'),
      key: 'ORIGINAL_CALLER_NUMBER'
    }
  ];

  const handleChange = selectedOption => {
    let newValues = [...values[field]];
    if (newValues.includes(selectedOption.key)) {
      newValues = newValues.filter(key => key !== selectedOption.key);
    } else {
      newValues.push(selectedOption.key);
    }
    setValue(field, newValues, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  return (
    <div className={styles['group-displayed-labels-area']}>
      <div className={styles['title-area']}>
        <div className={styles.title}>
          {t('callflows.viewCallflowContent.groupDisplayedLabels')}
        </div>
        <TooltipExtension
          title={t('callflows.viewCallflowContent.groupDisplayedLabels')}
          text={parse(
            `${t('callflows.viewCallflowContent.groupDisplayedLabelsTooltip1')}<br><br><li>${t(
              'callflows.viewCallflowContent.groupDisplayedLabelsTooltip2'
            )}</li><li>${t(
              'callflows.viewCallflowContent.groupDisplayedLabelsTooltip3'
            )}</li><li>${t(
              'callflows.viewCallflowContent.groupDisplayedLabelsTooltip4'
            )}</li><li>${t('callflows.viewCallflowContent.groupDisplayedLabelsTooltip5')}</li>`
          )}
        />
      </div>
      <div id="group-displayed-labels-area" className={styles['group-displayed-labels-area']}>
        <div className={styles.info}>
          {t('callflows.viewCallflowContent.groupDisplayedLabelsInfo')}
        </div>
        {groupDisplayedLabelsOptions.map(option => (
          <Checkbox
            id={option.key}
            key={option.key}
            name={`${field}_check`}
            checked={values[field].includes(option.key)}
            label={option.value}
            onChange={() => handleChange(option)}
          />
        ))}
      </div>
    </div>
  );
};

export default GroupDisplayedLabels;
