// @flow strict-local

import React, { Component, type Element } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import type { ExactPropsT, StoreStateT } from '../../commonTypes';
import { STICKY_ELEMENT_DEBOUNCE_DURATION } from '../../constants';
import type {
  DismissNotificationActionT,
  NotificationT,
  NotificationTagT
} from '../../ducks/ui/notification/notificationUiTypes';
import {
  actions as notificationActions,
  selectors as selectNotifications
} from '../../ducks/ui/notification';
import Notification from './Notification';

import styles from './Notifications.module.scss';

type OwnPropsT = {
  tags: NotificationTagT[],
  render?: ({
    keyId: string,
    id: string,
    tag: string,
    message: string,
    type: string,
    // flowlint-next-line unclear-type:off
    dismiss: () => any // eslint-disable-line flowtype/no-weak-types
    // flowlint-next-line unclear-type:off
  }) => Element<any>, // eslint-disable-line flowtype/no-weak-types
  hideDismiss?: boolean,
  noDebounce?: boolean,
  className?: string
};

type StatePropsT = {
  notifications: NotificationT[]
};

type DispatchPropsT = {
  dismiss: string => DismissNotificationActionT
};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, DispatchPropsT>;

type StateT = {|
  sticky: boolean
|};

export class Notifications extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.scheduleDismissables = this.scheduleDismissables.bind(this);
    this.cancelScheduledDismissables = this.cancelScheduledDismissables.bind(this);
    this.dismissAll = this.dismissAll.bind(this);
    this.handleOnScroll = this.props.noDebounce
      ? this.handleOnScroll.bind(this)
      : debounce(this.handleOnScroll.bind(this), STICKY_ELEMENT_DEBOUNCE_DURATION);
    this.scheduled = {};
    this.ref = React.createRef();
    this.yFromDocTop = 0;
  }

  state = {
    sticky: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleOnScroll);
    this.scheduleDismissables();
  }

  componentDidUpdate() {
    this.scheduleDismissables();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll);
    this.cancelScheduledDismissables();
    this.dismissAll();
  }

  scheduled: { [notificationId: string]: TimeoutID };

  scheduleDismissables: () => void;

  scheduleDismissables() {
    const { notifications, dismiss } = this.props;

    this.scheduled = notifications
      .filter(({ id, duration }) => !(id in this.scheduled) && duration > 0)
      .reduce(
        (acc, { id, duration, onDismiss = () => {} }) => ({
          ...acc,
          [id]: setTimeout(() => {
            dismiss(id);
            onDismiss();
          }, duration)
        }),
        this.scheduled
      );
  }

  cancelScheduledDismissables: () => void;

  cancelScheduledDismissables() {
    Object.keys(this.scheduled).forEach(id => {
      clearTimeout(this.scheduled[id]);
    });
  }

  dismissAll: () => void;

  dismissAll() {
    const { notifications, dismiss } = this.props;
    notifications.forEach(({ id, onDismiss = () => {} }) => {
      dismiss(id);
      onDismiss();
    });
  }

  handleOnScroll: () => void;

  handleOnScroll() {
    const { scrollY } = window;

    // check if notifications are shown in non-sticky mode
    if (this.ref.current !== null) {
      const topWithScroll = this.ref.current.getBoundingClientRect().top;
      this.yFromDocTop = topWithScroll + scrollY;

      // ..and should be in sticky
      if (topWithScroll < 0) {
        this.setState({ sticky: true });
      }
    }

    // check if window is scrolled less than required for stickiness
    if (scrollY < this.yFromDocTop) {
      this.setState({ sticky: false });
    }
  }

  ref: { current: null | HTMLDivElement };

  yFromDocTop: number;

  render(): Element<'div'> {
    const { notifications, render, dismiss, hideDismiss, ...rest } = this.props;
    const { sticky } = this.state;

    const renderComponent =
      render === undefined
        ? p => (
            <Notification
              {...p}
              key={`n-${p.id}`}
              hideDismiss={hideDismiss}
              dismiss={() => dismiss(p.id)}
            />
          )
        : render;

    const renderedNotifications = notifications.map(({ id, tag, message, type }) =>
      renderComponent({
        keyId: `notification-${id}`,
        id,
        tag,
        message,
        type,
        ...{ dismiss: () => dismiss(id) }
      })
    );

    return sticky ? (
      <div {...rest} className={styles.sticky}>
        {renderedNotifications}
      </div>
    ) : (
      <div ref={this.ref} {...rest}>
        {renderedNotifications}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT, { tags }: OwnPropsT) => ({
  notifications: selectNotifications.byTags(state, tags)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dismiss: notificationActions.createDismissNotificationAction
    },
    dispatch
  );

export default compose(connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps))(
  Notifications
);
