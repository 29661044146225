import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSalasanapankkiRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m21.58 1-9.5-1h-.16l-9.5 1a.75.75 0 0 0-.67.75V8.4c0 8.76 3.79 12.43 9.91 15.52a.76.76 0 0 0 .68 0c6.12-3.09 9.91-6.76 9.91-15.52V1.75a.75.75 0 0 0-.67-.75m-.83 7.4c0 7.87-3.2 11.15-8.75 14-5.55-2.86-8.75-6.14-8.75-14V2.43L12 1.54l8.75.89zm-12.5-.15a3.74 3.74 0 0 0 3 3.67v4.83a.76.76 0 0 0 .75.75h2a.75.75 0 0 0 0-1.5h-1.25v-1h.75a.75.75 0 0 0 0-1.5h-.75v-1.58a3.75 3.75 0 1 0-4.5-3.67m6 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0"/></svg>)}
    />
  );
}
  