// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../BaseContainer/BaseContainer';
import { goToCallFlowServices } from '../../../navigationOperations';
import SubHeader from '../../../components/SubHeader/SubHeader';
import CallflowTypeSelector from './CallflowTypeSelector';
import type { AvailableServicesT } from './CallflowTypeSelector';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import EditCallflowContent from '../singlePage/EditCallflowContent';
import type { CallFlowTypeT } from '../../../ducks/entities/callFlow/callFlowTypes';
import TopNavigation from '../../navigation/TopNavigation';
import styles from './CreateCallflow.module.scss';

export type PropsT = {|
  match: {
    params: {
      id: string
    }
  }
|};

// eslint-disable-next-line no-unused-vars
const CreateCallflow = (props: PropsT) => {
  const { id: enterpriseId } = props.match.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const [selectedType, setSelectedType] = useState<CallFlowTypeT>('ACD_CUSTOMER_SERVICE');

  const availableServices: AvailableServicesT = {
    customerService: !(currentUser.featureFlags || []).includes(
      'FEATURE-DISABLE-CREATE-CUSTOMERSERVICE'
    ),
    switchboardService: !(currentUser.featureFlags || []).includes(
      'FEATURE-DISABLE-CREATE-SWITCHBOARD'
    ),
    extGroupService: !(currentUser.featureFlags || []).includes('FEATURE-DISABLE-CREATE-EXTGROUP'),
    playMusicService: !(currentUser.featureFlags || []).includes(
      'FEATURE-DISABLE-CREATE-PLAYMUSIC'
    ),
    welcomeAttendantService: !(currentUser.featureFlags || []).includes(
      'FEATURE-DISABLE-CREATE-WELCOMEATTENDANT'
    ),
    speedDialService: !(currentUser.featureFlags || []).includes('FEATURE-DISABLE-CREATE-SPEEDDIAL')
  };

  const callflowId = `${enterpriseId}-${selectedType}-create`;

  return (
    <BaseContainer header={<TopNavigation />}>
      <SubHeader
        smallTitleText={t('callflows.create.headerSmallTitle')}
        bigTitleText={t('callflows.create.headerBigTitle')}
        returnLinkAction={() => dispatch(goToCallFlowServices(enterpriseId))}
        returnLinkText={t('callflows.create.headerReturnToCallFlowsPage')}
      />
      <div className={styles.container}>
        <CallflowTypeSelector
          availableServices={availableServices}
          onChange={callflowType => setSelectedType(callflowType)}
        />
        <EditCallflowContent
          callflowId={callflowId}
          onClick={() => console.log('create')}
          hideBillingSectionSubTopic
        />
      </div>
    </BaseContainer>
  );
};

export default CreateCallflow;
