import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTickRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.2 8.2c0 .1.1.2.1.3v.3c0 .1-.1.2-.2.3l-7 8c-.1.1-.2.1-.2.2-.1 0-.2.1-.3.1s-.2 0-.3-.1c-.1 0-.2-.1-.3-.2l-4-4c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5.3-.2.5-.2.4.1.5.2l3.4 3.4L16.9 8l.2-.2c.1 0 .2-.1.3-.1h.3c.1 0 .2.1.3.1.1.2.1.3.2.4M24 12c0 6.6-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0s12 5.4 12 12m-1.5 0c0-5.8-4.7-10.5-10.5-10.5S1.5 6.2 1.5 12 6.2 22.5 12 22.5 22.5 17.8 22.5 12" className="st0"/></svg>)}
    />
  );
}
  