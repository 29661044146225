import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMenuFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.2 4.5c0-.7.6-1.2 1.2-1.2h21c.7 0 1.2.6 1.2 1.2s-.6 1.2-1.2 1.2h-21C.8 5.8.2 5.2.2 4.5m22.3 6.3h-21c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2h21c.7 0 1.2-.6 1.2-1.2s-.5-1.2-1.2-1.2m0 7.4h-21c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2h21c.7 0 1.2-.6 1.2-1.2s-.5-1.2-1.2-1.2"/></svg>)}
    />
  );
}
  