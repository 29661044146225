/* eslint-disable flowtype/no-weak-types */
/* eslint-disable no-unused-vars */
// @flow

import React, { type Element, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosPromise } from 'axios';
import { useFormContext } from 'react-hook-form';
import type { Canceler } from 'axios';
import * as yup from 'yup';
import * as R from 'ramda';
import Moment from 'moment';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/component-library';
import Toggle from '@design-system/component-library/src/components/Toggle';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import BaseContainer from '../../BaseContainer/BaseContainer';
import { goToCalendarTemplates } from '../../../navigationOperations';
import GoBackLink from '../../../components/Button/GoBackLink';
import AbsoluteTimesField from '../calendar/AbsoluteTimesField';
import EditCallflowDetails from '../components/edit/EditCallflowDetails';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import TopNavigation from '../../navigation/TopNavigation';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { toFormSlots, findTitleForDate } from './calendarTemplateUtil';
import InputField from '../components/edit/children/InputField';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import GenericError from '../../../components/Error/GenericError';
import Dialog from '../../../components/Dialog';
import styles from './EditCalendarTemplate.module.scss';

export type PropsT = {|
  match: {
    params: {
      id: string,
      calendarId: string
    }
  }
|};

const EditCalendarTemplate = (props: PropsT): Element<typeof BaseContainer> => {
  const { id: enterpriseId, calendarId } = props.match.params;
  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const updateCalendarCanceller = React.useRef<Canceler>();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [slots, setSlots] = useState([]);
  const [calendarData, setCalendarData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(undefined);

  const [includeHolidays, setIncludeHolidays] = useState(false);

  const [
    showDeleteAbsoluteTimeDeleteDialog,
    setShowDeleteAbsoluteTimeDeleteDialog
  ] = useState<boolean>(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [bankHolidays, setBankHolidays] = useState([]);

  const isNew = () => {
    return calendarId === 'create';
  };

  const calendarTemplateSchema = yup.object().shape({
    name: yup.string().required(t('calendars.editCalendarForm.nameEmptyValidationError'))
  });

  const IncludeBankHolidaysToggle = (): * => {
    const { setValue } = useFormContext();
    return (
      <Toggle
        id="includeholidays-toggle"
        onToggle={() => {
          if (!bankHolidays) {
            return;
          }
          if (!includeHolidays) {
            bankHolidays.forEach(holiday => {
              let alreadyIn = false;
              slots.some(s => {
                if (s.startTime.startsWith(holiday.date)) {
                  alreadyIn = true;
                }
                return alreadyIn;
              });

              if (!alreadyIn) {
                slots.push({
                  id: uuid(),
                  startTime: Moment(holiday.date)
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('seconds', 0)
                    .format('YYYY-MM-DDTHH:mm:ss'),
                  endTime: Moment(holiday.date)
                    .set('hour', 23)
                    .set('minute', 59)
                    .set('seconds', 0)
                    .format('YYYY-MM-DDTHH:mm:ss'),
                  active: false,
                  presenceState: 'AWAY',
                  newSlot: false,
                  title: t(`holidays.${holiday.name}`)
                });
              }
            });
            setValue('slots', slots, {
              shouldValidate: true,
              shouldDirty: true
            });
            setSlots(slots);
          }

          setIncludeHolidays(!includeHolidays);
        }}
        value={includeHolidays}
      />
    );
  };

  const RenderDateTimePicker = (): * => {
    const { setValue } = useFormContext();

    return (
      <DateTimePicker
        field="slots"
        onClose={async absoluteTimeSlots => {
          if (absoluteTimeSlots) {
            for (let i = 0; i < absoluteTimeSlots.length; i++) {
              // eslint-disable-next-line no-param-reassign
              absoluteTimeSlots[i].title = findTitleForDate(
                absoluteTimeSlots[i].startTime,
                bankHolidays,
                t
              );
            }

            setSlots(absoluteTimeSlots);
            setValue('slots', absoluteTimeSlots, {
              shouldValidate: true,
              shouldDirty: true
            });
          }
          setIsDatePickerVisible(false);
        }}
        selectedTimeSlot={selectedSlot}
        minDate={new Date(0)}
      />
    );
  };

  const fetchData = async () => {
    try {
      const response: AxiosPromise = await axios({
        method: 'GET',
        url: `/api/v1/config/bank_holidays`
      });
      if (!response || !response.data) {
        throw new Error('Error getting holiday data');
      }
      setBankHolidays(response.data);

      if (isNew()) {
        setCalendarData({
          name: '',
          slots: []
        });
      } else {
        const cResponse: AxiosPromise = await axios({
          method: 'GET',
          url: `/api/v1/config/calendars/${calendarId}`
        });
        if (!cResponse || !cResponse.data) {
          throw new Error('Error getting calendar data');
        }
        const cdata = cResponse.data;
        cdata.slots = cdata.calendarSlots.map(s => {
          return {
            ...toFormSlots(s),
            title: findTitleForDate(s.startTime, response.data, t)
          };
        });
        setSlots(cdata.slots);
        setCalendarData(cdata);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error', error.message);
      }
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const calendarTopic = (
    <>
      <GoBackLink
        id="callflow-page-back-link"
        text={t('calendars.createCalendar.backToCalendarsLink')}
        onClick={() => dispatch(goToCalendarTemplates(enterpriseId))}
      />
      <div className={styles['subheader-container']}>
        <div className={styles.big}>
          {isNew() ? t('calendars.templates.createTitle') : t('calendars.templates.editTitle')}
        </div>
      </div>
    </>
  );

  const onSaveForm = async (formData: any): Promise<void> => {
    const payload = {
      enterpriseId,
      name: formData.name,
      calendarSlots: formData.slots.map(slotData => {
        return {
          startTime: slotData.startTime,
          endTime: slotData.endTime,
          open: slotData.active,
          calendar: isNew() ? null : calendarId
        };
      })
    };

    let response: AxiosPromise;

    try {
      if (isNew()) {
        response = await axios({
          method: 'POST',
          url: '/api/v1/config/calendars',
          data: payload,
          headers: createCsrfHeader(currentUser)
        });
      } else {
        // $FlowFixMe
        payload.id = calendarId;

        response = await axios({
          method: 'PATCH',
          url: `/api/v1/config/calendars/${calendarId}`,
          data: payload,
          headers: createCsrfHeader(currentUser)
        });
      }
    } catch (error) {
      console.error(error);
    }

    const success = !!response;
    dispatch(
      notificationActions.createCreateNotificationAction({
        tag: success ? `calendar-edit-success` : `calendar-edit-fail`,
        duration: 15000,
        type: success ? 'info' : 'error',
        message: success
          ? t('calendars.templates.saveSuccessNotification')
          : t('calendars.templates.saveFailureNotification')
      })
    );
    dispatch(goToCalendarTemplates(enterpriseId));
  };

  const deleteCalendar = async () => {
    if (isNew()) return;

    const response: AxiosPromise = await axios({
      method: 'DELETE',
      url: `/api/v1/config/enterprise/${enterpriseId}/calendars/${calendarId}`,
      headers: createCsrfHeader(currentUser)
    });

    const success = !!response;
    dispatch(
      notificationActions.createCreateNotificationAction({
        tag: success ? `calendar-delete-success` : `calendar-delete-fail`,
        duration: 15000,
        type: success ? 'info' : 'error',
        message: success
          ? t('calendars.templates.deleteSuccessNotification')
          : t('calendars.templates.deleteFailureNotification')
      })
    );
    dispatch(goToCalendarTemplates(enterpriseId));
  };

  return (
    <BaseContainer header={<TopNavigation />} containerStyle={styles.container}>
      {calendarTopic}
      {isError && <GenericError message={t('efax.efaxListFailedMsg')} />}
      {isLoading && (
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      )}
      {calendarData && (
        <>
          <div className={styles.container}>
            <EditCallflowDetails
              nodeId="calendars-page"
              icon={null}
              title=""
              disableCallflowNotifications
              onSaveForm={formData => onSaveForm(formData)}
              onCancel={e => dispatch(goToCalendarTemplates(enterpriseId))}
              defaultValues={calendarData}
              deleteButtonLabel={isNew() ? '' : t('generic.commonAction.delete')}
              onDelete={() => setShowDeleteDialog(true)}
              validationSchema={calendarTemplateSchema}
            >
              <div className={styles['info-text']}>{t('calendars.templates.helpText')}</div>

              <div className={styles['form-container']}>
                <div className={styles['form-section']}>
                  <InputField
                    field="name"
                    title={t('calendars.editCalendarForm.name')}
                    description={t('calendars.editCalendarForm.nameDescription')}
                    maxLength={107}
                    shouldValidate
                  />
                </div>

                <div className={styles['form-section']}>
                  <div className={styles['section-title']}>
                    {t('calendars.templates.includeBankHolidays')}
                  </div>
                  <div className={styles['info-text']}>
                    {t('calendars.templates.bankHolidaysInfoText')}
                  </div>
                  <IncludeBankHolidaysToggle />
                </div>

                <AbsoluteTimesField
                  field="slots"
                  handleUpdate={slot => {
                    setSelectedSlot(slot);
                    setIsDatePickerVisible(true);
                  }}
                  showDeleteAbsoluteTimeDeleteDialog={showDeleteAbsoluteTimeDeleteDialog}
                  setShowDeleteAbsoluteTimeDeleteDialog={setShowDeleteAbsoluteTimeDeleteDialog}
                  updateAbsoluteTimeSlots={async absoluteTimeSlots => {
                    setShowDeleteAbsoluteTimeDeleteDialog(false);
                    if (absoluteTimeSlots) setSlots(absoluteTimeSlots);
                  }}
                />
                {isDatePickerVisible ? <RenderDateTimePicker /> : null}
                <div className={styles['form-section']}>
                  <Button
                    size="l"
                    onClick={() => {
                      setIsDatePickerVisible(true);
                    }}
                  >
                    {t('calendars.templates.addDate')}
                  </Button>
                </div>
              </div>

              {showDeleteDialog ? (
                <Dialog
                  onCancel={() => setShowDeleteDialog(false)}
                  onConfirm={() => {
                    deleteCalendar();
                  }}
                  onClose={() => {
                    setShowDeleteDialog(false);
                  }}
                  title={t('calendars.templates.deleteDialog.title')}
                  description=""
                  confirmLabel={t('calendars.templates.deleteDialog.confirm')}
                  cancelLabel={t('calendars.templates.deleteDialog.cancel')}
                  loading={false}
                  disabled={false}
                />
              ) : null}
            </EditCallflowDetails>
          </div>
        </>
      )}
    </BaseContainer>
  );
};

export default EditCalendarTemplate;
