// @flow strict-local

import { type $AxiosError } from 'axios';
import type {
  WelcomeAttendantEntityT,
  UpdateWelcomeAttendantCancelT,
  UpdateWelcomeAttendantFailureT,
  UpdateWelcomeAttendantRequestT,
  UpdateWelcomeAttendantSuccessT
} from './welcomeAttendantTypes';

type CreateUpdateWelcomeAttendantRequestFnT = (string, string) => UpdateWelcomeAttendantRequestT;
export const createUpdateWelcomeAttendantRequest: CreateUpdateWelcomeAttendantRequestFnT = (
  enterpriseId,
  welcomeAttendantId
) => ({
  type: 'ring/entity/welcomeattendant/UPDATE_REQUEST',
  error: false,
  meta: { enterpriseId, welcomeAttendantId }
});

type CreateUpdateWelcomeAttendantSuccessFnT = (
  string,
  WelcomeAttendantEntityT
) => UpdateWelcomeAttendantSuccessT;
export const createUpdateWelcomeAttendantSuccess: CreateUpdateWelcomeAttendantSuccessFnT = (
  enterpriseId,
  welcomeAttendant
) => ({
  type: 'ring/entity/welcomeattendant/UPDATE_SUCCESS',
  payload: { ...welcomeAttendant },
  error: false,
  meta: { enterpriseId, welcomeAttendantId: welcomeAttendant.id }
});

type CreateUpdateWelcomeAttendantFailureFnT = (
  string,
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => UpdateWelcomeAttendantFailureT;
export const createUpdateWelcomeAttendantFailure: CreateUpdateWelcomeAttendantFailureFnT = (
  enterpriseId,
  welcomeAttendantId,
  error
) => ({
  type: 'ring/entity/welcomeattendant/UPDATE_FAILURE',
  payload: error,
  error: true,
  meta: { enterpriseId, welcomeAttendantId }
});

type CreateUpdateWelcomeAttendantCancelFnT = (string, string) => UpdateWelcomeAttendantCancelT;
export const createUpdateWelcomeAttendantCancel: CreateUpdateWelcomeAttendantCancelFnT = (
  enterpriseId,
  welcomeAttendantId
) => ({
  type: 'ring/entity/welcomeattendant/UPDATE_CANCEL',
  payload: {},
  error: false,
  meta: { enterpriseId, welcomeAttendantId }
});
