// @flow
import * as React from 'react';
import classnames from 'classnames';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import type { ImportOptionsT, ImportOptionTypeT } from './importUtils';
import styles from './ImportServiceHeaderRow.module.scss';

type PropsT = {|
  importOptions: ImportOptionsT,
  updateImportOptions: (string, ImportOptionTypeT) => void
|};

const ImportServiceHeaderRow = (props: PropsT): React.Element<'div'> => {
  const { importOptions, updateImportOptions } = props;

  const { t } = useTranslation();
  const [rowKey, setRowKey] = useState(0);

  useEffect(() => {
    setRowKey(new Date().getTime());
  }, [importOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classnames(styles.row, styles.header)}>
      <div>{t('importServices.includeId')}</div>
      <div className={styles.fields} key={rowKey}>
        <div className={styles.checkbox}>
          {t('importServices.includePnNumbers')}
          <Checkbox
            id="pstn-all"
            name="pstn-all"
            checked={importOptions.all.includePstn}
            onChange={() => updateImportOptions('all', 'includePstn')}
          />
        </div>

        <div className={styles.checkbox}>
          {t('importServices.includeMembers')}
          <Checkbox
            id="member-all"
            name="member-all"
            checked={importOptions.all.includeMembers}
            onChange={() => updateImportOptions('all', 'includeMembers')}
          />
        </div>

        <div className={styles.checkbox}>
          {t('importServices.includeCalendar')}
          <Checkbox
            id="calendar-all"
            name="calendar-all"
            checked={importOptions.all.includeCalendar}
            onChange={() => updateImportOptions('all', 'includeCalendar')}
          />
        </div>

        <div className={styles.checkbox}>
          {t('importServices.includeforwardings')}
          <Checkbox
            id="forwarding-all"
            name="forwarding-all"
            checked={importOptions.all.includeForwardings}
            onChange={() => updateImportOptions('all', 'includeForwardings')}
          />
        </div>

        <div className={styles.checkbox}>
          {t('importServices.includeAudio')}
          <Checkbox
            id="audio-all"
            name="audio-all"
            checked={importOptions.all.includeAudio}
            onChange={() => updateImportOptions('all', 'includeAudio')}
          />
        </div>

        <div className={styles.checkbox}>
          {t('importServices.includeOther')}
          <Checkbox
            id="other-all"
            name="other-all"
            checked={importOptions.all.includeOther}
            onChange={() => updateImportOptions('all', 'includeOther')}
          />
        </div>
      </div>
      <div />
    </div>
  );
};

export default ImportServiceHeaderRow;
