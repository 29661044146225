import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileGeneralLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.5 13c-.3 0-.5.2-.5.5s.2.5.5.5h11c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM6 16.5c0-.3.2-.5.5-.5h11c.3 0 .5.2.5.5s-.2.5-.5.5h-11c-.3 0-.5-.2-.5-.5m.5 2.5c-.3 0-.5.2-.5.5s.2.5.5.5h11c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM15.2.4l5.4 5.4c.2.3.4.7.4 1.1V22c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h9.1c.4 0 .8.2 1.1.4m.3 5.6h3.8L15 1.7v3.8c0 .3.2.5.5.5M5 23h14c.6 0 1-.4 1-1V7h-4.5c-.8 0-1.5-.7-1.5-1.5V1H5c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1"/></svg>)}
    />
  );
}
  