import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFacebookRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.19 0H1.73A1.77 1.77 0 0 0 0 1.77v20.46C0 23.208.792 24 1.77 24h20.46A1.77 1.77 0 0 0 24 22.23V1.77A1.77 1.77 0 0 0 22.23 0zm.27 22.19a.27.27 0 0 1-.27.27h-6.24v-7.65h2.62a.51.51 0 0 0 .51-.45l.39-3.07a.52.52 0 0 0-.51-.57h-3V9.64a1.74 1.74 0 0 1 1.75-1.73h1.85a.51.51 0 0 0 .51-.51V4.33a.51.51 0 0 0-.51-.51h-1.85a5.83 5.83 0 0 0-5.82 5.82v1.08H9.31a.51.51 0 0 0-.51.51v3.07c0 .282.228.51.51.51h2.55v7.65H1.77a.27.27 0 0 1-.27-.27V1.73a.27.27 0 0 1 .27-.27h20.46a.27.27 0 0 1 .27.27z"/></svg>)}
    />
  );
}
  