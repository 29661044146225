import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilmCameraRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M9.75 5.375a4.375 4.375 0 1 1-8.75 0 4.375 4.375 0 0 1 8.75 0m-1.5 0a2.875 2.875 0 1 1-5.75 0 2.875 2.875 0 0 1 5.75 0m11.5 0a4.375 4.375 0 1 1-8.75 0 4.375 4.375 0 0 1 8.75 0m-1.5 0a2.875 2.875 0 1 1-5.75 0 2.875 2.875 0 0 1 5.75 0m.5 8.658V13a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12.75a2 2 0 0 0 2-2v-1.033l2.819 1.348A1 1 0 0 0 23 20.413v-6.826a1 1 0 0 0-1.431-.902zm-2-1.533H4a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h12.75a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5m2 3.195v2.61l2.75 1.315v-5.24z" clipRule="evenodd"/></svg>)}
    />
  );
}
  