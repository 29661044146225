// @flow

import reducer from './historyActionReducer';
import * as selectors from './historyActionSelectors';
import type { HistoryActionEntityT } from './historyActionTypes';
import operations from './historyActionOperations';

export default reducer.rootReducer;
export { operations, selectors };
export type { HistoryActionEntityT };
