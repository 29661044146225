import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileTxtFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M15.3 7.2h5c.3 0 .5.2.5.5v14.5c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h8.4c.3 0 .5.2.5.5v5c0 1 .7 1.7 1.6 1.7M9 16.5v-1H5.3v1h1.3v3.7h1v-3.7zm4.9 3.7-1.5-2.4 1.5-2.3h-1.2l-.9 1.5-.9-1.5H9.6l1.5 2.4-1.5 2.3h1.2l.9-1.5.9 1.5zm4.4-4.7v1H17v3.7h-1v-3.7h-1.3v-1zm1.5-9.6c.1 0 .2-.1.2-.2s0-.2-.1-.3L15.3.7c-.1-.1-.2-.1-.3-.1v4.9c0 .2.2.4.4.4z" clipRule="evenodd"/></svg>)}
    />
  );
}
  