import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPhoneLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.2 24c-1.2 0-2.3-.4-3.3-1C9.4 19.3 4.7 14.6 1 9.2c-1.6-2.4-1.2-5.6.8-7.7l.8-.8C3.5-.2 5-.3 6 .7L9.3 4c.9.9.9 2.4 0 3.4-.6.5-.6 1.4 0 2l5.3 5.3c.3.3.6.4 1 .4s.7-.1 1-.4c.9-.9 2.4-.9 3.4 0l3.3 3.3c.9.9.9 2.4 0 3.4l-.8.8c-1.1 1.1-2.7 1.8-4.3 1.8m-2.8-1.8c2 1.3 4.7 1 6.4-.7l.8-.8c.6-.5.6-1.4 0-2l-3.3-3.3c-.5-.6-1.4-.6-2 0-.4.5-1 .7-1.7.7-.6 0-1.2-.3-1.7-.7l-5.3-5.3c-.9-.9-.9-2.4 0-3.4.6-.5.6-1.4 0-2L5.3 1.4c-.5-.6-1.4-.6-2 0l-.8.8C.8 3.9.5 6.6 1.8 8.6c3.6 5.4 8.2 10 13.6 13.6"/></svg>)}
    />
  );
}
  