// @flow
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import type { CallFlowEntityT, CallFlowGridElementT } from '../../../../ducks/entities/callFlow';
import { selectors as callflowSelectors } from '../../../../ducks/ui/callflow';
import DetailsContainer from './DetailsContainer';
import GridElement from './GridElement';

export type PropsT = {|
  gridElement: CallFlowGridElementT,
  hideDescription?: boolean,
  single?: boolean,
  viewContentSupplier: (?string) => ?Element<*>,
  editContentSupplier: (?string) => ?Element<*>
|};

export const GridElementContainer = (props: PropsT): Element<*> | null => {
  const { gridElement, hideDescription, single, viewContentSupplier, editContentSupplier } = props;

  // redux
  const isOpen: string = useSelector(state =>
    callflowSelectors.getSelectedId(state, gridElement.id)
  );
  const editStatus: boolean = useSelector(state =>
    callflowSelectors.getEditStatus(state, gridElement.id)
  );
  const selectedNodeType: string = useSelector(state =>
    callflowSelectors.getSelectedType(state, gridElement.id)
  );

  const callflowId = `${gridElement.enterpriseId}-${gridElement.type}-${gridElement.id}`;
  const callflow: CallFlowEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const hasFullContent =
    callflow && (callflow.type === 'ACD_SWITCHBOARD' || callflow.type === 'ACD_CUSTOMER_SERVICE')
      ? R.path(['servicesSettings', 'acdCallCenter'], callflow)
      : true;

  const contentSupplier = editStatus ? editContentSupplier : viewContentSupplier;

  return (
    <>
      <GridElement gridElement={gridElement} hideLeftPanel={hideDescription} singleView={single} />
      {isOpen && hasFullContent && (
        <DetailsContainer
          content={contentSupplier(selectedNodeType)}
          serviceId={gridElement.id}
          singleView={single}
        />
      )}
    </>
  );
};

export default GridElementContainer;
