import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFolderRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 4.75H10.88L9.3 2.65a2.26 2.26 0 0 0-1.8-.9H2.25A2.25 2.25 0 0 0 0 4v16a2.25 2.25 0 0 0 2.25 2.25h19.5A2.25 2.25 0 0 0 24 20V7a2.25 2.25 0 0 0-2.25-2.25M22.5 20a.76.76 0 0 1-.75.75H2.25A.76.76 0 0 1 1.5 20V4a.76.76 0 0 1 .75-.75H7.5a.74.74 0 0 1 .6.3L9.9 6a.74.74 0 0 0 .6.3h11.25a.76.76 0 0 1 .75.75z"/></svg>)}
    />
  );
}
  