// @flow

import type { UpdateUserColumnsT, FieldToShowT, SelectedCategoryT } from './userUiTypes';

type CreateUpdateUserColumnsToShowFnT = FieldToShowT => UpdateUserColumnsT;
export const createUpdateUserColumnsToShow: CreateUpdateUserColumnsToShowFnT = userColumnsToShow => ({
  type: 'ring/ui/user/UPDATE_USER_COLUMNS',
  payload: {
    userColumnsToShow
  }
});

export const createUpdateSelectedCategory = (selectedCategory: SelectedCategoryT) => ({
  type: 'ring/ui/user/UPDATE_SELECTED_CATEGORY',
  payload: { selectedCategory }
});

export const createUpdateSearchTerm = (searchTerm: string) => ({
  type: 'ring/ui/user/UPDATE_SEARCH_TERM',
  payload: { searchTerm }
});
