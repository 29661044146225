import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHealthFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.679 9.875a.44.44 0 0 1-.422-.308 6.28 6.28 0 0 1 .406-4.586C2.098 2.113 5.619.592 8.971 2.079c.684.303 1.285.769 1.815 1.298L12 4.591l1.223-1.221c.529-.529 1.132-.994 1.817-1.296 3.351-1.478 6.864.041 8.299 2.907.716 1.43.838 3.03.43 4.496a.56.56 0 0 1-.541.397h-7.552a.47.47 0 0 1-.425-.266l-.818-1.682a1 1 0 0 0-.522-.481 1 1 0 0 0-.768.01 1 1 0 0 0-.533.548L10.554 13.2a.143.143 0 0 1-.262.011l-1.38-2.759c-.177-.352-.529-.579-.894-.579zm14.559 1.25c-.397 0-.732-.196-.896-.525 0 0-.44-.929-.546-1.123-.106-.195-.323-.237-.426.005-.138.325-1.979 5.001-1.979 5.001a1 1 0 0 1-.928.629c-.366 0-.72-.202-.896-.552l-1.55-3.098a.61.61 0 0 0-.548-.339h-5.99c-.319 0-.287.349-.163.516q.236.32.522.609l9.803 10.099a.5.5 0 0 0 .717.002l9.8-10.097c.226-.226.425-.47.606-.724.094-.132.012-.402-.267-.402z"/></svg>)}
    />
  );
}
  