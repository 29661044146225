// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WelcomeAnnouncementIcon } from '../../../../../../assets/callflow/details/welcome-announcement-small.svg';
import CallflowDetails from '../../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../../components/view/CallflowDetailsColumn';
import type { WelcomeAttendantEntityT } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import CallflowItem from '../../../../components/view/children/CallflowItem';
import WelcomeAttendantAudio from './WelcomeAttendantAudio';
import type { IvrAudioT } from '../../../../components/edit/children/audio/CallflowAudioUtils';

export type PropsT = {|
  callflowId: string,
  commandId: string
|};

export const MessageDetails = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  // redux
  const welcomeAttendant: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const message = R.path(['commands', commandId], welcomeAttendant) || {};
  if (!welcomeAttendant || !message || !message.name) {
    return null;
  }
  const getAudioData = (audioName: string): IvrAudioT => ({
    enterpriseId: welcomeAttendant.enterpriseId,
    callflowType: 'welcomeattendants',
    callflowId: welcomeAttendant.id,
    audioType: 'name',
    filename: audioName
  });

  const audios = message.audios || [];

  return (
    <CallflowDetails
      enterpriseId={welcomeAttendant.enterpriseId}
      callflowId={welcomeAttendant.id}
      elementType="WELCOME_ATTENDANT"
      nodeId={message.name.toUpperCase()}
      nodeType={message.type}
      icon={<WelcomeAnnouncementIcon />}
      title={t('callflows.viewWelcomeAttendantMessage.title')}
      active
    >
      <CallflowDetailsColumn id="wa-message-column-1">
        <CallflowItem
          id={`step-name-${message.id}`}
          title={t('callflows.welcomeAttendantGeneric.stepName')}
          value={message.name}
        />

        {!audios ? (
          <CallflowItem
            id={`${welcomeAttendant.id}_audio_not_set`}
            title={t('callflows.viewWelcomeAttendantMessage.audio')}
            value={t('callflows.valueNotSet')}
          />
        ) : (
          audios.map(audio => (
            <WelcomeAttendantAudio
              key={`${welcomeAttendant.id}_audio_${audio}`}
              title={t('callflows.viewWelcomeAttendantMessage.audio')}
              audioPlayData={getAudioData(audio)}
            />
          ))
        )}

        <CallflowItem
          id="nextState"
          title={t('callflows.viewWelcomeAttendantMessage.nextState')}
          value={
            message.nextState && message.nextState.state
              ? message.nextState.state
              : t('callflows.targetNotSet')
          }
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default MessageDetails;
