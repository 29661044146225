// @flow strict-local

import * as R from 'ramda';
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import SubTopic from '../SubTopic';

import styles from './NumberRangeSelector.module.scss';

export type NumberRangeFieldT = {
  +number: string,
  +range: string
};

type PropsT = {|
  field: string,
  title: string,
  rangeLabel: string,
  numberLabel: string,
  numberRangeOptions: { value: string, label: string }[],
  numberOptions: { value: string, label: string }[]
|};

export const NumberRangeSelector = (props: PropsT): Element<'div'> => {
  const { field, title, rangeLabel, numberLabel, numberRangeOptions, numberOptions } = props;
  const { setValue, watch } = useFormContext();
  const values = watch();

  const rangeValue = R.path([field, 'range'], values) || '';
  const numberValue = R.path([field, 'number'], values) || '';
  return (
    <div className={styles.container}>
      <SubTopic title={title} />
      {numberRangeOptions.length > 0 && (
        <div className={styles.dropdown}>
          <Dropdown
            items={numberRangeOptions}
            label={rangeLabel}
            selectedValue={rangeValue}
            onValueChange={option => setValue(field, { ...field, range: option.dataset.value })}
          />
        </div>
      )}
      <div className={styles.dropdown}>
        <Dropdown
          items={numberOptions}
          label={numberLabel}
          selectedValue={numberValue}
          onValueChange={option => setValue(field, { ...field, number: option.dataset.value })}
        />
      </div>
    </div>
  );
};

export default NumberRangeSelector;
