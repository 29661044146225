import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowDownFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.3 16.2c-.7.7-1.8.7-2.5 0L4.1 9.4c-.5-.5-.5-1.3 0-1.8s1.3-.5 1.8 0L12 14l6.1-6.3c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8z"/></svg>)}
    />
  );
}
  