// @flow strict-local

import React, { type Element, type Node } from 'react';
import classnames from 'classnames';
import styles from './InfoBox.module.scss';

export type PropsT = {|
  children: Node,
  extraStyle?: string
|};

export const InfoBox = ({ children, extraStyle }: PropsT): Element<'div'> => (
  <div className={classnames(styles.box, extraStyle)}>{children}</div>
);

export default InfoBox;
