import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrangeDescRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 15.47a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4A.75.75 0 0 1 2 15.47l2.72 2.72V4a.75.75 0 0 1 1.5 0v14.19L9 15.47a.75.75 0 0 1 1 0m12.5 3.78h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5m0-4h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5m0-4h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5m0-4h-9a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5m-9-2.5h9a.75.75 0 0 0 0-1.5h-9a.75.75 0 0 0 0 1.5"/></svg>)}
    />
  );
}
  