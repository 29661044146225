import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNewMessageFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.001 8.572V22c0 1.103-.897 2-2 2H2c-1.103 0-2-.897-2-2V5c0-1.103.897-2 2-2h13.429a.25.25 0 0 1 .176.426L9.033 9.999a.74.74 0 0 0-.212.425l-.651 4.56a.74.74 0 0 0 .212.637.76.76 0 0 0 .637.213l4.56-.653a.76.76 0 0 0 .424-.212l6.572-6.572a.25.25 0 0 1 .426.175m-2.697-5.723c-.047-.047-.11-.073-.177-.073s-.13.026-.177.073l-7.858 7.857a.25.25 0 0 0-.071.142l-.472 3.322a.25.25 0 0 0 .283.282l3.32-.475a.25.25 0 0 0 .142-.07l7.858-7.858a.25.25 0 0 0 0-.354zm4.534-1.686c-.854-.854-2.347-.854-3.2 0l-.448.448a.25.25 0 0 0 0 .354l2.847 2.847c.047.047.11.073.177.073s.13-.026.177-.073l.448-.448a2.25 2.25 0 0 0 .661-1.601c0-.605-.235-1.173-.662-1.6"/></svg>)}
    />
  );
}
  