import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowUpAltFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.998 0c.472 0 .931.194 1.258.534l6.642 6.846a1.247 1.247 0 0 1-.027 1.767 1.25 1.25 0 0 1-1.768-.027L13.25 4.118V22.75a1.25 1.25 0 0 1-2.5 0V4.114L5.897 9.116a1.25 1.25 0 0 1-1.795-1.74L10.741.532A1.76 1.76 0 0 1 11.998 0"/></svg>)}
    />
  );
}
  