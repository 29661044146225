import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSustainabilityLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.132 20.361A12.2 12.2 0 0 1 10.254 22a8.17 8.17 0 0 1-6.068-2.729.51.51 0 0 1 0-.71.5.5 0 0 1 .706-.003l.003.003A7.14 7.14 0 0 0 10.244 21a11.2 11.2 0 0 0 5.389-1.509c8.363-4.708 7.555-13.794 7.106-16.483a14.6 14.6 0 0 1-8.423 2.209c-5.798 0-8.284 2.409-8.284 2.439a7.51 7.51 0 0 0-2.994 5.997c.004.887.163 1.767.469 2.599l.689-.6a25.9 25.9 0 0 1 8.982-4.578.517.517 0 1 1 .27 1 24.8 24.8 0 0 0-8.603 4.328L.853 19.871a.51.51 0 0 1-.709-.05.51.51 0 0 1 0-.71l2.505-2.179a8.454 8.454 0 0 1 2.664-9.996c.05-.07 2.675-2.689 8.982-2.689a13.4 13.4 0 0 0 7.824-1.999 1 1 0 0 1 .788-.24 1 1 0 0 1 .659.43q.117.19.16.41c.48 2.849 1.348 12.485-7.594 17.513"/></svg>)}
    />
  );
}
  