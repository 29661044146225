// @flow

import React, { Component, type Element } from 'react';
import Modal from 'react-modal';
import Triangle from './Triangle';
import styles from './ModalPopOver.module.scss';

export type PropsT = {
  isOpen: boolean,
  children: Element<*>,
  contentLabel: string,
  location: {
    x: number,
    y: number
  },
  locationType: 'arrow' | 'popOver',
  arrowOffsetLeft?: number,
  modalTestMode?: boolean,
  overlayAbsolutePosition?: boolean,
  onClose?: () => void,
  popOverWidth?: number,
  popOverHeight?: number,
  withArrow?: boolean,
  flipY?: boolean
};

type StateT = {
  popOverTopLeftLocation: { x: number, y: number },
  initialScrollYOffset: number,
  initialScrollXOffset: number
};

export class ModalPopOver extends Component<PropsT, StateT> {
  static defaultProps = {
    modalTestMode: false
  };

  constructor(props: PropsT) {
    super(props);
    this.state = {
      popOverTopLeftLocation: { x: 0, y: 0 },
      initialScrollYOffset: 0,
      initialScrollXOffset: 0
    };
    this.adjustPopOverPositionWhenScrolling = this.adjustPopOverPositionWhenScrolling.bind(this);
    this.renderTriangle = this.renderTriangle.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.setInitialModalPopOverPosition();
    window.addEventListener('scroll', this.adjustPopOverPositionWhenScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.adjustPopOverPositionWhenScrolling);
  }

  setInitialModalPopOverPosition() {
    const { location, locationType = 'arrow', arrowOffsetLeft, flipY, popOverHeight } = this.props;
    const loc = location || { x: 0, y: 0 };
    const arrowHeight = 18;
    let popOverTopLeftLocation = loc;
    if (locationType === 'arrow') {
      const offset = arrowOffsetLeft || 0;
      popOverTopLeftLocation = {
        x: loc.x - offset - parseInt(styles.arrowWidth, 10) / 2,
        y: flipY && popOverHeight ? loc.y - popOverHeight - arrowHeight : loc.y
      };
    }
    this.setState({
      popOverTopLeftLocation,
      initialScrollYOffset: window.pageYOffset
    });
  }

  adjustPopOverPositionWhenScrolling: () => void;

  adjustPopOverPositionWhenScrolling() {
    const { popOverTopLeftLocation, initialScrollYOffset, initialScrollXOffset } = this.state;
    if (this.modalContentRef != null) {
      const scrollAdjustedTopPosition =
        popOverTopLeftLocation.y + initialScrollYOffset - window.pageYOffset;
      this.modalContentRef.style.top = `${scrollAdjustedTopPosition}px`;
      const scrollAdjustedLeftPosition =
        popOverTopLeftLocation.x + initialScrollXOffset - window.pageXOffset;
      this.modalContentRef.style.left = `${scrollAdjustedLeftPosition}px`;
    }
  }

  modalContentRef: ?HTMLElement;

  renderTriangle: () => Element<typeof Triangle>;

  renderTriangle() {
    const { arrowOffsetLeft, flipY } = this.props;
    return (
      <Triangle
        arrowSize={{
          width: parseInt(styles.arrowWidth, 10) || 0,
          height: parseInt(styles.arrowHeight, 10) || 0
        }}
        arrowOffsetLeft={arrowOffsetLeft || 0}
        arrowOffsetTop={flipY ? 12 : 0}
        flipY={flipY}
      />
    );
  }

  render(): Element<typeof Modal> {
    const {
      withArrow = true,
      children,
      isOpen,
      contentLabel,
      modalTestMode,
      onClose,
      flipY,
      overlayAbsolutePosition,
      popOverWidth = 340,
      popOverHeight = 400
    } = this.props;

    const popOverStyle = {
      overlay: {
        zIndex: '100',
        backgroundColor: 'transparent',
        position: overlayAbsolutePosition ? 'absolute' : 'fixed'
      },
      content: {
        left: `${this.state.popOverTopLeftLocation.x}px`,
        top: `${this.state.popOverTopLeftLocation.y}px`,
        width: `${popOverWidth}px`,
        height: `${popOverHeight}px`,
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0',
        overflow: 'visible'
      }
    };
    return (
      <Modal
        isOpen={isOpen}
        style={popOverStyle}
        contentLabel={contentLabel}
        contentRef={(ref: HTMLElement) => {
          this.modalContentRef = ref;
        }}
        ariaHideApp={!modalTestMode}
        onRequestClose={onClose}
      >
        <div>
          {withArrow && !flipY && this.renderTriangle()}
          <div className={styles['content-container']}>{children}</div>
          {withArrow && flipY && this.renderTriangle()}
        </div>
      </Modal>
    );
  }
}

ModalPopOver.defaultProps = {
  popOverWidth: 340,
  popOverHeight: 0,
  onClose: () => {}
};

export default ModalPopOver;
