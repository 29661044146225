// @flow
import type {
  AudioDynamicPropertyT,
  DynamicPropertyFilesOnLevelT
} from '../../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import type { AudioFieldT, PropertyAudioT, ServiceSettingNameT } from './CallflowAudioUtils';
import type {
  AudioLevelT,
  CallFlowUrlNameT
} from '../../../../../../ducks/entities/callFlow/callFlowTypes';

class AudioFieldConverter {
  enterpriseId: string;

  callflowType: CallFlowUrlNameT;

  callflowId: string;

  serviceSettingName: ServiceSettingNameT;

  filename: string;

  constructor(
    enterpriseId: string,
    callflowId: string,
    callflowType: CallFlowUrlNameT,
    serviceSettingName: ?ServiceSettingNameT
  ) {
    this.enterpriseId = enterpriseId;
    this.callflowId = callflowId;
    this.callflowType = callflowType;
    this.serviceSettingName = serviceSettingName || 'ACDCallCenter';
  }

  convertToAudioPropertyField(
    fieldName: string,
    filename: string,
    audioDynamicProperty?: AudioDynamicPropertyT,
    level?: AudioLevelT
  ): AudioFieldT<PropertyAudioT> {
    const hasAudioFilesOnLevels: DynamicPropertyFilesOnLevelT[] =
      audioDynamicProperty && audioDynamicProperty.hasAudioFilesOnLevels
        ? audioDynamicProperty.hasAudioFilesOnLevels
        : [];
    const audioPlayData = this.createPropertyAudioPlayData(
      fieldName,
      filename,
      audioDynamicProperty
    );
    return {
      fieldName,
      audioPlayData,
      hasAudioFilesOnLevels,
      fileToImport: undefined,
      level: level || audioPlayData.level
    };
  }

  createPropertyAudioPlayData(
    fieldName: string,
    filename: string,
    audioDynamicProperty: ?AudioDynamicPropertyT
  ): PropertyAudioT {
    return {
      audioType: 'property',
      enterpriseId: this.enterpriseId,
      callflowType: this.callflowType,
      callflowId: this.callflowId,
      serviceSettingName: this.serviceSettingName,
      fieldName,
      filename,
      level: audioDynamicProperty ? audioDynamicProperty.level : null
    };
  }
}

export default AudioFieldConverter;
