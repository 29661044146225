import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSimLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 5 16.1.7c-.4-.4-1-.7-1.5-.7H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.3c0-.5-.2-.9-.5-1.3M20 22c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h3v3.5c0 .3.2.5.5.5s.5-.2.5-.5V1h3v3.5c0 .3.2.5.5.5s.5-.2.5-.5V1h1.6c.3 0 .6.1.8.4l4.4 4.3c.1.1.2.4.2.6z"/><path d="M14.5 12h-6C7.1 12 6 13.1 6 14.5v4C6 19.9 7.1 21 8.5 21h6c1.4 0 2.5-1.1 2.5-2.5v-4c0-1.4-1.1-2.5-2.5-2.5m1.5 2.5V16h-2v-3h.5c.8 0 1.5.7 1.5 1.5M10 13h3v7h-3zm-1.5 0H9v3H7v-1.5c0-.8.7-1.5 1.5-1.5M7 18.5V17h2v3h-.5c-.8 0-1.5-.7-1.5-1.5m7.5 1.5H14v-3h2v1.5c0 .8-.7 1.5-1.5 1.5"/></svg>)}
    />
  );
}
  