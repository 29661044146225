// @flow
import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import * as R from 'ramda';
import parse from 'html-react-parser';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { ReactComponent as ExtGroupOverflowIcon } from '../../../../../assets/callflow/details/ext-group-overflow-small.svg';
import DestinationField from '../../../components/edit/children/destinationField/DestinationField';
import { convertForwardingToDestinationField } from '../../../components/edit/children/destinationField/DestinationFieldUtils';
import type { DestinationFieldT } from '../../../components/edit/children/destinationField/DestinationFieldUtils';
import {
  createForwardingPayload,
  DEFAULT_NO_ANSWER_FORWARDING_DELAY,
  getActiveForwardingsByType,
  getForwardingTargetName,
  getFwdTargetDestination,
  hasInvalidForwardingConfiguration
} from './extensionGroupForwardingUtils';
import type { CallForwardingT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import { operations as callForwardingOps } from '../../../../../ducks/entities/callForwarding';
import { createCallForwarding } from '../../../../../ducks/entities/callForwarding/callForwardingOperations';
import { retrieve } from '../../../../../ducks/entities/callFlow/callFlowOperations';
import InvalidConfigurationInfo from '../../../components/view/children/InvalidConfigurationInfo';
import IntegerField from '../../../components/edit/children/IntegerField';
import {
  getIntegerFieldLimits,
  validateForwardingField
} from '../../../../../utils/validationUtils';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  onBusyFwdTarget: DestinationFieldT,
  onNoAnswerFwdTarget: DestinationFieldT,
  onUnreachableFwdTarget: DestinationFieldT,
  noAnswerForwardingDelay: number
};

let cancelTokenSource;

export const EditExtensionGroupOverflow = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const enterpriseId = R.path(['currentEnterprise', 'id'], currentUser) || '';

  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );

  const onBusyTarget = getFwdTargetDestination(extGroupData.forwardings, 'ON_BUSY');
  const onNoAnswerTarget = getFwdTargetDestination(extGroupData.forwardings, 'NO_ANSWER');
  const onUnReachableTarget = getFwdTargetDestination(extGroupData.forwardings, 'UNREACHABLE');
  const hasInvalidData = hasInvalidForwardingConfiguration(extGroupData.forwardings);
  const noAnswerFwds = getActiveForwardingsByType(extGroupData.forwardings, 'NO_ANSWER');

  // $FlowFixMe build form schema
  const extensionGroupSchema = yup.object().shape({
    onBusyFwdTarget: validateForwardingField(t('callflows.validation.forwardingError')),
    onNoAnswerFwdTarget: validateForwardingField(t('callflows.validation.forwardingError')),
    onUnreachableFwdTarget: validateForwardingField(t('callflows.validation.forwardingError')),
    noAnswerForwardingDelay: yup
      .number()
      .integer()
      .min(15)
      .max(115)
      .default(45)
      .required()
  });

  const initialFormValues: FormT = {
    onBusyFwdTarget: convertForwardingToDestinationField(
      onBusyTarget,
      getForwardingTargetName(onBusyTarget, extGroupData),
      true
    ),
    onNoAnswerFwdTarget: convertForwardingToDestinationField(
      onNoAnswerTarget,
      getForwardingTargetName(onNoAnswerTarget, extGroupData),
      true
    ),
    onUnreachableFwdTarget: convertForwardingToDestinationField(
      onUnReachableTarget,
      getForwardingTargetName(onUnReachableTarget, extGroupData),
      true
    ),
    noAnswerForwardingDelay:
      noAnswerFwds && noAnswerFwds.length === 1 && noAnswerFwds[0].noAnswerForwardingDelay
        ? noAnswerFwds[0].noAnswerForwardingDelay / 1000
        : DEFAULT_NO_ANSWER_FORWARDING_DELAY
  };

  const deleteExistingForwardingsByType = async fwdType => {
    const fwdIdsToBeRemoved = getActiveForwardingsByType(extGroupData.forwardings, fwdType).map(
      fwd => fwd.id
    );
    const promises = fwdIdsToBeRemoved.map(forwardingId =>
      dispatch(
        callForwardingOps.deleteCallForwarding(
          enterpriseId,
          forwardingId,
          createCsrfHeader(currentUser)
        )
      )
    );
    await Promise.all(promises);
  };

  const onSaveForm = async (formData: FormT): Promise<?CallForwardingT> => {
    await deleteExistingForwardingsByType('ON_BUSY');
    await deleteExistingForwardingsByType('NO_ANSWER');
    await deleteExistingForwardingsByType('UNREACHABLE');
    const onBusyFwd = getActiveForwardingsByType(extGroupData.forwardings, 'ON_BUSY');
    const onNoAnswerFwd = getActiveForwardingsByType(extGroupData.forwardings, 'NO_ANSWER');
    const onOnUnreachableFwd = getActiveForwardingsByType(extGroupData.forwardings, 'UNREACHABLE');

    const newOnBusyFwd = createForwardingPayload(formData.onBusyFwdTarget, 'ON_BUSY', onBusyFwd);
    const newOnNoAnswerFwd = createForwardingPayload(formData.onNoAnswerFwdTarget, 'NO_ANSWER', [
      {
        ...onNoAnswerFwd[0],
        noAnswerForwardingDelay: formData.noAnswerForwardingDelay * 1000
      }
    ]);
    const newOnUnreachableFwd = createForwardingPayload(
      formData.onUnreachableFwdTarget,
      'UNREACHABLE',
      onOnUnreachableFwd
    );

    if (newOnBusyFwd) {
      await dispatch(
        createCallForwarding(
          enterpriseId,
          newOnBusyFwd,
          null,
          extGroupData.id,
          createCsrfHeader(currentUser)
        )
      );
    }
    if (newOnNoAnswerFwd) {
      await dispatch(
        createCallForwarding(
          enterpriseId,
          newOnNoAnswerFwd,
          extGroupData.id,
          null,
          createCsrfHeader(currentUser)
        )
      );
    }
    if (newOnUnreachableFwd) {
      await dispatch(
        createCallForwarding(
          enterpriseId,
          newOnUnreachableFwd,
          extGroupData.id,
          null,
          createCsrfHeader(currentUser)
        )
      );
    }

    return dispatch(
      retrieve(enterpriseId, extGroupData.id, 'EXTENSION_GROUP', cancelTokenSource.token, false)
    );
  };

  useEffect(() => {
    cancelTokenSource = CancelToken.source();
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
    };
  }, []); // eslint-disable-line

  return (
    <EditCallflowDetails
      nodeId={extGroupData.id}
      icon={<ExtGroupOverflowIcon />}
      title={t('callflows.editExtensionGroupOverflow.title')}
      defaultValues={initialFormValues}
      validationSchema={extensionGroupSchema}
      onSaveForm={onSaveForm}
    >
      {hasInvalidData ? (
        <InvalidConfigurationInfo
          title={t('callflows.viewExtensionGroupOverflow.invalidDataTitle')}
        />
      ) : null}

      <DestinationField
        field="onBusyFwdTarget"
        title={t('callflows.editExtensionGroupOverflow.onBusyFwd')}
        notInUseAvailable
        currentAddressNumber={extGroupData.addressNumber}
      />

      <DestinationField
        field="onNoAnswerFwdTarget"
        title={t('callflows.editExtensionGroupOverflow.onNoAnswerFwd')}
        notInUseAvailable
        currentAddressNumber={extGroupData.addressNumber}
      />

      <IntegerField
        field="noAnswerForwardingDelay"
        title={t('callflows.viewExtensionGroupOverflow.noAnswerForwardingDelay')}
        tooltip={parse(
          `${t('callflows.editExtensionGroupOverflow.onNoAnswerTooltip1')}<br/>${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip2'
          )}<br/><ul style="margin-left:30px;"><li style="list-style-type:disc">${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip3'
          )}</li><li style="list-style-type:disc">${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip4'
          )}</li><li style="list-style-type:disc">${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip5'
          )}</li></ul><br/>${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip6'
          )}</li></ul><br/>${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip7'
          )}<br/><ul style="margin-left:30px;"><li style="list-style-type:disc">${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip8'
          )}</li><li style="list-style-type:disc">${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip9'
          )}</li><li style="list-style-type:disc">${t(
            'callflows.editExtensionGroupOverflow.onNoAnswerTooltip10'
          )}</li></ul>`
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupSchema.fields.noAnswerForwardingDelay)
        )}
        disabledFn={(formData: FormT) => formData.onNoAnswerFwdTarget.type === 'NOT_IN_USE'}
      />

      <DestinationField
        field="onUnreachableFwdTarget"
        title={t('callflows.editExtensionGroupOverflow.onUnreachableFwd')}
        notInUseAvailable
        currentAddressNumber={extGroupData.addressNumber}
      />
    </EditCallflowDetails>
  );
};

export default EditExtensionGroupOverflow;
