import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrangeAscRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 8.53a.75.75 0 0 0 0-1.06l-4-4a.75.75 0 0 0-1 0l-4 4a.75.75 0 0 0 1 1.06l2.75-2.72V20a.75.75 0 0 0 1.5 0V5.81L9 8.53a.75.75 0 0 0 1 0m12.5-3.78h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5m0 4h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5m0 4h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5m0 4h-9a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 0 1.5m-9 2.5h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1 0-1.5"/></svg>)}
    />
  );
}
  