// @flow

import React, { type Element, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
// $FlowFixMe
import readXlsxFile from 'read-excel-file';
// $FlowFixMe
import convertToJson from 'read-excel-file/map';
import type { XlsxRowDataT } from './importUtils';
import ImportServicesTable from './ImportServicesTable';
import styles from './ImportServices.module.scss';

export type PropsT = {|
  enterpriseId: string
|};

const ImportServices = (props: PropsT): Element<'div'> => {
  const { enterpriseId } = props;

  // State
  const [fileNameToImport, setFileNameToImport] = useState<?string>(null);
  const [dataToImport, setDataToImport] = useState<XlsxRowDataT[]>([]);

  // eslint-disable-next-line no-unused-vars
  const [fileMetaData, setFileMetaData] = useState<string[]>([]);

  const { t } = useTranslation();

  const parseRows = rowsData => {
    if (rowsData.length > 2) {
      setFileMetaData(rowsData[0]);
      const tableData = rowsData.slice(1);
      const schema = {};
      tableData[0].forEach(header => {
        schema[header] = {
          prop: header,
          type: String
        };
      });
      const { rows } = convertToJson(tableData, schema);
      setDataToImport(rows);
    }
  };

  const handleOnDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length === 1 && enterpriseId) {
      readXlsxFile(acceptedFiles[0]).then(rows => parseRows(rows));
      setFileNameToImport(acceptedFiles[0].name);
    }
  };

  const selectFileView = (
    <div id="callflow-import-xlsx" data-cy="file-upload" className={styles.choose}>
      <div className={styles['choose-header']}>{t('importServices.selectFileViewHeader')}</div>
      <div className={styles['choose-text']}>
        {parse(`<span>${t('importServices.selectFileViewText')}</span>`)}
      </div>
    </div>
  );

  const selectedFile = (
    <div
      id="callflow-import-select-file"
      data-cy="file-upload-select-file"
      className={styles.choose}
    >
      <div className={styles['choose-header']}>
        {t('importServices.selectedFile', { fileNameToImport })}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles['header-container']}>
        <h4 className={styles.title} data-cy="calendars-title">
          {t('importServices.title')}
        </h4>
      </div>
      <div className={styles['content-container']}>
        <div>{t('importServices.checkExtensions')}</div>
        <div>{t('importServices.checkAudios')}</div>
        <div>
          {t('importServices.addXlsxFile')}
          <Dropzone
            id="file-dropzone"
            data-cy="file-dropzone"
            onDrop={handleOnDrop}
            className={styles.dropzone}
            activeClassName={styles['dropzone-active']}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className={styles.dropzone}>
                  <input {...getInputProps()} id="file-dropzone" />
                  {!fileNameToImport ? selectFileView : selectedFile}
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
      <ImportServicesTable
        dataToImport={dataToImport}
        enterpriseId={enterpriseId}
        fileMetaData={fileMetaData}
      />
    </div>
  );
};

export default ImportServices;
