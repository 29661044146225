// @flow

import { combineReducers } from 'redux';
import type { StoreStateT } from '../../commonTypes';

import header from './header';
import notification from './notification';
import user from './user';
import callflow from './callflow';
import enterprises from './enterprises';
import tour from './tour';
import department from './department';
import forms from './forms';
import userImport from './userImport';
import historyActions from './historyActions';
import tooltip from './tooltip/tooltipUiReducer';
import callForwardings from './callForwardings';

const reducers = {
  header,
  notification,
  user,
  enterprises,
  tour,
  department,
  forms,
  userImport,
  historyActions,
  callflow,
  tooltip,
  callForwardings
};

export default combineReducers<$PropertyType<StoreStateT, 'ui'>, *>(reducers);
