// @flow
import React, { type Element } from 'react';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import AudioFileSelection from './AudioFileSelection';
import type {
  VoiceMailAudioTypesT,
  VoiceMailEntityT
} from '../../../../../../ducks/entities/voicemailTypes';

import styles from './VoiceMailAudioField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  voicemailSettings: VoiceMailEntityT,
  enterpriseId: string,
  entityId: string,
  serviceType: VoiceMailAudioTypesT
|};

export type VoiceMailAudioFieldT = {
  level: 'PhoneNumber' | 'AudioName' | 'Greeting',
  audioNameFileToImport: *,
  greetingAudioToImport: *
};

export const VoiceMailAudioField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    enterpriseId,
    entityId,
    voicemailSettings,
    serviceType
  } = props;

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const audioField: VoiceMailAudioFieldT = values[field];

  const { t } = useTranslation();

  const hasAudioNameFile = !!R.path(
    ['audios', 'audioName', 'files', 'durationInSeconds'],
    voicemailSettings
  );
  const hasGreetingFile = !!R.path(
    ['audios', 'greeting', 'files', 'durationInSeconds'],
    voicemailSettings
  );

  const audioNameAudio = {
    enterpriseId,
    callflowType: 'acds',
    callflowId: entityId,
    audioType: 'voicemail',
    propertyName: 'AudioName',
    serviceType
  };

  const greetingAudio = {
    enterpriseId,
    callflowType: 'acds',
    callflowId: entityId,
    audioType: 'voicemail',
    propertyName: 'Greeting',
    serviceType
  };

  const getHelpText = () => {
    if (audioField.level === 'AudioName') {
      return t('voicemailSettings.audioDefaultTooltip');
    }
    if (audioField.level === 'Greeting') {
      return t('voicemailSettings.audioAudioTooltip');
    }
    return '';
  };

  return (
    <div className={title && styles['title-padding']}>
      {title && <div className={classnames(styles['small-title'])}>{title}</div>}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div
        className={styles.container}
        role="group"
        aria-label="Message type"
        id="voicemail-type-selection"
      >
        <RadioGroup value={audioField.level} i18n_radiogroup_helpText={getHelpText()}>
          <Radio
            name="voicemailMessage"
            label={t('voicemailSettings.audioDefault')}
            value="PhoneNumber"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: 'PhoneNumber'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              )
            }
          />
          <Radio
            name="voicemailMessage"
            value="AudioName"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: 'AudioName'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              )
            }
            label={t('voicemailSettings.audioAudioName')}
          />
          <Radio
            name="voicemailMessage"
            value="Greeting"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: 'Greeting'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              )
            }
            label={t('voicemailSettings.audioGreeting')}
          />
        </RadioGroup>
        {audioField.level === 'AudioName' && (
          <div className={styles.audio} id="audio-name-audio-file-selection">
            <AudioFileSelection
              audio={audioNameAudio}
              onChange={file =>
                setValue(
                  field,
                  {
                    ...values[field],
                    audioNameFileToImport: file
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              showInitialView={hasAudioNameFile}
              disabled={false}
            />
          </div>
        )}
        {audioField.level === 'Greeting' && (
          <div className={styles.audio} id="greeting-audio-file-selection">
            <AudioFileSelection
              className={styles.audio}
              audio={greetingAudio}
              onChange={file =>
                setValue(
                  field,
                  {
                    ...values[field],
                    greetingAudioToImport: file
                  },
                  {
                    shouldValidate: false,
                    shouldDirty: true
                  }
                )
              }
              showInitialView={hasGreetingFile}
              disabled={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VoiceMailAudioField;
