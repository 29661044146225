"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FOCUS_LAST = exports.FOCUS_FIRST = exports.FOCUS_NONE = void 0;
var react_1 = require("react");
exports.FOCUS_NONE = 0;
exports.FOCUS_FIRST = 1;
exports.FOCUS_LAST = 2;
var HeaderContext = (0, react_1.createContext)({
    activeItem: null,
    desktopBreakpointOffset: 0,
    focusItemOnOpen: exports.FOCUS_NONE,
    isDesktopLayout: function () { return null; },
    isLoggedIn: false,
    setFocusItemOnOpen: function () { },
    toggleActiveItem: function () { },
});
exports.default = HeaderContext;
