import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPictureRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 9.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5m0-3a.75.75 0 1 1-.75.75.76.76 0 0 1 .75-.75m15-4.5H3A2.19 2.19 0 0 0 .75 3.86v16.28A2.19 2.19 0 0 0 3 22.25h18a2.19 2.19 0 0 0 2.25-2.11V3.86A2.19 2.19 0 0 0 21 1.75m.75 18.39a.7.7 0 0 1-.75.61H3a.7.7 0 0 1-.75-.61V3.86A.7.7 0 0 1 3 3.25h18a.7.7 0 0 1 .75.61zm-1.59-2a.75.75 0 0 1-.3 1 .76.76 0 0 1-1.02-.3L14.44 11l-3.11 4.66a1 1 0 0 1-1.66 0l-1.17-1.8-3.38 5.06a.75.75 0 0 1-1.24-.84l3.37-5a1.5 1.5 0 0 1 2.5 0l.75 1.13 2.69-4.05c.297-.427.79-.675 1.31-.66.524.017.999.31 1.25.77z"/></svg>)}
    />
  );
}
  