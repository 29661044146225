import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMoreRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.25 8.75A3.25 3.25 0 1 0 6.5 12a3.26 3.26 0 0 0-3.25-3.25m0 5A1.75 1.75 0 1 1 5 12a1.76 1.76 0 0 1-1.75 1.75m8.75-5A3.25 3.25 0 1 0 15.25 12 3.26 3.26 0 0 0 12 8.75m0 5A1.75 1.75 0 1 1 13.75 12 1.76 1.76 0 0 1 12 13.75m8.75-5A3.25 3.25 0 1 0 24 12a3.26 3.26 0 0 0-3.25-3.25m0 5A1.75 1.75 0 1 1 22.5 12a1.76 1.76 0 0 1-1.75 1.75"/></svg>)}
    />
  );
}
  