import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSettingsRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 24c-1.1 0-2.1-.7-2.4-1.7L9.1 21c-.2-.6-.8-.9-1.4-.7h-.1l-1.1.7c-.6.3-1.3.3-2 .1-.6-.2-1.2-.7-1.5-1.3-.3-.7-.3-1.5 0-2.2l.6-1.2c.1-.3.1-.6 0-.8-.1-.4-.3-.6-.6-.7l-1.2-.4c-1.3-.5-2-2-1.6-3.3.2-.8.8-1.4 1.5-1.6L3 9.1c.6-.2.9-.8.7-1.4v-.1L3 6.5C2.4 5.2 2.9 3.6 4.2 3c.7-.3 1.5-.3 2.2 0l1.2.6c.5.3 1.2 0 1.4-.5V3l.4-1.2c.2-.6.7-1.2 1.3-1.5s1.3-.3 2-.1c.7.3 1.3.8 1.6 1.6l.6 1.2q.15.45.6.6c.3.1.6.1.8 0h.1l1.1-.6c.6-.3 1.3-.3 2-.1s1.2.7 1.5 1.3c.3.7.3 1.5 0 2.2l-.6 1.2c-.1.3-.1.6 0 .8.1.4.3.6.6.7l1.2.4c.6.2 1.2.7 1.5 1.3s.3 1.3.1 2c-.3.7-.8 1.3-1.6 1.6l-1.2.5c-.6.2-.9.8-.7 1.4v.1l.6 1.2c.3.6.3 1.3.1 2s-.7 1.2-1.3 1.5c-.7.3-1.5.3-2.2 0l-1.2-.6c-.5-.3-1.2 0-1.4.5v.1l-.4 1.2c-.4 1-1.4 1.7-2.5 1.7m-3.9-5.2c1.1 0 2.1.7 2.4 1.7l.4 1.2c.2.6.8.9 1.4.7.3-.1.6-.4.7-.7l.4-1.2c.3-.7.8-1.3 1.6-1.6.6-.2 1.4-.2 2 .1l1.2.6q.45.15.9 0t.6-.6c.1-.3.1-.6 0-.8l-.6-1.2c-.3-.7-.3-1.5 0-2.2.3-.6.8-1.1 1.5-1.3l1.2-.4c.3-.1.5-.4.7-.7.1-.3.1-.6 0-.8q-.15-.45-.6-.6l-1.2-.4c-.9-.3-1.5-.9-1.7-1.6-.2-.6-.2-1.4.1-2l.6-1.2c.3-.5 0-1.2-.5-1.4h-.1c-.3-.1-.6-.1-.8 0l-1.3.5c-.7.3-1.5.3-2.2 0-.6-.3-1.1-.8-1.3-1.5L13 2.2c-.2-.6-.8-.9-1.4-.7-.3.1-.6.4-.7.7l-.4 1.2c-.5 1.3-2 2-3.3 1.6-.1 0-.1 0-.2-.1l-1.2-.5q-.45-.15-.9 0c-.6.2-.8.9-.5 1.4L4.9 7c.6 1.3.1 2.8-1.2 3.4-.1 0-.2.1-.2.1l-1.3.5c-.6.2-.9.8-.7 1.4.1.3.4.5.7.7l1.2.4c.8.2 1.4.8 1.6 1.5.2.6.2 1.4-.1 2l-.6 1.2c-.3.5 0 1.2.5 1.5h.1c.3.1.6.1.8 0l1.3-.6c.3-.2.7-.3 1.1-.3m3.9-2c-2.6 0-4.8-2.1-4.8-4.8S9.4 7.3 12 7.3s4.8 2.1 4.8 4.8-2.2 4.7-4.8 4.7m0-8c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2 3.2-1.5 3.2-3.2-1.4-3.2-3.2-3.2"/></svg>)}
    />
  );
}
  