// @flow

type FeaturesT = {
  [flag: string]: boolean
};

const FEATURES: FeaturesT = {
  USER_SORTING: false
};

export default FEATURES;
