// @flow

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import type { IntervalEntryT } from '../callFlowGrid/details/CalendarUtils';
import LinkButton from '../../../components/Button/LinkButton';
import Dismiss from '../../../components/Button/Dismiss';
import styles from './AbsoluteTimeSlot.module.scss';

export type PropsT = {|
  slot: IntervalEntryT,
  editable: boolean,
  handleDelete: IntervalEntryT => void,
  handleUpdate: IntervalEntryT => void
|};

const AbsoluteTimeSlot = (props: PropsT): Element<'div'> | null => {
  const { slot, editable, handleDelete, handleUpdate } = props;
  const { t } = useTranslation();

  let label = slot.active
    ? slot.interval
    : `${slot.interval.split(' ')[0]} ${t('callflows.details.closed')}`;

  if (slot.title) {
    label = label.replace(/^(\S+)\s/, `$1 ${slot.title} `);
  }

  return editable ? (
    <div key={slot.id} className={styles['absolute-time-row']} data-cy="absolute-time-row">
      <LinkButton
        id={`update-time-${slot.id}`}
        className={styles.link}
        label={label}
        onClickAction={event => {
          event.preventDefault();
          handleUpdate(slot);
        }}
      />
      <Dismiss
        id={`delete-time-${slot.id}`}
        onClose={() => handleDelete(slot)}
        dismissStyle={styles['remove-button']}
      />
    </div>
  ) : (
    <div key={slot.id}>{label}</div>
  );
};

export default AbsoluteTimeSlot;
