import React from 'react';
import IconContainer from '../IconContainer';

export default function IconReplyFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 15v4c0 .4 0 .9-.5 1h-.1c-.4 0-.6-.5-.7-.9-.8-3-3.6-5.1-6.8-5.1h-2.5v2.5q0 .9-.6 1.5c-.4.4-.9.6-1.4.5-.5 0-1-.3-1.4-.6l-5.6-6c-.7-.8-.7-2 0-2.7l5.6-6c.5-.5 1-.7 1.5-.7 1.1 0 2 .9 2 2V7H14c4.4 0 8 3.6 8 8"/></svg>)}
    />
  );
}
  