// @flow

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import * as yup from 'yup';
import type { Canceler } from 'axios';
import { useTranslation } from 'react-i18next';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { ReactComponent as AgentsIcon } from '../../../../../assets/callflow/details/agents-small.svg';
import IntegerField from '../../../components/edit/children/IntegerField';
import CallDistributionSelector from '../../../components/edit/children/CallDistributionSelector';
import { updateExtensionGroup } from '../../../../../ducks/entities/extensionGroup/extensionGroupOperations';
import type { ExtensionGroupUpdateT } from '../../../../../ducks/entities/extensionGroup/extensionGroupOperations';
import type { RingPatternT } from '../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import DirectorySelector from '../../../../../components/DirectorySelector/DirectorySelector';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import { fetchExtensionIdWithAddressNumber } from '../../../../../helpers';
import useEnterprise from '../../../../useEnterprise';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  ringPattern: RingPatternT,
  groupMembers: *[],
  maxCycles: number,
  cyclicRingingTime: number
};

// $FlowFixMe: TODO: fix
export const extensionGroupSchema = yup.object().shape({
  maxCycles: yup
    .number()
    .integer()
    .min(1)
    .max(20)
    .default(3)
    .required(),
  cyclicRingingTime: yup
    .number()
    .integer()
    .min(15)
    .max(115)
    .default(27)
    .required()
});

export const EditExtensionGroup = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  const cancelAddressNumberRequest = React.useRef<Canceler>();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { enterprise } = useEnterprise(extGroupData.enterpriseId);
  const initialFormValues: FormT = {
    ringPattern: extGroupData.ringPattern,
    groupMembers: extGroupData.members || [],
    maxCycles: extGroupData.maxCycles,
    cyclicRingingTime: extGroupData.cyclicRingingTime / 1000
  };

  const fetchMemberIds: * = groupMembers => {
    return groupMembers.map(member => {
      if (member.publicInfo) {
        return fetchExtensionIdWithAddressNumber(
          extGroupData.enterpriseId,
          member.publicInfo.addressNumber,
          new CancelToken(canceler => {
            cancelAddressNumberRequest.current = canceler;
          })
        );
      }
      return member.id;
    });
  };

  // update
  const onSubmit = async (formData: FormT): Promise<ExtensionGroupEntityT> => {
    // $FlowFixMe
    return Promise.all(fetchMemberIds(formData.groupMembers)).then(async memberIds => {
      const patchPayload: ExtensionGroupUpdateT = {
        ringPattern: formData.ringPattern,
        maxCycles: formData.maxCycles,
        memberIds,
        cyclicRingingTime: formData.cyclicRingingTime * 1000
      };
      return dispatch(
        updateExtensionGroup(
          extGroupData.enterpriseId,
          extGroupData.id,
          patchPayload,
          CancelToken.source().token,
          createCsrfHeader(currentUser)
        )
      );
    });
  };

  const maxGroupSizesByPatterns = {
    cyclic: (enterprise || {}).maxMembersInExtensionGroup || 50,
    unisson: (enterprise || {}).maxMembersInUnissonGroup || 8,
    sequential: (enterprise || {}).maxMembersInExtensionGroup || 50,
    oldestIdle: (enterprise || {}).maxMembersInExtensionGroup || 50,
    pickUp: (enterprise || {}).maxMembersInExtensionGroup || 50
  };

  const maxSelectedFn = formValues => maxGroupSizesByPatterns[formValues.ringPattern];

  return (
    <EditCallflowDetails
      nodeId={extGroupData.id}
      icon={<AgentsIcon />}
      title={t('callflows.editExtensionGroup.title')}
      description={t('callflows.editExtensionGroup.description')}
      defaultValues={initialFormValues}
      validationSchema={extensionGroupSchema}
      onSaveForm={onSubmit}
    >
      <CallDistributionSelector
        field="ringPattern"
        memberCountFn={(formValues: FormT) => (formValues.groupMembers || []).length}
        unisonMaxMembers={maxGroupSizesByPatterns.unisson}
        isACD={false}
      />

      <DirectorySelector
        field="groupMembers"
        enterpriseId={extGroupData.enterpriseId}
        title={t('callflows.editExtensionGroup.members.title')}
        description={t('callflows.editExtensionGroup.members.description')}
        maxSelected={values => maxSelectedFn(values)}
      />

      <IntegerField
        field="maxCycles"
        maxLength={2}
        title={t('callflows.editExtensionGroup.maxCycles')}
        inputDescription={t('callflows.editExtensionGroup.maxCyclesInputDescription')}
        postFixDescription={t('callflows.editExtensionGroup.maxCyclesPostFix')}
        tooltip={t(
          'callflows.viewExtensionGroup.maxCyclesTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupSchema.fields.maxCycles)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupSchema.fields.maxCycles)
        )}
      />

      <IntegerField
        field="cyclicRingingTime"
        title={t('callflows.editExtensionGroup.cyclicRingingTime')}
        inputDescription={t('callflows.editExtensionGroup.cyclicRingingTimeInputDescription')}
        tooltip={t(
          'callflows.viewExtensionGroup.cyclicRingingTimeTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupSchema.fields.cyclicRingingTime)
        )}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupSchema.fields.cyclicRingingTime)
        )}
      />
    </EditCallflowDetails>
  );
};

export default EditExtensionGroup;
