// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CallForwardingFilterTypeT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import FilterSelection from '../../components/FilterSelection';

type FieldT = {|
  filter: CallForwardingFilterTypeT,
  specificCaller: string
|};

export type PropsT = {|
  field: string,
  shouldValidate?: boolean
|};

export const SpecificCallerFilterSelector = (props: PropsT): Element<typeof FilterSelection> => {
  const { field, shouldValidate } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();

  const value: FieldT = values[field];
  return (
    <FilterSelection
      errorMessage={errors[field] ? errors[field].message : ''}
      filter={value.filter}
      handleChange={({
        filter,
        specificCaller
      }: {
        filter: ?CallForwardingFilterTypeT,
        specificCaller: ?string
      }) => {
        setValue(
          field,
          {
            filter: filter || value.filter,
            specificCaller
          },
          {
            shouldValidate: shouldValidate === undefined ? true : shouldValidate,
            shouldDirty: true
          }
        );
      }}
      specificCaller={value.specificCaller}
    />
  );
};

export default SpecificCallerFilterSelector;
