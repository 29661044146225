import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilePdfLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.562 5.854 15.149.439A1.5 1.5 0 0 0 14.088 0H5C3.897 0 3 .897 3 2v20c0 1.103.897 2 2 2h13.995a2 2 0 0 0 1.999-1.999L21 6.914c0-.399-.155-.775-.438-1.06M19.294 6h-3.8a.5.5 0 0 1-.5-.5V1.698zm-.3 17H5c-.551 0-1-.448-1-1V2c0-.551.448-1 1-1h8.994v4.5c0 .827.673 1.5 1.5 1.5H20l-.007 15.001a1 1 0 0 1-.999.999M7.929 15.524H6.507a.02.02 0 0 0-.02.02v4.452q.002.018.02.02h.944a.02.02 0 0 0 .02-.02v-1.564h.385c.555 0 .989-.129 1.29-.384.303-.257.456-.632.456-1.115 0-.465-.144-.82-.429-1.056-.283-.234-.701-.353-1.244-.353m-.458.813h.41q.378 0 .555.153.174.156.174.476t-.209.485q-.21.168-.639.169h-.291zm4.662-.813h-1.398a.02.02 0 0 0-.02.02v4.452q.002.018.02.02h1.261c.789 0 1.402-.198 1.821-.59s.631-.963.631-1.698c0-.69-.204-1.236-.607-1.622-.405-.386-.98-.582-1.708-.582m-.049 3.673h-.385v-2.86h.483c.826 0 1.246.477 1.246 1.415 0 .973-.44 1.445-1.344 1.445m6.131-3.673a.02.02 0 0 1 .02.02v.773a.02.02 0 0 1-.02.02h-1.604v1.109h1.49a.02.02 0 0 1 .02.02v.771a.02.02 0 0 1-.02.02h-1.49v1.74a.02.02 0 0 1-.02.02h-.93a.02.02 0 0 1-.02-.02v-4.452a.02.02 0 0 1 .02-.02z"/></svg>)}
    />
  );
}
  