import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowRightRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m9 19.56 6.88-6.65a1.23 1.23 0 0 0 0-1.8L9 4.47a.75.75 0 1 0-1 1.08L14.66 12 8 18.48a.76.76 0 0 0 0 1.06.75.75 0 0 0 1 .02"/></svg>)}
    />
  );
}
  