import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvestorsFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.25 4.75c0 2.481-2.018 4.5-4.5 4.5s-4.5-2.019-4.5-4.5S5.269.25 7.75.25s4.5 2.019 4.5 4.5m11.5 13c0 3.309-2.691 6-6 6s-6-2.691-6-6 2.691-6 6-6 6 2.691 6 6m-7.877-.625a2.36 2.36 0 0 1 2.267-1.729c.452 0 .893.129 1.272.372a.624.624 0 1 0 .674-1.052 3.6 3.6 0 0 0-1.946-.569 3.615 3.615 0 0 0-3.551 2.979h-.359a.625.625 0 0 0 0 1.25h.357c.294 1.7 1.77 3 3.553 3a3.6 3.6 0 0 0 1.945-.568.623.623 0 0 0 .19-.862.62.62 0 0 0-.862-.19 2.37 2.37 0 0 1-1.273.371 2.36 2.36 0 0 1-2.274-1.75h1.883a.625.625 0 0 0 0-1.25h-1.876zm-3.627-4.694a.5.5 0 0 0-.212-.35 7.5 7.5 0 0 0-4.266-1.331c-4.145 0-7.518 3.365-7.518 7.5a.5.5 0 0 0 .501.5h9.037a.503.503 0 0 0 .5-.536 6 6 0 0 1-.018-.464c0-1.815.659-3.565 1.855-4.928a.5.5 0 0 0 .121-.391"/></svg>)}
    />
  );
}
  