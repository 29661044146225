import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilmCameraLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M9.75 5.375a4.375 4.375 0 1 1-8.75 0 4.375 4.375 0 0 1 8.75 0m-1 0a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0m11 0a4.375 4.375 0 1 1-8.75 0 4.375 4.375 0 0 1 8.75 0m-1 0a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0m0 8.649V13a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12.75a2 2 0 0 0 2-2v-1.024l2.82 1.343a1 1 0 0 0 1.43-.903v-6.832a1 1 0 0 0-1.43-.903zm-2-2.024H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12.75a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1m2 3.131v3.738L22 20.416v-6.832z" clipRule="evenodd"/></svg>)}
    />
  );
}
  