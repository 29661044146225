// @flow

import debounce from 'lodash/debounce';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { routerMiddleware } from 'connected-react-router-for-react18';
import { LOCALSTORE_WRITE_THROTTLE } from './constants';
import createRootReducer from './ducks';
import { loadState, saveState } from './persistedState';

const persistedState = loadState();
const enhancers = [];
export const history = createBrowserHistory();
const middleware = [thunk, routerMiddleware(history)];

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);

// $FlowFixMe
const store = createStore(createRootReducer(history), persistedState, composedEnhancers);

if (window.Cypress) {
  // eslint-disable-next-line no-underscore-dangle
  window.__store__ = store;
}

export const saveStateToStore = () => {
  const state = store.getState();
  let statusToSave = state.currentUser.__metadata.status; // eslint-disable-line

  if (statusToSave !== 'logged in') {
    statusToSave = 'idle';
  }
  saveState({
    currentUser: {
      ...state.currentUser,
      __metadata: {
        status: statusToSave
      }
    }
  });
};

store.subscribe(
  debounce(saveStateToStore, LOCALSTORE_WRITE_THROTTLE, {
    maxWait: LOCALSTORE_WRITE_THROTTLE
  })
);

export default store;
