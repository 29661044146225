// @flow

/* eslint-disable react/no-unused-state, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React, { type Element, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabNavigation from '@design-system/component-library/src/components/TabNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import Departments from './Departments/Departments';
import { type EnterpriseEntityT } from '../../ducks/entities/enterprise';
import { createSelectEnterpriseAction } from '../../ducks/currentUser/currentUserActions';
import { goToErrorPage } from '../../navigationOperations';
import fieldValidators from '../../fieldValidators';
import Notifications from '../../components/Notifications/Notifications';
import BaseContainer from '../BaseContainer/BaseContainer';
import Locations from './Locations/Locations';
import SingleField from '../../components/FormInput/SingleField';
import EnterpriseSettings from './EnterpriseSettings/EnterpriseSettings';
import TopNavigation from '../navigation/TopNavigation';
import * as enterpriseOps from '../../ducks/entities/enterprise/enterpriseOperations';
import { createCsrfHeader, isAdmin } from '../../utils/accessRightUtils';
import { createSetStepsAction, createStartTourAction } from '../../ducks/ui/tour/tourActions';
import * as enterpriseSelectors from '../../ducks/entities/enterprise/enterpriseSelectors';

import styles from './Enterprise.module.scss';

const Enterprise = (): Element<'div'> => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: enterpriseId } = useParams();
  const currentUser = useSelector(state => state.currentUser);
  const enterpriseName =
    currentUser && currentUser.currentEnterprise ? currentUser.currentEnterprise.fullName : '';
  const enterpriseHasLoadingError = useSelector(state =>
    enterpriseSelectors.enterpriseHasLoadingError(state, enterpriseId)
  );
  const requestCanceller = React.useRef<Canceler>();
  const location = useLocation();
  const [tabSelected, setTabSelected] = useState(
    location.state && location.state.departmentTabSelected ? 'departmentsTab' : 'locationsTab'
  );
  const createLocationInstructions = () => {
    dispatch(
      createSetStepsAction([
        {
          disableBeacon: true,
          title: t('tours.enterpriseTourSteps.step1.title'),
          content: t('tours.enterpriseTourSteps.step1.text'),
          target: '#locationsTab'
        },
        {
          title: t('tours.enterpriseTourSteps.step2.title'),
          content: t('tours.enterpriseTourSteps.step2.text'),
          target: '#add-location-button'
        }
      ])
    );
  };

  const createTourSteps = () => {
    if (tabSelected === 'locationsTab') {
      createLocationInstructions();
    } else if (tabSelected === 'departmentsTab') {
      dispatch(
        createSetStepsAction([
          {
            disableBeacon: true,
            title: t('tours.enterpriseTourSteps.step3.title'),
            content: t('tours.enterpriseTourSteps.step3.text'),
            target: '#departmentsTab',
            position: 'top'
          },
          {
            title: t('tours.enterpriseTourSteps.step4.title'),
            content: t('tours.enterpriseTourSteps.step4.text'),
            target: '#department-action-buttons',
            position: 'top'
          }
        ])
      );
    } else {
      dispatch(createSetStepsAction([]));
    }
  };

  useEffect(() => {
    createTourSteps();
    const isCurrentEnterprise =
      currentUser &&
      currentUser.currentEnterprise &&
      currentUser.currentEnterprise.id === enterpriseId;
    if (enterpriseHasLoadingError) {
      dispatch(goToErrorPage());
    } else if (enterpriseId && !currentUser.currentEnterprise && !isCurrentEnterprise) {
      dispatch(
        enterpriseOps.getEnterprise(
          enterpriseId,
          new CancelToken(canceler => {
            requestCanceller.current = canceler;
          })
        )
      );
    }
    window.scrollTo(0, 0);
    return () => {
      if (requestCanceller.current) {
        requestCanceller.current();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveEnterpriseName = async (name: string) => {
    if (enterpriseId && enterpriseName !== name) {
      // $FlowFixMe
      const data: EnterpriseEntityT = await dispatch(
        enterpriseOps.saveEnterpriseFullName(enterpriseId, name, createCsrfHeader(currentUser))
      );
      if (data && data.fullName) {
        dispatch(createSelectEnterpriseAction(data.entID, data.fullName));
      }
    }
  };

  const showTour = (): void => {
    createTourSteps();
    dispatch(createStartTourAction());
  };

  // eslint-disable-next-line class-methods-use-this
  const validateEnterpriseName = (value: ?string) => {
    const trimmedEnterpriseName = value ? value.trim() : '';
    if (trimmedEnterpriseName.length > 0) {
      return (
        fieldValidators.enterpriseNameFieldValidator(trimmedEnterpriseName, 'error') !== 'error'
      );
    }
    return false;
  };

  useEffect(() => {
    createTourSteps();
    return () => {};
  }, [tabSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseContainer header={<TopNavigation onClickTutorial={showTour} viewName="enterprise" />}>
      <Notifications
        tags={[
          'location-deleted',
          'location-deleted-failure',
          'enterprise-settings-saved',
          'enterprise-settings-saved-failure'
        ]}
      />
      <div className={styles['enterprise-container']}>
        <div className={styles.content}>
          <div className={styles['title-container']}>
            <img src="/enterprise-icon-page.svg" alt="enterprise" />
            <SingleField
              id="enterprise-edit"
              // $FlowFixMe
              onSaveField={saveEnterpriseName}
              initialValue={enterpriseName}
              errorMessage={t('generic.validators.enterpriseName')}
              isValid={validateEnterpriseName}
              hideEdit={!currentUser.multiEnterpriseAdmin}
            />
          </div>
          <TabNavigation>
            <TabNavigation.Tab
              selected={tabSelected === 'locationsTab'}
              title={t('enterprise.locations')}
              id="locationsTab"
              onClick={() => {
                setTabSelected('locationsTab');
              }}
            >
              <Locations enterpriseId={enterpriseId} />
            </TabNavigation.Tab>
            <TabNavigation.Tab
              id="departmentsTab"
              title={t('enterprise.departments')}
              onClick={() => {
                setTabSelected('departmentsTab');
              }}
              selected={tabSelected === 'departmentsTab'}
            >
              <Departments />
            </TabNavigation.Tab>
            {enterpriseId && isAdmin(currentUser) ? (
              <TabNavigation.Tab
                id="adminTab"
                title={t('enterprise.settings')}
                onClick={() => {
                  setTabSelected('adminTab');
                }}
                selected={tabSelected === 'adminTab'}
              >
                <EnterpriseSettings enterpriseId={enterpriseId} />
              </TabNavigation.Tab>
            ) : (
              <TabNavigation.Tab />
            )}
          </TabNavigation>
        </div>
      </div>
    </BaseContainer>
  );
};

export default Enterprise;
