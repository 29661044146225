import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUploadFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.6 18.1v1.8c0 2.3-1.8 4.1-4.1 4.1h-15C2.2 24 .4 22.2.4 19.9v-1.8c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1v1.8c0 1 .8 1.9 1.9 1.9h15c1 0 1.9-.8 1.9-1.9v-1.8c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1M20.114 7.773 12.796.455c-.009-.009-.022-.012-.032-.021A1.1 1.1 0 0 0 12 .125l-.006.001a1.13 1.13 0 0 0-.789.329L3.886 7.773a1.126 1.126 0 0 0 1.591 1.591l5.398-5.398V18.5a1.125 1.125 0 0 0 2.25 0V3.966l5.397 5.398a1.12 1.12 0 0 0 1.592 0 1.126 1.126 0 0 0 0-1.591"/></svg>)}
    />
  );
}
  