// @flow
import { useEffect, useState } from 'react';
import { asyncPool } from '../../../helpers';

const useAsyncPool = (inputData: *, fetchData: (*) => *) => {
  // State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState(undefined);

  useEffect(() => {
    asyncPool(4, inputData, fetchData).then(data => {
      setResult(data);
      setIsLoading(false);
    });
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [result, isLoading];
};

export default useAsyncPool;
