// @flow

import React, { useEffect } from 'react';
import * as R from 'ramda';
import { FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useFormAutoRegister from '../../callFlows/callFlowGrid/details/useFormAutoRegister';
import type { InternalUserEntityT } from '../../../ducks/entities/user/userTypes';
import DirectorySelector from '../../../components/DirectorySelector/DirectorySelector';
import type { DirectoryStateEntityT } from '../../../ducks/entities/directory';
import { actionCreators as departmentUiActions } from '../../../ducks/ui/department';

type PropsT = {|
  enterpriseId: string,
  initialSelectedUsers: InternalUserEntityT[],
  departmentId: ?string
|};

type FormT = {
  selectedUsers: DirectoryStateEntityT[]
};

export const AddDepartmentPeopleForm = (props: PropsT) => {
  const { enterpriseId, initialSelectedUsers, departmentId } = props;
  // redux
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const initialFormValues: FormT = {
    selectedUsers: []
  };
  const methods = useFormAutoRegister(R.keys(initialFormValues), {
    defaultValues: { selectedUsers: initialSelectedUsers }
  });

  const handleUserSelectionChange = (selectedUsers: DirectoryStateEntityT[]) => {
    if (departmentId) {
      dispatch(
        departmentUiActions.createSelectDepartmentUsers(enterpriseId, departmentId, selectedUsers)
      );
    }
  };

  const { selectedUsers } = methods.getValues();
  useEffect(() => {
    handleUserSelectionChange(selectedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  return (
    <FormProvider {...methods}>
      <DirectorySelector
        field="selectedUsers"
        enterpriseId={enterpriseId}
        title={t('enterprise.addPeopleToDepartment.title')}
        description={t('enterprise.addPeopleToDepartment.userSelectorDescription')}
        shouldValidate
        hideInitialSelection
      />
    </FormProvider>
  );
};

export default AddDepartmentPeopleForm;
