// @flow

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import DropdownField from '../edit/children/DropdownField';
import TooltipExtension from '../../../../components/TooltipExtension';
import styles from './GroupDisplayedNumber.module.scss';

export type PropsT = {|
  field: string
|};

export const GroupDisplayedNumber = (props: PropsT): Element<'div'> => {
  const { field } = props;
  const { t } = useTranslation();

  const groupDisplayedNumberOptions = [
    { label: t('callflows.numberToDisplay.originalCaller'), value: 'originalCaller' },
    { label: t('callflows.numberToDisplay.originalCalled'), value: 'originalCalled' },
    { label: t('callflows.numberToDisplay.lastCalledGroup'), value: 'lastCalledGroup' }
  ];
  return (
    <div className={styles['group-displayed-number-area']}>
      <div className={styles['title-area']}>
        <div className={styles.title}>
          {t('callflows.viewCallflowContent.groupDisplayedNumber')}
        </div>
        <TooltipExtension
          title={t('callflows.viewCallflowContent.groupDisplayedNumber')}
          text={parse(
            `${t('callflows.viewCallflowContent.groupDisplayedNumberTooltip1')}<br><br><li>${t(
              'callflows.viewCallflowContent.groupDisplayedNumberTooltip2'
            )}</li><li>${t(
              'callflows.viewCallflowContent.groupDisplayedNumberTooltip3'
            )}</li><li>${t('callflows.viewCallflowContent.groupDisplayedNumberTooltip4')}</li>`
          )}
        />
      </div>
      <div id="group-displayed-number-area" className={styles['group-displayed-number-area']}>
        <DropdownField
          field={field}
          label={t('callflows.groupDisplayedNumber.info')}
          options={groupDisplayedNumberOptions}
        />
      </div>
    </div>
  );
};

export default GroupDisplayedNumber;
