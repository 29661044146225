import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBroadbandLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.41 23h-.08a.49.49 0 0 1-.412-.557l.002-.013q.072-.412.08-.83A5.6 5.6 0 0 0 2.4 16q-.414.002-.82.08a.51.51 0 0 1-.58-.42.49.49 0 0 1 .397-.568l.013-.002q.496-.087 1-.09A6.6 6.6 0 0 1 9 21.59q-.003.504-.09 1a.5.5 0 0 1-.5.41m7.59-.46q.048-.453.05-.91C16.039 14.091 9.919 7.989 2.38 8h-.92a.51.51 0 0 0-.46.55.5.5 0 0 0 .54.45h.82c6.975.006 12.629 5.655 12.64 12.63a8 8 0 0 1-.05.83.51.51 0 0 0 .46.54.5.5 0 0 0 .59-.46m7 0v-.89C22.989 10.25 13.75 1.011 2.35 1h-.88a.5.5 0 0 0-.473.526L1 1.56a.49.49 0 0 0 .53.44h.82c10.844.011 19.633 8.796 19.65 19.64v.83a.5.5 0 0 0 .46.53.49.49 0 0 0 .538-.437L23 22.53z"/></svg>)}
    />
  );
}
  