import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowDownAltRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.002 23.5c-.337 0-.665-.139-.898-.381l-6.642-6.847a.745.745 0 0 1 .016-1.06.75.75 0 0 1 1.061.016l5.712 5.888V1.25a.75.75 0 0 1 1.5 0v19.87l5.712-5.888a.75.75 0 1 1 1.076 1.045L12.9 23.12c-.233.241-.561.38-.898.38"/></svg>)}
    />
  );
}
  