// @flow strict-local

import * as R from 'ramda';
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CallbackReentranceIcon } from '../../../../../assets/callflow/details/dialpad-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';

export type PropsT = {|
  callflowId: string
|};

export const CallbackReentranceDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;

  const { t } = useTranslation();

  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const callflowAudioFieldConverter = new AudioFieldConverter(
    acdData.enterpriseId,
    acdData.id,
    'acds'
  );

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_callback_reentrance_${acdData.id}`}
      nodeType="ACD_CALLBACK_REENTRANCE"
      icon={<CallbackReentranceIcon />}
      title={t('callflows.treeLayout.acd.callbackReentrance')}
      active={acdCallCenter.callbackActivateReentrance}
    >
      <CallflowDetailsColumn id="callback-reentrance-column-1">
        <CallflowProperty
          id="callbackActivateReentrance"
          title={t('callflows.editAcdCallbackDetails.callbackActivateReentrance')}
          tooltip={t('callflows.editAcdCallbackDetails.callbackActivateReentranceTooltip')}
          property={acdCallCenter.callbackActivateReentrance}
          basicValue
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackMenuAccept')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackMenuAccept',
            R.path(['audios', 'callbackMenuAccept', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackMenuAccept'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackMenuAcceptTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackMenuKeep')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackMenuKeep',
            R.path(['audios', 'callbackMenuKeep', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackMenuKeep'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackMenuKeepTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowProperty
          id="callbackAllowModifyNumber"
          title={t('callflows.editAcdCallbackDetails.callbackAllowModifyNumber')}
          property={acdCallCenter.callbackAllowModifyNumber}
          basicValue
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackMenuModify')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackMenuModify',
            R.path(['audios', 'callbackMenuModify', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackMenuModify'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackMenuModifyTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackMenuDecline')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackMenuDecline',
            R.path(['audios', 'callbackMenuDecline', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackMenuDecline'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackMenuDeclineTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackMenuReenter')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackMenuReenter',
            R.path(['audios', 'callbackMenuReenter', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackMenuReenter'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackMenuReenterTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
      </CallflowDetailsColumn>
      <CallflowDetailsColumn id="callback-reentrance-column-2">
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackKept')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackKept',
            R.path(['audios', 'callbackKept', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackKept'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackKeptTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackCancelled')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackCancelled',
            R.path(['audios', 'callbackCancelled', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackCancelled'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackCancelledTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackNumber')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackNumber',
            R.path(['audios', 'callbackNumber', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackNumber'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackNumberTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowAudioProperty
          title={t('callflows.editAcdCallbackDetails.callbackWrongNumber')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackWrongNumber',
            R.path(['audios', 'callbackWrongNumber', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackWrongNumber'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.editAcdCallbackDetails.callbackWrongNumberTooltip')}
          isActive={acdCallCenter.callbackActivateReentrance}
        />
        <CallflowProperty
          id="callbackSendSmsOnCancel"
          title={t('callflows.editAcdCallbackDetails.callbackSendSmsOnCancel')}
          property={acdCallCenter.callbackSendSmsOnCancel}
          basicValue
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default CallbackReentranceDetails;
