import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWebcamLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.23 18.935c4.668-3.437 5.662-10.005 2.222-14.668S10.438-1.391 5.77 2.047.108 12.051 3.549 16.715a10.5 10.5 0 0 0 2.222 2.22l-3.65 4.236a.508.508 0 0 0 .38.829H21.5a.51.51 0 0 0 .38-.829zM2.501 10.513c0-5.242 4.253-9.491 9.499-9.491s9.499 4.249 9.499 9.491-4.253 9.491-9.499 9.491c-5.244-.006-9.494-4.252-9.499-9.491m1.09 12.488 2.95-3.417s0-.07.05-.1a10.39 10.39 0 0 0 10.799 0l3 3.507zM16.5 10.513c0-2.483-2.015-4.496-4.5-4.496S7.5 8.03 7.5 10.513s2.015 4.496 4.5 4.496a4.51 4.51 0 0 0 4.5-4.496m-8 0c0-1.931 1.567-3.497 3.5-3.497s3.5 1.566 3.5 3.497-1.567 3.497-3.5 3.497-3.5-1.566-3.5-3.497M12 4.518a.88.88 0 1 0 0-1.76.88.88 0 0 0 0 1.76"/></svg>)}
    />
  );
}
  