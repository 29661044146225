import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTravelFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.938 4.956-2.962 2.962c.001 2.4.033 4.422.062 6.208.086 5.427.125 7.904-.942 8.989-.427.433-1.03.635-1.899.635-2.106 0-2.182-1.894-2.309-5.03-.058-1.452-.13-3.233-.4-5.313L8.845 19.05v2.426a2.276 2.276 0 0 1-2.274 2.274c-1.702 0-2.058-1.26-2.47-2.719a25 25 0 0 0-.258-.874c-.318-.103-.627-.19-.918-.273C1.49 19.478.25 19.127.25 17.429a2.277 2.277 0 0 1 2.274-2.275H4.95l5.642-5.642c-2.073-.27-3.852-.341-5.302-.399C2.147 8.987.25 8.911.25 6.803c0-.722.196-1.268.599-1.668 1.198-1.191 4.212-1.174 10.806-1.13 1.328.008 2.8.018 4.426.018l2.962-2.962c1.102-1.086 2.838-1.073 3.897 0l.013.013a2.744 2.744 0 0 1-.015 3.882"/></svg>)}
    />
  );
}
  