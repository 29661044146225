import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmarthomeFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.435 11.715 13.383 1.541c-.749-.716-2.004-.729-2.775.01L.565 11.715a.751.751 0 0 0 .533 1.278H2.75a.25.25 0 0 1 .25.25v8.255C3 22.328 3.672 23 4.5 23h15a1.5 1.5 0 0 0 1.5-1.501v-8.255a.25.25 0 0 1 .25-.25h1.651a.753.753 0 0 0 .534-1.279M12 18.86a1.001 1.001 0 0 1 0-2 1 1 0 0 1 0 2m3.254-2.756a.62.62 0 0 1-.883-.001c-1.266-1.266-3.474-1.266-4.741 0a.625.625 0 1 1-.884-.883c.869-.87 2.025-1.349 3.254-1.349s2.386.479 3.255 1.349a.625.625 0 0 1-.001.884m1.688-2.312a.623.623 0 0 1-.884 0C14.974 12.708 13.533 12.11 12 12.11s-2.974.598-4.058 1.682a.625.625 0 1 1-.884-.885c1.32-1.32 3.075-2.047 4.942-2.047s3.622.727 4.942 2.047a.626.626 0 0 1 0 .885"/></svg>)}
    />
  );
}
  