import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDroneFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.75 5C.75 2.657 2.657.75 5 .75S9.25 2.657 9.25 5a.75.75 0 0 1-1.5 0c0-1.517-1.233-2.75-2.75-2.75S2.25 3.483 2.25 5 3.483 7.75 5 7.75a.75.75 0 0 1 0 1.5A4.255 4.255 0 0 1 .75 5m14.75.75a.75.75 0 0 0 .75-.75c0-1.517 1.233-2.75 2.75-2.75S21.75 3.483 21.75 5 20.517 7.75 19 7.75a.75.75 0 0 0 0 1.5c2.344 0 4.25-1.907 4.25-4.25S21.344.75 19 .75 14.75 2.657 14.75 5c0 .414.336.75.75.75m-7 12.5a.75.75 0 0 0-.75.75c0 1.517-1.233 2.75-2.75 2.75S2.25 20.517 2.25 19 3.483 16.25 5 16.25a.75.75 0 0 0 0-1.5C2.657 14.75.75 16.656.75 19S2.657 23.25 5 23.25 9.25 21.344 9.25 19a.75.75 0 0 0-.75-.75m10.5-3.5a.75.75 0 0 0 0 1.5c1.517 0 2.75 1.233 2.75 2.75s-1.233 2.75-2.75 2.75-2.75-1.233-2.75-2.75a.75.75 0 0 0-1.5 0c0 2.344 1.906 4.25 4.25 4.25s4.25-1.906 4.25-4.25-1.906-4.25-4.25-4.25m-.53 4.78a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.061l-3.455-3.455c.263-.446.425-.96.425-1.514v-3c0-.555-.162-1.068-.425-1.514L19.53 5.53a.75.75 0 1 0-1.061-1.061l-3.455 3.455A3 3 0 0 0 13.5 7.5h-3c-.555 0-1.068.162-1.514.425L5.53 4.47a.75.75 0 1 0-1.061 1.061l3.455 3.455A3 3 0 0 0 7.5 10.5v3c0 .555.162 1.068.425 1.514L4.47 18.47a.75.75 0 0 0 1.06 1.061l3.455-3.455c.447.262.96.424 1.515.424h3c.555 0 1.068-.162 1.514-.425z"/></svg>)}
    />
  );
}
  