// @flow
/* eslint-disable no-undef */

import React, { Component, type Element, type ChildrenArray } from 'react';
import { compose, bindActionCreators } from 'redux';
import classnames from 'classnames';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Field } from 'redux-form';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import EditPencil, { type PropsT as EditPencilPropsT } from './EditPencil';
import { actionCreators as formsUiAction } from '../../ducks/ui/forms/index';
import type { StoreStateT } from '../../commonTypes';
import type {
  ActiveSectionT,
  SectionIdT,
  SetActiveFormsSectionT
} from '../../ducks/ui/forms/formsUiTypes';
import Search from '../Search/Search';
import styles from './Section.module.scss';

type StatusT = 'viewing' | 'disabled' | 'editing' | 'error';

type StateT = {
  status: StatusT
};

type OwnPropsT = {
  alwaysEdit?: ?boolean,
  sectionId: ?SectionIdT,
  children: ChildrenArray<?string | ?Element<*>>, // TODO: ChildrenArray<Element<typeof Field | typeof FormSection>>
  title?: string,
  columnTitleRight?: string,
  onSave?: () => void,
  className?: string,
  sectionTitleStyle?: string,
  isSaving?: boolean,
  wasSaved?: boolean,
  touched?: boolean,
  errorMessage?: string,
  onReset?: () => void,
  hasValues?: boolean,
  noValuesMessage?: string,
  showEmptyMessage?: boolean,
  showButtonsBottom?: boolean,
  forceStopEditing?: () => void,
  forceStartEditing?: () => void,
  hideEdit?: ?boolean
};

type StatePropsT = {
  isSectionDisabled: ?boolean
};

type DispatchPropsT = {
  setActiveSection: ActiveSectionT => SetActiveFormsSectionT
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export class Section extends Component<PropsT, StateT> {
  static defaultProps = {
    children: [],
    className: '',
    sectionTitleStyle: '',
    title: '',
    columnTitleRight: '',
    sectionId: null,
    isSaving: false,
    wasSaved: false,
    touched: false,
    hasValues: true,
    showEmptyMessage: true,
    hideEdit: false,
    noValuesMessage: undefined
  };

  constructor(props: PropsT) {
    super(props);
    this.state = {
      status: props.alwaysEdit ? 'editing' : 'viewing'
    };
    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.getEditPencilStatus = this.getEditPencilStatus.bind(this);
    this.manageErrorMode = this.manageErrorMode.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PropsT) {
    if (nextProps.forceStopEditing) {
      this.onCancel();
      // $FlowFixMe
      nextProps.forceStopEditing();
    }
    if (nextProps.forceStartEditing) {
      this.onEdit();
      // $FlowFixMe
      nextProps.forceStartEditing();
    }
    this.manageErrorMode(nextProps);
  }

  componentWillUnmount() {
    this.props.setActiveSection(null);
  }

  onEdit: *;

  onEdit() {
    this.setState({ status: 'editing' });
    if (this.props.sectionId) {
      this.props.setActiveSection({ id: this.props.sectionId });
    }
  }

  onCancel: *;

  onCancel() {
    this.setState({ status: 'viewing' });
    this.props.setActiveSection(null);
    if (this.props.onReset) {
      this.props.onReset();
    }
  }

  onSave: *;

  onSave() {
    if (this.props.onSave) {
      const saveResult = this.props.onSave() || {};
      // validation has passed if onSave returns a promise
      if (typeof saveResult.then === 'function') {
        this.setState({ status: 'viewing' });
        this.props.setActiveSection(null);
      }
    }
  }

  getEditPencilStatus: *;

  getEditPencilStatus(status: StatusT): $PropertyType<EditPencilPropsT, 'status'> {
    const { isSaving, wasSaved, touched } = this.props;

    switch (status) {
      case 'viewing': {
        if (isSaving) {
          return 'saving';
        }
        if (wasSaved) {
          return 'save-success';
        }
        return 'view';
      }
      case 'editing': {
        return touched ? 'touched' : 'edit';
      }
      default: {
        return 'view';
      }
    }
  }

  manageErrorMode: *;

  manageErrorMode(nextProps: PropsT) {
    if (this.props.errorMessage === undefined && nextProps.errorMessage !== undefined) {
      this.setState({
        status: 'error'
      });
    }

    if (this.props.errorMessage !== undefined && nextProps.errorMessage === undefined) {
      this.setState({
        status: 'viewing'
      });
    }
  }

  renderWrappedChildren(
    children: ChildrenArray<*>,
    status: StatusT,
    isSectionDisabled: boolean,
    showEmptyMessage?: boolean
  ) {
    return React.Children.map(children, child => {
      if (!child || !child.props) {
        return child;
      }

      if (status !== 'viewing' && !showEmptyMessage && child.type === 'p') return null;
      if (status === 'viewing' && child.props.hideWhenViewing) return null;
      if (child.props.children) {
        return React.cloneElement(child, {
          children: this.renderWrappedChildren(
            child.props.children,
            status,
            isSectionDisabled,
            showEmptyMessage
          )
        });
      }
      return React.cloneElement(
        child,
        child.type === Field || child.type === Search
          ? {
              isSectionInEditMode: status === 'editing',
              isSectionDisabled,
              alwaysEdit: this.props.alwaysEdit
            }
          : {}
      );
    });
  }

  render(): Element<'div'> {
    const {
      alwaysEdit,
      title,
      columnTitleRight,
      children,
      sectionId,
      isSectionDisabled,
      className,
      errorMessage,
      t,
      sectionTitleStyle,
      hasValues,
      noValuesMessage,
      showEmptyMessage,
      showButtonsBottom,
      hideEdit
    } = this.props;
    const { status } = this.state;
    const sectionStyle = isSectionDisabled ? styles['section-disabled'] : styles['section-enabled'];
    return (
      <div id={sectionId} className={classnames(sectionStyle, className)}>
        {!showButtonsBottom && (
          <div className={classnames(sectionTitleStyle, styles.title)}>
            {title}
            {!alwaysEdit && !hideEdit && (
              <EditPencil
                id={`${sectionId || ''}-edit-pencil`.replace(/\s+/g, '-')}
                onSave={this.onSave}
                onEdit={this.onEdit}
                onCancel={this.onCancel}
                status={this.getEditPencilStatus(status)}
                disabled={!!isSectionDisabled}
                translate={t}
              />
            )}
          </div>
        )}
        <div className={styles['column-titles']}>
          <p className={styles['column-title-right']}>{columnTitleRight}</p>
        </div>
        {(status === 'error' || errorMessage) && (
          <div id="error-container" className={styles['error-container']} role="status">
            {errorMessage}
          </div>
        )}
        <div>
          {!hasValues && status === 'viewing' && showEmptyMessage ? (
            <p className={styles['no-content']}>
              {noValuesMessage || t('users.sectionHasNoValuesDefaultMessage')}
            </p>
          ) : (
            this.renderWrappedChildren(
              children,
              status,
              isSectionDisabled || false,
              showEmptyMessage
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT, { sectionId }: OwnPropsT): StatePropsT => {
  const activeSection = R.path(['ui', 'forms', 'activeSection', 'id'], state) || null;
  return {
    isSectionDisabled: activeSection && activeSection !== sectionId
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveSection: formsUiAction.createSetActiveFormsSection
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(Section);
