// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CallFlowType from '../callFlowGrid/gridElement/CallFlowType';
import { selectors as callFlowSelect } from '../../../ducks/entities/callFlow';
import type { CallFlowEntityT } from '../../../ducks/entities/callFlow';
import { getNameToShow, getPnNumbers } from '../../../ducks/entities/callFlow/callFlowSelectors';
import GoBackLink from '../../../components/Button/GoBackLink';

import styles from './CallFlowHeader.module.scss';

type PropsT = {|
  backToList: () => void,
  callflow: ?CallFlowEntityT
|};

export const CallFlowHeader = (props: PropsT): Element<'div'> => {
  const { backToList, callflow } = props;

  const { t } = useTranslation();
  const hasLoadError: boolean = useSelector(state =>
    callflow
      ? callFlowSelect.hasLoadErrorSingle(state, callflow.enterpriseId, callflow.type, callflow.id)
      : false
  );

  const callFlowType = callflow ? callflow.type : '';
  const showCallflowTopic = !hasLoadError && callFlowType && callflow;

  let numberToShow: string = '';
  let nameToShow: string = '';
  let typeToShow: string = '';
  if (callflow) {
    numberToShow = `${getPnNumbers(callflow, ' ')} (${callflow.addressNumber})` || '';
    nameToShow = getNameToShow(callflow);
    typeToShow = callflow.type;
  }
  const callflowTopic = (
    <div className={styles['subheader-box-texts']}>
      <div className={styles.small}>{t(`callflow.modify.${typeToShow}`)}</div>
      <div className={styles.big}>{nameToShow}</div>
      <div className={styles.small}>
        <CallFlowType type={typeToShow} textOnly number={numberToShow} />
      </div>
    </div>
  );

  return (
    <div className={styles['subheader-box']} id="header-box">
      <GoBackLink
        id="callflow-page-back-link"
        text={t('callflow.backToListLink')}
        onClick={backToList}
      />
      {showCallflowTopic && callflowTopic}
    </div>
  );
};

export default CallFlowHeader;
