import React from 'react';
import IconContainer from '../IconContainer';

export default function IconShareLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 7h2.5V4.52a2 2 0 0 1 2-2 1.8 1.8 0 0 1 1.37.64l5.63 6a2 2 0 0 1 0 2.74l-5.62 6a1.91 1.91 0 0 1-3.38-1.4V14H10a7 7 0 0 0-7 7 .5.5 0 0 1-1 0v-6a8 8 0 0 1 8-8M3 17.13A8 8 0 0 1 10 13h3a.5.5 0 0 1 .5.5v3a1 1 0 0 0 .27.73.9.9 0 0 0 1.273.014q.035-.035.067-.074l5.62-6a1 1 0 0 0 0-1.38l-5.63-6a.8.8 0 0 0-.64-.33 1 1 0 0 0-1 1v3a.5.5 0 0 1-.5.5h-3a7 7 0 0 0-7 7z"/></svg>)}
    />
  );
}
  