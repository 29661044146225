import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSwitchLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.85 15.85-3 3a.48.48 0 0 1-.678.022l-.022-.022a.48.48 0 0 1-.022-.678l.022-.022L21.29 16H9.5a.5.5 0 0 1 0-1h11.79l-2.14-2.15a.496.496 0 0 1 .7-.7l3 3a.36.36 0 0 1 .11.16.5.5 0 0 1 0 .38.36.36 0 0 1-.11.16M2.71 9H14.5a.5.5 0 0 0 0-1H2.71l2.14-2.15a.496.496 0 0 0-.7-.7l-3 3a.36.36 0 0 0-.11.16.5.5 0 0 0 0 .38.36.36 0 0 0 .11.16l3 3a.48.48 0 0 0 .678.022l.022-.022a.48.48 0 0 0 .022-.678l-.022-.022z"/></svg>)}
    />
  );
}
  