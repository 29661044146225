// @flow

import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import * as R from 'ramda';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../components/BaseModal';
import ActionButton from '../../components/Button/ActionButton';
import { operations as userOps, selectors as userSelect } from '../../ducks/entities/user';
import { createExportUsersCancel } from '../../ducks/entities/user/userActions';
import styles from './ExportModal.module.scss';

type PropsT = {||};

const EXPORT_CHECK_DELAY = 5000;

// eslint-disable-next-line no-unused-vars
export const ExportModal = (props: PropsT): Element<typeof BaseModal> | null => {
  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isExporting = useSelector(state => userSelect.collectionIsExporting(state));
  const exported = useSelector(state => userSelect.collectionExported(state));
  const toBeExported = useSelector(state => userSelect.collectionToBeExported(state));
  const exportUUID = useSelector(state => userSelect.collectionExportUUID(state));
  const cancelRequest = React.useRef<Canceler>();
  const currentUser = useSelector(state => state.currentUser);
  const enterpriseId = R.path(['currentEnterprise', 'id'], currentUser) || '';

  useEffect(() => {
    let id;
    if (isExporting && exportUUID) {
      id = setInterval(async () => {
        try {
          const data = await dispatch(
            userOps.getExportUsersProcess(
              enterpriseId,
              exportUUID,
              new CancelToken(canceler => {
                cancelRequest.current = canceler;
              })
            )
          );
          if (
            data &&
            data.toBeExported !== -1 &&
            data.exported === data.toBeExported &&
            data.done
          ) {
            clearInterval(id);
            await dispatch(
              userOps.exportUsersDone(
                enterpriseId,
                currentUser.currentEnterprise.fullName,
                exportUUID,
                data.userType,
                new CancelToken(canceler => {
                  cancelRequest.current = canceler;
                })
              )
            );
          }
        } catch (e) {
          console.log(e);
        }
      }, EXPORT_CHECK_DELAY);
    }
    return () => clearInterval(id);
  }, [isExporting, exportUUID]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClose = (): void => {
    dispatch(createExportUsersCancel());
  };
  return isExporting ? (
    <BaseModal modalStyles={[styles.modal]} onClose={() => handleOnClose()}>
      <div className={styles['export-container']}>
        <div className={styles.title}>{t('exportUsers.title')}</div>
        <div className={styles['text-content']}>
          {toBeExported > 0
            ? t('exportUsers.text', { exported, toBeExported })
            : t('exportUsers.starting')}
        </div>
        <div className={styles['spinner-container']}>
          <LoadingSpinner />
        </div>
        <div className={styles['bottom-area']}>
          <ActionButton
            id="cancel-export-button"
            className={styles['export-cancel-button']}
            label={t('exportUsers.cancel')}
            onClickAction={() => handleOnClose()}
          />
        </div>
      </div>
    </BaseModal>
  ) : null;
};

export default ExportModal;
