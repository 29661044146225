import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGuideLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.7 4.8-2.5-2.5c-.2-.2-.4-.3-.7-.3H12V.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V2H4c-.6 0-1 .4-1 1v5c0 .6.4 1 1 1h7v1.5H3.5c-.3 0-.5.1-.7.3L.3 13.3c-.4.4-.4 1 0 1.4l2.5 2.5c.2.2.4.3.7.3H11v6c0 .3.2.5.5.5s.5-.2.5-.5v-6h8c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1h-8V9h8.5c.3 0 .5-.1.7-.3l2.5-2.5c.2-.2.3-.4.3-.7s-.1-.5-.3-.7M20 16.5H3.5L1 14l2.5-2.5H20zm.5-8.5H4V3h16.5L23 5.5z"/></svg>)}
    />
  );
}
  