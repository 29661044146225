import React from 'react';
import IconContainer from '../IconContainer';

export default function IconThumbUpLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.5 8.983h-2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h2a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5h-2a1.5 1.5 0 0 1-1.5-1.5v-1.18c-5.911 3-6.611 3-10.851 2.93h-1.92c-2.56-.01-4.231-1.51-5.231-4.59a.14.14 0 0 1 0-.07l-1.9-6.35v-.06a2.52 2.52 0 0 1 .39-2.18 2.56 2.56 0 0 1 2-1h4.911a1.48 1.48 0 0 0 1.18-.58c.285-.369.384-.848.27-1.3l-.85-3.34A2.22 2.22 0 0 1 12 1.923l4.881 6.93c.259.368.671.6 1.12.63h2a1.5 1.5 0 0 1 1.5-1.5h2a.51.51 0 0 1 .5.5.5.5 0 0 1-.501.5m-7.501.44-4.901-6.92a1.23 1.23 0 0 0-1.51-.41 1.22 1.22 0 0 0-.66 1.41l.88 3.35a2.48 2.48 0 0 1-2.41 3.13H2.497a1.53 1.53 0 0 0-1.2.61 1.5 1.5 0 0 0-.23 1.33.07.07 0 0 1 0 .05l1.93 6.36v.06c.83 2.65 2.14 3.83 4.241 3.83h1.93c4.311.05 4.731 0 10.841-3.05v-8.69h-2.01a2.5 2.5 0 0 1-2-1.06"/></svg>)}
    />
  );
}
  