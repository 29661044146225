import React from 'react';
/* eslint-disable */
class Event extends React.PureComponent {
  render() {
    const {
      start,
      end,
      value,
    } = this.props;
    return (
      <div className="event">
        <span>{`${start.format('HH:mm')} - ${end.format('HH:mm')}`}</span>
        <br /><br />
        {value}
      </div>
    );
  }
}

export default Event;
