// @flow

import React, { type Element, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Canceler } from 'axios';
import { CancelToken } from 'axios';
import Input from '@design-system/component-library/src/components/Input';
import { useTranslation } from 'react-i18next';
import CreateButton from '../../../components/Button/CreateButton';
import { goToCreateCalendar } from '../../../navigationOperations';
import { update } from '../../../ducks/entities/calendar/calendarOperations';
import CalendarConverter from '../components/edit/children/calendar/CalendarConverterUtils';
import useCalendars from './useCalendars';
import type { UseCalendarsResponseT } from './useCalendars';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import CalendarContent from './CalendarContent';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

import styles from './Calendars.module.scss';

export type PropsT = {|
  enterpriseId: string
|};

const Calendars = (props: PropsT): Element<'div'> => {
  const { enterpriseId } = props;

  // State
  const [editModes, setEditModes] = useState<{}>({});
  const updateCalendarCanceller = React.useRef<Canceler>();
  const { calendars, isLoadingCalendar }: UseCalendarsResponseT = useCalendars(enterpriseId);
  const [searchText, setSearchText] = useState<string>('');
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const header = (
    <div className={styles['header-container']}>
      <h4 className={styles.title} data-cy="calendars-title">
        {t('calendars.title', {
          numberOfCalendars: calendars.length
        })}
      </h4>
      <div className={styles['header-area']}>
        <div className={styles.description}>{t('calendars.description')}</div>
        <div>
          <CreateButton
            id="create-calendar-button"
            text={t('calendars.createCalendarButton')}
            onClickAction={() => dispatch(goToCreateCalendar(enterpriseId))}
            containerStyle={styles['create-button']}
            showTextAlways
          />
        </div>
      </div>
    </div>
  );

  const toggleEditMode = (calendarId: string) =>
    setEditModes({ [calendarId]: !editModes[calendarId] });

  const handleSearchChange = (search: string) => {
    setEditModes({});
    setSearchText(search);
  };

  const handleCalendarNotification = success => {
    const notification = {
      tag: success ? 'calendar-edit-success' : 'calendar-edit-fail',
      duration: 15000,
      type: success ? 'info' : 'error',
      message: success
        ? t('calendars.editCalendar.successNotification')
        : t('calendars.editCalendar.failedNotification')
    };

    dispatch(notificationActions.createCreateNotificationAction(notification));
  };

  const updateCalendar = async (cal, formData) => {
    const payload = {
      weekSchedule: formData.weekSlots
        ? CalendarConverter.convertToUpdateData(formData.weekSlots)
        : cal.weekSchedule,
      absoluteTimeSlots: formData.absoluteTimeSlots
    };
    const result = await dispatch(
      update(
        enterpriseId,
        cal.id,
        // $FlowFixMe
        payload,
        new CancelToken(canceler => {
          updateCalendarCanceller.current = canceler;
        }),
        createCsrfHeader(currentUser)
      )
    );
    handleCalendarNotification(!!result);
  };

  return (
    <div className={styles.container}>
      {header}
      <Input
        id="search-calendar"
        className={styles['search-field']}
        placeholder={t('calendars.searchPlaceholder')}
        onValueChange={e => handleSearchChange(e.target.value)}
        type="search"
        optional
        i18n_input_optionalText=""
        maxlength={107}
      />
      <div className={styles['content-container']}>
        <CalendarContent
          isLoadingCalendar={isLoadingCalendar}
          calendars={calendars}
          searchText={searchText}
          editModes={editModes}
          enterpriseId={enterpriseId}
          updateCalendar={updateCalendar}
          toggleEditMode={toggleEditMode}
        />
      </div>
    </div>
  );
};

export default Calendars;
