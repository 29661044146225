import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDraftSaveFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.5.5c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6m2.692 7.192-2.25 2.25c-.01.01-.023.013-.034.023a.6.6 0 0 1-.169.112.62.62 0 0 1-.478 0 .6.6 0 0 1-.169-.112c-.01-.009-.024-.013-.034-.023l-2.25-2.25a.626.626 0 0 1 .885-.884l1.183 1.183V3.5a.625.625 0 0 1 1.25 0v4.491l1.183-1.183a.626.626 0 0 1 .885 0 .627.627 0 0 1-.002.884m-2.909 10.985-4.606 4.609a.25.25 0 0 1-.273.054.25.25 0 0 1-.154-.231v-4.484c0-.207.168-.375.375-.375h4.481a.25.25 0 0 1 .177.427m.723-4.427.001 2.25a.5.5 0 0 1-.5.5h-4.882c-.897 0-1.625.728-1.625 1.625V23.5a.5.5 0 0 1-.5.5h-9A1.5 1.5 0 0 1 0 22.5v-21A1.5 1.5 0 0 1 1.5 0h11.848c.258 0 .357.329.142.472A7.24 7.24 0 0 0 10.25 6.5c0 3.998 3.252 7.25 7.25 7.25.25 0 .506.25.506.5"/></svg>)}
    />
  );
}
  