import React, { Component } from 'react';
import classnames from 'classnames';

const convertToKebabCase = string => {
  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();
};

const getGridItemClass = (prop, value) =>
  value !== undefined && value !== null
    ? `ea-fgrid__item--${convertToKebabCase(prop)}-${value}`.trim()
    : '';

class FluidGridItem extends Component<PropsT> {
  static get getDefaultProps() {
    return {
      phone: null,
      phoneLarge: null,
      tablet: null,
      laptop: null,
      desktop: null,
      phonePush: null,
      phoneLargePush: null,
      tabletPush: null,
      laptopPush: null,
      desktopPush: null,
      align: null
    };
  }

  render() {
    const {
      className,
      align,
      phone,
      phoneLarge,
      tablet,
      laptop,
      desktop,
      phonePush,
      phoneLargePush,
      tabletPush,
      laptopPush,
      desktopPush,
      ratio,
      phoneRatio,
      phoneLargeRatio,
      tabletRatio,
      laptopRatio,
      desktopRatio,
      ...otherProps
    } = this.props;

    const breakpointTypes = { phone, phoneLarge, tablet, laptop, desktop };
    const breakpoints = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(breakpointTypes).map(prop => {
      breakpoints.push(getGridItemClass(prop, breakpointTypes[prop]));
    });
    const breakpointClasses = breakpoints.join(' ');

    const offsetTypes = { phonePush, phoneLargePush, tabletPush, laptopPush, desktopPush };
    const offsets = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(offsetTypes).map(prop => {
      offsets.push(getGridItemClass(prop, offsetTypes[prop]));
    });
    const offsetClasses = offsets.join(' ');

    const ratioTypes = {
      ratio,
      phoneRatio,
      phoneLargeRatio,
      tabletRatio,
      laptopRatio,
      desktopRatio
    };
    const ratios = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(ratioTypes).map(prop => {
      ratios.push(getGridItemClass(prop, ratioTypes[prop]));
    });
    const ratioClasses = ratios.join(' ');

    const alignmentClass = align ? `ea-fgrid__item--${align}` : '';
    const classes = classnames([
      'ea-fgrid__item',
      className,
      alignmentClass,
      breakpointClasses,
      offsetClasses,
      ratioClasses
    ]);

    return <div className={classes} {...otherProps} />;
  }
}

export default FluidGridItem;
