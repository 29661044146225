import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWebsiteFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 2h-21A1.5 1.5 0 0 0 0 3.5v17A1.5 1.5 0 0 0 1.5 22h21a1.5 1.5 0 0 0 1.5-1.5v-17A1.5 1.5 0 0 0 22.5 2m-13 2.625c.482 0 .875.393.875.875s-.393.875-.875.875-.875-.393-.875-.875.393-.875.875-.875m-3 0c.482 0 .875.393.875.875s-.393.875-.875.875-.875-.393-.875-.875.393-.875.875-.875m-3 0c.482 0 .875.393.875.875s-.393.875-.875.875-.875-.393-.875-.875.393-.875.875-.875m19 14.875a1 1 0 0 1-1 1h-19a1 1 0 0 1-1-1V8.75a.25.25 0 0 1 .25-.25h20.5a.25.25 0 0 1 .25.25z"/></svg>)}
    />
  );
}
  