// @flow

import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import * as R from 'ramda';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../components/Button/ActionButton';
import { operations as userOps, selectors as userSelect } from '../../ducks/ui/userImport';
import { createAnalyzeImportProcessCancel } from '../../ducks/ui/userImport/userImportUiActions';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';

import styles from './AnalyzeImportProcess.module.scss';

type PropsT = {|
  closeImport: void => void
|};

const IMPORT_CHECK_DELAY = 5000;

// eslint-disable-next-line no-unused-vars
export const AnalyzeImportProcess = (props: PropsT): Element<'div'> | null => {
  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAnalyzing = useSelector(state => userSelect.collectionIsImporting(state));
  const imported = useSelector(state => userSelect.collectionImported(state));
  const toBeImported = useSelector(state => userSelect.collectionToBeImported(state));
  const importUUID = useSelector(state => userSelect.collectionImportUUID(state));
  const cancelRequest = React.useRef<Canceler>();
  const currentUser = useSelector(state => state.currentUser);
  const enterpriseId = R.path(['currentEnterprise', 'id'], currentUser) || '';

  useEffect(() => {
    let id;
    if (isAnalyzing && importUUID) {
      id = setInterval(async () => {
        try {
          const data = await dispatch(
            userOps.getImportUsersProcess(
              enterpriseId,
              importUUID,
              new CancelToken(canceler => {
                cancelRequest.current = canceler;
              })
            )
          );

          if (data && data.imported === data.toBeImported && data.done) {
            clearInterval(id);
            await dispatch(
              userOps.importUsersDone(
                enterpriseId,
                importUUID,
                new CancelToken(canceler => {
                  cancelRequest.current = canceler;
                })
              )
            );
          }
        } catch (e) {
          console.log(e);
        }
      }, IMPORT_CHECK_DELAY);
    }
    return () => clearInterval(id);
  }, [isAnalyzing, importUUID]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClose = (): void => {
    const { closeImport } = props;
    closeImport();
    dispatch(createAnalyzeImportProcessCancel());
  };
  return (
    <div id="user-import-upload-step-loading" className={styles['import-container']}>
      <div className={styles.title}>{t('importUsers.analyzeProcess.title')}</div>
      <CenterHorizontally>
        <LoadingSpinner />
      </CenterHorizontally>
      <div className={styles['text-content']}>
        {toBeImported > 0
          ? t('importUsers.analyzeProcess.text', {
              imported,
              toBeImported
            })
          : t('importUsers.analyzeProcess.starting')}
      </div>
      <div className={styles['bottom-area']}>
        <ActionButton
          id="cancel-import-button"
          className={styles['import-cancel-button']}
          label={t('importUsers.analyzeProcess.cancel')}
          onClickAction={() => handleOnClose()}
        />
      </div>
    </div>
  );
};

export default AnalyzeImportProcess;
