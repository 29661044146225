/* eslint-disable jsx-a11y/label-has-for, flowtype/no-types-missing-file-annotation */
// Flow removed because of bug https://github.com/facebook/flow/issues/4563
import * as R from 'ramda';
import React from 'react';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import classNames from 'classnames';
import type { Element } from 'react';
import type { IdTreeT } from '../../ducks/entities/department/departmentTypes';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import styles from './IdTreeCombobox.module.scss';

export type PropsT = {|
  disabled: boolean,
  label: string,
  hideKey?: ?string,
  hideKeyAndChildren: ?string,
  options: IdTreeT,
  onChange: (*) => *,
  value: { label: string, value: string },
  renderItem: *,
  hasEmptyOption: boolean,
  hasRootOption: boolean,
  rootOptionKey: ?string,
  className?: string
|};

export const IdTreeCombobox = (props: PropsT): Element<'div'> => {
  const {
    label,
    disabled,
    onChange,
    options,
    hideKeyAndChildren,
    hideKey,
    hasEmptyOption,
    hasRootOption
  } = props;

  const selectedValue = props.value.value;

  const createListItem = (id: string | null) => {
    return {
      value: id,
      html: props.renderItem({
        _value: id
      })
    };
  };

  const traverse = (opts: IdTreeT, items: *) => {
    opts.forEach(({ id, children }) => {
      if (hideKeyAndChildren !== id) {
        if (hideKey !== id) {
          items.push(createListItem(id));
        }
        if (children != null) {
          traverse(children, items);
        }
      }
    });
  };

  const onOptionChange = option => {
    onChange(option);
  };

  const createRootItem = () => {
    const item = {
      value: props.rootOptionKey,
      label: '>'
    };

    return {
      ...item,
      html: (
        <div>
          <HomeIcon /> &gt;
        </div>
      )
    };
  };

  const createItems = (): Array<*> => {
    let items = hasEmptyOption ? [createListItem('empty')] : [];
    items = hasRootOption ? R.prepend(createRootItem(), items) : items;
    if (options) {
      traverse(options, items);
    }
    return items;
  };

  const listItems = createItems();
  return (
    <Dropdown
      items={listItems}
      label={label}
      disabled={disabled}
      selectedValue={selectedValue}
      className={classNames(styles['target-options'], props.className)}
      onValueChange={option => {
        onOptionChange({ label: option.dataset.label, value: option.dataset.value });
      }}
    />
  );
};

export default IdTreeCombobox;
