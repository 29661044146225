// @flow strict-local

import React, { type Element, useEffect } from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import Dropdown from '@design-system/component-library/src/components/Dropdown';

import { useTranslation } from 'react-i18next';
import styles from './DropdownField.module.scss';

type PropsT = {|
  field: string,
  label?: string,
  options: { value: string, label: string }[],
  className?: string,
  innerClassName?: string
|};

export const DropdownField = (props: PropsT): Element<'div'> => {
  const { field, label, options, className, innerClassName } = props;
  const { setValue, watch, setError, clearErrors } = useFormContext();
  const { t } = useTranslation();
  const values = watch();
  const value = values[field];

  useEffect(() => {
    // KeyValueCombobox with initial value of empty key is marked as error
    if (value && value.value === '') {
      setError(field);
      if (options && options.length > 0) {
        setValue(
          field,
          { value: options[0].value },
          {
            shouldValidate: true,
            shouldDirty: true
          }
        );
      }
    } else {
      clearErrors(field);
    }
  }, [clearErrors, field, setError, value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classnames(styles.dropdown, className)}>
      {options && options.length > 0 ? (
        <Dropdown
          items={options}
          label={label}
          selectedValue={values[field].value}
          className={innerClassName}
          onValueChange={option => {
            setValue(
              field,
              { value: option.dataset.value },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            );
          }}
        />
      ) : (
        t('')
      )}
    </div>
  );
};

export default DropdownField;
