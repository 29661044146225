import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOfficeFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 22.5H20V2c0-1.1-.9-2-2-2H6C4.9 0 4 .9 4 2v20.5H1.5c-.4 0-.8.3-.8.8s.4.7.8.7h21c.4 0 .8-.3.8-.8s-.4-.7-.8-.7M7.2 3.5c0-.1.1-.2.2-.2h3c.1 0 .2.1.2.2v4c0 .1-.1.2-.2.2h-3c-.1 0-.2-.1-.2-.2zm0 11v-4c0-.1.1-.2.2-.2h3c.1 0 .2.1.2.2v4c0 .1-.1.2-.2.2h-3c0 .1-.2-.1-.2-.2m7 8H9.8v-3.2c0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2zm2.6-8c0 .1-.1.2-.2.2h-3c-.1 0-.2-.1-.2-.2v-4c0-.1.1-.2.2-.2h3c.1 0 .2.1.2.2zm0-7c0 .1-.1.2-.2.2h-3c-.1 0-.2-.1-.2-.2v-4c0-.1.1-.2.2-.2h3c.1 0 .2.1.2.2z"/></svg>)}
    />
  );
}
  