// @flow
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import directoryOperations from '../../ducks/entities/directory/directoryOperations';

export type EnterpriseUsersResponseT = {|
  enterpriseUsers: *,
  isLoadingEnterpriseUsers: boolean
|};

let requestUsersCancelTokenSource: CancelTokenSource;
const useEnterpriseUsers = (enterpriseId: string, searchTerm: string): EnterpriseUsersResponseT => {
  // state
  const [isLoadingEnterpriseUsers, setIsLoadingEnterpriseUsers] = useState<boolean>(true);
  const [enterpriseUsers, setEnterpriseUsers] = useState<*>([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    if (requestUsersCancelTokenSource) {
      requestUsersCancelTokenSource.cancel();
    }
    requestUsersCancelTokenSource = CancelToken.source();
    if (enterpriseId) {
      const params = {
        page: 1,
        size: 99999,
        search: searchTerm,
        type: 'users'
      };
      const response = await dispatch(
        directoryOperations.searchDirectory(
          enterpriseId,
          requestUsersCancelTokenSource.token,
          params
        )
      );
      if (response.results) {
        setEnterpriseUsers(response.results);
      }
      setIsLoadingEnterpriseUsers(false);
    }
  };

  useEffect(() => {
    requestUsersCancelTokenSource = CancelToken.source();
    setIsLoadingEnterpriseUsers(true);
    const delayDebounceFn = setTimeout(async () => {
      fetchData();
    }, 1000);
    return () => {
      if (requestUsersCancelTokenSource) {
        requestUsersCancelTokenSource.cancel();
      }
      clearTimeout(delayDebounceFn);
    };
  }, [enterpriseId, searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    enterpriseUsers,
    isLoadingEnterpriseUsers
  };
};

export default useEnterpriseUsers;
