// @flow

import classNames from 'classnames';
import React, { type Element, useEffect, useRef, useState } from 'react';
import * as queryString from 'querystring';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { operations as configOps, selectors as configSelect } from '../../ducks/config';
import { selectors as directorySelect } from '../../ducks/entities/directory';
import type { UserStateEntityT } from '../../ducks/entities/user/userTypes';
import { createSetStepsAction, createStartTourAction } from '../../ducks/ui/tour/tourActions';
import { Desktop, Laptop, Phone, PhoneLarge, Tablet } from '../../Responsive';
import {
  goToCreateExternalUser,
  goToEnterpriseUsers,
  goToUserDetails
} from '../../navigationOperations';
import { createCloseUsersImportAction } from '../../ducks/ui/header/headerUiActions';
import Notifications from '../../components/Notifications/Notifications';
import UserCarousel from './UserCarousel/UserCarousel';
import UserDetails from './UserDetails/UserDetails';
import ImportUsers from './Import/ImportUsers';
import BaseContainer from '../BaseContainer/BaseContainer';
import useFormAutoRegister from '../callFlows/callFlowGrid/details/useFormAutoRegister';
import UsersForm from './UsersForm';
import type { CategoryT } from './useUsersSearch';
import { MAX_FAVOURITES_RESULTS } from '../../constants';
import { selectors as efaxSelect } from '../../ducks/entities/efax';
import TopNavigation from '../navigation/TopNavigation';
import { deserializeColumnsToShowConfigValue } from '../../userConfigHelpers';
import { createUpdateUserColumnsToShow } from '../../ducks/ui/user/userUiActions';
import { USER_TABLE_SETTINGS_CONFIG } from './ColumnSelector/ColumnSelector';
import { pushAnalyticsEvent } from '../../utils/analyticsHandler';
import {
  OPEN_FAVOURITE_USER_FROM_CAROUSEL,
  OPEN_RECENT_USER_FROM_CAROUSEL
} from '../../matomo_constants';

import styles from './Users.module.scss';

type PropsT = {|
  match: {
    params: {
      id: string,
      userId: string
    }
  }
|};

const FADE_DURATION = 285;

export type FormT = {
  searchField: string,
  categoryField: { value: CategoryT, label: string }
};

export const Users = (props: PropsT): Element<typeof BaseContainer> => {
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const { id: enterpriseId, userId } = props.match.params;
  const recentsFromConfig = useSelector(state =>
    configSelect.selectRecentConfigsAsUsers(state, enterpriseId)
  );
  const matchingRecentUsersFromStore = useSelector(state =>
    directorySelect.byIds(
      state,
      recentsFromConfig.map(({ personId }) => `IUser-${personId}`)
    )
  );

  const decorateWithAvailability = (usersFromConfig, usersFromStore) =>
    usersFromConfig.map(recentUser => {
      const match = R.find(R.propEq('id', `IUser-${recentUser.personId}`))(usersFromStore);
      return match && match.alias === 'IUser'
        ? {
            ...recentUser,
            telephonicState: match.publicInfo.telephonicState,
            presenceState: match.publicInfo.presenceState
          }
        : recentUser;
    });

  const recentUsers = decorateWithAvailability(recentsFromConfig, matchingRecentUsersFromStore);

  const [activeCarouselTab, setActiveCarouselTab] = useState(
    recentUsers && recentUsers.length > 0 ? 'recent' : 'favorite'
  );
  const [selectedUserId, setSelectedUserId] = useState();
  const [displayError, setDisplayError] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);

  const [showFixedTitle, setShowFixedTitle] = useState(false);
  const [importUsersActive, setImportUsersActive] = useState(false);
  const [showCreateUser] = useState(false);
  const userDetailsRef = useRef(null);

  const directory = useSelector(state => state.entities.directory);
  const efax = useSelector(state => state.entities.efax);
  const userConfig = useSelector(state => state.config.userConfig);
  const selectedCategory = useSelector(state => state.ui.user.selectedCategory);
  const searchTerm = useSelector(state => state.ui.user.searchTerm);

  const initialFormValues: FormT = {
    searchField: searchTerm,
    categoryField: {
      value: selectedCategory,
      label: t(`generic.searchCategoryStatuses.${selectedCategory}`)
    }
  };
  const methods = useFormAutoRegister(R.keys(initialFormValues), {
    defaultValues: initialFormValues
  });
  const users = useSelector(state => directorySelect.byIds(state, directory.allIds));
  const efaxes = useSelector(state => efaxSelect.byIds(state, efax.allIds));

  const displayUserDetails = (id: string) => {
    if (!selectedUserId) {
      setShowUserDetails(true);
      setSelectedUserId(id);
      setDisplayError(displayError);
    } else {
      setDisplayError(false);

      setTimeout(() => {
        setShowUserDetails(true);
        setSelectedUserId(id);
      }, FADE_DURATION);
    }
  };

  const displayCreateUser = (userType: string) => {
    if (userType === 'externalUser') {
      dispatch(goToCreateExternalUser(enterpriseId));
    }
  };

  useEffect(() => {
    userConfig
      .filter(({ key }) => key === USER_TABLE_SETTINGS_CONFIG)
      .forEach(({ value }) => {
        const updatedColumnsToShow = deserializeColumnsToShowConfigValue(value);
        dispatch(createUpdateUserColumnsToShow(updatedColumnsToShow));
      });
  }, [userConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(configOps.getUserConfig());
    dispatch(createCloseUsersImportAction());
    if (enterpriseId) {
      const params = queryString.parse(window.location.search.slice(1));
      if (userId) {
        displayUserDetails(userId);
      } else if (params.action === 'create') {
        displayCreateUser(params.type);
      } else {
        setSelectedUserId(null);
        setShowUserDetails(false);
      }
    }
  }, [enterpriseId, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentSectionActive = useSelector(state => state.ui.forms.activeSection);

  const favoritesFromConfig = useSelector(state =>
    configSelect.selectFavouriteConfigsAsUsers(state, enterpriseId)
  );
  const matchingFavoriteUsersFromStore = useSelector(state =>
    directorySelect.byIds(
      state,
      favoritesFromConfig.map(({ personId }) => `IUser-${personId}`)
    )
  );

  const favoriteUsers = decorateWithAvailability(
    favoritesFromConfig,
    matchingFavoriteUsersFromStore
  );

  const showTour = (): void => {
    dispatch(
      createSetStepsAction([
        {
          disableBeacon: true,
          title: t('tours.usersTourSteps.step1.title'),
          content: t('tours.usersTourSteps.step1.text'),
          target: '#searchContainer',
          placement: 'bottom'
        },
        {
          title: t('tours.usersTourSteps.step2.title'),
          content: t('tours.usersTourSteps.step2.text'),
          target: '#usersTable',
          placement: 'auto'
        },
        {
          title: t('tours.usersTourSteps.step3.title'),
          content: t('tours.usersTourSteps.step3.text'),
          target: '#users-column-selector-button',
          placement: 'bottom'
        },
        {
          title: t('tours.usersTourSteps.step4.title'),
          content: t('tours.usersTourSteps.step4.text'),
          target: '#external-contact-link-button',
          placement: 'bottom'
        }
      ])
    );
    dispatch(createStartTourAction());
  };

  const handleUserDetailsClosed = () => {
    setShowUserDetails(false);
    setSelectedUserId(undefined);
    if (enterpriseId) {
      dispatch(goToEnterpriseUsers(enterpriseId));
    }
  };

  const endImportUsers = () => {
    dispatch(createCloseUsersImportAction());
    setImportUsersActive(false);
  };

  const handleScrollDetailsContainer = () => {
    const refTop = userDetailsRef.current ? userDetailsRef.current.getBoundingClientRect().top : 0;
    const titlePosition = -200;
    if (!showFixedTitle && refTop <= titlePosition) {
      setShowFixedTitle(true);
    }
    if (showFixedTitle && refTop > titlePosition) {
      setShowFixedTitle(false);
    }
  };

  const renderReloadingCarousel = (
    favouriteUsers: UserStateEntityT[],
    isFavourites: boolean
  ): Element<'div'> => {
    const matomoAction = () =>
      pushAnalyticsEvent(
        isFavourites ? OPEN_FAVOURITE_USER_FROM_CAROUSEL : OPEN_RECENT_USER_FROM_CAROUSEL,
        enterpriseId
      );
    // Remounts carousel if screen type changes
    return (
      <div>
        <Phone>
          {match =>
            match ? (
              <UserCarousel
                users={favouriteUsers}
                onSelect={(usrId, userType) => {
                  matomoAction();
                  dispatch(goToUserDetails(enterpriseId, usrId, userType));
                }}
              />
            ) : null
          }
        </Phone>
        <PhoneLarge>
          {match =>
            match ? (
              <UserCarousel
                users={favouriteUsers}
                onSelect={(usrId, userType) => {
                  matomoAction();
                  dispatch(goToUserDetails(enterpriseId, usrId, userType));
                }}
              />
            ) : null
          }
        </PhoneLarge>
        <Tablet>
          {match =>
            match ? (
              <UserCarousel
                users={favouriteUsers}
                onSelect={(usrId, userType) => {
                  matomoAction();
                  dispatch(goToUserDetails(enterpriseId, usrId, userType));
                }}
              />
            ) : null
          }
        </Tablet>
        <Laptop>
          {match =>
            match ? (
              <UserCarousel
                users={favouriteUsers}
                onSelect={(usrId, userType) => {
                  matomoAction();
                  dispatch(goToUserDetails(enterpriseId, usrId, userType));
                }}
              />
            ) : null
          }
        </Laptop>
        <Desktop>
          {match =>
            match ? (
              <UserCarousel
                users={favouriteUsers}
                onSelect={(usrId, userType) => {
                  matomoAction();
                  dispatch(goToUserDetails(enterpriseId, usrId, userType));
                }}
              />
            ) : null
          }
        </Desktop>
      </div>
    );
  };

  const renderFavouriteCarousel = () => {
    return favoriteUsers.length > 0 ? (
      renderReloadingCarousel(favoriteUsers.slice(0, MAX_FAVOURITES_RESULTS), true)
    ) : (
      <div className={styles['no-favorites']}>
        <h4>{t('users.noFavoritesTitle')}</h4>
        <div>{t('users.noFavorites')}</div>
      </div>
    );
  };
  const recentSliderStyle = activeCarouselTab === 'recent' ? {} : { display: 'none' };
  const favouriteSliderStyle = activeCarouselTab === 'recent' ? { display: 'none' } : {};

  return (
    <BaseContainer header={<TopNavigation onClickTutorial={showTour} viewName="users" />}>
      <div className={classNames(styles.container)}>
        <Notifications
          tags={[
            'external-user-deleted',
            'internal-user-password-reset',
            'external-user-created',
            'update-user-success',
            'update-user-failure'
          ]}
          render={({ message, keyId, type }) => (
            <div
              className={classNames('ea-notification', styles.notification, styles[type])}
              key={keyId}
              role="status"
            >
              {message}
            </div>
          )}
        />
        <div className={styles['users-container']}>
          <div
            id="user-details-container"
            className={classNames(
              styles['user-details-container'],
              {
                [`${styles['user-details-container--open']}`]:
                  currentSectionActive || showCreateUser
              },
              {
                [`${styles['user-details-container--details-open']}`]: showUserDetails
              }
            )}
            onScroll={handleScrollDetailsContainer}
          >
            <div
              className={classNames(styles['user-details-poser'], {
                [`${styles['user-details-poser-hidden']}`]: !showUserDetails
              })}
            >
              <span ref={userDetailsRef} />
              {showUserDetails && selectedUserId && (
                <UserDetails
                  onClose={handleUserDetailsClosed}
                  userId={selectedUserId}
                  showFixedTitle={showFixedTitle}
                  displayError={displayError}
                  enterpriseId={enterpriseId}
                />
              )}
            </div>
          </div>

          <div className={styles['card-list']}>
            <div className={styles.selector}>
              {recentUsers && recentUsers.length > 0 && (
                <button
                  className={classNames(
                    styles.selection,
                    activeCarouselTab === 'recent' ? styles['active-selection'] : ''
                  )}
                  id="carousel-recent-user-tab"
                  onClick={() => setActiveCarouselTab('recent')}
                >
                  {t('users.tabs.recent')}
                </button>
              )}
              <button
                className={classNames(
                  styles.selection,
                  activeCarouselTab === 'favorite' ? styles['active-selection'] : ''
                )}
                id="carousel-favorite-user-tab"
                onClick={() => setActiveCarouselTab('favorite')}
              >
                {t('users.tabs.favorites')}
              </button>
            </div>
            <div className={styles['cards-container']}>
              <div
                id="recent_carousel"
                style={recentSliderStyle}
                className={styles['carousel-container']}
              >
                {renderReloadingCarousel(recentUsers, false)}
              </div>
              <div
                id="favorite_carousel"
                style={favouriteSliderStyle}
                className={styles['carousel-container']}
              >
                {renderFavouriteCarousel()}
              </div>
            </div>
          </div>
          <div className={classNames(importUsersActive && styles['user-active'], styles.content)}>
            {importUsersActive ? (
              <ImportUsers endImportUsers={endImportUsers} />
            ) : (
              <FormProvider {...methods}>
                <UsersForm
                  enterpriseId={enterpriseId}
                  users={users}
                  efaxes={efaxes}
                  setImportUsersActive={setImportUsersActive}
                  setDisplayError={setDisplayError}
                />
              </FormProvider>
            )}
          </div>
        </div>
      </div>
    </BaseContainer>
  );
};

export default Users;
