import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWarningFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.814 20.603 13.425 1.814A1.55 1.55 0 0 0 12.063 1h-.003c-.57 0-1.079.302-1.361.809L.228 20.599a1.62 1.62 0 0 0 .019 1.624c.285.487.787.777 1.342.777h20.862c.554 0 1.056-.29 1.342-.775a1.62 1.62 0 0 0 .021-1.622M11.396 8.75a.625.625 0 0 1 1.25 0v6.5a.625.625 0 0 1-1.25 0zM12 18.875a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75"/></svg>)}
    />
  );
}
  