import { useId, useEffect, useRef, useState } from "react";

export default {};

/**
 * @param {string} prefix - Prefix for the generated ID
 * @param {null|string} [id] - ID provided in component props if available
 * @returns {string} Either the given id, or a memoized nextId
 */
export const useIdWithFallback = (prefix, id) => {
  const generatedId = useId();
  return id ?? `${prefix}-${generatedId.replace(/\:/g, '')}`;
}

/**
 * @param {string} query See https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * @return {boolean|null}
 */
export const useMediaQuery = (query) => {
  // It's important that we return null (or false) on the first run,
  // so that this returns the same result on SSR and browser.
  const [matches, setMatches] = useState(null);
  useEffect(() => {
    // Make sure we have window.matchMedia: this might be run on SSR or Jest
    // or something else that might be missing window or matchMedia.
    // If we can't generate the matcher, we never update the value from null.
    const matchMedia = window?.matchMedia?.(query);
    if (matchMedia) {
      const handleChange = () => setMatches(matchMedia.matches);
      handleChange();
      // TODO remove addListener & removeListener once Safari <= 13 usage is low enough.
      // At the time of writing, in yrityksille.elisa.fi it's about 0.43%.
      if (matchMedia.addEventListener) {
        matchMedia.addEventListener("change", handleChange);
      } else {
        matchMedia.addListener(handleChange);
      }
      return () => {
        if (matchMedia.removeEventListener) {
          matchMedia.removeEventListener("change", handleChange);
        } else {
          matchMedia.removeListener(handleChange);
        }
      };
    }
    return () => {
      /* NOOP, there's nothing to clean up. */
    };
  }, [query]);

  return matches;
};

/**
 * Mostly used to check if the value has changed.
 * @param value New value
 * @returns Old value
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
