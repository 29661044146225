// @flow

import axios, { type AxiosPromise, type CancelToken } from 'axios';
import {
  createRetrieveHistoryCollectionRequest,
  createRetrieveHistoryCollectionSuccess,
  createRetrieveHistoryCollectionFailure,
  createRetrieveHistoryCollectionCancel,
  createEmptyHistoryCollectionRequest
} from './historyActionActions';
import { type ThunkActionT } from '../../../commonTypes';
import type {
  RetrieveHistoryCollectionActionT,
  HistoryActionsPayloadT
} from './historyActionTypes';

type RetrieveCollectionFnT = (
  string,
  CancelToken,
  params: { page: number, size: number, sort: string },
  selectedActionFilter?: string,
  emptyList?: boolean
) => ThunkActionT<RetrieveHistoryCollectionActionT>;

// eslint-disable-next-line arrow-body-style
export const retrieveCollection: RetrieveCollectionFnT = (
  enterpriseId,
  cancelToken,
  params = {},
  selectedActionFilter = '',
  emptyList = false
) => async dispatch => {
  if (emptyList) {
    dispatch(createEmptyHistoryCollectionRequest());
  }
  dispatch(createRetrieveHistoryCollectionRequest(enterpriseId, params.page));
  try {
    const response: AxiosPromise<HistoryActionsPayloadT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/historyactions?action=${selectedActionFilter}`,
      cancelToken,
      params
    });
    const { data } = await response;
    dispatch(createRetrieveHistoryCollectionSuccess(enterpriseId, data));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveHistoryCollectionCancel());
    } else {
      dispatch(createRetrieveHistoryCollectionFailure(enterpriseId, error));
    }
  }
};

const callFlowOperations = {
  retrieveCollection
};

export default callFlowOperations;
