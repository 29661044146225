// @flow
import React, { type ElementRef, Component, type Element } from 'react';
import classnames from 'classnames';
import FocusLock from 'react-focus-lock';
import Dismiss from './Button/Dismiss';
import styles from './BaseModal.module.scss';

export type OwnPropsT = {
  children: Element<*>,
  onClose: () => *,
  modalStyles: string[],
  onClickOutsideClose?: boolean
};

export type PropsT = OwnPropsT;

export class BaseModal extends Component<PropsT> {
  static defaultProps = {
    onClickOutsideClose: false
  };

  static disableBackgroundScrolling() {
    if (document.body) {
      document.body.style.overflow = 'hidden';
    }
  }

  static restoreBackgroundScrolling() {
    if (document.body) {
      document.body.style.overflow = 'auto';
    }
  }

  constructor(props: PropsT) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    BaseModal.disableBackgroundScrolling();
  }

  componentWillUnmount() {
    BaseModal.restoreBackgroundScrolling();
  }

  modalRef: ElementRef<*>;

  render() {
    const { children, onClose, modalStyles, onClickOutsideClose } = this.props;
    return (
      <FocusLock>
        <div
          className={styles['modal-overlay']}
          id="modal"
          onClick={onClickOutsideClose ? onClose : undefined}
          onKeyPress={onClickOutsideClose ? onClose : undefined}
          role="button"
          tabIndex={0}
        >
          <div
            onClick={e => e.stopPropagation()}
            onKeyPress={e => e.stopPropagation()}
            role="button"
            tabIndex={0}
            className={classnames(styles.modal, {
              ...modalStyles.reduce(
                (classNames, className) => ({ ...classNames, [className]: true }),
                {}
              )
            })}
            ref={this.modalRef}
          >
            <Dismiss id="dismiss-modal" onClose={onClose} />
            {children}
          </div>
        </div>
      </FocusLock>
    );
  }
}

export default BaseModal;
