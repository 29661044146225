import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDroneLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M1 5c0-2.2 1.8-4 4-4s4 1.8 4 4c0 .3-.2.5-.5.5S8 5.3 8 5c0-1.7-1.3-3-3-3S2 3.3 2 5s1.3 3 3 3c.3 0 .5.2.5.5S5.3 9 5 9C2.8 9 1 7.2 1 5m14.5.5c.3 0 .5-.2.5-.5 0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3c-.3 0-.5.2-.5.5s.2.5.5.5c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4c0 .3.2.5.5.5m-7 13c-.3 0-.5.2-.5.5 0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3c.3 0 .5-.2.5-.5S5.3 15 5 15c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4c0-.3-.2-.5-.5-.5M19 15c-.3 0-.5.2-.5.5s.2.5.5.5c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3c0-.3-.2-.5-.5-.5s-.5.2-.5.5c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4m-3.8.9c-.5.4-1.1.6-1.7.6h-3c-.6 0-1.2-.2-1.7-.6l-3.4 3.4c-.2.2-.5.2-.7 0s-.2-.5 0-.7l3.4-3.4c-.4-.5-.6-1.1-.6-1.7v-3c0-.6.2-1.2.6-1.7L4.7 5.4c-.2-.2-.2-.5 0-.7s.5-.2.7 0l3.4 3.4c.5-.4 1.1-.6 1.7-.6h3c.6 0 1.2.2 1.7.6l3.4-3.4c.2-.2.5-.2.7 0s.2.5 0 .7l-3.4 3.4c.4.5.6 1.1.6 1.7v3c0 .6-.2 1.2-.6 1.7l3.4 3.4c.2.2.2.5 0 .7s-.5.2-.7 0zm.3-2.4v-3c0-.5-.2-1-.5-1.4l-.1-.1c-.4-.3-.8-.5-1.4-.5h-3c-1.1 0-2 .9-2 2v3c0 1.1.9 2 2 2h3c1.2 0 2-.9 2-2"/></svg>)}
    />
  );
}
  