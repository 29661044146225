import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMobilePaymentFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.5 1.5c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6m.5 5.375a.625.625 0 0 1 0 1.25h-1.883a2.36 2.36 0 0 0 2.274 1.75c.453 0 .893-.128 1.272-.372a.624.624 0 1 1 .674 1.053 3.6 3.6 0 0 1-1.946.569 3.614 3.614 0 0 1-3.553-3h-.358a.625.625 0 0 1 0-1.25h.359a3.614 3.614 0 0 1 3.551-2.979c.692 0 1.365.196 1.946.568a.623.623 0 0 1 .189.863.623.623 0 0 1-.863.189 2.354 2.354 0 0 0-3.539 1.358zM15.25 17.75H1.75a.25.25 0 0 0-.25.25v4.5A1.5 1.5 0 0 0 3 24h11a1.5 1.5 0 0 0 1.5-1.5V18a.25.25 0 0 0-.25-.25m-5.94 3.09c-.05.1-.109.2-.189.28a.9.9 0 0 1-.62.25c-.23 0-.45-.09-.62-.25-.16-.16-.26-.39-.26-.62s.1-.45.26-.62a.92.92 0 0 1 1.24 0c.08.08.14.18.189.29.04.1.061.22.061.33a1 1 0 0 1-.061.34m6.19-5.744V16a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V1.5C1.5.673 2.173 0 3 0h10.179a.501.501 0 0 1 .216.952C10.876 2.153 9.25 4.723 9.25 7.5a7.26 7.26 0 0 0 5.847 7.105.5.5 0 0 1 .403.491"/></svg>)}
    />
  );
}
  