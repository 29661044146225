import React from 'react';
import IconContainer from '../IconContainer';

export default function IconShopRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 6.511a.74.74 0 0 0-.09-.329l-2.609-4.436a1.72 1.72 0 0 0-1.549-.997H4.13a1.71 1.71 0 0 0-1.539.938L.092 6.162a.76.76 0 0 0-.09.339v.997a3.6 3.6 0 0 0 3.476 3.725 3.62 3.62 0 0 0 2.151-.615c1.691 1.935 4.374 2.191 6.377.578a4.86 4.86 0 0 0 2.998 1.057q.234.015.47 0c1.213-.123 2.095-.7 2.899-1.615a3.614 3.614 0 0 0 5.007-.983 3.6 3.6 0 0 0 .61-2.117v-.997zM20 2.39v.05l1.94 3.31h-3.13l-1-3.5h2a.24.24 0 0 1 .16.14zm-7.23-.14h3.45l1 3.5h-4.48zm-1.5 3.5H6.74l1.05-3.5h3.45zM3.9 2.39a.24.24 0 0 1 .22-.14h2.11l-1 3.5H2.01zM2.81 9.56A2.13 2.13 0 0 1 1.48 7.5v-.25h3.5v1.9a2.1 2.1 0 0 1-2.17.41m3.67-.31v-2h4.76V9.8c-1.273 1.257-3.085 1.245-4.342-.029a3.2 3.2 0 0 1-.418-.521m8.83 1.47a3.25 3.25 0 0 1-2.57-.92V7.25h4.76v2c-.542.827-1.207 1.367-2.19 1.47M22.5 7.5A2.09 2.09 0 0 1 19 9.14V7.25h3.5zm-.26 5.75v8.25a1.75 1.75 0 0 1-1.75 1.75h-1.5c-.69 0-1.25-.56-1.25-1.25v-5a1.75 1.75 0 0 0-3.5 0v5c0 .69-.56 1.25-1.25 1.25h-9.5a1.76 1.76 0 0 1-1.75-1.75v-8.25a.75.75 0 0 1 1.5 0v8.25c0 .138.112.25.25.25h9.25V17a3.25 3.25 0 1 1 6.5 0v4.75h1.25a.25.25 0 0 0 .25-.25v-8.25a.75.75 0 0 1 1.5 0m-11.75 6a.76.76 0 0 0 .75-.75v-4a.76.76 0 0 0-.75-.75h-5a.76.76 0 0 0-.75.75v4a.76.76 0 0 0 .75.75zm-4.25-4h3.5v2.5h-3.5z"/></svg>)}
    />
  );
}
  