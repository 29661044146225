import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSendLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.937.884a1.28 1.28 0 0 0-1.618-.821L1.398 6.884a2.048 2.048 0 0 0 .114 3.92l9.417 2.258 2.273 9.439A2.04 2.04 0 0 0 15.177 24c.314 0 .627-.073.916-.219a2.03 2.03 0 0 0 1.026-1.186l6.818-20.923a1.26 1.26 0 0 0 0-.788M1.04 8.542c.094-.333.347-.599.672-.708l20.39-6.648-10.913 10.91-9.433-2.262a1.05 1.05 0 0 1-.716-1.292m15.131 13.742a1.05 1.05 0 0 1-1.327.661 1.04 1.04 0 0 1-.673-.694l-2.275-9.448L22.819 1.884z"/></svg>)}
    />
  );
}
  