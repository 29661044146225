import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHelpFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m.1 17.1c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1m-.1-3.2c-.3 0-.6-.3-.6-.6s.3-.6.6-.6c1.3 0 2.4-1.1 2.4-2.4S13.3 7.9 12 7.9 9.6 9 9.6 10.3c0 .3-.3.6-.6.6s-.6-.3-.6-.6c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6"/></svg>)}
    />
  );
}
  