// @flow strict-local

import type {
  ForwardingUiStateT,
  ForwardingUiActionT,
  UpdateForwardingPopUpT
} from './callForwardingsUiTypes';

const initialState: ForwardingUiStateT = {
  activeTab: 'users',
  popup: {},
  searchIds: [],
  totalSearchResults: 0
};

const tabReducer = (state: ForwardingUiStateT, action: ForwardingUiActionT): ForwardingUiStateT => {
  switch (action.type) {
    case 'ring/ui/callForwardings/UPDATE_ACTIVE_TAB': {
      const {
        payload: { activeTab }
      } = action;
      return {
        ...state,
        activeTab
      };
    }
    default:
      return state;
  }
};

const popUpReducer = (
  state: ForwardingUiStateT,
  action: UpdateForwardingPopUpT
): ForwardingUiStateT => {
  switch (action.type) {
    case 'ring/ui/callForwardings/UPDATE_POP_UP': {
      const { payload } = action;
      return {
        ...state,
        popup: payload
      };
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: ForwardingUiStateT = initialState,
  action: ForwardingUiActionT
): ForwardingUiStateT => {
  switch (action.type) {
    case 'ring/ui/callForwardings/UPDATE_ACTIVE_TAB': {
      return tabReducer(state, action);
    }
    case 'ring/ui/callForwardings/UPDATE_POP_UP': {
      return popUpReducer(state, action);
    }
    default:
      return state;
  }
};

const forwardingUiReducer = {
  initialState,
  rootReducer,
  tabReducer,
  popUpReducer
};

export default forwardingUiReducer;
