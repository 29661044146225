import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSendRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.925 1.044a1.5 1.5 0 0 0-.353-.576c-.008-.008-.01-.019-.018-.027-.011-.011-.025-.014-.036-.024a1.51 1.51 0 0 0-1.504-.342L1.546 6.748A2.26 2.26 0 0 0 .085 8.281a2.267 2.267 0 0 0 1.565 2.795l.029.008 9.057 2.172 2.189 9.089A2.26 2.26 0 0 0 15.104 24c.949 0 1.836-.602 2.153-1.553l6.671-20.471a1.5 1.5 0 0 0-.003-.932M1.529 8.687a.76.76 0 0 1 .488-.515l18.88-6.135-9.772 9.77-9.079-2.178a.766.766 0 0 1-.517-.942m14.303 13.29a.764.764 0 0 1-.968.483.75.75 0 0 1-.487-.493l-2.192-9.101 9.812-9.81z"/></svg>)}
    />
  );
}
  