// @flow

import axios, { type CancelToken, type AxiosPromise } from 'axios';
import * as Sentry from '@sentry/browser';
import {
  createRetrieveLocationCollectionRequest,
  createRetrieveLocationCollectionSuccess,
  createRetrieveLocationCollectionFailure,
  createDeleteLocationRequest,
  createDeleteLocationSuccess,
  createDeleteLocationFailure,
  createUpdateLocationRequest,
  createUpdateLocationSuccess,
  createUpdateLocationFailure,
  createRetrieveLocationCollectionCancel,
  createDeleteLocationCancel,
  createUpdateLocationCancel
} from './locationActions';
import { type ThunkActionT } from '../../../commonTypes';
import type {
  RetrieveLocationCollectionActionT,
  LocationsPayloadT,
  LocationDeleteActionT,
  LocationEntityT,
  LocationPostActionT,
  UpdateLocationPostDataT,
  LocationPayloadEntityT
} from './locationTypes';

type RetrieveCollectionFnT = (
  string,
  CancelToken
) => ThunkActionT<RetrieveLocationCollectionActionT>;

// eslint-disable-next-line arrow-body-style
export const retrieveCollection: RetrieveCollectionFnT = (id, cancelToken) => async dispatch => {
  dispatch(createRetrieveLocationCollectionRequest(id));
  try {
    const response: AxiosPromise<LocationsPayloadT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${id}/locations`,
      cancelToken
    });
    const { data } = await response;
    dispatch(createRetrieveLocationCollectionSuccess(id, data));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveLocationCollectionCancel());
    } else {
      dispatch(createRetrieveLocationCollectionFailure(id, error));
    }
  }
};

export type DeleteLocationFnT = (
  string,
  string,
  headers: {}
) => ThunkActionT<LocationDeleteActionT>;
// eslint-disable-next-line arrow-body-style
export const deleteLocation: DeleteLocationFnT = (
  enterpriseId,
  locationId,
  headers
) => async dispatch => {
  dispatch(createDeleteLocationRequest());
  try {
    const response: AxiosPromise<*> = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/locations`,
      data: {
        locationId
      },
      headers
    });
    await response;
    dispatch(createDeleteLocationSuccess(enterpriseId, locationId));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createDeleteLocationCancel());
    } else if (error.config) {
      dispatch(createDeleteLocationFailure(enterpriseId, locationId, error));
    } else {
      Sentry.captureException(error);
    }
  }
};

export type UpdateLocationFnT = (
  string,
  LocationEntityT,
  headers: {}
) => ThunkActionT<LocationPostActionT>;
// eslint-disable-next-line arrow-body-style
export const updateLocation: UpdateLocationFnT = (
  enterpriseId,
  location,
  headers
) => async dispatch => {
  dispatch(createUpdateLocationRequest());
  try {
    const response: AxiosPromise<UpdateLocationPostDataT, LocationPayloadEntityT> = axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/locations`,
      data: location,
      headers
    });
    const { data } = await response;
    dispatch(createUpdateLocationSuccess(enterpriseId, location.id, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateLocationCancel());
    } else if (error.config) {
      dispatch(createUpdateLocationFailure(location.name, error));
    } else {
      Sentry.captureException(error);
    }
  }
  return undefined;
};

const locationOperations = {
  retrieveCollection,
  deleteLocation,
  updateLocation
};

export default locationOperations;
