// @flow
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import CallflowDetails from '../../../../components/view/CallflowDetails';
import type {
  StartEntityT,
  WelcomeAttendantEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import CallflowDetailsColumn from '../../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../../components/view/children/CallflowItem';
import type { PropsT } from '../message/MessageDetails';
import { ReactComponent as StartIcon } from '../../../../../../assets/callflow/details/start.svg';

export const StartDetails = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  // redux
  const welcomeAttendant: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const startNode: ?StartEntityT = R.path(['commands', commandId], welcomeAttendant);
  if (!startNode) {
    return null;
  }

  return (
    <CallflowDetails
      enterpriseId={welcomeAttendant.enterpriseId}
      callflowId={welcomeAttendant.id}
      elementType="WELCOME_ATTENDANT"
      nodeId={startNode.name}
      nodeType={startNode.type}
      icon={<StartIcon />}
      title={t('callflows.viewWelcomeAttendantStart.title')}
      active
    >
      <CallflowDetailsColumn id="wa-start-column-1">
        <CallflowItem
          id="nextState"
          title={t('callflows.viewWelcomeAttendantStart.nextState')}
          value={
            startNode.nextState && startNode.nextState.state
              ? startNode.nextState.state
              : t('callflows.valueNotSet')
          }
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default StartDetails;
