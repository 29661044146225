// @flow

import * as Sentry from '@sentry/browser';
import axios, { type CancelToken, type AxiosPromise } from 'axios';

import {
  createRetrieveDepartmentCollectionRequest,
  createRetrieveDepartmentCollectionSuccess,
  createRetrieveDepartmentCollectionFailure,
  createRetrieveDepartmentCollectionCancel,
  createUpdateDepartmentRequest,
  createUpdateDepartmentSuccess,
  createUpdateDepartmentFailure,
  createCreateDepartmentRequest,
  createCreateDepartmentSuccess,
  createCreateDepartmentFailure,
  createDeleteDepartmentRequest,
  createDeleteDepartmentFailure,
  createDeleteDepartmentSuccess,
  createUpdateDepartmentCancel,
  createCreateDepartmentCancel,
  createDeleteDepartmentCancel,
  createRetrieveUserCountForDepartmentRequest,
  createRetrieveUserCountForDepartmentSuccess,
  createRetrieveUserCountForDepartmentCancel,
  createRetrieveUserCountForDepartmentFailure
} from './departmentActions';
import type { ThunkActionT } from '../../../commonTypes';
import type {
  CreateDepartmentPostDataT,
  DepartmentEntityT,
  DepartmentPatchActionT,
  DepartmentPayloadEntityT,
  DepartmentDeleteActionT,
  RetrieveDepartmentCollectionActionT,
  RetrieveUserCountForDepartmentActionT,
  DepartmentsPayloadT,
  DepartmentPostActionT
} from './departmentTypes';

type RetrieveCollectionFnT = (
  string,
  CancelToken,
  params?: {}
) => ThunkActionT<RetrieveDepartmentCollectionActionT>;

export const retrieveCollection: RetrieveCollectionFnT = (
  enterpriseId,
  cancelToken,
  params = {}
) => async dispatch => {
  dispatch(createRetrieveDepartmentCollectionRequest(enterpriseId, params));
  try {
    const response: AxiosPromise<DepartmentsPayloadT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/departments`,
      cancelToken,
      params
    });
    const { data } = await response;
    dispatch(createRetrieveDepartmentCollectionSuccess(enterpriseId, data, params));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveDepartmentCollectionCancel());
    } else {
      dispatch(createRetrieveDepartmentCollectionFailure(enterpriseId, error, params));
    }
  }
};

export type UpdateDepartmentFnT = (
  DepartmentEntityT,
  headers: {}
) => ThunkActionT<DepartmentPatchActionT>;
// eslint-disable-next-line arrow-body-style
export const updateDepartment: UpdateDepartmentFnT = (department, headers) => async dispatch => {
  dispatch(createUpdateDepartmentRequest(department.id));
  try {
    const response: AxiosPromise<*> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${department.enterpriseId}/departments/${department.id}`,
      data: {
        name: department.name
      },
      headers
    });
    const { data } = await response;
    dispatch(createUpdateDepartmentSuccess(department.id, data.name));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateDepartmentCancel());
    } else if (error.config) {
      dispatch(createUpdateDepartmentFailure(department.id, error));
    } else {
      Sentry.captureException(error);
    }
  }
};

export type CreateDepartmentFnT = (
  string,
  string,
  ?string,
  headers: {}
) => ThunkActionT<DepartmentPostActionT>;
// eslint-disable-next-line arrow-body-style
export const createDepartment: CreateDepartmentFnT = (
  enterpriseId,
  departmentName,
  ownerDepartmentId,
  headers
) => async dispatch => {
  dispatch(createCreateDepartmentRequest());
  try {
    const response: AxiosPromise<CreateDepartmentPostDataT, DepartmentPayloadEntityT> = axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/departments`,
      data: {
        name: departmentName,
        ownerDepartmentId
      },
      headers
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createCreateDepartmentSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createCreateDepartmentCancel());
    } else if (error.config) {
      dispatch(createCreateDepartmentFailure(error));
    } else {
      Sentry.captureException(error);
    }
  }
  return undefined;
};

export type DeleteDepartmentFnT = (
  string,
  string,
  headers: {}
) => ThunkActionT<DepartmentDeleteActionT>;
// eslint-disable-next-line arrow-body-style
export const deleteDepartment: DeleteDepartmentFnT = (
  enterpriseId,
  departmentId,
  headers
) => async dispatch => {
  dispatch(createDeleteDepartmentRequest());
  try {
    const response: AxiosPromise<*> = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/departments/${departmentId}`,
      headers
    });
    await response;
    dispatch(createDeleteDepartmentSuccess(enterpriseId, departmentId));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createDeleteDepartmentCancel());
    } else if (error.config) {
      dispatch(createDeleteDepartmentFailure(departmentId, error));
    } else {
      Sentry.captureException(error);
    }
  }
};

export type RetrieveUserCountForDepartmentFnT = (
  string,
  string,
  CancelToken
) => ThunkActionT<RetrieveUserCountForDepartmentActionT>;
export const retrieveUserCountForDepartment: RetrieveUserCountForDepartmentFnT = (
  enterpriseId,
  departmentId,
  cancelToken
) => async dispatch => {
  dispatch(createRetrieveUserCountForDepartmentRequest(enterpriseId));
  try {
    const url = `/api/v1/enterprises/${enterpriseId}/departments/${departmentId}/users/count`;
    const response: AxiosPromise<{ count: number }> = axios({
      method: 'GET',
      url,
      cancelToken
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createRetrieveUserCountForDepartmentSuccess(enterpriseId, departmentId, data));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveUserCountForDepartmentCancel());
    } else {
      dispatch(createRetrieveUserCountForDepartmentFailure(enterpriseId, error));
    }
  }
};

const departmentOperations = {
  retrieveCollection,
  updateDepartment,
  createDepartment,
  deleteDepartment,
  retrieveUserCountForDepartment
};

export default departmentOperations;
