// @flow

import reducer from './currentUserReducer';
import * as operations from './currentUserOperations';
import * as actionCreators from './currentUserActions';
import * as selectors from './currentUserSelectors';
import type { CurrentUserStateT, LoginActionT, LoginStatusT } from './currentUserTypes';

export default reducer.rootReducer;
export { operations, actionCreators, selectors };
export type { CurrentUserStateT, LoginActionT, LoginStatusT };
