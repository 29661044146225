import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTvLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 2H2C.9 2 0 2.9 0 4v12c0 1.1.9 2 2 2h9.5v3h-4c-.3 0-.5.2-.5.5s.2.5.5.5h9.1c.3 0 .5-.2.5-.5s-.3-.5-.5-.5h-4.1v-3H22c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m1 14c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h20c.6 0 1 .4 1 1z"/></svg>)}
    />
  );
}
  