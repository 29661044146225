// @flow strict-local

/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/label-has-for */

import HTTP from 'http-status-codes';
import axios from 'axios';
import type { $AxiosXHR } from 'axios';
import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, type FormProps, formValueSelector } from 'redux-form';
import { type ContextRouter, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { ExactPropsT, StoreStateT } from '../../commonTypes';
import { InputField } from '../../components/InputComponent/ReduxFormField';
import { goToUserPasswordResult, goToUserPasswordMissing, goTo } from '../../navigationOperations';
import LoginBase from '../login/LoginBase';
import fieldValidators from '../../fieldValidators';
import ActionButton from '../../components/Button/ActionButton';
import UserPasswordRequirements from './UserPasswordRequirements';
import { actions as notificationActions } from '../../ducks/ui/notification';
import { setActiveLang } from '../../utils/languageutils';

import styles from './UserPasswordReset.module.scss';

type OwnPropsT = {
  match: {
    params: {
      token: string
    }
  }
};

type StatePropsT = {
  password1: string,
  password2: string
};

type ActiveLanguageT = 'fi' | 'en' | 'sv' | 'et';

type DispatchPropsT = {
  goToUserPasswordResult: typeof goToUserPasswordResult,
  goToUserPasswordMissing: typeof goToUserPasswordMissing,
  goTo: typeof goTo
};

export type PropsT = {|
  ...ExactPropsT<OwnPropsT, StatePropsT, DispatchPropsT>,
  ...$Exact<ContextRouter>,
  ...$Exact<FormProps>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {
  sending: boolean,
  error: boolean,
  input1ErrorMsg: ?string,
  input2ErrorMsg: ?string
};

export type RestoreUserResponseT = {|
  +environment: string,
  +loginName: string
|};

export class UserPasswordReset extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.handleOnClickSaveButton = this.handleOnClickSaveButton.bind(this);
    this.validateComplexity = this.validateComplexity.bind(this);
    this.validateMatching = this.validateMatching.bind(this);
    this.readyToSend = this.readyToSend.bind(this);
    this.validateToken = this.validateToken.bind(this);
  }

  state = {
    sending: false, // eslint-disable-line react/no-unused-state
    error: false,
    input1ErrorMsg: null,
    input2ErrorMsg: null
  };

  validateToken: ActiveLanguageT => void;

  async validateToken(activeLanguage: ActiveLanguageT) {
    const {
      match: {
        params: { token }
      }
    } = this.props;

    if (token) {
      try {
        await axios({
          method: 'POST',
          url: `/api/v1/validatetoken`,
          headers: { type: 'user' },
          data: {
            token
          },
          validateStatus: status => status === HTTP.OK
        });
      } catch (e) {
        this.props.goToUserPasswordMissing(activeLanguage, true);
      }
    }
  }

  async componentDidMount() {
    const {
      location: { pathname },
      i18n
    } = this.props;
    setActiveLang(
      pathname,
      '/vaihdasalasana',
      '/changepassword',
      '/andralosenord',
      '/muudasalasona',
      i18n.changeLanguage
    );
    await this.validateToken(i18n.language);
  }

  handleOnClickSaveButton: () => void;

  async handleOnClickSaveButton() {
    if (this.readyToSend()) {
      const {
        goToUserPasswordResult, // eslint-disable-line no-shadow
        password1,
        match: {
          params: { token }
        }
      } = this.props;
      this.setState({ error: false, sending: true });
      try {
        const { data }: $AxiosXHR<RestoreUserResponseT> = await axios({
          method: 'PATCH',
          url: '/api/v1/restore',
          headers: { type: 'user' },
          data: {
            password: password1,
            token
          },
          validateStatus: status => status === HTTP.OK
        });
        goToUserPasswordResult(data.environment);
      } catch (error) {
        this.setState({
          error: true,
          sending: false
        });
      }
    }
  }

  validateComplexity: string => void;

  validateComplexity(value: string) {
    this.setState({
      input1ErrorMsg: fieldValidators.userPasswordComplexityValidator(value, ' ')
    });
  }

  validateMatching: (value: string) => void;

  validateMatching(value: string) {
    const { t, password1 = '' } = this.props;
    this.setState({
      input2ErrorMsg:
        password1 !== value ? t('password.user.passwordReset.validationErrorMatching') : undefined
    });
  }

  readyToSend: () => boolean;

  readyToSend() {
    const { input1ErrorMsg, input2ErrorMsg } = this.state;
    return input1ErrorMsg === undefined && input2ErrorMsg === undefined;
  }

  render(): Element<typeof LoginBase> {
    // eslint-disable-next-line no-shadow, no-unused-vars
    const { t } = this.props;
    const { error, sending, input1ErrorMsg, input2ErrorMsg } = this.state;

    const passwordInput1 = (
      <Field
        key="password1"
        id="password1"
        label={t('password.user.passwordReset.pw1Placeholder')}
        name="password1"
        type="password"
        className={styles['input-container-top']}
        component={InputField}
        onChange={e => {
          // $FlowFixMe
          if (e && e.currentTarget) {
            this.validateComplexity(e.currentTarget.value);
          }
        }}
        i18n_input_errorMessage={input1ErrorMsg}
        autofocus
      />
    );
    const passwordInput2 = (
      <Field
        key="password2"
        id="password2"
        label={t('password.user.passwordReset.pw2Placeholder')}
        name="password2"
        type="password"
        className={styles['input-container-bottom']}
        component={InputField}
        onChange={e => {
          // $FlowFixMe
          if (e && e.currentTarget) {
            this.validateMatching(e.currentTarget.value);
          }
        }}
        i18n_input_errorMessage={input2ErrorMsg}
      />
    );

    const saveButton = (
      <div className={styles['button-container']}>
        <ActionButton
          id="set-new-user-password-save-button"
          label={t('password.user.passwordReset.saveButton')}
          onClickAction={this.handleOnClickSaveButton}
          loading={sending}
          disabled={!this.readyToSend()}
        />
      </div>
    );

    return (
      <LoginBase>
        <div>
          <h3 className="ea-h3 ea-pad ea-pad--pad-bottom-1" id="login-page-title">
            {t('password.user.passwordReset.title')}
          </h3>
          <p>{t('password.user.passwordReset.description')}</p>
          <UserPasswordRequirements password={this.props.password1} />
          {passwordInput1}
          {passwordInput2}
          <div className="ea-pad ea-pad--pad-top-1">{saveButton}</div>
          <p>{error && t('password.user.passwordReset.genericError')}</p>
        </div>
      </LoginBase>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => ({
  initialValues: { password1: '', password2: '' },
  ...formValueSelector('resetPassword')(state, 'password1', 'password2')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToUserPasswordResult,
      notify: notificationActions.createCreateNotificationAction,
      goToUserPasswordMissing,
      goTo
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<$Diff<PropsT, ContextRouter>, OwnPropsT, _, _, _, _>(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: false }
  ),
  withRouter,
  reduxForm({ form: 'resetPassword', enableReinitialize: true })
)(UserPasswordReset);
