import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAddRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.25 12a.76.76 0 0 1-.75.75h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 1.5 0v6.75h6.75a.76.76 0 0 1 .75.75"/></svg>)}
    />
  );
}
  