import React from 'react';
import IconContainer from '../IconContainer';

export default function IconContinuousRecordingRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.7 4.5C9.8 4.2 8.9 4 8 4c-4.4 0-8 3.6-8 8s3.6 8 8 8c.9 0 1.8-.2 2.7-.5-.5-.3-.9-.7-1.4-1.2-.4.1-.9.1-1.3.1-3.6 0-6.5-2.9-6.5-6.5S4.4 5.4 8 5.4c.4 0 .9 0 1.3.1.4-.3.9-.7 1.4-1M16 4c-1.5 0-2.8.4-4 1.1-.5.3-.9.6-1.4 1C9 7.5 8 9.6 8 12s1 4.5 2.6 5.9c.4.4.9.7 1.4 1 1.2.7 2.5 1.1 4 1.1 4.4 0 8-3.6 8-8s-3.6-8-8-8m0 14.5c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5"/></svg>)}
    />
  );
}
  