import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNotificationRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.5 18.75a.76.76 0 0 1-.75-.75v-6.5a8.76 8.76 0 0 0-8-8.71V.75a.75.75 0 0 0-1.5 0v2.04a8.76 8.76 0 0 0-8 8.71V18a.76.76 0 0 1-.75.75.75.75 0 0 0 0 1.5h19a.75.75 0 0 0 0-1.5m-16.88 0a2.1 2.1 0 0 0 .13-.75v-6.5A7.26 7.26 0 0 1 12 4.25a7.26 7.26 0 0 1 7.25 7.25V18c-.002.256.042.51.13.75zm10.23 3.64A3.31 3.31 0 0 1 12 24a3.29 3.29 0 0 1-2.84-1.6.75.75 0 1 1 1.279-.77 1.83 1.83 0 0 0 3.12 0 .74.74 0 0 1 1-.26.75.75 0 0 1 .291 1.02M17.75 11a.75.75 0 0 1-1.5 0 3.71 3.71 0 0 0-2.08-3.33.75.75 0 1 1 .66-1.34A5.17 5.17 0 0 1 17.75 11"/></svg>)}
    />
  );
}
  