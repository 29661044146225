// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTextRepresentation } from '../../../../../helpers';
import InfoBox from '../../../../../components/InfoBox';
import type { CallForwardingT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import styles from './EditGroupsForwardingInfoBox.module.scss';

type PropsT = {
  forwarding: CallForwardingT
};

const EditGroupsForwardingInfoBox = (props: PropsT) => {
  const { forwarding } = props;
  const { t } = useTranslation();

  // $FlowFixMe
  const textRepresentation = getTextRepresentation(forwarding, 'long', t).toString();

  return (
    <InfoBox extraStyle={styles.infobox}>
      <div className={styles.strong} id="fwd-copy-info">
        {t('forwardingDetails.modifyGroupText')}
      </div>
      <div className={styles['copy-summary']}>{textRepresentation}</div>
    </InfoBox>
  );
};

export default EditGroupsForwardingInfoBox;
