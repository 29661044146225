// @flow

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { CancelToken } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WelcomeAnnouncementIcon } from '../../../../../assets/callflow/details/welcome-announcement-small.svg';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import IntegerField from '../../../components/edit/children/IntegerField';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { updatePlayMusic } from '../../../../../ducks/entities/playMusic/playMusicOperations';
import type { PlayMusicUpdateEntityT } from '../../../../../ducks/entities/playMusic/playMusicOperations';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import type { PlayMusicEntityT } from '../../../../../ducks/entities/playMusic/playMusicTypes';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  musicWait: AudioFieldT<PropertyAudioT>,
  maxDuration: number
};

export const EditPlayMusic = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const playMusicData = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const playMusic = R.path(['ivrProperties', 'playMusic'], playMusicData) || {};

  const playMusicAudioFieldConverter = new AudioFieldConverter(
    playMusicData.enterpriseId,
    playMusicData.id,
    'playmusics',
    'PlayMusic'
  );

  const initialFormValues: FormT = {
    musicWait: playMusicAudioFieldConverter.convertToAudioPropertyField(
      'MusicWait',
      '',
      playMusic.musicWait
    ),
    maxDuration: R.path(['maxDuration', 'value'], playMusic)
  };

  const acdPlayMusicSchema = yup.object().shape({
    maxDuration: yup
      .number()
      .integer()
      .min(15)
      .max(7200)
      .default(600)
      .required()
  });

  // update
  const onSubmit = async (formData: FormT): Promise<PlayMusicEntityT> => {
    const fileName = (formData.musicWait.fileToImport || {}).name;
    const patchPayload: PlayMusicUpdateEntityT = {
      ivrProperties: {
        playMusic: {
          musicWait: {
            type: 'File',
            value: fileName,
            level: formData.musicWait.level || 'Default',
            fileRestUri: fileName
          },
          maxDuration: {
            level: 'Extension',
            value: formData.maxDuration
          }
        }
      }
    };

    // $FlowFixMe Improve formData typing
    const failedAudioUploads = await uploadAudios(
      formData,
      CancelToken.source(),
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }
    return dispatch(
      updatePlayMusic(
        playMusicData.enterpriseId,
        playMusicData.id,
        patchPayload,
        CancelToken.source().token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={playMusicData.id}
      icon={<WelcomeAnnouncementIcon />}
      title={t('callflows.editPlayMusicDetails.title')}
      description={t('callflows.editPlayMusicDetails.description')}
      defaultValues={initialFormValues}
      onSaveForm={onSubmit}
      validationSchema={acdPlayMusicSchema}
    >
      <AcdAudioField
        field="musicWait"
        title={t('callflows.editPlayMusicDetails.musicWait')}
        description={t('callflows.editPlayMusicDetails.musicWaitDescription')}
        hasDefault
      />

      <IntegerField
        field="maxDuration"
        title={t('callflows.editPlayMusicDetails.maxDuration')}
        inputDescription={t('callflows.editPlayMusicDetails.maxDurationInputDescription')}
        tooltip={t('callflows.editPlayMusicDetails.maxDurationTooltip')}
        maxLength={4}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(acdPlayMusicSchema.fields.maxDuration)
        )}
      />
    </EditCallflowDetails>
  );
};

export default EditPlayMusic;
