// @flow

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStartTourAction, createSetStepsAction } from '../../../ducks/ui/tour/tourActions';
import type { CallForwardingTypeT } from '../../../ducks/entities/callForwarding/callForwardingTypes';

export type PropsT = {||};

// eslint-disable-next-line no-unused-vars
const useForwardingDetailsTour = (props: PropsT) => {
  // Redux
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const setupForwardingTour = (
    forwardingType: CallForwardingTypeT,
    isCreate: boolean,
    showUserSelectorStep: boolean,
    showPresenceSelectorStep: boolean,
    showTimedDestinationStep: boolean
  ): void => {
    const userSelectorStep = {
      disableBeacon: true,
      title: t('tours.createForwardingTourSteps.userSelectorStep.title'),
      content: t('tours.createForwardingTourSteps.userSelectorStep.text'),
      target: '#search-input-UserSelectorInput',
      position: 'bottom-left'
    };

    const presenceSelectorStep = {
      disableBeacon: true,
      title: t('tours.createForwardingTourSteps.presenceStep.title'),
      content: t('tours.createForwardingTourSteps.presenceStep.text'),
      target: '#statetype-area',
      position: 'bottom-left'
    };

    const steps = [
      ...(showUserSelectorStep ? [userSelectorStep] : []),
      ...(showPresenceSelectorStep ? [presenceSelectorStep] : []),
      ...(showTimedDestinationStep
        ? [
            {
              disableBeacon: true,
              title: t('tours.createForwardingTourSteps.destinationNoAnswerStep.title'),
              content: t('tours.createForwardingTourSteps.destinationNoAnswerStep.text'),
              target: '#forwarding-destination',
              position: 'bottom-left'
            }
          ]
        : [
            {
              disableBeacon: true,
              title: t('tours.createForwardingTourSteps.destinationStep.title'),
              content: t('tours.createForwardingTourSteps.destinationStep.text'),
              target: '#forwarding-destination',
              position: 'bottom-left'
            }
          ]),
      {
        title: t('tours.createForwardingTourSteps.filtersOnNumberStep.title'),
        content: t('tours.createForwardingTourSteps.filtersOnNumberStep.text'),
        target: '#on-target-number-box',
        position: 'bottom-left'
      },
      {
        title: t('tours.createForwardingTourSteps.additionalStep.title'),
        content: t('tours.createForwardingTourSteps.additionalStep.text'),
        target: '#additional-settings-box',
        position: 'left'
      }
    ];
    dispatch(createSetStepsAction(steps));
  };

  const startTour = (
    forwardingType: CallForwardingTypeT,
    isCreate: boolean,
    showUserSelectorStep: boolean,
    showPresenceSelectorStep: boolean,
    showTimedDestinationStep: boolean
  ) => {
    setupForwardingTour(
      forwardingType,
      isCreate,
      showUserSelectorStep,
      showPresenceSelectorStep,
      showTimedDestinationStep
    );
    dispatch(createStartTourAction());
  };

  return [startTour];
};

export default useForwardingDetailsTour;
