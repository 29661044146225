// @flow

import React, { Component, type ComponentType, type Element } from 'react';

type StateT = {
  loading: boolean
};

type ConfigT = {
  delay: number,
  propName: string
};

export default function delayBooleanTransitionToTrue<WrapperPropsT: {}>(
  config: ConfigT
): (ComponentType<WrapperPropsT>) => ComponentType<WrapperPropsT> {
  const getDisplayName = (WrappedComponent: *): string =>
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return (WrappedComponent: ComponentType<WrapperPropsT>): ComponentType<WrapperPropsT> => {
    class WrapperComponent extends Component<WrapperPropsT, StateT> {
      constructor(props: WrapperPropsT) {
        super(props);
        this.state = {
          loading: false
        };
      }

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillReceiveProps(nextProps: WrapperPropsT) {
        if (nextProps[config.propName] && !this.props[config.propName]) {
          this.loadingIndicatorTimeoutId = setTimeout(() => {
            this.setState({ loading: true });
          }, config.delay);
        } else if (!nextProps[config.propName] && this.props[config.propName]) {
          clearTimeout(this.loadingIndicatorTimeoutId);
          this.setState({ loading: false });
        }
      }

      componentWillUnmount() {
        clearTimeout(this.loadingIndicatorTimeoutId);
      }

      loadingIndicatorTimeoutId: TimeoutID;

      render(): Element<typeof WrappedComponent> {
        return (
          <WrappedComponent
            {...{
              ...this.props,
              [config.propName]: this.state.loading
            }}
          />
        );
      }
    }
    WrapperComponent.displayName = `delayBooleanTransitionToTrue(${getDisplayName(
      WrappedComponent
    )})`;
    return WrapperComponent;
  };
}
