import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSimAddRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.75 13.25a.75.75 0 0 0 0-1.5h-4A2.75 2.75 0 0 0 3 14.5v4a2.75 2.75 0 0 0 2.75 2.75H9a.75.75 0 0 0 0-1.5H7.5v-6.5zm-4 6.5c-.689 0-1.25-.561-1.25-1.25v-1.25H6v2.5zm.25-4H4.5V14.5c0-.689.561-1.25 1.25-1.25H6zm11.25-5.25c-3.722 0-6.75 3.028-6.75 6.75S13.528 24 17.25 24 24 20.972 24 17.25s-3.028-6.75-6.75-6.75m0 12c-2.895 0-5.25-2.355-5.25-5.25S14.355 12 17.25 12s5.25 2.355 5.25 5.25-2.355 5.25-5.25 5.25M21 17.25a.75.75 0 0 1-.75.75H18v2.25a.75.75 0 0 1-1.5 0V18h-2.25a.75.75 0 0 1 0-1.5h2.25v-2.25a.75.75 0 0 1 1.5 0v2.25h2.25a.75.75 0 0 1 .75.75m-9.5 6a.75.75 0 0 1-.75.75h-8.5A2.253 2.253 0 0 1 0 21.75V2.25A2.25 2.25 0 0 1 2.25 0h9.31a2.25 2.25 0 0 1 1.71.793l4.39 4.248c.397.457.59.977.59 1.515V8.5a.75.75 0 0 1-1.5 0V6.555a.74.74 0 0 0-.18-.487l-4.393-4.25c-.192-.22-.4-.317-.62-.317H10.25v3.25a.75.75 0 1 1-1.5-.001V1.5h-2.5v3.25a.75.75 0 0 1-1.5 0V1.5h-2.5a.75.75 0 0 0-.75.75v19.5c0 .413.336.75.75.75h8.5a.75.75 0 0 1 .75.75"/></svg>)}
    />
  );
}
  