import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInformationFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m-.4 6.1c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .5-1 1-1m2.6 11h-.8c-1.2 0-2.1-.9-2.1-2.1v-3.8c0-.1-.1-.1-.1-.1h-.8c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h.8c.8 0 1.4.6 1.4 1.4V15c0 .5.4.9.9.9h.8c.3 0 .6.3.6.6s-.3.6-.7.6q.15 0 0 0"/></svg>)}
    />
  );
}
  