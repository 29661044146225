import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHotFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.205.006a.9.9 0 0 0-.7.22.885.885 0 0 0-.066 1.249c5.534 6.147 2.746 9.924 1.291 11.262a.27.27 0 0 1-.357.011 4.42 4.42 0 0 1-1.518-3.962.884.884 0 0 0-1.204-.907c-3.289 1.244-5.459 4.436-5.4 7.935 0 4.361 4.556 8.186 9.75 8.186 6.698 0 9.749-5.439 9.749-10.492C21.75 7.295 16.139.733 10.205.006m2.572 19.618-.027.001a.625.625 0 0 1-.027-1.249c2.093-.091 3.729-1.932 3.649-4.103a.624.624 0 0 1 .602-.648c.346.01.636.257.648.602.105 2.856-2.068 5.278-4.845 5.397"/></svg>)}
    />
  );
}
  