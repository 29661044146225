import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilterRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.75 9.826V1.5a.75.75 0 0 0-1.5 0v8.326c-1.71.348-3 1.863-3 3.674a3.755 3.755 0 0 0 3 3.674V22.5a.75.75 0 0 0 1.5 0v-5.326c1.71-.349 3-1.864 3-3.674a3.755 3.755 0 0 0-3-3.674M4 15.75c-1.241 0-2.25-1.01-2.25-2.25S2.759 11.25 4 11.25s2.25 1.01 2.25 2.25S5.241 15.75 4 15.75m8.75-12.924V1.5a.75.75 0 0 0-1.5 0v1.326c-1.71.348-3 1.863-3 3.674s1.29 3.326 3 3.674V22.5a.75.75 0 0 0 1.5 0V10.174c1.709-.349 3-1.863 3-3.674s-1.291-3.326-3-3.674M12 8.75c-1.241 0-2.25-1.009-2.25-2.25S10.759 4.25 12 4.25c1.24 0 2.25 1.009 2.25 2.25S13.24 8.75 12 8.75m11.75 8.75a3.756 3.756 0 0 0-3-3.674V1.5a.75.75 0 0 0-1.5 0v12.326a3.76 3.76 0 0 0-3 3.674 3.755 3.755 0 0 0 3.015 3.676c-.003.026-.015.048-.015.074v1.25a.75.75 0 0 0 1.5 0v-1.25c0-.026-.012-.049-.015-.074A3.755 3.755 0 0 0 23.75 17.5M20 19.75c-1.24 0-2.25-1.01-2.25-2.25s1.01-2.25 2.25-2.25 2.25 1.01 2.25 2.25-1.01 2.25-2.25 2.25"/></svg>)}
    />
  );
}
  