// @flow
import React, { type Element } from 'react';
import Tooltip from '../../../../../components/Tooltip';
import styles from './SubTopic.module.scss';

export type PropsT = {|
  title: string,
  description?: string,
  tooltip?: string
|};

export const SubTopic = (props: PropsT): Element<'div'> => {
  const { title, description, tooltip } = props;

  return (
    <div className={styles['field-section']}>
      <div className={styles['title-area']}>
        <div className={styles.title}>{title}</div>
        {tooltip && <Tooltip>{tooltip}</Tooltip>}
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default SubTopic;
