import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 4H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2m0 1a.6.6 0 0 1 .18 0l-9.25 7.62a1.45 1.45 0 0 1-1.86 0L1.82 5A.6.6 0 0 1 2 5zm1 13a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 .06-.29l9.38 7.73a2.45 2.45 0 0 0 3.12 0l9.38-7.73A1 1 0 0 1 23 6z"/></svg>)}
    />
  );
}
  