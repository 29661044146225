// @flow
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import CallflowDetails from '../../../../components/view/CallflowDetails';
import type {
  RelatedNumberT,
  TransferEntityT,
  WelcomeAttendantEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import CallflowDetailsColumn from '../../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../../components/view/children/CallflowItem';
import type { PropsT } from '../message/MessageDetails';
import { ReactComponent as AcdOrExtGroupIcon } from '../../../../../../assets/callflow/details/cf-acd-or-ext-group-small.svg';
import { ReactComponent as WelcomeAttendantIcon } from '../../../../../../assets/callflow/details/cf-welcome-attendant-small.svg';
import { ReactComponent as SpeedDialIcon } from '../../../../../../assets/callflow/details/cf-speeddial-small.svg';
import { ReactComponent as AgentTargetIcon } from '../../../../../../assets/callflow/details/agents-small.svg';
import { ReactComponent as ExtGroupTargetIcon } from '../../../../../../assets/callflow/details/welcome-attendant-ext-group-small.svg';
import { ReactComponent as OcIcon } from '../../../../../../assets/callflow/details/oc-small.svg';

export const destinationIcons = {
  ACDGroupAddress: <AcdOrExtGroupIcon />,
  GroupAddress: <ExtGroupTargetIcon />,
  IVRService: <WelcomeAttendantIcon />,
  SpeedDial: <SpeedDialIcon />,
  Extension: <AgentTargetIcon />,
  FaxAddress: <AcdOrExtGroupIcon />,
  OcService: <OcIcon />
};

export const getTransferIcon = (linkedNumber: ?RelatedNumberT) => {
  if (linkedNumber) {
    if (linkedNumber.type === 'SPEED_DIAL_OC_SERVICE') {
      return destinationIcons.OcService;
    }
    return destinationIcons[linkedNumber.alias];
  }
  return <AgentTargetIcon />;
};

export const TransferDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  // redux
  const welcomeAttendant: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  // $FlowFixMe transfer node should always exists
  const transfer: TransferEntityT = R.path(['commands', commandId], welcomeAttendant) || {};
  const linkedNumber: ?RelatedNumberT = transfer
    ? welcomeAttendant.relatedNumbers[transfer.extensionNumber]
    : undefined;

  const getForwardingType = () => {
    if (!transfer.extensionNumber) {
      return '';
    }
    if (!linkedNumber) {
      return '';
    }
    switch (linkedNumber.alias) {
      case 'ACDGroupAddress':
        return (linkedNumber.label || '').startsWith('kutsu:')
          ? t('callflows.callflowProperty.destination.acdSwitchBoard')
          : t('callflows.callflowProperty.destination.acdCustomerBoard');
      case 'GroupAddress':
        return t('callflows.callflowProperty.destination.extensionGroup');
      case 'IVRService':
        // TODO: Specify IVRService; PlayMusic, VoiceMail, WA
        return t('callflows.callflowProperty.destination.ivr');
      case 'SpeedDial':
        if (linkedNumber.type === 'SPEED_DIAL_OC_SERVICE') {
          return t('callflows.callflowProperty.destination.speedDialOC');
        }
        return t('callflows.callflowProperty.destination.speedDial');
      case 'Extension':
        return linkedNumber.userName ? `${linkedNumber.userName} ` : '';
      case 'FaxAddress':
        return t('callflows.callflowProperty.destination.fax');
      default:
        return '';
    }
  };

  const callflowLabel =
    linkedNumber && linkedNumber.label ? ` ${linkedNumber.label.replace(/^kutsu:/, '')} ` : '';
  const callflowExtensionNumber =
    transfer.extensionNumber &&
    (callflowLabel || (linkedNumber && linkedNumber.alias === 'Extension'))
      ? `(${transfer.extensionNumber})`
      : transfer.extensionNumber;
  const value = callflowExtensionNumber
    ? `${getForwardingType()}${callflowLabel}${callflowExtensionNumber}`
    : null;
  const valueToDisplay = () => {
    if (transfer && value) {
      return value;
    }
    return t('callflows.targetNotSet');
  };

  return (
    <CallflowDetails
      enterpriseId={welcomeAttendant.enterpriseId}
      callflowId={welcomeAttendant.id}
      elementType="WELCOME_ATTENDANT"
      nodeId={transfer.name.toUpperCase()}
      nodeType={transfer.type}
      icon={getTransferIcon(linkedNumber)}
      title={t('callflows.viewWelcomeAttendantTransfer.title')}
      active
    >
      <CallflowDetailsColumn id="wa-transfer-column-1">
        <CallflowItem
          id={`step-name-${transfer.name}`}
          title={t('callflows.welcomeAttendantGeneric.stepName')}
          value={transfer.name}
        />
        <CallflowItem
          id={`targetNumber-${commandId}`}
          title={t('callflows.viewWelcomeAttendantTransfer.targetNumber')}
          value={valueToDisplay()}
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default TransferDetails;
