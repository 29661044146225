import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvoiceMultipleFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.8 1.5H2.2c-.4 0-.8.3-.8.8v14.5c0 .4-.3.8-.8.8s-.6-.4-.6-.8V2.2C0 1 1 0 2.2 0h12.5c.4 0 .8.3.8.8s-.3.7-.7.7m3.7 2c0-.4-.3-.8-.8-.8H5c-1.2 0-2.2 1-2.2 2.2v14.8c0 .4.3.8.8.8s.8-.3.8-.8V5c0-.4.3-.8.8-.8H18c.2 0 .5-.3.5-.7m3 4.3v14c0 1-.8 1.8-1.8 1.8-.4 0-.8-.1-1.1-.4l-2-2.1-1.9 1.9-.1.1c-.6.5-1.5.5-2.1 0l-.1-.1-1.9-2-2.1 2.1c-.3.3-.7.4-1.1.4-.5 0-1-.2-1.3-.6-.3-.3-.4-.7-.4-1.1v-14c0-1.2 1-2.2 2.2-2.2h11.5c1.2-.1 2.2.9 2.2 2.2M8 9.1c0 .3.3.6.6.6h5.2c.3 0 .6-.3.6-.6s-.3-.6-.6-.6H8.6c-.3 0-.6.3-.6.6m5.5 6c0-.3-.3-.6-.6-.6H8.6c-.3 0-.6.3-.6.6s.3.6.6.6h4.2c.4.1.7-.2.7-.6m2-3c0-.3-.3-.6-.6-.6H8.6c-.3 0-.6.3-.6.6s.3.6.6.6h6.2c.4.1.7-.2.7-.6m3.3 3c0-.5-.4-.9-.9-.9s-.9.4-.9.9.4.9.9.9.9-.4.9-.9m0-3c0-.5-.4-.9-.9-.9s-.9.4-.9.9.4.9.9.9.9-.4.9-.9m0-3c0-.5-.4-.9-.9-.9s-.9.4-.9.9.4.9.9.9.9-.4.9-.9"/></svg>)}
    />
  );
}
  