import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLinkedinFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 1h-17C2.1 1 1 2.1 1 3.5v17C1 21.9 2.1 23 3.5 23h17c1.4 0 2.5-1.1 2.5-2.5v-17C23 2.1 21.9 1 20.5 1M6 3.6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2M8 20c0 .2-.2.4-.4.4H4.4c-.2 0-.4-.2-.4-.4V9.6c0-.2.2-.4.4-.4h3.2c.2 0 .4.2.4.4zm12 0c0 .2-.2.4-.4.4h-2.4c-.2 0-.4-.2-.4-.4v-6c0-1.1-.9-2-2-2s-2 .9-2 2v6c0 .2-.2.4-.4.4H10c-.2 0-.4-.2-.4-.4V9.6c0-.2.2-.4.4-.4h2.4c.2 0 .4.2.4.4 0 .1.1.3.2.3s.3 0 .4-.1c.7-.7 1.6-1 2.5-1 2.4 0 4.1 1.5 4.1 4.5z"/></svg>)}
    />
  );
}
  