import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGroupLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.5 12A4.5 4.5 0 1 1 12 7.5 4.51 4.51 0 0 1 7.5 12m0-8a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7M15 21a7.5 7.5 0 0 0-15 0 .5.5 0 0 0 1 0 6.5 6.5 0 1 1 13 0 .5.5 0 0 0 1 0m3-8a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0-7a3 3 0 1 0 0 6 3 3 0 0 0 0-6m6 15a6.51 6.51 0 0 0-6.5-6.5 6.4 6.4 0 0 0-2.44.48.5.5 0 1 0 .38.92A5.5 5.5 0 0 1 23 21a.5.5 0 0 0 1 0"/></svg>)}
    />
  );
}
  