import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRouterRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.25 18a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M9 16.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5m5.86-10.9a.74.74 0 0 0-.014 1.046l.014.014a.75.75 0 0 0 1.06 0 2.25 2.25 0 0 1 3.18 0 .73.73 0 0 0 .53.22.75.75 0 0 0 .53-1.28 3.75 3.75 0 0 0-5.3 0m-1.07-1.06a5.25 5.25 0 0 1 7.425-.005l.005.005a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06 6.75 6.75 0 0 0-9.546-.004l-.004.004a.75.75 0 0 0 1.06 1.06m6.259 17.43v.28a.75.75 0 0 1-1.5 0v-.25H5.442v.25a.75.75 0 0 1-1.5 0v-.28a4.24 4.24 0 0 1 .25-8.47H16.75V9.5a.75.75 0 0 1 1.5 0v4.25h1.549a4.239 4.239 0 0 1 .25 8.47m2.5-4.22a2.75 2.75 0 0 0-2.75-2.75H4.192a2.75 2.75 0 1 0 0 5.5h15.607a2.75 2.75 0 0 0 2.75-2.75"/></svg>)}
    />
  );
}
  