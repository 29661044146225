// @flow
import * as R from 'ramda';
import axios from 'axios';
import type { AudioFieldT, AudioT } from './children/audio/CallflowAudioUtils';
import type { ForwardingPropertyT } from './children/forwardingField/ForwardingUtils';
import {
  enterpriseVoiceMailAddress,
  isSearhableType
} from './children/forwardingField/ForwardingUtils';
import type { EnterpriseEntityT } from '../../../../ducks/entities/enterprise';
import type { CallFlowEntityT } from '../../../../ducks/entities/callFlow';
import { AgentStateByPresence } from '../../../enterprises/EnterpriseSettings/EnterpriseSettings';

const createAudioProp = (value: AudioFieldT<AudioT>) => {
  const fileName = R.path(['fileToImport', 'name'], value);
  const level = value && value.level ? value.level : 'Extension';
  if (level !== 'Extension') {
    return {
      type: 'File',
      level
    };
  }
  return {
    type: 'File',
    value: fileName,
    level,
    fileRestUri: fileName
  };
};

const convertToExternal = value => {
  if (value.value && value.value.startsWith('0') && value.value.length > 1) {
    return `external+358${value.value.substring(1)}`;
  }
  return `external${value.value}`;
};

const createForwardingProp = (value: ForwardingPropertyT) => {
  let val;
  if (value.type === 'TRANSFER_EXTERNAL') {
    val = convertToExternal(value);
  }
  if (isSearhableType(value.type)) {
    val = `internal${value.value}`;
  }
  if (value.type === 'TRANSFER_ENT_VM') {
    val = `internal${enterpriseVoiceMailAddress}`;
  }
  if (value.type === 'NO_TRANSFER') {
    val = '';
  }
  return {
    type: 'Destination',
    value: val,
    level: 'Extension'
  };
};

const createExtensionProp = (
  key: string,
  value: boolean | number | AudioFieldT<AudioT> | ForwardingPropertyT
) => {
  if (value && value.level && value.audioPlayData) {
    // $FlowFixMe Specify Property typing
    return { [key]: createAudioProp(value) };
  }

  if (value && value.propertyType === 'forwarding') {
    // $FlowFixMe Specify Property typing
    return { [key]: createForwardingProp(value) };
  }

  return {
    [key]: value
  };
};

// eslint-disable-next-line import/prefer-default-export
export const createPatchPayload = (
  initialPayload: { [string]: * },
  formData: { [string]: * },
  propertiesNames: string[],
  customProperties: ?({ [string]: * }[])
) => {
  const patchPayload = {
    ...initialPayload,
    servicesSettings: {
      acdCallCenter: Object.assign(
        {},
        ...[
          ...propertiesNames.map(propName => createExtensionProp(propName, formData[propName])),
          ...(customProperties || [])
        ]
      )
    }
  };
  return patchPayload;
};

const createIvrExtensionProp = (
  key: string,
  value: boolean | number | AudioFieldT<AudioT> | ForwardingPropertyT
) => {
  if (value && value.level && value.audioPlayData) {
    // $FlowFixMe Specify Property typing
    return { [key]: createAudioProp(value) };
  }

  if (value && value.propertyType === 'forwarding') {
    // $FlowFixMe Specify Property typing
    return { [key]: createForwardingProp(value) };
  }

  return {
    [key]: {
      level: 'Extension',
      value
    }
  };
};

// eslint-disable-next-line import/prefer-default-export
export const createIvrPatchPayload = (
  initialPayload: { [string]: * },
  formData: { [string]: * },
  propertiesNames: string[],
  customProperties: ?({ [string]: * }[])
) => {
  return {
    ...initialPayload,
    ivrProperties: {
      acdCallCenter: Object.assign(
        {},
        ...[
          ...propertiesNames.map(propName => createIvrExtensionProp(propName, formData[propName])),
          ...(customProperties || [])
        ]
      )
    }
  };
};

export const getGroupDisplayPolicies = (
  enterprise: ?EnterpriseEntityT,
  callflow: CallFlowEntityT
) => {
  let groupDisplayedNumberPolicy;
  let groupDisplayedLabelsTerminalsPolicy;

  groupDisplayedNumberPolicy = R.path(['groupDisplayedNumberPolicy'], callflow);
  groupDisplayedLabelsTerminalsPolicy = R.path(['groupDisplayedLabelsTerminalsPolicy'], callflow);

  if (groupDisplayedNumberPolicy && groupDisplayedLabelsTerminalsPolicy) {
    if (callflow.overrideEnterpriseGroupsDisplayPolicies) {
      groupDisplayedNumberPolicy = R.path(['groupDisplayedNumberPolicy'], callflow);
      groupDisplayedLabelsTerminalsPolicy = R.path(
        ['groupDisplayedLabelsTerminalsPolicy'],
        callflow
      );
    } else if (enterprise) {
      groupDisplayedNumberPolicy = enterprise.groupsDisplayedNumberPolicy;
      groupDisplayedLabelsTerminalsPolicy = enterprise.groupsDisplayedLabelsTerminalsPolicy;
    }
  }
  return { groupDisplayedNumberPolicy, groupDisplayedLabelsTerminalsPolicy };
};

export const MIN_QUEUE_SIZE = 1;
export const MAX_QUEUE_SIZE = 20;
export const DEFAULT_QUEUE_SIZE = 5;

export const getMaxGroupQueueSize = async (enterpriseId: string) => {
  const url = `/api/v1/enterprises/${enterpriseId}/services/ivrs`;
  const params = { ivrName: 'EnterpriseConfiguration' };
  const response = axios({
    method: 'GET',
    url,
    params
  });
  const { data } = await response;
  if (data && data.results) {
    const returnValue = R.pathOr(
      MAX_QUEUE_SIZE,
      ['ivrProperties', 'enterpriseConfiguration', 'maxExtensionGroupQueueSize', 'value'],
      data.results[0]
    );
    return returnValue !== -1 ? returnValue : MAX_QUEUE_SIZE;
  }
  return MAX_QUEUE_SIZE;
};

export const getAgentStateByPresence = async (enterpriseId: string): Promise<string> => {
  const url = `/api/v1/enterprises/${enterpriseId}/services/ivrs`;
  const params = { ivrName: 'EnterpriseConfiguration' };
  const response = axios({
    method: 'GET',
    url,
    params
  });
  const { data } = await response;
  if (data && data.results) {
    const presenceLogin = R.pathOr(
      undefined,
      ['ivrProperties', 'enterpriseConfiguration', 'presenceLogin', 'value'],
      data.results[0]
    );
    const presencePause = R.pathOr(
      undefined,
      ['ivrProperties', 'enterpriseConfiguration', 'presencePause', 'value'],
      data.results[0]
    );

    return AgentStateByPresence.FromPresenceLoginAndPause(presenceLogin, presencePause);
  }
  return '';
};
