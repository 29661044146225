// @flow

import React, { Component, type Element, type ChildrenArray } from 'react';
import Option from './Option';

export type PropsT = {
  children?: ChildrenArray<Element<typeof Option>>,
  onOptionChange: string => *,
  default: string
};

type StateT = {
  activeChild: ?string
};

export class OptionGroup extends Component<PropsT, StateT> {
  static defaultProps = {
    children: []
  };

  constructor(props: PropsT) {
    super(props);
    this.handleOnClickChild = this.handleOnClickChild.bind(this);
  }

  state = {
    activeChild: this.props.default
  };

  handleOnClickChild: *;

  handleOnClickChild(childName: string) {
    const { onOptionChange } = this.props;

    this.setState({
      activeChild: childName
    });
    onOptionChange(childName);
  }

  render() {
    const { children } = this.props;
    const { activeChild } = this.state;

    return (
      <div>
        {children
          ? React.Children.map(children, child =>
              React.cloneElement(child, {
                onClick: () => {
                  if (!child.props.disabled) {
                    this.handleOnClickChild(child.props.name);
                  }
                },
                active: child.props.name === activeChild
              })
            )
          : null}
      </div>
    );
  }
}

export default OptionGroup;
