import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPlayLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.9 10.2 5 .3C3.6-.4 2 .5 2 2.1V22c0 1.5 1.6 2.5 3 1.8l17.9-9.9c1.4-.9 1.4-2.9 0-3.7m-.5 2.7L4.5 22.8c-.1.1-.3.1-.5.1-.6 0-1-.4-1-1V2.1c0-.2 0-.3.1-.5.3-.5.9-.7 1.4-.4l17.9 9.9c.2.1.3.2.4.4.3.5.1 1.1-.4 1.4"/></svg>)}
    />
  );
}
  