import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowRightFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m9.4 19.9 6.9-6.6c.7-.7.7-1.8.1-2.5l-.1-.1-6.9-6.6c-.5-.5-1.3-.5-1.8 0s-.5 1.3 0 1.8l6.3 6.1-6.3 6.1c-.5.5-.6 1.2-.1 1.8s1.2.6 1.9 0q-.15.15 0 0"/></svg>)}
    />
  );
}
  