import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTickFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m6 8.9-7 8-.2.2c-.1 0-.2.1-.2.1h-.3c-.1 0-.2-.1-.2-.1l-4-4c-.1-.2-.1-.3-.2-.4v-.4c0-.1.1-.1.1-.2.1-.1.2-.1.3-.2h.4c.1 0 .1.1.2.1l3.5 3.5L17 8.1c.1-.1.3-.2.4-.2s.3 0 .5.2c.1.1.2.3.2.4s0 .3-.1.4"/></svg>)}
    />
  );
}
  