import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPictureAddLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.5 11c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5-2.9-6.5-6.5-6.5m0 12c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5m3.5-5.5c0 .3-.2.5-.5.5H18v2.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5V18h-2.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H17v-2.5c0-.3.2-.5.5-.5s.5.2.5.5V17h2.5c.3 0 .5.2.5.5m-11.5 3c0 .3-.2.5-.5.5H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h11c.5 0 1.1.2 1.4.6l3 2.9q.6.6.6 1.5v3.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5V5c0-.3-.1-.5-.3-.7l-2.9-3c-.2-.2-.5-.3-.8-.3H2c-.6 0-1 .4-1 1v17c0 .6.4 1 1 1h7c.3 0 .5.2.5.5M4.1 6c0 .5.4.9.9.9s.9-.4.9-.9-.4-.9-.9-.9-.9.4-.9.9m7.7 4.3-1-1.7c-.1-.1-.2-.1-.4-.1l-.1.1-2 2.9c-.1.1-.2.2-.3.2s-.3 0-.4-.1l-1.2-1c-.1-.1-.3-.1-.4 0L4.3 14h3.8c.3 0 .5.2.5.5s-.2.5-.5.5H3.5c-.2 0-.3-.1-.4-.2-.1-.2-.1-.3 0-.5l2-4.1.1-.1c.4-.6 1.2-.7 1.8-.2l.8.7 1.7-2.5.3-.3c.6-.4 1.4-.2 1.7.3l1.1 1.7c.1.2.1.5-.1.7-.2.1-.5.1-.7-.2"/></svg>)}
    />
  );
}
  