import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMessageFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 1C5.4 1 0 5.5 0 10.9c0 2.4 1 4.7 2.8 6.4l-1.7 3.5c-.3.6-.2 1.2.3 1.7.2.3.6.5.9.5.2 0 .4 0 .7-.1l5.4-2.5c1.2.3 2.4.5 3.6.5 6.6 0 12-4.5 12-9.9S18.6 1 12 1M6.5 7.9h8c.3 0 .6.3.6.6s-.3.6-.6.6h-8c-.3 0-.6-.3-.6-.6s.3-.6.6-.6m11 5.2h-11c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h11c.3 0 .6.3.6.6s-.3.6-.6.6"/></svg>)}
    />
  );
}
  