// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import IconEditRegular from '@design-system/component-library/src/components/Icon/lib/IconEditRegular';
import IconDeleteRegular from '@design-system/component-library/src/components/Icon/lib/IconDeleteRegular';
import moment from 'moment';
import Modal from '@design-system/component-library/src/components/Modal';
import { useTranslation } from 'react-i18next';
import type { InternalUserStateEntityT } from '../../ducks/entities/user/userTypes';
import { findWholeDayEvent, getAvailabilityInfo, parsePresenceDate } from '../../helpers';
import AvailabilityIndicator from '../Avatar/AvailabilityIndicator';
import CreateUpdatePresenceModal from '../CreateUpdatePresenceModal/CreateUpdatePresenceModal';
import DeletePresenceModal from '../DeletePresenceModal/DeletePresenceModal';

import styles from './CurrentPresenceState.module.scss';

export type PropsT = {
  userId: string,
  reloadFunc: () => void
};

const CurrentPresenceState = ({ userId, reloadFunc }: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const user: InternalUserStateEntityT = useSelector(state => state.entities.user.byId[userId]);
  const availability = getAvailabilityInfo(user.presenceState, user.telephonicState, t);
  const presenceModalRef = React.useRef<Modal>();
  const deleteModalRef = React.useRef<Modal>();

  const getActiveSchedule = () => {
    const currentDate = moment().locale('en');
    if (!user.calendar) {
      return null;
    }
    let schedule = user.calendar.weekSchedule[currentDate.format('dddd').toUpperCase()];
    schedule = schedule.concat(user.calendar.absoluteTimeSlots);
    schedule.sort(
      (a, b) => parsePresenceDate(b.startTime).valueOf() - parsePresenceDate(a.startTime).valueOf()
    );

    schedule = findWholeDayEvent(schedule, currentDate);
    return schedule
      ? schedule.find(slot => {
          return currentDate.isBetween(
            parsePresenceDate(slot.startTime),
            slot.endTime ? parsePresenceDate(slot.endTime) : moment().add(1, 'years')
          );
        })
      : null;
  };

  const activeSchedule = getActiveSchedule();
  const getEndTime = () => {
    if (activeSchedule) {
      if (!activeSchedule.endTime) {
        return '';
      }
      if (
        parsePresenceDate(activeSchedule.startTime).isSame(
          parsePresenceDate(activeSchedule.endTime),
          'date'
        )
      ) {
        return parsePresenceDate(activeSchedule.endTime).format('HH:mm');
      }
      return parsePresenceDate(activeSchedule.endTime).format('DD.MM. HH:mm');
    }
    return '';
  };

  return (
    <div className={styles['state-area']}>
      <div className={styles.title}>{t('currentPresenceState.title')}</div>
      <div className={styles['availability-field']}>
        <AvailabilityIndicator
          status={availability.icon}
          size="xsmall"
          className={styles['availability-icon']}
        />
        {availability.text}
      </div>
      {activeSchedule && !activeSchedule.endTime && (
        <div className={styles['extra-info-field']}>
          <b>{t('currentPresenceState.manualState1')}</b>
          {t('currentPresenceState.manualState2')}
        </div>
      )}
      <div className={styles['extra-info-field']}>
        <div className={styles['extra-info-field--title']}>
          {t('currentPresenceState.extraInfoTitle')}
        </div>
        <div>
          {activeSchedule && activeSchedule.customPresenceState
            ? activeSchedule.customPresenceState
            : user.statusMessage}
        </div>
      </div>
      {activeSchedule && activeSchedule.endTime && (
        <div className={styles['state-info-field']}>
          <div className={styles['state-info-field--title']}>
            {t('currentPresenceState.stateInfoTitle')}
          </div>
          <div>{getEndTime()}</div>
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        id="state-edit"
        className={styles['edit-field']}
        onClick={() => {
          if (presenceModalRef.current) {
            presenceModalRef.current.openModal();
          }
        }}
        role="button"
        tabIndex="0"
      >
        <IconEditRegular size="s" color="brand-blue" />
        <span className={styles['edit-message']}>{t('currentPresenceState.edit')}</span>
      </div>
      {activeSchedule && !activeSchedule.endTime && (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
        <div
          id="state-delete"
          className={styles['delete-field']}
          onClick={() => {
            if (deleteModalRef.current) {
              deleteModalRef.current.openModal();
            }
          }}
          role="button"
          tabIndex="0"
        >
          <IconDeleteRegular size="s" color="brand-blue" />
          <span className={styles['delete-message']}>{t('currentPresenceState.delete')}</span>
        </div>
      )}
      {user.calendar && (
        <CreateUpdatePresenceModal
          enterpriseId={user.enterpriseId}
          calendarId={user.calendar.id}
          userId={userId}
          modalRef={presenceModalRef}
          reloadFunc={reloadFunc}
          changeCurrentState
        />
      )}
      <DeletePresenceModal
        modalRef={deleteModalRef}
        selectedCalendarSlot={activeSchedule}
        enterpriseId={user.enterpriseId}
        calendarId={user.calendar.id}
        reloadFunc={reloadFunc}
      />
    </div>
  );
};
export default CurrentPresenceState;
