import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMenuLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M1 4.5c0-.3.2-.5.5-.5h21c.3 0 .5.2.5.5s-.2.5-.5.5h-21c-.3 0-.5-.2-.5-.5m21.5 7h-21c-.3 0-.5.2-.5.5s.2.5.5.5h21c.3 0 .5-.2.5-.5s-.2-.5-.5-.5m0 7.5h-21c-.3 0-.5.2-.5.5s.2.5.5.5h21c.3 0 .5-.2.5-.5s-.2-.5-.5-.5"/></svg>)}
    />
  );
}
  