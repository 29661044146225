// @flow

/* eslint-disable import/prefer-default-export, no-underscore-dangle */

import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import type { CallFlowEntityT, CallFlowStateEntityT, CallFlowTypeT } from './callFlowTypes';
import { searchCallFlows } from '../../../scenes/callFlows/components/CallFlowUtils';

type GetCallFlowsByEnterpriseIdFnT = (StoreStateT, string) => CallFlowEntityT[];
export const getCallFlowsByEnterpriseId: GetCallFlowsByEnterpriseIdFnT = (
  { entities: { callFlow } },
  enterpriseId
) =>
  // $FlowFixMe: compose missing in types
  R.compose(
    R.filter(R.propEq('enterpriseId', enterpriseId)),
    R.map(id => callFlow.byId[id])
  )(callFlow.allIds);

type GetCallFlowsByEnterpriseIdAndSearchFilterFnT = (
  StoreStateT,
  string,
  ?string,
  ?string
) => CallFlowEntityT[];
export const getCallFlowsByEnterpriseIdAndSearchFilter: GetCallFlowsByEnterpriseIdAndSearchFilterFnT = (
  { entities: { callFlow } },
  enterpriseId,
  searchTerm,
  selectedCategory
) => {
  const results =
    selectedCategory && selectedCategory !== 'ALL'
      ? // $FlowFixMe: compose missing in types
        R.compose(
          R.filter(R.propEq('enterpriseId', enterpriseId)),
          R.filter(R.propEq('type', selectedCategory)),
          R.map(id => callFlow.byId[id])
        )(callFlow.allIds)
      : // $FlowFixMe: compose missing in types
        R.compose(
          R.filter(R.propEq('enterpriseId', enterpriseId)),
          R.map(id => callFlow.byId[id])
        )(callFlow.allIds);

  return searchCallFlows(results, searchTerm);
};

type IsRetrievingFnT = StoreStateT => boolean;
export const isRetrieving: IsRetrievingFnT = ({ entities: { callFlow } }) =>
  R.pathOr(false, ['__metadata', 'isRetrieving'], callFlow);

type HasLoadErrorFnT = StoreStateT => boolean;
export const hasLoadError: HasLoadErrorFnT = ({ entities: { callFlow } }) =>
  R.path(['__metadata', 'error'], callFlow) !== undefined;

type IsUpdatingFnT = StoreStateT => boolean;
export const isUpdating: IsUpdatingFnT = ({ entities: { callFlow } }): boolean =>
  R.path(['__metadata', 'isUpdating'], callFlow) === true;

type GetServicePnNumbersFnT = (CallFlowEntityT, void | string) => string;
export const getPnNumbers: GetServicePnNumbersFnT = (callflow, delimeter = ' ') =>
  callflow &&
  callflow.pnNumbers &&
  Array.isArray(callflow.pnNumbers) &&
  callflow.pnNumbers.length > 0
    ? callflow.pnNumbers.join(delimeter)
    : '';

type GetCallFlowServiceByAddressNumberFnT = (StoreStateT, string, string) => ?CallFlowEntityT;
export const getCallFlowServiceByAddressNumber: GetCallFlowServiceByAddressNumberFnT = (
  store,
  enterpriseId,
  addressNumber
) => {
  const enterpriseCallFlows = getCallFlowsByEnterpriseId(store, enterpriseId);
  return R.find(service => service.addressNumber === addressNumber)(enterpriseCallFlows);
};

type GetCallFlowServiceByIdFnT = (StoreStateT, string, string) => ?CallFlowEntityT;
export const getCallFlowServiceById: GetCallFlowServiceByIdFnT = (store, enterpriseId, id) => {
  const enterpriseCallFlows = getCallFlowsByEnterpriseId(store, enterpriseId);
  return R.find(service => service.id === id)(enterpriseCallFlows);
};

type IsRetrievingSingleFnT = (StoreStateT, string, CallFlowTypeT, string) => boolean;
export const isRetrievingSingle: IsRetrievingSingleFnT = (
  { entities: { callFlow } },
  enterpriseId,
  type,
  callFlowId
) =>
  R.pathOr(
    false,
    ['byId', `${enterpriseId}-${type}-${callFlowId}`, '__metadata', 'isRetrieving'],
    callFlow
  );

type HasLoadErrorSingleFnT = (StoreStateT, string, CallFlowTypeT, string) => boolean;
export const hasLoadErrorSingle: HasLoadErrorSingleFnT = (
  { entities: { callFlow } },
  enterpriseId,
  type,
  callFlowId
) =>
  R.path(['byId', `${enterpriseId}-${type}-${callFlowId}`, '__metadata', 'error'], callFlow) !==
  undefined;

type GetNameToShowFnT = CallFlowEntityT => string;
export const getNameToShow: GetNameToShowFnT = (callFlow: CallFlowEntityT) => {
  switch (callFlow.type) {
    case 'ACD_CUSTOMER_SERVICE':
    case 'ACD_SWITCHBOARD': {
      let callflowName = '';
      if (callFlow.label) {
        callflowName = callFlow.label.startsWith('kutsu:')
          ? // $FlowFixMe: flow null check bug
            callFlow.label.slice(6)
          : callFlow.label;
      }
      return callflowName || '';
    }
    case 'OC':
    case 'SPEED_DIAL':
    case 'PLAY_MUSIC':
    case 'EXTENSION_GROUP':
    case 'WELCOME_ATTENDANT':
      return callFlow.label || '';
    default:
      return '';
  }
};

type IsfullyLoadedOrErroneousFnT = CallFlowStateEntityT => boolean;
const isfullyLoadedOrErroneous: IsfullyLoadedOrErroneousFnT = callFlow =>
  callFlow.__metadata.hasFullData || !!callFlow.__metadata.error;

type IsfullyLoadedOrErroneousWithPreceedingFnT = (StoreStateT, string, CallFlowTypeT) => boolean;
export const isfullyLoadedOrErroneousWithPreceeding: IsfullyLoadedOrErroneousWithPreceedingFnT = (
  { entities: { callFlow } },
  callFlowReducerId,
  type
) => {
  const idsByType = callFlow.allIds.filter(id => callFlow.byId[id].type === type);
  const index = idsByType.findIndex(id => id === callFlowReducerId);
  if (index === -1) {
    return false;
  }
  if (index === 0) {
    return isfullyLoadedOrErroneous(callFlow.byId[callFlowReducerId]);
  }
  return isfullyLoadedOrErroneous(callFlow.byId[callFlowReducerId]);
};

type AllFullyLoadedFnT = StoreStateT => boolean;
export const allFullyLoaded: AllFullyLoadedFnT = ({ entities: { callFlow } }) =>
  callFlow.allIds.length === 0
    ? false
    : R.all(id => callFlow.byId[id].__metadata.hasFullData)(callFlow.allIds);

type HasMoreFnT = StoreStateT => boolean;
export const hasMore: HasMoreFnT = ({ entities: { callFlow } }) => {
  if ('totalCount' in callFlow.__metadata) {
    return callFlow.allIds.length < callFlow.__metadata.totalCount;
  }
  return true;
};
