import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDisplayLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 2H2C.9 2 0 2.9 0 4v13c0 1.1.9 2 2 2h6.5L8 22H5.5c-.3 0-.5.2-.5.5s.2.5.5.5h13c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-2.4l-.5-3H22c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2M2 3h20c.6 0 1 .4 1 1v11H1V4c0-.6.4-1 1-1m13 19H9l.6-3h5zm7-4H2c-.6 0-1-.4-1-1v-1h22v1c0 .6-.4 1-1 1"/></svg>)}
    />
  );
}
  