// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import SelectGroup from '@design-system/component-library/src/components/SelectGroup';
import { useTranslation } from 'react-i18next';
import type { PresenceStateTypeT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';

export type PropsT = {|
  field: string,
  setFieldValue: (PresenceStateTypeT[]) => void
|};

export const PresenceStateSelector = (props: PropsT): Element<'div'> => {
  const { field, setFieldValue } = props;
  const { t } = useTranslation();
  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const value: PresenceStateTypeT[] = values[field] || [];
  const statuses = [
    'AWAY',
    'HOLIDAYS',
    'SICK',
    'WORKING',
    'LUNCH',
    'BUSINESS_TRIP',
    'TRAINING',
    'CUSTOMER_MEETING',
    'AVAILABLE'
  ];
  const states = statuses.map(status => ({
    id: status,
    title: t(`forwardingDetails.statusSelector.${status}`),
    value: status
  }));
  return (
    <div id="statetype-area">
      <SelectGroup
        name={field}
        multiselect
        forceselection
        label={t('forwardingDetails.presenceStateDesc')}
        products={states}
        i18n_SelectGroup_errorMessage={errors[field] ? errors[field].message : ''}
        i18n_SelectGroup_forceOneSelectedWarningText={t('forwardingDetails.presenceStateWarning')}
        onChange={newStatuses => {
          setFieldValue([...newStatuses]);
          setValue('forwardingType', values.forwardingType, {
            shouldValidate: true,
            shouldDirty: true
          });
        }}
        value={value}
      />
    </div>
  );
};

export default PresenceStateSelector;
