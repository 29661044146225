import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHeadphonesLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 12c0-6.1-4.9-11-11-11S1 5.9 1 12v5.3c0 2.5 2 4.6 4.5 4.9v.3c0 .3.2.5.5.5h1.5c1.1 0 2-.9 2-2v-7.5c0-1.1-.9-2-2-2H6c-.3 0-.5.2-.5.5v.3c-1.4.1-2.7.9-3.5 2V12C2 6.5 6.5 2 12 2s10 4.5 10 10v2.3c-.8-1.1-2.1-1.9-3.5-2V12c0-.3-.2-.5-.5-.5h-1.5c-1.1 0-2 .9-2 2V21c0 1.1.9 2 2 2H18c.3 0 .5-.2.5-.5v-.3c2.5-.3 4.5-2.4 4.5-5zm-16.5.5h1c.6 0 1 .4 1 1V21c0 .6-.4 1-1 1h-1zm-1 .8v7.9c-2.2-.3-3.7-2.4-3.3-4.6.3-1.7 1.6-3 3.3-3.3m12 8.7h-1c-.6 0-1-.4-1-1v-7.5c0-.6.4-1 1-1h1zm1-.8v-7.9c2.2.3 3.7 2.4 3.3 4.6-.3 1.7-1.6 3-3.3 3.3"/></svg>)}
    />
  );
}
  