// @flow

import classNames from 'classnames';
import React, { Component, type Element } from 'react';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { type SortOrderT } from '../../commonTypes';
import Features from '../../features';

import styles from './TableHeader.module.scss';

export type TableSortT = {
  columnId: string,
  order: SortOrderT
};

export type TableHeaderColumnT = {
  columnClasses?: string[],
  columnId: string,
  optionMenu?: {
    options: string[],
    onValueChange: (value: string) => void,
    value?: string
  },
  sortable?: boolean,
  size: 'x-small' | 'small' | 'medium' | 'large' | 'x-large',
  border?: boolean,
  text?: string
};

export type PropsT = {
  sort: ?TableSortT,
  onSort: ?(params: TableSortT) => void,
  columns: TableHeaderColumnT[]
};

export class TableHeader extends Component<PropsT> {
  handleSort(column: TableHeaderColumnT) {
    const { sort, onSort } = this.props;
    if (column.sortable && onSort) {
      onSort({
        columnId: column.columnId,
        order: sort && sort.columnId === column.columnId && sort.order === 'desc' ? 'asc' : 'desc'
      });
    }
  }

  render(): Element<'div'> {
    const { columns, sort } = this.props;
    return (
      <div className={styles.header}>
        {columns.map((column: TableHeaderColumnT, index: number): Element<'div'> => {
          const {
            columnId,
            columnClasses,
            sortable,
            optionMenu,
            text,
            size,
            border = true
          } = column;
          const lastColumn = index === columns.length - 1;
          return (
            <div
              id={`header-${columnId}`}
              role="button"
              className={classNames(
                styles['cell-container'],
                border ? styles.border : '',
                styles[size],
                sortable && Features.USER_SORTING ? styles.sortable : '',
                lastColumn ? styles.last : ''
              )}
              key={`header_${columnId}`}
              onClick={Features.USER_SORTING ? (): void => this.handleSort(column) : null}
              onKeyPress={Features.USER_SORTING ? (): void => this.handleSort(column) : null}
              tabIndex={index}
            >
              <div
                className={classNames(
                  ...(columnClasses || []),
                  styles['cell-content'],
                  border ? styles.border : '',
                  index === 1 ? styles.first : ''
                )}
              >
                {optionMenu && (
                  <Dropdown
                    integrated
                    items={optionMenu.options}
                    selectedValue={optionMenu.value}
                    onValueChange={optionMenu.onValueChange}
                  />
                )}
                {text}
                {sortable && Features.USER_SORTING && (
                  <span
                    className={`ea-icon ${styles['font-reset']} ${styles['table-order']} ${
                      !sort || sort.columnId !== columnId ? styles.hidden : ''
                    } ${sort && sort.order && sort.order === 'desc' ? styles.flipped : ''}`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default TableHeader;
