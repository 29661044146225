// @flow

import { type $AxiosError } from 'axios';
import type {
  RetrieveEnterpriseCollectionRequestMetaT,
  EnterprisesPayloadT,
  EnterpriseEntityT,
  EnterpriseGetOneRequestT,
  EnterpriseGetOneSuccessT,
  EnterpriseGetOneFailureT,
  EnterpriseGetAllRequestT,
  EnterpriseGetAllSuccessT,
  EnterpriseGetAllFailureT,
  EnterprisePatchRequestT,
  EnterprisePatchSuccessT,
  EnterprisePatchFailureT,
  EnterpriseGetOneCancelT,
  EnterpriseGetAllCancelT,
  EnterprisePatchCancelT
} from './enterpriseTypes';

export const createGetEnterpriseRequest = (id: string): EnterpriseGetOneRequestT => ({
  type: 'ring/entity/enterprise/GET_ONE_REQUEST',
  error: false,
  meta: { id }
});

export const createGetEnterpriseSuccess = (
  payload: EnterpriseEntityT
): EnterpriseGetOneSuccessT => ({
  type: 'ring/entity/enterprise/GET_ONE_SUCCESS',
  payload,
  error: false,
  meta: {}
});

export const createGetEnterpriseFailure = (
  id: string,
  error: $AxiosError<*>
): EnterpriseGetOneFailureT => ({
  type: 'ring/entity/enterprise/GET_ONE_FAILURE',
  payload: error,
  error: true,
  meta: { id }
});

export const createGetEnterpriseCancel = (id: string): EnterpriseGetOneCancelT => ({
  type: 'ring/entity/enterprise/GET_ONE_CANCEL',
  error: false,
  meta: { id }
});

export const createGetEnterprisesRequest = (
  meta?: RetrieveEnterpriseCollectionRequestMetaT = {}
): EnterpriseGetAllRequestT => ({
  type: 'ring/entity/enterprise/GET_ALL_REQUEST',
  error: false,
  meta
});

export const createGetEnterprisesSuccess = (
  payload: EnterprisesPayloadT,
  meta?: RetrieveEnterpriseCollectionRequestMetaT
): EnterpriseGetAllSuccessT => ({
  type: 'ring/entity/enterprise/GET_ALL_SUCCESS',
  payload: payload.results,
  error: false,
  meta: {
    ...meta,
    length: payload.length,
    totalCount: payload.totalCount,
    offset: payload.offset
  }
});

export const createGetEnterprisesFailure = (
  payload: $AxiosError<*>,
  meta: {} = {}
): EnterpriseGetAllFailureT => ({
  type: 'ring/entity/enterprise/GET_ALL_FAILURE',
  payload,
  error: true,
  meta
});

export const createGetEnterprisesCancel = (meta: {} = {}): EnterpriseGetAllCancelT => ({
  type: 'ring/entity/enterprise/GET_ALL_CANCEL',
  error: false,
  meta
});

type CreateUpdateEnterpriseRequestFnT = string => EnterprisePatchRequestT;
export const createUpdateEnterpriseRequest: CreateUpdateEnterpriseRequestFnT = id => ({
  type: 'ring/entity/enterprise/PATCH_REQUEST',
  error: false,
  meta: { id }
});

type CreateUpdateEnterpriseSuccessFnT = (string, string) => EnterprisePatchSuccessT;
export const createUpdateEnterpriseSuccess: CreateUpdateEnterpriseSuccessFnT = (id, fullName) => ({
  type: 'ring/entity/enterprise/PATCH_SUCCESS',
  payload: { fullName },
  error: false,
  meta: { id }
});

type CreateUpdateEnterpriseFailureFnT = (string, $AxiosError<*>) => EnterprisePatchFailureT;
export const createUpdateEnterpriseFailure: CreateUpdateEnterpriseFailureFnT = (id, error) => ({
  type: 'ring/entity/enterprise/PATCH_FAILURE',
  payload: error,
  error: true,
  meta: { id }
});

type CreateUpdateEnterpriseCancelFnT = string => EnterprisePatchCancelT;
export const createUpdateEnterpriseCancel: CreateUpdateEnterpriseCancelFnT = id => ({
  type: 'ring/entity/enterprise/PATCH_CANCEL',
  error: false,
  meta: { id }
});
