import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDeliveryRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.25 6.75H15V5.5a1.76 1.76 0 0 0-1.75-1.75H1.75A1.76 1.76 0 0 0 0 5.5v10a.75.75 0 0 0 1.5 0v-1.25h12a.75.75 0 0 0 1.5 0v-6h1.5v3.25a1.76 1.76 0 0 0 1.75 1.75h4.25v3.25a.25.25 0 0 1-.25.25h-.5a.75.75 0 0 0 0 1.5h.5A1.76 1.76 0 0 0 24 16.5v-6a3.75 3.75 0 0 0-3.75-3.75m-18.75 6V5.5a.25.25 0 0 1 .25-.25h11.5a.25.25 0 0 1 .25.25v7.25zm16.75-1a.25.25 0 0 1-.25-.25V8.25h2.25a2.25 2.25 0 0 1 2.25 2.25v1.25zm-1 3a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5m0 4a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5m-12.5-4a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5m0 4a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5m8.75-1.25a.76.76 0 0 1-.75.75h-3.5a.75.75 0 0 1 0-1.5h3.5a.76.76 0 0 1 .75.75"/></svg>)}
    />
  );
}
  