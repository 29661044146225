import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrangeDescLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.854 15.646a.5.5 0 0 1 0 .707l-4 4a.5.5 0 0 1-.708.001l-4-4a.5.5 0 0 1 .707-.707L5 18.793V4a.5.5 0 0 1 1 0v14.793l3.146-3.146a.5.5 0 0 1 .708-.001M22.5 19.5h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1m0-4h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1m0-4h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1m0-4h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1m-9-3h9a.5.5 0 0 0 0-1h-9a.5.5 0 0 0 0 1"/></svg>)}
    />
  );
}
  