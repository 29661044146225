import React from 'react';
import IconContainer from '../IconContainer';

export default function IconExternalLinkLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 12.5V21a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h8.5a.5.5 0 0 1 0 1H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-8.5a.5.5 0 0 1 1 0m2-11.19a.5.5 0 0 0-.31-.31.4.4 0 0 0-.19 0h-6a.5.5 0 0 0 0 1h4.79L10.15 13.15a.48.48 0 0 0-.022.678l.022.022a.48.48 0 0 0 .678.022l.022-.022L22 2.71V7.5a.5.5 0 0 0 1 0v-6a.4.4 0 0 0 0-.19"/></svg>)}
    />
  );
}
  