import React from 'react';
import IconContainer from '../IconContainer';

export default function IconForwardFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.5 13.4-5.6 6q-.6.6-1.5.6c-.5 0-1-.2-1.3-.5q-.6-.6-.6-1.5v-2.5h-11c-.8 0-1.5-.7-1.5-1.5v-4c0-.8.7-1.5 1.5-1.5h11V6.1c0-1.1.8-2 1.9-2.1.6 0 1.1.2 1.5.7l5.6 6c.7.7.7 1.9 0 2.7"/></svg>)}
    />
  );
}
  