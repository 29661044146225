// @flow

import React, { Children, type Element, type Node } from 'react';

import classnames from 'classnames';
import styles from './UserGrid.module.scss';

type PropsT = {
  children: Node,
  dialogHeight: number,
  noUsersMessage: Element<'div'>,
  id?: string
};

export const UserGrid = ({
  children,
  dialogHeight,
  noUsersMessage,
  id
}: PropsT): Element<'div'> => (
  <div
    id={id}
    className={classnames(styles['user-grid'], {
      [styles['user-grid--empty']]: Children.count(children) === 0
    })}
    style={{ height: `${dialogHeight}px` }}
  >
    {Children.count(children) > 0 ? children : <span id="no-users-message">{noUsersMessage}</span>}
  </div>
);

export default UserGrid;
