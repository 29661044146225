// @flow strict-local

import React, { type Element, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProtectedRoutes from './ProtectedRoutes';
import PhonebookRoutes from './PhonebookRoutes';
import ErrorPage from '../scenes/error/ErrorPage';
import Downloads from '../scenes/downloads/Downloads';
import Login from '../scenes/login/Login';
import PBLogin from '../scenes/Phonebook/Login/Login';
import UserPasswordMissing from '../scenes/password/UserPasswordMissing';
import UserPasswordReset from '../scenes/password/UserPasswordReset';
import UserPasswordResetDone from '../scenes/password/UserPasswordResetDone';
import AdminAccessRightErrorPage from '../scenes/login/AdminAccessRightErrorPage';
import LoginWithElisaAD from '../scenes/login/LoginWithElisaAD';
import AutoLogin from '../scenes/login/AutoLogin';
import MissingRoleAccessRightErrorPage from '../scenes/login/MissingRoleAccessRightErrorPage';
import TwoStepAuthentication from '../scenes/login/TwoStepAuthentication';
import type { CurrentUserT } from '../ducks/currentUser/currentUserTypes';
import TransferMyIstraLogin from '../scenes/login/TransferMyIstraLogin';

import '../main.module.scss';

type OwnPropsT = {||};

// eslint-disable-next-line no-unused-vars
const OpenRoutes = (props: OwnPropsT): Element<'div'> => {
  const { i18n } = useTranslation();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  useEffect(() => {
    if (document.documentElement) {
      document.documentElement.lang = i18n.language;
    }
  }, [i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    i18n.changeLanguage(currentUser.appLanguage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main-container">
      <Switch>
        <Route
          exact
          path="/error"
          component={() => {
            // $FlowFixMe error is intentional
            undefinedFn(); // eslint-disable-line no-undef
            return null;
          }}
        />
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/phonebook/login" component={PBLogin} />
        <Route exact path="/autologin" component={AutoLogin} />
        <Route exact path="/transferMyIstraLogin" component={TransferMyIstraLogin} />

        <Route exact path="/two-step-authentication" component={TwoStepAuthentication} />
        <Route exact path="/otp-authentication" component={TwoStepAuthentication} />

        <Route exact path="/password" component={UserPasswordMissing} />
        <Route exact path="/salasana" component={UserPasswordMissing} />
        <Route exact path="/lösenord" component={UserPasswordMissing} />
        <Route exact path="/parool" component={UserPasswordMissing} />

        <Route exact path="/changepassword/:token" component={UserPasswordReset} />
        <Route exact path="/vaihdasalasana/:token" component={UserPasswordReset} />
        <Route exact path="/andralosenord/:token" component={UserPasswordReset} />
        <Route exact path="/muudasalasona/:token" component={UserPasswordReset} />
        <Route exact path="/done-password" component={UserPasswordResetDone} />

        <Route
          exact
          path="/instructions"
          component={() => {
            return <Redirect to="/downloads" />;
          }}
        />
        <Route
          exact
          path="/ohjeet"
          component={() => {
            return <Redirect to="/lataukset" />;
          }}
        />
        <Route
          exact
          path="/anvisningar"
          component={() => {
            return <Redirect to="/nedladdningar" />;
          }}
        />
        <Route
          exact
          path="/juhised"
          component={() => {
            return <Redirect to="/allalaadimised" />;
          }}
        />

        <Route exact path="/downloads" component={Downloads} />
        <Route exact path="/lataukset" component={Downloads} />
        <Route exact path="/nedladdningar" component={Downloads} />
        <Route exact path="/allalaadimised" component={Downloads} />
        <Route exact path="/login-with-elisa-ad" component={LoginWithElisaAD} />
        <Route exact path="/no-admin-access-rights-error" component={AdminAccessRightErrorPage} />
        <Route
          exact
          path="/missing-role-access-right-error"
          component={MissingRoleAccessRightErrorPage}
        />

        <Route path="/enterprises" component={ProtectedRoutes} />
        <Route path="/reports" component={ProtectedRoutes} />
        <Route path="/no-permission" component={ProtectedRoutes} />
        <Route path="/adminui" component={ProtectedRoutes} />

        <Route path="/phonebook" component={PhonebookRoutes} />

        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
};

export default OpenRoutes;
