import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLinkRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.7 20.736A4.95 4.95 0 0 1 2.202 19.3C1.266 18.371.75 17.136.75 15.821s.516-2.551 1.452-3.479L6.657 7.92c1.926-1.916 5.066-1.92 6.995-.007a4.87 4.87 0 0 1 .507 6.378.75.75 0 1 1-1.209-.887 3.38 3.38 0 0 0-.353-4.426 3.476 3.476 0 0 0-4.883.006l-4.456 4.422c-.65.646-1.008 1.503-1.008 2.415s.358 1.77 1.008 2.415a3.476 3.476 0 0 0 4.883 0l.87-.863a.75.75 0 1 1 1.057 1.065l-.87.863A4.95 4.95 0 0 1 5.7 20.736m11.644-4.657 4.455-4.421c.936-.929 1.451-2.165 1.451-3.479s-.516-2.551-1.451-3.479a4.98 4.98 0 0 0-6.997 0l-.7.695a.75.75 0 1 0 1.057 1.065l.7-.695a3.477 3.477 0 0 1 4.884 0c.65.646 1.008 1.503 1.008 2.415s-.357 1.77-1.008 2.415l-4.455 4.421a3.475 3.475 0 0 1-4.884.004 3.38 3.38 0 0 1-.353-4.424.75.75 0 0 0-.162-1.048.746.746 0 0 0-1.048.16 4.87 4.87 0 0 0 .507 6.377 4.95 4.95 0 0 0 3.495 1.433c1.268 0 2.536-.48 3.501-1.439"/></svg>)}
    />
  );
}
  