// @flow

/* eslint-disable no-underscore-dangle, jsx-a11y/label-has-for, react/no-array-index-key */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import Truncate from 'react-truncate-markup';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../../../commonTypes';
import Dismiss from '../../../components/Button/Dismiss';
import { actionCreators as userImportUiActions } from '../../../ducks/ui/userImport';
import type { ImportAnalysisSheetStateT } from '../../../ducks/ui/userImport/userImportUiTypes';
import { operations as departmentOps } from '../../../ducks/entities/department';
import styles from './SheetSelectionStep.module.scss';

export type SheetsSelectedT = ?('internalOrBothWithNewDepartments' | 'other');

type OwnPropsT = {
  onCancel: void => void,
  onSheetsSelected: SheetsSelectedT => void
};

type StatePropsT = {|
  enterpriseId: ?string,
  sheets: ImportAnalysisSheetStateT[],
  fileName: ?string
|};

type DispatchPropsT = {|
  retrieveDepartments: (string, CancelToken) => *,
  select: typeof userImportUiActions.createAnalyzeImportSelectSheet
|};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export class SheetSelectionStep extends Component<PropsT> {
  constructor(props: PropsT) {
    super(props);
    this.retrieveDepartmentsRequestCancelTokenSource = CancelToken.source();
    this.handleSheetSelection = this.handleSheetSelection.bind(this);
    this.selectSheets = this.selectSheets.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { enterpriseId, retrieveDepartments } = this.props;
    if (enterpriseId) {
      retrieveDepartments(enterpriseId, this.retrieveDepartmentsRequestCancelTokenSource.token);
    }
  }

  componentDidMount() {
    const { sheets } = this.props;
    sheets.map((sheet, index) => this.handleSheetSelection(index, true));
    const selectedSheets = sheets.filter(({ selected }) => selected);
    this.selectSheets(selectedSheets);
  }

  componentDidUpdate({ sheets: previousSheets }: PropsT) {
    const { sheets } = this.props;

    const peviousSelectedSheets = previousSheets.filter(({ selected }) => selected);
    const selectedSheets = sheets.filter(({ selected }) => selected);

    if (peviousSelectedSheets.length !== selectedSheets.length) {
      this.selectSheets(selectedSheets);
    }
  }

  selectSheets: *;

  selectSheets(selectedSheets: ImportAnalysisSheetStateT[]) {
    const { onSheetsSelected } = this.props;
    if (
      selectedSheets.filter(
        ({ type, newDepartments }) =>
          type === 'internalUsers' && newDepartments && newDepartments.length > 0
      ).length > 0
    ) {
      onSheetsSelected('internalOrBothWithNewDepartments');
    } else if (selectedSheets.length > 0) {
      onSheetsSelected('other');
    } else {
      onSheetsSelected();
    }
  }

  handleSheetSelection: *;

  handleSheetSelection(sheetIndex: number, selected: boolean) {
    const { select } = this.props;
    select(sheetIndex, selected);
  }

  retrieveDepartmentsRequestCancelTokenSource: CancelTokenSource;

  render() {
    const { t, sheets, fileName, onCancel } = this.props;
    return (
      <div id="user-import-sheet-selection-step" className={styles['step-content']}>
        <Dismiss id="close-select-sheets" onClose={onCancel} />
        <div>
          <div className={styles.filename}>
            <h3>{t('importUsers.sheetSelectionStep.fileName')}</h3>
            <div>
              <Truncate lines={1}>
                <span id="sheet-selection-filename">{fileName || ''}</span>
              </Truncate>
            </div>
          </div>
          <div id="sheet-selection-sheets" className={styles.sheets}>
            <h3>{t('importUsers.sheetSelectionStep.chooseUserType')}</h3>
            {sheets.map(({ name, type }, index) => (
              <div className="sheet-area" key={index}>
                {type === 'internalUsers'
                  ? t('importUsers.sheetSelectionStep.internal', { name })
                  : t('importUsers.sheetSelectionStep.external', { name })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => ({
  enterpriseId: state.currentUser.currentEnterprise.id,
  sheets: state.ui.userImport.importAnalysis.sheets,
  fileName: state.ui.userImport.importAnalysis.fileName
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retrieveDepartments: departmentOps.retrieveCollection,
      select: userImportUiActions.createAnalyzeImportSelectSheet
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(SheetSelectionStep);
