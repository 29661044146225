// @flow
import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Truncate from 'react-truncate-markup';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import CallFlowType from './CallFlowType';
import ConfirmButton from '../../../../components/Button/ConfirmButton';
import {
  getPnNumbers,
  hasLoadErrorSingle,
  isfullyLoadedOrErroneousWithPreceeding
} from '../../../../ducks/entities/callFlow/callFlowSelectors';
import type { CallFlowEntityT, CallFlowGridElementT } from '../../../../ducks/entities/callFlow';
import { goToCallFlow } from '../../../../navigationOperations';
import { ErrorView } from './content/ErrorView';
import { TreeView } from './content/TreeView';
import { LoadingView } from './content/LoadingView';
import { getLoadGroupStatus } from '../../components/CallFlowActivityUtils';
import styles from './GridElement.module.scss';

export type GridContentT = Element<typeof TreeView | typeof ErrorView | typeof LoadingView>;

export type PropsT = {
  gridElement: CallFlowGridElementT,
  hideLeftPanel?: boolean,
  singleView?: boolean
};

export const GridElement = (props: PropsT): Element<'div'> => {
  const { gridElement, hideLeftPanel, singleView } = props;
  const callflowId = `${gridElement.enterpriseId}-${gridElement.type}-${gridElement.id}`;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const callflowData: CallFlowEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const shouldBeShown: boolean = useSelector(state =>
    isfullyLoadedOrErroneousWithPreceeding(state, callflowId, gridElement.type)
  );
  const hasLoadError: boolean = useSelector(state =>
    hasLoadErrorSingle(state, gridElement.enterpriseId, gridElement.type, gridElement.id)
  );

  const callflowName =
    typeof gridElement.name === 'string' && gridElement.name.startsWith('kutsu:')
      ? gridElement.name.slice(6)
      : gridElement.name;

  const serviceNumber = getPnNumbers(callflowData, ' ');
  const addressNumber = callflowData ? callflowData.addressNumber : null;

  let gridContent: GridContentT;
  if ((singleView || shouldBeShown) && !hasLoadError) {
    gridContent = (
      <TreeView
        gridElement={gridElement}
        hideDescription={hideLeftPanel || false}
        loadGroupStatus={getLoadGroupStatus(callflowData)}
      />
    );
  } else if (hasLoadError) {
    gridContent = <ErrorView gridElement={gridElement} />;
  } else {
    gridContent = <LoadingView />;
  }

  return (
    <div
      className={classnames(styles['callflow-container'], {
        [`${styles['callflow-container--wide']}`]: singleView
      })}
    >
      <div className={styles['content-container']}>
        {!hideLeftPanel && (
          <div className={styles['description-area']}>
            <div>
              <div className={styles.title}>
                <Truncate id={`callflow-name-${gridElement.name}`} lines={3}>
                  <span>{callflowName}</span>
                </Truncate>
                {addressNumber && <div>{addressNumber}</div>}
                {serviceNumber && <div>{serviceNumber}</div>}
              </div>
              <CallFlowType type={gridElement.type} />
              <div className={styles.description}>{gridElement.description}</div>
            </div>
            <div>
              <ConfirmButton
                id="callflow-grid-modify-button"
                className={styles['modify-button']}
                label={t('callflows.modifyButton')}
                hideWhenViewing
                onClickAction={() =>
                  dispatch(
                    goToCallFlow(
                      gridElement.enterpriseId,
                      gridElement.id,
                      gridElement.type,
                      null,
                      null
                    )
                  )
                }
              />
            </div>
          </div>
        )}
        <div className={styles.content}>{gridContent}</div>
      </div>
    </div>
  );
};

export default GridElement;
