import React from 'react';
import PropTypes from 'prop-types';
import styles from './Links.module.scss';

function ImitationAccordionLink({ subcategory, handleClick }) {
  return <div className="ds-accordion__section ds-accordion__section__imitation_accordion">
    <div>
      <a
        className="ds-accordion__section__heading__button--m"
        href={subcategory.url}
        onClick={handleClick}>
        <h3 className={styles['accordion__section__heading--title']}>{subcategory.title}</h3>
      </a>
    </div>
  </div>
}

ImitationAccordionLink.propTypes = {
  handleClick: PropTypes.func,
  subcategory: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ImitationAccordionLink;
