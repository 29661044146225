// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { ReactComponent as CalendarIcon } from '../../../../../assets/callflow/details/calendar-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import WeekDayCalendar from '../../../components/view/children/calendar/WeekDayCalendar';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import type { BaseCalendarEntityT } from '../../../../../ducks/entities/calendar/calendarTypes';
import ExtensionGroupCalendarConverter from './forwardingConvertUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import ErrorBoundary from '../../../../../components/Error/ErrorBoundary';
import GenericError from '../../../../../components/Error/GenericError';
import CallflowItem from '../../../components/view/children/CallflowItem';
import InvalidConfigurationInfo from '../../../components/view/children/InvalidConfigurationInfo';
import {
  getActiveForwardingsByType,
  hasMultipleTargetTypes
} from './extensionGroupForwardingUtils';
import { ExtensionGroupForwardingTargetName } from '../../../components/view/children/ExtensionGroupForwardingTargetName';

export type PropsT = {|
  callflowId: string
|};

export const ViewExtensionGroupCalendar = (
  props: PropsT
): Element<typeof CallflowDetails> | null => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const extensionGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const calendar: BaseCalendarEntityT = ExtensionGroupCalendarConverter.convertForwardingsToCalendar(
    extensionGroupData.forwardings
  );
  const activeAlwaysTypeForwardings = getActiveForwardingsByType(
    extensionGroupData.forwardings,
    'ALWAYS'
  );
  const hasUnsupportedData = hasMultipleTargetTypes(activeAlwaysTypeForwardings);

  return (
    <CallflowDetails
      enterpriseId={extensionGroupData.enterpriseId}
      callflowId={extensionGroupData.id}
      elementType={extensionGroupData.type}
      nodeId={`group_calendar_${extensionGroupData.id}`}
      nodeType="EXTENSION_GROUP_CALENDAR"
      icon={<CalendarIcon />}
      title={t('callflows.viewExtensionGroupCalendar.title')}
      active
      hideButton={(currentUser.featureFlags || []).includes(
        'FEATURE-HIDE-EXTENSIONGROUP-CALENDAR-EDIT'
      )}
    >
      {hasUnsupportedData ? (
        <InvalidConfigurationInfo
          title={parse(
            `<b>${t('callflows.viewExtensionGroupCalendar.invalidDataTitle1')}</b>${t(
              'callflows.viewExtensionGroupCalendar.invalidDataTitle2'
            )}`
          )}
          details={t('callflows.viewExtensionGroupCalendar.invalidDataDetails', {
            activeCount: activeAlwaysTypeForwardings.length
          })}
          showLeftPadding
        />
      ) : (
        <CallflowDetailsColumn id="extension-group-calendar-column-1">
          <ErrorBoundary
            showError={!calendar || calendar.error !== undefined}
            errorElement={
              <GenericError
                message={parse(
                  `${t(
                    'callflows.viewExtensionGroupCalendar.parseError'
                  )}<a href="https://yrityksille.elisa.fi/yhteystiedot">https://yrityksille.elisa.fi/yhteystiedot</a>.`
                )}
              />
            }
          >
            <WeekDayCalendar
              title={t('callflows.viewExtensionGroupCalendar.openCalendarTitle')}
              selectedCalendar={calendar}
              hideCalendarName
            />
            <CallflowItem
              id="targetOnClosed"
              title={t('callflows.viewExtensionGroupCalendar.targetOnClosed')}
              value={
                <ExtensionGroupForwardingTargetName
                  extensionGroup={extensionGroupData}
                  forwardingType="ALWAYS"
                />
              }
              active={!ExtensionGroupCalendarConverter.isAlwaysOpenCalendar(calendar.weekSchedule)}
            />
          </ErrorBoundary>
        </CallflowDetailsColumn>
      )}
    </CallflowDetails>
  );
};

export default ViewExtensionGroupCalendar;
