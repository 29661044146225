// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import { CancelToken, CancelTokenSource } from 'axios';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { goToCallFlowServices } from '../../../navigationOperations';
import CallFlowHeader from './CallFlowHeader';
import BaseContainer from '../../BaseContainer/BaseContainer';
import GenericError from '../../../components/Error/GenericError';
import CallFlowElement from '../callFlowGrid/services/CallFlowElement';
import ViewCallflowContent from './ViewCallflowContent';
import {
  type CallFlowEntityT,
  selectors as callFlowSelect
} from '../../../ducks/entities/callFlow';
import { retrieve } from '../../../ducks/entities/callFlow/callFlowOperations';
import EditCallflowContent from './EditCallflowContent';
import Notifications from '../../../components/Notifications/Notifications';
import {
  createSetCategory,
  createUpdateEditStatus,
  createUpdateSelectedStatus
} from '../../../ducks/ui/callflow/callflowUiActions';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import TopNavigation from '../../navigation/TopNavigation';
import styles from './CallFlow.module.scss';

export type PropsT = {|
  match: {
    params: {
      callflowId: string,
      id: string
    }
  },
  location: {
    search: string
  }
|};

let cancelTokenSource: CancelTokenSource;

export const CallFlow = (props: PropsT): Element<typeof BaseContainer> => {
  const serviceId = props.match.params.callflowId;
  const enterpriseId = props.match.params.id;
  const params = qs.parse(props.location.search);
  // $FlowFixMe params.type should always be string
  const callFlowType: string = params.type;
  const callflowId = `${enterpriseId}-${callFlowType}-${serviceId}`;
  const location = useLocation();
  const selectedNodeId = location.state ? location.state.nodeId : null;
  const selectedNodeType = location.state ? location.state.nodeType : null;
  // state
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const callflow: CallFlowEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const hasLoadError: boolean = useSelector(state =>
    callflow
      ? callFlowSelect.hasLoadErrorSingle(state, callflow.enterpriseId, callflow.type, callflow.id)
      : false
  );

  const fetchCallFlow = async () => {
    setIsLoading(true);
    await dispatch(retrieve(enterpriseId, serviceId, callFlowType, cancelTokenSource.token, false));
    setIsLoading(false);

    if (selectedNodeId) {
      dispatch(createUpdateSelectedStatus(serviceId, selectedNodeType, selectedNodeId, true));
    }
    await dispatch(createSetCategory(callFlowType));
  };

  useEffect(() => {
    cancelTokenSource = CancelToken.source();
    fetchCallFlow();
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
    };
  }, []); // eslint-disable-line

  const showCallflowElement = !isLoading && !hasLoadError && callflow;

  const backToList = () => {
    dispatch(createUpdateEditStatus(serviceId || '', false));
    dispatch(goToCallFlowServices(enterpriseId));
  };

  const viewContentSupplier = () => (
    <ViewCallflowContent callflowId={callflowId} onClick={() => setIsEditing(true)} />
  );
  const editContentSupplier = () => (
    <EditCallflowContent callflowId={callflowId} onClick={() => setIsEditing(false)} />
  );
  const contentSupplier = isEditing ? editContentSupplier : viewContentSupplier;

  return (
    <BaseContainer
      header={
        <>
          <TopNavigation />
          <Notifications tags={['callflow-edit-fail', 'callflow-edit-success']} />
        </>
      }
      containerStyle={styles.container}
    >
      <CallFlowHeader backToList={backToList} callflow={callflow} />
      {isLoading && !hasLoadError && (
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      )}
      {showCallflowElement && (
        <CallFlowElement
          enterpriseId={enterpriseId}
          callFlowData={callflow}
          single
          hideDescription
          showError={hasLoadError}
          errorElement={<GenericError showReloadLink message={t('callflow.userListFailedMsg')} />}
        />
      )}
      {showCallflowElement && contentSupplier()}
    </BaseContainer>
  );
};

export default CallFlow;
