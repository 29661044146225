import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCalendarLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 3h-4V1c0-.3-.2-.5-.5-.5s-.5.2-.5.5v2H7V1c0-.3-.2-.5-.5-.5S6 .7 6 1v2H2C.9 3 0 3.9 0 5v17c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M2 4h4v2c0 .3.2.5.5.5S7 6.3 7 6V4h10v2c0 .3.2.5.5.5s.5-.2.5-.5V4h4c.6 0 1 .4 1 1v4H1V5c0-.6.4-1 1-1m20 19H2c-.6 0-1-.4-1-1V10h22v12c0 .6-.4 1-1 1"/></svg>)}
    />
  );
}
  