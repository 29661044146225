"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function LogoAndServiceService(_a) {
    var identifiers = _a.identifiers, label = _a.label, onClick = _a.onClick, target = _a.target, url = _a.url;
    return {
        identifiers: identifiers,
        label: label,
        onClick: onClick,
        target: target,
        url: url,
    };
}
exports.default = LogoAndServiceService;
