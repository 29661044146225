/* eslint-disable flowtype/no-weak-types */
// @flow

export const toFormSlots = (slotData: any): any => {
  return {
    id: slotData.id,
    startTime: slotData.startTime,
    endTime: slotData.endTime,
    active: slotData.open,
    presenceState: 'AWAY',
    newSlot: false
  };
};

export const findTitleForDate = (dateStr: string, holidayData: [], t: any): string => {
  let title = '';
  holidayData.some(hd => {
    if (dateStr.startsWith(hd.date)) {
      title = t(`holidays.${hd.name}`);
    }
    return title !== '';
  });
  return title;
};
