// @flow
import React, { type Element, useEffect, useState } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Combobox from '@design-system/component-library/src/components/Combobox';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup';
import { CancelToken } from 'axios';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { getAllForwardingTargets } from '../../../../../../ducks/entities/directory/directoryOperations';
import { isSearhableType, isExternalNumber } from './ForwardingUtils';
import Tooltip from '../../../../../../components/Tooltip';
import CenterHorizontally from '../../../../../../components/CenterHorizontally/CenterHorizontally';
import type { DirectoryFwdT } from '../../../../../../ducks/entities/directory/directoryOperations';
import styles from './ACDForwardingField.module.scss';

const NO_TRANSFER = 'NO_TRANSFER';
const TRANSFER_INTERNAL = 'TRANSFER_INTERNAL';
const TRANSFER_EXTERNAL = 'TRANSFER_EXTERNAL';
const TRANSFER_ENT_VM = 'TRANSFER_ENT_VM';

export type PropsT = {|
  field: string,
  title: string,
  filterSearchResults?: DirectoryFwdT => boolean,
  description?: string,
  disabled?: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  disabledFn?: any => boolean,
  noEndTransfer?: boolean,
  containerStyle?: string
|};

let requestDestinationsCancelTokenSource;

export const ACDForwardingField = (props: PropsT): Element<'div' | typeof CenterHorizontally> => {
  const {
    field,
    title,
    description,
    filterSearchResults,
    noEndTransfer,
    containerStyle,
    disabled,
    disabledFn
  } = props;
  const [searchResults, setSearchResults] = useState<{ label: string, value: string }[]>([]);
  const { t } = useTranslation();
  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const disabledValue = disabledFn === undefined ? disabled : disabledFn(values);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  // redux
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();

  const addCurrentSelectedValueToSearchResults = async filteredResults => {
    const currentSelectedValue = filteredResults.find(
      result => result.value === values[field].value
    );

    if (!currentSelectedValue && values[field].value) {
      filteredResults.push({ label: values[field].text, value: values[field].value });
    }

    setSearchResults(filteredResults);
  };

  const fetchDirectories = async searchText => {
    if (isSearhableType(values[field].type) && currentUser.currentEnterprise) {
      if (requestDestinationsCancelTokenSource) {
        requestDestinationsCancelTokenSource.cancel();
      }
      requestDestinationsCancelTokenSource = CancelToken.source();

      setShowLoadingSpinner(true);
      const results = await dispatch(
        getAllForwardingTargets(
          currentUser.currentEnterprise.id,
          requestDestinationsCancelTokenSource.token,
          {
            search: searchText
          }
        )
      );
      if (results) {
        const filteredResults =
          filterSearchResults && results
            ? results.filter(result => filterSearchResults(result))
            : results;
        addCurrentSelectedValueToSearchResults(filteredResults);

        setShowLoadingSpinner(false);
      }
    }
  };

  const internalTargets = [
    'TRANSFER_ACD_SWITCHBOARD',
    'TRANSFER_ACD_CUSTOMER_SERVICE',
    'TRANSFER_EXTENSION_GROUP',
    'TRANSFER_PLAY_MUSIC',
    'TRANSFER_SPEED_DIAL',
    'TRANSFER_OC',
    'TRANSFER_WELCOME_ATTENDANT',
    'TRANSFER_INTERNAL'
  ];

  const fieldValue = internalTargets.includes(values[field].type)
    ? 'TRANSFER_INTERNAL'
    : values[field].type;

  useEffect(() => {
    requestDestinationsCancelTokenSource = CancelToken.source();
    const fetchTargets = async () => {
      if (isSearhableType(values[field].type)) {
        if (fieldValue === 'TRANSFER_INTERNAL' && values[field].value) {
          await fetchDirectories(values[field].value);
        }
      }
    };
    fetchTargets();
    return () => {
      requestDestinationsCancelTokenSource.cancel();
    };
  }, [values[field].type]); // eslint-disable-line react-hooks/exhaustive-deps

  const descriptionMsg: string =
    description === undefined ? t('callflows.forwardingField.description') : description;

  const numberInputField = (
    <div className={styles.input}>
      <div className={styles['input-wrapper']} data-cy={`number-input-${field}`}>
        <Input
          id="external-number-input"
          data-cy="external-number-input"
          className={styles['external-number-input']}
          disabled={disabledValue}
          onValueChange={event => {
            setValue(
              field,
              {
                ...values[field],
                value: event.target.value
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            );
          }}
          defaultValue={values[field].value}
          touched
          i18n_input_errorMessage={errors[field] && (errors[field].message || 'error')}
          placeholder={t('callflows.forwardingField.longNumberPlaceholder')}
        />
      </div>
    </div>
  );

  const radioOnChange = (type: string) => {
    setValue(
      field,
      {
        ...values[field],
        type,
        value: '',
        text: ''
      },
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );
  };

  return (
    <div
      className={classnames(styles['field-group-section'], containerStyle)}
      data-cy={`forwarding-field-${field}`}
    >
      <div className={styles['field-section']}>
        <div className={styles.title}>{title}</div>
        {descriptionMsg && <div className={styles.description}>{descriptionMsg}</div>}
      </div>
      <div className={styles['forwarding-selection']}>
        <div className={styles['wide-detail']}>
          <div role="group" data-cy={`acd-forwarding-radio-button-${field}`}>
            <RadioGroup value={fieldValue}>
              {!noEndTransfer && (
                <Radio
                  disabled={disabledValue}
                  name={`${field}_options`}
                  label={t('callflows.acdForwardingField.noTransfer')}
                  value="NO_TRANSFER"
                  onChange={() => radioOnChange(NO_TRANSFER)}
                />
              )}
              <Radio
                disabled={disabledValue}
                name={`${field}_options`}
                label={t('callflows.acdForwardingField.transferEntVM')}
                value="TRANSFER_ENT_VM"
                onChange={() => radioOnChange(TRANSFER_ENT_VM)}
              />
              <Radio
                disabled={disabledValue}
                name={`${field}_options`}
                label={t('callflows.acdForwardingField.transferExternal')}
                value="TRANSFER_EXTERNAL"
                onChange={() => radioOnChange(TRANSFER_EXTERNAL)}
              />
              <Radio
                disabled={disabledValue}
                name={`${field}_options`}
                label={
                  <div className={styles['label-container']}>
                    {t('callflows.acdForwardingField.transferInternal')}
                    <div className={styles.tooltip}>
                      <Tooltip>{t('callflows.acdForwardingField.transferInternalTooltip')}</Tooltip>
                    </div>
                  </div>
                }
                value="TRANSFER_INTERNAL"
                onChange={() => radioOnChange(TRANSFER_INTERNAL)}
              />
            </RadioGroup>
            {isExternalNumber(values[field].type) && numberInputField}
            {fieldValue === 'TRANSFER_INTERNAL' && (
              <div className={styles['input-wrapper']}>
                {showLoadingSpinner && <LoadingSpinner className={styles['loading-spinner']} />}
                <Combobox
                  disabled={disabledValue}
                  id={`search_${values[field].type}`}
                  selectedValue={values[field].value}
                  items={searchResults}
                  i18n_combobox_helpText={t('callflows.acdForwardingField.transferInternalInfo')}
                  i18n_combobox_errorMessage={
                    errors[field] ? t('callflows.acdForwardingField.transferInternalError') : ''
                  }
                  onOptionsShow={() => {
                    fetchDirectories('');
                  }}
                  onValueSelect={element => {
                    if (element.dataset) {
                      setValue(
                        field,
                        {
                          ...values[field],
                          value: element.dataset.value,
                          text: element.innerText
                        },
                        {
                          shouldValidate: true,
                          shouldDirty: true
                        }
                      );
                    }
                  }}
                  onValueChange={value => {
                    setValue(
                      field,
                      {
                        ...values[field],
                        value: ''
                      },
                      {
                        shouldValidate: true,
                        shouldDirty: true
                      }
                    );
                    fetchDirectories(value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ACDForwardingField;
