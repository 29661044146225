// @flow

import classnames from 'classnames';
import React, { type Element } from 'react';
import IconEditRegular from '@design-system/component-library/src/components/Icon/lib/IconEditRegular';
import { type TranslateT } from '../../commonTypes';
import styles from './EditPencil.module.scss';

export type PropsT = {|
  translate: TranslateT<>,
  id: string,
  onSave: () => void,
  onCancel: () => void,
  onEdit: () => void,
  disabled: boolean,
  status: 'view' | 'edit' | 'touched' | 'saving' | 'save-success'
|};

const editButton = (props: *): Element<'div'> => {
  const { onEdit, translate, disabled, id } = props;
  return (
    <div id={id} className={classnames(styles.container, styles['view-mode'])}>
      <div
        className={classnames({
          [styles['modify-button']]: true,
          [styles.disabled]: disabled
        })}
      >
        <div
          id="edit-pencil-edit"
          className={classnames(styles['edit-message'])}
          onClick={disabled ? null : onEdit}
          role="button"
          tabIndex="0"
          onKeyPress={disabled ? null : onEdit}
        >
          <IconEditRegular />
          <div className={styles['modify-button-text']}>
            <span className={styles['edit-message']}>
              {translate('components.editPencil.edit')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const saveCancelButton = (props: *): Element<'div'> => {
  const { status, onSave, onCancel, translate } = props;

  const cancelButton = (
    <div
      className={styles['cancel-button']}
      key="cancel-people-edit-button"
      id="cancel-people-edit"
    >
      <div
        id="edit-pencil-cancel"
        className={classnames(
          'ea-icon',
          'ea-icon--cross',
          'ea-icon--font-reset',
          styles['edit-cancel']
        )}
        onClick={onCancel}
        role="button"
        tabIndex="0"
        onKeyPress={onCancel}
      >
        <div className={styles['cancel-button-text']}>
          {translate('components.editPencil.cancel')}
        </div>
      </div>
    </div>
  );

  const saveButton = (
    <div className={styles['save-button']} key="save-button">
      <div
        id="edit-pencil-save"
        className={classnames('ea-icon', 'ea-icon--check', 'ea-icon--font-reset')}
        onClick={onSave}
        role="button"
        tabIndex="0"
        onKeyPress={onSave}
      >
        <div className={styles['save-button-text']}>
          <span className={styles['save-message']}>{translate('components.editPencil.save')}</span>
        </div>
      </div>
    </div>
  );

  const savingIndicator = (
    <div className={styles['saving-indicator']}>
      <span className={classnames('ea-loading')} />
    </div>
  );

  const saveSuccessIndicator = (
    <div className={styles['save-success-indicator']}>
      <span className="ea-icon ea-icon--check" />
    </div>
  );

  return (
    <div className={classnames(styles.container, styles['edit-mode'])}>
      {status === 'edit' && cancelButton}
      {status === 'touched' && [cancelButton, saveButton]}
      {status === 'saving' && savingIndicator}
      {status === 'save-success' && saveSuccessIndicator}
    </div>
  );
};

export const EditPencil = (props: PropsT): Element<'div'> =>
  props.status === 'view' ? editButton(props) : saveCancelButton(props);

export default EditPencil;
