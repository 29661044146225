import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowUpFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m19.9 14.7-6.6-6.9c-.7-.7-1.8-.7-2.5 0l-6.6 6.9c-.5.5-.5 1.3-.1 1.8s1.3.5 1.8.1l.1-.1 6.1-6.3 6.1 6.3c.5.5 1.3.5 1.8 0 .4-.6.4-1.3-.1-1.8"/></svg>)}
    />
  );
}
  