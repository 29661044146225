import React from 'react';
import PropTypes from 'prop-types';

import allIcons from './lib';
import ALL_COLORS from '../../utils/colors';

function Icon(
  {
    icon = null,
    type = 'regular',
    size = 'm',
    color = null,
    ...rest
  }
) {
  const IconComponent = allIcons[`${icon}--${type}`];

  return (
    IconComponent ?
    <IconComponent
      color={color}
      size={size}
      {...rest}
    /> :
    null
  )
}

Icon.propTypes = {
  /**
   *  ID of icon to request
   */
  icon: PropTypes.string,
  /**
   * Type of icon outline
   */
  type: PropTypes.oneOf(['light', 'regular', 'filled']),
  /**
   * Size of icon
   */
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'fluid']),
  /**
   * Color of icon. One of Elisa design system's colors. Available colors: https://designsystem-storybook-pub.csf.elisa.fi/?path=/story/colors--page
   */
  color: PropTypes.oneOf(ALL_COLORS),
}

export default Icon;
