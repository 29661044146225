// @flow

import reducer from './userReducer';
import operations from './userOperations';
import * as selectors from './userSelectors';

import type { UserStateT, PasswordResetPayloadT } from './userTypes';

import type {
  RetrieveFnT,
  GetUsersFnT,
  SearchFnT,
  RetrieveCollectionByIdsFnT
} from './userOperations';

export default reducer.rootReducer;
export { operations, selectors };
export type {
  RetrieveCollectionByIdsFnT,
  UserStateT,
  PasswordResetPayloadT,
  RetrieveFnT,
  GetUsersFnT,
  SearchFnT
};
