// @flow
import * as React from 'react';
import classnames from 'classnames';
import Truncate from 'react-truncate-markup';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ActionButton from '../../../components/Button/ActionButton';
import type { XlsxRowDataT, ImportOptionsT } from './importUtils';
import styles from './ImportServiceRow.module.scss';

type PropsT = {|
  importOptions: ImportOptionsT,
  dataRow: XlsxRowDataT,
  index: number,
  onRowClick: XlsxRowDataT => Promise<void>,
  isLoading: boolean,
  isDisabled: boolean,
  rowStatusLine: ?string,
  updateImportOptions: *
|};

const ImportServiceRow = (props: PropsT): React.Element<'div'> | null => {
  const {
    importOptions,
    dataRow,
    index,
    onRowClick,
    isLoading,
    isDisabled,
    rowStatusLine,
    updateImportOptions
  } = props;

  const { t } = useTranslation();
  const [rowKey, setRowKey] = useState(0);

  useEffect(() => {
    setRowKey(new Date().getTime());
  }, [importOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return importOptions[dataRow.addressNumber] ? (
    <div className={classnames(styles.row, index % 2 === 0 ? styles.even : styles.odd)}>
      <div>
        <Truncate lines={1}>
          <span>
            {dataRow.addressNumber}, {dataRow.label}
          </span>
        </Truncate>
      </div>
      <div className={styles.fields} key={rowKey}>
        <div>
          <Checkbox
            id={`pstn-${dataRow.addressNumber}`}
            checked={importOptions[dataRow.addressNumber].includePstn}
            name={`pstn-${dataRow.addressNumber}`}
            onChange={() => updateImportOptions(dataRow.addressNumber, 'includePstn')}
          />
        </div>
        <div>
          <Checkbox
            id={`member-${dataRow.addressNumber}`}
            checked={importOptions[dataRow.addressNumber].includeMembers}
            name={`member-${dataRow.addressNumber}`}
            onChange={() => updateImportOptions(dataRow.addressNumber, 'includeMembers')}
          />
        </div>
        <div>
          <Checkbox
            id={`calendar-${dataRow.addressNumber}`}
            checked={importOptions[dataRow.addressNumber].includeCalendar}
            name={`calendar-${dataRow.addressNumber}`}
            onChange={() => updateImportOptions(dataRow.addressNumber, 'includeCalendar')}
          />
        </div>
        <div>
          <Checkbox
            id={`forwarding-${dataRow.addressNumber}`}
            checked={importOptions[dataRow.addressNumber].includeForwardings}
            name={`forwarding-${dataRow.addressNumber}`}
            onChange={() => updateImportOptions(dataRow.addressNumber, 'includeForwardings')}
          />
        </div>
        <div>
          <Checkbox
            id={`audio-${dataRow.addressNumber}`}
            checked={importOptions[dataRow.addressNumber].includeAudio}
            name={`audio-${dataRow.addressNumber}`}
            onChange={() => updateImportOptions(dataRow.addressNumber, 'includeAudio')}
          />
        </div>
        <div>
          <Checkbox
            id={`other-${dataRow.addressNumber}`}
            checked={importOptions[dataRow.addressNumber].includeOther}
            name={`other-${dataRow.addressNumber}`}
            onChange={() => updateImportOptions(dataRow.addressNumber, 'includeOther')}
          />
        </div>
      </div>
      <div>
        <ActionButton
          label={t('importServices.importButton')}
          className={styles['import-button']}
          id={`import-service-${dataRow.addressNumber}`}
          onClickAction={async e => {
            e.preventDefault();
            await onRowClick(dataRow);
          }}
          loading={isLoading || false}
          disabled={isDisabled || false}
        />
        <span>{rowStatusLine}</span>
      </div>
    </div>
  ) : null;
};

export default ImportServiceRow;
