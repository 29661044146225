import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRecruitmentRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.53 22.47-5.288-5.286a10.3 10.3 0 0 0 1.75-2.856 10.18 10.18 0 0 0-.072-7.839A10.17 10.17 0 0 0 14.327.997a10.18 10.18 0 0 0-7.838.072C3.971 2.139 2.021 4.125.997 6.662s-.999 5.321.072 7.838c1.656 3.894 5.455 6.235 9.441 6.235 1.333 0 2.688-.262 3.991-.815.992-.422 1.891-.99 2.685-1.673l5.285 5.283a.748.748 0 1 0 1.059-1.06m-9.616-3.931c-4.437 1.887-9.58-.191-11.465-4.627a8.68 8.68 0 0 1-.062-6.689 8.68 8.68 0 0 1 4.688-4.774 8.7 8.7 0 0 1 3.418-.701c1.107 0 2.214.213 3.272.64a8.68 8.68 0 0 1 4.773 4.688 8.68 8.68 0 0 1 .062 6.69 8.68 8.68 0 0 1-4.686 4.773m-3.42-7.795c2.066 0 3.748-1.681 3.748-3.748s-1.682-3.748-3.748-3.748-3.748 1.681-3.748 3.748 1.681 3.748 3.748 3.748m0-5.996c1.24 0 2.248 1.008 2.248 2.248s-1.008 2.248-2.248 2.248-2.248-1.008-2.248-2.248 1.008-2.248 2.248-2.248m4.929 10.496a.75.75 0 1 1-1.416.494 3.73 3.73 0 0 0-4.739-2.289 3.7 3.7 0 0 0-2.289 2.289c-.135.392-.563.597-.955.461s-.598-.563-.461-.955a5.2 5.2 0 0 1 3.21-3.211 5.226 5.226 0 0 1 6.65 3.211"/></svg>)}
    />
  );
}
  