// @flow

/* eslint-disable react/no-unused-prop-types */

import classNames from 'classnames';

import Truncate from 'react-truncate-markup';
import React, { type Element } from 'react';
import HighlightText from '../../scenes/users/SearchMatchRow/HighlightText';

import styles from './SmallUserTableRow.module.scss';

export type SmallUserTableRowItemT = {
  id: string,
  rowId: string,
  rowClasses?: string[],
  firstName: string,
  lastName: string,
  userType: string,
  title: string,
  addressNumber?: string,
  phoneNumbers?: string,
  searchTerm?: string,
  avatar: *,
  extraLine?: Element<*>
};

export type OnSelectFnT = SmallUserTableRowItemT => *;

export type PropsT = {
  rowItem: SmallUserTableRowItemT,
  rowExtraLine?: ?Element<*>,
  onSelect: ?OnSelectFnT
};

const SmallUserTableRow = ({ onSelect, rowItem, rowExtraLine }: PropsT): Element<'div'> => {
  const handleSelect = () => {
    if (onSelect) {
      onSelect(rowItem);
    }
  };
  const fullName = [rowItem.firstName, rowItem.lastName].join(' ').trim();
  return (
    <div
      id={rowItem.rowId}
      className={classNames(styles.row, ...(rowItem.rowClasses || []))}
      onClick={handleSelect}
      onKeyPress={handleSelect}
      tabIndex={0}
      role="button"
    >
      {rowItem.avatar}
      <div
        className={classNames(styles['text-area'], {
          [`${styles['text-area--empty-title']}`]: !rowItem.title
        })}
      >
        <span className={styles['name-field']}>
          {rowItem.searchTerm ? (
            <HighlightText text={fullName} highlight={rowItem.searchTerm} />
          ) : (
            <Truncate lines={1}>
              <span>{fullName}</span>
            </Truncate>
          )}
        </span>
        {rowExtraLine && <div id={`extra-line-${rowItem.rowId}`}>{rowExtraLine}</div>}
        {rowItem.phoneNumbers ? (
          <div className={styles['info-field']}>
            {rowItem.searchTerm ? (
              <HighlightText text={rowItem.phoneNumbers} highlight={rowItem.searchTerm} />
            ) : (
              rowItem.phoneNumbers
            )}
          </div>
        ) : (
          rowItem.addressNumber && (
            <div className={styles['info-field']}>
              {rowItem.searchTerm ? (
                <HighlightText text={rowItem.addressNumber} highlight={rowItem.searchTerm} />
              ) : (
                rowItem.addressNumber
              )}
            </div>
          )
        )}
        {rowItem.title && (
          <div className={styles['info-field']}>
            {rowItem.searchTerm ? (
              <HighlightText text={rowItem.title} highlight={rowItem.searchTerm} />
            ) : (
              rowItem.title
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SmallUserTableRow;
