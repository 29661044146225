import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFacebookFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 1.5v21c0 .8-.7 1.5-1.5 1.5h-5.7c-.3 0-.5-.2-.5-.5v-8.2c0-.3.2-.5.5-.5h2.1c.3 0 .5-.2.5-.5l.4-3.1c0-.1 0-.3-.1-.4s-.2-.2-.4-.2h-2.5c-.3 0-.5-.2-.5-.4v-.6c0-1 .8-1.7 1.7-1.7h1.9c.3 0 .5-.2.5-.5V4.3c0-.3-.2-.5-.5-.5H18c-3.2 0-5.8 2.6-5.8 5.8v.6c0 .3-.2.5-.5.5H9.6c-.3 0-.5.2-.5.5v3.1c0 .3.2.5.5.5h2.1c.3 0 .5.2.5.5v8.2c0 .3-.2.5-.5.5H1.5C.7 24 0 23.3 0 22.5v-21C0 .7.7 0 1.5 0h21c.8 0 1.5.7 1.5 1.5"/></svg>)}
    />
  );
}
  