import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWebsiteLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 2H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2M2 3h20a1 1 0 0 1 1 1v4H1V4a1 1 0 0 1 1-1m20 18H2a1 1 0 0 1-1-1V9h22v11a1 1 0 0 1-1 1M3.5 6.25a.75.75 0 1 0-.75-.75.76.76 0 0 0 .75.75m3 0a.75.75 0 1 0-.75-.75.76.76 0 0 0 .75.75m3 0a.75.75 0 1 0-.75-.75.76.76 0 0 0 .75.75"/></svg>)}
    />
  );
}
  