// @flow
import React, { type Element, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import UserSelectorComponent from '../../../../../components/UserSelector/UserSelector';
import type { InternalUserStateEntityT } from '../../../../../ducks/entities/user/userTypes';
import useAcdAgents from '../../../callFlowGrid/details/acd/useAcdAgents';
import styles from './UserSelector.module.scss';

export type PropsT = {|
  field: string,
  title: string,
  description: string,
  enterpriseId: string,
  shouldValidate?: boolean,
  maxSizesPatterns: {
    cyclic: number,
    unisson: number,
    sequential: number,
    oldestIdle: number,
    pickUp: number
  }
|};

export const UserSelector = (props: PropsT): Element<'div'> => {
  const { field, title, description, enterpriseId, shouldValidate, maxSizesPatterns } = props;

  // State
  const [searchTerm, setSearchTerm] = useState('');

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();

  const initialGroupMembers: InternalUserStateEntityT[] = values[field];
  const { acdAgents, isLoadingAcdAgents } = useAcdAgents(enterpriseId, searchTerm);

  const maxSelectedFn = formValues => maxSizesPatterns[formValues.ringPattern];
  return (
    <div className={styles.container}>
      <div className={styles['field-section']}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles['field-section']}>
        <div id="member-selector-box" className={styles['selector-input']}>
          <UserSelectorComponent
            searchResults={acdAgents}
            hideButtons
            isLoadingSearchResults={isLoadingAcdAgents}
            onSearchTermChange={newSearchTerm => setSearchTerm(newSearchTerm)}
            onSearchTermClear={() => setSearchTerm('')}
            onSelectionChange={(selectedUsers: InternalUserStateEntityT[]) => {
              setValue(field, selectedUsers, {
                shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                shouldDirty: true
              });
            }}
            preselection={initialGroupMembers}
            errorMessage={errors[field] ? errors[field].message : ''}
            maxSelected={maxSelectedFn(values)}
            selectedOrderable
            onReOrderUsers={(selectedUsers: InternalUserStateEntityT[]) => {
              setValue(field, selectedUsers, {
                shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                shouldDirty: true
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSelector;
