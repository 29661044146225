/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/css';
import styles from "./SelectGroup.module.scss";
import IconCheckFilled from '../Icon/lib/IconCheckFilled';
import { useIdWithFallback } from '../../utils/hooks';
import SelectGroupContext from './SelectGroupContext';
import {KEY_SPACE, KEY_RETURN} from '../../utils/keycodes';

// eslint-disable-next-line react/function-component-definition
const Product = (
  {
    id = null,
    forceselection = false,
    multiselect = false,
    type = 'radio',
    product = null,
    name = null,
    autoFocus = false,
    onCheck = () => {},
    onClick = () => {},
    inputProps = null,
    forceselectionWarningFunction = () => {},
    ariaDescribedby = null,
  }
) => {
  const htmlId = useIdWithFallback('dsSelectGroupProduct-', id);
  const {selectedValues, setSelectedValues} = useContext(SelectGroupContext);
  const [isChecked, setIsChecked] = useState(selectedValues ? selectedValues === product.id || selectedValues.indexOf(product.id) > -1 : false);

  const onValueChange = (e) => {
    const val = e.target.value || e.target.dataset.value;
    if(multiselect) {
      let selected = null;

      // empty selectedValues.[0] === "[]"

      if(typeof selectedValues === "string"){
        selected = JSON.parse(selectedValues);
      }
      else {
        selected = selectedValues;
      }

      const index = selected.indexOf(val);

      if (index > -1) {
        if(!(forceselection && selected.length === 1)){
          selected.splice(index, 1);
          forceselectionWarningFunction(false);
          setIsChecked(false);
        }
        else {
          forceselectionWarningFunction(true);
        }
      }
      else if(selected.indexOf(val) === -1){
        selected.push(val);
        forceselectionWarningFunction(false);
        setIsChecked(true);
      }

      setSelectedValues(JSON.stringify(selected));    
    }
    else {
      setSelectedValues(val);
    }

    if(onCheck) onCheck(e);
  }

  useEffect(() => {
    setIsChecked(selectedValues ? selectedValues === product.id || selectedValues.indexOf(product.id) > -1 : false);
  }, [selectedValues]);

  const classes = classNames([
    styles[`selectgroup__list-item`],
    product.classNames,
    product.disabled ? styles[`selectgroup__list-item--disabled`] : null,
    multiselect && isChecked ? styles[`selectgroup__list-item--selected`] : null,
    !multiselect && selectedValues === product.id ? styles[`selectgroup__list-item--selected`] : null,
  ]);

  const titlePartClass = (product.name || product.title) && product.description ? classNames([styles[`selectgroup__list-item__text__name`], styles[`selectgroup__list-item__text__name--alt`]]) : styles[`selectgroup__list-item__text__name`];

  const iconElement = product.image ? (
    <img className={styles[`selectgroup__list-item__img`]} src={product.image.url} alt={product.name || product.title ? '' : product.image.alt || ''} />
  ) : (
    product.icon && <span className={styles[`selectgroup__list-item__icon`]}>{product.icon}</span>
  );

  const titlePart = (product.name || product.title) && <span className={titlePartClass}>{product.name || product.title}</span>;
  const descriptionPart = product.description && <span className={styles[`selectgroup__list-item__text__price`]}>{product.description}</span>;
  const isDisabled = product.disabled && product.disabled === true;

  return (
    <label
      // tabIndex={isDisabled ? -1 : 0}
      ref={(ref) => autoFocus && !isDisabled && ref?.focus()}
      onKeyDown={(e) => {
        if (e.key === KEY_RETURN || e.key === KEY_SPACE) {
          onValueChange(e);
        }
      }}
      onClick={onClick(product.id)}
      data-value={product.id}
      className={classes}
      htmlFor={htmlId}
    >
      {multiselect === true && (
        <span className={styles[`selectgroup__list-item__checkmark`]}>
          <IconCheckFilled />
        </span>
      )}
      {iconElement}
      {(titlePart || descriptionPart) && (
        <span className={styles[`selectgroup__list-item__text`]}>
          {titlePart}
          {descriptionPart}
        </span>
      )}
      <input
        // tabIndex="-1"
        type={type}
        // role={type}
        title={product.image && !product.name && product.title && !product.description ? product.image.alt : null}
        id={htmlId}
        className={classNames([styles[`selectgroup__list-item__input`]])}
        value={product.id}
        name={name}
        onChange={onValueChange}
        checked={multiselect ? isChecked : selectedValues === product.id}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        aria-describedby={ariaDescribedby}
        // aria-checked={multiselect ? isChecked : selectedValues === product.id}
        {...inputProps}
      />
    </label>
  );
};

export default Product;

Product.propTypes = {
  forceselection: PropTypes.bool,
  onCheck: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string,
  multiselect: PropTypes.bool,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    classNames: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  inputProps: PropTypes.object,
  forceselectionWarningFunction: PropTypes.func,
  ariaDescribedby: PropTypes.string,
};
