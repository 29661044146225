// @flow

import React from 'react';
import classnames from 'classnames';
import ActionButton from '../../../../../components/Button/ActionButton';
import CancelButton from '../../../../../components/Button/CancelButton';
import DeleteButton from '../../../../../components/Button/DeleteButton';
import styles from './FormButtons.module.scss';

type PropsT = {
  id?: string,
  saveDisabled: boolean,
  isUpdating: boolean,
  onSaveLabel: string,
  onCancelLabel: string,
  onDeleteLabel?: string,
  onSave: (SyntheticEvent<HTMLButtonElement>) => void,
  onCancel: (*) => void,
  onDelete?: (SyntheticEvent<HTMLButtonElement>) => void,
  errorMessage: string,
  containerStyle?: string
};

export const FormButtons = (props: PropsT) => {
  const {
    id,
    saveDisabled,
    isUpdating,
    onSaveLabel,
    onCancelLabel,
    onDeleteLabel,
    onSave,
    onCancel,
    onDelete,
    errorMessage,
    containerStyle
  } = props;

  return (
    <div>
      <div className={classnames(styles['button-area'], containerStyle)}>
        <ActionButton
          id={`${id || 'form'}-save-button`}
          label={onSaveLabel}
          onClickAction={onSave}
          disabled={saveDisabled}
          loading={isUpdating}
          className={styles['save-button']}
          type="submit"
        />
        <CancelButton
          id={`${id || 'form'}-cancel-button`}
          data-cy="form-cancel-button"
          className={styles['cancel-button']}
          label={onCancelLabel}
          onClickAction={onCancel}
          hideWhenViewing
        />
        {onDelete && onDeleteLabel && (
          <DeleteButton
            id={`${id || 'form'}-delete-button`}
            data-cy="form-delete-button"
            className={styles['delete-button']}
            label={onDeleteLabel}
            onClickAction={onDelete}
          />
        )}
      </div>
      {errorMessage.length > 0 && <div className={styles['error-message']}>{errorMessage}</div>}
    </div>
  );
};

export default FormButtons;
