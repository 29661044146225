import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRouterFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.158 5.849a.75.75 0 0 1-1.06 1.061 2.253 2.253 0 0 0-3.181 0 .75.75 0 1 1-1.061-1.061 3.754 3.754 0 0 1 5.302 0m-6.364-1.061c.992-.992 2.31-1.538 3.712-1.538s2.721.546 3.713 1.538a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.061c-1.275-1.275-2.97-1.978-4.773-1.978s-3.499.702-4.773 1.978a.75.75 0 1 0 1.061 1.061m6.456 17.187v.275a.75.75 0 0 1-1.5 0V22H5.25v.25a.75.75 0 0 1-1.5 0v-.275C1.663 21.843 0 20.12 0 18c0-2.206 1.794-4 4-4h12.75V9.5a.75.75 0 0 1 1.5 0V14H20c2.206 0 4 1.794 4 4 0 2.12-1.663 3.843-3.75 3.975M6.125 18c0-.62-.505-1.125-1.125-1.125S3.875 17.38 3.875 18 4.38 19.125 5 19.125 6.125 18.62 6.125 18m4 0c0-.62-.505-1.125-1.125-1.125S7.875 17.38 7.875 18 8.38 19.125 9 19.125s1.125-.505 1.125-1.125"/></svg>)}
    />
  );
}
  