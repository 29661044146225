import React from 'react';
import IconContainer from '../IconContainer';

export default function IconYoutubeFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.498 6.155a3.02 3.02 0 0 0-2.122-2.147C19.505 3.5 12 3.5 12 3.5s-7.505 0-9.377.507A3.03 3.03 0 0 0 .502 6.155C0 8.049 0 12 0 12s0 3.951.502 5.845a3.02 3.02 0 0 0 2.122 2.147C4.495 20.5 12 20.5 12 20.5s7.505 0 9.377-.508a3.02 3.02 0 0 0 2.122-2.147C24 15.951 24 12 24 12s0-3.951-.502-5.845M9.545 15.568V8.432L15.818 12z"/></svg>)}
    />
  );
}
  