// @flow

import React, { type Element } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useTranslation } from 'react-i18next';
import fieldValidators from '../../fieldValidators';
import styles from './ContactSelector.module.scss';

export type PropsT = {|
  isSmsSelected: boolean,
  isEmailSelected: boolean,
  handleSmsClick: *,
  handleEmailClick: *,
  handleChangePhoneNumber: (SyntheticInputEvent<HTMLInputElement>) => void,
  handleChangeEmail: (SyntheticInputEvent<HTMLInputElement>) => void,
  phoneNumber: string,
  email: string
|};

export const ContactSelector = (props: PropsT): Element<'div'> => {
  const {
    isSmsSelected,
    isEmailSelected,
    handleSmsClick,
    handleEmailClick,
    handleChangePhoneNumber,
    handleChangeEmail,
    phoneNumber,
    email
  } = props;

  const { t } = useTranslation();
  return (
    <div className={styles['contact-selector']}>
      <RadioGroup value={String(isSmsSelected)}>
        <Radio
          id="contact-selector-send-sms-option"
          name="send-option"
          onChange={handleSmsClick}
          className={styles.radio}
          label={t('contactSelector.sendSMS')}
          value="true"
        />
        <Radio
          id="contact-selector-section-send-email-option"
          name="send-option"
          onChange={handleEmailClick}
          className={styles.radio}
          label={t('contactSelector.sendEmail')}
          value="false"
        />
      </RadioGroup>
      {isSmsSelected && (
        <Input
          id="contact-selector-phoneNumber"
          className={styles['input-field']}
          name="phoneNumber"
          label={`${t('contactSelector.phoneNumber')}`}
          onValueChange={handleChangePhoneNumber}
          i18n_input_errorMessage={
            fieldValidators.phoneNumberValidator(phoneNumber, t('contactSelector.phoneError')) &&
            t('contactSelector.phoneError')
          }
          maxlength={20}
          data-cy="contact-phoneNumber"
          touched
          type="tel"
          defaultValue={phoneNumber}
        />
      )}
      {isEmailSelected && (
        <div className={styles['input-wrapper']}>
          <Input
            id="contact-selector-email"
            name="email"
            label={`${t('contactSelector.email')}`}
            onValueChange={handleChangeEmail}
            className={styles['input-field']}
            i18n_input_errorMessage={
              fieldValidators.commaSeparatedUniqueEmailsValidator(
                email,
                t('contactSelector.emailError')
              ) && t('contactSelector.emailError')
            }
            maxlength={40}
            touched
            required
            type="email"
            defaultValue={email}
          />
        </div>
      )}
    </div>
  );
};

export default ContactSelector;
