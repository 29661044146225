// @flow strict-local
import React, { type Element } from 'react';
import CreateButton from '../../../components/Button/CreateButton';

import styles from './ForwardingsHeader.module.scss';

type PropsT = {|
  title: string,
  description: string,
  buttonText: string,
  onCreateClickAction: () => void,
  singleUserAddressNumber?: string
|};

export const ForwardingsHeader = (props: PropsT): Element<'div'> => {
  const { title, description, buttonText, onCreateClickAction, singleUserAddressNumber } = props;

  return (
    <div id="forwardings-header" className={styles['section-header']}>
      <h4 className={styles['section-title']}>{!singleUserAddressNumber && title}</h4>
      <div className={styles['section-description-area']}>
        <div className={styles['section-description']}>
          {!singleUserAddressNumber && description}
        </div>
        {!singleUserAddressNumber ? (
          <CreateButton
            id="create-callforwarding-button"
            text={buttonText}
            showTextAlways
            onClickAction={onCreateClickAction}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ForwardingsHeader;
