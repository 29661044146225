import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTurvapakettiLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.55.998 12.053 0h-.05L2.456.998a.5.5 0 0 0-.45.499v7.175a18.4 18.4 0 0 0 1.3 7.295c1.589 3.712 4.539 5.987 8.467 7.983a.55.55 0 0 0 .46 0C18.231 20.906 22 17.294 22 8.682V1.457a.5.5 0 0 0-.45-.459M21 4.281 6.365 18.9a11.5 11.5 0 0 1-1.869-2.714l14.505-14.48 1.999.2zM3.006 8.632V1.906l8.997-.908 5.708.579L4.066 15.208a18.1 18.1 0 0 1-1.06-6.576m8.997 14.26a21.7 21.7 0 0 1-4.938-3.283L21 5.698v2.934c0 8.033-3.299 11.326-8.997 14.26"/></svg>)}
    />
  );
}
  