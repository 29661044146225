import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCreditCardRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.1 3.75H2.9c-1.186 0-2.15.948-2.15 2.114v12.273c0 1.165.964 2.113 2.15 2.113h18.2c1.186 0 2.15-.948 2.15-2.113V5.864c0-1.166-.965-2.114-2.15-2.114m.65 14.387c0 .338-.292.613-.65.613H2.9c-.358 0-.65-.275-.65-.613V5.864c0-.338.292-.614.65-.614h18.2c.358 0 .65.275.65.614zm-7-10.387c-1.027 0-1.99.375-2.753 1.03a4.2 4.2 0 0 0-2.741-1.03A4.255 4.255 0 0 0 5.006 12a4.255 4.255 0 0 0 4.25 4.25 4.2 4.2 0 0 0 2.746-1.034 4.23 4.23 0 0 0 2.748 1.034A4.254 4.254 0 0 0 19 12a4.255 4.255 0 0 0-4.25-4.25m-5.495 7c-1.517 0-2.75-1.233-2.75-2.75s1.233-2.75 2.75-2.75 2.75 1.233 2.75 2.75-1.234 2.75-2.75 2.75m5.495 0a2.73 2.73 0 0 1-1.801-.69 4.2 4.2 0 0 0 .556-2.06c0-.752-.213-1.449-.557-2.063a2.73 2.73 0 0 1 1.802-.687c1.517 0 2.75 1.233 2.75 2.75s-1.233 2.75-2.75 2.75"/></svg>)}
    />
  );
}
  