// @flow
import React, { type Element } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  createUpdateEditStatus,
  createUpdateOpenStatus,
  createUpdateSelectedStatus
} from '../../../../ducks/ui/callflow/callflowUiActions';
import Dismiss from '../../../../components/Button/Dismiss';

import styles from './DetailsContainer.module.scss';

export type PropsT = {
  serviceId: string,
  content: *,
  singleView?: boolean
};

export const DetailsContainer = (props: PropsT): Element<'div'> => {
  const { serviceId, content, singleView } = props;

  // redux
  const dispatch = useDispatch();

  const closeView = () => {
    dispatch(createUpdateOpenStatus(serviceId, false));
    dispatch(createUpdateEditStatus(serviceId, false));
    dispatch(createUpdateSelectedStatus(serviceId, null, null));
  };

  return (
    <div
      className={classnames(styles['detail-area'], {
        [`${styles['detail-area--wide']}`]: singleView
      })}
      data-cy={`details-container-${serviceId}`}
    >
      <div>
        <Dismiss
          id="close-user-details-button"
          onClose={() => {
            closeView();
          }}
        />
      </div>
      {content}
    </div>
  );
};

export default DetailsContainer;
