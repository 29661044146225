// @flow

import React, { type Element, type ChildrenArray } from 'react';
import styles from './UserCardContainer.module.scss';

type PropsT = {
  children?: ChildrenArray<Element<*>>
};

const UserCardContainer = ({ children }: PropsT): Element<'div'> => (
  <div className={styles['user-card-container']}>{children}</div>
);

UserCardContainer.defaultProps = {
  children: []
};

export default UserCardContainer;
