import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileDocFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M15 5.5V1c0-.1.1-.2.2-.2s.2 0 .3.1L20 5.5c.1.1.1.2.1.3s-.1.2-.2.2h-4.5c-.3-.1-.4-.2-.4-.5m-3 10.7c-.7 0-1.2.6-1.2 1.4S11.3 19 12 19s1.2-.6 1.2-1.4-.5-1.4-1.2-1.4m-5.4.1h-.4V19h.5c1 0 1.4-.6 1.4-1.4s-.5-1.3-1.5-1.3m14.2-8.6v14.5c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h8.4c.3 0 .5.2.5.5v5c0 .9.7 1.6 1.6 1.6h5c.3.1.5.3.5.6M9.2 17.6c0-1.3-.9-2.3-2.8-2.3-.5 0-.8 0-1.3.1v4.4c.6 0 .9.1 1.4.1 1.9 0 2.7-1 2.7-2.3m5.1 0c0-1.3-.9-2.3-2.3-2.3s-2.3 1-2.3 2.3c0 1.4.8 2.4 2.3 2.4 1.3 0 2.3-1 2.3-2.4m4.4 1.9-.3-.9c-.4.3-.8.4-1.2.4-.8 0-1.4-.5-1.4-1.4 0-.8.5-1.4 1.4-1.4.4 0 .7.1 1.1.3l.3-.9c-.4-.2-.9-.4-1.4-.4-1.5 0-2.5 1-2.5 2.3s1 2.3 2.5 2.3c.6.2 1.1 0 1.5-.3" clipRule="evenodd"/></svg>)}
    />
  );
}
  