import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDraftFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.495 18.25h4.361c.222 0 .333.268.176.425l-4.361 4.363a.249.249 0 0 1-.425-.176V18.5a.25.25 0 0 1 .249-.25m-1.749.25c0-.966.783-1.75 1.749-1.75h5.002a.503.503 0 0 0 .503-.503l-.006-14.748A1.5 1.5 0 0 0 19.494 0H4.499A1.5 1.5 0 0 0 3 1.5v21A1.5 1.5 0 0 0 4.499 24h8.744a.503.503 0 0 0 .503-.503z"/></svg>)}
    />
  );
}
  