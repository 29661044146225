// @flow strict-local

import React, { type Element } from 'react';
import { type GroupMemberT } from '../../../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import { type InternalUserStateEntityT } from '../../../../../../../ducks/entities/user/userTypes';
import delayBooleanTransitionToTrue from '../../../../../../../components/delayBooleanTransitionToTrue';
import { SmallLoadingIndicator } from '../../../../../../../components/SmallLoadingIndicator/SmallLoadingIndicator';
import { ErrorWithRetry } from '../../../../../../../components/ErrorWithRetry/ErrorWithRetry';
import styles from './Members.module.scss';

export type PropsT = {|
  members: GroupMemberT[],
  users: InternalUserStateEntityT[],
  noUsersText: string,
  loadingText: string,
  errorText: string,
  errorButtonText: string,
  isLoading: boolean,
  showLoadingIndicator: boolean,
  hasError: boolean,
  onRetry: () => * // flowlint-line deprecated-type:off
|};

type ReturnT = Array<Element<'div'>> | null;

export const Members = (props: PropsT): ReturnT => {
  if (props.isLoading) {
    return props.showLoadingIndicator
      ? [
          <div className={styles['message-container']} key="message-container">
            <SmallLoadingIndicator text={props.loadingText} />
          </div>
        ]
      : null;
  }

  if (props.hasError) {
    return [
      <div className={styles['message-container']} key="message-container">
        <ErrorWithRetry
          text={props.errorText}
          onRetry={props.onRetry}
          buttonText={props.errorButtonText}
        />
      </div>
    ];
  }

  return props.members.length > 0
    ? props.members.map<Element<'div'>>((member, index) => {
        const memberUser = props.users.find(user => user.id === member.id);
        return (
          <div key={member.id}>
            {index + 1}.{memberUser && memberUser.userName} ({member.addressNumber})
          </div>
        );
      })
    : [<div key="empty-user-element">{props.noUsersText}</div>];
};

export default delayBooleanTransitionToTrue<PropsT>({
  delay: 500,
  propName: 'showLoadingIndicator'
})(Members);
