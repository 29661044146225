import React from 'react';
import IconContainer from '../IconContainer';

export default function IconKeyLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.991 22.99a5.74 5.74 0 0 1-4.061-1.669c-1.999-1.997-2.233-5.212-.545-7.479 1.613-2.165 4.558-2.897 6.985-1.786L18.844 1.583a2 2 0 0 1 2.824.003c.377.378.584.88.583 1.414s-.21 1.035-.588 1.411l-.701.699 1.915 1.917a2.006 2.006 0 0 1-.019 2.803 2.004 2.004 0 0 1-2.797.024l-1.923-1.922-1.407 1.408 1.917 1.915.034.038a1.996 1.996 0 0 1-2.856 2.787l-1.914-1.919-2.72 2.718c1.111 2.427.38 5.371-1.784 6.984a5.7 5.7 0 0 1-3.417 1.127m.008-10.448a4.76 4.76 0 0 0-3.812 1.898c-1.394 1.871-1.2 4.524.45 6.174s4.305 1.841 6.174.447a4.76 4.76 0 0 0 1.333-6.045.5.5 0 0 1 .086-.592l3.329-3.326a.5.5 0 0 1 .708.001l2.267 2.272a1 1 0 0 0 .96.258.998.998 0 0 0 .416-1.699l-2.24-2.238a.5.5 0 0 1 0-.708l2.114-2.115a.514.514 0 0 1 .707 0l2.271 2.27c.382.369 1.007.363 1.389-.019s.387-1.007.013-1.396L19.9 5.462a.5.5 0 0 1 .001-.708l1.057-1.053a.995.995 0 0 0 .002-1.41 1 1 0 0 0-1.41-.002L8.823 13.018a.495.495 0 0 1-.591.086 4.7 4.7 0 0 0-2.233-.562m-.008 7.084c-1.306 0-2.369-1.062-2.369-2.369s1.062-2.369 2.369-2.369S8.36 15.95 8.36 17.257s-1.063 2.369-2.369 2.369m0-3.738c-.755 0-1.369.614-1.369 1.369s.614 1.369 1.369 1.369 1.369-.614 1.369-1.369-.614-1.369-1.369-1.369"/></svg>)}
    />
  );
}
  