import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrangeAscLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.854 7.646a.5.5 0 0 1-.708.708L6 5.207V20a.5.5 0 0 1-1 0V5.207L1.854 8.354a.5.5 0 0 1-.707-.707l4-4a.5.5 0 0 1 .707 0zM13.5 4.5h9a.5.5 0 0 0 0-1h-9a.5.5 0 0 0 0 1m9 3h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1m0 4h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1m0 4h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1m0 4h-9a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1"/></svg>)}
    />
  );
}
  