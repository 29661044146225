// @flow

import React, { type Element, useEffect, useState } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import { useTranslation } from 'react-i18next';
import axios, { $AxiosXHR, CancelToken } from 'axios';
import HTTP from 'http-status-codes';
import { useSelector } from 'react-redux';
import type { Canceler } from 'axios';
import EditCalendarForm from './EditCalendarForm';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import type { CallFlowCalendarEntityT } from '../../../ducks/entities/calendar/calendarTypes';
import CalendarSection from '../components/view/children/calendar/CalendarSection/CalendarSection';
import ConfirmButton from '../../../components/Button/ConfirmButton';
import { enterpriseCalendarRegExp } from '../../../helpers';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

import styles from './CalendarContent.module.scss';

export type PropsT = {|
  isLoadingCalendar: boolean,
  calendars: CallFlowCalendarEntityT[],
  searchText: string,
  editModes: {},
  enterpriseId: string,
  updateCalendar: (*, *) => Promise<void>,
  toggleEditMode: string => void
|};

const PROCESS_CHECK_DELAY = 1000;

const CalendarContent = (props: PropsT): Element<typeof CenterHorizontally | typeof Accordion> => {
  const {
    isLoadingCalendar,
    calendars,
    searchText,
    editModes,
    enterpriseId,
    updateCalendar,
    toggleEditMode
  } = props;
  const isDefaultCalendar = ownerAdmtiveDomainId => ownerAdmtiveDomainId === '0.';
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const { t } = useTranslation();
  const cancelRequest = React.useRef<Canceler>();
  const [uuid, setUuid] = useState();
  const [calendarServices, setCalendarServices] = useState([]);
  const [isLoadingServicesData, setIsLoadingServicesData] = useState(false);

  const calendarDetailsView = (cal, removeEditButton) => (
    <>
      <CalendarSection
        calendar={cal}
        calendarServices={calendarServices}
        isLoadingServicesData={isLoadingServicesData}
      />
      {!removeEditButton && (
        <ConfirmButton
          id="modify-calendar-button"
          className={styles['modify-button']}
          label={t('calendars.modifyCalendarButton')}
          hideWhenViewing
          onClickAction={() => toggleEditMode(cal.id)}
        />
      )}
    </>
  );

  const loadCalendarServices = async (calendarName: string) => {
    const response: $AxiosXHR<string> = await axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/calendarservices`,
      data: { calendarName },
      headers: createCsrfHeader(currentUser)
    });

    if (response && response.status === HTTP.OK) {
      setUuid(response.data);
    }
  };

  const stopCalendarSearch = () => {
    axios({
      method: 'POST',
      // $FlowFixMe: enterpriseId already null checked
      url: `/api/v1/enterprises/${enterpriseId}/calendarservices/done?uuid=${uuid}`,
      cancelToken: new CancelToken(canceler => {
        cancelRequest.current = canceler;
      }),
      headers: createCsrfHeader(currentUser)
    });
  };

  useEffect(() => {
    let id;
    if (uuid) {
      id = setInterval(async () => {
        try {
          const response = await axios({
            method: 'GET',
            // $FlowFixMe: enterpriseId already null checked
            url: `/api/v1/enterprises/${enterpriseId}/calendarservices/process?uuid=${uuid}`,
            cancelToken: new CancelToken(canceler => {
              cancelRequest.current = canceler;
            })
          });
          if (response) {
            if (response.data.searched === response.data.toBeSearched) {
              setCalendarServices(response.data.calendarServiceItems);
              setIsLoadingServicesData(false);
              clearInterval(id);
              stopCalendarSearch();
            }
          }
        } catch (e) {
          console.log(e);
        }
      }, PROCESS_CHECK_DELAY);
    }
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoadingCalendar ? (
    <CenterHorizontally>
      <LoadingSpinner />
    </CenterHorizontally>
  ) : (
    <Accordion className={styles.header}>
      {calendars &&
        calendars
          .sort((a, b) =>
            a.name
              .replace(enterpriseCalendarRegExp, '')
              .localeCompare(b.name.replace(enterpriseCalendarRegExp, ''))
          )
          .filter(
            cal =>
              !searchText ||
              cal.name
                .replace(enterpriseCalendarRegExp, '')
                .toUpperCase()
                .includes(searchText.toUpperCase())
          )
          .map(
            cal =>
              cal && (
                <AccordionItem
                  id={`calendar-${cal.id}`}
                  key={`calendar-${cal.id}`}
                  heading={`${cal.name.replace(enterpriseCalendarRegExp, '')} 
                                ${
                                  isDefaultCalendar(cal.ownerAdmtiveDomainId)
                                    ? t('calendars.cannotEditTitle')
                                    : ''
                                }`}
                  onOpen={() => {
                    setIsLoadingServicesData(true);
                    loadCalendarServices(cal.name);
                  }}
                >
                  <div className={styles['calendar-row']}>
                    {isDefaultCalendar(cal.ownerAdmtiveDomainId) && (
                      <div className={styles['info-row']}>{t('calendars.cannotEditInfo')}</div>
                    )}
                    {editModes[cal.id] ? (
                      <EditCalendarForm
                        enterpriseId={enterpriseId}
                        calendar={cal}
                        onSaveForm={formData => updateCalendar(cal, formData)}
                        onCancel={event => {
                          if (event) {
                            event.preventDefault();
                          }
                          toggleEditMode(cal.id);
                        }}
                      />
                    ) : (
                      calendarDetailsView(cal, isDefaultCalendar(cal.ownerAdmtiveDomainId))
                    )}
                  </div>
                </AccordionItem>
              )
          )}
    </Accordion>
  );
};

export default CalendarContent;
