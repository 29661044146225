import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHelpLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m0 23C5.9 23 1 18.1 1 12S5.9 1 12 1s11 4.9 11 11-4.9 11-11 11m1-6.9c0 .5-.4.9-.9.9s-.9-.4-.9-.9.4-.9.9-.9.9.4.9.9m2.5-5.9c0 1.9-1.6 3.5-3.5 3.5-.3 0-.5-.2-.5-.5s.2-.5.5-.5c1.4 0 2.5-1.1 2.5-2.5S13.4 7.7 12 7.7s-2.5 1.1-2.5 2.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5"/></svg>)}
    />
  );
}
  