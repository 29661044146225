// @flow
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import type { AxiosPromise } from 'axios';
import axios, { CancelToken, CancelTokenSource } from 'axios';

export type AcdAgentsResponseT = {|
  acdAgents: *,
  isLoadingAcdAgents: boolean
|};

let requestUsersCancelTokenSource: CancelTokenSource;
const useAcdAgents = (enterpriseId: string, searchTerm: string): AcdAgentsResponseT => {
  // state
  const [isLoadingAcdAgents, setIsLoadingAcdAgents] = useState<boolean>(true);
  const [acdAgents, setAcdAgents] = useState<*>([]);

  const fetchData = async () => {
    if (enterpriseId) {
      try {
        const response: AxiosPromise<*> = axios({
          url: `/api/v1/enterprises/${enterpriseId}/acdagents?search=${searchTerm ||
            '*'}&page=1&size=10000`,
          cancelToken: requestUsersCancelTokenSource.token
        });
        const { data } = await response;
        if (data && data.results) {
          setAcdAgents(data.results);
        }
      } catch (error) {
        //
      }
      setIsLoadingAcdAgents(false);
    }
  };

  useEffect(() => {
    requestUsersCancelTokenSource = CancelToken.source();
    return () => {
      requestUsersCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoadingAcdAgents(true);
    const delayDebounceFn = setTimeout(async () => {
      fetchData();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [enterpriseId, searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    acdAgents,
    isLoadingAcdAgents
  };
};

export default useAcdAgents;
