// @flow

/* eslint-disable import/prefer-default-export */

import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import { NO_DEPARTMENT } from './departmentUiActions';

export const selectedUsersByEnterpriseAndDepartment = (
  state: StoreStateT,
  enterpriseId: string,
  departmentId: string = NO_DEPARTMENT
): * => R.pathOr([], ['ui', 'department', 'enterprises', enterpriseId, departmentId], state);

export const selectedDirectoriesByEnterpriseAndDepartment = (
  state: StoreStateT,
  enterpriseId: string,
  departmentId: string = NO_DEPARTMENT
): * =>
  R.pathOr([], ['ui', 'department', 'enterprises', enterpriseId, departmentId], state).filter(
    dir => dir.publicInfo
  );
