import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOnDeviceLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.5 0C13.916 0 11 2.916 11 6.5s2.916 6.5 6.5 6.5S24 10.084 24 6.5 21.084 0 17.5 0m0 12C14.468 12 12 9.533 12 6.5S14.468 1 17.5 1 23 3.467 23 6.5 20.532 12 17.5 12m0 2.5a.5.5 0 0 0-.5.5v2H5V2c0-.551.449-1 1-1h5.5a.5.5 0 0 0 0-1H6C4.897 0 4 .897 4 2v20c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-7a.5.5 0 0 0-.5-.5M16 23H6c-.551 0-1-.448-1-1v-4h12v4a1 1 0 0 1-1 1m-4.31-2.79a.75.75 0 1 1-1.22-.24c.27-.28.78-.28 1.06 0 .07.07.13.15.16.24m8.924-15.803a.5.5 0 0 1-.021.707l-4.25 4a.5.5 0 0 1-.697-.01l-1.5-1.5a.5.5 0 0 1 .707-.707l1.157 1.157 3.896-3.667a.5.5 0 0 1 .708.02"/></svg>)}
    />
  );
}
  