// @flow

import React, { type Element } from 'react';
import classNames from 'classnames';
import IconMoreFilled from '@design-system/component-library/src/components/Icon/lib/IconMoreFilled';
import Popover from '@design-system/component-library/src/components/Popover';
import { useTranslation } from 'react-i18next';
import { PhoneLargeAndDown, TabletAndUp } from '../../Responsive';
import { getTextRepresentation, scrollToRef } from '../../helpers';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import type { CallForwardingT } from '../../ducks/entities/callForwarding/callForwardingTypes';
import type { TwoPartForwardingTextRepresentationTypeT } from '../../helpers';
import type { DirectoryStateEntityT } from '../../ducks/entities/directory';
import ForwardingPopOverSelector from '../../scenes/callForwardings/components/ForwardingPopOverSelector';
import type { InternalUserStateEntityT } from '../../ducks/entities/user/userTypes';

import styles from './Destination.module.scss';

export type PropsT = {|
  enterpriseId?: string,
  userForwarding: CallForwardingT,
  showMenuIcon?: boolean,
  user: DirectoryStateEntityT,
  onForwardingActivityChange?: () => Promise<void>,
  onDeleteForwarding?: () => void,
  forceSmall?: boolean,
  singleUser?: InternalUserStateEntityT
|};

export const Destination = (props: PropsT): Element<'div'> => {
  const {
    userForwarding,
    showMenuIcon,
    user,
    forceSmall,
    enterpriseId,
    onForwardingActivityChange,
    onDeleteForwarding,
    singleUser
  } = props;

  const { t } = useTranslation();

  // $FlowFixMe
  const texts: TwoPartForwardingTextRepresentationTypeT = getTextRepresentation(
    userForwarding,
    'twoPart',
    t
  );

  const valueRef = React.createRef();

  const renderSmall = (className?: string) => {
    return (
      <div className={className}>
        <ArrowIcon />
        <span className={styles['text-area']}>{texts.destination}</span>
        {!userForwarding.isEditable && (
          <span
            className={classNames(styles['default-fwd'], {
              [styles['inactive-fwd']]: !userForwarding.isActive
            })}
          >
            {t('forwardingDetails.defaultForwarding')}
          </span>
        )}
      </div>
    );
  };

  if (forceSmall) {
    return renderSmall(styles['small-destination']);
  }

  return (
    <div className={styles['destinations-row']}>
      <PhoneLargeAndDown>{renderSmall()}</PhoneLargeAndDown>
      <TabletAndUp>
        <div ref={valueRef}>
          <span className={styles['text-area']}>{texts.destination}</span>
          {!userForwarding.isEditable && (
            <span
              className={classNames(styles['default-fwd'], {
                [styles['inactive-fwd']]: !userForwarding.isActive
              })}
            >
              {t('forwardingDetails.defaultForwarding')}
            </span>
          )}
        </div>
        {showMenuIcon && enterpriseId && onForwardingActivityChange && onDeleteForwarding && (
          <Popover
            triggerElement={<IconMoreFilled size="m" />}
            placement="left"
            i18n_popover_triggerAriaLabel="Action"
            onOpen={ref => scrollToRef(ref)}
          >
            <ForwardingPopOverSelector
              enterpriseId={enterpriseId}
              activeForwarding={{ forwarding: userForwarding, user }}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
              singleUser={singleUser}
            />
          </Popover>
        )}
      </TabletAndUp>
    </div>
  );
};

export default Destination;
