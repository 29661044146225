// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Canceler } from 'axios';
import { CancelToken } from 'axios';
import * as R from 'ramda';
import { operations as calendarOps } from '../../../ducks/entities/calendar';
import type { CallFlowCalendarEntityT } from '../../../ducks/entities/calendar/calendarTypes';

export type UseCalendarsResponseT = {|
  calendars: CallFlowCalendarEntityT[],
  isLoadingCalendar: boolean
|};

const useCalendars = (enterpriseId: string): UseCalendarsResponseT => {
  // State
  const [isLoadingCalendar, setIsLoadingCalendar] = useState<boolean>(true);
  const cancelFetchCalendars = React.useRef<Canceler>();

  // redux
  const dispatch = useDispatch();
  const allCalendars = useSelector(state => state.entities.calendar);
  const fetchCalendars = async () => {
    if (enterpriseId) {
      await dispatch(
        calendarOps.retrieveCalendars(
          enterpriseId,
          null,
          new CancelToken(canceler => {
            cancelFetchCalendars.current = canceler;
          })
        )
      );

      setIsLoadingCalendar(false);
    }
  };

  const calendars = useMemo(
    () =>
      R.sortBy(
        c => (c.name || '').toLowerCase(),
        allCalendars.allIds.map(id => allCalendars.byId[id])
      ),
    [allCalendars.allIds, allCalendars.byId]
  );

  // side effects
  useEffect(() => {
    fetchCalendars();
    return () => cancelFetchCalendars.current && cancelFetchCalendars.current();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    calendars,
    isLoadingCalendar
  };
};

export default useCalendars;
