import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDroneRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.75 5a4.25 4.25 0 0 1 8.5 0 .75.75 0 0 1-1.5 0A2.75 2.75 0 1 0 5 7.75a.75.75 0 0 1 0 1.5A4.26 4.26 0 0 1 .75 5m14.75.75a.76.76 0 0 0 .75-.75A2.75 2.75 0 1 1 19 7.75a.75.75 0 0 0 0 1.5A4.25 4.25 0 1 0 14.75 5a.76.76 0 0 0 .75.75m-7 12.5a.76.76 0 0 0-.75.75A2.75 2.75 0 1 1 5 16.25a.75.75 0 0 0 0-1.5A4.25 4.25 0 1 0 9.25 19a.76.76 0 0 0-.75-.75m10.5-3.5a.75.75 0 0 0 0 1.5A2.75 2.75 0 1 1 16.25 19a.75.75 0 0 0-1.5 0A4.25 4.25 0 1 0 19 14.75m-3.8 1.51a3.3 3.3 0 0 1-1.7.49h-3a3.3 3.3 0 0 1-1.7-.49l-3.27 3.27a.75.75 0 0 1-1.06 0 .75.75 0 0 1 0-1.06l3.27-3.27a3.3 3.3 0 0 1-.49-1.7v-3a3.3 3.3 0 0 1 .49-1.7L4.47 5.53a.75.75 0 0 1 1.06-1.06L8.8 7.74a3.3 3.3 0 0 1 1.7-.49h3a3.3 3.3 0 0 1 1.7.49l3.27-3.27a.75.75 0 0 1 1.06 1.06L16.26 8.8c.315.512.484 1.099.49 1.7v3a3.3 3.3 0 0 1-.49 1.7l3.27 3.27a.75.75 0 0 1 0 1.06.75.75 0 0 1-1.06 0zm.05-2.76v-3a1.76 1.76 0 0 0-1.75-1.75h-3a1.76 1.76 0 0 0-1.75 1.75v3a1.76 1.76 0 0 0 1.75 1.75h3a1.76 1.76 0 0 0 1.75-1.75"/></svg>)}
    />
  );
}
  