// @flow
import React, { type Element } from 'react';
import classnames from 'classnames';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import type { CallFlowGridElementT } from '../../../../../ducks/entities/callFlow';
import styles from './ErrorView.module.scss';
import CallFlowActivity from '../../../components/CallFlowActivity';
import CallFlowTree from '../../tree/CallFlowTree';
import type { LoadGroupStatusT } from '../../../../../ducks/entities/acd/acdTypes';

export type PropsT = {
  gridElement: CallFlowGridElementT,
  hideDescription: boolean,
  loadGroupStatus: ?LoadGroupStatusT
};

export const TreeView = (props: PropsT): Element<'div'> => {
  const { gridElement, hideDescription, loadGroupStatus } = props;
  const { t } = useTranslation();

  const callflowTree = !R.isEmpty(gridElement.rootNode) ? (
    <CallFlowTree rootNode={gridElement.rootNode} gridElement={gridElement} />
  ) : null;

  return (
    <div className={styles['overview-area']}>
      <div
        className={classnames(styles['activity-container'], {
          [`${styles['activity-container--wide']}`]: hideDescription
        })}
      >
        <CallFlowActivity translate={t} loadGroupStatus={loadGroupStatus} />
      </div>
      {callflowTree}
    </div>
  );
};

export default TreeView;
