import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCablecardRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 3.74H2.25A2.25 2.25 0 0 0 0 5.99V18a2.25 2.25 0 0 0 2.24 2.26h19.51A2.25 2.25 0 0 0 24 18.01V6a2.25 2.25 0 0 0-2.24-2.26zM22.5 18a.76.76 0 0 1-.75.75H2.25A.76.76 0 0 1 1.5 18V6a.76.76 0 0 1 .75-.76h19.5a.76.76 0 0 1 .75.75zM7.75 9.26h-4a.75.75 0 0 0-.75.75v4a.76.76 0 0 0 .75.75h4a.76.76 0 0 0 .75-.75V10a.75.75 0 0 0-.75-.74m-.75 4H4.5v-2.5H7zm14 3.25a.76.76 0 0 1-.75.75H15.5a.75.75 0 0 1 0-1.5h4.75a.75.75 0 0 1 .75.75"/></svg>)}
    />
  );
}
  