import React from 'react';
import moment from 'moment';

import DayColumn from './DayColumn';
import { getDayIntervals } from './Utils';

/* eslint-disable */
class CalendarBody extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.scaleUnit !== this.props.scaleUnit ||
      nextProps.cellHeight !== this.props.cellHeight ||
      nextProps.numberOfDays !== this.props.numberOfDays ||
      !nextProps.firstDay.isSame(this.props.firstDay, 'day')
    );
  }

  render() {
    const {
      firstDay,
      numberOfDays,
      scaleUnit,
      scaleIntervals,
      cellHeight,
      dayCellComponent
    } = this.props;

    const weekdayColumns = [];
    for (let i = 0; i < numberOfDays; i += 1) {
      const day = moment(firstDay).add(i, 'd');
      const intervals = getDayIntervals(day, scaleIntervals);
      weekdayColumns.push(
        <DayColumn
          key={i}
          colPos={i}
          cellHeight={cellHeight}
          dayCellComponent={dayCellComponent}
          scaleUnit={scaleUnit}
          dayIntervals={intervals}
          onSelectionStart={this.props.onSelectionStart}
          onCellMouseEnter={this.props.onCellMouseEnter}
        />
      );
    }

    return (
      <div className="calendarBody">
        <div className="calendarBody__row">{weekdayColumns}</div>
      </div>
    );
  }
}

export default CalendarBody;
