import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileZipRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.104 6.473q-.012-.025-.026-.049a1.8 1.8 0 0 0-.341-.497L15.331.515a1.8 1.8 0 0 0-.534-.363L14.79.148A1.8 1.8 0 0 0 14.088 0H4.999A2.25 2.25 0 0 0 2.75 2.25v19.5c0 1.24 1.009 2.25 2.249 2.25h13.995a2.25 2.25 0 0 0 2.249-2.249l.007-14.586c0-.239-.052-.475-.146-.692M18.69 6H15.5a.25.25 0 0 1-.25-.25V2.557zm.304 16.5H4.999a.75.75 0 0 1-.749-.75V2.25a.75.75 0 0 1 .749-.75h8.751v4.25c0 .965.785 1.75 1.75 1.75h4.25l-.007 14.251a.75.75 0 0 1-.749.749m-8.376-3.283v.779c0 .08-.065.145-.145.145H7.161a.144.144 0 0 1-.145-.145v-.612q0-.045.026-.083l1.976-2.832h-1.8a.144.144 0 0 1-.145-.145v-.78c0-.08.065-.145.145-.145h3.197c.08 0 .145.064.145.145v.608a.15.15 0 0 1-.026.083l-1.973 2.837h1.912c.08 0 .145.065.145.145m1.965-3.673v4.452c0 .08-.064.145-.145.145h-.944a.144.144 0 0 1-.145-.145v-4.452c0-.08.065-.145.145-.145h.944c.081 0 .145.065.145.145m2.709-.145H13.87a.144.144 0 0 0-.145.145v4.452c0 .08.064.145.145.145h.944c.08 0 .145-.064.145-.145v-1.439h.261c.584 0 1.046-.14 1.371-.415.331-.281.499-.688.499-1.209 0-.505-.159-.893-.473-1.151-.306-.255-.753-.383-1.325-.383m-.333 1.063h.285q.331 0 .472.123c.089.078.133.202.133.381q0 .26-.163.388c-.115.093-.305.141-.561.141h-.166z"/></svg>)}
    />
  );
}
  