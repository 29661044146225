// @flow
import React from 'react';
import { ReactComponent as NoSearchResultsPic } from '../../assets/no-search-results.svg';
import styles from './NoResults.module.scss';

type PropsT = {
  title: string
};

const NoResults = (props: PropsT) => {
  const { title } = props;

  return (
    <div className={styles['no-search-results-container']}>
      <NoSearchResultsPic />
      <h2>{title}</h2>
    </div>
  );
};

export default NoResults;
