import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUserRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0M5.75 20.417v-.167c0-2.273 3.22-3.5 6.25-3.5 3.01 0 6.25 1.096 6.25 3.5v.168A10.44 10.44 0 0 1 12 22.5a10.43 10.43 0 0 1-6.25-2.083m13.864-1.208c-.643-2.406-3.54-3.959-7.614-3.959-3.424 0-6.879 1.361-7.604 3.97A10.45 10.45 0 0 1 1.5 12C1.5 6.21 6.21 1.5 12 1.5S22.5 6.21 22.5 12c0 2.792-1.103 5.327-2.886 7.209M12 4.75c-2.619 0-4.75 2.131-4.75 4.75s2.131 4.75 4.75 4.75 4.75-2.131 4.75-4.75S14.619 4.75 12 4.75m0 8c-1.792 0-3.25-1.458-3.25-3.25S10.208 6.25 12 6.25s3.25 1.458 3.25 3.25-1.458 3.25-3.25 3.25"/></svg>)}
    />
  );
}
  