import React from 'react';
import IconContainer from '../IconContainer';

export default function IconYoutubeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.56 11.571 10 8.434a.502.502 0 0 0-.75.43v6.273a.5.5 0 0 0 .25.429.47.47 0 0 0 .25.07.43.43 0 0 0 .25-.07l5.52-3.136a.49.49 0 0 0 0-.859zm-5.27 2.706V9.723l4 2.277zm13.21-8.12a3 3 0 0 0-2.12-2.147c-1.85-.5-9.07-.51-9.38-.51s-7.53 0-9.38.509A3 3 0 0 0 .5 6.157 32.7 32.7 0 0 0 0 12a32.6 32.6 0 0 0 .5 5.843 3 3 0 0 0 2.12 2.147c1.85.5 9.07.51 9.38.51s7.53 0 9.38-.509a3 3 0 0 0 2.12-2.137c.342-1.932.509-3.891.5-5.853a33 33 0 0 0-.5-5.844m-1 11.426a2 2 0 0 1-1.38 1.408c-1.44.39-7.07.469-9.12.469s-7.68-.05-9.12-.469a2 2 0 0 1-1.41-1.438A31.5 31.5 0 0 1 1 12c-.01-1.871.148-3.74.47-5.583a2 2 0 0 1 1.41-1.409C4.32 4.589 10 4.509 12 4.509s7.68.08 9.12.469a2 2 0 0 1 1.41 1.438c.322 1.844.479 3.713.47 5.584.01 1.871-.148 3.74-.47 5.583z"/></svg>)}
    />
  );
}
  