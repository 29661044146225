import React from 'react';
import IconContainer from '../IconContainer';

export default function IconReplyRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14 6.75h-2.25V4.52a2.21 2.21 0 0 0-2.149-2.269L9.54 2.25A2.07 2.07 0 0 0 8 3L2.35 9a2.29 2.29 0 0 0 0 3.08L8 18a2.16 2.16 0 0 0 1.52.73 2.2 2.2 0 0 0 1.58-.6 2.18 2.18 0 0 0 .67-1.65v-2.23H14A6.76 6.76 0 0 1 20.75 21a.75.75 0 0 0 1.5 0v-6A8.25 8.25 0 0 0 14 6.75m6.73 9.51A8.24 8.24 0 0 0 14 12.75h-3a.76.76 0 0 0-.75.75v3a.66.66 0 0 1-.19.54.65.65 0 0 1-1 0L3.45 11a.77.77 0 0 1 0-1l5.63-6a.56.56 0 0 1 .46-.25.72.72 0 0 1 .712.728l-.002.042v3a.76.76 0 0 0 .75.75h3A6.75 6.75 0 0 1 20.75 15z"/></svg>)}
    />
  );
}
  