// @flow

import React, { type Element, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { IntervalEntryT } from '../callFlowGrid/details/CalendarUtils';
import AbsoluteTimeSlots from './AbsoluteTimeSlots';
import type { AbsoluteTimeSlotEntityT } from '../../../ducks/entities/calendar/calendarTypes';
import Dialog from '../../../components/Dialog';
import styles from './AbsoluteTimesField.module.scss';

export type PropsT = {|
  field: string,
  handleUpdate: AbsoluteTimeSlotEntityT => void,
  updateAbsoluteTimeSlots: (AbsoluteTimeSlotEntityT[]) => Promise<void>,
  showDeleteAbsoluteTimeDeleteDialog: boolean,
  setShowDeleteAbsoluteTimeDeleteDialog: boolean => void
|};

const AbsoluteTimesField = (props: PropsT): Element<'div'> | null => {
  const {
    handleUpdate,
    field,
    updateAbsoluteTimeSlots,
    showDeleteAbsoluteTimeDeleteDialog,
    setShowDeleteAbsoluteTimeDeleteDialog
  } = props;
  const { t } = useTranslation();

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  const [activeDate, setActiveDate] = useState<?IntervalEntryT>();
  const [updating, setUpdating] = useState<boolean>(false);
  const absoluteTimeSlots = values[field] || [];

  const deleteAbsoluteTimeSlot = async () => {
    if (activeDate) {
      const timeSlots = absoluteTimeSlots.filter(slot => activeDate.id !== slot.id);
      setValue(field, timeSlots, {
        shouldValidate: true,
        shouldDirty: true
      });
      setUpdating(true);
      await updateAbsoluteTimeSlots(timeSlots);
      setUpdating(false);
    }
  };

  return (absoluteTimeSlots && absoluteTimeSlots.length > 0) || updating ? (
    <div className={styles.container}>
      {showDeleteAbsoluteTimeDeleteDialog && activeDate ? (
        <Dialog
          onCancel={() => setShowDeleteAbsoluteTimeDeleteDialog(false)}
          onConfirm={() => {
            deleteAbsoluteTimeSlot();
          }}
          onClose={() => {
            setShowDeleteAbsoluteTimeDeleteDialog(false);
          }}
          title={t('calendars.editCalendarForm.deleteDialog.title')}
          description={t('calendars.editCalendarForm.deleteDialog.descriptions', {
            date: activeDate.interval
          })}
          confirmLabel={t('calendars.editCalendarForm.deleteDialog.confirm')}
          cancelLabel={t('calendars.editCalendarForm.deleteDialog.cancel')}
          loading={updating}
          disabled={false}
        />
      ) : null}
      {absoluteTimeSlots && absoluteTimeSlots.length > 0 ? (
        <AbsoluteTimeSlots
          absoluteTimeSlots={absoluteTimeSlots}
          editable
          handleDelete={date => {
            setActiveDate(date);
            setShowDeleteAbsoluteTimeDeleteDialog(true);
          }}
          handleUpdate={slot => handleUpdate(absoluteTimeSlots.find(s => s.id === slot.id))}
        />
      ) : null}
    </div>
  ) : null;
};

export default AbsoluteTimesField;
