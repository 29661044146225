import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoMsOfficeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.75 1 2.833 5.372v13.374L14.75 23l6.417-1.934V3.053zm0 19.167-11.917-1.42 4.116-1.833V6.399l7.718-1.833z"/></svg>)}
    />
  );
}
  