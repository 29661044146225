import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDownloadRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 18.501v1.75A3.756 3.756 0 0 1 19.501 24H4.5a3.754 3.754 0 0 1-3.75-3.749v-1.75a.75.75 0 0 1 1.5 0v1.75A2.25 2.25 0 0 0 4.5 22.5h15.001a2.254 2.254 0 0 0 2.249-2.249v-1.75a.75.75 0 0 1 1.5 0m-11.781.529a.75.75 0 0 0 .818.162.8.8 0 0 0 .243-.162l7.319-7.318a.75.75 0 1 0-1.061-1.061l-6.038 6.038V.75a.75.75 0 0 0-1.5 0v15.939l-6.038-6.038a.75.75 0 1 0-1.061 1.061z"/></svg>)}
    />
  );
}
  