import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLaptopRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 15.25H22V5a2.28 2.28 0 0 0-2.25-2.25H4.25A2.28 2.28 0 0 0 2 5v10.25H.75A.76.76 0 0 0 0 16v1.43a3.82 3.82 0 0 0 3.82 3.82h16.36A3.82 3.82 0 0 0 24 17.43V16a.76.76 0 0 0-.75-.75M3.5 5a.78.78 0 0 1 .75-.75h15.5a.78.78 0 0 1 .78.78v10.22h-5.75a.73.73 0 0 0-.65.38 2.53 2.53 0 0 1-4.2 0 .73.73 0 0 0-.65-.38H3.53zm19 12.4a2.32 2.32 0 0 1-2.32 2.32H3.82A2.32 2.32 0 0 1 1.5 17.4v-.68h7.35a4.06 4.06 0 0 0 6.3 0h7.35z"/></svg>)}
    />
  );
}
  