import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDownloadingRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12m0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5m2.69 14.918H9.31a1.73 1.73 0 0 1-1.728-1.729v-5.38A1.73 1.73 0 0 1 9.31 7.581h5.381a1.73 1.73 0 0 1 1.729 1.728v5.381a1.73 1.73 0 0 1-1.73 1.728M9.31 9.081a.23.23 0 0 0-.228.228v5.381a.23.23 0 0 0 .228.229h5.381a.23.23 0 0 0 .229-.229V9.309a.23.23 0 0 0-.229-.228z"/></svg>)}
    />
  );
}
  