// @flow

import * as R from 'ramda';
import { push } from 'connected-react-router-for-react18';
import type { CurrentUserStateT } from './ducks/currentUser';
import { type CallFlowTypeT } from './ducks/entities/callFlow/callFlowTypes';
import type { CallForwardingT } from './ducks/entities/callForwarding/callForwardingTypes';
import type { InternalUserStateEntityT, UserTypeT } from './ducks/entities/user/userTypes';

export type PushObjT = { pathname: string, state: mixed };

type GoToFnT = (string | PushObjT) => $Call<typeof push, string | PushObjT>;
export const goTo: GoToFnT = pathOrObj => push(pathOrObj);

type GoToEnterpriseFnT = (string, ?$PropertyType<PushObjT, 'state'>) => *;
export const goToEnterprise: GoToEnterpriseFnT = (enterpriseId, state = {}) =>
  goTo({
    pathname: `/enterprises/${enterpriseId}`,
    state
  });

export type GoToEnterpriseUsersFnT = string => *;
export const goToEnterpriseUsers: GoToEnterpriseUsersFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/users`);

export type GoToUserDetailsFnT = (string, string, UserTypeT) => *;
export const goToUserDetails: GoToUserDetailsFnT = (enterpriseId, userId, userType) =>
  goTo(`/enterprises/${enterpriseId}/users/${userId}?type=${userType}`);

type GoToDepartmentFnT = (string, string, ?$PropertyType<PushObjT, 'state'>) => *;
export const goToDepartment: GoToDepartmentFnT = (enterpriseId, departmentId, state) =>
  goTo({
    pathname: `/enterprises/${enterpriseId}/departments/${departmentId}`,
    state
  });

type GoToLandingPageFnT = () => *;
export const goToLandingPage: GoToLandingPageFnT = () => goTo('/enterprises');

type GoToHomePageFnT = CurrentUserStateT => *;
export const goToHomePage: GoToHomePageFnT = currentUser =>
  currentUser.multiEnterpriseAdmin
    ? goTo('/enterprises')
    : goTo(`/enterprises/${currentUser.enterprises ? currentUser.enterprises[0].id : ''}`);

type GoToErrorPageFnT = () => *;
export const goToErrorPage: GoToErrorPageFnT = () => goTo('/404');

type GoToInstructionsPageFnT = () => *;
export const goToFinnishInstructionsPage: GoToInstructionsPageFnT = () => goTo('/lataukset');
export const goToEnglishInstructionsPage: GoToInstructionsPageFnT = () => goTo('/downloads');
export const goToSwedishInstructionsPage: GoToInstructionsPageFnT = () => goTo('/nedladdningar');
export const goToEstonianInstructionsPage: GoToInstructionsPageFnT = () => goTo('/allalaadimised');

type GoToLoginFnT = () => *;
export const goToLogin: GoToLoginFnT = () => goTo('/login');

type GoToUserPasswordMissingFnT = (lang: ?string, hasInvalidToken: ?boolean) => *;
export const goToUserPasswordMissing: GoToUserPasswordMissingFnT = (
  lang: ?string,
  hasInvalidToken: ?boolean
) => {
  const params = hasInvalidToken ? '?invalidToken=true' : '';
  switch (lang) {
    case 'en':
      return goTo(`/password${params}`);
    case 'fi':
      return goTo(`/salasana${params}`);
    case 'sv':
      return goTo(`/lösenord${params}`);
    case 'et':
      return goTo(`/parool${params}`);
    default:
      return goTo(`/password${params}`);
  }
};

type GoToPasswordTemporaryFnT = string => *;
export const goToPasswordTemporary: GoToPasswordTemporaryFnT = identifier =>
  goTo({
    pathname: '/password-temporary',
    state: { identifier }
  });

type GoToPasswordResetFnT = (string, string, string) => *;
export const goToPasswordReset: GoToPasswordResetFnT = (identifier, passphrase, nonce) =>
  goTo({
    pathname: '/password-reset',
    state: { identifier, passphrase, nonce }
  });

type GoToUserPasswordResultFnT = string => *;
export const goToUserPasswordResult: GoToUserPasswordResultFnT = environment =>
  goTo({
    pathname: '/done-password',
    state: { environment }
  });

type GoToEnterpriseForwardingsFnT = string => $Call<typeof goTo, *>;
export const goToEnterpriseForwardings: GoToEnterpriseForwardingsFnT = enterpriseId =>
  goTo({
    pathname: `/enterprises/${enterpriseId}/forwardings`,
    state: {}
  });

type GoToEnterpriseUserForwardingsFnT = (string, string) => $Call<typeof goTo, *>;
export const goToEnterpriseUserForwardings: GoToEnterpriseUserForwardingsFnT = (
  enterpriseId,
  addressNumber
) => goTo({ pathname: `/enterprises/${enterpriseId}/forwardings`, state: { addressNumber } });

type GoToForwardingDetailsUpdateFnT = (
  string,
  string,
  ?string,
  ?boolean,
  ?InternalUserStateEntityT
) => $Call<typeof goTo, *>;
export const goToForwardingDetailsUpdate: GoToForwardingDetailsUpdateFnT = (
  enterpriseId,
  callForwardingId,
  addressNumber,
  isEnterpriseForwarding,
  singleUser
) =>
  goTo({
    pathname: `/enterprises/${enterpriseId}/forwardings/${callForwardingId}`,
    state: { addressNumber, isEnterpriseForwarding, action: 'modify', singleUser }
  });

type GoToUserForwardingDetailsFnT = (
  string,
  string,
  ?string,
  ?InternalUserStateEntityT
) => $Call<typeof goTo, *>;
export const goToUserForwardingDetails: GoToUserForwardingDetailsFnT = (
  enterpriseId,
  callForwardingId,
  addressNumber,
  singleUser
) => goToForwardingDetailsUpdate(enterpriseId, callForwardingId, addressNumber, false, singleUser);

type GoToEnterpriseForwardingDetailsFnT = (string, string) => $Call<typeof goTo, *>;
export const goToEnterpriseForwardingDetails: GoToEnterpriseForwardingDetailsFnT = (
  enterpriseId,
  callForwardingId
) => goToForwardingDetailsUpdate(enterpriseId, callForwardingId, null, true);

type GoToForwardingDetailsCreateFnT = (
  string,
  ?string,
  ?boolean,
  ?InternalUserStateEntityT
) => $Call<typeof goTo, *>;
export const goToForwardingDetailsCreate: GoToForwardingDetailsCreateFnT = (
  enterpriseId,
  addressNumber,
  isEnterpriseForwarding,
  singleUser
) => {
  return goTo({
    pathname: `/enterprises/${enterpriseId}/forwardings/create`,
    state: {
      addressNumber,
      isEnterpriseForwarding,
      action: 'create',
      singleUser
    }
  });
};

type GoToForwardingDetailsCopyFnT = (string, CallForwardingT) => $Call<typeof goTo, *>;
export const goToForwardingDetailsModifyGroup: GoToForwardingDetailsCopyFnT = (
  enterpriseId,
  callForwarding
) => {
  const fwdType = R.isEmpty(callForwarding.presenceStateFilter)
    ? callForwarding.forwardingType
    : 'PRESENCE';
  return goTo({
    pathname: `/enterprises/${enterpriseId}/forwardings/create/${fwdType}`,
    state: { callForwarding, action: 'group' }
  });
};

type GoToCallFlowFnT = (string, string, CallFlowTypeT, ?string, ?string) => $Call<typeof goTo, *>;
export const goToCallFlow: GoToCallFlowFnT = (
  enterpriseId,
  callFlowId,
  callFlowType,
  nodeType,
  nodeId
) =>
  goTo({
    pathname: `/enterprises/${enterpriseId}/callflows/services/${callFlowId}`,
    search: `?type=${callFlowType}`,
    state: { nodeId, nodeType }
  });

type GoToCallFlowServicesFnT = string => $Call<typeof goTo, *>;
export const goToCallFlowServices: GoToCallFlowServicesFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/services`);

type GoToCallFlowImportFnT = string => $Call<typeof goTo, *>;
export const goToCallFlowImport: GoToCallFlowImportFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/import-services`);

type GoToAcdSupervisorstFnT = string => $Call<typeof goTo, *>;
export const goToAcdSupervisors: GoToAcdSupervisorstFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/acd-supervisors`);

type GoToCallFlowCalendarsFnT = string => $Call<typeof goTo, *>;
export const goToCallFlowCalendars: GoToCallFlowCalendarsFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/calendars`);

type GoToCreateCalendarFnT = string => $Call<typeof goTo, *>;
export const goToCreateCalendar: GoToCreateCalendarFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/calendars/create`);

type GoToCalendarTemplatesFnT = string => $Call<typeof goTo, *>;
export const goToCalendarTemplates: GoToCalendarTemplatesFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/calendartemplates`);

type GoToCreateCalendarTemplateFnT = string => $Call<typeof goTo, *>;
export const goToCreateCalendarTemplate: GoToCreateCalendarTemplateFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/calendartemplates/create`);

type GoToEditCalendarTemplateFnT = (string, string) => $Call<typeof goTo, *>;
export const goToEditCalendarTemplate: GoToEditCalendarTemplateFnT = (enterpriseId, calendarId) =>
  goTo(`/enterprises/${enterpriseId}/callflows/calendartemplates/${calendarId}`);

type GoToCreateCallflowServiceFnT = string => $Call<typeof goTo, *>;
export const goToCreateCallflowService: GoToCreateCallflowServiceFnT = enterpriseId =>
  goTo(`/enterprises/${enterpriseId}/callflows/create`);

type GoToCreateExternalUserFnT = string => $Call<typeof goTo, *>;
export const goToCreateExternalUser: GoToCreateExternalUserFnT = enterpriseId =>
  goTo({
    pathname: `/enterprises/${enterpriseId}/users/external/create`,
    state: {}
  });

type GoToEditUserFnT = (string, string, string, ?boolean) => $Call<typeof goTo, *>;
export const goToEditUser: GoToEditUserFnT = (enterpriseId, userId, userType, callFwdTabSelected) =>
  goTo({
    pathname: `/enterprises/${enterpriseId}/users/${userId}/edit`,
    search: `?type=${userType}`,
    state: { callFwdTabSelected }
  });
