import React from 'react';
/* eslint-disable */
class DayCell extends React.Component {
  handleMouseDown = e => {
    if (e.button === 0) {
      this.props.startSelection();
    }
  };

  render() {
    return (
      <div onMouseDown={this.handleMouseDown} className="dayCell" role="presentation">
        &nbsp;
      </div>
    );
  }
}

export default DayCell;
