"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isReactNodeInsteadOfMenuItem = void 0;
var isReactNodeInsteadOfMenuItem = function (item) { return !item || typeof item !== "object" || !item.title || !!item.type; };
exports.isReactNodeInsteadOfMenuItem = isReactNodeInsteadOfMenuItem;
var isMegaMenuSubMenu = function (subMenu) { return subMenu.some(function (subItem) { return Array.isArray(subItem); }); };
function LeafMenuItem(_a) {
    var _b = _a.active, active = _b === void 0 ? false : _b, current = _a.current, _c = _a.external, external = _c === void 0 ? false : _c, _d = _a.isFooter, isFooter = _d === void 0 ? false : _d, subTitles = _a.subTitles, tag = _a.tag, target = _a.target, title = _a.title, url = _a.url;
    return {
        active: active,
        current: current,
        external: external,
        isFooter: isFooter,
        subTitles: subTitles,
        tag: tag,
        target: target,
        title: title,
        url: url,
    };
}
function BranchMenuItem(_a) {
    var _b = _a.active, active = _b === void 0 ? false : _b, current = _a.current, _c = _a.external, external = _c === void 0 ? false : _c, onClick = _a.onClick, _d = _a.subMenu, subMenu = _d === void 0 ? [] : _d, tag = _a.tag, target = _a.target, title = _a.title, url = _a.url;
    return {
        active: active,
        current: current,
        external: external,
        onClick: onClick,
        subMenu: subMenu === null || subMenu === void 0 ? void 0 : subMenu.map(function (subMenuItem) {
            return (0, exports.isReactNodeInsteadOfMenuItem)(subMenuItem) ? subMenuItem : LeafMenuItem(subMenuItem);
        }),
        tag: tag,
        target: target,
        title: title,
        url: url,
    };
}
function MenuItem(_a) {
    var _b = _a.active, active = _b === void 0 ? false : _b, current = _a.current, _c = _a.external, external = _c === void 0 ? false : _c, onClick = _a.onClick, _d = _a.subMenu, subMenu = _d === void 0 ? [] : _d, tag = _a.tag, target = _a.target, title = _a.title, url = _a.url;
    var isMegaMenu = isMegaMenuSubMenu(subMenu);
    return {
        active: active,
        current: current,
        external: external,
        onClick: onClick,
        subMenu: isMegaMenu
            ? subMenu.map(function (subMenuItems) {
                return subMenuItems.map(function (subMenuItem) { return (0, exports.isReactNodeInsteadOfMenuItem)(subMenuItem) ? subMenuItem : BranchMenuItem(subMenuItem); });
            })
            : subMenu.map(function (subMenuItem) { return (0, exports.isReactNodeInsteadOfMenuItem)(subMenuItem) ? subMenuItem : LeafMenuItem(subMenuItem); }),
        tag: tag,
        target: target,
        title: title,
        url: url,
    };
}
exports.default = MenuItem;
