import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import { classNames } from '../../utils/css';

import styles from './InputHelp.module.scss';

function InputHelp(
  {
    id = null,
    className = null,
    children = null,
    ...otherProps
  }
) {
  const htmlId = id || nextId(`dsInputHelp`);
  const allClasses = classNames([styles.inputhelp, className || null]);

  return (
    <p className={allClasses} id={`${htmlId}Help`} {...otherProps}>
      {children}
    </p>
  );
}

InputHelp.propTypes = {
  /** Element's id */
  id: PropTypes.string,
  /** Class names the user can give to the component. */
  className: PropTypes.string,
  /** Content of the error message. Automatically detected. */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default InputHelp;
