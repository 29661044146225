import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSimFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.486 4.97 16.08.705A2 2 0 0 0 14.56 0h-1.435v4.5a.625.625 0 0 1-1.25 0V0h-2.75v4.5a.625.625 0 0 1-1.25 0V0H5C3.897 0 3 .897 3 2v20c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6.305c0-.478-.171-.94-.514-1.335M16.25 14.5v1.25h-2v-3h.25c.965 0 1.75.785 1.75 1.75m-3.5-1.75v7.5h-2.5v-7.5zm-4.25 0h.25v3h-2V14.5c0-.965.785-1.75 1.75-1.75M6.75 18.5v-1.25h2v3H8.5c-.965 0-1.75-.785-1.75-1.75m7.75 1.75h-.25v-3h2v1.25c0 .965-.785 1.75-1.75 1.75"/></svg>)}
    />
  );
}
  