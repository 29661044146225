import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUploadRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 18.5v1.75A3.75 3.75 0 0 1 19.5 24h-15a3.75 3.75 0 0 1-3.75-3.75V18.5a.75.75 0 0 1 1.5 0v1.75A2.25 2.25 0 0 0 4.5 22.5h15a2.25 2.25 0 0 0 2.25-2.25V18.5a.75.75 0 0 1 1.5 0M19.849 7.538 12.53.22A.75.75 0 0 0 12 0l-.005.001a.76.76 0 0 0-.525.219L4.151 7.538a.75.75 0 1 0 1.061 1.061l6.038-6.038V18.5a.75.75 0 0 0 1.5 0V2.561l6.038 6.038a.75.75 0 0 0 1.06 0 .75.75 0 0 0 .001-1.061"/></svg>)}
    />
  );
}
  