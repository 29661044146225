// @flow
import classNames from 'classnames';
import React from 'react';
import IconCloseRegular from '@design-system/component-library/src/components/Icon/lib/IconCloseRegular';
import type { DismissNotificationActionT } from '../../ducks/ui/notification/notificationUiTypes';

import styles from './Notification.module.scss';

/* eslint-disable react/no-unused-state, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

type PropsT = {
  keyId: string,
  tag: string,
  message: string,
  type: string,
  dismiss?: string => DismissNotificationActionT,
  additionalStyle?: string,
  hideDismiss?: boolean
};

export const Notification = ({
  message,
  keyId,
  type,
  tag,
  additionalStyle,
  dismiss,
  hideDismiss
}: PropsT) => (
  <div
    className={classNames(
      'ea-notification',
      styles[`notification-${type}`],
      styles.notification,
      `notification-${tag}`,
      additionalStyle
    )}
    role="status"
    key={keyId}
  >
    {message}
    {!hideDismiss && dismiss && (
      <IconCloseRegular onClick={dismiss} className={styles['cross-icon']} />
    )}
  </div>
);

export default Notification;
