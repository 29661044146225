"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRefs = setRefs;
function setRefs(val) {
    var refs = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        refs[_i - 1] = arguments[_i];
    }
    refs.filter(Boolean).forEach(function (ref) {
        if (typeof ref === 'function') {
            ref(val);
        }
        else {
            // eslint-disable-next-line no-param-reassign
            ref.current = val;
        }
    });
}
