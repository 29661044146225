import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 6.5V18a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6.5q0-.168.024-.337a.5.5 0 0 1 .813-.316l9.439 7.78c.966.797 2.482.797 3.446 0l9.438-7.78a.5.5 0 0 1 .496-.081.5.5 0 0 1 .316.391q.029.17.028.343m-12.769 5.971a1.194 1.194 0 0 0 1.54 0l9.193-7.579a.5.5 0 0 0-.279-.885A2 2 0 0 0 21.5 4h-19a2 2 0 0 0-.185.007.502.502 0 0 0-.278.885z"/></svg>)}
    />
  );
}
  