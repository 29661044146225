import React from 'react';
import IconContainer from '../IconContainer';

export default function IconExternalLinkFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.625 12.5V21A2.63 2.63 0 0 1 19 23.625H3A2.63 2.63 0 0 1 .375 21V5A2.63 2.63 0 0 1 3 2.375h8.5a1.125 1.125 0 0 1 0 2.25H3A.375.375 0 0 0 2.625 5v16c0 .207.168.375.375.375h16a.375.375 0 0 0 .375-.375v-8.5a1.125 1.125 0 0 1 2.25 0m1.994-11.031a1.1 1.1 0 0 0-.081-.398 1.1 1.1 0 0 0-.214-.323c-.012-.013-.016-.03-.028-.042-.009-.009-.022-.012-.032-.021a1.2 1.2 0 0 0-.334-.222 1.1 1.1 0 0 0-.43-.087h-6a1.125 1.125 0 0 0 0 2.25h3.284L9.705 12.704a1.127 1.127 0 0 0 .795 1.921c.288 0 .576-.109.795-.329l10.08-10.08V7.5a1.125 1.125 0 0 0 2.25 0v-6c0-.011-.006-.02-.006-.031"/></svg>)}
    />
  );
}
  