import React from 'react';
import IconContainer from '../IconContainer';

export default function IconViewOffFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.435 10.51a21.3 21.3 0 0 0-4.871-4.014L22.03 3.03a.75.75 0 1 0-1.061-1.061l-3.783 3.783-.002-.001-2.674 2.675.002.002-6.084 6.084-.002-.002-3 3 .002.001L1.97 20.97a.75.75 0 0 0 1.06 1.061l3.782-3.782c1.603.764 3.31 1.251 5.022 1.251h.312c4.349 0 8.674-3.093 11.292-6.016.75-.847.75-2.125-.003-2.974m-8.294 4.584a4.35 4.35 0 0 1-3.092 1.281h-.002l-.026-.002-.021.002h-.002c-.074 0-.144-.018-.217-.021-.166-.01-.333-.02-.495-.049a4 4 0 0 1-.53-.13q-.07-.02-.139-.042a4.5 4.5 0 0 1-1.114-.575l.889-.889c.481.291 1.03.456 1.607.457h.002a3.13 3.13 0 0 0 3.124-3.125v-.002a3.1 3.1 0 0 0-.458-1.604l.936-.936c.532.744.82 1.618.821 2.539a4.35 4.35 0 0 1-1.283 3.096M4.178 16.697a21.7 21.7 0 0 1-3.614-3.211 2.24 2.24 0 0 1 0-2.974C3.166 7.605 7.497 4.5 11.85 4.5h.297c1.087 0 2.236.2 3.414.594a.25.25 0 0 1 .098.414l-2.233 2.234a.25.25 0 0 1-.246.063 4.2 4.2 0 0 0-1.177-.18 4.38 4.38 0 0 0-4.377 4.374c0 .374.059.76.181 1.181a.25.25 0 0 1-.063.246L4.5 16.67a.25.25 0 0 1-.322.027"/></svg>)}
    />
  );
}
  