// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { TranslateT } from '../../../../../../commonTypes';
import AudioFileSelection from './AudioFileSelection';
import type { AudioFieldT, AudioT } from './CallflowAudioUtils';
import { getAudioPlayData } from './CallflowAudioUtils';
import AudioPlayerContainer from '../../../../../../components/AudioPlayer/AudioPlayerContainer';

import styles from './AcdAudioField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  disabled?: boolean,
  hasDefault?: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  disabledFn?: any => boolean,
  translateMock?: TranslateT<>,
  audioFilename?: string
|};

export const AcdAudioField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    disabled,
    disabledFn,
    translateMock,
    hasDefault,
    audioFilename
  } = props;
  const { t } = useTranslation();
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  const audioField: AudioFieldT<AudioT> = values[field];

  const currentUser = useSelector(state => state.currentUser);

  const disabledValue = disabledFn === undefined ? disabled : disabledFn(values);

  const isInactiveLevel = values[field].level === 'Inactive';
  const isExtensionLevel = values[field].level === 'Extension';
  const isDefaultLevel = values[field].level === 'Default';

  const extensionAudioData: AudioT = getAudioPlayData(
    audioField,
    isExtensionLevel ? 'Extension' : 'Entreprise'
  );

  const defaultAudioData = {
    audio: {
      ...audioField.audioPlayData,
      filename: 'Default.mp3',
      level: 'Default'
    },
    level: 'Default',
    loading: undefined,
    error: undefined
  };

  const hasDefaultAudio =
    audioField.hasAudioFilesOnLevels && audioField.hasAudioFilesOnLevels.includes('Default');
  const hasExtensionAudio =
    (audioField.hasAudioFilesOnLevels && audioField.hasAudioFilesOnLevels.includes('Extension')) ||
    audioFilename !== undefined;

  const viewWithoutDefaultAudio = t('callflows.audioField.defaultAudioLabel');
  const viewWithDefaultAudio = t('callflows.audioField.defaultOption');
  const customAudioLabel = t('callflows.audioField.customAudioLabel');
  const defaultAudioFileSelector =
    hasDefaultAudio || hasDefault ? viewWithDefaultAudio : viewWithoutDefaultAudio;
  const customAudioFileSelector = (
    <div data-cy={field}>
      <AudioFileSelection
        audio={extensionAudioData}
        onChange={file =>
          setValue(
            field,
            {
              ...values[field],
              fileToImport: file
            },
            {
              shouldValidate: false,
              shouldDirty: true
            }
          )
        }
        disabled={disabledValue || !isExtensionLevel}
        showInitialView={hasExtensionAudio}
        {...(translateMock ? { translateMock } : {})}
        audioFilename={audioFilename}
      />
    </div>
  );

  const fieldValue = values[field].level !== 'Extension' ? 'Default' : values[field].level;
  return (
    <div className={title && styles['title-padding']}>
      {title && (
        <div
          className={classnames(styles['small-title'], {
            [styles['small-title--disabled']]: disabledValue
          })}
        >
          {title}
        </div>
      )}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div role="group" aria-label="Message type">
        <RadioGroup value={fieldValue}>
          <Radio
            name={field}
            label={defaultAudioFileSelector}
            value="Default"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: hasDefaultAudio ? 'Default' : 'Inactive'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              )
            }
            disabled={disabledValue}
            className={styles.radio}
          />
          <Radio
            name={field}
            value="Extension"
            onChange={() =>
              setValue(
                field,
                {
                  ...values[field],
                  level: 'Extension'
                },
                {
                  shouldValidate: false,
                  shouldDirty: true
                }
              )
            }
            disabled={disabledValue}
            label={customAudioLabel}
            className={styles.radio}
          />
        </RadioGroup>
        {isExtensionLevel && customAudioFileSelector}
        {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') &&
          isDefaultLevel &&
          (hasDefaultAudio || hasDefault) && (
            <div className={styles['audio-area']}>
              <AudioPlayerContainer {...defaultAudioData} audioFilename={audioFilename} />
            </div>
          )}
      </div>
      {isInactiveLevel && hasDefaultAudio && hasExtensionAudio && (
        <div style={{ color: 'red' }}>{t('callflows.audioField.error')}</div>
      )}
    </div>
  );
};

export default AcdAudioField;
