import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailSentFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 16v5.5c0 .965-.785 1.75-1.75 1.75h-19c-.965 0-1.75-.785-1.75-1.75V16c0-.01.005-.018.006-.029.002-.05.018-.097.03-.146s.02-.098.041-.143c.004-.009.003-.019.008-.028l3.637-7a1.77 1.77 0 0 1 1.526-.904h2.548a.75.75 0 0 1 0 1.5H6a.25.25 0 0 0-.213.125L2.735 15.25h4.812a.75.75 0 0 1 .75.75v1a1.26 1.26 0 0 0 1.252 1.25h4.999a1.257 1.257 0 0 0 1.25-1.252V16a.75.75 0 0 1 .75-.75h4.795L18.68 9.307a.36.36 0 0 0-.182-.057h-2.951a.75.75 0 0 1 0-1.5H18.5c.622.001 1.2.336 1.512.874l3.173 7.07.002.008c.019.044.022.095.032.143.011.05.029.098.03.148zM8.53 6.03l2.72-2.72v9.19a.75.75 0 0 0 1.5 0V3.311l2.72 2.72a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.061l-4-4A.75.75 0 0 0 12 .75l-.005.001a.76.76 0 0 0-.525.219l-4 4a.75.75 0 1 0 1.06 1.06"/></svg>)}
    />
  );
}
  