import React from 'react';
import IconContainer from '../IconContainer';

export default function IconStarLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.94 9a1 1 0 0 0-.93-.64h-6.7l-2.38-6.72A1 1 0 0 0 12 1a1 1 0 0 0-.93.64L8.69 8.32H2a1 1 0 0 0-.93.64 1 1 0 0 0 .3 1.1L7 14.69l-2.36 7a1 1 0 0 0 1.53 1.11L12 18.56l5.85 4.25a1 1 0 0 0 .58.19 1 1 0 0 0 .95-1.3l-2.36-7 5.63-4.62A1 1 0 0 0 22.94 9m-6.82 5.17a.5.5 0 0 0-.16.54L18.44 22l-6.15-4.46a.48.48 0 0 0-.58 0L5.57 22 8 14.68a.5.5 0 0 0-.16-.54L2 9.33h7a.51.51 0 0 0 .47-.34L12 2l2.49 7c.071.2.258.335.47.34h7.05z"/></svg>)}
    />
  );
}
  