// @flow strict-local

import { type $AxiosError } from 'axios';
import type {
  AcdEntityT,
  UpdateAcdCancelT,
  UpdateAcdFailureT,
  UpdateAcdRequestT,
  UpdateAcdSuccessT
} from './acdTypes';

export type AcdTypeT = 'ACD_SWITCHBOARD' | 'ACD_CUSTOMER_SERVICE';
type CreateUpdateAcdRequestFnT = (string, AcdTypeT, string) => UpdateAcdRequestT;
export const createUpdateAcdRequest: CreateUpdateAcdRequestFnT = (
  enterpriseId,
  acdType,
  acdId
) => ({
  type: 'ring/entity/acd/UPDATE_REQUEST',
  error: false,
  meta: { enterpriseId, type: acdType, acdId }
});

type CreateUpdateAcdSuccessFnT = (string, AcdEntityT) => UpdateAcdSuccessT;
export const createUpdateAcdSuccess: CreateUpdateAcdSuccessFnT = (enterpriseId, acd) => ({
  type: 'ring/entity/acd/UPDATE_SUCCESS',
  payload: { ...acd },
  error: false,
  meta: { enterpriseId, type: acd.type, acdId: acd.id }
});

type CreateUpdateAcdFailureFnT = (
  string,
  AcdTypeT,
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => UpdateAcdFailureT;
export const createUpdateAcdFailure: CreateUpdateAcdFailureFnT = (
  enterpriseId,
  acdType,
  acdId,
  error
) => ({
  type: 'ring/entity/acd/UPDATE_FAILURE',
  payload: error,
  error: true,
  meta: { enterpriseId, type: acdType, acdId }
});

type CreateUpdateAcdCancelFnT = (string, AcdTypeT, string) => UpdateAcdCancelT;
export const createUpdateAcdCancel: CreateUpdateAcdCancelFnT = (enterpriseId, acdType, acdId) => ({
  type: 'ring/entity/acd/UPDATE_CANCEL',
  payload: {},
  error: false,
  meta: { enterpriseId, type: acdType, acdId }
});
