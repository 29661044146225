import React from 'react';
import IconContainer from '../IconContainer';

export default function IconReplyAllLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.859 17.167a.49.49 0 0 1 .007.692l-.007.007a.45.45 0 0 1-.36.14.46.46 0 0 1-.36-.16l-5.6-5.991a1.994 1.994 0 0 1 0-2.726l5.6-5.991a.501.501 0 1 1 .74.679l-5.6 5.991a.997.997 0 0 0 0 1.368zM24 15.01v5.991a.5.5 0 0 1-1 0c0-3.86-3.134-6.989-7.001-6.989h-2.5v2.496a1.91 1.91 0 1 1-3.34 1.348l-5.62-5.991a1.995 1.995 0 0 1 0-2.736l5.63-5.991a1.8 1.8 0 0 1 1.37-.639c1.105 0 2 .894 2 1.997v2.526h2.46C20.418 7.023 24 10.599 24 15.01m-1 0c0-3.86-3.134-6.989-7.001-6.989h-3a.5.5 0 0 1-.5-.499V4.527a1 1 0 0 0-1-.998.8.8 0 0 0-.64.329l-5.63 5.991a1 1 0 0 0 0 1.378l5.62 5.991a.9.9 0 0 0 1.341.06 1 1 0 0 0 .27-.729v-2.995a.5.5 0 0 1 .54-.539h3a8 8 0 0 1 7.001 4.123z"/></svg>)}
    />
  );
}
  