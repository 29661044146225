// @flow strict-local

import { type $AxiosError } from 'axios';
import type {
  UpdatePlayMusicCancelT,
  UpdatePlayMusicFailureT,
  UpdatePlayMusicRequestT,
  UpdatePlayMusicSuccessT
} from '../callFlow/callFlowTypes';
import type { PlayMusicEntityT } from './playMusicTypes';

type CreateUpdatePlayMusicRequestFnT = (string, string) => UpdatePlayMusicRequestT;
export const createUpdatePlayMusicRequest: CreateUpdatePlayMusicRequestFnT = (
  enterpriseId,
  playMusicId
) => ({
  type: 'ring/entity/playmusic/UPDATE_REQUEST',
  error: false,
  meta: { enterpriseId, playMusicId }
});

type CreateUpdatePlayMusicSuccessFnT = (string, PlayMusicEntityT) => UpdatePlayMusicSuccessT;
export const createUpdatePlayMusicSuccess: CreateUpdatePlayMusicSuccessFnT = (
  enterpriseId,
  playMusicData
) => ({
  type: 'ring/entity/playmusic/UPDATE_SUCCESS',
  payload: { ...playMusicData },
  error: false,
  meta: { enterpriseId, playMusicId: playMusicData.id }
});

type CreateUpdatePlayMusicFailureFnT = (
  string,
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => UpdatePlayMusicFailureT;
export const createUpdatePlayMusicFailure: CreateUpdatePlayMusicFailureFnT = (
  enterpriseId,
  playMusicId,
  error
) => ({
  type: 'ring/entity/playmusic/UPDATE_FAILURE',
  payload: error,
  error: true,
  meta: { enterpriseId, playMusicId }
});

type CreateUpdatePlayMusicCancelFnT = (string, string) => UpdatePlayMusicCancelT;
export const createUpdatePlayMusicCancel: CreateUpdatePlayMusicCancelFnT = (
  enterpriseId,
  playMusicId
) => ({
  type: 'ring/entity/playmusic/UPDATE_CANCEL',
  payload: {},
  error: false,
  meta: { enterpriseId, playMusicId }
});
