import React from 'react';
import IconContainer from '../IconContainer';

export default function IconViewOffRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.386 10.35a21.2 21.2 0 0 0-4.698-3.977L22.03 3.03a.75.75 0 1 0-1.061-1.061l-19 19a.75.75 0 0 0 1.06 1.061l3.634-3.634c1.785.89 3.528 1.354 5.164 1.354l.157-.001c4.406.085 8.773-3.103 11.403-6.104a2.52 2.52 0 0 0-.001-3.295M15 12.001c0 .801-.313 1.555-.879 2.121A2.98 2.98 0 0 1 12 15h-.001c-.54 0-1.063-.148-1.52-.418l4.104-4.104c.269.458.417.981.417 1.523m7.258.657c-2.379 2.716-6.272 5.592-10.139 5.592l-.134-.001c-1.315.029-2.73-.319-4.192-.982l1.612-1.612a4.47 4.47 0 0 0 2.593.845H12a4.47 4.47 0 0 0 3.18-1.317 4.47 4.47 0 0 0 1.319-3.185c0-.938-.306-1.836-.845-2.593L17.6 7.46a19.5 19.5 0 0 1 4.656 3.876c.323.372.323.953.002 1.322M3.795 16.6a22 22 0 0 1-3.182-2.953 2.52 2.52 0 0 1-.001-3.294c2.628-3.001 6.977-6.184 11.4-6.101.924-.021 1.968.132 3.033.445a.75.75 0 0 1-.422 1.44c-.92-.269-1.81-.403-2.611-.384-3.924-.088-7.865 2.841-10.27 5.587-.32.369-.32.951.002 1.322.85.973 1.874 1.922 2.958 2.744a.75.75 0 1 1-.907 1.194M12 7.5A.75.75 0 0 1 12 9a3.005 3.005 0 0 0-3 3 .75.75 0 0 1-1.5 0A4.506 4.506 0 0 1 12 7.5"/></svg>)}
    />
  );
}
  