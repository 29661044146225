import React from 'react';
import IconContainer from '../IconContainer';

export default function IconStarRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.17 8.88a1.25 1.25 0 0 0-1.16-.8h-6.52l-2.33-6.53a1.24 1.24 0 0 0-2.32 0L8.52 8.07H2a1.25 1.25 0 0 0-1.16.8 1.23 1.23 0 0 0 .37 1.38l5.49 4.51-2.3 6.85A1.24 1.24 0 0 0 6.29 23L12 18.87 17.7 23a1.25 1.25 0 0 0 1.47 0c.441-.313.62-.88.44-1.39l-2.3-6.85 5.5-4.51a1.24 1.24 0 0 0 .36-1.37M16 13.94a.75.75 0 0 0-.23.82l2.2 6.57-5.49-4a.77.77 0 0 0-.44-.14.8.8 0 0 0-.44.14l-5.49 4 2.2-6.56a.75.75 0 0 0-.23-.82L2.72 9.58H9a.76.76 0 0 0 .71-.5L12 2.76l2.25 6.32a.76.76 0 0 0 .71.5h6.32z"/></svg>)}
    />
  );
}
  