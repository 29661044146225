import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTravelRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.082 24c-2.546 0-2.637-2.268-2.764-5.405-.047-1.163-.102-2.543-.267-4.121l-4.633 4.633v2.164A2.73 2.73 0 0 1 6.689 24c-2.048 0-2.501-1.608-2.902-3.027q-.08-.287-.17-.59-.328-.098-.633-.184C1.585 19.803 0 19.354 0 17.312a2.73 2.73 0 0 1 2.729-2.729h2.164L9.526 9.95c-1.572-.165-2.95-.22-4.111-.267C2.271 9.558 0 9.466 0 6.918c0-.848.24-1.499.735-1.99 1.321-1.313 4.134-1.297 10.932-1.252 1.241.008 2.609.017 4.115.018l2.75-2.75A3.18 3.18 0 0 1 20.793 0h.024c.85.006 1.646.343 2.243.948l.028.029c.592.599.918 1.4.912 2.251a3.17 3.17 0 0 1-.946 2.245l-2.747 2.746c.003 2.255.033 4.192.06 5.852.087 5.455.126 7.945-1.067 9.157-.682.692-1.592.772-2.218.772m-2.475-11.893a.75.75 0 0 1 .742.644c.327 2.293.405 4.228.468 5.782.139 3.447.304 3.966 1.265 3.966.734 0 .999-.173 1.148-.324.752-.765.709-3.519.636-8.081-.027-1.742-.06-3.789-.06-6.187 0-.199.079-.39.22-.53l2.97-2.969c.323-.319.501-.741.504-1.191a1.67 1.67 0 0 0-.507-1.216 1.67 1.67 0 0 0-1.186-.501h-.013c-.445 0-.864.172-1.182.484l-2.99 2.99a.75.75 0 0 1-.53.22c-1.633 0-3.106-.009-4.435-.018-5.605-.038-8.987-.056-9.865.816-.136.135-.292.362-.292.926 0 .962.52 1.127 3.975 1.266 1.554.062 3.487.14 5.774.466a.75.75 0 0 1 .424 1.273l-5.939 5.939a.75.75 0 0 1-.53.22H2.729A1.23 1.23 0 0 0 1.5 17.311c0 .83.334 1.003 1.892 1.444.335.095.691.196 1.06.318a.75.75 0 0 1 .474.475c.116.349.213.69.305 1.017.435 1.543.635 1.935 1.458 1.935a1.23 1.23 0 0 0 1.229-1.229v-2.475c0-.199.079-.39.22-.53l5.939-5.938a.75.75 0 0 1 .53-.221"/></svg>)}
    />
  );
}
  