// @flow

import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { CancelToken } from 'axios';
import React, { type Element, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import type { Canceler } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectGroup } from '@design-system/component-library';
import IconAddRegular from '@design-system/component-library/src/components/Icon/lib/IconAddRegular';
import Dialog from '../../../components/Dialog';
import ContentFetchError from '../../../components/Error/ContentFetchError';
import ErrorBoundary from '../../../components/Error/ErrorBoundary';
import NotFoundCard from '../../../components/NotFoundCard/NotFoundCard';
import {
  operations as locationOps,
  selectors as locationSelect
} from '../../../ducks/entities/location';
import type { LocationEntityT } from '../../../ducks/entities/location/locationTypes';
import Sites from './Sites';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import userOperations from '../../../ducks/entities/user/userOperations';
import SecondaryButton from '../../../components/Button/SecondaryButton';
import styles from './Locations.module.scss';

export type PropsT = {|
  enterpriseId: ?string
|};

export const Locations = (props: PropsT): Element<'div'> => {
  const { enterpriseId } = props;
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [newLocation, setNewLocation] = useState(false);
  const [activeLocation, setActiveLocation] = useState();
  const [locationDeleteDialogOpen, setLocationDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const getLocationRequestCancelTokenSource = React.useRef<Canceler>();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const { t } = useTranslation();

  const enterpriseHasLocations = useSelector(
    state => locationSelect.byEnterpriseId(state, enterpriseId).length > 0
  );
  const enterpriseLocations = useSelector(state =>
    locationSelect.byEnterpriseId(state, enterpriseId)
  );
  const showLocationLoadingSpinner = useSelector(state =>
    locationSelect.collectionIsLoading(state)
  );
  const isLoadingLocations = useSelector(state => locationSelect.collectionIsLoading(state));
  const locationsHasError = useSelector(state =>
    locationSelect.collectionHasError(state, enterpriseId)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (enterpriseId) {
      dispatch(
        locationOps.retrieveCollection(
          enterpriseId,
          new CancelToken(canceler => {
            getLocationRequestCancelTokenSource.current = canceler;
          })
        )
      );
    }
    return () => {
      if (getLocationRequestCancelTokenSource.current) {
        getLocationRequestCancelTokenSource.current();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const openLocationDetails = (locationId: string) => {
    setShowLocationDetails(true);
    const selectedLocation: ?LocationEntityT = enterpriseLocations.find(
      location => location.id === locationId
    );
    if (selectedLocation) {
      setActiveLocation(selectedLocation);
    }
  };

  const hideLocationDetails = () => {
    setShowLocationDetails(false);
    setActiveLocation(null);
    setNewLocation(false);
  };

  const handleUpdateLocation = async (location: LocationEntityT) => {
    const id = enterpriseId || '';
    const returnValue = await dispatch(
      locationOps.updateLocation(id, location, createCsrfHeader(currentUser))
    );
    setActiveLocation(returnValue);
    setNewLocation(false);
  };

  const showConfirmLocationDelete = () => {
    setShowLocationDetails(false);
    setLocationDeleteDialogOpen(true);
    setNewLocation(false);
  };

  const showCreateNewLocation = () => {
    const entId = enterpriseId || '';
    setNewLocation(true);
    setShowLocationDetails(true);
    setActiveLocation({
      id: '',
      name: '',
      enterpriseId: entId,
      addresses: [{ type: 'visiting', address: [] }]
    });
  };

  const closeConfirmLocationDelete = () => {
    setLocationDeleteDialogOpen(false);
    setActiveLocation(null);
  };

  const handleRemoveLocation = async () => {
    const id = enterpriseId || '';

    if (activeLocation) {
      setLoading(true);
      await dispatch(
        locationOps.deleteLocation(id, activeLocation.id, createCsrfHeader(currentUser))
      );
      userOperations.removeLocationFromUsers(activeLocation.name);
    } else return;

    setLoading(false);
    closeConfirmLocationDelete();
    if (!locationSelect.hasError(enterpriseLocations, activeLocation.name)) {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'location-deleted',
          duration: 15000,
          type: 'info',
          message: t('enterprise.locationDeleteNotification.successNotification', {
            activeLocationName: activeLocation ? activeLocation.name : ''
          })
        })
      );
    } else {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'location-deleted-failure',
          duration: 15000,
          type: 'info',
          message: t('enterprise.locationDeleteNotification.failureNotification', {
            activeLocationName: activeLocation ? activeLocation.name : ''
          })
        })
      );
    }
  };

  const renderAddress = location => {
    return parse(
      `<div style="text-align: left; overflow: hidden"><div style="font-weight: bold; padding-bottom: 0.5em">${location.name ||
        ''}</div>
            <div>${
              location.addresses[0] && location.addresses[0].address1
                ? location.addresses[0].address1
                : ''
            } ${
        location.addresses[0] && location.addresses[0].address2
          ? location.addresses[0].address2
          : ''
      }</div><div>${
        location.addresses[0] && location.addresses[0].zip ? location.addresses[0].zip : ''
      } ${
        location.addresses[0] && location.addresses[0].city ? location.addresses[0].city : ''
      }</div></div>`
    );
  };

  const renderLocations = (): Element<
    'div' | typeof ContentFetchError | typeof ErrorBoundary | typeof NotFoundCard
  > => {
    if (!isLoadingLocations) {
      if (enterpriseHasLocations || newLocation) {
        const locations = [];

        enterpriseLocations.forEach(location => {
          locations.push({
            id: location.id,
            name: renderAddress(location)
          });
        });

        return (
          <ErrorBoundary
            errorElement={<ContentFetchError message={t('enterprise.locationsGenericErrorMsg')} />}
          >
            <div className={styles['locations-area']}>
              {locations.length > 0 ? (
                <SelectGroup
                  key={activeLocation ? activeLocation.id : '0'}
                  products={locations}
                  onChange={id => {
                    openLocationDetails(id);
                  }}
                  value={activeLocation ? activeLocation.id : ''}
                />
              ) : (
                <div />
              )}
              {((showLocationDetails && activeLocation) || newLocation) && (
                <Sites
                  onClose={hideLocationDetails}
                  onDelete={showConfirmLocationDelete}
                  onUpdate={handleUpdateLocation}
                  location={activeLocation}
                  newLocation={newLocation}
                  enterpriseId={enterpriseId || ''}
                />
              )}
            </div>
          </ErrorBoundary>
        );
      }
      if (locationsHasError) {
        return <ContentFetchError message={t('enterprise.locationsErrorMsg')} />;
      }
      return (
        <NotFoundCard
          id="sites-no-results"
          label={t('enterprise.noLocationsFoundMsg')}
          linkLabel={t('enterprise.addFirstLocationLink')}
          onClickAction={(e: *): void => {
            e.preventDefault();
            showCreateNewLocation();
          }}
          type="site"
        />
      );
    }
    if (showLocationLoadingSpinner && !enterpriseHasLocations) {
      return (
        <div className={styles.spinner}>
          <CenterHorizontally>
            <LoadingSpinner />
          </CenterHorizontally>
        </div>
      );
    }
    return <div />;
  };

  const dialog = (
    <Dialog
      onCancel={closeConfirmLocationDelete}
      onConfirm={handleRemoveLocation}
      onClose={closeConfirmLocationDelete}
      title={t('enterprise.locationDeleteDialog.title')}
      description={parse(
        `${t('enterprise.locationDeleteDialog.descriptions1', {
          activeLocationName: activeLocation ? activeLocation.name : ''
        })}<br />${t('enterprise.locationDeleteDialog.descriptions2')}`
      )}
      confirmLabel={t('enterprise.locationDeleteDialog.confirm')}
      cancelLabel={t('enterprise.locationDeleteDialog.cancel')}
      loading={loading}
      disabled={false}
    />
  );

  const header = (
    <div className={styles['header-area']}>
      <div>
        <h4 className={styles['section-title']}>{t('enterprise.locations')}</h4>
      </div>
      <div>
        <SecondaryButton
          id="add-location-button"
          type="button"
          onClickAction={() => showCreateNewLocation()}
          className={styles['add-location-button']}
          color="link"
          label={t('enterprise.addLocation')}
          leading={<IconAddRegular />}
        />
      </div>
    </div>
  );

  return (
    <div>
      {locationDeleteDialogOpen && dialog}
      {header}
      {renderLocations()}
    </div>
  );
};

export default Locations;
