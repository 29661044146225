// @flow

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { ExactPropsT } from '../../../commonTypes';
import ResultView from '../../../components/ResultView';

type OwnPropsT = {
  onConfirm: void => void,
  numberOfUsersImported: number,
  maxNumberOfUsersImported: number,
  numberOfDepartmentsImported: number,
  maxNumberOfDepartmentsImported: number
};

type StatePropsT = {};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

// eslint-disable-next-line
export class SuccessStep extends Component<PropsT> {
  render(): Element<typeof ResultView> {
    const {
      t,
      onConfirm,
      numberOfUsersImported,
      maxNumberOfUsersImported,
      numberOfDepartmentsImported,
      maxNumberOfDepartmentsImported
    } = this.props;

    const users = `${numberOfUsersImported}/${maxNumberOfUsersImported}`;
    const departments = `${numberOfDepartmentsImported}/${maxNumberOfDepartmentsImported}`;

    return (
      <ResultView
        onConfirm={onConfirm}
        viewType="success view"
        mainInfos={[t('importUsers.usersImported', { usersImported: users })]}
        secondaryInfos={[
          t('importUsers.departmentsImported', {
            departmentsImported: departments
          })
        ]}
        title={t('importUsers.successStep.title')}
        confirmButtonLabel={t('importUsers.successStep.confirm')}
      />
    );
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)
)(SuccessStep);
