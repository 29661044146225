import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmartWatchFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 4H6c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2M9.155 8.31a.873.873 0 1 1 1.689.452.86.86 0 0 1-.409.532.875.875 0 0 1-1.28-.984M8 12.875c-.482 0-.875-.393-.875-.875s.393-.875.875-.875.875.393.875.875-.393.875-.875.875m2.756 3.028a.86.86 0 0 1-.758.436.877.877 0 0 1-.756-1.313.87.87 0 0 1 .531-.407.86.86 0 0 1 .665.088c.2.114.346.304.407.53a.86.86 0 0 1-.089.666M10.375 12c0-.896.729-1.625 1.625-1.625s1.625.729 1.625 1.625-.729 1.625-1.625 1.625A1.627 1.627 0 0 1 10.375 12m4.064 4.221a.86.86 0 0 1-.665.089.87.87 0 0 1-.533-.408V15.9a.873.873 0 0 1 .322-1.194.876.876 0 0 1 1.193.318.88.88 0 0 1-.317 1.197m.321-7.25a.88.88 0 0 1-1.198.322.876.876 0 0 1-.321-1.193l.001-.002a.876.876 0 0 1 1.518.873M16 12.875c-.482 0-.875-.393-.875-.875s.393-.875.875-.875.875.393.875.875-.393.875-.875.875m.491-10.721A.497.497 0 0 1 16 2.75H8a.498.498 0 0 1-.491-.594l.096-.5A1.98 1.98 0 0 1 9.556 0h4.888c.971 0 1.792.702 1.952 1.668zm0 19.692-.097.498A1.976 1.976 0 0 1 14.444 24H9.556a1.98 1.98 0 0 1-1.953-1.671l-.094-.484A.5.5 0 0 1 8 21.25h8a.498.498 0 0 1 .491.596"/></svg>)}
    />
  );
}
  