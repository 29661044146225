// @flow

import React, { type Element } from 'react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate-markup';
import classnames from 'classnames';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { ExactPropsT } from '../../../commonTypes';
import type { DepartmentStateEntityT } from '../../../ducks/entities/department';
import { ReactComponent as DepartmentSvg } from '../../../assets/department.svg';
import { ReactComponent as DepartmentSelectedSvg } from '../../../assets/department-select.svg';
import styles from './DepartmentTreeCard.module.scss';

type OwnPropsT = {|
  department: DepartmentStateEntityT,
  onClick: () => *,
  onClickPeopleLink: () => *,
  onClickEditLink: () => *,
  selected: boolean,
  onCreateDepartment: (*, ?string) => *,
  id?: string
|};

type StatePropsT = {||};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

export const DepartmentTreeCard = ({
  department,
  onClick,
  onClickPeopleLink,
  onClickEditLink,
  selected,
  dispatch,
  t,
  onCreateDepartment,
  ...rest
}: PropsT): Element<'span'> => (
  <span
    className={classnames(styles.card, selected ? styles.selected : '')}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
    {...rest}
  >
    <span className={styles.content}>
      <span>{selected ? <DepartmentSelectedSvg /> : <DepartmentSvg />}</span>
      <span className={selected ? styles['name-selected'] : styles.name}>
        <Truncate>
          <span data-cy="view-department-name" id={`view-department-name-${department.id}`}>
            {department.name}
          </span>
        </Truncate>
      </span>
      {department.subDepartments && department.subDepartments.length > 0 && (
        <span className={styles.arrow} />
      )}
    </span>
    {selected && (
      <ul className={styles.links}>
        <li>
          <span
            id={`view-department-edit-link-${department.id}`}
            onClick={e => {
              e.stopPropagation();
              onClickEditLink();
            }}
            onKeyPress={e => {
              e.stopPropagation();
              onClickEditLink();
            }}
            role="button"
            tabIndex={0}
          >
            <span className={styles.text}>{t('enterprise.departmentTree.edit')}</span>
          </span>
        </li>
        <li>
          <span
            id={`view-department-people-link-${department.id}`}
            onClick={e => {
              e.stopPropagation();
              onClickPeopleLink();
            }}
            onKeyPress={e => {
              e.stopPropagation();
              onClickPeopleLink();
            }}
            role="button"
            tabIndex={0}
          >
            <span className={styles.text}>
              {t('enterprise.departmentTree.inspectPeople', {
                userCount: department.userCount !== undefined ? department.userCount : '...'
              })}
            </span>
          </span>
        </li>
        <li>
          <span
            id={`add-department-link-${department.id}`}
            onClick={e => {
              e.stopPropagation();
              onCreateDepartment(null, department.id);
            }}
            onKeyPress={e => {
              e.stopPropagation();
              onCreateDepartment(null, department.id);
            }}
            role="button"
            tabIndex={0}
          >
            <span className={`${styles['add-department']}`}>
              {t('enterprise.createDepartmentLinkLabel')}
            </span>
          </span>
        </li>
      </ul>
    )}
  </span>
);

DepartmentTreeCard.defaultProps = {
  onClickPeopleLink: () => {} // eslint-disable-line react/default-props-match-prop-types
};

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)
)(DepartmentTreeCard);
