import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLiveNowFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.007 15.5a.247.247 0 0 0 .243-.25V4.75a.247.247 0 0 0-.244-.25H2.993a.247.247 0 0 0-.243.25v10.5c0 .138.109.25.243.25zm-10.76-8.182a.5.5 0 0 1 .498-.004l4 2.25a.5.5 0 0 1 0 .872l-4 2.25A.5.5 0 0 1 10 12.25v-4.5a.5.5 0 0 1 .248-.432z" clipRule="evenodd"/><path d="M2.221 1.75h19.558C23.004 1.75 24 2.759 24 4v12c0 1.24-.996 2.25-2.221 2.25h-9.004v2.5H16.5a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1 0-1.5h3.776v-2.5H2.221C.996 18.25 0 17.24 0 16V4c0-1.241.996-2.25 2.221-2.25m19.558 15c.398 0 .721-.337.721-.75V4c0-.414-.323-.75-.72-.75H2.221c-.398 0-.721.336-.721.75v12c0 .413.323.75.721.75z" clipRule="evenodd"/></svg>)}
    />
  );
}
  