// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import axios, { AxiosPromise } from 'axios';
import Dismiss from '../../../components/Button/Dismiss';
import Avatar from '../../../components/Avatar/Avatar';
import Item from '../../../components/Item';
import LinkButton from '../../../components/Button/LinkButton';
import { goTo } from '../../../navigationOperations';
import AvailabilityIndicator from '../../../components/Avatar/AvailabilityIndicator';
import { getAvailabilityInfo } from '../../../helpers';
import { createCsrfHeader } from '../../../utils/accessRightUtils';

import styles from './ContactCard.module.scss';

type PropsT = {|
  selectedContact: string,
  selectedPhonebook: string,
  showEdit: boolean,
  onClose: () => *
|};

const ContactCard = ({
  selectedContact,
  onClose,
  selectedPhonebook,
  showEdit
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const contact = JSON.parse(selectedContact);
  const phonebook = JSON.parse(selectedPhonebook);
  const currentUser = useSelector(state => state.currentUser);
  const [postalAddress, setPostalAddress] = useState();
  const [billingAddress, setBillingAddress] = useState();
  const [visitingAddress, setVisitingAddress] = useState();
  const [showAllAddresses, setShowAllAddresses] = useState(false);

  useEffect(() => {
    const fetchExtraInfo = async () => {
      const userInfoResponse: AxiosPromise<> = await axios({
        method: 'GET',
        url: `/api/v1/phonebook/${phonebook.environment}/enterprises/${contact.enterpriseId}/users/${contact.publicInfo.addressNumber}`,
        headers: createCsrfHeader(currentUser)
      });
      if (!userInfoResponse || !userInfoResponse.data) {
        return;
      }
      setUserData(userInfoResponse.data);

      setShowAllAddresses(false);
      setPostalAddress(undefined);
      setVisitingAddress(undefined);
      setBillingAddress(undefined);

      const addresses = userInfoResponse.data.professionalPostalAddresses || [];
      addresses.forEach(address => {
        switch (address.type) {
          case 'postal':
            setPostalAddress(address);
            break;
          case 'visiting':
            setVisitingAddress(address);
            break;
          case 'billing':
            setBillingAddress(address);
            break;
          default:
        }
      });
    };

    fetchExtraInfo();
  }, [contact.publicInfo.addressNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const availability = getAvailabilityInfo(
    contact.publicInfo.presenceState,
    contact.publicInfo.telephonicState,
    t
  );

  const phoneNumberHtmlContent = (values: string[]): * => (
    <>
      {values.map(v => (
        <>
          <a href={`tel:${v}`}>{v}</a>
          <span>&nbsp;</span>
        </>
      ))}
    </>
  );

  const itemFor = (value: string | Element<*>, label: string): * =>
    value && <Item label={label} value={value} id={label} />;

  const phoneNumberItem = (values: string[], label: string): * =>
    values && values.length > 0 && itemFor(phoneNumberHtmlContent(values), label);

  // eslint-disable-next-line flowtype/no-weak-types
  const addressField = (value: Object, label: string): * => (
    <>
      {value && (
        <div className={styles['item-container']}>
          <div className={styles['item-label']}>{label}</div>
          {value.address1 && (
            <>
              <>{value.address1}</>
              <br />
            </>
          )}
          {value.address2 && (
            <>
              <>{value.address2}</>
              <br />
            </>
          )}
          {value.zip && (
            <>
              <>{value.zip}</>
              <br />
            </>
          )}
          {value.city && (
            <>
              <>{value.city}</>
              <br />
            </>
          )}
          {value.district && (
            <>
              <>{value.district}</>
              <br />
            </>
          )}
          {value.country && (
            <>
              <>{value.country}</>
              <br />
            </>
          )}
        </div>
      )}
    </>
  );

  const getAvatar = () => {
    return contact.publicInfo.isAvatarCustomized ? contact.publicInfo.urlPhoto : null;
  };

  const phoneNums = [...contact.publicInfo.pstnNumbers, ...contact.publicInfo.plmnNumbers];

  return (
    <div className={styles['contact-card-container']}>
      <div className={styles['contact-card-content']}>
        <div className={styles['top-bg']} />
        <div>
          <Dismiss
            id="close-user-details-button"
            onClose={onClose}
            tabIndex="0"
            dismissStyle={styles['close-button']}
            color="white"
          />
        </div>
        <div className={styles['avatar-container']}>
          <Avatar
            src={getAvatar()}
            name={contact.displayName}
            availability={availability.icon}
            color="#0019AF"
            shadow
            size="large"
            hideWhenViewing
          />
        </div>
        <div className={styles['quick-info-area']}>
          {showEdit && (
            <LinkButton
              id={`edit_${contact.id}`}
              label={t('phonebook.edit')}
              onClickAction={() =>
                dispatch(
                  goTo({
                    pathname: `/phonebook/${contact.publicInfo.addressNumber}`,
                    state: {
                      directoryUser: contact,
                      phonebook
                    }
                  })
                )
              }
            />
          )}
          {contact.displayName && (
            <div className={styles['display-name']}>{contact.displayName}</div>
          )}
          {phoneNums && (
            <div className={styles['phone-number']}>{phoneNumberHtmlContent(phoneNums)}</div>
          )}
          {contact.publicInfo.title && (
            <div className={styles.title}>{contact.publicInfo.title}</div>
          )}
          {userData && userData.department && (
            <div className={styles.department}>{userData.department.name}</div>
          )}
          {availability && (
            <div>
              <AvailabilityIndicator
                status={availability.icon}
                size="small"
                className={styles['availability-indicator']}
              />
              {availability.text}
            </div>
          )}
        </div>
        <div>
          {userData && (
            <Accordion className={styles['accordion-header']}>
              <AccordionItem id="phoneNumbers" key="phoneNumbers" heading={t('users.phoneNumbers')}>
                {phoneNumberItem(
                  contact.publicInfo.plmnNumbers,
                  t('users.personalDetails.plmnNumbersLabel')
                )}
                {phoneNumberItem(
                  contact.publicInfo.pstnNumbers,
                  t('users.personalDetails.pstnNumbersLabel')
                )}
                {userData.mobileNumber &&
                  phoneNumberItem(
                    [userData.mobileNumber],
                    t('users.personalDetails.mobileNumberLabel')
                  )}
                {userData.homeNumber &&
                  phoneNumberItem(
                    [userData.homeNumber],
                    t('users.personalDetails.homeNumberLabel')
                  )}
                {itemFor(
                  contact.publicInfo.addressNumber,
                  t('users.personalDetails.shortNumberLabel')
                )}
              </AccordionItem>
              <AccordionItem
                id="contactInfoTitle"
                key="contactInfoTitle"
                heading={t('users.personalDetails.contactInfoTitle')}
              >
                {itemFor(userData.emails, t('users.personalDetails.emails'))}
                {itemFor(userData.website, t('users.personalDetails.website'))}
                {itemFor(userData.faxAddress, t('users.personalDetails.faxAddress'))}
              </AccordionItem>
              <AccordionItem
                id="location"
                key="location"
                heading={t('users.personalDetails.location')}
              >
                {itemFor(
                  userData.professionalPostalAddress,
                  t('users.personalDetails.professionalPostalAddress')
                )}
                {addressField(postalAddress, t('siteDetails.postalAddress'))}
                {!showAllAddresses && (billingAddress || visitingAddress) ? (
                  <LinkButton
                    className={styles['show-more-button']}
                    id="show-more-button"
                    label={t('phonebook.showMoreLabel')}
                    onClickAction={() => {
                      setShowAllAddresses(true);
                    }}
                  />
                ) : (
                  <>
                    {addressField(billingAddress, t('siteDetails.billingAddress'))}
                    {addressField(visitingAddress, t('siteDetails.visitingAddress'))}
                  </>
                )}
                {itemFor(userData.officeReferenceID, t('users.personalDetails.officeReferenceID'))}
              </AccordionItem>
              <AccordionItem
                id="organizationInfo"
                key="organizationInfo"
                heading={t('phonebook.fieldLabels.organizationInfoLabel')}
              >
                {itemFor(contact.displayName, t('users.displayName'))}
                {itemFor(userData.nickName, t('users.personalDetails.nickName'))}
                {itemFor(contact.publicInfo.title, t('users.personalDetails.title'))}
                {itemFor(
                  userData.department ? userData.department.name : '',
                  t('users.departmentAndColleagues.department')
                )}
                {itemFor(
                  userData.corporateUserId,
                  t('users.additionalInformation.corporateUserId')
                )}
                {itemFor(
                  userData.superiors
                    .map(ob => {
                      return ob.userName;
                    })
                    .join(', '),
                  t('users.departmentAndColleagues.superiors')
                )}
                {itemFor(
                  userData.assistants
                    .map(ob => {
                      return ob.userName;
                    })
                    .join(', '),
                  t('users.departmentAndColleagues.assistants')
                )}
                {itemFor(
                  userData.substitutes
                    .map(ob => {
                      return ob.userName;
                    })
                    .join(', '),
                  t('users.departmentAndColleagues.substitutes')
                )}
                {itemFor(userData.costCenter, t('users.additionalInformation.costCenter'))}
                {itemFor(
                  userData.accessCtrlSystemPersonId,
                  t('users.additionalInformation.accessCtrlSystemPersonId')
                )}
              </AccordionItem>
              <AccordionItem
                id="additionalInformation"
                key="additionalInformation"
                heading={t('users.additionalInformation.sectionTitle')}
              >
                {itemFor(
                  userData.additionalExplanations,
                  t('users.additionalInformation.additionalExplanations')
                )}
                {itemFor(userData.additionalInfo, t('users.additionalInformation.additionalInfo'))}
                {itemFor(
                  userData.contactInformation,
                  t('users.additionalInformation.contactInformation')
                )}
                {itemFor(userData.duties, t('users.departmentAndColleagues.duties'))}
                {itemFor(userData.tagNames.join(' '), t('users.additionalInformation.tagNames'))}
              </AccordionItem>
            </Accordion>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContactCard;
