// @flow

import React, { type Element } from 'react';
import { ReactComponent as ContentFetchErrorSvg } from '../../assets/content-fetch-error.svg';
import styles from './ContentFetchError.module.scss';

type PropsT = {
  message: string
};

const ContentFetchError = (props: PropsT): Element<'div'> => (
  <div className={styles.container}>
    <ContentFetchErrorSvg />
    <p>{props.message}</p>
  </div>
);

export default ContentFetchError;
