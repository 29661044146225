import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrangeAscFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.2 8.7c.4-.4.4-1 0-1.4l-4-4c-.4-.4-1-.4-1.4 0l-4 4c-.4.4-.4 1 0 1.4s1 .4 1.4 0l2.3-2.3V20c0 .6.4 1 1 1s1-.4 1-1V6.4l2.3 2.3c.4.4 1 .4 1.4 0M22.5 5h-9c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1m0 4h-9c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1m0 4h-9c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1m0 4h-9c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1s-.4 1-1 1m-9 2h9c.6 0 1 .4 1 1s-.4 1-1 1h-9c-.6 0-1-.4-1-1s.4-1 1-1"/></svg>)}
    />
  );
}
  