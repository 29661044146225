import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSearchFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M15.419 17.186a9 9 0 1 1 1.768-1.768l5.447 5.448a1.25 1.25 0 0 1-1.768 1.768zM16.5 10a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0" clipRule="evenodd"/></svg>)}
    />
  );
}
  