// @flow

/* eslint-disable flowtype/no-weak-types */

import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import React, { Component, type Element } from 'react';
import classnames from 'classnames';
import type { InternalUserEntityT, UserListElementT } from '../../ducks/entities/user/userTypes';
import SearchInput from './SearchInput';
import CenterHorizontally from '../CenterHorizontally/CenterHorizontally';

import styles from './Search.module.scss';

const MAX_SEARCH_RESULTS = 20;

type BaseOwnPropsT<T, K, A = void> = {
  id: string,
  inputLabel: string,
  searchResults: Array<T>,
  renderResultItem: K,
  searchInputChanged: string => void,
  label?: string,
  searchClassName?: string,
  searchInputContainerClassName?: string,
  resultsClassName?: string,
  renderSelectedList?: (?boolean) => *,
  searchInputCleared?: () => *,
  handleSelectResult?: number => *,
  selectedItems?: A,
  isSectionInEditMode?: boolean,
  isLoading?: boolean,
  inputFieldRef?: { current: null | HTMLInputElement },
  maxSearchResults?: number,
  placeholder?: string,
  hideResults?: boolean,
  isLoadingSearchResults?: boolean,
  firstResultElement?: Element<'li'>,
  searchResultsOnlyWithSearchTerm?: boolean
};

// $FlowFixMe
type OwnPropsForDepartmentSearchT = BaseOwnPropsT<Array<string>, (Array<string>) => *>;
// eslint-disable-next-line flowtype/generic-spacing
export type OwnPropsForInternalUserDetailsT = BaseOwnPropsT<
  InternalUserEntityT,
  (InternalUserEntityT, number, boolean) => *,
  Array<UserListElementT>
>;

export type PropsT = OwnPropsForInternalUserDetailsT | OwnPropsForDepartmentSearchT;

type StateT = {
  selectedResult: number,
  searchTerm: string
};

export class Search extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.selectNextResult = this.selectNextResult.bind(this);
    this.selectNextResult = this.selectNextResult.bind(this);
    this.state = {
      selectedResult: 0,
      searchTerm: ''
    };
  }

  handleKeyDown: *;

  handleKeyDown(event: SyntheticKeyboardEvent<>) {
    const { handleSelectResult } = this.props;
    const { selectedResult } = this.state;
    if (event.key) {
      if (event.key === 'ArrowDown') {
        if (event) event.preventDefault();
        this.selectNextResult();
        if (event) event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        if (event) event.preventDefault();
        this.selectPreviousResult();
      } else if (event.key === 'Enter') {
        if (event) event.preventDefault();
        if (handleSelectResult) {
          handleSelectResult(selectedResult);
        }
        this.setState({ selectedResult: 0 });
      }
    }
  }

  selectNextResult: () => void;

  selectNextResult(): void {
    const { searchResults } = this.props;
    const { selectedResult } = this.state;
    if (selectedResult < searchResults.length - 1) {
      this.setState({ selectedResult: selectedResult + 1 });
    }
  }

  selectPreviousResult: () => void;

  selectPreviousResult(): void {
    const { selectedResult } = this.state;
    if (selectedResult > 0) {
      this.setState({ selectedResult: selectedResult - 1 });
    }
  }

  renderResults: *;

  renderResults() {
    const {
      renderResultItem,
      searchResults,
      resultsClassName,
      maxSearchResults,
      isLoadingSearchResults,
      firstResultElement,
      searchResultsOnlyWithSearchTerm
    } = this.props;
    const { selectedResult, searchTerm } = this.state;
    return isLoadingSearchResults ? (
      <CenterHorizontally>
        <LoadingSpinner />
      </CenterHorizontally>
    ) : (
      searchResults &&
        searchResults.length > 0 &&
        ((searchResultsOnlyWithSearchTerm && searchTerm.length > 0) ||
          !searchResultsOnlyWithSearchTerm) && (
          <ul className={classnames(styles['list-results'], resultsClassName)}>
            {firstResultElement}
            {searchResults
              .filter((_, index) => index < (maxSearchResults || MAX_SEARCH_RESULTS))
              .map((result, index) => renderResultItem(result, index, selectedResult === index))}
          </ul>
        )
    );
  }

  render() {
    const {
      id,
      isLoading,
      isSectionInEditMode,
      searchClassName,
      searchInputContainerClassName,
      label,
      inputLabel,
      searchInputChanged,
      searchInputCleared,
      selectedItems,
      renderSelectedList,
      inputFieldRef,
      placeholder = '',
      hideResults = false
    } = this.props;
    const hasItems = selectedItems && selectedItems.length > 0;
    return isSectionInEditMode || hasItems ? (
      <div
        id={`search-content-${id}`}
        tabIndex={0}
        role="button"
        className={classnames(styles.search, searchClassName, 'search-field')}
        onKeyDown={event => {
          this.handleKeyDown(event);
        }}
      >
        <div className={searchInputContainerClassName}>
          {!isSectionInEditMode && <div className={styles.label}>{label}</div>}
          {isSectionInEditMode && (
            <SearchInput
              id={id}
              label={inputLabel}
              searchInputChanged={(searchTerm: string) => {
                this.setState({ selectedResult: 0, searchTerm });
                searchInputChanged(searchTerm);
              }}
              searchInputCleared={() => {
                this.setState({ selectedResult: 0, searchTerm: '' });
                if (searchInputCleared) {
                  searchInputCleared();
                }
              }}
              isLoading={isLoading}
              inputFieldRef={inputFieldRef}
              placeholder={placeholder}
            />
          )}
          {isSectionInEditMode && this.renderResults()}
        </div>
        {selectedItems &&
          renderSelectedList &&
          !hideResults &&
          renderSelectedList(isSectionInEditMode)}
      </div>
    ) : null;
  }
}

export default Search;
