// @flow
/* eslint-disable import/prefer-default-export */

export const MAX_RECENT_RESULTS = 15;
export const MAX_FAVOURITES_RESULTS = 50;
export const LOCALSTORE_WRITE_THROTTLE = 1000;
export const PEOPLE_CATEGORY_KEYS = [
  'ALL',
  'INTERNAL_CONTACTS',
  'EXTERNAL_CONTACTS',
  'SERVICES',
  'EFAXES'
];
export const INPUT_DEBOUNCE_DURATION = 300;
export const STICKY_ELEMENT_DEBOUNCE_DURATION = 20;
export const USERS_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 100000;
export const MAX_MANUAL_CUSTOM_PRESENCE_STATE_CHARS = 512;
