// @flow

/* eslint-disable import/prefer-default-export */

import type { StoreStateT } from '../../../commonTypes';
import type { NotificationT, NotificationTagT } from './notificationUiTypes';

type ByTagsFnT = (StoreStateT, NotificationTagT[]) => NotificationT[];
export const byTags: ByTagsFnT = ({ ui: { notification } }, tags) =>
  notification.allIds
    .filter(id => tags.includes(notification.byId[id].tag))
    .map(id => notification.byId[id]);
