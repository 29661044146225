// @flow

// eslint-disable-next-line import/prefer-default-export
export const setActiveLang = (
  pathname: string,
  fiPage: string,
  enPage: string,
  svPage: string,
  etPage: string,
  func: string => *
) => {
  if (pathname.includes(fiPage)) {
    func('fi');
  } else if (pathname.includes(enPage)) {
    func('en');
  } else if (pathname.includes(svPage)) {
    func('sv');
  } else if (pathname.includes(etPage)) {
    func('et');
  }
};

export const goToActiveLangPage = (
  currentLanguage: string,
  goToFi: () => *,
  goToEn: () => *,
  goToSv: () => *,
  goToEt: () => *
) => {
  switch (currentLanguage) {
    case 'fi':
      goToFi();
      break;
    case 'en':
      goToEn();
      break;
    case 'sv':
      goToSv();
      break;
    case 'et':
      goToEt();
      break;
    default:
  }
};
