// @flow strict-local

import React, { type Element } from 'react';
import * as R from 'ramda';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import type { ExactPropsT } from '../../../../commonTypes';
import type { FiltersOnTargetNumberTypeT } from '../../../../ducks/entities/callForwarding/callForwardingTypes';

import styles from './FiltersOnTargetNumber.module.scss';

type OwnPropsT = {|
  handleForwardingTargetNumberFilterChange: (option: FiltersOnTargetNumberTypeT) => void,
  filtersOnTargetNumber: FiltersOnTargetNumberTypeT[],
  errorMessage: string
|};

export type PropsT = ExactPropsT<OwnPropsT>;

export const FiltersOnTargetNumber = ({
  handleForwardingTargetNumberFilterChange,
  filtersOnTargetNumber,
  errorMessage
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  return (
    <div className={styles['create-filter-on-target-number-section']} id="on-target-number-box">
      <div className={styles['description-text']}>
        {t('forwardingDetails.filterOnTargetNumber.topic')}
      </div>

      {errorMessage && (
        <div
          data-cy="filter-on-target-number-error"
          className={styles['specific-validation-error']}
        >
          {errorMessage}
        </div>
      )}

      <div className={styles['checkbox-container']}>
        <Checkbox
          id="forwarding-filterOnTargetNumber-option-2"
          name="filterOnTargetNumber-option"
          onChange={() => handleForwardingTargetNumberFilterChange('PSTN')}
          checked={R.includes('PSTN', filtersOnTargetNumber)}
        >
          {t('forwardingDetails.filterOnTargetNumber.pstn')}
        </Checkbox>
      </div>

      <div className={styles['checkbox-container']}>
        <Checkbox
          id="forwarding-filterOnTargetNumber-option-1"
          name="filterOnTargetNumber-option"
          onChange={() => handleForwardingTargetNumberFilterChange('EXT')}
          className={styles.checkbox}
          checked={R.includes('EXT', filtersOnTargetNumber)}
        >
          {t('forwardingDetails.filterOnTargetNumber.external')}
        </Checkbox>
      </div>

      <div className={styles['checkbox-container']}>
        <Checkbox
          id="forwarding-filterOnTargetNumber-option-3"
          name="filterOnTargetNumber-option"
          onChange={() => handleForwardingTargetNumberFilterChange('PLMN')}
          checked={R.includes('PLMN', filtersOnTargetNumber)}
        >
          {t('forwardingDetails.filterOnTargetNumber.plmn')}
        </Checkbox>
      </div>
    </div>
  );
};

export default FiltersOnTargetNumber;
