import React, { Component } from 'react';
import classnames from 'classnames';

// eslint-disable-next-line react/prefer-stateless-function
class FluidGrid extends Component<PropsT> {
  render() {
    const { className, gutter, children, bordered, animated, column, ...props } = this.props;

    const classes = [
      'ea-fgrid',
      { 'ea-fgrid--gutter-mini': gutter === 'minimal' },
      { 'ea-fgrid--gutter': gutter === 'normal' },
      { 'ea-fgrid--bordered': bordered },
      { 'ea-fgrid--animated': animated },
      { 'ea-fgrid--column': column }
    ];

    return (
      <div className={classnames([classes, className])} {...props}>
        {children}
      </div>
    );
  }
}

FluidGrid.defaultProps = {
  className: '',
  children: null,
  bordered: false,
  column: false,
  animated: false,
  gutter: null
};

export default FluidGrid;
