// @flow strict-local

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import type { SpeedDialEntityT } from '../../../../ducks/entities/speedDial/speedDialTypes';
import type { SpeedDialGridElementT } from '../../../../ducks/entities/callFlow/callFlowTypes';

import GridElementContainer from '../gridElement/GridElementContainer';
import SpeedDialDetails from '../details/speeddial/SpeedDialDetails';
import EditSpeedDialDetails from '../details/speeddial/EditSpeedDialDetails';

export type PropsT = {|
  data: SpeedDialEntityT,
  enterpriseId: string,
  hideDescription: boolean,
  single?: boolean
|};

export const SpeedDialElement = (props: PropsT): Element<typeof GridElementContainer> => {
  const { data, enterpriseId, hideDescription, single } = props;
  const { t } = useTranslation();

  const gridElement: SpeedDialGridElementT = {
    id: data.id,
    type: 'SPEED_DIAL',
    name: data.label || '',
    number: data.addressNumber,
    description: t('callflows.type.speedDial.description'),
    enterpriseId,
    rootNode: {
      id: 'speed_dial',
      type: 'SPEED_DIAL'
    }
  };
  const callflowId = `${enterpriseId}-SPEED_DIAL-${data.id}`;
  return (
    <GridElementContainer
      gridElement={gridElement}
      hideDescription={hideDescription}
      single={single}
      viewContentSupplier={() => <SpeedDialDetails callflowId={callflowId} />}
      editContentSupplier={() => <EditSpeedDialDetails callflowId={callflowId} />}
    />
  );
};

export default SpeedDialElement;
