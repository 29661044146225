import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLockOpenFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9h-9.5V6.2C12.5 2.8 9.7 0 6.2 0S0 2.8 0 6.2v3.2c0 .4.3.8.8.8s.8-.3.8-.8V6.2c0-2.6 2.1-4.8 4.8-4.8S11 3.6 11 6.2V9H8c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2m-6.4 9c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-3c0-.3.3-.6.6-.6s.6.3.6.6z"/></svg>)}
    />
  );
}
  