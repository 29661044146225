import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAsterixRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.25 12a.76.76 0 0 1-.75.75h-6.2l3.1 5.37a.74.74 0 0 1-.28 1 .7.7 0 0 1-.37.11.76.76 0 0 1-.65-.38L12 13.5l-3.1 5.37a.76.76 0 0 1-.65.38.7.7 0 0 1-.37-.11.74.74 0 0 1-.28-1l3.1-5.37H4.5a.75.75 0 0 1 0-1.5h6.2L7.6 5.88a.75.75 0 0 1 1.3-.75L12 10.5l3.1-5.37a.75.75 0 0 1 1.3.75l-3.1 5.37h6.2a.76.76 0 0 1 .75.75"/></svg>)}
    />
  );
}
  