"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Notification(_a) {
    var count = _a.count, icon = _a.icon, onClick = _a.onClick, tag = _a.tag, target = _a.target, text = _a.text, url = _a.url;
    return {
        count: count,
        onClick: onClick,
        icon: icon,
        tag: tag,
        target: target,
        text: text,
        url: url,
    };
}
exports.default = Notification;
