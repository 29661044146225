import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRouterLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2m4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2M20 5.999a.5.5 0 0 1-.35.85.5.5 0 0 1-.36-.15 2.5 2.5 0 0 0-3.53 0 .5.5 0 0 1-.71 0 .5.5 0 0 1-.05-.7 3.49 3.49 0 0 1 5 0m-7.09-1.39a.5.5 0 0 1-.003-.707l.003-.003a6.51 6.51 0 0 1 9.19 0 .5.5 0 0 1 .003.707l-.003.003a.48.48 0 0 1-.678.022l-.022-.022a5.5 5.5 0 0 0-7.778-.002l-.002.002a.5.5 0 0 1-.707.003zm7.09 9.39h-2v-4.5a.5.5 0 0 0-1 0v4.5H4a4 4 0 0 0 0 8v.5a.5.5 0 1 0 1 .001V22h14v.5a.5.5 0 0 0 1 0V22a4 4 0 0 0 0-8.001M20 21H4a3 3 0 1 1 0-6h16a3 3 0 1 1 0 6"/></svg>)}
    />
  );
}
  