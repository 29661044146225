// @flow

import React, { type Element } from 'react';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import styles from './PresenceCalendar.module.scss';

export type PropsT = {
  selectedDate: Date,
  selectDate: () => void
};

const PresenceCalendar = ({ selectedDate, selectDate }: PropsT): Element<'div'> => {
  return (
    <div className={styles['calendar-area']}>
      <CustomDatePicker selected={selectedDate} onChange={selectDate} minDate={new Date()} inline />
    </div>
  );
};
export default PresenceCalendar;
