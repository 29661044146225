import React from 'react';
import IconContainer from '../IconContainer';

export default function IconThumbUpFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 20.5V10c0-.3-.2-.5-.5-.5H18q-.75 0-1.2-.6L11.9 2c-.7-1-2.1-1.2-3.1-.5C8.1 2 7.7 3 8 3.9l.9 3.2c.1.5 0 .9-.3 1.3s-.7.6-1.2.6H2.5c-.8 0-1.5.4-2 1s-.6 1.4-.4 2.2v.1L2 18.6v.1c1 3.1 2.7 4.6 5.2 4.6h3.2c2.9 0 4.1-.2 8.3-2.3.2-.1.3-.3.3-.5m4.5 2.5h-1.7c-.8 0-1.5-.7-1.5-1.5v-12c0-.8.7-1.5 1.5-1.5h1.7c.3 0 .5.2.5.5v14c0 .3-.2.5-.5.5"/></svg>)}
    />
  );
}
  