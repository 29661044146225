import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFolderOpenLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 22c-1 0-1.8-.7-2-1.6l-2-12V8c0-.5.2-1 .6-1.4S1.5 6 2 6h6.5c1 0 1.8.7 2 1.6l.2 1.4H22c.6 0 1.2.3 1.5.7.4.5.6 1.1.5 1.7l-2 9c-.2.9-1 1.6-2 1.6zM2 7c-.6 0-1 .4-1 1v.2l2 12c.1.5.5.8 1 .8h16c.5 0 .9-.3 1-.8l2-9c.1-.3 0-.6-.2-.8-.2-.3-.5-.4-.8-.4H10.7c-.5 0-.9-.3-1-.8l-.3-1.4c-.1-.5-.5-.8-1-.8zm20 .5v-3C22 3.1 20.9 2 19.5 2h-15C3.1 2 2 3.1 2 4.5c0 .3.2.5.5.5s.5-.2.5-.5C3 3.7 3.7 3 4.5 3h15c.8 0 1.5.7 1.5 1.5v3c0 .3.2.5.5.5s.5-.2.5-.5"/></svg>)}
    />
  );
}
  