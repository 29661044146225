// @flow

import React, { type Element } from 'react';
import Tooltip from './Tooltip';
import styles from './TooltipExtension.module.scss';

export type PropsT = {|
  title: string,
  text: string,
  subText?: string
|};

export const TooltipExtension = (props: PropsT): Element<typeof Tooltip> => {
  const { title, text, subText } = props;

  return (
    <Tooltip containerStyle={styles.tooltip}>
      <div className={styles['tooltip-title']}>{title}</div>
      <div className={styles['tooltip-text']}>{text}</div>
      {subText && (
        <div>
          <br />
          <b>{subText}</b>
        </div>
      )}
    </Tooltip>
  );
};

export default TooltipExtension;
