// @flow

import { type $AxiosError } from 'axios';
import * as R from 'ramda';
import type {
  LocationsPayloadT,
  RetrieveLocationCollectionRequestT,
  RetrieveLocationCollectionSuccessT,
  RetrieveLocationCollectionFailureT,
  LocationDeleteRequestT,
  LocationDeleteSuccessT,
  LocationDeleteFailureT,
  LocationPostRequestT,
  LocationPostSuccessT,
  LocationPostFailureT,
  LocationPayloadEntityT,
  LocationDeleteCancelT,
  LocationPostCancelT,
  RetrieveLocationCollectionCancelT
} from './locationTypes';

type CreateRetrieveLocationCollectionRequestFnT = (
  string,
  ?{}
) => RetrieveLocationCollectionRequestT;

export const createRetrieveLocationCollectionRequest: CreateRetrieveLocationCollectionRequestFnT = (
  enterpriseId,
  meta = {}
) => ({
  type: 'ring/entity/location/RETRIEVE_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});

type CreateRetrieveLocationCollectionSuccessFnT = (
  string,
  LocationsPayloadT,
  ?{}
) => RetrieveLocationCollectionSuccessT;

export const createRetrieveLocationCollectionSuccess: CreateRetrieveLocationCollectionSuccessFnT = (
  enterpriseId,
  locations,
  meta = {}
) => ({
  type: 'ring/entity/location/RETRIEVE_COLLECTION_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId
  },
  payload: R.map(R.assoc('enterpriseId', enterpriseId), locations.results)
});

type CreateRetrieveLocationCollectionFailureFnT = (
  string,
  $AxiosError<*>,
  ?{}
) => RetrieveLocationCollectionFailureT;

export const createRetrieveLocationCollectionFailure: CreateRetrieveLocationCollectionFailureFnT = (
  enterpriseId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/location/RETRIEVE_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});

type CreateRetrieveLocationCollectionCancelFnT = () => RetrieveLocationCollectionCancelT;
export const createRetrieveLocationCollectionCancel: CreateRetrieveLocationCollectionCancelFnT = () => ({
  type: 'ring/entity/location/RETRIEVE_COLLECTION_CANCEL',
  error: false,
  meta: {}
});

type CreateDeleteLocationRequestFnT = () => LocationDeleteRequestT;
export const createDeleteLocationRequest: CreateDeleteLocationRequestFnT = () => ({
  type: 'ring/entity/location/DELETE_REQUEST',
  error: false,
  payload: {},
  meta: {}
});

type CreateDeleteLocationFailureFnT = (string, string, $AxiosError<*>) => LocationDeleteFailureT;
export const createDeleteLocationFailure: CreateDeleteLocationFailureFnT = (
  enterpriseId,
  locationId,
  error
) => ({
  type: 'ring/entity/location/DELETE_FAILURE',
  payload: error,
  error: true,
  meta: { enterpriseId, locationId }
});

type CreateDeleteLocationSuccessFnT = (string, string) => LocationDeleteSuccessT;
export const createDeleteLocationSuccess: CreateDeleteLocationSuccessFnT = (
  enterpriseId,
  locationId
) => ({
  type: 'ring/entity/location/DELETE_SUCCESS',
  error: false,
  meta: {},
  payload: { enterpriseId, locationId }
});

type CreateDeleteLocationCancelFnT = () => LocationDeleteCancelT;
export const createDeleteLocationCancel: CreateDeleteLocationCancelFnT = () => ({
  type: 'ring/entity/location/DELETE_CANCEL',
  error: false,
  payload: {},
  meta: {}
});

type CreateUpdateLocationRequestFnT = () => LocationPostRequestT;
export const createUpdateLocationRequest: CreateUpdateLocationRequestFnT = () => ({
  type: 'ring/entity/location/POST_REQUEST',
  payload: {},
  error: false,
  meta: {}
});

type CreateUpdateLocationSuccessFnT = (
  string,
  string,
  LocationPayloadEntityT
) => LocationPostSuccessT;
export const createUpdateLocationSuccess: CreateUpdateLocationSuccessFnT = (
  enterpriseId,
  locationId,
  location
) => ({
  type: 'ring/entity/location/POST_SUCCESS',
  payload: R.assoc('enterpriseId', enterpriseId, location),
  error: false,
  meta: { enterpriseId, locationId }
});

type CreateUpdateLocationFailureFnT = (string, $AxiosError<*>) => LocationPostFailureT;
export const createUpdateLocationFailure: CreateUpdateLocationFailureFnT = (locationId, error) => ({
  type: 'ring/entity/location/POST_FAILURE',
  payload: error,
  error: true,
  meta: { locationId }
});

type CreateUpdateLocationCancelFnT = () => LocationPostCancelT;
export const createUpdateLocationCancel: CreateUpdateLocationCancelFnT = () => ({
  type: 'ring/entity/location/POST_CANCEL',
  payload: {},
  error: false,
  meta: {}
});
