import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEditFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 4.237a4.26 4.26 0 0 1-1.159 2.876c-.048.05-.137.07-.182.078a.25.25 0 0 1-.177-.073l-5.601-5.601a.25.25 0 0 1 .005-.358A4.26 4.26 0 0 1 19.762 0c1.161 0 2.199.425 3.007 1.229.79.794 1.24 1.89 1.231 3.008M2.069 16.885A.25.25 0 0 0 1.65 17L.015 23.377a.5.5 0 0 0 .609.607l6.383-1.637a.25.25 0 0 0 .115-.419zM15.997 2.4a.25.25 0 0 0-.354 0L2.675 15.369a.25.25 0 0 0 0 .354l5.607 5.597a.25.25 0 0 0 .354 0L21.599 8.356a.25.25 0 0 0 0-.354z"/></svg>)}
    />
  );
}
  