import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDiamondLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.734 6.753 19.036.4a1 1 0 0 0-.8-.4H5.741a1 1 0 0 0-.8.4L.242 6.753a1.25 1.25 0 0 0 0 1.481L11.239 23.6a1 1 0 0 0 .64.4h.11a.94.94 0 0 0 .57-.18q.134-.096.23-.23L23.784 8.233a1.26 1.26 0 0 0-.05-1.48m-7.887 1.25-3.859 13.666L8.13 8.003zm-7.457-1 3.599-5.582 3.599 5.582zM12.908 1h4.918l-1.499 5.302zM7.65 6.303 6.15 1h4.918zm3.239 15.156L1.292 8.003H7.09zm5.997-13.456h5.808l-9.607 13.456zm.28-1 1.519-5.392 3.999 5.392zM5.291 1.611 6.81 7.003H1.302z"/></svg>)}
    />
  );
}
  