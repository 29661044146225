// @flow strict-local

/* eslint-disable react/no-unused-prop-types */

import React, { type Element } from 'react';

import { type TableRowItemT } from './TableRow';
import ForwardingRowContents from './ForwardingRowContents';

import styles from './SmallTableRow.module.scss';

export type PropsT = {
  columns: string[],
  rowItem: TableRowItemT
};

const SmallTableRow = ({ columns, rowItem }: PropsT): Element<'div'> => (
  <div className={styles.row}>
    <div className={styles['action-cell']}>{rowItem.action}</div>
    <ForwardingRowContents columns={columns} rowItem={rowItem} />
  </div>
);

export default SmallTableRow;
