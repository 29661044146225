// @flow

import React, { type Element } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../components/Tooltip';

import styles from './CallflowList.module.scss';

type PropsT = {|
  id: string,
  title: string,
  tooltip?: string,
  values: (Element<*> | string)[],
  key?: string,
  active?: boolean,
  className?: string,
  headerStyle?: string
|};

export const CallflowList = (props: PropsT): Element<'div'> => {
  const { id, key, title, values, active, tooltip, className, headerStyle } = props;
  const activeStyle = active ? styles.active : styles.inactive;
  const { t } = useTranslation();
  return (
    <div className={classnames(styles.detail, className)} key={key} data-cy={id}>
      <div className={classnames(styles.header, activeStyle, headerStyle)}>
        {title}
        {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
      </div>
      {(values || []).map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classnames(styles.value, activeStyle)} key={`item-${index}`}>
          {value}
        </div>
      ))}
      {(!values || values.length === 0) && t('callflows.valueNotSet')}
    </div>
  );
};

export default CallflowList;
