"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function LogoAndServiceLogo(_a) {
    var label = _a.label, _b = _a.logo, logo = _b === void 0 ? null : _b, onClick = _a.onClick, target = _a.target, url = _a.url;
    return {
        label: label,
        logo: logo,
        onClick: onClick,
        target: target,
        url: url,
    };
}
exports.default = LogoAndServiceLogo;
