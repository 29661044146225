import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmartWatchLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 4h-1.09l-.46-2.33a2 2 0 0 0-2-1.67h-5a2 2 0 0 0-2 1.65L7.09 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1.09l.46 2.33a2 2 0 0 0 2 1.67h5a2 2 0 0 0 2-1.66l.36-2.34H18a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2M8.53 1.83a1 1 0 0 1 1-.83h5a1 1 0 0 1 1 .85L15.89 4H8.11zm6.94 20.33a1 1 0 0 1-1 .84h-5a1 1 0 0 1-1-.85L8.11 20h7.78zM19 18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-5.5-6a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 13.5 12M8 11.25a.75.75 0 1 0 .75.75.76.76 0 0 0-.75-.75m8 0a.75.75 0 1 0 .75.75.76.76 0 0 0-.75-.75m-5.62 3.56a.76.76 0 0 0-1 .28.75.75 0 0 0 1.3.75.76.76 0 0 0-.3-1.03m4-6.92a.75.75 0 0 0-1 .27.76.76 0 0 0 .27 1 .9.9 0 0 0 .38.1.76.76 0 0 0 .65-.38.75.75 0 0 0-.3-.99m-.76 6.92a.76.76 0 0 0-.27 1 .75.75 0 0 0 1.3-.75.76.76 0 0 0-1.03-.25m-4.27-5.9a.76.76 0 0 0 .65.38.9.9 0 0 0 .38-.1.76.76 0 0 0 .27-1 .75.75 0 0 0-1.3.75z"/></svg>)}
    />
  );
}
  