import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDraftSaveRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.51 15a.75.75 0 0 0-.75.75v.75H12.5a1.76 1.76 0 0 0-1.75 1.75v4.25h-9a.25.25 0 0 1-.25-.25V1.75a.25.25 0 0 1 .25-.25h8.5a.75.75 0 0 0 0-1.5h-8.5A1.76 1.76 0 0 0 0 1.75v20.5A1.76 1.76 0 0 0 1.75 24h9.34c.241.001.48-.051.7-.15q.296-.135.53-.36l5.41-5.41c.33-.329.517-.774.52-1.24v-1.09a.76.76 0 0 0-.74-.75m-5.26 6.5v-3.25a.25.25 0 0 1 .25-.25h3.2zm5-21.5A6.75 6.75 0 1 0 24 6.75 6.76 6.76 0 0 0 17.25 0m0 12a5.25 5.25 0 1 1 5.25-5.25A5.26 5.26 0 0 1 17.25 12m2.78-5a.75.75 0 0 1 0 1.06l-2.28 2.19a.8.8 0 0 1-.24.16.73.73 0 0 1-.58 0 .8.8 0 0 1-.18-.16l-2.28-2.22a.75.75 0 0 1 1.06-1.06l1 1V3.75a.75.75 0 0 1 1.5 0v4.19l1-1a.75.75 0 0 1 1 .03z"/></svg>)}
    />
  );
}
  