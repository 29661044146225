// @flow strict-local

import React, { type Element } from 'react';
import classnames from 'classnames';
import uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import type { DynamicPropertyT } from '../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import styles from './CallflowItem.module.scss';

type PropsT = {|
  title: string,
  options: {
    label: string,
    property: DynamicPropertyT<boolean>
  }[],
  notInUseMsg?: string,
  active?: boolean
|};

export const PropertyList = (props: PropsT): Element<'div'> => {
  const { title, options, notInUseMsg, active } = props;
  const { t } = useTranslation();

  const activeStyle = active ? styles.active : styles.inactive;
  const optionsToBeShown = [...options.filter(option => option.property && option.property.value)];
  const optionList = optionsToBeShown.map(option => (
    <div key={uuid()} className={classnames(styles.value, activeStyle)}>
      {option.label}
    </div>
  ));

  const notInUseMessage = notInUseMsg || t('callflows.propertyList.notInUse');

  return (
    <div className={styles.detail} key={uuid()}>
      <div className={classnames(styles.header, activeStyle)}>{title}</div>
      {optionsToBeShown.length ? optionList : notInUseMessage}
    </div>
  );
};

export default PropertyList;
