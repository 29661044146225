import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowRightLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.5 19.5a.5.5 0 0 1-.347-.859l6.879-6.644-6.875-6.641a.5.5 0 0 1 .694-.72l6.876 6.641c.196.19.305.445.305.718s-.107.529-.304.72L8.847 19.36a.5.5 0 0 1-.347.14"/></svg>)}
    />
  );
}
  