import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvoiceLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 0h-15C3.673 0 3 .673 3 1.5V23a1.004 1.004 0 0 0 1.006 1.001.96.96 0 0 0 .628-.228L8 21.136l3.401 2.665a1.01 1.01 0 0 0 1.208-.007L16 21.136l3.352 2.625A1 1 0 0 0 21 23V1.5c0-.827-.673-1.5-1.5-1.5m.5 23-3.691-2.894a.5.5 0 0 0-.617 0l-3.683 2.9-3.7-2.9a.5.5 0 0 0-.618 0L4 23V1.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 .5.5zM6 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m8 4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 .5.5m-2 4a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5m6 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m0-4a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m0-4a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0"/></svg>)}
    />
  );
}
  