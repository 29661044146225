import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvoiceFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 0h-15A1.5 1.5 0 0 0 3 1.5v21.499A1.004 1.004 0 0 0 4.006 24a.96.96 0 0 0 .628-.228l3.212-2.517a.25.25 0 0 1 .308 0l3.247 2.545c.353.264.846.265 1.208-.007l3.237-2.538a.25.25 0 0 1 .308 0l3.198 2.506A1.002 1.002 0 0 0 21 22.999V1.5A1.5 1.5 0 0 0 19.5 0m-13 4.875h5a.625.625 0 0 1 0 1.25h-5a.625.625 0 0 1 0-1.25m5 9.25h-5a.625.625 0 0 1 0-1.25h5a.625.625 0 0 1 0 1.25m2-4h-7a.625.625 0 0 1 0-1.25h7a.625.625 0 0 1 0 1.25m3.75 4.375a1 1 0 1 1 0-2 1 1 0 0 1 0 2m0-4a1 1 0 1 1 0-2 1 1 0 0 1 0 2m0-4a1 1 0 1 1 0-2 1 1 0 0 1 0 2"/></svg>)}
    />
  );
}
  