// @flow

import { type $AxiosError } from 'axios';
import * as R from 'ramda';
import type {
  UsersPayloadT,
  UserEntityT,
  RetrieveUserRequestT,
  RetrieveUserFailureT,
  RetrieveUserSuccessT,
  RetrieveUserCollectionRequestT,
  RetrieveUserCollectionSuccessT,
  RetrieveUserCollectionFailureT,
  ExportUsersRequestT,
  ExportUsersSuccessT,
  ExportUsersFailureT,
  UpdateUserRequestT,
  UpdateUserSuccessT,
  UpdateUserFailureT,
  CreateUserRequestT,
  CreateUserSuccessT,
  CreateUserFailureT,
  DeleteUserSuccessT,
  DeleteUserFailureT,
  DeleteUserRequestT,
  RetrieveUserCompleteCollectionRequestT,
  RetrieveUserCompleteCollectionSuccessT,
  RetrieveUserCompleteCollectionFailureT,
  RetrieveUserCancelT,
  RetrieveUserCollectionCancelT,
  RetrieveUserCompleteCollectionCancelT,
  UpdateUserCancelT,
  CreateUserCancelT,
  DeleteUserCancelT,
  ExportUsersCancelT,
  RetrieveUserForwardingsRequestT,
  RetrieveUserForwardingsSuccessT,
  RetrieveUserForwardingsFailureT,
  ResetUserPasswordRequestT,
  ResetUserPasswordSuccessT,
  ResetUserPasswordFailureT,
  ResetUserPasswordCancelT,
  RemoveLocationFromUsersActionT,
  ExportUsersProcessRequestT,
  ExportUsersProcessSuccessT,
  ExportUsersProcessFailureT,
  ExportUsersProcessCancelT,
  ExportUsersProcessT,
  ExportUsersStartedSuccessT,
  AvatarEntitiesT
} from './userTypes';
import type { CallForwardingT } from '../callForwarding/callForwardingTypes';
import type {
  RetrieveAvatarCancelT,
  RetrieveAvatarFailureT,
  RetrieveAvatarRequestT,
  RetrieveAvatarSuccessT
} from '../directory/directoryTypes';

export const createRetrieveUserForwardingsRequest = (
  enterpriseId: string,
  id: string
): RetrieveUserForwardingsRequestT => ({
  type: 'ring/entity/user/RETRIEVE_FORWARDINGS_REQUEST',
  error: false,
  meta: { enterpriseId, id }
});
export const createRetrieveUserForwardingsSuccess = (
  enterpriseId: string,
  id: string,
  forwardings: CallForwardingT[]
): RetrieveUserForwardingsSuccessT => ({
  type: 'ring/entity/user/RETRIEVE_FORWARDINGS_SUCCESS',
  error: false,
  meta: { enterpriseId, id },
  payload: forwardings
});

export const createRemoveLocationFromUsers = (
  locationName: string
): RemoveLocationFromUsersActionT => ({
  type: 'ring/entity/user/REMOVE_LOCATION_FROM_USERS',
  error: false,
  meta: { locationName }
});

export const createRetrieveUserForwardingsFailure = (
  enterpriseId: string,
  id: string,
  error: $AxiosError<*>
): RetrieveUserForwardingsFailureT => ({
  type: 'ring/entity/user/RETRIEVE_FORWARDINGS_FAILURE',
  error: true,
  meta: { enterpriseId, id },
  payload: error
});

export const createRetrieveUserRequest = (
  enterpriseId: string,
  id: string
): RetrieveUserRequestT => ({
  type: 'ring/entity/user/RETRIEVE_REQUEST',
  error: false,
  meta: { enterpriseId, id }
});
export const createRetrieveUserSuccess = (
  enterpriseId: string,
  id: string,
  user: UserEntityT
): RetrieveUserSuccessT => ({
  type: 'ring/entity/user/RETRIEVE_SUCCESS',
  error: false,
  meta: { enterpriseId, id },
  payload: R.assoc('enterpriseId', enterpriseId, user)
});
export const createRetrieveUserFailure = (
  enterpriseId: string,
  id: string,
  error: $AxiosError<*>
): RetrieveUserFailureT => ({
  type: 'ring/entity/user/RETRIEVE_FAILURE',
  error: true,
  meta: { enterpriseId, id },
  payload: error
});
export const createRetrieveUserCancel = (id: string): RetrieveUserCancelT => ({
  type: 'ring/entity/user/RETRIEVE_CANCEL',
  error: false,
  meta: { id },
  payload: {}
});

export const createRetrieveUserCollectionRequest = (
  enterpriseId: string,
  meta: {} = {}
): RetrieveUserCollectionRequestT => ({
  type: 'ring/entity/user/RETRIEVE_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});
export const createRetrieveUserCollectionSuccess = (
  enterpriseId: string,
  users: UsersPayloadT,
  meta: {} = {}
): RetrieveUserCollectionSuccessT => ({
  type: 'ring/entity/user/RETRIEVE_COLLECTION_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId,
    length: users.length,
    totalCount: users.totalCount,
    offset: users.offset
  },
  payload: R.map(R.assoc('enterpriseId', enterpriseId), (users.results: any)) // eslint-disable-line flowtype/no-weak-types
});
export const createRetrieveUserCollectionFailure = (
  enterpriseId: string,
  error: $AxiosError<*>,
  meta: {} = {}
): RetrieveUserCollectionFailureT => ({
  type: 'ring/entity/user/RETRIEVE_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});
export const createRetrieveUserCollectionCancel = (): RetrieveUserCollectionCancelT => ({
  type: 'ring/entity/user/RETRIEVE_COLLECTION_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

export const createRetrieveUserCompleteCollectionRequest = (
  enterpriseId: string,
  meta: {} = {}
): RetrieveUserCompleteCollectionRequestT => ({
  type: 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});
export const createRetrieveUserCompleteCollectionSuccess = (
  enterpriseId: string,
  users: UsersPayloadT,
  meta: {} = {}
): RetrieveUserCompleteCollectionSuccessT => ({
  type: 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId
  },
  payload: R.map(R.assoc('enterpriseId', enterpriseId), (users.results: any)) // eslint-disable-line flowtype/no-weak-types
});
export const createRetrieveUserCompleteCollectionFailure = (
  enterpriseId: string,
  error: $AxiosError<*>,
  meta: {} = {}
): RetrieveUserCompleteCollectionFailureT => ({
  type: 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});
export const createRetrieveUserCompleteCollectionCancel = (): RetrieveUserCompleteCollectionCancelT => ({
  type: 'ring/entity/user/RETRIEVE_COMPLETE_COLLECTION_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

export const createUpdateUserRequest = (enterpriseId: string, id: string): UpdateUserRequestT => ({
  type: 'ring/entity/user/UPDATE_REQUEST',
  error: false,
  meta: { enterpriseId, id }
});
export const createUpdateUserSuccess = (
  enterpriseId: string,
  id: string,
  user: UserEntityT
): UpdateUserSuccessT => ({
  type: 'ring/entity/user/UPDATE_SUCCESS',
  error: false,
  meta: { enterpriseId, id },
  payload: R.assoc('enterpriseId', enterpriseId, user)
});
export const createUpdateUserFailure = (
  enterpriseId: string,
  id: string,
  error: $AxiosError<*>
): UpdateUserFailureT => ({
  type: 'ring/entity/user/UPDATE_FAILURE',
  error: true,
  meta: { enterpriseId, id },
  payload: error
});
export const createUpdateUserCancel = (id: string): UpdateUserCancelT => ({
  type: 'ring/entity/user/UPDATE_CANCEL',
  error: false,
  meta: { id },
  payload: {}
});

export const createCreateUserRequest = (enterpriseId: string): CreateUserRequestT => ({
  type: 'ring/entity/user/CREATE_REQUEST',
  error: false,
  meta: { enterpriseId }
});
export const createCreateUserSuccess = (
  enterpriseId: string,
  user: UserEntityT
): CreateUserSuccessT => ({
  type: 'ring/entity/user/CREATE_SUCCESS',
  error: false,
  meta: { enterpriseId },
  payload: R.assoc('enterpriseId', enterpriseId, user)
});
export const createCreateUserFailure = (
  enterpriseId: string,
  error: $AxiosError<*>
): CreateUserFailureT => ({
  type: 'ring/entity/user/CREATE_FAILURE',
  error: true,
  meta: { enterpriseId },
  payload: error
});
export const createCreateUserCancel = (): CreateUserCancelT => ({
  type: 'ring/entity/user/CREATE_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

export const createDeleteUserRequest = (enterpriseId: string, id: string): DeleteUserRequestT => ({
  type: 'ring/entity/user/DELETE_REQUEST',
  error: false,
  meta: { enterpriseId, id }
});
export const createDeleteUserSuccess = (enterpriseId: string, id: string): DeleteUserSuccessT => ({
  type: 'ring/entity/user/DELETE_SUCCESS',
  error: false,
  meta: { enterpriseId, id },
  payload: null
});
export const createDeleteUserFailure = (
  enterpriseId: string,
  id: string,
  error: $AxiosError<*>
): DeleteUserFailureT => ({
  type: 'ring/entity/user/DELETE_FAILURE',
  error: true,
  meta: { enterpriseId, id },
  payload: error
});
export const createDeleteUserCancel = (id: string): DeleteUserCancelT => ({
  type: 'ring/entity/user/DELETE_CANCEL',
  error: false,
  meta: { id },
  payload: {}
});

export const createExportUsersRequest = (
  enterpriseId: string,
  meta: {} = {}
): ExportUsersRequestT => ({
  type: 'ring/entity/user/EXPORT_USERS_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});

export const createExportUsersStartedSuccess = (
  enterpriseId: string,
  uuid: string,
  meta: {} = {}
): ExportUsersStartedSuccessT => ({
  type: 'ring/entity/user/EXPORT_USERS_STARTED_SUCCESS',
  error: false,
  meta: { ...meta, enterpriseId },
  payload: uuid
});

export const createExportUsersSuccess = (
  enterpriseId: string,
  meta: {} = {}
): ExportUsersSuccessT => ({
  type: 'ring/entity/user/EXPORT_USERS_SUCCESS',
  error: false,
  meta: { ...meta, enterpriseId },
  payload: null
});

export const createExportUsersFailure = (
  enterpriseId: string,
  error: $AxiosError<*>
): ExportUsersFailureT => ({
  type: 'ring/entity/user/EXPORT_USERS_FAILURE',
  error: true,
  meta: { enterpriseId },
  payload: error
});
export const createExportUsersCancel = (): ExportUsersCancelT => ({
  type: 'ring/entity/user/EXPORT_USERS_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

export const createExportUsersProcessRequest = (
  enterpriseId: string,
  meta: {} = {}
): ExportUsersProcessRequestT => ({
  type: 'ring/entity/user/EXPORT_USERS_PROCESS_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});
export const createExportUsersProcessSuccess = (
  enterpriseId: string,
  processed: ExportUsersProcessT,
  meta: {} = {}
): ExportUsersProcessSuccessT => ({
  type: 'ring/entity/user/EXPORT_USERS_PROCESS_SUCCESS',
  error: false,
  meta: { ...meta, enterpriseId },
  payload: processed
});
export const createExportUsersProcessFailure = (
  enterpriseId: string,
  error: $AxiosError<*>
): ExportUsersProcessFailureT => ({
  type: 'ring/entity/user/EXPORT_USERS_PROCESS_FAILURE',
  error: true,
  meta: { enterpriseId },
  payload: error
});
export const createExportUsersProcessCancel = (): ExportUsersProcessCancelT => ({
  type: 'ring/entity/user/EXPORT_USERS_PROCESS_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

export const createResetUserPasswordRequest = (id: string): ResetUserPasswordRequestT => ({
  type: 'ring/entity/user/RESET_USER_PASSWORD_REQUEST',
  error: false,
  meta: { id }
});
export const createResetUserPasswordSuccess = (id: string): ResetUserPasswordSuccessT => ({
  type: 'ring/entity/user/RESET_USER_PASSWORD_SUCCESS',
  error: false,
  meta: { id },
  payload: null
});
export const createResetUserPasswordFailure = (
  id: string,
  error: $AxiosError<*>
): ResetUserPasswordFailureT => ({
  type: 'ring/entity/user/RESET_USER_PASSWORD_FAILURE',
  error: true,
  meta: { id },
  payload: error
});
export const createResetUserPasswordCancel = (id: string): ResetUserPasswordCancelT => ({
  type: 'ring/entity/user/RESET_USER_PASSWORD_CANCEL',
  error: false,
  meta: { id },
  payload: {}
});

export const createRetrieveAvatarRequest = (meta: {} = {}): RetrieveAvatarRequestT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_REQUEST',
  error: false,
  meta: { ...meta }
});
export const createRetrieveAvatarSuccess = (
  avatarEntities: AvatarEntitiesT,
  meta: {} = {}
): RetrieveAvatarSuccessT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_SUCCESS',
  error: false,
  meta: {
    ...meta
  },
  payload: avatarEntities
});
export const createRetrieveAvatarFailure = (
  error: $AxiosError<*>,
  meta: {} = {}
): RetrieveAvatarFailureT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_FAILURE',
  error: true,
  meta: { ...meta },
  payload: error
});
export const createRetrieveAvatarCancel = (): RetrieveAvatarCancelT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_CANCEL',
  error: false,
  meta: {},
  payload: {}
});
