// @flow

/* eslint-disable: no-unused-vars */
import HTTP from 'http-status-codes';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import axios, { type AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { change, Field, type FormProps, formValueSelector, reduxForm } from 'redux-form';
import { Accordion, Card, CardContainer } from '@design-system/component-library';
import Link from '@design-system/component-library/src/components/Link';
import IconLogoAppleRegular from '@design-system/component-library/src/components/Icon/lib/IconLogoAppleRegular';
import IconPlayFilled from '@design-system/component-library/src/components/Icon/lib/IconPlayFilled';
import IconArrowRightAltRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowRightAltRegular';
import IconMessageRegular from '@design-system/component-library/src/components/Icon/lib/IconMessageRegular';
import IconWarningRegular from '@design-system/component-library/src/components/Icon/lib/IconWarningRegular';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { ReactComponent as MobileIcon } from '../../assets/public/mobile.svg';
import { ReactComponent as ComputerIcon } from '../../assets/public/computer.svg';
import { ReactComponent as BrowserIcon } from '../../assets/public/browser.svg';
import type { StoreStateT } from '../../commonTypes';
import { InputField } from '../../components/InputComponent/ReduxFormField';
import LanguageSelector from '../login/LanguageSelector';
import Footer from '../login/Footer';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import { PHONE_NUMBER_MAX_LENGTH } from '../../fieldValidators';

import styles from './Downloads.module.scss';

type SearchResultT = { environment: string, type: 'ADMIN' | 'USER' };

export type OwnPropsT = { noDebounce?: boolean };

type StatePropsT = {
  phoneNumber: string
};
type DispatchPropsT = {
  resetSearch: () => *
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<ContextRouter>,
  ...$Exact<FormProps>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {
  loading: boolean,
  error: ?('notFound' | 'generic' | 'noNumberEntered'),
  environment: string,
  type: ?('ADMIN' | 'USER')
};

const SEARCH_DEBOUNCE_DURATION = 500;

export class Downloads extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.searchPhoneNumber = this.props.noDebounce
      ? this.searchPhoneNumber.bind(this)
      : debounce(this.searchPhoneNumber.bind(this), SEARCH_DEBOUNCE_DURATION);
    this.handlePhoneNumberOnChange = this.handlePhoneNumberOnChange.bind(this);
    this.state = {
      loading: false,
      error: undefined,
      type: undefined,
      environment: ''
    };
  }

  searchNumberRequestCancelTokenSource: CancelTokenSource;

  searchPhoneNumber: *;

  async searchPhoneNumber(searchTerm: string): Promise<void> {
    if (!searchTerm || searchTerm.length < 6) return;

    try {
      this.setState({ loading: true, error: undefined, type: undefined });
      this.searchNumberRequestCancelTokenSource = CancelToken.source();
      const response: AxiosPromise<SearchResultT> = axios({
        method: 'GET',
        url: `/api/v1/search/${searchTerm}`,
        cancelToken: this.searchNumberRequestCancelTokenSource.token
      });
      const {
        data: { environment, type }
      } = await response;

      this.setState({ environment, type });
    } catch (error) {
      if (axios.isCancel(error)) {
        this.setState({ loading: false, error: undefined, type: undefined });
        return;
      }
      if (error.response && error.response.status === HTTP.NOT_FOUND) {
        this.setState({ error: 'notFound' });
      } else {
        this.setState({ error: 'generic' });
      }
    }
    this.setState({ loading: false });
  }

  handlePhoneNumberOnChange: *;

  async handlePhoneNumberOnChange(searchTerm: string): Promise<void> {
    const { loading } = this.state;
    if (loading) {
      this.searchNumberRequestCancelTokenSource.cancel();
    }

    return this.searchPhoneNumber(searchTerm);
  }

  setNoNumberEnteredError(): void {
    const { environment } = this.state;
    if (environment === '') {
      this.setState({ error: 'noNumberEntered' });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderInstructions(): Element<'div'> {
    const { t } = this.props;
    const { environment, type } = this.state;

    const downloadURL = t(`downloads.linkToMyIstraDownload.${environment}`);
    const browserVersion = t(`downloads.browserVersion.${environment}`);
    const eFaxDomain = t(`downloads.eFaxDomain.${environment}`);
    return (
      <div>
        {type && (
          <div>
            <div>
              <p>{t('downloads.efax1', { eFaxDomain })}</p>
              <p>
                <a
                  href="https://yrityksille.elisa.fi/ohje/elisa-efax"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('downloads.efax2')}
                </a>
              </p>
            </div>
            <div className={styles['section-title']}>{t('downloads.sections.mainTitle1')}</div>
            <CardContainer cols={3} id="section-1">
              <Card
                badge=""
                heading={
                  <div className={styles['subsection-card']}>
                    <div>
                      <MobileIcon />
                    </div>
                    <div className={styles['subsection-title']}>
                      {t('downloads.sections.title1')}
                    </div>
                  </div>
                }
                content={
                  <div className={styles['subsection-card']}>
                    {t('downloads.sections.content1')}
                  </div>
                }
                footer={
                  <div className={styles['subsection-card']}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      buttonSize="l"
                      linkStyle="link-button"
                      buttonColor="primary"
                      linkHref="https://itunes.apple.com/fi/app/elisa-ring/id1212766084"
                      linkTarget="_blank"
                      className={styles['link-button']}
                    >
                      <IconLogoAppleRegular color="white" />
                      {t('downloads.sections.button11')}
                    </Link>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      buttonSize="l"
                      linkStyle="link-button"
                      buttonColor="primary"
                      linkHref="https://play.google.com/store/apps/details?id=fi.elisa.ring"
                      linkTarget="_blank"
                      className={styles['link-button']}
                    >
                      <IconPlayFilled color="white" />
                      {t('downloads.sections.button12')}
                    </Link>
                  </div>
                }
                type="white"
              />
              <Card
                badge=""
                heading={
                  <div className={styles['subsection-card']}>
                    <div>
                      <ComputerIcon />
                    </div>
                    <div className={styles['subsection-title']}>
                      {t('downloads.sections.title2')}
                    </div>
                  </div>
                }
                content={
                  <div className={styles['subsection-card']}>
                    {t('downloads.sections.content2')}
                  </div>
                }
                footer={
                  <div className={styles['subsection-card']}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      buttonSize="l"
                      linkStyle="link-button"
                      buttonColor="linkblue"
                      linkHref={environment === '' ? '#' : downloadURL}
                      linkTarget={environment === '' ? '_self' : '_blank'}
                      className={styles['link-button']}
                      onClick={() => this.setNoNumberEnteredError()}
                    >
                      {t('downloads.sections.button2')}
                    </Link>
                  </div>
                }
                type="white"
              />
              <Card
                badge=""
                heading={
                  <div className={styles['subsection-card']}>
                    <div>
                      <BrowserIcon />
                    </div>
                    <div className={styles['subsection-title']}>
                      {t('downloads.sections.title3')}
                    </div>
                  </div>
                }
                content={
                  <div className={styles['subsection-card']}>
                    {t('downloads.sections.content3')}
                  </div>
                }
                footer={
                  <div className={styles['subsection-card']}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      buttonSize="l"
                      linkStyle="link-button"
                      buttonColor="light"
                      linkHref={environment === '' ? '#' : browserVersion}
                      linkTarget={environment === '' ? '_self' : '_blank'}
                      className={styles['link-button']}
                      onClick={() => this.setNoNumberEnteredError()}
                    >
                      {t('downloads.sections.button3')}
                      <IconArrowRightAltRegular color="black" />
                    </Link>
                  </div>
                }
                type="white"
              />
            </CardContainer>
            {type === 'ADMIN' && (
              <div>
                <hr />
                <div className={styles['section-title']}>{t('downloads.sections.mainTitle2')}</div>
                <CardContainer cols={2} id="section-2">
                  <Card
                    badge=""
                    heading={
                      <div className={styles['subsection-card']}>
                        <div className={styles['subsection-title']}>
                          {t('downloads.sections.title4')}
                        </div>
                      </div>
                    }
                    content={
                      <div className={styles['subsection-card']}>
                        {t('downloads.sections.content4')}
                      </div>
                    }
                    footer={
                      <div className={styles['subsection-card']}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                          buttonSize="l"
                          linkStyle="link-button"
                          buttonColor="light"
                          linkHref="https://ring.elisa.fi"
                          linkTarget="_blank"
                          className={styles['link-button']}
                        >
                          {t('downloads.sections.button4')}
                          <IconArrowRightAltRegular color="black" />
                        </Link>
                        <Accordion className={styles['more-info']}>
                          <Accordion.Item
                            id="item11"
                            heading={t('downloads.sections.moreInfoTitle')}
                          >
                            <p>{t('downloads.sections.moreInfoContent11')}</p>
                            <ul>
                              <li style={{ listStyleType: 'disc' }}>
                                {t('downloads.sections.moreInfoContent12')}
                              </li>
                              <li style={{ listStyleType: 'disc' }}>
                                {t('downloads.sections.moreInfoContent13')}
                              </li>
                              <li style={{ listStyleType: 'disc' }}>
                                {t('downloads.sections.moreInfoContent14')}
                              </li>
                            </ul>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    }
                    type="white"
                  />
                  <Card
                    badge=""
                    heading={
                      <div className={styles['subsection-card']}>
                        <div className={styles['subsection-title']}>
                          {t('downloads.sections.title5')}
                        </div>
                      </div>
                    }
                    content={
                      <div className={styles['subsection-card']}>
                        {t('downloads.sections.content5')}
                      </div>
                    }
                    footer={
                      <div className={styles['subsection-card']}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                          buttonSize="l"
                          linkStyle="link-button"
                          buttonColor="light"
                          linkHref="https://yrityksille.elisa.fi/"
                          linkTarget="_blank"
                          className={styles['link-button']}
                        >
                          {t('downloads.sections.button5')}
                          <IconArrowRightAltRegular color="black" />
                        </Link>
                        <Accordion className={styles['more-info']}>
                          <Accordion.Item
                            id="item21"
                            heading={t('downloads.sections.moreInfoTitle')}
                          >
                            <p>{t('downloads.sections.moreInfoContent21')}</p>
                            <ul>
                              <li style={{ listStyleType: 'disc' }}>
                                {t('downloads.sections.moreInfoContent22')}
                              </li>
                              <li style={{ listStyleType: 'disc' }}>
                                {t('downloads.sections.moreInfoContent23')}
                              </li>
                              <li style={{ listStyleType: 'disc' }}>
                                {t('downloads.sections.moreInfoContent24')}
                              </li>
                              <li style={{ listStyleType: 'disc' }}>
                                {t('downloads.sections.moreInfoContent25')}
                              </li>
                            </ul>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    }
                    type="white"
                  />
                </CardContainer>
              </div>
            )}
          </div>
        )}
        <hr />
        <div className={styles['subsection-area']}>
          <div className={styles.subsection}>
            <div className={styles['subsection-title']}>{t('downloads.sections.mainTitle3')}</div>
            <div className={styles['subsection-button']}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link linkStyle="button" linkHref="mailto:ringpalautteet@elisa.fi">
                <IconMessageRegular color="blue" />
                {t('downloads.sections.button6')}
              </Link>
            </div>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link linkStyle="button" linkHref="mailto:servicedesk@elisa.fi">
                <IconWarningRegular color="blue" />
                {t('downloads.sections.button7')}
              </Link>
            </div>
          </div>
          <div className={styles.subsection}>
            <div className={styles['subsection-title']}>{t('downloads.sections.mainTitle4')}</div>
            <div>
              <p>
                {t('downloads.sections.content61')}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link linkHref={t('downloads.sections.passwordUrl')}>
                  {t('downloads.sections.passwordUrl')}
                </Link>
                .
              </p>
              <p>{t('downloads.sections.content62')}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getError(): string {
    const { error } = this.state;
    const { t } = this.props;
    switch (error) {
      case 'noNumberEntered':
        return t('downloads.errorNoNumberEntered');
      case 'notFound':
        return t('downloads.errorNotFound');
      default:
        return t('downloads.errorGeneric');
    }
  }

  render(): Element<'div'> {
    const { t, resetSearch, handleSubmit, i18n } = this.props;
    const { loading, error } = this.state;

    return (
      <div className="downloads-page">
        <div className={styles['page-container']}>
          <div className={styles['content-container']}>
            <div className={styles.title}>{t('downloads.title')}</div>
            <div className={styles.language}>
              <LanguageSelector language={i18n.language} />
            </div>
            <form onSubmit={handleSubmit(() => {})} className={styles.content}>
              <p className={styles['text-style']}>{t('downloads.introduction')}</p>
              <Field
                key="phoneNumber"
                id="phoneNumber"
                label={t('downloads.inputLabel')}
                name="phoneNumber"
                component={InputField}
                className={styles['phonenumber-input']}
                onChange={e => {
                  // $FlowFixMe
                  if (e && e.currentTarget) {
                    this.handlePhoneNumberOnChange(e.currentTarget.value);
                  }
                }}
                onClearInput={resetSearch}
                maxlength={PHONE_NUMBER_MAX_LENGTH}
              />
              {!loading && !!error && (
                <p className={styles.error} id="downloads-error">
                  {this.getError()}
                </p>
              )}
              <p>
                {t('downloads.link')}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link linkHref="https://elisa.fi/ringohjeet" linkTarget="_blank">
                  {t('downloads.here')}
                </Link>
                .
              </p>
              {loading && (
                <CenterHorizontally>
                  <LoadingSpinner />
                </CenterHorizontally>
              )}
              {this.renderInstructions()}
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => ({
  phoneNumber: formValueSelector('downloads')(state, 'phoneNumber')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetSearch: change.bind(null, 'downloads', 'phoneNumber', '')
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'downloads',
    enableReinitialize: true
  }),
  withRouter
)(Downloads);
