// @flow
/* eslint-disable import/prefer-default-export */
import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import type { DirectoryStateEntityT, DirectoryAvatarStateEntityT } from './directoryTypes';

type ByIdsFnT = (StoreStateT, string[]) => DirectoryStateEntityT[];
export const byIds: ByIdsFnT = ({ entities: { directory } }, ids) =>
  ids.filter(id => id in directory.byId).map(id => directory.byId[id]);

type AvatarsByIdsFnT = (StoreStateT, string[]) => DirectoryAvatarStateEntityT[];
export const avatarsByIds: AvatarsByIdsFnT = ({ entities: { directory } }, ids) =>
  ids.filter(id => id in directory.avatarById).map(id => directory.avatarById[id]);

type SelectNewSearchResultsFnT = (
  StoreStateT,
  ?string,
  (string | typeof undefined)[]
) => DirectoryStateEntityT[];
export const selectNewSearchResults: SelectNewSearchResultsFnT = (
  { entities: { directory } },
  enterpriseId,
  initialAddressNumbers
) =>
  directory.allIds
    .filter(
      id =>
        directory.byId[id].enterpriseId === enterpriseId &&
        directory.byId[id].publicInfo.addressNumber &&
        !initialAddressNumbers.includes(directory.byId[id].publicInfo.addressNumber)
    )
    .map(id => directory.byId[id]);

type CollectionIsLoadingFnT = StoreStateT => boolean;
export const collectionIsLoading: CollectionIsLoadingFnT = ({ entities: { directory } }) =>
  R.path(['__metadata', 'isRetrieving'], directory) === true;

type CollectionHasMoreResultsFnT = StoreStateT => boolean;
export const collectionHasMoreResults: CollectionHasMoreResultsFnT = ({
  entities: { directory }
}) => R.path(['__metadata', 'hasMore'], directory) === true;

type CollectionHasErrorFnT = StoreStateT => boolean;
export const collectionHasError: CollectionHasErrorFnT = ({ entities: { directory } }) =>
  R.path(['__metadata', 'error'], directory);

const directorySelectors = {
  byIds,
  avatarsByIds,
  selectNewSearchResults,
  collectionIsLoading,
  collectionHasMoreResults,
  collectionHasError
};

export default directorySelectors;
