import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailReadRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.26 8.15 13.71.86a2.7 2.7 0 0 0-3.4 0L.75 8.14A2.14 2.14 0 0 0 0 10v11.5a2.25 2.25 0 0 0 2.25 2.25h19.5A2.25 2.25 0 0 0 24 21.5V10a2.33 2.33 0 0 0-.74-1.85M11.23 2a1.2 1.2 0 0 1 1.54 0l9.09 7-9.09 7a1.22 1.22 0 0 1-1.56 0L2.3 8.9zm10.52 20.25H2.25a.76.76 0 0 1-.75-.75V10.14l8.75 7c.488.4 1.099.619 1.73.62a2.67 2.67 0 0 0 1.69-.6l8.8-6.78V21.5a.76.76 0 0 1-.72.75"/></svg>)}
    />
  );
}
  