import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCreditCardLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.1 4H2.9C1.9 4 1 4.8 1 5.9v12.3c0 1 .9 1.9 1.9 1.9h18.2c1 0 1.9-.8 1.9-1.9V5.9c0-1.1-.9-1.9-1.9-1.9m.9 14.1c0 .5-.4.9-.9.9H2.9c-.5 0-.9-.4-.9-.9V5.9c0-.5.4-.9.9-.9h18.2c.5 0 .9.4.9.9zM14.8 8c-1 0-2 .4-2.8 1.1-.7-.7-1.7-1.1-2.8-1.1-2.2 0-4 1.8-4 4s1.8 4 4 4c1 0 2-.4 2.8-1.1.7.7 1.7 1.1 2.8 1.1 2.2 0 4-1.8 4-4s-1.8-4-4-4m-5.6 7c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3m5.6 0c-.8 0-1.6-.3-2.1-.9.8-1.3.8-2.9 0-4.2 1.2-1.2 3.1-1.2 4.2 0s1.2 3.1 0 4.2q-.9.9-2.1.9"/></svg>)}
    />
  );
}
  