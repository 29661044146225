import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNotificationLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.5 18.955a1 1 0 0 1-1-.998v-6.484c.003-4.493-3.504-8.21-8-8.48V.499a.5.5 0 0 0-1 0v2.494c-4.488.269-7.992 3.974-8 8.46v6.504a1 1 0 0 1-1 .998.5.5 0 1 0 0 .998h19a.5.5 0 1 0 0-.998m-17.27 0c.176-.303.269-.647.27-.998v-6.484C4.5 7.34 7.858 3.99 12 3.99s7.5 3.35 7.5 7.482v6.484c.001.35.094.694.27.998zM15 21.548a3.06 3.06 0 0 1-3.603 2.391A3.06 3.06 0 0 1 9 21.548a.49.49 0 0 1 .36-.591l.04-.008a.51.51 0 0 1 .59.389 2 2 0 0 0 1.6 1.606A2 2 0 0 0 14 21.348a.51.51 0 0 1 .6-.399.49.49 0 0 1 .4.599m2.5-10.574a.5.5 0 0 1-1 0 3.92 3.92 0 0 0-2.22-3.542.5.5 0 0 1 .44-.897 4.92 4.92 0 0 1 2.78 4.439"/></svg>)}
    />
  );
}
  