import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFolderOpenRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.217 22.25a2.27 2.27 0 0 1-2.209-1.796L.009 8.477a2.6 2.6 0 0 1 0-.429 2.237 2.237 0 0 1 2.209-2.266h6.248a2.26 2.26 0 0 1 2.209 1.796l.24 1.198h10.833a2.247 2.247 0 0 1 2.209 2.685l-1.999 8.982a2.26 2.26 0 0 1-2.209 1.806H4.217zM2.218 7.279a.74.74 0 0 0-.53.21.77.77 0 0 0-.22.529.6.6 0 0 0 0 .15l1.999 11.977a.74.74 0 0 0 .73.579h15.551a.78.78 0 0 0 .74-.599l1.999-8.982a.76.76 0 0 0-.16-.609.72.72 0 0 0-.58-.269H10.705a1.24 1.24 0 0 1-1.22-.998l-.28-1.397a.75.75 0 0 0-.74-.599zm19.779-.269V4.495a2.747 2.747 0 0 0-2.749-2.745H4.717a2.75 2.75 0 0 0-2.659 2.056.75.75 0 0 0 1.436.433l.014-.054a1.25 1.25 0 0 1 1.21-.938h14.531a1.26 1.26 0 0 1 1.25 1.248V7.01a.75.75 0 0 0 1.498 0"/></svg>)}
    />
  );
}
  