// @flow strict-local

import React, { type Element, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as R from 'ramda';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import type {
  WelcomeAttendantEntityT,
  CalendarEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import { updateWelcomeAttendant } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantOperations';
import {
  getInitialNextStep,
  getNewStepData,
  getNextStepPayload,
  getWelcomeAttendantStepOptions,
  renameCommandName
} from '../WelcomeAttendantUtils';
import EditCallflowDetails from '../../../../components/edit/EditCallflowDetails';
import { ReactComponent as CalendarIcon } from '../../../../../../assets/callflow/details/calendar-small.svg';
import { InputField } from '../../../../components/edit/children/InputField';
import WelcomeAttendantStepField from '../../../../components/edit/children/welcomeAttendantStepField/WelcomeAttendantStepField';
import CalendarSelector from '../../../../components/edit/children/calendar/CalendarSelector';
import type { NextStepT } from '../WelcomeAttendantUtils';
import { getCommandNames, validateStepName } from '../../../../../../utils/validationUtils';
import { createUpdateSelectedStatus } from '../../../../../../ducks/ui/callflow/callflowUiActions';
import type { WelcomeAttendantUpdateEntityT } from '../../../../../../ducks/entities/callFlow/callFlowTypes';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string,
  commandId: string
|};

export type FormT = {
  stepName: string,
  calendar: ?string,
  nextStepWithinRange: ?NextStepT,
  nextStepOutOfRange: ?NextStepT
};

let requestCancelTokenSource: CancelTokenSource;

export const buildWelcomeAttendantCalendarNodeUpdatePayload = (
  welcomeAttendantData: WelcomeAttendantEntityT,
  formData: FormT,
  commandName: string
) => ({
  commands: {
    ...welcomeAttendantData.commands,
    ...getNewStepData(formData.nextStepWithinRange),
    ...getNewStepData(formData.nextStepOutOfRange),
    [commandName]: {
      ...welcomeAttendantData.commands[commandName],
      calendars: [{ name: formData.calendar }],
      withinPeriodSate: getNextStepPayload(formData.nextStepWithinRange),
      outOfPeriodState: getNextStepPayload(formData.nextStepOutOfRange)
    }
  }
});

const EditWelcomeAttendantCalendar = (
  props: PropsT
): Element<typeof EditCallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const welcomeAttendantData: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { enterpriseId } = welcomeAttendantData;
  const calendarNode: ?CalendarEntityT = R.path(['commands', commandId], welcomeAttendantData);
  if (!calendarNode) {
    return null;
  }
  const stepOptions: NextStepT[] = getWelcomeAttendantStepOptions(welcomeAttendantData);
  const initialFormValues: FormT = {
    stepName: calendarNode.name,
    calendar:
      calendarNode.calendars && calendarNode.calendars.length && calendarNode.calendars[0]
        ? calendarNode.calendars[0].name
        : null,
    nextStepWithinRange: getInitialNextStep(
      stepOptions,
      // $FlowFixMe
      R.path(['withinPeriodSate', 'state'], calendarNode)
    ),
    nextStepOutOfRange: getInitialNextStep(
      stepOptions,
      // $FlowFixMe
      R.path(['outOfPeriodState', 'state'], calendarNode)
    )
  };
  const waCalendarSchema = yup.object().shape({
    stepName: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, calendarNode.name),
      [
        ['nextStepWithinRange', 'newStepName'],
        ['nextStepOutOfRange', 'newStepName']
      ]
    ),
    nextStepWithinRange: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, calendarNode.name),
      [['stepName'], ['nextStepOutOfRange', 'newStepName']],
      ['newStepName']
    ),
    nextStepOutOfRange: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, calendarNode.name),
      [['stepName'], ['nextStepWithinRange', 'newStepName']],
      ['newStepName']
    )
  });

  // update
  const onSubmit = async (formData: FormT): Promise<WelcomeAttendantEntityT> => {
    // Update WelcomeAttendant
    const commandName = calendarNode.name;

    const patchPayload: WelcomeAttendantUpdateEntityT = buildWelcomeAttendantCalendarNodeUpdatePayload(
      welcomeAttendantData,
      formData,
      commandName
    );
    const modifiedPayload: WelcomeAttendantUpdateEntityT = renameCommandName(
      patchPayload,
      commandName,
      formData.stepName.toUpperCase()
    );
    const returnValue = await dispatch(
      updateWelcomeAttendant(
        enterpriseId,
        welcomeAttendantData.id,
        modifiedPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );

    dispatch(
      createUpdateSelectedStatus(
        welcomeAttendantData.id,
        'CALENDAR',
        formData.stepName.toUpperCase()
      )
    );
    return returnValue;
  };

  return (
    <EditCallflowDetails
      nodeId={welcomeAttendantData.id}
      icon={<CalendarIcon />}
      title={t('callflows.editWelcomeAttendantCalendar.title')}
      description={t('callflows.editWelcomeAttendantCalendar.description')}
      defaultValues={initialFormValues}
      validationSchema={waCalendarSchema}
      onSaveForm={onSubmit}
    >
      <InputField
        field="stepName"
        title={t('callflows.welcomeAttendantGeneric.stepName')}
        shouldValidate
        tooltip={t('callflows.editWelcomeAttendantCalendar.stepNameTooltip')}
      />
      <CalendarSelector
        field="calendar"
        description={t('callflows.editAcdCalendar.calendarSelector')}
        enterpriseId={enterpriseId}
        tooltip={t('callflows.editWelcomeAttendantCalendar.calendarTooltip')}
      />
      <WelcomeAttendantStepField
        field="nextStepWithinRange"
        title={t('callflows.editWelcomeAttendantCalendar.nextStepWithinRange')}
        stepOptions={stepOptions}
        noStartStep
        tooltip={{
          title: t('callflows.editWelcomeAttendantCalendar.nextStepWithinRangeTooltipTitle'),
          text: t('callflows.editWelcomeAttendantCalendar.nextStepWithinRangeTooltipText')
        }}
      />
      <WelcomeAttendantStepField
        field="nextStepOutOfRange"
        title={t('callflows.editWelcomeAttendantCalendar.nextStepOutOfRange')}
        stepOptions={stepOptions}
        noStartStep
        tooltip={{
          title: t('callflows.editWelcomeAttendantCalendar.nextStepOutOfRangeTooltipTitle'),
          text: t('callflows.editWelcomeAttendantCalendar.nextStepOutOfRangeTooltipText')
        }}
      />
    </EditCallflowDetails>
  );
};

export default EditWelcomeAttendantCalendar;
