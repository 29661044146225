// @flow

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { ExactPropsT } from '../../../commonTypes';
import ActionButton from '../../../components/Button/ActionButton';
import LinkButton from '../../../components/Button/LinkButton';
import styles from './MoveUserIndicator.module.scss';

export type MoveStatusT = 'failed' | 'success';

type OwnPropsT = {
  isMovingUsers: boolean,
  moveStatus: ?MoveStatusT,
  successfullyMovedPeople: number,
  totalNumberOfPeopleToBeMoved: number,
  movePeopleSuccessfulAction: void => *,
  movePeopleTryAgainAction: void => *,
  cancelMovingUsers: void => void,
  createDepartment?: boolean
};

type StatePropsT = {};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

// eslint-disable-next-line react/prefer-stateless-function
export class MoveUserIndicator extends Component<PropsT> {
  static defaultProps = {
    isMovingUsers: false,
    moveStatus: undefined,
    successfullyMovedPeople: 0,
    totalNumberOfPeopleToBeMoved: 0,
    createDepartment: false
  };

  render(): Element<'div'> | null {
    const {
      isMovingUsers,
      moveStatus,
      t,
      successfullyMovedPeople,
      totalNumberOfPeopleToBeMoved,
      movePeopleTryAgainAction,
      movePeopleSuccessfulAction,
      createDepartment,
      cancelMovingUsers
    } = this.props;

    const renderMoveUsersIndicator = (
      <div className={styles['move-users-indicator-container']}>
        <LoadingSpinner id="move-users-processing-indicator" />
        <h2>
          {createDepartment
            ? t('enterprise.moveUserIndicator.processingDepartmentTopic')
            : t('enterprise.moveUserIndicator.processingTopic')}
        </h2>
        <div>
          {t('enterprise.moveUserIndicator.processingLabel', {
            movedPeople: successfullyMovedPeople,
            totalPeopleToBeMoved: totalNumberOfPeopleToBeMoved
          })}
        </div>
        <LinkButton
          id="cancel-move-users-button"
          className={styles['cancel-button']}
          label={t('enterprise.moveUserIndicator.cancelMove')}
          onClickAction={() => cancelMovingUsers()}
        />
      </div>
    );

    const renderMoveUsersComplete = (
      <div className={styles['move-users-indicator-container']}>
        <img src="/checkmark green.svg" alt="successful" />
        <h2>
          {createDepartment
            ? t('enterprise.moveUserIndicator.successfulDepartmentTopic')
            : t('enterprise.moveUserIndicator.successfulTopic')}
        </h2>
        <div>
          {t('enterprise.moveUserIndicator.successfulLabel', {
            movedPeople: successfullyMovedPeople,
            totalPeopleToBeMoved: totalNumberOfPeopleToBeMoved
          })}
        </div>
        <ActionButton
          id="move-people-completed-button"
          label={t('enterprise.moveUserIndicator.successfulButtonLabel')}
          className={styles['move-users-cancel-button']}
          onClickAction={movePeopleSuccessfulAction}
        />
      </div>
    );

    const renderMoveUsersFailed = (
      <div className={styles['move-users-indicator-container']}>
        <img src="/error-red.svg" alt="error" />
        <h2>{t('enterprise.moveUserIndicator.failedTopic')}</h2>
        <div>{t('enterprise.moveUserIndicator.failedLabel1')}</div>
        <div>
          {t('enterprise.moveUserIndicator.failedLabel2', {
            movedPeople: successfullyMovedPeople,
            totalPeopleToBeMoved: totalNumberOfPeopleToBeMoved
          })}
        </div>
        <ActionButton
          id="move-user-try-again-button"
          label={t('enterprise.moveUserIndicator.failedButtonLabel')}
          className={styles['move-users-cancel-button']}
          onClickAction={movePeopleTryAgainAction}
        />
      </div>
    );

    if (isMovingUsers) {
      return renderMoveUsersIndicator;
    }
    switch (moveStatus) {
      case 'success':
        return renderMoveUsersComplete;
      case 'failed':
        return renderMoveUsersFailed;
      default:
        return null;
    }
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)
)(MoveUserIndicator);
