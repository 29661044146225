import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSwitchFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.383 16.384-2.999 2.999a1.246 1.246 0 0 1-1.768.001 1.25 1.25 0 0 1 0-1.768l.866-.866H9.5a1.25 1.25 0 0 1 0-2.5h9.982l-.866-.866a1.25 1.25 0 0 1 1.768-1.768l2.999 2.999a1.254 1.254 0 0 1 0 1.769M4.518 9.75H14.5a1.25 1.25 0 0 0 0-2.5H4.518l.866-.866a1.25 1.25 0 0 0-1.768-1.768l-2.999 3a1.25 1.25 0 0 0-.271 1.361c.063.153.155.292.27.407l2.999 2.999a1.247 1.247 0 0 0 1.769.001 1.25 1.25 0 0 0 0-1.768z"/></svg>)}
    />
  );
}
  