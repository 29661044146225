import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMoneyRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.37 7A1.13 1.13 0 1 0 5.5 8.13v-.01A1.12 1.12 0 0 0 4.38 7zm12.26 4.75a1.13 1.13 0 1 0 1.12 1.14v-.01a1.12 1.12 0 0 0-1.11-1.13zM21 15V6a2.23 2.23 0 0 0-2.21-2.25H2.25A2.23 2.23 0 0 0 0 5.96V15a2.23 2.23 0 0 0 2.21 2.25h16.54A2.23 2.23 0 0 0 21 15.04zM1.5 15V6a.74.74 0 0 1 .73-.75h16.52a.74.74 0 0 1 .75.72V15a.74.74 0 0 1-.75.72H2.25A.74.74 0 0 1 1.5 15m9-8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5m0 6a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5m11.194 7.272H5.5a.75.75 0 0 1 0-1.5h16.194a.784.784 0 0 0 .783-.784V9.5a.75.75 0 0 1 1.5 0v8.238a2.285 2.285 0 0 1-2.283 2.284"/></svg>)}
    />
  );
}
  