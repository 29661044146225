import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDraftSaveLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.51 14.5a.5.5 0 0 0-.5.5v2H12.5a1.5 1.5 0 0 0-1.5 1.5V23H1.5a.5.5 0 0 1-.5-.5v-21a.5.5 0 0 1 .5-.5h10a.5.5 0 0 0 0-1h-10A1.5 1.5 0 0 0 0 1.5v21A1.5 1.5 0 0 0 1.5 24h9.59c.207-.003.411-.047.6-.13q.252-.115.45-.31l5.42-5.41a1.52 1.52 0 0 0 .44-1.06V15a.5.5 0 0 0-.49-.5M12 22.3v-3.8a.5.5 0 0 1 .5-.5h3.8zM17.5 0A6.5 6.5 0 1 0 24 6.5 6.51 6.51 0 0 0 17.5 0m0 12a5.5 5.5 0 1 1 0-11A5.5 5.5 0 0 1 23 6.5a5.51 5.51 0 0 1-5.5 5.5m2.6-5.1a.48.48 0 0 1 .022.678L20.1 7.6l-2.25 2.25a.36.36 0 0 1-.16.11.47.47 0 0 1-.38 0 .36.36 0 0 1-.16-.11L14.9 7.6a.496.496 0 0 1 .7-.7L17 8.29V3.5a.5.5 0 0 1 1 0v4.79l1.4-1.39a.48.48 0 0 1 .678-.022z"/></svg>)}
    />
  );
}
  