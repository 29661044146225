// @flow strict-local

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import UserForwardings from './userForwardings/UserForwardings';
import EnterpriseForwardings from './enterpriseForwardings/EnterpriseForwardings';
import type { EnterpriseEntityT } from '../../ducks/entities/enterprise';
import { selectors as enterpriseSelectors } from '../../ducks/entities/enterprise';
import { goToForwardingDetailsUpdate } from '../../navigationOperations';
import type { CallForwardingsTabT } from '../../ducks/ui/callForwardings/callForwardingsUiTypes';
import { createUpdateActiveTab } from '../../ducks/ui/callForwardings/callForwardingsUiActions';
import ContentTabs from '../../components/ContentTabs/ContentTabs';
import Tab from '../../components/ContentTabs/Tab';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import { isAdmin } from '../../utils/accessRightUtils';

type PropsT = {|
  enterprise: ?EnterpriseEntityT,
  goToForwardingDetailsUpdate: typeof goToForwardingDetailsUpdate,
  onForwardingActivityChange: () => Promise<void>,
  onDeleteForwarding: () => void
|};

export const CallForwardingContentTabs = (
  props: PropsT
): Element<typeof ContentTabs | typeof CenterHorizontally> => {
  // eslint-disable-next-line no-shadow
  const {
    enterprise,
    goToForwardingDetailsUpdate: goToFwdDetailsUpdate,
    onForwardingActivityChange,
    onDeleteForwarding
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const enterpriseIsLoading: boolean = useSelector(state =>
    enterpriseSelectors.isLoading(state, (enterprise && enterprise.entID) || '')
  );
  const currentUser = useSelector(state => state.currentUser);
  const activeTab: CallForwardingsTabT =
    useSelector(state => state.ui.callForwardings.activeTab) || 'users';

  const tabOptions = [{ id: 'users', name: t('forwardings.tabs.users') }];
  if (isAdmin(currentUser)) {
    tabOptions.push({ id: 'enterprises', name: t('forwardings.tabs.enterprises') });
  }

  if (!enterprise) {
    return (
      <CenterHorizontally>
        <LoadingSpinner />
      </CenterHorizontally>
    );
  }

  return (
    <ContentTabs
      activeTab={activeTab}
      tabOptions={tabOptions}
      // $FlowFixMe selectedTab value should be always users, enterprises or groups
      updateActiveTab={selectedTab => dispatch(createUpdateActiveTab(selectedTab))}
    >
      <Tab id="users">
        <UserForwardings
          enterprise={enterprise}
          onForwardingActivityChange={onForwardingActivityChange}
          onDeleteForwarding={onDeleteForwarding}
        />
      </Tab>
      <Tab id="enterprises">
        <EnterpriseForwardings
          enterprise={enterprise}
          translate={t}
          onClickModify={forwardingId =>
            goToFwdDetailsUpdate(enterprise.entID, forwardingId, null, true)
          }
          isLoading={enterpriseIsLoading}
          showLoadingSpinner={enterpriseIsLoading}
          onForwardingActivityChange={onForwardingActivityChange}
          onDeleteForwarding={onDeleteForwarding}
        />
      </Tab>
      <Tab id="groups">
        <div />
      </Tab>
    </ContentTabs>
  );
};

export default CallForwardingContentTabs;
