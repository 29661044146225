// @flow

import React, { type Element } from 'react';
import classNames from 'classnames';
import { isValidAddressNumber } from '../../utils/validationUtils';

import styles from './ResultItem.module.scss';

export type ResultItemT = {|
  index: number,
  label: string,
  addressNumber?: string
|};

export type PropsT = {|
  onSelect: (*) => *,
  result: ResultItemT,
  selected: boolean
|};

export const ResultItem = (props: PropsT): Element<'div'> | null => {
  const { onSelect, result, selected } = props;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      data-cy={result.addressNumber}
      role="button"
      tabIndex={0}
      className={classNames(
        styles.result,
        selected ? styles.selected : '',
        !isValidAddressNumber(result.addressNumber) ? styles.invalid : ''
      )}
      onClick={() => onSelect(result)}
    >
      {result.label} {result.addressNumber && `(${result.addressNumber})`}
    </div>
  );
};

export default ResultItem;
