// @flow strict-local

import React, { type Element } from 'react';
import type { TranslateT } from '../../../commonTypes';
import ConfirmButton from '../../../components/Button/ConfirmButton';

import styles from './UserLoadingError.module.scss';

type OwnPropsT = {
  reloadFunc: () => void,
  translate: TranslateT<string>
};

export type PropsT = OwnPropsT;

const UserLoadingError = (props: PropsT): Element<'div'> => {
  const { reloadFunc, translate } = props;
  return (
    <div className={styles['user-error-container']}>
      <img src="/content-fetch-error.png" alt="error" />
      <div className={styles['text-area']}>
        {translate('users.personalDetails.error.dataFetchFailedErrorMessage')}
      </div>
      <ConfirmButton
        id="reload-button"
        onClickAction={reloadFunc}
        label={translate('users.personalDetails.repeatFetch')}
      />
    </div>
  );
};

export default UserLoadingError;
