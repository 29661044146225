// @flow

import type { TourStateT, ActionT } from './tourTypes';

export const initialState: TourStateT = {
  active: false,
  steps: []
};

const tourReducer = (state: TourStateT = initialState, action: ActionT): TourStateT => {
  switch (action.type) {
    case 'ring/ui/tour/START_TOUR': {
      return {
        ...state,
        active: true
      };
    }
    case 'ring/ui/tour/END_TOUR': {
      return {
        ...state,
        active: false
      };
    }
    case 'ring/ui/tour/SET_STEPS': {
      const {
        payload: { steps }
      } = action;
      return {
        ...state,
        steps
      };
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  initialState,
  tourReducer
};
