import './globals/scss/common.scss';

import Accordion from './components/Accordion/Accordion';
import AccordionItem from './components/Accordion/AccordionItem';
import Badge, { BadgeProps} from './components/Badge';
import BankSelectGroup from './components/BankSelectGroup';
import Breadcrumbs, { BreadcrumbsProps } from './components/Breadcrumbs';
import Button, { ButtonProps } from './components/Button';
import ButtonGroup, { ButtonGroupProps } from './components/ButtonGroup';
import Card, { CardProps } from './components/Card';
import CardContainer, { CardContainerProps } from './components/CardContainer';
import Carousel from './components/Carousel';
import Checkbox from './components/Checkbox';
import Combobox from './components/Combobox';
import ComparisonTable, { ComparisonTableProps } from './components/ComparisonTable';
import DatePicker from './components/DatePicker';
import Description, { DescriptionProps } from './components/Description';
import Disclaimer, { DisclaimerProps } from './components/Disclaimer';
import Dropdown from './components/Dropdown';
import Dummy from './components/Dummy';
import FileUpload, { FileUploadProps } from './components/FileUpload';
import Fonts from './components/Fonts';
import Footer from './components/Footer';
import Grid, { GridProps } from './components/Grid';
import GridCol, { GridColProps} from './components/Grid/GridCol';
import GridRow, { GridRowProps } from './components/Grid/GridRow';
import Header, { HeaderProps } from './components/Header';
import Hero, { HeroProps } from './components/Hero';
import HeaderAccordion, { HeaderAccordionProps } from './components/Header/subcomponents/HeaderAccordion';
import HeaderContext from './components/Header/HeaderContext';
import HeaderDivider, { HeaderDividerProps } from './components/Header/subcomponents/HeaderDivider';
import HeaderExternalLinks, { HeaderExternalLinksProps } from './components/Header/subcomponents/HeaderExternalLinks';
import HeaderHamburger, { HeaderHamburgerProps } from './components/Header/subcomponents/HeaderHamburger';
import HeaderLoginLogoutButton, { HeaderLoginLogoutButtonProps } from './components/Header/subcomponents/HeaderLoginLogoutButton';
import HeaderLogoAndService, { HeaderLogoAndServiceProps } from './components/Header/subcomponents/HeaderLogoAndService';
import HeaderModels from './components/Header/models';
import HeaderNavigation, { HeaderNavigationProps } from './components/Header/subcomponents/HeaderNavigation';
import HeaderNavigationButton, { HeaderNavigationButtonProps } from './components/Header/subcomponents/HeaderNavigationButton';
import HeaderNavigationDropdown, { HeaderNavigationDropdownProps } from './components/Header/subcomponents/HeaderNavigationDropdown';
import HeaderNavigationSection, { HeaderNavigationSectionProps } from './components/Header/subcomponents/HeaderNavigationSection';
import HeaderNotifications, { HeaderNotificationsProps } from './components/Header/subcomponents/HeaderNotifications';
import HeaderSearchButton, { HeaderSearchButtonProps } from './components/Header/subcomponents/HeaderSearchButton';
import HeaderServicesMenu, { HeaderServicesMenuProps } from './components/Header/subcomponents/HeaderServicesMenu';
import HeaderShoppingCart, { HeaderShoppingCartProps } from './components/Header/subcomponents/HeaderShoppingCart';
import HeaderSiteSelect, { HeaderSiteSelectProps } from './components/Header/subcomponents/HeaderSiteSelect';
import HeaderSubMenu, { HeaderSubMenuProps } from './components/Header/subcomponents/HeaderSubMenu';
import HeaderTopBar, { HeaderTopBarProps } from './components/Header/subcomponents/HeaderTopBar';
import HeaderUserMenu, { HeaderUserMenuProps } from './components/Header/subcomponents/HeaderUserMenu';
import HelperClasses from './components/HelperClasses';
import Icon from './components/Icon';
import IconContainer from './components/Icon/IconContainer';
import Input from './components/Input';
import InputHelp from './components/InputHelp';
import InputError from './components/InputError';
import InputGroup from './components/InputGroup';
import Label from './components/Label';
import LanguageSwitch, { LanguageSwitchProps } from './components/LanguageSwitch';
import Link, { LinkProps } from './components/Link';
import List from './components/List';
import Loader from './components/Loader';
import LoadingSpinner, { LoadingSpinnerProps } from './components/LoadingSpinner';
import Login from './components/Login';
import Logo, { LogoProps } from './components/Logo';
import Modal from './components/Modal';
import Notification from './components/Notification';
import NotificationBanner, { NotificationBannerProps } from './components/NotificationBanner';
import Pagination, { PaginationProps } from './components/Pagination';
import PaginationNav, { PaginationNavProps } from './components/PaginationNav';
import Picture, { PictureProps } from './components/Picture';
import Popover from './components/Popover';
import Price, { PriceProps } from './components/Price';
import ProductCard, { ProductCardProps } from './components/ProductCard';
import ProductGrid, { ProductGridProps } from './components/ProductGrid';
import ProductGridV2, { ProductGridV2Props } from './components/ProductGridV2';
import Progressbar, { ProgressbarProps } from './components/Progressbar';
import Quantity from './components/Quantity';
import RadioGroup from './components/RadioGroup';
import RadioV2 from './components/RadioGroup/Radio';
import SelectGroup from './components/SelectGroup';
import ShoppingCart, { ShoppingCartProps } from './components/ShoppingCart';
import SkeletonLoader from './components/SkeletonLoader';
import Skiplink, { SkiplinkProps } from './components/Skiplink';
import Stepper, { StepperProps } from './components/Stepper';
import StoryBlock from './components/StoryBlock';
import TabNavigation from './components/TabNavigation';
import TabNavigationTab from './components/TabNavigation/TabNavigationTab';
import Table, { TableProps } from './components/Table';
import Toggle from './components/Toggle';
import Tooltip, { TooltipProps } from './components/Tooltip';
import Typography from './components/Typography';

// IMPORTANT!
// Add your new exported components as a direct export to
// so that it can be used like so: export { FluidGridItem } from 'react-patterns'
// The other exports in this file are kept to support old codebases. Do NOT add anything there.
// exported for index.test.js
export default {};

export {
  Accordion,
  AccordionItem,
  Badge,
  BadgeProps,
  BankSelectGroup,
  Breadcrumbs,
  BreadcrumbsProps,
  Button,
  ButtonProps,
  ButtonGroup,
  ButtonGroupProps,
  Card,
  CardProps,
  CardContainer,
  CardContainerProps,
  Carousel,
  Checkbox,
  Combobox,
  ComparisonTable,
  ComparisonTableProps,
  DatePicker,
  Description,
  DescriptionProps,
  Disclaimer,
  DisclaimerProps,
  Dropdown,
  Dummy,
  FileUpload,
  FileUploadProps,
  Fonts,
  Footer,
  Grid,
  GridProps,
  GridCol,
  GridColProps,
  GridRow,
  GridRowProps,
  Header,
  HeaderProps,
  HeaderAccordion,
  HeaderAccordionProps,
  HeaderContext,
  HeaderDivider,
  HeaderDividerProps,
  HeaderExternalLinks,
  HeaderExternalLinksProps,
  HeaderHamburger,
  HeaderHamburgerProps,
  HeaderLoginLogoutButton,
  HeaderLoginLogoutButtonProps,
  HeaderLogoAndService,
  HeaderLogoAndServiceProps,
  HeaderModels,
  HeaderNavigation,
  HeaderNavigationProps,
  HeaderNavigationButton,
  HeaderNavigationButtonProps,
  HeaderNavigationDropdown,
  HeaderNavigationDropdownProps,
  HeaderNavigationSection,
  HeaderNavigationSectionProps,
  HeaderNotifications,
  HeaderNotificationsProps,
  HeaderSearchButton,
  HeaderSearchButtonProps,
  HeaderServicesMenu,
  HeaderServicesMenuProps,
  HeaderShoppingCart,
  HeaderShoppingCartProps,
  HeaderSiteSelect,
  HeaderSiteSelectProps,
  HeaderSubMenu,
  HeaderSubMenuProps,
  HeaderTopBar,
  HeaderTopBarProps,
  HeaderUserMenu,
  HeaderUserMenuProps,
  Hero,
  HeroProps,
  HelperClasses,
  Icon,
  IconContainer,
  Input,
  InputHelp,
  InputError,
  InputGroup,
  Label,
  LanguageSwitch,
  LanguageSwitchProps,
  Link,
  LinkProps,
  List,
  Loader,
  LoadingSpinner,
  LoadingSpinnerProps,
  Login,
  Logo,
  LogoProps,
  Modal,
  Notification,
  NotificationBanner,
  NotificationBannerProps,
  Pagination,
  PaginationProps,
  PaginationNav,
  PaginationNavProps,
  Picture,
  PictureProps,
  Price,
  PriceProps,
  Popover,
  ProductCard,
  ProductCardProps,
  ProductGrid,
  ProductGridProps,
  ProductGridV2,
  ProductGridV2Props,
  Progressbar,
  ProgressbarProps,
  Quantity,
  RadioGroup,
  RadioV2,
  SelectGroup,
  ShoppingCart,
  ShoppingCartProps,
  SkeletonLoader,
  Skiplink,
  SkiplinkProps,
  Stepper,
  StepperProps,
  StoryBlock,
  Table,
  TableProps,
  TabNavigation,
  TabNavigationTab,
  Toggle,
  Tooltip,
  TooltipProps,
  Typography,
};
