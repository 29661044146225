// @flow

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { TranslateT, ExactPropsT } from '../../../commonTypes';
import ResultView from '../../../components/ResultView';

export type OwnPropsT = {
  onCancel: void => void,
  numberOfUsersImported: number,
  maxNumberOfUsersImported: number,
  numberOfDepartmentsImported: number,
  maxNumberOfDepartmentsImported: number
};

type StatePropsT = { translate: TranslateT<> };

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

// eslint-disable-next-line
export class ProcessStep extends Component<PropsT> {
  render(): Element<typeof ResultView> {
    const {
      t,
      onCancel,
      numberOfUsersImported,
      maxNumberOfUsersImported,
      numberOfDepartmentsImported,
      maxNumberOfDepartmentsImported
    } = this.props;
    const users = `${numberOfUsersImported}/${maxNumberOfUsersImported}`;
    const departments = `${numberOfDepartmentsImported}/${maxNumberOfDepartmentsImported}`;
    return (
      <ResultView
        onCancel={onCancel}
        viewType="process view"
        mainInfos={[t('importUsers.usersImported', { usersImported: users })]}
        secondaryInfos={[
          t('importUsers.departmentsImported', {
            departmentsImported: departments
          })
        ]}
        title={t('importUsers.processStep.title')}
        cancelButtonLabel={t('importUsers.processStep.cancel')}
      />
    );
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)
)(ProcessStep);
