// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VoiceMailExtraIcon } from '../../../../../assets/callflow/details/callflow_voicemail_extra_open.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import CallflowList from '../../../components/view/children/CallflowList';
import CallflowItem from '../../../components/view/children/CallflowItem';
import { getSendVoiceMailToAgentsValue } from './EditUnhandled';
import type { VoiceMailTargetT } from './EditUnhandled';

export type PropsT = {|
  callflowId: string
|};

export const VoiceMailExtraDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const acdAudioFieldConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');
  const currentUser = useSelector(state => state.currentUser);

  const sendVoiceMailToLoggedAgents = () => {
    const voiceMailTarget: VoiceMailTargetT = getSendVoiceMailToAgentsValue(
      R.path(['blockVoiceMailToAgents'], acdCallCenter),
      R.path(['blockVoiceMailToUnloggedAgents'], acdCallCenter)
    );

    switch (voiceMailTarget) {
      case 'ALL_AGENTS':
        return t('callflows.voiceMailTarget.voiceMailTargetAllAgents');
      case 'LOGGED_AGENTS':
        return t('callflows.voiceMailTarget.voiceMailTargetLoggedAgents');
      case 'NO_AGENTS':
      default:
        return t('callflows.voiceMailTarget.voiceMailTargetNoAgents');
    }
  };
  const emails = !(currentUser.featureFlags || []).includes('FEATURE-HIDE-VOICEMAIL-SPECIAL-EMAILS')
    ? R.pathOr(' ', ['voiceMailSpecialEmails', 'value'], ivrAcdCallCenter).split(/[,;]/) || []
    : acdData.emails
    ? acdData.emails.split(/[,;]/)
    : [];

  const voiceMailSpecialEmails = emails.map(s => s.trim()).filter(s => s);

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_voice_mail_details_${acdData.id}`}
      nodeType="ACD_VOICE_MAIL_DETAILS"
      icon={<VoiceMailExtraIcon />}
      title={t('callflows.viewVoiceMailExtra.title')}
      active
    >
      <CallflowDetailsColumn id="acd-voice-mail-extra-column-1">
        <CallflowProperty
          id="starToVoiceMail"
          title={t('callflows.viewVoiceMailExtra.starToVoiceMail')}
          property={acdCallCenter.starToVoiceMail}
          tooltip={t('callflows.viewVoiceMailExtra.starToVoiceMailTooltip')}
          basicValue
        />
        {acdCallCenter.starToVoiceMail && (
          <CallflowAudioProperty
            title={t('callflows.viewVoiceMailExtra.starToVoiceMailMsg')}
            audioField={acdAudioFieldConverter.convertToAudioPropertyField(
              'StarToVoiceMailMessage',
              '',
              R.path(['starToVoiceMailMessage'], ivrAcdCallCenter)
            )}
            isActive
          />
        )}
        <CallflowItem
          id="sendVoiceMailToAgents"
          title={t('callflows.viewVoiceMailExtra.sendVoiceMailToAgents')}
          value={sendVoiceMailToLoggedAgents()}
          tooltip={t('callflows.viewVoiceMailExtra.sendVoiceMailToAgentsTooltip')}
        />
        <CallflowList
          id="voiceMailSpecialEmails"
          title={t('callflows.viewVoiceMailExtra.voiceMailSpecialEmails')}
          values={
            voiceMailSpecialEmails.length > 0
              ? voiceMailSpecialEmails
              : [t('callflows.viewVoiceMailExtra.noAddresses')]
          }
          tooltip={
            !(currentUser.featureFlags || []).includes('FEATURE-HIDE-VOICEMAIL-SPECIAL-EMAILS')
              ? t('callflows.viewVoiceMailExtra.voiceMailSpecialEmailsTooltip')
              : t('callflows.viewVoiceMailExtra.emailsTooltip')
          }
        />
        <CallflowProperty
          id="bypassDisplayPoliciesForVM"
          title={t('callflows.viewVoiceMailExtra.bypassDisplayPoliciesForVM')}
          property={acdCallCenter.bypassDisplayPoliciesForVM}
          tooltip={t('callflows.viewVoiceMailExtra.bypassDisplayPoliciesForVMTooltip')}
          basicValue
        />

        <CallflowItem
          id="groupPassword"
          title={t('callflows.viewVoiceMailExtra.groupPassword')}
          value={
            (currentUser.featureFlags || []).includes('FEATURE-HIDE-GROUP-PASSWORD')
              ? t('callflows.viewVoiceMailExtra.groupPasswordDeprecated')
              : '**********'
          }
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default VoiceMailExtraDetails;
