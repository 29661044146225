import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDevicesFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.25 6.75A.25.25 0 0 1 4 6.5V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v8.625a.25.25 0 0 1-.25.25H9.5a.25.25 0 0 1-.25-.25V10A3.25 3.25 0 0 0 6 6.75zm12.112 9.375a.24.24 0 0 0-.163.066c-.705.657-1.554.954-2.699.954-1.126 0-1.906-.273-2.682-.958a.25.25 0 0 0-.16-.062H9.5a.25.25 0 0 0-.25.25V18a3.2 3.2 0 0 1-.456 1.635c-.097.161.033.365.22.365H20.5a3.5 3.5 0 0 0 3.5-3.5v-.125a.25.25 0 0 0-.25-.25zM0 17.375V18a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-.625a.25.25 0 0 0-.25-.25H.25a.25.25 0 0 0-.25.25m8-1.75V10a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v5.625c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25"/></svg>)}
    />
  );
}
  