import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPowerFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.001 11.623a1.125 1.125 0 0 1-1.125-1.125V1.5a1.125 1.125 0 0 1 2.25 0v8.998c0 .621-.504 1.125-1.125 1.125m10.471 3.172a10.636 10.636 0 0 0-5.998-11.433 1.124 1.124 0 1 0-.945 2.041 8.39 8.39 0 0 1 4.727 9.011 8.355 8.355 0 0 1-8.254 6.958 8.354 8.354 0 0 1-8.253-6.958 8.384 8.384 0 0 1 4.727-9.011 1.125 1.125 0 1 0-.948-2.041A10.64 10.64 0 0 0 1.53 14.795c.878 5.115 5.282 8.827 10.471 8.827s9.594-3.713 10.471-8.827"/></svg>)}
    />
  );
}
  