// @flow
import React, { type Element } from 'react';
import IconCloseRegular from '@design-system/component-library/src/components/Icon/lib/IconCloseRegular';
import classnames from 'classnames';
import styles from './Dismiss.module.scss';

export type PropsT = {
  id: string,
  onClose: () => *,
  dismissStyle?: string,
  color?: string
};

const Dismiss = ({ id, onClose, dismissStyle, color }: PropsT): Element<'span'> => (
  <IconCloseRegular
    id={id}
    data-cy={id}
    role="button"
    tabIndex="0"
    className={classnames(styles.dismiss, dismissStyle)}
    onClick={onClose}
    onKeyPress={onClose}
    color={color}
  />
);

export default Dismiss;
