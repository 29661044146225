// @flow strict-local

import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { ReactComponent as CalendarIcon } from '../../../../../assets/callflow/details/calendar-small.svg';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import CalendarSelector from '../../../components/edit/children/calendar/CalendarSelector';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import { removeOverflowGroupIfNeeded } from './EditAcdAgent';

type PropsT = {|
  callflowId: string
|};

type FormT = {
  openHours: string,
  keepQueuedOnCalendarClosed: boolean
};

const EditAcdCalendar = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const cancelUpdateRequest = React.useRef<?Canceler>();

  // form
  const initialValues = {
    openHours: acdCallCenter.openHours ? acdCallCenter.openHours : '',
    keepQueuedOnCalendarClosed: acdCallCenter.keepQueuedOnCalendarClosed
  };

  // side effects
  useEffect(
    () => () => {
      if (cancelUpdateRequest.current) {
        cancelUpdateRequest.current();
      }
    },
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const openHours = formData.openHours === '-' ? '' : formData.openHours;
    const acdPatchPayload = {
      servicesSettings: {
        acdCallCenter: {
          openHours,
          keepQueuedOnCalendarClosed: formData.keepQueuedOnCalendarClosed
        }
      },
      ...removeOverflowGroupIfNeeded(acdData.ringPattern, acdData.overflowGroup)
    };

    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        acdPatchPayload,
        new CancelToken(canceler => {
          cancelUpdateRequest.current = canceler;
        }),
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<CalendarIcon />}
      title={t('callflows.editAcdCalendar.title')}
      description={t('callflows.editAcdCalendar.description')}
      defaultValues={initialValues}
      onSaveForm={onSubmit}
    >
      <ToggleField
        field="keepQueuedOnCalendarClosed"
        elementId="keepQueuedOnCalendarClosed"
        label={t('callflows.editAcdCalendar.keepQueuedOnCalendarClosed')}
        tooltip={t('callflows.editAcdCalendar.keepQueuedOnCalendarClosedInfo')}
      />
      <CalendarSelector
        field="openHours"
        description={t('callflows.editAcdCalendar.calendarSelector')}
        enterpriseId={acdData.enterpriseId}
      />
    </EditCallflowDetails>
  );
};

export default EditAcdCalendar;
