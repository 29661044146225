import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSearchLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M18.3 6.5C16.4 1.9 11.1-.2 6.5 1.7c-4.6 2-6.7 7.2-4.8 11.8s7.2 6.7 11.8 4.8c.9-.4 1.8-.9 2.5-1.6l6.1 6.1c.2.2.5.2.7 0s.2-.5 0-.7L16.7 16c2.3-2.5 3-6.2 1.6-9.5m-2.6 9.1c2.2-2.2 2.9-5.6 1.6-8.7-1.7-4.1-6.4-6-10.5-4.2-4.1 1.7-6 6.4-4.2 10.5 1.7 4.1 6.4 6 10.5 4.2.9-.4 1.8-1 2.4-1.6z" clipRule="evenodd"/></svg>)}
    />
  );
}
  