import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAnnouncementRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.936 12.653a3.3 3.3 0 0 1-1.523 2.183.75.75 0 0 1-1.03-.254.75.75 0 0 1 .254-1.029 1.8 1.8 0 0 0 .828-1.191 1.825 1.825 0 0 0-.833-1.917.75.75 0 1 1 .771-1.286 3.32 3.32 0 0 1 1.533 3.494M19.991 3.5v16.989a.75.75 0 0 1-1.178.616l-1.104-.767c-3.639-2.527-7.231-3.878-10.694-4.054a5.38 5.38 0 0 0 1.742 3.662.75.75 0 0 1-1.014 1.106 6.88 6.88 0 0 1-2.23-4.803H4.237C1.9 16.25 0 14.344 0 12s1.9-4.25 4.237-4.25H6.25c3.687 0 7.542-1.379 11.459-4.099l1.104-.767a.75.75 0 0 1 1.178.616M4.237 14.75H5.5v-5.5H4.237C2.728 9.25 1.5 10.483 1.5 12s1.228 2.75 2.737 2.75m14.254-9.816C14.604 7.618 10.742 9.062 7 9.233v5.534c3.75.17 7.612 1.611 11.491 4.289z"/></svg>)}
    />
  );
}
  