// @flow

/* eslint-disable import/prefer-default-export */

import * as R from 'ramda';
import type { StoreStateT } from '../../commonTypes';
import { deserializeUserConfigValue, sortUsersByProp } from '../../userConfigHelpers';
import type {
  ExternalUserStateEntityT,
  InternalUserStateEntityT,
  UserStateEntityT
} from '../entities/user/userTypes';
import type { UserConfigT } from './configTypes';

const convertConfigToUsers = (
  configType: 'favouriteUser' | 'recentUser',
  enterpriseId: string,
  userConfig: UserConfigT
): Array<InternalUserStateEntityT | ExternalUserStateEntityT> =>
  userConfig
    .filter(({ key }) => key.startsWith(`${configType}_${enterpriseId}`))
    .map(({ value }) => {
      const {
        id,
        userType,
        firstName,
        lastName,
        subtitle,
        urlPhoto,
        personId
      } = deserializeUserConfigValue(value);
      return userType === 'internalUser'
        ? {
            id,
            enterpriseId,
            userType: 'internalUser',
            urlPhoto,
            firstName,
            lastName,
            personId,
            title: subtitle,
            __metadata: {},
            locked: false,
            isAcdAgent: false
          }
        : {
            id,
            enterpriseId,
            userType: 'externalUser',
            firstName,
            lastName,
            company: subtitle,
            __metadata: {}
          };
    });

export const selectFavouriteConfigsAsUsers = (
  state: StoreStateT,
  enterpriseId: ?string
): UserStateEntityT[] =>
  // $FlowFixMe: compose missing in types
  R.compose(
    R.curry(sortUsersByProp)('lastName'),
    R.curry(convertConfigToUsers)('favouriteUser')(enterpriseId || 'unknown')
  )(state.config.userConfig);

export const hasFavouriteConfig = (
  state: StoreStateT,
  type: 'external' | 'internal',
  enterpriseId: string,
  userId: string
): boolean =>
  state.config.userConfig &&
  state.config.userConfig.some(
    ({ key }) => key === `favouriteUser_${enterpriseId}_${type}_${userId}`
  );

export const selectRecentConfigsAsUsers = (
  state: StoreStateT,
  enterpriseId: ?string
): UserStateEntityT[] => {
  // $FlowFixMe: compose missing in types
  return R.compose(R.curry(convertConfigToUsers)('recentUser')(enterpriseId || 'unknown'))(
    state.config.userConfig
  );
};
