// @flow

import * as R from 'ramda';
import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '@design-system/component-library/src/components/Toggle';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import IconArrowRightRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowRightRegular';
import IconArrowLeftRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowLeftRegular';
import { createCloseOnboardingAction } from '../../ducks/ui/header/headerUiActions';
import { BaseModal } from '../../components/BaseModal';
import { operations } from '../../ducks/config';
import { getConfigurationValue } from '../../userConfigHelpers';
import OnboardingContent from './OnboardingContent';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import ActionButton from '../../components/Button/ActionButton';
import SecondaryButton from '../../components/Button/SecondaryButton';

import styles from './OnboardingModal.module.scss';

type PropsT = {||};

export const ONBOARDING_DISPLAYED_CONFIG = 'onboarding_displayed_v2';
// eslint-disable-next-line no-unused-vars
export const OnboardingModal = (props: PropsT): Element<typeof BaseModal> | null => {
  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboardingOpen: boolean = useSelector(state => state.ui.header.onboardingOpen);
  const userConfig = useSelector(state => state.config.userConfig);
  const [stepNumber, setStepNumber] = useState(0);
  const currentUser = useSelector(state => state.currentUser);
  const ONBOARDING_STEP_COUNT = 6;

  useEffect(() => {
    const fetchUserConfig = async () => {
      await dispatch(operations.getUserConfig());
    };
    fetchUserConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const flipOnboardingValue = (): void => {
    const newOnboardingDisplayedValue =
      getConfigurationValue(userConfig, ONBOARDING_DISPLAYED_CONFIG) === 'true' ? 'false' : 'true';
    dispatch(
      operations.updateUserConfig(
        [
          ...R.reject(
            item => 'key' in item && item.key === ONBOARDING_DISPLAYED_CONFIG,
            userConfig
          ),
          { key: ONBOARDING_DISPLAYED_CONFIG, value: newOnboardingDisplayedValue }
        ],
        createCsrfHeader(currentUser)
      )
    );
  };

  const handleOnClose = (): void => {
    setStepNumber(0);
    dispatch(createCloseOnboardingAction());
  };

  const handleOnPreviousStep = () => {
    setStepNumber(stepNumber - 1);
  };

  const handleOnNextStep = () => {
    setStepNumber(stepNumber + 1);
  };

  const onboardDisplayedValue = getConfigurationValue(userConfig, ONBOARDING_DISPLAYED_CONFIG);
  return onboardingOpen ? (
    <BaseModal modalStyles={[styles.modal]} onClose={() => handleOnClose()}>
      <div className={styles['onboarding-container']}>
        {stepNumber === 0 && (
          <OnboardingContent
            id="welcome"
            contentText={t('onboarding.step1Text')}
            imgSrc="/welcome-pic.svg"
            title={t('onboarding.step1Title')}
          />
        )}
        {stepNumber === 1 && (
          <OnboardingContent
            id="userinterface"
            contentText={t('onboarding.step2Text')}
            imgSrc="/user-interface-pic.svg"
            title={t('onboarding.step2Title')}
          />
        )}
        {stepNumber === 2 && (
          <OnboardingContent
            id="callforwardings"
            contentText={t('onboarding.step3Text')}
            imgSrc="/call-forwardings-pic.svg"
            title={t('onboarding.step3Title')}
          />
        )}
        {stepNumber === 3 && (
          <OnboardingContent
            id="callflows"
            contentText={t('onboarding.step4Text')}
            imgSrc="/call-flows-pic.svg"
            title={t('onboarding.step4Title')}
          />
        )}
        {stepNumber === 4 && (
          <OnboardingContent
            id="contacts"
            contentText={t('onboarding.step5Text')}
            imgSrc="/contacts-pic.svg"
            title={t('onboarding.step5Title')}
          />
        )}
        {stepNumber === 5 && (
          <OnboardingContent
            id="notfound"
            contentText={parse(
              `<p>${t('onboarding.step6Text1')}<a href=${t(
                'onboarding.step6Link'
              )} target="_blank">${t('onboarding.step6LinkText')}</a>${t(
                'onboarding.step6Text2'
              )}</p>${t('onboarding.step6Text3')}`
            )}
            imgSrc="/looking-pic.svg"
            title={t('onboarding.step6Title')}
          />
        )}
        <div className={styles['bottom-area']}>
          <div className={styles.checkbox}>
            <Toggle
              id="show-availability-checkbox"
              value={onboardDisplayedValue === 'true'}
              onToggle={() => flipOnboardingValue()}
              label={t('onboarding.doNotShowAgain')}
            />
          </div>
          <div>
            <div className={styles['button-area']}>
              {stepNumber > 0 && (
                <SecondaryButton
                  id="onboarding-previous-button"
                  className={styles['onboarding-button']}
                  label={t('onboarding.previous')}
                  onClickAction={() => handleOnPreviousStep()}
                  leading={<IconArrowLeftRegular />}
                />
              )}
              <div className={styles['paging-text']}>
                {stepNumber + 1}/{ONBOARDING_STEP_COUNT}
              </div>
              {stepNumber === ONBOARDING_STEP_COUNT - 1 && (
                <ActionButton
                  id="onboarding-next-button"
                  className={styles['next-button']}
                  label={t('onboarding.ok')}
                  onClickAction={() => handleOnClose()}
                />
              )}
              {stepNumber < ONBOARDING_STEP_COUNT - 1 && (
                <SecondaryButton
                  id="onboarding-next-button"
                  className={styles['onboarding-button']}
                  label={t('onboarding.next')}
                  onClickAction={() => handleOnNextStep()}
                  trailing={<IconArrowRightRegular />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  ) : null;
};

export default OnboardingModal;
