// @flow strict-local

/* eslint-disable class-methods-use-this */

import React, { Component, type Element } from 'react';
import classnames from 'classnames';
import type { CallFlowGridElementT, CallFlowTreeNodeT } from '../../../../ducks/entities/callFlow';
import { treeHeight } from './CallFlowTreeUtils';
import { calculateCallFlowTreeNodePositions } from './CallFlowTreeCoordinateCalculator';
import WelcomeAttendantNode from '../nodes/welcomeAttendant/WelcomeAttendantNode';
import AcdNode from '../nodes/acd/AcdNode';
import ExtensionGroupNode from '../nodes/extensionGroup/ExtensionGroupNode';
import PlayMusicNode from '../nodes/playMusic/PlayMusicNode';
import SpeedDialNode from '../nodes/speedDial/SpeedDialNode';
import OcNode from '../nodes/OcNode';
import styles from './CallFlowTree.module.scss';

type StateT = {};

export type PropsT = {|
  rootNode: ?CallFlowTreeNodeT,
  gridElement: CallFlowGridElementT
|};

export class CallFlowTree extends Component<PropsT, StateT> {
  static getGridStyles(rootNode: ?CallFlowTreeNodeT) {
    let columns = '';
    let rows = '';
    const width = 0;
    let height = 0;
    if (rootNode) {
      const maxWidth = rootNode.maxWidth || 0;
      const maxHeight = treeHeight(rootNode);
      columns += 'auto '.repeat(maxWidth);
      height = maxHeight * 140;
      rows += 'auto '.repeat(maxHeight);
    }
    return {
      gridTemplateColumns: columns,
      width: `${width}px`,
      gridTemplateRows: rows,
      height: `${height}px`
    };
  }

  renderServiceNode(node: CallFlowTreeNodeT) {
    const { gridElement } = this.props;
    switch (gridElement.type) {
      case 'WELCOME_ATTENDANT':
        return (
          <WelcomeAttendantNode
            enterpriseId={gridElement.enterpriseId}
            id={node.id}
            type={node.type}
          />
        );
      case 'ACD_CUSTOMER_SERVICE':
      case 'ACD_SWITCHBOARD': {
        const callflowId = `${gridElement.enterpriseId}-${gridElement.type}-${gridElement.id}`;
        return <AcdNode node={node} callflowId={callflowId} />;
      }
      case 'EXTENSION_GROUP':
        return (
          <ExtensionGroupNode
            node={node}
            serviceId={gridElement.id}
            enterpriseId={gridElement.enterpriseId}
          />
        );
      case 'PLAY_MUSIC':
        return <PlayMusicNode serviceId={gridElement.id} />;
      case 'OC':
        return <OcNode serviceId={gridElement.id} enterpriseId={gridElement.enterpriseId} />;
      case 'SPEED_DIAL':
        return <SpeedDialNode serviceId={gridElement.id} enterpriseId={gridElement.enterpriseId} />;
      default:
        break;
    }
    return <div />;
  }

  renderGridContent(step: CallFlowTreeNodeT) {
    return <div className={styles.content}>{this.renderServiceNode(step)}</div>;
  }

  static renderVerticalBackgroundLine(dir: string = 'down') {
    return dir === 'down' ? (
      <div className={styles['background-vertical']} />
    ) : (
      <div
        className={classnames(styles['background-vertical'], styles['background-vertical--up'])}
      />
    );
  }

  static renderHorizontalBackgroundLine(length: number) {
    return (
      <div
        className={styles['background-horizontal']}
        style={{ width: `${120 * length - 20}px` }}
      />
    );
  }

  renderNodes(currentNode: ?CallFlowTreeNodeT): ?(Element<'div'>[]) {
    if (!currentNode) {
      return [];
    }
    const coordinateX = currentNode.coordinate ? currentNode.coordinate.x : 0;
    const coordinateY = currentNode.coordinate ? currentNode.coordinate.y : 0;

    const rowItems = this.renderNodes(currentNode.horizontalNode) || [];
    const columnItems = this.renderNodes(currentNode.verticalNode) || [];

    const horizontalLineLength =
      currentNode.horizontalNode && currentNode.horizontalNode.coordinate
        ? currentNode.horizontalNode.coordinate.x - coordinateX
        : 0;

    return [
      ...columnItems,
      ...rowItems,
      <div
        key={`${currentNode.type}_${coordinateX}_${coordinateY}`}
        className={styles['grid-element']}
        style={{ top: `${coordinateY * 140}px`, left: `${coordinateX * 120}px` }}
      >
        {horizontalLineLength
          ? CallFlowTree.renderHorizontalBackgroundLine(horizontalLineLength)
          : null}{' '}
        {currentNode.verticalNode ? CallFlowTree.renderVerticalBackgroundLine('down') : null}{' '}
        {this.renderGridContent(currentNode)}
      </div>
    ];
  }

  render(): Element<'div'> {
    const { rootNode } = this.props;
    const node = calculateCallFlowTreeNodePositions(rootNode);
    const gridStyles = node ? CallFlowTree.getGridStyles(node) : [];

    return (
      <div className={styles['grid-container']}>
        <div className={styles['grid-sub-container']} style={gridStyles}>
          {node && this.renderNodes(node)}
        </div>
      </div>
    );
  }
}

export default CallFlowTree;
