import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMobilePaymentLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 1.25a6.5 6.5 0 1 0 6.5 6.5 6.51 6.51 0 0 0-6.5-6.5m0 12a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 5.5 5.5 5.51 5.51 0 0 1-5.5 5.5m-2-6h2a.5.5 0 0 1 0 1h-2a2.5 2.5 0 0 0 2.44 2 2.5 2.5 0 0 0 1.34-.39.5.5 0 1 1 .54.84A3.48 3.48 0 0 1 14 8.25h-.47a.5.5 0 0 1 0-1H14a3.48 3.48 0 0 1 5.32-2.43.5.5 0 1 1-.54.84 2.5 2.5 0 0 0-1.34-.39A2.5 2.5 0 0 0 15 7.25m.05 7.5a.5.5 0 0 0-.5.5V17H2.5V2a1 1 0 0 1 1-1h10a.5.5 0 0 0 0-1h-10a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6.75a.5.5 0 0 0-.5-.5zM13.5 23h-10a1 1 0 0 1-1-1v-4h12v4a1 1 0 0 1-1 1m-4.25-2.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0"/></svg>)}
    />
  );
}
  