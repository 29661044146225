// @flow
import type { DirectoryEntityT } from './directoryTypes';

export type SearchCategoryT =
  | 'all'
  | 'acds'
  | 'departments'
  | 'favorites'
  | 'switchboardfavorites'
  | 'groups'
  | 'privates'
  | 'services'
  | 'shared'
  | 'personalspeeddials'
  | 'speeddials'
  | 'welcomeattendants'
  | 'users'
  | 'servicesFiltered';

// eslint-disable-next-line import/prefer-default-export
export const getCallflowServiceUrlByType = (
  type: SearchCategoryT,
  enterpriseId: string
): ?string => {
  switch (type) {
    case 'users':
      return `/api/v1/enterprises/${enterpriseId}/users`;
    case 'acds':
      return `/api/v1/enterprises/${enterpriseId}/services/acds`;
    case 'groups':
      return `/api/v1/enterprises/${enterpriseId}/services/groups`;
    case 'services':
      return `/api/v1/enterprises/${enterpriseId}/services/ivrs`;
    case 'speeddials':
      return `/api/v1/enterprises/${enterpriseId}/services/speeddials`;
    case 'welcomeattendants':
      return `/api/v1/enterprises/${enterpriseId}/services/welcomeattendants`;
    default:
      return undefined;
  }
};

export const convertToDirectoryData = (type: SearchCategoryT, data: *): DirectoryEntityT[] => {
  switch (type) {
    case 'users':
      return data.map(d => ({
        id: d.id,
        type: 'IUser',
        displayName: d.userName,
        publicInfo: { addressNumber: d.addressNumber }
      }));
    case 'acds':
    case 'groups':
    case 'speeddials':
    case 'welcomeattendants':
      return data.map(d => ({
        id: d.id,
        type: d.type,
        displayName: d.label,
        publicInfo: { addressNumber: d.addressNumber }
      }));
    case 'services':
      return data
        .filter(d => d.ivrName === 'PlayMusic')
        .map(d => ({
          id: d.id,
          type: 'PLAY_MUSIC',
          displayName: d.label,
          publicInfo: { addressNumber: d.addressNumber }
        }));
    default:
      return [];
  }
};

export const modifySearchForPhoneNumber = (searchTerm: string): string => {
  let modifiedSearchTerm = searchTerm.replace(/[+*?]/g, '');
  if (modifiedSearchTerm.startsWith('0')) {
    modifiedSearchTerm = modifiedSearchTerm.substring(1);
  }
  return modifiedSearchTerm;
};
