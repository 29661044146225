import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUserFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m0 5.2c2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2-4.2-1.9-4.2-4.2c0-2.2 1.9-4.1 4.2-4.2m0 17.3c-2.7 0-5.2-1-7.2-2.9.5-2.5 3.8-3.9 7.2-3.9 3.9 0 6.8 1.6 7.2 3.9-1.9 1.9-4.5 2.9-7.2 2.9"/></svg>)}
    />
  );
}
  