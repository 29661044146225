// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VoiceMailIcon } from '../../../../../assets/callflow/details/voicemail_open.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import VoiceMailAudio from '../../../components/edit/children/audio/VoiceMailAudio';
import CallflowItem from '../../../components/view/children/CallflowItem';
import type { VoiceMailAudioFieldT } from '../../../components/edit/children/audio/CallflowAudioUtils';
import type { VoiceMailLevelT } from '../../../../../ducks/entities/voicemailTypes';

export type PropsT = {|
  callflowId: string
|};

export const VoiceMailDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const { voicemailSettings } = acdData;
  const currentlyActiveFile: VoiceMailLevelT =
    R.path(['audios', 'current', 'name'], voicemailSettings) || 'PhoneNumber';

  const voiceMailAudio: VoiceMailAudioFieldT = {
    enterpriseId: acdData.enterpriseId,
    callflowType: 'acds',
    callflowId: acdData.id,
    audioType: 'voicemail',
    propertyName: currentlyActiveFile,
    serviceType: 'ACD'
  };

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_voice_mail_${acdData.id}`}
      nodeType="ACD_VOICE_MAIL"
      icon={<VoiceMailIcon />}
      title={t('callflows.viewVoiceMail.title')}
      active
    >
      <CallflowDetailsColumn id="acd-voice-mail-column-1">
        <CallflowItem
          id="isVoiceMailNoMessage"
          title={t('voicemailSettings.isVoiceMailMessage')}
          value={
            voicemailSettings.isVoiceMailNoMessage
              ? t('voicemailSettings.voiceMailNotInUse')
              : t('voicemailSettings.voiceMailInUse')
          }
        />
        <VoiceMailAudio
          title={t('voicemailSettings.title')}
          audioData={voiceMailAudio}
          noAudioLabel={
            currentlyActiveFile === 'PhoneNumber' ? t('voicemailSettings.audioDefault') : ''
          }
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default VoiceMailDetails;
