// @flow

import React, { type Element } from 'react';
import classNames from 'classnames';
import type { ExactPropsT } from '../../commonTypes';
import { ReactComponent as NoSitesIcon } from '../../assets/no-sites.svg';
import { ReactComponent as NoDepartmentsIcon } from '../../assets/no-departments.svg';
import LinkButton from '../Button/LinkButton';
import styles from './NotFoundCard.module.scss';

type PropsT = {
  id: string,
  label: string,
  linkLabel: string,
  className?: string,
  classNameLink?: string,
  type: 'site' | 'organization',
  onClickAction: Event => void
};

export type TestPropsT = ExactPropsT<PropsT>;

const NotFoundCard = ({
  id,
  label,
  linkLabel,
  className,
  classNameLink,
  onClickAction,
  type
}: PropsT): Element<'div'> => (
  <div id={id} className={classNames(styles['no-content-found-container'], className)}>
    {type === 'organization' ? <NoDepartmentsIcon /> : <NoSitesIcon />}
    <div className={styles['text-link-container']}>
      <p>{label}</p>
      <LinkButton
        id={`${id}-link-button`}
        label={linkLabel}
        className={classNames(styles['link-style'], classNameLink)}
        onClickAction={onClickAction}
      />
    </div>
  </div>
);

NotFoundCard.defaultProps = {
  className: '',
  classLinkStyle: ''
};

export default NotFoundCard;
