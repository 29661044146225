// @flow

import axios, { type CancelToken, type AxiosPromise } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import {
  createGetEnterpriseFailure,
  createGetEnterpriseRequest,
  createGetEnterprisesFailure,
  createGetEnterprisesRequest,
  createGetEnterprisesSuccess,
  createGetEnterpriseSuccess,
  createUpdateEnterpriseFailure,
  createUpdateEnterpriseRequest,
  createUpdateEnterpriseSuccess,
  createGetEnterpriseCancel,
  createGetEnterprisesCancel,
  createUpdateEnterpriseCancel
} from './enterpriseActions';
import type {
  RetrieveEnterpriseCollectionRequestMetaT,
  EnterpriseEntityT,
  EnterpriseGetAllActionT,
  EnterpriseGetOneActionT,
  EnterprisePatchActionT
} from './enterpriseTypes';

type GetEnterpriseFnT = (
  string,
  CancelToken
) => AxiosPromise<EnterpriseGetOneActionT | ?EnterpriseEntityT>;
// eslint-disable-next-line arrow-body-style
export const getEnterprise: GetEnterpriseFnT = (id, cancelToken) => async dispatch => {
  dispatch(createGetEnterpriseRequest(id));
  try {
    const response = await axios({
      method: 'GET',
      url: `/api/v1/enterprises/${id}`,
      cancelToken
    });
    dispatch(createGetEnterpriseSuccess(response.data));
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createGetEnterpriseCancel(id));
    } else if (error.config) {
      dispatch(createGetEnterpriseFailure(id, error));
    } else {
      console.error('Internal error:', error); // eslint-disable-line no-console
    }
  }
  return undefined;
};

type GetEnterprisesFnT = (
  CancelToken,
  params?: RetrieveEnterpriseCollectionRequestMetaT
) => ThunkActionT<EnterpriseGetAllActionT>;
export const getEnterprises: GetEnterprisesFnT = (cancelToken, params = {}) => async dispatch => {
  dispatch(createGetEnterprisesRequest(params));
  try {
    const response = await axios({
      method: 'GET',
      url: '/api/v1/enterprises?sort=fullName',
      cancelToken,
      params
    });
    dispatch(createGetEnterprisesSuccess(response.data, params));
    return response.data.results;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createGetEnterprisesCancel());
    } else if (error.config) {
      dispatch(createGetEnterprisesFailure(error, params));
    } else {
      console.error('Internal error:', error); // eslint-disable-line no-console
    }
  }
  return undefined;
};

export type SaveEnterpriseFullNameFnT = (
  string,
  string,
  headers: {}
) => ThunkActionT<EnterprisePatchActionT>;
// eslint-disable-next-line arrow-body-style
export const saveEnterpriseFullName: SaveEnterpriseFullNameFnT = (
  enterpriseId,
  enterpriseFullName,
  headers
) => async dispatch => {
  dispatch(createUpdateEnterpriseRequest(enterpriseId));
  try {
    const { data } = await axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}`,
      data: {
        fullName: enterpriseFullName
      },
      headers
    });
    dispatch(createUpdateEnterpriseSuccess(enterpriseId, data.fullName));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateEnterpriseCancel(enterpriseId));
    } else if (error.config) {
      dispatch(createUpdateEnterpriseFailure(enterpriseId, error));
    } else {
      console.error('Internal error:', error); // eslint-disable-line no-console
    }
  }
  return undefined;
};
