import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPictureLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 9a2 2 0 1 0 .001-3.999A2 2 0 0 0 6 9m0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2m15-4H3a1.93 1.93 0 0 0-2 1.857V20.14A1.93 1.93 0 0 0 2.997 22H21a1.93 1.93 0 0 0 2-1.857V3.86A1.93 1.93 0 0 0 21.003 2zm1 18.14a.94.94 0 0 1-1 .86H3a.94.94 0 0 1-1-.86V3.86A.94.94 0 0 1 3 3h18a.94.94 0 0 1 1 .86zm-2.06-1.88a.51.51 0 0 1-.2.68.5.5 0 0 1-.679-.198l-.001-.002-4.4-7.91a.23.23 0 0 0-.21-.13.24.24 0 0 0-.22.11l-3.11 4.66a.77.77 0 0 1-1.24 0l-1.17-1.75a.26.26 0 0 0-.42 0l-3.37 5.06a.504.504 0 1 1-.84-.56l3.38-5.06a1.26 1.26 0 0 1 1-.55c.405.012.778.221 1 .56l1 1.44 2.9-4.36a1.26 1.26 0 0 1 1.09-.55 1.2 1.2 0 0 1 1 .64z"/></svg>)}
    />
  );
}
  