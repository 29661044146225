// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import type {
  RetrieveCallFlowCollectionActionT,
  RetrieveCallFlowActionT,
  CallFlowStateT,
  CallFlowActionT,
  UpdateExtensionGroupActionT,
  UpdateSpeedDialActionT,
  UpdatePlayMusicActionT
} from './callFlowTypes';
import type { UpdateAcdActionT } from '../acd/acdTypes';
import type { UpdateWelcomeAttendantActionT } from '../welcomeAttendant/welcomeAttendantTypes';

const initialState: CallFlowStateT = {
  byId: {},
  allIds: [],
  __metadata: {}
};

const retrieveCollectionReducer = (
  state: CallFlowStateT,
  action: RetrieveCallFlowCollectionActionT
): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_REQUEST': {
      return {
        ...state,
        __metadata: {
          ...state.__metadata,
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe problem with call flow types
          ...action.payload.reduce(
            (collection, callflow) => ({
              ...collection,
              [`${callflow.enterpriseId}-${callflow.type}-${callflow.id}`]: {
                ...callflow,
                __metadata: {}
              }
            }),
            {}
          )
        },
        allIds: R.union(
          state.allIds,
          action.payload.map(callflow => `${callflow.enterpriseId}-${callflow.type}-${callflow.id}`)
        ),
        __metadata: {
          ...action.meta
        }
      };
    }
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const retrieveReducer = (
  state: CallFlowStateT,
  action: RetrieveCallFlowActionT
): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/callflow/RETRIEVE_REQUEST': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`]: {
            ...state.byId[
              `${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`
            ],
            __metadata: {
              isRetrieving: true,
              isRetrying: action.meta.retrying
            }
          }
        }
      };
    }
    case 'ring/entity/callflow/RETRIEVE_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`]: {
            ...action.payload,
            __metadata: {
              hasFullData: true
            }
          }
        },
        allIds: R.union(state.allIds, [
          `${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`
        ])
      };
    }
    case 'ring/entity/callflow/RETRIEVE_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`]: {
            ...state.byId[
              `${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`
            ],
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/callflow/RETRIEVE_CANCEL': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`]: {
            ...state.byId[
              `${action.meta.enterpriseId}-${action.meta.callFlowType}-${action.meta.callFlowId}`
            ],
            __metadata: {}
          }
        }
      };
    }
    default:
      return state;
  }
};

const updatePlayMusicReducer = (
  state: CallFlowStateT,
  action: UpdatePlayMusicActionT
): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/playmusic/UPDATE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isUpdating: true
        }
      };
    }
    case 'ring/entity/playmusic/UPDATE_SUCCESS': {
      const callFlowId = `${action.meta.enterpriseId}-${action.payload.type}-${action.payload.id}`;
      return {
        ...state,
        __metadata: {
          isUpdating: false
        },
        byId: {
          ...state.byId,
          // $FlowFixMe
          [callFlowId]: {
            ...state.byId[callFlowId],
            // $FlowFixMe payload typing is out of date
            ...action.payload,
            __metadata: {
              hasFullData: true
            }
          }
        },
        allIds: R.union(state.allIds, [callFlowId])
      };
    }
    case 'ring/entity/playmusic/UPDATE_FAILURE': {
      return {
        ...state,
        __metadata: {
          isUpdating: false,
          error: action.payload
        }
      };
    }
    case 'ring/entity/playmusic/UPDATE_CANCEL': {
      return {
        ...state,
        __metadata: {
          isUpdating: false
        }
      };
    }
    default:
      return state;
  }
};

const updateWelcomeAttendantReducer = (
  state: CallFlowStateT,
  action: UpdateWelcomeAttendantActionT
): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/welcomeattendant/UPDATE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isUpdating: true
        }
      };
    }
    case 'ring/entity/welcomeattendant/UPDATE_SUCCESS': {
      const callFlowId = `${action.meta.enterpriseId}-${action.payload.type}-${action.payload.id}`;
      return {
        ...state,
        __metadata: {
          isUpdating: false
        },
        byId: {
          ...state.byId,
          [callFlowId]: {
            ...state.byId[callFlowId],
            ...action.payload,
            __metadata: {
              hasFullData: true
            }
          }
        },
        allIds: R.union(state.allIds, [callFlowId])
      };
    }
    case 'ring/entity/welcomeattendant/UPDATE_FAILURE': {
      return {
        ...state,
        __metadata: {
          isUpdating: false,
          error: action.payload
        }
      };
    }
    case 'ring/entity/welcomeattendant/UPDATE_CANCEL': {
      return {
        ...state,
        __metadata: {
          isUpdating: false
        }
      };
    }
    default:
      return state;
  }
};

const updateAcdReducer = (state: CallFlowStateT, action: UpdateAcdActionT): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/acd/UPDATE_REQUEST': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.type}-${action.meta.acdId}`]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.type}-${action.meta.acdId}`],
            __metadata: {
              isUpdating: true
            }
          }
        }
      };
    }
    case 'ring/entity/acd/UPDATE_SUCCESS': {
      const callflowId = `${action.meta.enterpriseId}-${action.payload.type}-${action.payload.id}`;
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          [callflowId]: {
            ...state.byId[callflowId],
            // $FlowFixMe should make more types exact
            ...action.payload,
            __metadata: {
              hasFullData: true,
              isUpdating: false
            }
          }
        },
        allIds: R.union(state.allIds, [
          `${action.meta.enterpriseId}-${action.payload.type}-${action.payload.id}`
        ])
      };
    }
    case 'ring/entity/acd/UPDATE_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.type}-${action.payload.id}`]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.type}-${action.payload.id}`],
            __metadata: {
              error: action.payload,
              isUpdating: false
            }
          }
        }
      };
    }
    case 'ring/entity/acd/UPDATE_CANCEL': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.type}-${action.meta.acdId}`]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.type}-${action.meta.acdId}`],
            __metadata: {}
          }
        }
      };
    }
    default:
      return state;
  }
};

const updateExtensionGroupReducer = (
  state: CallFlowStateT,
  action: UpdateExtensionGroupActionT
): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/extgroup/UPDATE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isUpdating: true
        }
      };
    }
    case 'ring/entity/extgroup/UPDATE_SUCCESS': {
      const callflowId = `${action.meta.enterpriseId}-${action.payload.type}-${action.payload.id}`;
      return {
        ...state,
        __metadata: {
          isUpdating: false
        },
        byId: {
          ...state.byId,
          [callflowId]: {
            ...state.byId[callflowId],
            // 2021-07-02: should make more types exact
            ringPattern: action.payload.ringPattern,
            maxCycles: action.payload.maxCycles,
            cyclicRingingTime: action.payload.cyclicRingingTime,
            members: action.payload.members,
            label: action.payload.label,
            active: action.payload.active,
            hiddenDirectory: action.payload.hiddenDirectory,
            groupDisplayedLabelsTerminalsPolicy: action.payload.groupDisplayedLabelsTerminalsPolicy,
            groupDisplayedNumberPolicy: action.payload.groupDisplayedNumberPolicy,
            overrideEnterpriseGroupsDisplayPolicies:
              action.payload.overrideEnterpriseGroupsDisplayPolicies,
            voicemailSettings: action.payload.voicemailSettings,
            ivrProperties: {
              ...action.payload.ivrProperties
            },
            forwardingsOnService: action.payload.forwardingsOnService,
            __metadata: {
              hasFullData: true
            }
          }
        },
        allIds: R.union(state.allIds, [
          `${action.meta.enterpriseId}-${action.payload.type}-${action.payload.id}`
        ])
      };
    }
    case 'ring/entity/extgroup/UPDATE_FAILURE': {
      return {
        ...state,
        __metadata: {
          isUpdating: false,
          error: action.payload
        }
      };
    }
    case 'ring/entity/extgroup/UPDATE_CANCEL': {
      return {
        ...state,
        __metadata: {
          isUpdating: false
        }
      };
    }
    default:
      return state;
  }
};

const updateSpeedDialReducer = (
  state: CallFlowStateT,
  action: UpdateSpeedDialActionT
): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/speeddial/UPDATE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isUpdating: true
        }
      };
    }
    case 'ring/entity/speeddial/UPDATE_SUCCESS': {
      const callflowId = `${action.meta.enterpriseId}-${action.payload.type}-${action.payload.id}`;
      return {
        ...state,
        __metadata: {
          isUpdating: false
        },
        byId: {
          ...state.byId,
          [callflowId]: {
            ...state.byId[callflowId],
            // $FlowFixMe should make more types exact
            ...action.payload,
            __metadata: {}
          }
        },
        allIds: R.union(state.allIds, [callflowId])
      };
    }
    case 'ring/entity/speeddial/UPDATE_FAILURE': {
      return {
        ...state,
        __metadata: {
          isUpdating: false,
          error: action.payload
        }
      };
    }
    case 'ring/entity/speeddial/UPDATE_CANCEL': {
      return {
        ...state,
        __metadata: {
          isUpdating: false
        }
      };
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: CallFlowStateT = initialState,
  action: CallFlowActionT
): CallFlowStateT => {
  switch (action.type) {
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_REQUEST':
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_SUCCESS':
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_FAILURE':
    case 'ring/entity/callflow/RETRIEVE_COLLECTION_CANCEL': {
      return callFlowReducer.retrieveCollectionReducer(state, action);
    }
    case 'ring/entity/callflow/RETRIEVE_REQUEST':
    case 'ring/entity/callflow/RETRIEVE_SUCCESS':
    case 'ring/entity/callflow/RETRIEVE_FAILURE':
    case 'ring/entity/callflow/RETRIEVE_CANCEL': {
      return callFlowReducer.retrieveReducer(state, action);
    }
    case 'ring/entity/acd/UPDATE_REQUEST':
    case 'ring/entity/acd/UPDATE_SUCCESS':
    case 'ring/entity/acd/UPDATE_FAILURE':
    case 'ring/entity/acd/UPDATE_CANCEL': {
      return callFlowReducer.updateAcdAgentReducer(state, action);
    }
    case 'ring/entity/playmusic/UPDATE_REQUEST':
    case 'ring/entity/playmusic/UPDATE_SUCCESS':
    case 'ring/entity/playmusic/UPDATE_FAILURE':
    case 'ring/entity/playmusic/UPDATE_CANCEL': {
      return callFlowReducer.updatePlayMusicReducer(state, action);
    }
    case 'ring/entity/extgroup/UPDATE_REQUEST':
    case 'ring/entity/extgroup/UPDATE_SUCCESS':
    case 'ring/entity/extgroup/UPDATE_FAILURE':
    case 'ring/entity/extgroup/UPDATE_CANCEL': {
      return callFlowReducer.updateExtensionGroupReducer(state, action);
    }
    case 'ring/entity/speeddial/UPDATE_REQUEST':
    case 'ring/entity/speeddial/UPDATE_SUCCESS':
    case 'ring/entity/speeddial/UPDATE_FAILURE':
    case 'ring/entity/speeddial/UPDATE_CANCEL': {
      return callFlowReducer.updateSpeedDialReducer(state, action);
    }
    case 'ring/entity/welcomeattendant/UPDATE_REQUEST':
    case 'ring/entity/welcomeattendant/UPDATE_SUCCESS':
    case 'ring/entity/welcomeattendant/UPDATE_FAILURE':
    case 'ring/entity/welcomeattendant/UPDATE_CANCEL': {
      return callFlowReducer.updateWelcomeAttendantReducer(state, action);
    }
    case 'ring/entity/callflow/CLEAR': {
      return initialState;
    }
    default:
      return state;
  }
};

const callFlowReducer = {
  initialState,
  retrieveCollectionReducer,
  retrieveReducer,
  updateAcdAgentReducer: updateAcdReducer,
  updateExtensionGroupReducer,
  updateSpeedDialReducer,
  updatePlayMusicReducer,
  updateWelcomeAttendantReducer,
  rootReducer
};

export default callFlowReducer;
