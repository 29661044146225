// @flow strict-local

import React, { type Element } from 'react';
import classnames from 'classnames';
import type { VoiceMailAudioFieldT } from './CallflowAudioUtils';
import AudioPlayerContainer from '../../../../../../components/AudioPlayer/AudioPlayerContainer';
import Tooltip from '../../../../../../components/Tooltip';

import styles from './VoiceMailAudio.module.scss';

type PropsT = {|
  title: string,
  audioData: VoiceMailAudioFieldT,
  active?: boolean,
  tooltip?: string,
  noAudioLabel?: string
|};

export const VoiceMailAudio = (props: PropsT): Element<'div'> => {
  const { title, active, tooltip, noAudioLabel, audioData } = props;
  const activeStyle = active ? styles.active : styles.inactive;
  return (
    <div className={styles.detail}>
      <div className={classnames(styles.header, activeStyle)}>
        {title}
        {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
      </div>
      <div className={classnames(styles.value, activeStyle)}>
        {noAudioLabel ? (
          <div>{noAudioLabel}</div>
        ) : (
          <AudioPlayerContainer
            audio={audioData}
            active={active}
            customNoAudioLabel={noAudioLabel}
          />
        )}
      </div>
    </div>
  );
};

export default VoiceMailAudio;
