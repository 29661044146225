import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTvRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.8 1.75H2.25A2.25 2.25 0 0 0 0 4v12a2.25 2.25 0 0 0 2.25 2.25h8.97v2.5H7.45a.75.75 0 0 0 0 1.5h9.09a.75.75 0 0 0 0-1.5h-3.82v-2.5h9.08A2.25 2.25 0 0 0 24 16V4a2.25 2.25 0 0 0-2.2-2.25M22.5 16a.76.76 0 0 1-.75.75H2.25A.76.76 0 0 1 1.5 16V4a.75.75 0 0 1 .75-.75H21.8a.75.75 0 0 1 .7.75z"/></svg>)}
    />
  );
}
  