import React from 'react';
import IconContainer from '../IconContainer';

export default function IconViewOffLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.81 16.439a22 22 0 0 1-3.245-2.953 2.24 2.24 0 0 1-.002-2.974c2.648-2.957 7.081-6.071 11.444-6.011 1.247-.03 2.592.225 3.966.733a.5.5 0 1 1-.348.937c-1.258-.465-2.492-.703-3.618-.67-4.048-.091-8.199 2.887-10.698 5.677a1.24 1.24 0 0 0 .002 1.644 21 21 0 0 0 3.094 2.813l.257.187q.236.168.479.332a.5.5 0 1 1-.556.83q-.255-.17-.504-.349zm19.627-2.955c-2.648 2.958-7.002 6.083-11.448 6.014q-.076.002-.156.002c-1.667 0-3.442-.459-5.263-1.363l-3.716 3.716a.5.5 0 1 1-.708-.706l18.999-19a.5.5 0 0 1 .707.707l-3.517 3.518a21.1 21.1 0 0 1 5.099 4.139 2.24 2.24 0 0 1 .003 2.973m-8.785-3.429-4.549 4.549a3.23 3.23 0 0 0 1.945.646h.002c.867 0 1.683-.338 2.296-.951a3.23 3.23 0 0 0 .953-2.298 3.23 3.23 0 0 0-.647-1.946m8.037 1.121c-1.472-1.641-3.27-3.074-5.082-4.076l-2.259 2.259c.036.027.073.053.102.091.554.739.848 1.62.849 2.548a4.22 4.22 0 0 1-1.246 3.008 4.22 4.22 0 0 1-3.003 1.244h-.002c-.929 0-1.81-.294-2.55-.85-.038-.028-.063-.065-.09-.101l-2.08 2.08c1.619.759 3.176 1.152 4.661 1.119 4.098.085 8.201-2.888 10.702-5.679a1.24 1.24 0 0 0-.002-1.643M12.049 8.75c.296 0 .588.039.867.116a.5.5 0 1 0 .265-.964 4.255 4.255 0 0 0-5.232 5.231.505.505 0 0 0 .617.349.5.5 0 0 0 .35-.615 3.253 3.253 0 0 1 3.133-4.117"/></svg>)}
    />
  );
}
  