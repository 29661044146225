// @flow

import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CenterHorizontally from '../../../../components/CenterHorizontally/CenterHorizontally';

import styles from './LoadingView.module.scss';

export type PropsT = {|
  numberOfServicesToCheck: number,
  numberOfServicesChecked: number
|};

// eslint-disable-next-line no-unused-vars
const LoadingView = (props: PropsT) => {
  const { numberOfServicesChecked, numberOfServicesToCheck } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('calendars.deleteCalendarLoadingView.title')}</h2>
      <div className={styles.description}>
        {t('calendars.deleteCalendarLoadingView.description')}
      </div>
      <div className={styles.spinner}>
        <div className={styles.info}>
          {t('calendars.deleteCalendarLoadingView.progress', {
            numberOfServicesChecked,
            numberOfServicesToCheck
          })}
        </div>
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      </div>
    </div>
  );
};

export default LoadingView;
