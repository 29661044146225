// @flow
import React, { type Element } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CallflowTreeNode from '../CallflowTreeNode';
import type { CallFlowTreeNodeT } from '../../../../../ducks/entities/callFlow';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import { getTransferTargetIcon } from '../TransferTargetNode';
import ExtensionGroupCalendarConverter from '../../details/extensionGroup/forwardingConvertUtils';
import ExtensionGroupForwardingTargetName from '../../../components/view/children/ExtensionGroupForwardingTargetName';
import fieldValidators from '../../../../../fieldValidators';
import { hasQueueEnabled } from '../../details/extensionGroup/extensionGroupForwardingUtils';

export type PropsT = {|
  node: CallFlowTreeNodeT,
  serviceId: string,
  enterpriseId: string
|};

export const ExtensionGroupNode = (props: PropsT): Element<typeof CallflowTreeNode> | null => {
  const { node, serviceId, enterpriseId } = props;
  const callflowId = `${enterpriseId}-EXTENSION_GROUP-${serviceId}`;
  const { t } = useTranslation();
  // redux
  const extensionGroup: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );

  const createNodeProps = () => {
    switch (node.type) {
      case 'EXTENSION_GROUP_CALENDAR':
        return {
          image: '/callflow_calendar.svg',
          selectedImage: '/callflow_selected_calendar.svg',
          title: t('callflows.treeLayout.extensionGroup.calendar')
        };
      case 'EXTENSION_GROUP_CALENDAR_CLOSED': {
        const calendarClosedTargetNumber = ExtensionGroupCalendarConverter.getCalendarClosedTarget(
          extensionGroup.forwardings
        );
        return {
          ...getTransferTargetIcon(
            R.path(['relatedNumbers', calendarClosedTargetNumber, 'alias'], extensionGroup),
            fieldValidators.externalContactPhoneNumberStrictValidator(
              calendarClosedTargetNumber
            ) === undefined
          ),
          title: (
            <ExtensionGroupForwardingTargetName
              extensionGroup={extensionGroup}
              forwardingType="ALWAYS"
            />
          ),
          notSelectable: true
        };
      }
      case 'EXTENSION_GROUP_OVERFLOW': {
        return {
          image: '/ext-group-overflow.svg',
          selectedImage: '/ext-group-overflow-selected.svg',
          title: t('callflows.treeLayout.extensionGroup.overflow')
        };
      }
      case 'EXTENSION_GROUP_VOICE_MAIL': {
        return {
          image: '/callflow_voicemail.svg',
          selectedImage: '/callflow_voicemail_selected.svg',
          title: t('callflows.treeLayout.acd.voiceMail')
        };
      }
      case 'EXTENSION_GROUP_QUEUE': {
        return {
          image: hasQueueEnabled(extensionGroup.forwardingsOnService)
            ? '/callflow_queue.svg'
            : '/callflow_queue_not_in_use.svg',
          selectedImage: '/callflow_selected_queue.svg',
          title: t('callflows.treeLayout.acd.queue')
        };
      }
      case 'EXTENSION_GROUP_MEMBERS':
      default:
        return {
          image: '/welcome_attendant_agents.svg',
          selectedImage: '/callflow_selected_agents.svg',
          title: t('callflows.treeLayout.extensionGroup.agents')
        };
    }
  };

  return (
    <CallflowTreeNode
      serviceId={serviceId}
      nodeId={node.id}
      type={node.type}
      {...createNodeProps()}
    />
  );
};

export default ExtensionGroupNode;
