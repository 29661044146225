// @flow

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import * as R from 'ramda';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import { ReactComponent as QueueIcon } from '../../../../../assets/callflow/details/queue-small.svg';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import IntegerField from '../../../components/edit/children/IntegerField';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import { updateExtensionGroup } from '../../../../../ducks/entities/extensionGroup/extensionGroupOperations';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { ExtensionGroupUpdateT } from '../../../../../ducks/entities/extensionGroup/extensionGroupOperations';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import IvrAudioField from '../../../components/edit/children/audio/IvrAudioField';
import ToggleField from '../../../components/edit/children/ToggleField';
import {
  createForwardingPayload,
  getActiveCallQueuingServices,
  hasQueueEnabled
} from './extensionGroupForwardingUtils';
import { operations as callForwardingOps } from '../../../../../ducks/entities/callForwarding';
import { createCallForwarding } from '../../../../../ducks/entities/callForwarding/callForwardingOperations';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  queueEnabled: boolean,
  queueSize: number,
  waitTime: number,
  musicOnWait: AudioFieldT<PropertyAudioT>
};

// $FlowFixMe: TODO: fix
export const extensionGroupQueueSchema = yup.object().shape({
  queueSize: yup
    .number()
    .integer()
    .min(1)
    .max(2)
    .default(2)
    .required(),
  waitTime: yup
    .number()
    .integer()
    .min(30)
    .max(9999)
    .default(600)
    .required()
});

export const EditExtensionGroupQueue = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const callflowAudioFieldConverter = new AudioFieldConverter(
    extGroupData.enterpriseId,
    extGroupData.id,
    'groups',
    'CallQueuingService'
  );
  const initialFormValues: FormT = {
    queueEnabled: hasQueueEnabled(extGroupData.forwardingsOnService),
    queueSize: R.path(['ivrProperties', 'callQueuingService', 'queueSize', 'value'], extGroupData),
    waitTime: R.path(['ivrProperties', 'callQueuingService', 'waitTime', 'value'], extGroupData),
    musicOnWait: callflowAudioFieldConverter.convertToAudioPropertyField(
      'MusicOnWait',
      '',
      R.path(['ivrProperties', 'callQueuingService', 'musicOnWait'], extGroupData)
    )
  };

  const deleteExistingForwardings = async () => {
    const fwdIdsToBeRemoved = getActiveCallQueuingServices(extGroupData.forwardingsOnService).map(
      fwd => fwd.id
    );
    const promises = fwdIdsToBeRemoved.map(forwardingId =>
      dispatch(
        callForwardingOps.deleteCallForwarding(
          extGroupData.enterpriseId,
          forwardingId,
          createCsrfHeader(currentUser)
        )
      )
    );
    await Promise.all(promises);
  };

  // update
  const onSubmit = async (formData: FormT): Promise<ExtensionGroupEntityT> => {
    if (!formData.queueEnabled) {
      await deleteExistingForwardings();
    }
    if (formData.queueEnabled) {
      const callQueuingServices = getActiveCallQueuingServices(extGroupData.forwardingsOnService);
      if (!callQueuingServices.length) {
        const newCallQueuingService = createForwardingPayload(
          {
            type: 'TRANSFER_CALL_QUEUING_SERVICE',
            value: null
          },
          'ON_ACTIVE_CALL'
        );
        if (newCallQueuingService) {
          await dispatch(
            createCallForwarding(
              extGroupData.enterpriseId,
              newCallQueuingService,
              null,
              extGroupData.id,
              createCsrfHeader(currentUser)
            )
          );
        }
      }
    }

    const fileName = (formData.musicOnWait.fileToImport || {}).name;
    const patchPayload: ExtensionGroupUpdateT = {
      ivrProperties: {
        CallQueuingService: {
          ServiceSpecificWaitTime: true,
          MusicOnWait: {
            type: 'File',
            value: fileName,
            level: formData.musicOnWait.level || 'Default',
            fileRestUri: fileName
          },
          QueueSize: formData.queueSize,
          WaitTime: formData.waitTime
        }
      }
    };
    // $FlowFixMe Improve formData typing
    const failedAudioUploads = await uploadAudios(
      formData,
      CancelToken.source(),
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }
    return dispatch(
      updateExtensionGroup(
        extGroupData.enterpriseId,
        extGroupData.id,
        patchPayload,
        CancelToken.source().token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={extGroupData.id}
      icon={<QueueIcon />}
      title={t('callflows.editExtensionGroupQueue.title')}
      description={t('callflows.editExtensionGroupQueue.description')}
      defaultValues={initialFormValues}
      validationSchema={extensionGroupQueueSchema}
      onSaveForm={onSubmit}
    >
      <ToggleField
        field="queueEnabled"
        elementId="queueEnabled"
        label={t('callflows.editExtensionGroupQueue.useQueuing')}
      />

      <IntegerField
        field="queueSize"
        maxLength={1}
        title={t('callflows.editExtensionGroupQueue.queueSize')}
        postFixDescription={t('callflows.editExtensionGroupQueue.queueSizePostFix')}
        tooltip={t(
          'callflows.viewExtensionGroupQueue.queueSizeTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupQueueSchema.fields.queueSize)
        )}
        disabledFn={(formData: FormT) => !formData.queueEnabled}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupQueueSchema.fields.queueSize)
        )}
      />

      <IntegerField
        field="waitTime"
        title={t('callflows.editExtensionGroupQueue.waitTime')}
        tooltip={t(
          'callflows.viewExtensionGroupQueue.waitTimeTooltip',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupQueueSchema.fields.waitTime)
        )}
        maxLength={4}
        disabledFn={(formData: FormT) => !formData.queueEnabled}
        errorMessage={t(
          'integerField.error',
          // $FlowFixMe: TODO: fix
          getIntegerFieldLimits(extensionGroupQueueSchema.fields.waitTime)
        )}
      />
      <IvrAudioField
        field="musicOnWait"
        title={t('callflows.editExtensionGroupQueue.musicOnWait')}
        hasDefault
        disabledFn={(formData: FormT) => !formData.queueEnabled}
      />
    </EditCallflowDetails>
  );
};

export default EditExtensionGroupQueue;
