import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDiamondFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m8.903 6.756 2.888-4.524c.091-.143.325-.143.416 0l2.888 4.524a.26.26 0 0 1 .01.256.25.25 0 0 1-.218.131H9.111a.25.25 0 0 1-.218-.131.26.26 0 0 1 .01-.256m-1.107-.845a.25.25 0 0 0 .244-.112L11.335.637a.26.26 0 0 0 .01-.256.25.25 0 0 0-.218-.131H6.391a.25.25 0 0 0-.238.319l1.441 5.162a.25.25 0 0 0 .202.18m7.733 2.585a.24.24 0 0 0-.197-.099H8.665a.25.25 0 0 0-.197.099.25.25 0 0 0-.041.219l3.334 11.938a.247.247 0 0 0 .476 0L15.57 8.715a.25.25 0 0 0-.041-.219m1.896-1.452c.046.063.12.099.197.099h5.616a.25.25 0 0 0 .222-.138.26.26 0 0 0-.022-.262l-4.064-5.557a.247.247 0 0 0-.437.082l-1.552 5.557a.25.25 0 0 0 .04.219M6.863 8.396H.747a.25.25 0 0 0-.221.136.25.25 0 0 0 .019.26L10.67 23.145a.245.245 0 0 0 .308.081.25.25 0 0 0 .131-.294L7.101 8.579a.25.25 0 0 0-.238-.183m16.61.136a.25.25 0 0 0-.221-.136h-6.119a.25.25 0 0 0-.238.183l-4.006 14.35a.25.25 0 0 0 .131.294.245.245 0 0 0 .308-.081l10.124-14.35a.25.25 0 0 0 .021-.26m-7.516-2.733a.25.25 0 0 0 .245.112.25.25 0 0 0 .201-.18L17.844.569a.25.25 0 0 0-.238-.318H12.87a.25.25 0 0 0-.208.387zM.757 7.143h5.617a.25.25 0 0 0 .239-.319L5.061 1.269a.247.247 0 0 0-.437-.081L.558 6.743a.26.26 0 0 0-.022.262.25.25 0 0 0 .221.138"/></svg>)}
    />
  );
}
  