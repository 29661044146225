import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMoreVerticalRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 6.4c1.8.1 3.2-1.4 3.2-3.2C15.2 1.5 13.8 0 12 0S8.8 1.5 8.8 3.2s1.5 3.2 3.2 3.2m0-5c1 0 1.8.8 1.8 1.8S13 5 12 5s-1.7-.8-1.8-1.8c0-1 .8-1.8 1.8-1.8m0 7.4c-1.7 0-3.2 1.4-3.2 3.2s1.5 3.2 3.2 3.2c1.8 0 3.2-1.4 3.2-3.2S13.7 8.8 12 8.8m0 5c-1-.1-1.7-.8-1.8-1.8 0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8m0 3.8c-1.7 0-3.2 1.4-3.2 3.2S10.2 24 12 24s3.2-1.5 3.2-3.2c0-1.8-1.5-3.2-3.2-3.2m0 4.9c-1 0-1.7-.8-1.8-1.7 0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.7-1.8 1.7"/></svg>)}
    />
  );
}
  