import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowDownAltLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.002 23.25a1 1 0 0 1-.719-.305L4.64 16.097a.499.499 0 1 1 .719-.694l6.141 6.331V1.25a.5.5 0 0 1 1 0v20.486l6.141-6.33a.5.5 0 0 1 .718.695l-6.639 6.844a1 1 0 0 1-.718.305"/></svg>)}
    />
  );
}
  