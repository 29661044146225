// @flow

import React, { type Element } from 'react';
import Popover from '@design-system/component-library/src/components/Popover';
import IconMoreFilled from '@design-system/component-library/src/components/Icon/lib/IconMoreFilled';
import type { CallForwardingT } from '../../../ducks/entities/callForwarding/callForwardingTypes';
import ForwardingPopOverSelector from '../components/ForwardingPopOverSelector';

export type PropsT = {
  enterpriseId: string,
  forwarding: CallForwardingT,
  onForwardingActivityChange: () => Promise<void>,
  onDeleteForwarding: () => void
};

export const ModifyLink = (props: PropsT): Element<'span'> => {
  const { forwarding, enterpriseId, onForwardingActivityChange, onDeleteForwarding } = props;
  return (
    <Popover
      triggerElement={<IconMoreFilled size="m" />}
      placement="left"
      i18n_popover_triggerAriaLabel="Action"
    >
      <ForwardingPopOverSelector
        enterpriseId={enterpriseId}
        activeForwarding={{ forwarding, user: null }}
        onForwardingActivityChange={onForwardingActivityChange}
        onDeleteForwarding={onDeleteForwarding}
      />
    </Popover>
  );
};
export default ModifyLink;
