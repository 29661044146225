import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFacebookLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 0h-21C.7 0 0 .7 0 1.5v21c0 .8.7 1.5 1.5 1.5h10.6c.3 0 .5-.2.5-.5v-8.7c0-.3-.2-.5-.5-.5H9.6v-3.1h2.6c.3 0 .5-.2.5-.5V9.6c0-2.9 2.4-5.3 5.3-5.3h1.9v3.1H18c-.6 0-1.2.2-1.6.6s-.7 1-.6 1.6v1.1c0 .3.2.5.5.5h3l-.4 3.1h-2.6c-.3 0-.5.2-.5.5v8.7c0 .3.2.5.5.5h6.2c.8 0 1.5-.7 1.5-1.5v-21c0-.8-.7-1.5-1.5-1.5m.5 22.5c0 .3-.2.5-.5.5h-5.7v-7.7h2.1c.5 0 .9-.4 1-.9l.4-3.1c0-.3 0-.6-.2-.8s-.5-.3-.8-.3h-2.5v-.6c0-.7.6-1.2 1.2-1.2h1.9c.6 0 1-.4 1-1V4.3c0-.6-.4-1-1-1H18c-3.5 0-6.3 2.8-6.3 6.3v.6H9.6c-.6 0-1 .4-1 1v3.1c0 .6.4 1 1 1h2.1V23H1.5c-.3 0-.5-.2-.5-.5v-21c0-.3.2-.5.5-.5h21c.3 0 .5.2.5.5z"/></svg>)}
    />
  );
}
  