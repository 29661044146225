// @flow strict-local

import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VoiceMailIcon } from '../../../../../assets/callflow/details/voicemail_open.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import VoiceMailAudioField from '../../../components/edit/children/audio/VoiceMailAudioField';
import type { VoiceMailAudioFieldT } from '../../../components/edit/children/audio/VoiceMailAudioField';
import { uploadVoiceMailAudio } from '../../../../../ducks/entities/callFlow/callFlowOperations';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import { removeOverflowGroupIfNeeded } from './EditAcdAgent';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  isVoiceMailMessage: boolean,
  voiceMailAudio: VoiceMailAudioFieldT
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

export const EditVoiceMail = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const { voicemailSettings } = acdData;
  const currentlyActiveFile =
    R.path(['audios', 'current', 'name'], voicemailSettings) || 'PhoneNumber';

  const initialFormValues: FormT = {
    isVoiceMailMessage: !voicemailSettings.isVoiceMailNoMessage,
    voiceMailAudio: {
      level: currentlyActiveFile,
      audioNameFileToImport: undefined,
      greetingAudioToImport: undefined
    }
  };

  const acdVoiceMailSchema = yup.object().shape({});

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []);

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const patchPayload = {
      ...removeOverflowGroupIfNeeded(acdData.ringPattern, acdData.overflowGroup),
      voicemailSettings: {
        isVoiceMailNoMessage: !formData.isVoiceMailMessage,
        audios: {
          current: {
            name: formData.voiceMailAudio.level
          }
        }
      }
    };
    const uploadAudio = async (audioLevel, file) => {
      return uploadVoiceMailAudio(
        acdData.enterpriseId,
        acdData.id,
        audioLevel,
        'ACD',
        file,
        audioRequestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      );
    };

    if (formData.voiceMailAudio.audioNameFileToImport) {
      const failedAudioUpload = await uploadAudio(
        'AudioName',
        formData.voiceMailAudio.audioNameFileToImport
      );
      if (failedAudioUpload) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          t('callflows.audioUploadFailed', {
            audioFileNames: [failedAudioUpload].join(',')
          })
        );
      }
    }
    if (formData.voiceMailAudio.greetingAudioToImport) {
      const failedAudioUpload = await uploadAudio(
        'Greeting',
        formData.voiceMailAudio.greetingAudioToImport
      );
      if (failedAudioUpload) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          t('callflows.audioUploadFailed', {
            audioFileNames: [failedAudioUpload].join(',')
          })
        );
      }
    }

    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<VoiceMailIcon />}
      title={t('callflows.editVoiceMail.title')}
      description={t('callflows.editVoiceMail.description')}
      defaultValues={initialFormValues}
      validationSchema={acdVoiceMailSchema}
      onSaveForm={onSubmit}
    >
      <ToggleField
        field="isVoiceMailMessage"
        elementId="isVoiceMailMessage"
        label={t('voicemailSettings.isVoiceMailMessage')}
      />
      <VoiceMailAudioField
        field="voiceMailAudio"
        enterpriseId={acdData.enterpriseId}
        entityId={acdData.id}
        voicemailSettings={voicemailSettings}
        title={t('voicemailSettings.title')}
        description={t('voicemailSettings.description')}
        serviceType="ACD"
      />
    </EditCallflowDetails>
  );
};

export default EditVoiceMail;
