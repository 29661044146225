import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDeleteLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 9.5v8a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 1 0m4.5-.5a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 1 0v-8a.5.5 0 0 0-.5-.5M23 4.5a.5.5 0 0 1-.5.5h-1.79l-1.35 16.17a2 2 0 0 1-2 1.83H6.63a2 2 0 0 1-2-1.83L3.29 5H1.5a.5.5 0 0 1 0-1h6.25V3a2 2 0 0 1 2-2h4.5a2 2 0 0 1 2 2v1h6.25a.5.5 0 0 1 .5.5M8.75 4h6.5V3a1 1 0 0 0-1-1h-4.5a1 1 0 0 0-1 1zm11 1H4.29l1.34 16.08a1 1 0 0 0 1 .92h10.74a1 1 0 0 0 1-.92z"/></svg>)}
    />
  );
}
  