import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDownloadFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.8 18.2v1.5c0 2.3-1.9 4.2-4.2 4.2h-15C2.3 23.9.4 22 .4 19.7v-1.5c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2v1.5c0 1 .8 1.8 1.8 1.8h15c1 0 1.7-.8 1.8-1.8v-1.5c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2m-12.7 1.2c.1.1.2.2.4.3.3.1.7.1 1 0 .2-.1.3-.2.4-.3l7.3-7.3c.5-.5.5-1.3 0-1.8s-1.3-.5-1.8 0l-5.2 5.2V1.2C13.3.6 12.7 0 12 0s-1.2.6-1.2 1.2v14.2l-5.2-5.2c-.5-.5-1.3-.5-1.8 0s-.5 1.3 0 1.8z"/></svg>)}
    />
  );
}
  