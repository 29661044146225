import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUpdateLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 14.5v4a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1 0-1H4C.686 13.582 1.58 7.314 5.998 4a10 10 0 0 1 9.852-1.23.49.49 0 0 1 .27.65.51.51 0 0 1-.65.27A9 9 0 0 0 5 17.64V14.5a.5.5 0 0 1 1 0M22.5 5h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 1 0V6.35a9 9 0 0 1-10.47 14 .5.5 0 1 0-.38.92c5.087 2.151 10.954-.228 13.105-5.315A10 10 0 0 0 20 6h2.5a.5.5 0 0 0 0-1"/></svg>)}
    />
  );
}
  