import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLocationUnknownFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.48 20.33a.89.89 0 0 0 1.24-.19C14.31 18 19.5 10.75 19.5 7.35A7.43 7.43 0 0 0 12 0a7.43 7.43 0 0 0-7.5 7.35c0 3.4 5.19 10.65 6.79 12.8q.08.106.19.18m-.73-13.95a.63.63 0 0 1-1.25 0 2.5 2.5 0 1 1 3.12 2.42v.45a.62.62 0 1 1-1.24 0V8.249a.63.63 0 0 1 .62-.63 1.25 1.25 0 1 0-1.25-1.249zM12 11.12a.88.88 0 1 1-.88.88.89.89 0 0 1 .88-.88M12 24C6.582 24 .75 22.826.75 20.25c0-1.803 2.775-2.734 5.104-3.198a.75.75 0 0 1 .293 1.471c-2.916.581-3.896 1.43-3.896 1.728 0 .673 3.317 2.25 9.75 2.25s9.75-1.577 9.75-2.25c0-.299-.986-1.149-3.917-1.73a.75.75 0 1 1 .291-1.471c2.339.463 5.126 1.394 5.126 3.201C23.25 22.826 17.418 24 12 24"/></svg>)}
    />
  );
}
  