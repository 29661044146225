// @flow

import React, { type Element, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import EditCallflowDetails from '../components/edit/EditCallflowDetails';
import InputField from '../components/edit/children/InputField';
import CalendarField from '../components/edit/children/calendar/CalendarField';
import type {
  AbsoluteTimeSlotEntityT,
  BaseCalendarEntityT,
  WeeklySlotT
} from '../../../ducks/entities/calendar/calendarTypes';
import CalendarConverterUtils from '../components/edit/children/calendar/CalendarConverterUtils';
import AbsoluteTimesField from './AbsoluteTimesField';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import ConfirmButton from '../../../components/Button/ConfirmButton';
import DeleteCalendarModal from './delete/DeleteCalendarModal';
import { enterpriseCalendarRegExp } from '../../../helpers';

import styles from './EditCalendarForm.module.scss';

export type FormT = {
  label?: string,
  weekSlots?: WeeklySlotT[],
  absoluteTimeSlots: AbsoluteTimeSlotEntityT[]
};

export type PropsT = {|
  enterpriseId: string,
  onCancel: () => void,
  calendar: ?BaseCalendarEntityT,
  showLabel?: boolean,
  onSaveForm: FormT => Promise<void>
|};

const EditCalendarForm = (props: PropsT): Element<'div'> => {
  const { onCancel, calendar, showLabel, onSaveForm, enterpriseId } = props;
  const { t } = useTranslation();
  // redux
  const allCalendars = useSelector(state => state.entities.calendar);
  const calendarNames = R.values(allCalendars.byId).map(c => c.name);

  // form
  const initialValues: FormT = {
    label: calendar ? calendar.name : '',
    weekSlots: CalendarConverterUtils.convertToWeekScheduleFormField(calendar),
    absoluteTimeSlots: R.path(['absoluteTimeSlots'], calendar) || []
  };
  const [isAbsoluteTimeDialogVisible, setIsAbsoluteTimeDialogVisible] = useState(false);
  const [selectedAbsoluteTime, setSelectedAbsoluteTime] = useState();
  const [showDeleteCalendarModal, setShowDeleteCalendarModal] = useState(false);
  const [
    showDeleteAbsoluteTimeDeleteDialog,
    setShowDeleteAbsoluteTimeDeleteDialog
  ] = useState<boolean>(false);

  const calendarSchema = showLabel
    ? yup.object().shape({
        label: yup
          .string()
          .required(t('calendars.editCalendarForm.nameEmptyValidationError'))
          .matches(/^[a-zA-Z0-9\-_.!%@+']+$/, t('calendars.editCalendarForm.nameValidationError'))
          .test('is-unique', t('calendars.editCalendarForm.nameValidationUniqueError'), value =>
            value
              ? !calendarNames
                  .map(name => name.replace(enterpriseCalendarRegExp, '').toLowerCase())
                  .includes(value.toLowerCase())
              : true
          )
      })
    : null;

  const calendarId = calendar ? calendar.id : '0';
  return (
    <div className={styles.container}>
      <EditCallflowDetails
        nodeId="calendars-page"
        icon={null}
        title=""
        defaultValues={initialValues}
        validationSchema={calendarSchema}
        disableCallflowNotifications
        onSaveForm={formData => onSaveForm(formData)}
        onCancel={onCancel}
        onDelete={event => {
          if (event) {
            event.preventDefault();
          }
          setShowDeleteCalendarModal(true);
        }}
        {...(calendar
          ? { deleteButtonLabel: t('calendars.editCalendarForm.calendarDeleteButton') }
          : {})}
      >
        {isAbsoluteTimeDialogVisible ? (
          <DateTimePicker
            field="absoluteTimeSlots"
            onClose={async absoluteTimeSlots => {
              if (calendar && absoluteTimeSlots) {
                await onSaveForm({ absoluteTimeSlots });
              }
              setIsAbsoluteTimeDialogVisible(false);
            }}
            selectedTimeSlot={selectedAbsoluteTime}
            minDate={new Date(0)}
          />
        ) : null}
        {showLabel ? (
          <InputField
            field="label"
            title={t('calendars.editCalendarForm.name')}
            description={t('calendars.editCalendarForm.nameDescription')}
            shouldValidate={showLabel}
            maxLength={107}
          />
        ) : null}
        <AbsoluteTimesField
          field="absoluteTimeSlots"
          handleUpdate={slot => {
            setSelectedAbsoluteTime(slot);
            setIsAbsoluteTimeDialogVisible(true);
          }}
          showDeleteAbsoluteTimeDeleteDialog={showDeleteAbsoluteTimeDeleteDialog}
          setShowDeleteAbsoluteTimeDeleteDialog={setShowDeleteAbsoluteTimeDeleteDialog}
          updateAbsoluteTimeSlots={async absoluteTimeSlots => {
            if (calendar && absoluteTimeSlots) {
              await onSaveForm({ absoluteTimeSlots });
              setShowDeleteAbsoluteTimeDeleteDialog(false);
            }
          }}
        />
        <ConfirmButton
          id={`add-time-button-${calendarId}`}
          className={styles['add-button']}
          label={t('calendars.editCalendarForm.addTimeButton')}
          onClickAction={event => {
            event.preventDefault();
            setSelectedAbsoluteTime(undefined);
            setIsAbsoluteTimeDialogVisible(true);
          }}
        />

        <CalendarField field="weekSlots" title={t('calendars.editCalendarForm.calendarTitle')} />
      </EditCallflowDetails>
      {showDeleteCalendarModal ? (
        <DeleteCalendarModal
          calendarName={calendar ? calendar.name : ''}
          enterpriseId={enterpriseId}
          calendarId={calendarId}
          onClose={() => setShowDeleteCalendarModal(false)}
        />
      ) : null}
    </div>
  );
};

export default EditCalendarForm;
