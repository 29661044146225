import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLocationLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 11a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7m0-6a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m0 15.5a.9.9 0 0 1-.52-.17.8.8 0 0 1-.19-.18C9.69 18 4.5 10.75 4.5 7.35A7.43 7.43 0 0 1 12 0a7.43 7.43 0 0 1 7.5 7.34c0 3.4-5.19 10.65-6.78 12.79a.89.89 0 0 1-.72.37M12 1a6.43 6.43 0 0 0-6.5 6.35c0 2.43 3.34 7.81 6.5 12.08 3.16-4.27 6.5-9.65 6.5-12.08A6.43 6.43 0 0 0 12 1m11 19.5c0-1.72-3.08-2.59-4.92-3a.51.51 0 0 0-.59.4.48.48 0 0 0 .362.574l.028.006c2.83.56 4.12 1.42 4.12 2 0 1-3.9 2.5-10 2.5S2 21.52 2 20.5c0-.56 1.28-1.41 4.1-2a.51.51 0 0 0 .39-.59.49.49 0 0 0-.57-.394l-.02.004C4.07 17.91 1 18.78 1 20.5 1 22.8 6.53 24 12 24s11-1.2 11-3.5"/></svg>)}
    />
  );
}
  