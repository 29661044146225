// @flow

import { CancelToken } from 'axios';
import { uploadAudio } from '../../../../../../ducks/entities/callFlow/callFlowOperations';
import type {
  AudioLevelT,
  CallFlowUrlNameT
} from '../../../../../../ducks/entities/callFlow/callFlowTypes';
import type { DynamicPropertyFilesOnLevelT } from '../../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import type { AudioCreateT } from '../../../../../../ducks/entities/callFlow/callFlowOperations';
import type {
  VoiceMailAudioTypesT,
  VoiceMailLevelT
} from '../../../../../../ducks/entities/voicemailTypes';
import { stripExtension } from '../../../../../../helpers';

export type ServiceSettingNameT =
  | 'ACDCallCenter'
  | 'PlayMusic'
  | 'CallQueuingService'
  | 'AnnoucementService';

type BaseAudioT = {|
  enterpriseId: string,
  callflowType?: CallFlowUrlNameT,
  callflowId: string
|};

export type PropertyAudioT = {|
  ...BaseAudioT,
  audioType: 'property',
  serviceSettingName: ServiceSettingNameT,
  fieldName: string,
  filename: string,
  level: AudioLevelT
|};

export type IvrAudioT = {|
  ...BaseAudioT,
  audioType: 'name',
  filename: string
|};

export type VoiceMailAudioFieldT = {|
  ...BaseAudioT,
  audioType: 'voicemail',
  propertyName: VoiceMailLevelT,
  serviceType: VoiceMailAudioTypesT
|};

export type AudioT = PropertyAudioT | IvrAudioT | VoiceMailAudioFieldT;

export type AudioFieldT<T> = {
  fieldName: string,
  audioPlayData: T,
  fileToImport: ?File,
  hasAudioFilesOnLevels: DynamicPropertyFilesOnLevelT[],
  level: AudioLevelT
};

export const getAudioPlayData = (audioField: AudioFieldT<AudioT>, level?: AudioLevelT) =>
  audioField.audioPlayData.audioType === 'property'
    ? {
        ...audioField.audioPlayData,
        level
      }
    : audioField.audioPlayData;

const createAudioUploadProperty = (audioField: AudioFieldT<PropertyAudioT>): AudioCreateT => ({
  service: audioField.audioPlayData.serviceSettingName,
  field: audioField.fieldName,
  level: 'EXTENSION',
  filename: stripExtension((audioField.fileToImport || {}).name)
});

export const uploadAudioProperty = async (
  audioField: AudioFieldT<PropertyAudioT>,
  audioFile: File,
  cancelToken: CancelToken,
  headers: {}
): Promise<?string> => {
  return uploadAudio(
    audioField.audioPlayData.enterpriseId,
    audioField.audioPlayData.callflowType || 'acds',
    audioField.audioPlayData.callflowId,
    createAudioUploadProperty(audioField),
    audioFile,
    cancelToken.token,
    headers
  );
};

export const sanitizeAudioFilename = (audioFilename: ?string): string =>
  (audioFilename || '').replace(/[^a-zA-Z0-9_.]|[.](?=.*[.])/g, '_');

export const uploadAudios = async (formData: *, cancelToken: CancelToken, headers: {}): * => {
  // $FlowFixMe Improve value typing
  const audios = [...Object.values(formData).filter(value => value && value.fileToImport)];
  const promises = audios.map(a =>
    // $FlowFixMe Improve value typing
    uploadAudioProperty(a, a.fileToImport, cancelToken, headers)
  );
  const results: (?string)[] = await Promise.all(promises);
  return results.filter(result => !!result);
};

export const isDefaultAudioSet = (audioField: AudioFieldT<PropertyAudioT>) => {
  if (audioField.fieldName === 'MusicWait') {
    return audioField.level === 'Default';
  }
  return audioField.level === 'Default' && audioField.hasAudioFilesOnLevels.includes('Default');
};
