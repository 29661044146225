import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTravelLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.2 23.991c-2.341 0-2.431-2.098-2.551-5.265-.06-1.329-.12-2.927-.34-4.776l-5.212 5.195v2.328a2.52 2.52 0 0 1-2.521 2.518c-1.891 0-2.311-1.479-2.711-2.897-.07-.24-.14-.49-.22-.739l-.78-.24c-1.471-.41-2.861-.799-2.861-2.688a2.52 2.52 0 0 1 2.521-2.518h2.331l5.202-5.205c-1.841-.22-3.451-.28-4.772-.33-3.181-.13-5.282-.22-5.282-2.558a2.47 2.47 0 0 1 .67-1.808c1.271-1.299 4.332-1.289 10.995-1.239h4.322L18.872.882a3.003 3.003 0 0 1 4.244-.007l.007.007a2.995 2.995 0 0 1 0 4.236l-2.891 2.887c0 2.358 0 4.376.07 6.095.08 5.495.12 7.993-1 9.162a2.77 2.77 0 0 1-2.102.729m-2.521-11.61q.076-.015.15 0a.51.51 0 0 1 .34.41c.26 1.949.42 3.91.48 5.875.14 3.487.3 4.306 1.551 4.306.494.055.988-.093 1.371-.41.85-.859.8-3.557.73-8.443 0-1.778-.07-3.867-.07-6.314a.53.53 0 0 1 .15-.36l3.001-2.997a1.997 1.997 0 0 0 0-2.818 2.003 2.003 0 0 0-2.811 0L16.55 4.638a.53.53 0 0 1-.36.15h-4.532c-6.002 0-9.304-.06-10.274.909-.275.305-.412.71-.38 1.119 0 1.249.82 1.419 4.322 1.559q2.95.077 5.872.47c.19.027.348.158.41.34a.51.51 0 0 1-.12.51L5.415 15.76a.53.53 0 0 1-.36.15H2.524c-.84 0-1.521.68-1.521 1.519 0 1.089.61 1.299 2.131 1.728l1.07.32a.51.51 0 0 1 .32.32c.11.35.21.699.3.999.43 1.499.66 2.168 1.751 2.168.84 0 1.521-.68 1.521-1.519v-2.448a.53.53 0 0 1 .15-.36l6.073-6.065a.52.52 0 0 1 .36-.191"/></svg>)}
    />
  );
}
  