import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../../../utils/css';

import { CookieSettingsButton } from './CookieSettingsButton';
import { DisclaimerElement } from './DisclaimerElement';
import { CopyRightElement } from './CopyRightElement';

import styles from './Disclaimer.module.scss';

function Disclaimer(
  {
    className = undefined,
    handleClick = () => {},
    rel = 'nofollow',
    links= null,
  }
) {
  if (!links) return null;
  return (
    <div className={classNames([styles.footer__disclaimer, className])}>
      <ul className={styles.footer__disclaimer_list}>
        <DisclaimerElement
          text={links.TOUL?.TOULText}
          url={links.TOUL?.TOULUrl}
          handleClick={handleClick}
          rel={rel}
        />
        <DisclaimerElement
          text={links.contract?.contractText}
          url={links.contract?.contractUrl}
          handleClick={handleClick}
          rel={rel}
        />
        <DisclaimerElement
          text={links.privacy?.privacyText}
          url={links.privacy?.privacyUrl}
          handleClick={handleClick}
          rel={rel}
        />
        <DisclaimerElement
          text={links.accessibility?.accessibilityText}
          url={links.accessibility?.accessibilityUrl}
          handleClick={handleClick}
          rel={rel}
        />
        <CookieSettingsButton text={links.cookiesettings?.text} handleClick={handleClick} />
        <CopyRightElement text={links.copyright?.copyrightText} />
      </ul>
    </div>
  );
}

Disclaimer.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  rel: PropTypes.string,
  links: PropTypes.shape({
    TOUL: PropTypes.shape({
      TOULText: PropTypes.string,
      TOULUrl: PropTypes.string,
    }),
    contract: PropTypes.shape({
      contractText: PropTypes.string,
      contractUrl: PropTypes.string,
    }),
    privacy: PropTypes.shape({
      privacyText: PropTypes.string,
      privacyUrl: PropTypes.string,
    }),
    cookiesettings: PropTypes.shape({
      text: PropTypes.string,
    }),
    copyright: PropTypes.shape({
      copyrightText: PropTypes.string,
    }),
    accessibility: PropTypes.shape({
      accessibilityText: PropTypes.string,
      accessibilityUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default Disclaimer;
