import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCreditCardFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.6 12c0 1.6-1.3 2.9-2.9 2.9-.7 0-1.5-.3-2-.8.8-1.3.8-2.9 0-4.2 1.1-1.1 2.9-1.1 4 0q.9.9.9 2.1M9.3 9.1c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9 2.9-1.3 2.9-2.9-1.4-2.9-2.9-2.9M23 5.9v12.3c0 1-.9 1.9-1.9 1.9H2.9c-1 0-1.9-.8-1.9-1.9V5.9C1 4.8 1.9 4 2.9 4h18.2c1 0 1.9.8 1.9 1.9M18.9 12c0-2.3-1.9-4.1-4.1-4.1-1 0-2 .4-2.7 1.1-1.7-1.5-4.3-1.4-5.8.3s-1.4 4.3.3 5.8c1.6 1.4 4 1.4 5.5 0 .7.7 1.7 1.1 2.7 1.1 2.2 0 4.1-1.9 4.1-4.2"/></svg>)}
    />
  );
}
  