// @flow strict

import React, { type Element, Component } from 'react';
import type { ExactPropsT } from '../../commonTypes';
import FavouriteIcon from './FavouriteIcon';

type PropsT = {
  onToggle: (selected: boolean) => ?void,
  isSelected: boolean,
  disabled: boolean
};

export type TestPropsT = ExactPropsT<PropsT>;

type StateT = {
  selected: boolean
};

export class Favourite extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  state = {
    selected: this.props.isSelected
  };

  componentDidUpdate(prevProps: PropsT) {
    if (prevProps.isSelected !== this.props.isSelected) {
      this.setState({ selected: this.props.isSelected });
    }
  }

  handleOnClick: () => void;

  handleOnClick(e: SyntheticEvent<HTMLButtonElement>) {
    const { onToggle } = this.props;
    if (e) e.preventDefault();
    this.setState(prevState => {
      const selected = !prevState.selected;
      onToggle(selected);
      return { selected };
    });
  }

  render(): Element<typeof FavouriteIcon> {
    const { selected } = this.state;

    return (
      <FavouriteIcon
        selected={selected}
        disabled={this.props.disabled}
        handleOnClick={this.handleOnClick}
      />
    );
  }
}

export default Favourite;
