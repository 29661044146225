import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFolderOpenFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m24 11.4-2 9c-.2.9-1 1.6-2 1.6H4c-1 0-1.8-.7-2-1.6l-2-12V8c0-.5.2-1 .6-1.4S1.5 6 2 6h6.5c1 0 1.8.7 2 1.6l.2 1.4H22c1.1 0 2 .9 2 2zM2.4 4.7c.4.1.8-.1.9-.5.1-.6.6-.9 1.2-.9h15c.7 0 1.2.6 1.2 1.2V7c0 .4.3.8.8.8s.8-.3.8-.8V4.5c0-1.5-1.2-2.8-2.8-2.8h-15c-1.3 0-2.3.8-2.7 2.1-.1.4.2.8.6.9"/></svg>)}
    />
  );
}
  