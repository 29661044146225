"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function User(_a) {
    var email = _a.email, fullName = _a.fullName, name = _a.name;
    return {
        email: email,
        fullName: fullName,
        name: name,
    };
}
exports.default = User;
