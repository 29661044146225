// @flow
import React from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createEndTourAction } from '../ducks/ui/tour/tourActions';
import type { TourStateT } from '../ducks/ui/tour';

export const Tour = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tour: TourStateT = useSelector(state => state.ui.tour);
  const handleJoyrideCallback = data => {
    const { status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(createEndTourAction());
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={tour.active}
      showSkipButton
      hideCloseButton
      disableOverlay
      disableScrolling={false}
      continuous
      showProgress
      styles={{
        options: {
          primaryColor: '#005fed',
          textColor: '#404041'
        }
      }}
      locale={{
        back: t('tours.common.back'),
        next: t('tours.common.next'),
        skip: t('tours.common.skip'),
        last: t('tours.common.last')
      }}
      steps={tour.steps}
    />
  );
};

export default Tour;
