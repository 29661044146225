// @flow
import React, { type Element } from 'react';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import classnames from 'classnames';
import Toggle from '@design-system/component-library/src/components/Toggle';
import * as R from 'ramda';
import type { FieldProps } from 'redux-form';
import { useParams } from 'react-router-dom';
import AudioFileSelection from '../../../callFlows/components/edit/children/audio/AudioFileSelection';
import type { VoiceMailEntityT } from '../../../../ducks/entities/voicemailTypes';
import type { TranslateT } from '../../../../commonTypes';
import Item from '../../../../components/Item';
import VoiceMailAudio from '../../../callFlows/components/edit/children/audio/VoiceMailAudio';
import type { VoiceMailAudioFieldT } from '../../../callFlows/components/edit/children/audio/CallflowAudioUtils';

import styles from './UserVoiceMailAudioField.module.scss';

export type PropsT = FieldProps & {|
  title?: string,
  voicemailSettings: VoiceMailEntityT,
  translate: TranslateT<string>,
  wrapperStyle: *,
  isSectionInEditMode: boolean,
  id: string,
  isSectionDisabled?: boolean
|};

export const UserVoiceMailAudioField = (props: PropsT): Element<'div'> => {
  const {
    id,
    title,
    input,
    translate,
    isSectionInEditMode = false,
    wrapperStyle,
    voicemailSettings
  } = props;
  const { id: enterpriseId, userId } = useParams();
  const hasAudioNameFile = !!R.path(
    ['audios', 'audioName', 'files', 'durationInSeconds'],
    voicemailSettings
  );
  const hasGreetingFile = !!R.path(
    ['audios', 'greeting', 'files', 'durationInSeconds'],
    voicemailSettings
  );

  const inputAudioLevel =
    input.value && input.value.voiceMailAudio ? input.value.voiceMailAudio.level : 'PhoneNumber';

  const audioNameAudio = {
    enterpriseId: enterpriseId || '',
    callflowId: userId || '',
    audioType: 'voicemail',
    propertyName: 'AudioName',
    serviceType: 'User'
  };

  const greetingAudio = {
    enterpriseId: enterpriseId || '',
    callflowId: userId || '',
    audioType: 'voicemail',
    propertyName: 'Greeting',
    serviceType: 'User'
  };

  const voiceMailAudio: VoiceMailAudioFieldT = {
    enterpriseId: enterpriseId || '',
    callflowId: userId || '',
    audioType: 'voicemail',
    propertyName: inputAudioLevel,
    serviceType: 'User'
  };

  const getHelpText = () => {
    if (inputAudioLevel === 'PhoneNumber') {
      return translate('voicemailSettings.audioDefaultTooltip');
    }
    if (inputAudioLevel === 'AudioName') {
      return translate('voicemailSettings.audioAudioTooltip');
    }
    if (inputAudioLevel === 'Greeting') {
      return translate('voicemailSettings.audioGreetingTooltip');
    }
    return '';
  };

  return (
    <div className={wrapperStyle} id={id}>
      {isSectionInEditMode && (
        <div className={title && styles['title-padding']}>
          {title && <div className={classnames(styles['small-title'])}>{title}</div>}
          <div className={styles.description}>{translate('callflows.audioField.description')}</div>

          <Toggle
            className={styles.switch}
            value={!input.value.isVoiceMailNoMessage}
            data-cy="user-voice-mail-switch"
            onToggle={() => {
              input.onChange({
                ...input.value,
                isVoiceMailNoMessage: !input.value.isVoiceMailNoMessage
              });
            }}
            label={translate('voicemailSettings.isVoiceMailMessage')}
          />
          <div
            className={styles.container}
            role="group"
            aria-label="Message type"
            id="voicemail-type-selection"
          >
            <RadioGroup value={inputAudioLevel} i18n_radiogroup_helpText={getHelpText()}>
              <Radio
                name="voicemailMessage"
                label={translate('voicemailSettings.audioDefault')}
                value="PhoneNumber"
                onChange={(): void =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      level: 'PhoneNumber'
                    }
                  })
                }
              />
              <Radio
                name="voicemailMessage"
                value="AudioName"
                onChange={(): void =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      level: 'AudioName'
                    }
                  })
                }
                label={translate('voicemailSettings.audioAudioName')}
              />
              <Radio
                name="voicemailMessage"
                value="Greeting"
                onChange={(): void =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      level: 'Greeting'
                    }
                  })
                }
                label={translate('voicemailSettings.audioGreeting')}
              />
            </RadioGroup>
            {inputAudioLevel === 'AudioName' && (
              <AudioFileSelection
                audio={audioNameAudio}
                onChange={file =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      audioNameFileToImport: file
                    }
                  })
                }
                showInitialView={hasAudioNameFile}
                disabled={false}
              />
            )}
            {inputAudioLevel === 'Greeting' && (
              <AudioFileSelection
                className={styles.audio}
                audio={greetingAudio}
                onChange={file =>
                  input.onChange({
                    ...input.value,
                    voiceMailAudio: {
                      ...input.value.voiceMailAudio,
                      greetingAudioToImport: file
                    }
                  })
                }
                showInitialView={hasGreetingFile}
                disabled={false}
              />
            )}
          </div>
        </div>
      )}
      {!isSectionInEditMode && input.value && (
        <div>
          <Item
            id="isVoiceMailNoMessage"
            label={translate('voicemailSettings.isVoiceMailMessage')}
            value={
              input.value.isVoiceMailNoMessage
                ? translate('voicemailSettings.voiceMailNotInUse')
                : translate('voicemailSettings.voiceMailInUse')
            }
          />
          <VoiceMailAudio
            active
            title={translate('voicemailSettings.title')}
            audioData={voiceMailAudio}
            noAudioLabel={
              inputAudioLevel === 'PhoneNumber' ? translate('voicemailSettings.audioDefault') : ''
            }
          />
        </div>
      )}
    </div>
  );
};

export default UserVoiceMailAudioField;
