import React from 'react';
import IconContainer from '../IconContainer';

export default function IconChatRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M1.754 17.5c-.356 0-.704-.152-.947-.435a1.26 1.26 0 0 1-.14-1.442l1.414-2.503A7.42 7.42 0 0 1 .024 8.09C.024 3.625 4.286 0 9.525 0c4.662 0 8.701 2.953 9.396 6.869a.75.75 0 1 1-1.476.262c-.57-3.21-3.974-5.631-7.92-5.631-4.412 0-8.001 2.952-8.001 6.58a5.9 5.9 0 0 0 1.993 4.34.75.75 0 0 1 .157.932l-1.356 2.4 3.401-1.376a.751.751 0 0 1 .563 1.391l-4.06 1.643a1.3 1.3 0 0 1-.468.09m21.402 6.073c.338-.37.435-.909.272-1.296l-.729-2.567A5.9 5.9 0 0 0 24 15.999C24 12.205 20.337 9 16 9s-8 3.205-8 7.015a6.95 6.95 0 0 0 3.321 5.802 7.98 7.98 0 0 0 7.094.795l3.397 1.334a1.31 1.31 0 0 0 1.344-.373m-1.143-.808a.1.1 0 0 1-.012-.039zm-3.881-1.652a6.5 6.5 0 0 1-6.011-.564 5.46 5.46 0 0 1-2.621-4.55c0-2.98 2.977-5.499 6.5-5.499s6.5 2.519 6.5 5.501a4.4 4.4 0 0 1-1.183 3.016.75.75 0 0 0-.172.715l.754 2.64-3.208-1.263a.77.77 0 0 0-.559.004"/></svg>)}
    />
  );
}
  