import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmartphoneLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 0H7a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M7 1h10a1 1 0 0 1 1 1v15H6V2a1 1 0 0 1 1-1m10 22H7a1 1 0 0 1-1-1v-4h12v4a1 1 0 0 1-1 1m-4.31-2.79a.749.749 0 0 1-.98.98.749.749 0 1 1 .98-.98"/></svg>)}
    />
  );
}
  