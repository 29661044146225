// @flow

import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import * as R from 'ramda';
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddPlusIconHover } from '../../../assets/add-plus-hover.svg';
import Avatar from '../../../components/Avatar/Avatar';
import Destination from '../../../components/ForwardingsTable/Destination';
import Reason from '../../../components/ForwardingsTable/Reason';
import Table from '../../../components/ForwardingsTable/Table';
import type { MapItemToCellFnT } from '../../../components/ForwardingsTable/Table';
import type { TableHeaderColumnT } from '../../../components/ForwardingsTable/TableHeader';
import type { TableRowItemT } from '../../../components/ForwardingsTable/TableRow';
import { PhoneLargeAndTablet, Phone, LaptopAndUp } from '../../../Responsive';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import ForwardingTableCell from './ForwardingTableCell';
import { compareForwardingPriority } from '../forwardingPriorityUtils';
import NoResults from '../../../components/Notifications/NoResults';
import type { CallForwardingT } from '../../../ducks/entities/callForwarding/callForwardingTypes';
import type { DirectoryStateEntityT } from '../../../ducks/entities/directory';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';

import styles from './UserForwardingsTable.module.scss';

export type PropsT = {
  allowLoadMore: boolean,
  enterpriseId: string,
  onLoadMore: number => void | Promise<void>,
  onCreateUserSpecific: DirectoryStateEntityT => void,
  isUserCollectionLoading: boolean,
  showEnterpriseForwardings: boolean,
  showExternalServiceFwds: boolean,
  onForwardingActivityChange: () => Promise<void>,
  onDeleteForwarding: () => void,
  singleUser?: InternalUserStateEntityT
};

export type UserForwardingStatusT = 'empty' | 'complete' | 'error' | 'loading';

const UserForwardingsTable = (props: PropsT): Element<typeof Table | typeof NoResults> => {
  const {
    enterpriseId,
    allowLoadMore,
    onLoadMore,
    onCreateUserSpecific,
    isUserCollectionLoading,
    showEnterpriseForwardings,
    showExternalServiceFwds,
    onForwardingActivityChange,
    onDeleteForwarding,
    singleUser
  } = props;
  const location = useLocation();
  const singleUserAddressNumber = singleUser ? singleUser.addressNumber : '';
  const searchTerm =
    location.state && location.state.addressNumber
      ? location.state.addressNumber
      : singleUserAddressNumber;

  const userForwardings: { [string]: { values: CallForwardingT[], __metadata: {} } } =
    useSelector(state => R.path(['entities', 'callForwarding', 'byAddressNumber'], state)) || [];
  const userEntries: { [string]: DirectoryStateEntityT[] } =
    useSelector(state => R.path(['entities', 'directory', 'byId'], state)) || [];
  // $FlowFixMe could not resolve "incompatible with exact DirectoryEntityT"
  const usersToDisplay: DirectoryStateEntityT[] = R.values(userEntries).filter(
    s =>
      R.path(['publicInfo', 'addressNumber'], s) &&
      !(R.path(['publicInfo', 'addressNumber'], s) || '').startsWith('-') &&
      (!searchTerm || (searchTerm && R.path(['matchings', 'addressNumber'], s) === searchTerm))
  );

  const { t } = useTranslation();

  const getUserForwardings = (
    addressNumber: ?string
  ): { status: UserForwardingStatusT, data: CallForwardingT[] } => {
    if (!addressNumber) {
      return {
        status: 'loading',
        data: []
      };
    }
    const forwardings = userForwardings[`${enterpriseId}-${addressNumber}`];
    if (forwardings && R.path(['__metadata', 'complete'], forwardings)) {
      const enterpriseFilter = showEnterpriseForwardings ? () => true : f => f.isEditable;
      const serviceFwdFilter = showExternalServiceFwds ? () => true : f => !f.serviceName;

      const userForwardingsToShow: CallForwardingT[] = [
        ...(forwardings.values || []).filter(enterpriseFilter).filter(serviceFwdFilter)
      ];
      return {
        status: R.isEmpty(userForwardingsToShow) ? 'empty' : 'complete',
        data: R.sort(compareForwardingPriority, userForwardingsToShow || [])
      };
    }

    return {
      status: R.path(['__metadata', 'error'], forwardings) ? 'error' : 'loading',
      data: []
    };
  };

  const renderRulesColumn = (userForwardingWithStatus, user: DirectoryStateEntityT) => {
    switch (userForwardingWithStatus.status) {
      case 'error': {
        const errorView = (
          <div key="error" className={styles['vertical-center']}>
            {t('forwardings.userForwardingsTable.errorMsg')}
          </div>
        );
        return <div>{[errorView]}</div>;
      }
      case 'loading': {
        const loadingView = (
          <CenterHorizontally key="loading-spinner" style={{ paddingTop: '15px' }}>
            <LoadingSpinner />
          </CenterHorizontally>
        );
        return <div>{[loadingView]}</div>;
      }
      case 'complete': {
        const fwds = R.filter(f => !R.isEmpty(f), userForwardingWithStatus.data);
        const forwardingFields = R.map(userForwarding => {
          return (
            <ForwardingTableCell
              key={`user-forwarding-${userForwarding.id}-reason`}
              userForwarding={userForwarding}
            >
              <Reason
                userForwarding={userForwarding}
                user={user}
                showMenuIcon
                enterpriseId={enterpriseId}
                onForwardingActivityChange={onForwardingActivityChange}
                onDeleteForwarding={onDeleteForwarding}
              />
            </ForwardingTableCell>
          );
        }, fwds);
        return <div>{forwardingFields}</div>;
      }
      case 'empty':
      default: {
        const emptyView = (
          <div key="no-results" className={styles['vertical-center']}>
            {t('forwardings.userForwardingsTable.noResults')}
          </div>
        );
        return <div>{[emptyView]}</div>;
      }
    }
  };

  const renderForwardingDestinationColumn = (forwardings, user) => {
    const fwds = R.filter(f => !R.isEmpty(f), forwardings);
    const forwardingFields = R.map(userForwarding => {
      return (
        <ForwardingTableCell
          key={`user-forwarding-${userForwarding.id}-destinations`}
          userForwarding={userForwarding}
        >
          <Destination
            enterpriseId={enterpriseId}
            userForwarding={userForwarding}
            user={user}
            showMenuIcon
            onForwardingActivityChange={onForwardingActivityChange}
            onDeleteForwarding={onDeleteForwarding}
            singleUser={singleUser}
          />
        </ForwardingTableCell>
      );
    }, fwds);
    return <div>{forwardingFields}</div>;
  };

  const items: TableRowItemT[] = usersToDisplay.map(
    (user: DirectoryStateEntityT): TableRowItemT => {
      const userForwardingWithStatus = getUserForwardings(user.publicInfo.addressNumber);

      const fullName = [user.publicInfo.firstName, user.publicInfo.lastName].join(' ').trim();
      const defaultAction = user.publicInfo.addressNumber ? (
        <div className={styles['action-container']}>
          <div
            className={styles['action-button']}
            role="button"
            tabIndex={0}
            onKeyPress={() => onCreateUserSpecific(user)}
            onClick={() => onCreateUserSpecific(user)}
          >
            <LaptopAndUp>
              <AddPlusIconHover />
            </LaptopAndUp>
            <PhoneLargeAndTablet>
              <div className={styles['action-text']}>
                {t('forwardings.createCallForwardingButton')}
              </div>
              <AddPlusIconHover />
            </PhoneLargeAndTablet>
            <Phone>
              <AddPlusIconHover />
              <div className={styles['action-text']}>
                {t('forwardings.createCallForwardingButton')}
              </div>
            </Phone>
          </div>
        </div>
      ) : (
        <div />
      );

      return {
        id: user.id,
        rowId: user.id,
        avatar: (
          <Avatar
            name={fullName}
            hideAvailability
            color="#0019AF"
            size="small"
            className={styles.avatar}
          />
        ),
        name: (
          <div className={styles['name-item']}>
            <span className={styles['name-text']} key="full-name">
              {fullName || '-'}
            </span>
          </div>
        ),
        action: defaultAction,
        nameDescription: (
          <span className={styles['name-text']} key="addressNumber">
            {user.publicInfo.addressNumber}
          </span>
        ),
        rules: renderRulesColumn(userForwardingWithStatus, user),
        destination: renderForwardingDestinationColumn(userForwardingWithStatus.data, user)
      };
    }
  );

  const columns: TableHeaderColumnT[] = [
    {
      columnId: 'avatar',
      text: '',
      size: 'x-small',
      border: false
    },
    {
      columnId: 'name',
      text: t('forwardings.userCallForwardings.table.name'),
      size: 'medium'
    },
    {
      columnId: 'rules',
      text: t('forwardings.userCallForwardings.table.reasons'),
      size: 'x-large'
    },
    {
      columnId: 'destination',
      text: t('forwardings.userCallForwardings.table.destination'),
      size: 'large'
    }
  ];

  const smallItemToCell: MapItemToCellFnT = ({ columnId, size, border = true }, item) => ({
    value: item[columnId],
    size,
    border,
    valueClasses: item.rowClasses
  });

  // $FlowFixMe
  const hasNoItems = R.chain(R.path(['forwardings']), usersToDisplay).length === 0;

  if (hasNoItems && !isUserCollectionLoading) {
    return <NoResults title={t('forwardings.userCallForwardings.noResults')} />;
  }

  return (
    <Table
      id="user-forwardings-table"
      items={items}
      columns={columns}
      allowLoadMore={allowLoadMore}
      onInfiniteScrollLoadMore={onLoadMore}
      mapItemToCell={smallItemToCell}
      tableStyle={styles['user-table-container']}
    />
  );
};

export default UserForwardingsTable;
