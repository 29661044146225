// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { AcdGridElementT } from '../../../../ducks/entities/callFlow/callFlowTypes';
import type { AcdEntityT } from '../../../../ducks/entities/acd/acdTypes';
import GridElementContainer from '../gridElement/GridElementContainer';
import type { CallFlowTreeNodeT } from '../../../../ducks/entities/callFlow';
import NotificationDetails from '../details/acd/NotificationDetails';
import QueueDetails from '../details/acd/QueueDetails';
import AcdAgentDetails from '../details/acd/AcdAgentDetails';
import UnhandledDetails from '../details/acd/UnhandledDetails';
import CalendarClosedDetails from '../details/acd/CalendarClosedDetails';
import OverflowDetails from '../details/acd/OverflowDetails';
import CallbackDetails from '../details/acd/CallbackDetails';
import CallbackReentranceDetails from '../details/acd/CallbackReentranceDetails';
import AcdCalendarDetails from '../details/acd/AcdCalendarDetails';
import EditNotificationDetails from '../details/acd/EditNotificationDetails';
import EditAcdQueue from '../details/acd/EditAcdQueue';
import EditAcdAgent from '../details/acd/EditAcdAgent';
import EditUnhandled from '../details/acd/EditUnhandled';
import EditCalendarClosed from '../details/acd/EditCalendarClosed';
import EditOverflow from '../details/acd/EditOverflow';
import EditCallbackDetails from '../details/acd/EditCallbackDetails';
import EditCallbackReentranceDetails from '../details/acd/EditCallbackReentranceDetails';
import EditAcdCalendar from '../details/acd/EditAcdCalendar';
import EditVoiceMail from '../details/acd/EditVoiceMail';
import EditVoiceMailExtra from '../details/acd/EditVoiceMailExtra';
import VoiceMailDetails from '../details/acd/VoiceMailDetails';
import VoiceMailExtraDetails from '../details/acd/VoiceMailExtraDetails';
import type { CurrentUserT } from '../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {
  data: AcdEntityT,
  enterpriseId: string,
  hideDescription: boolean,
  single?: boolean
};

export const AcdGroupElement = (props: PropsT): Element<typeof GridElementContainer> => {
  const { data, enterpriseId, hideDescription, single } = props;
  const { t } = useTranslation();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  // redux
  const nodeId = data.id;
  const callflowId = `${enterpriseId}-${data.type}-${nodeId}`;

  const callbackReentranceNode: CallFlowTreeNodeT = {
    id: `acd_callback_reentrance_${data.id}`,
    type: 'ACD_CALLBACK_REENTRANCE'
  };
  const callbackNode: CallFlowTreeNodeT = {
    id: `acd_callback_${data.id}`,
    type: 'ACD_CALLBACK',
    horizontalNode: ((currentUser && currentUser.featureFlags) || []).includes(
      'FEATURE-GENERIC-API'
    )
      ? callbackReentranceNode
      : null
  };
  const overFlowNode = {
    id: `acd_overflow_${data.id}`,
    type: 'ACD_OVERFLOW',
    verticalNode: callbackNode
  };
  const calendarNotOpenNode = {
    id: `acd_calendar_closed_${data.id}`,
    type: 'ACD_CALENDAR_CLOSED'
  };

  const voiceMailDetailsNode = {
    id: `acd_voice_mail_details_${data.id}`,
    type: 'ACD_VOICE_MAIL_DETAILS'
  };

  const voiceMailNode = {
    id: `acd_voice_mail_${data.id}`,
    type: 'ACD_VOICE_MAIL',
    verticalNode: voiceMailDetailsNode
  };

  const unhandledNode = {
    id: `acd_unhandled_${data.id}`,
    type: 'ACD_UNHANDLED',
    horizontalNode: voiceMailNode
  };

  const agentsNode = {
    id: `acd_agents_${data.id}`,
    type: 'ACD_AGENTS',
    horizontalNode: unhandledNode
  };
  const queueNode = {
    id: `acd_queue_${data.id}`,
    type: 'ACD_QUEUE',
    verticalNode: overFlowNode,
    horizontalNode: agentsNode
  };
  const messageNode = {
    id: `acd_message_${data.id}`,
    type: 'ACD_MESSAGE',
    horizontalNode: queueNode
  };
  const calendarNode = {
    id: `acd_calendar_${data.id}`,
    type: 'ACD_CALENDAR',
    horizontalNode: messageNode,
    verticalNode: calendarNotOpenNode
  };

  const gridElement: AcdGridElementT = {
    id: data.id,
    type: data.type,
    name: data.label || t(`callflows.type.${data.type}.defaultName`),
    number: data.addressNumber,
    description: t(`callflows.type.${data.type}.description`),
    enterpriseId,
    rootNode: calendarNode
  };

  const viewContent = nodeType => {
    switch (nodeType) {
      case 'ACD_MESSAGE':
        return <NotificationDetails callflowId={callflowId} />;
      case 'ACD_QUEUE':
        return <QueueDetails callflowId={callflowId} />;
      case 'ACD_AGENTS':
        return <AcdAgentDetails callflowId={callflowId} />;
      case 'ACD_UNHANDLED':
        return <UnhandledDetails callflowId={callflowId} />;
      case 'ACD_VOICE_MAIL':
        return <VoiceMailDetails callflowId={callflowId} />;
      case 'ACD_VOICE_MAIL_DETAILS':
        return <VoiceMailExtraDetails callflowId={callflowId} />;
      case 'ACD_CALENDAR_CLOSED':
        return <CalendarClosedDetails callflowId={callflowId} />;
      case 'ACD_OVERFLOW':
        return <OverflowDetails callflowId={callflowId} />;
      case 'ACD_CALLBACK':
        return <CallbackDetails callflowId={callflowId} />;
      case 'ACD_CALLBACK_REENTRANCE':
        return <CallbackReentranceDetails callflowId={callflowId} />;
      case 'ACD_CALENDAR':
        return <AcdCalendarDetails callflowId={callflowId} />;
      default:
        return <div />;
    }
  };

  const editContent = nodeType => {
    switch (nodeType) {
      case 'ACD_MESSAGE':
        return <EditNotificationDetails callflowId={callflowId} />;
      case 'ACD_QUEUE':
        return <EditAcdQueue callflowId={callflowId} />;
      case 'ACD_AGENTS':
        return <EditAcdAgent callflowId={callflowId} />;
      case 'ACD_UNHANDLED':
        return <EditUnhandled callflowId={callflowId} />;
      case 'ACD_VOICE_MAIL':
        return <EditVoiceMail callflowId={callflowId} />;
      case 'ACD_VOICE_MAIL_DETAILS':
        return <EditVoiceMailExtra callflowId={callflowId} />;
      case 'ACD_CALENDAR_CLOSED':
        return <EditCalendarClosed callflowId={callflowId} />;
      case 'ACD_OVERFLOW':
        return <EditOverflow callflowId={callflowId} />;
      case 'ACD_CALLBACK':
        return <EditCallbackDetails callflowId={callflowId} />;
      case 'ACD_CALLBACK_REENTRANCE':
        return <EditCallbackReentranceDetails callflowId={callflowId} />;
      case 'ACD_CALENDAR':
        return <EditAcdCalendar callflowId={callflowId} />;
      default:
        return <div />;
    }
  };

  return (
    <GridElementContainer
      key={gridElement.id}
      gridElement={gridElement}
      hideDescription={hideDescription}
      viewContentSupplier={viewContent}
      editContentSupplier={editContent}
      single={single}
    />
  );
};

export default AcdGroupElement;
