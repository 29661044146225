// @flow
import React, { type Element } from 'react';
import classNames from 'classnames';
import styles from './Requirement.module.scss';

type PropsT = {|
  text: string,
  checked: boolean,
  isEmpty?: boolean
|};

export const Requirement = (props: PropsT): Element<'p'> => (
  <p
    className={classNames(
      styles.requirement,
      !props.isEmpty && !props.checked ? styles.invalid : ''
    )}
  >
    {props.text}
    {props.checked && <img src="/checkmark green.svg" alt="successful" width="16px" />}
  </p>
);

export default Requirement;
