// @flow strict-local

import React, { type Element, useEffect, useRef, useState } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { FormProvider } from 'react-hook-form';
import { CancelToken } from 'axios';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GenericError from '../../../components/Error/GenericError';
import type { EnterpriseEntityT } from '../../../ducks/entities/enterprise';
import ErrorBoundary from '../../../components/Error/ErrorBoundary';
import { goToForwardingDetailsCreate } from '../../../navigationOperations';
import UserForwardingsTable from './UserForwardingsTable';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import UserForwardingsSearch from './UserForwardingsSearch';
import ForwardingsHeader from '../components/ForwardingsHeader';
import useFormAutoRegister from '../../callFlows/callFlowGrid/details/useFormAutoRegister';
import directoryOperations from '../../../ducks/entities/directory/directoryOperations';
import callForwardingOperations from '../../../ducks/entities/callForwarding/callForwardingOperations';
import {
  collectionHasError,
  collectionHasMoreResults,
  collectionIsLoading
} from '../../../ducks/entities/directory/directorySelectors';
import type { DirectoryStateEntityT } from '../../../ducks/entities/directory';
import { DEFAULT_DEBOUNCE_DURATION } from '../../../utils/timeutils';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';

import styles from './UserForwardings.module.scss';

export type FormT = {
  searchField: string,
  showEnterpriseForwardings: boolean,
  showExternalServiceFwds: boolean
};

export type PropsT = {|
  enterprise: EnterpriseEntityT,
  onForwardingActivityChange: () => Promise<void>,
  onDeleteForwarding: () => void,
  singleUser?: InternalUserStateEntityT
|};

const FIRST_PAGE = 1;
const USER_FORWARDINGS_PAGE_SIZE = 5;

export const UserForwardings = (props: PropsT): Element<'div'> => {
  const { enterprise, onForwardingActivityChange, onDeleteForwarding, singleUser } = props;

  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const showLoadingSpinner = useSelector(state => collectionIsLoading(state));
  const hasError = useSelector(state => collectionHasError(state));
  const hasMore = useSelector(state => collectionHasMoreResults(state));

  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const mounted = useRef(false);
  const location = useLocation();
  const singleUserAddressNumber = singleUser ? singleUser.addressNumber : '';
  const searchTerm =
    location.state && location.state.addressNumber
      ? location.state.addressNumber
      : singleUserAddressNumber;

  const initialFormValues: FormT = {
    searchField: searchTerm || '',
    showEnterpriseForwardings: true,
    showExternalServiceFwds: false
  };

  const methods = useFormAutoRegister(R.keys(initialFormValues), {
    defaultValues: initialFormValues
  });
  const values = methods.watch();

  let requestCancelTokenSource;
  const getData = async (page, userSearchTerm) => {
    requestCancelTokenSource = CancelToken.source();
    setIsLoadingUsers(true);
    if (requestCancelTokenSource) {
      const userEntries = await dispatch(
        directoryOperations.searchDirectory(enterprise.entID, requestCancelTokenSource.token, {
          page,
          size: USER_FORWARDINGS_PAGE_SIZE,
          search: userSearchTerm || '',
          type: 'users'
        })
      );

      const promises = (userEntries.results || []).map(
        user =>
          user.publicInfo.addressNumber &&
          dispatch(
            callForwardingOperations.retrieveUserForwardingsByAddressNumber(
              enterprise.entID,
              user.publicInfo.addressNumber,
              requestCancelTokenSource.token
            )
          )
      );
      Promise.all(promises).then(() => {
        if (mounted.current) {
          setIsLoadingUsers(false);
        }
      });
    }
  };

  // Wrap debounce inside callback-function, this prevents redeclaring the debounce function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceGetData = React.useCallback(
    AwesomeDebouncePromise(getData, DEFAULT_DEBOUNCE_DURATION),
    []
  );

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    debounceGetData(FIRST_PAGE, values.searchField);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (requestCancelTokenSource) {
        requestCancelTokenSource.cancel();
      }
    };
  }, [values.searchField, singleUser ? singleUser.id : '']); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="user-call-forwardings-container" className={styles['user-call-forwardings-container']}>
      <ErrorBoundary
        showError={hasError}
        errorElement={<GenericError message={t('users.userListFailedMsg')} />}
      >
        <ForwardingsHeader
          title={t('forwardings.userCallForwardings.title')}
          description={t('forwardings.userCallForwardings.description')}
          buttonText={t('forwardings.createCallForwardingButton')}
          onCreateClickAction={() => {
            dispatch(
              goToForwardingDetailsCreate(
                enterprise ? enterprise.entID : '',
                null,
                false,
                singleUser
              )
            );
          }}
          singleUserAddressNumber={singleUserAddressNumber}
        />
        <FormProvider {...methods}>
          <UserForwardingsSearch
            fieldName="searchField"
            numberOfEnterpriseForwardings={
              enterprise && enterprise.forwardings ? enterprise.forwardings.length : 0
            }
            singleUserAddressNumber={singleUserAddressNumber}
          />
        </FormProvider>
        <UserForwardingsTable
          enterpriseId={enterprise.entID}
          allowLoadMore={hasMore && !isLoadingUsers}
          onLoadMore={page => getData(page, values.searchField)}
          onCreateUserSpecific={(user: DirectoryStateEntityT) => {
            dispatch(
              goToForwardingDetailsCreate(
                enterprise ? enterprise.entID : '',
                user.publicInfo.addressNumber,
                false,
                singleUser
              )
            );
          }}
          isUserCollectionLoading={showLoadingSpinner}
          showEnterpriseForwardings={values.showEnterpriseForwardings}
          showExternalServiceFwds={values.showExternalServiceFwds}
          onForwardingActivityChange={onForwardingActivityChange}
          onDeleteForwarding={onDeleteForwarding}
          singleUser={singleUser}
        />
        {showLoadingSpinner && (
          <CenterHorizontally>
            <LoadingSpinner />
          </CenterHorizontally>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default UserForwardings;
