import React from 'react';
import IconContainer from '../IconContainer';

export default function IconForwardRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.42 20.25a2.22 2.22 0 0 1-1.5-.6 2.18 2.18 0 0 1-.67-1.65v-2.25H2.5A1.75 1.75 0 0 1 .75 14v-4c0-.966.784-1.75 1.75-1.75h10.75V6.07a2.29 2.29 0 0 1 2.13-2.32 2 2 0 0 1 1.62.73l5.62 6a2.29 2.29 0 0 1 0 3.08l-5.63 6a2.18 2.18 0 0 1-1.57.69M2.5 9.75a.25.25 0 0 0-.25.25v4c0 .138.112.25.25.25h11.39a.85.85 0 0 1 .86.84V18a.66.66 0 0 0 .19.54c.127.141.31.218.5.21a.7.7 0 0 0 .48-.25l5.63-6a.77.77 0 0 0 0-1l-5.63-6c-.2-.22-.3-.26-.49-.25a.79.79 0 0 0-.68.82v2.82a.85.85 0 0 1-.84.86z"/></svg>)}
    />
  );
}
  