// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../components/BaseModal';
import ActionButton from '../../components/Button/ActionButton';
import { operations as userOps } from '../../ducks/entities/user';
import type { InternalUserEntityT } from '../../ducks/entities/user/userTypes';
import { selectors as enterpriseSelect } from '../../ducks/entities/enterprise';
import { sleepDelay } from '../../utils/timeutils';
import styles from './ServicePackModal.module.scss';

type ShowDialogT = 'add' | 'remove' | typeof undefined;

type PropsT = {|
  showDialog: ShowDialogT,
  selectedEnterprises: string[],
  selectedServicePacks: string[],
  restrictedServicePacks: string[],
  acdServicePacks: string[],
  closeDialog: () => void
|};

let cancelRequest: CancelTokenSource;

// eslint-disable-next-line no-unused-vars
export const ServicePackModal = (props: PropsT): Element<typeof BaseModal> | null => {
  const {
    showDialog,
    selectedEnterprises,
    selectedServicePacks,
    restrictedServicePacks,
    acdServicePacks,
    closeDialog
  } = props;
  // redux
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [enterpriseProcessed, setEnterpriseProcessed] = useState(0);
  const [usersProcessed, setUsersProcessed] = useState(0);
  const [usersToBeProcessed, setUsersToBeProcessed] = useState(0);
  const enterprises = useSelector(state =>
    state.entities.enterprise
      ? enterpriseSelect.enterprisesById(state, state.entities.enterprise.allIds)
      : []
  );
  const [failedUsers, setFailedUsers] = useState([]);
  const [succeededUsers, setSucceededUsers] = useState([]);
  const [skippedUsers, setSkippedUsers] = useState([]);
  const [updating, setUpdating] = useState(false);
  const MAX_USERS = 100;
  const PROCESS_USERS_DELAY = 400;

  const patchUser = async (enterpriseId: string, user: InternalUserEntityT) => {
    return dispatch(
      userOps.update(
        {
          enterpriseId,
          id: user.id,
          personId: user.personId,
          userType: user.userType,
          servicePackIds: user.servicePackIds
        },
        cancelRequest.token,
        {}
      )
    );
  };

  const containsRestrictedServicePacks = servicePackIds => {
    const foundRestrictedSP = selectedServicePacks.find(sp =>
      sp ? restrictedServicePacks.includes(sp) : false
    );
    if (foundRestrictedSP) {
      return servicePackIds.find(sp => acdServicePacks.includes(sp));
    }
    return true;
  };

  const processUsers = async (enterpriseId, usersData) => {
    if (usersData) {
      let processed = 0;

      let succeeded = [];
      let failed = [];
      let skipped = [];
      const usersToBeUpdated = usersData
        .map(user => {
          let servicePackIds = user.servicePacks.map(sp => sp.id);
          if (showDialog === 'add') {
            const newSPIds = selectedServicePacks.filter(sp => !servicePackIds.includes(sp));
            if (containsRestrictedServicePacks(servicePackIds) && newSPIds.length > 0) {
              servicePackIds = servicePackIds.concat(newSPIds);
            }
          } else if (showDialog === 'remove') {
            servicePackIds = user.servicePacks
              .filter(sp => !selectedServicePacks.includes(sp.id))
              .map(sp => sp.id);
          }
          if (user.servicePacks.length !== servicePackIds.length) {
            return { ...user, servicePackIds };
          }
          skipped = [...skipped, { ...user, enterpriseId }];
          return null;
        })
        .filter(usr => usr);

      setSkippedUsers(skipped);
      processed = skipped.length;
      await Promise.all(
        usersToBeUpdated.map(async (user, i) => {
          if (showDialog) {
            await sleepDelay(PROCESS_USERS_DELAY * i);
            // $FlowFixMe: nulls already filtered
            const data = await patchUser(enterpriseId, user);
            if (data) {
              succeeded = [...succeeded, { ...user, enterpriseId }];
            } else {
              failed = [...failed, { ...user, enterpriseId }];
            }
            setSucceededUsers(succeeded);
            setFailedUsers(failed);
            processed += 1;
            setUsersProcessed(processed + 1);
          }
        })
      );
    }
  };

  const processEnterprises = async () => {
    let processed = 0;
    await Promise.all(
      selectedEnterprises.map(async entId => {
        let usersData = [];
        let fetchMore = true;
        let page = 1;
        setUsersProcessed(0);
        while (fetchMore && showDialog) {
          // eslint-disable-next-line no-await-in-loop
          const data = await dispatch(
            userOps.getUsers(entId, cancelRequest.token, { page, size: MAX_USERS })
          );
          if (data) {
            usersData = usersData.concat(data.results);
            if (usersData.length >= data.totalCount) {
              fetchMore = false;
            }
            setUsersToBeProcessed(usersData.length);
          }
          page++;
        }
        if (usersData) {
          usersData = usersData.filter(user => user.personId);
          setUsersToBeProcessed(usersData.length);
          await processUsers(entId, usersData);
        }
        processed += 1;
        setEnterpriseProcessed(processed);
      })
    );
  };

  useEffect(() => {
    if (enterpriseProcessed === selectedEnterprises.length) {
      setUpdating(false);
    }
  }, [enterpriseProcessed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    cancelRequest = CancelToken.source();

    if (showDialog) {
      setUpdating(true);
      setEnterpriseProcessed(0);
      setUsersToBeProcessed(0);
      setUsersProcessed(0);
      setSucceededUsers([]);
      setFailedUsers([]);
      setSkippedUsers([]);
      processEnterprises();
    }
    return () => {
      cancelRequest.cancel();
    };
  }, [showDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeSPDialog = () => {
    setUpdating(true);
    setEnterpriseProcessed(0);
    setUsersProcessed(0);
    setUsersToBeProcessed(0);
    setSucceededUsers([]);
    setFailedUsers([]);
    setSkippedUsers([]);
    closeDialog();
    window.location.reload();
  };

  const processedContent = () => (
    <div className={styles['sp-container']}>
      <div className={styles.title}>
        {showDialog === 'add' ? t('adminui.spAddModalTitle') : t('adminui.spRemoveModalTitle')}
      </div>
      <div className={styles['text-content']}>
        <div>
          {t('adminui.enterprisesProcessed', {
            processed: enterpriseProcessed,
            amountOfEnterprises: selectedEnterprises.length
          })}
        </div>
        <div>
          {t('adminui.usersProcessed', {
            processed: usersProcessed,
            amountOfUsers: usersToBeProcessed
          })}
        </div>
      </div>
      <div className={styles['spinner-container']}>
        <LoadingSpinner />
      </div>
      <div className={styles['bottom-area']}>
        <ActionButton
          id="cancel-sp-button"
          label={t('adminui.cancel')}
          onClickAction={closeSPDialog}
        />
      </div>
    </div>
  );

  const updateDoneContent = () => (
    <div>
      <h4>{t('adminui.successTitle')}</h4>
      {selectedEnterprises.map(entId => {
        const enterprise = enterprises.find(ent => ent.entID === entId);
        const succeeded = succeededUsers.filter(usr => usr.enterpriseId === entId);
        if (enterprise) {
          return <div key={enterprise.entID}>{succeeded.length}</div>;
        }
        return null;
      })}
      <h4>{t('adminui.failedTitle')}</h4>
      {selectedEnterprises.map(entId => {
        const enterprise = enterprises.find(ent => ent.entID === entId);
        const failed = failedUsers.filter(usr => usr.enterpriseId === entId);
        if (enterprise) {
          return <div key={enterprise.entID}>{failed.length}</div>;
        }
        return null;
      })}
      <h4>{t('adminui.skippedTitle')}</h4>
      {selectedEnterprises.map(entId => {
        const enterprise = enterprises.find(ent => ent.entID === entId);
        const skipped = skippedUsers.filter(usr => usr.enterpriseId === entId);
        if (enterprise) {
          return <div key={enterprise.entID}>{skipped.length}</div>;
        }
        return null;
      })}
    </div>
  );

  return showDialog ? (
    <BaseModal modalStyles={[styles.modal]} onClose={closeSPDialog}>
      {updating ? processedContent() : updateDoneContent()}
    </BaseModal>
  ) : null;
};

export default ServicePackModal;
