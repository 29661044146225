import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLinkedinLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 1h-17C2.1 1 1 2.1 1 3.5v17C1 21.9 2.1 23 3.5 23h17c1.4 0 2.5-1.1 2.5-2.5v-17C23 2.1 21.9 1 20.5 1M22 20.5c0 .8-.7 1.5-1.5 1.5h-17c-.8 0-1.5-.7-1.5-1.5v-17C2 2.7 2.7 2 3.5 2h17c.8 0 1.5.7 1.5 1.5zM7.9 8.9h-3c-.5 0-.9.4-.9.8v9.8c0 .5.4.9.9.9h3c.5 0 .9-.4.9-.9V9.8c0-.5-.4-.9-.9-.9m-.1 10.5H5V9.9h2.8zm-1.4-11c1.3 0 2.4-1 2.4-2.4 0-1.3-1-2.4-2.4-2.4C5.1 3.6 4 4.7 4 6s1.1 2.4 2.4 2.4m0-3.8c.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4S5 6.8 5 6s.6-1.4 1.4-1.4m9.3 3.9c-.9 0-1.8.3-2.5.9-.1-.3-.5-.5-.8-.5h-2.3c-.5 0-.9.4-.9.9v9.7c0 .5.4.9.9.9h2.3c.5 0 .9-.4.9-.9v-5.6c-.1-.8.5-1.4 1.3-1.5s1.4.5 1.5 1.3v5.8c0 .5.4.9.9.9h2.2c.5 0 .9-.4.9-.9v-6.3c-.1-2.9-1.8-4.7-4.4-4.7M19 19.4h-2v-5.5c.1-1.3-.9-2.4-2.2-2.5s-2.4.9-2.5 2.2v5.8h-2V9.9h2c.1.4.5.7.9.7.2 0 .3-.1.5-.2.5-.5 1.3-.8 2-.8 2.1 0 3.3 1.4 3.3 3.7z"/></svg>)}
    />
  );
}
  