import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDeliveryLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 7H15V5.5c0-.8-.7-1.5-1.5-1.5h-12C.7 4 0 4.7 0 5.5v10c0 .3.2.5.5.5s.5-.2.5-.5V14h13v1.5c0 .3.2.5.5.5s.5-.2.5-.5V8h2v3.5c0 .8.7 1.5 1.5 1.5H23v3.5c0 .3-.2.5-.5.5h-1c-.3 0-.5.2-.5.5s.2.5.5.5h1c.8 0 1.5-.7 1.5-1.5v-6C24 8.6 22.4 7 20.5 7M1 13V5.5c0-.3.2-.5.5-.5h12c.3 0 .5.2.5.5V13zm17.5-1c-.3 0-.5-.2-.5-.5V8h2.5c1.4 0 2.5 1.1 2.5 2.5V12zm-1 3c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5m0 4c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5m-13-4C3.1 15 2 16.1 2 17.5S3.1 20 4.5 20 7 18.9 7 17.5 5.9 15 4.5 15m0 4c-.8 0-1.5-.7-1.5-1.5S3.7 16 4.5 16s1.5.7 1.5 1.5S5.3 19 4.5 19m9.5-1.5c0 .3-.2.5-.5.5h-5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.3 0 .5.2.5.5"/></svg>)}
    />
  );
}
  