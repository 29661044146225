import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLaptopLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.5 15.5H22V5a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10.5H.5a.5.5 0 0 0-.5.5v1.43A3.57 3.57 0 0 0 3.57 21h16.86A3.57 3.57 0 0 0 24 17.43V16a.5.5 0 0 0-.5-.5M3 5a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v10.5h-6.25a.5.5 0 0 0-.43.25 2.78 2.78 0 0 1-4.64 0 .5.5 0 0 0-.43-.25H3zm20 12.4a2.57 2.57 0 0 1-2.54 2.6H3.57A2.57 2.57 0 0 1 1 17.43v-.93h8a3.79 3.79 0 0 0 6 0h8z"/></svg>)}
    />
  );
}
  