import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTurvapakettiRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m21.58 1-9.5-1h-.16l-9.5 1a.75.75 0 0 0-.67.75V8.4c0 8.76 3.79 12.43 9.91 15.52a.76.76 0 0 0 .68 0c6.12-3.09 9.91-6.76 9.91-15.52V1.75a.75.75 0 0 0-.67-.75m-.83 3.48L6.37 18.32a11.3 11.3 0 0 1-1.57-2.31L19.09 2.29l1.66.17zM3.25 2.43 12 1.54l5.16.52-13 12.46a18.4 18.4 0 0 1-.9-6.13zM12 22.41a21.7 21.7 0 0 1-4.58-3L20.75 6.6v1.8c0 7.87-3.2 11.15-8.75 14.01"/></svg>)}
    />
  );
}
  