// @flow
import * as R from 'ramda';
import moment from 'moment';
import uuid from 'uuid';

import type {
  BaseCalendarEntityT,
  TimeRangeT,
  UpdateWeekScheduleT,
  WeeklySlotT
} from '../../../../../../ducks/entities/calendar/calendarTypes';

const getHours = (s: string) => (s ? s.split(':')[0] : undefined);
const getMinutes = (s: string) => (s ? s.split(':')[1] : undefined);

const createDaySlots = (daySlots, dayIndex) => {
  return daySlots.map(c => {
    const endHour = getHours(c.endTime) === '24' ? '23' : getHours(c.endTime);
    const endMinute = getHours(c.endTime) === '24' ? '59' : getMinutes(c.endTime);
    return {
      uid: uuid(),
      start: moment({ d: dayIndex, h: getHours(c.startTime), m: getMinutes(c.startTime) }),
      end: moment({ d: dayIndex, h: endHour, m: endMinute }),
      value: '',
      dayIndex
    };
  });
};

const convertToFormCalendar = (cal: BaseCalendarEntityT): WeeklySlotT[] => [
  ...createDaySlots(cal.weekSchedule.MONDAY, 1),
  ...createDaySlots(cal.weekSchedule.TUESDAY, 2),
  ...createDaySlots(cal.weekSchedule.WEDNESDAY, 3),
  ...createDaySlots(cal.weekSchedule.THURSDAY, 4),
  ...createDaySlots(cal.weekSchedule.FRIDAY, 5),
  ...createDaySlots(cal.weekSchedule.SATURDAY, 6),
  ...createDaySlots(cal.weekSchedule.SUNDAY, 7)
];

const WEEKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

class CalendarConverter {
  static convertToWeekScheduleFormField(calendar: ?BaseCalendarEntityT): WeeklySlotT[] {
    return calendar && calendar.weekSchedule ? convertToFormCalendar(calendar) : [];
  }

  static convertToUpdateData(weekSlots: WeeklySlotT[]): UpdateWeekScheduleT {
    const timeSlots: TimeRangeT[] = (weekSlots || []).map(daySlot => ({
      startTime: daySlot.start.format('HH:mm'),
      endTime: daySlot.end.format('HH:mm'),
      dayIndex: parseInt(daySlot.start.format('D'), 10) - 1
    }));
    // $FlowFixMe index should always match to the weekday
    const groupByWeekDay = R.groupBy(({ dayIndex }) => WEEKDAYS[dayIndex]);
    // $FlowFixMe index should always match to the weekday
    return groupByWeekDay(timeSlots);
  }
}

export default CalendarConverter;
