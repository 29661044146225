// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UnhandledIcon } from '../../../../../assets/callflow/details/speeddialling-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';

export type PropsT = {|
  callflowId: string
|};

export const UnhandledDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};

  const acdAudioFieldConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_unhandled_${acdData.id}`}
      nodeType="ACD_UNHANDLED"
      icon={<UnhandledIcon />}
      title={t('callflows.viewAcdUnhandledDetails.title')}
      active
    >
      <CallflowDetailsColumn id="acd-unhandled-column-1">
        <CallflowProperty
          id="busyFwd"
          title={t('callflows.viewAcdUnhandledDetails.busyFwd')}
          property={ivrAcdCallCenter.busyFwd} // TODO: use servicesSettings
        />
        <CallflowAudioProperty
          title={t('callflows.viewAcdUnhandledDetails.queueFullMsg')}
          audioField={acdAudioFieldConverter.convertToAudioPropertyField(
            'QueueFullMsg',
            R.path(['audios', 'queueFullMsg', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['queueFullMsg'], ivrAcdCallCenter)
          )}
          isActive
        />

        <CallflowProperty
          id="goodByeFwd"
          title={t('callflows.viewAcdUnhandledDetails.goodByeFwd')}
          property={ivrAcdCallCenter.goodByeFwd} // TODO: use servicesSettings
        />
        <CallflowAudioProperty
          title={t('callflows.viewAcdUnhandledDetails.goodByeMsg')}
          audioField={acdAudioFieldConverter.convertToAudioPropertyField(
            'GoodByeMsg',
            R.path(['audios', 'goodByeMsg', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['goodByeMsg'], ivrAcdCallCenter)
          )}
          isActive
        />

        <CallflowProperty
          id="noOperator"
          title={t('callflows.viewAcdUnhandledDetails.noOperator')}
          property={acdCallCenter.noOperator}
          basicValue
        />

        {acdCallCenter.noOperator && (
          <CallflowProperty
            id="noOperatorFwd"
            title={t('callflows.viewAcdUnhandledDetails.noOperatorFwd')}
            property={ivrAcdCallCenter.noOperatorFwd} // TODO: use servicesSettings
          />
        )}
        {acdCallCenter.noOperator && (
          <CallflowAudioProperty
            title={t('callflows.viewAcdUnhandledDetails.noOperatorMsg')}
            audioField={acdAudioFieldConverter.convertToAudioPropertyField(
              'NoOperatorMsg',
              R.path(['audios', 'noOperatorMsg', 'extensionAudio', 'name'], acdCallCenter),
              R.path(['noOperatorMsg'], ivrAcdCallCenter)
            )}
            isActive
          />
        )}
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default UnhandledDetails;
