// @flow

/* eslint-disable import/prefer-default-export, no-underscore-dangle */

import * as R from 'ramda';
import { type StoreStateT } from '../../../commonTypes';
import type { EnterpriseEntityT, EnterpriseStateEntityT } from './enterpriseTypes';

type GetEnterprisesByIdFnT = (StoreStateT, string[]) => EnterpriseStateEntityT[];
export const enterprisesById: GetEnterprisesByIdFnT = ({ entities: { enterprise } }, ids) =>
  ids.map(id => enterprise.byId[id]);

type GetEnterpriseByIdFnT = (StoreStateT, string) => EnterpriseEntityT;
export const enterpriseById: GetEnterpriseByIdFnT = ({ entities: { enterprise } }, id) =>
  enterprise.byId[id];

type CollectionIsLoadingFnT = StoreStateT => boolean;
export const collectionIsLoading: CollectionIsLoadingFnT = ({ entities: { enterprise } }) =>
  enterprise.__metadata.isRetrieving === true || enterprise.__metadata.isSearching === true;

type EnterpriseIsForbiddenFnT = (StoreStateT, ?string) => boolean;
export const enterpriseIsForbidden: EnterpriseIsForbiddenFnT = (
  { entities: { enterprise } },
  enterpriseId
) =>
  R.path(['byId', enterpriseId || '', '__metadata', 'error', 'response', 'status'], enterprise) ===
  403;

type EnterpriseHasLoadingErrorFnT = (StoreStateT, ?string) => boolean;
export const enterpriseHasLoadingError: EnterpriseHasLoadingErrorFnT = (
  { entities: { enterprise } },
  enterpriseId
) =>
  R.path(['byId', enterpriseId || '', '__metadata', 'hasLoadingError'], enterprise) !== undefined;

export const isLoading = (state: StoreStateT, id: string) =>
  R.pathOr(false, ['entities', 'enterprise', 'byId', id, '__metadata', 'isRetrieving'], state);
