import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBulletsLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.5 1.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m0 4a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 2.5 5.5M8 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15A.5.5 0 0 1 8 4M2.5 9.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m0 4a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 2.5 13.5M24 12a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 .5.5M2.5 17.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m0 4a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 2.5 21.5M24 20a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 .5.5"/></svg>)}
    />
  );
}
  