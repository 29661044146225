// @flow

import React, { type Element } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import type { ExactPropsT } from '../../commonTypes';
import { goToLogin } from '../../navigationOperations';
import styles from './BackToLoginLink.module.scss';

type OwnPropsT = {};

type DispatchPropsT = {
  goToLogin: () => *
};

export type PropsT = ExactPropsT<OwnPropsT, DispatchPropsT>;

export const BackToLoginLink = (props: PropsT): Element<'div'> => {
  const { goToLogin: goToLoginPage } = props;
  const { t } = useTranslation();
  return (
    <div>
      <button
        className={styles['text-link']}
        onClick={() => {
          goToLoginPage();
        }}
      >
        {t('login.backToLoginLink')}
      </button>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToLogin
    },
    dispatch
  );

export default connect<PropsT, OwnPropsT, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(BackToLoginLink);
