// @flow
import React, { type Element } from 'react';
import classnames from 'classnames';
import { ReactComponent as ErrorIcon } from '../../../../../assets/public/error-red.svg';
import styles from './InvalidConfigurationInfo.module.scss';

type PropsT = {|
  title: string | Element<*>,
  details?: string | Element<*>,
  showLeftPadding?: boolean
|};

export const InvalidConfigurationInfo = (props: PropsT): Element<'div'> => {
  const { title, details, showLeftPadding } = props;
  return (
    <div className={classnames(styles.container, showLeftPadding ? styles['left-padding'] : '')}>
      <ErrorIcon className={styles.error} />
      <div className={styles.text}>{title}</div>
      {details && <div className={styles.text}>{details}</div>}
    </div>
  );
};

export default InvalidConfigurationInfo;
