"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function UserMenuLinkCategoryItem(_a) {
    var icon = _a.icon, onClick = _a.onClick, tag = _a.tag, target = _a.target, title = _a.title, url = _a.url;
    return {
        icon: icon,
        onClick: onClick,
        tag: tag,
        target: target,
        title: title,
        url: url,
    };
}
function UserMenuLinkCategory(_a) {
    var _b = _a.items, items = _b === void 0 ? [] : _b, onToggle = _a.onToggle, order = _a.order, tag = _a.tag, title = _a.title, toggled = _a.toggled;
    return {
        items: items.map(function (item) { return UserMenuLinkCategoryItem(item); }),
        onToggle: onToggle,
        order: order,
        tag: tag,
        title: title,
        toggled: toggled,
    };
}
exports.default = UserMenuLinkCategory;
