import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoutFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16 14H9c-1.1 0-2-.9-2-2s.9-2 2-2h7V3.2C16 2 15 1 13.7 1H4.2C3 1 2 2 2 3.2v17.6C2 22 3 23 4.2 23h9.6c1.2 0 2.2-1 2.2-2.2zm4-6.3c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l2.5 2.5H9c-.4 0-.8.3-.8.8s.3.8.8.8h12.4L19 15.2c-.3.3-.3.8 0 1.1s.8.3 1.1 0l3.8-3.8c.3-.3.3-.8 0-1.1z"/></svg>)}
    />
  );
}
  