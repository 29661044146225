import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGamingLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.083 24.001a2.1 2.1 0 0 1-1.451-.59c-.4-.39-.63-.934-.632-1.492V11.5C0 9.019 2.019 7 4.5 7h15c2.481 0 4.5 2.019 4.5 4.5l-.001 10.38a2.15 2.15 0 0 1-.632 1.506 2.05 2.05 0 0 1-1.512.614h-.242c-1.452 0-1.591-.252-2.85-2.542-.428-.778-1.002-1.822-1.818-3.224A2.51 2.51 0 0 0 14.789 17H9.21a2.52 2.52 0 0 0-2.162 1.244 151 151 0 0 0-1.819 3.203c-1.28 2.307-1.416 2.551-2.782 2.551h-.005L2.133 24zM4.5 8C2.57 8 1 9.57 1 11.5v10.418c0 .29.121.573.329.775s.502.31.785.307l.33-.002c.785-.015.782.001 1.912-2.036.433-.78 1.012-1.824 1.83-3.222A3.51 3.51 0 0 1 9.209 16h5.58c1.237 0 2.395.663 3.02 1.729a138 138 0 0 1 1.832 3.247c1.114 2.026 1.123 2.02 1.999 2.023h.234A1.13 1.13 0 0 0 23 21.877V11.5C23 9.57 21.43 8 19.5 8zm19 3.5h.01zm-16 .499H6v-1.5a.5.5 0 0 0-1 0v1.5H3.501a.5.5 0 0 0 0 1H5v1.5a.5.5 0 0 0 1 0v-1.5h1.5a.5.5 0 0 0 0-1m5-6.499V2.999A3.015 3.015 0 0 0 9.5 0h-3a.5.5 0 0 0 0 1h2.999A2.01 2.01 0 0 1 11.5 3v2.5a.5.5 0 1 0 1 0m5.5 8.25a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0m2.5-2.5a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0"/></svg>)}
    />
  );
}
  