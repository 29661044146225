// @flow
import React, { type Element, useEffect, useMemo, useState } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { CancelToken } from 'axios';
import classnames from 'classnames';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { useTranslation } from 'react-i18next';
import Combobox from '@design-system/component-library/src/components/Combobox';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { getForwardingTargets } from '../../../../../../ducks/entities/directory/directoryOperations';
import { getSearchType, isSearhableType, isExternalNumber, parseResults } from './ForwardingUtils';
import Tooltip from '../../../../../../components/Tooltip';
import CenterHorizontally from '../../../../../../components/CenterHorizontally/CenterHorizontally';

import styles from './ForwardingField.module.scss';

const NO_TRANSFER = 'NO_TRANSFER';
const TRANSFER_INTERNAL = 'TRANSFER_INTERNAL';
const TRANSFER_EXTERNAL = 'TRANSFER_EXTERNAL';
const TRANSFER_ACD_CUSTOMER_SERVICE = 'TRANSFER_ACD_CUSTOMER_SERVICE';
const TRANSFER_EXTENSION_GROUP = 'TRANSFER_EXTENSION_GROUP';
const TRANSFER_PLAY_MUSIC = 'TRANSFER_PLAY_MUSIC';
const TRANSFER_WELCOME_ATTENDANT = 'TRANSFER_WELCOME_ATTENDANT';
const TRANSFER_OC = 'TRANSFER_OC';
const TRANSFER_ACD_SWITCHBOARD = 'TRANSFER_ACD_SWITCHBOARD';
const TRANSFER_SPEED_DIAL = 'TRANSFER_SPEED_DIAL';
const TRANSFER_ENT_VM = 'TRANSFER_ENT_VM';

export type PropsT = {|
  field: string,
  title: string,
  filterSearchResults?: string => boolean,
  description?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean,
  noEndTransfer?: boolean,
  containerStyle?: string,
  tooltip?: string
|};

let requestDestinationsCancelTokenSource;

export const ForwardingField = (props: PropsT): Element<'div' | typeof CenterHorizontally> => {
  const {
    field,
    title,
    description,
    filterSearchResults,
    noEndTransfer,
    containerStyle,
    tooltip
  } = props;
  const [searchResults, setSearchResults] = useState([]);
  const { t, i18n } = useTranslation();

  const activeLanguage = i18n.language;
  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  // redux
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();

  const transferOptions = useMemo(
    () => {
      const targets = !noEndTransfer
        ? [
            {
              value: NO_TRANSFER,
              label: t('callflows.forwardingField.noTransfer')
            }
          ]
        : [];
      return [
        ...targets,
        ...[
          {
            value: TRANSFER_INTERNAL,
            label: t('callflows.forwardingField.transferInternal')
          },
          {
            value: TRANSFER_EXTERNAL,
            label: t('callflows.forwardingField.transferExternal')
          },
          {
            value: TRANSFER_ACD_CUSTOMER_SERVICE,
            label: t('callflows.forwardingField.transferAcdCustomerService')
          },
          {
            value: TRANSFER_EXTENSION_GROUP,
            label: t('callflows.forwardingField.transferExtensionGroup')
          },
          {
            value: TRANSFER_PLAY_MUSIC,
            label: t('callflows.forwardingField.transferPlayMusic')
          },
          {
            value: TRANSFER_OC,
            label: t('callflows.forwardingField.transferOC')
          },
          {
            value: TRANSFER_WELCOME_ATTENDANT,
            label: t('callflows.forwardingField.transferWelcomeAttendant')
          },
          {
            value: TRANSFER_ACD_SWITCHBOARD,
            label: t('callflows.forwardingField.transferAcdSwitchboard')
          },
          {
            value: TRANSFER_SPEED_DIAL,
            label: t('callflows.forwardingField.transferSpeedDial')
          },
          {
            value: TRANSFER_ENT_VM,
            label: t('callflows.forwardingField.transferEntVM')
          }
        ]
      ];
    },
    [activeLanguage] // eslint-disable-line
  );

  const addCurrentSelectedValueToSearchResults = async filteredResults => {
    const currentSelectedValue = filteredResults.find(
      result => result.value === values[field].value
    );

    if (!currentSelectedValue && values[field].value) {
      filteredResults.push({ label: values[field].text, value: values[field].value });
    }

    setSearchResults(filteredResults);
  };

  const fetchDirectories = async searchText => {
    if (isSearhableType(values[field].type) && currentUser.currentEnterprise) {
      if (requestDestinationsCancelTokenSource) {
        requestDestinationsCancelTokenSource.cancel();
      }
      requestDestinationsCancelTokenSource = CancelToken.source();
      setShowLoadingSpinner(true);
      const results = await dispatch(
        getForwardingTargets(
          currentUser.currentEnterprise.id,
          requestDestinationsCancelTokenSource.token,
          {
            type: getSearchType(values[field].type),
            search: searchText
          }
        )
      );
      if (results) {
        const filteredResults = parseResults(results, values[field].type, t).filter(result =>
          filterSearchResults ? filterSearchResults(result.addressNumber) : true
        );
        addCurrentSelectedValueToSearchResults(filteredResults);
        setShowLoadingSpinner(false);
      }
    }
  };

  const currentTransferType =
    transferOptions.find(o => o.value === values[field].type) || transferOptions[0];

  useEffect(() => {
    requestDestinationsCancelTokenSource = CancelToken.source();
    const fetchTargets = async () => {
      if (isSearhableType(values[field].type)) {
        if (values[field].value) {
          await fetchDirectories(values[field].value);
        }
      }
    };
    fetchTargets();
    return () => {
      requestDestinationsCancelTokenSource.cancel();
    };
  }, [currentTransferType.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const descriptionMsg: string =
    description === undefined ? t('callflows.forwardingField.description') : description;

  const numberInputField = (
    <div className={styles.input}>
      <div className={styles['input-wrapper']} data-cy={`input-field-${field}`}>
        <Input
          id="acd-queue-size-input"
          data-cy="input"
          className={styles['forwarding-selection']}
          onValueChange={event => {
            setValue(
              field,
              {
                ...values[field],
                value: event.target.value
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            );
          }}
          defaultValue={values[field].value}
          touched
          i18n_input_errorMessage={errors[field] && (errors[field].message || 'error')}
          placeholder={t('callflows.forwardingField.longNumberPlaceholder')}
        />
      </div>
    </div>
  );

  const searchableField = (
    <div className={styles['input-wrapper']}>
      {showLoadingSpinner && <LoadingSpinner className={styles['loading-spinner']} />}
      <Combobox
        id="forwarding_search_internal"
        className={styles['search-area']}
        items={searchResults}
        selectedValue={values[field].value}
        i18n_combobox_helpText={t('callflows.acdForwardingField.transferInternalInfo')}
        onOptionsShow={() => {
          fetchDirectories('');
        }}
        onValueChange={value => {
          setValue(
            field,
            {
              ...values[field],
              value: ''
            },
            {
              shouldValidate: true,
              shouldDirty: true
            }
          );
          fetchDirectories(value);
        }}
        i18n_combobox_errorMessage={
          errors[field] ? t('callflows.acdForwardingField.transferInternalError') : ''
        }
        onValueSelect={element => {
          setValue(
            field,
            {
              ...values[field],
              value: element.dataset.value,
              text: element.innerText
            },
            {
              shouldValidate: true,
              shouldDirty: true
            }
          );
        }}
      />
    </div>
  );
  return (
    <div
      className={classnames(styles['field-group-section'], containerStyle)}
      data-cy={`forwarding-field-${field}`}
    >
      <div className={styles['field-section']}>
        <div className={styles['title-area']}>
          <div className={styles.title}>{title}</div>
          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </div>
        {descriptionMsg && <div className={styles.description}>{descriptionMsg}</div>}
      </div>
      <div className={styles.columns}>
        <div>
          {t('callflows.forwardingField.transferCalls')}
          <Dropdown
            className={styles['forwarding-selection']}
            label=""
            items={transferOptions}
            selectedValue={currentTransferType.value}
            onValueChange={option => {
              setSearchResults([]);
              setValue(
                field,
                {
                  ...values[field],
                  type: option.dataset.value,
                  value: '',
                  text: ''
                },
                {
                  shouldValidate: true,
                  shouldDirty: true
                }
              );
            }}
          />
        </div>
        {isExternalNumber(values[field].type) && numberInputField}
        {((noEndTransfer && values[field].type === 'TRANSFER_INTERNAL') ||
          isSearhableType(values[field].type)) &&
          searchableField}
      </div>
    </div>
  );
};

export default ForwardingField;
