import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPhoneFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.1 21.2-.8.8c-1.1 1.1-2.6 1.7-4.2 1.7-1.1 0-2.2-.3-3.2-.9C9.5 19.2 4.9 14.5 1.2 9-.3 6.7 0 3.6 2 1.7l.8-.8c.8-.8 2.2-.8 3 0l3.3 3.3q.6.6.6 1.5c0 .9-.2 1.1-.6 1.5-.6.6-.6 1.7 0 2.3l5.3 5.3c.3.3.7.5 1.2.5.4 0 .8-.2 1.1-.5.8-.8 2.2-.8 3 0l3.3 3.3c.9.9 1 2.3.1 3.1"/></svg>)}
    />
  );
}
  