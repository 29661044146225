import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 3.75H2.25A2.25 2.25 0 0 0 0 6v12a2.25 2.25 0 0 0 2.25 2.25h19.5A2.25 2.25 0 0 0 24 18V6a2.25 2.25 0 0 0-2.25-2.25m-.47 1.5-8.51 7.22a1.21 1.21 0 0 1-1.54 0L2.72 5.25zm.47 13.5H2.25A.76.76 0 0 1 1.5 18V6.19l8.75 7.44a2.7 2.7 0 0 0 3.44 0l8.75-7.44V18a.76.76 0 0 1-.69.75"/></svg>)}
    />
  );
}
  