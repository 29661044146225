// @flow
import React, { type Element, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ErrorPageBase from '../error/ErrorPageBase';
import ActionButton from '../../components/Button/ActionButton';
import LanguageSelector from './LanguageSelector';
import {
  goToEnglishInstructionsPage,
  goToFinnishInstructionsPage,
  goToSwedishInstructionsPage,
  goToEstonianInstructionsPage
} from '../../navigationOperations';

import styles from './MissingRoleAccessRightErrorPage.module.scss';

export type PropsT = {||};

// eslint-disable-next-line no-unused-vars
const MissingRoleAccessRightErrorPage = (props: PropsT): Element<typeof ErrorPageBase> => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = i18n.language;
  const REDIRECT_TO_INFO_PAGE_TIMEOUT = 5000;

  const redirectToInfoPage = () => {
    if (activeLanguage === 'en') {
      dispatch(goToEnglishInstructionsPage());
    } else if (activeLanguage === 'sv') {
      dispatch(goToSwedishInstructionsPage());
    } else if (activeLanguage === 'et') {
      dispatch(goToEstonianInstructionsPage());
    } else {
      dispatch(goToFinnishInstructionsPage());
    }
  };

  useEffect(() => {
    setTimeout(() => {
      redirectToInfoPage();
    }, REDIRECT_TO_INFO_PAGE_TIMEOUT);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorPageBase>
      <div className={styles.language}>
        <LanguageSelector />
      </div>
      <h1>{t('missingRoleAccessRightErrorPage.title')}</h1>
      <h2 className={styles.description}>{t('missingRoleAccessRightErrorPage.description')}</h2>
      <ActionButton
        id="login-as-normal-user"
        label={t('missingRoleAccessRightErrorPage.infoLinkText')}
        className={styles.button}
        onClickAction={e => {
          e.preventDefault();
          redirectToInfoPage();
        }}
      />
    </ErrorPageBase>
  );
};

export default MissingRoleAccessRightErrorPage;
