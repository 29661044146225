// @flow strict-local

import React, { type Element } from 'react';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useFormContext } from 'react-hook-form';
import type { ExactPropsT } from '../../../../commonTypes';
import type { UserListElementT } from '../../../../ducks/entities/user/userTypes';
import type { FieldT } from '../../../../ducks/entities/callForwarding/callForwardingTypes';
import styles from './SubstituteSelection.module.scss';

type OwnPropsT = {|
  field: string,
  substitutes: UserListElementT[]
|};

type StatePropsT = {||};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;
/* eslint-disable react/no-array-index-key */
export const SubstituteSelection = ({ field, substitutes }: PropsT): Element<'div'> => {
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const value: FieldT = values[field];

  return (
    <div key="substitute-options" className={styles.substitutes}>
      <RadioGroup value={value[field].target.value}>
        {substitutes &&
          substitutes.map((sub, index) => (
            <Radio
              id={`substitute-option-${index}`}
              name="substitute-option"
              key={`substitute-option-${index}`}
              onChange={() =>
                setValue(
                  field,
                  {
                    type: 'INTERNAL',
                    value: sub.addressNumber
                  },
                  {
                    shouldValidate: true,
                    shouldDirty: true
                  }
                )
              }
              className={styles.radio}
              label={sub.userName}
              value={sub.addressNumber}
            />
          ))}
      </RadioGroup>
    </div>
  );
};

export default SubstituteSelection;
