import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOmaguruFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.703 1.695A11.2 11.2 0 0 0 12.775 0h-.036a11.13 11.13 0 0 0-7.914 3.274 11.15 11.15 0 0 0-3.293 7.927 11.2 11.2 0 0 0 1.549 5.708L.048 23.285a.5.5 0 0 0 .667.666l6.368-3.036a11.15 11.15 0 0 0 8.239 1.259c2.92-.682 5.4-2.46 6.983-5.008 3.269-5.259 1.653-12.199-3.602-15.471M12.88 16.62a1 1 0 1 1 0-2 1 1 0 0 1 0 2m-.12-3.49h-.01a.63.63 0 1 1 0-1.25 2.381 2.381 0 0 0 .02-4.76 2.38 2.38 0 0 0-2.39 2.37v.02a.63.63 0 1 1-1.26 0 3.63 3.63 0 1 1 3.64 3.62"/></svg>)}
    />
  );
}
  