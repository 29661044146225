// @flow

import { CancelToken, CancelTokenSource } from 'axios';
import isEqual from 'lodash/isEqual';
import { type ContextRouter, type Location, withRouter, type Match } from 'react-router-dom';
import queryString from 'querystring';
import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { ExactPropsT, StoreStateT } from '../../../commonTypes';
import type { RetrieveFnT } from '../../../ducks/entities/user/index';
import type {
  UserEntityT,
  InternalUserStateEntityT,
  UserStateEntityT,
  UserStateEntityCollectionT,
  InternalUserEntityT
} from '../../../ducks/entities/user/userTypes';
import { goToEnterpriseUsers } from '../../../navigationOperations';
import {
  operations as userOps,
  selectors as userSelect,
  selectors as userSelectors
} from '../../../ducks/entities/user/index';
import delayBooleanTransitionToTrue from '../../../components/delayBooleanTransitionToTrue';
import { removeUserConfig, toggleFavoriteUserConfig } from '../../../userConfigHelpers';
import InternalUserDetails from './Internal/InternalUserDetails';
import ExternalUserDetails from './ExternalUser/ExternalUserDetails';
import {
  operations as configOps,
  type ConfigStateT,
  type UserConfigT
} from '../../../ducks/config';
import BaseContainer from '../../BaseContainer/BaseContainer';
import Dialog from '../../../components/Dialog';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import type { CreateCreateNotificationActionFnT } from '../../../ducks/ui/notification/notificationUiActions';
import { hasError } from '../../../ducks/entities/user/userSelectors';
import type { DirectoryAvatarStateEntityT } from '../../../ducks/entities/directory/directoryTypes';
import EditInternalUser from './InternalUser/EditInternalUser';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import TopNavigation from '../../navigation/TopNavigation';
import { pushAnalyticsEvent } from '../../../utils/analyticsHandler';
import { ADD_FAVOURITE_USER, REMOVE_FAVOURITE_USER } from '../../../matomo_constants';
import styles from '../Users.module.scss';

type OwnPropsT = {
  onClose: (*) => *,
  showFixedTitle: boolean,
  displayError?: boolean,
  userId?: string,
  match: Match,
  enterpriseId?: string
};

type StatePropsT = {
  currentUser: CurrentUserStateT,
  showLoadingSpinner: boolean,
  user: UserStateEntityT,
  userConfig: $PropertyType<ConfigStateT, 'userConfig'>,
  location: Location,
  usersById: UserStateEntityCollectionT,
  directoryAvatar: ?DirectoryAvatarStateEntityT
};

type DispatchPropsT = {
  retrieve: RetrieveFnT,
  update: (UserEntityT, CancelToken, {}) => *,
  updateUserConfig: typeof configOps.updateUserConfig,
  fetchUserConfig: typeof configOps.getUserConfig,
  removeExternalUser: typeof userOps.remove,
  goToUsers: () => void,
  notify: CreateCreateNotificationActionFnT,
  addRecentUserToConfig: typeof configOps.addRecentUserToConfig,
  retrieveAvatar: typeof userOps.retrieveAvatar
};

export type PropsT = {|
  ...ExactPropsT<OwnPropsT, StatePropsT, DispatchPropsT>,
  ...$Exact<ContextRouter>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {
  user: UserStateEntityT,
  showLoadingError: boolean,
  deleteDialogOpen: boolean,
  deleteUserFullName: string,
  deleting: boolean
};

export class UserDetails extends Component<PropsT, StateT> {
  static generateKeys(array: ?(string[]), prefix: string): * {
    return array
      ? array.reduce((accumulator: {}, currentValue: string, index: number): * => {
          accumulator[`${prefix}_${index}`] = currentValue; // eslint-disable-line no-param-reassign
          return accumulator;
        }, {})
      : {};
  }

  static defaultUser: InternalUserStateEntityT = {
    id: '',
    personId: '',
    enterpriseId: '',
    __metadata: {},
    userType: 'internalUser',
    locked: false,
    isAcdAgent: false
  };

  constructor(props: PropsT) {
    super(props);
    const { user } = props;
    this.state = {
      user: user || UserDetails.defaultUser,
      showLoadingError: false,
      deleteDialogOpen: false,
      deleteUserFullName: '',
      deleting: false
    };
    this.handleOnToggleFavourite = this.handleOnToggleFavourite.bind(this);
    this.removeExternalContactRequestCancelTokenSource = CancelToken.source();
    this.avatarCancelTokenSource = CancelToken.source();

    this.loadUser = this.loadUser.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.removeExternalUser = this.removeExternalUser.bind(this);
    this.confirmRemoveExternalUser = this.confirmRemoveExternalUser.bind(this);
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
    this.renderDeleteDialog = this.renderDeleteDialog.bind(this);
    this.renderUserDetails = this.renderUserDetails.bind(this);
    this.loadAvatar = this.loadAvatar.bind(this);
    this.singleUserRequestCancelTokenSource = CancelToken.source();
  }

  componentDidMount() {
    this.loadUser();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: PropsT) {
    const { user: nextPropsUser } = nextProps;
    const newUser = nextPropsUser || UserDetails.defaultUser;
    if (!isEqual(this.state.user, newUser)) {
      this.setState({ user: newUser });
    }
  }

  componentDidUpdate(oldProps: PropsT) {
    const {
      match: {
        params: { userId: currentUserId }
      }
    } = this.props;
    const {
      match: {
        params: { userId: oldUserId }
      }
    } = oldProps;

    if (currentUserId === oldUserId) {
      return;
    }
    this.loadUser();
  }

  removeExternalContactRequestCancelTokenSource: CancelTokenSource;

  avatarCancelTokenSource: CancelTokenSource;

  componentWillUnmount() {
    this.singleUserRequestCancelTokenSource.cancel();
    this.removeExternalContactRequestCancelTokenSource.cancel();
    this.avatarCancelTokenSource.cancel();
  }

  singleUserRequestCancelTokenSource: CancelTokenSource;

  loadAvatar: *;

  async loadAvatar() {
    const {
      match: {
        params: { id: currentEnterpriseId }
      },
      user,
      directoryAvatar,
      retrieveAvatar
    } = this.props;

    let isAvatarCustomized;
    if (!directoryAvatar) {
      if (currentEnterpriseId) {
        const avatars = await retrieveAvatar(
          currentEnterpriseId,
          user.personId,
          this.avatarCancelTokenSource.token
        );
        if (avatars && avatars.count > 0) {
          isAvatarCustomized = avatars.results[0].isAvatarCustomized;
        }
      }
    } else {
      isAvatarCustomized = directoryAvatar.isAvatarCustomized;
    }
    return isAvatarCustomized;
  }

  loadUser: *;

  async loadUser(showError?: boolean) {
    const {
      match: {
        params: { id: currentEnterpriseId, userId: currentUserId }
      },
      location: { search },
      displayError,
      retrieve,
      history,
      addRecentUserToConfig,
      user,
      fetchUserConfig,
      currentUser
    } = this.props;
    // $FlowFixMe
    const config: ?UserConfigT = await fetchUserConfig();
    this.setState({ showLoadingError: false });
    if (currentEnterpriseId && currentUserId) {
      const params = queryString.parse(search.slice(1));
      const userReceived = await retrieve(
        currentEnterpriseId,
        currentUserId,
        config,
        this.singleUserRequestCancelTokenSource.token,
        {
          type: params.type
        }
      );
      if (!userReceived && hasError(user)) {
        if (displayError || showError) {
          this.setState({ showLoadingError: true });
        } else {
          history.push(`/enterprises/${currentEnterpriseId}/users`);
        }
      } else if (userReceived) {
        const isAvatarCustomized = await this.loadAvatar();
        addRecentUserToConfig(
          {
            ...userReceived,
            urlPhoto: isAvatarCustomized ? userReceived.urlPhoto : null,
            enterpriseId: currentEnterpriseId
          },
          config,
          createCsrfHeader(currentUser)
        );
      }
    }
  }

  handleOnToggleFavourite: *;

  async handleOnToggleFavourite(selected: boolean): * {
    const {
      updateUserConfig,
      userConfig,
      user,
      currentUser,
      match: {
        params: { id: enterpriseId }
      }
    } = this.props;
    pushAnalyticsEvent(selected ? ADD_FAVOURITE_USER : REMOVE_FAVOURITE_USER, enterpriseId);
    updateUserConfig(
      toggleFavoriteUserConfig(
        userConfig,
        // $FlowFixMe
        { ...user, urlPhoto: null },
        selected
      ),
      createCsrfHeader(currentUser)
    );
  }

  // eslint-disable-next-line class-methods-use-this
  convertInternalUserForEdit(user: *): InternalUserEntityT {
    const convertedUser = user;
    convertedUser.tagNames =
      !user.tagNames || (user.tagNames.length === 1 && !user.tagNames[0]) ? [] : user.tagNames;
    convertedUser.departmentId = user.departmentId === 'empty' ? '' : user.departmentId;
    return convertedUser;
  }

  saveUser: *;

  async saveUser(user: UserEntityT): Promise<*> {
    const { update, currentUser, notify, t } = this.props; // eslint-disable-line no-shadow
    const response = await update(
      user && user.userType === 'internalUser' ? this.convertInternalUserForEdit(user) : user,
      CancelToken.source().token,
      createCsrfHeader(currentUser)
    );
    if (response !== undefined) {
      notify({
        tag: 'update-user-success',
        duration: 15000,
        type: 'info',
        message:
          user && user.userType === 'internalUser'
            ? t('editInternalUser.updateSuccess')
            : t('editExternalUser.updateSuccess')
      });
    } else {
      notify({
        tag: 'update-user-failure',
        duration: 15000,
        type: 'error',
        message:
          user && user.userType === 'internalUser'
            ? t('editInternalUser.updateFailure')
            : t('editExternalUser.updateFailure')
      });
    }
    return response;
  }

  confirmRemoveExternalUser: string => void;

  confirmRemoveExternalUser(deleteUserFullName: string) {
    this.setState({ deleteDialogOpen: true, deleteUserFullName });
  }

  closeConfirmDialog: () => void;

  closeConfirmDialog() {
    this.setState({ deleteDialogOpen: false });
  }

  removeExternalUser: () => void;

  async removeExternalUser() {
    const {
      removeExternalUser,
      match: {
        params: { id: enterpriseId, userId: selectedUserId }
      },
      notify,
      t,
      goToUsers,
      updateUserConfig,
      userConfig,
      currentUser
    } = this.props;
    const { deleteUserFullName } = this.state;
    if (enterpriseId && selectedUserId) {
      const userId = selectedUserId;

      this.setState({ deleting: true });

      await removeExternalUser(
        enterpriseId,
        userId,
        {
          type: 'externalUser'
        },
        this.removeExternalContactRequestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      );

      updateUserConfig(
        removeUserConfig(userConfig, enterpriseId, userId),
        createCsrfHeader(currentUser)
      );

      this.closeConfirmDialog();
      if (
        selectedUserId &&
        !userSelect.hasDeleteErrorOnUserId(this.props.usersById, selectedUserId)
      ) {
        goToUsers();
        notify({
          tag: 'external-user-deleted',
          duration: 15000,
          type: 'info',
          message: t('externalUsers.deleteNotification.successNotification', {
            externalContactName: deleteUserFullName
          })
        });
        this.setState({ deleting: false });
      } else if (selectedUserId) this.setState({ deleting: false });
    }
  }

  renderDeleteDialog: () => Element<typeof Dialog>;

  renderDeleteDialog() {
    const { t } = this.props;
    const { deleteUserFullName, deleting } = this.state;
    return (
      <Dialog
        title={t('externalUsers.deleteDialog.title')}
        description={t('externalUsers.deleteDialog.description', {
          externalContactName: deleteUserFullName
        })}
        confirmLabel={t('externalUsers.deleteDialog.confirm')}
        cancelLabel={t('externalUsers.deleteDialog.cancel')}
        disabled={false}
        loading={deleting}
        onCancel={this.closeConfirmDialog}
        onConfirm={this.removeExternalUser}
        onClose={this.closeConfirmDialog}
      />
    );
  }

  renderUserDetails: boolean => Element<'div'>;

  renderUserDetails(alwaysEdit: boolean) {
    const {
      user: { userType, id },
      onClose,
      showLoadingSpinner,
      showFixedTitle
    } = this.props;
    const { deleteDialogOpen, showLoadingError } = this.state;
    return (
      <div>
        {deleteDialogOpen && this.renderDeleteDialog()}
        {userType === 'internalUser' ? (
          alwaysEdit ? (
            <EditInternalUser
              onSave={this.saveUser}
              userId={id}
              onClose={onClose}
              showLoadingSpinner={showLoadingSpinner}
              showFixedTitle={showFixedTitle}
              onToggleFavourite={this.handleOnToggleFavourite}
              showLoadingError={showLoadingError}
              reloadFunc={() => this.loadUser(true)}
              alwaysEdit={alwaysEdit}
            />
          ) : (
            <InternalUserDetails
              onSave={this.saveUser}
              userId={id}
              onClose={onClose}
              showLoadingSpinner={showLoadingSpinner}
              showFixedTitle={showFixedTitle}
              onToggleFavourite={this.handleOnToggleFavourite}
              showLoadingError={showLoadingError}
              reloadFunc={() => this.loadUser(true)}
              alwaysEdit={alwaysEdit}
            />
          )
        ) : (
          <ExternalUserDetails
            onSave={this.saveUser}
            userId={id}
            onClose={onClose}
            showLoadingSpinner={showLoadingSpinner}
            onRemoveExternalUser={this.confirmRemoveExternalUser}
            showFixedTitle={showFixedTitle}
            onToggleFavourite={this.handleOnToggleFavourite}
            showLoadingError={showLoadingError}
            reloadFunc={() => this.loadUser(true)}
            alwaysEdit={alwaysEdit}
          />
        )}
      </div>
    );
  }

  render(): Element<*> {
    const { userId } = this.props;
    const {
      user: { id }
    } = this.state;
    if (id) {
      return !userId ? (
        <BaseContainer header={<TopNavigation />}>
          <div className={classNames(styles.container)}>{this.renderUserDetails(true)}</div>
        </BaseContainer>
      ) : (
        this.renderUserDetails(false)
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: PropsT) => {
  const { userId } = ownProps;
  const {
    entities: { user }
  } = state;
  const currentUserId = userId || ownProps.match.params.userId;
  return {
    currentUser: state.currentUser,
    showLoadingSpinner: userSelectors.isLoading(state, currentUserId || ''),
    user: state.entities.user.byId[currentUserId],
    directoryAvatar: state.entities.directory.avatarById[`IUser-${user.personId}`],
    userConfig: state.config.userConfig,
    location: state.router.location,
    usersById: user.byId
  };
};

const mapDispatchToProps = (dispatch, props: PropsT) =>
  bindActionCreators(
    {
      retrieve: userOps.retrieve,
      update: userOps.update,
      updateUserConfig: configOps.updateUserConfig,
      removeExternalUser: userOps.remove,
      fetchUserConfig: configOps.getUserConfig,
      goToUsers: goToEnterpriseUsers.bind(null, props.enterpriseId || props.match.params.id || ''),
      notify: notificationActions.createCreateNotificationAction,
      addRecentUserToConfig: configOps.addRecentUserToConfig,
      retrieveAvatar: userOps.retrieveAvatar
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<$Diff<PropsT, ContextRouter>, PropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter,
  delayBooleanTransitionToTrue({ delay: 500, propName: 'showLoadingSpinner' })
)(UserDetails);
