// @flow

import type { $AxiosError } from 'axios';
import type {
  RetrieveCallForwardingRequestT,
  RetrieveCallForwardingSuccessT,
  RetrieveCallForwardingFailureT,
  RetrieveCallForwardingCancelT,
  UpdateCallForwardingSuccessT,
  UpdateCallForwardingRequestT,
  UpdateCallForwardingFailureT,
  UpdateCallForwardingCancelT,
  DeleteCallForwardingFailureT,
  DeleteCallForwardingRequestT,
  DeleteCallForwardingSuccessT,
  DeleteCallForwardingCancelT,
  CreateCallForwardingFailureT,
  CreateCallForwardingCancelT,
  CreateCallForwardingRequestT,
  CreateCallForwardingSuccessT,
  RetrieveUserForwardingsRequestT,
  RetrieveUserForwardingsFailureT,
  RetrieveUserForwardingsSuccessT,
  CallForwardingT,
  DeleteCallForwardingsCancelT,
  DeleteCallForwardingsFailureT,
  DeleteCallForwardingsSuccessT,
  DeleteCallForwardingsRequestT,
  UpdateCallForwardingsSuccessT,
  UpdateCallForwardingsRequestT,
  UpdateCallForwardingsFailureT,
  UpdateCallForwardingsCancelT
} from './callForwardingTypes';

type CreateRetrieveCallForwardingRequestFnT = (string, string) => RetrieveCallForwardingRequestT;
export const createRetrieveCallForwardingRequest: CreateRetrieveCallForwardingRequestFnT = (
  enterpriseId,
  callForwardingId
) => ({
  type: 'ring/entity/callForwarding/RETRIEVE_REQUEST',
  error: false,
  meta: { enterpriseId, callForwardingId }
});

type CreateRetrieveCallForwardingSuccessFnT = (
  string,
  string,
  CallForwardingT
) => RetrieveCallForwardingSuccessT;

export const createRetrieveCallForwardingSuccess: CreateRetrieveCallForwardingSuccessFnT = (
  enterpriseId,
  callForwardingId,
  payload
) => ({
  type: 'ring/entity/callForwarding/RETRIEVE_SUCCESS',
  error: false,
  meta: {
    enterpriseId,
    callForwardingId
  },
  payload
});

type CreateRetrieveCallForwardingFailureFnT = (
  string,
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => RetrieveCallForwardingFailureT;

export const createRetrieveCallForwardingFailure: CreateRetrieveCallForwardingFailureFnT = (
  enterpriseId,
  callForwardingId,
  error
) => ({
  type: 'ring/entity/callForwarding/RETRIEVE_FAILURE',
  error: true,
  meta: { enterpriseId, callForwardingId },
  payload: error
});

type CreateRetrieveCallForwardingCancelFnT = (string, string) => RetrieveCallForwardingCancelT;
export const createRetrieveCallForwardingCancel: CreateRetrieveCallForwardingCancelFnT = (
  enterpriseId,
  callForwardingId
) => ({
  type: 'ring/entity/callForwarding/RETRIEVE_CANCEL',
  error: false,
  meta: { enterpriseId, callForwardingId },
  payload: {}
});

type CreateRetrieveUserForwardingsRequestFnT = (
  enterpriseId: string,
  addressNumber: string
) => RetrieveUserForwardingsRequestT;
export const createRetrieveUserForwardingsRequest: CreateRetrieveUserForwardingsRequestFnT = (
  enterpriseId,
  addressNumber
) => ({
  type: 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_REQUEST',
  payload: {
    enterpriseId,
    addressNumber
  },
  error: false,
  meta: { enterpriseId, addressNumber }
});

export const createRetrieveUserForwardingsSuccess = (
  enterpriseId: string,
  addressNumber: string,
  forwardings: CallForwardingT[]
): RetrieveUserForwardingsSuccessT => ({
  type: 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_SUCCESS',
  error: false,
  meta: { enterpriseId, addressNumber },
  payload: forwardings
});

export const createRetrieveUserForwardingsFailure = (
  enterpriseId: string,
  addressNumber: string,
  error: $AxiosError<*>
): RetrieveUserForwardingsFailureT => ({
  type: 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_FAILURE',
  error: true,
  meta: { enterpriseId, addressNumber },
  payload: error
});

type CreateUpdateCallForwardingRequestFnT = string => UpdateCallForwardingRequestT;
export const createUpdateCallForwardingRequest: CreateUpdateCallForwardingRequestFnT = id => ({
  type: 'ring/entity/callForwarding/UPDATE_REQUEST',
  error: false,
  meta: { id }
});

type CreateUpdateCallForwardingSuccessFnT = (
  string,
  CallForwardingT
) => UpdateCallForwardingSuccessT;
export const createUpdateCallForwardingSuccess: CreateUpdateCallForwardingSuccessFnT = (
  enterpriseId,
  callForwarding
) => ({
  type: 'ring/entity/callForwarding/UPDATE_SUCCESS',
  payload: { ...callForwarding },
  error: false,
  meta: { enterpriseId, callForwardingId: callForwarding.id }
});

type CreateUpdateCallForwardingFailureFnT = (
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => UpdateCallForwardingFailureT;
export const createUpdateCallForwardingFailure: CreateUpdateCallForwardingFailureFnT = (
  id,
  error
) => ({
  type: 'ring/entity/callForwarding/UPDATE_FAILURE',
  payload: error,
  error: true,
  meta: { id }
});

type CreateUpdateCallForwardingCancelFnT = (string, string) => UpdateCallForwardingCancelT;
export const createUpdateCallForwardingCancel: CreateUpdateCallForwardingCancelFnT = (
  enterpriseId,
  callForwardingId
) => ({
  type: 'ring/entity/callForwarding/UPDATE_CANCEL',
  payload: {},
  error: false,
  meta: { enterpriseId, callForwardingId }
});

type CreateUpdateCallForwardingsRequestFnT = (string[]) => UpdateCallForwardingsRequestT;
export const createUpdateCallForwardingsRequest: CreateUpdateCallForwardingsRequestFnT = callForwardingIds => ({
  type: 'ring/entity/callForwardings/UPDATE_REQUEST',
  error: false,
  meta: { callForwardingIds }
});

type CreateUpdateCallForwardingsSuccessFnT = (
  string,
  string[],
  CallForwardingT
) => UpdateCallForwardingsSuccessT;
export const createUpdateCallForwardingsSuccess: CreateUpdateCallForwardingsSuccessFnT = (
  enterpriseId,
  callForwardingIds,
  callForwarding
) => ({
  type: 'ring/entity/callForwardings/UPDATE_SUCCESS',
  payload: callForwarding,
  error: false,
  meta: { enterpriseId, callForwardingIds }
});

type CreateUpdateCallForwardingsFailureFnT = (
  string[],
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => UpdateCallForwardingsFailureT;
export const createUpdateCallForwardingsFailure: CreateUpdateCallForwardingsFailureFnT = (
  callForwardingIds,
  error
) => ({
  type: 'ring/entity/callForwardings/UPDATE_FAILURE',
  payload: error,
  error: true,
  meta: { callForwardingIds }
});

type CreateUpdateCallForwardingsCancelFnT = (string, string[]) => UpdateCallForwardingsCancelT;
export const createUpdateCallForwardingsCancel: CreateUpdateCallForwardingsCancelFnT = (
  enterpriseId,
  callForwardingIds
) => ({
  type: 'ring/entity/callForwardings/UPDATE_CANCEL',
  payload: {},
  error: false,
  meta: { enterpriseId, callForwardingIds }
});

type CreateCreateCallForwardingRequestFnT = () => CreateCallForwardingRequestT;
export const createCreateCallForwardingRequest: CreateCreateCallForwardingRequestFnT = () => ({
  type: 'ring/entity/callForwarding/CREATE_REQUEST',
  error: false,
  meta: {}
});

type CreateCreateCallForwardingSuccessFnT = (
  string,
  CallForwardingT
) => CreateCallForwardingSuccessT;
export const createCreateCallForwardingSuccess: CreateCreateCallForwardingSuccessFnT = (
  enterpriseId,
  callForwarding
) => ({
  type: 'ring/entity/callForwarding/CREATE_SUCCESS',
  error: false,
  payload: callForwarding,
  meta: { enterpriseId }
});

// $FlowFixMe deprecated-type
type CreateCreateCallForwardingFailureFnT = ($AxiosError<*>) => CreateCallForwardingFailureT;
export const createCreateCallForwardingFailure: CreateCreateCallForwardingFailureFnT = error => ({
  type: 'ring/entity/callForwarding/CREATE_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

type CreateCreateCallForwardingCancelFnT = () => CreateCallForwardingCancelT;
export const createCreateCallForwardingCancel: CreateCreateCallForwardingCancelFnT = () => ({
  type: 'ring/entity/callForwarding/CREATE_CANCEL',
  payload: {},
  error: false,
  meta: {}
});

type CreateDeleteCallForwardingRequestFnT = () => DeleteCallForwardingRequestT;
export const createDeleteCallForwardingRequest: CreateDeleteCallForwardingRequestFnT = () => ({
  type: 'ring/entity/callForwarding/DELETE_REQUEST',
  error: false,
  meta: {}
});

type CreateDeleteCallForwardingSuccessFnT = (string, string) => DeleteCallForwardingSuccessT;
export const createDeleteCallForwardingSuccess: CreateDeleteCallForwardingSuccessFnT = (
  enterpriseId,
  callForwardingId
) => ({
  type: 'ring/entity/callForwarding/DELETE_SUCCESS',
  error: false,
  meta: {},
  payload: { enterpriseId, callForwardingId }
});

type CreateDeleteCallForwardingFailureFnT = (
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => DeleteCallForwardingFailureT;
export const createDeleteCallForwardingFailure: CreateDeleteCallForwardingFailureFnT = (
  callForwardingId,
  error
) => ({
  type: 'ring/entity/callForwarding/DELETE_FAILURE',
  payload: error,
  error: true,
  meta: { callForwardingId }
});

type CreateDeleteCallForwardingCancelFnT = () => DeleteCallForwardingCancelT;
export const createDeleteCallForwardingCancel: CreateDeleteCallForwardingCancelFnT = () => ({
  type: 'ring/entity/callForwarding/DELETE_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

type CreateDeleteCallForwardingsRequestFnT = () => DeleteCallForwardingsRequestT;
export const createDeleteCallForwardingsRequest: CreateDeleteCallForwardingsRequestFnT = () => ({
  type: 'ring/entity/callForwardings/DELETE_REQUEST',
  error: false,
  meta: {}
});

type CreateDeleteCallForwardingsSuccessFnT = (string, string[]) => DeleteCallForwardingsSuccessT;
export const createDeleteCallForwardingsSuccess: CreateDeleteCallForwardingsSuccessFnT = (
  enterpriseId,
  callForwardingIds
) => ({
  type: 'ring/entity/callForwardings/DELETE_SUCCESS',
  error: false,
  meta: {},
  payload: { enterpriseId, callForwardingIds }
});

type CreateDeleteCallForwardingsFailureFnT = (
  string[],
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => DeleteCallForwardingsFailureT;
export const createDeleteCallForwardingsFailure: CreateDeleteCallForwardingsFailureFnT = (
  callForwardingIds,
  error
) => ({
  type: 'ring/entity/callForwardings/DELETE_FAILURE',
  payload: error,
  error: true,
  meta: { callForwardingIds }
});

type CreateDeleteCallForwardingsCancelFnT = () => DeleteCallForwardingsCancelT;
export const createDeleteCallForwardingsCancel: CreateDeleteCallForwardingsCancelFnT = () => ({
  type: 'ring/entity/callForwardings/DELETE_CANCEL',
  error: false,
  meta: {},
  payload: {}
});
