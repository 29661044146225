import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSearchRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.281 6.48a9 9 0 0 0-11.8-4.761 8.997 8.997 0 0 0-4.762 11.8 8.997 8.997 0 0 0 11.8 4.762 9 9 0 0 0 2.293-1.409l5.908 5.908a.75.75 0 1 0 1.06-1.06l-5.908-5.909a9 9 0 0 0 1.41-9.33zM7.067 3.1a7.498 7.498 0 1 1 5.866 13.8 7.497 7.497 0 0 1-9.834-3.967 7.497 7.497 0 0 1 3.968-9.834z" clipRule="evenodd"/></svg>)}
    />
  );
}
  