// @flow
import type {
  RelatedNumberT,
  RelatedNumberTypesT
} from '../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import type { InternalAddressDestinationT } from '../../../../ducks/entities/acd/acdTypes';

export type PropsT = {|
  serviceId: string,
  name: string,
  addressNumber: string,
  type: string,
  linkedNumber: ?RelatedNumberT
|};

export const getTransferTargetIcon = (
  linkedNumberType: ?RelatedNumberTypesT,
  isExternal: ?boolean,
  transferType: ?InternalAddressDestinationT
) => {
  const normalIcons = {
    ACDGroupAddress: '/welcome_attendant_group.svg',
    GroupAddress: '/welcome_attendant_ext_group.svg',
    IVRService: '/welcome_attendant_transfer.svg',
    SpeedDial: '/welcome_attendant_speeddial.svg',
    Extension: '/welcome_attendant_agents.svg',
    FaxAddress: '/welcome_attendant_agents.svg',
    OcService: '/welcome_attendant_oc.svg'
  };

  const selectedIcons = {
    ACDGroupAddress: '/callflow_selected_group.svg',
    GroupAddress: '/welcome_attendant_selected_ext_group.svg',
    IVRService: '/callflow_selected_welcomeattendant.svg',
    SpeedDial: '/callflow_selected_speeddial.svg',
    Extension: '/callflow_selected_agents.svg',
    FaxAddress: '/callflow_selected_agents.svg',
    OcService: '/callflow_selected_oc.svg'
  };
  const icon = isExternal ? '/callflow_external.svg' : '/welcome_attendant_agents.svg';
  let image = linkedNumberType ? normalIcons[linkedNumberType] : icon;
  let selectedImage = linkedNumberType
    ? selectedIcons[linkedNumberType]
    : '/callflow_selected_agents.svg';
  if (transferType === 'SPEED_DIAL_OC_SERVICE') {
    image = '/welcome_attendant_oc.svg';
    selectedImage = '/callflow_selected_oc.svg';
  }
  return {
    image,
    selectedImage
  };
};

export const getTransferTargetTitle = (
  name: string,
  targetNumber: string,
  linkedNumber: ?RelatedNumberT,
  errorMsg: string
) => {
  let trimmedName = (name || '').substring(0, 13);
  if (trimmedName.length < (name || '').length) {
    trimmedName += '..';
  }
  if (trimmedName) {
    trimmedName += '\n';
  }
  const extensionNumber = targetNumber ? ` (${targetNumber})` : '';
  if (linkedNumber) {
    const labelToShow = linkedNumber.label && linkedNumber.label.replace(/^kutsu:/, '');
    const targetToShow = linkedNumber.userName ? linkedNumber.userName : labelToShow;
    return `${trimmedName}${targetToShow || ''}${extensionNumber}`;
  }
  return extensionNumber ? `${trimmedName}${extensionNumber}` : `${trimmedName}${errorMsg}`;
};
