import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSimRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.66 5.04 16.27.79A2.25 2.25 0 0 0 14.56 0H5a2.25 2.25 0 0 0-2.25 2.25v19.5A2.25 2.25 0 0 0 5 24h14a2.25 2.25 0 0 0 2.25-2.25V6.56a2.28 2.28 0 0 0-.59-1.52m-.91 16.71a.76.76 0 0 1-.75.75H5a.76.76 0 0 1-.75-.75V2.25A.76.76 0 0 1 5 1.5h2.75v3.25a.75.75 0 0 0 1.5 0V1.5h2.5v3.25a.75.75 0 0 0 1.5 0V1.5h1.31a.81.81 0 0 1 .62.32l4.39 4.25c.119.135.183.31.18.49z"/><path d="M14.5 11.5h-6a2.75 2.75 0 0 0-2.75 2.75v4A2.75 2.75 0 0 0 8.5 21h6a2.75 2.75 0 0 0 2.75-2.75v-4a2.75 2.75 0 0 0-2.75-2.75m1.25 2.75v1.25h-1.5V13h.25c.69 0 1.25.56 1.25 1.25M10.25 13h2.5v6.5h-2.5zM8.5 13h.25v2.5h-1.5v-1.25c0-.69.56-1.25 1.25-1.25m-1.25 5.25V17h1.5v2.5H8.5c-.69 0-1.25-.56-1.25-1.25m7.25 1.25h-.25V17h1.5v1.25c0 .69-.56 1.25-1.25 1.25"/></svg>)}
    />
  );
}
  