import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMaintenanceLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.21 3.396a.5.5 0 0 0-.79-.11l-3.28 3.281h-1.7V4.856l3.27-3.281a.48.48 0 0 0 .14-.42.49.49 0 0 0-.25-.37 6.44 6.44 0 0 0-9.241 7.533L.897 18.771a3.064 3.064 0 0 0 4.331 4.332l10.451-10.454a6.441 6.441 0 0 0 7.531-9.253m-.53 4.932a5.43 5.43 0 0 1-7.001 3.271.5.5 0 0 0-.52.12L4.517 22.403a2.1 2.1 0 0 1-2.91 0 2.063 2.063 0 0 1-.007-2.915l.007-.007L12.278 8.808a.5.5 0 0 0 .12-.52 5.442 5.442 0 0 1 7.12-6.912l-2.94 2.921a.5.5 0 0 0-.14.35v2.421a.5.5 0 0 0 .5.5h2.41a.5.5 0 0 0 .35-.15l2.92-2.921a5.38 5.38 0 0 1 .062 3.831"/></svg>)}
    />
  );
}
  