// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import Toggle from '@design-system/component-library/src/components/Toggle';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import uuid from 'uuid/v4';
import type { ExactPropsT } from '../../commonTypes';
import Dismiss from '../Button/Dismiss';
import ActionButton from '../Button/ActionButton';
import CancelButton from '../Button/CancelButton';
import 'react-datepicker/dist/react-datepicker.css';
import TimeRangePicker from './TimeRangePicker';
import type { AbsoluteTimeSlotEntityT } from '../../ducks/entities/calendar/calendarTypes';
import { removeContainedAndMergeOverlappingAbsoluteTimeSlots } from '../../scenes/callFlows/callFlowGrid/details/CalendarUtils';
import ConfirmButton from '../Button/ConfirmButton';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';
import styles from './DateTimePicker.module.scss';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

type StatePropsT = {};

type TimeSlotT = $ReadOnly<
  $Exact<{
    newSlot?: boolean,
    ...$Exact<AbsoluteTimeSlotEntityT>
  }>
>;

type OwnPropsT = {
  className?: string,
  field: string,
  onClose: (?(AbsoluteTimeSlotEntityT[])) => Promise<void>,
  minDate?: Date,
  timeOnly?: boolean,
  selectedTimeSlot?: AbsoluteTimeSlotEntityT
};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;
export function DateTimePicker(props: PropsT): Element<'div'> {
  const { className, onClose, minDate, timeOnly, selectedTimeSlot, field } = props;
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Moment>(
    selectedTimeSlot ? Moment(selectedTimeSlot.startTime) : Moment()
  );
  const [foundTimeError, setFoundTimeError] = useState({});
  const [timeSlots, setTimeSlots] = useState<TimeSlotT[]>(values[field] || []);
  const daysAbsoluteTimeSlots =
    selectedDate && timeSlots
      ? timeSlots.filter(slot => Moment(slot.startTime).isSame(selectedDate, 'day'))
      : [];
  const closed = daysAbsoluteTimeSlots.length > 0 ? !daysAbsoluteTimeSlots[0].active : false;
  const [closedAllDay, setClosedAllDay] = useState(closed);
  const [isUpdating, setIsUpdating] = useState();

  useEffect(() => {
    setClosedAllDay(closed);
  }, [closed]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectDate = date => {
    const newSelectedDate = Moment(date);
    if (selectedDate !== newSelectedDate) {
      const oldSlots = timeSlots.filter(slot => !slot.newSlot);
      setSelectedDate(newSelectedDate);
      setTimeSlots(oldSlots);
    }
  };

  const convertFromSelectedDateTime = time => {
    const updatedDate: Moment = selectedDate || Moment();
    updatedDate.set('hour', time.get('hour'));
    updatedDate.set('minute', time.get('minute'));
    return updatedDate;
  };

  const isTimeSlotError = (slots: TimeSlotT[]) =>
    slots.find(
      slot =>
        slot.active &&
        (!Moment(slot.startTime).isValid() ||
          !Moment(slot.endTime).isValid() ||
          Moment(slot.startTime).isSameOrAfter(Moment(slot.endTime)))
    );
  const handleTimeChange = (range, slotId) => {
    if (selectedDate) {
      const slots = timeSlots.map(slot => {
        const updatedSlot = {
          ...slot
        };
        if (slot.id === slotId) {
          updatedSlot.startTime = convertFromSelectedDateTime(range.start).format(
            'YYYY-MM-DDTHH:mm'
          );
          updatedSlot.endTime = convertFromSelectedDateTime(range.end).format('YYYY-MM-DDTHH:mm');
        }
        return updatedSlot;
      });
      setTimeSlots(slots);
      setFoundTimeError(isTimeSlotError(slots));
    }
  };

  const handleAddAbsoluteTimeSlot = () => {
    const slots = [...timeSlots];
    slots.push({
      id: uuid(),
      startTime: selectedDate
        ? selectedDate
            .set('hour', 8)
            .set('minute', 0)
            .format('YYYY-MM-DDTHH:mm')
        : Moment()
            .set('hour', 8)
            .set('minute', 0)
            .format('YYYY-MM-DDTHH:mm'),
      endTime: selectedDate
        ? selectedDate
            .set('hour', 16)
            .set('minute', 0)
            .format('YYYY-MM-DDTHH:mm')
        : Moment()
            .set('hour', 16)
            .set('minute', 0)
            .format('YYYY-MM-DDTHH:mm'),
      active: true,
      presenceState: 'AWAY',
      newSlot: true
    });
    setTimeSlots(slots);
    setFoundTimeError(isTimeSlotError(slots));
  };

  const handleRemoveAbsoluteTimeSlot = timeSlotId => {
    const slots = timeSlots.filter(slot => slot.id !== timeSlotId);
    setTimeSlots(slots);
    setFoundTimeError(isTimeSlotError(slots));
  };

  const handleClosedAllDayChange = () => {
    if (selectedDate) {
      const slots = selectedDate
        ? timeSlots.filter(slot => !Moment(slot.startTime).isSame(selectedDate, 'day'))
        : [];
      slots.push({
        id: uuid(),
        startTime: selectedDate
          .set('hour', 0)
          .set('minute', 0)
          .set('seconds', 0)
          .format('YYYY-MM-DDTHH:mm:ss'),
        endTime: selectedDate
          .set('hour', 23)
          .set('minute', 59)
          .set('seconds', 0)
          .format('YYYY-MM-DDTHH:mm:ss'),
        active: !!closedAllDay,
        presenceState: 'AWAY',
        newSlot: true
      });
      setClosedAllDay(!closedAllDay);
      setTimeSlots(slots);
      setFoundTimeError(isTimeSlotError(slots));
    }
  };
  // side effects
  useEffect(() => {
    if (daysAbsoluteTimeSlots.length === 0) {
      handleAddAbsoluteTimeSlot();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buttonsSection = (
    <div className={styles['button-area']}>
      <div>
        <ActionButton
          id="date-timepicker-save-button"
          className={styles['save-button']}
          label={t('generic.dateTimePicker.save')}
          onClickAction={event => {
            setIsUpdating(true);
            event.preventDefault();
            // $FlowFixMe TimeSlotT-type should be ok
            const slots = removeContainedAndMergeOverlappingAbsoluteTimeSlots(timeSlots);
            const newSlots = slots.map(s => ({ ...s, newSlot: false }));
            setValue(field, newSlots);
            onClose(newSlots);
          }}
          disabled={
            !selectedDate ||
            (!closedAllDay && (foundTimeError !== undefined || daysAbsoluteTimeSlots.length === 0))
          }
          loading={isUpdating}
        />
        <CancelButton
          id="date-timepicker-cancel-button"
          className={styles['cancel-button']}
          label={t('generic.dateTimePicker.cancel')}
          onClickAction={event => {
            if (event) {
              event.preventDefault();
            }
            onClose();
          }}
          hideWhenViewing
        />
      </div>
    </div>
  );
  return (
    <div className={`ea-modal ea-modal--open styleguide-dialog-position ${styles.container}`}>
      <div className={`ea-modal__overlay ${styles.overlay}`} />
      <div className={classnames(styles['timedate-area'], className)}>
        <div className={styles['upper-area']}>
          <Dismiss
            id="close-datepicker-button"
            onClose={event => {
              if (event) {
                event.preventDefault();
              }
              onClose();
            }}
          />
          <div className={styles['date-area']}>{t('generic.dateTimePicker.info')}</div>
        </div>
        {!timeOnly && (
          <CustomDatePicker
            selected={selectedDate ? selectedDate.toDate() : null}
            onChange={selectDate}
            minDate={minDate || new Date()}
            inline
            highlightDates={timeSlots.map(slot => new Date(Date.parse(slot.startTime)))}
          />
        )}
        <div className={styles['time-area-container']}>
          <div className={styles['toggle-info']}>{t('generic.dateTimePicker.allDayInfo')}</div>
          <div className={styles['toggle-container']}>
            <Toggle
              key={closedAllDay.toString()}
              name="allday-switch"
              value={closedAllDay}
              className={styles.toggle}
              data-cy="allday-switch"
              onToggle={() => handleClosedAllDayChange()}
              label={t('generic.dateTimePicker.allDay')}
            />
          </div>
          <div className={styles['title-area']}>
            <div className={styles['icon-area']}>
              <ClockIcon />
            </div>
            {t('generic.dateTimePicker.timeTitle')}
          </div>
          {daysAbsoluteTimeSlots.map(
            slot =>
              !closedAllDay && (
                <div key={`time-picker-area-${slot.id}`} className={styles['time-picker-area']}>
                  <TimeRangePicker
                    key={`time-picker-${slot.id}`}
                    initialStartTime={slot.startTime !== '' ? Moment(slot.startTime) : null}
                    initialEndTime={slot.endTime !== '' ? Moment(slot.endTime) : null}
                    onChange={range => handleTimeChange(range, slot.id)}
                    validationMessage={t('calendars.rangeValidationError')}
                  />
                  <div className={styles['remove-button']}>
                    <Dismiss
                      id="remove-time-slot-button"
                      onClose={() => handleRemoveAbsoluteTimeSlot(slot.id)}
                    />
                  </div>
                </div>
              )
          )}
          <ConfirmButton
            id="addTimeRangeButton"
            onClickAction={event => {
              event.preventDefault();
              handleAddAbsoluteTimeSlot();
            }}
            label={t('generic.dateTimePicker.addTimeRangeButton')}
            disabled={closedAllDay}
          />
        </div>
        {buttonsSection}
      </div>
    </div>
  );
}

// $FlowFixMe
const mapStateToProps = () => ({});

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(DateTimePicker);
