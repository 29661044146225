import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowLeftLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.532 19.5a.5.5 0 0 1-.348-.141l-6.879-6.644a1 1 0 0 1-.305-.72c0-.273.109-.529.306-.718l6.875-6.641a.501.501 0 0 1 .695.72L9 11.997l6.88 6.644a.5.5 0 0 1-.348.859"/></svg>)}
    />
  );
}
  