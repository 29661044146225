// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QueueIcon } from '../../../../../assets/callflow/details/queue-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../components/view/children/CallflowItem';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import { extensionGroupQueueSchema } from './EditExtensionGroupQueue';
import { hasQueueEnabled } from './extensionGroupForwardingUtils';

export type PropsT = {|
  callflowId: string
|};

export const ViewExtensionGroupQueue = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );

  const callflowAudioFieldConverter = new AudioFieldConverter(
    extGroupData.enterpriseId,
    extGroupData.id,
    'groups',
    'CallQueuingService'
  );

  if (extGroupData) {
    return (
      <CallflowDetails
        enterpriseId={extGroupData.enterpriseId}
        callflowId={extGroupData.id}
        elementType={extGroupData.type}
        nodeId={`group_queue_${extGroupData.id}`}
        nodeType="EXTENSION_GROUP_QUEUE"
        icon={<QueueIcon />}
        title={t('callflows.viewExtensionGroupQueue.title')}
        active={hasQueueEnabled(extGroupData.forwardingsOnService)}
      >
        <CallflowDetailsColumn id="ext-group-queue-column-1">
          <CallflowItem
            id="queueSize"
            title={t('callflows.viewExtensionGroupQueue.queueSize')}
            value={t('callflows.viewExtensionGroupQueue.queueSizeValue', {
              value: R.path(
                ['ivrProperties', 'callQueuingService', 'queueSize', 'value'],
                extGroupData
              )
            })}
            tooltip={t(
              'callflows.viewExtensionGroupQueue.queueSizeTooltip',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(extensionGroupQueueSchema.fields.queueSize)
            )}
          />
          <CallflowItem
            id="waitTime"
            title={t('callflows.viewExtensionGroupQueue.waitTime')}
            value={t('callflows.viewExtensionGroupQueue.waitTimeValue', {
              value: R.path(
                ['ivrProperties', 'callQueuingService', 'waitTime', 'value'],
                extGroupData
              )
            })}
            tooltip={t(
              'callflows.viewExtensionGroupQueue.waitTimeTooltip',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(extensionGroupQueueSchema.fields.waitTime)
            )}
          />
          <CallflowAudioProperty
            isActive={hasQueueEnabled(extGroupData.forwardingsOnService)}
            title={t('callflows.viewExtensionGroupQueue.musicOnWait')}
            audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
              'MusicOnWait',
              '',
              R.path(['ivrProperties', 'callQueuingService', 'musicOnWait'], extGroupData)
            )}
          />
        </CallflowDetailsColumn>
      </CallflowDetails>
    );
  }
  return null;
};

export default ViewExtensionGroupQueue;
