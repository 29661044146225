"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShoppingCartItemAddon = void 0;
exports.ShoppingCartItemAddon = ShoppingCartItemAddon;
var ShoppingCartPrice_1 = require("./ShoppingCartPrice");
function ShoppingCartItemAddon(_a) {
    var description = _a.description, _b = _a.disclaimer, disclaimer = _b === void 0 ? [] : _b, id = _a.id, name = _a.name, price = _a.price, quantity = _a.quantity;
    return {
        description: description,
        disclaimer: disclaimer,
        id: id,
        name: name,
        price: (0, ShoppingCartPrice_1.ShoppingCartPrice)({ amount: price.amount, unit: price.unit }),
        quantity: quantity,
    };
}
var isShoppingCartItemAddon = function (addon) {
    if (!addon || typeof addon !== 'object') {
        return false;
    }
    var addonProps = Object.keys(addon);
    return addon && ['id', 'name', 'price'].every(function (key) { return addonProps.includes(key); });
};
exports.isShoppingCartItemAddon = isShoppingCartItemAddon;
exports.default = {};
