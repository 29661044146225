// @flow
import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPageBase from '../error/ErrorPageBase';
import ActionButton from '../../components/Button/ActionButton';
import LanguageSelector from './LanguageSelector';
import styles from './AdminAccessRightErrorPage.module.scss';

export type PropsT = {||};

// eslint-disable-next-line no-unused-vars
const AdminAccessRightErrorPage = (props: PropsT): Element<typeof ErrorPageBase> => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  let redirectUrl = '/lataukset';
  if (activeLanguage === 'en') {
    redirectUrl = '/downloads';
  } else if (activeLanguage === 'sv') {
    redirectUrl = '/nedladdningar';
  } else if (activeLanguage === 'et') {
    redirectUrl = '/allalaadimised';
  }
  return (
    <ErrorPageBase>
      <LanguageSelector />
      <h1>{t('adminAccessRightErrorPage.title')}</h1>
      <h2 className={styles.description}>{t('adminAccessRightErrorPage.description')}</h2>
      <ActionButton
        id="login-as-normal-user"
        label={t('adminAccessRightErrorPage.infoLinkText')}
        onClickAction={e => {
          e.preventDefault();
          window.location.replace(redirectUrl);
        }}
      />
    </ErrorPageBase>
  );
};

export default AdminAccessRightErrorPage;
