// @flow

/* eslint-disable arrow-body-style */

import * as R from 'ramda';
import * as Sentry from '@sentry/browser';
import axios, { type CancelToken, type AxiosPromise } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import {
  createCreateCallForwardingCancel,
  createCreateCallForwardingFailure,
  createCreateCallForwardingRequest,
  createCreateCallForwardingSuccess,
  createDeleteCallForwardingCancel,
  createDeleteCallForwardingFailure,
  createDeleteCallForwardingRequest,
  createDeleteCallForwardingsCancel,
  createDeleteCallForwardingsFailure,
  createDeleteCallForwardingsRequest,
  createDeleteCallForwardingsSuccess,
  createDeleteCallForwardingSuccess,
  createRetrieveCallForwardingCancel,
  createRetrieveCallForwardingFailure,
  createRetrieveCallForwardingRequest,
  createRetrieveCallForwardingSuccess,
  createRetrieveUserForwardingsFailure,
  createRetrieveUserForwardingsRequest,
  createRetrieveUserForwardingsSuccess,
  createUpdateCallForwardingCancel,
  createUpdateCallForwardingFailure,
  createUpdateCallForwardingRequest,
  createUpdateCallForwardingsCancel,
  createUpdateCallForwardingsFailure,
  createUpdateCallForwardingsRequest,
  createUpdateCallForwardingsSuccess,
  createUpdateCallForwardingSuccess
} from './callForwardingActions';
import type {
  CallForwardingT,
  CreateCallForwardingActionT,
  CreateCallForwardingPayloadT,
  DeleteCallForwardingActionT,
  DeleteCallForwardingsActionT,
  RetrieveCallForwardingActionT,
  UpdateCallForwardingActionT,
  UpdateCallForwardingPayloadT,
  UpdateCallForwardingsActionT
} from './callForwardingTypes';
import type { UserEntityT } from '../user/userTypes';

type RetrieveFnT = (
  string,
  string,
  CancelToken,
  params?: {}
) => ThunkActionT<RetrieveCallForwardingActionT>;

export const retrieveCallForwarding: RetrieveFnT = (
  enterpriseId,
  callForwardingId,
  cancelToken
) => async dispatch => {
  dispatch(createRetrieveCallForwardingRequest(enterpriseId, callForwardingId));
  try {
    const response: AxiosPromise<CallForwardingT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/forwardings/${callForwardingId}`,
      cancelToken
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createRetrieveCallForwardingSuccess(enterpriseId, callForwardingId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveCallForwardingCancel(enterpriseId, callForwardingId));
    } else {
      dispatch(createRetrieveCallForwardingFailure(enterpriseId, callForwardingId, error));
    }
  }
  return undefined;
};

type RetrieveUserFwdsFnT = (string, string, ?CancelToken) => ThunkActionT<*>;
const retrieveUserForwardingsByAddressNumber: RetrieveUserFwdsFnT = (
  enterpriseId,
  addressNumber,
  cancelToken
) => async dispatch => {
  dispatch(createRetrieveUserForwardingsRequest(enterpriseId, addressNumber));
  try {
    const userIdResponse: AxiosPromise<{ extensionId: string }> = await axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumber/${addressNumber}`,
      cancelToken
    });

    const response: AxiosPromise<UserEntityT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/users/${userIdResponse.data.extensionId}/forwardings`,
      cancelToken,
      params: {}
    });
    const { data } = await response;
    dispatch(createRetrieveUserForwardingsSuccess(enterpriseId, addressNumber, data));
    return data;
  } catch (error) {
    dispatch(createRetrieveUserForwardingsFailure(enterpriseId, addressNumber, error));
  }
  return undefined;
};

export type UpdateCallForwardingFnT = (
  string,
  UpdateCallForwardingPayloadT,
  headers: {}
) => ThunkActionT<UpdateCallForwardingActionT, ?mixed>;
export const updateCallForwarding: UpdateCallForwardingFnT = (
  enterpriseId,
  forwarding,
  headers
) => async (dispatch, getState) => {
  if (
    R.path(['entities', 'callForwarding', 'byId', '__metadata', 'isUpdating'], getState()) === true
  ) {
    return undefined;
  }
  dispatch(createUpdateCallForwardingRequest(forwarding.id));
  try {
    const response: AxiosPromise<UpdateCallForwardingPayloadT> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/forwardings/${forwarding.id}`,
      data: { ...forwarding },
      headers
    });
    const { data } = await response;
    dispatch(createUpdateCallForwardingSuccess(enterpriseId, forwarding));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateCallForwardingCancel(enterpriseId, forwarding.id));
    } else if (error.config) {
      dispatch(createUpdateCallForwardingFailure(forwarding.id, error));
    } else {
      Sentry.captureException(error);
    }
    return { error };
  }
};

export type UpdateCallForwardingsFnT = (
  string,
  string[],
  UpdateCallForwardingPayloadT,
  headers: {}
) => ThunkActionT<UpdateCallForwardingsActionT, ?mixed>;
export const updateCallForwardings: UpdateCallForwardingsFnT = (
  enterpriseId,
  forwardingIds,
  forwarding,
  headers
) => async dispatch => {
  dispatch(createUpdateCallForwardingsRequest(forwardingIds));
  try {
    const response = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/forwardings`,
      data: { forwardingIds, forwardingUpdate: forwarding },
      headers
    });
    const { data } = await response;
    dispatch(createUpdateCallForwardingsSuccess(enterpriseId, forwardingIds, forwarding));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateCallForwardingsCancel(enterpriseId, forwardingIds));
    } else if (error.config) {
      dispatch(createUpdateCallForwardingsFailure(forwardingIds, error));
    } else {
      Sentry.captureException(error);
    }
    return { error };
  }
};

export type CreateCallForwardingFnT = (
  string,
  CreateCallForwardingPayloadT,
  ?string,
  ?string,
  headers: {}
) => ThunkActionT<CreateCallForwardingActionT, ?mixed>;
export const createCallForwarding: CreateCallForwardingFnT = (
  enterpriseId,
  forwarding,
  internalAddressId,
  extGroupId,
  headers
) => async dispatch => {
  let url: string;
  if (internalAddressId) {
    url = `/api/v1/enterprises/${enterpriseId}/users/${internalAddressId}/forwardings`;
  } else if (extGroupId) {
    url = `/api/v1/enterprises/${enterpriseId}/services/groups/${extGroupId}/forwardings`;
  } else {
    url = `/api/v1/enterprises/${enterpriseId}/forwardings`;
  }
  dispatch(createCreateCallForwardingRequest());
  try {
    const response: AxiosPromise<CreateCallForwardingPayloadT> = axios({
      method: 'POST',
      url,
      data: {
        ...forwarding
      },
      headers
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createCreateCallForwardingSuccess(enterpriseId, data));
    return { ...data, userId: internalAddressId };
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createCreateCallForwardingCancel());
    } else if (error.config) {
      dispatch(createCreateCallForwardingFailure(error));
    } else {
      Sentry.captureException(error);
    }
    return { userId: internalAddressId, error };
  }
};

export type DeleteCallForwardingsFnT = (
  string,
  string[],
  headers: {}
) => ThunkActionT<DeleteCallForwardingsActionT, boolean>;
export const deleteCallForwardings: DeleteCallForwardingsFnT = (
  enterpriseId,
  forwardingIds,
  headers
) => async dispatch => {
  dispatch(createDeleteCallForwardingsRequest());
  try {
    // $FlowFixMe deprecated-type
    const response: AxiosPromise = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/forwardings`,
      data: forwardingIds,
      headers
    });
    await response;
    dispatch(createDeleteCallForwardingsSuccess(enterpriseId, forwardingIds));
    return true;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createDeleteCallForwardingsCancel());
    } else if (error.config) {
      dispatch(createDeleteCallForwardingsFailure(forwardingIds, error));
    } else {
      Sentry.captureException(error);
    }
  }
  return false;
};

export type DeleteCallForwardingFnT = (
  string,
  string,
  headers: {}
) => ThunkActionT<DeleteCallForwardingActionT, boolean>;
export const deleteCallForwarding: DeleteCallForwardingFnT = (
  enterpriseId,
  forwardingId,
  headers
) => async dispatch => {
  dispatch(createDeleteCallForwardingRequest());
  try {
    // $FlowFixMe deprecated-type
    const response: AxiosPromise = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/forwardings/${forwardingId}`,
      headers
    });
    await response;
    dispatch(createDeleteCallForwardingSuccess(enterpriseId, forwardingId));
    return true;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createDeleteCallForwardingCancel());
    } else if (error.config) {
      dispatch(createDeleteCallForwardingFailure(forwardingId, error));
    } else {
      Sentry.captureException(error);
    }
  }
  return false;
};

const callForwardingOperations = {
  retrieveCallForwarding,
  updateCallForwarding,
  updateCallForwardings,
  createCallForwarding,
  deleteCallForwarding,
  retrieveUserForwardingsByAddressNumber
};

export default callForwardingOperations;
