// @flow

/* eslint-disable: no-unused-vars */

import React, { type Node } from 'react';

// eslint-disable-next-line flowtype/no-weak-types
export type PropsT = {| children: Node, style?: any, className?: any |};

export const CenterHorizontally = ({ children, style, className }: PropsT) => (
  <div className={className} style={{ textAlign: 'center', margin: 'auto', ...style }}>
    {children}
  </div>
);

export default CenterHorizontally;
