import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLikeFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.154 12.252-9.795 10.097a.504.504 0 0 1-.718 0L1.843 12.25A6.29 6.29 0 0 1 .668 4.981a6.31 6.31 0 0 1 4.624-3.4 6.31 6.31 0 0 1 5.461 1.764L12 4.591l1.248-1.247a6.31 6.31 0 0 1 5.461-1.763 6.32 6.32 0 0 1 4.624 3.4 6.29 6.29 0 0 1-1.179 7.271"/></svg>)}
    />
  );
}
  