// @flow

import React from 'react';
import type { Element } from 'react';
import styles from './Triangle.module.scss';

export type PropsT = {
  arrowSize: { width: number, height: number },
  arrowOffsetLeft: number,
  arrowOffsetTop: number,
  strokeColor?: string,
  strokeWidth?: number,
  flipY?: boolean
};

export const Triangle = (props: PropsT): Element<'svg'> => {
  const { arrowSize, arrowOffsetLeft, arrowOffsetTop, strokeColor, strokeWidth, flipY } = props;
  const triangleLeftAndRightSides = `M0,${arrowSize.height} L${arrowSize.width / 2.0},0 L${
    arrowSize.width
  },${arrowSize.height}`;
  const triangleBottomSide = `M3,${arrowSize.height} L${arrowSize.width - 3},${arrowSize.height}`;
  const triangleStyle = {
    left: `${arrowOffsetLeft}px`,
    top: `${arrowOffsetTop}px`
  };
  return (
    <svg
      style={triangleStyle}
      className={styles.triangle}
      width={arrowSize.width}
      height={arrowSize.height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="side-line-path" transform={flipY ? 'scale(1,-1)' : 'scale(1,1)'}>
        <path
          d={triangleLeftAndRightSides}
          className={styles['triangle-side-line-paths']}
          style={{
            // $FlowFixMe
            ...(strokeColor ? { color: strokeColor } : {}),
            ...(strokeWidth ? { strokeWidth: `${strokeWidth}px` } : {})
          }}
        />
      </g>
      <g id="bottom-line-path" transform={flipY ? 'scale(1,-1)' : 'scale(1,1)'}>
        <path d={triangleBottomSide} className={styles['triangle-bottom-line-path']} />
      </g>
    </svg>
  );
};

export default Triangle;
