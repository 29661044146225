import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWifiLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 22.5c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3m0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2m5.771-2.901a.5.5 0 0 0 0-.707c-3.184-3.183-8.365-3.183-11.549 0a.5.5 0 0 0 .707.707c2.794-2.793 7.34-2.793 10.135 0a.5.5 0 0 0 .707 0m3.256-3.653a.5.5 0 0 0 0-.707A12.68 12.68 0 0 0 12 6.5c-3.41 0-6.616 1.328-9.027 3.739a.5.5 0 0 0 .707.707A11.7 11.7 0 0 1 12 7.5c3.144 0 6.098 1.224 8.32 3.446a.5.5 0 0 0 .707 0m2.839-4.076a.5.5 0 0 0-.025-.707C17.201-.026 6.799-.026.159 6.163a.5.5 0 0 0 .682.732c6.258-5.833 16.062-5.833 22.318 0a.5.5 0 0 0 .707-.025"/></svg>)}
    />
  );
}
  