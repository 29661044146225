import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMessageLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.39 23a1.45 1.45 0 0 1-1-.44 1.43 1.43 0 0 1-.26-1.66l1.72-3.55A9 9 0 0 1 0 10.94C0 5.46 5.38 1 12 1s12 4.46 12 9.94-5.38 9.93-12 9.93c-1.198.001-2.39-.15-3.55-.45L3 22.87c-.191.088-.4.132-.61.13M12 2C5.93 2 1 6 1 10.94a8 8 0 0 0 2.76 5.91.51.51 0 0 1 .12.6L2 21.34a.45.45 0 0 0 .08.52.48.48 0 0 0 .53.1l5.59-2.52c.11-.04.23-.04.34 0a13 13 0 0 0 3.46.46c6.07 0 11-4 11-8.93S18.07 2 12 2m6 10.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5m-3-4a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0 0 1h8a.5.5 0 0 0 .5-.5"/></svg>)}
    />
  );
}
  