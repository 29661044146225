// @flow
import React, { type Element } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import CallflowTreeNode from '../CallflowTreeNode';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import type { CallFlowTreeNodeT } from '../../../../../ducks/entities/callFlow';

export type PropsT = {|
  node: CallFlowTreeNodeT,
  callflowId: string
|};

export const AcdNode = (props: PropsT): Element<typeof CallflowTreeNode> | null => {
  const { node, callflowId } = props;
  const { t } = useTranslation();
  // redux
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const overflowData =
    acdData && acdData.overflowGroup
      ? {
          name: (acdData.overflowGroup.label || t('callflows.unnamedGroup')).replace(/^kutsu:/, ''),
          number: acdData.overflowGroup ? acdData.overflowGroup.addressNumber : ''
        }
      : { name: '', number: '' };

  const acdMessageActive: boolean =
    !R.path(['servicesSettings', 'acdCallCenter', 'skipWelcomeMsg'], acdData) || false;

  const getOverflowDescription = () => {
    if (acdData.ringPattern === 'unisson') {
      return t('callflows.treeLayout.acd.serviceUnavailable');
    }
    return overflowData && overflowData.name !== ''
      ? overflowData.name
      : t('callflows.treeLayout.acd.serviceNotInUse');
  };

  const serviceStatus = value =>
    value
      ? t('callflows.treeLayout.acd.serviceInUse')
      : t('callflows.treeLayout.acd.serviceNotInUse');

  const createAcdNodeProps = () => {
    switch (node.type) {
      case 'ACD_AGENTS':
        return {
          image: '/welcome_attendant_agents.svg',
          selectedImage: '/callflow_selected_agents.svg',
          title: t('callflows.treeLayout.acd.agents')
        };
      case 'ACD_CALENDAR':
        return {
          image: '/callflow_calendar.svg',
          selectedImage: '/callflow_selected_calendar.svg',
          title: t('callflows.treeLayout.acd.calendar')
        };
      case 'ACD_MESSAGE':
        return {
          image: '/callflow_message.svg',
          selectedImage: '/callflow_selected_message.svg',
          title: t('callflows.treeLayout.acd.message'),
          description: serviceStatus(acdMessageActive)
        };
      case 'ACD_QUEUE':
        return {
          image: '/callflow_queue.svg',
          selectedImage: '/callflow_selected_queue.svg',
          title: t('callflows.treeLayout.acd.queue')
        };
      case 'ACD_UNHANDLED':
        return {
          image: '/callflow_unhandled.svg',
          selectedImage: '/callflow_selected_unhandled.svg',
          title: t('callflows.treeLayout.acd.unhandled')
        };
      case 'ACD_CALENDAR_CLOSED':
        return {
          image: '/callflow_unhandled.svg',
          selectedImage: '/callflow_selected_unhandled.svg',
          title: parse(
            `${t('callflows.treeLayout.acd.calendarClosed1')}<br/>${t(
              'callflows.treeLayout.acd.calendarClosed2'
            )}`
          )
        };
      case 'ACD_OVERFLOW': {
        const description = getOverflowDescription();
        return {
          image: '/welcome_attendant_group.svg',
          selectedImage: '/callflow_selected_group.svg',
          title: t('callflows.treeLayout.acd.overflowGroup'),
          description
        };
      }
      case 'ACD_CALLBACK':
        return {
          image: '/callflow_callback.svg',
          selectedImage: '/callflow_selected_callback.svg',
          title: t('callflows.treeLayout.acd.callback', overflowData),
          description: serviceStatus(acdData.acdGroupCallbackEnabled)
        };
      case 'ACD_CALLBACK_REENTRANCE':
        return {
          image: '/callflow_dialpad.svg',
          selectedImage: '/callflow_selected_dialpad.svg',
          title: t('callflows.treeLayout.acd.callbackReentrance', overflowData),
          description: serviceStatus(
            acdData.servicesSettings?.acdCallCenter?.callbackActivateReentrance
          )
        };
      case 'ACD_VOICE_MAIL':
        return {
          image: '/callflow_voicemail.svg',
          selectedImage: '/callflow_voicemail_selected.svg',
          title: t('callflows.treeLayout.acd.voiceMail', overflowData)
        };
      case 'ACD_VOICE_MAIL_DETAILS':
        return {
          image: '/welcome_attendant_transfer.svg',
          selectedImage: '/voicemail_selected.svg',
          title: t('callflows.treeLayout.acd.voiceMailDetails', overflowData)
        };
      default:
        return {
          image: '/end_state.svg',
          selectedImage: '/end_state.svg',
          title: t('callflows.treeLayout.acd.callEnd')
        };
    }
  };

  if (acdData) {
    return (
      <CallflowTreeNode
        serviceId={acdData.id}
        nodeId={node.id}
        type={node.type}
        {...createAcdNodeProps()}
      />
    );
  }
  return null;
};

export default AcdNode;
