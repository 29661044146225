// @flow
/* eslint-disable import/prefer-default-export */
import type { ActiveSectionT, SetActiveFormsSectionT } from './formsUiTypes';

export type CreateSetActiveFormsSectionFnT = ActiveSectionT => SetActiveFormsSectionT;
export const createSetActiveFormsSection: CreateSetActiveFormsSectionFnT = activeSection => ({
  type: 'ring/ui/forms/SET_FORMS_ACTIVE_SECTION',
  payload: {
    activeSection
  }
});
