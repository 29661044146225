// @flow

import React, { useEffect, useState } from 'react';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import Button from '@design-system/component-library/src/components/Button';
// $FlowFixMe
import writeXlsxFile from 'write-excel-file';
import moment from 'moment';

let requestAdminsCancelTokenSource: CancelTokenSource;

export const ROBMExport = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState();

  useEffect(() => {
    requestAdminsCancelTokenSource = CancelToken.source();
    return () => {
      requestAdminsCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const saveDataAsXlsx = async (responseData, fileName) => {
    // collect all the keys in the data and construct header row
    const allKeys = {};
    responseData.forEach(entry => {
      Object.keys(entry).forEach(key => {
        allKeys[key] = true;
      });
    });

    const HEADER_ROW = [];
    Object.keys(allKeys).forEach(key => {
      HEADER_ROW.push({
        value: key,
        fontWeight: 'bold'
      });
    });

    // rows. if key is missing for object, put empty string in
    const rows = [];
    responseData.forEach(entry => {
      const row = [];
      Object.keys(allKeys).forEach(key => {
        row.push({
          type: String,
          value: entry[key] ? `${entry[key]}` : ''
        });
      });
      rows.push(row);
    });

    const data = [HEADER_ROW, ...rows];
    await writeXlsxFile(data, {
      fileName: `${fileName}.xlsx`
    });
  };

  const saveDataAsJSON = (responseData, fileName) => {
    const jsonData = new Blob([JSON.stringify(responseData, null, 3)], {
      type: 'application/json'
    });
    const jsonURL = URL.createObjectURL(jsonData);
    const link = document.createElement('a');
    link.href = jsonURL;
    link.download = `${fileName}.json`;
    // $FlowFixMe
    document.body.appendChild(link);
    link.click();
    // $FlowFixMe
    document.body.removeChild(link);
  };

  const exportUsers = async (path: string, fileFormat: string) => {
    setProcessing(true);
    try {
      if (!['newusers', 'allusers'].includes(path)) {
        throw new Error(`unexpected path: ${path}`);
      }
      const response: AxiosPromise<> = await axios({
        method: 'GET',
        url: `/api/v1/admin/robm/export/${path}`,
        cancelToken: new CancelToken(canceler => {
          requestAdminsCancelTokenSource.current = canceler;
        })
      });
      if (response.data) {
        const fileName = `robm_export_${path}_${moment().format('YYYYMMDD')}`;
        if (fileFormat === 'xlsx') {
          await saveDataAsXlsx(response.data, fileName);
        } else if (fileFormat === 'json') {
          await saveDataAsJSON(response.data, fileName);
        } else throw new Error(`unexpected file format: ${fileFormat}`);
      }
    } catch (error) {
      console.error(error);
      setProcessing(false);
    }
    setProcessing(false);
  };

  return (
    <div>
      <div>{t('adminui.robmExport.newUsers')}</div>
      &nbsp;
      <Button loading={processing} onClick={() => exportUsers('newusers', 'json')}>
        {t('adminui.robmExport.json')}
      </Button>
      &nbsp;
      <Button loading={processing} onClick={() => exportUsers('newusers', 'xlsx')}>
        {t('adminui.robmExport.xlsx')}
      </Button>
      <div>&nbsp;</div>
      <div>{t('adminui.robmExport.allUsers')}</div>
      &nbsp;
      <Button loading={processing} onClick={() => exportUsers('allusers', 'json')}>
        {t('adminui.robmExport.json')}
      </Button>
      &nbsp;
      <Button loading={processing} onClick={() => exportUsers('allusers', 'xlsx')}>
        {t('adminui.robmExport.xlsx')}
      </Button>
    </div>
  );
};

export default ROBMExport;
