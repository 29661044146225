// @flow

import React, { type Element } from 'react';
import classnames from 'classnames';
import Truncate from 'react-truncate-markup';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as callflowSelectors } from '../../../../ducks/ui/callflow';
import {
  createUpdateEditStatus,
  createUpdateOpenStatus,
  createUpdateSelectedStatus
} from '../../../../ducks/ui/callflow/callflowUiActions';
import ExtensionGroupForwardingTargetName from '../../components/view/children/ExtensionGroupForwardingTargetName';
import styles from './CallflowTreeNode.module.scss';

export type PropsT = {|
  serviceId: string,
  nodeId: string,
  type?: string,
  image: string,
  selectedImage?: string,
  title?: string | Element<*>,
  description?: string | Element<typeof ExtensionGroupForwardingTargetName> | Element<'div'>,
  notSelectable?: boolean
|};

export const CallflowTreeNode = (props: PropsT): Element<'div'> => {
  const {
    serviceId,
    nodeId,
    type,
    title,
    description,
    image,
    selectedImage,
    notSelectable
  } = props;

  // redux
  const dispatch = useDispatch();
  const selectedNodeId: ?string = useSelector(state =>
    callflowSelectors.getSelectedId(state, serviceId)
  );

  const onClick = () => {
    if (serviceId && !notSelectable) {
      const selectedId = selectedNodeId === nodeId ? null : nodeId;
      const openStatus = selectedNodeId !== null;
      const selectedType = type === undefined ? selectedNodeId : type;

      dispatch(createUpdateOpenStatus(serviceId, openStatus));
      dispatch(createUpdateSelectedStatus(serviceId, selectedType, selectedId));
      dispatch(createUpdateEditStatus(serviceId, false));
    }
  };

  const realImage = selectedNodeId === nodeId ? selectedImage || image : image;
  const noSelect = notSelectable || selectedImage === undefined;
  const formatTitle = () =>
    title && typeof title === 'string' ? (
      <Truncate lines={title.indexOf(' ') === -1 && title.length > 13 ? 1 : 3}>
        <span>
          {title.split('\n').map((line, i, arr) => {
            // eslint-disable-next-line react/no-array-index-key
            const formattedLine = <span key={i}>{line}</span>;

            if (i === arr.length - 1) {
              return formattedLine;
            }
            // eslint-disable-next-line react/no-array-index-key
            return [formattedLine, <br key={`${i}br`} />];
          })}
        </span>
      </Truncate>
    ) : (
      title || ''
    );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyPress={onClick}
      onClick={onClick}
      className={classnames(styles['grid-container'], { [styles['no-select']]: noSelect })}
      data-cy={nodeId}
    >
      <div id={image} role="button" tabIndex={0}>
        <img className={classnames(styles['grid-image'])} alt={realImage} src={realImage} />
      </div>
      <div className={styles.title}>{title && formatTitle()}</div>
      {description && (
        <div className={styles['details-container']} data-cy="node-description">
          {description}
        </div>
      )}
    </div>
  );
};

export default CallflowTreeNode;
