import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPinterestFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.01C24 18.632 18.623 24 11.99 24c-1.245 0-2.432-.188-3.558-.533.494-.799 1.226-2.102 1.493-3.148l.751-2.852c.386.75 1.532 1.382 2.748 1.382 3.618 0 6.227-3.326 6.227-7.461 0-3.967-3.242-6.928-7.404-6.928-5.189 0-7.937 3.474-7.937 7.253 0 1.766.939 3.947 2.442 4.648.227.109.356.059.405-.158.04-.168.247-.987.336-1.362a.36.36 0 0 0-.079-.345c-.494-.602-.89-1.707-.89-2.734 0-2.645 2.007-5.201 5.427-5.201 2.956 0 5.021 2.003 5.021 4.875 0 3.237-1.641 5.487-3.776 5.487-1.176 0-2.066-.977-1.779-2.171.346-1.431.998-2.97.998-3.997 0-.918-.494-1.688-1.522-1.688-1.206 0-2.175 1.243-2.175 2.911 0 1.056.356 1.776.356 1.776S7.888 18.78 7.67 19.717c-.237 1.036-.148 2.497-.04 3.444C3.163 21.414 0 17.072 0 11.99 0 5.368 5.377 0 12.01 0S24.02 5.368 24 12.01"/></svg>)}
    />
  );
}
  