// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExtGroupOverflowIcon } from '../../../../../assets/callflow/details/ext-group-overflow-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../components/view/children/CallflowItem';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import {
  getActiveForwardingsByType,
  getForwardingTargetName,
  hasInvalidForwardingConfiguration
} from './extensionGroupForwardingUtils';
import InvalidConfigurationInfo from '../../../components/view/children/InvalidConfigurationInfo';
import type { CallForwardingTypeT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import Tooltip from '../../../../../components/Tooltip';

import styles from '../../../components/view/children/CallflowItem.module.scss';

export type PropsT = {|
  callflowId: string
|};

export const ViewExtensionGroupOverflow = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );

  const getTranslationId = (fwdType: CallForwardingTypeT): string => {
    const fwds = getActiveForwardingsByType(extGroupData.forwardings, fwdType);
    if (fwds.length > 1) {
      return t('callflows.viewExtensionGroupOverflow.invalidDataFwd');
    }

    const fwdTarget = R.path([0, 'destination'], fwds) || null;
    const target = getForwardingTargetName(fwdTarget, extGroupData);
    if (target === 'ENT_VM' || target === 'REJECTION') {
      if (target === 'ENT_VM') {
        return t('callflows.viewExtensionGroupOverflow.ent_vm');
      }
      return t('callflows.viewExtensionGroupOverflow.rejection');
    }
    return target;
  };

  const onBusyTarget = getTranslationId('ON_BUSY');
  const onNoAnswerTarget = getTranslationId('NO_ANSWER');
  const onUnreachableTarget = getTranslationId('UNREACHABLE');
  const hasInvalidData = hasInvalidForwardingConfiguration(extGroupData.forwardings);
  const noAnswerFwds = getActiveForwardingsByType(extGroupData.forwardings, 'NO_ANSWER');
  const noAnswerForwardingDelay =
    noAnswerFwds && noAnswerFwds.length === 1 && noAnswerFwds[0].noAnswerForwardingDelay
      ? noAnswerFwds[0].noAnswerForwardingDelay
      : -1;

  return (
    <CallflowDetails
      enterpriseId={extGroupData.enterpriseId}
      callflowId={extGroupData.id}
      elementType={extGroupData.type}
      nodeId={`group_overflow_${extGroupData.id}`}
      nodeType="EXTENSION_GROUP_OVERFLOW"
      icon={<ExtGroupOverflowIcon />}
      title={t('callflows.viewExtensionGroupOverflow.title')}
      active
    >
      <CallflowDetailsColumn id="extension-group-forwardings-column">
        {hasInvalidData ? (
          <InvalidConfigurationInfo
            title={parse(
              `<b>${t('callflows.viewExtensionGroupOverflow.invalidDataTitle1')}</b>${t(
                'callflows.viewExtensionGroupOverflow.invalidDataTitle2'
              )}`
            )}
          />
        ) : null}

        <CallflowItem
          id="busy-fwd"
          title={t('callflows.viewExtensionGroupOverflow.onBusyFwd')}
          value={onBusyTarget || t('callflows.viewExtensionGroupOverflow.serviceNotInUse')}
        />

        <CallflowItem
          id="no-answer-fwd"
          title={t('callflows.viewExtensionGroupOverflow.onNoAnswerFwd')}
          value={onNoAnswerTarget || t('callflows.viewExtensionGroupOverflow.serviceNotInUse')}
        />
        {onNoAnswerTarget ? (
          <CallflowItem
            id="noAnswerForwardingDelay"
            title={t('callflows.viewExtensionGroupOverflow.noAnswerForwardingDelay')}
            value={
              noAnswerForwardingDelay !== -1
                ? t('callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayValue', {
                    value: noAnswerForwardingDelay / 1000
                  })
                : t('callflows.viewExtensionGroupOverflow.invalidDataFwd')
            }
            tooltip={
              <Tooltip containerStyle={styles.tooltip}>
                {parse(
                  `${t(
                    'callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip1'
                  )}<br/><br/>${t(
                    'callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip2'
                  )}<br/><br/>${t(
                    'callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip3'
                  )}<br/><br/><ul style="margin-left:30px;"><li style="list-style-type:disc">${t(
                    'callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip4'
                  )}
                </li><ul style="margin-left:30px;"><li style="list-style-type:circle">
                ${t('callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip5')}
                </li><li style="list-style-type:circle"><li style="list-style-type:circle">
                ${t('callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip6')}
                </li><li style="list-style-type:circle">${t(
                  'callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip7'
                )}</li></ul></ul><br/><br/>${t(
                    'callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip8'
                  )}<br/><br/>${t(
                    'callflows.viewExtensionGroupOverflow.noAnswerForwardingDelayTooltip9'
                  )}`
                )}
              </Tooltip>
            }
          />
        ) : null}

        <CallflowItem
          id="unreachable-fwd"
          title={t('callflows.viewExtensionGroupOverflow.onUnreachableFwd')}
          value={onUnreachableTarget || t('callflows.viewExtensionGroupOverflow.serviceNotInUse')}
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default ViewExtensionGroupOverflow;
