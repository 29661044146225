import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLinkLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.7 20.486a4.7 4.7 0 0 1-3.322-1.363C1.489 18.242 1 17.069 1 15.821s.489-2.421 1.378-3.303l4.455-4.421a4.73 4.73 0 0 1 6.644-.007 4.62 4.62 0 0 1 .481 6.054.5.5 0 1 1-.806-.592 3.63 3.63 0 0 0-.378-4.751 3.727 3.727 0 0 0-5.236.006l-4.456 4.422C2.384 13.921 2 14.842 2 15.821s.384 1.9 1.082 2.593a3.727 3.727 0 0 0 5.236 0l.87-.863a.499.499 0 1 1 .704.709l-.87.863A4.7 4.7 0 0 1 5.7 20.486m11.468-4.585 4.455-4.42C22.511 10.599 23 9.426 23 8.179s-.489-2.42-1.377-3.302a4.733 4.733 0 0 0-6.646 0l-.7.695a.5.5 0 0 0 .705.71l.7-.695a3.725 3.725 0 0 1 5.235 0C21.616 6.279 22 7.2 22 8.179s-.384 1.9-1.082 2.592l-4.455 4.421a3.724 3.724 0 0 1-5.236.004 3.63 3.63 0 0 1-.378-4.75.5.5 0 1 0-.807-.591 4.62 4.62 0 0 0 .481 6.052 4.7 4.7 0 0 0 3.319 1.36 4.7 4.7 0 0 0 3.326-1.366"/></svg>)}
    />
  );
}
  