// @flow

import { combineReducers } from 'redux';
import type { StoreStateT } from '../../commonTypes';
import enterprise from './enterprise';
import user from './user';
import location from './location';
import department from './department';
import callForwarding from './callForwarding';
import callFlow from './callFlow';
import historyAction from './historyAction';
import calendar from './calendar';
import directory from './directory';
import efax from './efax';

const reducers = {
  user,
  enterprise,
  location,
  department,
  callForwarding,
  callFlow,
  calendar,
  directory,
  efax,
  historyAction
};

export default combineReducers<$PropertyType<StoreStateT, 'entities'>, *>(reducers);
