import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmarthomeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 23.25H5A2.253 2.253 0 0 1 2.75 21v-7a.75.75 0 0 1 1.5 0v7c0 .413.336.75.75.75h14c.413 0 .75-.337.75-.75v-7a.75.75 0 0 1 1.5 0v7c0 1.24-1.01 2.25-2.25 2.25m4.777-10.467a.75.75 0 0 0 .006-1.061L13.555 1.375a2.25 2.25 0 0 0-3.125.014L.217 11.723a.75.75 0 1 0 1.067 1.055l10.198-10.32a.743.743 0 0 1 1.021-.015l10.214 10.334a.746.746 0 0 0 1.06.006m-8.434 3.423a.75.75 0 0 0 0-1.061 4.733 4.733 0 0 0-6.686 0 .75.75 0 1 0 1.061 1.061 3.23 3.23 0 0 1 4.564 0 .75.75 0 0 0 1.061 0M8.03 13.894a5.623 5.623 0 0 1 7.939 0 .75.75 0 1 0 1.061-1.061c-2.773-2.773-7.287-2.772-10.061 0a.75.75 0 1 0 1.061 1.061M12 16.75A1.125 1.125 0 1 0 12 19a1.125 1.125 0 0 0 0-2.25"/></svg>)}
    />
  );
}
  