// @flow
import { type $AxiosError } from 'axios';
import * as R from 'ramda';
import type {
  EfaxEntitiesT,
  RetrieveEfaxCollectionCancelT,
  RetrieveEfaxCollectionFailureT,
  RetrieveEfaxCollectionRequestT,
  RetrieveEfaxCollectionSuccessT
} from './efaxTypes';

export const createRetrieveEfaxCollectionRequest = (
  enterpriseId: string,
  meta: {} = {}
): RetrieveEfaxCollectionRequestT => ({
  type: 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});
export const createRetrieveEfaxCollectionSuccess = (
  enterpriseId: string,
  efaxEntities: EfaxEntitiesT,
  meta: {} = {}
): RetrieveEfaxCollectionSuccessT => ({
  type: 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId,
    length: efaxEntities.length,
    totalCount: efaxEntities.totalCount,
    offset: efaxEntities.offset
  },
  payload: R.map(R.assoc('enterpriseId', enterpriseId), efaxEntities.results)
});
export const createRetrieveEfaxCollectionFailure = (
  enterpriseId: string,
  error: $AxiosError<*>,
  meta: {} = {}
): RetrieveEfaxCollectionFailureT => ({
  type: 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});
export const createRetrieveEfaxCollectionCancel = (): RetrieveEfaxCollectionCancelT => ({
  type: 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_CANCEL',
  error: false,
  meta: {},
  payload: {}
});
