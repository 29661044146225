// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import React, { Component, type Element } from 'react';
import classNames from 'classnames';
import styles from './StepsIndicator.module.scss';

export type PropsT = {
  currentStep: number,
  stepCount: number,
  onStepChange?: number => *
};

export class StepsIndicator extends Component<PropsT> {
  constructor(props: PropsT) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick: *;

  handleClick(step: number): void {
    const { onStepChange, currentStep } = this.props;

    if (onStepChange && step !== currentStep) {
      onStepChange(step - currentStep);
    }
  }

  render(): Element<'div'> {
    const { currentStep, stepCount, onStepChange } = this.props;

    return (
      <div className={self.styles['steps-container']}>
        {R.range(1, stepCount + 1).map(step => (
          <span
            key={`step-${step}`}
            onClick={() => this.handleClick(step)}
            onKeyPress={() => this.handleClick(step)}
            role="button"
            tabIndex={0}
            data-cy={`step-${step}`}
            className={classNames({
              [self.styles.step]: true,
              [self.styles.done]: step <= currentStep,
              [self.styles.clickable]: onStepChange,
              [self.styles.current]: step === currentStep
            })}
          />
        ))}
      </div>
    );
  }
}

const self = {
  styles,
  StepsIndicator
};

export default self;
