import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailAddressLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.072 5.78C17.641.777 10.779-.501 5.78 2.929.778 6.36-.501 13.219 2.929 18.221a10.93 10.93 0 0 0 7.056 4.592 11 11 0 0 0 2.039.189c2.199 0 4.346-.661 6.198-1.932a.5.5 0 1 0-.566-.824 9.92 9.92 0 0 1-7.487 1.584 9.94 9.94 0 0 1-6.415-4.176C.636 13.108 1.798 6.872 6.345 3.753s10.783-1.956 13.903 2.592A9.96 9.96 0 0 1 22 11.992v.912a2.696 2.696 0 0 1-2.693 2.693 2.696 2.696 0 0 1-2.692-2.693v-.912c0-2.54-2.066-4.605-4.605-4.605s-4.605 2.066-4.605 4.605a4.61 4.61 0 0 0 4.605 4.605c1.662 0 3.108-.893 3.918-2.217a3.7 3.7 0 0 0 3.38 2.217 3.7 3.7 0 0 0 3.693-3.693v-.913a10.97 10.97 0 0 0-1.929-6.211m-9.063 9.818c-1.988 0-3.605-1.617-3.605-3.605s1.617-3.605 3.605-3.605 3.605 1.617 3.605 3.605-1.617 3.605-3.605 3.605"/></svg>)}
    />
  );
}
  