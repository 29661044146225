import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmartWatchRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 4h-.883l-.419-2.125A2.244 2.244 0 0 0 14.479 0H9.521a2.24 2.24 0 0 0-2.216 1.855L6.883 4H6a2.25 2.25 0 0 0-2.25 2.25v11.5C3.75 18.99 4.759 20 6 20h.883l.418 2.121A2.244 2.244 0 0 0 9.521 24h4.958a2.24 2.24 0 0 0 2.217-1.858L17.117 20H18c1.24 0 2.25-1.01 2.25-2.25V6.25C20.25 5.009 19.24 4 18 4M8.781 2.125a.75.75 0 0 1 .74-.625h4.958c.368 0 .68.263.744.645L15.588 4H8.412zm6.439 19.748a.75.75 0 0 1-.741.627H9.521a.756.756 0 0 1-.744-.647L8.412 20h7.176zm3.53-4.123c0 .413-.337.75-.75.75H6a.75.75 0 0 1-.75-.75V6.25A.75.75 0 0 1 6 5.5h12a.75.75 0 0 1 .75.75z"/><circle cx="12" cy="12" r="1.75"/><circle cx="10" cy="8.5" r="1"/><circle cx="14" cy="8.5" r="1"/><circle cx="10" cy="15.5" r="1"/><circle cx="14" cy="15.5" r="1"/><circle cx="16" cy="12" r="1"/><circle cx="8" cy="12" r="1"/></svg>)}
    />
  );
}
  