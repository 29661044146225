import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileTxtRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.4 6.5c-.1 0-.1-.1 0 0-.1-.2-.2-.4-.4-.5L15.6.6c-.2-.2-.3-.3-.5-.4-.3-.1-.5-.2-.8-.2H5.2C4 0 3 1 3 2.2v19.5C3 23 4 24 5.2 24h14c1.2 0 2.2-1 2.2-2.2V7.2c.1-.3 0-.5 0-.7M18.9 6h-3.2c-.1 0-.2-.1-.2-.2V2.6zm.3 16.5h-14c-.4 0-.7-.3-.7-.8V2.2c0-.4.3-.8.7-.8H14v4.2c0 1 .8 1.8 1.8 1.8H20v14.3c0 .5-.3.8-.8.8m-9.9-6v-1H5.6v1H7v3.7h1v-3.7zm4.8 3.7-1.5-2.4 1.5-2.3h-1.2L12 17l-.9-1.5H9.9l1.5 2.4-1.5 2.3h1.2l.9-1.5.9 1.5zm4.3-3.7v-1h-3.7v1H16v3.7h1v-3.7z" className="st0"/></svg>)}
    />
  );
}
  