// @flow

import * as R from 'ramda';
import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { createCloseChangelogAction } from '../../ducks/ui/header/headerUiActions';
import { BaseModal } from '../../components/BaseModal';
import ActionButton from '../../components/Button/ActionButton';
import { operations } from '../../ducks/config';
import {
  findEnvironmentBulletins,
  getBulletin,
  getChangelogKey,
  getNewChangelogDisplayedValue
} from '../login/LoginUtil';
import { getConfigurationValue } from '../../userConfigHelpers';
import ConfirmButton from '../../components/Button/ConfirmButton';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import styles from './ChangelogModal.module.scss';

type PropsT = {||};

// eslint-disable-next-line no-unused-vars
export const ChangelogModal = (props: PropsT): Element<typeof BaseModal> | null => {
  // redux
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const changelogOpen: boolean = useSelector(state => state.ui.header.changelogOpen);
  const userConfig = useSelector(state => state.config.userConfig);
  const currentUser = useSelector(state => state.currentUser);
  const bulletins = useSelector(state => state.config.bulletins);
  const activeLanguage = i18n.language;
  const [bulletinIndex, setBulletinIndex] = useState(0);

  useEffect(() => {
    const fetchUserConfig = async () => {
      await dispatch(operations.getUserConfig());
      await dispatch(operations.getBulletins());
    };
    fetchUserConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClose = async (): Promise<void> => {
    setBulletinIndex(0);
    const changelogDisplayedKey = getChangelogKey(currentUser.environment);
    const currentChangelogDisplayedValue =
      userConfig && getConfigurationValue(userConfig, changelogDisplayedKey);
    const newChangelogDisplayedValue = getNewChangelogDisplayedValue(
      currentUser.environment,
      currentUser.enterprises ? currentUser.enterprises.map(e => e.ownerAdmtiveDomainId) : [],
      bulletins,
      currentChangelogDisplayedValue
    );
    dispatch(
      operations.updateUserConfig(
        [
          ...R.reject(item => 'key' in item && item.key === changelogDisplayedKey, userConfig),
          { key: changelogDisplayedKey, value: newChangelogDisplayedValue }
        ],
        createCsrfHeader(currentUser)
      )
    );
    dispatch(createCloseChangelogAction());
  };

  const bulletin = getBulletin(
    currentUser.environment,
    currentUser.enterprises ? currentUser.enterprises.map(e => e.ownerAdmtiveDomainId) : [],
    bulletins,
    bulletinIndex
  );
  let message = '';
  if (bulletin) {
    if (activeLanguage === 'fi') {
      message = bulletin.messageFi;
    } else if (activeLanguage === 'en') {
      message = bulletin.messageEn;
    } else {
      message = bulletin.messageSv;
    }
  }

  const environmentBulletins = findEnvironmentBulletins(
    currentUser.environment,
    currentUser.enterprises
      ? currentUser.enterprises.map(enterprise => enterprise.ownerAdmtiveDomainId || '')
      : [],
    bulletins
  );

  return changelogOpen ? (
    <BaseModal modalStyles={[styles.modal]} onClose={() => handleOnClose()}>
      <div className={styles['changelog-container']}>
        <div className={styles['text-content']}>{parse(message)}</div>
        <ConfirmButton
          id="changelog-previous-button"
          className={styles['previous-button']}
          label={t('changelog.previous')}
          disabled={bulletinIndex === environmentBulletins.length - 1}
          onClickAction={() => {
            if (bulletinIndex < environmentBulletins.length - 1) {
              setBulletinIndex(bulletinIndex + 1);
            }
          }}
        />
        <ConfirmButton
          id="changelog-next-button"
          className={styles['next-button']}
          label={t('changelog.next')}
          disabled={bulletinIndex === 0}
          onClickAction={() => {
            if (bulletinIndex > 0) {
              setBulletinIndex(bulletinIndex - 1);
            }
          }}
        />
        <div className={styles['bottom-area']}>
          <ActionButton
            id="close-button"
            className={styles['changelog-close-button']}
            label={t('changelog.ok')}
            onClickAction={() => handleOnClose()}
          />
        </div>
      </div>
    </BaseModal>
  ) : null;
};

export default ChangelogModal;
