// @flow

import axios, { type AxiosPromise, type CancelToken } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import {
  createAnalyzeImportCancel,
  createAnalyzeImportFailure,
  createAnalyzeImportRequest,
  createAnalyzeImportSuccess,
  createAnalyzeImportProcessRequest,
  createAnalyzeImportProcessCancel,
  createAnalyzeImportProcessSuccess,
  createAnalyzeImportStartedSuccess,
  createAnalyzeImportProcessFailure
} from './userImportUiActions';
import type { AnalyzeImportActionT, ImportAnalysisPayloadT } from './userImportUiTypes';

type ImportUsersFnT = (
  string,
  *,
  CancelToken,
  params?: {},
  headers: {}
) => ThunkActionT<AnalyzeImportActionT>;

const importUsers: ImportUsersFnT = (
  enterpriseId,
  file,
  cancelToken,
  params = {},
  headers = {}
) => async dispatch => {
  dispatch(createAnalyzeImportRequest(enterpriseId, params));
  try {
    const formData = new FormData();
    formData.append(
      'file',
      new Blob([file], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      'file'
    );
    const response: AxiosPromise = axios({
      method: 'POST',
      url: `/api/v1/import/enterprises/${enterpriseId}/users/analyze`,
      cancelToken,
      data: formData,
      headers
    });
    const { data } = await response;
    dispatch(createAnalyzeImportStartedSuccess(enterpriseId, data));
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createAnalyzeImportCancel());
    } else {
      dispatch(createAnalyzeImportFailure(error));
    }
  }
};

type ImportUsersDoneFnT = (
  string,
  string,
  CancelToken,
  params?: {},
  headers?: {}
) => ThunkActionT<AnalyzeImportActionT, ImportAnalysisPayloadT>;

const importUsersDone: ImportUsersDoneFnT = (
  enterpriseId,
  uuid,
  cancelToken,
  headers = {}
) => async dispatch => {
  const params = new URLSearchParams();
  params.append('uuid', uuid);
  try {
    const response: AxiosPromise<FormData, ImportAnalysisPayloadT> = axios({
      method: 'GET',
      url: `/api/v1/import/enterprises/${enterpriseId}/done`,
      cancelToken,
      params,
      headers
    });
    const { data } = await response;
    dispatch(createAnalyzeImportSuccess(data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createAnalyzeImportCancel());
    } else {
      dispatch(createAnalyzeImportFailure(error));
    }
    return error;
  }
};

type GetImportUsersProcessFnT = (
  string,
  string,
  CancelToken,
  params?: {},
  headers?: {}
) => ThunkActionT<AnalyzeImportActionT>;

const getImportUsersProcess: GetImportUsersProcessFnT = (
  enterpriseId,
  uuid,
  cancelToken,
  headers = {}
) => async dispatch => {
  dispatch(createAnalyzeImportProcessRequest(enterpriseId));
  const params = new URLSearchParams();
  params.append('uuid', uuid);
  try {
    const response: AxiosPromise = axios({
      method: 'GET',
      url: `/api/v1/import/enterprises/${enterpriseId}/process`,
      cancelToken,
      params,
      headers
    });
    const { data } = await response;
    if (data) {
      dispatch(createAnalyzeImportProcessSuccess(enterpriseId, data));
      return data;
    }
    return undefined;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createAnalyzeImportProcessCancel());
    } else {
      dispatch(createAnalyzeImportProcessFailure(enterpriseId, error));
    }
    return undefined;
  }
};

const userOperations = {
  importUsers,
  importUsersDone,
  getImportUsersProcess
};

export default userOperations;
