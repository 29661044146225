import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHealthRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.465 15.249c-.412 0-.809-.227-1.007-.618l-1.685-3.376H5.751a.75.75 0 0 1 0-1.5h2.268c.413 0 .807.254 1.006.648l1.398 2.802 2.07-5.245c.11-.28.323-.499.599-.618a1.12 1.12 0 0 1 1.451.531l.913 1.882h3.792a.75.75 0 0 1 0 1.5h-4.013c-.444 0-.821-.222-1.007-.593l-.656-1.353-2.065 5.232a1.12 1.12 0 0 1-1.042.708m-2.446-3.995h.01zM12 22.5a.75.75 0 0 1-.537-.227l-9.585-9.847A6.38 6.38 0 0 1 .682 5.037a6.44 6.44 0 0 1 4.715-3.455 6.44 6.44 0 0 1 5.567 1.792L12 4.407l1.037-1.032a6.46 6.46 0 0 1 5.568-1.792 6.44 6.44 0 0 1 4.714 3.456 6.38 6.38 0 0 1-1.203 7.393l-9.579 9.842A.75.75 0 0 1 12 22.5M6.42 3a4.93 4.93 0 0 0-4.397 2.711 4.88 4.88 0 0 0 .922 5.661L12 20.675l9.05-9.297a4.89 4.89 0 0 0 .93-5.667 4.94 4.94 0 0 0-3.615-2.648 4.95 4.95 0 0 0-4.27 1.374l-1.566 1.56a.75.75 0 0 1-1.058 0l-1.565-1.56A4.95 4.95 0 0 0 6.42 3"/></svg>)}
    />
  );
}
  