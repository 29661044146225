// @flow

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { $AxiosXHR, Canceler } from 'axios';
import axios, { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import HTTP from 'http-status-codes';
import { BaseModal } from '../../../../components/BaseModal';
import { remove as removeEnterpriseCalendar } from '../../../../ducks/entities/calendar/calendarOperations';
import LoadingView from './LoadingView';
import EnsureDeleteCalendarView from './EnsureDeleteCalendarView';
import CalendarsInUseView from './CalendarsInUseView';
import { actions as notificationActions } from '../../../../ducks/ui/notification';
import { createCsrfHeader } from '../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../ducks/currentUser/currentUserTypes';
import { enterpriseCalendarRegExp } from '../../../../helpers';
import styles from './DeleteCalendarModal.module.scss';

export type PropsT = {|
  enterpriseId: string,
  calendarId: string,
  calendarName: string,
  onClose: () => void
|};

const PROCESS_CHECK_DELAY = 2000;

const DeleteCalendarModal = (props: PropsT): Element<typeof BaseModal> => {
  const { onClose, enterpriseId, calendarName, calendarId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const cancelRequest = React.useRef<Canceler>();
  const [calendarServices, setCalendarServices] = useState([]);
  const [uuid, setUuid] = useState();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [numberOfServicesToCheck, setNumberOfServicesToCheck] = useState(0);
  const [numberOfServicesChecked, setNumberOfServicesChecked] = useState(0);

  const loadCalendarServices = async () => {
    const response: $AxiosXHR<string> = await axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/calendarservices`,
      data: { calendarName },
      headers: createCsrfHeader(currentUser)
    });

    if (response && response.status === HTTP.OK) {
      setUuid(response.data);
    }
  };

  useEffect(() => {
    loadCalendarServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopCalendarSearch = () => {
    axios({
      method: 'POST',
      // $FlowFixMe: enterpriseId already null checked
      url: `/api/v1/enterprises/${enterpriseId}/calendarservices/done?uuid=${uuid}`,
      cancelToken: new CancelToken(canceler => {
        cancelRequest.current = canceler;
      }),
      headers: createCsrfHeader(currentUser)
    });
  };

  useEffect(() => {
    let id;
    if (uuid) {
      id = setInterval(async () => {
        try {
          const response = await axios({
            method: 'GET',
            // $FlowFixMe: enterpriseId already null checked
            url: `/api/v1/enterprises/${enterpriseId}/calendarservices/process?uuid=${uuid}`,
            cancelToken: new CancelToken(canceler => {
              cancelRequest.current = canceler;
            })
          });
          if (response) {
            setNumberOfServicesToCheck(response.data.toBeSearched);
            setNumberOfServicesChecked(response.data.searched);
            if (response.data.searched === response.data.toBeSearched) {
              setCalendarServices(response.data.calendarServiceItems);
              setIsLoadingData(false);
              clearInterval(id);
              stopCalendarSearch();
            }
          }
        } catch (e) {
          console.log(e);
        }
      }, PROCESS_CHECK_DELAY);
    }
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCalendarDeleteNotification = success => {
    const notification = {
      tag: success ? 'calendar-delete-success' : 'calendar-delete-fail',
      duration: 15000,
      type: success ? 'info' : 'error',
      message: success
        ? t('calendars.deleteCalendar.successNotification')
        : t('calendars.deleteCalendar.failedNotification')
    };

    dispatch(notificationActions.createCreateNotificationAction(notification));
  };

  const removeCalendar = async () => {
    const removeSuccessful = await dispatch(
      removeEnterpriseCalendar(
        enterpriseId,
        calendarId,
        CancelToken.source().token,
        createCsrfHeader(currentUser)
      )
    );
    handleCalendarDeleteNotification(!!removeSuccessful);
    onClose();
  };

  const fixedCalendarName = calendarName.replace(enterpriseCalendarRegExp, ' ');

  const resultView =
    calendarServices.length > 0 ? (
      <CalendarsInUseView
        enterpriseId={enterpriseId}
        calendarServices={calendarServices}
        calendarName={fixedCalendarName}
        onClose={onClose}
      />
    ) : (
      <EnsureDeleteCalendarView
        onDelete={() => removeCalendar()}
        onCancel={onClose}
        calendarName={fixedCalendarName}
      />
    );

  return (
    <BaseModal modalStyles={[styles.modal]} onClose={onClose} onClickOutsideClose>
      {isLoadingData ? (
        <LoadingView
          numberOfServicesToCheck={numberOfServicesToCheck}
          numberOfServicesChecked={numberOfServicesChecked}
        />
      ) : (
        resultView
      )}
    </BaseModal>
  );
};

export default DeleteCalendarModal;
