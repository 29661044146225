import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvestorsRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.375 9.25C9.925 9.25 12 7.175 12 4.625S9.925 0 7.375 0 2.75 2.075 2.75 4.625 4.825 9.25 7.375 9.25m0-7.75c1.723 0 3.125 1.402 3.125 3.125S9.098 7.75 7.375 7.75 4.25 6.348 4.25 4.625 5.652 1.5 7.375 1.5m2.569 10.769A6.006 6.006 0 0 0 1.5 17.75a.75.75 0 0 1-1.5 0c0-4.136 3.364-7.5 7.5-7.5 1.064 0 2.092.218 3.056.649a.75.75 0 0 1-.612 1.37M17.25 10.5c-3.722 0-6.75 3.028-6.75 6.75S13.528 24 17.25 24 24 20.972 24 17.25s-3.028-6.75-6.75-6.75m0 12c-2.895 0-5.25-2.355-5.25-5.25S14.355 12 17.25 12s5.25 2.355 5.25 5.25-2.355 5.25-5.25 5.25m2.632-3.123a.75.75 0 0 1-.229 1.035 3.73 3.73 0 0 1-2.012.588 3.744 3.744 0 0 1-3.665-3h-.246a.75.75 0 0 1 0-1.5h.248a3.745 3.745 0 0 1 3.662-2.979 3.73 3.73 0 0 1 2.014.589c.349.224.45.688.228 1.036a.75.75 0 0 1-1.036.228 2.23 2.23 0 0 0-1.205-.353c-.967 0-1.785.619-2.098 1.479h1.708a.75.75 0 1 1-.001 1.5h-1.714c.307.87 1.13 1.5 2.105 1.5.429 0 .846-.122 1.206-.352a.747.747 0 0 1 1.035.229"/></svg>)}
    />
  );
}
  