import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAttachmentFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.2 10.8 13 21.1c-2.8 2.9-7.5 2.9-10.3 0s-2.9-7.5 0-10.3L11 2.3c2.1-2 5.3-2 7.4.1 1.9 2 2 5.1.1 7.1L10 18.1c-1.2 1.2-3.2 1.2-4.4 0s-1.2-3.2 0-4.4L14 5.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4L7 15.1c-.4.4-.4 1.1 0 1.5s1.1.4 1.5 0L17 8.3l.1-.1c1.3-1.2 1.3-3.2.1-4.5s-3.2-1.4-4.5-.2l-.2.2L4 12.1c-2 2.1-2 5.5 0 7.5 2.1 2.1 5.4 2.1 7.5 0L21.8 9.4c.4-.4 1-.4 1.4 0s.4 1 0 1.4"/></svg>)}
    />
  );
}
  