import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowDownRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m19.54 9-6.64 6.9c-.239.24-.562.376-.9.38-.339 0-.663-.137-.9-.38L4.46 9a.75.75 0 0 1 1.08-1L12 14.67 18.46 8a.75.75 0 0 1 1.08 1"/></svg>)}
    />
  );
}
  