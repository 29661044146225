// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OcIcon } from '../../../../../assets/callflow/details/oc-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../components/view/children/CallflowItem';

export type PropsT = {|
  callflowId: string
|};

export const OcDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const speeddial = useSelector(state => state.entities.callFlow.byId[callflowId]);

  return (
    <CallflowDetails
      callflowId={speeddial.id}
      elementType="OC"
      icon={<OcIcon />}
      title={t('callflows.viewOcDetails.title')}
      hideButton
      active
    >
      <CallflowDetailsColumn id="oc-details-column-1">
        <CallflowItem
          id="destination"
          title={t('callflows.viewOcDetails.destination')}
          value={speeddial.externalDestination}
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default OcDetails;
