// @flow
import React, { type Element, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { goToCreateExternalUser } from '../../navigationOperations';
import useUsersSearch from './useUsersSearch';
import InputField from '../callFlows/components/edit/children/InputField';
import DropdownField from '../callFlows/components/edit/children/DropdownField';
import { PEOPLE_CATEGORY_KEYS } from '../../constants';
import {
  createUpdateSearchTerm,
  createUpdateSelectedCategory
} from '../../ducks/ui/user/userUiActions';
import { modifySearchForPhoneNumber } from '../../ducks/entities/directory/directoryUtils';
import CreateButton from '../../components/Button/CreateButton';

import styles from './UsersSearch.module.scss';

export type PropsT = {|
  currentEnterpriseId: string,
  sortTerm: string,
  pageToSearch: number
|};

export const UsersSearch = (props: PropsT): Element<'div'> => {
  const dispatch = useDispatch();

  // form
  const { watch, setValue } = useFormContext();
  const values = watch();

  const { currentEnterpriseId, sortTerm, pageToSearch } = props;
  const { searchUsers } = useUsersSearch(currentEnterpriseId, sortTerm, values.categoryField);
  const { t } = useTranslation();
  const selectedCategory = useSelector(state => state.ui.user.selectedCategory);
  const searchTerm = useSelector(state => state.ui.user.searchTerm);
  const [searchKey, setSearchKey] = useState(0);

  const generateCategorySelectionMap = () =>
    PEOPLE_CATEGORY_KEYS.map(key => ({
      value: key,
      label: t(`generic.searchCategoryStatuses.${key}`)
    }));
  const categoryOptions = generateCategorySelectionMap();
  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  // For checking that value is actually changed when opening details view
  const prevSearchField = usePrevious(values.searchField);
  const prevCategoryField = usePrevious(values.categoryField);

  useEffect(() => {
    if (
      currentEnterpriseId &&
      values.categoryField &&
      ((prevCategoryField && prevCategoryField.value !== values.categoryField.value) ||
        prevSearchField !== values.searchField)
    ) {
      searchUsers(
        modifySearchForPhoneNumber(values.searchField),
        sortTerm,
        values.categoryField.value,
        1
      );
    }
    if (selectedCategory !== values.categoryField.value) {
      setValue('searchField', '', {
        shouldValidate: false,
        shouldDirty: true
      });
      setSearchKey(new Date().getTime());
      dispatch(createUpdateSelectedCategory(values.categoryField.value));
    }
    if (searchTerm !== values.searchField) {
      dispatch(createUpdateSearchTerm(modifySearchForPhoneNumber(values.searchField)));
      if (values.searchField === '') {
        setSearchKey(new Date().getTime());
      }
    }

    return () => {
      dispatch(createUpdateSearchTerm(''));
    };
  }, [values.searchField, values.categoryField]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentEnterpriseId && values.categoryField) {
      searchUsers(values.searchField, sortTerm, values.categoryField.value, pageToSearch);
    }
  }, [pageToSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles['upper-container']}>
      <div id="searchContainer" className={styles['search-container']}>
        <InputField
          key={searchKey}
          field="searchField"
          placeholder={t('users.usersSearchFieldPlaceholder')}
          type="search"
          maxLength={50}
          containerStyle={styles.input}
          inputStyle={styles.input}
          autofocus
        />
        <DropdownField
          field="categoryField"
          options={categoryOptions}
          className={styles.dropdown}
          innerClassName={styles['category-field']}
        />
      </div>
      <div className={styles['external-link-container']} id="external-contact-link-button-area">
        <CreateButton
          id="external-contact-link-button"
          text={t('users.addExternalContact')}
          showTextAlways
          containerStyle={styles['create-button']}
          onClickAction={() => dispatch(goToCreateExternalUser(currentEnterpriseId))}
        />
      </div>
    </div>
  );
};

export default UsersSearch;
