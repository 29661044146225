// @flow

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { push } from 'connected-react-router-for-react18';
import Tour from '../components/Tour';
import Feedback from '../components/Feedback';
import { actionCreators as action, selectors as currentUserSelectors } from '../ducks/currentUser';
import { selectors as headerSelectors } from '../ducks/ui/header';
import { createRedirectParam, isBrowserRunningE2eTests } from '../helpers';
import Enterprises from '../scenes/enterprises/Enterprises';
import { goTo } from '../navigationOperations';
import { useInterval } from '../utils/useInterval';
import { useCancelToken } from '../utils/useCancelToken';
import EnterpriseRoutes from './EnterpriseRoutes';
import OnboardingModal from '../scenes/OnboardingModal/OnboardingModal';
import ChangelogModal from '../scenes/ChangelogModal/ChangelogModal';
import ExportModal from '../scenes/ExportModal/ExportModal';
import NoPermissionError from '../components/Error/NoPermissionError';
import AdminUi from '../scenes/AdminUi/AdminUi';
import { getOmaRingSessionCookie } from '../ducks/currentUser/currentUserOperations';
import IdleTimeOutHandler from '../IdleTimeOutHandler';
import styles from '../main.module.scss';

const ELISA_SSO_COOKIE_CHECK_DELAY = 120000; // every 2 minutes

const ProtectedRoutes = (): Element<'div'> => {
  // redux
  const dispatch = useDispatch();
  const hasOmaRingSession: boolean = useSelector(state => currentUserSelectors.hasSession(state));
  const modalOpen: boolean = useSelector(state => headerSelectors.modalIsOpen(state));
  const currentUser = useSelector(state => state.currentUser);
  const { createNewCancelToken } = useCancelToken();

  // Unauthorized (don't run on Cypress)
  if (!hasOmaRingSession && !isBrowserRunningE2eTests()) {
    dispatch(goTo(`/login${createRedirectParam()}`));
  }

  useInterval(() => {
    if (!getOmaRingSessionCookie()) {
      dispatch(action.createLogoutSuccess());
      dispatch(push(`/login${createRedirectParam()}`));
    }
    if (
      isBrowserRunningE2eTests() ||
      (currentUser && (currentUser.loginType || []).includes('DIRECT_PLATFORM_LOGIN')) ||
      (currentUser.loginType || []).includes('BEHALF_OF_RING_USER')
    ) {
      // Skip Elisa_SSO_Session validation if using direct platform credentials or user login
      return;
    }
    try {
      axios({
        method: 'GET',
        url: `/api/v1/session`,
        cancelToken: createNewCancelToken()
      });
    } catch (e) {
      console.log('Elisa_SSO_Cookie has expired!');
    }
  }, ELISA_SSO_COOKIE_CHECK_DELAY);

  return (
    <div>
      <IdleTimeOutHandler />
      <div className={styles['main-content']} style={modalOpen ? { position: 'fixed' } : {}}>
        <div>
          <Switch>
            <Route path="/enterprises/:id" component={EnterpriseRoutes} />
            <Route exact path="/enterprises" component={Enterprises} />
            <Route exact path="/adminui" component={AdminUi} />
            <Route exact path="/no-permission" component={NoPermissionError} />
            <Redirect to="/404" />
          </Switch>
        </div>
      </div>
      <Tour />
      <Feedback />
      <OnboardingModal />
      <ChangelogModal />
      <ExportModal />
    </div>
  );
};

export default ProtectedRoutes;
