import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTickLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.8 8.1c.1.1.2.2.2.4 0 .1 0 .3-.1.4l-7 8c0 .1-.1.1-.2.1h-.4c-.1 0-.1-.1-.2-.1l-4-4s-.1-.1-.1-.2v-.4c0-.1.1-.1.1-.2s.1-.1.2-.1h.4c.1 0 .1.1.2.1l3.6 3.6 6.6-7.6c.1 0 .2-.1.4-.1.1 0 .2 0 .3.1M24 12c0 6.6-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0s12 5.4 12 12m-1 0c0-6.1-4.9-11-11-11S1 5.9 1 12s4.9 11 11 11 11-4.9 11-11" className="st0"/></svg>)}
    />
  );
}
  