// @flow

import React, { type Element, useState } from 'react';
import type Moment from 'moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from '../../../../../../assets/clock.svg';
import TimeRangePicker from '../../../../../../components/DateTimePicker/TimeRangePicker';
import ConfirmButton from '../../../../../../components/Button/ConfirmButton';
import ActionButton from '../../../../../../components/Button/ActionButton';
import styles from './EditTimeSlotModal.module.scss';

export type PropsT = {
  start: Moment,
  end: Moment,
  // eslint-disable-next-line react/no-unused-prop-types
  value: string,
  onRemove: () => void,
  // eslint-disable-next-line flowtype/no-weak-types
  onSave: any => void,
  // eslint-disable-next-line react/no-unused-prop-types
  actionType: string
};

const EditTimeSlotModal = (props: PropsT): Element<'div'> => {
  const { onSave, onRemove, start, end } = props;
  const { t } = useTranslation();
  const [timeRange, setTimeRange] = useState<{ start: Moment, end: Moment } | null>({
    start,
    end
  });
  const handleOnChange = range => {
    const newRange = {
      start: timeRange
        ? timeRange.start.set({
            hour: range.start.get('hours'),
            minute: range.start.get('minutes')
          })
        : moment(),
      end: timeRange
        ? timeRange.end.set({ hour: range.end.get('hours'), minute: range.end.get('minutes') })
        : moment()
    };
    setTimeRange(newRange);
  };
  const handleOnSave = () => {
    if (timeRange) {
      onSave(timeRange);
    }
  };

  return (
    <div className="customModal">
      <div className={styles['title-area']}>
        <div className={styles['icon-area']}>
          <ClockIcon />
        </div>
        {t('generic.dateTimePicker.timeTitle')}
      </div>
      <TimeRangePicker
        initialStartTime={start}
        initialEndTime={end}
        translateMock={t}
        validationMessage={t('calendars.rangeValidationError')}
        onChange={handleOnChange}
      />
      <ActionButton
        id="timeslot-save-button"
        className={styles['save-button']}
        label={t('timeslot.save')}
        onClickAction={handleOnSave}
      />
      <ConfirmButton
        id="timeslot-remove-button"
        className={styles['remove-button']}
        label={t('timeslot.remove')}
        onClickAction={onRemove}
      />
    </div>
  );
};

export default EditTimeSlotModal;
