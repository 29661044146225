import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBroadbandRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.41 23.25h-.12a.75.75 0 0 1-.62-.86q.072-.392.08-.79a5.35 5.35 0 0 0-5.35-5.35 5 5 0 0 0-.78.07.75.75 0 1 1-.24-1.479 6.5 6.5 0 0 1 1-.09 6.86 6.86 0 0 1 6.85 6.84q-.005.505-.1 1a.75.75 0 0 1-.72.659m7.79-.68c0-.31.05-.62.05-.94-.011-7.665-6.225-13.874-13.89-13.88-.31 0-.62 0-.93.05a.75.75 0 1 0 .14 1.49h.79c6.821.005 12.357 5.519 12.39 12.34v.8a.75.75 0 0 0 .68.82h.06a.74.74 0 0 0 .71-.68m7 0v-.91C23.195 10.137 13.873.789 2.35.75h-.89a.76.76 0 0 0-.71.8.77.77 0 0 0 .79.7h.81c10.706.011 19.383 8.684 19.4 19.39v.81a.74.74 0 0 0 .677.798l.023.002a.74.74 0 0 0 .76-.7z"/></svg>)}
    />
  );
}
  