// @flow
import React, { type Element } from 'react';
import styles from './ErrorPageBase.module.scss';

export type PropsT = {|
  +children: Element<*>[]
|};

const ErrorPageBase = (props: PropsT): Element<'div'> => {
  return (
    <div>
      <div className={styles.container} />
      {/* eslint-disable-next-line react/prop-types */}
      <div className={styles.error}>{props.children}</div>
    </div>
  );
};

export default ErrorPageBase;
