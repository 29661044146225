import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCustomerServiceFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 1.5c-2 0-3.9.7-5.3 2.1-1.2 1.1-2 2.6-2.1 4.2H5c.4 0 .8.1 1.1.4s.5.7.5 1.1v7.5c0 .4-.2.8-.5 1.1s-.7.4-1.1.4h-.9c-1.1 0-2.1-.4-2.9-1.1-.7-.7-1.2-1.7-1.2-2.7v-3c0-1 .5-2 1.2-2.7.5-.4 1.2-.8 1.8-.9.2-2 1.1-4 2.6-5.4C7.3.9 9.6 0 12 0s4.7.9 6.3 2.5c1.5 1.4 2.5 3.4 2.6 5.4q1.05.3 1.8.9c.8.7 1.2 1.7 1.2 2.7v3c0 1-.5 2-1.2 2.7q-.75.75-1.8.9v.6c0 1-.4 2-1.2 2.7-.7.7-1.7 1.1-2.7 1.1h-1.9c-.1.3-.3.6-.5.9-.4.4-.8.6-1.4.6h-1.4c-.5 0-1-.2-1.4-.6-.2-.4-.4-.9-.4-1.4s.2-1 .6-1.4.8-.6 1.4-.6h1.4c.5 0 1 .2 1.4.6.1.1.2.3.3.4h2.1c.6 0 1.3-.2 1.7-.7.4-.4.7-1 .7-1.6v-.5h-.4c-.4 0-.8-.1-1.1-.4s-.5-.7-.5-1.1V9.2c0-.4.2-.8.5-1.1s.7-.4 1.1-.4h.4q-.3-2.4-2.1-4.2c-1.6-1.3-3.5-2-5.5-2" className="st0"/></svg>)}
    />
  );
}
  