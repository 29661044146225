// @flow

import classNames from 'classnames';
import React, { Component, type Element } from 'react';
import { type TableHeaderColumnT } from './TableHeader';
import Avatar from '../Avatar/Avatar';
import styles from './TableRow.module.scss';

export type TableCellT = {
  emphasized?: boolean,
  valueClasses?: string[],
  size: 'x-small' | 'small' | 'medium' | 'large' | 'x-large',
  border?: boolean,
  value: string
};

export type TableRowItemT = {
  id: string,
  rowId: string,
  isSelected?: boolean,
  rowClasses?: string[],
  externalLink?: string,
  action: Element<'div'>,
  name?: Element<'div'>,
  nameDescription?: Element<'div' | 'span'>,
  avatar?: Element<typeof Avatar>
};

export type OnSelectFnT = TableRowItemT => *;
export type HoverButtonT = {
  label: string,
  onClick: OnSelectFnT
};

export type PropsT = {
  cells?: TableCellT[],
  headerCells?: TableHeaderColumnT[],
  rowItem: TableRowItemT,
  hoverEnabled: boolean,
  hoverButton: ?HoverButtonT,
  onSelect: ?OnSelectFnT
};

// eslint-disable-next-line react/prefer-stateless-function
export class TableRow extends Component<PropsT> {
  static defaultProps = {
    cells: [],
    headerCells: []
  };

  render(): Element<'a'> {
    const {
      cells = [],
      headerCells = [],
      hoverEnabled,
      hoverButton,
      onSelect,
      rowItem
    } = this.props;
    const handleSelect = () => {
      if (onSelect) {
        onSelect(rowItem);
      }
    };
    const handleHoverButtonClicked = () => {
      if (hoverButton) {
        hoverButton.onClick(rowItem);
      }
    };
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <a href={rowItem.externalLink} className={styles.link} id="forwarding-table-row">
        <div
          className={classNames(
            styles.row,
            ...(rowItem.rowClasses || []),
            hoverEnabled ? styles['hover-enabled'] : ''
          )}
        >
          <div className={styles['check-container']}>
            {rowItem.isSelected ? (
              <img src="/checkmark-blue-small.svg" alt="check" />
            ) : (
              <img className={styles.check} src="/checkmark-light-grey-small.svg" alt="check" />
            )}
          </div>
          <div
            id={rowItem.rowId}
            className={styles.cells}
            onClick={handleSelect}
            onKeyPress={handleSelect}
          >
            {cells.map(
              (
                { value, emphasized, valueClasses, size, border }: TableCellT,
                index: number
              ): Element<'div'> => (
                <div
                  className={classNames(
                    styles.cell,
                    emphasized ? styles.emphasized : '',
                    ...(headerCells[index].columnClasses || []),
                    styles[size],
                    index === 1 ? styles.first : '',
                    border ? styles.border : ''
                  )}
                  key={
                    `ea-cell-${rowItem.rowId}-${index}` // eslint-disable-line react/no-array-index-key
                  }
                  id={`row-${rowItem.rowId}-column-${headerCells[index].columnId}`}
                >
                  <span className={classNames(styles['cell-content'], ...(valueClasses || []))}>
                    {value}
                  </span>
                </div>
              )
            )}
          </div>
          {hoverEnabled && hoverButton && (
            <div className={styles['hover-button-container']}>
              <div
                id={`${rowItem.rowId}-hoverButton`}
                className={styles['hover-button']}
                onClick={handleHoverButtonClicked}
                onKeyPress={handleHoverButtonClicked}
                tabIndex={-1}
                role="button"
              >
                {hoverButton.label}
              </div>
            </div>
          )}
        </div>
      </a>
    );
  }
}

export default TableRow;
