import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoSpotifyRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1m5.045 15.866a.685.685 0 0 1-.943.228c-2.583-1.579-5.834-1.935-9.663-1.061a.685.685 0 1 1-.305-1.337c4.19-.958 7.784-.546 10.684 1.226a.69.69 0 0 1 .227.944m1.346-2.995a.86.86 0 0 1-1.18.282c-2.956-1.817-7.464-2.343-10.961-1.282a.858.858 0 0 1-.498-1.641c3.995-1.212 8.962-.625 12.357 1.461.403.249.53.777.282 1.18m.116-3.119c-3.546-2.106-9.395-2.3-12.779-1.272a1.03 1.03 0 0 1-.598-1.969c3.886-1.179 10.345-.952 14.427 1.471a1.029 1.029 0 0 1-1.05 1.77"/></svg>)}
    />
  );
}
  