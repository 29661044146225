import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGroupFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 7.5C3 5.019 5.019 3 7.5 3S12 5.019 12 7.5c0 2.482-2.019 4.501-4.5 4.501S3 9.982 3 7.5m4.497 6.002C3.363 13.502 0 16.865 0 21a.5.5 0 0 0 .5.5h13.994a.5.5 0 0 0 .5-.5c0-4.135-3.363-7.498-7.497-7.498m10.503-.5c2.206 0 4-1.795 4-4.001s-1.794-4-4-4-4 1.794-4 4 1.794 4.001 4 4.001m-.5 1.5c-.855 0-1.691.167-2.485.495a.498.498 0 0 0-.215.754 9.07 9.07 0 0 1 1.694 5.202.5.5 0 0 0 .5.497H23.5a.5.5 0 0 0 .5-.504c-.031-3.553-2.947-6.444-6.5-6.444"/></svg>)}
    />
  );
}
  