// @flow

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GenericErrorSVG } from '../../assets/generic-error.svg';
import BaseContainer from '../../scenes/BaseContainer/BaseContainer';
import TopNavigation from '../../scenes/navigation/TopNavigation';

import styles from './NoPermissionError.module.scss';

export const NoPermissionError = (): Element<typeof BaseContainer> => {
  const { t } = useTranslation();

  return (
    <BaseContainer header={<TopNavigation />}>
      <div className={styles.error}>
        <div>
          <GenericErrorSVG />
          <div className={styles.message}>{t('noPermissionError.message')}</div>
        </div>
      </div>
    </BaseContainer>
  );
};

export default NoPermissionError;
