// @flow

import React, { type Element } from 'react';
import { connect } from 'react-redux';
import Truncate from 'react-truncate-markup';
import classnames from 'classnames';
import { useTranslation, WithTranslationProps } from 'react-i18next';
import type { ExactPropsT } from '../../../commonTypes';
import type { UserStateEntityT } from '../../../ducks/entities/user/userTypes';
import Avatar from '../../../components/Avatar/Avatar';
import { getAvailability } from '../../../helpers';
import styles from './UserCarouselCard.module.scss';

type OwnPropsT = {
  user?: UserStateEntityT,
  onClick?: (userId: string) => *
};

type StatePropsT = {};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

export const UserCarouselCard = ({ user, onClick }: PropsT): Element<'div'> | null => {
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  const fullName = [user.firstName, user.lastName].join(' ').trim();
  const userAvailability = getAvailability(user, t);
  const avatarProps = {
    src: user.urlPhoto,
    size: 'medium',
    name: fullName,
    color: user.userType === 'internalUser' ? '#0019AF' : '#002447',
    innerClassName: 'avatar-image',
    ...(user.userType === 'internalUser'
      ? { availability: userAvailability.icon }
      : { hideAvailability: true })
  };

  const userDescription = user.userType === 'internalUser' ? user.title : user.company;
  return (
    <div
      id={`user-card-${user.id}`}
      className={classnames(styles.card, 'user-carousel-card')}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      <div className={styles['avatar-container']}>
        <Avatar {...avatarProps} />
      </div>
      <div className={classnames(styles['text-container'], styles['text-container--large'])}>
        <div className={styles['text-container-wrapper']}>
          <h4>
            <Truncate lines={2}>
              <div>{fullName || t('generic.defaultUserName')}</div>
            </Truncate>
          </h4>
          <div className={styles.title}>
            <Truncate lines={1}>
              <div>{userDescription || ' \u00A0 '}</div>
            </Truncate>
          </div>
        </div>
      </div>
      <div className={classnames(styles['text-container'], styles['text-container--medium'])}>
        <div className={styles['text-container-wrapper']}>
          <h4>
            <Truncate lines={1}>
              <div>{user.firstName || t('generic.defaultUserName')}</div>
            </Truncate>
          </h4>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(UserCarouselCard);
