// @flow

/* eslint-disable no-underscore-dangle */

import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import type { InternalUserStateEntityT, UserStateEntityT } from './userTypes';

type InternalUsersByIdsAndEnterpriseIdFnT = (
  StoreStateT,
  string[],
  ?string
) => InternalUserStateEntityT[];
export const internalByIdsAndEnterpriseId: InternalUsersByIdsAndEnterpriseIdFnT = (
  { entities: { user } },
  ids,
  enterpriseId
) =>
  ids
    .filter(
      id =>
        enterpriseId != null &&
        id in user.byId &&
        !id.startsWith('-') &&
        user.byId[id].enterpriseId === enterpriseId &&
        user.byId[id].userType === 'internalUser'
    )
    .map(id => user.byId[id]);

type ByIdsAndEnterpriseIdFnT = (StoreStateT, string[], ?string) => UserStateEntityT[];
export const byIdsAndEnterpriseId: ByIdsAndEnterpriseIdFnT = (
  { entities: { user } },
  ids,
  enterpriseId
) =>
  ids
    .filter(
      id =>
        enterpriseId != null &&
        !id.startsWith('-') &&
        id in user.byId &&
        user.byId[id].enterpriseId === enterpriseId
    )
    .map(id => user.byId[id]);

type ByEnterpriseIdFnT = (StoreStateT, string) => UserStateEntityT[];
export const byEnterpriseId: ByEnterpriseIdFnT = ({ entities: { user } }, enterpriseId) =>
  user.allIds
    .filter(id => user.byId[id].enterpriseId === enterpriseId)
    .filter(id => !id.startsWith('-'))
    .map(id => user.byId[id]);

type CollectionIsLoadingFnT = StoreStateT => boolean;
export const collectionIsLoading: CollectionIsLoadingFnT = ({ entities: { user } }) =>
  user.__metadata.isRetrieving === true || user.__metadata.isSearching === true;

type CollectionHasErrorFnT = StoreStateT => boolean;
export const collectionHasError: CollectionHasErrorFnT = ({ entities: { user } }) =>
  user.__metadata.error !== undefined;

type IsLoadingFnT = (StoreStateT, string) => boolean;
export const isLoading: IsLoadingFnT = ({ entities: { user } }, userId): boolean =>
  R.path(['byId', userId, '__metadata', 'isRetrieving'], user) === true;

type IsUpdatingFnT = (StoreStateT, string) => boolean;
export const isUpdating: IsUpdatingFnT = ({ entities: { user } }, userId): boolean =>
  R.path(['byId', userId, '__metadata', 'isUpdating'], user) === true;

type IsResettingPasswordFnT = (StoreStateT, string) => boolean;
export const isResettingPassword: IsResettingPasswordFnT = (
  { entities: { user } },
  userId
): boolean => R.path(['byId', userId, '__metadata', 'isResettingPassword'], user) === true;

type CollectionIsExportingFnT = StoreStateT => boolean;
export const collectionIsExporting: CollectionIsExportingFnT = ({ entities: { user } }) =>
  user.__metadata.isExporting === true;

type CollectionExportedFnT = StoreStateT => number;
export const collectionExported: CollectionExportedFnT = ({ entities: { user } }) =>
  user.__metadata.exported ? user.__metadata.exported : 0;

type CollectionToBeExportedFnT = StoreStateT => number;
export const collectionToBeExported: CollectionToBeExportedFnT = ({ entities: { user } }) =>
  user.__metadata.toBeExported ? user.__metadata.toBeExported : 0;

type CollectionExportUuidFnT = StoreStateT => string;
export const collectionExportUUID: CollectionExportUuidFnT = ({ entities: { user } }) =>
  user.__metadata.exportUUID;

type CollectionIsCreatingFnT = StoreStateT => boolean;
export const collectionIsCreating: CollectionIsCreatingFnT = ({ entities: { user } }) =>
  user.__metadata.isCreating === true;

type HasErrorFnT = (?UserStateEntityT) => boolean;
export const hasError: HasErrorFnT = user => R.path(['__metadata', 'error'], user) !== undefined;

type HasDeleteErrorFnT = (?UserStateEntityT) => boolean;
export const hasDeleteError: HasDeleteErrorFnT = user =>
  R.path(['__metadata', 'error'], user) !== undefined &&
  R.path(['__metadata', 'deleting'], user) !== undefined;

type HasDeleteErrorOnUserIdFnT = (userById: {}, string) => boolean;
export const hasDeleteErrorOnUserId: HasDeleteErrorOnUserIdFnT = (usersById, userId) =>
  R.path(['__metadata', 'error'], usersById[userId]) !== undefined &&
  R.path(['__metadata', 'deleting'], usersById[userId]) !== undefined;

type HasPasswordResetErrorByIdFnT = (StoreStateT, string) => boolean;
export const hasPasswordResetErrorById: HasPasswordResetErrorByIdFnT = (
  { entities: { user } },
  userId
) => R.path(['byId', userId, '__metadata', 'resetError'], user) !== undefined;

type HasPasswordResetEmailFieldErrorFnT = (StoreStateT, string) => boolean;
export const hasPasswordResetEmailFieldError: HasPasswordResetEmailFieldErrorFnT = (
  { entities: { user } },
  userId
) => R.path(['byId', userId, '__metadata', 'resetError'], user) !== undefined;

type ByDepartmentIdFnT = (StoreStateT, string, string) => InternalUserStateEntityT[];
export const byDepartmentId: ByDepartmentIdFnT = (
  { entities: { user } },
  enterpriseId,
  departmentId
) =>
  user.allIds
    .filter(
      id =>
        user.byId[id].userType === 'internalUser' &&
        user.byId[id].enterpriseId === enterpriseId &&
        !id.startsWith('-') &&
        R.path(['department', 'id'], user.byId[id]) === departmentId
    )
    .map(id => user.byId[id]);

type ByAddressNumberFnT = (StoreStateT, string, ?string) => InternalUserStateEntityT | void;
export const byAddressNumber: ByAddressNumberFnT = (
  { entities: { user } },
  enterpriseId,
  addressNumber
) => {
  // $FlowFixMe  flow is unable to refine with filter
  return R.head(
    user.allIds
      .filter(
        id =>
          user.byId[id].userType === 'internalUser' &&
          !id.startsWith('-') &&
          user.byId[id].enterpriseId === enterpriseId &&
          addressNumber !== undefined &&
          R.path(['addressNumber'], user.byId[id]) === addressNumber
      )
      .map(id => user.byId[id])
  );
};
