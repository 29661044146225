// @flow

import { type $AxiosError } from 'axios';
import type {
  FeedbackFailureT,
  FeedbackRequestT,
  FeedbackSuccessT,
  SelectEnterpriseT,
  LoginRequestT,
  LoginSuccessT,
  LoginFailureT,
  LogoutRequestT,
  LogoutSuccessT,
  LogoutFailureT,
  DisableFirstTimeViewT,
  CurrentUserT,
  UpdateConfigSuccessT,
  UpdateConfigRequestT,
  UpdateConfigFailureT,
  SsoLoginFailureT,
  SsoLoginMissingRoleFailureT,
  FetchCsrfFailureT,
  FetchCsrfRequestT,
  FetchCsrfSuccessT
} from './currentUserTypes';

export const createLoginRequest = (): LoginRequestT => ({
  type: 'ring/currentUser/LOGIN_REQUEST',
  error: false,
  meta: {}
});

export const createLoginSuccess = (currentUser: CurrentUserT): LoginSuccessT => ({
  type: 'ring/currentUser/LOGIN_SUCCESS',
  payload: currentUser,
  error: false,
  meta: {}
});

export const createLoginFailure = (error: $AxiosError<*>): LoginFailureT => ({
  type: 'ring/currentUser/LOGIN_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createLoginInvalidAccessRightFailure = (error: $AxiosError<*>): SsoLoginFailureT => ({
  type: 'ring/currentUser/ELISA_SSO_LOGIN_FAILURE_INVALID_ACCESS_RIGHTS',
  payload: error,
  error: true,
  meta: {}
});

export const createLoginMissingRoleAccessRightFailure = (
  error: $AxiosError<*>
): SsoLoginMissingRoleFailureT => ({
  type: 'ring/currentUser/RING_USER_LOGGED_WITHOUT_ADDITIONAL_ROLES',
  payload: error,
  error: true,
  meta: {}
});

export const createLogoutRequest = (): LogoutRequestT => ({
  type: 'ring/currentUser/LOGOUT_REQUEST',
  error: false,
  meta: {}
});

export const createLogoutSuccess = (): LogoutSuccessT => ({
  type: 'ring/currentUser/LOGOUT_SUCCESS',
  payload: undefined,
  error: false,
  meta: {}
});

export const createLogoutFailure = (error: $AxiosError<*>): LogoutFailureT => ({
  type: 'ring/currentUser/LOGOUT_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

type CreateDisableFirstTimeViewFnT = () => DisableFirstTimeViewT;
export const createDisableFirstTimeViewAction: CreateDisableFirstTimeViewFnT = () => ({
  type: 'ring/currentUser/DISABLE_FIRST_TIME_VIEW'
});

export type CreateSelectEnterpriseFnT = (string, string) => SelectEnterpriseT;
export const createSelectEnterpriseAction: CreateSelectEnterpriseFnT = (
  enterpriseId,
  enterpriseFullName
) => ({
  type: 'ring/currentUser/SELECT_ENTERPRISE',
  payload: { enterpriseId, enterpriseFullName }
});

export const createFeedbackRequest = (): FeedbackRequestT => ({
  type: 'ring/currentUser/FEEDBACK_REQUEST',
  error: false,
  meta: {}
});

export const createFeedbackSuccess = (): FeedbackSuccessT => ({
  type: 'ring/currentUser/FEEDBACK_SUCCESS',
  payload: {},
  error: false,
  meta: {}
});

export const createFeedbackFailure = (error: $AxiosError<*>): FeedbackFailureT => ({
  type: 'ring/currentUser/FEEDBACK_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createUpdateConfigRequest = (): UpdateConfigRequestT => ({
  type: 'ring/currentUser/UPDATE_CONFIG_REQUEST',
  error: false,
  meta: {}
});

export const createUpdateConfigSuccess = (currentUser: CurrentUserT): UpdateConfigSuccessT => ({
  type: 'ring/currentUser/UPDATE_CONFIG_SUCCESS',
  payload: currentUser,
  error: false,
  meta: {}
});

export const createUpdateConfigFailure = (error: $AxiosError<*>): UpdateConfigFailureT => ({
  type: 'ring/currentUser/UPDATE_CONFIG_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export const createFetchCsrfRequest = (): FetchCsrfRequestT => ({
  type: 'ring/currentUser/CSRF_REQUEST',
  error: false,
  meta: {}
});

export const createFetchCsrfSuccess = (csrf: string): FetchCsrfSuccessT => ({
  type: 'ring/currentUser/CSRF_SUCCESS',
  payload: { csrf },
  error: false,
  meta: {}
});

export const createFetchCsrfFailure = (error: $AxiosError<*>): FetchCsrfFailureT => ({
  type: 'ring/currentUser/CSRF_FAILURE',
  payload: error,
  error: true,
  meta: {}
});
