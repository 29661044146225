import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileXlsFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M15.3 7.2h5c.3 0 .5.2.5.5v14.5c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h8.4c.3 0 .5.2.5.5v5c0 1 .7 1.7 1.6 1.7m1 10.2c.8.4 1.1.8 1.1 1.3 0 .7-.5 1.4-1.6 1.4-.5 0-1.1-.2-1.5-.5l.3-1c.4.3.8.4 1.3.4.4 0 .5-.2.5-.4s-.1-.4-.8-.7c-.8-.3-1.1-.8-1.1-1.3 0-.7.5-1.3 1.6-1.3.5 0 1 .2 1.3.4l-.3.9c-.3-.2-.7-.4-1-.4-.4 0-.5.2-.5.4-.1.5.2.6.7.8m-5.8 2.7L9 17.8l1.5-2.2H9.2l-.9 1.5-.9-1.5H6.2l1.5 2.3-1.5 2.2h1.2l.9-1.5.9 1.5zm3.5 0v-1h-1.8v-3.6h-1V20H14zm5.8-14.2c.1 0 .2-.1.2-.2s0-.2-.1-.3L15.3.7c-.1-.1-.2-.1-.3-.1v4.9c0 .2.2.4.4.4z" clipRule="evenodd"/></svg>)}
    />
  );
}
  