// @flow

import * as Sentry from '@sentry/browser';
import { Component, type Element } from 'react';

type StateT = {
  hasError: boolean
};

type PropsT = {
  errorElement: Element<*>,
  children: *,
  showError?: boolean
};

export default class ErrorBoundary extends Component<PropsT, StateT> {
  static defaultProps = {
    showError: false
  };

  constructor(props: PropsT) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: *, info: *) {
    this.setState({ hasError: true });
    // $FlowFixMe
    Sentry.captureException(error, { extra: info });
  }

  render(): Element<*> {
    if (this.state.hasError || this.props.showError) {
      return this.props.errorElement;
    }
    return this.props.children;
  }
}
