// @flow
import React, { type Element, useEffect, useState } from 'react';
import TabNavigation from '@design-system/component-library/src/components/TabNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-named-default
import { default as axios, CancelToken, AxiosPromise } from 'axios';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import type {
  InternalUserEntityT,
  InternalUserStateEntityT
} from '../../../../ducks/entities/user/userTypes';
import InternalUserDetails from '../Internal/InternalUserDetails';
import PresenceDetails from './Presence/PresenceDetails';
import Notifications from '../../../../components/Notifications/Notifications';
import UserForwardings from '../../../callForwardings/userForwardings/UserForwardings';
import useEnterprise from '../../../useEnterprise';
import useForwardingActions from '../../../callForwardings/ForwardingDetails/form/useForwardingActions';
import callForwardingOperations from '../../../../ducks/entities/callForwarding/callForwardingOperations';
import DeleteForwardingDialog from '../../../callForwardings/components/DeleteForwardingDialog';
import type { PopUpDataT } from '../../../../ducks/ui/callForwardings/callForwardingsUiTypes';
import { operations as userOps } from '../../../../ducks/entities/user';
import styles from './EditInternalUser.module.scss';

export type PropsT = {
  onSave: InternalUserEntityT => Promise<void>,
  onClose: () => void,
  userId: string,
  showLoadingSpinner: boolean,
  showFixedTitle: boolean,
  onToggleFavourite: (selected: boolean) => *,
  showLoadingError?: boolean,
  reloadFunc: () => void,
  alwaysEdit?: boolean
};

const EditInternalUser = ({
  onSave,
  onClose,
  userId,
  showLoadingSpinner,
  showFixedTitle,
  onToggleFavourite,
  showLoadingError,
  reloadFunc,
  alwaysEdit
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user: InternalUserStateEntityT = useSelector(state => state.entities.user.byId[userId]);
  const { id: enterpriseId } = useParams();
  const { enterprise } = useEnterprise(enterpriseId);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const popUp: ?PopUpDataT = useSelector(state =>
    R.path(['ui', 'callForwardings', 'popup'], state)
  );
  const currentUser = useSelector(state => state.currentUser);
  const activeForwarding = popUp ? { forwarding: popUp.forwarding, user: popUp.user } : undefined;
  const [tabKey, setTabKey] = useState(moment().toISOString());

  useEffect(() => {
    setTabKey(moment().toISOString());
  }, [currentUser.appLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const { updateCallForwardingActiveState } = useForwardingActions({
    enterpriseId,
    action: 'modify'
  });
  const callFwdTabSelected: boolean = useSelector(state =>
    R.path(['router', 'location', 'state', 'callFwdTabSelected'], state)
  );

  const updateForwardingData = async () => {
    const userCancelToken = CancelToken.source();
    const addressNumber = user.addressNumber || '';

    await dispatch(
      callForwardingOperations.retrieveUserForwardingsByAddressNumber(
        enterpriseId,
        addressNumber,
        userCancelToken.token
      )
    );
    const userIdResponse: AxiosPromise<{ extensionId: string }> = await axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumber/${addressNumber}`,
      cancelToken: userCancelToken.token
    });
    await dispatch(
      userOps.retrieveForwardings(
        enterpriseId,
        userIdResponse.data.extensionId,
        userCancelToken.token
      )
    );
  };

  const onForwardingActivityChange = async forwarding => {
    if (forwarding) {
      await updateCallForwardingActiveState({
        isActive: !forwarding.isActive,
        id: forwarding.id
      });
      await updateForwardingData();
    }
  };

  return (
    <div className={styles['outer-form']}>
      <Notifications
        className={styles.notifications}
        tags={[
          'presence-create-success',
          'presence-create-failure',
          'presence-update-success',
          'presence-update-failure',
          'presence-delete-success',
          'presence-delete-failure',
          'callforwarding-deleted',
          'callforwarding-success',
          'callforwarding-fail',
          'internal-user-password-reset',
          'update-user-failure'
        ]}
      />
      <div className={styles['user-name']}>
        {user.firstName} {user.lastName}
      </div>
      <div>
        {deleteDialogOpen && (
          <DeleteForwardingDialog
            onClose={() => {
              setDeleteDialogOpen(false);
              updateForwardingData();
            }}
            activeForwarding={activeForwarding}
            enterpriseId={enterpriseId}
            forwardingType="user"
          />
        )}
        <TabNavigation className={styles.form} key={tabKey}>
          <TabNavigation.Tab
            title={t('editInternalUser.contactInfoTab')}
            selected={!callFwdTabSelected}
            id="contactInfoTab"
          >
            <InternalUserDetails
              onSave={onSave}
              userId={userId}
              onClose={onClose}
              showLoadingSpinner={showLoadingSpinner}
              showFixedTitle={showFixedTitle}
              onToggleFavourite={onToggleFavourite}
              showLoadingError={showLoadingError}
              reloadFunc={() => reloadFunc()}
              alwaysEdit={alwaysEdit}
            />
          </TabNavigation.Tab>
          <TabNavigation.Tab title={t('editInternalUser.presenceTab')} id="presenceTab">
            <PresenceDetails
              userId={userId}
              reloadFunc={() => {
                reloadFunc();
                updateForwardingData();
              }}
            />
          </TabNavigation.Tab>
          {enterprise ? (
            <TabNavigation.Tab
              title={t('editInternalUser.callForwardingTab')}
              selected={callFwdTabSelected}
              id="callForwardingTab"
            >
              <UserForwardings
                enterprise={enterprise}
                onForwardingActivityChange={onForwardingActivityChange}
                onDeleteForwarding={() => {
                  setDeleteDialogOpen(true);
                }}
                singleUser={user}
              />
            </TabNavigation.Tab>
          ) : null}
        </TabNavigation>
      </div>
    </div>
  );
};
export default EditInternalUser;
