// @flow
import React, { type Element } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../components/Tooltip';
import styles from './IntegerField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  inputDescription?: string,
  description?: string,
  postFixDescription?: string,
  maxLength?: number,
  shouldValidate?: boolean,
  showMinutes?: boolean,
  tooltip?: string,
  className?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  disabledFn?: any => boolean,
  errorMessage?: string
|};

export const IntegerField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    inputDescription,
    postFixDescription,
    maxLength,
    shouldValidate,
    tooltip,
    className,
    disabledFn,
    errorMessage,
    showMinutes
  } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const { t } = useTranslation();

  const postFixIsDefined = postFixDescription !== undefined && postFixDescription !== null;

  const disabledValue = disabledFn === undefined ? false : disabledFn(values);

  const convertSecondsToMinutesAndSeconds = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const postFixText = (): string => {
    let postText =
      postFixIsDefined && postFixDescription !== undefined
        ? postFixDescription
        : t('callflows.integerField.postFixDescription');
    if (showMinutes) {
      postText = `${postText} ${t('callflows.integerField.minutes', {
        minutes: convertSecondsToMinutesAndSeconds(values[field])
      })}`;
    }
    return postText;
  };

  return (
    <div className={classnames(styles['field-group-section'], className)}>
      <div className={styles['field-section']}>
        <div className={styles.title}>
          {title}
          {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div className={styles['field-section']}>
        <div className={styles['input-description']}>{inputDescription}</div>
        <div data-cy={field}>
          <Input
            name={field}
            data-cy="input"
            className={styles['integer-input']}
            onValueChange={e => {
              setValue(field, e.target.value, {
                shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                shouldDirty: true
              });
            }}
            defaultValue={`${values[field]}`}
            maxlength={maxLength || 3}
            touched
            i18n_input_errorMessage={errors[field] && errorMessage ? errorMessage : ''}
            disabled={disabledValue}
            i18n_input_helpText={postFixText()}
          />
        </div>
      </div>
    </div>
  );
};

export default IntegerField;
