import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTabletFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.8 17.8H3.2c-.1 0-.2.1-.2.2v4.5c0 .8.7 1.5 1.5 1.5h15c.8 0 1.5-.7 1.5-1.5V18c0-.1-.1-.2-.2-.2m-7.3 3.3h-3c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h3c.3 0 .6.3.6.6s-.3.6-.6.6M21 1.5v14.8c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2V1.5C3 .7 3.7 0 4.5 0h15c.8 0 1.5.7 1.5 1.5"/></svg>)}
    />
  );
}
  