// @flow strict-local

import React, { type Element, type ChildrenArray } from 'react';
import { CallflowItem } from './children/CallflowItem';
import { CallflowProperty } from './children/CallflowProperty';
import { CallflowAudioProperty } from './children/CallflowAudioProperty';
import { PropertyList } from './children/PropertyList';
import { WeekDayCalendar } from './children/calendar/WeekDayCalendar';
import { MemberList } from './children/MemberList/MemberList';
import { CallflowList } from './children/CallflowList';
import { WelcomeAttendantAudio } from '../../callFlowGrid/details/welcomeAttendant/message/WelcomeAttendantAudio';
import ErrorBoundary from '../../../../components/Error/ErrorBoundary';
import styles from './CallflowDetailsColumn.module.scss';
import { VoiceMailAudio } from '../edit/children/audio/VoiceMailAudio';
import { InvalidConfigurationInfo } from './children/InvalidConfigurationInfo';

type ChildTypesT =
  | 'div'
  | typeof CallflowProperty
  | typeof CallflowItem
  | typeof CallflowAudioProperty
  | typeof PropertyList
  | typeof MemberList
  | typeof CallflowList
  | typeof WelcomeAttendantAudio
  | typeof WeekDayCalendar
  | typeof VoiceMailAudio
  | typeof InvalidConfigurationInfo
  | typeof ErrorBoundary;

type PropsT = {|
  id: string,
  children?: ChildrenArray<Element<ChildTypesT> | null> | Element<ChildTypesT>,
  active?: boolean
|};

export const CallflowDetailsColumn = (props: PropsT): Element<'div'> => {
  const { id, children, active } = props;
  return (
    <div className={styles.details} key={id}>
      {React.Children.map(children, child =>
        child ? React.cloneElement(child, { active }) : null
      )}
    </div>
  );
};

export default CallflowDetailsColumn;
