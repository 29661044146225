import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAddressBookLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 0H4C2.9 0 2 .9 2 2v20c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2M3 22V2c0-.6.4-1 1-1h3v22H4c-.6 0-1-.4-1-1m18 0c0 .6-.4 1-1 1H8V1h12c.6 0 1 .4 1 1zM16.9 4h-4.8c-1.1-.1-2 .8-2.1 1.9V6c0 1.1 1 2 2.1 2h4.8C18 8 19 7.1 19 6s-.9-2-2-2zm0 3h-4.8c-.6 0-1.1-.4-1.1-1s.5-1 1.1-1h4.8c.6 0 1.1.4 1.1 1s-.5 1-1.1 1"/></svg>)}
    />
  );
}
  