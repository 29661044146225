// @flow

import React, { type Element } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../components/Tooltip';

import styles from './CallflowItem.module.scss';

type PropsT = {|
  id: string,
  title: string,
  value: string | Element<*> | Element<*>[],
  key?: string,
  active?: boolean,
  tooltip?: string | Element<*>
|};

export const CallflowItem = (props: PropsT): Element<'div'> => {
  const { id, key, title, value, active, tooltip } = props;
  const activeStyle = active ? styles.active : styles.inactive;
  const { t } = useTranslation();
  const displayTooltip =
    typeof tooltip === 'string' ? (
      <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>
    ) : (
      tooltip
    );
  return (
    <div className={styles.detail} key={key}>
      <div className={classnames(styles.header, activeStyle)}>
        {title}
        {tooltip && displayTooltip}
      </div>
      <div className={classnames(styles.value, activeStyle)} data-cy={id}>
        {value || t('callflows.valueNotSet')}
      </div>
    </div>
  );
};

export default CallflowItem;
