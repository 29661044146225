// @flow
import React, { useState } from 'react';

import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useSelector } from 'react-redux';
import type { Element } from 'react';
import { useTranslation } from 'react-i18next';
import GenericError from '../../components/Error/GenericError';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import type { SortOrderT } from '../../commonTypes';
import { ReactComponent as NoSearchResultsPic } from '../../assets/no-search-results.svg';
import type { TableRowItemT } from '../../components/Table/TableRow';
import type { SmallUserTableRowItemT } from '../../components/SmallUserTable/SmallUserTableRow';
import type { TableHeaderColumnT, TableSortT } from '../../components/Table/TableHeader';
import Table from '../../components/Table/Table';
import { selectors as efaxSelect } from '../../ducks/entities/efax';
import SmallEfaxTable from '../../components/SmallEfaxTable/SmallEfaxTable';
import type { EfaxEntityT, EfaxStateEntityT } from '../../ducks/entities/efax';
import styles from './EfaxTable.module.scss';

type PropsT = {|
  efaxes: EfaxStateEntityT[],
  setSortTerm: string => void,
  setPageToSearch: number => void,
  numberOfEfaxes: number
|};

export const EfaxTable = (props: PropsT): Element<'div' | typeof GenericError> => {
  const { t } = useTranslation();
  const { efaxes, setSortTerm, setPageToSearch, numberOfEfaxes } = props;

  const hasLoadingError = useSelector(state => efaxSelect.collectionHasError(state));
  const isLoadingEfaxes = useSelector(state => efaxSelect.collectionIsLoading(state));
  const hasMoreResults = useSelector(state => efaxSelect.collectionHasMoreResults(state));
  const [sort, setSort] = useState({
    columnId: 'phoneNumbers',
    order: 'asc'
  });

  const columns: TableHeaderColumnT[] = [];
  columns.push({
    columnId: 'icon',
    text: '',
    size: 'x-small',
    border: false
  });
  columns.push({
    columnId: 'emails',
    text: t('efax.emails'),
    size: 'medium',
    border: false
  });
  columns.push({
    columnId: 'label',
    text: t('efax.label'),
    size: 'medium',
    border: false
  });
  columns.push({
    columnId: 'numbers',
    text: t('efax.numbers'),
    size: 'medium',
    border: false
  });

  const handleTableSorted = (sorting: TableSortT) => {
    const { columnId, order }: { columnId: string, order: SortOrderT } = sorting;

    const newSortTerm = `${columnId},${order}`;
    setSortTerm(newSortTerm);
    setSort({
      columnId,
      order
    });
  };

  const renderTable = (): Element<'div'> => {
    const items: TableRowItemT[] | SmallUserTableRowItemT[] = efaxes.map(
      (efax: EfaxEntityT): TableRowItemT => {
        return {
          id: efax.id,
          rowId: efax.id,
          emails: efax.emails || efax.emailsOfUsers,
          emailsOfUsers: efax.emailsOfUsers,
          label: efax.label,
          numbers: efax.pnNumbers.join(', '),
          icon: efax.emails ? (
            <img style={{ paddingTop: '5px', width: '32px' }} src="/fax.svg" alt="fax-icon" />
          ) : (
            <img
              style={{ paddingTop: '5px', width: '32px' }}
              src="/fax-user.svg"
              alt="fax-user-icon"
            />
          )
        };
      }
    );
    return (
      <div>
        {!isLoadingEfaxes
          ? t('efax.numberOfEfaxes', {
              numberOfEfaxes: `${efaxes.length} / ${numberOfEfaxes}`
            })
          : ''}
        <div className={styles['large-table']}>
          <Table
            id="efaxesTable"
            // $FlowFixMe
            items={items}
            columns={columns}
            onSort={handleTableSorted}
            allowLoadMore={!isLoadingEfaxes && hasMoreResults}
            onInfiniteScrollLoadMore={page => {
              if (!isLoadingEfaxes && hasMoreResults) {
                setPageToSearch(page);
              }
            }}
            onSelectRow={() => {}}
            sort={sort}
          />
        </div>
        <div className={styles['small-table']}>
          <SmallEfaxTable
            id="smallEfaxTable"
            // $FlowFixMe
            items={items}
            allowLoadMore={!isLoadingEfaxes && hasMoreResults}
            onInfiniteScrollLoadMore={page => {
              if (!isLoadingEfaxes && hasMoreResults) {
                setPageToSearch(page);
              }
            }}
          />
        </div>
      </div>
    );
  };

  let elementAfterTable = null;
  if (hasLoadingError) {
    return <GenericError message={t('efax.efaxListFailedMsg')} />;
  }

  const renderNoResults = (
    <div className={styles['no-search-results-container']}>
      <NoSearchResultsPic />
      <h2>{t('efax.searchNoResults')}</h2>
    </div>
  );

  if (!isLoadingEfaxes && efaxes.length === 0) {
    elementAfterTable = renderNoResults;
  } else if (isLoadingEfaxes) {
    elementAfterTable = (
      <CenterHorizontally>
        <LoadingSpinner />
      </CenterHorizontally>
    );
  }
  return (
    <div>
      {renderTable()}
      {elementAfterTable}
    </div>
  );
};

export default EfaxTable;
