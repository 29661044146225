// @flow strict-local

import type { StateT, ActionT } from './callflowUiTypes';

export const initialState: StateT = {
  contents: {},
  viewMode: 'list',
  category: 'ACD_CUSTOMER_SERVICE',
  searchTerm: ''
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: StateT = initialState, action: ActionT): StateT => {
  switch (action.type) {
    case 'ring/ui/callflow/UPDATE_EDIT_STATUS': {
      const {
        payload: { nodeId, editStatus }
      } = action;
      return {
        ...state,
        contents: {
          ...state.contents,
          [nodeId]: {
            ...state.contents[nodeId],
            editStatus
          }
        }
      };
    }
    case 'ring/ui/callflow/UPDATE_OPEN_STATUS': {
      const {
        payload: { nodeId, isOpen }
      } = action;
      return {
        ...state,
        contents: {
          ...state.contents,
          [nodeId]: {
            ...state.contents[nodeId],
            isOpen
          }
        }
      };
    }
    case 'ring/ui/callflow/UPDATE_SELECTED_STATUS': {
      const {
        payload: { nodeId, selectedId, selectedType, isEditMode }
      } = action;
      return {
        ...state,
        contents: {
          ...state.contents,
          [nodeId]: {
            ...state.contents[nodeId],
            selectedId,
            selectedType,
            editStatus: isEditMode
          }
        }
      };
    }
    case 'ring/ui/callflow/SET_VIEW_MODE': {
      const {
        payload: { viewMode }
      } = action;
      return {
        ...state,
        viewMode
      };
    }
    case 'ring/ui/callflow/RESET_GRID_VIEW': {
      return {
        ...state,
        contents: {}
      };
    }
    case 'ring/ui/callflow/SET_CATEGORY': {
      const {
        payload: { category }
      } = action;
      return {
        ...state,
        category
      };
    }
    case 'ring/ui/callflow/SET_SEARCH_TERM': {
      const {
        payload: { searchTerm }
      } = action;
      return {
        ...state,
        searchTerm
      };
    }
    default:
      return state;
  }
};
