import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowUpAltRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.998.5c.337 0 .665.139.898.381l6.642 6.847a.745.745 0 0 1-.016 1.06.75.75 0 0 1-1.061-.016L12.75 2.885V22.75a.75.75 0 0 1-1.5 0V2.88L5.538 8.769a.75.75 0 0 1-1.076-1.045L11.1.88c.233-.241.561-.38.898-.38"/></svg>)}
    />
  );
}
  