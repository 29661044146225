import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInboxFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.99 12.47a.4.4 0 0 0 0-.15 1 1 0 0 0 0-.14l-3.66-7a1.76 1.76 0 0 0-1.58-.93H5.17a1.79 1.79 0 0 0-1.53.9l-3.64 7a1 1 0 0 0 0 .14.4.4 0 0 0 0 .15v6a1.76 1.76 0 0 0 1.67 1.81h20.58A1.76 1.76 0 0 0 24 18.5v-6zM4.96 5.88a.23.23 0 0 1 .21-.13h13.58a.24.24 0 0 1 .2.1l3.06 5.9h-5.76a.76.76 0 0 0-.75.75v1c0 .69-.56 1.25-1.25 1.25H9.67c-.69 0-1.25-.56-1.25-1.25v-1a.76.76 0 0 0-.75-.75H1.91zM5 10c.005-.412.298-.745.71-.75h12.58c.414 0 .71.336.71.75s-.296.75-.71.75H5.67c-.412-.005-.665-.338-.67-.75m1.5-2.5c.004-.397.274-.724.67-.75h9.58a.75.75 0 0 1 0 1.5H7.17c-.412-.005-.665-.338-.67-.75"/></svg>)}
    />
  );
}
  