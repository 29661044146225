// @flow

/* eslint-disable no-return-assign */

import React, { Component, type Element } from 'react';
import Slider from 'react-slick';
import UserCarouselCard from './UserCarouselCard';
import UserCardContainer from './UserCardContainer';
import CarouselScrollerButton from './CarouselScrollerButton';
import type { UserStateEntityT, UserTypeT } from '../../../ducks/entities/user/userTypes';
import styles from './UserCarousel.module.scss';

const DISPLAYED_USER_COUNT = 4;

export type PropsT = {
  users: UserStateEntityT[],
  onSelect: (string, UserTypeT) => *
};

type StateT = {
  firstUserFocused: boolean,
  lastUserFocused: boolean
};

export default class UserCarousel extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      firstUserFocused: true,
      lastUserFocused: this.props.users.length - DISPLAYED_USER_COUNT === 0
    };
    this.afterChangeHandler = this.afterChangeHandler.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  slider: *;

  afterChangeHandler: (number, 'desktop' | 'laptop' | 'tablet' | 'phone-large-and-down') => void;

  afterChangeHandler = (
    currentSlide: number,
    screen: 'desktop' | 'laptop' | 'tablet' | 'phone-large-and-down'
  ) => {
    // Because we are using custom arrows and want to hide them when there's nothing more to scroll, we
    // need to keep track of scrolling state ourselves:
    // Info: https://github.com/akiran/react-slick/issues/1517#issuecomment-475363004

    // Also, different screen sizes require magical tuning
    let focusOffset;
    switch (screen) {
      case 'desktop':
        focusOffset = 0;
        break;
      case 'laptop':
        focusOffset = 1;
        break;
      case 'tablet':
        focusOffset = -1;
        break;
      case 'phone-large-and-down':
        focusOffset = 0;
        break;
      default:
        focusOffset = 0;
    }

    this.setState({
      firstUserFocused: currentSlide === 0,
      lastUserFocused: currentSlide === this.props.users.length - DISPLAYED_USER_COUNT + focusOffset
    });
  };

  next: () => void;

  next() {
    // $FlowFixMe
    this.slider.slickNext();
  }

  previous: () => void;

  previous() {
    // $FlowFixMe
    this.slider.slickPrev();
  }

  render(): Element<typeof UserCardContainer> {
    const { onSelect, users } = this.props;
    const { firstUserFocused, lastUserFocused } = this.state;
    const EmptyArrow = () => <div style={{ display: 'none' }} />;
    const settings = {
      arrows: true,
      cssEase: 'ease-out',
      speed: 150,
      slidesToShow: DISPLAYED_USER_COUNT,
      slidesToScroll: 1,
      infinite: false,
      nextArrow: firstUserFocused ? (
        <EmptyArrow />
      ) : (
        <CarouselScrollerButton
          onSelect={this.previous}
          direction="left"
          classNames={styles['left-arrow-container']}
        />
      ),
      prevArrow: lastUserFocused ? (
        <EmptyArrow />
      ) : (
        <CarouselScrollerButton
          onSelect={this.next}
          direction="right"
          classNames={styles['right-arrow-container']}
        />
      ),
      afterChange: index => this.afterChangeHandler(index, 'desktop'),
      responsive: [
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 4,
            afterChange: index => this.afterChangeHandler(index, 'laptop')
          }
        },
        {
          breakpoint: 959,
          settings: {
            slidesToShow: 6,
            afterChange: index => this.afterChangeHandler(index, 'tablet')
          }
        },
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 5,
            afterChange: index => this.afterChangeHandler(index, 'phone-large-and-down')
          }
        }
      ]
    };

    return (
      <UserCardContainer>
        <div className={styles.container}>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {users.map(user => (
              <UserCarouselCard
                key={user.id}
                user={user}
                onClick={() => onSelect(user.id, user.userType)}
              />
            ))}
          </Slider>
        </div>
      </UserCardContainer>
    );
  }
}
