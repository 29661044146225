import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDisplayFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 14.625a.25.25 0 0 1-.25.25H.25a.25.25 0 0 1-.25-.25V4.005A2.01 2.01 0 0 1 2.005 2H22a2 2 0 0 1 2 2zm-24 1.75v.62A2.01 2.01 0 0 0 2.005 19h6.479l-.502 2.75H5.5a.75.75 0 0 0 0 1.5h13a.75.75 0 0 0 0-1.5h-2.483l-.5-2.75h6.478A2.01 2.01 0 0 0 24 16.994v-.619a.25.25 0 0 0-.25-.25H.25a.25.25 0 0 0-.25.25"/></svg>)}
    />
  );
}
  