// @flow

import * as R from 'ramda';
import React, { type Element } from 'react';
import { type FieldProps } from 'redux-form';
import type { IdTreeT } from '../../ducks/entities/department/departmentTypes';
import DepartmentComboboxItem from '../../scenes/enterprises/DepartmentDetails/DepartmentComboboxItem';
import Item from '../Item';
import { IdTreeCombobox } from './ReduxFormField';

type OwnPropsT = FieldProps & {
  label: string,
  fieldEditingDisabled: boolean,
  isSectionInEditMode: boolean,
  isSectionDisabled?: boolean,
  hasEmptyOption?: boolean,
  emptySelection?: ?{ value: string, key: string },
  wrapperStyle: *,
  viewModeOnClick?: *,
  id: string,
  options: IdTreeT,
  noDepartmentText: string,
  hideElementWithId?: ?string
};

export type PropsT = OwnPropsT;

export const FormIdTreeCombobox = ({
  label,
  wrapperStyle,
  isSectionInEditMode = false,
  isSectionDisabled = false,
  hasEmptyOption = false,
  hideElementWithId,
  emptySelection,
  options,
  id,
  input,
  noDepartmentText,
  viewModeOnClick,
  ...rest
}: PropsT): Element<'div'> => (
  <div className={wrapperStyle} id={id}>
    {isSectionInEditMode ? ( // eslint-disable-line no-nested-ternary
      <IdTreeCombobox
        {...rest}
        label={label}
        input={input}
        options={options}
        hideKeyAndChildren={hideElementWithId}
        hasEmptyOption={hasEmptyOption}
        emptyOptionKey={emptySelection ? emptySelection.value : ''}
        renderItem={p => <DepartmentComboboxItem {...p} emptySelection={emptySelection} />}
      />
    ) : (
      <Item
        {...rest}
        classNames={['form-input-item']}
        label={label}
        id={id}
        value={R.path(['value', 'label'], input) || noDepartmentText}
        disabled={isSectionDisabled}
        onClick={viewModeOnClick}
      />
    )}
  </div>
);

FormIdTreeCombobox.defaultProps = {
  options: []
};

export default FormIdTreeCombobox;
