"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Logout(_a) {
    var onClick = _a.onClick, title = _a.title, url = _a.url;
    return {
        onClick: onClick,
        title: title,
        url: url,
    };
}
exports.default = Logout;
