import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNetworkLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.002 15.007a4 4 0 0 0-3.248 1.688l-6.995-3.757a3.8 3.8 0 0 0 .25-1.429 3.7 3.7 0 0 0-.21-1.209l6.825-3.167a3.998 3.998 0 0 0 6.755-4.274 3.998 3.998 0 0 0-7.375 2.156c.003.412.074.82.21 1.209L8.389 9.392C7.221 7.52 4.755 6.949 2.882 8.117S.438 11.75 1.607 13.623a3.997 3.997 0 0 0 6.653.195l6.995 3.757a3.8 3.8 0 0 0-.25 1.429 3.997 3.997 0 1 0 3.997-3.997m0-12.988a2.997 2.997 0 1 1 .001 5.995 2.997 2.997 0 0 1-.001-5.995M5.012 14.508a2.997 2.997 0 1 1-.001-5.995 2.997 2.997 0 0 1 .001 5.995m13.99 7.493a2.997 2.997 0 1 1-.001-5.995 2.997 2.997 0 0 1 .001 5.995"/></svg>)}
    />
  );
}
  