// @flow
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IdleTimeOutModal from './components/IdleTimeOutModal/IdleTimeOutModal';

const IdleTimeOutHandler = () => {
  const [showModal, setShowModal] = useState(false);
  let timer;
  const documentEvents = [
    'mousemove',
    'click',
    'mousedown',
    'keypress',
    'DOMMouseScroll',
    'mousewheel',
    'touchmove',
    'MSPointerMove'
  ];
  const windowEvents = ['mousemove', 'resize'];
  const TWENTY_FIVE_MINUTES = 1500000;
  const location = useLocation();

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setShowModal(true);
    }, TWENTY_FIVE_MINUTES);
  };

  const eventHandler = () => {
    if (timer) {
      if (showModal) {
        setShowModal(false);
        window.location.reload();
      }
      startTimer();
    }
  };

  const addEvents = () => {
    documentEvents.forEach(eventName => {
      document.addEventListener(eventName, eventHandler);
    });
    windowEvents.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    documentEvents.forEach(eventName => {
      document.removeEventListener(eventName, eventHandler);
    });
    windowEvents.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  useEffect(() => {
    addEvents();
    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShowModal(false);
    startTimer();
    return () => {};
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return showModal && <IdleTimeOutModal />;
};

export default IdleTimeOutHandler;
