// @flow

/* eslint-disable react/no-unused-prop-types */

import classNames from 'classnames';

import React, { type Element } from 'react';

import styles from './SmallEfaxTableRow.module.scss';

export type SmallEfaxTableRowItemT = {
  id: string,
  rowId: string,
  rowClasses?: string[],
  emails?: string,
  numbers?: string,
  emailsOfUsers?: string[],
  label: string
};

export type PropsT = {
  rowItem: SmallEfaxTableRowItemT
};

const SmallEfaxTableRow = ({ rowItem }: PropsT): Element<'div'> => {
  return (
    <div
      id={rowItem.rowId}
      className={classNames(styles.row, ...(rowItem.rowClasses || []))}
      tabIndex={0}
      role="button"
    >
      {rowItem.emailsOfUsers && rowItem.emailsOfUsers.length > 0 ? (
        <img
          style={{ paddingRight: '5px', width: '26px' }}
          src="/fax-user.svg"
          alt="fax-user-icon"
        />
      ) : (
        <img style={{ paddingRight: '5px', width: '26px' }} src="/fax.svg" alt="fax-icon" />
      )}
      <div>
        {rowItem.label && <div className={styles['label-field']}>{rowItem.label}</div>}
        {rowItem.emails && <div className={styles['info-field']}>{rowItem.emails}</div>}
        {rowItem.numbers && <div className={styles['info-field']}>{rowItem.numbers}</div>}
      </div>
    </div>
  );
};

export default SmallEfaxTableRow;
