import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLiveNowRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.371 7.103a.75.75 0 0 1 .747-.007l4 2.25a.75.75 0 0 1 0 1.308l-4 2.25A.75.75 0 0 1 10 12.25v-4.5a.75.75 0 0 1 .371-.647"/><path d="M2.25 1.75H21.8A2.25 2.25 0 0 1 24 4v12a2.25 2.25 0 0 1-2.2 2.25h-9.05v2.5h3.79a.75.75 0 0 1 0 1.5H7.5a.75.75 0 0 1 0-1.5h3.75v-2.5h-9A2.25 2.25 0 0 1 0 16V4a2.25 2.25 0 0 1 2.25-2.25m19.5 15a.76.76 0 0 0 .75-.75V4a.75.75 0 0 0-.7-.75H2.25A.75.75 0 0 0 1.5 4v12a.76.76 0 0 0 .75.75z" clipRule="evenodd"/></svg>)}
    />
  );
}
  