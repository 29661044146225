import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMobileBroadbandFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11 6.3v11.5c0 1.8-1.5 3.3-3.3 3.3H3.3C1.5 21 0 19.5 0 17.7V6.3C0 4.5 1.5 3 3.3 3h4.4C9.5 3 11 4.5 11 6.3m2.4 2c-.3.3-.3.8 0 1.1 1.5 1.5 1.5 3.8 0 5.3-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2c2-2 2.1-5.4 0-7.4-.2-.4-.7-.4-1-.1m4-2.7c-.3-.3-.8-.3-1 0-.3.3-.3.8 0 1.1 2.9 2.9 2.9 7.7 0 10.6-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2c3.5-3.6 3.5-9.3 0-12.8M20.3 3c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1c4.4 4.4 4.4 11.5 0 15.9-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2c5-5 5-13.1.1-18.1"/></svg>)}
    />
  );
}
  