import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPowerLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.5 10.5v-9a.5.5 0 0 1 1 0v9a.5.5 0 0 1-1 0m3.83-6.93a.5.5 0 1 0-.33.95 9 9 0 1 1-6 0 .5.5 0 0 0-.33-.95 10 10 0 1 0 6.66 0"/></svg>)}
    />
  );
}
  