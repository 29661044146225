// @flow

import React, { type Element } from 'react';
import Truncate from 'react-truncate-markup';
import classnames from 'classnames';
import type { InternalUserStateEntityT } from '../../ducks/entities/user/userTypes';
import Avatar from '../Avatar/Avatar';
import styles from './UserItem.module.scss';

export type PropsT = {
  user: InternalUserStateEntityT,
  className?: string
};

export const UserItem = ({ user, className }: PropsT): Element<'div'> => {
  const { userName = '', title = '', addressNumber } = user;

  const addressNumberText = addressNumber && (
    <span className={styles['address-number']}>{`(${addressNumber})`}</span>
  );
  return (
    <div className={classnames(styles['user-item'], className)}>
      <Avatar
        className={styles['user-item-avatar']}
        hideAvailability
        name={userName}
        color="#0019AF"
        size="small"
      />
      <div
        className={classnames(styles['user-item-details'], {
          [`${styles['no-title']}`]: !title
        })}
      >
        <Truncate lines={1}>
          <div className={styles['user-item-details-name']}>
            {userName} {addressNumberText}
          </div>
        </Truncate>
        {title && (
          <Truncate lines={1}>
            <div className={styles['user-item-details-title']}>{title}</div>
          </Truncate>
        )}
      </div>
    </div>
  );
};

export default UserItem;
