import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTiktokLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.255 23.99a7.7 7.7 0 0 1-4.452-1.41 7.8 7.8 0 0 1-3.32-6.362c0-4.28 3.483-7.764 7.763-7.764.336 0 .671.029 1.046.077l.412.058v5.114l-.623-.192c-1.862-.585-3.685.874-3.685 2.716 0 1.056.585 2.025 1.525 2.524q.62.328 1.325.326c1.535 0 2.783-1.2 2.84-2.744V0h4.923v.48q0 .446.086.921a5.1 5.1 0 0 0 2.207 3.301 4.96 4.96 0 0 0 2.735.816h.48v4.923h-.48a9.9 9.9 0 0 1-5.028-1.363v7.159c0 4.28-3.483 7.763-7.763 7.763zm0-14.576a6.81 6.81 0 0 0-6.803 6.804 6.81 6.81 0 0 0 2.907 5.575 6.77 6.77 0 0 0 3.906 1.238 6.81 6.81 0 0 0 6.804-6.804V7.245l.758.547a8.97 8.97 0 0 0 4.75 1.67V6.458a5.9 5.9 0 0 1-2.783-.95 6.02 6.02 0 0 1-2.63-3.925 7 7 0 0 1-.086-.614h-3.023v15.392c-.086 2.073-1.756 3.685-3.81 3.685a3.76 3.76 0 0 1-1.765-.441 3.81 3.81 0 0 1-2.044-3.368c0-2.265 2.034-4.079 4.309-3.772V9.452c-.173-.019-.336-.019-.5-.019z"/></svg>)}
    />
  );
}
  