// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import type {
  DepartmentDeleteActionT,
  DepartmentPatchActionT,
  DepartmentPostActionT,
  DepartmentStateEntityCollectionT,
  RetrieveDepartmentCollectionActionT,
  DepartmentActionT,
  DepartmentEntityT,
  DepartmentStateT,
  IdTreeT,
  RetrieveUserCountForDepartmentActionT
} from './departmentTypes';

const initialState: DepartmentStateT = {
  byId: {},
  idTrees: {},
  allIds: [],
  __metadata: {}
};

const retrieveCollectionReducer = (
  state: DepartmentStateT,
  action: RetrieveDepartmentCollectionActionT
): DepartmentStateT => {
  switch (action.type) {
    case 'ring/entity/department/RETRIEVE_COLLECTION_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/department/RETRIEVE_COLLECTION_SUCCESS': {
      const { enterpriseId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.results.reduce(
            (
              collection: DepartmentStateEntityCollectionT,
              department: DepartmentEntityT
            ): DepartmentStateEntityCollectionT => ({
              ...collection,
              [department.id]: {
                ...department,
                __metadata: {}
              }
            }),
            {}
          )
        },
        idTrees: {
          ...state.idTrees,
          [enterpriseId]: action.payload.idTrees
        },
        allIds: R.union(state.allIds, R.map(R.prop('id'), action.payload.results)),
        __metadata: {}
      };
    }
    case 'ring/entity/department/RETRIEVE_COLLECTION_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/department/RETRIEVE_COLLECTION_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const updateDepartmentReducer = (
  state: DepartmentStateT,
  action: DepartmentPatchActionT
): DepartmentStateT => {
  switch (action.type) {
    case 'ring/entity/department/PATCH_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/department/PATCH_REQUEST': {
      return state;
    }
    case 'ring/entity/department/PATCH_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            name: action.payload.name
          }
        }
      };
    }
    case 'ring/entity/department/PATCH_CANCEL': {
      return state;
    }
    default:
      return state;
  }
};

const deleteDepartmentReducer = (
  state: DepartmentStateT,
  action: DepartmentDeleteActionT
): DepartmentStateT => {
  switch (action.type) {
    case 'ring/entity/department/DELETE_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/department/DELETE_REQUEST': {
      return state;
    }
    case 'ring/entity/department/DELETE_SUCCESS': {
      const { enterpriseId, departmentId } = action.payload;
      const newTree = enterpriseId in state.idTrees ? state.idTrees[enterpriseId] : [];
      removeFromTree(newTree, departmentId);
      let { allIds } = state;
      const { byId } = state;
      delete byId[departmentId];
      allIds = allIds.filter(e => e !== departmentId);
      return {
        ...state,
        byId,
        idTrees: {
          ...state.idTrees,
          [enterpriseId]: [...newTree]
        },
        allIds,
        __metadata: {
          hasUpdated: true
        }
      };
    }
    case 'ring/entity/department/DELETE_CANCEL': {
      return state;
    }
    default:
      return state;
  }
};

type FindParentAndInsertFnT = (IdTreeT, string, string) => void;
const findParentAndInsert: FindParentAndInsertFnT = (tree, parentDepartmentId, newDepartmentId) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === parentDepartmentId) {
      // eslint-disable-next-line no-param-reassign
      tree[i].children = [...(tree[i].children || []), { id: newDepartmentId }];
      break;
    }
    if (tree[i].children) {
      findParentAndInsert(tree[i].children, parentDepartmentId, newDepartmentId);
    }
  }
};

type InsertToTreeFnT = (IdTreeT, ?string, string) => void;
const insertToTree: InsertToTreeFnT = (tree, parentDepartmentId, newDepartmentId) => {
  if (parentDepartmentId) {
    findParentAndInsert(tree, parentDepartmentId, newDepartmentId);
  } else {
    tree.push({ id: newDepartmentId });
  }
};

type FindDepartmentAndRemoveItFnT = (IdTreeT, string) => void;
const findDepartmentAndRemoveIt: FindDepartmentAndRemoveItFnT = (tree, departmentId) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === departmentId) {
      // eslint-disable-next-line no-param-reassign
      tree.splice(i, 1);
      break;
    }
    if (tree[i].children) {
      findDepartmentAndRemoveIt(tree[i].children, departmentId);
    }
  }
};
type RemoveFromTreeFnT = (IdTreeT, string) => void;
const removeFromTree: RemoveFromTreeFnT = (tree, departmentId) => {
  findDepartmentAndRemoveIt(tree, departmentId);
};

const createDepartmentReducer = (
  state: DepartmentStateT,
  action: DepartmentPostActionT
): DepartmentStateT => {
  switch (action.type) {
    case 'ring/entity/department/POST_REQUEST': {
      return {
        ...state,
        __metadata: {
          isPosting: true
        }
      };
    }
    case 'ring/entity/department/POST_SUCCESS': {
      const { enterpriseId } = action.meta;
      const { id, ownerDepartment } = action.payload;
      const ownerDepartmentId = ownerDepartment ? ownerDepartment.id : null;

      const newTree = enterpriseId in state.idTrees ? state.idTrees[enterpriseId] : [];
      insertToTree(newTree, ownerDepartmentId, id);

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...action.payload,
            path: ownerDepartmentId ? [...state.byId[ownerDepartmentId].path, id] : [id],
            enterpriseId
          }
        },
        idTrees: {
          ...state.idTrees,
          [enterpriseId]: [...newTree]
        },
        allIds: R.union(state.allIds, [id]),
        __metadata: {
          hasUpdated: true
        }
      };
    }
    case 'ring/entity/department/POST_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/department/POST_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const retrieveUserCountForDepartmentReducer = (
  state: DepartmentStateT,
  action: RetrieveUserCountForDepartmentActionT
): DepartmentStateT => {
  switch (action.type) {
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrievingCount: true
        }
      };
    }
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_SUCCESS': {
      const { departmentId } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [departmentId]: {
            ...state.byId[departmentId],
            userCount: action.payload.totalCount
          }
        },
        __metadata: {}
      };
    }
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: DepartmentStateT = initialState,
  action: DepartmentActionT
): DepartmentStateT => {
  switch (action.type) {
    case 'ring/entity/department/RETRIEVE_COLLECTION_REQUEST':
    case 'ring/entity/department/RETRIEVE_COLLECTION_SUCCESS':
    case 'ring/entity/department/RETRIEVE_COLLECTION_FAILURE':
    case 'ring/entity/department/RETRIEVE_COLLECTION_CANCEL': {
      return departmentReducer.retrieveCollectionReducer(state, action);
    }
    case 'ring/entity/department/PATCH_REQUEST':
    case 'ring/entity/department/PATCH_SUCCESS':
    case 'ring/entity/department/PATCH_FAILURE':
    case 'ring/entity/department/PATCH_CANCEL': {
      return departmentReducer.updateDepartmentReducer(state, action);
    }
    case 'ring/entity/department/POST_REQUEST':
    case 'ring/entity/department/POST_SUCCESS':
    case 'ring/entity/department/POST_FAILURE':
    case 'ring/entity/department/POST_CANCEL': {
      return departmentReducer.createDepartmentReducer(state, action);
    }
    case 'ring/entity/department/DELETE_REQUEST':
    case 'ring/entity/department/DELETE_SUCCESS':
    case 'ring/entity/department/DELETE_FAILURE':
    case 'ring/entity/department/DELETE_CANCEL': {
      return departmentReducer.deleteDepartmentReducer(state, action);
    }
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_REQUEST':
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_SUCCESS':
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_FAILURE':
    case 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_CANCEL': {
      return departmentReducer.retrieveUserCountForDepartmentReducer(state, action);
    }
    default:
      return state;
  }
};

const departmentReducer = {
  initialState,
  deleteDepartmentReducer,
  retrieveCollectionReducer,
  updateDepartmentReducer,
  createDepartmentReducer,
  retrieveUserCountForDepartmentReducer,
  rootReducer
};

export default departmentReducer;
