import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Input from '../../Input';
import BankSelectGroup from '../../BankSelectGroup';

import globalStyles from '../../../globals/scss/_typography.scss';
import styles from '../Login.module.scss';

import { classNames } from '../../../utils/css';

class StrongIdentification extends Component {
  static propTypes = {
    /** Login component's id  */
    id: PropTypes.string,
    /** Container classname */
    className: PropTypes.string,
    /** Should the business ID be asked when user uses bank authentication. This ensures that the user has signatory rights to the company. */
    enableYTunnus: PropTypes.bool,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    /** Whether the first input field is automatically focused or not */
    autofocus: PropTypes.bool,
    /** List of banks to show on tiles */
    banks: PropTypes.arrayOf(PropTypes.array),
    /** Interface for switching login type */
    loginTypesSwitcher: PropTypes.element,
    /** Link to help with the login */
    loginHelp: PropTypes.element,
    /** Text for creating a new login */
    loginHelpText: PropTypes.string,
    /** Link to creating a new login */
    loginCreate: PropTypes.element,
    /** URL for login help */
    loginHelpUrl: PropTypes.string,
    /** URL to creating a new login */
    createLoginUrl: PropTypes.string,
    /** Object with i18n strings */
    translations: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      ytunnusActive: false,
      ytunnusVisible: this.props.enableYTunnus,
    };
  }

  toggleYTunnusVisibility = (e) => {
    e.preventDefault();
    this.setState({ ytunnusVisible: !this.state.ytunnusVisible });
  };

  onBankSelect = (bank) => {
    const { setFieldValue, submitForm } = this.props;
    setFieldValue('bank', bank.value);
    setFieldValue('useYTunnus', this.state.ytunnusVisible);
    submitForm();
  };

  handleFocus = (e) => {
    switch (e.target.name) {
      case 'ytunnus':
        this.setState({ ytunnusActive: true });
        break;
      default:
        break;
    }
  };

  handleBlur = (e) => {
    switch (e.target.name) {
      case 'ytunnus':
        this.setState({ ytunnusActive: false });
        break;
      default:
        break;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { handleSubmit } = this.props;
    handleSubmit(e);
    this.toggleYTunnusVisibility(e);
  };

  render() {
    const { id, className, values, autofocus, errors, touched, enableYTunnus, handleChange, handleBlur, banks, errorMessage, loginTypesSwitcher, loginHelp, loginCreate, loginHelpUrl, createLoginUrl, wide } = this.props;

    const {
      i18n_login_header_strongIdentification,
      i18n_login_ytunnusLabel,
      i18n_login_ytunnusPlaceholder,
      i18n_login_ytunnusChangeText,
      i18n_login_buttonContinue,
      i18n_login_ytynnysText,
      i18n_login_createLoginText,
      i18n_login_loginHelpText,
    } = this.props.translations;

    return (
      <div id={id} ref={this.myRef} className={className}>
        <h3 className={classNames([globalStyles['h3--book'], styles['login__view--title']])}>{i18n_login_header_strongIdentification}</h3>

        {errorMessage}
        {this.state.ytunnusVisible ? (
          <form onSubmit={this.handleSubmit}>
            <Input
              className={styles.login__input}
              name="ytunnus"
              value={values.ytunnus}
              onValueChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                this.handleBlur(e);
              }}
              onFocus={(e) => {
                this.handleFocus(e);
              }}
              active={this.state.ytunnusActive.toString()}
              placeholder={i18n_login_ytunnusPlaceholder}
              label={i18n_login_ytunnusLabel || i18n_login_ytunnusPlaceholder}
              touched={touched.ytunnus}
              error={errors.ytunnus}
              wide={wide}
              autoFocus={autofocus}
            />

            <Button className={styles[`login__submit-button`]} id="button-set-ytunnus" size="m" block type="submit">
              {i18n_login_buttonContinue}
            </Button>
          </form>
        ) : (
          <div>
            {enableYTunnus ? (
              <div>
                <p>
                  {i18n_login_ytynnysText}: {values.ytunnus}
                  <span className={globalStyles[`text--s`]}>
                    &nbsp;(
                    <a href="#" onClick={this.toggleYTunnusVisibility}>
                      {i18n_login_ytunnusChangeText}
                    </a>
                    )
                  </span>
                </p>
              </div>
            ) : null}
            <BankSelectGroup
              id={`${id}Bank_`}
              onBankSelect={this.onBankSelect}
              autoFocus={autofocus}
              banks={banks}
            />
          </div>
        )}

        {loginTypesSwitcher}

        {loginHelp}

        {loginCreate}
      </div>
    );
  }
}

export const validate = (values, errors, translations) => {
  if (values.useYTunnus) {
    if (!values.ytunnus) {
      errors.ytunnus = translations.i18n_login_errorYTunnusRequired;
    } else if (!/^[0-9]{7}\-[0-9]{1}$/.test(values.ytunnus)) {
      errors.ytunnus = translations.i18n_login_errorYTunnusInvalid;
    }
  }
};

export default StrongIdentification;
