// @flow strict
import React, { type Element } from 'react';
import classNames from 'classnames';
import styles from './LinkButton.module.scss'; // flowlint-line nonstrict-import: off

export type PropsT = {
  id: string,
  className?: string,
  disabled?: boolean,
  label: Element<'div'> | string,
  onClickAction: Event => void
};

const LinkButton = (props: PropsT): Element<'button'> => {
  const { id, className, disabled, label, onClickAction } = props;
  return (
    <button
      id={id}
      className={classNames(styles['link-button'], className)}
      onClick={onClickAction}
      onKeyPress={onClickAction}
      disabled={disabled}
      tabIndex={0}
    >
      {label}
    </button>
  );
};

LinkButton.defaultProps = {
  className: '',
  disabled: false
};

export default LinkButton;
