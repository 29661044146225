import React from 'react';
import IconContainer from '../IconContainer';

export default function IconErrorFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m-.8 6.8c0-.5.4-.8.8-.8s.8.3.8.8v6.5c0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8zM12 18c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1"/></svg>)}
    />
  );
}
  