import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDisturbanceLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.78 13.81a.51.51 0 0 0-.18.69 10.9 10.9 0 0 1 1.4 5.42V22a1 1 0 0 1-1 1h-6.5v-.25a3.5 3.5 0 0 0-2.82-3.43l1.79-5.16a.5.5 0 0 0-.31-.63.49.49 0 0 0-.626.298l-.004.012-1.9 5.45a3.49 3.49 0 0 0-3.13 3.46V23H2a1 1 0 0 1-1-1v-2.25A10.77 10.77 0 0 1 10.08 9a.507.507 0 0 0-.16-1A11.75 11.75 0 0 0 .001 19.75V22a2 2 0 0 0 2 2H22a2 2 0 0 0 2-2v-2.08A11.8 11.8 0 0 0 22.47 14a.52.52 0 0 0-.69-.19M9.5 22.75a2.51 2.51 0 0 1 2.5-2.5 2.5 2.5 0 0 1 2.49 2.5V23h-5zm-3.749-3.37a.88.88 0 1 1-.88-.88h.01c.48 0 .87.389.87.87zm-.25-3.75a.88.88 0 1 1 1.75-.187.88.88 0 0 1-1.75.187m12.749 3.75a.88.88 0 1 1 .89.87h-.02a.87.87 0 0 1-.87-.87m.25-3.75a.88.88 0 1 1-.88-.88.87.87 0 0 1 .88.86zm-8.25-3a.88.88 0 1 1-1.75-.187.88.88 0 0 1 1.75.187M18 12a6 6 0 1 0 0-12 6 6 0 0 0 0 12m0-11a5 5 0 1 1-.001 10.001A5 5 0 0 1 18 1m-1.85 6.15L17.29 6l-1.14-1.15a.496.496 0 0 1 .7-.7L18 5.29l1.15-1.14a.496.496 0 0 1 .7.7L18.71 6l1.14 1.15a.48.48 0 0 1 .022.678l-.022.022a.48.48 0 0 1-.678.022l-.022-.022L18 6.71l-1.15 1.14a.48.48 0 0 1-.678.022l-.022-.022a.48.48 0 0 1-.022-.678z"/></svg>)}
    />
  );
}
  