import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCustomerServiceLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.9 9c-.6-.5-1.3-.9-2.1-1-.1-2-1-3.9-2.6-5.4S14.3.2 12 .2s-4.5.9-6.2 2.5C4.3 4.1 3.4 6 3.3 8c-.8.1-1.6.5-2.2 1-.7.7-1.1 1.6-1.1 2.5v3c0 .9.4 1.8 1.1 2.5.7.6 1.7 1 2.7 1h.8c.3 0 .7-.1.9-.3.3-.3.5-.6.5-.9V9.2c0-.3-.2-.7-.4-.9Q5.15 8 4.7 8h-.4c.1-1.7.9-3.4 2.2-4.6C8 2 9.9 1.2 12 1.2s4 .8 5.5 2.1c1.3 1.2 2.1 2.9 2.2 4.6h-.4c-.3 0-.7.1-.9.3-.2.4-.4.7-.4 1v7.5c0 .3.2.7.4.9q.45.3.9.3h.4v.8c0 .7-.3 1.3-.8 1.8s-1.2.7-1.9.7h-1.9c-.1-.4-.2-.8-.5-1.1-.4-.4-.8-.6-1.4-.6h-1.4c-.5 0-1 .2-1.4.6s-.5.9-.5 1.5.2 1.1.5 1.5c.4.4.8.6 1.4.6h1.4a1.974 1.974 0 0 0 1.9-1.5H17c1 0 1.9-.4 2.6-1 .7-.7 1.1-1.5 1.1-2.5V18c.8-.1 1.5-.4 2.1-1 .7-.7 1.1-1.5 1.1-2.5v-3c.1-.9-.3-1.8-1-2.5M4.7 9c.1 0 .2 0 .3.1v7.6s0 .1-.1.2c0 .1-.1.1-.2.1h-.9c-.8 0-1.5-.3-2-.8-.5-.4-.8-1.1-.8-1.7v-3c0-.6.3-1.3.8-1.7.5-.5 1.3-.8 2-.8zM14 22.4q-.3.3-.6.3H12q-.3 0-.6-.3c-.2-.2-.3-.5-.3-.8s.1-.6.3-.8q.3-.3.6-.3h1.4q.3 0 .6.3c.2.2.3.5.3.8s-.2.6-.3.8m9-7.9c0 .6-.3 1.3-.8 1.7-.5.5-1.3.8-2 .8h-.8c-.1 0-.2 0-.3-.1-.1 0-.1-.1-.1-.1V9.2s0-.1.1-.2h1c.8 0 1.5.3 2 .8s.8 1.1.8 1.7v3z"/></svg>)}
    />
  );
}
  