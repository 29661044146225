// @flow
import React, { type Element, useMemo } from 'react';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import * as R from 'ramda';
import Input from '@design-system/component-library/src/components/Input';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { useTranslation } from 'react-i18next';
import ConfirmButton from '../../../../../../components/Button/ConfirmButton';
import { getAllNextStepOptions, NEW_FIELDS } from '../WelcomeAttendantUtils';
import TrashBinButton from '../../../../../../components/Button/TrashBinButton';
import type { NextStepT, StepOptionsT } from '../WelcomeAttendantUtils';

import styles from './InteractionNextStepsField.module.scss';

export type InteractionNextStepFieldT = {
  choice: string,
  nextState: ?string,
  type?: ?string,
  isNew: boolean,
  stepName?: ?string,
  newFieldName?: string
};

export type InteractionNextStepsFieldT = InteractionNextStepFieldT[];

export type PropsT = {|
  field: string,
  title: string,
  description: string,
  stepOptions: NextStepT[],
  allDialOptions: { value: string, label: string }[],
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean
|};

export const InteractionNextStepsField = (props: PropsT): Element<'div'> => {
  const { field, allDialOptions, title, description, stepOptions } = props;
  const { t, i18n } = useTranslation();
  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();

  const activeLanguage = i18n.language;

  const transferOptions: StepOptionsT[] = useMemo(
    () => getAllNextStepOptions(stepOptions, t, styles['new-step']),
    [activeLanguage] // eslint-disable-line
  );

  const allNextSteps: InteractionNextStepsFieldT = (values[field] || []).filter(f => f);
  const allActiveTransferNumbers = allNextSteps.map(f => f.choice);
  const availableNumberOptions = allDialOptions.filter(
    opt => !allActiveTransferNumbers.includes(opt.value)
  );
  const renderRow = (optionField: InteractionNextStepFieldT, index: number) => {
    const currentComboboxValue = transferOptions.find(
      o =>
        o.value.toUpperCase() === (optionField.nextState || '').toUpperCase() ||
        (o.stepName || '').toUpperCase() === (optionField.nextState || '').toUpperCase()
    ) || { value: 'INITIAL-SELECTION', label: '' };
    const availableTransferOptions = transferOptions.filter(f => !f.value.startsWith('START -'));
    return (
      <div className={styles['option-row']} key={`dialselection-${optionField.choice}`}>
        <Dropdown
          items={[
            ...availableNumberOptions,
            { value: optionField.choice, label: optionField.choice }
          ]}
          label={t('callflows.dialSelectionField.optionNumberLabel')}
          className={styles['option-number-input']}
          selectedValue={optionField.choice}
          onValueChange={option => {
            const options = R.map(
              R.when(
                R.propEq('choice', optionField.choice),
                R.assoc('choice', option.dataset.value)
              ),
              allNextSteps
            );
            setValue(field, options, {
              shouldValidate: true,
              shouldDirty: true
            });
          }}
        />
        <Dropdown
          label={t('callflows.dialSelectionField.targetStepLabel')}
          className={styles['option-next-step-input']}
          items={availableTransferOptions}
          selectedValue={currentComboboxValue.value}
          onValueChange={option => {
            const options = R.map(
              R.when(R.propEq('choice', optionField.choice), f => ({
                ...f,
                isNew: NEW_FIELDS.includes(option.dataset.value),
                nextState: option.dataset.value,
                stepName: option.stepName,
                newFieldName: ''
              })),
              allNextSteps
            );
            setValue(field, options, {
              shouldValidate: true,
              shouldDirty: true
            });
          }}
        />
        {optionField.isNew && (
          <Input
            id={`add-new-step-${optionField.choice}`}
            data-cy="new-step-input"
            className={styles['caller-input']}
            label={t('callflows.dialSelectionField.newStepLabel')}
            onValueChange={event => {
              event.preventDefault();
              const options = R.map(
                R.when(
                  R.propEq('choice', optionField.choice),
                  R.assoc('newFieldName', event.currentTarget.value)
                ),
                allNextSteps
              );
              setValue(field, options, {
                shouldValidate: true,
                shouldDirty: true
              });
            }}
            defaultValue={optionField.newFieldName || ''}
          />
        )}
        <TrashBinButton
          id={`remove-step-${optionField.choice}`}
          style={styles.trashbin}
          onClose={() => {
            const options = [...(allNextSteps || []).filter(r => r.choice !== optionField.choice)];
            setValue(field, options, {
              shouldValidate: true,
              shouldDirty: true
            });
          }}
        />
        {optionField.isNew && R.path([field, index, 'newFieldName', 'message'], errors) && (
          <div data-cy={`input-field-error-${field}`} className={styles.error}>
            {errors[field][index].newFieldName.message}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <div className={classnames(styles.header)}>{title}</div>
      <div className={classnames(styles.description)}>{description}</div>
      {allNextSteps.map((f, index) => renderRow(f, index))}
      <ConfirmButton
        id="add-interaction-button"
        label={t('callflows.dialSelectionField.addInteractionButton')}
        className={styles['add-button']}
        onClickAction={e => {
          e.preventDefault();
          setValue(
            field,
            [
              ...allNextSteps,
              {
                choice: availableNumberOptions[0].value,
                nextState: 'END',
                isNew: false
              }
            ],
            {
              shouldValidate: true,
              shouldDirty: true
            }
          );
        }}
        disabled={availableNumberOptions.length === 0}
      />
    </div>
  );
};

export default InteractionNextStepsField;
