// @flow
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import ActionButton from '../../../../components/Button/ActionButton';
import CancelButton from '../../../../components/Button/CancelButton';

import styles from './EnsureDeleteCalendarView.module.scss';

export type PropsT = {|
  onDelete: () => *,
  onCancel: () => void,
  calendarName: string
|};

const EnsureDeleteCalendarView = (props: PropsT) => {
  const { calendarName, onDelete, onCancel } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('calendars.ensureDeleteCalendarView.title')}</h2>
      <div className={styles.description}>
        {parse(`${t('calendars.ensureDeleteCalendarView.description')}<b>${calendarName}</b>`)}
      </div>
      <div className={styles.description}>
        <b>{t('calendars.ensureDeleteCalendarView.areYouSure')}</b>
      </div>
      <div className={styles['buttons-row']}>
        <ActionButton
          className={styles['delete-button']}
          label={t('calendars.ensureDeleteCalendarView.confirmButton')}
          id="confirm-delete-calendar-btn"
          onClickAction={onDelete}
        />
        <CancelButton
          id="calendar-delete-cancel-button"
          label={t('calendars.ensureDeleteCalendarView.cancelButton')}
          onClickAction={onCancel}
          className={classNames(styles.buttons, styles['cancel-button'])}
        />
      </div>
    </div>
  );
};

export default EnsureDeleteCalendarView;
