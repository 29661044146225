import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMoreVerticalFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 5.8c-1.5 0-2.8-1.2-2.8-2.8S10.5.2 12 .2s2.8 1.3 2.8 2.8-1.3 2.8-2.8 2.8m2.8 6.2c0-1.5-1.2-2.8-2.8-2.8S9.2 10.4 9.2 12s1.2 2.8 2.8 2.8 2.8-1.3 2.8-2.8m0 9c0-1.5-1.2-2.8-2.8-2.8S9.2 19.4 9.2 21s1.2 2.8 2.8 2.8 2.8-1.3 2.8-2.8"/></svg>)}
    />
  );
}
  