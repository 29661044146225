"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUIRED_INPUT_TYPES = exports.READONLY_INPUT_TYPES = exports.NUMERIC_INPUT_TYPES = exports.INPUT_TYPES = exports.INTERACTIVE_ELEMENTS = exports.FOCUSABLE_ELEMENTS = void 0;
exports.FOCUSABLE_ELEMENTS = ['a', 'button', 'input', 'select', 'textarea', 'svg', 'area', 'details', 'summary', 'iframe', 'object', 'embed', '[tabindex]', '[contenteditable]'];
exports.INTERACTIVE_ELEMENTS = [
    'a',
    'audio[controls]',
    'button',
    'details',
    'embed',
    'iframe',
    'img[usemap]',
    "input:not([type='hidden'])",
    'keygen',
    'label',
    "menu[type='toolbar']",
    'object[usemap]',
    'select',
    'textarea',
    'video[controls]',
    '[tabindex]',
    '[contenteditable]',
    "[role='scrollbar']",
    "[role='searchbox']",
    "[role='separator']",
    "[role='slider']",
    "[role='spinbutton']",
    "[role='switch']",
    "[role='tab']",
    "[role='tabpanel']",
    "[role='treeitem']",
    "[role='combobox']",
    "[role='button']",
    "[role='link']",
    "[role='checkbox']",
    "[role='option']",
    "[role='radio']",
    "[role='textbox']",
];
exports.INPUT_TYPES = ['textarea', 'date', 'datetime-local', 'email', 'hidden', 'month', 'number', 'password', 'select', 'search', 'tel', 'text', 'time', 'url', 'week'];
exports.NUMERIC_INPUT_TYPES = ['date', 'month', 'week', 'time', 'datetime-local', 'number'];
exports.READONLY_INPUT_TYPES = ['textarea', 'text', 'search', 'url', 'tel', 'email', 'password', 'date', 'month', 'week', 'time', 'datetime-local', 'number'];
exports.REQUIRED_INPUT_TYPES = ['textarea', 'text', 'select', 'search', 'url', 'tel', 'email', 'date', 'month', 'week', 'time', 'datetime-local', 'number', 'password'];
