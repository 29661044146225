// @flow
import * as R from 'ramda';
import type { LoadGroupStatusT } from '../../../ducks/entities/acd/acdTypes';
import type { CallFlowEntityT } from '../../../ducks/entities/callFlow';

const acdTypes = ['ACD', 'ACD_SWITCHBOARD', 'ACD_CUSTOMER_SERVICE'];
const convertActivityToServiceStatus = (activity: ?boolean): ?LoadGroupStatusT => {
  if (activity === false) return 'CLOSED';
  if (activity === true) return 'OPEN';
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const getLoadGroupStatus = (callflow: CallFlowEntityT) => {
  if (acdTypes.includes(callflow.type)) {
    const loadGroupStatus = R.path(['loadGroupStatus'], callflow);
    if (loadGroupStatus) {
      return loadGroupStatus;
    }
  }
  return convertActivityToServiceStatus(R.path(['active'], callflow));
};
