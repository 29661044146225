// @flow strict

import React, { type Element } from 'react';
import i18n from 'i18next';
import classnames from 'classnames';
import type { ExactPropsT } from '../../commonTypes';
import styles from './Favourite.module.scss'; // flowlint-line nonstrict-import: off

type PropsT = {
  selected: boolean,
  handleOnClick: (SyntheticEvent<HTMLButtonElement>) => void,
  disabled: boolean
};

export type TestPropsT = ExactPropsT<PropsT>;

const FavouriteIcon = ({ selected, handleOnClick, disabled }: PropsT): Element<'button'> => (
  <button
    id="favourite-button"
    aria-label={i18n.t('components.favouritesIcon.add')}
    className={classnames(
      styles['favourite-button'],
      {
        [`${styles['favourite-button--selected']}`]: selected
      },
      { [styles.disabled]: disabled }
    )}
    onClick={e => {
      e.preventDefault();
      if (!disabled) {
        handleOnClick(e);
      }
    }}
  />
);

FavouriteIcon.defaultProps = {
  selected: false
};

export default FavouriteIcon;
