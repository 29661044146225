// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { useTranslation } from 'react-i18next';
import type { CallForwardingTypeT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';

import styles from './ForwardingTypeSelector.module.scss';

export type PropsT = {|
  field: string,
  onChange: CallForwardingTypeT => void,
  createForEnterprise?: boolean
|};

export const ForwardingTypeSelector = (props: PropsT): Element<'div'> => {
  const { field, createForEnterprise, onChange } = props;

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const value: CallForwardingTypeT[] = values[field] || [];
  const { t } = useTranslation();

  const fwdTypes = [
    {
      value: 'NO_ANSWER',
      label: t('forwardings.createSelection.noAnswerButton')
    },
    {
      value: 'ON_BUSY',
      label: t('forwardings.createSelection.onBusyButton')
    },
    {
      value: 'UNREACHABLE',
      label: t('forwardings.createSelection.unreachableButton')
    },
    {
      value: 'PRESENCE',
      label: t('forwardings.createSelection.presenceButton')
    }
  ];
  if (!createForEnterprise) {
    fwdTypes.push({
      value: 'ALWAYS',
      label: t('forwardings.createSelection.alwaysButton')
    });
  }
  return (
    <div className={styles['type-area']} id="fwdtype-area">
      <Dropdown
        label={t('presenceCalendarListing.statusActive')}
        id="status-active"
        name="status-active"
        items={fwdTypes}
        selectedValue={value}
        onValueSelect={element => {
          setValue(field, element.dataset.value, {
            shouldValidate: true,
            shouldDirty: true
          });
          setValue('presenceStates', [], {
            shouldValidate: false,
            shouldDirty: false
          });
          setValue('timeFilter', null, {
            shouldValidate: false,
            shouldDirty: false
          });
          onChange(element.dataset.value);
        }}
      />
    </div>
  );
};

export default ForwardingTypeSelector;
