// @flow strict-local

import React, { type Element } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmButton from '../../../../components/Button/ConfirmButton';
import { CallflowDetailsColumn } from './CallflowDetailsColumn';
import { InvalidConfigurationInfo } from './children/InvalidConfigurationInfo';
import { goToCallFlow } from '../../../../navigationOperations';
import type { CallFlowTypeT } from '../../../../ducks/entities/callFlow/callFlowTypes';
import createUpdateEditStatus from '../../../../ducks/ui/callflow/callflowUiActions';

import styles from './CallflowDetails.module.scss';

type ChildenTypeT =
  | Element<typeof CallflowDetailsColumn>[]
  | Element<typeof CallflowDetailsColumn>
  | Element<typeof InvalidConfigurationInfo>
  | Element<'div'>;

type PropsT = {|
  nodeId?: string, // TODO: make nodeId mandatory field
  nodeType?: string,
  enterpriseId?: string,
  callflowId?: string,
  elementType?: CallFlowTypeT,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  icon: React$Node,
  title: string,
  children: ChildenTypeT,
  buttonLabel?: string,
  active: boolean,
  hideButton?: boolean
|};

export const CallflowDetails = (props: PropsT): Element<'div'> => {
  const {
    enterpriseId,
    icon,
    title,
    children,
    buttonLabel,
    active,
    nodeId,
    hideButton,
    callflowId,
    elementType,
    nodeType,
    onClick
  } = props;
  const { callflowId: cfId } = useParams();
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const onClickFn =
    onClick ||
    (() => {
      if (cfId) {
        dispatch(createUpdateEditStatus(callflowId || '', true));
      } else {
        dispatch(
          goToCallFlow(
            enterpriseId || '',
            callflowId || '',
            elementType || 'ACD_CUSTOMER_SERVICE',
            nodeType,
            nodeId
          )
        );
      }
    });

  const inactiveView = (
    <div className={styles.subtitle}>{t('callflows.callflowDetails.notInUseMsg')}</div>
  );

  return (
    <div className={styles.container}>
      <div className={styles['topic-icon']}>{icon}</div>
      <div className={styles.title}>{title}</div>
      {!active && inactiveView}
      {React.Children.map(children, child => {
        return child ? React.cloneElement(child, { active }) : null;
      })}
      {!hideButton && (
        <div className={styles['modify-button']}>
          <ConfirmButton
            id="modify-callflow-details-button"
            label={buttonLabel || t('callflows.callflowDetails.buttonLabel')}
            hideWhenViewing
            onClickAction={onClickFn}
          />
        </div>
      )}
    </div>
  );
};

export default CallflowDetails;
