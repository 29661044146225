// @flow

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import Input from '@design-system/component-library/src/components/Input';
import IconAddRegular from '@design-system/component-library/src/components/Icon/lib/IconAddRegular';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { Button } from '@design-system/component-library';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import IconRemoveRegular from '@design-system/component-library/src/components/Icon/lib/IconRemoveRegular';
import type { AxiosPromise } from 'axios';
import HTTP from 'http-status-codes';
import { getEnterprises } from '../../../ducks/entities/enterprise/enterpriseOperations';
import ActionButton from '../../../components/Button/ActionButton';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import styles from './SharedPhonebook.module.scss';

let requestEnterprisesCancelTokenSource: CancelTokenSource;
let requestSharedPhonebooksCancelTokenSource: CancelTokenSource;

export const SharedPhonebook = () => {
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const [newSharedPhonebookName, setNewSharedPhonebookName] = useState<string>('');
  const [sharedPhonebooks, setSharedPhonebooks] = useState([]);
  const [selectedPhonebook, setSelectedPhonebook] = useState();
  const [fetchedPhonebooks, setFetchedPhonebooks] = useState();
  const [enterprises, setEnterprises] = useState([]);
  const [selectedEnterprises, setSelectedEnterprises] = useState([]);
  const DISABLED_FIELDS = ['homeNumber', 'mobileNumber', 'substitutes'];
  const [editableFields, setEditableFields] = useState(DISABLED_FIELDS);
  const [selectedEntraTenants, setSelectedEntraTenants] = useState([]);
  const [enterpriseListKey, setEnterpriseListKey] = useState(0);
  const [newEntraId, setNewEntraId] = useState('');
  const inputRef = useRef<Input>();
  const currentUser = useSelector(state => state.currentUser);
  const MAX_ENTERPRISES = 10000;
  const editableColumns = [
    'avatar',
    'title',
    'department',
    'duties',
    'additionalExplanations',
    'additionalInformation',
    'internalAddress',
    'costCenter',
    'contactInformation',
    'nickName',
    'officeAddress',
    'superiors',
    'assistants',
    'tagNames',
    'email',
    ...DISABLED_FIELDS
  ];

  const fetchEnterprises = async () => {
    const params = {};
    params.size = MAX_ENTERPRISES;
    setEnterprises(
      await dispatch(getEnterprises(requestEnterprisesCancelTokenSource.token, params))
    );
  };

  const fetchAllSharedPhonebooks = async () => {
    try {
      const response: AxiosPromise = await axios({
        method: 'GET',
        url: `/api/v1/config/sharedphonebooks/${currentUser.environment}`
      });
      if (response && response.data) {
        setFetchedPhonebooks(response.data);
        setSharedPhonebooks(
          response.data.map(entry => ({
            id: entry.id.toString(),
            label: entry.name,
            value: entry.id.toString()
          }))
        );
        if (response.data.length > 0 && response.data[0]) {
          setSelectedEnterprises(response.data[0].enterpriseIds);
          setSelectedEntraTenants(response.data[0].entraTenantIds);
          setEditableFields(response.data[0].editableFields);
          setSelectedPhonebook(response.data[0].id.toString());
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log('Error', error.message);
      }
    }
  };

  useEffect(() => {
    requestEnterprisesCancelTokenSource = CancelToken.source();
    requestSharedPhonebooksCancelTokenSource = CancelToken.source();
    fetchEnterprises();
    fetchAllSharedPhonebooks();
    return () => {
      requestEnterprisesCancelTokenSource.cancel();
      requestSharedPhonebooksCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchedPhonebooks && selectedPhonebook) {
      const foundPhonebook = fetchedPhonebooks.find(pb => pb.id.toString() === selectedPhonebook);
      if (foundPhonebook) {
        setSelectedEnterprises(foundPhonebook.enterpriseIds);
        setSelectedEntraTenants(foundPhonebook.entraTenantIds);
        setEditableFields(foundPhonebook.editableFields);
      }
    } else {
      setSelectedEnterprises([]);
      setSelectedEntraTenants([]);
      setEditableFields(DISABLED_FIELDS);
    }
    setEnterpriseListKey(Date.now());
  }, [selectedPhonebook]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveSharedPhonebook = async () => {
    const postData = {
      environment: currentUser.environment,
      name: newSharedPhonebookName,
      enterpriseIds: [],
      entraTenantIds: [],
      editableFields: DISABLED_FIELDS
    };
    const response: AxiosPromise = await axios({
      method: 'POST',
      url: '/api/v1/config/sharedphonebooks',
      data: postData,
      headers: createCsrfHeader(currentUser)
    });
    if (response && response.data && response.status === HTTP.OK) {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'phonebook-create-success',
          duration: 15000,
          type: 'info',
          message: t('adminui.createPhonebookSuccess')
        })
      );
      return response.data;
    }
    dispatch(
      notificationActions.createCreateNotificationAction({
        tag: 'phonebook-create-failure',
        duration: 15000,
        type: 'error',
        message: t('adminui.createPhonebookFailure')
      })
    );
    return null;
  };

  const updateSharedPhonebook = async () => {
    if (selectedPhonebook && fetchedPhonebooks) {
      const phoneBookToUpdate = fetchedPhonebooks.find(
        pb => pb.id.toString() === selectedPhonebook
      );
      const patchData = {
        id: phoneBookToUpdate.id,
        name: phoneBookToUpdate.name,
        environment: phoneBookToUpdate.environment,
        enterpriseIds: selectedEnterprises,
        entraTenantIds: selectedEntraTenants,
        editableFields
      };
      const response: AxiosPromise = await axios({
        method: 'PATCH',
        url: `/api/v1/config/sharedphonebooks/${selectedPhonebook}`,
        data: patchData,
        headers: createCsrfHeader(currentUser)
      });
      if (response && response.data && response.status === HTTP.OK) {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'phonebook-update-success',
            duration: 15000,
            type: 'info',
            message: t('adminui.savePhonebookSuccess')
          })
        );
        setFetchedPhonebooks([
          ...fetchedPhonebooks.filter(pb => pb.id !== phoneBookToUpdate.id),
          response.data
        ]);
      } else {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'phonebook-update-failure',
            duration: 15000,
            type: 'error',
            message: t('adminui.savePhonebookFailure')
          })
        );
      }
    }
  };

  const isValidUUID = (value: string): boolean => {
    const exp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return exp.test(value);
  };

  const addPhonebook = async () => {
    const newPhonebook = await saveSharedPhonebook();
    if (newPhonebook) {
      // $FlowFixMe
      setFetchedPhonebooks([...fetchedPhonebooks, newPhonebook]);
      setSharedPhonebooks([
        ...sharedPhonebooks,
        {
          label: newPhonebook.name,
          value: newPhonebook.id.toString()
        }
      ]);
      setSelectedPhonebook(newPhonebook.id.toString());
      setNewSharedPhonebookName('');
      setSelectedEnterprises([]);
      setSelectedEntraTenants([]);
      setEditableFields(DISABLED_FIELDS);
      setEnterpriseListKey(Date.now());
    }
  };

  const removePhonebook = async () => {
    if (selectedPhonebook) {
      const response: AxiosPromise = await axios({
        method: 'DELETE',
        url: `/api/v1/config/sharedphonebooks/${selectedPhonebook}`,
        headers: createCsrfHeader(currentUser)
      });
      if (response && response.status === HTTP.OK && fetchedPhonebooks) {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'phonebook-delete-success',
            duration: 15000,
            type: 'info',
            message: t('adminui.deletePhonebookSuccess')
          })
        );
        setFetchedPhonebooks(
          fetchedPhonebooks.filter(pb => pb.id.toString() !== selectedPhonebook)
        );
        const filteredPhonebooks = sharedPhonebooks.filter(pb => pb.value !== selectedPhonebook);
        setSharedPhonebooks(sharedPhonebooks.filter(pb => pb.value !== selectedPhonebook));
        if (filteredPhonebooks.length > 0) {
          setSelectedPhonebook(filteredPhonebooks[0].value);
        } else {
          setSelectedPhonebook();
        }
        setEnterpriseListKey(Date.now());
      } else {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'phonebook-delete-failure',
            duration: 15000,
            type: 'error',
            message: t('adminui.deletePhonebookFailure')
          })
        );
      }
    }
  };

  const getEditableFieldsRows = () => {
    return editableColumns.map((column, index) => ({
      id: index.toString(),
      label: t(`adminui.fieldNames.${column}`),
      value: column
    }));
  };

  return (
    <div className={styles['phonebook-area']}>
      <Input
        id="phone-book-name"
        className={styles['phone-book-name']}
        placeholder={t('adminui.spNamePlaceholder')}
        onValueChange={event => {
          setNewSharedPhonebookName(event.target.value);
        }}
        defaultValue={newSharedPhonebookName || ''}
        maxlength={50}
        optional
        ariaAutocomplete="none"
        autocomplete="off"
        type="text"
      />
      <Button
        id="add-pb-button"
        type="button"
        icon={<IconAddRegular />}
        onClick={() => {
          addPhonebook();
        }}
        disabled={!newSharedPhonebookName}
      >
        {t('adminui.addSPButton')}
      </Button>
      {sharedPhonebooks.length > 0 && (
        <div>
          <div className={styles['phonebook-select']}>
            <Dropdown
              id="state-select"
              name="state-select"
              className={styles['phonebook-list']}
              items={sharedPhonebooks}
              key={selectedPhonebook}
              selectedValue={selectedPhonebook}
              onValueChange={element => {
                setSelectedPhonebook(element.dataset.value);
              }}
            />
            <Button
              id="remove-pb-button"
              type="button"
              className={styles['remove-button']}
              icon={<IconRemoveRegular />}
              onClick={() => {
                removePhonebook();
              }}
              disabled={!selectedPhonebook}
            >
              {t('adminui.removeSPButton')}
            </Button>
          </div>
          <div className={styles['settings-area']} key={enterpriseListKey}>
            <div>
              <div>{t('adminui.enterpriseSelectTitle')}</div>
              <div className={styles['settings-list']}>
                {enterprises &&
                  enterprises.map(ent => (
                    <Checkbox
                      key={`${ent.entID}_check`}
                      id={`${ent.entID}_check`}
                      name="enterpriseCheckbox"
                      label={ent.fullName}
                      checked={selectedEnterprises.includes(ent.entID)}
                      onChange={() => {
                        if (selectedEnterprises.includes(ent.entID)) {
                          setSelectedEnterprises(
                            selectedEnterprises.filter(item => item !== ent.entID) ?? []
                          );
                        } else {
                          setSelectedEnterprises(() => [...selectedEnterprises, ent.entID]);
                        }
                      }}
                    />
                  ))}
              </div>
            </div>
            <div className={styles['settings-list--right']}>
              <div>{t('adminui.editableFieldSelectTitle')}</div>
              <div className={styles['settings-list']}>
                {getEditableFieldsRows().map(ent => (
                  <Checkbox
                    key={`${ent.id}_check`}
                    id={`${ent.id}_check`}
                    name="fieldCheckbox"
                    label={ent.label}
                    disabled={DISABLED_FIELDS.includes(ent.value)}
                    checked={editableFields.includes(ent.value)}
                    onChange={() => {
                      if (editableFields.includes(ent.value)) {
                        setEditableFields(
                          editableFields.filter(item => item !== ent.value) ?? DISABLED_FIELDS
                        );
                      } else {
                        setEditableFields(() => [...editableFields, ent.value]);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
            <div className={styles['settings-list--right']}>
              <div>{t('adminui.entraTenantsTitle')}</div>
              <div className={styles['add-tenant-input']}>
                <Input
                  ref={inputRef}
                  optional
                  onValueChange={event => {
                    if (event) setNewEntraId(event.target.value);
                  }}
                />
                <Button
                  block="true"
                  onClick={() => {
                    if (!isValidUUID(newEntraId) || selectedEntraTenants.includes(newEntraId)) {
                      return;
                    }
                    setSelectedEntraTenants(() => [...selectedEntraTenants, newEntraId]);
                    inputRef.current.value('');
                    setNewEntraId('');
                  }}
                  disabled={!isValidUUID(newEntraId)}
                >
                  {t('adminui.addEntraTenantButton')}
                </Button>
              </div>
              <div className={styles['tenant-list']}>
                {selectedEntraTenants.map((ent, ind) => (
                  <div key={`${ent + ind}_div`} className={styles['tenant-list-item']}>
                    <span className={styles['id-text']}>{ent}</span>
                    <Button
                      size="small"
                      icon={<IconRemoveRegular />}
                      className={styles['remove-item-button']}
                      onClick={() => {
                        setSelectedEntraTenants(
                          selectedEntraTenants.filter(tenant => {
                            return tenant !== ent;
                          })
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <ActionButton
              id="save-button"
              className={styles.button}
              label={t('enterpriseSettings.save')}
              disabled={!selectedPhonebook}
              onClickAction={() => {
                updateSharedPhonebook();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SharedPhonebook;
