// @flow

import React, { useEffect, useState } from 'react';
import type { ExactPropsT } from '../../../../commonTypes';
import ViewSite from './ViewSite';
import EditSite from './EditSite';
import type { LocationEntityT } from '../../../../ducks/entities/location/locationTypes';

export type OwnPropsT = {
  onClose: () => void,
  onDelete: () => void,
  onUpdate: LocationEntityT => Promise<void>,
  newLocation: boolean,
  location: LocationEntityT,
  enterpriseId: string
};

export type PropsT = ExactPropsT<OwnPropsT>;

export const SiteDetails = (props: PropsT) => {
  const { location, newLocation, enterpriseId, onClose, onDelete, onUpdate } = props;

  const getAddress = (type: string, addresses: *) => {
    return addresses.filter(addr => addr.type === type);
  };

  const [visitingAddress, setVisitingAddress] = useState({
    visible: getAddress('visiting', location.addresses).length > 0
  });
  const [billingAddress, setBillingAddress] = useState({
    visible: getAddress('billing', location.addresses).length > 0
  });
  const [postalAddress, setPostalAddress] = useState({
    visible: getAddress('postal', location.addresses).length > 0
  });
  const [editing, setEditing] = useState(newLocation);

  const startEditing = () => {
    setEditing(true);
  };

  const endEditing = () => {
    setEditing(false);
  };

  useEffect(() => {
    endEditing();
    setVisitingAddress({
      visible: getAddress('visiting', location.addresses).length > 0
    });
    setBillingAddress({
      visible: getAddress('billing', location.addresses).length > 0
    });
    setPostalAddress({
      visible: getAddress('postal', location.addresses).length > 0
    });
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = async (values: *, isNewLocation: boolean) => {
    const updateObj = {
      id: isNewLocation ? '' : location.id,
      name: values.locationDetails.name || `new-location-${Date.now()}`,
      addresses: [],
      enterpriseId
    };
    if (postalAddress.visible) {
      updateObj.addresses.push({
        type: 'postal',
        zip: values.postal.zip,
        city: values.postal.city,
        district: values.postal.district,
        country: values.postal.country,
        address1: values.postal.streetAddress,
        address2: values.postal.addressLine
      });
    }
    if (visitingAddress.visible) {
      updateObj.addresses.push({
        type: 'visiting',
        zip: values.visiting.zip,
        city: values.visiting.city,
        district: values.visiting.district,
        country: values.visiting.country,
        address1: values.visiting.streetAddress,
        address2: values.visiting.addressLine
      });
    }
    if (billingAddress.visible) {
      updateObj.addresses.push({
        type: 'billing',
        zip: values.billing.zip,
        city: values.billing.city,
        district: values.billing.district,
        country: values.billing.country,
        address1: values.billing.streetAddress,
        address2: values.billing.addressLine
      });
    }
    await onUpdate(updateObj);
    endEditing();
  };

  const changeAddressVisibility = (type: string, visible: boolean) => {
    switch (type) {
      case 'visiting':
        setVisitingAddress({ visible });
        break;
      case 'billing':
        setBillingAddress({ visible });
        break;
      case 'postal':
        setPostalAddress({ visible });
        break;
      default:
        break;
    }
  };
  return editing || newLocation ? (
    <EditSite
      endEditing={endEditing}
      handleFormSubmit={handleFormSubmit}
      location={location}
      onClose={onClose}
      onDelete={onDelete}
      visitingAddress={visitingAddress}
      billingAddress={billingAddress}
      postalAddress={postalAddress}
      changeAddressVisibility={changeAddressVisibility}
      newLocation={newLocation}
    />
  ) : (
    <ViewSite
      location={location}
      onClose={onClose}
      showModify={location}
      startEditing={startEditing}
    />
  );
};

export default SiteDetails;
