// @flow

/* eslint-disable import/prefer-default-export, no-underscore-dangle */

import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import type { LocationStateEntityT } from './locationTypes';

type CollectionIsLoadingFnT = StoreStateT => boolean;
export const collectionIsLoading: CollectionIsLoadingFnT = ({ entities: { location } }) =>
  location.__metadata.isRetrieving === true;

type CollectionHasErrorFnT = (StoreStateT, ?string) => boolean;
export const collectionHasError: CollectionHasErrorFnT = ({ entities: { location } }) =>
  location.__metadata.error !== undefined;

type ByEnterpriseIdFnT = (StoreStateT, ?string) => LocationStateEntityT[];
export const byEnterpriseId: ByEnterpriseIdFnT = ({ entities: { location } }, enterpriseId) => {
  const locations = enterpriseId
    ? location.allIds
        .filter(id => id.startsWith(enterpriseId))
        .map(id => location.byId[id])
        .filter(id => !R.isEmpty(id))
    : [];
  const sortByNameCaseInsensitive = R.sortBy(
    // $FlowFixMe: compose missing in types
    R.compose(R.toLower, R.prop('name'))
  );
  return sortByNameCaseInsensitive(locations);
};

type HasErrorFnT = (LocationStateEntityT[], string) => boolean;
export const hasError: HasErrorFnT = (locations, locationId) =>
  R.path(['__metadata', 'error'], R.find(R.propEq('name', locationId))(locations)) !== undefined;

export type LocationNamesFnT = (StoreStateT, ?string) => string[];
export const locationNames: LocationNamesFnT = ({ entities: { location } }, enterpriseId) =>
  enterpriseId
    ? R.values(location.byId)
        .filter(site => site.enterpriseId.toString() === enterpriseId.toString())
        .map(site => site.name)
    : [];
