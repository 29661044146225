import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTurvapakettiFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.403.997 11.95 0 2.398.997c-.199 0-.398.199-.398.498v7.178c0 8.573 3.682 12.162 9.751 15.252.1.1.299.1.498 0C18.219 20.935 22 17.246 22 8.673V1.495c-.1-.299-.299-.498-.597-.498m-.995 4.785L7.174 19.04c-1.194-1.097-2.09-2.492-2.687-3.988l12.935-12.96 2.886.299z"/></svg>)}
    />
  );
}
  