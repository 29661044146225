import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFolderFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 7v13c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h5.5c.6 0 1.2.3 1.6.8L10.8 5H22c1.1 0 2 .9 2 2"/></svg>)}
    />
  );
}
  