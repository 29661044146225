import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPictureAddFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.75 11.75a6 6 0 1 0 0 12 6 6 0 0 0 0-12m3 6.75h-2.225v2.25a.75.75 0 0 1-1.5 0V18.5H14.75a.75.75 0 0 1 0-1.5h2.275v-2.25a.75.75 0 0 1 1.5 0V17h2.225a.75.75 0 0 1 0 1.5M17.656 3.777 14.719.844A2.03 2.03 0 0 0 13.283.25H2.28C1.164.25.25 1.164.25 2.28v16.94c0 1.116.914 2.03 2.03 2.03h6.972l1.404-.006a.475.475 0 0 0 .439-.659 7.2 7.2 0 0 1-.595-2.863c0-3.991 3.252-7.239 7.25-7.239.25 0 .5-.234.5-.484v-4.79c0-.536-.214-1.052-.594-1.432M5.25 5.125c.62 0 1.125.505 1.125 1.125S5.87 7.375 5.25 7.375 4.125 6.87 4.125 6.25 4.63 5.125 5.25 5.125m7.115 5.011a.75.75 0 0 1-1.034-.239l-.551-.883-2.06 2.934a.75.75 0 0 1-1.083.155l-1.26-1.009L4.953 14H8.35a.75.75 0 0 1 0 1.5h-4.6a.75.75 0 0 1-.675-1.079l1.98-4.069c.028-.058.103-.166.145-.214.508-.633 1.464-.738 2.116-.215l.636.509 1.585-2.26a1.5 1.5 0 0 1 .401-.393 1.52 1.52 0 0 1 2.103.422l.563.902a.75.75 0 0 1-.239 1.033"/></svg>)}
    />
  );
}
  