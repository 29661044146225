// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GridElementContainer from '../gridElement/GridElementContainer';
import type {
  WelcomeAttendantGridElementT,
  CallFlowTreeNodeT
} from '../../../../ducks/entities/callFlow/callFlowTypes';
import type { WelcomeAttendantEntityT } from '../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import WelcomeAttendantCalendarDetails from '../details/welcomeAttendant/calendar/WelcomeAttendantCalendarDetails';
import MessageDetails from '../details/welcomeAttendant/message/MessageDetails';
import { WelcomeAttendantUtils } from './WelcomeAttendantUtils';
import EditWelcomeAttendantCalendar from '../details/welcomeAttendant/calendar/EditWelcomeAttendantCalendar';
import EditMessageDetails from '../details/welcomeAttendant/message/EditMessageDetails';
import { selectors as callflowSelectors } from '../../../../ducks/ui/callflow';
import TransferDetails from '../details/welcomeAttendant/transfer/TransferDetails';
import EditTransferDetails from '../details/welcomeAttendant/transfer/EditTransferDetails';
import EditInteractionDetails from '../details/welcomeAttendant/interaction/EditInteractionDetails';
import InteractionDetails from '../details/welcomeAttendant/interaction/InteractionDetails';
import StartDetails from '../details/welcomeAttendant/start/StartDetails';
import EditStartDetails from '../details/welcomeAttendant/start/EditStartDetails';

export type PropsT = {
  data: WelcomeAttendantEntityT,
  enterpriseId: string,
  hideDescription: boolean,
  single?: boolean
};

export const WelcomeAttendantElement = (props: PropsT): Element<typeof GridElementContainer> => {
  const { data, enterpriseId, hideDescription, single } = props;
  const { t } = useTranslation();
  // redux
  const commandId: string = useSelector(state => callflowSelectors.getSelectedId(state, data.id));

  const welcomeAttendantUtils = new WelcomeAttendantUtils();
  const rootNode: ?CallFlowTreeNodeT = welcomeAttendantUtils.createCallFlowTree(data);
  const callflowId = `${enterpriseId}-WELCOME_ATTENDANT-${data.id}`;

  const gridElement: WelcomeAttendantGridElementT = {
    id: data.id,
    type: 'WELCOME_ATTENDANT',
    name: data.label || '',
    number: data.addressNumber,
    description: t('callflows.type.welcomeAttendant.description'),
    enterpriseId: data.enterpriseId,
    rootNode
  };

  const viewContent = (nodeType: ?string) => {
    switch (nodeType) {
      case 'CALENDAR':
        return <WelcomeAttendantCalendarDetails callflowId={callflowId} commandId={commandId} />;
      case 'AUDIO':
        return <MessageDetails callflowId={callflowId} commandId={commandId} />;
      case 'TRANSFER':
        return <TransferDetails callflowId={callflowId} commandId={commandId} />;
      case 'INTERACTION':
        return <InteractionDetails callflowId={callflowId} commandId={commandId} />;
      case 'START':
        return <StartDetails callflowId={callflowId} commandId={commandId} />;
      default:
        return <div />;
    }
  };

  const editContent = (nodeType: ?string) => {
    switch (nodeType) {
      case 'CALENDAR':
        return <EditWelcomeAttendantCalendar callflowId={callflowId} commandId={commandId} />;
      case 'AUDIO':
        return <EditMessageDetails callflowId={callflowId} commandId={commandId} />;
      case 'TRANSFER':
        return <EditTransferDetails callflowId={callflowId} commandId={commandId} />;
      case 'INTERACTION':
        return <EditInteractionDetails callflowId={callflowId} commandId={commandId} />;
      case 'START':
        return <EditStartDetails callflowId={callflowId} commandId={commandId} />;
      default:
        return <div />;
    }
  };

  return (
    <GridElementContainer
      key={gridElement.id}
      gridElement={gridElement}
      hideDescription={hideDescription}
      viewContentSupplier={viewContent}
      editContentSupplier={editContent}
      single={single}
    />
  );
};

export default WelcomeAttendantElement;
