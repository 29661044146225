// @flow

import classnames from 'classnames';
import React, { type Element } from 'react';
import styles from './Item.module.scss';

export type PropsT = {
  label: string,
  id: string,
  value: ?(string | Element<*>),
  disabled: boolean,
  onClick: ?() => *,
  classNames: string[],
  labelClassName?: string
};

const Item = (props: PropsT): Element<'div'> => {
  const { id } = props;
  const elementId = id || '';

  if (props.onClick != null) {
    return (
      <div
        className={classnames({
          ...props.classNames.reduce(
            (classNames, className) => ({ ...classNames, [className]: true }),
            {}
          ),
          [styles['disabled-item']]: props.disabled,
          [styles.item]: !props.disabled
        })}
        onClick={props.disabled ? null : props.onClick}
        onKeyPress={props.disabled ? null : props.onClick}
        role="button"
        tabIndex={0}
      >
        <div
          className={classnames(!props.labelClassName && styles.label, props.labelClassName)}
          id={`${elementId}-label`}
        >
          {props.label}
        </div>
        <div className={styles.link} id={`${elementId}-value`}>
          {props.value}
        </div>
      </div>
    );
  }
  return (
    <div
      className={classnames({
        ...props.classNames.reduce(
          (classNames, className) => ({ ...classNames, [className]: true }),
          {}
        ),
        [styles['disabled-item']]: props.disabled,
        [styles.item]: !props.disabled
      })}
    >
      <div
        className={classnames(!props.labelClassName && styles.label, props.labelClassName)}
        id={`${elementId}-label`}
      >
        {props.label}
      </div>
      <div className={styles.value} id={`${elementId}-value`}>
        {props.value}
      </div>
    </div>
  );
};

Item.defaultProps = {
  disabled: false,
  onClick: null,
  classNames: []
};

export default Item;
