import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowLeftAltLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.75 12.002c0-.273.108-.529.305-.719L7.903 4.64a.499.499 0 0 1 .695.719L2.267 11.5H22.75a.5.5 0 0 1 0 1H2.264l6.33 6.141a.5.5 0 0 1-.695.718L1.055 12.72a1 1 0 0 1-.305-.718"/></svg>)}
    />
  );
}
  