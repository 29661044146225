import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDevicesRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 15.5v1a3.75 3.75 0 0 1-3.75 3.75H10a.75.75 0 0 1 0-1.5h10.25a2.25 2.25 0 0 0 2.25-2.25v-.25h-6.18a3.82 3.82 0 0 1-2.82 1 3.87 3.87 0 0 1-2.8-1h-.45a.75.75 0 0 1 0-1.5H11c.199 0 .389.079.53.22a2.39 2.39 0 0 0 2 .8 2.31 2.31 0 0 0 1.91-.77.75.75 0 0 1 .56-.25h4.5V6a.76.76 0 0 0-.75-.75H6a.76.76 0 0 0-.74.61.75.75 0 1 1-1.47-.27A2.23 2.23 0 0 1 6 3.75h13.75A2.25 2.25 0 0 1 22 6v8.75h1.25a.76.76 0 0 1 .75.75M8.25 10v8A2.25 2.25 0 0 1 6 20.25H2.25A2.25 2.25 0 0 1 0 18v-8a2.25 2.25 0 0 1 2.25-2.25H6A2.25 2.25 0 0 1 8.25 10M1.5 10v5.75h5.25V10A.76.76 0 0 0 6 9.25H2.25a.76.76 0 0 0-.75.75m5.25 8v-.75H1.5V18a.76.76 0 0 0 .75.75H6a.76.76 0 0 0 .75-.75"/></svg>)}
    />
  );
}
  