import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvoiceMultipleRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.8.5C1.6.5.6 1.5.6 2.7v14.5c0 .4.3.8.8.8.3 0 .6-.3.6-.8V2.8c0-.5.3-.8.8-.8h12.5c.4 0 .7-.3.7-.8 0-.4-.3-.8-.8-.8H2.8zm.4 5c0-1.2 1-2.2 2.2-2.2h12.8c.5-.1.8.3.8.7s-.3.8-.8.8H5.5c-.4 0-.8.3-.8.8v14.8c.1.3-.3.6-.7.6s-.8-.3-.8-.8zm14 10.3c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1-1-.5-1-1m1-4c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1m-1-2c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1-1-.5-1-1M9.8 15c-.5 0-.8.3-.8.8s.3.8.8.8h3.5c.4 0 .8-.3.8-.8s-.4-.8-.9-.8zM9 9.8c0-.5.3-.8.8-.8h4.5c.4 0 .7.3.7.8 0 .4-.3.8-.8.8H9.8c-.5-.1-.8-.4-.8-.8m.8 2.2c-.5 0-.8.3-.8.8s.3.8.8.8h5.5c.4 0 .8-.3.8-.8s-.4-.8-.9-.8zM6 8.2C6 7 7 6 8.2 6h11.5C21 6 22 7 22 8.2v14c0 1-.8 1.8-1.8 1.8-.4 0-.8-.1-1.1-.4l-2-2.1-1.9 2.1-.1.1c-.6.5-1.5.5-2.1 0l-.1-.1-1.9-2.1-2.1 2.1c-.4.3-.8.4-1.1.4-.5 0-1-.2-1.3-.6-.4-.3-.5-.7-.5-1.2zm2.2-.7c-.4 0-.8.3-.8.8v14c0 .1 0 .1.1.2 0 .1.1.1.2.1h.1l2.6-2.6c.1-.1.3-.2.6-.2.2 0 .4.1.5.2l2.3 2.6h.2l2.3-2.6c.1-.2.3-.2.5-.2s.4.1.6.2l2.6 2.6h.1c.1 0 .2-.1.2-.2v-14c0-.4-.3-.8-.8-.8H8.2z"/></svg>)}
    />
  );
}
  