import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAsterixFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.75 12.001c0 .69-.56 1.25-1.25 1.25h-5.336l2.669 4.625a1.25 1.25 0 0 1-2.166 1.25L12 14.502l-2.667 4.623a1.25 1.25 0 1 1-2.165-1.25l2.668-4.625H4.5a1.25 1.25 0 0 1 0-2.5h5.336L7.167 6.126a1.25 1.25 0 1 1 2.165-1.249L12 9.499l2.667-4.623a1.25 1.25 0 0 1 2.166 1.249l-2.669 4.625H19.5c.69.001 1.25.56 1.25 1.251"/></svg>)}
    />
  );
}
  