import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowRightAltLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 11.998a1 1 0 0 1-.305.719l-6.848 6.643a.5.5 0 1 1-.695-.718l6.331-6.141H1.25a.5.5 0 0 1 0-1h20.486l-6.33-6.141a.5.5 0 0 1 .695-.718l6.844 6.639a.98.98 0 0 1 .305.717"/></svg>)}
    />
  );
}
  