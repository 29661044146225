import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCheckLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 18a.53.53 0 0 1-.36-.17l-3.5-4a.503.503 0 0 1 .76-.66L8 16.78 19.15 6.14a.502.502 0 0 1 .7.72l-11.5 11A.52.52 0 0 1 8 18"/></svg>)}
    />
  );
}
  