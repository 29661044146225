// @flow

import * as R from 'ramda';
import * as yup from 'yup';
import fieldValidators from '../../../fieldValidators';
import type {
  CallForwardingDestinationT,
  CallForwardingFilterTypeT,
  FiltersOnTargetNumberTypeT,
  PresenceStateTypeT
} from '../../../ducks/entities/callForwarding/callForwardingTypes';
import type { TranslateT } from '../../../commonTypes';

const isValidFiltersOnTargetNumber = (targetNumber: FiltersOnTargetNumberTypeT[]) => {
  return !R.isEmpty(targetNumber);
};

const isValidCallForwardingDestination = (
  destination: CallForwardingDestinationT,
  errorMessages: *
) => {
  if (destination.type === 'INTERNAL' && destination.value === '') {
    // eslint-disable-next-line prefer-destructuring
    return errorMessages.internal;
  }
  if (destination.type === 'EXTERNAL' && (!destination.value || destination.value.length < 1)) {
    // eslint-disable-next-line prefer-destructuring
    return errorMessages.external;
  }
  if (
    destination.type === 'EXTERNAL' &&
    fieldValidators.phoneNumberValidator(destination.value || '') !== undefined
  ) {
    // eslint-disable-next-line prefer-destructuring
    return errorMessages.other;
  }
  return false;
};

const isValidPresenceState = (presenceStateFilter: ?(PresenceStateTypeT[])) => {
  return !R.isEmpty(presenceStateFilter);
};

const isValidSpecificCallerFilter = (
  filter?: CallForwardingFilterTypeT,
  specificCaller?: string
) => {
  return filter === 'SPECIFIC_CALLER'
    ? !(
        !specificCaller ||
        specificCaller.length < 1 ||
        fieldValidators.validateSpecificCaller(specificCaller) !== undefined
      )
    : true;
};

const validateUsers = (errorMsg: string) => {
  return yup.mixed().test('validateUsers', errorMsg, function(value) {
    return value ? value.length > 0 : false;
  });
};

const validateFiltersOnTargetNumber = (errorMsg: string) => {
  return yup.mixed().test('validateFiltersOnTargetNumber', errorMsg, function(value) {
    return value ? isValidFiltersOnTargetNumber(value) : true;
  });
};

const validateForwardingTarget = (translate: TranslateT<>) =>
  yup.mixed().test('validateForwardingTarget', '', function(value) {
    const returnValue = value
      ? isValidCallForwardingDestination(value.target, {
          internal: translate('forwardingDetails.validation.destinationInternal'),
          external: translate('forwardingDetails.validation.destinationExternalEmpty'),
          other: translate('forwardingDetails.validation.destinationExternalValidity')
        })
      : true;
    return !returnValue
      ? true
      : this.createError({
          message: returnValue
        });
  });

const validatePresenceState = (errorMsg: string) => {
  return yup.mixed().test('validatePresenceState', errorMsg, function(value) {
    return isValidPresenceState(value);
  });
};

const validateSpecificCallerFilter = (errorMsg: string) => {
  return yup.mixed().test('validateSpecificCallerFilter', errorMsg, function(value) {
    return value ? isValidSpecificCallerFilter(value.filter, value.specificCaller) : true;
  });
};

const isValidTimeFilter = (value: string) => {
  const splitDateTime = value.split('/');
  return splitDateTime[0].length > 0;
};

const validateTimeFilter = (errorMsg: string) => {
  return yup.mixed().test('validateTimeFilter', errorMsg, function(value) {
    return value ? isValidTimeFilter(value) : true;
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  validateFiltersOnTargetNumber,
  validatePresenceState,
  validateSpecificCallerFilter,
  validateUsers,
  validateForwardingTarget,
  validateTimeFilter
};
