// @flow

/* eslint-disable no-use-before-define */

import { type SetTooltipVisibilityT, type StateT } from './tooltipUiTypes';

export const initialState: StateT = {
  visible: null
};

const tooltipReducer = (state: StateT = initialState, action: SetTooltipVisibilityT): StateT => {
  switch (action.type) {
    case 'ring/ui/tooltip/SET_TOOLTIP_VISIBILITY': {
      return {
        ...state,
        visible: action.payload.visible
      };
    }
    default:
      return state;
  }
};

export default tooltipReducer;
