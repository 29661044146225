// @flow

import * as R from 'ramda';
import type { HistoryActionT } from '../../entities/historyAction/historyActionTypes';
import type { StateT } from './historyActionsUiTypes';

export const initialState: StateT = {
  historyActionList: {
    allIds: [],
    __metadata: {}
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: StateT = initialState, action: HistoryActionT): StateT => {
  switch (action.type) {
    case 'ring/entity/history/RETRIEVE_COLLECTION_REQUEST': {
      // page 1 means a new user list has been requested and the old one gets removed
      if (action.meta.page === 1) {
        return {
          ...state,
          historyActionList: {
            allIds: [],
            __metadata: {
              isRetrieving: true,
              lastRequestedPage: action.meta.page
            }
          }
        };
      }
      return {
        ...state,
        historyActionList: {
          ...state.historyActionList,
          __metadata: {
            isRetrieving: true,
            lastRequestedPage: action.meta.page
          }
        }
      };
    }
    case 'ring/entity/history/RETRIEVE_COLLECTION_SUCCESS': {
      const ids = R.map(R.prop('id'), action.payload);
      return {
        ...state,
        historyActionList: {
          allIds: R.union(state.historyActionList.allIds, ids),
          __metadata: {
            hasMore: action.meta.offset + action.payload.length < action.meta.totalCount
          }
        }
      };
    }
    case 'ring/entity/history/RETRIEVE_COLLECTION_FAILURE': {
      return {
        ...state,
        historyActionList: {
          ...state.historyActionList,
          __metadata: {
            error: action.payload
          }
        }
      };
    }
    default:
      return state;
  }
};
