import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWifiOffRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.809 6.801a.75.75 0 0 1-1.059.059 16.2 16.2 0 0 0-2.64-1.908l-2.561 2.561a13 13 0 0 1 2.99 1.947.75.75 0 0 1-.986 1.131 11.4 11.4 0 0 0-3.139-1.942l-2.769 2.769a8.44 8.44 0 0 1 3.711 1.761.752.752 0 0 1-.478 1.328.75.75 0 0 1-.478-.172 6.84 6.84 0 0 0-4.104-1.569L3.03 22.03a.75.75 0 0 1-1.06 0 .75.75 0 0 1 0-1.061l20-20a.75.75 0 1 1 1.061 1.061l-1.835 1.835a17.6 17.6 0 0 1 2.555 1.876.75.75 0 0 1 .058 1.06M15.25 19.5c0 1.792-1.458 3.25-3.25 3.25s-3.25-1.458-3.25-3.25 1.458-3.25 3.25-3.25 3.25 1.458 3.25 3.25m-1.5 0c0-.965-.785-1.75-1.75-1.75s-1.75.785-1.75 1.75.785 1.75 1.75 1.75 1.75-.785 1.75-1.75M3.462 9.459a.75.75 0 1 0 .987 1.13C6.77 8.565 9.76 7.581 12.875 7.816a.75.75 0 1 0 .114-1.496c-3.518-.267-6.901.848-9.527 3.139m13.486-5.923a.75.75 0 0 0 .459-1.428C11.462.195 4.887 1.587.25 5.742a.75.75 0 0 0 1 1.117c4.243-3.8 10.261-5.072 15.698-3.323"/></svg>)}
    />
  );
}
  