// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import FiltersOnTargetNumber from '../../components/FiltersOnTargetNumber';
import type { FiltersOnTargetNumberTypeT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';

export type PropsT = {
  field: string,
  shouldValidate?: boolean
};

const toggleItemInArray = (
  arr: FiltersOnTargetNumberTypeT[],
  item: FiltersOnTargetNumberTypeT
): FiltersOnTargetNumberTypeT[] => {
  return arr.some(o => o === item) ? [...arr.filter(o => o !== item)] : [...arr, item];
};

export const TargetNumberFiltersSelector = (
  props: PropsT
): Element<typeof FiltersOnTargetNumber> => {
  const { field, shouldValidate } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const value: FiltersOnTargetNumberTypeT[] = values[field];

  return (
    <FiltersOnTargetNumber
      handleForwardingTargetNumberFilterChange={(option: FiltersOnTargetNumberTypeT) => {
        setValue(field, toggleItemInArray(value, option), {
          shouldValidate: shouldValidate === undefined ? true : shouldValidate,
          shouldDirty: true
        });
      }}
      filtersOnTargetNumber={value}
      errorMessage={errors[field] ? errors[field].message : ''}
    />
  );
};

export default TargetNumberFiltersSelector;
