import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRemoveLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 12.5h-15c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h15c.3 0 .5.2.5.5s-.2.5-.5.5"/></svg>)}
    />
  );
}
  