import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCalendarTimeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M1 8v8c0 .6.4 1 1 1h7c.3 0 .5.2.5.5s-.2.5-.5.5H2c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h3V.5c0-.3.2-.5.5-.5s.5.2.5.5V2h6V.5c0-.3.2-.5.5-.5s.5.2.5.5V2h3c1.1 0 2 .9 2 2v5c0 .3-.2.5-.5.5S17 9.3 17 9V8zm4-5v1.5c0 .3.2.5.5.5s.5-.2.5-.5V3h6v1.5c0 .3.2.5.5.5s.5-.2.5-.5V3h3c.6 0 1 .4 1 1v3H1V4c0-.6.4-1 1-1zm13 11.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v3c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H18zm-.5 9.5c3.6 0 6.5-2.9 6.5-6.5S21.1 11 17.5 11 11 13.9 11 17.5s2.9 6.5 6.5 6.5m0-1c3 0 5.5-2.5 5.5-5.5S20.5 12 17.5 12 12 14.5 12 17.5s2.5 5.5 5.5 5.5"/></svg>)}
    />
  );
}
  