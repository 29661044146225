import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMenuRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.75 4.5a.76.76 0 0 1 .75-.75h21a.75.75 0 0 1 0 1.5h-21a.76.76 0 0 1-.75-.75m21.75 6.75h-21a.75.75 0 0 0 0 1.5h21a.75.75 0 0 0 0-1.5m0 7.5h-21a.75.75 0 0 0 0 1.5h21a.75.75 0 0 0 0-1.5"/></svg>)}
    />
  );
}
  