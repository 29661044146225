import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNonWatchableFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m5.3.3 3.9 2.2v16.9c-.3.4-.5 1-.5 1.5v.2c0 .2 0 .4.1.6l-3.5 1.9c-1.5.8-3.3-.2-3.3-2V2.3C2 .5 3.8-.5 5.3.3M13.8 5l9.1 5c1.5.9 1.5 3.1 0 3.9l-9.1 5zm-2.3-3.5c.6 0 1 .4 1 1v14.8c0 .6-.4 1-1 1s-1-.4-1-1V2.5c0-.6.4-1 1-1m0 21c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5-1.5.7-1.5 1.5.7 1.5 1.5 1.5"/></svg>)}
    />
  );
}
  