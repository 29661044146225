// @flow

import React, { type Element } from 'react';
import classNames from 'classnames';
import { ReactComponent as TrashBinIcon } from '../../assets/trashbin.svg';
import styles from './TrashBinButton.module.scss';

export type PropsT = {
  id: string,
  onClose: () => void,
  style?: string
};

const TrashBinButton = ({ id, onClose, style }: PropsT): Element<'span'> => (
  <span
    id={id}
    className={classNames(styles.dismiss, style)}
    onClick={onClose}
    role="button"
    tabIndex="0"
    onKeyPress={onClose}
  >
    <TrashBinIcon />
  </span>
);

export default TrashBinButton;
