// @flow
import React, { Component, type Element } from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Field, FormSection, reduxForm, type FormProps, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import IconPinRegular from '@design-system/component-library/src/components/Icon/lib/IconPinRegular';
import IconInvoiceRegular from '@design-system/component-library/src/components/Icon/lib/IconInvoiceRegular';
import IconEmailReadRegular from '@design-system/component-library/src/components/Icon/lib/IconEmailReadRegular';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../../../../commonTypes';
import type { AddressT, LocationEntityT } from '../../../../ducks/entities/location/locationTypes';
import Section from '../../../../components/Section/Section';
import FormInput from '../../../../components/InputComponent/FormInput';
import ActionButton from '../../../../components/Button/ActionButton';
import ConfirmButton from '../../../../components/Button/ConfirmButton';
import CancelButton from '../../../../components/Button/CancelButton';
import LinkButton from '../../../../components/Button/LinkButton';
import fieldValidators, {
  SITE_ADDRESS_MAX_LENGTH,
  SITE_NAME_MAX_LENGTH
} from '../../../../fieldValidators';
import { selectors as locationSelect } from '../../../../ducks/entities/location';
import styles from './EditSite.module.scss';

export type AddressPropsT = {|
  visible: boolean
|};

type LocationDetailsT = {|
  locationDetails: {
    +name: ?string
  },
  visiting: AddressT,
  billing: AddressT,
  postal: AddressT
|};

export type OwnPropsT = {|
  onClose: () => void,
  onDelete: (*) => *,
  changeAddressVisibility: (string, boolean) => void,
  location: LocationEntityT,
  visitingAddress: AddressPropsT,
  billingAddress: AddressPropsT,
  postalAddress: AddressPropsT,
  newLocation: boolean,
  locationNames: () => string[],
  handleFormSubmit: (*, boolean) => Promise<void>,
  endEditing: () => void
|};

type StatePropsT = {|
  initialValues: LocationDetailsT,
  locationName: string,
  visiting: AddressT,
  billing: AddressT,
  postal: AddressT
|};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  // $FlowFixMe
  ...FormProps,
  ...$Exact<WithTranslationProps>
|};

export class EditSite extends Component<PropsT> {
  constructor(props: PropsT) {
    super(props);
    this.validateLocationName = this.validateLocationName.bind(this);
    this.validateGenericAddressField = this.validateGenericAddressField.bind(this);
    this.validateRequired = this.validateRequired.bind(this);
  }

  changeAddress(addressName: string) {
    this.props.change(`${addressName}.streetAddress`, '');
    this.props.change(`${addressName}.addressLine`, '');
    this.props.change(`${addressName}.zip`, '');
    this.props.change(`${addressName}.city`, '');
    this.props.change(`${addressName}.district`, '');
    this.props.change(`${addressName}.country`, '');
  }

  deleteAddress(type: string) {
    const { changeAddressVisibility } = this.props;
    changeAddressVisibility(type, false);
    this.changeAddress(type.toLowerCase());
  }

  addAddress(type: string) {
    const { changeAddressVisibility } = this.props;
    changeAddressVisibility(type, true);
    this.changeAddress(type.toLowerCase());
  }

  validateLocationName: *;

  validateLocationName(value: string): ?string {
    const errorMsg = fieldValidators.locationNameFieldValidator(
      value,
      this.props.t('generic.validators.locationName')
    );

    if (errorMsg) {
      return errorMsg;
    }
    if (value) {
      const locationNameList = this.props
        .locationNames()
        .filter(name => name !== this.props.location.name)
        .map(name => name.toLowerCase());
      if (locationNameList && locationNameList.includes(value.toLowerCase().trim())) {
        return this.props.t('generic.validators.locationNameOverlap');
      }
    }
    return '';
  }

  validateGenericAddressField: *;

  validateGenericAddressField(value: string): ?string {
    return fieldValidators.genericAddressFieldValidator(
      value,
      this.props.t('generic.validators.genericAddressField')
    );
  }

  validateRequired: *;

  validateRequired(value: string): ?string {
    return fieldValidators.requiredValidator(value, this.props.t('generic.validators.required'));
  }

  renderAddressForm(): Element<'div'> {
    const { t, visitingAddress, billingAddress, postalAddress } = this.props;
    return (
      <div style={{ overflow: 'auto' }}>
        <div className={styles.header}>
          <h3>{t('siteEdit.sitesAddress')}</h3>
        </div>
        <div className={styles['edit-site-address-container']}>
          <Section
            title=""
            onSave={() => {}}
            sectionId="visitingAddress"
            alwaysEdit
            className={styles['input-section']}
          >
            {this.renderFormSection('visiting')}
          </Section>
          {!visitingAddress.visible ? (
            <ConfirmButton
              className={styles['add-site-button']}
              id="add-visiting-address-button"
              label={`${t('siteEdit.addAddress')}`}
              onClickAction={(e: SyntheticEvent<HTMLButtonElement>): void => {
                if (e) e.preventDefault();
                this.addAddress('visiting');
              }}
            />
          ) : (
            <LinkButton
              className={styles['site-button']}
              id="remove-visiting-address-button"
              label={`${t('siteEdit.deleteAddress')}`}
              onClickAction={(e: Event): void => {
                if (e) e.preventDefault();
                this.deleteAddress('visiting');
              }}
            />
          )}
        </div>
        <div className={styles['edit-site-address-container']}>
          <Section
            onSave={() => {}}
            sectionId="billingAddress"
            alwaysEdit
            className={styles['input-section--without-title']}
          >
            {this.renderFormSection('billing')}
          </Section>
          {!billingAddress.visible ? (
            <ConfirmButton
              className={styles['add-site-button']}
              id="add-billing-address-button"
              label={`${t('siteEdit.addAddress')}`}
              onClickAction={(e: SyntheticEvent<HTMLButtonElement>): void => {
                if (e) e.preventDefault();
                this.addAddress('billing');
              }}
            />
          ) : (
            <LinkButton
              className={styles['site-button']}
              id="remove-billing-address-button"
              label={`${t('siteEdit.deleteAddress')}`}
              onClickAction={(e: Event): void => {
                if (e) e.preventDefault();
                this.deleteAddress('billing');
              }}
            />
          )}
        </div>
        <div className={styles['edit-site-address-container']}>
          <Section
            id="edit-site-postal-section"
            onSave={() => {}}
            sectionId="postalAddress"
            alwaysEdit
            className={styles['input-section--without-title']}
          >
            {this.renderFormSection('postal')}
          </Section>
          {!postalAddress.visible ? (
            <ConfirmButton
              className={styles['add-site-button']}
              id="add-postal-address-button"
              label={`${t('siteEdit.addAddress')}`}
              onClickAction={(e: SyntheticEvent<HTMLButtonElement>): void => {
                if (e) e.preventDefault();
                this.addAddress('postal');
              }}
            />
          ) : (
            <LinkButton
              className={styles['site-button']}
              id="remove-postal-address-button"
              label={`${t('siteEdit.deleteAddress')}`}
              onClickAction={(e: Event): void => {
                if (e) e.preventDefault();
                this.deleteAddress('postal');
              }}
            />
          )}
        </div>
      </div>
    );
  }

  renderFormSection(addressType: string) {
    const { t, visitingAddress, billingAddress, postalAddress } = this.props;
    let icon = <IconPinRegular className={styles.icon} />;
    let title = t('siteEdit.visitingAddress');
    let fieldsVisible = false;
    switch (addressType) {
      case 'visiting':
        fieldsVisible = visitingAddress.visible;
        break;
      case 'billing':
        icon = <IconInvoiceRegular className={styles.icon} />;
        title = t('siteEdit.billingAddress');
        fieldsVisible = billingAddress.visible;
        break;
      case 'postal':
        icon = <IconEmailReadRegular className={styles.icon} />;
        title = t('siteEdit.postalAddress');
        fieldsVisible = postalAddress.visible;
        break;
      default:
        break;
    }
    return (
      <FormSection name={addressType.toLowerCase()}>
        <h4>
          {icon}
          {title}
        </h4>
        {fieldsVisible && (
          <div>
            <Field
              id={`${addressType}_streetAddress`}
              key={`${addressType}_streetAddress`}
              label={`${t('siteEdit.streetAddress')}`}
              name="streetAddress"
              maxlength={SITE_ADDRESS_MAX_LENGTH}
              component={FormInput}
              className={styles['input-field']}
              validate={[this.validateRequired, this.validateGenericAddressField]}
            />
            <Field
              id={`${addressType}_addressLine`}
              key={`${addressType}_addressLine`}
              label={`${t('siteEdit.addressLine')}`}
              name="addressLine"
              maxlength={SITE_ADDRESS_MAX_LENGTH}
              component={FormInput}
              className={styles['input-field']}
              validate={[this.validateGenericAddressField]}
              optional
              i18n_input_optionalText=""
            />
            <Field
              id={`${addressType}_zip`}
              key={`${addressType}_zip`}
              label={`${t('siteEdit.zipCode')}`}
              name="zip"
              optional
              maxlength={SITE_ADDRESS_MAX_LENGTH}
              i18n_input_optionalText=""
              component={FormInput}
              className={styles['input-field']}
              validate={[this.validateGenericAddressField]}
            />
            <Field
              id={`${addressType}_city`}
              key={`${addressType}_city`}
              label={`${t('siteEdit.city')}`}
              name="city"
              optional
              maxlength={SITE_ADDRESS_MAX_LENGTH}
              i18n_input_optionalText=""
              component={FormInput}
              className={styles['input-field']}
              validate={[this.validateGenericAddressField]}
            />
            <Field
              id={`${addressType}_district`}
              key={`${addressType}_district`}
              label={`${t('siteEdit.district')}`}
              name="district"
              component={FormInput}
              maxlength={SITE_ADDRESS_MAX_LENGTH}
              className={styles['input-field']}
              validate={[this.validateGenericAddressField]}
              optional
              i18n_input_optionalText=""
            />
            <Field
              id={`${addressType}_country`}
              key={`${addressType}_country`}
              label={`${t('siteEdit.country')}`}
              name="country"
              optional
              maxlength={SITE_ADDRESS_MAX_LENGTH}
              i18n_input_optionalText=""
              component={FormInput}
              className={styles['input-field']}
              validate={[this.validateGenericAddressField]}
            />
          </div>
        )}
      </FormSection>
    );
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  render(): Element<'form'> {
    const {
      endEditing,
      location,
      t,
      onClose,
      onDelete,
      handleSubmit,
      submitting,
      newLocation,
      invalid,
      submitFailed,
      handleFormSubmit
    } = this.props;
    return (
      <form
        onSubmit={handleSubmit(values => handleFormSubmit(values, newLocation))}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        }}
      >
        <Section onSave={() => {}} sectionId="locationDetails" alwaysEdit>
          <FormSection name="locationDetails" className={styles['upper-area']}>
            <div className={styles['name-edit-container']}>
              <Field
                id="name"
                key="name"
                label={`${t('siteEdit.name')}`}
                name="name"
                component={FormInput}
                maxlength={SITE_NAME_MAX_LENGTH}
                validate={this.validateLocationName}
                className={classnames(styles['name-input'], styles['input-field'])}
              />
              {!newLocation && (
                <ConfirmButton
                  id="sites-delete-button"
                  className={styles['delete-button']}
                  label={t('siteEdit.deleteButton')}
                  onClickAction={() => onDelete(location.id)}
                />
              )}
            </div>
          </FormSection>
        </Section>
        {this.renderAddressForm()}
        <div className={styles.actions}>
          <div>
            <ActionButton
              id="save-sites-button"
              type="submit"
              className={styles['save-button']}
              disabled={submitting}
              loading={submitting}
              label={t('siteEdit.saveButton')}
            />
            <CancelButton
              id="cancel-sites-button"
              className={styles['cancel-button']}
              label={t('siteEdit.cancelButton')}
              onClickAction={() => (newLocation ? onClose() : endEditing())}
            />
          </div>
          {submitFailed && invalid && (
            <p className={styles['submit-validation-error-text']} role="status">
              {t('generic.formValidationError')}
            </p>
          )}
        </div>
      </form>
    );
  }
}

function getAddress(type, addresses) {
  const address = addresses.filter(addr => addr.type === type);
  return address[0]
    ? {
        ...address[0],
        // $FlowFixMe
        streetAddress: R.pathOr('', ['address1'], address[0]),
        // $FlowFixMe
        addressLine: R.pathOr('', ['address2'], address[0])
      }
    : { type };
}

type MapLocationDataToFieldValuesFnT = LocationEntityT => LocationDetailsT;
const mapLocationDataToFieldValues: MapLocationDataToFieldValuesFnT = ({ name, addresses }) => ({
  locationDetails: {
    name
  },
  visiting: getAddress('visiting', addresses),
  billing: getAddress('billing', addresses),
  postal: getAddress('postal', addresses)
});

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => {
  const selectFromForm = formValueSelector('sites');
  const { currentUser } = state;
  return {
    initialValues: mapLocationDataToFieldValues(ownProps.location),
    visiting: selectFromForm(state, 'visiting'),
    billing: selectFromForm(state, 'billing'),
    postal: selectFromForm(state, 'postal'),
    locationName: selectFromForm(state, 'locationDetails.name'),
    locationNames: () =>
      locationSelect.locationNames(
        state,
        currentUser.currentEnterprise ? currentUser.currentEnterprise.id : ''
      )
  };
};

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps),
  reduxForm({
    form: 'sites',
    enableReinitialize: true
  })
)(EditSite);
