import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLockLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 9h-1V6c0-3.3-2.7-6-6-6S6 2.7 6 6v3H5c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2M7 6c0-2.8 2.2-5 5-5s5 2.2 5 5v3H7zm13 16c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V11c0-.6.4-1 1-1h14c.6 0 1 .4 1 1zm-7.5-7v3c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5s.5.2.5.5"/></svg>)}
    />
  );
}
  