// @flow
import type { CallFlowTreeNodeT } from '../../../../ducks/entities/callFlow';

export type TreeHeightFnT = (?CallFlowTreeNodeT) => number;
export const treeHeight: TreeHeightFnT = rootNode => {
  if (!rootNode) {
    return 0;
  }
  if (!rootNode.verticalNode && !rootNode.horizontalNode) {
    return 1;
  }
  const maxHeight1 = treeHeight(rootNode.verticalNode) + 1;
  const maxHeight2 = treeHeight(rootNode.horizontalNode);
  return Math.max(maxHeight1, maxHeight2);
};

export type TreeWidthFnT = (?CallFlowTreeNodeT) => number;
export const treeWidth: TreeWidthFnT = rootNode => {
  if (!rootNode) {
    return 0;
  }
  if (!rootNode.verticalNode && !rootNode.horizontalNode) {
    return 1;
  }
  const subTreeWidth1 = treeWidth(rootNode.verticalNode);
  const subTreeWidth2 = treeWidth(rootNode.horizontalNode) + 1;
  return Math.max(subTreeWidth1, subTreeWidth2);
};

// eslint-disable-next-line flowtype/type-id-match
export type FindMaxPositionXFromTreeFnT = (?CallFlowTreeNodeT) => number;
export const findMaxXPositionFromTree: FindMaxPositionXFromTreeFnT = rootNode => {
  if (!rootNode || !rootNode.coordinate) {
    return 0;
  }

  const maxWidth1 = findMaxXPositionFromTree(rootNode.verticalNode);
  const maxWidth2 = findMaxXPositionFromTree(rootNode.horizontalNode);
  return Math.max(rootNode.coordinate ? rootNode.coordinate.x : 0, Math.max(maxWidth1, maxWidth2));
};

export type GetVerticalNodesFnT = (?CallFlowTreeNodeT) => CallFlowTreeNodeT[];
export const getVerticalNodes: GetVerticalNodesFnT = node =>
  node
    ? (({ verticalNode }) =>
        verticalNode != null ? [node, ...getVerticalNodes(verticalNode)] : [])(node)
    : [];

export type PrintTreeFnT = (?CallFlowTreeNodeT) => void;
export const printNodePositions: PrintTreeFnT = rootNode => {
  if (!rootNode) {
    return;
  }
  const queue = [rootNode];
  let currentNode = queue.pop();

  while (currentNode) {
    if (currentNode.verticalNode) {
      queue.push(currentNode.verticalNode);
    }

    if (currentNode.horizontalNode) {
      queue.push(currentNode.horizontalNode);
    }

    currentNode = queue.pop();
  }
};
