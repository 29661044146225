/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/css';
import styles from './RadioGroup.module.scss';
import { useIdWithFallback } from '../../utils/hooks';
import RadioGroupContext from './RadioGroupContext';

// eslint-disable-next-line react/function-component-definition
const RadioInternal = (
  {
    id = null,
    className = null,
    label = null,
    children = null,
    value = false,
    checked = false,
    name = null,
    onGroupChange = () => {},
    onChange = () => {},
    disabled = false,
    required = null,
    inputRef = null,
    inputProps = null,
    ...otherProps
  },
  ref,
) => {
  const htmlId = useIdWithFallback('dsRadio', id);
  const {selectedValue, setSelectedValue} = useContext(RadioGroupContext);
  const [isChecked, setIsChecked] = useState(selectedValue ? value === selectedValue : checked);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useImperativeHandle(ref, () => ({
    disabled: (newDisabledValue) => {
      if(newDisabledValue === true || newDisabledValue === false){        
        setIsDisabled(newDisabledValue);
      }
      else {
        return isDisabled;
      }
    }
  }));

  const onValueChange = (e) => {
    const val = e.target.value || e.target.dataset.value;
    setSelectedValue(val);

    if(onChange) onChange(e);

    if(onGroupChange) onGroupChange(val);
  }

  useEffect(() => {
    if(!selectedValue && checked) {
      setSelectedValue(value);
      setIsChecked(true);
    }
    else {
      setIsChecked(selectedValue === value);
    }
  }, [selectedValue]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const allClasses = classNames([styles.radio, disabled ? styles[`radio--disabled`] : null, className]);
  const labelClasses = classNames([styles.radio__label]);

  return(
    <div id={`${htmlId}Container`} className={allClasses} {...otherProps}>
      <input
        ref={ref || inputRef}
        type="radio"
        id={htmlId}
        name={name || htmlId}
        value={value || htmlId}
        checked={isChecked}
        onChange={onValueChange}
        disabled={isDisabled}
        required={required}
        {...inputProps}
      />
      <label className={labelClasses} htmlFor={htmlId}>
        <span className={styles.radio__icon} />
        {label || children}
      </label>
    </div>
  );
}

const Radio = React.forwardRef(RadioInternal);
export default Radio;

Radio.propTypes = {
  /** Radio button's id */
  id: PropTypes.string,
  /** Any CSS classes for the component */
  className: PropTypes.string,
  /** Radio's label text */
  label: PropTypes.string,
  /** Component's content. Automatically detected. */
  children: PropTypes.node,
  /** Radio's value */
  value: PropTypes.string,
  /** If present, indicates that this radio button is the default selected one in the group. */
  checked: PropTypes.bool,
  /** Radio's name */
  name: PropTypes.string,

  onGroupChange: PropTypes.func,
  /** The function run after the radio is checked or unchecked */
  onChange: PropTypes.func,
  /** Whether the Radio is disabled */
  disabled: PropTypes.bool,
  /** Read more: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#required */
  required: PropTypes.bool,
  /** Ref for the actual input element, not the wrapper */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
  /** Additional properties that are passed to the input element. Handy for adding data-attributes. */
  inputProps: PropTypes.object,
};