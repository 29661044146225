import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';

import IconDeleteRegular from '../Icon/lib/IconDeleteRegular';
import IconAddRegular from '../Icon/lib/IconAddRegular';
import IconRemoveRegular from '../Icon/lib/IconRemoveRegular';
import { useIdWithFallback } from '../../utils/hooks';
import { classNames } from '../../utils/css';

import styles from './Quantity.module.scss';

function QuantityInternal(
  {
    id = null,
    className = null,
    disabled = false,
    currentValue = 0,
    minValue = -1*Number. MAX_VALUE,
    maxValue = null,
    valueIncrement = 1,
    removeLessThanMin = false,
    onRemove = () => {},
    onChange = () => {},
    controlled = null,
    i18n_quantity_quantityAriaLabel = 'Määrä',
    i18n_quantity_minusAriaLabel = 'Vähennä yksi',
    i18n_quantity_plusAriaLabel = 'Lisää yksi',
    i18n_quantity_deleteAriaLabel = 'Poista',
    inputProps = null,
    ...otherProps
  },
  ref
) {
  const quantityRef = useRef(null);
  const htmlId = useIdWithFallback('dsQuantity', id);
  const isControlled = controlled || (currentValue !== '' && currentValue !== 0);
  const [valueNow, setValueNow] = useState(currentValue);
  const [disabledState, setDisabledState] = useState(disabled);

  const onMinusClick = () => {
    let newQuantity = parseInt(Number(valueNow), 10) - valueIncrement;

    if (newQuantity <= minValue) {
      if (removeLessThanMin && newQuantity < minValue) {
        onRemove(htmlId);
        newQuantity = minValue;
      } else {
        newQuantity = minValue;
      }
    }

    setValueNow(newQuantity);
    quantityRef.current.currentValue = newQuantity;
    onChange(newQuantity);
  }

  const onPlusClick = () => {
    let newQuantity = parseInt(valueNow, 10) + valueIncrement;
    if (maxValue && newQuantity > maxValue) {
      newQuantity = maxValue;
    }

    setValueNow(newQuantity);
    quantityRef.current.currentValue = newQuantity;
    onChange(newQuantity);
  }

  const onDirectChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10) || '';

    if (minValue && newQuantity < minValue) {
      return null;
    }
    if (maxValue && newQuantity > maxValue) {
      return null;
    }

    setValueNow(newQuantity);
    quantityRef.current.currentValue = newQuantity;
    onChange(newQuantity);
  }

  useImperativeHandle(ref, () => ({
    currentValue: (newValue) => {
      if (newValue) {
        quantityRef.current.currentValue = newValue;
        setValueNow(newValue);

        if (onChange) {
          onChange(newValue);
        }
      }
      else {
        const clearValue = minValue !== -1*Number. MAX_VALUE ? minValue : 0;
        setValueNow(clearValue);
        quantityRef.current.currentValue = clearValue;

        if (onChange) {
          onChange(clearValue);
        }
      }
    },

    getValue: () => {
      if(isControlled){
        return currentValue;
      }

      return quantityRef.current.value;
    },

    // eslint-disable-next-line consistent-return
    disabled: (state) => {
      if(state === true || state === false){
        setDisabledState(state);
      }
      else {
        return disabledState;
      }
    },
  }));

  useEffect(() => {
    setValueNow(currentValue);
  }, [currentValue]);

  useEffect(() => {
    setDisabledState(disabled);
  }, [disabled]);

  const allClasses = classNames([styles.quantity, disabledState ? styles[`quantity-disabled`] : null, className || null]);
  const dw = `${valueNow}`.length;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div id={htmlId} className={allClasses} {...otherProps}>
      <button
        type="button"
        id={`${htmlId}-minus`}
        aria-label={removeLessThanMin && valueNow === minValue ? i18n_quantity_deleteAriaLabel : i18n_quantity_minusAriaLabel}
        onClick={onMinusClick}
        className={classNames([styles[`quantity--button`], styles[`quantity--button-remove`]])}
        disabled={disabledState}
      >
        {removeLessThanMin && valueNow === minValue ? <IconDeleteRegular /> : <IconRemoveRegular />}
      </button>
      <input
        aria-label={i18n_quantity_quantityAriaLabel}
        type="text"
        size={dw > 0 ? dw : 1}
        pattern="-*[0-9]*"
        onChange={(e) => {
          onDirectChange(e);
        }}
        className={styles[`quantity--amount`]}
        value={`${valueNow}`}
        disabled={disabledState}
        ref={quantityRef}
        {...inputProps}
        // key={`${valueNow}`}
      />
      <button
        type="button"
        id={`${htmlId}-plus`}
        aria-label={i18n_quantity_plusAriaLabel}
        onClick={onPlusClick}
        className={classNames([styles[`quantity--button`], styles[`quantity--button-add`]])}
        disabled={disabledState}
      >
        <IconAddRegular />
      </button>
    </div>
  );
}

const Quantity = React.forwardRef(QuantityInternal);
export default Quantity;

Quantity.propTypes = {
  /**
   * Element's id
   */
  id: PropTypes.string,
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Whether the component is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Current quantity
   */
  currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Minimum quantity
   */
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Maximum quantity
   */
  maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * How much will be added or removed when button is clicked
   */
  valueIncrement: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If value is same as minValue the minus button becomes a trash can button
   */
  removeLessThanMin: PropTypes.bool,
  /**
   * Function that is ran when remove button (trash can) is clicked
   */
  onRemove: PropTypes.func,
  /**
   * Function that is ran when quantity is changed
   */
  onChange: PropTypes.func,
  /**
   * Forces the component to be controlled of uncontrolled.
   * If not specified, the component tries to deduct this from given props (controlled component has currentValue prop while uncontrolled doesn't).
   */
  controlled: PropTypes.bool,
  /**
   * Aria-label for the quantity input
   */
  i18n_quantity_quantityAriaLabel: PropTypes.string,
  /**
   * Aria-label for the removing button
   */
  i18n_quantity_minusAriaLabel: PropTypes.string,
  /**
   * Aria-label for the adding button
   */
  i18n_quantity_plusAriaLabel: PropTypes.string,
  /**
   * Aria-label for the delete button
   */
  i18n_quantity_deleteAriaLabel: PropTypes.string,
  /**
   * Additional properties that are passed to the input element
   */
  inputProps: PropTypes.object
};
