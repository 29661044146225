// @flow

import reducer from './departmentUiReducer';
import type { StateT, SelectUserActionT, DeselectUserActionT } from './departmentUiTypes';
import * as actionCreators from './departmentUiActions';
import * as selectors from './departmentUiSelectors';

export default reducer;
export { actionCreators, selectors };
export type { StateT, SelectUserActionT, DeselectUserActionT };
