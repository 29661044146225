import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGuideFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 5.5c0 .3-.1.6-.4.9l-2.5 2.3q-.3.3-.9.3h-8v1.5h7.5c.7 0 1.2.6 1.2 1.2v4.5c0 .7-.6 1.2-1.2 1.2h-7.5v5.8c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-5.8h-7c-.3 0-.6-.1-.9-.4L.2 14.8c-.5-.5-.5-1.3 0-1.8l2.5-2.3c.2-.2.5-.3.8-.3h7V9H4.2C3.6 9 3 8.4 3 7.8V3.2C3 2.6 3.6 2 4.2 2h6.5V.8c0-.4.3-.8.8-.8s.8.3.8.8V2h8c.3 0 .6.1.9.4l2.5 2.2c.2.2.3.6.3.9"/></svg>)}
    />
  );
}
  