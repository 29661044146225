// @flow

import React, { type Element } from 'react';
import styles from './TabSelector.module.scss';

export type TabT = {|
  +tabName: string,
  +title: string,
  +onClick: () => *
|};

export type PropsT = {
  +tabs: TabT[],
  +selectedTab: string
};

export const TabSelector = (props: PropsT): Element<'div'> => {
  const { tabs, selectedTab } = props;
  return (
    <div className={styles.container}>
      {tabs.map(tab => {
        const { tabName, title, onClick } = tab;
        const classes = selectedTab === tabName ? styles['active-selection'] : styles.selection;
        return (
          <div className={styles.selector} key={tabName}>
            <div
              className={classes}
              id={`tabbed-header-${tabName}`}
              onClick={onClick}
              role="button"
              tabIndex="0"
              onKeyPress={onClick}
            >
              <span className={styles.text}>{title}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TabSelector;
