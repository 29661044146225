// @flow strict-local

import * as R from 'ramda';
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CallbackIcon } from '../../../../../assets/callflow/details/callback-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import { acdCallbackSchema } from './EditCallbackDetails';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import CallflowItem from '../../../components/view/children/CallflowItem';
import { getInitialCallbackOption } from './CallbackUtils';

export type PropsT = {|
  callflowId: string
|};

export const CallbackDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;

  const { t } = useTranslation();

  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const callflowAudioFieldConverter = new AudioFieldConverter(
    acdData.enterpriseId,
    acdData.id,
    'acds'
  );

  const callbackMethodOptions = [
    {
      label: t('callflows.editAcdCallbackDetails.callbackDisabled'),
      value: 'disabled'
    },
    {
      label: t('callflows.editAcdCallbackDetails.proposeCallbackRegularly'),
      value: 'proposeCallbackRegularly'
    },
    {
      label: t('callflows.editAcdCallbackDetails.whenQueueFullOrTimeout'),
      value: 'whenQueueFullOrTimeout'
    },
    {
      label: t('callflows.editAcdCallbackDetails.callbackSystematic'),
      value: 'always'
    }
  ];
  const callbackMethod = getInitialCallbackOption(acdData);
  const callbackMethodText = callbackMethodOptions.filter(
    option => option.value === callbackMethod
  )[0].label;

  return (
    <CallflowDetails
      enterpriseId={acdData.enterpriseId}
      callflowId={acdData.id}
      elementType={acdData.type}
      nodeId={`acd_callback_${acdData.id}`}
      nodeType="ACD_CALLBACK"
      icon={<CallbackIcon />}
      title={t('callflows.viewAcdCallbackDetails.title')}
      active={acdData.acdGroupCallbackEnabled}
    >
      <CallflowDetailsColumn id="callback-column-1">
        <CallflowItem
          id="callbackMethod"
          title={t('callflows.viewAcdCallbackDetails.callbackMethod')}
          value={callbackMethodText}
        />
        <CallflowProperty
          id="callbackKeepTimeout"
          title={t('callflows.viewAcdCallbackDetails.callbackKeepTimeout')}
          property={acdCallCenter.callbackKeepTimeout}
          postMsg={t('callflows.viewAcdCallbackDetails.callbackKeepTimeoutPostMsg')}
          tooltip={t(
            'callflows.viewAcdCallbackDetails.callbackKeepTimeoutTooltip',
            // $FlowFixMe: TODO: fix
            getIntegerFieldLimits(acdCallbackSchema.fields.callbackKeepTimeout)
          )}
          basicValue
        />
        <CallflowProperty
          id="callbackRingingTimeout"
          title={t('callflows.viewAcdCallbackDetails.callbackRingingTimeout')}
          property={acdCallCenter.callbackRingingTimeout}
          tooltip={t(
            'callflows.viewAcdCallbackDetails.callbackRingingTimeoutTooltip',
            // $FlowFixMe: TODO: fix
            getIntegerFieldLimits(acdCallbackSchema.fields.callbackRingingTimeout)
          )}
          basicValue
        />
        <CallflowProperty
          id="callbackMaxAttempts"
          title={t('callflows.viewAcdCallbackDetails.callbackMaxAttempts')}
          property={acdCallCenter.callbackMaxAttempts}
          tooltip={t(
            'callflows.viewAcdCallbackDetails.callbackMaxAttemptsTooltip',
            // $FlowFixMe: TODO: fix
            getIntegerFieldLimits(acdCallbackSchema.fields.callbackMaxAttempts)
          )}
          postMsg=""
          basicValue
        />
        <CallflowProperty
          id="callbackFeedbackDuration"
          title={t('callflows.viewAcdCallbackDetails.callbackFeedbackDuration')}
          property={acdCallCenter.callbackFeedbackDuration}
          tooltip={t(
            'callflows.viewAcdCallbackDetails.callbackFeedbackDurationTooltip',
            // $FlowFixMe: TODO: fix
            getIntegerFieldLimits(acdCallbackSchema.fields.callbackFeedbackDuration)
          )}
          basicValue
        />
        <CallflowProperty
          id="callbackReinjectionPauseDuration"
          title={t('callflows.viewAcdCallbackDetails.callbackReinjectionPauseDuration')}
          property={acdCallCenter.callbackReinjectionPauseDuration}
          tooltip={t(
            'callflows.viewAcdCallbackDetails.callbackReinjectionPauseDurationTooltip',
            // $FlowFixMe: TODO: fix
            getIntegerFieldLimits(acdCallbackSchema.fields.callbackReinjectionPauseDuration)
          )}
          basicValue
        />
      </CallflowDetailsColumn>
      <CallflowDetailsColumn id="callback-column-2">
        <CallflowAudioProperty
          title={t('callflows.viewAcdCallbackDetails.callbackAnnouncement')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackAnnouncement',
            R.path(['audios', 'callbackAnnouncement', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackAnnouncement'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.viewAcdCallbackDetails.callbackAnnouncementTooltip')}
          isActive={acdData.acdGroupCallbackEnabled}
        />
        <CallflowAudioProperty
          title={t('callflows.viewAcdCallbackDetails.callbackAnnouncementBeforeExiting')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackAnnouncementBeforeExiting',
            R.path(
              ['audios', 'callbackAnnouncementBeforeExiting', 'extensionAudio', 'name'],
              acdCallCenter
            ),
            R.path(['callbackAnnouncementBeforeExiting'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.viewAcdCallbackDetails.callbackAnnouncementBeforeExitingTooltip')}
          isActive={acdData.acdGroupCallbackEnabled}
        />
        <CallflowAudioProperty
          title={t('callflows.viewAcdCallbackDetails.callbackAlreadyInQueue')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackAlreadyInQueue',
            R.path(['audios', 'callbackAlreadyInQueue', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackAlreadyInQueue'], ivrAcdCallCenter)
          )}
          isActive={acdData.acdGroupCallbackEnabled}
        />
        <CallflowAudioProperty
          title={t('callflows.viewAcdCallbackDetails.callbackRegistered')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackRegistered',
            R.path(['audios', 'callbackRegistered', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackRegistered'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.viewAcdCallbackDetails.callbackRegisteredTooltip')}
          isActive={acdData.acdGroupCallbackEnabled}
        />
        <CallflowAudioProperty
          title={t('callflows.viewAcdCallbackDetails.callbackWaitAnswer')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'CallbackWaitAnswer',
            R.path(['audios', 'callbackWaitAnswer', 'extensionAudio', 'name'], acdCallCenter),
            R.path(['callbackWaitAnswer'], ivrAcdCallCenter)
          )}
          tooltip={t('callflows.viewAcdCallbackDetails.callbackWaitAnswerTooltip')}
          isActive={acdData.acdGroupCallbackEnabled}
        />
        <CallflowProperty
          id="callbackSendSmsOnRegister"
          title={t('callflows.viewAcdCallbackDetails.callbackSendSmsOnRegister')}
          property={acdCallCenter.callbackSendSmsOnRegister}
          basicValue
        />
        <CallflowProperty
          id="callbackSendSmsOnSuccess"
          title={t('callflows.viewAcdCallbackDetails.callbackSendSmsOnSuccess')}
          property={acdCallCenter.callbackSendSmsOnSuccess}
          basicValue
        />
        <CallflowProperty
          id="callbackSendSmsOnError"
          title={t('callflows.viewAcdCallbackDetails.callbackSendSmsOnError')}
          property={acdCallCenter.callbackSendSmsOnError}
          basicValue
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default CallbackDetails;
