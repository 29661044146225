// @flow
import React, { type Element } from 'react';
import Button from '@design-system/component-library/src/components/Button';
import classnames from 'classnames';
import styles from './ConfirmButton.module.scss';

export type PropsT = {
  id: string,
  className?: string,
  disabled?: boolean,
  label: string,
  onClickAction: (SyntheticEvent<HTMLButtonElement>) => void
};

const ConfirmButton = (props: PropsT): Element<'Button'> => {
  const { id, className, disabled, label, onClickAction } = props;
  return (
    <Button
      id={id}
      type="button"
      value="Confirm"
      className={classnames(styles['confirm-button'], className)}
      color="light"
      size="small"
      onClick={onClickAction}
      disabled={disabled}
      data-cy={`confirm-button-${id}`}
    >
      {label}
    </Button>
  );
};

ConfirmButton.defaultProps = {
  className: '',
  disabled: false
};

export default ConfirmButton;
