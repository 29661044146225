// @flow

import { type $AxiosError } from 'axios';
import type {
  RetrieveCalendarRequestT,
  RetrieveCalendarSuccessT,
  RetrieveCalendarFailureT,
  RetrieveCalendarCancelT,
  BaseCalendarEntityT,
  RetrieveCalendarsCancelT,
  RetrieveCalendarsFailureT,
  RetrieveCalendarsSuccessT,
  RetrieveCalendarsRequestT,
  UpdateCalendarRequestT,
  CreateCalendarSuccessT,
  CreateCalendarFailureT,
  CreateCalendarCancelT,
  CreateCalendarRequestT,
  UpdateCalendarSuccessT,
  UpdateCalendarFailureT,
  UpdateCalendarCancelT,
  DeleteCalendarFailureT,
  DeleteCalendarSuccessT,
  DeleteCalendarRequestT,
  DeleteCalendarCancelT
} from './calendarTypes';

type CreateRetrieveCalendarRequestFnT = (string, string, ?{}) => RetrieveCalendarRequestT;

export const createRetrieveCalendarRequest: CreateRetrieveCalendarRequestFnT = (
  enterpriseId,
  calendarId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/RETRIEVE_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId, calendarId }
});

type CreateRetrieveCalendarSuccessFnT = (
  string,
  string,
  BaseCalendarEntityT,
  ?{}
) => RetrieveCalendarSuccessT;

export const createRetrieveCalendarSuccess: CreateRetrieveCalendarSuccessFnT = (
  enterpriseId,
  calendarId,
  calendar,
  meta = {}
) => ({
  type: 'ring/entity/calendar/RETRIEVE_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId,
    calendarId
  },
  payload: calendar
});

type CreateRetrieveCalendarFailureFnT = (
  string,
  string,
  $AxiosError<*>,
  ?{}
) => RetrieveCalendarFailureT;

export const createRetrieveCalendarFailure: CreateRetrieveCalendarFailureFnT = (
  enterpriseId,
  calendarId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/calendar/RETRIEVE_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId, calendarId },
  payload: error
});

type CreateRetrieveCalendarCancelFnT = () => RetrieveCalendarCancelT;
export const createRetrieveCalendarCancel: CreateRetrieveCalendarCancelFnT = () => ({
  type: 'ring/entity/calendar/RETRIEVE_CANCEL',
  error: false,
  meta: {}
});

type CreateRetrieveCalendarsRequestFnT = (string, ?{}) => RetrieveCalendarsRequestT;

export const createRetrieveCalendarsRequest: CreateRetrieveCalendarsRequestFnT = (
  enterpriseId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/RETRIEVE_ALL_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});

type CreateRetrieveCalendarsSuccessFnT = (
  string,
  { results: BaseCalendarEntityT[] },
  ?{}
) => RetrieveCalendarsSuccessT;

export const createRetrieveCalendarsSuccess: CreateRetrieveCalendarsSuccessFnT = (
  enterpriseId,
  calendars,
  meta = {}
) => ({
  type: 'ring/entity/calendar/RETRIEVE_ALL_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId
  },
  payload: calendars
});

type CreateRetrieveCalendarsFailureFnT = (string, $AxiosError<*>, ?{}) => RetrieveCalendarsFailureT;

export const createRetrieveCalendarsFailure: CreateRetrieveCalendarsFailureFnT = (
  enterpriseId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/calendar/RETRIEVE_ALL_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});

type CreateRetrieveCalendarsCancelFnT = () => RetrieveCalendarsCancelT;
export const createRetrieveCalendarsCancel: CreateRetrieveCalendarsCancelFnT = () => ({
  type: 'ring/entity/calendar/RETRIEVE_ALL_CANCEL',
  error: false,
  meta: {}
});

// Create calendar
type CreateCreateCalendarRequestFnT = (string, ?{}) => CreateCalendarRequestT;
export const createCreateCalendarRequest: CreateCreateCalendarRequestFnT = (
  enterpriseId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/CREATE_REQUEST',
  meta: { ...meta, enterpriseId },
  error: false
});

type CreateCreateCalendarSuccessFnT = (string, BaseCalendarEntityT, ?{}) => CreateCalendarSuccessT;
export const createCreateCalendarSuccess: CreateCreateCalendarSuccessFnT = (
  enterpriseId,
  calendar,
  meta = {}
) => ({
  type: 'ring/entity/calendar/CREATE_SUCCESS',
  payload: calendar,
  meta: { ...meta, enterpriseId },
  error: false
});

type CreateCreateCalendarFailureFnT = (string, $AxiosError<*>, ?{}) => CreateCalendarFailureT;
export const createCreateCalendarFailure: CreateCreateCalendarFailureFnT = (
  enterpriseId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/calendar/CREATE_FAILURE',
  meta: { ...meta, enterpriseId },
  payload: error,
  error: true
});

type CreateCreateCalendarCancelFnT = (string, ?{}) => CreateCalendarCancelT;
export const createCreateCalendarCancel: CreateCreateCalendarCancelFnT = (
  enterpriseId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/CREATE_CANCEL',
  meta: { ...meta, enterpriseId },
  error: false
});

// update calendar
type CreateUpdateCalendarRequestFnT = (string, string, ?{}) => UpdateCalendarRequestT;
export const createUpdateCalendarRequest: CreateUpdateCalendarRequestFnT = (
  enterpriseId,
  calendarId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/UPDATE_REQUEST',
  meta: { ...meta, enterpriseId, calendarId },
  error: false
});

type CreateUpdateCalendarSuccessFnT = (
  string,
  string,
  BaseCalendarEntityT,
  ?{}
) => UpdateCalendarSuccessT;
export const createUpdateCalendarSuccess: CreateUpdateCalendarSuccessFnT = (
  enterpriseId,
  calendarId,
  calendar,
  meta = {}
) => ({
  type: 'ring/entity/calendar/UPDATE_SUCCESS',
  payload: calendar,
  meta: { ...meta, enterpriseId, calendarId },
  error: false
});

type CreateUpdateCalendarFailureFnT = (
  string,
  string,
  $AxiosError<*>,
  ?{}
) => UpdateCalendarFailureT;
export const createUpdateCalendarFailure: CreateUpdateCalendarFailureFnT = (
  enterpriseId,
  calendarId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/calendar/UPDATE_FAILURE',
  meta: { ...meta, enterpriseId, calendarId },
  payload: error,
  error: true
});

type CreateUpdateCalendarCancelFnT = (string, string, ?{}) => UpdateCalendarCancelT;
export const createUpdateCalendarCancel: CreateUpdateCalendarCancelFnT = (
  enterpriseId,
  calendarId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/UPDATE_CANCEL',
  meta: { ...meta, enterpriseId, calendarId },
  error: false
});

// Delete calendar
type CreateDeleteCalendarRequestFnT = (string, string, ?{}) => DeleteCalendarRequestT;
export const createDeleteCalendarRequest: CreateDeleteCalendarRequestFnT = (
  enterpriseId,
  calendarId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/DELETE_REQUEST',
  meta: { ...meta, enterpriseId, calendarId },
  error: false
});

type CreateDeleteCalendarSuccessFnT = (string, string, ?{}) => DeleteCalendarSuccessT;
export const createDeleteCalendarSuccess: CreateDeleteCalendarSuccessFnT = (
  enterpriseId,
  calendarId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/DELETE_SUCCESS',
  meta: { ...meta },
  payload: { enterpriseId, calendarId },
  error: false
});

type CreateDeleteCalendarFailureFnT = (
  string,
  string,
  $AxiosError<*>,
  ?{}
) => DeleteCalendarFailureT;
export const createDeleteCalendarFailure: CreateDeleteCalendarFailureFnT = (
  enterpriseId,
  calendarId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/calendar/DELETE_FAILURE',
  meta: { ...meta, enterpriseId, calendarId },
  payload: error,
  error: true
});

type CreateDeleteCalendarCancelFnT = (string, string, ?{}) => DeleteCalendarCancelT;
export const createDeleteCalendarCancel: CreateDeleteCalendarCancelFnT = (
  enterpriseId,
  calendarId,
  meta = {}
) => ({
  type: 'ring/entity/calendar/DELETE_CANCEL',
  meta: { ...meta, enterpriseId, calendarId },
  error: false
});
