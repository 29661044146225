// @flow

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Canceler } from 'axios';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../BaseContainer/BaseContainer';
import { goToCallFlowCalendars } from '../../../navigationOperations';
import GoBackLink from '../../../components/Button/GoBackLink';
import EditCalendarForm from './EditCalendarForm';
import { create } from '../../../ducks/entities/calendar/calendarOperations';
import CalendarConverter from '../components/edit/children/calendar/CalendarConverterUtils';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import type {
  AbsoluteTimeSlotEntityT,
  WeeklySlotT
} from '../../../ducks/entities/calendar/calendarTypes';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import TopNavigation from '../../navigation/TopNavigation';
import styles from './CreateCalendar.module.scss';

export type PropsT = {|
  match: {
    params: {
      id: string
    }
  }
|};

const CreateCalendar = (props: PropsT): Element<typeof BaseContainer> => {
  const { id: enterpriseId } = props.match.params;

  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();
  const updateCalendarCanceller = React.useRef<Canceler>();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  type FormT = {
    label: string,
    weekSlots: WeeklySlotT[],
    absoluteTimeSlots: AbsoluteTimeSlotEntityT[]
  };

  const calendarTopic = (
    <>
      <GoBackLink
        id="callflow-page-back-link"
        text={t('calendars.createCalendar.backToCalendarsLink')}
        onClick={() => dispatch(goToCallFlowCalendars(enterpriseId))}
      />
      <div className={styles['subheader-container']}>
        <div className={styles.big}>{t('calendars.createCalendar.title')}</div>
      </div>
    </>
  );

  const handleCalendarCreateNotification = success => {
    const notification = {
      tag: success ? 'calendar-create-success' : 'calendar-create-fail',
      duration: 15000,
      type: success ? 'info' : 'error',
      message: success
        ? t('calendars.createCalendar.createSuccessNotification')
        : t('calendars.createCalendar.createFailedNotification')
    };

    dispatch(notificationActions.createCreateNotificationAction(notification));
  };

  const onSaveForm = async (formData: FormT): Promise<void> => {
    const payload = {
      name: `~${enterpriseId}~${formData.label}`,
      weekSchedule: CalendarConverter.convertToUpdateData(formData.weekSlots),
      absoluteTimeSlots: formData.absoluteTimeSlots
    };
    const result = await dispatch(
      create(
        enterpriseId,
        // $FlowFixMe
        payload,
        new CancelToken(canceler => {
          updateCalendarCanceller.current = canceler;
        }),
        createCsrfHeader(currentUser)
      )
    );
    handleCalendarCreateNotification(!!result);
  };

  return (
    <BaseContainer header={<TopNavigation />} containerStyle={styles.container}>
      {calendarTopic}
      <EditCalendarForm
        showLabel
        enterpriseId={enterpriseId}
        calendar={null}
        // $FlowFixMe
        onSaveForm={onSaveForm}
        onCancel={() => dispatch(goToCallFlowCalendars(enterpriseId))}
      />
    </BaseContainer>
  );
};

export default CreateCalendar;
