import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBookLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.407 11.171a.5.5 0 0 1-.633.315 21.3 21.3 0 0 0-5.253-1.054.5.5 0 0 1-.463-.534.49.49 0 0 1 .534-.463c1.869.133 3.719.504 5.5 1.103a.5.5 0 0 1 .315.633m-.315 3.655a22.2 22.2 0 0 0-5.5-1.104.49.49 0 0 0-.534.463.5.5 0 0 0 .463.535c1.787.127 3.554.481 5.253 1.053a.5.5 0 0 0 .318-.947m5.974-3.314a.5.5 0 0 0 .159-.026 21.3 21.3 0 0 1 5.253-1.054.5.5 0 0 0-.071-.997 22.2 22.2 0 0 0-5.5 1.103.5.5 0 0 0 .159.974m0 4.288a.5.5 0 0 0 .159-.026 21.2 21.2 0 0 1 5.253-1.053.5.5 0 0 0-.071-.998c-1.87.134-3.721.505-5.5 1.104a.5.5 0 0 0 .159.973M24 2.031V19.07a1.03 1.03 0 0 1-.997 1.03c-8.097.11-10.609 2.712-10.634 2.738a.5.5 0 0 1-.179.121l-.01.008A.5.5 0 0 1 12 23c-.034 0-.066-.014-.099-.021-.027-.006-.056-.005-.082-.016l-.003-.002a.5.5 0 0 1-.184-.124c-.026-.027-2.54-2.628-10.627-2.738A1.03 1.03 0 0 1 0 19.066V2.031c0-.273.11-.539.302-.731.191-.194.468-.286.734-.3C7.965 1.092 11.021 2.954 12 3.732c.978-.778 4.034-2.64 10.964-2.732h.014A1.023 1.023 0 0 1 24 2.031M11.5 21.475V4.617C10.899 4.104 8.096 2.094 1.022 2L1 19.069c6.037.104 9.144 1.524 10.5 2.406M23 2.031c-7.087.067-9.894 2.07-10.5 2.586v16.857c1.355-.88 4.459-2.293 10.482-2.375z"/></svg>)}
    />
  );
}
  