import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPictureAddRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.25 10.5A6.75 6.75 0 1 0 24 17.25a6.76 6.76 0 0 0-6.75-6.75m0 12a5.25 5.25 0 1 1 5.25-5.25 5.26 5.26 0 0 1-5.25 5.25M21 17.25a.76.76 0 0 1-.75.75h-2.22v2.25a.75.75 0 0 1-1.5 0V18h-2.28a.75.75 0 0 1 0-1.5h2.28v-2.25a.75.75 0 0 1 1.5 0v2.25h2.22a.76.76 0 0 1 .75.75M9.75 20.5a.75.75 0 0 1-.75.75H2.25A2.28 2.28 0 0 1 0 19V2.25A2.28 2.28 0 0 1 2.25 0H13c.607.001 1.19.242 1.62.67l2.93 2.93c.448.432.701 1.028.7 1.65V8.5a.75.75 0 0 1-1.5 0V5.25a.75.75 0 0 0-.23-.55l-2.94-2.97A.75.75 0 0 0 13 1.5H2.25a.78.78 0 0 0-.75.75V19c0 .431.349.78.78.78h6.75a.76.76 0 0 1 .72.75zM3.88 6A1.12 1.12 0 1 0 5 4.88 1.12 1.12 0 0 0 3.88 6m7.7 4.45-1.05-1.69-2.06 2.94a.75.75 0 0 1-1.046.176l-.034-.026-1.26-1-1.43 2.9h3.4a.75.75 0 0 1 0 1.5H3.5a.74.74 0 0 1-.67-1.08l2-4.07q.051-.1.12-.19a1.52 1.52 0 0 1 2.136-.243l.004.003.63.51 1.59-2.26a1.52 1.52 0 0 1 2.5.03l1.07 1.71a.753.753 0 0 1-1.28.79z"/></svg>)}
    />
  );
}
  