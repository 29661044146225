// @flow
import { type $AxiosError } from 'axios';
import type {
  DirectoryEntitiesT,
  RetrieveDirectoryCollectionCancelT,
  RetrieveDirectoryCollectionFailureT,
  RetrieveDirectoryCollectionRequestT,
  RetrieveDirectoryCollectionSuccessT
} from './directoryTypes';

export const createRetrieveDirectoryCollectionRequest = (
  enterpriseId: string,
  meta: {} = {}
): RetrieveDirectoryCollectionRequestT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});
export const createRetrieveDirectoryCollectionSuccess = (
  enterpriseId: string,
  directoryEntities: DirectoryEntitiesT,
  meta: {} = {}
): RetrieveDirectoryCollectionSuccessT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId,
    length: directoryEntities.length,
    totalCount: directoryEntities.totalCount,
    offset: directoryEntities.offset
  },
  payload: directoryEntities
  // R.map(R.assoc('enterpriseId', enterpriseId), (directoryEntities.results: any)) // eslint-disable-line flowtype/no-weak-types
});
export const createRetrieveDirectoryCollectionFailure = (
  enterpriseId: string,
  error: $AxiosError<*>,
  meta: {} = {}
): RetrieveDirectoryCollectionFailureT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});
export const createRetrieveDirectoryCollectionCancel = (): RetrieveDirectoryCollectionCancelT => ({
  type: 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_CANCEL',
  error: false,
  meta: {},
  payload: {}
});
