import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvestorsLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.5 9A4.5 4.5 0 1 0 3 4.5 4.51 4.51 0 0 0 7.5 9m0-8a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7m3.24 11.37A6.49 6.49 0 0 0 1 18a.5.5 0 0 1-1 0 7.5 7.5 0 0 1 11.24-6.5.502.502 0 0 1-.5.87M17.49 11A6.5 6.5 0 1 0 24 17.5a6.51 6.51 0 0 0-6.51-6.5m0 12A5.5 5.5 0 1 1 23 17.5a5.51 5.51 0 0 1-5.51 5.5m-2-6h2a.5.5 0 0 1 0 1h-2a2.49 2.49 0 0 0 3.78 1.61.51.51 0 0 1 .7.15.5.5 0 0 1-.159.689l-.001.001A3.48 3.48 0 0 1 14.44 18H14a.5.5 0 0 1 0-1h.48a3.47 3.47 0 0 1 5.31-2.43.5.5 0 0 1 .161.689l-.001.001a.51.51 0 0 1-.7.15 2.49 2.49 0 0 0-3.8 1.59z"/></svg>)}
    />
  );
}
  