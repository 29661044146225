// @flow

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, type ContextRouter } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { goToLogin } from '../../navigationOperations';
import ErrorPageBase from './ErrorPageBase';

type OwnPropsT = {};

type StatePropsT = {};

type DispatchPropsT = {
  goToLogin: () => *
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<ContextRouter>,
  ...$Exact<WithTranslationProps>
|};

const rootPattern = /^\/?$/;

export class ErrorPage extends Component<PropsT> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { location, goToLogin } = this.props; // eslint-disable-line no-shadow

    if (location.pathname.match(rootPattern)) {
      goToLogin();
    }
  }

  render() {
    const { t, goToLogin } = this.props; // eslint-disable-line no-shadow

    return (
      <ErrorPageBase>
        <h1>{t('error.404.title')}</h1>
        <h2>{t('error.404.explanation')}</h2>
        <span
          id="back-to-service-link"
          role="button"
          tabIndex={0}
          onKeyPress={goToLogin}
          onClick={goToLogin}
        >
          {t('error.404.link')}
        </span>
        <p>{t('error.404.error', { code: 404 })}</p>
      </ErrorPageBase>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToLogin
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<$Diff<PropsT, ContextRouter>, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter
)(ErrorPage);
