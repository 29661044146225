// @flow

import reducer from './configReducer';
import operations from './configOperations';
import * as actionCreators from './configActions';
import * as selectors from './configSelectors';
import type { ConfigStateT, UserConfigActionT, LanguagesT, UserConfigT } from './configTypes';

export default reducer.rootReducer;
export { operations, actionCreators, selectors };
export type { ConfigStateT, UserConfigActionT, LanguagesT, UserConfigT };
