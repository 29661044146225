// @flow strict-local

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { CancelToken, CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import type {
  CommandEntityT,
  WelcomeAttendantEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import UnattachedStepRow from './UnattachedStepRow';
import { getUnattachedSteps } from '../../../../callFlowGrid/details/welcomeAttendant/WelcomeAttendantUtils';
import Dialog from '../../../../../../components/Dialog';
import CallflowList from '../../../view/children/CallflowList';
import { updateWelcomeAttendant } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantOperations';
import DeleteButton from '../../../../../../components/Button/DeleteButton';
import { actions as notificationActions } from '../../../../../../ducks/ui/notification';
import type { WelcomeAttendantUpdateEntityT } from '../../../../../../ducks/entities/callFlow/callFlowTypes';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';

import styles from './UnattachedStepsField.module.scss';

export type PropsT = {|
  welcomeAttendant: WelcomeAttendantEntityT
|};

let patchCancelTokenSource: CancelTokenSource;

export const createRemoveUnattachedStepsPayload = (
  welcomeAttendant: WelcomeAttendantEntityT,
  stepsToDelete: CommandEntityT[]
): WelcomeAttendantUpdateEntityT => {
  const stepsToDeleteNames = stepsToDelete.map(s => s.name);
  const commands = R.filter(
    R.where({ name: n => !stepsToDeleteNames.includes(n) }),
    R.values(welcomeAttendant.commands)
  );
  return {
    commands: R.mergeAll(commands.map(r => r && { [r.name]: r }))
  };
};

export const UnattachedStepsField = (props: PropsT) => {
  const { welcomeAttendant } = props;
  const { t } = useTranslation();
  useEffect(() => {
    patchCancelTokenSource = CancelToken.source();
    return () => {
      patchCancelTokenSource.cancel();
    };
  }, []);

  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  // state
  const [stepsToDelete, setStepsToDelete] = useState<CommandEntityT[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const unattachedSteps: CommandEntityT[] = welcomeAttendant
    ? getUnattachedSteps(welcomeAttendant)
    : [];

  const handleCallFlowNotification = success => {
    const notification = {
      tag: success ? 'callflow-edit-success' : 'callflow-edit-fail',
      duration: 15000,
      type: success ? 'info' : 'error',
      message: success
        ? t('callflow.editCallflow.successNotification')
        : t('callflow.editCallflow.failedNotification')
    };

    dispatch(notificationActions.createCreateNotificationAction(notification));
  };

  const removeStep = async e => {
    if (e) {
      e.preventDefault();
    }

    setIsUpdating(true);
    const returnValue = await dispatch(
      updateWelcomeAttendant(
        welcomeAttendant.enterpriseId,
        welcomeAttendant.id,
        createRemoveUnattachedStepsPayload(welcomeAttendant, stepsToDelete),
        patchCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
    handleCallFlowNotification(!!returnValue);
    setIsUpdating(false);
    setStepsToDelete([]);
  };

  return (
    <div className={styles.container}>
      {stepsToDelete.length > 0 && (
        <Dialog
          onCancel={() => setStepsToDelete([])}
          onConfirm={e => removeStep(e)}
          onClose={() => setStepsToDelete([])}
          title={t('callflows.unattachedStepsField.removeTitle')}
          description={
            stepsToDelete.length === 1
              ? t('callflows.unattachedStepsField.removeDescription', {
                  name: stepsToDelete[0].name || ''
                })
              : t('callflows.unattachedStepsField.removeAllDescription')
          }
          confirmLabel={t('callflows.unattachedStepsField.removeConfirm')}
          cancelLabel={t('callflows.unattachedStepsField.removeCancel')}
          loading={isUpdating}
          disabled={false}
        />
      )}
      <CallflowList
        id="callflow-unattached"
        title={t('callflows.viewCallflowContent.unattachedTitle')}
        tooltip={t('callflows.viewCallflowContent.unattachedTooltip')}
        headerStyle={styles.tooltip}
        values={
          unattachedSteps && unattachedSteps.length > 0
            ? unattachedSteps.map(cmd => (
                <UnattachedStepRow
                  name={cmd.name}
                  type={cmd.type}
                  onRemove={() => setStepsToDelete([cmd])}
                />
              ))
            : [t('callflows.viewCallflowContent.unattachedNoResults')]
        }
        className={styles['step-list']}
      />
      {unattachedSteps && unattachedSteps.length > 0 && (
        <DeleteButton
          onClickAction={e => {
            if (e) {
              e.preventDefault();
            }
            setStepsToDelete(unattachedSteps);
          }}
          label={t('callflows.unattachedStepsField.deleteAllButton')}
          id="delete-all-steps-btn"
          className={styles['remove-button']}
        />
      )}
    </div>
  );
};

export default UnattachedStepsField;
