import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLikeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 22.5c-.1 0-.3-.1-.4-.2L1.8 12.2C-.1 10.3-.6 7.4.7 5c.9-1.9 2.6-3.2 4.7-3.4 1.9-.3 4 .3 5.4 1.7L12 4.6l1.2-1.2c1.4-1.4 3.5-2.1 5.4-1.7 1.9.3 3.7 1.6 4.7 3.3 1.2 2.4.7 5.4-1.2 7.3l-9.8 10.1s-.2.1-.3.1m-5.7-20c-.3 0-.6 0-.8.1-1.7.2-3.2 1.2-3.9 2.8-1 2.1-.6 4.5 1 6.1l9.4 9.7 9.4-9.7c1.6-1.6 2-4.1 1-6.1-.8-1.5-2.3-2.6-3.9-2.8-1.6-.3-3.4.3-4.6 1.5l-1.6 1.6c-.2.2-.5.2-.7 0L10 4.1c-.9-1-2.3-1.6-3.7-1.6"/></svg>)}
    />
  );
}
  