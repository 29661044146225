// @flow strict-local
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-named-default
import { CancelToken, type Canceler, type AxiosPromise, default as axios } from 'axios';
import { operations as userOperations } from '../../../ducks/entities/user';
import type { UserEntityT } from '../../../ducks/entities/user/userTypes';

const useForwarding = (enterpriseId: string, addressNumber: string): [?UserEntityT, boolean] => {
  const cancelUserRequest = React.useRef<Canceler>();
  // redux
  const dispatch = useDispatch();
  const [userIsLoading, setUserIsLoading] = useState<boolean>(false);

  const [user, setUser] = useState<?UserEntityT>();

  const fetchUsers = async () => {
    if (enterpriseId) {
      // Why this operation is made? For fetching substitutes, we could define which users to fetch.
      dispatch(
        userOperations.retrieveCompleteCollection(
          { enterpriseId },
          new CancelToken(cancelFn => {
            cancelUserRequest.current = cancelFn;
          })
        )
      );

      if (addressNumber) {
        setUserIsLoading(true);
        const userIdResponse: AxiosPromise<{ extensionId: string }> = await axios({
          method: 'GET',
          url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumber/${addressNumber}`,
          cancelToken: new CancelToken(cancelFn => {
            cancelUserRequest.current = cancelFn;
          })
        });

        const u = await dispatch(
          userOperations.retrieve(
            enterpriseId,
            userIdResponse.data.extensionId,
            null,
            new CancelToken(cancelFn => {
              cancelUserRequest.current = cancelFn;
            }),
            {
              type: 'internalUser'
            }
          )
        );
        setUser(u);
        setUserIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUsers();
    return () => cancelUserRequest.current && cancelUserRequest.current();
  }, [enterpriseId, addressNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  return [user, userIsLoading];
};

export default useForwarding;
