import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoSonyRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.944 2.452c-2.112 0-4.488.352-6.512 1.32C2.496 4.652 1 5.972 1 8.26c0 2.024 1.32 3.168 1.232 3.168.528.44 1.408 1.32 3.784 1.848 1.056.264 3.256.528 5.368.792 2.2.264 4.312.44 5.192.704.704.176 1.848.44 1.848 1.76s-1.232 1.672-1.408 1.76-1.672.792-4.312.792c-1.936 0-4.312-.616-5.104-.88-.968-.352-2.024-.792-2.992-2.024-.264-.264-.616-1.056-.616-1.848H1.528v6.688h2.64v-.88c0-.088.088-.528.616-.352.528.264 2.2.88 3.872 1.232 1.408.264 2.288.528 4.048.528 2.816 0 4.312-.44 5.368-.792.968-.264 2.2-.792 3.168-1.584.528-.44 1.76-1.496 1.76-3.52a4.8 4.8 0 0 0-1.408-3.432 4.7 4.7 0 0 0-1.76-1.144c-.528-.264-1.408-.528-2.112-.704-1.408-.352-4.488-.792-5.984-.88-1.584-.176-4.224-.352-5.28-.704-.264-.352-.968-.616-.968-1.408 0-.528.264-1.056.88-1.408 1.056-.616 2.904-.968 4.928-.968 2.376 0 4.4.528 5.632 1.144.44.176.968.44 1.32.792.44.352 1.056 1.144 1.32 2.2h2.112V3.332h-2.376v.704c0 .176-.264.528-.616.264-.968-.616-4.048-1.848-7.744-1.848"/></svg>)}
    />
  );
}
  