import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCalendarFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 3h-3.5V1a.75.75 0 0 0-1.5 0v5.125a.625.625 0 0 1-1.25 0V3.501A.5.5 0 0 0 14.999 3H7.25V1a.75.75 0 0 0-1.5 0v5.125a.625.625 0 0 1-1.25 0V3.501A.5.5 0 0 0 3.999 3H2.25A2.25 2.25 0 0 0 0 5.25v16.5A2.25 2.25 0 0 0 2.25 24h19.5A2.25 2.25 0 0 0 24 21.75V5.25A2.25 2.25 0 0 0 21.75 3m.75 18.75a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1-.75-.75V10.004a.5.5 0 0 1 .5-.5h20a.5.5 0 0 1 .5.5z"/></svg>)}
    />
  );
}
  