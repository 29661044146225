// @flow strict-local

import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SpeedDiallingIcon } from '../../../../../assets/callflow/details/speeddialling-small.svg';
import { updateSpeedDial } from '../../../../../ducks/entities/speedDial/speedDialOperations';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import type {
  SpeedDialEntityT,
  SpeedDialUpdateEntityT
} from '../../../../../ducks/entities/speedDial/speedDialTypes';
import fieldValidators from '../../../../../fieldValidators';
import SpeedDialForwardingField from '../../../components/edit/children/forwardingField/SpeedDialForwardingField';
import { validateForwardingFieldWithType } from '../../../../../utils/validationUtils';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  externalDestination: { value: string, type: string, text: string },
  internalDestination: { value: string, type: string, text: string }
};

export const EditSpeedDialDetails = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const speedDial = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const initialFormValues: FormT = {
    externalDestination: {
      value:
        speedDial.externalDestination &&
        fieldValidators.phoneNumberValidator(speedDial.externalDestination) === undefined
          ? speedDial.externalDestination
          : '',
      text:
        speedDial.externalDestination &&
        fieldValidators.phoneNumberValidator(speedDial.externalDestination) === undefined
          ? speedDial.externalDestination
          : '',
      type: 'TRANSFER_EXTERNAL'
    },
    internalDestination: {
      value:
        fieldValidators.phoneNumberValidator(speedDial.externalDestination) !== undefined
          ? speedDial.externalDestination
          : '',
      text:
        fieldValidators.phoneNumberValidator(speedDial.externalDestination) !== undefined
          ? speedDial.externalDestination
          : '',
      type: 'TRANSFER_INTERNAL'
    }
  };

  const speedDialSchema = yup.object().shape({
    externalDestination: yup
      .object()
      .test('validateFields', t('callflows.validation.forwardingError'), function() {
        // eslint-disable-next-line react/no-this-in-sfc
        const { externalDestination, internalDestination } = this.parent;
        if (externalDestination.value) {
          return validateForwardingFieldWithType(externalDestination);
        }
        if (internalDestination.value) {
          return validateForwardingFieldWithType(internalDestination);
        }
        return true;
      }),
    internalDestination: yup
      .object()
      .test('validateFields', t('callflows.validation.forwardingError'), function() {
        // eslint-disable-next-line react/no-this-in-sfc
        const { externalDestination, internalDestination } = this.parent;
        if (externalDestination.value) {
          return validateForwardingFieldWithType(externalDestination);
        }
        if (internalDestination.value) {
          return validateForwardingFieldWithType(internalDestination);
        }
        return true;
      })
  });

  // update
  const onSubmit = async (formData: FormT): Promise<SpeedDialEntityT> => {
    const patchPayload: SpeedDialUpdateEntityT = {
      externalDestination: formData.externalDestination.value || formData.internalDestination.value
    };

    return dispatch(
      updateSpeedDial(
        speedDial.enterpriseId,
        speedDial.id,
        patchPayload,
        CancelToken.source().token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={speedDial.id}
      icon={<SpeedDiallingIcon />}
      title={t('callflows.editSpeedDialDetails.title')}
      description={t('callflows.editSpeedDialDetails.description')}
      defaultValues={initialFormValues}
      validationSchema={speedDialSchema}
      onSaveForm={onSubmit}
    >
      <SpeedDialForwardingField
        externalField="externalDestination"
        internalField="internalDestination"
        title={t('callflows.editSpeedDialDetails.targetNumber')}
        filterSearchResults={entry => (entry ? entry.value !== speedDial.addressNumber : false)}
      />
    </EditCallflowDetails>
  );
};

export default EditSpeedDialDetails;
