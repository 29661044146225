// @flow
import React, { type Element } from 'react';
import Button from '@design-system/component-library/src/components/Button';
import classnames from 'classnames';
import styles from './DeleteButton.module.scss';

export type PropsT = {
  id: string,
  className?: string,
  disabled?: boolean,
  label: string,
  onClickAction: (SyntheticEvent<HTMLButtonElement>) => void
};

const DeleteButton = (props: PropsT): Element<'Button'> => {
  const { id, className, disabled, label, onClickAction, ...rest } = props;
  return (
    <Button
      id={id}
      type="button"
      value="Confirm"
      data-cy="delete-button"
      className={classnames(styles['delete-button'], className)}
      color="light"
      onClick={onClickAction}
      disabled={disabled}
      size="l"
      // $FlowFixMe
      {...rest}
    >
      {label}
    </Button>
  );
};

DeleteButton.defaultProps = {
  className: '',
  disabled: false
};

export default DeleteButton;
