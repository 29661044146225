// @flow
import * as R from 'ramda';
import React, { type Element } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { ReactComponent as CalendarIcon } from '../../../../../assets/callflow/details/calendar-small.svg';
import CalendarConverterUtils from '../../../components/edit/children/calendar/CalendarConverterUtils';
import type {
  BaseCalendarEntityT,
  WeeklySlotT
} from '../../../../../ducks/entities/calendar/calendarTypes';
import ExtensionGroupCalendarConverter from './forwardingConvertUtils';
import DestinationField from '../../../components/edit/children/destinationField/DestinationField';
import { convertForwardingToDestinationField } from '../../../components/edit/children/destinationField/DestinationFieldUtils';
import type { DestinationFieldT } from '../../../components/edit/children/destinationField/DestinationFieldUtils';
import {
  getActiveForwardingsByType,
  getForwardingTargetName
} from './extensionGroupForwardingUtils';
import { operations as callForwardingOps } from '../../../../../ducks/entities/callForwarding';
import type { CreateCallForwardingPayloadT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import { createCallForwarding } from '../../../../../ducks/entities/callForwarding/callForwardingOperations';
import { retrieve } from '../../../../../ducks/entities/callFlow/callFlowOperations';
import { useCancelToken } from '../../../../../utils/useCancelToken';
import CalendarWithRadio from './CalendarWithRadio';
import type { CalendarTypeT } from './forwardingConvertUtils';
import { validateForwardingField } from '../../../../../utils/validationUtils';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';

import styles from '../acd/EditUnhandled.module.scss';

export type PropsT = {|
  callflowId: string
|};

type FormT = {
  calendarType: CalendarTypeT,
  forwardingTarget: DestinationFieldT,
  weekSlots: WeeklySlotT[]
};

export const EditExtensionGroupCalendar = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const enterpriseId = R.path(['currentEnterprise', 'id'], currentUser) || '';

  const extensionGroupSchema = yup.object().shape({
    forwardingTarget: validateForwardingField(t('callflows.validation.forwardingError'))
  });

  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const calendar: BaseCalendarEntityT = ExtensionGroupCalendarConverter.convertForwardingsToCalendar(
    extGroupData.forwardings
  );
  const weekSlots: WeeklySlotT[] = CalendarConverterUtils.convertToWeekScheduleFormField(calendar);
  const { createNewCancelToken } = useCancelToken();

  const activeAlwaysOnForwardings = getActiveForwardingsByType(extGroupData.forwardings, 'ALWAYS');
  const fwdTarget =
    activeAlwaysOnForwardings && activeAlwaysOnForwardings[0]
      ? activeAlwaysOnForwardings[0].destination
      : null;

  const fwdTargetName = getForwardingTargetName(fwdTarget, extGroupData);
  const initialFormValues: FormT = {
    calendarType: ExtensionGroupCalendarConverter.isAlwaysOpenCalendar(calendar.weekSchedule)
      ? 'always'
      : 'byCalendar',
    forwardingTarget: convertForwardingToDestinationField(fwdTarget, fwdTargetName),
    weekSlots
  };

  const deleteActiveTypeAlwaysForwardings = async () => {
    const fwdIdsToBeRemoved = getActiveForwardingsByType(extGroupData.forwardings, 'ALWAYS').map(
      fwd => fwd.id
    );
    const promises = fwdIdsToBeRemoved.map(forwardingId =>
      dispatch(
        callForwardingOps.deleteCallForwarding(
          enterpriseId,
          forwardingId,
          createCsrfHeader(currentUser)
        )
      )
    );
    await Promise.all(promises);
  };

  const saveForwardings = async newForwardings => {
    // eslint-disable-next-line no-restricted-syntax
    for (const forwarding of newForwardings) {
      // eslint-disable-next-line no-await-in-loop
      const returnValue = await dispatch(
        createCallForwarding(
          enterpriseId,
          forwarding,
          null,
          extGroupData.id,
          createCsrfHeader(currentUser)
        )
      );
      if (returnValue.error) {
        return returnValue.error;
      }
    }
    return null;
  };

  const onSaveForm = async (formData: FormT): Promise<*> => {
    await deleteActiveTypeAlwaysForwardings();

    const newForwardings: CreateCallForwardingPayloadT[] = ExtensionGroupCalendarConverter.convertCalendarSlotsToForwardingRules(
      formData.calendarType,
      formData.forwardingTarget,
      formData.weekSlots
    );
    let createForwardingError;
    if (!R.isEmpty(newForwardings)) {
      createForwardingError = await saveForwardings(newForwardings);
    }
    const getResponse = await dispatch(
      retrieve(enterpriseId, extGroupData.id, 'EXTENSION_GROUP', createNewCancelToken(), false)
    );
    return R.isEmpty(newForwardings) ? getResponse : !createForwardingError;
  };

  return (
    <EditCallflowDetails
      nodeId={extGroupData.id}
      icon={<CalendarIcon />}
      title={t('callflows.editExtensionGroupCalendar.title')}
      description={t('callflows.editExtensionGroupCalendar.description')}
      defaultValues={initialFormValues}
      validationSchema={extensionGroupSchema}
      onSaveForm={onSaveForm}
    >
      <CalendarWithRadio typeField="calendarType" calendarField="weekSlots" />

      <DestinationField
        currentAddressNumber={extGroupData.addressNumber}
        field="forwardingTarget"
        title={t('callflows.editExtensionGroupCalendar.forwardingTarget')}
        containerStyle={styles['forwarding-container']}
        hiddenFn={(formData: FormT) => formData.calendarType === 'always'}
      />
    </EditCallflowDetails>
  );
};

export default EditExtensionGroupCalendar;
