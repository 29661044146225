import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPrintLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.5 19.999a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1Zm0-3a.5.5 0 1 1 0-1h9a.5.5 0 0 1 0 1ZM6.5 24A1.5 1.5 0 0 1 5 22.5V17H2.5A2.51 2.51 0 0 1 0 14.501V8.5A2.51 2.51 0 0 1 2.499 6H21.5A2.51 2.51 0 0 1 24 8.499V14.5a2.51 2.51 0 0 1-2.499 2.5H19v5.5a1.5 1.5 0 0 1-1.5 1.5ZM6 22.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V14H6ZM21.5 16a1.51 1.51 0 0 0 1.5-1.501V8.5A1.51 1.51 0 0 0 21.499 7H2.5A1.51 1.51 0 0 0 1 8.502V14.5A1.51 1.51 0 0 0 2.501 16H5v-2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5V16ZM18.479 4.999H14.5a.5.5 0 0 1-.5-.5V1H6v3.5a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5h8.979l.021-.001.021.001h.564a1.5 1.5 0 0 1 1.061.439l2.414 2.414A1.5 1.5 0 0 1 19 3.914V4.5a.5.5 0 0 1-.5.5Zm-.479-1v-.085a.5.5 0 0 0-.146-.354L15.44 1.146A.5.5 0 0 0 15.086 1H15v2.999Zm-14.5 7a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5m0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5"/></svg>)}
    />
  );
}
  