import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTwitterFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.976 10.162 22.72 0h-2.072l-7.591 8.824L6.993 0H0l9.168 13.343L0 24h2.072l8.016-9.318L16.491 24h6.993zm-2.838 3.299-.928-1.329L2.818 1.56H6l5.965 8.532.93 1.329 7.753 11.09h-3.182z"/></svg>)}
    />
  );
}
  