// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AgentsIcon } from '../../../../../assets/callflow/details/agents-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../components/view/children/CallflowItem';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import MemberList from '../../../components/view/children/MemberList/MemberList';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import { extensionGroupSchema } from './EditExtensionGroup';

export type PropsT = {|
  callflowId: string
|};

export const ExtensionGroupDetails = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );

  if (extGroupData) {
    return (
      <CallflowDetails
        enterpriseId={extGroupData.enterpriseId}
        callflowId={extGroupData.id}
        elementType={extGroupData.type}
        nodeId={`group_members_${extGroupData.id}`}
        nodeType="EXTENSION_GROUP_MEMBERS"
        icon={<AgentsIcon />}
        title={t('callflows.viewExtensionGroup.title')}
        active
      >
        <CallflowDetailsColumn id="ext-group-column-1">
          <CallflowItem
            id="ringPattern"
            title={t('callflows.viewExtensionGroup.ringPattern')}
            value={t(`callflows.ringPattern.${extGroupData.ringPattern}`)}
          />
          <MemberList
            title={t('callflows.viewExtensionGroup.groupMembers', {
              numberOfPeople: extGroupData.members ? extGroupData.members.length : ''
            })}
            members={extGroupData.members || []}
            enterpriseId={extGroupData.enterpriseId}
            serviceType="group"
            id="groupMembers"
            callFlowId={extGroupData.id}
          />
        </CallflowDetailsColumn>
        <CallflowDetailsColumn id="ext-group-column-2">
          <CallflowItem
            id="maxCycles"
            title={t('callflows.viewExtensionGroup.maxCycles')}
            value={t('callflows.viewExtensionGroup.maxCyclesValue', {
              value: extGroupData.maxCycles
            })}
            tooltip={t(
              'callflows.viewExtensionGroup.maxCyclesTooltip',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(extensionGroupSchema.fields.maxCycles)
            )}
          />
          <CallflowItem
            id="cyclicRingingTime"
            title={t('callflows.viewExtensionGroup.cyclicRingingTime')}
            value={t('callflows.viewExtensionGroup.cyclicRingingTimeValue', {
              value: extGroupData.cyclicRingingTime / 1000
            })}
            tooltip={t(
              'callflows.viewExtensionGroup.cyclicRingingTimeTooltip',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(extensionGroupSchema.fields.cyclicRingingTime)
            )}
          />
        </CallflowDetailsColumn>
      </CallflowDetails>
    );
  }
  return null;
};

export default ExtensionGroupDetails;
