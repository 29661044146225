import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileXlsLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.6 5.9 15.2.5c-.3-.3-.7-.5-1.1-.5H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.9c0-.4-.2-.8-.4-1m-1.3.1h-3.8c-.3 0-.5-.2-.5-.5V1.7zM19 23H5c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h9v4.5c0 .8.7 1.5 1.5 1.5H20v15c0 .6-.5 1-1 1m-9.5-3.1h1.1l-1.5-2.3 1.4-2.3h-1l-1 1.6-1-1.6h-1l1.4 2.2-1.5 2.3h1l1.1-1.7zm1.7-4.5v4.5H14v-.8h-1.8v-3.7zm5.8 4.2c.3-.2.4-.6.4-1 0-.3-.1-.5-.2-.7-.2-.2-.4-.4-.9-.6q-.45-.3-.6-.3l-.2-.2c0-.1-.1-.1-.1-.2s0-.2.1-.3.2-.1.4-.1.3 0 .5.1c.2 0 .4.1.6.2l.3-.7c-.2-.1-.5-.2-.7-.2-.2-.1-.4-.1-.7-.1-.5 0-.9.1-1.1.3-.3.2-.4.5-.4.9 0 .2 0 .4.1.6s.2.3.3.4.3.2.6.4q.45.15.6.3c.1.1.2.1.2.2s.1.1.1.2c0 .2-.1.3-.2.4s-.3.1-.5.1-.4 0-.6-.1-.5-.2-.8-.3v.9c.4.2.8.3 1.3.3.8-.1 1.2-.3 1.5-.5"/></svg>)}
    />
  );
}
  