import React from 'react';
import PropTypes from 'prop-types';
import styles from './Links.module.scss';

function Link({ page = null, handleClick = () => {} }) {
  if (!page || !page.url || !page.title) return null;
  return (
    <li key={page.title}>
      <a href={page.url} className={styles.footer__link} target={page.target} onClick={handleClick}>
        {page.title}
      </a>
    </li>
  );
}

Link.propTypes = {
  handleClick: PropTypes.func,
  page: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
  }),
};

export default Link;
