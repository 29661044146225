// @flow strict-local
import React, { type Element } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../callFlows/components/edit/children/InputField';
import ToggleField from '../../callFlows/components/edit/children/ToggleField';

import styles from './UserForwardingsSearch.module.scss';

export type PropsT = {|
  fieldName: string,
  numberOfEnterpriseForwardings: number,
  singleUserAddressNumber?: string
|};

export const UserForwardingsSearch = (props: PropsT): Element<'div'> => {
  const { fieldName, numberOfEnterpriseForwardings, singleUserAddressNumber } = props;
  const { t } = useTranslation();

  const location = useLocation();
  const searchTerm = location.state ? location.state.addressNumber : '';

  const history = useHistory();

  const onChange = () => {
    if (searchTerm) {
      history.replace({});
    }
  };

  return (
    <div className={styles['search-container']}>
      {!singleUserAddressNumber && (
        <InputField
          type="search"
          field={fieldName}
          placeholder={t('forwardings.userForwardingsSearch.placeholder')}
          onChange={() => onChange()}
        />
      )}
      {numberOfEnterpriseForwardings > 0 && (
        <ToggleField
          field="showEnterpriseForwardings"
          label={t('forwardings.userForwardingsSearch.toggle', {
            forwardings: numberOfEnterpriseForwardings
          })}
          elementId="showEnterpriseForwardings"
        />
      )}

      <ToggleField
        field="showExternalServiceFwds"
        elementId="showExternalServiceFwds"
        label={t('forwardings.userForwardingsSearch.showExternalServiceFwds')}
        tooltip={t('forwardings.userForwardingsSearch.showExternalServiceFwdsTooltip')}
      />
    </div>
  );
};

export default UserForwardingsSearch;
