// @flow
import React, { type Element } from 'react';
import { useTranslation, WithTranslationProps } from 'react-i18next';
import styles from './LanguageSelector.module.scss';

type OwnPropsT = {
  language?: string
};

type StatePropsT = {};

type DispatchPropsT = {};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export const LanguageSelector = (props: $Shape<PropsT>): Element<'div'> => {
  const { i18n } = useTranslation();

  const supportedLanguages = ['en', 'fi', 'sv', 'et'];
  const currentLanguage = props.language || i18n.language;

  return (
    <div>
      {Object.keys(i18n.services.resourceStore.data)
        .filter(lang => supportedLanguages.includes(lang))
        .map((language: string, index: number): Element<'div'> => (
          <div
            className={styles['language-button-container']}
            key={`language-button-container-${language}`}
          >
            <button
              tabIndex={supportedLanguages.length - index}
              className={styles['language-button']}
              onClick={() => {
                i18n.changeLanguage(language);
              }}
              id={`language-button-${language}`}
              lang={language}
              aria-current={currentLanguage && currentLanguage === language}
            >
              <span
                className={
                  currentLanguage && currentLanguage === language ? styles['active-language'] : ''
                }
              >
                {language}
              </span>
            </button>
            {index > 0 && <span>/</span>}
          </div>
        ))}
    </div>
  );
};

export default LanguageSelector;
