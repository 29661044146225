import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTvFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.779 1.75H2.221C.996 1.75 0 2.759 0 4v12c0 1.24.996 2.25 2.221 2.25h9.055v2.5H7.5a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-3.725v-2.5h9.004C23.004 18.25 24 17.24 24 16V4c0-1.241-.996-2.25-2.221-2.25M22.5 16c0 .413-.323.75-.721.75H2.221c-.398 0-.721-.337-.721-.75V4c0-.414.323-.75.721-.75H21.78c.397 0 .72.336.72.75zM21.25 4.75v10.5c0 .138-.109.25-.243.25H2.993a.247.247 0 0 1-.243-.25V4.75c0-.138.109-.25.243-.25h18.013c.135 0 .244.112.244.25"/></svg>)}
    />
  );
}
  