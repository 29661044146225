import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAuthorizationFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.253 4.75c0 2.481-2.019 4.5-4.501 4.5s-4.501-2.019-4.501-4.5S5.271.25 7.752.25s4.501 2.019 4.501 4.5m1.151 8.579a7.5 7.5 0 0 0-5.652-2.578c-4.137 0-7.502 3.364-7.502 7.5 0 .276.256.5.533.5h8.365a.5.5 0 0 0 .489-.396q.053-.248.125-.494a5.88 5.88 0 0 1 3.451-3.74.5.5 0 0 0 .191-.792m7.375-1.573-3.541 3.326a.5.5 0 0 1-.494.109c-1.027-.307-2.185-.299-3.468.464-1.21.72-2.1 1.953-2.249 3.354a4.503 4.503 0 0 0 4.181 4.984c1.674.108 3.184-.871 4.092-2.281.807-1.253.813-2.338.547-3.317a.5.5 0 0 1 .124-.487l3.461-3.395a1.91 1.91 0 0 0 .003-2.715 1.91 1.91 0 0 0-2.656-.042m-5.275 9.245A1.5 1.5 0 1 1 15.505 18a1.5 1.5 0 0 1-.001 3z"/></svg>)}
    />
  );
}
  