// @flow strict-local

import React, { type Element } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { cutBySequence, cutByWordBoundary } from '../../utils/textUtils';
import HighlightText from '../users/SearchMatchRow/HighlightText';
import styles from './SearchMatchRow.module.scss';

type PropsT = {|
  rowId: string,
  matchings: { [string]: string | string[] },
  searchTerm: string,
  visibleColumns: string[]
|};

const MAX_MATCH_LENGTH = 40;

export const SearchMatchRow = (props: PropsT): Element<'div'> | null => {
  const { rowId, matchings, searchTerm, visibleColumns } = props;
  const { t } = useTranslation();

  const translateKey = key => {
    return t(`searchMatchRow.${key}`, null, { onMissingTranslation: () => key });
  };

  const formatValue = rawValue => {
    // $FlowFixMe otherwise rawValue should be string
    const value: string = Array.isArray(rawValue) ? rawValue.join(',') : rawValue;
    if (value == null) {
      return '';
    }

    if (value && value.length <= MAX_MATCH_LENGTH) {
      return value;
    }

    const wordResult = cutByWordBoundary(value, searchTerm);
    if (wordResult && wordResult.length <= MAX_MATCH_LENGTH) {
      return `... ${wordResult} ...`;
    }

    const sequenceResult = cutBySequence(value, searchTerm, 10, 10);
    if (sequenceResult && sequenceResult.length <= MAX_MATCH_LENGTH) {
      return `... ${sequenceResult} ...`;
    }
    return value;
  };

  const nonVisibleColumnMatches = Object.entries(matchings || {}).filter(
    ([key]) => !visibleColumns.includes(key)
  );

  return searchTerm ? (
    <div id={`${rowId}-matches`} className={styles.container}>
      {R.map(([key, value]) => {
        let visualValue = formatValue(value);
        if (key === 'department') {
          // $FlowFixMe value should always be a string
          const departmentContainsSearchTerm = (value || '')
            .toLowerCase()
            .includes((searchTerm || '').toLowerCase());
          if (!departmentContainsSearchTerm) {
            visualValue = `.../${visualValue}`;
          }
        }
        return (
          <span className={styles.cell} key={`${rowId}-search-column-${key}`}>
            {translateKey(key)}:{' '}
            {value !== undefined && searchTerm && (
              <HighlightText text={visualValue} highlight={searchTerm} />
            )}
          </span>
        );
      }, nonVisibleColumnMatches)}
    </div>
  ) : null;
};

export default SearchMatchRow;
