// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../components/Dialog';
import { getTextRepresentation } from '../../../helpers';
import { operations as callForwardingOps } from '../../../ducks/entities/callForwarding';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import type { CallForwardingT } from '../../../ducks/entities/callForwarding/callForwardingTypes';
import type { DirectoryEntityT } from '../../../ducks/entities/directory';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';

import styles from './DeleteForwardingDialog.module.scss';

export type ActiveForwardingT = { forwarding: ?CallForwardingT, user: ?DirectoryEntityT };

type PropsT = {
  enterpriseId: string,
  forwardingType: 'user' | 'enterprise',
  activeForwarding: ?ActiveForwardingT,
  onClose: (reload: ?boolean) => Promise<void> | void
};

const DeleteForwardingDialog = (props: PropsT) => {
  const { enterpriseId, forwardingType, activeForwarding, onClose } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [hasDeleteError, setHasDeleteError] = useState<boolean>(false);

  const getSelectedUserFullName = (fwd): string => {
    return R.path(['user'], fwd)
      ? [
          R.path(['user', 'publicInfo', 'firstName'], fwd),
          R.path(['user', 'publicInfo', 'lastName'], fwd)
        ]
          .join(' ')
          .trim()
      : t('callflows.valueNotSet');
  };

  const generateEnterpriseCallForwardingDescription = (fwd: ?ActiveForwardingT): string => {
    if (forwardingType === 'enterprise' && fwd && fwd.forwarding) {
      const textRepresentation = getTextRepresentation(
        // $FlowFixMe Requires complex callforwarding type $Diff<CallForwardingT, { id: string }>
        fwd.forwarding,
        'long',
        t
      ).toString();
      return t('forwardings.deleteDialog.enterpriseCallForwardingDescription', {
        description: textRepresentation
      });
    }
    return '';
  };

  const removeCallForwarding = async () => {
    const user = R.path(['user'], activeForwarding);
    setIsDeleting(true);
    const forwardingId = R.path(['forwarding', 'id'], activeForwarding);
    if (forwardingId) {
      const success = await dispatch(
        callForwardingOps.deleteCallForwarding(
          enterpriseId,
          forwardingId,
          createCsrfHeader(currentUser)
        )
      );

      if (success) {
        setIsDeleting(false);
        setHasDeleteError(false);
        const fullName = getSelectedUserFullName(activeForwarding);
        dispatch(
          notificationActions.createNotification(
            'callforwarding-deleted',
            user
              ? t('forwardings.deleteNotification.successNotification', { fullName })
              : t('forwardings.enterpriseDeleteNotification.successNotification'),
            '',
            false
          )
        );
        onClose(true);
      } else {
        setHasDeleteError(true);
        setIsDeleting(false);
      }
    }
  };

  const title =
    forwardingType === 'user'
      ? t('forwardings.deleteDialog.title')
      : t('forwardings.deleteDialog.enterpriseTitle');

  const description =
    forwardingType === 'user'
      ? t('forwardings.deleteDialog.description', {
          fullName: getSelectedUserFullName(activeForwarding)
        })
      : t('forwardings.deleteDialog.enterpriseDescription');

  return (
    <Dialog
      title={title}
      description={description}
      descriptionClassName={styles['fwd-description']}
      descriptionExtended={generateEnterpriseCallForwardingDescription(activeForwarding)}
      descriptionExtendedClassName={styles['fwd-description-extended']}
      confirmLabel={t('forwardings.deleteDialog.confirm')}
      cancelLabel={t('forwardings.deleteDialog.cancel')}
      disabled={false}
      loading={isDeleting}
      onCancel={onClose}
      onConfirm={removeCallForwarding}
      onClose={onClose}
      errorMessage={hasDeleteError ? t('forwardings.deleteDialog.error') : undefined}
    />
  );
};

export default DeleteForwardingDialog;
