// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as R from 'ramda';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VoiceMailExtraIcon } from '../../../../../assets/callflow/details/callflow_voicemail_extra_open.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { createIvrPatchPayload } from '../../../components/edit/CallflowPropertyUtils';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import { InputField } from '../../../components/edit/children/InputField';
import { PasswordField } from '../../../components/edit/children/PasswordField';
import RadioButton from '../../../components/edit/children/RadioButton';
import fieldValidators from '../../../../../fieldValidators';
import Tooltip from '../../../../../components/Tooltip';
import ToggleField from '../../../components/edit/children/ToggleField';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import { removeOverflowGroupIfNeeded } from './EditAcdAgent';
import styles from './EditVoiceMailExtra.module.scss';

export type PropsT = {|
  callflowId: string
|};

export type VoiceMailTargetT = 'LOGGED_AGENTS' | 'ALL_AGENTS' | 'NO_AGENTS';

type FormT = {
  starToVoiceMail: boolean,
  starToVoiceMailMessage: AudioFieldT<PropertyAudioT>,
  sendVoiceMailToAgents: VoiceMailTargetT,
  voiceMailSpecialEmails?: string,
  emails?: string,
  bypassDisplayPoliciesForVM: boolean,
  groupPassword: ?string
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

export const getSendVoiceMailToAgentsValue = (
  blockVoiceMailToAgents: boolean,
  blockVoiceMailToUnloggedAgents: boolean
): VoiceMailTargetT => {
  if (!blockVoiceMailToAgents) {
    if (blockVoiceMailToUnloggedAgents) {
      return 'LOGGED_AGENTS';
    }
    return 'ALL_AGENTS';
  }
  return 'NO_AGENTS';
};

export const EditVoiceMailExtra = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const acdAudioConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');

  const [passwordFieldEnabled, setPasswordFieldEnabled] = useState(false);

  let initialProperties = {
    starToVoiceMail: acdCallCenter.starToVoiceMail,
    starToVoiceMailMessage: acdAudioConverter.convertToAudioPropertyField(
      'StarToVoiceMailMessage',
      '',
      ivrAcdCallCenter.starToVoiceMailMessage
    ),
    sendVoiceMailToAgents: getSendVoiceMailToAgentsValue(
      R.path(['blockVoiceMailToAgents'], acdCallCenter),
      R.path(['blockVoiceMailToUnloggedAgents'], acdCallCenter)
    ),
    bypassDisplayPoliciesForVM: R.path(['bypassDisplayPoliciesForVM'], acdCallCenter),
    groupPassword: null
  };
  if (!(currentUser.featureFlags || []).includes('FEATURE-HIDE-VOICEMAIL-SPECIAL-EMAILS')) {
    initialProperties = {
      ...initialProperties,
      voiceMailSpecialEmails: R.path(['voiceMailSpecialEmails'], acdCallCenter) || ''
    };
  } else {
    initialProperties = {
      ...initialProperties,
      emails: acdData.emails || ''
    };
  }
  const acdVoiceMailExtraSchema = yup.object().shape({
    sendVoiceMailToAgents: yup.string().required(),
    voiceMailSpecialEmails: yup.string().test({
      name: 'value',
      message: t('callflows.validation.emailError'),
      test: value => fieldValidators.commaSeparatedUniqueEmailsValidator(value) === undefined
    }),
    emails: yup.string().test({
      name: 'value',
      message: t('callflows.validation.emailError'),
      test: value => fieldValidators.semicolonSeparatedUniqueEmailsValidator(value) === undefined
    }),
    groupPassword: yup
      .string()
      .test({
        name: 'value',
        message: t('callflows.validation.passwordError'),
        test: value =>
          value ? fieldValidators.userPasswordComplexityValidator(value) === undefined : true
      })
      .nullable()
  });

  const initialFormValues: FormT = {
    ...initialProperties
  };

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []);

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const ignoredProperties = ['sendVoiceMailToAgents', 'emails'];
    const customProperties = [
      {
        blockVoiceMailToAgents: {
          level: 'Extension',
          value: formData.sendVoiceMailToAgents === 'NO_AGENTS'
        }
      },
      {
        blockVoiceMailToUnloggedAgents: {
          level: 'Extension',
          value: formData.sendVoiceMailToAgents === 'LOGGED_AGENTS'
        }
      }
    ];

    const payload = {
      // $FlowFixMe
      ...(passwordFieldEnabled
        ? {
            groupPassword: formData.groupPassword
          }
        : {}),
      ...((currentUser.featureFlags || []).includes('FEATURE-HIDE-VOICEMAIL-SPECIAL-EMAILS')
        ? {
            emails: formData.emails
          }
        : {}),
      ...removeOverflowGroupIfNeeded(acdData.ringPattern, acdData.overflowGroup)
    };

    const patchPayload = createIvrPatchPayload(
      payload,
      formData,
      Object.keys(initialProperties).filter(prop => !ignoredProperties.includes(prop)),
      customProperties
    );

    // $FlowFixMe Improve formData typing
    const failedAudioUploads = await uploadAudios(
      formData,
      audioRequestCancelTokenSource,
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }
    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  const sendVoiceMailTargetOptions = [
    {
      label: t('callflows.voiceMailTarget.voiceMailTargetNoAgents'),
      value: 'NO_AGENTS'
    },
    {
      label: t('callflows.voiceMailTarget.voiceMailTargetLoggedAgents'),
      value: 'LOGGED_AGENTS'
    },
    {
      label: t('callflows.voiceMailTarget.voiceMailTargetAllAgents'),
      value: 'ALL_AGENTS'
    }
  ];

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<VoiceMailExtraIcon />}
      title={t('callflows.editVoiceMailExtra.title')}
      description={t('callflows.editVoiceMailExtra.description')}
      defaultValues={initialFormValues}
      validationSchema={acdVoiceMailExtraSchema}
      onSaveForm={onSubmit}
    >
      <ToggleField
        field="starToVoiceMail"
        elementId="starToVoiceMail"
        label={t('callflows.viewVoiceMailExtra.starToVoiceMail')}
      />
      <AcdAudioField
        field="starToVoiceMailMessage"
        title={t('callflows.viewVoiceMailExtra.starToVoiceMailMsg')}
        disabledFn={(formData: FormT) => formData.starToVoiceMail !== true}
      />
      <RadioButton
        field="sendVoiceMailToAgents"
        title={t('callflows.viewVoiceMailExtra.sendVoiceMailToAgents')}
        options={sendVoiceMailTargetOptions}
        tooltip={
          <Tooltip>{t('callflows.viewVoiceMailExtra.sendVoiceMailToAgentsTooltip')}</Tooltip>
        }
      />
      <InputField
        containerStyle={styles['input-container']}
        field={
          !(currentUser.featureFlags || []).includes('FEATURE-HIDE-VOICEMAIL-SPECIAL-EMAILS')
            ? 'voiceMailSpecialEmails'
            : 'emails'
        }
        title={t('callflows.viewVoiceMailExtra.voiceMailSpecialEmails')}
        shouldValidate
      />
      <ToggleField
        field="bypassDisplayPoliciesForVM"
        elementId="bypassDisplayPoliciesForVM"
        label={t('callflows.viewVoiceMailExtra.bypassDisplayPoliciesForVM')}
        tooltip={t('callflows.viewVoiceMailExtra.bypassDisplayPoliciesForVMTooltip')}
      />
      {!(currentUser.featureFlags || []).includes('FEATURE-HIDE-GROUP-PASSWORD') ? (
        <PasswordField
          title={t('callflows.viewVoiceMailExtra.groupPassword')}
          field="groupPassword"
          shouldValidate
          fieldEnabled={passwordFieldEnabled}
          setFieldEnabled={() => setPasswordFieldEnabled(!passwordFieldEnabled)}
        />
      ) : null}
    </EditCallflowDetails>
  );
};

export default EditVoiceMailExtra;
