import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHotRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.749 19.75a.75.75 0 0 1-.031-1.499c2.023-.088 3.607-1.87 3.53-3.974a.75.75 0 0 1 .722-.776c.382-.035.762.307.776.722.108 2.925-2.119 5.403-4.964 5.526zm9.001-6.274C21.75 7.28 16.179.735 10.284.009a1.12 1.12 0 0 0-.89.282 1.13 1.13 0 0 0-.083 1.587c5.23 5.84 2.632 9.396 1.278 10.656a4.08 4.08 0 0 1-1.388-3.646 1.12 1.12 0 0 0-.681-1.15 1.1 1.1 0 0 0-.846-.012 8.44 8.44 0 0 0-5.423 8.006c0 4.404 4.556 8.268 9.75 8.268 6.698 0 9.749-5.455 9.749-10.524m-12.133.201c.577.486 1.43.467 1.985-.044 1.263-1.169 4.823-5.382-.479-11.94 4.748 1.224 9.127 6.729 9.127 11.783 0 4.492-2.551 9.024-8.249 9.024-4.395 0-8.25-3.163-8.25-6.781a6.95 6.95 0 0 1 3.914-6.356 5.57 5.57 0 0 0 1.952 4.314m-1.408-4.55.015-.005z"/></svg>)}
    />
  );
}
  