// @flow strict-local
import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import IconPinRegular from '@design-system/component-library/src/components/Icon/lib/IconPinRegular';
import IconEmailReadRegular from '@design-system/component-library/src/components/Icon/lib/IconEmailReadRegular';
import IconInvoiceRegular from '@design-system/component-library/src/components/Icon/lib/IconInvoiceRegular';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { ExactPropsT } from '../../../../commonTypes';
import type { AddressT, LocationEntityT } from '../../../../ducks/entities/location/locationTypes';
import ConfirmButton from '../../../../components/Button/ConfirmButton';
import styles from './ViewSite.module.scss';

export type OwnPropsT = {
  showModify?: boolean,
  location: LocationEntityT,
  onClose: () => void,
  startEditing: () => void
};

type StatePropsT = {};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

export class ViewSite extends Component<PropsT> {
  renderAddress(address: ?AddressT): ?Element<'div'> {
    const { t } = this.props;
    if (!address) {
      return <div />;
    }
    let icon = <IconPinRegular />;
    let topic = <div />;
    switch (address.type) {
      case 'visiting':
        topic = t('siteDetails.visitingAddress');
        break;
      case 'billing':
        icon = <IconInvoiceRegular />;
        topic = t('siteDetails.billingAddress');
        break;
      case 'postal':
        icon = <IconEmailReadRegular />;
        topic = t('siteDetails.postalAddress');
        break;
      default:
        break;
    }

    return (
      <div className={styles.address}>
        {icon}
        <h4>{topic}</h4>
        <div className={styles.description}>{address.address1 || ''}</div>
        {address.address2 && <div className={styles.description}>{address.address2 || ''}</div>}
        <div className={styles.description}>
          {address.zip} {address.city}
        </div>
        <div className={styles.description}>{address.district}</div>
        <div className={styles.description}>{address.country}</div>
      </div>
    );
  }

  render() {
    const { t, location, startEditing, showModify } = this.props;
    return (
      <div>
        <div className={styles.header}>
          <h4>{t('siteDetails.siteAddresses')}</h4>
        </div>
        <div className={styles['address-row']}>
          {this.renderAddress(location.addresses.filter(addr => addr.type === 'visiting')[0])}
          {this.renderAddress(location.addresses.filter(addr => addr.type === 'billing')[0])}
          {this.renderAddress(location.addresses.filter(addr => addr.type === 'postal')[0])}
        </div>
        {showModify && (
          <div className={styles['button-row']}>
            <ConfirmButton
              id="sites-modify-button"
              className={styles['modify-button']}
              label={t('siteEdit.editButton')}
              onClickAction={() => startEditing()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose(withTranslation(), connect<PropsT, OwnPropsT, _, _, _, _>())(ViewSite);
