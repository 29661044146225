// @flow strict
import React, { type Element } from 'react';
import { Button } from '@design-system/component-library';
import styles from './SecondaryButton.module.scss'; // flowlint-line nonstrict-import: off
import { LaptopAndUp } from '../../Responsive'; // flowlint-line nonstrict-import: off

export type PropsT = {
  id: string,
  disabled?: boolean,
  label: Element<'div'> | string,
  onClickAction: Event => void,
  leading?: Element<'div'>,
  trailing?: Element<'div'>,
  alwaysShowText?: boolean
};

const SecondaryButton = (props: PropsT): Element<'button'> => {
  const { id, disabled, label, onClickAction, leading, trailing, alwaysShowText } = props;
  return (
    <Button
      id={id}
      color="link"
      className={styles.button}
      onClick={onClickAction}
      onKeyPress={onClickAction}
      disabled={disabled}
      tabIndex={0}
    >
      {leading}
      {alwaysShowText ?? true ? label : <LaptopAndUp>{label}</LaptopAndUp>}
      {trailing}
    </Button>
  );
};

SecondaryButton.defaultProps = {
  className: '',
  disabled: false
};

export default SecondaryButton;
