// @flow

import React, { type Element } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';
import SmallUserTableRow from './SmallUserTableRow';
import type { SmallUserTableRowItemT } from './SmallUserTableRow';

import styles from './SmallUserTable.module.scss';

export type PropsT = {
  id: string,
  allowLoadMore?: boolean,
  items: SmallUserTableRowItemT[],
  onInfiniteScrollLoadMore?: (page: number) => void,
  onSelectRow?: *,
  showIfEmpty?: Element<*>,
  tableStyle?: string
};

const getTableRows: * = (rows, onSelectRow) =>
  rows.map((row, index) => (
    <SmallUserTableRow
      key={
        `cl_${row.rowId}_${index}` // eslint-disable-line react/no-array-index-key
      }
      onSelect={onSelectRow}
      rowItem={row}
      rowExtraLine={row.extraLine}
    />
  ));

const SmallUserTable = ({
  id,
  allowLoadMore,
  items = [],
  onInfiniteScrollLoadMore,
  onSelectRow,
  showIfEmpty,
  tableStyle
}: PropsT): Element<'div'> => {
  if (!items.length && showIfEmpty) {
    return showIfEmpty;
  }
  const rowComponents = getTableRows(items, onSelectRow);
  const showRows = rowComponents.length > 0;
  const rowsElement = onInfiniteScrollLoadMore ? (
    <InfiniteScroll
      // This component started to fetch more pages than necessary when hasMore
      // was passed as true unless we know it's false. Hence allowLoadMore.
      hasMore={allowLoadMore}
      loadMore={onInfiniteScrollLoadMore}
      useWindow
      pageStart={1}
    >
      {rowComponents}
    </InfiniteScroll>
  ) : (
    rowComponents
  );
  return (
    <div className={classNames(styles.container, tableStyle)}>
      <div className={styles.table} id={id}>
        {showRows && rowsElement}
      </div>
    </div>
  );
};

SmallUserTable.defaultProps = {
  allowLoadMore: false,
  mapItemToCell: undefined,
  onInfiniteScrollLoadMore: undefined,
  onSelectRow: undefined,
  showIfEmpty: undefined,
  sort: undefined,
  tableStyle: ''
};

export default SmallUserTable;
