import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCalendarRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 2.5h-3.5V.75a.75.75 0 0 0-1.5 0V2.5h-9.5V.75a.75.75 0 0 0-1.5 0V2.5h-3.5A2.29 2.29 0 0 0 0 4.75v17A2.29 2.29 0 0 0 2.25 24h19.5A2.29 2.29 0 0 0 24 21.75v-17a2.29 2.29 0 0 0-2.25-2.25M2.25 4h3.5v1.75a.75.75 0 0 0 1.5 0V4h9.5v1.75a.75.75 0 0 0 1.5 0V4h3.5a.78.78 0 0 1 .78.78V8.5H1.5V4.75A.78.78 0 0 1 2.25 4m19.5 18.5H2.25a.78.78 0 0 1-.75-.75V10h21v11.75a.78.78 0 0 1-.75.75"/></svg>)}
    />
  );
}
  