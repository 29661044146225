// @flow

import React, { Component, type Element, type Node } from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import type { ExactPropsT, StoreStateT } from '../commonTypes';
import { createSetTooltipVisibility } from '../ducks/ui/tooltip/tooltipUiActions';
import Dismiss from './Button/Dismiss';
import Triangle from './Triangle';
import styles from './Tooltip.module.scss';

type OwnPropsT = {
  children?: Node,
  containerStyle?: string
};

type StatePropsT = {
  visible: string | null
};

type DispatchPropsT = {
  setTooltipVisibility: (string | null) => void
};

type StateT = {
  id: string
};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, DispatchPropsT>;

export class Tooltip extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      id: uuid()
    };
    this.activate = this.activate.bind(this);
  }

  activate: *;

  activate(isActive: boolean): void {
    this.props.setTooltipVisibility(isActive ? this.state.id : null);
  }

  render(): Element<'div'> {
    const { children, visible, containerStyle } = this.props;
    const { id } = this.state;

    return (
      <div className={classNames(styles.container, containerStyle)}>
        {visible === id ? (
          <div
            className={classNames({
              [styles.icon]: true,
              [styles.active]: true
            })}
            onClick={event => {
              event.preventDefault();
              this.activate(false);
            }}
            tabIndex="0"
            role="button"
            onKeyPress={event => {
              event.preventDefault();
              this.activate(false);
            }}
          >
            <div className={styles.content}>
              <div className={styles.arrow}>
                <Triangle
                  arrowSize={{
                    width: parseInt(styles.triangleArrowWidth, 10),
                    height: parseInt(styles.triangleArrowHeight, 10)
                  }}
                  arrowOffsetLeft={parseInt(styles.triangleArrowOffsetLeft, 10)}
                  arrowOffsetTop={0}
                  strokeWidth={parseInt(styles.triangleStrokeWidth, 10)}
                />
              </div>
              <div className={styles.children}>
                <Dismiss id="id" onClose={() => this.activate(false)} />
                {children}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={classNames({
              [styles.icon]: true,
              [styles.active]: false
            })}
            onClick={event => {
              event.preventDefault();
              this.activate(true);
            }}
            tabIndex="0"
            role="button"
            onKeyPress={event => {
              event.preventDefault();
              this.activate(true);
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => ({
  visible: state.ui.tooltip.visible
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTooltipVisibility: createSetTooltipVisibility
    },
    dispatch
  );

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Tooltip);
