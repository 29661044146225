import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Input from '../../Input';

import globalStyles from '../../../globals/scss/_typography.scss';
import styles from '../Login.module.scss';

import { classNames } from '../../../utils/css';

class MobilePhone extends Component {
  static propTypes = {
    id: PropTypes.string,
    /** Container classname */
    className: PropTypes.string,
    /** Whether the first input field is automatically focused or not */
    autofocus: PropTypes.bool,
    /** Interface showing error messsage */
    errorMessage: PropTypes.element,
    /** Flag to show spam-code after mobile phone logging in or not */
    showSpamCode: PropTypes.bool,
    /** Id for mobile auth session */
    mobileAuthEventId: PropTypes.string,
    /** Callback for calceling mobile polling */
    cancelMobileAuthPolling: PropTypes.func.isRequired,
    /** Interface for switching login type */
    loginTypesSwitcher: PropTypes.element,
    /** Link to help with the login */
    loginHelp: PropTypes.element,
    /** Text for creating a new login */
    loginHelpText: PropTypes.string,
    /** Link to creating a new login */
    loginCreate: PropTypes.element,
    /** URL for login help */
    loginHelpUrl: PropTypes.string,
    /** URL to creating a new login */
    createLoginUrl: PropTypes.string,
    /** Object with i18n strings */
    translations: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumberActive: false,
      spamCodeActive: false,
    };
  }

  handleBlur = (e) => {
    switch (e.target.name) {
      case 'phonenumber':
        this.setState({ phoneNumberActive: false });
        break;
      case 'spamcode':
        this.setState({ spamCodeActive: false });
        break;
      default:
        break;
    }
  };

  handleFocus = (e) => {
    switch (e.target.name) {
      case 'phonenumber':
        this.setState({ phoneNumberActive: true });
        break;
      case 'spamcode':
        this.setState({ spamCodeActive: true });
        break;
      default:
        break;
    }
  };

  render() {
    const { id, className, values, autofocus, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, showSpamCode, errorMessage, loginTypesSwitcher, loginHelp, loginHelpUrl, createLoginUrl, loginCreate, wide } = this.props;

    const {
      i18n_login_header_mobilePhone,
      i18n_login_loginButtonLabel_mobilePhone,
      i18n_login_loginButtonLabel,
      i18n_login_phoneNumberLabel,
      i18n_login_phoneNumberPlaceholder,
      i18n_login_spamCodePlaceholder,
      i18n_login_spamCodeLabel,
      i18n_login_createLoginText,
      i18n_login_loginHelpText,
    } = this.props.translations;

    return (
      <form onSubmit={handleSubmit} className={className}>
        <div id={id} ref={this.myRef}>
          <h3 className={classNames([globalStyles['h3--book'], styles['login__view--title']])}>{i18n_login_header_mobilePhone}</h3>

          {errorMessage}

          <Input
            name="phonenumber"
            onValueChange={handleChange}
            onBlur={(e) => {
              handleBlur(e);
              this.handleBlur(e);
            }}
            onFocus={this.handleFocus.bind(this)}
            value={values.phonenumber}
            active={this.state.phoneNumberActive.toString()}
            placeholder={i18n_login_phoneNumberPlaceholder}
            label={i18n_login_phoneNumberLabel || i18n_login_phoneNumberPlaceholder}
            wide={wide}
            autoFocus={autofocus}
            optionalText=""
            touched={touched.phonenumber}
            error={errors.phonenumber}
          />
          {showSpamCode && (
            <Input
              name="spamcode"
              onValueChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                this.handleBlur(e);
              }}
              onFocus={this.handleFocus.bind(this)}
              value={values.spamcode}
              active={this.state.spamCodeActive.toString()}
              placeholder={i18n_login_spamCodePlaceholder}
              label={i18n_login_spamCodeLabel || i18n_login_spamCodePlaceholder}
              wide={wide}
              optionalText=""
              touched={touched.spamcode}
              error={errors.spamcode}
            />
          )}

          <Button className={styles[`login__submit-button`]} id="button-login-with-mobile-phone" size="l" block type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
            {i18n_login_loginButtonLabel_mobilePhone || i18n_login_loginButtonLabel}
          </Button>

          {loginTypesSwitcher}

          {loginHelp}

          {loginCreate}
        </div>
      </form>
    );
  }
}

export const validate = (values, errors, translations) => {
  let { phonenumber } = values;

  if (!phonenumber) {
    errors.phonenumber = translations.i18n_login_errorPhoneNumberRequired;
  } else {
    phonenumber = `${phonenumber}`
      .replace(/(\s|-)/g, '')
      .replace(/^00/, '+')
      .replace(/^358/, '+358')
      .replace(/^(?:\+358|0)([1-9]\d*)$/, '+358$1');

    if (!/^\+[1-9]\d{7,14}$/.test(phonenumber)) {
      errors.phonenumber = translations.i18n_login_errorPhoneNumberInvalid;
    }
  }
};

export default MobilePhone;
