import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFlagRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.25.75v22.5a.75.75 0 0 1-1.5 0V.75a.75.75 0 0 1 1.5 0m19 4.75v10a1.76 1.76 0 0 1-1.75 1.75h-10a1.76 1.76 0 0 1-1.75-1.75v-1.25H5a.75.75 0 0 1 0-1.5h6.5a.25.25 0 0 0 .25-.25v-10a.25.25 0 0 0-.25-.25H5a.75.75 0 0 1 0-1.5h6.5a1.76 1.76 0 0 1 1.75 1.75v1.25h7.25a1.76 1.76 0 0 1 1.75 1.75m-1.5 0a.25.25 0 0 0-.25-.25h-7.25v7.25a1.76 1.76 0 0 1-1.75 1.75h-1.25v1.25c0 .138.112.25.25.25h10a.25.25 0 0 0 .25-.25z"/></svg>)}
    />
  );
}
  