import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSettingsFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.42 9.762-1.263-.449a1.37 1.37 0 0 1-.837-.836 1.36 1.36 0 0 1 .054-1.05l.578-1.213a2.36 2.36 0 0 0 0-2.047 2.36 2.36 0 0 0-1.351-1.216 2.36 2.36 0 0 0-1.815.096l-1.213.578a1.375 1.375 0 0 1-1.884-.781l-.45-1.265a2.376 2.376 0 0 0-4.475 0l-.45 1.265a1.375 1.375 0 0 1-1.885.78l-1.211-.575a2.36 2.36 0 0 0-2.052 0 2.35 2.35 0 0 0-1.214 1.352 2.36 2.36 0 0 0 .098 1.813l.575 1.211a1.375 1.375 0 0 1-.78 1.885l-1.265.45a2.36 2.36 0 0 0-1.443 1.444 2.38 2.38 0 0 0 1.444 3.032l1.263.449a1.37 1.37 0 0 1 .837.836c.123.346.104.719-.054 1.051l-.575 1.211a2.377 2.377 0 0 0 3.166 3.168l1.206-.574a1.377 1.377 0 0 1 1.891.778l.45 1.265A2.37 2.37 0 0 0 11.998 24c.977 0 1.894-.607 2.24-1.579l.449-1.263a1.37 1.37 0 0 1 .836-.837 1.37 1.37 0 0 1 1.051.054l1.212.576c.65.311 1.396.311 2.047 0a2.36 2.36 0 0 0 1.216-1.352 2.36 2.36 0 0 0-.096-1.815l-.578-1.211a1.375 1.375 0 0 1 .781-1.884l1.265-.45a2.37 2.37 0 0 0 1.444-1.444 2.38 2.38 0 0 0-1.445-3.033M12 16.75c-2.619 0-4.75-2.131-4.75-4.75S9.381 7.25 12 7.25s4.75 2.131 4.75 4.75-2.131 4.75-4.75 4.75"/></svg>)}
    />
  );
}
  