// @flow strict-local

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { compose } from 'redux';
import type { ExactPropsT } from '../../../../commonTypes';
import type { ExtensionGroupGridElementT } from '../../../../ducks/entities/callFlow/callFlowTypes';
import type { ExtensionGroupEntityT } from '../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import GridElementContainer from '../gridElement/GridElementContainer';
import EditExtensionGroup from '../details/extensionGroup/EditExtensionGroup';
import ExtensionGroupDetails from '../details/extensionGroup/ExtensionGroupDetails';
import ViewExtensionGroupCalendar from '../details/extensionGroup/ViewExtensionGroupCalendar';
import EditExtensionGroupCalendar from '../details/extensionGroup/EditExtensionGroupCalendar';
import ViewExtensionGroupOverflow from '../details/extensionGroup/ViewExtensionGroupOverflow';
import EditExtensionGroupOverflow from '../details/extensionGroup/EditExtensionGroupOverflow';
import VoiceMailDetails from '../details/extensionGroup/VoiceMailDetails';
import EditVoiceMail from '../details/extensionGroup/EditVoiceMail';
import ViewExtensionGroupQueue from '../details/extensionGroup/ViewExtensionGroupQueue';
import EditExtensionGroupQueue from '../details/extensionGroup/EditExtensionGroupQueue';

type OwnPropsT = {|
  data: ExtensionGroupEntityT,
  enterpriseId: string,
  hideDescription: boolean,
  single?: boolean
|};

type StatePropsT = {||};

type StateT = {};
export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

export class ExtensionGroupElement extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {};
  }

  render(): Element<typeof GridElementContainer> {
    const { t, data, enterpriseId, hideDescription, single } = this.props;
    const callflowId = `${enterpriseId}-EXTENSION_GROUP-${data.id}`;

    const voiceMailNode = {
      id: `group_voice_mail_${data.id}`,
      type: 'EXTENSION_GROUP_VOICE_MAIL'
    };
    const overflowNode = {
      id: `group_overflow_${data.id}`,
      type: 'EXTENSION_GROUP_OVERFLOW',
      verticalNode: voiceMailNode
    };
    const membersNode = {
      id: `group_members_${data.id}`,
      type: 'EXTENSION_GROUP_MEMBERS',
      horizontalNode: overflowNode
    };
    const queueNode = {
      id: `group_queue_${data.id}`,
      type: 'EXTENSION_GROUP_QUEUE',
      horizontalNode: membersNode
    };
    const calendarNotOpenNode = {
      id: `group_calendar_closed_${data.id}`,
      type: 'EXTENSION_GROUP_CALENDAR_CLOSED'
    };
    const calendarNode = {
      id: `group_calendar_${data.id}`,
      type: 'EXTENSION_GROUP_CALENDAR',
      horizontalNode: queueNode,
      verticalNode: calendarNotOpenNode
    };
    const rootElement: ExtensionGroupGridElementT = {
      id: data.id,
      type: 'EXTENSION_GROUP',
      name: data.label || t('callflows.type.extensionGroup.defaultName'),
      number: data.addressNumber,
      description: t('callflows.type.extensionGroup.description'),
      enterpriseId,
      rootNode: calendarNode
    };

    const viewContent = nodeType => {
      switch (nodeType) {
        case 'EXTENSION_GROUP_QUEUE':
          return <ViewExtensionGroupQueue callflowId={callflowId} />;
        case 'EXTENSION_GROUP_MEMBERS':
          return <ExtensionGroupDetails callflowId={callflowId} />;
        case 'EXTENSION_GROUP_CALENDAR':
          return <ViewExtensionGroupCalendar callflowId={callflowId} />;
        case 'EXTENSION_GROUP_CALENDAR_CLOSED':
          return null;
        case 'EXTENSION_GROUP_OVERFLOW':
          return <ViewExtensionGroupOverflow callflowId={callflowId} />;
        case 'EXTENSION_GROUP_VOICE_MAIL':
          return <VoiceMailDetails callflowId={callflowId} />;
        default:
          return <div />;
      }
    };

    const editContent = nodeType => {
      switch (nodeType) {
        case 'EXTENSION_GROUP_QUEUE':
          return <EditExtensionGroupQueue callflowId={callflowId} />;
        case 'EXTENSION_GROUP_MEMBERS':
          return <EditExtensionGroup callflowId={callflowId} />;
        case 'EXTENSION_GROUP_CALENDAR':
          return <EditExtensionGroupCalendar callflowId={callflowId} />;
        case 'EXTENSION_GROUP_OVERFLOW':
          return <EditExtensionGroupOverflow callflowId={callflowId} />;
        case 'EXTENSION_GROUP_VOICE_MAIL':
          return <EditVoiceMail callflowId={callflowId} />;
        case 'EXTENSION_GROUP_CALENDAR_CLOSED':
        default:
          return <div />;
      }
    };
    return (
      <GridElementContainer
        key={rootElement.id}
        gridElement={rootElement}
        hideDescription={hideDescription}
        single={single}
        viewContentSupplier={viewContent}
        editContentSupplier={editContent}
      />
    );
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)
)(ExtensionGroupElement);
