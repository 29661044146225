import React from 'react';
import IconContainer from '../IconContainer';

export default function IconContinuousRecordingLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.7 4.5C9.8 4.2 8.9 4 8 4c-4.4 0-8 3.6-8 8s3.6 8 8 8c.9 0 1.8-.2 2.7-.5-.3-.2-.6-.5-.9-.8q-.9.3-1.8.3c-3.9 0-7-3.1-7-7s3.1-7 7-7c.6 0 1.2.1 1.7.2.3-.3.6-.5 1-.7M16 4c-1.5 0-2.8.4-4 1.1l-.9.6C9.2 7.2 8 9.4 8 12s1.2 4.8 3.1 6.3l.9.6c1.2.7 2.5 1.1 4 1.1 4.4 0 8-3.6 8-8s-3.6-8-8-8m0 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7"/></svg>)}
    />
  );
}
  