import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMobiilivarmenneRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.6 9.7v6.7h-1.5v-5.2zm4.9-5-.5.5-.6.6c1.3 1.8 2 3.9 2 6.2 0 5.8-4.7 10.5-10.5 10.5-5.7 0-10.4-4.7-10.4-10.5S6.2 1.5 11.9 1.5q3.45 0 6.3 2.1l1.2-1.1C17.3.9 14.7 0 12 0 5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12c0-2.6-.9-5.2-2.5-7.3m-6.4 3.2L12 11 8.9 7.9H7.4v.6l4.6 4.6 8-8L21.1 4c-.3-.4-.7-.7-1.1-1l-1.1 1.1zm-7.7 8.5h1.5v-5.2L7.4 9.7z"/></svg>)}
    />
  );
}
  