// @flow

import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { createStartTourAction, createSetStepsAction } from '../../ducks/ui/tour/tourActions';
import type { CallForwardingsTabT } from '../../ducks/ui/callForwardings/callForwardingsUiTypes';
import type { DirectoryStateEntityT } from '../../ducks/entities/directory';

const useForwardingListTour = () => {
  // Redux
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const activeTab: CallForwardingsTabT =
    useSelector(state => state.ui.callForwardings.activeTab) || 'users';
  const userEntries: { [string]: DirectoryStateEntityT[] } =
    useSelector(state => R.path(['entities', 'directory', 'byId'], state)) || [];

  const createUserSteps = () => {
    return [
      {
        disableBeacon: true,
        title: t('tours.userForwardingsSteps.step1.title'),
        content: t('tours.userForwardingsSteps.step1.text'),
        target: '#forwardings-header',
        position: 'right'
      },
      {
        title: t('tours.userForwardingsSteps.step2.title'),
        content: t('tours.userForwardingsSteps.step2.text'),
        target: '#create-callforwarding-button',
        position: 'right'
      },
      ...(!R.isEmpty(userEntries)
        ? [
            {
              title: t('tours.userForwardingsSteps.step3.title'),
              content: t('tours.userForwardingsSteps.step3.text'),
              target: '#user-forwardings-table span[class="ds-popover-holder"]:first-of-type',
              position: 'right'
            }
          ]
        : [])
    ];
  };

  const enterpriseSteps = [
    {
      disableBeacon: true,
      title: t('tours.enterpriseForwardingSteps.step1.title'),
      content: parse(
        `${t('tours.enterpriseForwardingSteps.step1.text1')}<br/><br/>${t(
          'tours.enterpriseForwardingSteps.step1.text2'
        )}<br/>${t('tours.enterpriseForwardingSteps.step1.text3')}<br/>${t(
          'tours.enterpriseForwardingSteps.step1.text4'
        )}<br/>${t('tours.enterpriseForwardingSteps.step1.text5')}`
      ),
      target: '#forwardings-header',
      position: 'top'
    },
    {
      title: t('tours.enterpriseForwardingSteps.step2.title'),
      content: parse(
        `${t('tours.enterpriseForwardingSteps.step2.text1')}<br/>${t(
          'tours.enterpriseForwardingSteps.step2.text2'
        )}`
      ),
      target: '#create-callforwarding-button',
      position: 'right'
    },
    {
      title: t('tours.enterpriseForwardingSteps.step3.title'),
      content: t('tours.enterpriseForwardingSteps.step3.text'),
      target: 'tr td:last-of-type',
      position: 'right'
    }
  ];

  const startTour = () => {
    const steps = activeTab === 'enterprises' ? enterpriseSteps : createUserSteps();
    dispatch(createSetStepsAction(steps));
    dispatch(createStartTourAction());
  };

  return [startTour];
};

export default useForwardingListTour;
