import React from 'react';
/* eslint-disable */


class HeaderCell extends React.PureComponent {
  render() {
    const {
      date,
      dayFormat,
    } = this.props;
    return (<span className="title">{date.format(dayFormat)}</span>);
  }
}
export default HeaderCell;
