import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilePdfRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.75 5.93 15.33.51A1.73 1.73 0 0 0 14.1 0H5.01a2.25 2.25 0 0 0-2.26 2.24v19.51A2.25 2.25 0 0 0 5 24h14.01a2.25 2.25 0 0 0 2.24-2.25V7.17a1.74 1.74 0 0 0-.5-1.24m-2 .07h-3.2a.25.25 0 0 1-.25-.25v-3.2zm1 15.75a.75.75 0 0 1-.75.75H5.01a.76.76 0 0 1-.76-.75V2.25a.76.76 0 0 1 .76-.75h8.75v4.25a1.76 1.76 0 0 0 1.75 1.75h4.26v14.25zM7.58 15.4H6.16a.15.15 0 0 0-.15.14V20a.15.15 0 0 0 .15.14h.94a.14.14 0 0 0 .14-.14v-1.44h.27c.492.028.978-.121 1.37-.42a1.51 1.51 0 0 0 .5-1.21 1.4 1.4 0 0 0-.48-1.15 2 2 0 0 0-1.32-.38m.39 2a.86.86 0 0 1-.56.14h-.17v-1h.29a.72.72 0 0 1 .47.12.5.5 0 0 1 .13.39.44.44 0 0 1-.12.3zm3.81-2h-1.4a.14.14 0 0 0-.14.14V20c0 .077.063.14.14.14h1.26a2.7 2.7 0 0 0 1.91-.62 2.33 2.33 0 0 0 .67-1.79 2.25 2.25 0 0 0-.65-1.73 2.48 2.48 0 0 0-1.79-.6m0 3.67h-.26v-2.61h.36c.77 0 1.12.41 1.12 1.29s-.44 1.32-1.27 1.32zm6.23-3.53v.78a.15.15 0 0 1-.15.14h-1.47v.86h1.36a.15.15 0 0 1 .14.15v.77a.14.14 0 0 1-.14.14h-1.36V20a.15.15 0 0 1-.15.14h-.93a.14.14 0 0 1-.14-.14v-4.46a.14.14 0 0 1 .14-.14h2.55a.15.15 0 0 1 .15.14"/></svg>)}
    />
  );
}
  