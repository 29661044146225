import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBlockLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m0 1c2.7 0 5.4 1 7.4 2.9L3.9 19.4C-.2 14.9.1 7.9 4.6 3.9 6.6 2 9.3 1 12 1m0 22c-2.7 0-5.4-1-7.4-2.9L20.1 4.6c4.1 4.5 3.8 11.5-.7 15.5-2 1.9-4.7 2.9-7.4 2.9"/></svg>)}
    />
  );
}
  