// @flow
import React, { type Element, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '@design-system/component-library/src/components/Input';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { useTranslation } from 'react-i18next';
import {
  getAllNextStepOptions,
  NEW_FIELDS
} from '../../../../callFlowGrid/details/welcomeAttendant/WelcomeAttendantUtils';
import type {
  StepOptionsT,
  NextStepT
} from '../../../../callFlowGrid/details/welcomeAttendant/WelcomeAttendantUtils';
import TooltipExtension from '../../../../../../components/TooltipExtension';

import styles from './WelcomeAttendantStepField.module.scss';

export type TooltipT = {|
  title: string,
  text: string
|};

export type PropsT = {|
  field: string,
  title: string,
  stepOptions: NextStepT[],
  description?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean,
  noEndStep?: boolean,
  noStartStep?: boolean,
  tooltip?: TooltipT
|};

export const WelcomeAttendantStepField = (props: PropsT): Element<'div'> => {
  const { field, title, description, stepOptions, noEndStep, noStartStep, tooltip } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();

  const nextStep: ?NextStepT = values[field];
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const transferOptions: StepOptionsT[] = useMemo(
    () => getAllNextStepOptions(stepOptions, t, styles['new-step'], noEndStep, noStartStep),
    [activeLanguage, stepOptions] // eslint-disable-line
  );
  const currentComboboxValue = transferOptions.find(
    o => o && nextStep && o.value === nextStep.value
  ) || { value: 'INITIAL-SELECTION', label: '' };

  const convertNewType = (type: string) => {
    switch (type) {
      case 'ADD_PLAY_MENU': {
        return 'INTERACTION';
      }
      case 'ADD_FILTER_TIME': {
        return 'CALENDAR';
      }
      case 'ADD_TRANSFER_CALL': {
        return 'TRANSFER';
      }
      case 'ADD_PLAY_MESSAGE': {
        return 'AUDIO';
      }
      default:
        return type;
    }
  };

  const descriptionMsg: string =
    description === undefined ? t('callflows.forwardingField.description') : description;
  return (
    <div className={styles['field-group-section']} data-cy={`welcomeattendant-step-field-${field}`}>
      <div className={styles['field-section']}>
        <div className={styles['title-area']}>
          <div className={styles.title}>{title}</div>
          {tooltip && <TooltipExtension title={tooltip.title} text={tooltip.text} />}
        </div>
        {descriptionMsg && <div className={styles.description}>{descriptionMsg}</div>}
      </div>
      <div className={styles['next-step-container']}>
        <p className={styles.subtitle}>{t('callflows.welcomeAttendantStepField.transferCalls')}</p>
        <div>
          {currentComboboxValue && (
            <Dropdown
              label=""
              className={styles['option-next-step-input']}
              // $FlowFixMe Improve typings
              items={transferOptions}
              selectedValue={currentComboboxValue.value}
              onValueChange={option => {
                if (option) {
                  setValue(
                    field,
                    {
                      ...option,
                      type: convertNewType(option.dataset.value),
                      name: option.dataset.value,
                      value: option.dataset.value,
                      newStepName: option.dataset.value ? null : ''
                    },
                    { shouldDirty: true }
                  );
                }
              }}
            />
          )}
          {nextStep && NEW_FIELDS.includes(currentComboboxValue.value) && (
            <div className={styles['input-area']}>
              <Input
                id="new-step-name-input"
                data-cy="new-step-input"
                className={styles['new-step-name-input']}
                label={t('callflows.welcomeAttendantStepField.newStepLabel')}
                onValueChange={event => {
                  event.preventDefault();
                  setValue(
                    field,
                    {
                      ...nextStep,
                      newStepName: (event.currentTarget.value || '').trim()
                    },
                    true
                  );
                }}
                defaultValue={nextStep.newStepName || ''}
                i18n_input_errorMessage={errors && errors[field] ? errors[field].message : ''}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomeAttendantStepField;
