import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAddFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 10.75h-6.25V4.5a1.25 1.25 0 0 0-2.5 0v6.25H4.5a1.25 1.25 0 0 0 0 2.5h6.25v6.25a1.25 1.25 0 0 0 2.5 0v-6.25h6.25a1.25 1.25 0 0 0 0-2.5"/></svg>)}
    />
  );
}
  