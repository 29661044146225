// @flow

import React, { type Element } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GenericErrorSVG } from '../../assets/generic-error.svg';
import ActionButton from '../Button/ActionButton';
import type { ExactPropsT, TranslateT } from '../../commonTypes';
import styles from './GenericError.module.scss';

export type OwnPropsT = {|
  showReloadLink?: boolean,
  message: string,
  translateMock?: TranslateT<>
|};

type StatePropsT = {||};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;

export const GenericError = ({
  message,
  showReloadLink,
  translateMock
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  return (
    <div className={styles.error}>
      <div>
        <GenericErrorSVG />
        <div className={styles.message}>{message}</div>
        {showReloadLink && (
          <ActionButton
            id="reload-link"
            onClickAction={() => window.location.reload()}
            label={(translateMock || t)('generic.reload')}
          />
        )}
      </div>
    </div>
  );
};

GenericError.defaultProps = {
  showReloadLink: false
};

const mapStateToProps = () => ({});

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(GenericError);
