import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowDownLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 16a1 1 0 0 1-.719-.305L4.64 8.819a.5.5 0 0 1 .72-.694L12 15l6.645-6.879a.5.5 0 0 1 .719.694l-6.645 6.88A1 1 0 0 1 12 16"/></svg>)}
    />
  );
}
  