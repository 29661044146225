import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHotLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.75 19.501a.5.5 0 0 1 0-1 4 4 0 0 0 3.771-4.216l-.001-.013a.5.5 0 0 1 .479-.52H17a.48.48 0 0 1 .52.48 5 5 0 0 1-4.715 5.268l-.015.001zm9-5.999C21.75 7.303 16.14.735 10.2.005a.88.88 0 0 0-.766 1.463l.006.007c5.56 6.149 2.74 9.928 1.29 11.267a.29.29 0 0 1-.36 0 4.43 4.43 0 0 1-1.51-3.999.86.86 0 0 0-.07-.419.88.88 0 0 0-1.14-.49 8.36 8.36 0 0 0-5.4 7.928C2.251 20.171 6.811 24 12 24c6.7 0 9.75-5.439 9.75-10.488zm-12 0c.48.423 1.2.423 1.68 0 1.29-1.18 5-5.559-1-12.427 5.24.83 10.32 6.928 10.32 12.437C20.75 18.231 18 23 12 23c-4.66 0-8.75-3.359-8.75-7.188a7.37 7.37 0 0 1 4.59-6.928 5.41 5.41 0 0 0 1.89 4.629z"/></svg>)}
    />
  );
}
  