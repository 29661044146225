// @flow strict-local

import axios, { AxiosPromise } from 'axios';
import type { CancelToken } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import {
  createRetrieveEfaxCollectionCancel,
  createRetrieveEfaxCollectionFailure,
  createRetrieveEfaxCollectionRequest,
  createRetrieveEfaxCollectionSuccess
} from './efaxActions';
import type { EfaxEntitiesT, SearchEfaxActionT } from './efaxTypes';

type SearchParamsT = {|
  page?: number,
  size?: number,
  search?: string,
  sort?: string
|};

type SearchEfaxFnT = (
  string,
  CancelToken,
  SearchParamsT
) => ThunkActionT<SearchEfaxActionT, EfaxEntitiesT | void>;
const searchEfax: SearchEfaxFnT = (enterpriseId, cancelToken, params = {}) => async dispatch => {
  dispatch(createRetrieveEfaxCollectionRequest(enterpriseId, params));
  try {
    const response: AxiosPromise<EfaxEntitiesT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/efax`,
      params,
      cancelToken
    });
    const { data } = await response;
    dispatch(
      createRetrieveEfaxCollectionSuccess(enterpriseId, data, {
        saveTotalCount: params.search === ''
      })
    );
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveEfaxCollectionCancel());
    } else {
      dispatch(createRetrieveEfaxCollectionFailure(enterpriseId, error));
    }
    return error;
  }
};

const efaxOperations = {
  searchEfax
};

export default efaxOperations;
