// @flow
import React, { useEffect, useRef, useState } from 'react';

import axios, { AxiosPromise, CancelToken } from 'axios';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import type { Element } from 'react';
import { useParams } from 'react-router-dom';
import type { Canceler } from 'axios';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useTranslation } from 'react-i18next';
import { Button } from '@design-system/component-library';
import IconSettingsRegular from '@design-system/component-library/src/components/Icon/lib/IconSettingsRegular';
import GenericError from '../../components/Error/GenericError';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import type { SortOrderT } from '../../commonTypes';
import type { DirectoryEntityT, DirectoryStateEntityT } from '../../ducks/entities/directory';
import { ReactComponent as NoSearchResultsPic } from '../../assets/no-search-results.svg';
import type { TableRowItemT } from '../../components/Table/TableRow';
import type { SmallUserTableRowItemT } from '../../components/SmallUserTable/SmallUserTableRow';
import { getAvailability, getCallFlowType } from '../../helpers';
import { selectors as directorySelect } from '../../ducks/entities/directory';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import Avatar from '../../components/Avatar/Avatar';
import type { TableHeaderColumnT, TableSortT } from '../../components/Table/TableHeader';
import Table from '../../components/Table/Table';
import SmallUserTable from '../../components/SmallUserTable/SmallUserTable';
import { createOpenUsersImportAction } from '../../ducks/ui/header/headerUiActions';
import { goToCallFlow, goToEnterpriseUsers, goToUserDetails } from '../../navigationOperations';
import SearchMatchRow from './SearchMatchRow/SearchMatchRow';

import { operations as userOps, selectors as userSelect } from '../../ducks/entities/user';
import Dialog from '../../components/Dialog';
import directoryOperations from '../../ducks/entities/directory/directoryOperations';
import ActionButton from '../../components/Button/ActionButton';
import { ReactComponent as TrashBinIcon } from '../../assets/trashbin_new.svg';
import { actions as notificationActions } from '../../ducks/ui/notification';
import { ReactComponent as AcdIcon } from '../../assets/callflow/grid/acd.svg';
import { ReactComponent as PlayMusicIvrIcon } from '../../assets/callflow/grid/ivr.svg';
import { ReactComponent as WelcomeAttendantIcon } from '../../assets/callflow/grid/welcomeAttendant.svg';
import { ReactComponent as SpeedDialIcon } from '../../assets/callflow/grid/speedDial.svg';
import { ReactComponent as OcIcon } from '../../assets/callflow/grid/oc.svg';
import { ReactComponent as ExtensionGroupIcon } from '../../assets/callflow/grid/extgrp.svg';
import { ReactComponent as CalendarIcon } from '../../assets/callflow/details/calendar-small.svg';
import { ReactComponent as VoiceMailIcon } from '../../assets/callflow/details/voicemail_open.svg';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import { removeUserConfig } from '../../userConfigHelpers';
import { operations } from '../../ducks/config';
import ColumnSelector from './ColumnSelector/ColumnSelector';
import styles from './UsersOrServicesTable.module.scss';

type PropsT = {|
  data: DirectoryStateEntityT[],
  setImportUsersActive: boolean => void,
  setDisplayError: boolean => void,
  setSortTerm: string => void,
  setPageToSearch: number => void,
  numberOfUsers: number,
  numberOfSearchedUsers: number,
  isServicesTable: boolean
|};

export const UsersOrServicesTable = (props: PropsT): Element<'div' | typeof GenericError> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data,
    setImportUsersActive,
    setDisplayError,
    setSortTerm,
    setPageToSearch,
    numberOfUsers,
    numberOfSearchedUsers,
    isServicesTable
  } = props;
  const mounted = useRef(false);
  const currentUser = useSelector(state => state.currentUser);
  const hasLoadingError = useSelector(state => directorySelect.collectionHasError(state));
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const isLoadingUsers = useSelector(state => directorySelect.collectionIsLoading(state));
  const hasMoreResults = useSelector(state => directorySelect.collectionHasMoreResults(state));
  const usersById = useSelector(state => state.entities.directory.byId);
  const avatars = useSelector(state =>
    directorySelect.avatarsByIds(state, state.entities.directory.allIds)
  );
  const { id: enterpriseId } = useParams();
  const [sort, setSort] = useState({
    columnId: 'phoneNumbers',
    order: 'asc'
  });

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  const uiUser = useSelector(state => state.ui.user);
  const columnSelectorRef = useRef(null);
  const cancelAddressNumberRequest = React.useRef<Canceler>();

  const startImportUsers = () => {
    dispatch(createOpenUsersImportAction());
    setShowColumnSelector(false);
    setImportUsersActive(true);
  };

  const internalUsersSelected =
    values.categoryField && values.categoryField.value === 'INTERNAL_CONTACTS';
  const externalUsersSelected =
    values.categoryField && values.categoryField.value === 'EXTERNAL_CONTACTS';

  const columnsToShow = uiUser.userColumnsToShow;
  const columns: TableHeaderColumnT[] = [];

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const userConfig = useSelector(state => state.config.userConfig);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const selectOrDeselectUsers = checkAllEl => {
    data.forEach(u => {
      const el = document.querySelector(`#extuser_check_${u.internalAddressId}`);
      // $FlowFixMe
      if (el.checked !== checkAllEl.checked) {
        // $FlowFixMe
        el.click();
      }
    });

    // $FlowFixMe
    setSelectedIds(!checkAllEl.checked ? [] : data.map(u => u.internalAddressId));
  };

  const deselectAll = () => {
    const checkAllEl = document.querySelector('#checkall');

    // $FlowFixMe
    if (checkAllEl && checkAllEl.checked) {
      // $FlowFixMe
      checkAllEl.click();
    } else {
      selectOrDeselectUsers(checkAllEl);
    }
  };

  const selectOrDeselect = () => {
    const checkAllEl = document.querySelector('#checkall');
    selectOrDeselectUsers(checkAllEl);
  };

  useEffect(() => {
    if (externalUsersSelected) {
      deselectAll();
    }
    return () => {};
  }, [values.searchField, values.categoryField.value]); // eslint-disable-line react-hooks/exhaustive-deps

  if (columnsToShow.avatar.show) {
    columns.push({
      columnId: 'avatar',
      text: '',
      size: 'x-small',
      border: false
    });
  }
  if (columnsToShow.availability.show && !isServicesTable) {
    columns.push({
      columnId: 'availability',
      text: t('users.availability'),
      size: 'medium',
      border: false
    });
  }
  if (columnsToShow.lastName.show && !isServicesTable) {
    columns.push({
      columnId: 'lastName',
      text: t('users.lastName'),
      size: 'medium',
      border: false
    });
  }
  if (columnsToShow.firstName.show && !isServicesTable) {
    columns.push({
      columnId: 'firstName',
      text: t('users.firstName'),
      size: 'medium',
      border: false
    });
  }
  if (isServicesTable) {
    columns.push({
      columnId: 'label',
      text: t('users.label'),
      size: 'medium',
      border: false
    });
  }
  if (columnsToShow.title.show) {
    columns.push({
      columnId: 'title',
      text: t('users.title'),
      size: 'medium',
      border: false
    });
  }
  if (columnsToShow.phoneNumbers.show) {
    columns.push({
      columnId: 'phoneNumbers',
      text: t('users.phoneNumbers'),
      sortable: internalUsersSelected,
      size: 'large',
      border: false
    });
  }
  if (columnsToShow.addressNumber.show) {
    columns.push({
      columnId: 'addressNumber',
      text: t('users.addressNumber'),
      size: 'medium',
      border: false
    });
  }
  if (columnsToShow.emails.show) {
    columns.push({
      columnId: 'emails',
      text: t('users.emails'),
      size: 'large',
      border: false
    });
  }

  const handleTableSorted = (sorting: TableSortT) => {
    const { columnId, order }: { columnId: string, order: SortOrderT } = sorting;

    const newSortTerm = `${columnId},${order}`;
    setSortTerm(newSortTerm);
    setSort({
      columnId,
      order
    });
  };

  const getUser = (id: string) => usersById[id];

  const confirmRemoveExternalUser = () => {
    if (selectedIds.length > 0) {
      setDeleteDialogOpen(true);
    }
  };

  const closeConfirmDialog = () => {
    setDeleteDialogOpen(false);
  };

  const removeExternalUsers = async () => {
    if (enterpriseId) {
      setDeleting(true);
      const promises = [];
      selectedIds.forEach(userId => {
        promises.push(
          dispatch(
            userOps.remove(
              enterpriseId,
              userId,
              {
                type: 'externalUser'
              },
              new CancelToken(canceler => {
                cancelAddressNumberRequest.current = canceler;
              }),
              createCsrfHeader(currentUser)
            )
          )
        );
      });
      await Promise.all(promises);
      let hasError = false;
      selectedIds.forEach(userId => {
        if (userSelect.hasDeleteErrorOnUserId(usersById, userId)) {
          hasError = true;
        }
      });
      deselectAll();
      const params = {
        page: 1,
        size: 20,
        type: 'shared'
      };
      await dispatch(
        directoryOperations.searchDirectory(
          enterpriseId,
          new CancelToken(canceler => {
            cancelAddressNumberRequest.current = canceler;
          }),
          params
        )
      );
      setValue('searchField', '', {
        shouldValidate: false,
        shouldDirty: true
      });
      let config = userConfig;
      selectedIds.forEach(userId => {
        config = removeUserConfig(config, enterpriseId, userId);
      });

      dispatch(operations.updateUserConfig(config, createCsrfHeader(currentUser)));

      setDeleting(false);
      setSelectedIds([]);
      closeConfirmDialog();
      if (hasError) {
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'external-user-deleted',
            duration: 15000,
            type: 'error',
            message: t('externalUsers.bulkDeleteNotification.errorNotification')
          })
        );
      } else {
        dispatch(goToEnterpriseUsers(enterpriseId));
        dispatch(
          notificationActions.createCreateNotificationAction({
            tag: 'external-user-deleted',
            duration: 15000,
            type: 'info',
            message: t('externalUsers.bulkDeleteNotification.successNotification')
          })
        );
      }
    }
  };

  const renderDeleteDialog = () => {
    return (
      <Dialog
        title={t('externalUsers.bulkDeleteDialog.title')}
        description={t('externalUsers.bulkDeleteDialog.description', {
          externalContactCount: selectedIds.length
        })}
        confirmLabel={t('externalUsers.bulkDeleteDialog.confirm')}
        cancelLabel={t('externalUsers.bulkDeleteDialog.cancel')}
        disabled={false}
        loading={deleting}
        onCancel={closeConfirmDialog}
        onConfirm={removeExternalUsers}
        onClose={closeConfirmDialog}
      />
    );
  };

  const onSelectRow = async (rowItem: TableRowItemT | SmallUserTableRowItemT) => {
    const { id } = rowItem;
    if (enterpriseId) {
      let userId = '';
      const selectedUser = getUser(id);
      if (selectedUser.alias === 'IUser' || selectedUser.alias === 'UserContact') {
        if (selectedUser.alias === 'IUser') {
          const userIdResponse: AxiosPromise<{ data: { extensionId: string } }> = await axios({
            method: 'GET',
            url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumber/${selectedUser.publicInfo.addressNumber}`,
            cancelToken: new CancelToken(canceler => {
              cancelAddressNumberRequest.current = canceler;
            })
          });
          userId = userIdResponse.data.extensionId;
        } else {
          userId = selectedUser.internalAddressId;
        }
        if (mounted.current) {
          setDisplayError(true);
        }
        dispatch(
          goToUserDetails(
            enterpriseId,
            userId,
            selectedUser.alias === 'IUser' ? 'internalUser' : 'externalUser'
          )
        );
      } else if (
        selectedUser.alias === 'IVRService' ||
        selectedUser.alias === 'ACDGroupAddress' ||
        selectedUser.alias === 'GroupAddress' ||
        selectedUser.alias === 'SpeedDial'
      ) {
        const callFlowType = getCallFlowType(
          selectedUser.alias,
          selectedUser.publicInfo.ivrName,
          selectedUser.displayName,
          selectedUser.publicInfo.pstnNumbers,
          selectedUser.publicInfo.externalDestination
        );
        if (callFlowType) {
          dispatch(goToCallFlow(enterpriseId, selectedUser.internalAddressId, callFlowType));
        }
      }
    }
  };

  const getAvatar = (element, fullName, availability) => {
    let avatar;
    if (
      element.alias === 'IUser' ||
      element.alias === 'UserContact' ||
      element.alias === 'Extension'
    ) {
      const avatarFound = avatars.find(av => av.id === element.id);
      avatar = (
        <Avatar
          src={avatarFound && avatarFound.isAvatarCustomized ? element.publicInfo.urlPhoto : null}
          name={fullName}
          color={element.alias === 'IUser' ? '#0019AF' : '#002447'}
          size="small"
          {...(element.alias === 'IUser'
            ? { availability: availability.icon }
            : { hideAvailability: true })}
        />
      );
    } else if (element.alias === 'ACDGroupAddress') {
      avatar = <AcdIcon className={styles.avatar} />;
    } else if (element.alias === 'GroupAddress') {
      avatar = <ExtensionGroupIcon className={styles.avatar} />;
    } else if (element.alias === 'SpeedDial') {
      if (
        element.publicInfo.pstnNumbers &&
        element.publicInfo.externalDestination &&
        element.publicInfo.pstnNumbers.includes(element.publicInfo.externalDestination)
      ) {
        avatar = <OcIcon className={styles.avatar} />;
      } else {
        avatar = <SpeedDialIcon className={styles.avatar} />;
      }
    } else if (element.alias === 'IVRService') {
      if (element.publicInfo.ivrName && element.publicInfo.ivrName.includes('WelcomeAttendant')) {
        avatar = <WelcomeAttendantIcon className={styles.avatar} />;
      } else if (element.publicInfo.ivrName && element.publicInfo.ivrName.includes('PlayMusic')) {
        avatar = <PlayMusicIvrIcon className={styles.avatar} />;
      } else if (element.publicInfo.ivrName && element.publicInfo.ivrName.includes('VoiceClock')) {
        avatar = <CalendarIcon className={styles.avatar} />;
      } else if (element.publicInfo.ivrName && element.publicInfo.ivrName.includes('AccessACD')) {
        avatar = <AcdIcon className={styles.avatar} />;
      } else if (
        (element.publicInfo.ivrName && element.publicInfo.ivrName.includes('AccessVoiceMail')) ||
        (element.publicInfo.ivrName && element.publicInfo.ivrName.includes('EnterpriseVM')) ||
        (element.publicInfo.ivrName && element.publicInfo.ivrName.includes('AccessVM'))
      ) {
        avatar = <VoiceMailIcon className={styles.avatar} />;
      } else {
        avatar = <Avatar src={null} name={null} color="#002447" size="small" hideAvailability />;
      }
    } else if (element.alias === 'FaxAddress') {
      avatar = element.publicInfo.emails ? (
        <img style={{ paddingTop: '5px', width: '32px' }} src="/fax.svg" alt="fax-icon" />
      ) : (
        <img style={{ paddingTop: '5px', width: '32px' }} src="/fax-user.svg" alt="fax-user-icon" />
      );
    }
    return avatar;
  };

  const renderTable = (): Element<'div'> => {
    const items: TableRowItemT[] | SmallUserTableRowItemT[] = data.map(
      (element: DirectoryEntityT, index: number): TableRowItemT => {
        let title = '';
        let phoneNumbers = '';
        let emails = '';
        let fullName = '';
        if (element.alias === 'IUser') {
          title = element.publicInfo.title;
          emails = element.publicInfo.emails;
          fullName = [element.publicInfo.firstName, element.publicInfo.lastName].join(' ').trim();
        } else {
          title = element.privateInfo ? element.privateInfo.title : '';
          phoneNumbers = element.privateInfo ? element.privateInfo.defaultNumber : '';
          emails = element.privateInfo ? element.privateInfo.emails : '';
          fullName = element.privateInfo
            ? [element.privateInfo.firstName, element.privateInfo.lastName].join(' ').trim()
            : '';
        }
        const availability = getAvailability(element, t);
        let firstName = element.displayName;
        let label = element.displayName;

        const SWITCHBOARD_PREFIX = 'kutsu:';
        if (element.alias === 'ACDGroupAddress' && label.startsWith(SWITCHBOARD_PREFIX)) {
          label = label.replace(SWITCHBOARD_PREFIX, '');
        }

        let addressNumber = '';
        if (
          element.alias === 'IVRService' ||
          element.alias === 'ACDGroupAddress' ||
          element.alias === 'GroupAddress' ||
          element.alias === 'SpeedDial' ||
          element.alias === 'IUser'
        ) {
          phoneNumbers = (
            [
              ...(element.publicInfo.plmnNumbers || []),
              ...(element.publicInfo.pstnNumbers || [])
            ] || []
          ).join(', ');

          addressNumber = element.publicInfo.addressNumber;
        }
        if (element.publicInfo.firstName) {
          firstName = element.publicInfo.firstName;
        }
        if (element.privateInfo && element.privateInfo.firstName) {
          firstName = element.privateInfo.firstName;
        }
        let lastName = '';
        if (element.publicInfo.lastName) {
          lastName = element.publicInfo.lastName;
        }
        if (element.privateInfo && element.privateInfo.lastName) {
          lastName = element.privateInfo.lastName;
        }
        const avatar = element.publicInfo.locked ? (
          <LockIcon className={styles['lock-icon']} />
        ) : (
          getAvatar(element, fullName, availability)
        );

        return {
          id: element.id,
          rowId: element.id,
          avatar,
          title,
          firstName,
          lastName,
          phoneNumbers,
          addressNumber,
          emails,
          label,
          searchTerm: values.searchField,
          extraLine: (
            <SearchMatchRow matchings={element.matchings || {}} rowId={element.id || ''} />
          ),
          availability: element.alias === 'IUser' ? availability.text : '',
          rowIndex: index,
          rowSelector: externalUsersSelected ? (
            <Checkbox
              id={`extuser_check_${element.internalAddressId}`}
              value={selectedIds.includes(element.internalAddressId)}
              checked={selectedIds.includes(element.internalAddressId)}
              onChange={() => {
                if (selectedIds.includes(element.internalAddressId)) {
                  setSelectedIds(
                    selectedIds.filter(item => item !== element.internalAddressId) ?? []
                  );
                } else {
                  setSelectedIds(() => [...selectedIds, element.internalAddressId]);
                }
              }}
            />
          ) : null
        };
      }
    );
    let numberOfUsersText = '';
    if (!isLoadingUsers) {
      if (numberOfSearchedUsers !== -1) {
        numberOfUsersText = t('users.numberOfUsersSearched', {
          numberOfResults: numberOfSearchedUsers,
          visibleOfResults: data.length,
          numberOfUsers
        });
      } else {
        numberOfUsersText = t('users.numberOfUsers', {
          visibleOfResults: data.length,
          numberOfUsers
        });
      }
    }
    return (
      <div>
        {deleteDialogOpen && renderDeleteDialog()}
        {numberOfUsersText}
        {externalUsersSelected && (
          <div className={styles['bulk-delete-button-container']}>
            <ActionButton
              id="bulk-delete-button"
              label={t('users.bulkDelete', {
                numberOfUsers: `${selectedIds.length}`
              })}
              onClickAction={confirmRemoveExternalUser}
              image={<TrashBinIcon alt="Bulk delete" />}
            />
          </div>
        )}
        <div className={styles['large-table']}>
          <Button
            id="users-column-selector-button"
            size="m"
            color="light"
            className={styles['settings-button']}
            icon={<IconSettingsRegular />}
            onClick={() => setShowColumnSelector(true)}
          >
            <span ref={columnSelectorRef}>{t('users.tableSettings')}</span>
          </Button>
          {showColumnSelector && (
            <ColumnSelector
              onClose={() => setShowColumnSelector(false)}
              showColumnSelector={showColumnSelector}
              anchorElementRef={columnSelectorRef}
              startImportUsers={startImportUsers}
            />
          )}
          <Table
            id="usersTable"
            // $FlowFixMe
            items={items}
            columns={columns}
            onSort={handleTableSorted}
            allowLoadMore={!isLoadingUsers && hasMoreResults}
            onInfiniteScrollLoadMore={page => {
              if (!isLoadingUsers && hasMoreResults) {
                setPageToSearch(page);
              }
            }}
            onSelectRow={onSelectRow}
            sort={sort}
            leftCornerElement={
              externalUsersSelected ? (
                <div id="select-all-button-container">
                  <Checkbox id="checkall" onChange={selectOrDeselect} />
                </div>
              ) : (
                <div />
              )
            }
          />
        </div>
        <div className={styles['small-table']}>
          <SmallUserTable
            id="smallUsersTable"
            // $FlowFixMe
            items={items}
            allowLoadMore={!isLoadingUsers && hasMoreResults}
            onInfiniteScrollLoadMore={page => {
              if (!isLoadingUsers && hasMoreResults) {
                setPageToSearch(page);
              }
            }}
            onSelectRow={onSelectRow}
          />
        </div>
      </div>
    );
  };

  let elementAfterTable = null;
  if (hasLoadingError) {
    return <GenericError message={t('users.userListFailedMsg')} />;
  }

  const renderNoResults = (
    <div className={styles['no-search-results-container']}>
      <NoSearchResultsPic />
      <h2>{t(`users.searchNoResults.${isServicesTable ? 'services' : 'users'}`)}</h2>
    </div>
  );

  if (!isLoadingUsers && data.length === 0) {
    elementAfterTable = renderNoResults;
  } else if (isLoadingUsers) {
    elementAfterTable = (
      <CenterHorizontally>
        <LoadingSpinner />
      </CenterHorizontally>
    );
  }
  return (
    <div>
      {renderTable()}
      {elementAfterTable}
    </div>
  );
};

export default UsersOrServicesTable;
