// @flow

import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router-for-react18';
import type { StoreStateT } from '../commonTypes';
import currentUser from './currentUser';
import ui from './ui';
import entities from './entities';
import config from './config';

const reducers = (history: *) =>
  combineReducers<StoreStateT, *>({
    router: connectRouter(history),
    form: reduxFormReducer,
    currentUser,
    ui,
    entities,
    config
  });

export default reducers;
