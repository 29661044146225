// @flow

import React, { type Element, useEffect, useState } from 'react';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PresenceCalendarListing from '../../../../../components/PresenceCalendarListing/PresenceCalendarListing';
import CurrentPresenceState from '../../../../../components/CurrentPresenceState/CurrentPresenceState';
import PresenceCalendar from '../../../../../components/PresenceCalendar/PresenceCalendar';
import { pushAnalyticsEvent } from '../../../../../utils/analyticsHandler';
import { SHOW_USER_PRESENCE_DETAILS } from '../../../../../matomo_constants';
import styles from './PresenceDetails.module.scss';

export type PropsT = {
  userId: string,
  reloadFunc: () => void
};

const PresenceDetails = ({ userId, reloadFunc }: PropsT): Element<'div'> => {
  const [selectedDate, setSelectedDate] = useState<Moment>(Moment());
  const currentUser = useSelector(state => state.currentUser);
  const { id: enterpriseId } = useParams();

  useEffect(() => {
    pushAnalyticsEvent(
      SHOW_USER_PRESENCE_DETAILS,
      ((currentUser && currentUser.featureFlags) || []).includes('FEATURE-MATOMO-DEBUG')
        ? `${enterpriseId}_${currentUser.id}`
        : enterpriseId
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.row}>
      <div className={styles['left-area']}>
        <CurrentPresenceState userId={userId} reloadFunc={reloadFunc} />
        <PresenceCalendar
          selectedDate={selectedDate.toDate()}
          selectDate={date => setSelectedDate(Moment(date))}
        />
      </div>
      <PresenceCalendarListing
        userId={userId}
        selectedDate={selectedDate}
        reloadFunc={reloadFunc}
      />
    </div>
  );
};
export default PresenceDetails;
