// @flow

import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import HTTP from 'http-status-codes';
import axios, { type Axios } from 'axios';
import type { Store } from 'redux';
import { push } from 'connected-react-router-for-react18';
import { actionCreators as action } from './ducks/currentUser';
import reduxStore from './store';
import { createRedirectParam, isBrowserRunningE2eTests } from './helpers';
import { pushAnalyticsEvent } from './utils/analyticsHandler';
import {
  INTERCEPTOR_RECEIVED_FORBIDDEN,
  INTERCEPTOR_RECEIVED_UNAUTHORIZED
} from './matomo_constants';

export const HEADER_TRANSACTION_ID = 'X-Transaction-Id';
const SENTRY_TAG_TRANSACTION_ID = 'transaction_id';

export const ignoredPaths = [
  '/api/v1/restore',
  '/api/v1/validatetoken',
  '/api/v1/2stepauth/validate'
];
type IgnoredFnT = (?string) => boolean;
const ignored: IgnoredFnT = url =>
  url ? ignoredPaths.some(ignoredPattern => url.includes(ignoredPattern)) : false;

const interceptors = (store: Store<*, *> = reduxStore): Axios => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (
        error.response &&
        error.response.status === HTTP.UNAUTHORIZED &&
        !ignored(R.path(['config', 'url'], error.response)) &&
        !isBrowserRunningE2eTests()
      ) {
        pushAnalyticsEvent(INTERCEPTOR_RECEIVED_UNAUTHORIZED);
        store.dispatch(action.createLogoutSuccess());
        if (window.location.pathname.includes('/phonebook')) {
          store.dispatch(push(`/phonebook/login`));
        } else {
          store.dispatch(push(`/login${createRedirectParam()}`));
        }
      } else if (
        error.response &&
        error.response.status === HTTP.FORBIDDEN &&
        window.location.pathname.includes('/enterprises/') &&
        !isBrowserRunningE2eTests()
      ) {
        pushAnalyticsEvent(INTERCEPTOR_RECEIVED_FORBIDDEN);
        store.dispatch(push('/no-permission'));
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    config => {
      const transactionId = Math.random()
        .toString(36)
        .substr(2, 9);
      Sentry.configureScope(scope => scope.setTag(SENTRY_TAG_TRANSACTION_ID, transactionId));
      // $FlowFixMe
      return {
        ...config,
        headers: {
          ...config.headers,
          [HEADER_TRANSACTION_ID]: transactionId
        }
      };
    },
    error => Promise.reject(error)
  );
  return axios;
};

export default interceptors;
