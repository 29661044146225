import React from 'react';
import IconContainer from '../IconContainer';

export default function IconChatFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.438 22.596c.135.326.057.762-.218 1.06a1.06 1.06 0 0 1-1.088.299l-3.464-1.36a7.74 7.74 0 0 1-6.961-.737A6.71 6.71 0 0 1 8.5 16.259c0-3.669 3.549-6.76 7.75-6.76S24 12.59 24 16.249a5.65 5.65 0 0 1-1.327 3.654zM16.25 8.249c.541 0 1.101.051 1.665.152.138.026.295-.053.409-.147a.6.6 0 0 0 .18-.422C18.504 3.513 14.354 0 9.252 0S0 3.513 0 7.838a7.22 7.22 0 0 0 2.117 4.992v.001L.611 15.498c-.209.368-.165.833.113 1.156a.99.99 0 0 0 1.129.276l4.27-1.729h.001c.21.063.417.119.645.173a.5.5 0 0 0 .606-.393c.729-3.839 4.544-6.732 8.875-6.732"/></svg>)}
    />
  );
}
  