// @flow

import axios, { type CancelToken, type AxiosPromise } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import type { UpdateExtensionGroupActionT } from '../callFlow/callFlowTypes';
import {
  createUpdateExtensionGroupCancel,
  createUpdateExtensionGroupFailure,
  createUpdateExtensionGroupRequest,
  createUpdateExtensionGroupSuccess
} from './extensionGroupActions';
import type { RingPatternT, UpdatePropertyT } from '../callFlow/commonCallFlowTypes';
import type { InternalUserStateEntityT } from '../user/userTypes';

export type ExtensionGroupUpdateT = {
  ringPattern?: RingPatternT,
  memberIds?: string[],
  maxCycles?: number,
  cyclicRingingTime?: number,
  active?: boolean,
  label?: string,
  // members field is used at CF reducer because extension group patch does not return correct values
  members?: InternalUserStateEntityT[],
  ivrProperties?: {
    CallQueuingService: {
      ServiceSpecificWaitTime: boolean,
      WaitTime: number,
      QueueSize: number,
      MusicOnWait: UpdatePropertyT<string>
    }
  }
};

export type UpdateExtensionGroupAgentT = (
  string,
  string,
  ExtensionGroupUpdateT,
  CancelToken,
  headers: {}
) => ThunkActionT<UpdateExtensionGroupActionT>;

export const updateExtensionGroup: UpdateExtensionGroupAgentT = (
  enterpriseId,
  extensionGroupId,
  extensionGroup,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createUpdateExtensionGroupRequest(enterpriseId, extensionGroupId));
  try {
    const response: AxiosPromise<typeof extensionGroup> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/services/groups/${extensionGroupId}`,
      cancelToken,
      data: extensionGroup,
      headers
    });
    const { data } = await response;
    dispatch(createUpdateExtensionGroupSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateExtensionGroupCancel(enterpriseId, extensionGroupId));
    } else {
      dispatch(createUpdateExtensionGroupFailure(enterpriseId, extensionGroupId, error));
    }
    return undefined;
  }
};

const extensionGroupOperations = {
  updateExtensionGroup
};

export default extensionGroupOperations;
