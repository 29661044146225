import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAuthorizationRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.685 13.462a6 6 0 0 0-8.474.087 5.96 5.96 0 0 0-1.711 4.2.75.75 0 0 1-.749.751H.75a.75.75 0 0 1-.75-.749 7.45 7.45 0 0 1 2.14-5.252c2.889-2.951 7.644-3 10.594-.109a.751.751 0 0 1-1.049 1.072m4.686 4.667a1.504 1.504 0 0 1-.001 2.123 1.5 1.5 0 0 1-1.062.439c-.383 0-.767-.145-1.061-.439a1.506 1.506 0 0 1-.003-2.12 1.506 1.506 0 0 1 2.127-.003m6.932-3.574-3.356 3.356c.277.998.226 2.06-.157 3.035a4.817 4.817 0 0 1-6.233 2.723 4.77 4.77 0 0 1-2.653-2.551 4.77 4.77 0 0 1-.071-3.679 4.82 4.82 0 0 1 5.755-2.882l3.356-3.36A2.376 2.376 0 0 1 24 12.876c0 .635-.247 1.231-.697 1.679m-.803-1.679a.876.876 0 0 0-1.495-.618l-3.683 3.688a.75.75 0 0 1-.804.168 3.314 3.314 0 0 0-4.289 1.873 3.28 3.28 0 0 0 .049 2.53 3.28 3.28 0 0 0 1.825 1.756 3.316 3.316 0 0 0 4.289-1.873 3.3 3.3 0 0 0 .011-2.384.75.75 0 0 1 .154-.836l3.687-3.686a.87.87 0 0 0 .256-.618M2.75 4.625C2.75 2.075 4.825 0 7.375 0S12 2.075 12 4.625 9.925 9.25 7.375 9.25 2.75 7.175 2.75 4.625m1.5 0c0 1.723 1.402 3.125 3.125 3.125S10.5 6.348 10.5 4.625 9.098 1.5 7.375 1.5 4.25 2.902 4.25 4.625"/></svg>)}
    />
  );
}
  