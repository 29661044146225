import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPukFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 15.649V14.5c0-2.068-1.683-3.75-3.75-3.75s-3.75 1.682-3.75 3.75v1.149A2 2 0 0 0 13 17.5V22c0 1.103.897 2 2 2h6c1.103 0 2-.897 2-2v-4.5a2 2 0 0 0-1.25-1.851M18 12.25c1.24 0 2.25 1.009 2.25 2.25v1h-4.5v-1c0-1.241 1.01-2.25 2.25-2.25M21 1H3a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h9.994c.26 0 .492-.142.634-.359.936-1.433 2.537-2.391 4.372-2.391 1.425 0 2.71.58 3.662 1.5.541.522 1.338.233 1.338-.75V3a2 2 0 0 0-2-2M6 7.75c-.689 0-1.25-.561-1.25-1.25a1.25 1.25 0 0 1 1.244-1.25H6c.689 0 1.25.561 1.25 1.25S6.689 7.75 6 7.75m6 0c-.689 0-1.25-.561-1.25-1.25a1.25 1.25 0 0 1 1.244-1.25H12c.689 0 1.25.561 1.25 1.25S12.689 7.75 12 7.75m6 0a1.252 1.252 0 0 1-.888-2.132c.234-.236.548-.366.882-.368H18c.689 0 1.25.561 1.25 1.25S18.689 7.75 18 7.75"/></svg>)}
    />
  );
}
  