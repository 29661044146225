// @flow

import * as R from 'ramda';
import type { StateT, ActionT } from './departmentUiTypes';

export const initialState: StateT = {
  enterprises: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: StateT = initialState, action: ActionT): StateT => {
  switch (action.type) {
    case 'ring/ui/department/SELECT_USER': {
      const { enterpriseId, departmentId, selectedUser } = action.payload;
      const enterpriseState =
        enterpriseId in state.enterprises ? state.enterprises[enterpriseId] : {};
      const departmentState = departmentId in enterpriseState ? enterpriseState[departmentId] : [];
      return {
        ...state,
        enterprises: {
          ...state.enterprises,
          [enterpriseId]: {
            ...enterpriseState,
            [departmentId]: R.uniq([...departmentState, selectedUser])
          }
        }
      };
    }
    case 'ring/ui/department/SELECT_USERS': {
      const { enterpriseId, departmentId, selectedUsers } = action.payload;
      const enterpriseState =
        enterpriseId in state.enterprises ? state.enterprises[enterpriseId] : {};
      return {
        ...state,
        enterprises: {
          ...state.enterprises,
          [enterpriseId]: {
            ...enterpriseState,
            [departmentId]: selectedUsers
          }
        }
      };
    }
    case 'ring/ui/department/DESELECT_USER': {
      const { enterpriseId, departmentId, selectedUser } = action.payload;
      const enterpriseState =
        enterpriseId in state.enterprises ? state.enterprises[enterpriseId] : {};
      const departmentState = departmentId in enterpriseState ? enterpriseState[departmentId] : [];
      return {
        ...state,
        enterprises: {
          ...state.enterprises,
          [enterpriseId]: {
            ...enterpriseState,
            [departmentId]: R.reject(
              user =>
                // $FlowFixMe: internalUser
                selectedUser.extensionId
                  ? // $FlowFixMe: internalUser
                    user.extensionId === selectedUser.extensionId
                  : // $FlowFixMe: directory
                    user.id === selectedUser.id,
              departmentState
            )
          }
        }
      };
    }
    case 'ring/ui/department/CLEAR_USERS': {
      const { enterpriseId, departmentId } = action.payload;
      const enterpriseState =
        enterpriseId in state.enterprises ? state.enterprises[enterpriseId] : {};
      return {
        ...state,
        enterprises: {
          ...state.enterprises,
          [enterpriseId]: {
            ...enterpriseState,
            [departmentId]: []
          }
        }
      };
    }
    default:
      return state;
  }
};
