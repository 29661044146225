// @flow
import * as R from 'ramda';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import type {
  CallForwardingDestinationT,
  CallForwardingDestinationTypeT,
  CallForwardingT,
  CreateCallForwardingPayloadT,
  CallForwardingTypeT
} from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import type {
  CategoryOptionT,
  DestinationFieldT
} from '../../../components/edit/children/destinationField/DestinationFieldUtils';

export const getActiveForwardingsByType = (
  forwardings: CallForwardingT[],
  forwardingType: CallForwardingTypeT
) =>
  R.filter(
    (fwd: CallForwardingT) => fwd.forwardingType === forwardingType && !!fwd.isActive,
    forwardings || []
  );

export const getActiveCallQueuingServices = (forwardings: CallForwardingT[]) =>
  R.filter(
    (fwd: CallForwardingT) => !!fwd.isActive && fwd.destination.type === 'CALL_QUEUING_SERVICE',
    forwardings || []
  );

export const hasMultipleTargetTypes = (activeForwardings: CallForwardingT[]) => {
  const targetTypeList = activeForwardings.map(
    fwd => R.path(['destination', 'value'], fwd) || R.path(['destination', 'type'], fwd)
  );
  const targetTypes = new Set(targetTypeList);

  return targetTypes && targetTypes.size > 1;
};

export const getFwdTargetDestination = (
  forwardings: CallForwardingT[],
  type: CallForwardingTypeT
): ?CallForwardingDestinationT => {
  const fwds = getActiveForwardingsByType(forwardings, type);
  return fwds.length === 1 ? R.path([0, 'destination'], fwds) : null;
};

export const getForwardingTargetName = (
  target: ?CallForwardingDestinationT,
  extGroupData: ExtensionGroupEntityT
): string => {
  const targetNumber = target ? target.value || '' : '';
  if (target) {
    const targetName =
      R.path(['relatedNumbers', targetNumber, 'userName'], extGroupData) ||
      R.path(['relatedNumbers', targetNumber, 'label'], extGroupData) ||
      R.path(['relatedNumbers', targetNumber, 'alias'], extGroupData);
    if (targetName) {
      return `${targetName} (${targetNumber})`;
    }
  }
  if (target && target.type === 'EXTERNAL') {
    return target.value || '';
  }
  if (target && target.type) {
    return target.type;
  }
  return '';
};

export const convertFormDestinationTypeToFwdDestinationType = (
  dest: CategoryOptionT
): CallForwardingDestinationTypeT => {
  const mapping = {
    NOT_IN_USE: '',
    NO_TRANSFER: 'REJECTION',
    TRANSFER_INTERNAL: 'INTERNAL',
    TRANSFER_EXTERNAL: 'EXTERNAL',
    TRANSFER_ENT_VM: 'ENT_VM',
    TRANSFER_CALL_QUEUING_SERVICE: 'CALL_QUEUING_SERVICE'
  };
  return mapping[dest] || 'INVALID';
};

export const DEFAULT_NO_ANSWER_FORWARDING_DELAY = 45;

export const createForwardingPayload = (
  destination: ?DestinationFieldT,
  fwdType: CallForwardingTypeT,
  fwds: *
): ?CreateCallForwardingPayloadT => {
  if (!destination || !destination.type) {
    return null;
  }

  const labels = {
    ON_BUSY: 'When all members are busy',
    NO_ANSWER: 'When members do not answer',
    UNREACHABLE: 'When all members are unreachable'
  };
  const destinationType = convertFormDestinationTypeToFwdDestinationType(destination.type);
  return {
    label: R.path([fwdType], labels) || 'Auto-generated',
    destination:
      destinationType !== 'INVALID'
        ? {
            type: destinationType,
            value: destination.value || ''
          }
        : {
            type: '',
            value: ''
          },
    filter: 'ALL_CALLS',
    isActive: true,
    presenceStateFilter: [],
    isEditable: true,
    forwardingType: fwdType,
    noAnswerForwardingDelay:
      fwds && fwds.length === 1 && fwds[0].noAnswerForwardingDelay
        ? fwds[0].noAnswerForwardingDelay
        : DEFAULT_NO_ANSWER_FORWARDING_DELAY * 1000
  };
};

export const hasInvalidForwardingConfiguration = (forwardings: CallForwardingT[]) => {
  const onBusyForwardings = getActiveForwardingsByType(forwardings, 'ON_BUSY');
  const onNoAnswerForwardings = getActiveForwardingsByType(forwardings, 'NO_ANSWER');
  const onUnreachableForwardings = getActiveForwardingsByType(forwardings, 'UNREACHABLE');

  return (
    onBusyForwardings.length > 1 ||
    onNoAnswerForwardings.length > 1 ||
    onUnreachableForwardings.length > 1
  );
};

export const hasQueueEnabled = (forwardingsOnService: CallForwardingT[]) => {
  return (
    forwardingsOnService &&
    forwardingsOnService.some(forwarding => forwarding.destination.type === 'CALL_QUEUING_SERVICE')
  );
};
