import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMoneyLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.1 7.2c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9M16.9 12c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9m4.1 3V6c0-1.1-.9-2-2-2H2C.9 4 0 4.9 0 6v9c0 1.1.9 2 2 2h17c1.1 0 2-.9 2-2M1 15V6c0-.6.4-1 1-1h17c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1m9.5-8C8.6 7 7 8.6 7 10.5S8.6 14 10.5 14s3.5-1.6 3.5-3.5S12.4 7 10.5 7m0 6C9.1 13 8 11.9 8 10.5S9.1 8 10.5 8 13 9.1 13 10.5 11.9 13 10.5 13M24 9.5V18c0 1.1-.9 2-2 2H5.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H22c.6 0 1-.4 1-1V9.5c0-.3.2-.5.5-.5s.5.2.5.5"/></svg>)}
    />
  );
}
  