import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBankFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.932 1.573a2.05 2.05 0 0 1 2.207 0l9.617 5.748a.5.5 0 0 1-.256.929h-21a.5.5 0 0 1-.255-.93zM.75 22.25a.75.75 0 0 1 .75-.75h21a.75.75 0 1 1 0 1.5h-21a.75.75 0 0 1-.75-.75M17.5 10a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-6.75 0a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-7 0a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"/></svg>)}
    />
  );
}
  