// @flow strict

import { useState, useEffect, useRef } from 'react';

const DELAY_MS = 500;

const useLoadingIndicator = (isLoading: boolean, delay: number = DELAY_MS) => {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const timeoutId = useRef(null);

  useEffect(() => {
    if (isLoading) {
      timeoutId.current = setTimeout(() => {
        setShowLoadingIndicator(true);
      }, delay);
    } else {
      clearTimeout(timeoutId.current);
      setShowLoadingIndicator(false);
    }
  }, [delay, isLoading]);

  return showLoadingIndicator;
};

export default useLoadingIndicator;
