import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBankRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M13.3 1.4c-.4-.3-.8-.4-1.3-.4-.4 0-.9.1-1.2.4L1.1 7.1c-.1.1-.2.2-.3.4s-.1.3 0 .5c0 .2.1.3.3.4.1.1.3.2.5.2h21c.2 0 .3-.1.5-.2.1-.1.2-.2.3-.4v-.5c-.1-.2-.2-.3-.3-.4zm-.8 1.2c-.1-.1-.3-.1-.4-.1-.2 0-.3.1-.4.1L4.2 7h15.5zM.8 22.2c0-.2.1-.4.2-.5s.3-.2.5-.2h21c.2 0 .4.1.5.2s.2.3.2.5-.1.4-.2.5-.3.2-.5.2h-21c-.2 0-.4-.1-.5-.2-.2-.1-.2-.3-.2-.5m3.5-11.7c0-.2-.1-.4-.2-.5-.2-.2-.4-.2-.6-.2s-.4 0-.5.2c-.1.1-.2.3-.2.5v9c0 .4.3.8.8.8h3c.2 0 .4-.1.5-.2s.2-.3.2-.5v-9c0-.2-.1-.4-.2-.5-.2-.3-.4-.3-.6-.3s-.4 0-.5.2c-.1.1-.2.3-.2.5v8.2H4.3zm6.2-.7c.2 0 .4.1.5.2s.2.3.2.5v8.2h1.5v-8.2c0-.2.1-.4.2-.5s.3-.2.5-.2.5 0 .6.2c.1.1.2.3.2.5v9c0 .2-.1.4-.2.5s-.3.2-.5.2h-3c-.2 0-.4-.1-.5-.2s-.2-.3-.2-.5v-9c0-.2.1-.4.2-.5.1-.2.3-.2.5-.2m7.7.7c0-.2-.1-.4-.2-.5s-.3-.2-.5-.2-.4 0-.5.2c-.1.1-.2.3-.2.5v9c0 .4.3.8.8.8h3c.2 0 .4-.1.5-.2s.2-.3.2-.5v-9c0-.2-.1-.4-.2-.5s-.3-.2-.5-.2-.5-.1-.6.1c-.1.1-.2.3-.2.5v8.2h-1.5v-8.2z"/></svg>)}
    />
  );
}
  