// @flow
import type { Element } from 'react';
import React from 'react';
import * as R from 'ramda';
import { Field } from 'redux-form';
import FormInput from '../../../components/InputComponent/FormInput';
import styles from './ExternalUser/ExternalUserDetails.module.scss';
import KeywordInput from '../../../components/InputComponent/KeywordInput';
import { DUTIES_MAX_LENGTH } from './FormHelpers';

export type OwnPropsT = {
  id: string,
  title: string,
  form: string,
  formValueParameter: string,
  validate: string => ?string,
  changeFormValue: *,
  isSectionInEditMode: boolean
};

export type PropsT = OwnPropsT;

export const KeywordInputField = (props: PropsT) => {
  return (
    <Field
      id={props.id}
      key={props.id}
      label={props.title}
      name={props.id}
      isSectionInEditMode={props.isSectionInEditMode}
      component={(componentProps: *): Element<'div'> => {
        const mapIndexed = R.addIndex(R.map);
        const keywords = R.filter(
          entry => !!entry.text,
          mapIndexed(
            (tag, index) => ({ id: `${tag}-${index}`, text: tag }),
            (componentProps.input.value || '').split(',')
          ) || []
        );
        return !componentProps.isSectionInEditMode ? (
          // $FlowFixMe
          <FormInput
            id="keyword-form-input"
            {...{
              ...componentProps,
              input: {
                ...componentProps.input,
                value:
                  componentProps.input.value && componentProps.input.value.split(',').join(', ')
              }
            }}
          />
        ) : (
          <div className={styles['input-field']}>
            <label className="ds-input--labelarea-label">{props.title}</label>
            <KeywordInput
              placeholder={props.title}
              initialValues={keywords}
              maxLength={DUTIES_MAX_LENGTH}
              onChange={keywordList => {
                props.changeFormValue(
                  props.form,
                  props.formValueParameter,
                  R.join(
                    ',',
                    keywordList.map(entry => entry.text)
                  )
                );
              }}
            />
          </div>
        );
      }}
      validate={props.validate}
    />
  );
};

export default KeywordInputField;
