// @flow

/* eslint-disable react/no-unused-state, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import * as R from 'ramda';
import React, { type Element, useEffect, useRef, useState } from 'react';
import { CancelToken } from 'axios';
import type { Canceler } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Combobox from '@design-system/component-library/src/components/Combobox';
import ConfirmButton from '../../../components/Button/ConfirmButton';
import CreateDepartmentModal from '../DepartmentDetails/CreateDepartmentModal';
import DepartmentDetails from '../DepartmentDetails/DepartmentDetails';
import DepartmentTreeBrowser from '../DepartmentTree/DepartmentTreeBrowser';
import ContentFetchError from '../../../components/Error/ContentFetchError';
import ErrorBoundary from '../../../components/Error/ErrorBoundary';
import type { DepartmentStateEntityT } from '../../../ducks/entities/department';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import * as departmentOps from '../../../ducks/entities/department/departmentOperations';
import { goToDepartment, goToEnterprise } from '../../../navigationOperations';
import * as selectDepartments from '../../../ducks/entities/department/departmentSelectors';
import { DepartmentSearchResult } from '../DepartmentSearchResult';
import styles from './Departments.module.scss';

const Departments = (): Element<'div'> => {
  const location = useLocation();
  const activeDepartmentDetailsMode = R.pathOr('hidden', ['state', 'detailsMode'], location);
  const departmentPath = R.pathOr([], ['state', 'path'], location);
  const currentUser = useSelector(state => state.currentUser);
  const { id: enterpriseId, departmentId } = useParams();
  const enterpriseDepartmentTree = useSelector(state =>
    selectDepartments.departmentTreeByEnterpriseId(state, enterpriseId)
  );
  const [searchResults, setSearchResults] = useState([]);
  const [createOpen, setCreateOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [departmentTreeKey, setDepartmentTreeKey] = useState(0);
  const [detailsMode, setDetailsMode] = useState('hidden');
  const [departmentDetailsId, setDepartmentDetailsId] = useState('');
  const [createDepartmentForId, setCreateDepartmentForId] = useState();
  const isLoadingDepartments = useSelector(state => selectDepartments.collectionIsLoading(state));
  const departmentsHasError = useSelector(state => selectDepartments.hasError(state));
  const prevDepartmentPath = useRef(departmentPath);
  const prevEnterpriseDepartmentTree = useRef(enterpriseDepartmentTree);
  const hasEnterpriseDepartment = useSelector(state =>
    selectDepartments.hasDepartment(state, enterpriseId, departmentId)
  );
  const showDepartmentLoadingSpinner = useSelector(state =>
    selectDepartments.collectionIsLoading(state)
  );
  const enterpriseHasDepartments = useSelector(
    state => selectDepartments.departmentTreeByEnterpriseId(state, enterpriseId).length > 0
  );
  const getUserCountForDepartmentCancelTokenSource = React.useRef<Canceler>();
  const getDepartmentRequestCancelTokenSource = React.useRef<Canceler>();
  const [departmentTabSelected] = useState(
    location.state ? location.state.departmentTabSelected : false
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateDepartmentPeopleCounts = (dPath: string[]) => {
    if (enterpriseId && dPath.length > 0) {
      // update all people counts in the path...
      Promise.all(
        // $FlowFixMe: compose missing in types
        R.compose(
          R.map(id =>
            dispatch(
              departmentOps.retrieveUserCountForDepartment(
                enterpriseId,
                id,
                new CancelToken(canceler => {
                  getUserCountForDepartmentCancelTokenSource.current = canceler;
                })
              )
            )
          ),
          R.reverse // ...starting with the leaf
        )(dPath)
      );
    }
  };

  const handleSelectDepartment = (selectedResultIndex: number, result?: *[]) => {
    const selectedEnterpriseId = enterpriseId || '';
    const selectedResult = result || searchResults[selectedResultIndex];

    dispatch(
      goToDepartment(selectedEnterpriseId, selectedResult[selectedResult.length - 1], {
        detailsMode: 'hidden',
        path: selectedResult,
        renderedTreeDepth: {
          current: selectedResult.length - 1,
          direction: 'FORWARD'
        }
      })
    );
  };

  const searchDepartments = (nodes: DepartmentStateEntityT[], term: string, results) => {
    nodes.forEach(node => {
      if (node.name && node.name.toLowerCase().includes(term.toLowerCase())) {
        results.push({
          label: node.name,
          ariaLabel: node.name,
          value: node.path,
          html: (
            <DepartmentSearchResult
              path={node.path}
              onSelect={() => handleSelectDepartment(-1, node.path)}
            />
          )
        });
      }
      if (node.subDepartments) {
        searchDepartments(node.subDepartments, term, results);
      }
    });
    setSearchResults(R.reverse(R.sort((a, b) => b.length - a.length, results)));
  };

  const openDepartmentDetails = () => {
    if (departmentTabSelected) {
      setDetailsMode('modify');
      setDetailsOpen(true);
      setDepartmentDetailsId(departmentId);
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      if (enterpriseId) {
        await dispatch(
          departmentOps.retrieveCollection(
            enterpriseId,
            new CancelToken(canceler => {
              getDepartmentRequestCancelTokenSource.current = canceler;
            })
          )
        );
        const results = [];
        searchDepartments(enterpriseDepartmentTree, '', results);
      }
    };
    fetchDepartments();
    openDepartmentDetails();
    updateDepartmentPeopleCounts(departmentPath);
    return () => {
      if (getUserCountForDepartmentCancelTokenSource.current) {
        getUserCountForDepartmentCancelTokenSource.current();
      }
      if (getDepartmentRequestCancelTokenSource.current) {
        getDepartmentRequestCancelTokenSource.current();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!R.equals(prevDepartmentPath.current, departmentPath)) {
      updateDepartmentPeopleCounts(departmentPath);
      setDepartmentTreeKey(new Date().getTime());
      prevDepartmentPath.current = departmentPath;
    }
    if (
      JSON.stringify(prevEnterpriseDepartmentTree.current) !==
      JSON.stringify(enterpriseDepartmentTree)
    ) {
      prevEnterpriseDepartmentTree.current = enterpriseDepartmentTree;

      setDepartmentTreeKey(new Date().getTime());
      const results = [];
      searchDepartments(enterpriseDepartmentTree, '', results);
    }
    return () => {};
  }, [enterpriseDepartmentTree, departmentPath]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeDepartment = async (department: DepartmentStateEntityT) => {
    await dispatch(
      departmentOps.deleteDepartment(
        department.enterpriseId,
        department.id,
        createCsrfHeader(currentUser)
      )
    );
    const newPath = R.dropLast(1, department.path);
    const parentId = R.last(newPath);
    if (parentId) {
      dispatch(
        goToDepartment(department.enterpriseId, parentId, {
          detailsMode: 'hidden',
          path: newPath,
          renderedTreeDepth: {
            current: newPath.length,
            direction: 'BACKWARD'
          }
        })
      );
    } else {
      dispatch(goToEnterprise(department.enterpriseId));
    }
  };

  const handleOnCloseCreate = () => {
    setCreateOpen(false);
  };

  const handleDeleteDepartment = (department: DepartmentStateEntityT) => {
    removeDepartment(department);
    setDetailsOpen(false);
  };

  const showUsersWithoutDepartment = () => {
    setDetailsMode('people');
    setDetailsOpen(true);
    setDepartmentDetailsId('');
  };

  const handleOnCloseDetails = () => {
    updateDepartmentPeopleCounts(departmentPath);
    setDetailsMode('hidden');
    setDetailsOpen(false);
  };

  const openCreate = (event: *, forId: ?string): void => {
    const forDepartmentID = forId !== undefined ? forId : departmentId;
    setCreateOpen(true);
    setCreateDepartmentForId(forDepartmentID);
  };

  const openDetailsModify = (id: string): void => {
    setDetailsMode('modify');
    setDetailsOpen(true);
    setDepartmentDetailsId(id);
  };

  const openDetailsPeople = (id: string): void => {
    setDetailsMode('people');
    setDetailsOpen(true);
    setDepartmentDetailsId(id);
  };

  const renderDepartments = (): Element<
    'div' | typeof ContentFetchError | typeof ErrorBoundary
  > => {
    const departmentTreeButtons = (
      <div className={styles['button-row']} id="department-action-buttons">
        <ConfirmButton
          className={styles['box-button']}
          id="users-without-department-button"
          label={`${t('enterprise.userWithoutDepartment')}`}
          onClickAction={showUsersWithoutDepartment}
        />
        <ConfirmButton
          className={styles['box-button']}
          id="add-department-button"
          label={`${t('enterprise.createDepartmentButtonLabel')}`}
          onClickAction={openCreate}
        />
      </div>
    );
    if (!isLoadingDepartments) {
      if (departmentsHasError) {
        return <ContentFetchError message={t('enterprise.departmentsErrorMsg')} />;
      }
      return (
        <ErrorBoundary
          errorElement={<ContentFetchError message={t('enterprise.departmentsGenericErrorMsg')} />}
        >
          <div className={styles.search}>
            <Combobox
              label={t('enterprise.departmentSearchFieldPlaceholder')}
              id="searchDepartment"
              name="searchDepartment"
              items={searchResults}
              optional
              i18n_combobox_optionalText=""
              onValueSelect={element => {
                handleSelectDepartment(-1, element.dataset.value.split(','));
              }}
              className={styles['search-input']}
            />
          </div>
          <div>
            <DepartmentTreeBrowser
              enterpriseId={enterpriseId || ''}
              activeDepartment={hasEnterpriseDepartment ? departmentId : null}
              activeDepartmentDetailsMode={activeDepartmentDetailsMode}
              departments={enterpriseDepartmentTree}
              deleteDepartment={removeDepartment}
              key={departmentTreeKey}
              onCreateDepartment={openCreate}
              onOpenDetailsModify={openDetailsModify}
              onOpenDetailsPeople={openDetailsPeople}
            />
            {departmentTreeButtons}
          </div>
        </ErrorBoundary>
      );
    }
    if (showDepartmentLoadingSpinner && !enterpriseHasDepartments) {
      return (
        <div className={styles.spinner}>
          <CenterHorizontally>
            <LoadingSpinner />
          </CenterHorizontally>
        </div>
      );
    }
    return <div />;
  };

  const title = <h4 className={styles['section-title']}>{t('enterprise.departments')}</h4>;
  const departmentDetails = (
    <DepartmentDetails
      departmentId={departmentDetailsId}
      enterpriseId={enterpriseId || ''}
      onClose={handleOnCloseDetails}
      activeTab={detailsMode}
      removeDepartment={handleDeleteDepartment}
    />
  );
  const createDepartment = (
    <CreateDepartmentModal
      enterpriseId={enterpriseId}
      onClose={handleOnCloseCreate}
      initialParentDepartmentId={createDepartmentForId}
    />
  );

  return (
    <div>
      {title}
      {renderDepartments()}
      {detailsOpen && departmentDetails}
      {createOpen && createDepartment}
    </div>
  );
};

export default Departments;
