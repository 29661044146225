import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLikeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 22.5a.75.75 0 0 1-.538-.227l-9.587-9.847A6.38 6.38 0 0 1 .679 5.037a6.44 6.44 0 0 1 4.716-3.455 6.44 6.44 0 0 1 5.568 1.792L12 4.407l1.037-1.032a6.46 6.46 0 0 1 5.569-1.792 6.44 6.44 0 0 1 4.715 3.456 6.38 6.38 0 0 1-1.203 7.393l-9.581 9.842A.75.75 0 0 1 12 22.5M6.419 3a4.93 4.93 0 0 0-4.398 2.711 4.88 4.88 0 0 0 .922 5.661L12 20.675l9.052-9.297a4.89 4.89 0 0 0 .93-5.667 4.94 4.94 0 0 0-3.615-2.648 4.95 4.95 0 0 0-4.271 1.374l-1.566 1.56a.75.75 0 0 1-1.059 0l-1.565-1.56A4.96 4.96 0 0 0 6.419 3"/></svg>)}
    />
  );
}
  