import React from 'react';
import IconContainer from '../IconContainer';

export default function IconReplyAllFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.251 17a.75.75 0 0 1 0 1.06.76.76 0 0 1-.51.2.75.75 0 0 1-.54-.26l-5.6-6a2.26 2.26 0 0 1 0-3.07L6.201 3a.75.75 0 0 1 1 0 .76.76 0 0 1 .05 1l-5.6 6a.76.76 0 0 0 0 1zM16 7h-2.25V4.52a2 2 0 0 0-2-2 1.8 1.8 0 0 0-1.37.64l-5.63 6a2 2 0 0 0 0 2.74l5.62 6c.349.383.833.616 1.35.65a1.87 1.87 0 0 0 1.4-.54c.399-.378.614-.911.59-1.46V14H16a7 7 0 0 1 6.75 5.13c.11.42.24.88.67.88h.08c.5-.07.5-.59.5-1V15a8 8 0 0 0-8-8"/></svg>)}
    />
  );
}
  