import React from 'react';
import IconContainer from '../IconContainer';

export default function IconViewFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.125 11.998v.003a3.13 3.13 0 0 1-3.124 3.124H12A3.13 3.13 0 0 1 8.875 12 3.13 3.13 0 0 1 12 8.875h.002a3.127 3.127 0 0 1 3.123 3.123m8.312 1.486C20.82 16.407 16.495 19.5 12.146 19.5h-.312c-4.333 0-8.655-3.093-11.27-6.014a2.243 2.243 0 0 1-.001-2.974C3.179 7.591 7.502 4.5 11.85 4.5h.297c4.346 0 8.671 3.091 11.289 6.01.752.849.752 2.127.001 2.974m-7.062-1.486a4.35 4.35 0 0 0-1.282-3.093 4.34 4.34 0 0 0-3.091-1.28H12a4.38 4.38 0 0 0-4.375 4.374 4.38 4.38 0 0 0 4.374 4.376h.002a4.35 4.35 0 0 0 3.092-1.28 4.35 4.35 0 0 0 1.282-3.094z"/></svg>)}
    />
  );
}
  