import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPrintRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" viewBox="0 0 24 24"><path d="M6.441 3.121c0-.443.367-.821.854-.821h7.15c.214 0 .414.074.577.214l.002.001 2.25 1.961.002.001c.18.16.28.385.28.61v.211a.65.65 0 1 0 1.3 0v-.211a2.12 2.12 0 0 0-.723-1.587l-.002-.002-2.256-1.965-.003-.003c-.398-.343-.9-.53-1.427-.53h-7.15c-1.177 0-2.154.932-2.154 2.121v2.177a.65.65 0 1 0 1.3 0zM2.3 8.946c0-.48.384-.861.854-.861h17.688c.47 0 .854.38.854.861v6.51a.856.856 0 0 1-.854.862h-1.977v-3.582a.65.65 0 0 0-.65-.65H5.791a.65.65 0 0 0-.65.65v9.604c0 .359.291.65.65.65h12.424a.65.65 0 0 0 .65-.65v-4.722h1.977a2.155 2.155 0 0 0 2.154-2.161v-6.51c0-1.193-.96-2.162-2.154-2.162H3.154C1.96 6.785 1 7.755 1 8.946v6.51c0 1.193.96 2.162 2.154 2.162H5.14v-1.3H3.154a.856.856 0 0 1-.854-.861zm4.141 4.44v8.304h11.124v-8.304zm2.098 1.632a.65.65 0 1 0 0 1.3h6.848a.65.65 0 0 0 0-1.3zm-.66 3.422a.65.65 0 0 1 .65-.65h4.773a.65.65 0 1 1 0 1.3H8.529a.65.65 0 0 1-.65-.65m-1.867-8.375c0 .557-.449 1.008-1.003 1.008a1.005 1.005 0 0 1-1.003-1.008c0-.557.45-1.008 1.003-1.008.554 0 1.003.451 1.003 1.008"/></svg>)}
    />
  );
}
  