import React from 'react';
import IconContainer from '../IconContainer';

export default function IconContinuousRecordingFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 4c.9 0 1.8.2 2.7.5C8.3 6.1 6.8 8.9 6.8 12s1.5 5.9 3.9 7.5c-.9.3-1.8.5-2.7.5-4.4 0-8-3.6-8-8s3.6-8 8-8m0 8c0-3 1.6-5.5 4-6.9 1.2-.7 2.5-1.1 4-1.1 4.4 0 8 3.6 8 8s-3.6 8-8 8c-1.5 0-2.8-.4-4-1.1C9.6 17.5 8 15 8 12"/></svg>)}
    />
  );
}
  