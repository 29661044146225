import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowLeftAltFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 12.002c0-.472.194-.931.534-1.258L7.38 4.103a1.245 1.245 0 0 1 1.767.027 1.25 1.25 0 0 1-.027 1.768L4.118 10.75H22.75a1.25 1.25 0 0 1 0 2.5H4.114l5.002 4.853a1.25 1.25 0 1 1-1.741 1.795L.531 13.259A1.76 1.76 0 0 1 0 12.002"/></svg>)}
    />
  );
}
  