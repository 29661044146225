import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAttachmentLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.807 22.747a6.78 6.78 0 0 1-4.823-2.004C.358 18.1.358 13.8 2.984 11.157L11.4 2.742c1.747-1.887 4.726-2.002 6.629-.24a4.706 4.706 0 0 1 .114 6.787l-8.514 8.518a2.606 2.606 0 0 1-3.682-.05 2.59 2.59 0 0 1-.001-3.621l8.424-8.422a.5.5 0 0 1 .707.707l-8.418 8.417c-.6.62-.6 1.599.006 2.224a1.605 1.605 0 0 0 2.263.034l8.621-8.63a3.703 3.703 0 0 0-.199-5.229 3.705 3.705 0 0 0-5.229.2l-8.429 8.428c-2.238 2.253-2.238 5.921.001 8.175a5.81 5.81 0 0 0 8.203.026L22.14 9.819a.5.5 0 0 1 .707.707L12.602 20.772a6.78 6.78 0 0 1-4.795 1.975"/></svg>)}
    />
  );
}
  