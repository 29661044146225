import React from 'react';
import IconContainer from '../IconContainer';

export default function IconElisaViihde(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M22 19H2c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h20c1.1 0 2 .9 2 2v13c0 1.1-.9 2-2 2M2 17.5c-.3 0-.5-.2-.5-.5V4c0-.3.2-.5.5-.5h20c.3 0 .5.2.5.5v13c0 .3-.2.5-.5.5zm14.8 3h.2c.3.1.5.4.5.7 0 .4-.3.8-.8.8H7.2c-.4 0-.8-.3-.8-.8 0-.3.2-.6.5-.7zm-.6-9.9c.4-.2.4-.7 0-.9L9.7 6.2c-.3-.1-.7.1-.7.5v7c0 .4.4.6.7.4z"/></svg>)}
    />
  );
}
  