// @flow
import React from 'react';

import GoBackLink from '../Button/GoBackLink';
import styles from './SubHeader.module.scss';

export type PropsT = {|
  returnLinkText: string,
  smallTitleText: string,
  bigTitleText: string,
  returnLinkAction: () => void
|};

// eslint-disable-next-line no-unused-vars
const SubHeader = (props: PropsT) => {
  const { smallTitleText, bigTitleText, returnLinkAction, returnLinkText } = props;

  return (
    <div className={styles['subheader-box']} id="header-box">
      <GoBackLink
        className={styles.link}
        id="header-link"
        text={returnLinkText}
        onClick={returnLinkAction}
      />
      <div className={styles['subheader-box-texts']}>
        <div data-cy="smallTitle" className={styles.small}>
          {smallTitleText}
        </div>
        <div data-cy="bigTitle" className={styles.big}>
          {bigTitleText}
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
