import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInformationRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15 16.5a.76.76 0 0 1-.75.75h-.75A2.25 2.25 0 0 1 11.25 15v-3.75h-.75a.75.75 0 0 1 0-1.5h.75a1.5 1.5 0 0 1 1.5 1.5V15a.76.76 0 0 0 .75.75h.75a.76.76 0 0 1 .75.75m-3.38-8.25a1.13 1.13 0 1 0-1.12-1.14v.01a1.12 1.12 0 0 0 1.11 1.13zM12 1.5c5.79 0 10.5 4.71 10.5 10.5S17.79 22.5 12 22.5 1.5 17.79 1.5 12 6.21 1.5 12 1.5M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0"/></svg>)}
    />
  );
}
  