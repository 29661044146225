// @flow strict-local

import React, { useState } from 'react';
import classNames from 'classnames';
import AudioFileDropzone from '../../../../../../components/AudioFileDropzone/AudioFileDropzone';
import AudioPlayerContainer from '../../../../../../components/AudioPlayer/AudioPlayerContainer';
import Dismiss from '../../../../../../components/Button/Dismiss';
import type { TranslateT } from '../../../../../../commonTypes';
import type { AudioT } from './CallflowAudioUtils';

import styles from './AudioFileSelection.module.scss';

type PropsT = {
  onChange: File => void,
  disabled: boolean,
  showInitialView: boolean,
  audio: AudioT,
  dismissAction?: (AudioT, boolean) => void,
  dropboxActiveStatus?: boolean,
  translateMock?: TranslateT<>,
  audioStyle?: string,
  audioFilename?: string
};

export function AudioFileSelection(props: PropsT) {
  const {
    onChange,
    disabled,
    showInitialView,
    audio,
    dismissAction,
    dropboxActiveStatus,
    translateMock,
    audioStyle,
    audioFilename
  } = props;
  const [dropboxActive, setDropboxActive] = useState(dropboxActiveStatus || false);

  const handleClose = closeAudioPlayer => {
    setDropboxActive(closeAudioPlayer);
    if (dismissAction) {
      dismissAction(audio, closeAudioPlayer);
    }
  };

  const audioPlayerView = (
    <div className={classNames(styles.container, audioStyle)}>
      <Dismiss
        id="audio-upload-close-player"
        data-cy="audio-upload-close-player"
        onClose={() => handleClose(true)}
        dismissStyle={styles['player-dismiss']}
      />
      <AudioPlayerContainer
        audio={audio}
        translateMock={translateMock}
        audioFilename={audioFilename}
      />
    </div>
  );

  const selectFileView = (
    <div className={classNames(styles.container, audioStyle)}>
      <Dismiss
        id="audio-upload-close-dropzone"
        onClose={() => handleClose(false)}
        dismissStyle={styles['dropzone-dismiss']}
      />
      <AudioFileDropzone
        onChange={onChange}
        disabled={disabled}
        {...(translateMock ? { translateMock } : {})}
      />
    </div>
  );
  return showInitialView && !dropboxActive ? audioPlayerView : selectFileView;
}

export default AudioFileSelection;
