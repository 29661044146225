import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRecruitmentLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 23.5c-.1 0-.3 0-.4-.1L17.2 18c-.8.7-1.7 1.3-2.8 1.8-2.4 1.1-5.1 1.1-7.6.1s-4.5-3-5.6-5.4c-.9-2.5-1-5.2 0-7.7s2.9-4.4 5.4-5.5c5-2.2 10.9.2 13.2 5.3 1.6 3.7.8 7.8-1.8 10.7l5.4 5.4c.1.1.1.2.1.4s0 .3-.1.4-.3 0-.4 0m-12.5-22c-1.2 0-2.4.2-3.5.7-2.2 1-4 2.7-4.9 4.9-.8 2.3-.8 4.7.1 6.9s2.7 4 5 4.9c2.2.9 4.7.9 6.8-.1 2.2-1 3.9-2.7 4.8-5 .9-2.2.9-4.7-.1-6.9-1.4-3.3-4.7-5.4-8.2-5.4M6.2 16H6c-.3-.1-.4-.4-.3-.6.6-1.5 1.8-2.7 3.1-3.1 1.2-.4 2.6-.3 3.8.3s2.1 1.6 2.5 2.8q.15.45-.3.6t-.6-.3c-.3-1-1.1-1.8-2-2.3-1-.5-2-.6-3-.2-1.1.3-2 1.3-2.5 2.5-.1.2-.3.3-.5.3m4.3-5.5C8.5 10.5 7 8.9 7 7s1.5-3.5 3.5-3.5S14 5 14 7s-1.6 3.5-3.5 3.5m0-6C9.1 4.5 8 5.6 8 7s1.1 2.5 2.5 2.5S13 8.4 13 7s-1.1-2.5-2.5-2.5"/></svg>)}
    />
  );
}
  