import React from 'react';
import IconContainer from '../IconContainer';

export default function IconThumbDownLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.5 15.007h-2a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5h2a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5h-2a1.5 1.5 0 0 0-1.5 1.5v1.19c-5.911-3-6.611-3-10.852-2.93h-1.92c-2.561.01-4.231 1.51-5.231 4.59a.14.14 0 0 0 0 .07l-1.9 6.35v.06a2.52 2.52 0 0 0 .39 2.17 2.56 2.56 0 0 0 2 1h4.911a1.5 1.5 0 0 1 1.45 1.88l-.85 3.35a2.22 2.22 0 0 0 4.001 1.84l4.881-6.93c.259-.368.671-.6 1.12-.63h2a1.5 1.5 0 0 0 1.5 1.49h2a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5m-7.502-.43-4.901 6.92c-.34.48-.974.652-1.51.41a1.22 1.22 0 0 1-.66-1.41l.88-3.35a2.48 2.48 0 0 0-2.411-3.14H2.495a1.53 1.53 0 0 1-1.2-.61 1.5 1.5 0 0 1-.23-1.33.07.07 0 0 0 0-.05l1.93-6.35v-.06c.82-2.6 2.13-3.83 4.231-3.83h1.93c4.311 0 4.731 0 10.842 3.05v8.7h-2a2.5 2.5 0 0 0-2 1.05"/></svg>)}
    />
  );
}
  