// @flow

import React, { type Element } from 'react';
import styles from './Tab.module.scss';

type PropsT = {|
  id: string,
  children?: *
|};

export const Tab = (props: PropsT): Element<*> => {
  const { id, children } = props;

  return (
    <div id={id} className={styles['tab-container']}>
      {children}
    </div>
  );
};

export default Tab;
