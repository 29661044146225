import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSmartphoneRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 0H7a2.25 2.25 0 0 0-2.25 2.25v19.5A2.25 2.25 0 0 0 7 24h10a2.25 2.25 0 0 0 2.25-2.25V2.25A2.25 2.25 0 0 0 17 0M7 1.5h10a.76.76 0 0 1 .75.75V16.5H6.25V2.25A.76.76 0 0 1 7 1.5m10 21H7a.76.76 0 0 1-.75-.75V18h11.5v3.75a.76.76 0 0 1-.75.75m-4-2.25a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/></svg>)}
    />
  );
}
  