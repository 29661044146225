// @flow strict
import * as R from 'ramda';
import type { CallForwardingStateEntityT } from './callForwardingTypes';
import type { StoreStateT } from '../../../commonTypes';

type IsRetrievingByIdFnT = (StoreStateT, ?string) => boolean;
export const isRetrievingById: IsRetrievingByIdFnT = (
  { entities: { callForwarding } },
  forwardingId
) => R.pathOr(false, [forwardingId || '', '__metadata', 'isRetrieving'], callForwarding.byId);

type CollectionIsLoadingFnT = StoreStateT => boolean;
export const collectionIsLoading: CollectionIsLoadingFnT = ({ entities: { callForwarding } }) =>
  callForwarding.__metadata.isRetrieving === true || callForwarding.__metadata.isSearching === true;

type IsUpdatingByIdFnT = (StoreStateT, ?string) => boolean;
export const isUpdatingById: IsUpdatingByIdFnT = ({ entities: { callForwarding } }, forwardingId) =>
  R.pathOr(false, [forwardingId || '', '__metadata', 'isUpdating'], callForwarding.byId);

type HasLoadErrorByIdFnT = (StoreStateT, ?string) => boolean;
export const hasLoadErrorById: HasLoadErrorByIdFnT = (
  { entities: { callForwarding } },
  forwardingId
) => R.path([forwardingId || '', '__metadata', 'error'], callForwarding.byId) !== undefined;

type GetCallForwardingsByIdFnT = (StoreStateT, ?string) => ?CallForwardingStateEntityT;
// eslint-disable-next-line import/prefer-default-export
export const callForwardingById: GetCallForwardingsByIdFnT = (
  { entities: { callForwarding } },
  forwardingId
) => (!R.isNil(forwardingId) && callForwarding ? callForwarding.byId[forwardingId] : undefined);
