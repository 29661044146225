// @flow

import * as R from 'ramda';
import axios, { type AxiosPromise } from 'axios';
import HTTP from 'http-status-codes';
import type { ThunkActionT } from '../../commonTypes';
import type { UserStateEntityT } from '../entities/user/userTypes';

import {
  createLanguageFailure,
  createLanguageRequest,
  createLanguageSuccess,
  createUserConfigFailure,
  createUserConfigRequest,
  createUserConfigSuccess,
  createUpdateUserConfigFailure,
  createUpdateUserConfigRequest,
  createUpdateUserConfigSuccess,
  createRemoveFavouriteFailure,
  createRemoveFavouriteRequest,
  createRemoveFavouriteSuccess,
  createUpdateBulletinRequest,
  createUpdateBulletinSuccess,
  createUpdateBulletinFailure,
  createGetBulletinsRequest,
  createGetBulletinsSuccess,
  createGetBulletinsFailure,
  createCreateBulletinRequest,
  createCreateBulletinSuccess,
  createCreateBulletinFailure,
  createDeleteBulletinFailure,
  createDeleteBulletinSuccess,
  createDeleteBulletinRequest,
  createUpdateEnterpriseSettingsSuccess,
  createUpdateEnterpriseSettingsRequest,
  createUpdateEnterpriseSettingsFailure,
  createGetEnterpriseSettingsRequest,
  createGetEnterpriseSettingsSuccess,
  createGetEnterpriseSettingsFailure
} from './configActions';

import type {
  LanguagesT,
  UserConfigActionT,
  LanguageConfigActionT,
  UserConfigT,
  BulletinT,
  BulletinsActionT,
  EnterpriseSettingsT,
  EnterpriseSettingsActionT
} from './configTypes';
import { serializeUserConfigValue } from '../../userConfigHelpers';
import { MAX_RECENT_RESULTS } from '../../constants';

type GetLanguagesFnT = () => ThunkActionT<LanguageConfigActionT>;
const getLanguages: GetLanguagesFnT = () => async dispatch => {
  dispatch(createLanguageRequest());
  try {
    const response: AxiosPromise<LanguagesT> = axios({
      method: 'GET',
      url: '/api/v1/language'
    });
    const { data } = await response;
    dispatch(createLanguageSuccess(data));
  } catch (error) {
    dispatch(createLanguageFailure(error));
  }
};

type GetUserConfigFnT = () => AxiosPromise<UserConfigActionT | ?UserConfigT>;
const getUserConfig: GetUserConfigFnT = () => async dispatch => {
  dispatch(createUserConfigRequest());
  try {
    const response: AxiosPromise<UserConfigT> = axios({
      method: 'GET',
      url: '/api/v1/config'
    });
    const { data } = await response;
    dispatch(createUserConfigSuccess(data));
    return data;
  } catch (error) {
    dispatch(createUserConfigFailure(error));
  }
  return undefined;
};

type UpdateUserConfigFnT = (
  UserConfigT,
  headers: {}
) => ThunkActionT<UserConfigActionT, ?UserConfigT>;
const updateUserConfig: UpdateUserConfigFnT = (userConfig, headers) => async dispatch => {
  dispatch(createUpdateUserConfigRequest(userConfig));
  try {
    const response: AxiosPromise<UserConfigT> = axios({
      method: 'POST',
      url: '/api/v1/config',
      data: userConfig,
      headers
    });
    const { data } = await response;
    dispatch(createUpdateUserConfigSuccess(data));
    return data;
  } catch (error) {
    dispatch(createUpdateUserConfigFailure(error));
  }
  return undefined;
};

type RemoveFavouriteFnT = (
  string,
  string,
  string,
  UserConfigT,
  headers: {}
) => ThunkActionT<UserConfigActionT, ?UserConfigT>;
export const removeFavourite: RemoveFavouriteFnT = (
  enterpriseId,
  id,
  type,
  userConfig,
  headers
) => async dispatch => {
  const updatedUserConfig = userConfig
    ? [
        ...R.reject(
          item => item && item.key === `favouriteUser_${enterpriseId}_${type}_${id}`,
          userConfig
        )
      ]
    : [];
  dispatch(createRemoveFavouriteRequest(updatedUserConfig));
  try {
    const response: AxiosPromise<UserConfigT> = axios({
      method: 'POST',
      url: '/api/v1/config',
      data: updatedUserConfig,
      headers
    });
    const { data } = await response;
    dispatch(createRemoveFavouriteSuccess(data));
    return data;
  } catch (error) {
    dispatch(createRemoveFavouriteFailure(error));
  }
  return undefined;
};

type AddRecentUserToConfigFnT = (
  UserStateEntityT,
  UserConfigT,
  headers: {}
) => ThunkActionT<UserConfigActionT, ?UserConfigT>;
export const addRecentUserToConfig: AddRecentUserToConfigFnT = (user, userConfig, headers) => {
  const key = `recentUser_${user.enterpriseId}_${user.userType}_${user.id}`;
  const recentIds = userConfig
    ? R.filter(item => item && item.key.includes('recentUser'), userConfig)
    : [];
  const updatedRecentIds = [
    ...[
      {
        key,
        value: serializeUserConfigValue(user)
      }
    ],
    ...R.reject(item => item && item.key === key, recentIds)
  ].slice(0, MAX_RECENT_RESULTS);
  return updateUserConfig(
    userConfig
      ? [
          ...R.reject(item => item && item.key.includes('recentUser'), userConfig),
          ...updatedRecentIds
        ]
      : [],
    headers
  );
};

type CreateBulletinFnT = (
  bulletin: BulletinT,
  headers: {}
) => ThunkActionT<BulletinsActionT, ?BulletinT>;
const createBulletin: CreateBulletinFnT = (bulletin, headers) => async dispatch => {
  dispatch(createCreateBulletinRequest());
  try {
    const response: AxiosPromise<BulletinT> = axios({
      method: 'POST',
      url: '/api/v1/config/bulletin',
      data: bulletin,
      headers
    });
    const { data } = await response;
    dispatch(createCreateBulletinSuccess(data));
    return data;
  } catch (error) {
    dispatch(createCreateBulletinFailure(error));
  }
  return undefined;
};

type UpdateBulletinFnT = (
  number,
  BulletinT,
  headers: {}
) => ThunkActionT<BulletinsActionT, ?BulletinT>;
const updateBulletin: UpdateBulletinFnT = (bulletinId, bulletin, headers) => async dispatch => {
  dispatch(createUpdateBulletinRequest());
  try {
    const response: AxiosPromise<BulletinT[]> = axios({
      method: 'PATCH',
      url: `/api/v1/config/bulletin/${bulletinId}`,
      data: bulletin,
      headers
    });
    const { data } = await response;
    dispatch(createUpdateBulletinSuccess(data));
    return data;
  } catch (error) {
    dispatch(createUpdateBulletinFailure(error));
  }
  return undefined;
};

type UploadBulletinAttachmentFnT = (
  number,
  { name: string, url: string, attachmentId: string },
  headers: {}
) => AxiosPromise<boolean>;
const uploadBulletinAttachment: UploadBulletinAttachmentFnT = async (
  bulletinId,
  attachment,
  headers
) => {
  const formData = new FormData();
  const fetchedFileRes = await fetch(attachment.url);
  const blob = await fetchedFileRes.blob();
  formData.append('file', blob);
  formData.append('filename', attachment.name);
  formData.append('attachmentId', attachment.attachmentId);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try {
    const response: AxiosPromise = axios({
      method: 'POST',
      url: `/api/v1/config/bulletin/${bulletinId}/attachment`,
      data: formData,
      config,
      headers
    });
    const { data } = await response;
    return !!data;
  } catch (error) {
    return false;
  }
};

type DeleteBulletinAttachmentFnT = (
  number,
  attachmentId: string,
  headers: {}
) => AxiosPromise<boolean>;
const deleteBulletinAttachment: DeleteBulletinAttachmentFnT = async (
  bulletinId,
  attachmentId,
  headers
) => {
  try {
    await axios({
      method: 'DELETE',
      url: `/api/v1/config/bulletin/${bulletinId}/attachment/${attachmentId}`,
      headers
    });
    return true;
  } catch (error) {
    return false;
  }
};

type GetBulletinsFnT = () => AxiosPromise<BulletinsActionT, ?(BulletinT[])>;
const getBulletins: GetBulletinsFnT = () => async dispatch => {
  dispatch(createGetBulletinsRequest());
  try {
    const response: AxiosPromise<BulletinT[]> = axios({
      method: 'GET',
      url: '/api/v1/config/bulletin'
    });
    const { data } = await response;
    dispatch(createGetBulletinsSuccess(data));
    return data;
  } catch (error) {
    dispatch(createGetBulletinsFailure(error));
  }
  return undefined;
};

type DeleteBulletinFnT = (number, headers: {}) => ThunkActionT<BulletinsActionT, void>;
const deleteBulletin: DeleteBulletinFnT = (bulletinId, headers) => async dispatch => {
  dispatch(createDeleteBulletinRequest());
  try {
    const response: AxiosPromise<BulletinT> = axios({
      method: 'DELETE',
      url: `/api/v1/config/bulletin/${bulletinId}`,
      headers
    });
    await response;
    dispatch(createDeleteBulletinSuccess(bulletinId));
    return HTTP.OK;
  } catch (error) {
    dispatch(createDeleteBulletinFailure(error));
    return undefined;
  }
};

type UpdateEnterpiseSettingsFnT = (
  EnterpriseSettingsT,
  headers: {}
) => ThunkActionT<EnterpriseSettingsActionT, ?EnterpriseSettingsT>;
const updateEnterpriseSettings: UpdateEnterpiseSettingsFnT = (
  enterpriseSettings,
  headers
) => async dispatch => {
  dispatch(createUpdateEnterpriseSettingsRequest());
  try {
    const response: AxiosPromise<EnterpriseSettingsT> = axios({
      method: 'PATCH',
      url: '/api/v1/config/entsettings',
      data: enterpriseSettings,
      headers
    });
    const { data } = await response;
    dispatch(createUpdateEnterpriseSettingsSuccess(data));
    return data;
  } catch (error) {
    dispatch(createUpdateEnterpriseSettingsFailure(error));
  }
  return undefined;
};

type UpdateEnterpiseSettingsAsEnterpriseAdminFnT = (
  EnterpriseSettingsT,
  headers: {}
) => ThunkActionT<EnterpriseSettingsActionT, ?EnterpriseSettingsT>;
const updateEnterpriseSettingsAsEnterpriseAdmin: UpdateEnterpiseSettingsAsEnterpriseAdminFnT = (
  enterpriseSettings,
  headers
) => async dispatch => {
  if (enterpriseSettings && enterpriseSettings.enterpriseId) {
    dispatch(createUpdateEnterpriseSettingsRequest());
    try {
      const response: AxiosPromise<EnterpriseSettingsT> = axios({
        method: 'PATCH',
        // $FlowFixMe: enterpriseId is checked above
        url: `/api/v1/config/entsettingsentadmin/${enterpriseSettings.enterpriseId}`,
        data: enterpriseSettings,
        headers
      });
      const { data } = await response;
      dispatch(createUpdateEnterpriseSettingsSuccess(data));
      return data;
    } catch (error) {
      dispatch(createUpdateEnterpriseSettingsFailure(error));
    }
  }
  return undefined;
};

type GetEnterpriseSettingsFnT = string => ThunkActionT<
  EnterpriseSettingsActionT,
  ?EnterpriseSettingsT
>;
const getEnterpriseSettings: GetEnterpriseSettingsFnT = enterpriseId => async dispatch => {
  dispatch(createGetEnterpriseSettingsRequest());
  try {
    const response: AxiosPromise<EnterpriseSettingsT> = axios({
      method: 'GET',
      url: `/api/v1/config/entsettings/${enterpriseId}`
    });
    const { data } = await response;
    dispatch(createGetEnterpriseSettingsSuccess(data));
    return data;
  } catch (error) {
    dispatch(createGetEnterpriseSettingsFailure(error));
  }
  return undefined;
};

const operations = {
  getLanguages,
  getUserConfig,
  updateUserConfig,
  addRecentUserToConfig,
  updateBulletin,
  uploadBulletinAttachment,
  getBulletins,
  createBulletin,
  deleteBulletin,
  updateEnterpriseSettings,
  updateEnterpriseSettingsAsEnterpriseAdmin,
  deleteBulletinAttachment,
  getEnterpriseSettings
};

export default operations;
