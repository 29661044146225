import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFI from './locale/fi.json';
import translationSV from './locale/sv.json';
import translationEN from './locale/en.json';
import translationET from './locale/et.json';
import translationDEV from './locale/dev.json';

console.log('process.env.REACT_APP_VERSION', process.env.REACT_APP_VERSION);
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: false
    },
    resources: {
      en: {
        translation: translationEN
      },
      sv: {
        translation: translationSV
      },
      fi: {
        translation: translationFI
      },
      et: {
        translation: translationET
      },
      aa: {
        translation: translationDEV
      }
    },
    debug: process.env.REACT_APP_VERSION === 'local',
    fallbackLng: 'aa',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ','
    },
    keySeparator: '.',
    lng: 'fi',
    supportedLngs: ['fi', 'en', 'sv', 'et', 'aa'],
    preload: true,
    lowerCaseLng: true
  });

export default i18n;
