import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWarningLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.788 20.6 13.404 1.81A1.55 1.55 0 0 0 12.005 1a1.53 1.53 0 0 0-1.359.81L.222 20.6a1.59 1.59 0 0 0 0 1.62c.275.49.797.79 1.359.78h20.848c.552 0 1.062-.293 1.339-.77.302-.5.31-1.123.02-1.63m-.879 1.12a.57.57 0 0 1-.48.28H1.581a.57.57 0 0 1-.48-.28.62.62 0 0 1 0-.63L11.555 2.3a.55.55 0 0 1 .45-.3.55.55 0 0 1 .49.3l10.424 18.79a.62.62 0 0 1-.01.63M11.505 15.5V9a.5.5 0 0 1 1 0v6.5a.5.5 0 0 1-1 0M11.25 18a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0"/></svg>)}
    />
  );
}
  