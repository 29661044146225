// @flow

import type {
  CallForwardingsTabT,
  UpdateActiveTabT,
  UpdateForwardingPopUpT
} from './callForwardingsUiTypes';
import type { CallForwardingT } from '../../entities/callForwarding/callForwardingTypes';
import type { DirectoryStateEntityT } from '../../entities/directory';

type CreateUpdateActiveTabFnT = CallForwardingsTabT => UpdateActiveTabT;
export const createUpdateActiveTab: CreateUpdateActiveTabFnT = activeTab => ({
  type: 'ring/ui/callForwardings/UPDATE_ACTIVE_TAB',
  payload: {
    activeTab
  }
});

type CreateUpdateForwardingPopUpFnT = (
  CallForwardingT,
  ?DirectoryStateEntityT
) => UpdateForwardingPopUpT;
export const createUpdateForwardingPopUp: CreateUpdateForwardingPopUpFnT = (
  forwarding: CallForwardingT,
  user: ?DirectoryStateEntityT
) => ({
  type: 'ring/ui/callForwardings/UPDATE_POP_UP',
  payload: {
    forwarding,
    user
  }
});

export default createUpdateActiveTab;
