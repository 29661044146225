// @flow
import React, { type Element } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import Tooltip from '../../../../../components/Tooltip';
import styles from './InputField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  shouldValidate?: boolean,
  maxLength?: number,
  containerStyle?: string,
  inputStyle?: string,
  placeholder?: string,
  tooltip?: string,
  disabled?: boolean,
  onChange?: () => void,
  type?: string,
  showLength?: boolean,
  autofocus?: boolean
|};

export const InputField = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    shouldValidate,
    maxLength,
    containerStyle,
    inputStyle,
    tooltip,
    placeholder,
    disabled,
    onChange,
    type,
    showLength,
    autofocus
  } = props;

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();

  const value: string = !values[field] ? '' : `${values[field]}`;
  return (
    <div className={classnames(styles.container, containerStyle)} data-cy={`input-field-${field}`}>
      {title && (
        <div className={styles['input-title-area']}>
          <div className={styles['input-title']}>{title}</div>
          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </div>
      )}
      {description && <div className={styles['input-description']}>{description}</div>}
      <div id={`${field}-input`} className={styles.input}>
        <div>
          <Input
            autofocus={autofocus}
            disabled={disabled}
            name={field}
            data-cy={field}
            className={classnames(styles['string-input'], inputStyle)}
            onValueChange={e => {
              setValue(field, e.target.value, {
                shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                shouldDirty: true
              });
              if (onChange) {
                onChange();
              }
            }}
            defaultValue={value}
            touched
            i18n_input_errorMessage={errors[field] && (errors[field].message || 'error')}
            maxlength={maxLength || 100}
            placeholder={placeholder || ''}
            optional
            i18n_input_optionalText=""
            type={type}
            i18n_input_helpText={
              showLength && values[field] ? `${values[field].length} / ${maxLength || 100}` : ''
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InputField;
