import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHeadphonesRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 12C23.25 5.787 18.213.75 12 .75S.75 5.787.75 12v5.2a5.23 5.23 0 0 0 4.5 5.22v.08a.76.76 0 0 0 .75.75h1.5A2.25 2.25 0 0 0 9.75 21v-7.5a2.25 2.25 0 0 0-2.25-2.25H6a.76.76 0 0 0-.75.75v.08a5.14 5.14 0 0 0-3 1.51V12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75v1.59a5.14 5.14 0 0 0-3-1.51V12a.76.76 0 0 0-.75-.75h-1.5a2.25 2.25 0 0 0-2.25 2.25V21a2.25 2.25 0 0 0 2.25 2.25H18a.76.76 0 0 0 .75-.75v-.08a5.23 5.23 0 0 0 4.5-5.22zm-15.75.75a.76.76 0 0 1 .75.75V21a.76.76 0 0 1-.75.75h-.75v-9zm-2.25.83v7.34a3.74 3.74 0 0 1 0-7.34m11.25 8.17a.76.76 0 0 1-.75-.75v-7.5a.76.76 0 0 1 .75-.75h.75v9zm2.25-.83v-7.34a3.74 3.74 0 0 1 0 7.34"/></svg>)}
    />
  );
}
  