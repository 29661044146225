import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPrintFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.92 6.232a.917.917 0 0 1-1.566-.649v-2.75A1.833 1.833 0 0 1 7.188 1h8.1c.485.001.951.195 1.295.538l2.253 2.25a.92.92 0 0 1 .268.649v1.146a.917.917 0 1 1-1.833 0v-.767l-1.984-1.983h-8.1v2.75a.92.92 0 0 1-.268.649ZM3.291 7.417h17.416A2.29 2.29 0 0 1 23 9.708v4.125a2.293 2.293 0 0 1-2.292 2.292h-1.833v5.73A1.146 1.146 0 0 1 17.729 23h-11a1.146 1.146 0 0 1-1.146-1.146v-5.729H3.292A2.29 2.29 0 0 1 1 13.833V9.708a2.29 2.29 0 0 1 2.292-2.291Zm.407 3.97a.917.917 0 1 0 1.019-1.525.917.917 0 0 0-1.019 1.525m13.208 9.645a.46.46 0 0 0 .135-.324v-8.29a.46.46 0 0 0-.459-.457H7.875a.46.46 0 0 0-.458.458v8.29a.46.46 0 0 0 .458.458h8.708a.46.46 0 0 0 .324-.135ZM14.75 14.52H9.708a.687.687 0 1 0 0 1.375h5.042a.687.687 0 1 0 0-1.375m-5.042 3.208h3.438a.687.687 0 1 1 0 1.375H9.708a.688.688 0 0 1 0-1.375"/></svg>)}
    />
  );
}
  