// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import type { AudioFieldT, AudioT } from './CallflowAudioUtils';
import AudioPlayerContainer from '../../../../../../components/AudioPlayer/AudioPlayerContainer';

import styles from './AcdAudioField.module.scss';

export type PropsT = {|
  field: string,
  audioFilename?: string
|};

export const AcdAudioListItem = (props: PropsT): Element<'div'> => {
  const { field, audioFilename } = props;
  // form
  const { watch } = useFormContext();
  const values = watch();

  const audioField: AudioFieldT<AudioT> = values[field];

  const defaultAudioData = {
    audio: {
      ...audioField.audioPlayData
    },
    // $FlowFixMe
    level: audioField.audioPlayData.level,
    loading: undefined,
    error: undefined
  };

  return (
    <div className={styles['audio-area']}>
      <AudioPlayerContainer {...defaultAudioData} audioFilename={audioFilename} />
    </div>
  );
};

export default AcdAudioListItem;
