import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWifiOffFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.75 19.5c0 1.517-1.233 2.75-2.75 2.75s-2.75-1.233-2.75-2.75 1.233-2.75 2.75-2.75 2.75 1.233 2.75 2.75m1.147-15.499a1.25 1.25 0 1 0 .623-2.421C10.903.134 4.893 1.478.446 5.17a1.251 1.251 0 0 0 1.597 1.924c3.833-3.184 9.012-4.339 13.854-3.093m7.651 1.162a18 18 0 0 0-2.151-1.5L23.03 2.03A.75.75 0 1 0 21.969.969l-20 20a.75.75 0 0 0 1.06 1.061l7.943-7.943a6.34 6.34 0 0 1 4.813 1.152 1.25 1.25 0 0 0 1.48-2.016 8.8 8.8 0 0 0-3.838-1.591l2.226-2.226a10.8 10.8 0 0 1 3.217 1.754 1.25 1.25 0 0 0 1.757-.195 1.25 1.25 0 0 0-.194-1.757 13.2 13.2 0 0 0-2.859-1.723l1.993-1.993c.836.464 1.644.979 2.385 1.594a1.248 1.248 0 0 0 1.596-1.923M3.568 9.21a1.25 1.25 0 0 0 1.562 1.952 10.9 10.9 0 0 1 6.392-2.381 1.25 1.25 0 0 0 1.194-1.303c-.029-.69-.618-1.212-1.303-1.195A13.4 13.4 0 0 0 3.568 9.21"/></svg>)}
    />
  );
}
  