import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDisplayRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.75 1.75H2.25A2.26 2.26 0 0 0 0 4v13a2.26 2.26 0 0 0 2.25 2.25h5.92l-.45 2.5H5.5a.75.75 0 0 0 0 1.5h13a.75.75 0 0 0 0-1.5h-2.23l-.45-2.5h5.93A2.26 2.26 0 0 0 24 17V4a2.26 2.26 0 0 0-2.25-2.25m-19.5 1.5h19.5a.76.76 0 0 1 .76.75v10.75H1.5V4a.76.76 0 0 1 .75-.75m12.49 18.5h-5.5l.46-2.5h4.58zm6.99-4H2.25A.76.76 0 0 1 1.5 17v-.75h21V17a.76.76 0 0 1-.75.75z"/></svg>)}
    />
  );
}
  