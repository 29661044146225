import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPinRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 24a1.3 1.3 0 0 1-.68-.2 1.4 1.4 0 0 1-.37-.38c-2.17-3.45-7.2-11.36-7.2-15.03A8.33 8.33 0 0 1 12 0a8.33 8.33 0 0 1 8.25 8.39c0 3.67-5 11.58-7.19 15.02-.175.288-.461.49-.79.56q-.134.03-.27.03m0-22.5a6.83 6.83 0 0 0-6.75 6.89c0 2.63 3.45 8.59 6.75 13.88 3.3-5.3 6.75-11.26 6.75-13.88A6.83 6.83 0 0 0 12 1.5m0 10.25a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5m0-6a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5"/></svg>)}
    />
  );
}
  