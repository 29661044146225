import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowLeftRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m15 4.47-6.88 6.65a1.23 1.23 0 0 0 0 1.8L15 19.56a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06L9.34 12 16 5.55a.76.76 0 0 0 0-1.06.75.75 0 0 0-1-.02"/></svg>)}
    />
  );
}
  