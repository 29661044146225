// @flow

/* eslint-disable no-underscore-dangle */
import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import type { DepartmentStateEntityT } from './departmentTypes';

type CollectionIsLoadingFnT = StoreStateT => boolean;
export const collectionIsLoading: CollectionIsLoadingFnT = ({ entities: { department } }) =>
  department.__metadata.isRetrieving === true;

type DepartmentsByEnterpriseFnT = (StoreStateT, ?string) => DepartmentStateEntityT[];
export const departmentsByEnterprise: DepartmentsByEnterpriseFnT = (
  {
    entities: {
      department: { byId, allIds }
    }
  },
  enterpriseId
) => allIds.filter(id => byId[id].enterpriseId === enterpriseId).map(id => byId[id]);

type HasDepartmentFnT = (StoreStateT, ?string, ?string) => boolean;
export const hasDepartment: HasDepartmentFnT = (
  {
    entities: {
      department: { byId, allIds }
    }
  },
  enterpriseId,
  departmentId
) => allIds.some(id => byId[id].enterpriseId === enterpriseId && id === departmentId);

type DepartmentTreeByEnterpriseIdFnT = (StoreStateT, ?string) => DepartmentStateEntityT[];
export const departmentTreeByEnterpriseId: DepartmentTreeByEnterpriseIdFnT = (
  {
    entities: {
      department: { byId, idTrees }
    }
  },
  enterpriseId
) =>
  enterpriseId && enterpriseId in idTrees
    ? (function traverse(nodes) {
        return nodes.map(node => ({
          ...byId[node.id],
          ...(node.children ? { subDepartments: traverse(node.children) } : undefined)
        }));
      })(idTrees[enterpriseId])
    : [];

type DepartmentHasSubDepartmentsFnT = (StoreStateT, ?string, ?string) => boolean;
export const departmentHasSubDepartments: DepartmentHasSubDepartmentsFnT = (
  {
    entities: {
      department: { idTrees }
    }
  },
  enterpriseId,
  departmentId
) =>
  enterpriseId && enterpriseId in idTrees
    ? (function traverse(nodes) {
        const dep = nodes.map(node => {
          if (node.id === departmentId) {
            return node.children && node.children.length > 0 ? node : undefined;
          }
          if (node.children) {
            return traverse(node.children);
          }
          return undefined;
        });
        return R.flatten(dep).filter(a => a !== undefined);
      })(idTrees[enterpriseId]).length > 0
    : false;

type PathWithNamesFnT = (StoreStateT, string[]) => { id: string, name: ?string }[];
export const pathWithNames: PathWithNamesFnT = (
  {
    entities: {
      department: { byId }
    }
  },
  ids
) =>
  ids.map(id => ({
    id,
    name: id in byId ? byId[id].name : undefined
  }));

type HasErrorFnT = StoreStateT => boolean;
export const hasError: HasErrorFnT = ({ entities: { department } }) =>
  department.__metadata.error !== undefined;

type CreatedDepartmentSuccessfullyFnT = StoreStateT => boolean;
export const createdDepartmentSuccessfully: CreatedDepartmentSuccessfullyFnT = ({
  entities: { department }
}) => department.__metadata.hasUpdated === true;

type DepartmentPathWithIdFnT = (
  StoreStateT,
  ?string
) => $PropertyType<DepartmentStateEntityT, 'path'>;
export const pathWithId: DepartmentPathWithIdFnT = (
  {
    entities: {
      department: { byId }
    }
  },
  id
) => (id != null && id in byId ? byId[id].path : []);

type DepartmentByNamePathFnT = (StoreStateT, ?string, string[]) => ?DepartmentStateEntityT;
export const departmentByNamePath: DepartmentByNamePathFnT = (
  {
    entities: {
      department: { allIds, byId }
    }
  },
  enterpriseId,
  namePath
) =>
  // $FlowFixMe: compose missing in types
  R.compose(
    R.find(({ path }) =>
      R.equals(
        R.map(id => byId[id].name.toLowerCase(), path),
        namePath.map(p => p.toLowerCase())
      )
    ),
    R.filter(R.propEq('enterpriseId', enterpriseId)),
    R.map(id => byId[id])
  )(allIds);

type ParentDepartmentByNamePathFnT = (StoreStateT, ?string, string[]) => ?DepartmentStateEntityT;
export const parentDepartmentByNamePath: ParentDepartmentByNamePathFnT = (
  {
    entities: {
      department: { allIds, byId }
    }
  },
  enterpriseId,
  namePath
) =>
  // $FlowFixMe: compose missing in types
  R.compose(
    R.find(({ path }) =>
      R.equals(
        R.map(id => byId[id].name, path),
        R.dropLast(1, namePath)
      )
    ),
    R.filter(R.propEq('enterpriseId', enterpriseId)),
    R.map(id => byId[id])
  )(allIds);
