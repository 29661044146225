// @flow strict-local

import React, { Component, type Node, type Element } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { type ContextRouter, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import type { CurrentUserStateT } from '../../ducks/currentUser';
import type { ExactPropsT } from '../../commonTypes';
import { selectors as headerSelectors } from '../../ducks/ui/header';
import { type EnterpriseStateEntityCollectionT } from '../../ducks/entities/enterprise/enterpriseTypes';
import {
  createOpenChangelogAction,
  createOpenOnboardingAction
} from '../../ducks/ui/header/headerUiActions';
import { createStartTourAction } from '../../ducks/ui/tour/tourActions';

import styles from './BaseContainer.module.scss';

export type OwnPropsT = {|
  enterprises?: EnterpriseStateEntityCollectionT,
  children?: Node,
  header?: Node,
  containerStyle?: string
|};

type DispatchPropsT = {
  openOnboarding: typeof createOpenOnboardingAction,
  openChangelog: typeof createOpenChangelogAction,
  startTour: typeof createStartTourAction
};

type StatePropsT = {|
  menuOpen: boolean,
  modalOpen: boolean,
  navigationMenuOpen: boolean,
  currentUser: CurrentUserStateT
|};

export type PropsT = {|
  ...ExactPropsT<OwnPropsT, StatePropsT, DispatchPropsT>,
  ...$Exact<ContextRouter>
|};

type StateT = {};

// eslint-disable-next-line react/prefer-stateless-function
export class BaseContainer extends Component<PropsT, StateT> {
  render(): Element<'div'> {
    const { children, containerStyle, header } = this.props;
    return (
      <div className={styles['base-container']}>
        {header}
        <div className={classnames(styles['content-container'], containerStyle || '')}>
          {children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    currentUser,
    ui: {
      header: { navigationMenuOpen }
    },
    entities: { enterprise }
  } = state;

  return {
    menuOpen: headerSelectors.menuIsOpen(state),
    modalOpen: headerSelectors.modalIsOpen(state),
    enterprises: 'enterprises' in ownProps ? ownProps.enterprises : enterprise.byId,
    navigationMenuOpen,
    currentUser
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openOnboarding: createOpenOnboardingAction,
      openChangelog: createOpenChangelogAction,
      startTour: createStartTourAction
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<$Diff<PropsT, ContextRouter>, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter
)(BaseContainer);
