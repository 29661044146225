// @flow
/* eslint-disable import/prefer-default-export */

import type { SetTooltipVisibilityT } from './tooltipUiTypes';

export type CreateSetToolTipVisibilityFnT = (string | null) => SetTooltipVisibilityT;
export const createSetTooltipVisibility: CreateSetToolTipVisibilityFnT = visible => ({
  type: 'ring/ui/tooltip/SET_TOOLTIP_VISIBILITY',
  payload: {
    visible
  }
});
