// @flow

import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, type CancelTokenSource } from 'axios';
import React, { useEffect, useState, type Element } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../Button/ActionButton';
import fieldValidators from '../../fieldValidators';
import type { PasswordResetPayloadT } from '../../ducks/entities/user';
import { operations as userOps } from '../../ducks/entities/user';
import { actions as notificationActions } from '../../ducks/ui/notification';
import type { InternalUserEntityT } from '../../ducks/entities/user/userTypes';
import { pushAnalyticsEvent } from '../../utils/analyticsHandler';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import {
  ADMIN_OPEN_LOCKED_USER_FAILURE,
  ADMIN_OPEN_LOCKED_USER_REQUEST,
  ADMIN_OPEN_LOCKED_USER_SUCCESS
} from '../../matomo_constants';

import styles from './UserLocked.module.scss';

export type PropsT = {|
  user: InternalUserEntityT,
  handleCloseView: () => void,
  isSmsSelected: boolean,
  isEmailSelected: boolean,
  phoneNumber: string,
  email: string
|};

let updateCancelTokenSource: CancelTokenSource;

export const UserLockedContent = (props: PropsT): Element<'div'> => {
  const { user, handleCloseView, isSmsSelected, isEmailSelected, phoneNumber, email } = props;
  const [isUpdating, setIsUpdating] = useState(false);
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const validateFields = () => {
    const validPhoneNumber =
      isSmsSelected &&
      !fieldValidators.phoneNumberValidator(phoneNumber, t('userLocked.phoneError')) &&
      !!phoneNumber;
    const validEmail =
      isEmailSelected &&
      !fieldValidators.commaSeparatedUniqueEmailsValidator(email, t('userLocked.emailError')) &&
      !!email;
    return validPhoneNumber || validEmail;
  };

  const handleSendClick = async (event?: SyntheticEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    pushAnalyticsEvent(ADMIN_OPEN_LOCKED_USER_REQUEST);
    setIsUpdating(true);
    const params: PasswordResetPayloadT = {
      email: isEmailSelected && email ? email : '',
      mobileNumber: isSmsSelected && phoneNumber ? phoneNumber : '',
      userId: user.personId,
      language: user.language || ''
    };
    const resetPasswordSuccess = await dispatch(
      userOps.resetPassword(
        user.enterpriseId,
        user.id,
        params,
        updateCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
    if (resetPasswordSuccess !== undefined) {
      handleCloseView();
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'internal-user-password-reset',
          duration: 15000,
          type: 'info',
          message: t('userLocked.successNotification')
        })
      );
      pushAnalyticsEvent(ADMIN_OPEN_LOCKED_USER_SUCCESS);
    } else {
      dispatch(
        notificationActions.createCreateNotificationAction({
          tag: 'internal-user-password-reset',
          duration: 15000,
          type: 'error',
          message: t('userLocked.failNotification')
        })
      );
      pushAnalyticsEvent(ADMIN_OPEN_LOCKED_USER_FAILURE);
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    updateCancelTokenSource = CancelToken.source();
    return () => {
      updateCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles['button-area']}>
      <ActionButton
        id="send-reset-message-button"
        className={styles['send-reset-button']}
        label={t('userLocked.send')}
        disabled={isUpdating || !validateFields()}
        loading={isUpdating}
        onClickAction={event => handleSendClick(event)}
      />
    </div>
  );
};

export default UserLockedContent;
