// @flow

import React, { type Element } from 'react';
import classnames from 'classnames';
import styles from './GoBackLink.module.scss';

type PropsT = {|
  id: string,
  text: string,
  onClick: () => void,
  className?: string
|};

const GoBackLink = (props: PropsT): Element<'div'> => {
  const { id, text, onClick, className } = props;
  return (
    <div
      role="button"
      id={id}
      tabIndex={0}
      className={classnames(styles.link, className)}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <img src="/arrow-left-black.svg" alt="arrow-left" />
      {text}
    </div>
  );
};

export default GoBackLink;
