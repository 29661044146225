import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPukLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 3v5.75a.5.5 0 0 1-1 0V3c0-.551-.448-1-1-1H3c-.551 0-1 .449-1 1v7c0 .551.449 1 1 1h9.5a.5.5 0 0 1 0 1H3c-1.103 0-2-.897-2-2V3c0-1.103.897-2 2-2h18c1.103 0 2 .897 2 2M5.995 5.5a1 1 0 0 0-.704.294A1.002 1.002 0 0 0 6 7.5c.551 0 1-.449 1-1s-.449-1-1-1zm6 0a.996.996 0 0 0-.995 1c0 .551.449 1 1 1s1-.449 1-1-.449-1-1-1zM19 6.5c0-.551-.448-1-1-1h-.006A1 1 0 1 0 19 6.5m4 10V21c0 1.103-.897 2-2 2h-6c-1.103 0-2-.897-2-2v-4.5c0-.929.64-1.705 1.5-1.929V13.5c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v1.071c.86.224 1.5 1 1.5 1.929m-7.5-2h5v-1c0-1.378-1.121-2.5-2.5-2.5s-2.5 1.122-2.5 2.5zm6.5 2a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1V21a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1z"/></svg>)}
    />
  );
}
  