// @flow

import type {
  DynamicPropertyFwdT,
  InternalAddressDestinationT
} from '../../../../../../ducks/entities/acd/acdTypes';
import type { TranslateT } from '../../../../../../commonTypes';
import type { RelatedNumberT } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import fieldValidators from '../../../../../../fieldValidators';

export const enterpriseVoiceMailAddress = '777';

export const isExternalNumber = (type: string) => type === 'TRANSFER_EXTERNAL';

export const isSearhableType = (type: string) =>
  type === 'TRANSFER_ACD_CUSTOMER_SERVICE' ||
  type === 'TRANSFER_EXTENSION_GROUP' ||
  type === 'TRANSFER_PLAY_MUSIC' ||
  type === 'TRANSFER_WELCOME_ATTENDANT' ||
  type === 'TRANSFER_ACD_SWITCHBOARD' ||
  type === 'TRANSFER_INTERNAL' ||
  type === 'TRANSFER_SPEED_DIAL' ||
  type === 'TRANSFER_OC';

export const getTypeFilter = (type: string, selectedType: string) => {
  switch (selectedType) {
    case 'TRANSFER_ACD_CUSTOMER_SERVICE':
      return type === 'ACD_CUSTOMER_SERVICE';
    case 'TRANSFER_ACD_SWITCHBOARD':
      return type === 'ACD_SWITCHBOARD';
    case 'TRANSFER_EXTENSION_GROUP':
      return type === 'EXTENSION_GROUP';
    case 'TRANSFER_PLAY_MUSIC':
      return type === 'PLAY_MUSIC';
    case 'TRANSFER_OC':
      return type === 'OC';
    case 'TRANSFER_SPEED_DIAL':
      return type === 'SPEED_DIAL';
    default:
      return true;
  }
};

const validAddressNumber = (addressNumber?: string) => {
  if (addressNumber) {
    return !addressNumber.includes('~');
  }
  return true;
};

export const filterOwnAddressNumber: (string, string) => boolean = (
  ownAddressNumber: string,
  addressNumber: string
) => ownAddressNumber !== addressNumber;

const getDefaultDisplayName = (alias: string, translate: TranslateT<>): string => {
  switch (alias) {
    case 'IVRService':
      return translate('callflows.callflowProperty.destination.ivr');
    case 'WelcomeAttendant':
      return translate('callflows.callflowProperty.destination.welcomeAttendant');
    default:
      return '--';
  }
};

export const parseResults = (results: *, type: string, translate: TranslateT<>) =>
  results
    ? results
        .filter(
          result =>
            getTypeFilter(result.type, type) && validAddressNumber(result.publicInfo.addressNumber)
        )
        .map(result => {
          if (result.displayName === null || result.displayName === undefined) {
            return {
              ...result,
              displayName: getDefaultDisplayName(result.alias, translate)
            };
          }
          return result;
        })
        .map(result => ({
          id: result.id,
          label:
            result.displayName && result.displayName.startsWith('kutsu:')
              ? result.displayName.slice(6)
              : result.displayName,
          value: result.publicInfo.addressNumber
        }))
    : [];

export const getSearchType = (type: string) => {
  switch (type) {
    case 'TRANSFER_ACD_CUSTOMER_SERVICE':
    case 'TRANSFER_ACD_SWITCHBOARD':
      return 'acds';
    case 'TRANSFER_EXTENSION_GROUP':
      return 'groups';
    case 'TRANSFER_SPEED_DIAL':
    case 'TRANSFER_OC':
      return 'speeddials';
    case 'TRANSFER_PLAY_MUSIC':
      return 'services';
    case 'TRANSFER_WELCOME_ATTENDANT':
      return 'welcomeattendants';
    case 'TRANSFER_INTERNAL':
      return 'users';
    default:
      return 'all';
  }
};

type TransferTypeT =
  | 'NO_TRANSFER'
  | 'TRANSFER_INTERNAL'
  | 'TRANSFER_EXTERNAL'
  | 'TRANSFER_ACD_CUSTOMER_SERVICE'
  | 'TRANSFER_EXTENSION_GROUP'
  | 'TRANSFER_ACD_SWITCHBOARD'
  | 'TRANSFER_PLAY_MUSIC'
  | 'TRANSFER_WELCOME_ATTENDANT'
  | 'TRANSFER_SPEED_DIAL'
  | 'TRANSFER_OC'
  | 'TRANSFER_ENT_VM';

export type ForwardingPropertyT = {|
  propertyType: 'forwarding',
  type: TransferTypeT,
  value: string,
  text: string
|};

export const getServiceName = (label: ?string): ?string =>
  (label || '').startsWith('kutsu:') ? (label || '').slice(6) : label;

const getLabel = (propFwd: DynamicPropertyFwdT) => {
  let target = propFwd.value || '';
  if (propFwd.destination) {
    target = propFwd.destination.userName || getServiceName(propFwd.destination.label) || '';
  }
  return target;
};

const convertToTransferType = (type: InternalAddressDestinationT) => {
  switch (type) {
    case 'ACD_SWITCHBOARD':
      return 'TRANSFER_ACD_SWITCHBOARD';
    case 'ACD_CUSTOMER_SERVICE':
      return 'TRANSFER_ACD_CUSTOMER_SERVICE';
    case 'EXTENSION_GROUP':
      return 'TRANSFER_EXTENSION_GROUP';
    case 'IVR_ENTERPRISE_VM':
      return 'TRANSFER_ENT_VM';
    case 'IVR_PLAY_MUSIC':
      return 'TRANSFER_PLAY_MUSIC';
    case 'SPEED_DIAL_COMMON':
      return 'TRANSFER_SPEED_DIAL';
    case 'SPEED_DIAL_OC_SERVICE':
      return 'TRANSFER_OC';
    case 'IVR_WELCOME_ATTENDANT':
      return 'TRANSFER_WELCOME_ATTENDANT';
    case 'EXTENSION':
      return 'TRANSFER_INTERNAL';
    default:
      return 'NO_TRANSFER';
  }
};

const getForwardingType = (propFwd: DynamicPropertyFwdT): TransferTypeT => {
  if (
    propFwd.value &&
    propFwd.value !== 'none' &&
    propFwd.value !== enterpriseVoiceMailAddress &&
    !propFwd.destination
  ) {
    return 'TRANSFER_EXTERNAL';
  }
  if (!propFwd.destination || !propFwd.destination.type) {
    return 'NO_TRANSFER';
  }
  return convertToTransferType(propFwd.destination.type);
};

export const getForwardingTypeByRelatedNumber = (
  value: string,
  relatedNumber: ?RelatedNumberT
): TransferTypeT => {
  if (value === enterpriseVoiceMailAddress) {
    return 'TRANSFER_ENT_VM';
  }
  if (!relatedNumber && value) {
    if (fieldValidators.externalContactPhoneNumberStrictValidator(value) === undefined) {
      return 'TRANSFER_EXTERNAL';
    }
    return 'TRANSFER_INTERNAL';
  }
  return relatedNumber ? convertToTransferType(relatedNumber.type) : 'TRANSFER_INTERNAL';
};

export const createFwdProperty = (fwdField: DynamicPropertyFwdT): ForwardingPropertyT => {
  console.assert(fwdField.type === 'Destination');
  const textValue = getLabel(fwdField)
    ? `${getLabel(fwdField)} (${fwdField.value || ''})`
    : fwdField.value || '';
  return {
    propertyType: 'forwarding',
    type: getForwardingType(fwdField),
    value: fwdField.value || '',
    text: textValue
  };
};

export const getPlaceholderToInput = (type: string, translate: TranslateT<>) => {
  switch (type) {
    case 'TRANSFER_ACD_SWITCHBOARD':
      return translate('callflows.forwardingField.searchAcdSwitchboard');
    case 'TRANSFER_INTERNAL':
      return translate('callflows.forwardingField.searchInternalUser');
    case 'TRANSFER_ACD_CUSTOMER_SERVICE':
      return translate('callflows.forwardingField.searchAcdCustomerService');
    case 'TRANSFER_EXTENSION_GROUP':
      return translate('callflows.forwardingField.searchExtensionGroup');
    case 'TRANSFER_IVR':
      return translate('callflows.forwardingField.searchIvr');
    case 'TRANSFER_WELCOME_ATTENDANT':
      return translate('callflows.forwardingField.searchWelcomeAttendant');
    case 'TRANSFER_SPEED_DIAL':
      return translate('callflows.forwardingField.searchSpeedDial');
    case 'TRANSFER_OC':
      return translate('callflows.forwardingField.searchOC');
    default:
      return '';
  }
};
