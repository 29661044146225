// @flow strict-local

import React, { type Element } from 'react';
import type { TableRowItemT } from './TableRow';

import styles from './ForwardingRowContents.module.scss';

export type PropsT = {
  columns: string[],
  rowItem: TableRowItemT
};

const TOP_ITEM = 0;
const BOTTOM_ITEM = 1;

const ForwardingRowContents = ({ columns, rowItem }: PropsT): Element<'div'> => {
  const destination = index => {
    const destinations = rowItem[columns[BOTTOM_ITEM]].props.children;
    const hasDestinations = destinations !== undefined;

    return (
      <div className={styles['destination-area']}>
        {hasDestinations && (
          <div
            id={`row-${rowItem.rowId}-column-destination`}
            className={styles['destination-container']}
            key={`destination-container-${rowItem.rowId}-${index}`} // eslint-disable-line react/no-array-index-key
          >
            {destinations[index]}
          </div>
        )}
      </div>
    );
  };

  const forwardings = rowItem[columns[TOP_ITEM]].props.children;
  const hasForwardings = forwardings !== undefined;

  return (
    <div>
      {hasForwardings &&
        forwardings.map((reason, index) => (
          <div
            className={styles['details-row']}
            key={`details-row-${rowItem.rowId}-${index}`} // eslint-disable-line react/no-array-index-key
          >
            <div
              id={`row-${rowItem.rowId}-column-reason`}
              data-cy={`row-${rowItem.rowId}-column-reason`}
              className={styles['reason-container']}
              key={`reason-container-${rowItem.rowId}-${index}`} // eslint-disable-line react/no-array-index-key
            >
              {reason}
            </div>
            {destination(index)}
          </div>
        ))}
    </div>
  );
};

export default ForwardingRowContents;
