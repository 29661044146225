import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCameraLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.4 6h-1.3c-.2 0-.4-.1-.5-.3C19 4.3 18.4 3 17 3h-5c-1.1 0-1.6.8-2.7 2.2l-.1.2c-.3.4-.4.6-.7.6h-6C.4 6 0 7.2 0 8.2v10.4c-.1 1.2.8 2.3 2.1 2.4h19.6c1.1 0 2.1-.8 2.2-2V8.2c.1-1-.3-2.2-2.5-2.2M23 18.9c-.1.6-.6 1.1-1.2 1.1H2.2c-.7-.1-1.2-.7-1.2-1.4V8.2C1 7.5 1.3 7 2.5 7h6c.6 0 1.2-.4 1.5-1l.2-.2C11.2 4.3 11.5 4 12 4h5c.6 0 1.1.6 1.7 2 .2.6.8 1 1.4 1h1.3c1.3 0 1.6.5 1.6 1.2zm-18.2-9c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1M15 7.5c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5 5.5-2.5 5.5-5.5S18 7.5 15 7.5m0 10c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5-2 4.5-4.5 4.5"/></svg>)}
    />
  );
}
  