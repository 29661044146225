// @flow
import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { Field, FormSection, type FormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Rating from 'react-rating';
import IconStarFilled from '@design-system/component-library/src/components/Icon/lib/IconStarFilled';
import IconStarRegular from '@design-system/component-library/src/components/Icon/lib/IconStarRegular';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../commonTypes';
import { createCloseFeedbackAction } from '../ducks/ui/header/headerUiActions';
import { BaseModal } from './BaseModal';
import { InputField, CheckboxField } from './InputComponent/ReduxFormField';
import ActionButton from './Button/ActionButton';
import CancelButton from './Button/CancelButton';
import { operations as currentUserOps } from '../ducks/currentUser';
import type { CurrentUserT } from '../ducks/currentUser/currentUserTypes';
import { createCsrfHeader } from '../utils/accessRightUtils';
import styles from './Feedback.module.scss';

type StatePropsT = {
  feedbackOpen: boolean,
  currentUser: CurrentUserT
};

type DispatchPropsT = {
  closeFeedback: typeof createCloseFeedbackAction,
  sendFeedback: typeof currentUserOps.sendFeedback
};

export type PropsT = {|
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<FormProps>,
  ...$Exact<WithTranslationProps>
|};

export type StateT = {|
  selectedRating: number,
  showValidationError: boolean,
  showSendingError: boolean,
  showInfoText: boolean,
  showSuccessView: boolean
|};

export class Feedback extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleRatingChanged = this.handleRatingChanged.bind(this);
    this.onChangeContactMe = this.onChangeContactMe.bind(this);
    this.renderSuccessView = this.renderSuccessView.bind(this);
    this.state = {
      selectedRating: 0,
      showValidationError: false,
      showSendingError: false,
      showInfoText: false,
      showSuccessView: false
    };
  }

  onClose: *;

  onClose() {
    const { closeFeedback } = this.props;
    this.setState({
      selectedRating: 0,
      showValidationError: false,
      showSendingError: false,
      showInfoText: false,
      showSuccessView: false
    });
    this.props.reset();
    closeFeedback();
  }

  onChangeContactMe: *;

  onChangeContactMe(event: *) {
    this.setState({ showInfoText: event.target.checked });
  }

  handleRatingChanged: *;

  handleRatingChanged(value: number) {
    this.setState({ selectedRating: value, showValidationError: false });
  }

  handleFormSubmit: *;

  async handleFormSubmit(values: *) {
    const { selectedRating } = this.state;
    const { sendFeedback, currentUser } = this.props;
    if (!selectedRating || selectedRating < 1) {
      this.setState({ showValidationError: true });
    } else {
      const result = await sendFeedback(
        {
          rating: selectedRating,
          message: (values.feedback && values.feedback.openFeedback) || '',
          allowContacting: (values.feedback && values.feedback.contactMe) || false,
          email: values.feedback && values.feedback.contactMe ? currentUser.email : null,
          userAgent: navigator.userAgent
        },
        createCsrfHeader(currentUser)
      );
      if (!result) {
        this.setState({ showSuccessView: true });
      } else {
        this.setState({ showSendingError: true });
      }
    }
  }

  renderSuccessView: *;

  renderSuccessView() {
    const { t } = this.props;
    return (
      <div className={styles['success-view']}>
        <div className={styles['success-image-content']}>
          <img className={styles['success-image']} src="/icon-thumb.svg" alt="success" />
        </div>
        <h2>{t('feedback.successTitle')}</h2>
        <div className={styles['success-text']}>{t('feedback.success')}</div>
        <div className={styles['button-content']}>
          <ActionButton
            id="ok-button"
            className={styles['ok-button']}
            label={t('feedback.confirm')}
            onClickAction={this.onClose}
          />
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, feedbackOpen, t } = this.props;
    return (
      feedbackOpen && (
        <BaseModal modalStyles={[styles.modal]} onClose={this.onClose}>
          {this.state.showSuccessView ? (
            this.renderSuccessView()
          ) : (
            <form
              onSubmit={handleSubmit((values: FormProps): Promise<void> =>
                this.handleFormSubmit(values)
              )}
            >
              <div className={styles['feedback-container']}>
                <FormSection name="feedback">
                  <div className={styles.content}>
                    <div>
                      <h2>{t('feedback.title')}</h2>
                    </div>
                    <div className={styles.info}>{t('feedback.info')}</div>
                    <div>
                      <h3>{t('feedback.rating')}</h3>
                    </div>
                    <Rating
                      id="rating"
                      className={styles.rating}
                      fullSymbol={<IconStarFilled color="blue" className={styles.rating} />}
                      emptySymbol={<IconStarRegular className={styles['rating--empty']} />}
                      initialRating={this.state.selectedRating}
                      onChange={this.handleRatingChanged}
                    />
                    {this.state.showValidationError && (
                      <div id="validation-error" className={styles['validation-error']}>
                        {t('feedback.error')}
                      </div>
                    )}
                    <div>
                      <h3>{t('feedback.openFeedback')}</h3>
                    </div>
                    <Field
                      id="openFeedback"
                      key="openFeedback"
                      name="openFeedback"
                      type="textarea"
                      minrows="7"
                      maxrows="7"
                      maxlength={550}
                      component={InputField}
                      placeholder={t('feedback.openFeedbackPlaceholder')}
                    />
                    <Field
                      id="contact-me"
                      key="contactMe"
                      name="contactMe"
                      component={CheckboxField}
                      onChange={this.onChangeContactMe}
                      className={styles['feedback-area']}
                    >
                      {t('feedback.contactMe')}
                    </Field>
                    {this.state.showInfoText && (
                      <div className={styles['contact-info']}>{t('feedback.contactMeInfo')}</div>
                    )}
                    <div className={styles['button-area']}>
                      <ActionButton
                        id="send-feedback-button"
                        className={styles['send-button']}
                        label={t('feedback.send')}
                        loading={false}
                        type="submit"
                      />
                      <CancelButton
                        id="cancel-feedback-button"
                        label={t('feedback.cancel')}
                        onClickAction={this.onClose}
                      />
                    </div>
                    {this.state.showSendingError && (
                      <div id="sending-error" className={styles['sending-error']}>
                        {t('feedback.sendingError')}
                      </div>
                    )}
                  </div>
                </FormSection>
              </div>
            </form>
          )}
        </BaseModal>
      )
    );
  }
}

const mapStateToProps = (state: StoreStateT) => ({
  feedbackOpen: state.ui.header.feedbackOpen,
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closeFeedback: createCloseFeedbackAction,
      sendFeedback: currentUserOps.sendFeedback
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, *, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'feedback',
    enableReinitialize: true
  })
)(Feedback);
