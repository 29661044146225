import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTiktokFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.533 6.008A6.07 6.07 0 0 1 16.463 0h-3.919v10.707l-.005 5.863a3.552 3.552 0 0 1-5.374 3.041 3.54 3.54 0 0 1-1.732-2.99 3.552 3.552 0 0 1 4.67-3.422V9.228a7.5 7.5 0 0 0-1.13-.084 7.52 7.52 0 0 0-5.648 2.525 7.37 7.37 0 0 0-1.854 4.436 7.34 7.34 0 0 0 2.188 5.717A7.5 7.5 0 0 0 8.973 24c.38 0 .76-.028 1.13-.084a7.5 7.5 0 0 0 4.183-2.094 7.33 7.33 0 0 0 2.201-5.22l-.019-8.758a9.7 9.7 0 0 0 2.202 1.281c1.23.521 2.534.78 3.872.78V6.003c.005.004-.01.004-.01.004Z"/></svg>)}
    />
  );
}
  