// @flow
import * as yup from 'yup';
import { type Location } from 'react-router-dom';
import type {
  InternalUserStateEntityT,
  UserEntityT
} from '../../../../ducks/entities/user/userTypes';
import type {
  CallForwardingDestinationT,
  CallForwardingFilterTypeT,
  CallForwardingT,
  CallForwardingTypeT,
  FiltersOnTargetNumberTypeT,
  PresenceStateTypeT,
  UpdateCallForwardingPayloadT
} from '../../../../ducks/entities/callForwarding/callForwardingTypes';
import validators from '../validators';
import type { TranslateT } from '../../../../commonTypes';

export type OverwriteOverlappingT = 'disableOld' | 'doNotCreate';

export type ForwardingFormT = {
  label: string,
  members: InternalUserStateEntityT[],
  presenceStates: PresenceStateTypeT[],
  forwardingTarget: { target: CallForwardingDestinationT, noAnswerForwardingDelay: number },
  filtersOnTargetNumber: FiltersOnTargetNumberTypeT[],
  specificCallerFilter: { filter: CallForwardingFilterTypeT, specificCaller: string },
  isEnterpriseForwarding: boolean,
  visualForwardingType: CallForwardingTypeT,
  forwardingType: CallForwardingTypeT,
  overwriteOverlapping: OverwriteOverlappingT,
  timeFilter: string
};

export const initializeForwardingForm = (
  forwarding: ?CallForwardingT,
  visualForwardingType: ?CallForwardingTypeT,
  isEnterpriseForwarding: boolean,
  user: ?UserEntityT,
  destinationName: string,
  contacts: *
): ForwardingFormT => {
  const fwd: $Shape<CallForwardingT> = forwarding || {};
  // $FlowFixMe unclear type
  return {
    label: fwd.label || '',
    members: user ? [user] : contacts,
    presenceStates: fwd.presenceStateFilter || [],
    forwardingTarget: {
      target:
        fwd.destination && fwd.destination.type
          ? { ...fwd.destination, text: destinationName }
          : { type: 'ENT_VM' },
      noAnswerForwardingDelay: fwd.noAnswerForwardingDelay || 45000
    },
    filtersOnTargetNumber: fwd.filtersOnTargetNumber || ['EXT', 'PSTN'],
    specificCallerFilter: {
      filter: fwd.filter || 'ALL_CALLS',
      specificCaller: fwd.specificCaller || ''
    },
    visualForwardingType: visualForwardingType || 'PRESENCE',
    forwardingType: visualForwardingType || 'PRESENCE',
    overwriteOverlapping: 'disableOld',
    isEnterpriseForwarding,
    timeFilter: fwd.timeFilter
  };
};

export const convertForwardingFormToForwardingData = (
  form: ForwardingFormT,
  presenceStates: PresenceStateTypeT[]
): UpdateCallForwardingPayloadT => {
  return {
    label: form.label,
    destination: form.forwardingTarget.target || { type: 'ENT_VM' },
    filter: form.specificCallerFilter.filter || 'ALL_CALLS',
    filtersOnTargetNumber: [...(form.filtersOnTargetNumber || [])],
    noAnswerForwardingDelay: form.forwardingTarget.noAnswerForwardingDelay,
    specificCaller: form.specificCallerFilter.specificCaller || '',
    isActive: true,
    presenceStateFilter: presenceStates || [],
    isEditable: !form.isEnterpriseForwarding,
    forwardingType: form.forwardingType === 'PRESENCE' ? 'ALWAYS' : form.forwardingType,
    timeFilter: form.timeFilter
  };
};

export const forwardingFormSchema = (
  translate: TranslateT<>,
  location: Location,
  forwardingType: CallForwardingTypeT
) => {
  const isEnterpriseForwarding = location.state && location.state.isEnterpriseForwarding;
  // $FlowFixMe
  return yup.object().shape({
    label: yup.string().required(translate('forwardingDetails.validation.label')),
    members: !isEnterpriseForwarding
      ? validators.validateUsers(translate('forwardingDetails.validation.userSelector'))
      : false,
    forwardingTarget: validators.validateForwardingTarget(translate),
    filtersOnTargetNumber: validators.validateFiltersOnTargetNumber(
      translate('forwardingDetails.validation.filtersOnTargetNumber')
    ),
    presenceStates:
      forwardingType === 'PRESENCE'
        ? validators.validatePresenceState(translate('forwardingDetails.validation.presenceState'))
        : false,
    forwardingType: yup.string().required(translate('forwardingDetails.validation.label')),
    specificCallerFilter: validators.validateSpecificCallerFilter(
      translate('forwardingDetails.validation.specificCaller')
    )
  });
};
