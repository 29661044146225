// @flow

import * as R from 'ramda';
import type { StateT, ActionT, EfaxEntityT } from './efaxTypes';

const initialState: StateT = {
  byId: {},
  allIds: [],
  totalCount: 0
};

const retrieveEfaxes = (state: StateT, action: ActionT): StateT => {
  switch (action.type) {
    case 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_REQUEST': {
      // page 1 means a new efax list has been requested and the old one gets removed
      if (action.meta.page === 1) {
        return {
          ...state,
          __metadata: {
            isRetrieving: true
          },
          allIds: [],
          byId: {}
        };
      }
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_SUCCESS': {
      const results = action.payload;
      let ids = [];
      if (results) {
        ids = R.map(R.prop('id'), results);
      }

      const newValues: { [string]: EfaxEntityT } =
        results && results.length > 0
          ? Object.assign(
              // $FlowFixMe spread-operation not recognized by flowtype
              ...results.map(entry => ({
                [entry.id]: { ...entry, enterpriseId: action.meta.enterpriseId }
              }))
            )
          : {};
      return {
        ...state,
        __metadata: {
          hasMore: results ? action.meta.length === results.length : false
        },
        allIds: R.union(state.allIds, ids),
        byId: {
          ...state.byId,
          ...newValues
        },
        totalCount: action.meta.saveTotalCount ? action.meta.totalCount : state.totalCount
      };
    }
    case 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/efax/RETRIEVE_EFAX_COLLECTION_CANCEL': {
      return state;
    }

    default:
      return state;
  }
};

const rootReducer = (state: StateT = initialState, action: ActionT): StateT => {
  return retrieveEfaxes(state, action);
};

const efaxReducer = {
  initialState,
  rootReducer
};

export default efaxReducer;
