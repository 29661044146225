// @flow

import React, { type Element } from 'react';
import classnames from 'classnames';
import styles from './ContentTabs.module.scss';
import { Tab } from './Tab';

type TabT = {
  id: string,
  name: string
};

type PropsT = {|
  activeTab: string,
  tabOptions: TabT[],
  updateActiveTab: string => void,
  children: Element<typeof Tab>[],
  tabSelectorStyle?: string
|};

export const ContentTabs = (props: PropsT): Element<*> => {
  const { activeTab, tabOptions, updateActiveTab, children, tabSelectorStyle } = props;

  const tabs = tabOptions.map(tab => (
    <button
      className={classnames(
        styles.selection,
        activeTab === tab.id ? styles['active-selection'] : ''
      )}
      id={`${tab.id}-tab-button`}
      key={`${tab.id}-tab-button`}
      onClick={() => updateActiveTab(tab.id)}
    >
      {tab.name}
    </button>
  ));

  const activeChildren = children.filter(child => child.props.id === activeTab);
  return (
    <div id="tabs-list" className={styles['tabs-list']}>
      <div className={classnames(styles.selector, tabSelectorStyle)}>{tabs}</div>
      <div className={styles['tabs-container']}>{activeChildren}</div>
    </div>
  );
};

export default ContentTabs;
