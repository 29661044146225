// @flow

/* eslint-disable no-use-before-define */

import type { HeaderUiStateT, UserViewActionT } from './headerUiTypes';

import type { UserActionT } from '../../entities/user/userTypes';

const initialState: HeaderUiStateT = {
  userDropdownOpen: false,
  helpDropdownOpen: false,
  usersImportOpen: false,
  feedbackOpen: false,
  onboardingOpen: false,
  changelogOpen: false,
  navigationMenuOpen: false
};

const menuDropdownReducer = (state: HeaderUiStateT, action: UserViewActionT): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_USER_DROPDOWN': {
      return {
        ...state,
        helpDropdownOpen: false,
        userDropdownOpen: true
      };
    }
    case 'ring/ui/header/CLOSE_USER_DROPDOWN': {
      return {
        ...state,
        userDropdownOpen: false
      };
    }
    default:
      return state;
  }
};

const helpMenuDropdownReducer = (
  state: HeaderUiStateT,
  action: UserViewActionT
): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_HELP_DROPDOWN': {
      return {
        ...state,
        userDropdownOpen: false,
        helpDropdownOpen: true
      };
    }
    case 'ring/ui/header/CLOSE_HELP_DROPDOWN': {
      return {
        ...state,
        helpDropdownOpen: false
      };
    }
    default:
      return state;
  }
};

const importUsersReducer = (state: HeaderUiStateT, action: UserViewActionT): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_IMPORT_USERS': {
      return {
        ...state,
        usersImportOpen: true
      };
    }
    case 'ring/ui/header/CLOSE_IMPORT_USERS': {
      return {
        ...state,
        usersImportOpen: false
      };
    }
    default:
      return state;
  }
};

const feedbackReducer = (state: HeaderUiStateT, action: UserViewActionT): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_FEEDBACK': {
      return {
        ...state,
        feedbackOpen: true
      };
    }
    case 'ring/ui/header/CLOSE_FEEDBACK': {
      return {
        ...state,
        feedbackOpen: false
      };
    }
    default:
      return state;
  }
};

const onboardingReducer = (state: HeaderUiStateT, action: UserViewActionT): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_ONBOARDING': {
      return {
        ...state,
        onboardingOpen: true
      };
    }
    case 'ring/ui/header/CLOSE_ONBOARDING': {
      return {
        ...state,
        onboardingOpen: false
      };
    }
    default:
      return state;
  }
};

const changelogReducer = (state: HeaderUiStateT, action: UserViewActionT): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_CHANGELOG': {
      return {
        ...state,
        changelogOpen: true
      };
    }
    case 'ring/ui/header/CLOSE_CHANGELOG': {
      return {
        ...state,
        changelogOpen: false
      };
    }
    default:
      return state;
  }
};

const navigationMenuReducer = (state: HeaderUiStateT, action: UserViewActionT): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_NAVIGATION_MENU': {
      return {
        ...state,
        navigationMenuOpen: true
      };
    }
    case 'ring/ui/header/CLOSE_NAVIGATION_MENU': {
      return {
        ...state,
        navigationMenuOpen: false,
        usersImportOpen: false
      };
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: HeaderUiStateT = initialState,
  action: UserActionT | UserViewActionT
): HeaderUiStateT => {
  switch (action.type) {
    case 'ring/ui/header/OPEN_USER_DROPDOWN':
    case 'ring/ui/header/CLOSE_USER_DROPDOWN': {
      return headerUiReducer.menuDropdownReducer(state, action);
    }
    case 'ring/ui/header/OPEN_HELP_DROPDOWN':
    case 'ring/ui/header/CLOSE_HELP_DROPDOWN': {
      return headerUiReducer.helpMenuDropdownReducer(state, action);
    }
    case 'ring/ui/header/OPEN_IMPORT_USERS':
    case 'ring/ui/header/CLOSE_IMPORT_USERS': {
      return headerUiReducer.importUsersReducer(state, action);
    }
    case 'ring/ui/header/OPEN_FEEDBACK':
    case 'ring/ui/header/CLOSE_FEEDBACK': {
      return headerUiReducer.feedbackReducer(state, action);
    }
    case 'ring/ui/header/OPEN_ONBOARDING':
    case 'ring/ui/header/CLOSE_ONBOARDING': {
      return headerUiReducer.onboardingReducer(state, action);
    }
    case 'ring/ui/header/OPEN_CHANGELOG':
    case 'ring/ui/header/CLOSE_CHANGELOG': {
      return headerUiReducer.changelogReducer(state, action);
    }
    case 'ring/ui/header/OPEN_NAVIGATION_MENU':
    case 'ring/ui/header/CLOSE_NAVIGATION_MENU': {
      return headerUiReducer.navigationMenuReducer(state, action);
    }
    default:
      return state;
  }
};

const headerUiReducer = {
  initialState,
  importUsersReducer,
  menuDropdownReducer,
  helpMenuDropdownReducer,
  feedbackReducer,
  onboardingReducer,
  changelogReducer,
  rootReducer,
  navigationMenuReducer
};

export default headerUiReducer;
