// @flow
import React, { type Element } from 'react';
import { useDispatch } from 'react-redux';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import ConfirmButton from '../../../../../components/Button/ConfirmButton';
import type { CallFlowGridElementT } from '../../../../../ducks/entities/callFlow';
import ContentFetchError from '../../../../../components/Error/ContentFetchError';
import { retrieve as callflowRetrieve } from '../../../../../ducks/entities/callFlow/callFlowOperations';
import styles from './ErrorView.module.scss';

export type PropsT = {
  gridElement: CallFlowGridElementT
};

export const ErrorView = (props: PropsT): Element<'div'> => {
  const { gridElement } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();

  return (
    <div className={styles['error-container']}>
      <ContentFetchError message={t('callflows.fetchErrorMessage')} />
      <ConfirmButton
        id={`reload-button-${gridElement.id}`}
        label={t('callflows.fetchErrorRetryLabel')}
        onClickAction={() => {
          dispatch(
            callflowRetrieve(
              gridElement.enterpriseId,
              gridElement.id,
              gridElement.type,
              CancelToken.source().token,
              true
            )
          );
        }}
      />
    </div>
  );
};

export default ErrorView;
