// @flow strict
/* eslint-disable no-use-before-define */
import * as R from 'ramda';
import type {
  CallForwardingStateT,
  CallForwardingActionT,
  RetrieveCallForwardingActionT,
  UpdateCallForwardingActionT,
  DeleteCallForwardingActionT,
  CreateCallForwardingActionT,
  RetrieveUserForwardingsActionT
} from './callForwardingTypes';

const initialState: CallForwardingStateT = {
  byId: {},
  byAddressNumber: {},
  __metadata: {}
};

const retrieveReducer = (
  state: CallForwardingStateT,
  action: RetrieveCallForwardingActionT
): CallForwardingStateT => {
  switch (action.type) {
    case 'ring/entity/callForwarding/RETRIEVE_REQUEST': {
      const { callForwardingId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            __metadata: {
              isRetrieving: true
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/RETRIEVE_SUCCESS': {
      const { callForwardingId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            ...action.payload,
            __metadata: {}
          }
        }
      };
    }
    case 'ring/entity/callForwarding/RETRIEVE_FAILURE': {
      const { callForwardingId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/RETRIEVE_CANCEL': {
      const { callForwardingId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            __metadata: {}
          }
        }
      };
    }
    default:
      return state;
  }
};

const retrieveUserForwardings = (
  state: CallForwardingStateT,
  action: RetrieveUserForwardingsActionT
): CallForwardingStateT => {
  const entId =
    R.path(['meta', 'enterpriseId'], action) || R.path(['payload', 'enterpriseId'], action) || '';
  const extension =
    R.path(['meta', 'addressNumber'], action) || R.path(['payload', 'addressNumber'], action) || '';
  if (!entId || !extension) {
    return state;
  }
  const entryId: string = `${entId}-${extension}`;
  switch (action.type) {
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_REQUEST': {
      return {
        ...state,
        byAddressNumber: {
          ...state.byAddressNumber,
          [entryId]: {
            values: [],
            __metadata: {
              isRetrieving: true
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_SUCCESS': {
      return {
        ...state,
        byAddressNumber: {
          ...state.byAddressNumber,
          [entryId]: {
            values: action.payload,
            __metadata: {
              complete: true
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_FAILURE': {
      return {
        ...state,
        byAddressNumber: {
          ...state.byAddressNumber,
          [entryId]: {
            ...state.byAddressNumber[entryId],
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_CANCEL': {
      return {
        ...state,
        byAddressNumber: {
          ...state.byAddressNumber,
          [entryId]: {
            ...state.byAddressNumber[entryId],
            __metadata: {}
          }
        }
      };
    }
    default:
      return state;
  }
};

const updateReducer = (
  state: CallForwardingStateT,
  action: UpdateCallForwardingActionT
): CallForwardingStateT => {
  switch (action.type) {
    case 'ring/entity/callForwarding/UPDATE_REQUEST': {
      const { id: callForwardingId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            ...state.byId[callForwardingId],
            __metadata: {
              isUpdating: true
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/UPDATE_SUCCESS': {
      const { callForwardingId } = action.meta;
      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            ...R.mergeDeepRight(state.byId[callForwardingId], action.payload),
            __metadata: {}
          }
        }
      };
    }
    case 'ring/entity/callForwarding/UPDATE_FAILURE': {
      const { id: callForwardingId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            ...state.byId[callForwardingId],
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/UPDATE_CANCEL': {
      const { callForwardingId } = action.meta;

      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: {
            ...state.byId[callForwardingId],
            __metadata: {}
          }
        }
      };
    }
    default:
      return state;
  }
};

const createReducer = (
  state: CallForwardingStateT,
  action: CreateCallForwardingActionT
): CallForwardingStateT => {
  switch (action.type) {
    case 'ring/entity/callForwarding/CREATE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isPosting: true
        }
      };
    }
    case 'ring/entity/callForwarding/CREATE_SUCCESS': {
      const { id: callForwardingId } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [callForwardingId]: action.payload
        },
        __metadata: {
          hasUpdated: true
        }
      };
    }
    case 'ring/entity/callForwarding/CREATE_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/callForwarding/CREATE_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const deleteReducer = (
  state: CallForwardingStateT,
  action: DeleteCallForwardingActionT
): CallForwardingStateT => {
  switch (action.type) {
    case 'ring/entity/callForwarding/DELETE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isDeleting: true
        }
      };
    }
    case 'ring/entity/callForwarding/DELETE_SUCCESS': {
      const { callForwardingId } = action.payload;
      return {
        ...state,
        byId: {
          ...R.dissoc(callForwardingId, state.byId)
        },
        __metadata: {
          hasDeleted: true
        }
      };
    }
    case 'ring/entity/callForwarding/DELETE_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.callForwardingId]: {
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/callForwarding/DELETE_CANCEL': {
      return state;
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: CallForwardingStateT = initialState,
  action: CallForwardingActionT
): CallForwardingStateT => {
  switch (action.type) {
    case 'ring/entity/callForwarding/RETRIEVE_REQUEST':
    case 'ring/entity/callForwarding/RETRIEVE_SUCCESS':
    case 'ring/entity/callForwarding/RETRIEVE_FAILURE':
    case 'ring/entity/callForwarding/RETRIEVE_CANCEL': {
      return callForwardingReducer.retrieveReducer(state, action);
    }
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_REQUEST':
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_SUCCESS':
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_FAILURE':
    case 'ring/entity/callForwarding/RETRIEVE_USER_FORWARDINGS_CANCEL': {
      return callForwardingReducer.retrieveUserForwardings(state, action);
    }
    case 'ring/entity/callForwarding/UPDATE_REQUEST':
    case 'ring/entity/callForwarding/UPDATE_SUCCESS':
    case 'ring/entity/callForwarding/UPDATE_FAILURE':
    case 'ring/entity/callForwarding/UPDATE_CANCEL': {
      return callForwardingReducer.updateReducer(state, action);
    }
    case 'ring/entity/callForwarding/CREATE_REQUEST':
    case 'ring/entity/callForwarding/CREATE_SUCCESS':
    case 'ring/entity/callForwarding/CREATE_FAILURE':
    case 'ring/entity/callForwarding/CREATE_CANCEL': {
      return callForwardingReducer.createReducer(state, action);
    }
    case 'ring/entity/callForwarding/DELETE_REQUEST':
    case 'ring/entity/callForwarding/DELETE_SUCCESS':
    case 'ring/entity/callForwarding/DELETE_FAILURE':
    case 'ring/entity/callForwarding/DELETE_CANCEL': {
      return callForwardingReducer.deleteReducer(state, action);
    }
    default:
      return state;
  }
};

const callForwardingReducer = {
  initialState,
  retrieveReducer,
  retrieveUserForwardings,
  updateReducer,
  createReducer,
  deleteReducer,
  rootReducer
};

export default callForwardingReducer;
