// @flow
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { DirectoryStateEntityT } from '../../../ducks/entities/directory';
import {
  goToForwardingDetailsModifyGroup,
  goToEnterpriseForwardingDetails,
  goToUserForwardingDetails
} from '../../../navigationOperations';
import type { ActiveForwardingT } from './DeleteForwardingDialog';
import PopUpMenuItem from './PopUpMenuItem';
import { createUpdateForwardingPopUp } from '../../../ducks/ui/callForwardings/callForwardingsUiActions';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';

import styles from './ForwardingPopOverSelector.module.scss';

type PropsT = {
  enterpriseId: string,
  activeForwarding: ?ActiveForwardingT,
  onForwardingActivityChange: (*, *) => Promise<void>,
  onDeleteForwarding: () => void,
  singleUser?: InternalUserStateEntityT
};

const ForwardingPopOverSelector = (props: PropsT) => {
  const {
    enterpriseId,
    activeForwarding,
    onForwardingActivityChange,
    onDeleteForwarding,
    singleUser
  } = props;

  const { t } = useTranslation();

  // redux
  const dispatch = useDispatch();

  const isEditable = R.path(['forwarding', 'isEditable'], activeForwarding) || false;
  const isEnabled = R.path(['forwarding', 'isActive'], activeForwarding) || false;
  const forwarding = R.path(['forwarding'], activeForwarding);

  const user: ?DirectoryStateEntityT = R.path(['user'], activeForwarding);

  const forwardingId = R.path(['forwarding', 'id'], activeForwarding) || '';

  const showDeleteButton = isEditable || (!isEditable && !user);
  const showModifyButtons =
    (isEditable || (!isEditable && !user)) && forwarding && !forwarding.serviceName;
  const showModifyGroupButtons = isEditable && forwarding && !forwarding.serviceName;

  const addressNumber = R.path(['publicInfo', 'addressNumber'], user);
  return (
    <div className={styles['popup-dialog']}>
      <div className={styles.header}>
        <span>
          {activeForwarding && isEditable
            ? t('forwardings.popup.header')
            : t('forwardings.popup.headerCompanySetting')}
        </span>
      </div>
      {showModifyButtons && (
        <PopUpMenuItem
          id={`modify-user-callforwarding-button_${forwardingId}`}
          label={`${t('forwardings.popup.edit')}`}
          onClickAction={() => {
            return isEditable
              ? dispatch(
                  goToUserForwardingDetails(enterpriseId, forwardingId, addressNumber, singleUser)
                )
              : dispatch(goToEnterpriseForwardingDetails(enterpriseId, forwardingId));
          }}
        />
      )}
      {!R.isEmpty(forwarding) && (
        <PopUpMenuItem
          id={`activate-user-callforwarding-button_${forwardingId}`}
          label={isEnabled ? t('forwardings.popup.disable') : t('forwardings.popup.enable')}
          onClickAction={async () => {
            if (activeForwarding && activeForwarding.forwarding) {
              await dispatch(
                // $FlowFixMe: inexact type
                createUpdateForwardingPopUp(activeForwarding.forwarding, activeForwarding.user)
              );

              onForwardingActivityChange(activeForwarding.forwarding, activeForwarding.user);
            }
          }}
        />
      )}
      {showModifyGroupButtons && (
        <PopUpMenuItem
          id={`modify-group-callforwarding-button_${forwardingId}`}
          label={`${t('forwardings.popup.modifyAsGroup')}`}
          onClickAction={() => {
            if (activeForwarding && activeForwarding.forwarding && activeForwarding.user) {
              dispatch(goToForwardingDetailsModifyGroup(enterpriseId, activeForwarding.forwarding));
            }
          }}
        />
      )}
      {showDeleteButton && (
        <PopUpMenuItem
          id={`remove-user-callforwarding-button_${forwardingId}`}
          label={`${t('forwardings.popup.remove')}`}
          onClickAction={async () => {
            await dispatch(
              // $FlowFixMe: inexact type
              createUpdateForwardingPopUp(activeForwarding.forwarding, activeForwarding.user)
            );
            onDeleteForwarding();
          }}
        />
      )}
    </div>
  );
};

export default ForwardingPopOverSelector;
