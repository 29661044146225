import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAsterixLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 12a.5.5 0 0 1-.5.5h-6.63l3.31 5.75a.5.5 0 0 1-.43.75.5.5 0 0 1-.43-.25L12 13l-3.32 5.75a.5.5 0 0 1-.43.25.5.5 0 0 1-.43-.75l3.31-5.75H4.5a.5.5 0 0 1 0-1h6.63L7.82 5.75a.5.5 0 1 1 .86-.5L12 11l3.32-5.75a.51.51 0 0 1 .68-.18.5.5 0 0 1 .18.68l-3.31 5.75h6.63a.5.5 0 0 1 .5.5"/></svg>)}
    />
  );
}
  