import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNotificationFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.5 18.75h-.01a1 1 0 0 1-.99-.998V11.5c0-4.434-3.414-8.081-7.75-8.462V.75a.75.75 0 0 0-1.5 0v2.288C6.914 3.419 3.5 7.066 3.5 11.5v6.252a1 1 0 0 1-.99.998H2.5a.75.75 0 0 0 0 1.5h19a.75.75 0 0 0 0-1.5M17 11.624a.625.625 0 0 1-.625-.625 3.86 3.86 0 0 0-2.153-3.472.624.624 0 1 1 .557-1.119 5.1 5.1 0 0 1 2.847 4.591.627.627 0 0 1-.626.625M11.998 24q-.33 0-.664-.065a3.35 3.35 0 0 1-2.225-1.539.75.75 0 0 1-.01-.758.76.76 0 0 1 .663-.382l4.476-.007h.001c.274 0 .527.145.662.38a.75.75 0 0 1-.007.758A3.38 3.38 0 0 1 11.998 24"/></svg>)}
    />
  );
}
  