import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFreedomeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.55.998 12.05 0H12L2.45.998a.5.5 0 0 0-.45.499v7.175c0 8.572 3.74 12.184 9.77 15.278a.55.55 0 0 0 .46 0c6-3.044 9.77-6.656 9.77-15.268V1.457a.5.5 0 0 0-.45-.459M21 8.632c0 7.983-3.3 11.346-9 14.26-5.7-2.934-9-6.227-9-14.26V1.906l9-.908 9 .908zm-5.83 2.664a.51.51 0 0 1 0 .709.49.49 0 0 1-.71 0 3.575 3.575 0 0 0-4.92 0 .5.5 0 0 1-.707.003l-.003-.003a.51.51 0 0 1 0-.709 4.497 4.497 0 0 1 6.34 0m1.68-2.315a.499.499 0 0 1-.7.709 5.88 5.88 0 0 0-8.3 0 .5.5 0 0 1-.703-.706l.003-.003a6.87 6.87 0 0 1 9.7 0M12 12.973a.879.879 0 1 0-.002 1.758.879.879 0 0 0 .002-1.758"/></svg>)}
    />
  );
}
  