import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileDocLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.6 5.9 15.2.5c-.3-.3-.7-.5-1.1-.5H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.9c0-.4-.2-.8-.4-1m-1.3.1h-3.8c-.3 0-.5-.2-.5-.5V1.7zM19 23H5c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h9v4.5c0 .8.7 1.5 1.5 1.5H20v15c0 .6-.5 1-1 1m-9.4-5.6c0 .7-.2 1.3-.6 1.7q-.6.6-1.8.6H5.9v-4.5h1.4c.7 0 1.3.2 1.7.6.4.3.6.9.6 1.6m-1 0c0-1-.4-1.5-1.3-1.5h-.4v3h.4c.9 0 1.3-.5 1.3-1.5m5.6 0c0 .8-.2 1.3-.5 1.7-.4.4-.9.6-1.6.6s-1.2-.2-1.6-.6-.5-1-.5-1.7c0-.8.2-1.3.5-1.7.4-.4.9-.6 1.6-.6s1.2.2 1.6.6c.3.4.5 1 .5 1.7m-3.3 0c0 .5.1.9.3 1.1.2.3.5.4.8.4.8 0 1.1-.5 1.1-1.5s-.4-1.5-1.1-1.5c-.4 0-.7.1-.8.4-.2.2-.3.6-.3 1.1m5.6-1.5c-.4 0-.6.1-.8.4s-.3.6-.3 1.1c0 1 .4 1.5 1.1 1.5.3 0 .7-.1 1.1-.2v.8c-.4.2-.8.2-1.2.2q-.9 0-1.5-.6c-.3-.4-.5-1-.5-1.7q0-.75.3-1.2c.2-.4.4-.6.7-.8s.7-.3 1.1-.3.9.1 1.3.3l-.3.8c-.1-.1-.3-.2-.5-.2-.1-.1-.3-.1-.5-.1"/></svg>)}
    />
  );
}
  