import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHelpRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.12 15a1.13 1.13 0 1 0 1.13 1.13v-.01A1.12 1.12 0 0 0 12.13 15zm3.63-4.75A3.75 3.75 0 0 1 12 14a.75.75 0 0 1 0-1.5 2.25 2.25 0 1 0-2.25-2.25.75.75 0 0 1-1.5 0 3.75 3.75 0 0 1 7.5 0M12 1.5c5.79 0 10.5 4.71 10.5 10.5S17.79 22.5 12 22.5 1.5 17.79 1.5 12 6.21 1.5 12 1.5M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0"/></svg>)}
    />
  );
}
  