// @flow

import React, { type Element } from 'react';
import type { CallFlowEntityT } from '../../../../ducks/entities/callFlow';
import AcdGroupElement from './AcdGroupElement';
import WelcomeAttendantElement from './WelcomeAttendantElement';
import PlayMusicElement from './PlayMusicElement';
import ExtensionGroupElement from './ExtensionGroupElement';
import SpeedDialElement from './SpeedDialElement';
import ErrorBoundary from '../../../../components/Error/ErrorBoundary';
import OcElement from './OcElement';

export type PropsT = {|
  callFlowData: CallFlowEntityT,
  enterpriseId: string,
  single: boolean,
  hideDescription?: boolean,
  showError?: boolean,
  errorElement: Element<*>
|};

export const CallFlowElement = (props: PropsT) => {
  const {
    callFlowData,
    enterpriseId,
    hideDescription = false,
    showError = false,
    errorElement,
    single
  } = props;

  const commonProps = {
    enterpriseId,
    hideDescription,
    single
  };

  const getCallflowElement = () => {
    switch (callFlowData.type) {
      case 'WELCOME_ATTENDANT':
        return (
          <WelcomeAttendantElement
            {...commonProps}
            data={callFlowData}
            key={`${callFlowData.id}-WELCOME_ATTENDANT`}
          />
        );
      case 'ACD_CUSTOMER_SERVICE':
      case 'ACD_SWITCHBOARD':
        return (
          <AcdGroupElement {...commonProps} data={callFlowData} key={`${callFlowData.id}-ACD`} />
        );
      case 'PLAY_MUSIC':
        return (
          <PlayMusicElement
            {...commonProps}
            data={callFlowData}
            key={`${callFlowData.id}-PLAY_MUSIC`}
          />
        );
      case 'EXTENSION_GROUP':
        return (
          <ExtensionGroupElement
            {...commonProps}
            data={callFlowData}
            key={`${callFlowData.id}-EXTENSION_GROUP`}
          />
        );
      case 'SPEED_DIAL':
        return (
          <SpeedDialElement
            {...commonProps}
            data={callFlowData}
            key={`${callFlowData.id}-SPEED_DIAL`}
          />
        );
      case 'OC':
        return <OcElement {...commonProps} data={callFlowData} key={`${callFlowData.id}-OC`} />;
      default:
        return null;
    }
  };

  return (
    <ErrorBoundary showError={showError} errorElement={errorElement}>
      {getCallflowElement()}
    </ErrorBoundary>
  );
};
export default CallFlowElement;
