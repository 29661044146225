// @flow
import React, { type Element } from 'react';
import classnames from 'classnames';
import AudioPlayerContainer from '../../../../../../components/AudioPlayer/AudioPlayerContainer';
import type { IvrAudioT } from '../../../../components/edit/children/audio/CallflowAudioUtils';
import styles from './WelcomeAttendantAudio.module.scss';

type PropsT = {|
  title: string,
  audioPlayData: IvrAudioT,
  active?: boolean,
  key?: string,
  postDescription?: string,
  noAudioLabel?: string
|};

type AudioDataT = {|
  loading?: boolean,
  error?: boolean,
  audio: IvrAudioT
|};

export const WelcomeAttendantAudio = (props: PropsT): Element<'div'> => {
  const { key, title, active, postDescription, noAudioLabel, audioPlayData } = props;
  const filename = (audioPlayData.filename || '').split('.')[0];
  const audioData: AudioDataT = {
    audio: {
      ...audioPlayData,
      filename: `${filename}.ogg`
    },
    loading: undefined,
    error: undefined
  };

  const activeStyle = active ? styles.active : styles.inactive;
  return (
    <div className={styles.detail} key={key}>
      <div className={classnames(styles.header, activeStyle)}>{title}</div>
      <div className={classnames(styles.value, activeStyle)}>
        <AudioPlayerContainer {...audioData} active={active} customNoAudioLabel={noAudioLabel} />
      </div>
      {postDescription && (
        <div className={classnames(styles.value, activeStyle)}>{postDescription}</div>
      )}
    </div>
  );
};

export default WelcomeAttendantAudio;
