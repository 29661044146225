// @flow strict-local

import { CancelToken, type CancelTokenSource } from 'axios';
import React, { Component, type Element } from 'react';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT, ExactPropsT } from '../../commonTypes';
import {
  selectors as selectHistory,
  operations as historyOps
} from '../../ducks/entities/historyAction';
import type { HistoryActionStateEntityT } from '../../ducks/entities/historyAction/historyActionTypes';
import BaseContainer from '../BaseContainer/BaseContainer';
import HistoryActionsTable from './HistoryActionsTable/HistoryActionsTable';
import TopNavigation from '../navigation/TopNavigation';
import styles from './HistoryActions.module.scss';

type StatePropsT = {
  historyActions: HistoryActionStateEntityT[],
  hasMore: boolean,
  lastRequestedPage: number,
  isRetrieving: boolean
};

type DispatchPropsT = {
  retrieveHistory: typeof historyOps.retrieveCollection
};

export type OwnPropsT = {};

type StateT = {
  selectedActionFilter: ?string
};

export type PropsT = {|
  ...ExactPropsT<OwnPropsT, StatePropsT, DispatchPropsT>,
  ...$Exact<ContextRouter>,
  ...$Exact<WithTranslationProps>
|};

export const PAGE_SIZE = 100;

export class HistoryActions extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.retrieveHistoryRequestCancelTokenSource = CancelToken.source();
    this.state = {
      selectedActionFilter: null
    };
  }

  componentDidMount() {
    this.loadMore(1);
  }

  componentWillUnmount() {
    this.retrieveHistoryRequestCancelTokenSource.cancel();
  }

  generateCommonActionArray: () => string[];

  generateCommonActionArray() {
    const { t } = this.props;
    return [
      {
        label: t('generic.commonAction.all'),
        value: t('generic.commonAction.all')
      },
      {
        label: t('generic.commonAction.login'),
        value: t('generic.commonAction.login')
      },
      {
        label: t('generic.commonAction.create'),
        value: t('generic.commonAction.create')
      },
      {
        label: t('generic.commonAction.delete'),
        value: t('generic.commonAction.delete')
      },
      {
        label: t('generic.commonAction.update'),
        value: t('generic.commonAction.update')
      },
      {
        label: t('generic.commonAction.removeProperties'),
        value: t('generic.commonAction.removeProperties')
      },
      {
        label: t('generic.commonAction.setProperties'),
        value: t('generic.commonAction.setProperties')
      }
    ];
  }

  retrieveCommonAction: string => string;

  retrieveCommonAction(value: string) {
    const { t } = this.props;
    const COMMON_ACTION = {
      [t('generic.commonAction.all')]: '',
      [t('generic.commonAction.login')]: 'login',
      [t('generic.commonAction.create')]: 'createInstance',
      [t('generic.commonAction.delete')]: 'deleteInstances',
      [t('generic.commonAction.update')]: 'updateInstances',
      [t('generic.commonAction.removeProperties')]: 'invokeUpdate_removeProperties',
      [t('generic.commonAction.setProperties')]: 'invokeUpdate_setProperty'
    };
    return COMMON_ACTION[value];
  }

  loadMore: (number, ?string, ?boolean) => void;

  loadMore(page: number, value: string = '', emptyList: boolean = false): void {
    const {
      match: {
        params: { id: enterpriseId }
      },
      retrieveHistory
    } = this.props;
    if (enterpriseId) {
      retrieveHistory(
        enterpriseId,
        this.retrieveHistoryRequestCancelTokenSource.token,
        {
          page,
          size: PAGE_SIZE,
          sort: 'actionTimeStamp,desc'
        },
        this.retrieveCommonAction(value),
        emptyList
      );
    }
  }

  updateActionFilter: string => void;

  updateActionFilter(value: string) {
    this.setState({
      selectedActionFilter: value
    });
    this.loadMore(1, value, true);
  }

  retrieveHistoryRequestCancelTokenSource: CancelTokenSource;

  render(): Element<typeof BaseContainer> {
    const { t, historyActions, hasMore, isRetrieving, lastRequestedPage } = this.props;
    const { selectedActionFilter } = this.state;

    return (
      <BaseContainer header={<TopNavigation />}>
        <div id="search-history-page" className={styles['search-history-page']}>
          <Dropdown
            id="history-action-field"
            items={this.generateCommonActionArray()}
            selectedValue={selectedActionFilter || t('generic.commonAction.all')}
            className={styles.dropdown}
            onValueChange={element => {
              const { innerText = '' } = element;
              this.updateActionFilter(innerText);
            }}
          />
        </div>
        <div id="history-page" className={styles['history-page']}>
          <HistoryActionsTable
            historyActions={historyActions}
            translate={t}
            allowLoadMore={hasMore}
            onInfiniteScrollLoadMore={this.loadMore}
            isRetrieving={isRetrieving}
            lastRequestedPage={lastRequestedPage}
          />
        </div>
      </BaseContainer>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => {
  const {
    // $FlowFixMe
    match: {
      params: { id: enterpriseId }
    }
  } = ownProps;

  return {
    historyActions: enterpriseId
      ? selectHistory.getHistoryCollectionByEnterpriseId(state, enterpriseId)
      : [],
    hasMore: state.ui.historyActions.historyActionList.__metadata.hasMore === true, // eslint-disable-line no-underscore-dangle
    lastRequestedPage: state.ui.historyActions.historyActionList.__metadata.lastRequestedPage || 0, // eslint-disable-line no-underscore-dangle
    isRetrieving: state.ui.historyActions.historyActionList.__metadata.isRetrieving === true // eslint-disable-line no-underscore-dangle
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retrieveHistory: historyOps.retrieveCollection
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<$Diff<PropsT, ContextRouter>, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter
)(HistoryActions);
