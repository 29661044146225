import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWhatsappRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.383 0 0 5.383 0 11.999c0 2.413.711 4.727 2.062 6.717L.777 22.535a.75.75 0 0 0 .94.954l3.987-1.276A11.95 11.95 0 0 0 12.001 24C18.617 24 24 18.617 24 12S18.617 0 12 0m.001 22.5c-2.062 0-4.059-.601-5.776-1.736a.75.75 0 0 0-.643-.088l-2.911.932.933-2.772a.75.75 0 0 0-.104-.68A10.4 10.4 0 0 1 1.5 12C1.5 6.21 6.21 1.5 12 1.5S22.5 6.21 22.5 12s-4.71 10.5-10.499 10.5m6.177-7.794c.207.347.146.92-.156 1.459-.688 1.229-1.831 1.87-3.318 1.87a5 5 0 0 1-.367-.013c-3.099-.209-6.872-3.067-8.076-6.116-.529-1.341-.812-3.312.848-5.088.301-.329.858-.488 1.387-.395.402.071.703.283.827.582l.34.823c.242.589.517 1.256.562 1.349.073.146.154.37.024.627-.086.173-.127.21-.278.35-.079.072-.19.175-.362.346-.154.153-.229.242-.109.447.864 1.489 2.204 2.67 3.982 3.514l.104.05c.268.133.368.091.487-.045.156-.181.445-.591.661-.917.262-.393.576-.28.809-.194.31.112 2.003.952 2.02.96l.152.075c.231.111.384.184.463.316q0-.001 0 0"/></svg>)}
    />
  );
}
  