import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBankLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M13.1 1.3c-.3-.2-.7-.3-1.1-.3s-.8.1-1.1.3L1.2 7.1l-.2.2v.3c0 .1.1.2.2.3s.2.1.3.1h21c.1 0 .2 0 .3-.1s.2-.2.2-.3v-.3c0-.1-.1-.2-.2-.2zm-.5.9c-.2-.1-.4-.2-.6-.2s-.4.1-.6.2L3.3 7h17.4zM1 22.5c0-.1.1-.3.1-.4.1 0 .3-.1.4-.1h21c.1 0 .3.1.4.1.1.1.1.2.1.4s-.1.3-.1.4c-.1.1-.2.1-.4.1h-21c-.1 0-.3-.1-.4-.1 0-.1-.1-.3-.1-.4m3-12c0-.1-.1-.3-.1-.4-.1 0-.3-.1-.4-.1s-.3.1-.4.1c0 .1-.1.3-.1.4v9c0 .1.1.3.1.4.1 0 .3.1.4.1h3c.1 0 .3-.1.4-.1 0-.1.1-.3.1-.4v-9c0-.1-.1-.3-.1-.4-.1 0-.3-.1-.4-.1s-.3.1-.4.1c0 .1-.1.3-.1.4V19H4zm6.5-.5c.1 0 .3.1.4.1.1.1.1.2.1.4V19h2v-8.5c0-.1.1-.3.1-.4.1-.1.2-.1.4-.1s.3.1.4.1c.1.1.1.2.1.4v9c0 .1-.1.3-.1.4-.1.1-.2.1-.4.1h-3c-.1 0-.3-.1-.4-.1-.1-.1-.1-.2-.1-.4v-9c0-.1.1-.3.1-.4.1 0 .3-.1.4-.1m7.5.5c0-.1-.1-.3-.1-.4-.1-.1-.2-.1-.4-.1s-.3.1-.4.1c-.1.1-.1.2-.1.4v9c0 .1.1.3.1.4.1.1.2.1.4.1h3c.1 0 .3-.1.4-.1.1-.1.1-.2.1-.4v-9c0-.1-.1-.3-.1-.4-.1-.1-.2-.1-.4-.1s-.3.1-.4.1c-.1.1-.1.2-.1.4V19h-2z"/></svg>)}
    />
  );
}
  