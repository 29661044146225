import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPictureFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 3.857v16.286C23 21.167 22.103 22 21 22H3c-1.103 0-2-.833-2-1.857V3.857C1 2.833 1.897 2 3 2h18c1.103 0 2 .833 2 1.857M6 8.875c1.034 0 1.875-.841 1.875-1.875S7.034 5.125 6 5.125 4.125 5.966 4.125 7 4.966 8.875 6 8.875m13.828 9.442L15.426 10.4h-.001a1.11 1.11 0 0 0-.938-.577 1.09 1.09 0 0 0-.979.5L10.5 14.831l-1.064-1.597c-.418-.627-1.454-.627-1.872 0l-3.376 5.058a.375.375 0 0 0 .312.583h15c.133 0 .256-.07.323-.185a.37.37 0 0 0 .005-.373"/></svg>)}
    />
  );
}
  