// @flow
import { useEffect } from 'react';
import { CancelToken, CancelTokenSource } from 'axios';

let requestCancelTokenSource: CancelTokenSource;
const useRequestToken = () => {
  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return requestCancelTokenSource;
};

export default useRequestToken;
