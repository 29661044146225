// @flow

import React, { type Element, useState } from 'react';
import Table from '@design-system/component-library/src/components/Table';
import Modal from '@design-system/component-library/src/components/Modal';
import Popover from '@design-system/component-library/src/components/Popover';
import IconMoreFilled from '@design-system/component-library/src/components/Icon/lib/IconMoreFilled';
import IconAddCircleFilled from '@design-system/component-library/src/components/Icon/lib/IconAddCircleFilled';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';
import { useTranslation } from 'react-i18next';
import type { InternalUserStateEntityT } from '../../ducks/entities/user/userTypes';
import PopUpMenuItem from '../../scenes/callForwardings/components/PopUpMenuItem';
import { closePopovers, getAvailabilityInfo, parsePresenceDate } from '../../helpers';
import AvailabilityIndicator from '../Avatar/AvailabilityIndicator';
import ForwardingTableCell from '../../scenes/callForwardings/userForwardings/ForwardingTableCell';
import Reason from '../ForwardingsTable/Reason';
import Destination from '../ForwardingsTable/Destination';
import DeletePresenceModal from '../DeletePresenceModal/DeletePresenceModal';
import CreateUpdatePresenceModal from '../CreateUpdatePresenceModal/CreateUpdatePresenceModal';

import styles from './PresenceCalendarListing.module.scss';

export type PropsT = {
  userId: string,
  selectedDate: moment,
  reloadFunc: () => void
};

const PresenceCalendarListing = ({ userId, selectedDate, reloadFunc }: PropsT): Element<'div'> => {
  const { t, i18n } = useTranslation();
  const user: InternalUserStateEntityT = useSelector(state => state.entities.user.byId[userId]);
  const activeLanguage = i18n.language;
  const [selectedCalendarSlot, setSelectedCalendarSlot] = useState();
  const NUMBER_OF_DAYS_TO_SHOW = 7;
  const DAYS_IN_YEAR = 365;
  const deleteModalRef = React.useRef<Modal>();
  const presenceModalRef = React.useRef<Modal>();

  const getForwarding = schedule => {
    const userForwarding = user.forwardings
      ? user.forwardings.find(
          fwd =>
            fwd.isActive &&
            fwd.presenceStateFilter &&
            fwd.presenceStateFilter.includes(schedule.presenceState)
        )
      : null;
    if (!userForwarding) {
      return null;
    }

    return (
      <div key={`user-forwarding-${userForwarding.id}`} className={styles['forwarding-item']}>
        <ForwardingTableCell userForwarding={userForwarding} className={styles['forwarding-cell']}>
          <Reason
            user={user}
            userForwarding={userForwarding}
            forceSmall
            enterpriseId=""
            onForwardingActivityChange={() => new Promise(() => {})}
            onDeleteForwarding={() => {}}
          />
        </ForwardingTableCell>
        <ForwardingTableCell userForwarding={userForwarding} className={styles['forwarding-cell']}>
          <Destination user={user} userForwarding={userForwarding} forceSmall />
        </ForwardingTableCell>
      </div>
    );
  };

  const generateWeekSlotTimeSlotDate = (currentDate, startTime, endTime) => {
    if (
      endTime &&
      startTime.hour() === 0 &&
      startTime.minute() === 0 &&
      endTime.hour() === 23 &&
      endTime.minute() === 59
    ) {
      return t('presenceCalendarListing.allDayEvent');
    }
    return `${startTime.format('HH:mm')} - ${endTime ? endTime.format('HH:mm') : ''}`;
  };

  const generateAbsoluteTimeSlotDate = (currentDate, startTime, endTime) => {
    if (currentDate.isSame(startTime, 'date')) {
      if (endTime) {
        if (
          currentDate
            .clone()
            .add(1, 'days')
            .isSame(endTime, 'date') &&
          endTime.hour() === 0 &&
          endTime.minute() === 0
        ) {
          return t('presenceCalendarListing.allDayEvent');
        }
        if (currentDate.isSame(endTime, 'date')) {
          return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
        }
      }
      return `${startTime.format('HH:mm')} -`;
    }
    if (endTime && currentDate.isSame(endTime, 'date')) {
      return `- ${endTime.format('HH:mm')}`;
    }
    return t('presenceCalendarListing.allDayEvent');
  };

  const generateDateTime = (currentDate, startTime, endTime, isAbsoluteTime) => {
    return isAbsoluteTime
      ? generateAbsoluteTimeSlotDate(currentDate, startTime, endTime)
      : generateWeekSlotTimeSlotDate(currentDate, startTime, endTime);
  };

  const generateDayRows = (currentDate, calendar) => {
    const dataRows = [];
    let schedule = calendar.weekSchedule[currentDate.format('dddd').toUpperCase()];
    const absoluteTimes = calendar.absoluteTimeSlots.filter(slot => {
      return (
        currentDate.isBetween(
          parsePresenceDate(slot.startTime).set({ hour: 0, minute: 0 }),
          slot.endTime
            ? parsePresenceDate(slot.endTime).set({ hour: 24, minute: 0 })
            : moment().add(1, 'years')
        ) &&
        (!slot.endTime ||
          !currentDate.isSame(slot.endTime, 'date') ||
          (currentDate.isSame(slot.endTime, 'date') &&
            parsePresenceDate(slot.endTime).hour() !== 0) ||
          parsePresenceDate(slot.endTime).minutes() !== 0)
      );
    });

    schedule = schedule.concat(absoluteTimes);
    const sortedSchedule = [
      ...schedule.sort(
        (a, b) =>
          parsePresenceDate(a.startTime).valueOf() - parsePresenceDate(b.startTime).valueOf()
      )
    ];
    currentDate.locale(activeLanguage || 'fi');
    let dateRows = 0;
    if (sortedSchedule.length > 0) {
      dateRows++;
      sortedSchedule.forEach((s, index) => {
        const availability = getAvailabilityInfo(s.presenceState, null, t);
        const startTime = parsePresenceDate(s.startTime);
        const endTime = s.endTime ? parsePresenceDate(s.endTime) : null;
        dataRows.push({
          date: (
            <div className={styles['date-field']}>
              {index === 0 ? (
                <div className={styles['date-field--first']}>
                  <div className={styles['date-info']}>{currentDate.format('DD.MM.')}</div>
                  <div>{currentDate.format('dd')}</div>
                </div>
              ) : (
                <div className={styles['date-field--first']} />
              )}
            </div>
          ),
          time: (
            <div className={styles['time-field']}>
              {generateDateTime(currentDate, startTime, endTime, s.startTime.includes('T'))}
            </div>
          ),
          state: (
            <div>
              <div className={styles['availability-field']}>
                <AvailabilityIndicator
                  status={availability.icon}
                  size="xsmall"
                  className={styles['availability-icon']}
                />
                {availability.text}
              </div>
              <div className={styles['custom-field']}>{s.customPresenceState}</div>
            </div>
          ),
          callForwarding: <div className={styles['forwarding-field']}>{getForwarding(s)}</div>,
          action: (
            <Popover
              triggerElement={<IconMoreFilled size="m" />}
              placement="bottom"
              i18n_popover_triggerAriaLabel="Action"
              onOpen={popover => closePopovers(popover)}
            >
              <PopUpMenuItem
                id={`modify-presence-button_${s.id}`}
                label={`${t('presenceCalendarListing.popupEdit')}`}
                onClickAction={() => {
                  if (presenceModalRef.current) {
                    setSelectedCalendarSlot({ ...s });
                    presenceModalRef.current.openModal();
                  }
                }}
              />
              <PopUpMenuItem
                id={`remove-presence-button_${s.id}`}
                label={`${t('presenceCalendarListing.popupRemove')}`}
                onClickAction={() => {
                  if (deleteModalRef.current) {
                    setSelectedCalendarSlot({ ...s });
                    deleteModalRef.current.openModal();
                  }
                }}
              />
            </Popover>
          )
        });
      });
    }

    return { dataRows, dateRows };
  };

  const generateRows = () => {
    let allDataRows = [];
    let generatedDateRows = 0;
    let daysToAdd = 0;
    while (generatedDateRows < NUMBER_OF_DAYS_TO_SHOW) {
      const currentDate = selectedDate
        .clone()
        .locale('en')
        .add(daysToAdd, 'days');
      const { dataRows, dateRows } = generateDayRows(currentDate, user.calendar);
      allDataRows = allDataRows.concat(dataRows);
      generatedDateRows += dateRows;
      daysToAdd++;
      if (daysToAdd > DAYS_IN_YEAR) {
        break;
      }
    }
    return allDataRows;
  };
  const tableRows = user.calendar ? generateRows() : [];

  return (
    <div className={styles['table-area']}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        id="add-state"
        className={styles['add-button']}
        onClick={() => {
          if (presenceModalRef.current) {
            setSelectedCalendarSlot(null);
            presenceModalRef.current.openModal();
          }
        }}
        role="button"
        tabIndex="0"
      >
        <span className={styles['add-icon']}>
          <IconAddCircleFilled size="s" color="brand-blue" />
        </span>
        <span className={styles['add-message']}>{t('presenceCalendarListing.addState')}</span>
      </div>
      {tableRows.length > 0 ? (
        <Table
          id="presenceTable"
          useRowHeader
          hover
          columns={[
            { key: 'date', label: t('presenceCalendarListing.dateTime') },
            { key: 'time' },
            { key: 'state', label: t('presenceCalendarListing.state') },
            { key: 'callForwarding', label: t('presenceCalendarListing.callForwarding') },
            { key: 'action' }
          ]}
          rows={tableRows}
        />
      ) : (
        <div className={styles['empty-table']}>{t('presenceCalendarListing.noUserPresence')}</div>
      )}

      {user.calendar && (
        <CreateUpdatePresenceModal
          enterpriseId={user.enterpriseId}
          calendarId={user.calendar.id}
          userId={userId}
          modalRef={presenceModalRef}
          selectedCalendarSlot={selectedCalendarSlot}
          reloadFunc={reloadFunc}
        />
      )}
      <DeletePresenceModal
        modalRef={deleteModalRef}
        selectedCalendarSlot={selectedCalendarSlot}
        enterpriseId={user.enterpriseId}
        calendarId={user.calendar.id}
        reloadFunc={reloadFunc}
      />
    </div>
  );
};
export default PresenceCalendarListing;
