// @flow strict-local

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import type { SpeedDialEntityT } from '../../../../ducks/entities/speedDial/speedDialTypes';
import type { OcGridElementT } from '../../../../ducks/entities/callFlow/callFlowTypes';

import GridElementContainer from '../gridElement/GridElementContainer';
import OcDetails from '../details/oc/OcDetails';

export type PropsT = {|
  data: SpeedDialEntityT,
  enterpriseId: string,
  hideDescription: boolean,
  single?: boolean
|};

export const OcElement = (props: PropsT): Element<typeof GridElementContainer> => {
  const { data, enterpriseId, hideDescription, single } = props;
  const { t } = useTranslation();

  const gridElement: OcGridElementT = {
    id: data.id,
    type: 'OC',
    name: data.label || '',
    number: data.addressNumber,
    description: t('callflows.ocElement.description'),
    enterpriseId,
    rootNode: {
      id: 'oc_node',
      type: 'SPEED_DIAL'
    }
  };
  const callflowId = `${enterpriseId}-OC-${data.id}`;
  return (
    <GridElementContainer
      gridElement={gridElement}
      hideDescription={hideDescription}
      single={single}
      viewContentSupplier={() => <OcDetails callflowId={callflowId} />}
      editContentSupplier={() => <div />}
    />
  );
};

export default OcElement;
