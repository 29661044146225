import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailReadFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M1.436 8.534a.5.5 0 0 1 .007-.798l9.013-6.707c.85-.703 2.219-.711 3.098.007l9.159 6.816a.5.5 0 0 1 .001.802l-9.865 7.406c-.243.2-.532.308-.844.308h-.001a1.35 1.35 0 0 1-.866-.32zm22.288 1.288a.5.5 0 0 0-.523.047l-9.58 7.175c-.902.747-2.34.746-3.264-.019L.806 9.628a.499.499 0 0 0-.806.396v11.475A2 2 0 0 0 2 23.5h20a2 2 0 0 0 2-2.002V10.269a.5.5 0 0 0-.276-.447"/></svg>)}
    />
  );
}
  