import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAddressBookRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 0H4a2.25 2.25 0 0 0-2.25 2.25v19.5A2.25 2.25 0 0 0 4 24h16a2.25 2.25 0 0 0 2.25-2.25V2.25A2.25 2.25 0 0 0 20 0M3.25 21.75V2.25A.76.76 0 0 1 4 1.5h2.75v21H4a.76.76 0 0 1-.75-.75m17.5 0a.76.76 0 0 1-.75.75H8.25v-21H20a.76.76 0 0 1 .75.75zM16.9 4.03h-4.8a2.3 2.3 0 0 0-2.35 2.22 2.3 2.3 0 0 0 2.349 2.25H16.9a2.3 2.3 0 0 0 2.35-2.249V6.25a2.3 2.3 0 0 0-2.35-2.22m0 3h-4.8a.81.81 0 0 1-.85-.768V6.25a.8.8 0 0 1 .847-.75H16.9a.8.8 0 0 1 .85.747v.003a.81.81 0 0 1-.838.78z"/></svg>)}
    />
  );
}
  