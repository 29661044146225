import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoOneplusRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.688 18.248v-1.936h1.936v-5.72H7.688V8.568h3.872v7.568h1.936v1.936H7.688zM20.184 23V10.504h-1.936v10.56H2.936V5.752h10.56V3.816H1V23zm0-14.432V5.752H23V3.816h-2.816V1h-1.936v2.816h-2.816v1.936h2.816v2.816z"/></svg>)}
    />
  );
}
  