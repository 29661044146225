// @flow strict-local
import * as R from 'ramda';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';

export type CallBackOptionsT =
  | 'disabled'
  | 'proposeCallbackRegularly'
  | 'whenQueueFullOrTimeout'
  | 'always';

export const getInitialCallbackOption = (acdData: AcdEntityT): CallBackOptionsT => {
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  if (!acdData.acdGroupCallbackEnabled) {
    return 'disabled';
  }

  if (acdCallCenter.callbackSystematic) {
    return 'always';
  }

  if (acdCallCenter.callbackQueueFullOrTimeout) {
    return 'whenQueueFullOrTimeout';
  }

  if (!acdCallCenter.callbackQueueFullOrTimeout) {
    return 'proposeCallbackRegularly';
  }

  return 'disabled';
};

const createCallBackMethodProperties = (
  acdGroupCallbackEnabled,
  callbackSystematic,
  callbackQueueFullOrTimeout,
  callbackWhenNoOperator,
  callbackWhenMaxTimeInQueue
) => {
  return {
    acdGroupCallbackEnabled: {
      level: 'Extension',
      value: acdGroupCallbackEnabled
    },
    properties: [
      {
        callbackSystematic: {
          level: 'Extension',
          value: callbackSystematic
        }
      },
      {
        callbackQueueFullOrTimeout: {
          level: 'Extension',
          value: callbackQueueFullOrTimeout
        }
      },
      {
        callbackWhenNoOperator: {
          level: 'Extension',
          value: callbackWhenNoOperator
        }
      },
      {
        callbackWhenMaxTimeInQueue: {
          level: 'Extension',
          value: callbackWhenMaxTimeInQueue
        }
      }
    ]
  };
};

export const convertCallbackMethodToBooleanProperties = (
  option: CallBackOptionsT,
  whenNoOperator: boolean,
  whenMaxTimeInQueue: boolean
) => {
  if (option === 'disabled') {
    return createCallBackMethodProperties(0, 0, 0, false, false);
  }

  if (option === 'proposeCallbackRegularly') {
    return createCallBackMethodProperties(1, 0, 0, whenNoOperator, whenMaxTimeInQueue);
  }

  if (option === 'whenQueueFullOrTimeout') {
    return createCallBackMethodProperties(1, 0, 1, whenNoOperator, false);
  }

  if (option === 'always') {
    return createCallBackMethodProperties(1, 1, 0, false, false);
  }

  return createCallBackMethodProperties(0, 0, 0, false, false);
};
