// @flow

/* eslint-disable no-underscore-dangle */

import { CancelToken, CancelTokenSource, type $AxiosError } from 'axios';
import React, { Component, type Element } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter, type ContextRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import Dropzone, { FileRejection } from 'react-dropzone';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import parse from 'html-react-parser';
import type { StoreStateT } from '../../../commonTypes';
import type { ImportErrorT } from '../../../ducks/ui/userImport/userImportUiTypes';
import {
  actionCreators as userImportUiActions,
  selectors as userImportUiSelectors,
  operations as userOps
} from '../../../ducks/ui/userImport';
import DropzoneHelp from './DropzoneHelp';
import Table from '../../../components/Table/Table';
import ActionButton from '../../../components/Button/ActionButton';
import CancelButton from '../../../components/Button/CancelButton';
import type { TableRowItemT } from '../../../components/Table/TableRow';
import type { TableHeaderColumnT } from '../../../components/Table/TableHeader';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import AnalyzeImportProcess from '../../AnalyzeImportProcess/AnalyzeImportProcess';

import styles from './UploadStep.module.scss';

type OwnPropsT = {
  onFileDrop?: void => void,
  onSuccess: void => void,
  onError: void => void,
  closeImport: void => void
};

type StatePropsT = {
  importErrors: ImportErrorT[],
  error: $AxiosError<*> | void,
  uploading: boolean,
  cancelled: boolean,
  currentUser: CurrentUserStateT
};

type DispatchPropsT = {
  importUsers: typeof userOps.importUsers,
  setName: typeof userImportUiActions.createAnalyzeImportSetName
};

export type PropsT = {|
  ...$Exact<ContextRouter>,
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export type StateT = {
  fileToImport?: File,
  fileTypeError?: boolean
};

export class UploadStep extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      fileToImport: undefined,
      fileTypeError: false
    };
    this.renderErrors = this.renderErrors.bind(this);
    this.renderGenericErrors = this.renderGenericErrors.bind(this);
    this.renderValidationErrors = this.renderValidationErrors.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleRetryAction = this.handleRetryAction.bind(this);
    this.handleReturnAction = this.handleReturnAction.bind(this);
    this.handleRetryAction = this.handleRetryAction.bind(this);
    this.handleReturnAction = this.handleReturnAction.bind(this);
    this.requestCancelTokenSource = CancelToken.source();
  }

  componentDidUpdate(prevProps: PropsT) {
    const { uploading, error, cancelled, onSuccess, onError, importErrors } = this.props;
    const fileLoadingError = error || importErrors.length > 0;
    if (prevProps.uploading && !uploading) {
      if (!fileLoadingError && !cancelled) {
        onSuccess();
      }

      if (fileLoadingError && !cancelled) {
        onError();
      }
    }
  }

  componentWillUnmount() {
    this.requestCancelTokenSource.cancel();
  }

  requestCancelTokenSource: CancelTokenSource;

  handleOnDrop: *;

  handleOnDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    const {
      importUsers,
      uploading,
      match: {
        params: { id: enterpriseId }
      },
      setName,
      onFileDrop = () => {},
      currentUser
    } = this.props;

    if (uploading) {
      return;
    }

    if (
      enterpriseId &&
      fileRejections &&
      fileRejections.length >= 1 &&
      fileRejections[0].errors[0] &&
      fileRejections[0].errors[0].code === 'file-invalid-type'
    ) {
      this.setState({ fileTypeError: true });
      onFileDrop();
    } else if (acceptedFiles.length === 1 && enterpriseId) {
      this.setState({ fileToImport: acceptedFiles[0], fileTypeError: false });
      setName(acceptedFiles[0].name);
      onFileDrop();
      importUsers(
        enterpriseId,
        acceptedFiles[0],
        this.requestCancelTokenSource.token,
        {},
        createCsrfHeader(currentUser)
      );
    }
  }

  handleRetryAction: *;

  handleRetryAction(e: SyntheticEvent<HTMLButtonElement>) {
    const {
      importUsers,
      match: {
        params: { id: enterpriseId }
      },
      currentUser
    } = this.props;
    if (enterpriseId && this.state.fileToImport) {
      importUsers(
        enterpriseId,
        this.state.fileToImport,
        this.requestCancelTokenSource.token,
        {},
        createCsrfHeader(currentUser)
      );
      if (e) e.stopPropagation();
    }
  }

  handleReturnAction: *;

  handleReturnAction(e: SyntheticEvent<HTMLButtonElement>) {
    const { closeImport } = this.props;
    closeImport();
    if (e) e.stopPropagation();
  }

  renderError: (string, string, ?string, ?Element<*>, ?boolean, ?string, ?string) => Element<'div'>;

  renderError(
    errorHeader: string,
    errorText: string,
    errorLink?: ?string,
    errorDetails: ?Element<*> = null,
    showButtons: boolean = false,
    retryButtonText: string = '',
    returnButtonText: string = ''
  ) {
    return (
      <div id="user-import-upload-step-error" className={styles.error}>
        <div className={styles['error-header']}>{errorHeader}</div>
        <div className={styles['error-text']}>{errorText}</div>
        {errorLink && <div className={styles['error-link']}>{errorLink}</div>}
        {errorDetails}
        {showButtons && (
          <div className={styles['button-area']}>
            <ActionButton
              id="upload-retry-button"
              label={retryButtonText}
              onClickAction={this.handleRetryAction}
              className={styles['retry-button']}
            />
            <CancelButton
              id="upload-return-button"
              label={returnButtonText}
              onClickAction={this.handleReturnAction}
              className={styles['return-button']}
            />
          </div>
        )}
      </div>
    );
  }

  renderGenericErrors: *;

  renderGenericErrors() {
    const { t, error } = this.props;

    let message = '';
    if (error && error.response) {
      ({ message } = error.response.data);
    } else if (error) {
      message = error;
    }

    const defaultErrorLink = `<span style="text-decoration: underline;">${t(
      'importUsers.uploadStep.generalErrorLink'
    )}</span>`;
    const formatErrorLink = `<span style="text-decoration: underline;">${t(
      'importUsers.uploadStep.formatErrorLink'
    )}</span>`;
    const renderError = (errorText: string, errorLink: string) =>
      this.renderError(t('importUsers.uploadStep.errorHeader'), errorText, errorLink);

    if (message == null) {
      return renderError(t('importUsers.uploadStep.generalErrorText'), defaultErrorLink);
    }

    function getLimitOrActual(limitOrActual) {
      const words = message.split(' ');
      const index = words.findIndex(word => word.toLowerCase() === limitOrActual);
      if (index !== -1 && words.length - 1 >= index + 1) {
        return words[index + 1].replace(',', t('importUsers.uploadStep.rowCountSeparator'));
      }
      return '';
    }

    if (message.includes('UserImportSizeException')) {
      if (message.includes('User import row count is too high')) {
        const limit = getLimitOrActual('limit');
        const actual = getLimitOrActual('actual');
        return renderError(
          t('importUsers.uploadStep.rowCountErrorText', { limit, actual }),
          defaultErrorLink
        );
      }
      return renderError(t('importUsers.uploadStep.sizeErrorText'), defaultErrorLink);
    }
    if (message.includes('timed-out')) {
      return this.renderError(
        t('importUsers.uploadStep.connectionErrorHeader'),
        t('importUsers.uploadStep.connectionErrorText'),
        null,
        null,
        true,
        t('importUsers.uploadStep.retry'),
        t('importUsers.uploadStep.back')
      );
    }
    if (message.includes('UserImportFormatException') || this.state.fileTypeError) {
      return renderError(t('importUsers.uploadStep.formatErrorText'), formatErrorLink);
    }
    return renderError(t('importUsers.uploadStep.generalErrorText'), defaultErrorLink);
  }

  renderValidationErrors: *;

  renderValidationErrors() {
    const { t, importErrors } = this.props;

    const columns: TableHeaderColumnT[] = [
      {
        columnId: 'message',
        text: t('importUsers.uploadStep.tableErrorTopic1'),
        size: 'x-large',
        border: false
      },
      {
        columnId: 'cells',
        text: t('importUsers.uploadStep.tableErrorTopic2'),
        size: 'large',
        border: false
      },
      {
        columnId: 'sheet',
        text: t('importUsers.uploadStep.tableErrorTopic3'),
        size: 'large',
        border: false
      }
    ];

    const errorsByType = R.reduce(
      (acc, importError) => {
        const groupId = importError.type + importError.sheetName;
        return {
          ...acc,
          [groupId]: {
            id: groupId,
            type: importError.type,
            sheetName: importError.sheetName,
            cells: R.append(
              `${importError.column}${importError.row}`,
              R.path([groupId, 'cells'], acc) || []
            )
          }
        };
      },
      {},
      importErrors || []
    );

    // Converts grouped errors to Table format.
    // Table's values are assigned to variables('message', 'cells', and 'sheet') which are defined as Table's `columns`.
    const mapIndexed = R.addIndex(R.map);
    const items: TableRowItemT[] = mapIndexed(
      (item: *, idx: number): TableRowItemT => ({
        id: `import-error-${idx}`,
        rowId: `row-${idx}`,
        message:
          item.type === 'fieldValidationError'
            ? t('importUsers.uploadStep.fieldValidationError')
            : t('importUsers.uploadStep.duplicateIdentifierError'),
        cells: R.join(', ', item.cells),
        sheet: item.sheetName
      }),
      R.values(errorsByType)
    );

    const errorDetailsTable = (
      <Table
        id="error-details-table"
        items={items}
        columns={columns}
        tableStyle={styles['error-table']}
      />
    );

    return this.renderError(
      t('importUsers.uploadStep.errorHeader'),
      parse(
        `${t('importUsers.uploadStep.validationErrorText1')}<br/>${t(
          'importUsers.uploadStep.validationErrorText2'
        )}`
      ),
      t('importUsers.uploadStep.generalErrorLink'),
      errorDetailsTable
    );
  }

  renderErrors: *;

  renderErrors() {
    const { importErrors } = this.props;

    if (importErrors && importErrors.length > 0) {
      return this.renderValidationErrors();
    }
    return this.renderGenericErrors();
  }

  render() {
    const { t, error, uploading, importErrors, closeImport } = this.props;

    const selectFileView = (
      <div
        id="user-import-upload-step-choose"
        data-cy="file-upload-select-file"
        className={styles.choose}
      >
        <div className={styles['choose-header']}>{t('importUsers.uploadStep.startHeader')}</div>
        <div className={styles['choose-text']}>
          {parse(
            `${t('importUsers.uploadStep.startText1')}<span style="text-decoration: underline;">${t(
              'importUsers.uploadStep.startText2'
            )}</span>`
          )}
        </div>
      </div>
    );

    const processingFileView = <AnalyzeImportProcess closeImport={closeImport} />;

    const resultView =
      error || importErrors.length > 0 || this.state.fileTypeError
        ? this.renderErrors()
        : selectFileView;

    return (
      <div className={styles['upload-step-container']}>
        <DropzoneHelp />
        <Dropzone
          id="file-dropzone"
          data-cy="file-dropzone"
          onDrop={this.handleOnDrop}
          className={styles.dropzone}
          activeClassName={styles['dropzone-active']}
          accept=".xlsx"
          disabled={uploading}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} id="file-dropzone" />
                {uploading ? processingFileView : resultView}
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => ({
  importErrors: userImportUiSelectors.selectImportErrors(state),
  error: state.ui.userImport.importAnalysis.__metadata.error,
  uploading: state.ui.userImport.importAnalysis.__metadata.importing === true,
  cancelled: state.ui.userImport.importAnalysis.__metadata.wasCancelled === true,
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      importUsers: userOps.importUsers,
      setName: userImportUiActions.createAnalyzeImportSetName
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<$Diff<PropsT, ContextRouter>, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter
)(UploadStep);
