// @flow

import React, { type Element } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import {
  getActiveForwardingsByType,
  getForwardingTargetName,
  hasMultipleTargetTypes
} from '../../../callFlowGrid/details/extensionGroup/extensionGroupForwardingUtils';
import { type CallForwardingTypeT } from '../../../../../ducks/entities/callForwarding/callForwardingTypes';

type PropsT = {|
  extensionGroup: ExtensionGroupEntityT,
  forwardingType: CallForwardingTypeT
|};

export const ExtensionGroupForwardingTargetName = (props: PropsT): Element<'div'> => {
  const { extensionGroup, forwardingType } = props;
  const { t } = useTranslation();

  const getTranslationIdForTarget = (target: string): string => {
    if (
      target === 'ENT_VM' ||
      target === 'REJECTION' ||
      target === 'ENT_RCPT' ||
      target === 'USER_MOBILE' ||
      target === 'USER_HOME' ||
      target === 'UNDEFINED'
    ) {
      return t(`callflows.extensionGroupForwardingTargetName.${target}`);
    }
    return target || t('callflows.extensionGroupForwardingTargetName.serviceNotInUse');
  };
  const activeForwardings = getActiveForwardingsByType(extensionGroup.forwardings, forwardingType);
  const targetDest = R.path([0, 'destination'], activeForwardings);

  const hasUnsupportedData = hasMultipleTargetTypes(activeForwardings);

  let returnTarget;
  if (hasUnsupportedData) {
    returnTarget = t('callflows.extensionGroupForwardingTargetName.unknownTarget');
  } else {
    returnTarget = getTranslationIdForTarget(getForwardingTargetName(targetDest, extensionGroup));
  }

  return <div>{returnTarget}</div>;
};

export default ExtensionGroupForwardingTargetName;
