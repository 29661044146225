import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPowerRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.25 10.5v-9a.75.75 0 0 1 1.5 0v9a.75.75 0 0 1-1.5 0m4.17-7.16a.75.75 0 1 0-.5 1.409 8.75 8.75 0 1 1-5.84.001.75.75 0 0 0 .46-1 .75.75 0 0 0-1-.45C3.203 5.189.409 11.046 2.297 16.383s7.746 8.131 13.083 6.243 8.131-7.746 6.243-13.083A10.25 10.25 0 0 0 15.38 3.3z"/></svg>)}
    />
  );
}
  