import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPlayFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.803 13.966 5.345 23.689C3.845 24.525 2 23.44 2 21.724V2.276C2 .56 3.845-.524 5.345.311l17.458 9.723c1.54.858 1.54 3.074 0 3.932"/></svg>)}
    />
  );
}
  