// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import { CancelToken, CancelTokenSource } from 'axios';
import { useFormContext } from 'react-hook-form';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import type { ExactPropsT } from '../../../../../../commonTypes';
import { operations as calendarOps } from '../../../../../../ducks/entities/calendar';
import CalendarSection from '../../../view/children/calendar/CalendarSection/CalendarSection';
import Tooltip from '../../../../../../components/Tooltip';
import { enterpriseCalendarRegExp } from '../../../../../../helpers';

import styles from './CalendarSelector.module.scss';

type OwnPropsT = {
  field: string,
  description: string,
  enterpriseId: string,
  tooltip?: string
};

export type PropsT = {|
  ...ExactPropsT<OwnPropsT>
|};

let requestCancelTokenSource: CancelTokenSource;

export function CalendarSelector(props: PropsT): Element<'div'> {
  const { field, description, enterpriseId, tooltip } = props;
  const [selectedCalendar, setSelectedCalendar] = useState();
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false);
  const [calendarData, setCalendarData] = useState({
    calendars: [],
    calendarNames: []
  });
  const dispatch = useDispatch();

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const initialCalendarName: string = values[field] || '-';

  const fetchAllCalendars = async () => {
    setIsLoadingCalendar(true);
    const returnValue = await dispatch(
      calendarOps.retrieveCalendars(enterpriseId, null, requestCancelTokenSource.token)
    );
    setIsLoadingCalendar(false);
    if (returnValue) {
      setCalendarData({
        calendars: returnValue.results,
        calendarNames: returnValue.results.map(cal => cal.name)
      });
      const foundCalendar = returnValue.results.find(cal => cal.name === initialCalendarName);
      if (foundCalendar) {
        setSelectedCalendar(foundCalendar);
      }
    }
  };

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    fetchAllCalendars();
    return () => {
      requestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const allCalendarNames =
    [
      { label: '-', value: '-' },
      ...calendarData.calendarNames.map(name => ({
        label: name.replace(enterpriseCalendarRegExp, ''),
        value: name
      }))
    ] || [];
  const findCalendar = calendarName =>
    calendarData.calendars.find(cal => cal.name === calendarName);
  return (
    <div className={styles.container}>
      <div className={styles['combobox-description-area']}>
        <div className={styles['combobox-description']}>{description}</div>
        {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
      </div>
      {isLoadingCalendar ? (
        <LoadingSpinner />
      ) : (
        allCalendarNames.length > 0 && (
          <Dropdown
            id="calendar-action-field"
            items={allCalendarNames}
            selectedValue={initialCalendarName}
            className={styles.dropdown}
            onValueChange={element => {
              const name = element.getAttribute('data-value');
              const cal = name !== '-' ? findCalendar(name) : null;
              setValue(field, name, {
                shouldValidate: false,
                shouldDirty: true
              });
              setSelectedCalendar(cal);
            }}
          />
        )
      )}
      {selectedCalendar && <CalendarSection calendar={selectedCalendar} />}
    </div>
  );
}

export default CalendarSelector;
