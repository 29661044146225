import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDownloadingFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0m0 22.5C6.21 22.5 1.5 17.79 1.5 12S6.21 1.5 12 1.5 22.5 6.21 22.5 12 17.79 22.5 12 22.5m4.375-13.251v5.5c0 .896-.729 1.625-1.625 1.625h-5.5a1.627 1.627 0 0 1-1.625-1.625v-5.5c0-.896.729-1.625 1.625-1.625h5.5c.896 0 1.625.729 1.625 1.625"/></svg>)}
    />
  );
}
  