// @flow strict-local

import * as R from 'ramda';
import React, { Component, type Element } from 'react';
import { bindActionCreators, compose } from 'redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AccordionItem from '@design-system/component-library/src/components/Accordion/AccordionItem';
import Accordion from '@design-system/component-library/src/components/Accordion';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { ExactPropsT, TranslateT } from '../../../commonTypes';
import type { EnterpriseEntityT } from '../../../ducks/entities/enterprise';
import { Phone, PhoneLargeAndUp } from '../../../Responsive';
import { goToForwardingDetailsCreate } from '../../../navigationOperations';
import EnterpriseForwardingsTable from './EnterpriseForwardingsTable';
import delayBooleanTransitionToTrue from '../../../components/delayBooleanTransitionToTrue';
import { CenterHorizontally } from '../../../components/CenterHorizontally/CenterHorizontally';
import ForwardingsHeader from '../components/ForwardingsHeader';
import NoResults from '../../../components/Notifications/NoResults';
import styles from './EnterpriseForwardings.module.scss';

type OwnPropsT = {
  enterprise: EnterpriseEntityT,
  translate: TranslateT<>,
  onClickModify: (enterpriseCallForwardingId: string) => void,
  isLoading: boolean,
  showLoadingSpinner: boolean,
  onForwardingActivityChange: () => Promise<void>,
  onDeleteForwarding: () => void
};

type DispatchPropsT = {
  goToForwardingDetailsCreate: typeof goToForwardingDetailsCreate
};

export type PropsT = ExactPropsT<OwnPropsT, DispatchPropsT, WithTranslationProps>;

type StateT = {};

export class EnterpriseForwardings extends Component<PropsT, StateT> {
  // eslint-disable-next-line
  renderCallForwardingTable():
    | Element<typeof EnterpriseForwardingsTable>
    | Element<typeof CenterHorizontally>
    | Element<typeof NoResults>
    | null {
    const {
      translate,
      showLoadingSpinner,
      isLoading,
      onForwardingActivityChange,
      onDeleteForwarding,
      enterprise
    } = this.props;
    const forwardings = R.pathOr([], ['props', 'enterprise', 'forwardings'], this);

    if (showLoadingSpinner) {
      return (
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      );
    }

    if (isLoading) {
      return null;
    }

    if (forwardings.length === 0) {
      return <NoResults title={translate('forwardings.enterpriseCallForwardings.noResults')} />;
    }

    return (
      <EnterpriseForwardingsTable
        forwardings={forwardings}
        translate={translate}
        onForwardingActivityChange={onForwardingActivityChange}
        onDeleteForwarding={onDeleteForwarding}
        enterpriseId={enterprise.entID}
      />
    );
  }

  render(): Element<'div'> {
    const { enterprise, t } = this.props;
    return (
      <div className={styles['enterprise-callforwardings']}>
        <div className={styles.callforwardings} id="enterprise-callforwardings">
          <ForwardingsHeader
            title={t('forwardings.enterpriseCallForwardings.title')}
            description={t('forwardings.enterpriseCallForwardings.description')}
            buttonText={t('forwardings.createEnterpriseCallForwardingButton')}
            onCreateClickAction={() => {
              this.props.goToForwardingDetailsCreate(enterprise.entID, null, true);
            }}
          />
          <Phone>
            <Accordion className={styles.accordion}>
              <AccordionItem
                heading={t('forwardings.enterpriseCallForwardings.forwardings')}
                defaultOpen
              >
                {this.renderCallForwardingTable()}
              </AccordionItem>
            </Accordion>
          </Phone>
          <PhoneLargeAndUp>
            <div className={styles['table-container']}>{this.renderCallForwardingTable()}</div>
          </PhoneLargeAndUp>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToForwardingDetailsCreate
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  withRouter,
  delayBooleanTransitionToTrue({
    delay: 500,
    propName: 'showLoadingSpinner'
  })
)(EnterpriseForwardings);
