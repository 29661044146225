import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDeliveryFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.75 18c0 1.24-1.01 2.25-2.25 2.25s-2.25-1.01-2.25-2.25 1.01-2.25 2.25-2.25 2.25 1.01 2.25 2.25M4.5 15.75c-1.241 0-2.25 1.01-2.25 2.25s1.009 2.25 2.25 2.25S6.75 19.24 6.75 18s-1.009-2.25-2.25-2.25M24 10v6.5a1 1 0 0 1-1 1h-1.843a.25.25 0 0 1-.243-.205A3.49 3.49 0 0 0 17.5 14.5a3.49 3.49 0 0 0-3.414 2.795.25.25 0 0 1-.243.205H8.157a.25.25 0 0 1-.243-.205A3.49 3.49 0 0 0 4.5 14.5a3 3 0 0 0-.575.058 3.52 3.52 0 0 0-2.123 1.223c-.336.401-.29.469-1.053.469A.74.74 0 0 1 0 15.5V5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.875a.625.625 0 0 0 1.25 0V7.503c0-.278.225-.503.503-.503H21a3 3 0 0 1 3 3m-1.5 0A1.5 1.5 0 0 0 21 8.5h-2.753a.25.25 0 0 0-.247.247v3.505c0 .137.111.247.247.247h4.005a.247.247 0 0 0 .247-.247V10z"/></svg>)}
    />
  );
}
  