import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPilvilinnaLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 22.501a.5.5 0 0 1-.5.499h-2a.5.5 0 0 1-.5-.499v-2.497h-2v2.497a.5.5 0 0 1-.5.499h-3a.5.5 0 0 1-.5-.499v-9.908l-3-9.418-3 9.418v9.908a.5.5 0 0 1-.5.499h-3a.5.5 0 0 1-.5-.499v-2.497H3v2.497a.5.5 0 0 1-.5.499h-2a.5.5 0 1 1 0-.998H2v-2.497a.5.5 0 0 1 .5-.499h3a.5.5 0 0 1 .5.499v2.497h2v-9.488a1 1 0 0 1 0-.15l3.5-10.986a.52.52 0 0 1 1 0L16 12.363q.008.075 0 .15v9.488h2v-2.497a.5.5 0 0 1 .5-.499h3a.5.5 0 0 1 .5.499v2.497h1.5a.5.5 0 0 1 .5.5M.5 18.006a.5.5 0 0 0 .5-.499 2.51 2.51 0 0 1 2.12-2.497q.19-.015.38 0a.5.5 0 0 0 .5-.499 3.5 3.5 0 0 1 2.41-3.326q.251-.076.51-.12a.5.5 0 0 0 .411-.575l-.001-.004a.49.49 0 0 0-.557-.412l-.013.002A4.506 4.506 0 0 0 3 14.011a3.53 3.53 0 0 0-3 3.496.5.5 0 0 0 .5.499m15.81-8.679A5.485 5.485 0 0 1 20 14.511a.5.5 0 0 0 .5.499c1.381 0 2.5 1.118 2.5 2.497a.5.5 0 0 0 1 0 3.5 3.5 0 0 0-3-3.496 6.49 6.49 0 0 0-4.34-5.663.527.527 0 0 0-.33.999zM12 14.011a1 1 0 0 0-1 .999v3.496a.5.5 0 0 0 .5.499h1a.5.5 0 0 0 .5-.499V15.01a1 1 0 0 0-1-.999"/></svg>)}
    />
  );
}
  