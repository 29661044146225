// @flow

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import type { ExactPropsT, StoreStateT } from '../../../commonTypes';
import { selectors as selectDepartments } from '../../../ducks/entities/department';
import Breadcrumb from '../../../components/Breadcrumb';

type OwnPropsT = {
  path: string[],
  onPathSelect: (string[]) => *
};

type StatePropsT = {
  pathWithNames: {
    id: string,
    name: ?string
  }[]
};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;

export class DepartmentTreeBreadcrumb extends Component<PropsT> {
  constructor(props: PropsT) {
    super(props);
    this.handleOnSegmentSelect = this.handleOnSegmentSelect.bind(this);
  }

  handleOnSegmentSelect: *;

  handleOnSegmentSelect(index: number) {
    const { pathWithNames, onPathSelect } = this.props;

    if (index === -1) onPathSelect([]);
    else onPathSelect(pathWithNames.slice(0, index + 1).map(segment => segment.id));
  }

  render(): Element<typeof Breadcrumb> | null {
    const { pathWithNames } = this.props;
    return pathWithNames.every(segment => segment.name) ? (
      <Breadcrumb
        segments={pathWithNames.map(s => ({ key: s.id, name: s.name || '' }))}
        onSegmentSelect={this.handleOnSegmentSelect}
        showHome
        divider="/"
      />
    ) : null;
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => ({
  pathWithNames: selectDepartments.pathWithNames(state, ownProps.path)
});

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(DepartmentTreeBreadcrumb);
