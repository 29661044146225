import React from 'react';
import PropTypes from 'prop-types';

import styles from './Disclaimer.module.scss';

export function DisclaimerElement(
  {
    text = null,
    url = null,
    handleClick = () => {},
    rel = 'nofollow',
  }
) {
  if (url && text) {
    return (
      <li className={styles.footer__disclaimer_item}>
        <a
          className={styles.footer__disclaimer_link}
          href={url}
          rel={rel}
          onClick={handleClick}
        >
          {text}
        </a>
      </li>
    );
  }
  return null;
}

DisclaimerElement.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  handleClick: PropTypes.func,
  rel: PropTypes.string
};
