// @flow strict-local

import * as R from 'ramda';
import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CallbackReentranceIcon } from '../../../../../assets/callflow/details/dialpad-small.svg';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import { update } from '../../../../../ducks/entities/acd/acdOperations';
import ToggleField from '../../../components/edit/children/ToggleField';
import { uploadAudios } from '../../../components/edit/children/audio/CallflowAudioUtils';
import { createIvrPatchPayload } from '../../../components/edit/CallflowPropertyUtils';
import type {
  AudioFieldT,
  PropertyAudioT
} from '../../../components/edit/children/audio/CallflowAudioUtils';
import AcdAudioField from '../../../components/edit/children/audio/AcdAudioField';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';
import { removeOverflowGroupIfNeeded } from './EditAcdAgent';

type PropsT = {|
  callflowId: string
|};

type FormT = {
  callbackActivateReentrance: boolean,
  callbackAllowModifyNumber: boolean,
  callbackSendSmsOnCancel: boolean,
  callbackMenuAccept: AudioFieldT<PropertyAudioT>,
  callbackMenuKeep: AudioFieldT<PropertyAudioT>,
  callbackMenuModify: AudioFieldT<PropertyAudioT>,
  callbackMenuDecline: AudioFieldT<PropertyAudioT>,
  callbackMenuReenter: AudioFieldT<PropertyAudioT>,
  callbackKept: AudioFieldT<PropertyAudioT>,
  callbackCancelled: AudioFieldT<PropertyAudioT>,
  callbackNumber: AudioFieldT<PropertyAudioT>,
  callbackWrongNumber: AudioFieldT<PropertyAudioT>
};

let requestCancelTokenSource: CancelTokenSource;
let audioRequestCancelTokenSource: CancelTokenSource;

// $FlowFixMe: TODO: fix
export const acdCallbackSchema = yup.object().shape({});

export const EditCallbackReentranceDetails = (
  props: PropsT
): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  // const { watch } = useFormContext();
  // const values = watch();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const ivrAcdCallCenter = R.path(['ivrProperties', 'acdCallCenter'], acdData) || {};
  const acdAudioConverter = new AudioFieldConverter(acdData.enterpriseId, acdData.id, 'acds');
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const initialIvrProperties = {
    callbackActivateReentrance: acdCallCenter.callbackActivateReentrance,
    callbackAllowModifyNumber: acdCallCenter.callbackAllowModifyNumber,
    callbackSendSmsOnCancel: acdCallCenter.callbackSendSmsOnCancel,
    callbackMenuAccept: acdAudioConverter.convertToAudioPropertyField(
      'CallbackMenuAccept',
      R.path(['audios', 'callbackMenuAccept', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackMenuAccept
    ),
    callbackMenuKeep: acdAudioConverter.convertToAudioPropertyField(
      'CallbackAnnouncement',
      R.path(['audios', 'callbackMenuKeep', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackMenuKeep
    ),
    callbackMenuModify: acdAudioConverter.convertToAudioPropertyField(
      'CallbackMenuModify',
      R.path(['audios', 'callbackMenuModify', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackMenuModify
    ),
    callbackMenuDecline: acdAudioConverter.convertToAudioPropertyField(
      'CallbackMenuDecline',
      R.path(['audios', 'callbackMenuDecline', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackMenuDecline
    ),
    callbackMenuReenter: acdAudioConverter.convertToAudioPropertyField(
      'CallbackMenuReenter',
      R.path(['audios', 'callbackMenuReenter', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackMenuReenter
    ),
    callbackKept: acdAudioConverter.convertToAudioPropertyField(
      'CallbackKept',
      R.path(['audios', 'callbackKept', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackKept
    ),
    callbackCancelled: acdAudioConverter.convertToAudioPropertyField(
      'CallbackCancelled',
      R.path(['audios', 'callbackCancelled', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackCancelled
    ),
    callbackNumber: acdAudioConverter.convertToAudioPropertyField(
      'CallbackNumber',
      R.path(['audios', 'callbackNumber', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackNumber
    ),
    callbackWrongNumber: acdAudioConverter.convertToAudioPropertyField(
      'CallbackWrongNumber',
      R.path(['audios', 'callbackWrongNumber', 'extensionAudio', 'name'], acdCallCenter),
      ivrAcdCallCenter.callbackWrongNumber
    )
  };
  const initialFormValues: FormT = {
    ...initialIvrProperties
  };

  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    audioRequestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
      audioRequestCancelTokenSource.cancel();
    };
  }, []);

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const propertyNames = Object.keys(initialIvrProperties);
    const patchPayload = createIvrPatchPayload(
      { ...removeOverflowGroupIfNeeded(acdData.ringPattern, acdData.overflowGroup) },
      formData,
      propertyNames
    );

    const failedAudioUploads = await uploadAudios(
      formData,
      audioRequestCancelTokenSource,
      createCsrfHeader(currentUser)
    );
    if (failedAudioUploads.length > 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        t('callflows.audioUploadFailed', { audioFileNames: failedAudioUploads.join(',') })
      );
    }

    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<CallbackReentranceIcon />}
      title={t('callflows.treeLayout.acd.callbackReentrance')}
      description=""
      defaultValues={initialFormValues}
      validationSchema={acdCallbackSchema}
      onSaveForm={onSubmit}
    >
      <ToggleField
        field="callbackActivateReentrance"
        label={t('callflows.editAcdCallbackDetails.callbackActivateReentrance')}
        tooltip={t('callflows.editAcdCallbackDetails.callbackActivateReentranceTooltip')}
      />
      <AcdAudioField
        field="callbackMenuAccept"
        title={t('callflows.editAcdCallbackDetails.callbackMenuAccept')}
      />
      <AcdAudioField
        field="callbackMenuKeep"
        title={t('callflows.editAcdCallbackDetails.callbackMenuKeep')}
      />
      <ToggleField
        field="callbackAllowModifyNumber"
        label={t('callflows.editAcdCallbackDetails.callbackAllowModifyNumber')}
      />
      <AcdAudioField
        field="callbackMenuModify"
        title={t('callflows.editAcdCallbackDetails.callbackMenuModify')}
        disabledFn={(formValues: FormT) => !formValues.callbackAllowModifyNumber}
      />
      <AcdAudioField
        field="callbackMenuDecline"
        title={t('callflows.editAcdCallbackDetails.callbackMenuDecline')}
      />
      <AcdAudioField
        field="callbackMenuReenter"
        title={t('callflows.editAcdCallbackDetails.callbackMenuReenter')}
      />
      <AcdAudioField
        field="callbackKept"
        title={t('callflows.editAcdCallbackDetails.callbackKept')}
      />
      <AcdAudioField
        field="callbackCancelled"
        title={t('callflows.editAcdCallbackDetails.callbackCancelled')}
      />
      <AcdAudioField
        field="callbackNumber"
        title={t('callflows.editAcdCallbackDetails.callbackNumber')}
      />
      <AcdAudioField
        field="callbackWrongNumber"
        title={t('callflows.editAcdCallbackDetails.callbackWrongNumber')}
      />
      <ToggleField
        field="callbackSendSmsOnCancel"
        label={t('callflows.editAcdCallbackDetails.callbackSendSmsOnCancel')}
      />
    </EditCallflowDetails>
  );
};

export default EditCallbackReentranceDetails;
