import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCloseRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 19a.75.75 0 0 1 0 1 .75.75 0 0 1-1 0l-7-7-7 7a.75.75 0 0 1-1 0 .75.75 0 0 1 0-1l7-7-7-7a.75.75 0 0 1 1-1l7 7 7-7a.75.75 0 0 1 1 1l-7 7z"/></svg>)}
    />
  );
}
  