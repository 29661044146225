import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDeleteFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 3.8h-6V3c0-1.2-1-2.2-2.2-2.2H9.8C8.6.8 7.6 1.8 7.6 3v.8h-6c-.4 0-.8.3-.8.8s.3.8.8.8h1.8l1.3 15.9c.1 1 1 1.8 2 1.8h10.7c1 0 1.9-.8 2-1.8l1.3-15.9h1.8c.4 0 .8-.3.8-.8s-.4-.8-.8-.8M9 3c0-.4.3-.7.8-.8h4.5c.4.1.7.4.7.8v.8H9zm1.1 14.5c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-8c0-.3.3-.6.6-.6s.6.3.6.6zm5 0c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-8c0-.3.3-.6.6-.6s.6.3.6.6z"/></svg>)}
    />
  );
}
  