// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SpeedDiallingIcon } from '../../../../../assets/callflow/details/speeddialling-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../components/view/children/CallflowItem';

export type PropsT = {|
  callflowId: string
|};

export const SpeedDialDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const speedDial = useSelector(state => state.entities.callFlow.byId[callflowId]);

  const destination =
    (speedDial.pnNumbers || []).indexOf(speedDial.externalDestination) >= 0
      ? t('callflows.viewSpeedDialDetails.oc')
      : speedDial.externalDestination || t('callflows.viewSpeedDialDetails.notDefined');

  return (
    <CallflowDetails
      enterpriseId={speedDial.enterpriseId}
      callflowId={speedDial.id}
      elementType={speedDial.type}
      nodeId={speedDial.type}
      nodeType={speedDial.type}
      icon={<SpeedDiallingIcon />}
      title={t('callflows.viewSpeedDialDetails.title')}
      active
    >
      <CallflowDetailsColumn id="speeddial-column-1">
        <CallflowItem
          id="destination"
          title={t('callflows.viewSpeedDialDetails.destination')}
          value={destination}
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default SpeedDialDetails;
