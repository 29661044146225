import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAccessoryRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 24h-9a1.75 1.75 0 0 1-1.75-1.75V1.75C4.75.784 5.534 0 6.5 0h9a3.75 3.75 0 0 1 3.75 3.75V7a.75.75 0 0 1-1.5 0V3.75A2.25 2.25 0 0 0 15.5 1.5h-9a.25.25 0 0 0-.25.25v20.5c0 .138.112.25.25.25h9a2.25 2.25 0 0 0 2.25-2.25V17a.75.75 0 0 1 1.5 0v3.25A3.75 3.75 0 0 1 15.5 24M14.25 3.25a.76.76 0 0 0-.75-.75h-3a.75.75 0 0 0 0 1.5h3a.76.76 0 0 0 .75-.75m6 11.25v-5a.76.76 0 0 0-.75-.75H16a3.25 3.25 0 1 0 0 6.5h3.5a.76.76 0 0 0 .75-.75m-1.5-4.25v3.5H16a1.75 1.75 0 0 1 0-3.5z"/></svg>)}
    />
  );
}
  