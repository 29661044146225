import React from 'react';
import IconContainer from '../IconContainer';

export default function IconClockRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 7.2c.4 0 .8.4.8.8v3.7l4.8 4.8c.3.3.3.8 0 1.1s-.8.3-1.1 0l-5-5c-.2-.2-.2-.4-.2-.6V8c-.1-.4.3-.8.7-.8M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0M1.6 12C1.6 6.3 6.3 1.6 12 1.6S22.4 6.3 22.4 12 17.7 22.4 12 22.4 1.6 17.7 1.6 12"/></svg>)}
    />
  );
}
  