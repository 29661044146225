import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEuroRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m7.74 9.71-.03.04h5.79a.75.75 0 0 1 0 1.5H7.3a5.6 5.6 0 0 0 0 1.5h6.2a.75.75 0 0 1 0 1.5H7.71a5.76 5.76 0 0 0 8.39 2.6.74.74 0 0 1 1 .22.76.76 0 0 1-.23 1A7.16 7.16 0 0 1 13 19.25a7.26 7.26 0 0 1-6.89-5H4.5a.75.75 0 0 1 0-1.5h1.29a7 7 0 0 1 0-1.5H4.5a.75.75 0 0 1 0-1.5h1.61a7.26 7.26 0 0 1 6.89-5 7.15 7.15 0 0 1 3.9 1.14.76.76 0 0 1 .23 1 .74.74 0 0 1-1 .22 5.76 5.76 0 0 0-8.39 2.6M24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12m-1.5 0c0-5.79-4.71-10.5-10.5-10.5S1.5 6.21 1.5 12 6.21 22.5 12 22.5 22.5 17.79 22.5 12"/></svg>)}
    />
  );
}
  