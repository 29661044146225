import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFlagLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 .5v23c0 .3-.2.5-.5.5s-.5-.2-.5-.5V.5c0-.3.2-.5.5-.5s.5.2.5.5m19 5v10c0 .8-.7 1.5-1.5 1.5h-10c-.8 0-1.5-.7-1.5-1.5V14H4.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7c.3 0 .5-.2.5-.5v-10c0-.3-.2-.5-.5-.5h-7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7c.8 0 1.5.7 1.5 1.5V4h7.5c.8 0 1.5.7 1.5 1.5m-1 0c0-.3-.2-.5-.5-.5H13v7.5c0 .8-.7 1.5-1.5 1.5H10v1.5c0 .3.2.5.5.5h10c.3 0 .5-.2.5-.5z"/></svg>)}
    />
  );
}
  