// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import ActionButton from '../../../../components/Button/ActionButton';
import { ReactComponent as AcdOrExtensionGroupIcon } from '../../../../assets/callflow/grid/acd.svg';
import { ReactComponent as WelcomeAttendantIcon } from '../../../../assets/callflow/grid/welcomeAttendant.svg';
import styles from './CalendarsInUseView.module.scss';

export type PropsT = {|
  enterpriseId: string,
  calendarServices: {
    type: string,
    id: string,
    type: string,
    label: string,
    addressNumber: string
  }[],
  calendarName: string,
  onClose: () => void
|};

const CalendarsInUseView = (props: PropsT) => {
  const { enterpriseId, calendarName, calendarServices, onClose } = props;

  const { t } = useTranslation();

  const calendarServicesFiltered = calendarServices.filter(cal => cal.id);

  const getIcon = (type: string) => {
    if (type === 'ACD_CUSTOMER_SERVICE' || type === 'ACD_SWITCHBOARD') {
      return <AcdOrExtensionGroupIcon className={styles.icon} />;
    }
    return <WelcomeAttendantIcon className={styles.icon} />;
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('calendars.calendarsInUseView.title')}</h2>
      <div className={styles.description}>
        {calendarServicesFiltered.length > 0
          ? parse(
              `${t('calendars.calendarsInUseView.description1')}<b>${calendarName}</b>${t(
                'calendars.calendarsInUseView.description2'
              )}`
            )
          : parse(
              `${t('calendars.calendarsInUseView.description1')}<b>${calendarName}</b>${t(
                'calendars.calendarsInUseView.description3'
              )}`
            )}
      </div>

      <div className={styles.results}>
        {calendarServicesFiltered.map(service => (
          <div className={styles['result-row']} key={`service-link-area-${service.id}`}>
            {getIcon(service.type)}
            <a
              href={`/enterprises/${enterpriseId}/callflows/services/${service.id}?type=${service.type}`}
              id={`service-link-${service.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              {service.label} {service.addressNumber}
            </a>
          </div>
        ))}
        {calendarServicesFiltered.length !== calendarServices.length && (
          <div className={styles['result-row']} key="service-link-area-excluded">
            {t('calendars.calendarsInUseView.calendarServicesExcluded')}
          </div>
        )}
      </div>
      {calendarServicesFiltered.length > 0 && (
        <div className={styles.description}>
          {t('calendars.calendarsInUseView.calendarServicesHint')}
        </div>
      )}
      <ActionButton
        className={styles['close-button']}
        label={t('calendars.calendarsInUseView.closeButton')}
        id="close-modal-btn"
        onClickAction={onClose}
      />
    </div>
  );
};

export default CalendarsInUseView;
