// @flow

import React, { type Element } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';
import TableHeader, { type TableHeaderColumnT, type TableSortT } from './TableHeader';
import TableRow, {
  type HoverButtonT,
  type OnSelectFnT,
  type TableCellT,
  type TableRowItemT
} from './TableRow';
import styles from './Table.module.scss';

export type MapItemToCellFnT = (TableHeaderColumnT, TableRowItemT) => TableCellT;
type GetTableRowsFnT = (
  TableRowItemT[],
  MapItemToCellFnT,
  TableHeaderColumnT[],
  boolean,
  ?HoverButtonT,
  ?OnSelectFnT,
  ?string,
  ?() => void,
  ?string
) => Element<typeof TableRow>[];

export type PropsT = {
  id: string,
  columns: TableHeaderColumnT[],
  rowHoverEnabled?: boolean,
  hoverButton?: HoverButtonT,
  allowLoadMore?: boolean,
  items: TableRowItemT[],
  mapItemToCell?: MapItemToCellFnT,
  onInfiniteScrollLoadMore?: (page: number, sort?: TableSortT) => Promise<void> | void,
  onSelectRow?: OnSelectFnT,
  onSort?: (sort: TableSortT) => void,
  showIfEmpty?: Element<*>,
  cornerElement?: Element<*>,
  leftCornerElement?: Element<*>,
  sort?: TableSortT,
  tableStyle?: string,
  linkName?: string,
  linkFunction?: ?(mixed) => void,
  borderBottomClass?: ?string
};

const getTableRows: GetTableRowsFnT = (
  rows,
  mapCell,
  columns,
  hoverEnabled,
  hoverButton,
  onSelectRow,
  linkName,
  linkFunction,
  borderBottomClass
) =>
  rows.map((row, index) => (
    <TableRow
      cells={columns.map(column => mapCell(column, row))}
      headerCells={columns}
      key={
        `cl_${row.rowId}_${index}` // eslint-disable-line react/no-array-index-key
      }
      onSelect={onSelectRow}
      hoverEnabled={hoverEnabled}
      hoverButton={hoverButton}
      rowItem={row}
      rowExtraLine={row.extraLine}
      linkName={linkName}
      linkFunction={linkFunction}
      borderBottomClass={borderBottomClass}
      rowSelector={row.rowSelector}
    />
  ));

const defaultMapItemToCell: MapItemToCellFnT = ({ columnId, size, border = true }, item) => ({
  value: item[columnId],
  size,
  border
});

const Table = ({
  id,
  columns = [],
  cornerElement,
  leftCornerElement,
  rowHoverEnabled,
  hoverButton,
  allowLoadMore,
  items = [],
  mapItemToCell,
  onInfiniteScrollLoadMore,
  onSelectRow,
  onSort,
  showIfEmpty,
  sort,
  tableStyle,
  linkName,
  linkFunction,
  borderBottomClass
}: PropsT): Element<'div'> => {
  if (!items.length && showIfEmpty) {
    return showIfEmpty;
  }
  const rowComponents = getTableRows(
    items,
    mapItemToCell || defaultMapItemToCell,
    columns,
    rowHoverEnabled || false,
    hoverButton,
    onSelectRow,
    linkName,
    linkFunction,
    borderBottomClass
  );
  const showRows = rowComponents.length > 0;
  const rowsElement = onInfiniteScrollLoadMore ? (
    <InfiniteScroll
      // This component started to fetch more pages than necessary when hasMore
      // was passed as true unless we know it's false. Hence allowLoadMore.
      hasMore={allowLoadMore}
      loadMore={onInfiniteScrollLoadMore}
      useWindow
      pageStart={1}
    >
      {rowComponents}
    </InfiniteScroll>
  ) : (
    rowComponents
  );
  return (
    <div className={classNames(styles.container, tableStyle)}>
      {cornerElement && (
        <div className={styles.corner}>
          <div className={styles['corner-element']}>{cornerElement}</div>
        </div>
      )}
      {leftCornerElement && (
        <div className={styles['left-corner']}>
          <div className={styles['corner-element']}>{leftCornerElement}</div>
        </div>
      )}
      <div className={styles.table} id={id}>
        <TableHeader columns={columns} onSort={onSort} sort={sort} />
        {showRows && rowsElement}
      </div>
    </div>
  );
};

Table.defaultProps = {
  rowHoverEnabled: false,
  hoverButton: undefined,
  allowLoadMore: false,
  mapItemToCell: undefined,
  onInfiniteScrollLoadMore: undefined,
  onSelectRow: undefined,
  onSort: undefined,
  showIfEmpty: undefined,
  cornerElement: undefined,
  sort: undefined,
  tableStyle: ''
};

export default Table;
