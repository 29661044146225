import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCloseFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.4 18.6c.5.5.5 1.3 0 1.8s-1.3.5-1.7 0L12 13.8l-6.6 6.6c-.5.5-1.3.5-1.7 0-.5-.5-.5-1.3 0-1.8l6.6-6.6-6.7-6.6c-.5-.5-.5-1.3 0-1.8s1.3-.5 1.8 0l6.6 6.6 6.6-6.6c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8L13.8 12z"/></svg>)}
    />
  );
}
  