// @flow

import reducer from './locationReducer';
import * as operations from './locationOperations';
import * as selectors from './locationSelectors';

import type { LocationStateEntityT, LocationStateT } from './locationTypes';

export default reducer.rootReducer;
export { operations, selectors };
export type { LocationStateEntityT, LocationStateT };
