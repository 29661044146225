// @flow

import type { StoreStateT } from '../../../commonTypes';

type IsOpenFnT = (StoreStateT, string) => boolean;
export const isOpen: IsOpenFnT = ({ ui: { callflow } }, nodeId): boolean =>
  callflow.contents[nodeId] ? callflow.contents[nodeId].isOpen : false;

type GetSelectedIdFnT = (StoreStateT, string) => string;
export const getSelectedId: GetSelectedIdFnT = ({ ui: { callflow } }, nodeId): string =>
  callflow.contents[nodeId] ? callflow.contents[nodeId].selectedId : '';

type GetSelectedTypeFnT = (StoreStateT, string) => string;
export const getSelectedType: GetSelectedTypeFnT = ({ ui: { callflow } }, nodeId): string =>
  callflow.contents[nodeId] ? callflow.contents[nodeId].selectedType : '';

type GetEditStatusFnT = (StoreStateT, string) => boolean;
export const getEditStatus: GetEditStatusFnT = ({ ui: { callflow } }, nodeId): boolean =>
  callflow.contents[nodeId] ? callflow.contents[nodeId].editStatus : false;
