// @flow

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import { CancelToken } from 'axios';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../../../commonTypes';
import { operations as userOps, selectors as userSelect } from '../../../ducks/entities/user';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import type { CreateCreateNotificationActionFnT } from '../../../ducks/ui/notification/notificationUiActions';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import userOperations from '../../../ducks/entities/user/userOperations';
import { createCsrfHeader } from '../../../utils/accessRightUtils';

import styles from './DropzoneHelp.module.scss';

type OwnPropsT = {};

type StatePropsT = {
  currentUser: CurrentUserStateT,
  isExporting: boolean
};

type DispatchPropsT = {
  exportUsers: typeof userOperations.exportUsers,
  notify: CreateCreateNotificationActionFnT
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export class DropzoneHelp extends Component<PropsT> {
  constructor(props: PropsT) {
    super(props);
    this.handleExport = this.handleExport.bind(this);
  }

  handleExport: () => void;

  async handleExport() {
    const newTokenSource = CancelToken.source();
    const { currentUser, exportUsers, notify, t } = this.props;
    const { currentEnterprise } = currentUser;
    if (currentEnterprise) {
      const returnValue = await exportUsers(
        currentEnterprise.id,
        newTokenSource.token,
        {},
        createCsrfHeader(currentUser)
      );
      if (returnValue !== undefined) {
        notify({
          tag: 'export-success',
          duration: 15000,
          type: 'info',
          message: t('importUsers.uploadStep.exportSuccess')
        });
      } else {
        notify({
          tag: 'export-failure',
          duration: 15000,
          type: 'error',
          message: t('importUsers.uploadStep.exportFailure')
        });
      }
    }
  }

  render() {
    const { isExporting, t } = this.props;
    return (
      <div className={styles['info-content']}>
        {!isExporting && (
          <div>
            <span className={styles.header}>{t('importUsers.uploadStep.infoHeader')}</span>
            <span
              id="info-link"
              onClick={() => this.handleExport()}
              className={styles['info-link']}
            >
              {t('importUsers.uploadStep.infoLink')}
            </span>
            <div>{t('importUsers.uploadStep.infoText')}</div>
          </div>
        )}
        {isExporting && <span className={classNames('ea-loading', 'ea-loading--small')} />}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => ({
  currentUser: state.currentUser,
  isExporting: userSelect.collectionIsExporting(state)
});

const mapDispatchToProps = dispatch => {
  const { exportUsers } = userOps;
  return bindActionCreators(
    {
      exportUsers,
      notify: notificationActions.createCreateNotificationAction
    },
    dispatch
  );
};

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(DropzoneHelp);
