// @flow

import React, { type Element } from 'react';
import Truncate from 'react-truncate-markup';
import classnames from 'classnames';
import Avatar from '../Avatar/Avatar';
import type { DirectoryStateEntityT } from '../../ducks/entities/directory';
import type { InternalUserStateEntityT } from '../../ducks/entities/user/userTypes';
import styles from './DirectoryItem.module.scss';

export type PropsT = {
  directoryOrInteralUser: DirectoryStateEntityT | InternalUserStateEntityT,
  className?: string
};

const readVisibleValues = (
  directoryOrInteralUser: DirectoryStateEntityT | InternalUserStateEntityT
): { displayName: string, addressNumber: string, title: string } => {
  if (directoryOrInteralUser.publicInfo) {
    return {
      displayName: directoryOrInteralUser.displayName || '',
      addressNumber: directoryOrInteralUser.publicInfo.addressNumber || '',
      title: directoryOrInteralUser.publicInfo.title || ''
    };
  }
  return {
    // $FlowFixMe: InternalUserStateEntityT
    displayName: directoryOrInteralUser.userName,
    // $FlowFixMe: InternalUserStateEntityT
    addressNumber: directoryOrInteralUser.addressNumber,
    // $FlowFixMe: InternalUserStateEntityT
    title: directoryOrInteralUser.title
  };
};

export const DirectoryItem = ({ directoryOrInteralUser, className }: PropsT): Element<'div'> => {
  const { displayName, addressNumber, title } = readVisibleValues(directoryOrInteralUser);

  const addressNumberText = addressNumber && (
    <span className={styles['address-number']}>{`(${addressNumber})`}</span>
  );
  return (
    <div className={className}>
      <Avatar
        className={styles['user-item-avatar']}
        hideAvailability
        name={displayName}
        color="#0019AF"
        size="small"
      />
      <div
        className={classnames(styles['user-item-details'], {
          [`${styles['no-title']}`]: !title
        })}
      >
        <Truncate lines={1}>
          <div className={styles['user-item-details-name']}>
            {displayName} {addressNumberText}
          </div>
        </Truncate>
        {title && (
          <Truncate lines={1}>
            <div className={styles['user-item-details-title']}>{title}</div>
          </Truncate>
        )}
      </div>
    </div>
  );
};

export default DirectoryItem;
