import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLocationFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 7.35c0 3.4-5.19 10.65-6.78 12.79a.89.89 0 0 1-1.24.19.8.8 0 0 1-.19-.18C9.69 18 4.5 10.75 4.5 7.35A7.43 7.43 0 0 1 12 0a7.43 7.43 0 0 1 7.5 7.35m-4.25.15A3.25 3.25 0 1 0 12 10.75a3.26 3.26 0 0 0 3.25-3.25m8 12.75c0-1.808-2.787-2.738-5.126-3.201a.75.75 0 1 0-.291 1.471c2.931.581 3.917 1.432 3.917 1.73 0 .673-3.317 2.25-9.75 2.25s-9.75-1.577-9.75-2.25c0-.298.981-1.146 3.896-1.728a.75.75 0 0 0-.293-1.471C3.525 17.516.75 18.447.75 20.25.75 22.826 6.582 24 12 24s11.25-1.174 11.25-3.75"/></svg>)}
    />
  );
}
  