// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import type { NotificationActionT, NotificationUiStateT } from './notificationUiTypes';

const initialState: NotificationUiStateT = {
  byId: {},
  allIds: []
};

const rootReducer = (
  state: NotificationUiStateT = initialState,
  action: NotificationActionT
): NotificationUiStateT => {
  switch (action.type) {
    case 'ring/ui/notification/CREATE': {
      return {
        byId: R.assoc(action.payload.id, action.payload, state.byId),
        allIds: R.union([action.payload.id], state.allIds)
      };
    }
    case 'ring/ui/notification/DISMISS': {
      return {
        byId: R.omit([action.payload.id], state.byId),
        allIds: R.without([action.payload.id], state.allIds)
      };
    }
    default:
      return state;
  }
};

const notificationUiReducer = {
  initialState,
  rootReducer
};

export default notificationUiReducer;
