import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGlobeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0m9.079 17.25h-3.323c.282-1.437.448-2.951.49-4.5h4.216a10.4 10.4 0 0 1-1.383 4.5m-19.541-4.5h4.233c.041 1.549.208 3.063.489 4.5H2.92a10.4 10.4 0 0 1-1.382-4.5m1.383-6H6.26a27 27 0 0 0-.489 4.5H1.538a10.4 10.4 0 0 1 1.383-4.5m19.541 4.5h-4.216a27 27 0 0 0-.489-4.5h3.323a10.4 10.4 0 0 1 1.382 4.5m-5.716 0H7.27c.043-1.558.218-3.076.516-4.5h8.444c.298 1.424.473 2.942.516 4.5m-9.476 1.5h9.476a25.6 25.6 0 0 1-.516 4.5H7.786a25.7 25.7 0 0 1-.516-4.5m12.761-7.5h-2.619a20 20 0 0 0-.923-2.728 10.55 10.55 0 0 1 3.542 2.728m-5.544-3.44c.554 1.008 1.017 2.169 1.378 3.44H8.151c.361-1.273.825-2.436 1.38-3.445A10.5 10.5 0 0 1 12 1.5c.858 0 1.688.115 2.487.31m-6.956.703c-.361.85-.671 1.767-.927 2.737H3.969a10.6 10.6 0 0 1 3.562-2.737M3.969 18.75h2.635c.256.97.566 1.887.927 2.738a10.55 10.55 0 0 1-3.562-2.738m5.562 3.445c-.555-1.009-1.019-2.172-1.38-3.445h7.714c-.361 1.271-.824 2.432-1.378 3.44-.799.195-1.629.31-2.487.31-.852 0-1.675-.113-2.469-.305m6.958-.717c.36-.848.668-1.761.923-2.728h2.619a10.55 10.55 0 0 1-3.542 2.728"/></svg>)}
    />
  );
}
  