// @flow strict-local

import React, { type Element } from 'react';
import classnames from 'classnames';
import type { AudioFieldT, PropertyAudioT } from '../../edit/children/audio/CallflowAudioUtils';
import CallflowAudioProperty from './CallflowAudioProperty';
import styles from './CallflowAudioProperty.module.scss';

type PropsT = {|
  title: string,
  audioField: AudioFieldT<PropertyAudioT>,
  isActive?: boolean
|};

export const CallflowAudioPropertyList = (props: PropsT): Element<'div'> => {
  const { title, isActive, audioField } = props;

  const audioPlaylist = audioField?.audioPlayData?.filename?.split(',');
  const activeStyle = isActive ? styles.active : styles.inactive;

  return (
    <div>
      <div className={classnames(styles.header, activeStyle)}>{title}</div>
      {audioPlaylist && audioPlaylist.length > 0
        ? audioPlaylist.map(audioFilename => (
            <CallflowAudioProperty audioField={audioField} isActive audioFilename={audioFilename} />
          ))
        : '-'}
    </div>
  );
};

export default CallflowAudioPropertyList;
