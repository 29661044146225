// @flow
import React, { type Element } from 'react';
import SelectGroup from '@design-system/component-library/src/components/SelectGroup';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { ReactComponent as ExtGroupIcon } from '../../../assets/callflow/order/extgroup.svg';
import { ReactComponent as CustomerServiceIcon } from '../../../assets/callflow/order/customerservice.svg';
import { ReactComponent as SwitchboardIcon } from '../../../assets/callflow/order/switchboard.svg';
import { ReactComponent as PlayMusicIcon } from '../../../assets/callflow/order/playmusic.svg';
import { ReactComponent as WelcomeAttendantIcon } from '../../../assets/callflow/order/welcomeattendant.svg';
import { ReactComponent as SpeedDialIcon } from '../../../assets/callflow/order/speeddial.svg';
import { ReactComponent as SpeedDialDisabledIcon } from '../../../assets/callflow/order/speeddial_disabled.svg';
import { ReactComponent as CustomerServiceDisabledIcon } from '../../../assets/callflow/order/customerservice_disabled.svg';
import { ReactComponent as ExtGroupDisabledIcon } from '../../../assets/callflow/order/extgroup_disabled.svg';
import { ReactComponent as SwitchboardDisabledIcon } from '../../../assets/callflow/order/switchboard_disabled.svg';
import { ReactComponent as PlayMusicDisabledIcon } from '../../../assets/callflow/order/playmusic_disabled.svg';
import { ReactComponent as WelcomeAttendantDisabledIcon } from '../../../assets/callflow/order/welcomeattendant_disabled.svg';
import type { CallFlowTypeT } from '../../../ducks/entities/callFlow/callFlowTypes';
import styles from './CallflowTypeSelector.module.scss';

export type AvailableServicesT = {|
  customerService: boolean,
  switchboardService: boolean,
  extGroupService: boolean,
  playMusicService: boolean,
  welcomeAttendantService: boolean,
  speedDialService: boolean
|};

export type PropsT = {|
  availableServices: AvailableServicesT,
  onChange: CallFlowTypeT => void
|};

type SelectableCallflowTypeT = {|
  id: CallFlowTypeT,
  name: string,
  icon: Element<*>,
  disabled: boolean
|};

// eslint-disable-next-line no-unused-vars
const CallflowTypeSelector = (props: PropsT) => {
  const { availableServices, onChange } = props;
  const { t } = useTranslation();
  const selectableCallflowTypes: SelectableCallflowTypeT[] = [
    {
      id: 'ACD_CUSTOMER_SERVICE',
      name: parse(
        `<div style="padding-bottom: 4px;">${t(
          'callflowTypeSelector.optionCustomerService1'
        )}</div><div style="white-space: normal; font-size: 12px; line-height: 14px">${t(
          'callflowTypeSelector.optionCustomerService2'
        )}</div>`
      ),
      icon: availableServices.customerService ? (
        <CustomerServiceIcon />
      ) : (
        <CustomerServiceDisabledIcon />
      ),
      disabled: !availableServices.customerService
    },
    {
      id: 'ACD_SWITCHBOARD',
      name: parse(
        `<div style="padding-bottom: 4px;">${t(
          'callflowTypeSelector.optionSwitchboard1'
        )}</div><div style="white-space: normal; font-size: 12px; line-height: 14px">${t(
          'callflowTypeSelector.optionSwitchboard2'
        )}</div>`
      ),
      icon: availableServices.switchboardService ? (
        <SwitchboardIcon />
      ) : (
        <SwitchboardDisabledIcon />
      ),
      disabled: !availableServices.switchboardService
    },
    {
      id: 'EXTENSION_GROUP',
      name: parse(
        `<div style="padding-bottom: 4px;">${t(
          'callflowTypeSelector.optionExtGroup1'
        )}</div><div style="white-space: normal; font-size: 12px; line-height: 14px">${t(
          'callflowTypeSelector.optionExtGroup2'
        )}</div>`
      ),
      icon: availableServices.extGroupService ? <ExtGroupIcon /> : <ExtGroupDisabledIcon />,
      disabled: !availableServices.extGroupService
    },
    {
      id: 'PLAY_MUSIC',
      name: parse(
        `<div style="padding-bottom: 4px;">${t(
          'callflowTypeSelector.optionPlayMusic1'
        )}</div><div style="white-space: normal; font-size: 12px; line-height: 14px">${t(
          'callflowTypeSelector.optionPlayMusic2'
        )}</div>`
      ),
      icon: availableServices.playMusicService ? <PlayMusicIcon /> : <PlayMusicDisabledIcon />,
      disabled: !availableServices.playMusicService
    },
    {
      id: 'WELCOME_ATTENDANT',
      name: parse(
        `<div style="padding-bottom: 4px;">${t(
          'callflowTypeSelector.optionWelcomeAttendant1'
        )}</div><div style="white-space: normal; font-size: 12px; line-height: 14px">${t(
          'callflowTypeSelector.optionWelcomeAttendant2'
        )}</div>`
      ),
      icon: availableServices.welcomeAttendantService ? (
        <WelcomeAttendantIcon />
      ) : (
        <WelcomeAttendantDisabledIcon />
      ),
      disabled: !availableServices.welcomeAttendantService
    },
    {
      id: 'SPEED_DIAL',
      name: parse(
        `<div style="padding-bottom: 4px;">${t(
          'callflowTypeSelector.optionSpeedDial1'
        )}</div><div style="white-space: normal; font-size: 12px; line-height: 14px">${t(
          'callflowTypeSelector.optionSpeedDial2'
        )}</div>`
      ),
      icon: availableServices.speedDialService ? <SpeedDialIcon /> : <SpeedDialDisabledIcon />,
      disabled: !availableServices.speedDialService
    }
  ];
  return (
    <div className={styles['selector-content']}>
      <div className={styles['description-title']}>
        {t('callflowTypeSelector.descriptionTitle')}
      </div>
      <div className={styles.description}>{t('callflowTypeSelector.description')}</div>
      <div>
        <SelectGroup
          className={styles.selector}
          type="normal"
          products={selectableCallflowTypes}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default CallflowTypeSelector;
