// @flow

import * as R from 'ramda';
import { CancelToken } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { operations as userOps, selectors as userSelect } from '../../../ducks/entities/user';
import type { KeyValuePairT } from '../../../commonTypes';
import type {
  InternalUserUpdateEntityT,
  UserStateEntityT
} from '../../../ducks/entities/user/userTypes';
import type {
  ActiveTabT,
  InternalSelectedUserT
} from '../../../ducks/ui/department/departmentUiTypes';
import {
  actionCreators as departmentUiActions,
  selectors as departmentUiSelect
} from '../../../ducks/ui/department/index';
import MoveUserIndicator from './MoveUserIndicator';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import styles from './DepartmentMovePeople.module.scss';

type PropsT = {|
  enterpriseId: string,
  departmentId: string,
  setActiveTab: ActiveTabT => void,
  destinationDepartment: KeyValuePairT<>,
  onDoneMovingUsers: () => void,
  onRetrieveUsers: () => void
|};

export const DepartmentMovePeople = (props: PropsT) => {
  const {
    enterpriseId,
    departmentId,
    setActiveTab,
    destinationDepartment,
    onDoneMovingUsers,
    onRetrieveUsers
  } = props;

  // redux
  const dispatch = useDispatch();
  const selectedUsers: InternalSelectedUserT[] = useSelector(state =>
    departmentUiSelect.selectedUsersByEnterpriseAndDepartment(state, enterpriseId, departmentId)
  );
  const currentUser = useSelector(state => state.currentUser);
  const enterpriseUsers = useSelector(state => userSelect.byEnterpriseId(state, enterpriseId));
  const isMovingUsers = useSelector(state =>
    selectedUsers.some(user => userSelect.isUpdating(state, user.extensionId))
  );
  const cancelUpdateUserRequest = CancelToken.source();
  const transferFailedUsers = useRef([]);
  const successfullyMovedPeople = useRef(0);
  const [totalNumberOfPeopleToBeMoved] = useState(selectedUsers.length);
  const [moveStatus, setMoveStatus] = useState();

  const setMoveUserStatus = () => {
    if (transferFailedUsers.current.length > 0) {
      setMoveStatus('failed');
    } else {
      setMoveStatus('success');
    }
  };

  const moveUser = async (user: ?UserStateEntityT): Promise<void> => {
    if (user) {
      const payload: InternalUserUpdateEntityT = {
        id: user.id,
        personId: user.personId,
        enterpriseId,
        userType: 'internalUser',
        departmentId:
          destinationDepartment && destinationDepartment.value !== 'empty'
            ? destinationDepartment.value
            : null
      };

      const result = await dispatch(
        userOps.update(payload, cancelUpdateUserRequest.token, createCsrfHeader(currentUser))
      );

      if (result) {
        successfullyMovedPeople.current += 1;
        if (user.department && user.department.id) {
          await dispatch(
            departmentUiActions.createDeselectDepartmentUser(
              user.enterpriseId,
              user.department.id,
              { extensionId: user.id, personId: user.personId }
            )
          );
        }
      } else {
        transferFailedUsers.current = [
          ...transferFailedUsers.current,
          { extensionId: user.id, personId: user.personId }
        ];
      }
    }
  };

  const moveUsers = async (usersToBeMoved: InternalSelectedUserT[]): Promise<void> => {
    transferFailedUsers.current = [];
    await Promise.all(
      R.pipe(
        R.map(selectedUser => R.find(R.propEq('id', selectedUser.extensionId), enterpriseUsers)),
        R.map(user => moveUser(user))
      )(usersToBeMoved)
    );

    onRetrieveUsers();
    setMoveUserStatus();
  };

  useEffect(() => {
    moveUsers(selectedUsers);
    return () => {
      cancelUpdateUserRequest.cancel();
      onRetrieveUsers();
      onDoneMovingUsers();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tryAgainMovePeople = () => {
    moveUsers(transferFailedUsers.current);
  };

  const closeMovePeople = () => {
    setMoveStatus(undefined);
    setActiveTab('people');
  };

  const cancelMovingUsers = () => {
    cancelUpdateUserRequest.cancel();
    closeMovePeople();
  };

  return (
    <div className={styles['move-people-container']} id="department-move-section">
      <MoveUserIndicator
        isMovingUsers={isMovingUsers}
        moveStatus={moveStatus}
        successfullyMovedPeople={successfullyMovedPeople.current}
        totalNumberOfPeopleToBeMoved={totalNumberOfPeopleToBeMoved}
        movePeopleSuccessfulAction={closeMovePeople}
        movePeopleTryAgainAction={tryAgainMovePeople}
        cancelMovingUsers={cancelMovingUsers}
      />
    </div>
  );
};

export default DepartmentMovePeople;
