// @flow

import reducer from './departmentReducer';
import operations from './departmentOperations';
import * as selectors from './departmentSelectors';

import type { DepartmentStateEntityT, DepartmentStateT } from './departmentTypes';

export default reducer.rootReducer;
export { operations, selectors };
export type { DepartmentStateEntityT, DepartmentStateT };
