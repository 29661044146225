// @flow
import uuid from 'uuid';
import * as R from 'ramda';
import type { TranslateT } from '../../../../../commonTypes';
import type {
  CommandEntityT,
  CommandStateT,
  WelcomeAttendantEntityT
} from '../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import { WelcomeAttendantUtils } from '../../services/WelcomeAttendantUtils';
import type { WelcomeAttendantUpdateEntityT } from '../../../../../ducks/entities/callFlow/callFlowTypes';

export const ADD_PLAY_MENU = 'ADD_PLAY_MENU';
export const ADD_FILTER_TIME = 'ADD_FILTER_TIME';
export const ADD_TRANSFER_CALL = 'ADD_TRANSFER_CALL';
export const ADD_PLAY_MESSAGE = 'ADD_PLAY_MESSAGE';

export const NEW_FIELDS = [ADD_PLAY_MENU, ADD_FILTER_TIME, ADD_TRANSFER_CALL, ADD_PLAY_MESSAGE];
export type NextStepT = {
  type: string,
  value?: string,
  name: string,
  stepName?: string,
  newStepName?: string
};

export const convertAudioNamesToAudioFields = (audioNames: string[]) => {
  const entries = {};
  audioNames.forEach(audioName => {
    const id = uuid();
    entries[id] = {
      id,
      filename: audioName,
      initial: true
    };
  });
  return entries;
};

export const renameCommandReferences = (
  references: { [string]: string[] },
  oldName: string,
  newName: string
) => {
  const renamedReferences = {
    ...R.dissoc(oldName, references),
    ...(references[oldName] ? { [newName]: references[oldName] } : {})
  };

  // $FlowFixMe Ramda missing typings
  return R.map(ref => R.map(r => (r === oldName ? newName : r), ref || []), renamedReferences);
};

const nameToUpperCase = (commands: CommandEntityT) => ({
  ...commands,
  name: commands.name ? commands.name.toUpperCase() : null
});

export const renameCommandValues = (
  commands: { [string]: CommandEntityT },
  oldName: string,
  newName: string
) => {
  const renamedCommands = {
    ...R.dissoc(oldName, commands),
    ...(commands[oldName] ? { [newName]: nameToUpperCase(commands[oldName]) } : {})
  };

  const renameNextState = (nextState: ?CommandStateT) =>
    R.path(['state'], nextState) === oldName
      ? {
          ...nextState,
          state: newName
        }
      : nextState;
  const renameCommandTarget = cmd => {
    switch (cmd.type) {
      case 'START':
      case 'AUDIO':
        return {
          ...cmd,
          name: cmd.name === oldName ? newName : cmd.name,
          nextState: renameNextState(cmd.nextState)
        };
      case 'CALENDAR':
        return {
          ...cmd,
          name: cmd.name === oldName ? newName : cmd.name,
          withinPeriodSate: renameNextState(cmd.withinPeriodSate),
          outOfPeriodState: renameNextState(cmd.outOfPeriodState)
        };
      case 'INTERACTION':
        return {
          ...cmd,
          name: cmd.name === oldName ? newName : cmd.name,
          choices: R.map(
            c => (c && c.nextState ? { ...c, nextState: renameNextState(c.nextState) } : c),
            cmd.choices || []
          ),
          retryState: renameNextState(cmd.retryState)
        };
      case 'TRANSFER':
        return cmd.name === oldName ? { ...cmd, name: newName } : cmd;
      default:
        return cmd;
    }
  };

  // $FlowFixMe Ramda missing typings
  return R.map(cmd => renameCommandTarget(cmd), renamedCommands);
};

export const renameCommandName = (
  patchPayload: WelcomeAttendantUpdateEntityT,
  oldName: string,
  newName: string
) => ({
  ...patchPayload,
  commands: patchPayload.commands
    ? renameCommandValues(patchPayload.commands, oldName, newName)
    : null
});

export const getInitialNextStep = (stepOptions: ?(NextStepT[]), targetName: ?string): NextStepT => {
  const currentNextStep = (stepOptions || []).find(step => step.name === targetName);
  return currentNextStep
    ? {
        type: currentNextStep.type,
        name: currentNextStep.name,
        value: currentNextStep.name
      }
    : {
        type: '',
        name: '',
        value: ''
      };
};

export const getNewStepData = (nextStep: ?NextStepT) =>
  nextStep && nextStep.newStepName && typeof nextStep.newStepName === 'string'
    ? {
        [nextStep.newStepName.toUpperCase()]: {
          type: nextStep.type,
          name: nextStep.newStepName ? nextStep.newStepName.toUpperCase() : null
        }
      }
    : {};

export const getNextStepPayload = (nextStep: ?NextStepT) => {
  const stepName = nextStep ? nextStep.newStepName || nextStep.stepName || nextStep.name : null;
  return {
    state: stepName ? stepName.toUpperCase() : null,
    endState: nextStep ? nextStep.stepName === 'END' : true
  };
};

export const getNewStepOptions = (translate: TranslateT<>, newStepStyle: string) => {
  const options = [
    {
      type: 'INTERACTION',
      value: ADD_PLAY_MENU,
      label: translate('callflows.welcomeAttendantStepField.addPlayMenu'),
      optionStyle: newStepStyle
    },
    {
      type: 'CALENDAR',
      value: ADD_FILTER_TIME,
      label: translate('callflows.welcomeAttendantStepField.addFilterTime'),
      optionStyle: newStepStyle
    },
    {
      type: 'TRANSFER',
      value: ADD_TRANSFER_CALL,
      label: translate('callflows.welcomeAttendantStepField.addTransferCall'),
      optionStyle: newStepStyle
    },
    {
      type: 'AUDIO',
      value: ADD_PLAY_MESSAGE,
      label: translate('callflows.welcomeAttendantStepField.addPlayMessage'),
      optionStyle: newStepStyle
    }
  ];
  return options;
};

export type StepOptionsT = {
  value: string,
  label: string,
  stepName?: string
};

export const getAllNextStepOptions = (
  stepOptions: NextStepT[],
  translate: TranslateT<>,
  newStepStyle: string,
  noEndStep?: boolean,
  noStartStep?: boolean
): StepOptionsT[] => {
  const newStepOptions: StepOptionsT[] = getNewStepOptions(translate, newStepStyle);
  // $FlowFixMe Ramda not supported
  const sortByValue = R.sortBy(R.prop('label'));
  return sortByValue([
    ...(stepOptions || [])
      .map(step => ({
        type: step.type,
        value: step.name,
        label:
          step.type === 'END'
            ? translate('callflows.welcomeAttendantStepField.end')
            : `${step.type} - ${step.name}`,
        stepName: step.name
      }))
      .filter(value => !noStartStep || (noStartStep && value.type !== 'START'))
      .filter(value => !noEndStep || (noEndStep && value.type !== 'END')),
    ...newStepOptions
  ]);
};

export const getWelcomeAttendantStepOptions = (
  welcomeAttendantData: WelcomeAttendantEntityT,
  noEndStep?: boolean
): NextStepT[] => {
  const returnValues = (R.values(welcomeAttendantData.commands) || []).map(cmd => ({
    type: cmd.type,
    name: cmd.name
  }));
  if (!noEndStep) {
    returnValues.push({
      type: 'END',
      name: 'END'
    });
  }
  return returnValues;
};

export const getUnattachedSteps = (welcomeAttendantData: WelcomeAttendantEntityT) => {
  const welcomeAttendantUtils = new WelcomeAttendantUtils();
  welcomeAttendantUtils.createCallFlowTree(welcomeAttendantData);
  const linkedSteps = welcomeAttendantUtils.linkedNodeNames;

  // $FlowFixMe Ramda missing types
  return (R.values(welcomeAttendantData.commands) || []).filter(
    cmd => cmd.name && !linkedSteps.has(cmd.name)
  );
};
