import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileXlsRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.5 17.4c.8.4 1.1.8 1.1 1.3 0 .7-.5 1.4-1.6 1.4-.5 0-1.1-.2-1.5-.5l.3-1c.4.3.8.4 1.2.4s.5-.2.5-.4-.1-.4-.8-.7c-.8-.3-1.1-.8-1.1-1.3 0-.7.5-1.3 1.6-1.3.5 0 1 .2 1.2.4l-.3.9c-.3-.2-.7-.4-1-.4-.4 0-.5.2-.5.4.1.5.3.6.9.8m-5.8 2.7-1.5-2.3 1.5-2.2H9.5l-.9 1.5-.9-1.5H6.5L8 17.9l-1.5 2.2h1.2l.9-1.5.9 1.5zm3.5 0v-1h-1.8v-3.6h-1V20h2.8zm7.1-13.7c0 .1.1.1 0 0 .1.2.2.5.2.7v14.6c0 1.2-1 2.2-2.2 2.2h-14C4 24 3 23 3 21.8V2.2C3 1 4 0 5.2 0h9.1c.2 0 .5.1.7.1.2.1.4.2.5.4L21 5.9c.1.2.2.3.3.5M15.8 6H19l-3.4-3.4v3.2c-.1.1 0 .2.2.2M5.2 22.5h14c.4 0 .7-.3.7-.7V7.5h-4.2c-1 0-1.8-.8-1.8-1.8V1.5H5.2c-.4 0-.7.3-.7.8v19.5c0 .4.3.7.7.7" className="st0"/></svg>)}
    />
  );
}
  