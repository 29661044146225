// @flow

/* eslint-disable no-underscore-dangle, jsx-a11y/label-has-for, react/no-array-index-key */

import * as R from 'ramda';
import React, { useEffect, useRef } from 'react';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import type { CancelTokenSource } from 'axios';
import { useParams } from 'react-router-dom';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../components/Tooltip';
import UnidentifiedDepartmentCard from './UnidentifiedDepartmentCard';
import { operations as departmentOps } from '../../../ducks/entities/department';
import {
  createSetDepartmentItem,
  createUnsetDepartmentItem
} from '../../../ducks/ui/userImport/userImportUiActions';
import { selectUnidentifiedDepartments } from '../../../ducks/ui/userImport/userImportUiSelectors';

import styles from './DepartmentsAndLocationsStep.module.scss';

let retrieveDepartmentsRequestCancelTokenSource: CancelTokenSource;

const DepartmentsAndLocationsStep = () => {
  const ignoreCheckRef = useRef<Checkbox>();
  const { t } = useTranslation();
  const unidentifiedDepartments = useSelector(state => selectUnidentifiedDepartments(state));
  const dispatch = useDispatch();
  const { id: enterpriseId } = useParams();

  useEffect(() => {
    retrieveDepartmentsRequestCancelTokenSource = CancelToken.source();
    if (enterpriseId) {
      dispatch(
        departmentOps.retrieveCollection(
          enterpriseId,
          retrieveDepartmentsRequestCancelTokenSource.token
        )
      );
    }
    return () => {
      retrieveDepartmentsRequestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAllDepartmentsCheckboxChange = () => {
    if (ignoreCheckRef.current.checked) {
      unidentifiedDepartments.forEach(department =>
        dispatch(
          createSetDepartmentItem({
            status: 'ignore',
            path: department.path.join('/'),
            name: R.last(department.path) || '',
            departmentSelection: null
          })
        )
      );
    } else {
      unidentifiedDepartments.forEach(department =>
        dispatch(createUnsetDepartmentItem(department.path.join('/')))
      );
    }
  };

  return (
    <div id="user-import-departments-and-locations-step" className={styles['step-content']}>
      <h3>
        {t('importUsers.departmentsAndLocationsStep.departmentsHeader')} (
        {unidentifiedDepartments.length})
      </h3>
      <p>{t('importUsers.departmentsAndLocationsStep.departmentsText')}</p>
      <div className={styles['unidentified-departments-checkbox']}>
        <Checkbox
          id="unidentified-departments-ignore-all"
          key="unidentified-departments-ignore-all"
          name="unidentified-departments-ignore-all"
          label={t('importUsers.departmentsAndLocationsStep.ignoreAll')}
          onChange={() => handleAllDepartmentsCheckboxChange()}
          inputRef={ignoreCheckRef}
        />
        <Tooltip>
          <div className={styles['tooltip-header']}>
            {t('importUsers.departmentsAndLocationsStep.ignoreAllTooltipHeader')}
          </div>
          <div className={styles['tooltip-text']}>
            {t('importUsers.departmentsAndLocationsStep.ignoreAllTooltipText')}
          </div>
        </Tooltip>
      </div>
      <div className={styles['unidentified-departments-container']}>
        {unidentifiedDepartments.map((department, index) => (
          <UnidentifiedDepartmentCard
            key={`unidentified_department_${index}`}
            unidentifiedDepartment={{
              namePath: department.path,
              userCount: department.userCount
            }}
            index={index}
            enterpriseId={enterpriseId}
          />
        ))}
      </div>
    </div>
  );
};

export default DepartmentsAndLocationsStep;
