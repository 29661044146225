import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMobileBroadbandRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.7 2.8H3.6C1.6 2.7 0 4.3 0 6.3v11.5c0 2 1.6 3.5 3.6 3.5h4.1c2 0 3.5-1.6 3.6-3.5V6.3c-.1-2-1.7-3.6-3.6-3.5m2.1 15c0 1.1-.9 2-2 2H3.6c-1.1 0-2-.8-2.1-1.9V6.3c0-1.1.9-2 2-2h4.2c1.1 0 2 .8 2.1 1.9zm4.7-9.5c2 2 2.1 5.4 0 7.4-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1 1.5-1.5 1.5-3.8 0-5.3-.3-.3-.3-.8 0-1.1.2-.2.7-.2 1 .1m2.9 10.1c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1 2.9-2.9 2.9-7.7 0-10.6-.4-.3-.4-.7-.1-1s.8-.3 1 0c3.6 3.5 3.6 9.2.1 12.7M24 12c0 3.4-1.3 6.6-3.7 9-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1 4.4-4.4 4.4-11.5 0-15.9-.2-.3-.1-.8.2-1q.45-.3.9 0c2.3 2.4 3.6 5.6 3.6 9"/></svg>)}
    />
  );
}
  