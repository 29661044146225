// @flow
/* eslint-disable import/prefer-default-export */
import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import type { EfaxStateEntityT } from './efaxTypes';

type ByIdsFnT = (StoreStateT, string[]) => EfaxStateEntityT[];
export const byIds: ByIdsFnT = ({ entities: { efax } }, ids) =>
  ids.filter(id => id in efax.byId).map(id => efax.byId[id]);

type CollectionIsLoadingFnT = StoreStateT => boolean;
export const collectionIsLoading: CollectionIsLoadingFnT = ({ entities: { efax } }) =>
  R.path(['__metadata', 'isRetrieving'], efax) === true;

type CollectionHasMoreResultsFnT = StoreStateT => boolean;
export const collectionHasMoreResults: CollectionHasMoreResultsFnT = ({ entities: { efax } }) =>
  R.path(['__metadata', 'hasMore'], efax) === true;

type CollectionHasErrorFnT = StoreStateT => boolean;
export const collectionHasError: CollectionHasErrorFnT = ({ entities: { efax } }) =>
  R.path(['__metadata', 'error'], efax);

const efaxSelectors = {
  byIds,
  collectionIsLoading,
  collectionHasMoreResults,
  collectionHasError
};

export default efaxSelectors;
