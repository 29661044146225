// @flow

import { type $AxiosError } from 'axios';
import * as R from 'ramda';
import type {
  DepartmentsPayloadT,
  RetrieveDepartmentCollectionRequestT,
  RetrieveDepartmentCollectionSuccessT,
  RetrieveDepartmentCollectionFailureT,
  DepartmentPatchRequestT,
  DepartmentPatchSuccessT,
  DepartmentPatchFailureT,
  DepartmentPostRequestT,
  DepartmentPostSuccessT,
  DepartmentPostFailureT,
  DepartmentPayloadEntityT,
  DepartmentDeleteRequestT,
  DepartmentDeleteSuccessT,
  DepartmentDeleteFailureT,
  RetrieveDepartmentCollectionCancelT,
  DepartmentPatchCancelT,
  DepartmentPostCancelT,
  DepartmentDeleteCancelT,
  RetrieveUserCountForDepartmentRequestT,
  RetrieveUserCountForDepartmentSuccessT,
  RetrieveUserCountForDepartmentFailureT,
  RetrieveUserCountForDepartmentCancelT
} from './departmentTypes';

type CreateRetrieveDepartmentCollectionRequestFnT = (
  string,
  ?{}
) => RetrieveDepartmentCollectionRequestT;

export const createRetrieveDepartmentCollectionRequest: CreateRetrieveDepartmentCollectionRequestFnT = (
  enterpriseId,
  meta = {}
) => ({
  type: 'ring/entity/department/RETRIEVE_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});

type CreateRetrieveDepartmentCollectionSuccessFnT = (
  string,
  DepartmentsPayloadT,
  ?{}
) => RetrieveDepartmentCollectionSuccessT;

export const createRetrieveDepartmentCollectionSuccess: CreateRetrieveDepartmentCollectionSuccessFnT = (
  enterpriseId,
  payload,
  meta = {}
) => ({
  type: 'ring/entity/department/RETRIEVE_COLLECTION_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId,
    totalCount: payload.totalCount
  },
  payload: {
    results: R.map(R.assoc('enterpriseId', enterpriseId), payload.results),
    idTrees: payload.idTrees
  }
});

type CreateRetrieveDepartmentCollectionFailureFnT = (
  string,
  $AxiosError<*>,
  ?{}
) => RetrieveDepartmentCollectionFailureT;

export const createRetrieveDepartmentCollectionFailure: CreateRetrieveDepartmentCollectionFailureFnT = (
  enterpriseId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/department/RETRIEVE_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});

type CreateRetrieveDepartmentCollectionCancelFnT = () => RetrieveDepartmentCollectionCancelT;
export const createRetrieveDepartmentCollectionCancel: CreateRetrieveDepartmentCollectionCancelFnT = () => ({
  type: 'ring/entity/department/RETRIEVE_COLLECTION_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

type CreateUpdateDepartmentRequestFnT = string => DepartmentPatchRequestT;
export const createUpdateDepartmentRequest: CreateUpdateDepartmentRequestFnT = id => ({
  type: 'ring/entity/department/PATCH_REQUEST',
  error: false,
  meta: { id }
});

type CreateUpdateDepartmentSuccessFnT = (string, string) => DepartmentPatchSuccessT;
export const createUpdateDepartmentSuccess: CreateUpdateDepartmentSuccessFnT = (id, name) => ({
  type: 'ring/entity/department/PATCH_SUCCESS',
  payload: { name },
  error: false,
  meta: { id }
});

type CreateUpdateDepartmentFailureFnT = (string, $AxiosError<*>) => DepartmentPatchFailureT;
export const createUpdateDepartmentFailure: CreateUpdateDepartmentFailureFnT = (id, error) => ({
  type: 'ring/entity/department/PATCH_FAILURE',
  payload: error,
  error: true,
  meta: { id }
});

type CreateUpdateDepartmentCancelFnT = () => DepartmentPatchCancelT;
export const createUpdateDepartmentCancel: CreateUpdateDepartmentCancelFnT = () => ({
  type: 'ring/entity/department/PATCH_CANCEL',
  payload: {},
  error: false,
  meta: {}
});

type CreateCreateDepartmentRequestFnT = () => DepartmentPostRequestT;
export const createCreateDepartmentRequest: CreateCreateDepartmentRequestFnT = () => ({
  type: 'ring/entity/department/POST_REQUEST',
  error: false,
  meta: {}
});

type CreateCreateDepartmentSuccessFnT = (
  string,
  DepartmentPayloadEntityT
) => DepartmentPostSuccessT;
export const createCreateDepartmentSuccess: CreateCreateDepartmentSuccessFnT = (
  enterpriseId,
  department
) => ({
  type: 'ring/entity/department/POST_SUCCESS',
  error: false,
  payload: department,
  meta: { enterpriseId }
});

type CreateCreateDepartmentFailureFnT = ($AxiosError<*>) => DepartmentPostFailureT;
export const createCreateDepartmentFailure: CreateCreateDepartmentFailureFnT = error => ({
  type: 'ring/entity/department/POST_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

type CreateCreateDepartmentCancelFnT = () => DepartmentPostCancelT;
export const createCreateDepartmentCancel: CreateCreateDepartmentCancelFnT = () => ({
  type: 'ring/entity/department/POST_CANCEL',
  payload: {},
  error: false,
  meta: {}
});

type CreateDeleteDepartmentRequestFnT = () => DepartmentDeleteRequestT;
export const createDeleteDepartmentRequest: CreateDeleteDepartmentRequestFnT = () => ({
  type: 'ring/entity/department/DELETE_REQUEST',
  error: false,
  meta: {}
});

type CreateDeleteDepartmentFailureFnT = (string, $AxiosError<*>) => DepartmentDeleteFailureT;
export const createDeleteDepartmentFailure: CreateDeleteDepartmentFailureFnT = (id, error) => ({
  type: 'ring/entity/department/DELETE_FAILURE',
  payload: error,
  error: true,
  meta: { id }
});

type CreateDeleteDepartmentSuccessFnT = (string, string) => DepartmentDeleteSuccessT;
export const createDeleteDepartmentSuccess: CreateDeleteDepartmentSuccessFnT = (
  enterpriseId,
  departmentId
) => ({
  type: 'ring/entity/department/DELETE_SUCCESS',
  error: false,
  meta: {},
  payload: { enterpriseId, departmentId }
});

type CreateDeleteDepartmentCancelFnT = () => DepartmentDeleteCancelT;
export const createDeleteDepartmentCancel: CreateDeleteDepartmentCancelFnT = () => ({
  type: 'ring/entity/department/DELETE_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

export const createRetrieveUserCountForDepartmentRequest = (
  enterpriseId: string,
  meta: {} = {}
): RetrieveUserCountForDepartmentRequestT => ({
  type: 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});
export const createRetrieveUserCountForDepartmentSuccess = (
  enterpriseId: string,
  departmentId: string,
  totalCount: number,
  meta: {} = {}
): RetrieveUserCountForDepartmentSuccessT => ({
  type: 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId,
    departmentId
  },
  payload: { totalCount }
});
export const createRetrieveUserCountForDepartmentFailure = (
  enterpriseId: string,
  error: $AxiosError<*>,
  meta: {} = {}
): RetrieveUserCountForDepartmentFailureT => ({
  type: 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});
export const createRetrieveUserCountForDepartmentCancel = (): RetrieveUserCountForDepartmentCancelT => ({
  type: 'ring/entity/department/RETRIEVE_USER_COUNT_FOR_DEPARTMENT_CANCEL',
  error: false,
  meta: {},
  payload: {}
});
