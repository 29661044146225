// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import type {
  BaseCalendarEntityT,
  CalendarActionT,
  CalendarStateEntityCollectionT,
  CalendarStateT,
  CreateCalendarActionT,
  DeleteCalendarActionT,
  RetrieveCalendarActionT,
  RetrieveCalendarsActionT,
  UpdateCalendarActionT
} from './calendarTypes';

const initialState: CalendarStateT = {
  byId: {},
  allIds: [],
  __metadata: {}
};

const retrieveReducer = (
  state: CalendarStateT,
  action: RetrieveCalendarActionT
): CalendarStateT => {
  switch (action.type) {
    case 'ring/entity/calendar/RETRIEVE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/calendar/RETRIEVE_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.calendarId}`]: {
            ...action.payload,
            __metadata: {}
          }
        },
        allIds: R.union(state.allIds, [`${action.meta.enterpriseId}-${action.meta.calendarId}`]),
        __metadata: { isRetrieving: false }
      };
    }
    case 'ring/entity/calendar/RETRIEVE_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/calendar/RETRIEVE_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const retrieveCollectionReducer = (
  state: CalendarStateT,
  action: RetrieveCalendarsActionT
): CalendarStateT => {
  switch (action.type) {
    case 'ring/entity/calendar/RETRIEVE_ALL_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/calendar/RETRIEVE_ALL_SUCCESS': {
      const ids = R.map(R.prop('id'), action.payload.results);
      return {
        byId: {
          ...action.payload.results.reduce(
            (
              collection: CalendarStateEntityCollectionT,
              calendar: BaseCalendarEntityT
            ): CalendarStateEntityCollectionT => ({
              ...collection,
              [`${action.meta.enterpriseId}-${calendar.id}`]: {
                ...calendar,
                __metadata: {}
              }
            }),
            {}
          )
        },
        __metadata: {
          isRetrieving: false
        },
        allIds: R.union(
          [],
          ids.map(id => `${action.meta.enterpriseId}-${id}`)
        )
      };
    }
    case 'ring/entity/calendar/RETRIEVE_ALL_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/calendar/RETRIEVE_ALL_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const updateReducer = (state: CalendarStateT, action: UpdateCalendarActionT): CalendarStateT => {
  switch (action.type) {
    case 'ring/entity/calendar/UPDATE_REQUEST': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.calendarId}`]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.calendarId}`],
            __metadata: {
              isUpdating: true
            }
          }
        }
      };
    }
    case 'ring/entity/calendar/UPDATE_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.calendarId}`]: {
            ...action.payload,
            __metadata: {
              isUpdating: false
            }
          }
        }
      };
    }
    case 'ring/entity/calendar/UPDATE_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.calendarId]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.calendarId}`],
            __metadata: {
              isUpdating: false,
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/calendar/UPDATE_CANCEL': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.calendarId}`]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.calendarId}`],
            __metadata: {}
          }
        }
      };
    }
    default:
      return state;
  }
};

const createReducer = (state: CalendarStateT, action: CreateCalendarActionT): CalendarStateT => {
  switch (action.type) {
    case 'ring/entity/calendar/CREATE_REQUEST': {
      return {
        ...state,
        __metadata: {
          isCreating: true
        }
      };
    }
    case 'ring/entity/calendar/CREATE_SUCCESS': {
      const {
        payload: { id: calendarId }
      } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${calendarId}`]: {
            ...action.payload,
            __metadata: {}
          }
        },
        allIds: R.union(state.allIds, [`${action.meta.enterpriseId}-${calendarId}`]),
        __metadata: {}
      };
    }
    case 'ring/entity/calendar/CREATE_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/calendar/CREATE_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const deleteReducer = (state: CalendarStateT, action: DeleteCalendarActionT): CalendarStateT => {
  switch (action.type) {
    case 'ring/entity/calendar/DELETE_REQUEST': {
      return state;
    }
    case 'ring/entity/calendar/DELETE_SUCCESS': {
      const calId = `${action.payload.enterpriseId}-${action.payload.calendarId}`;
      return {
        ...state,
        byId: {
          ...R.dissoc(calId, state.byId)
        },
        allIds: R.filter(e => e !== calId, state.allIds),
        __metadata: {
          hasDeleted: true
        }
      };
    }
    case 'ring/entity/calendar/DELETE_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.calendarId]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.calendarId}`],
            __metadata: {
              isUpdating: false,
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/calendar/DELETE_CANCEL': {
      return state;
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: CalendarStateT = initialState,
  action: CalendarActionT
): CalendarStateT => {
  switch (action.type) {
    case 'ring/entity/calendar/RETRIEVE_REQUEST':
    case 'ring/entity/calendar/RETRIEVE_SUCCESS':
    case 'ring/entity/calendar/RETRIEVE_FAILURE':
    case 'ring/entity/calendar/RETRIEVE_CANCEL': {
      return calendarReducer.retrieveReducer(state, action);
    }
    case 'ring/entity/calendar/RETRIEVE_ALL_REQUEST':
    case 'ring/entity/calendar/RETRIEVE_ALL_SUCCESS':
    case 'ring/entity/calendar/RETRIEVE_ALL_FAILURE':
    case 'ring/entity/calendar/RETRIEVE_ALL_CANCEL': {
      return calendarReducer.retrieveCollectionReducer(state, action);
    }
    case 'ring/entity/calendar/UPDATE_REQUEST':
    case 'ring/entity/calendar/UPDATE_SUCCESS':
    case 'ring/entity/calendar/UPDATE_FAILURE':
    case 'ring/entity/calendar/UPDATE_CANCEL': {
      return calendarReducer.updateReducer(state, action);
    }
    case 'ring/entity/calendar/CREATE_REQUEST':
    case 'ring/entity/calendar/CREATE_SUCCESS':
    case 'ring/entity/calendar/CREATE_FAILURE':
    case 'ring/entity/calendar/CREATE_CANCEL': {
      return calendarReducer.createReducer(state, action);
    }
    case 'ring/entity/calendar/DELETE_REQUEST':
    case 'ring/entity/calendar/DELETE_SUCCESS':
    case 'ring/entity/calendar/DELETE_FAILURE':
    case 'ring/entity/calendar/DELETE_CANCEL': {
      return calendarReducer.deleteReducer(state, action);
    }
    default:
      return state;
  }
};

const calendarReducer = {
  initialState,
  retrieveReducer,
  retrieveCollectionReducer,
  updateReducer,
  createReducer,
  deleteReducer,
  rootReducer
};

export default calendarReducer;
