import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLinkedinRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5.75h-17A2.75 2.75 0 0 0 .75 3.5v17a2.75 2.75 0 0 0 2.75 2.75h17a2.75 2.75 0 0 0 2.75-2.75v-17A2.75 2.75 0 0 0 20.5.75m1.25 19.75c0 .69-.56 1.25-1.25 1.25h-17c-.69 0-1.25-.56-1.25-1.25v-17c0-.69.56-1.25 1.25-1.25h17c.69 0 1.25.56 1.25 1.25zM8.25 9.75v9.75a.38.38 0 0 1-.37.38h-3a.38.38 0 0 1-.38-.38V9.75a.37.37 0 0 1 .37-.37h3.01a.37.37 0 0 1 .37.37M4.5 6a1.86 1.86 0 1 1 1.86 1.88A1.87 1.87 0 0 1 4.5 6m15 7.2v6.3c0 .21-.17.38-.38.38h-2.24a.38.38 0 0 1-.38-.38v-5.62a1.88 1.88 0 1 0-3.75 0v5.62a.38.38 0 0 1-.37.38h-2.26a.38.38 0 0 1-.37-.38V9.75a.37.37 0 0 1 .37-.37h2.26a.37.37 0 0 1 .37.37.31.31 0 0 0 .25.25.31.31 0 0 0 .34-.09A3.17 3.17 0 0 1 15.67 9c2.23 0 3.83 1.37 3.83 4.2"/></svg>)}
    />
  );
}
  