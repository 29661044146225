import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOnDeviceRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.25 0c-3.722 0-6.75 3.028-6.75 6.75s3.028 6.75 6.75 6.75S24 10.472 24 6.75 20.972 0 17.25 0m0 12C14.355 12 12 9.645 12 6.75s2.355-5.25 5.25-5.25 5.25 2.355 5.25 5.25S20.145 12 17.25 12m.25 2.75a.75.75 0 0 0-.75.75v1H5.25V2.25A.75.75 0 0 1 6 1.5h4.75a.75.75 0 0 0 0-1.5H6a2.25 2.25 0 0 0-2.25 2.25v19.5C3.75 22.99 4.759 24 6 24h10c1.24 0 2.25-1.01 2.25-2.25V15.5a.75.75 0 0 0-.75-.75M16 22.5H6a.75.75 0 0 1-.75-.75V18h11.5v3.75c0 .413-.337.75-.75.75m-4.29-2.96c.18.189.29.45.29.71a.99.99 0 0 1-1 1 .994.994 0 0 1-1-1c0-.271.11-.521.29-.71.37-.37 1.04-.37 1.42 0m8.836-15.054a.75.75 0 0 1-.032 1.061l-4.25 4a.75.75 0 0 1-1.044-.017l-1.5-1.5a.75.75 0 1 1 1.061-1.061l.985.985 3.721-3.501a.75.75 0 0 1 1.059.033"/></svg>)}
    />
  );
}
  