// @flow
import React, { type Element } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CallflowTreeNode from '../CallflowTreeNode';
import type {
  CommandEntityT,
  RelatedNumberT,
  TransferEntityT,
  WelcomeAttendantEntityT
} from '../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import { getTransferTargetIcon, getTransferTargetTitle } from '../TransferTargetNode';
import fieldValidators from '../../../../../fieldValidators';

export type PropsT = {|
  id: string,
  enterpriseId: string,
  type: string
|};

export const WelcomeAttendantNode = (props: PropsT): Element<typeof CallflowTreeNode> | null => {
  const { id, enterpriseId, type } = props;
  const { t } = useTranslation();

  const nodeId = id.split('_')[0];
  const callflowId = `${enterpriseId}-WELCOME_ATTENDANT-${nodeId}`;
  const commandId = id.substring(nodeId.length + 1);
  const welcomeAttendant: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const command: ?CommandEntityT = R.path(['commands', commandId], welcomeAttendant);
  const nodeType: string = command ? command.type : type;

  const commandName = command ? command.name : '';
  const getInteractiveNodeProps = (): ?{|
    image: string,
    selectedImage: string,
    title: string
  |} => {
    switch (nodeType) {
      case 'INTERACTION': {
        return {
          image: '/callflow_dialpad.svg',
          selectedImage: '/callflow_selected_dialpad.svg',
          title: commandName
        };
      }
      case 'CALENDAR': {
        return {
          image: '/callflow_calendar.svg',
          selectedImage: '/callflow_selected_calendar.svg',
          title: commandName
        };
      }
      case 'AUDIO': {
        return {
          image: '/callflow_message.svg',
          selectedImage: '/callflow_selected_message.svg',
          title: commandName
        };
      }
      case 'START':
        return {
          image: '/callflow_start.svg',
          selectedImage: '/callflow_selected_start.svg',
          title: t('callflows.treeLayout.welcomeAttendant.start')
        };
      case 'TRANSFER': {
        // $FlowFixMe Ramda missing types
        const transfer: TransferEntityT = R.path(['commands', commandId], welcomeAttendant);
        const linkedNumber: ?RelatedNumberT =
          welcomeAttendant.relatedNumbers[transfer.extensionNumber];
        return {
          ...getTransferTargetIcon(
            linkedNumber ? linkedNumber.alias : null,
            fieldValidators.externalContactPhoneNumberStrictValidator(transfer.extensionNumber) ===
              undefined,
            linkedNumber ? linkedNumber.type : null
          ),
          title: getTransferTargetTitle(
            transfer.name || '',
            transfer.extensionNumber,
            linkedNumber,
            t('callflows.treeLayout.welcomeAttendant.error')
          )
        };
      }
      default:
        return null;
    }
  };

  const nodeProps = getInteractiveNodeProps();
  if (nodeProps) {
    return (
      <CallflowTreeNode
        serviceId={welcomeAttendant.id}
        nodeId={commandName}
        type={nodeType}
        {...nodeProps}
      />
    );
  }

  const otherElements = {
    WELCOME_ATTENDANT_STEP_0: { image: '/step_0.svg' },
    WELCOME_ATTENDANT_STEP_1: { image: '/step_1.svg' },
    WELCOME_ATTENDANT_STEP_2: { image: '/step_2.svg' },
    WELCOME_ATTENDANT_STEP_3: { image: '/step_3.svg' },
    WELCOME_ATTENDANT_STEP_4: { image: '/step_4.svg' },
    WELCOME_ATTENDANT_STEP_5: { image: '/step_5.svg' },
    WELCOME_ATTENDANT_STEP_6: { image: '/step_6.svg' },
    WELCOME_ATTENDANT_STEP_7: { image: '/step_7.svg' },
    WELCOME_ATTENDANT_STEP_8: { image: '/step_8.svg' },
    WELCOME_ATTENDANT_STEP_9: { image: '/step_9.svg' },
    'WELCOME_ATTENDANT_STEP_*': { image: '/step_star.svg' },
    'WELCOME_ATTENDANT_STEP_#': { image: '/step_hash.svg' },
    WELCOME_ATTENDANT_END: {
      image: '/end_state.svg',
      title: t('callflows.treeLayout.welcomeAttendant.end')
    },
    WELCOME_ATTENDANT_START: {
      image: '/callflow_start.svg',
      title: t('callflows.treeLayout.welcomeAttendant.start')
    },
    WELCOME_ATTENDANT_ERROR: {
      image: '/step_error.svg',
      title: t('callflows.treeLayout.welcomeAttendant.error')
    }
  };
  const elementProps = R.pathOr(null, [nodeType], otherElements);
  if (elementProps) {
    return (
      <CallflowTreeNode
        {...elementProps}
        serviceId={welcomeAttendant.id}
        type={nodeType}
        notSelectable={!nodeProps || !nodeProps.selectedImage}
        nodeId={id}
      />
    );
  }
  return null;
};

export default WelcomeAttendantNode;
