"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Language(_a) {
    var code = _a.code, href = _a.href, onClick = _a.onClick, title = _a.title;
    return {
        code: code,
        href: href,
        onClick: onClick,
        title: title,
    };
}
exports.default = Language;
