// @flow

import * as R from 'ramda';
import type { StateT, ActionT, DirectoryEntityT } from './directoryTypes';

export const initialState: StateT = {
  byId: {},
  allIds: [],
  totalCount: 0,
  searchTotalCount: -1,
  avatarById: {}
};

const retrieveDirectories = (state: StateT, action: ActionT): StateT => {
  switch (action.type) {
    case 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_REQUEST': {
      // page 1 means a new user list has been requested and the old one gets removed
      if (action.meta.page === 1) {
        return {
          ...state,
          __metadata: {
            isRetrieving: true
          },
          allIds: [],
          byId: {}
        };
      }
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_SUCCESS': {
      const { results } = action.payload;
      const ids = R.map(R.prop('id'), results);
      const newValues: { [string]: DirectoryEntityT } =
        results.length > 0
          ? Object.assign(
              // $FlowFixMe spread-operation not recognized by flowtype
              ...results.map(entry => ({
                [entry.id]: { ...entry, enterpriseId: action.meta.enterpriseId }
              }))
            )
          : {};
      return {
        ...state,
        __metadata: {
          hasMore: action.meta.length === results.length
        },
        allIds: R.union(state.allIds, ids),
        byId: {
          ...state.byId,
          ...newValues
        },
        totalCount: action.meta.saveTotalCount ? action.payload.totalCount : state.totalCount,
        searchTotalCount: !action.meta.saveTotalCount ? action.payload.totalCount : -1
      };
    }
    case 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/directory/RETRIEVE_DIRECTORY_COLLECTION_CANCEL': {
      return state;
    }

    case 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_REQUEST': {
      return state;
    }
    case 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_SUCCESS': {
      const { results } = action.payload;
      const newValues: { [string]: boolean } =
        results.length > 0
          ? Object.assign(
              // $FlowFixMe spread-operation not recognized by flowtype
              ...results.map(entry => ({
                [`IUser-${entry.userId}`]: {
                  id: `IUser-${entry.userId}`,
                  isAvatarCustomized: entry.isAvatarCustomized
                }
              }))
            )
          : {};
      return {
        ...state,
        avatarById: {
          ...state.avatarById,
          ...newValues
        }
      };
    }
    case 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_FAILURE': {
      return state;
    }
    case 'ring/entity/directory/RETRIEVE_DIRECTORY_AVATAR_CANCEL': {
      return state;
    }
    default:
      return state;
  }
};

const rootReducer = (state: StateT = initialState, action: ActionT): StateT => {
  return retrieveDirectories(state, action);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  rootReducer
};
