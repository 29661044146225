import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDeleteRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.25 9.5v8a.75.75 0 0 1-1.5 0v-8a.75.75 0 0 1 1.5 0m13-5a.76.76 0 0 1-.75.75h-1.56l-1.33 15.94a2.24 2.24 0 0 1-2.24 2.06H6.63a2.24 2.24 0 0 1-2.24-2.06L3.06 5.25H1.5a.75.75 0 0 1 0-1.5h6V3A2.25 2.25 0 0 1 9.75.75h4.5A2.25 2.25 0 0 1 16.5 3v.75h6a.76.76 0 0 1 .75.75M9 3.75h6V3a.76.76 0 0 0-.75-.75h-4.5A.76.76 0 0 0 9 3zm10.43 1.5H4.57l1.31 15.81a.76.76 0 0 0 .75.69h10.74a.76.76 0 0 0 .75-.69zm-4.93 3.5a.76.76 0 0 0-.75.75v8a.75.75 0 0 0 1.5 0v-8a.76.76 0 0 0-.75-.75"/></svg>)}
    />
  );
}
  