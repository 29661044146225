import * as R from 'ramda';

// The structure is based on forwarding definitions at:
// https://atlas.elisa.fi/confluence/pages/viewpage.action?spaceKey=RING&title=Priority+of+forwarding+rules
const MAX_PRIORITY = 1000;

// forwardingType: CallForwardingTypeT
const getTypePriority = forwardingType => {
  const priorities = {
    PRESENCE: 0,
    ALWAYS: 0,
    ON_ACTIVE_CALL: 1,
    ON_BUSY: 2,
    UNREACHABLE: 3,
    NO_ANSWER: 4
  };
  return forwardingType in priorities ? priorities[forwardingType] : 0;
};

// presenceStates: PresenceStateTypeT[]
const calculatePresenceStatePriority = presenceStates => {
  if (!presenceStates) {
    return MAX_PRIORITY;
  }

  const states = {
    AVAILABLE: { id: 4, depth: 3 },
    WORKING: { id: 5, depth: 3 },
    CUSTOMER_MEETING: { id: 7, depth: 4 },
    TRAINING: { id: 8, depth: 4 },
    BUSINESS_TRIP: { id: 9, depth: 3 },
    LUNCH: { id: 10, depth: 3 },
    AWAY: { id: 12, depth: 2 },
    SICK: { id: 13, depth: 2 },
    HOLIDAYS: { id: 14, depth: 2 }
  };

  const prioritize = (id, depth) => depth * MAX_PRIORITY - id;
  const priorityValues = presenceStates.map(s =>
    s in states ? prioritize(states[s].id, states[s].depth) : 0
  );

  return R.reduce(R.max, 0, priorityValues);
};

// filterType: ?CallForwardingFilterTypeT
const calculateFilterPriority = filterType => {
  const priorities = {
    ALL_CALLS: 5,
    EXTERNAL_CALLER: 4,
    INTERNAL_CALLER: 3,
    SPECIFIC_CALLER: 2,
    ANONYMOUS_CALLER: 1,
    NO_FILTER: 0
  };

  return filterType && filterType in priorities ? priorities[filterType] : priorities.NO_FILTER;
};

// filters: FiltersOnTargetNumberTypeT[]
const calculatePriorityOnFiltersOnTargetNumber = filters => {
  const priorities = {
    EXT: -1,
    PSTN: -2,
    PLMN: -4,
    GROUP: -8
  };

  return R.reduce(
    (acc, filter) => (filter in priorities ? acc + priorities[filter] : acc),
    0,
    filters || []
  );
};

// category: ?CallForwardingFilterOnCallCategoryT
const calculateCallCategoryPriority = category => {
  const priorities = {
    PROFESSIONAL_CALL: 1,
    PERSONAL_CALL: 2,
    NO_FILTER: 3
  };

  return category && category in priorities ? priorities[category] : priorities.NO_FILTER;
};

const calculateParentPriority = isEditable => {
  // No parent (meaning personal forwarding) = 0
  // Parent (meaning company forwarding) = 1
  return isEditable ? 0 : 1;
};

// CallForwardingT => number[]
export const createPriorityTag = forwarding => {
  const priorityOnType = getTypePriority(forwarding.forwardingType);
  const priorityOnDelay =
    forwarding.forwardingType === 'NO_ANSWER' ? forwarding.noAnswerForwardingDelay : 0;
  const priorityOnPresenceState = calculatePresenceStatePriority(
    forwarding.presenceStateFilter || []
  );
  const priorityOnFilter = calculateFilterPriority(forwarding.filter);
  const priorityOnFiltersOnTargetNumber = calculatePriorityOnFiltersOnTargetNumber(
    forwarding.filtersOnTargetNumber || []
  );
  const priorityOnFilterOnCallCategory = calculateCallCategoryPriority(
    forwarding.filterOnCallCategory
  );
  const priorityOrder = calculateParentPriority(forwarding.isEditable);
  return [
    priorityOnType,
    priorityOnDelay,
    priorityOnPresenceState,
    priorityOnFilter,
    priorityOnFiltersOnTargetNumber,
    priorityOnFilterOnCallCategory,
    priorityOrder
  ];
};

export const compareForwardingPriority = (fwdA, fwdB) => {
  const fwdPriorityTagA = createPriorityTag(fwdA);
  const fwdPriorityTagB = createPriorityTag(fwdB);
  for (let i = 0; i < fwdPriorityTagA.length && i < fwdPriorityTagB.length; i += 1) {
    if (fwdPriorityTagA[i] !== fwdPriorityTagB[i]) {
      return fwdPriorityTagA[i] - fwdPriorityTagB[i];
    }
  }
  return 0;
};
