// @flow strict-local

import { CancelToken, type Canceler } from 'axios';
import React, { useState, type Element, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import { ReactComponent as Icon } from '../../../../../assets/callflow/details/cf-acd-or-ext-group-small.svg';
import { update, getOverflowOptions } from '../../../../../ducks/entities/acd/acdOperations';
import EditCallflowDetails from '../../../components/edit/EditCallflowDetails';
import ToggleDropdown from '../../../components/edit/children/ToggleDropdown';
import { createCsrfHeader } from '../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

type PropsT = {|
  callflowId: string
|};

type FormT = {
  overflowGroupEnabled: boolean,
  overflowGroup: { key: string, value: string }
};

const EditOverflow = (props: PropsT): Element<typeof EditCallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  const [dropdownOptions, setDropdownOptions] = useState<AcdEntityT[]>([]);
  const cancelLoadRequest = React.useRef<?Canceler>();
  const cancelUpdateRequest = React.useRef<?Canceler>();

  // redux
  const dispatch = useDispatch();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  // form
  const initialValues = useMemo(
    () => ({
      overflowGroupEnabled: !!acdData.overflowGroup,
      overflowGroup: acdData.overflowGroup
        ? {
            value: acdData.overflowGroup.id,
            label: (acdData.overflowGroup.label || 'Unnamed ACD').replace(/^kutsu:/, '')
          }
        : { value: '', label: '\u00a0' }
    }),
    [acdData]
  );

  // side effects
  useEffect(
    () => () => {
      if (cancelLoadRequest.current) {
        cancelLoadRequest.current();
      }
      if (cancelUpdateRequest.current) {
        cancelUpdateRequest.current();
      }
    },
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    const acdPatchPayload = {
      overflowGroupId: formData.overflowGroupEnabled ? formData.overflowGroup.value : null
    };
    return dispatch(
      update(
        acdData.enterpriseId,
        acdData.type,
        acdData.id,
        acdPatchPayload,
        new CancelToken(canceler => {
          cancelUpdateRequest.current = canceler;
        }),
        createCsrfHeader(currentUser)
      )
    );
  };

  const parseDropdownOptions = options => {
    return options
      .map(({ id, label, addressNumber }) => ({
        value: id,
        label: `${label || t('callflows.unnamedGroup')} (${addressNumber})`
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const loadOverflowOptions = useCallback(async () => {
    const options = await getOverflowOptions(
      acdData.enterpriseId,
      acdData.id,
      new CancelToken(canceler => {
        cancelLoadRequest.current = canceler;
      })
    );
    // $FlowFixMe compose missing in types
    setDropdownOptions(parseDropdownOptions([...options]));
  }, [acdData.enterpriseId, acdData.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EditCallflowDetails
      nodeId={acdData.id}
      icon={<Icon />}
      title={t('callflows.editAcdOverflow.title')}
      description={t('callflows.editAcdOverflow.description')}
      defaultValues={initialValues}
      onSaveForm={onSubmit}
      onLoadData={loadOverflowOptions}
    >
      <ToggleDropdown
        toggleField="overflowGroupEnabled"
        toggleLabel={t('callflows.editAcdOverflow.toggleLabel')}
        dropdownField="overflowGroup"
        dropdownLabel={t('callflows.editAcdOverflow.dropdownLabel')}
        // $FlowFixMe compose missing in types
        dropdownOptions={dropdownOptions}
      />
    </EditCallflowDetails>
  );
};

export default EditOverflow;
