// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import type {
  EnterpriseEntityT,
  EnterpriseGetAllActionT
} from '../../entities/enterprise/enterpriseTypes';
import type { StateT } from './enterprisesUiTypes';

const initialState: StateT = {
  allIds: [],
  __metadata: {}
};

const enterpriseCollectionReducer = (state: StateT, action: EnterpriseGetAllActionT): StateT => {
  switch (action.type) {
    case 'ring/entity/enterprise/GET_ALL_REQUEST': {
      // page 1 means a new enterprise list has been requested and the old one gets removed
      if (action.meta.page === 1) {
        return {
          ...state,
          allIds: [],
          __metadata: {}
        };
      }
      return {
        ...state,
        __metadata: {}
      };
    }
    case 'ring/entity/enterprise/GET_ALL_SUCCESS': {
      return {
        ...state,
        allIds: R.union(
          state.allIds,
          action.payload.map((enterprise: EnterpriseEntityT): string => enterprise.entID)
        ),
        __metadata: {
          hasMore: action.payload.length === action.meta.length
        }
      };
    }
    case 'ring/entity/enterprise/GET_ALL_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    default:
      return state;
  }
};

const rootReducer = (state: StateT = initialState, action: EnterpriseGetAllActionT): StateT => {
  switch (action.type) {
    case 'ring/entity/enterprise/GET_ALL_REQUEST':
    case 'ring/entity/enterprise/GET_ALL_SUCCESS':
    case 'ring/entity/enterprise/GET_ALL_FAILURE': {
      return enterpriseReducer.enterpriseCollectionReducer(state, action);
    }
    default:
      return state;
  }
};

const enterpriseReducer = {
  initialState,
  enterpriseCollectionReducer,
  rootReducer
};

export default enterpriseReducer;
