import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOmaguruRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14 15.62a1.13 1.13 0 1 1-1.14-1.12h.02c.619 0 1.12.501 1.12 1.12m2.5-6.11a3.76 3.76 0 0 1-3.75 3.75.75.75 0 0 1 0-1.5 2.25 2.25 0 1 0-2.25-2.25.75.75 0 0 1-1.5 0 3.75 3.75 0 0 1 7.5 0M.75 24a.75.75 0 0 1-.677-1.073l2.908-6.112a11.2 11.2 0 0 1-1.482-5.596A11.16 11.16 0 0 1 4.797 3.28 11.15 11.15 0 0 1 12.724 0h.035c2.101 0 4.152.586 5.937 1.697 5.263 3.276 6.881 10.227 3.607 15.494a11.17 11.17 0 0 1-6.993 5.015 11.16 11.16 0 0 1-8.132-1.19l-6.105 2.911A.74.74 0 0 1 .75 24M12.724 1.5a9.66 9.66 0 0 0-6.867 2.841 9.67 9.67 0 0 0-2.858 6.88 9.73 9.73 0 0 0 1.469 5.159c.135.217.15.487.04.718l-2.172 4.565 4.56-2.174a.75.75 0 0 1 .719.04 9.66 9.66 0 0 0 7.354 1.216A9.68 9.68 0 0 0 21.03 16.4c2.837-4.566 1.434-10.59-3.127-13.43-1.551-.966-3.294-1.45-5.162-1.471q-.009.002-.017.001"/></svg>)}
    />
  );
}
  