import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAuthorizationLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.75 18.251a.5.5 0 0 1-.5-.499 7.2 7.2 0 0 1 2.069-5.076c2.793-2.854 7.388-2.901 10.241-.106a.5.5 0 0 1-.7.715 6.247 6.247 0 0 0-8.827.091 6.2 6.2 0 0 0-1.783 4.375.5.5 0 0 1-.5.5m15.619 2.001a1.503 1.503 0 0 0 .003-2.12l-.003-.003a1.501 1.501 0 1 0-1.06 2.562h.001c.4 0 .776-.156 1.059-.439m-.707-1.416a.503.503 0 0 1-.353.855h-.001a.501.501 0 1 1 .354-.855m3.895 2.019c.38-.971.416-2.029.108-3.017l3.461-3.46a2.1 2.1 0 0 0 .624-1.503 2.128 2.128 0 0 0-3.629-1.503l-3.461 3.466a4.564 4.564 0 0 0-5.595 2.691 4.53 4.53 0 0 0 .067 3.487 4.53 4.53 0 0 0 2.515 2.419 4.564 4.564 0 0 0 5.91-2.58m-2.948-4.974c.186.07.396.029.536-.112l3.683-3.688a1.127 1.127 0 0 1 1.922.795c0 .301-.117.582-.33.795l-3.687 3.686a.51.51 0 0 0-.099.563c.318.83.315 1.742-.01 2.571a3.562 3.562 0 0 1-6.576.127 3.53 3.53 0 0 1-.053-2.722 3.566 3.566 0 0 1 4.614-2.015M3 4.75a4.5 4.5 0 1 1 4.5 4.5A4.51 4.51 0 0 1 3 4.75m1 0a3.5 3.5 0 1 0 7 0 3.5 3.5 0 0 0-7 0"/></svg>)}
    />
  );
}
  