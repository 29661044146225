// @flow

/* eslint-disable import/prefer-default-export */
import * as R from 'ramda';
import type { StoreStateT } from '../../../commonTypes';
import type {
  ImportDepartmentItemT,
  UnidentifiedDepartmentsT,
  ImportErrorT
} from './userImportUiTypes';

export type SelectByDepartmentPathFnT = (StoreStateT, ?string) => ImportDepartmentItemT | {};
export const selectByDepartmentPath: SelectByDepartmentPathFnT = (state, departmentPath) =>
  departmentPath
    ? R.path(['ui', 'userImport', 'importDepartments', departmentPath], state) || {}
    : {};

const checkIsEmpty = arr =>
  R.isNil(arr) || R.isEmpty(arr) || (arr.length === 1 && arr[0] === undefined);

export type SelectUnidentifiedDepartmentsFnT = StoreStateT => UnidentifiedDepartmentsT[];
export const selectUnidentifiedDepartments: SelectUnidentifiedDepartmentsFnT = state => {
  // $FlowFixMe: compose missing in types
  const selectNewDepartments = R.compose(
    R.filter(department => !R.isNil(department)),
    R.flatten,
    R.map(({ newDepartments }) => newDepartments),
    R.filter(({ selected, type }) => selected && type === 'internalUsers'),
    R.path(['ui', 'userImport', 'importAnalysis', 'sheets'])
  );

  const newDepartments = selectNewDepartments(state) || [];
  if (checkIsEmpty(newDepartments)) {
    return [];
  }

  return R.values(
    R.reduce(
      (acc, dep) => ({
        ...acc,
        [R.join('/', dep.path)]: {
          path: dep.path,
          userCount: dep.userCount + (R.path([R.join('/', dep.path), 'userCount'], acc) || 0)
        }
      }),
      {},
      newDepartments
    )
  );
};

export type SelectImportErrorsFnT = StoreStateT => ImportErrorT[];
export const selectImportErrors: SelectImportErrorsFnT = state => {
  // $FlowFixMe: compose missing in types
  const selectErrors = R.compose(
    R.filter(importError => !R.isNil(importError)),
    R.flatten,
    R.map(({ importErrors }) => importErrors),
    R.path(['ui', 'userImport', 'importAnalysis', 'sheets'])
  );

  const errors = selectErrors(state) || [];
  return checkIsEmpty(errors) ? [] : errors;
};

type CollectionIsImportingFnT = StoreStateT => boolean;
export const collectionIsImporting: CollectionIsImportingFnT = ({ ui: { userImport } }) => {
  return userImport.importAnalysis.__metadata.importing === true;
};

type CollectionImportedFnT = StoreStateT => number;
export const collectionImported: CollectionImportedFnT = ({ ui: { userImport } }) => {
  return userImport.importAnalysis.__metadata.imported
    ? userImport.importAnalysis.__metadata.imported
    : 0;
};

type CollectionToBeImportedFnT = StoreStateT => number;
export const collectionToBeImported: CollectionToBeImportedFnT = ({ ui: { userImport } }) => {
  return userImport.importAnalysis.__metadata.toBeImported
    ? userImport.importAnalysis.__metadata.toBeImported
    : 0;
};

type CollectionImportUuidFnT = StoreStateT => string;
export const collectionImportUUID: CollectionImportUuidFnT = ({ ui: { userImport } }) =>
  userImport.importAnalysis.__metadata.importUUID;
