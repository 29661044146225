// @flow
import React, { type Element } from 'react';
import Button from '@design-system/component-library/src/components/Button';
import classnames from 'classnames';
import styles from './ActionButton.module.scss';

export type PropsT = {|
  id: string,
  className?: string,
  disabled?: boolean,
  loading?: boolean,
  image?: Element<*>,
  label: string,
  onClickAction?: (*) => *,
  type?: string,
  hideWhenViewing?: boolean
|};

const ActionButton = (props: PropsT): Element<'Button'> => {
  const { id, disabled, loading, label, onClickAction, type, image, className } = props;
  const buttonProps = {
    id,
    type,
    onClick: onClickAction,
    disabled,
    loading
  };
  return (
    <Button
      className={classnames(styles.button, className)}
      data-cy={`action-button-${id}`}
      {...buttonProps}
      size="l"
    >
      {image && (
        <div id="image-container" className={styles['image-container']}>
          {image}
        </div>
      )}
      {label}
    </Button>
  );
};

ActionButton.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  type: 'button',
  onClickAction: () => {}
};

export default ActionButton;
