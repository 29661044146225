import React from 'react';
import IconContainer from '../IconContainer';

export default function IconExternalLinkRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.25 12.5V21A2.25 2.25 0 0 1 19 23.25H3A2.25 2.25 0 0 1 .75 21V5A2.25 2.25 0 0 1 3 2.75h8.5a.75.75 0 0 1 0 1.5H3a.76.76 0 0 0-.75.75v16a.76.76 0 0 0 .75.75h16a.76.76 0 0 0 .75-.75v-8.5a.75.75 0 0 1 1.5 0m1.94-11.29a.7.7 0 0 0-.4-.4.8.8 0 0 0-.29-.06h-6a.75.75 0 0 0 0 1.5h4.19L10 13a.75.75 0 0 0 0 1 .75.75 0 0 0 1 0L21.75 3.31V7.5a.75.75 0 0 0 1.5 0v-6a.8.8 0 0 0-.06-.29"/></svg>)}
    />
  );
}
  