// @flow strict-local

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VoiceMailIcon } from '../../../../../assets/callflow/details/voicemail_open.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import VoiceMailAudio from '../../../components/edit/children/audio/VoiceMailAudio';
import CallflowItem from '../../../components/view/children/CallflowItem';
import type { VoiceMailLevelT } from '../../../../../ducks/entities/voicemailTypes';
import type { ExtensionGroupEntityT } from '../../../../../ducks/entities/extensionGroup/extensionGroupTypes';
import type { VoiceMailAudioFieldT } from '../../../components/edit/children/audio/CallflowAudioUtils';

export type PropsT = {|
  callflowId: string
|};

export const VoiceMailDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();
  // redux
  const extGroupData: ExtensionGroupEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { voicemailSettings } = extGroupData;
  const currentlyActiveFile: VoiceMailLevelT =
    R.path(['audios', 'current', 'name'], voicemailSettings) || 'PhoneNumber';
  const currentUser = useSelector(state => state.currentUser);

  const voiceMailAudio: VoiceMailAudioFieldT = {
    enterpriseId: extGroupData.enterpriseId,
    callflowType: 'groups',
    callflowId: extGroupData.id,
    audioType: 'voicemail',
    propertyName: currentlyActiveFile,
    serviceType: 'ExtensionGroup'
  };
  return (
    <CallflowDetails
      enterpriseId={extGroupData.enterpriseId}
      callflowId={extGroupData.id}
      elementType={extGroupData.type}
      nodeId={`group_voice_mail_${extGroupData.id}`}
      nodeType="EXTENSION_GROUP_VOICE_MAIL"
      icon={<VoiceMailIcon />}
      title={t('callflows.viewVoiceMail.title')}
      active
    >
      <CallflowDetailsColumn id="ext-group-voice-mail-column-1">
        {voicemailSettings && (
          <CallflowItem
            id="isVoiceMailNoMessage"
            title={t('voicemailSettings.isVoiceMailMessage')}
            value={
              voicemailSettings.isVoiceMailNoMessage
                ? t('voicemailSettings.voiceMailNotInUse')
                : t('voicemailSettings.voiceMailInUse')
            }
          />
        )}
        <VoiceMailAudio
          title={t('voicemailSettings.title')}
          audioData={voiceMailAudio}
          noAudioLabel={
            currentlyActiveFile === 'PhoneNumber' ? t('voicemailSettings.audioDefault') : ''
          }
        />
        <CallflowItem
          id="groupPassword"
          title={t('callflows.viewVoiceMailExtra.groupPassword')}
          value={
            (currentUser.featureFlags || []).includes('FEATURE-HIDE-GROUP-PASSWORD')
              ? t('callflows.viewVoiceMailExtra.groupPasswordDeprecated')
              : '**********'
          }
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default VoiceMailDetails;
