// @flow
import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import CallflowTreeNode from '../CallflowTreeNode';

export type PropsT = {|
  serviceId: string
|};

export const PlayMusicNode = (props: PropsT): Element<typeof CallflowTreeNode> => {
  const { serviceId } = props;
  const { t } = useTranslation();

  return (
    <CallflowTreeNode
      serviceId={serviceId}
      nodeId="MESSAGE"
      type="PLAY_MUSIC"
      image="/callflow_message.svg"
      selectedImage="/callflow_selected_message.svg"
      title={t('callflows.treeLayout.playMusicIvr.notification')}
    />
  );
};

export default PlayMusicNode;
