// @flow

/* eslint-disable jsx-a11y/label-has-for */

import * as R from 'ramda';
import React, { type Element } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import Truncate from 'react-truncate-markup';
import type { ExactPropsT } from '../../../commonTypes';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';
import { actionCreators as departmentUiActions } from '../../../ducks/ui/department';
import Avatar from '../../../components/Avatar/Avatar';
import styles from './UserGridItem.module.scss';

type OwnPropsT = {
  user: InternalUserStateEntityT,
  enterpriseId: string,
  selected?: boolean,
  editMode?: boolean
};

type DispatchPropsT = {
  select: typeof departmentUiActions.createSelectDepartmentUser,
  deselect: typeof departmentUiActions.createDeselectDepartmentUser
};

export type PropsT = ExactPropsT<OwnPropsT, {}, DispatchPropsT>;

export const UserGridItem = ({
  user,
  selected,
  select,
  deselect,
  editMode,
  enterpriseId
}: PropsT): Element<'div'> => {
  const { userName = '', title = '', id: extensionId, department } = user;
  // $FlowFixMe
  const departmentId: string = R.merge({ id: undefined }, department).id;
  return (
    <div className={styles['user-grid-item']}>
      <div className={styles['user-select']}>
        {editMode && (
          <Checkbox
            id={`department-user-checkbox-${extensionId}`}
            value={selected}
            checked={selected}
            onChange={e => {
              if (e.currentTarget.checked) {
                select(enterpriseId, departmentId, {
                  extensionId: user.id,
                  personId: user.personId
                });
              } else {
                deselect(enterpriseId, departmentId, {
                  extensionId: user.id,
                  personId: user.personId
                });
              }
            }}
          />
        )}
        <Avatar hideAvailability name={userName} color="#0019AF" size="small" />
        <div className={styles['user-grid-item-details']}>
          <Truncate>
            <div className={styles['user-grid-item-details-name']}>{userName}</div>
          </Truncate>
          <Truncate>
            <div className={styles['user-grid-item-details-title']}>{title}</div>
          </Truncate>
        </div>
      </div>
    </div>
  );
};

UserGridItem.defaultProps = {
  selected: false,
  editMode: false
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      select: departmentUiActions.createSelectDepartmentUser,
      deselect: departmentUiActions.createDeselectDepartmentUser
    },
    dispatch
  );

export default connect<PropsT, OwnPropsT, _, _, _, _>(null, mapDispatchToProps)(UserGridItem);
