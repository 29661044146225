import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLinkFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.703 20.997a5.45 5.45 0 0 1-3.853-1.582 5.37 5.37 0 0 1-1.6-3.835c0-1.45.568-2.812 1.601-3.837l4.457-4.425c2.122-2.109 5.579-2.113 7.705-.007a5.4 5.4 0 0 1 1.229 5.816 1.25 1.25 0 1 1-2.326-.916 2.9 2.9 0 0 0-.663-3.124 2.976 2.976 0 0 0-4.183.004l-4.458 4.425a2.9 2.9 0 0 0-.862 2.064c0 .778.306 1.51.861 2.061a2.974 2.974 0 0 0 4.183.001 1.25 1.25 0 0 1 1.761 1.774 5.45 5.45 0 0 1-3.852 1.581m12.003-4.805 4.457-4.424c1.033-1.024 1.602-2.386 1.602-3.836s-.568-2.812-1.601-3.836a5.484 5.484 0 0 0-7.705 0 1.25 1.25 0 0 0 1.762 1.774 2.977 2.977 0 0 1 4.183 0c.555.551.861 1.284.861 2.062s-.307 1.511-.862 2.062l-4.459 4.424a2.974 2.974 0 0 1-4.182.004 2.9 2.9 0 0 1-.664-3.123 1.25 1.25 0 1 0-2.327-.915 5.4 5.4 0 0 0 1.23 5.813 5.45 5.45 0 0 0 3.85 1.579 5.45 5.45 0 0 0 3.855-1.584"/></svg>)}
    />
  );
}
  