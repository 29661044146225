import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOfficeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.5 22.5h-2.25V2.25A2.25 2.25 0 0 0 18 0H6a2.25 2.25 0 0 0-2.25 2.25V22.5H1.5a.75.75 0 0 0 0 1.5h21a.75.75 0 0 0 0-1.5m-9.25 0h-2.5v-3.25a1.25 1.25 0 0 1 2.5 0zm1.5 0v-3.25a2.75 2.75 0 1 0-5.5 0v3.25h-4V2.25A.76.76 0 0 1 6 1.5h12a.76.76 0 0 1 .75.75V22.5zM10.5 3h-3a.76.76 0 0 0-.75.75V7.5a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75V3.75A.76.76 0 0 0 10.5 3m-.75 3.75h-1.5V4.5h1.5zM16.5 3h-3a.76.76 0 0 0-.75.75V7.5a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75V3.75A.76.76 0 0 0 16.5 3m-.75 3.75h-1.5V4.5h1.5zm-5.25 3h-3a.76.76 0 0 0-.75.75v3.75a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75V10.5a.76.76 0 0 0-.75-.75m-.75 3.75h-1.5v-2.25h1.5zm6.75-3.75h-3a.76.76 0 0 0-.75.75v3.75a.76.76 0 0 0 .75.75h3a.76.76 0 0 0 .75-.75V10.5a.76.76 0 0 0-.75-.75m-.75 3.75h-1.5v-2.25h1.5z"/></svg>)}
    />
  );
}
  