// @flow
import uuid from 'uuid';
import * as R from 'ramda';
import type {
  BaseCalendarEntityT,
  DayScheduleEntityT,
  UpdateWeekScheduleT,
  WeeklySlotT,
  WeekScheduleEntityT
} from '../../../../../ducks/entities/calendar/calendarTypes';
import type {
  CallForwardingT,
  CreateCallForwardingPayloadT
} from '../../../../../ducks/entities/callForwarding/callForwardingTypes';
import type { DestinationFieldT } from '../../../components/edit/children/destinationField/DestinationFieldUtils';
import { convertFormDestinationTypeToFwdDestinationType } from './extensionGroupForwardingUtils';
import CalendarConverter from '../../../components/edit/children/calendar/CalendarConverterUtils';
import { toTitleCase } from '../../../../../utils/textUtils';

export type CalendarTypeT = 'always' | 'byCalendar';

const convertFwdWeekDay = (d: string): string => {
  const mapping = {
    Mon: 'MONDAY',
    Tue: 'TUESDAY',
    Wed: 'WEDNESDAY',
    Thu: 'THURSDAY',
    Fri: 'FRIDAY',
    Sat: 'SATURDAY',
    Sun: 'SUNDAY'
  };
  return mapping[d];
};

const convertFwdTimeToDaySchedule = (fwdTimes: ?string): DayScheduleEntityT => {
  const [start, end] = (fwdTimes || '').split('-');
  return {
    id: uuid(),
    startTime: start,
    endTime: end,
    active: false
  };
};

const initializeTimeSlots = () => ({
  MONDAY: [],
  TUESDAY: [],
  WEDNESDAY: [],
  THURSDAY: [],
  FRIDAY: [],
  SATURDAY: [],
  SUNDAY: []
});

export const convertForwardingsToWeekSlots = (timeFilters: (?string)[]): WeekScheduleEntityT => {
  const timeSlots: WeekScheduleEntityT = initializeTimeSlots();

  const addWeekend = (daySchedule: DayScheduleEntityT) => {
    timeSlots.SATURDAY.push(daySchedule);
    timeSlots.SUNDAY.push(daySchedule);
  };

  const addWeekdays = (daySchedule: DayScheduleEntityT) => {
    timeSlots.MONDAY.push(daySchedule);
    timeSlots.TUESDAY.push(daySchedule);
    timeSlots.WEDNESDAY.push(daySchedule);
    timeSlots.THURSDAY.push(daySchedule);
    timeSlots.FRIDAY.push(daySchedule);
  };

  const WEEKDAY = 'WD';
  const WEEKEND = 'WE';
  const ALL_DAYS = 'All';

  for (let i = 0; i < timeFilters.length; i++) {
    const tf = timeFilters[i];
    const [dates, fwdTimes] = (tf || '').split('/');

    const daySchedule = convertFwdTimeToDaySchedule(fwdTimes);
    const dateArr = (dates || '').split('+') || [];
    for (let j = 0; j < dateArr.length; j++) {
      if (dateArr[j] === ALL_DAYS) {
        addWeekend(daySchedule);
        addWeekdays(daySchedule);
      } else if (dateArr[j] === WEEKEND) {
        addWeekend(daySchedule);
      } else if (dateArr[j] === WEEKDAY) {
        addWeekdays(daySchedule);
      } else {
        const weekDay = convertFwdWeekDay(dateArr[j]);
        try {
          timeSlots[weekDay].push(daySchedule);
        } catch (error) {
          throw new Error('Invalid weekday format');
        }
      }
    }
  }
  return timeSlots;
};

// TimeSchedules should not overlap
export const reverseDaySchedule = (daySchedule: DayScheduleEntityT[]) => {
  const sortedSchedule = daySchedule ? R.sortBy(R.prop('startTime'), daySchedule) : [];
  let startTimes = [];
  let endTimes = [];
  for (let i = 0; i < sortedSchedule.length; i++) {
    endTimes.push(sortedSchedule[i].startTime);
    startTimes.push(sortedSchedule[i].endTime);
  }
  startTimes = ['00:00', ...startTimes];
  endTimes = [...endTimes, '24:00'];

  const results = [];
  for (let i = 0; i < startTimes.length; i++) {
    if (startTimes[i] !== endTimes[i]) {
      results.push({
        id: uuid(),
        startTime: startTimes[i],
        endTime: endTimes[i],
        active: true
      });
    }
  }
  return results;
};

export const reverseWeekSchedule = (weekSchedule: WeekScheduleEntityT): WeekScheduleEntityT =>
  // $FlowFixMe
  R.map(daySchedule => reverseDaySchedule(daySchedule), weekSchedule);

export const createTypeAlwaysForwardingPayload = (
  timeFilter: string,
  destination: ?DestinationFieldT
): ?CreateCallForwardingPayloadT => {
  if (!destination || !destination.type) {
    return null;
  }
  const ALWAYS_FORWARDING_LABEL = `Time based forwarding ${timeFilter}`;

  return {
    label: ALWAYS_FORWARDING_LABEL,
    destination: {
      type: convertFormDestinationTypeToFwdDestinationType(destination.type),
      value: destination.value || ''
    },
    filter: 'ALL_CALLS',
    isActive: true,
    presenceStateFilter: [],
    isEditable: true,
    timeFilter,
    forwardingType: 'ALWAYS'
  };
};

export const convertMinuteToMidnightToMidnight = (weekSchedule: UpdateWeekScheduleT) => {
  return R.reduce(
    (acc, entry) => {
      const weekDay = entry[0];
      // $FlowFixMe typing challenging
      const dayTimeRanges: { endTime: string, ... }[] = entry[1];
      acc[weekDay] = dayTimeRanges.map(dayTimeRange => {
        return {
          ...dayTimeRange,
          endTime: dayTimeRange.endTime === '23:59' ? '24:00' : dayTimeRange.endTime
        };
      });
      return acc;
    },
    {},
    Object.entries(weekSchedule)
  );
};

export const convertToTimes = (weekSchedule: UpdateWeekScheduleT): string[] => {
  const weekTimeEntries: {
    startTime: string,
    endTime: string,
    timeFrame: string,
    weekday: string
  }[] = R.flatten(
    Object.keys(weekSchedule).map(weekday => {
      // $FlowFixMe typing challenging
      return (weekSchedule[weekday] || []).map(daySlot => ({
        ...daySlot,
        timeFrame: `${daySlot.startTime}-${daySlot.endTime}`,
        weekday: toTitleCase(weekday).substring(0, 3)
      }));
    })
  );

  // $FlowFixMe ramda missing types
  const scheduleByTimeSlots = R.groupBy(weekTimeEntry => weekTimeEntry.timeFrame)(weekTimeEntries);
  return Object.keys(scheduleByTimeSlots).map(daytime => {
    const days = scheduleByTimeSlots[daytime]
      .map(w => w.weekday)
      .sort()
      .join('+');
    return `${days}/${daytime}`;
  });
};

export const reverseUpdateDataTimeslots = (
  weekSchedule: UpdateWeekScheduleT
): UpdateWeekScheduleT => {
  return {
    // $FlowFixMe typing challenging
    MONDAY: reverseDaySchedule(weekSchedule.MONDAY || []),
    // $FlowFixMe typing challenging
    TUESDAY: reverseDaySchedule(weekSchedule.TUESDAY || []),
    // $FlowFixMe typing challenging
    WEDNESDAY: reverseDaySchedule(weekSchedule.WEDNESDAY || []),
    // $FlowFixMe typing challenging
    THURSDAY: reverseDaySchedule(weekSchedule.THURSDAY || []),
    // $FlowFixMe typing challenging
    FRIDAY: reverseDaySchedule(weekSchedule.FRIDAY || []),
    // $FlowFixMe typing challenging
    SATURDAY: reverseDaySchedule(weekSchedule.SATURDAY || []),
    // $FlowFixMe typing challenging
    SUNDAY: reverseDaySchedule(weekSchedule.SUNDAY || [])
  };
};

export class ExtensionGroupCalendarConverter {
  static convertCalendarSlotsToForwardingRules(
    calendarType: CalendarTypeT,
    forwardingTarget: DestinationFieldT,
    weekSlots: WeeklySlotT[]
  ): CreateCallForwardingPayloadT[] {
    if (calendarType === 'always') {
      return [];
    }

    const weekSchedule: UpdateWeekScheduleT = CalendarConverter.convertToUpdateData(weekSlots);
    const weekScheduleParsed: UpdateWeekScheduleT = convertMinuteToMidnightToMidnight(weekSchedule);
    const reversedSchedule: UpdateWeekScheduleT = reverseUpdateDataTimeslots(weekScheduleParsed);
    const fwdTimeSlots: string[] = convertToTimes(reversedSchedule);
    return (
      fwdTimeSlots
        // $FlowFixMe inaccurete payload type
        .map(timeSlot => createTypeAlwaysForwardingPayload(timeSlot, forwardingTarget))
        .filter(timeSlot => timeSlot)
    );
  }

  static convertForwardingsToCalendar(forwardings: CallForwardingT[]): BaseCalendarEntityT {
    const timeFilters = (forwardings || [])
      .filter(forwarding => forwarding.isActive && forwarding.forwardingType === 'ALWAYS')
      .map(forwarding => forwarding.timeFilter)
      .filter(tf => tf);
    const calendarToReturn: BaseCalendarEntityT = {
      id: `converted-calendar-${uuid()}`,
      absoluteTimeSlots: [],
      name: 'converted-calendar',
      ownerAdmtiveDomainId: 'converted-calendar',
      weekSchedule: initializeTimeSlots()
    };
    if (R.isEmpty(timeFilters)) {
      return { ...calendarToReturn, weekSchedule: reverseWeekSchedule(initializeTimeSlots()) };
    }
    try {
      const weekSlots: WeekScheduleEntityT = convertForwardingsToWeekSlots(timeFilters);
      return { ...calendarToReturn, weekSchedule: reverseWeekSchedule(weekSlots) };
    } catch (error) {
      return { ...calendarToReturn, weekSchedule: initializeTimeSlots(), error };
    }
  }

  static isAlwaysOpenCalendar(weekSchedule: WeekScheduleEntityT): boolean {
    const openDays = R.filter(
      daySchedule =>
        daySchedule.length === 1 &&
        daySchedule[0].startTime === '00:00' &&
        daySchedule[0].endTime === '24:00',
      weekSchedule
    );
    return Object.keys(openDays).length === 7;
  }

  static getCalendarClosedTarget(forwardings: ?(CallForwardingT[])): string {
    const forwardingTargets = (forwardings || [])
      .filter(f => f.destination && f.isActive && f.forwardingType === 'ALWAYS')
      .map(f => {
        if (f.destination.type === 'ENT_VM') {
          return '777';
        }
        return f.destination.value || f.destination.type;
      });

    const targetTypes = new Set(forwardingTargets);
    if (targetTypes && targetTypes.size === 1) {
      return forwardingTargets[0];
    }
    return '';
  }
}

export default ExtensionGroupCalendarConverter;
