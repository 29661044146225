// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import type {
  RetrieveLocationCollectionActionT,
  LocationPostActionT,
  LocationDeleteActionT,
  LocationStateT,
  LocationActionT
} from './locationTypes';

const initialState: LocationStateT = {
  byId: {},
  allIds: [],
  __metadata: {}
};

const retrieveCollectionReducer = (
  state: LocationStateT,
  action: RetrieveLocationCollectionActionT
): LocationStateT => {
  switch (action.type) {
    case 'ring/entity/location/RETRIEVE_COLLECTION_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/location/RETRIEVE_COLLECTION_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe: check locationTypes
          ...action.payload.reduce(
            (collection, location) => ({
              ...collection,
              [`${location.enterpriseId}-${location.id}`]: {
                ...location,
                __metadata: {}
              }
            }),
            {}
          )
        },
        allIds: R.union(
          state.allIds,
          action.payload.map(location => `${location.enterpriseId}-${location.id}`)
        ),
        __metadata: {}
      };
    }
    case 'ring/entity/location/RETRIEVE_COLLECTION_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/location/RETRIEVE_COLLECTION_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const updateLocationReducer = (
  state: LocationStateT,
  action: LocationPostActionT
): LocationStateT => {
  switch (action.type) {
    case 'ring/entity/location/POST_REQUEST': {
      return {
        ...state,
        __metadata: {
          isPosting: true
        }
      };
    }
    case 'ring/entity/location/POST_SUCCESS': {
      const location = action.payload;
      const byIdsOmitted = R.omit(
        [`${action.meta.enterpriseId}-${action.meta.locationId}`],
        state.byId
      );
      const allIdsOmitted = R.without(
        [`${action.meta.enterpriseId}-${action.meta.locationId}`],
        state.allIds
      );
      return {
        ...state,
        byId: {
          ...byIdsOmitted,
          [`${action.meta.enterpriseId}-${location.id}`]: {
            ...location,
            __metadata: { hasUpdated: true }
          }
        },
        allIds: R.union(allIdsOmitted, [`${action.meta.enterpriseId}-${location.id}`]),
        __metadata: {}
      };
    }
    case 'ring/entity/location/POST_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/location/POST_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const deleteLocationReducer = (
  state: LocationStateT,
  action: LocationDeleteActionT
): LocationStateT => {
  switch (action.type) {
    case 'ring/entity/location/DELETE_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [`${action.meta.enterpriseId}-${action.meta.locationId}`]: {
            ...state.byId[`${action.meta.enterpriseId}-${action.meta.locationId}`],
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/location/DELETE_REQUEST': {
      return state;
    }
    case 'ring/entity/location/DELETE_SUCCESS': {
      const { enterpriseId, locationId } = action.payload;
      return {
        ...state,
        byId: R.omit([`${enterpriseId}-${locationId}`], state.byId),
        allIds: R.without([`${enterpriseId}-${locationId}`], state.allIds),
        __metadata: {
          hasUpdated: true
        }
      };
    }
    case 'ring/entity/location/DELETE_CANCEL': {
      return state;
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: LocationStateT = initialState,
  action: LocationActionT
): LocationStateT => {
  switch (action.type) {
    case 'ring/entity/location/RETRIEVE_COLLECTION_REQUEST':
    case 'ring/entity/location/RETRIEVE_COLLECTION_SUCCESS':
    case 'ring/entity/location/RETRIEVE_COLLECTION_FAILURE':
    case 'ring/entity/location/RETRIEVE_COLLECTION_CANCEL': {
      return locationReducer.retrieveCollectionReducer(state, action);
    }
    case 'ring/entity/location/POST_REQUEST':
    case 'ring/entity/location/POST_SUCCESS':
    case 'ring/entity/location/POST_FAILURE':
    case 'ring/entity/location/POST_CANCEL': {
      return locationReducer.updateLocationReducer(state, action);
    }
    case 'ring/entity/location/DELETE_REQUEST':
    case 'ring/entity/location/DELETE_SUCCESS':
    case 'ring/entity/location/DELETE_FAILURE':
    case 'ring/entity/location/DELETE_CANCEL': {
      return locationReducer.deleteLocationReducer(state, action);
    }
    default:
      return state;
  }
};

const locationReducer = {
  initialState,
  deleteLocationReducer,
  retrieveCollectionReducer,
  updateLocationReducer,
  rootReducer
};

export default locationReducer;
