import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowRightAltRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.5 11.998c0 .337-.139.665-.381.898l-6.847 6.642a.745.745 0 0 1-1.06-.016.75.75 0 0 1 .016-1.061l5.888-5.712H1.25a.75.75 0 0 1 0-1.5h19.87l-5.888-5.712a.75.75 0 1 1 1.045-1.076L23.12 11.1c.241.233.38.561.38.898"/></svg>)}
    />
  );
}
  