import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFreedomeFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.5 1 12 0 2.4 1c-.2 0-.4.2-.4.5v7.2c0 8.6 3.7 12.2 9.8 15.3.1.1.3.1.5 0 6-3 9.8-6.7 9.8-15.3V1.5c-.1-.3-.3-.5-.6-.5M12 14.9c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0 .5-.4 1-1 1m3.2-2.8c-.2.2-.6.2-.9 0a3.32 3.32 0 0 0-4.7 0c-.2.2-.6.2-.9-.1-.2-.2-.2-.6 0-.8 1.8-1.8 4.7-1.8 6.5 0 .3.3.3.7 0 .9q.15 0 0 0m1.7-2.3c-.2.2-.6.2-.9 0-2.2-2.2-5.9-2.2-8.1 0-.2.2-.6.2-.8-.1-.2-.2-.2-.6 0-.8 2.7-2.7 7.1-2.7 9.9 0 .2.3.2.7-.1.9"/></svg>)}
    />
  );
}
  