// @flow

/* eslint-disable import/prefer-default-export, no-underscore-dangle */

import type { StoreStateT } from '../../../commonTypes';
import type { HistoryActionEntityT } from './historyActionTypes';

type GetHistoryCollectionByEnterpriseIdFnT = (StoreStateT, string) => HistoryActionEntityT[];
export const getHistoryCollectionByEnterpriseId: GetHistoryCollectionByEnterpriseIdFnT = (
  {
    entities: {
      historyAction: { byId }
    }
  },
  enterpriseId
) =>
  byId
    ? Object.keys(byId).reduce(
        (acc, key) => (key.startsWith(enterpriseId) ? acc.concat(byId[key]) : acc),
        []
      )
    : [];
