// @flow

import uuid from 'uuid/v4';
import type {
  CreateNotificationActionT,
  DismissNotificationActionT,
  NewNotificationT,
  NotificationTagT
} from './notificationUiTypes';

export type CreateCreateNotificationActionFnT = (
  NewNotificationT,
  ?string
) => CreateNotificationActionT;
export const createCreateNotificationAction: CreateCreateNotificationActionFnT = (
  newNotification,
  idForTests
) => ({
  type: 'ring/ui/notification/CREATE',
  payload: {
    ...newNotification,
    id: idForTests || uuid()
  }
});

export const createNotification = (
  tag: NotificationTagT,
  successMessage: string,
  errorMessage: string,
  hasError: boolean
) =>
  createCreateNotificationAction({
    tag,
    duration: 10000,
    type: hasError ? 'error' : 'info',
    message: hasError ? errorMessage : successMessage
  });

type CreateDismissNotificationActionFnT = string => DismissNotificationActionT;
export const createDismissNotificationAction: CreateDismissNotificationActionFnT = id => ({
  type: 'ring/ui/notification/DISMISS',
  payload: { id }
});
