import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCablecardFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2M7.8 14c0 .1-.1.2-.2.2h-4c-.1 0-.2-.1-.2-.2v-4c0-.1.1-.2.2-.2h4c.1 0 .2.1.2.2zm12.7 3.1h-5c-.3 0-.6-.4-.5-.7 0-.3.3-.5.5-.5h5c.3 0 .6.4.5.7 0 .3-.2.5-.5.5"/></svg>)}
    />
  );
}
  