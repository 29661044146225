import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPlayRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.8 10 5.3.3C3.8-.5 2 .6 2 2.3v19.4c0 1.7 1.8 2.8 3.3 2L22.8 14c1.5-.9 1.5-3.1 0-4m-.7 2.7L4.6 22.4c-.1.1-.2.1-.4.1-.4 0-.8-.3-.8-.7V2.3c0-.1 0-.3.1-.4.2-.4.7-.5 1-.3L22 11.3l.3.3c.3.4.1.9-.2 1.1"/></svg>)}
    />
  );
}
  