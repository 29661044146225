import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPhoneRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.158 24a6.13 6.13 0 0 1-3.304-.964A51.4 51.4 0 0 1 .983 9.157c-1.574-2.477-1.23-5.631.826-7.689l.792-.792c.901-.9 2.472-.902 3.373 0l3.333 3.341a2.393 2.393 0 0 1 0 3.376 1.39 1.39 0 0 0 0 1.961l5.332 5.339c.526.525 1.437.525 1.957.001.906-.904 2.475-.901 3.375-.001l3.333 3.334c.928.932.928 2.446.001 3.376l-.792.793A6.12 6.12 0 0 1 18.158 24M4.287 1.478a.88.88 0 0 0-.626.259l-.791.793a4.64 4.64 0 0 0-.632 5.804 49.8 49.8 0 0 0 13.44 13.447 4.63 4.63 0 0 0 2.481.717 4.63 4.63 0 0 0 3.294-1.365l.791-.792a.89.89 0 0 0-.001-1.255l-3.332-3.333a.91.91 0 0 0-1.254.001c-1.08 1.086-2.983 1.094-4.077 0l-5.333-5.341a2.895 2.895 0 0 1 0-4.084.89.89 0 0 0 0-1.254l-3.332-3.34a.9.9 0 0 0-.628-.257"/></svg>)}
    />
  );
}
  