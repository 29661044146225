import React from 'react';
import IconContainer from '../IconContainer';

export default function IconShopLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.996 6.5a.5.5 0 0 0-.06-.22l-2.599-4.42a1.46 1.46 0 0 0-1.34-.86H3.891a1.49 1.49 0 0 0-1.33.81L.072 6.25a.6.6 0 0 0-.07.25v1a3.36 3.36 0 0 0 5.418 2.78 4.55 4.55 0 0 0 6.579.58A4.6 4.6 0 0 0 14.998 12q.22.015.44 0a4.55 4.55 0 0 0 3.129-1.7 3.358 3.358 0 0 0 5.428-2.81v-1zm-3.549-4.22L22.626 6h-3.759l-1.2-4h2.329c.191.002.365.11.451.28M12.499 2h4.129l1.2 4h-5.329zm-1 4H6.171l1.2-4H11.5zM3.502 2.28A.5.5 0 0 1 3.891 2H6.33l-1.2 4H1.362zm-1 7.51a2.37 2.37 0 0 1-1.5-2.29V7h3.999v2.29a2.37 2.37 0 0 1-2.509.5zm3.499-.46V7h5.498v2.92a3.51 3.51 0 0 1-5.498-.59M15.368 11a3.58 3.58 0 0 1-2.869-1.06V7h5.498v2.33A3.54 3.54 0 0 1 15.368 11m7.628-3.5a2.36 2.36 0 0 1-3.998 1.78V7h3.999v.5zm-1 5v9a1.5 1.5 0 0 1-1.5 1.5h-1.5a1 1 0 0 1-1-1v-5a2 2 0 1 0-3.998 0v5a1 1 0 0 1-1 1H3.502a1.5 1.5 0 0 1-1.5-1.5v-9a.5.5 0 0 1 1 0v9a.5.5 0 0 0 .5.5h9.497v-5a3 3 0 1 1 5.998 0v5h1.5a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 .999 0M10.5 19a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5H5.501a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5zm-4.499-4H10v3H6.001z"/></svg>)}
    />
  );
}
  