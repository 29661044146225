// @flow strict-local

import React from 'react';
import { useFormContext } from 'react-hook-form';
import DropdownField from './DropdownField';
import ToggleField from './ToggleField';

type PropsT = {|
  toggleField: string,
  toggleLabel: string,
  dropdownField: string,
  dropdownLabel: string,
  dropdownOptions: { label: string, value: string }[],
  disabled?: boolean
|};

export const ToggleDropdown = (props: PropsT) => {
  const {
    toggleField,
    toggleLabel,
    dropdownField,
    dropdownLabel,
    dropdownOptions,
    disabled
  } = props;
  const { watch } = useFormContext();
  const values = watch();

  return (
    <>
      <ToggleField field={toggleField} label={toggleLabel} disabled={disabled} />
      {values[toggleField] && (
        <DropdownField field={dropdownField} label={dropdownLabel} options={dropdownOptions} />
      )}
    </>
  );
};

export default ToggleDropdown;
