import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHomeApplianceLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 1H4.6C3.7 1 3 1.7 3 2.5v18.9c0 .9.7 1.6 1.5 1.6H6v.5c0 .3.2.5.5.5s.5-.2.5-.5V23h10v.5c0 .3.2.5.5.5s.5-.2.5-.5V23h1.5c.9 0 1.5-.7 1.5-1.5V2.6c0-.9-.7-1.6-1.5-1.6M4.6 2h14.9c.3 0 .5.2.5.6V9H4V2.6q0-.6.6-.6c-.1 0 0 0 0 0m14.9 20H4.6c-.4 0-.6-.2-.6-.5V10h16v11.4c0 .4-.2.6-.5.6M7 12.5v4c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5s.5.2.5.5m-1-6v-2c0-.3.2-.5.5-.5s.5.2.5.5v2c0 .3-.2.5-.5.5S6 6.8 6 6.5"/></svg>)}
    />
  );
}
  