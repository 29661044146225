// TO USE:
// waitForElm('.some-class').then((elm) => {
//   console.log('Element is ready');
//   console.log(elm.textContent);
// });

// OR:
// const elm = await waitForElm('.some-class');

export const waitForElm = (selector) => new Promise(resolve => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  })