// @flow strict

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

const useFormAutoRegister = (fieldNames: string[], options: mixed) => {
  const { register, unregister, ...rest } = useForm(options);
  useEffect(() => {
    fieldNames.forEach(name => register(name));
    return () => fieldNames.forEach(name => unregister(name));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return rest;
};

export default useFormAutoRegister;
