// @flow

import * as R from 'ramda';
import type { InternalAddressT } from '../../../ducks/entities/acd/acdTypes';
import type { AcdTypeT } from '../../../ducks/entities/acd/acdActions';
import { isMobileNumber } from '../../../helpers';
import type { FeatureFlagT } from '../../../ducks/currentUser/currentUserTypes';

type LevelT = 'Extension' | 'Default' | 'Inactive';

export type XlsxRowDataT = {
  type: AcdTypeT,
  label: string,
  addressNumber: string,
  members: string,
  pnNumbers: string,
  queueSize: string,
  ringPattern: string,
  active: string,
  openHours: string,
  groupDisplayedLabelsTerminalsPolicy: string,
  groupDisplayedLabelsUssdPolicy: string,
  groupDisplayedNumberPolicy: string,
  keepQueuedOnCalendarClosed: string,
  syndicalPause: string,
  displayName: string,
  hiddenDirectory: string,
  priority: string,
  protectedFields: string,
  overflowGroup: string,
  skipWelcomeMsg: string,
  bypassWelcome: string,
  ringingTimeout: string,
  timeUnreachable: string,
  maxDurationInQueue: string,
  maxNoAnswer: string,
  noOperator: string,
  noOperatorFwd: string,
  closingFwd: string,
  periodicityTimer: string,
  callbackSystematic: string,
  queueTimeNotification: string,
  queueSizeNotification: string,
  blockVoiceMailToUnloggedAgents: string,
  blockVoiceMailToAgents: string,
  starToVoiceMail: string,
  acdGroupCallbackEnabled: string,
  servicePacks: string,
  busyFwd: string,
  goodByeFwd: string,
  exceptionalClosing: string,
  voiceMailSpecialEmails: string,
  bypassDisplayPoliciesForVM: string,
  callbackQueueFullOrTimeout: string,
  callbackKeepTimeout: string,
  callbackKeepCheckPeriod: string,
  callbackWhenNoOperator: string,
  callbackWhenMaxTimeInQueue: string,
  callbackRingingTimeout: string,
  callbackMaxAttempts: string,
  callbackFeedbackDuration: string,
  callbackReinjectionPauseDuration: string,
  callbackSendSmsOnRegister: string,
  callbackSendSmsOnSuccess: string,
  callbackSendSmsOnError: string,
  isVoiceMailNoMessage: string,
  starToVoiceMailMessage: LevelT,
  welcomeMsg: LevelT,
  waitingAnnounceMsg: LevelT,
  waitingMusicMsg: LevelT,
  queueFullMsg: LevelT,
  goodByeMsg: LevelT,
  noOperatorMsg: LevelT,
  closingMsg: LevelT,
  exceptionalClosingMsg: LevelT,
  callbackAnnouncement: LevelT,
  callbackAnnouncementBeforeExiting: LevelT,
  callbackAlreadyInQueue: LevelT,
  callbackRegistered: LevelT,
  callbackWaitAnswer: LevelT
};

export type InternalIdsT = {
  [string]: InternalAddressT
};

export type ImportOptionTypeT =
  | 'includePstn'
  | 'includeMembers'
  | 'includeAudio'
  | 'includeCalendar'
  | 'includeForwardings'
  | 'includeOther';

export type ImportOptionsItemT = {|
  includePstn: boolean,
  includeMembers: boolean,
  includeAudio: boolean,
  includeCalendar: boolean,
  includeForwardings: boolean,
  includeOther: boolean
|};

export type ImportOptionsT = {
  all: ImportOptionsItemT,
  [addressNumber: string]: ImportOptionsItemT
};

type PropTypeT = {
  type?: ?string,
  level: LevelT,
  value: ?string
};

type AudioPropTypeT = {
  type: 'File',
  level: LevelT
};

const createProp = (value: ?string, type?: string, level?: LevelT): PropTypeT => ({
  ...(type ? { type } : {}),
  level: level || 'Extension',
  value
});

const createAudioProp = (level: LevelT): AudioPropTypeT => ({
  type: 'File',
  level
});

const createFwdProp = (value: string) => {
  const isValidTarget =
    value && (isMobileNumber(value) || (value.match(/^\d+$/) && value.length < 8));
  if (!value || !isValidTarget) {
    return createProp(undefined);
  }
  if (value.length < 8) {
    return createProp(`internal${value}`);
  }
  return createProp(`external${value}`);
};

export const uniqueNonEmptyNumberList = (list: (?string)[]): string[] => {
  const filteredResults = list
    .filter(n => n !== undefined && n !== null && typeof n === 'string')
    // $FlowFixMe Missing types
    .map(n => n.trim())
    .filter(n => n.match(/^\d+$/) && n.length < 8);
  return [...new Set(filteredResults)];
};

export const convertCsvStringToList = (list: string): string[] =>
  (list ? list.split(',') : []).map(e => (e ? e.trim() : '')).filter(e => e);

const mergeSpecificAndOtherFields = (specificsFn, othersFn, sourceProps) =>
  // $FlowFixMe
  R.mergeRight(R.pickBy(specificsFn, sourceProps), R.pickBy(othersFn, sourceProps));
const calendarProp = key => key === 'openHours';
const forwardingProp = key => key.endsWith('Fwd');

// $FlowFixMe
const audioProp = value => value.type === 'File';

const createAcdCallCenterPayload = (
  xlsxData: XlsxRowDataT,
  importOptions: ImportOptionsItemT,
  featureFlags: FeatureFlagT[]
) => {
  let acdCallCenter = {
    callbackWaitAnswer: createAudioProp(xlsxData.callbackWaitAnswer),
    callbackRegistered: createAudioProp(xlsxData.callbackRegistered),
    callbackAlreadyInQueue: createAudioProp(xlsxData.callbackAlreadyInQueue),
    callbackAnnouncementBeforeExiting: createAudioProp(xlsxData.callbackAnnouncementBeforeExiting),
    callbackAnnouncement: createAudioProp(xlsxData.callbackAnnouncement),
    exceptionalClosingMsg: createAudioProp(xlsxData.exceptionalClosingMsg),
    closingMsg: createAudioProp(xlsxData.closingMsg),
    noOperatorMsg: createAudioProp(xlsxData.noOperatorMsg),
    goodByeMsg: createAudioProp(xlsxData.goodByeMsg),
    queueFullMsg: createAudioProp(xlsxData.queueFullMsg),
    waitingMusicMsg: createAudioProp(xlsxData.waitingMusicMsg),
    waitingAnnounceMsg: createAudioProp(xlsxData.waitingAnnounceMsg),
    welcomeMsg: createAudioProp(xlsxData.welcomeMsg),
    starToVoiceMailMessage: createAudioProp(xlsxData.starToVoiceMailMessage),
    bypassWelcome: createProp(xlsxData.bypassWelcome),
    ringingTimeout: createProp(xlsxData.ringingTimeout),
    skipWelcomeMsg: createProp(xlsxData.skipWelcomeMsg),
    timeUnreachable: createProp(xlsxData.timeUnreachable),
    maxDurationInQueue: createProp(xlsxData.maxDurationInQueue),
    keepQueuedOnCalendarClosed: createProp(xlsxData.keepQueuedOnCalendarClosed),
    maxNoAnswer: createProp(xlsxData.maxNoAnswer),
    noOperator: createProp(xlsxData.noOperator),
    openHours: createProp(xlsxData.openHours, 'Calendar'),
    queueSizeNotification: createProp(xlsxData.queueSizeNotification),
    queueTimeNotification: createProp(xlsxData.queueTimeNotification),
    periodicityTimer: createProp(xlsxData.periodicityTimer),
    callbackSystematic: createProp(xlsxData.callbackSystematic),
    starToVoiceMail: createProp(xlsxData.starToVoiceMail),
    blockVoiceMailToAgents: createProp(xlsxData.blockVoiceMailToAgents),
    blockVoiceMailToUnloggedAgents: createProp(xlsxData.blockVoiceMailToUnloggedAgents),
    noOperatorFwd: createFwdProp(xlsxData.noOperatorFwd),
    closingFwd: createFwdProp(xlsxData.closingFwd),
    busyFwd: createFwdProp(xlsxData.busyFwd),
    goodByeFwd: createFwdProp(xlsxData.goodByeFwd),
    exceptionalClosing: createProp(xlsxData.exceptionalClosing),
    bypassDisplayPoliciesForVM: createProp(xlsxData.bypassDisplayPoliciesForVM),
    callbackQueueFullOrTimeout: createProp(xlsxData.callbackQueueFullOrTimeout),
    callbackKeepTimeout: createProp(xlsxData.callbackKeepTimeout),
    callbackKeepCheckPeriod: createProp(xlsxData.callbackKeepCheckPeriod),
    callbackWhenNoOperator: createProp(xlsxData.callbackWhenNoOperator),
    callbackWhenMaxTimeInQueue: createProp(xlsxData.callbackWhenMaxTimeInQueue),
    callbackRingingTimeout: createProp(xlsxData.callbackRingingTimeout),
    callbackMaxAttempts: createProp(xlsxData.callbackMaxAttempts),
    callbackFeedbackDuration: createProp(xlsxData.callbackFeedbackDuration),
    callbackReinjectionPauseDuration: createProp(xlsxData.callbackReinjectionPauseDuration),
    callbackSendSmsOnRegister: createProp(xlsxData.callbackSendSmsOnRegister),
    callbackSendSmsOnSuccess: createProp(xlsxData.callbackSendSmsOnSuccess),
    callbackSendSmsOnError: createProp(xlsxData.callbackSendSmsOnError)
  };

  if (!(featureFlags || []).includes('FEATURE-HIDE-VOICEMAIL-SPECIAL-EMAILS')) {
    acdCallCenter = {
      ...acdCallCenter,
      voiceMailSpecialEmails: createProp(xlsxData.voiceMailSpecialEmails)
    };
  }

  const selectSpecificProps = (value, key) =>
    (importOptions.includeCalendar && calendarProp(key)) ||
    (importOptions.includeForwardings && forwardingProp(key)) ||
    (importOptions.includeAudio && audioProp(value));
  const selectOtherProps = (value, key) =>
    importOptions.includeOther && !forwardingProp(key) && !calendarProp(key) && !audioProp(value);
  return mergeSpecificAndOtherFields(selectSpecificProps, selectOtherProps, acdCallCenter);
};

const membersProp = key => key === 'memberIds';
const pnNumbersProp = key => key === 'pstnNumbers';

export const buildAcdPatchPayload = (
  xlsxData: XlsxRowDataT,
  internalIds: InternalIdsT,
  servicePackIds: string[],
  importOptions: ImportOptionsItemT,
  featureFlags: FeatureFlagT[]
) => {
  const groupMembers: string[] = convertCsvStringToList(xlsxData.members) || [];
  const patchProps = {
    active: xlsxData.active,
    memberIds: groupMembers.map(m => internalIds[m] && internalIds[m].id),
    label: xlsxData.label,
    overflowGroupId:
      xlsxData.overflowGroup && internalIds[xlsxData.overflowGroup]
        ? internalIds[xlsxData.overflowGroup].id
        : '',
    queueSize: xlsxData.queueSize,
    ringPattern: xlsxData.ringPattern,
    pstnNumbers: convertCsvStringToList(xlsxData.pnNumbers),
    priority: xlsxData.priority,
    protectedFields: convertCsvStringToList(xlsxData.protectedFields),
    syndicalPause: xlsxData.syndicalPause,
    servicePackIds,
    groupDisplayedLabelsTerminalsPolicy: convertCsvStringToList(
      xlsxData.groupDisplayedLabelsTerminalsPolicy
    ),
    groupDisplayedLabelsUssdPolicy: convertCsvStringToList(xlsxData.groupDisplayedLabelsUssdPolicy),
    groupDisplayedNumberPolicy: xlsxData.groupDisplayedNumberPolicy,
    hiddenDirectory: xlsxData.hiddenDirectory,
    acdGroupCallbackEnabled: xlsxData.acdGroupCallbackEnabled,
    isVoiceMailNoMessage: xlsxData.isVoiceMailNoMessage
  };

  const selectSpecificProps = (_, key) =>
    (importOptions.includeMembers && membersProp(key)) ||
    (importOptions.includePstn && pnNumbersProp(key));
  const selectOtherProps = (_, key) =>
    importOptions.includeOther && !membersProp(key) && !pnNumbersProp(key);

  return {
    ...mergeSpecificAndOtherFields(selectSpecificProps, selectOtherProps, patchProps),
    ...(importOptions.includeCalendar ||
    importOptions.includeForwardings ||
    importOptions.includeOther ||
    importOptions.includeAudio
      ? {
          ivrProperties: {
            acdCallCenter: createAcdCallCenterPayload(xlsxData, importOptions, featureFlags)
          }
        }
      : {})
  };
};
