// @flow

import React, { type Element } from 'react';
import classnames from 'classnames';
import styles from './EditTitle.module.scss';

export type PropsT = {
  title: string,
  className?: string
};

const EditTitle = ({ title, className }: PropsT): Element<'div'> => (
  <div className={classnames(styles.top, className)}>
    <div className={styles['buttons-bar']}>
      <div id="fullname-label" className={styles['user-name']}>
        {title}
      </div>
    </div>
  </div>
);

EditTitle.defaultProps = {
  className: '' // eslint-disable-line
};

export default EditTitle;
