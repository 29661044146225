import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAddressBookFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 0h2.8v24H4c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2m18 2v20c0 1.1-.9 2-2 2H8V0h12c1.1 0 2 .9 2 2m-3.2 4c0-1-.9-1.7-1.9-1.7h-4.8c-1 0-1.8.7-1.9 1.7 0 1 .8 1.8 1.8 1.8h4.8c1.1 0 1.9-.8 2-1.8q-.15 0 0 0"/></svg>)}
    />
  );
}
  