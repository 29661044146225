// @flow
import React, { type Element, useState } from 'react';
import Truncate from 'react-truncate-markup';
import Input from '@design-system/component-library/src/components/Input';
import IconEditRegular from '@design-system/component-library/src/components/Icon/lib/IconEditRegular';
import { useTranslation } from 'react-i18next';
import CancelButton from '../Button/CancelButton';
import ActionButton from '../Button/ActionButton';
import styles from './SingleField.module.scss';

export type PropsT = {
  id: string,
  onSaveField: string => void,
  initialValue: string,
  errorMessage: string,
  isValid: (value: string) => boolean,
  hideEdit: boolean
};

const SingleField = (props: PropsT): Element<'div'> => {
  const { id, onSaveField, initialValue, errorMessage, isValid, hideEdit } = props;

  const [editingTitle, setEditingTitle] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [enterpriseName, setEnterpriseName] = useState(initialValue);

  const { t } = useTranslation();

  const onSave = async () => {
    setIsUpdating(true);
    await onSaveField(enterpriseName);
    setEditingTitle(false);
    setIsUpdating(false);
  };

  const cancelEditing = () => {
    setEditingTitle(false);
    setEnterpriseName(initialValue);
  };

  const ViewForm = (
    <div className={styles.container}>
      <table>
        <tbody>
          <tr>
            <td>
              <div className={styles.title}>
                <Truncate lines={2}>
                  <div>{enterpriseName}</div>
                </Truncate>
              </div>
            </td>
            {!hideEdit && (
              <td className={styles['button-cell']}>
                <div
                  id={`${id}-button`}
                  className={styles['edit-button']}
                  onClick={() => setEditingTitle(true)}
                  onKeyPress={() => setEditingTitle(true)}
                  role="button"
                  tabIndex="0"
                >
                  <IconEditRegular size="s" color="brand-blue" />
                  <span>{t('components.singleField.edit')}</span>
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );

  const EditForm = (
    <div className={styles['edit-area']}>
      <div className={styles.input}>
        <Input
          id="enterpriseNameInput"
          touched
          i18n_input_errorMessage={!isValid(enterpriseName) ? errorMessage : ''}
          onValueChange={e => setEnterpriseName(e.currentTarget.value)}
          defaultValue={enterpriseName}
        />
      </div>
      <div className={styles['button-area']}>
        <ActionButton
          id={`save-button-${id}`}
          className={styles['save-button']}
          label={t('components.singleField.save')}
          loading={isUpdating}
          disabled={!isValid(enterpriseName) || isUpdating}
          onClickAction={() => onSave()}
        />
        <CancelButton
          id={`cancel-button-${id}`}
          className={styles['cancel-button']}
          label={t('components.singleField.cancel')}
          onClickAction={cancelEditing}
        />
      </div>
    </div>
  );

  return editingTitle ? EditForm : ViewForm;
};

export default SingleField;
