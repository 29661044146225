import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDownloadLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 18.5v2a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 20.5v-2a.5.5 0 0 1 1 0v2A2.5 2.5 0 0 0 3.5 23h17a2.5 2.5 0 0 0 2.5-2.5v-2a.5.5 0 0 1 1 0m-12.35.35a.36.36 0 0 0 .16.11.47.47 0 0 0 .38 0 .36.36 0 0 0 .16-.11l7.32-7.31a.502.502 0 0 0-.71-.71l-6.46 6.46V.5a.5.5 0 0 0-1 0v16.79L5 10.83a.502.502 0 0 0-.71.71z"/></svg>)}
    />
  );
}
  