import React from 'react';
import IconContainer from '../IconContainer';

export default function IconShopFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m6.273 6.539 1.608-5.364A.25.25 0 0 1 8.117 1h2.889c.136 0 .246.11.246.246v8.328c0 .146-.06.29-.172.384a3.28 3.28 0 0 1-2.417.761 3.3 3.3 0 0 1-2.411-1.465v-2.57q0-.074.021-.145M20.82 9.628l.006-.001c.14 0 .274-.022.409-.041.115-.018.224-.039.317-.061l.012-.003a2.917 2.917 0 0 0 1.755-4.348c-.005-.009 0-.015-.004-.023l-1.957-3.333A1.49 1.49 0 0 0 20.024 1h-2.037a.246.246 0 0 0-.236.317l1.493 4.967.032.216v2.408c0 .165.074.332.215.418.418.258.9.32 1.329.302M4.509 9.326a.49.49 0 0 0 .215-.418V6.5l.032-.216 1.493-4.967A.246.246 0 0 0 6.013 1H3.976a1.49 1.49 0 0 0-1.334.817L.685 5.15c-.004.008.001.015-.004.023a2.916 2.916 0 0 0 1.755 4.348l.012.003c.094.023.203.043.317.061.135.019.269.041.409.041l.006.001c.429.019.911-.043 1.329-.301m13.22-2.787-1.608-5.364A.25.25 0 0 0 15.885 1h-2.889a.246.246 0 0 0-.246.246v8.328c0 .146.06.29.172.384a3.28 3.28 0 0 0 2.417.761 3.3 3.3 0 0 0 2.411-1.465v-2.57a.5.5 0 0 0-.021-.145M22 10.952V21.5c0 .827-.673 1.5-1.5 1.5h-1.002c-.551 0-1-.449-1-1v-5.25a2.5 2.5 0 1 0-5 0V22c0 .551-.449 1-1 1H3.5c-.827 0-1.5-.673-1.5-1.5V10.939l.02.01a3.63 3.63 0 0 0 3.353-.354 4.82 4.82 0 0 0 3.142 1.617A4.8 4.8 0 0 0 12 11.168a4.82 4.82 0 0 0 3.485 1.045 4.8 4.8 0 0 0 3.149-1.626c.87.551 2.009.697 3.245.396zM10.75 14.75a.5.5 0 0 0-.5-.5h-4.5a.5.5 0 0 0-.5.5v3.5a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5z"/></svg>)}
    />
  );
}
  