import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGuideRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.605 4.588-2.473-2.224A1.24 1.24 0 0 0 20.25 2h-8V.75a.75.75 0 0 0-1.5 0V2h-6.5C3.561 2 3 2.561 3 3.25v4.5C3 8.439 3.561 9 4.25 9h6.5v1.25h-7c-.333 0-.646.129-.855.338L.368 12.865c-.237.236-.368.55-.368.885s.131.649.395.911L2.884 16.9c.234.226.541.35.866.35h7v6a.75.75 0 0 0 1.5 0v-6h7.5c.689 0 1.25-.561 1.25-1.25v-4.5c0-.689-.561-1.25-1.25-1.25h-7.5V9h8c.325 0 .632-.124.866-.349l2.518-2.267c.232-.232.366-.555.366-.884s-.134-.652-.395-.912M19.5 15.75H3.848l-2.223-1.999 2.221-2.001H19.5zm.653-8.25H4.5v-4h15.653l2.223 2z"/></svg>)}
    />
  );
}
  