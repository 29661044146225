// @flow
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import HTTP from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import { actions as notificationActions } from '../../../../ducks/ui/notification';
import type {
  CreateCallForwardingPayloadT,
  ForwardingActionT,
  UpdateCallForwardingPayloadT
} from '../../../../ducks/entities/callForwarding/callForwardingTypes';
import {
  createCallForwarding,
  updateCallForwarding as updateCallForwardingOp,
  updateCallForwardings as updateCallForwardingsOp,
  deleteCallForwarding as deleteCallForwardingOp,
  deleteCallForwardings as deleteCallForwardingsOp
} from '../../../../ducks/entities/callForwarding/callForwardingOperations';
import { asyncPool } from '../../../../helpers';
import type { OverwriteOverlappingT } from './forwardingFormUtils';
import type { CurrentUserT } from '../../../../ducks/currentUser/currentUserTypes';
import { createCsrfHeader } from '../../../../utils/accessRightUtils';

export type PropsT = {|
  action: ForwardingActionT,
  enterpriseId: ?string
|};

const CREATE_USER_FORWARDING_POOL_SIZE = 4;

type Error412T = {
  userId: string,
  error: *
};

export type SelectedUserT = {
  id?: string,
  userId: string,
  username: string,
  forwardingId?: string
};

export const parseFailedCallForwardingId = (failure412: Error412T) => {
  let matches = R.pathOr('', ['error', 'response', 'data', 'message'], failure412).match(
    /[{]\bForwarding-\b([0-9]*)/
  );
  if (matches && matches.length > 0) {
    return matches[matches.length - 1];
  }

  matches = R.pathOr('', ['error', 'response', 'data', 'detail'], failure412).match(
    /[{]\bForwarding-\b([0-9]*)/
  );
  if (matches && matches.length > 0) {
    return matches[matches.length - 1];
  }

  return '';
};

export const parseErrors = (results: ?mixed) => {
  // $FlowFixMe: compose missing in types
  const failures = R.compose(
    R.map(res => ({ userId: R.prop('userId', res), error: R.prop('error', res) })),
    R.filter(res => !R.isNil(res.error))
  )(results);
  // $FlowFixMe: compose missing in types
  const failures412 = R.compose(
    R.filter(res => res.error.response.status === HTTP.PRECONDITION_FAILED)
  )(failures);

  return { failures, failures412 };
};

const useForwardingActions = (props: PropsT) => {
  const { action, enterpriseId } = props;
  const { t } = useTranslation();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const dispatch = useDispatch();

  const editNotificationMessage = (success: boolean, preconditionFailed: boolean) => {
    if (success) {
      return t('forwardings.editNotification.successNotification');
    }
    if (preconditionFailed) {
      return t('forwardings.editNotification.fail412Notification');
    }
    return t('forwardings.editNotification.failNotification');
  };

  const deleteNotificationMessage = (success: boolean, multi: boolean) => {
    if (success) {
      if (multi) {
        return t('forwardings.deleteNotification.successNotificationMulti');
      }
      return t('forwardings.deleteNotification.successNotificationSingle');
    }
    if (multi) {
      return t('forwardings.deleteNotification.failNotificationMulti');
    }
    return t('forwardings.deleteNotification.failNotificationSingle');
  };

  const getFailedUsernames = (failedUserIds: string[], selectedUsers: SelectedUserT[]) => {
    return selectedUsers
      .filter(user => failedUserIds.includes(user.userId))
      .map(user => user.username)
      .join();
  };

  const createNotificationMessage = (
    success: boolean,
    failures412?: ?(Error412T[]),
    selectedUsers?: ?(SelectedUserT[])
  ) => {
    if (success) {
      if (selectedUsers && selectedUsers.length > 1) {
        return t('forwardings.createNotification.successNotificationMulti');
      }

      return t('forwardings.createNotification.successNotification');
    }
    if (failures412 && failures412.length > 0) {
      if (selectedUsers && selectedUsers.length > 1) {
        const failedUsernames = getFailedUsernames(
          R.map(R.prop('userId'), failures412),
          selectedUsers
        );
        return t('forwardings.createNotification.fail412NotificationWithUsernames', {
          usernames: failedUsernames
        });
      }
      return t('forwardings.createNotification.fail412Notification');
    }
    return t('forwardings.createNotification.failNotification');
  };

  const activationNotificationMessage = (
    success: boolean,
    preconditionFailed: boolean,
    activeState: string
  ) => {
    if (success) {
      return t(`forwardings.activationNotification.${activeState}.successNotification`);
    }
    if (preconditionFailed) {
      return t(`forwardings.activationNotification.${activeState}.fail412Notification`);
    }
    return t(`forwardings.activationNotification.${activeState}.failNotification`);
  };

  const getNotificationTag = (success: boolean, isMultipleUsers: boolean) => {
    if (success) {
      return 'callforwarding-success';
    }
    return (isMultipleUsers && action === 'create') || action !== 'create'
      ? 'callforwarding-fail'
      : 'callforwarding-fail-single-user';
  };

  const handleCallForwardingNotification = (
    success: boolean,
    notificationType: 'state' | 'create' | 'modify' | 'delete',
    failures412: ?(Error412T[]),
    activeState: ?string,
    selectedUsers?: ?(SelectedUserT[])
  ) => {
    let message = '';
    if (notificationType === 'state' && activeState) {
      message = activationNotificationMessage(success, !R.isNil(failures412), activeState);
    } else if (notificationType === 'modify') {
      message = editNotificationMessage(success, !R.isNil(failures412));
    } else if (notificationType === 'create') {
      message = createNotificationMessage(success, failures412, selectedUsers);
    } else if (notificationType === 'delete' && selectedUsers) {
      message = deleteNotificationMessage(success, selectedUsers && selectedUsers.length > 1);
    }

    const notification = {
      // $FlowFixMe: null check fails
      tag: getNotificationTag(success, selectedUsers && selectedUsers.length > 1),
      duration: success ? 15000 : 1000000,
      type: success ? 'info' : 'error',
      message
    };

    dispatch(notificationActions.createCreateNotificationAction(notification));
  };

  const getActivationState = (isActive: ?boolean) => {
    return isActive ? 'active' : 'inactive';
  };

  const updateCallForwarding = async (newForwarding: UpdateCallForwardingPayloadT) => {
    const returnValue = await dispatch(
      // $FlowFixMe: null check fails
      updateCallForwardingOp(enterpriseId, newForwarding, createCsrfHeader(currentUser))
    );
    handleCallForwardingNotification(
      !returnValue.error,
      'modify',
      returnValue.error && returnValue.error.response.status === HTTP.PRECONDITION_FAILED
    );
  };

  const updateCallForwardings = async (
    usersForwardingsToModify: SelectedUserT[],
    newForwarding: UpdateCallForwardingPayloadT
  ) => {
    const forwardingIds = usersForwardingsToModify
      .filter(usr => usr.forwardingId)
      .map(usr => usr.forwardingId);
    const results = await dispatch(
      updateCallForwardingsOp(
        // $FlowFixMe: null check fails
        enterpriseId,
        // $FlowFixMe: null check fails
        forwardingIds,
        newForwarding,
        createCsrfHeader(currentUser)
      )
    );
    const { failures, failures412 } = parseErrors(results);
    handleCallForwardingNotification(
      failures.length === 0,
      'modify',
      failures412,
      null,
      usersForwardingsToModify
    );
  };

  const deleteCallForwardings = async (usersForwardingsToRemove: SelectedUserT[]) => {
    const forwardingIds = usersForwardingsToRemove
      .filter(usr => usr.forwardingId)
      .map(usr => usr.forwardingId);
    const results = await dispatch(
      // $FlowFixMe: null check fails
      deleteCallForwardingsOp(enterpriseId, forwardingIds, createCsrfHeader(currentUser))
    );
    const { failures, failures412 } = parseErrors(results);
    handleCallForwardingNotification(
      failures.length === 0,
      'delete',
      failures412,
      null,
      usersForwardingsToRemove
    );
  };

  const deleteCallForwarding = async (forwardingId: string) => {
    const returnValue = await dispatch(
      // $FlowFixMe: null check fails
      deleteCallForwardingOp(enterpriseId, forwardingId, createCsrfHeader(currentUser))
    );
    handleCallForwardingNotification(
      !returnValue.error,
      'delete',
      returnValue.error && returnValue.error.response.status === HTTP.PRECONDITION_FAILED
    );
  };

  const updateCallForwardingActiveState = async (
    newForwarding: UpdateCallForwardingPayloadT,
    hideNotification?: boolean
  ) => {
    const returnValue = await dispatch(
      // $FlowFixMe: null check fails
      updateCallForwardingOp(enterpriseId, newForwarding, createCsrfHeader(currentUser))
    );
    if (!hideNotification) {
      handleCallForwardingNotification(
        !returnValue.error,
        'state',
        returnValue.error && returnValue.error.response.status === HTTP.PRECONDITION_FAILED,
        getActivationState(newForwarding.isActive)
      );
    }
  };

  const createEnterpriseForwarding = async (newForwarding: CreateCallForwardingPayloadT) => {
    // Enterprise call forwarding
    const returnValue = await dispatch(
      // $FlowFixMe: null check fails
      createCallForwarding(enterpriseId, newForwarding, null, null, createCsrfHeader(currentUser))
    );
    handleCallForwardingNotification(
      !returnValue.error,
      'create',
      returnValue.error && returnValue.error.response.status === HTTP.PRECONDITION_FAILED
    );
    return R.isNil(returnValue.error);
  };

  const createCallForwardingForUsers = async (
    newForwarding: CreateCallForwardingPayloadT,
    userIds: string[]
  ) => {
    const createNewCallForwarding = (id, cf) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async resolve => {
        const data = await dispatch(
          // $FlowFixMe: null check fails
          createCallForwarding(enterpriseId, cf, id, null, createCsrfHeader(currentUser))
        );
        if (data) {
          resolve(data);
          return;
        }
        resolve();
      });
    return asyncPool(
      CREATE_USER_FORWARDING_POOL_SIZE,
      userIds.map(id => [id, newForwarding]),
      R.apply(createNewCallForwarding)
    );
  };

  const process412Errors = async (
    newForwarding: CreateCallForwardingPayloadT,
    failures412: Error412T[]
  ) => {
    failures412.forEach(async failure => {
      await updateCallForwardingActiveState(
        {
          id: parseFailedCallForwardingId(failure),
          isActive: false
        },
        true
      );
    });
    const results = await createCallForwardingForUsers(
      newForwarding,
      R.map(R.prop('userId'), failures412)
    );
    return parseErrors(results);
  };

  const createUserForwarding = async (
    newForwarding: CreateCallForwardingPayloadT,
    selectedUsers: SelectedUserT[],
    overwriteOverlapping: OverwriteOverlappingT
  ) => {
    const results = await createCallForwardingForUsers(
      newForwarding,
      selectedUsers.map(user => user.userId)
    );

    let { failures, failures412 } = parseErrors(results);

    if (failures412.length !== 0 && overwriteOverlapping === 'disableOld') {
      const process412Results = await process412Errors(newForwarding, failures412);
      failures = process412Results.failures;
      failures412 = process412Results.failures412;
    }

    const success = failures.length === 0;
    handleCallForwardingNotification(success, 'create', failures412, null, selectedUsers);
    return success;
  };

  return {
    updateCallForwardings,
    updateCallForwardingActiveState,
    updateCallForwarding,
    deleteCallForwarding,
    createEnterpriseForwarding,
    createUserForwarding,
    deleteCallForwardings
  };
};

export default useForwardingActions;
