// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallDistributionSelection from '../../../callFlowGrid/details/extensionGroup/CallDistributionSelection';
import styles from './CallDistributionSelector.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  // eslint-disable-next-line flowtype/no-weak-types
  memberCountFn: any => number,
  unisonMaxMembers: number,
  overflowGroupEnabled?: boolean,
  isACD?: boolean,
  periodicStatsResetMoments?: string
|};

export const CallDistributionSelector = (props: PropsT): Element<'div'> => {
  const {
    field,
    title,
    description,
    memberCountFn,
    unisonMaxMembers,
    overflowGroupEnabled,
    isACD,
    periodicStatsResetMoments
  } = props;

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const { t } = useTranslation();

  return (
    <div className={styles['field-section']}>
      <div>
        <div className={styles.title}>{title || t('callflows.callDistributionSelector.title')}</div>
        <div className={styles.description}>
          {description || t('callflows.callDistributionSelector.description')}
        </div>
        <div className={styles.input}>
          <CallDistributionSelection
            ringPattern={values[field]}
            updateCallDistributionTarget={ringPattern =>
              setValue(field, ringPattern, {
                shouldValidate: false,
                shouldDirty: true
              })
            }
            errorMessage=""
            numberOfMembers={memberCountFn(values)}
            unisonMaxMembers={unisonMaxMembers}
            overflowGroupEnabled={overflowGroupEnabled}
            isACD={isACD}
            periodicStatsResetMoments={periodicStatsResetMoments}
          />
        </div>
      </div>
    </div>
  );
};

export default CallDistributionSelector;
