import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPukRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 3v5.75a.75.75 0 0 1-1.5 0V3a.75.75 0 0 0-.75-.75H3a.75.75 0 0 0-.75.75v7c0 .414.336.75.75.75h9.5a.75.75 0 0 1 0 1.5H3A2.25 2.25 0 0 1 .75 10V3A2.25 2.25 0 0 1 3 .75h18c1.24 0 2.25 1.009 2.25 2.25M5.994 5.25a1.24 1.24 0 0 0-.88.368A1.252 1.252 0 0 0 6 7.75c.689 0 1.25-.561 1.25-1.25S6.689 5.25 6 5.25zm6 0a1.24 1.24 0 0 0-.88.368A1.252 1.252 0 0 0 12 7.75c.689 0 1.25-.561 1.25-1.25S12.689 5.25 12 5.25zM19.25 6.5c0-.689-.561-1.25-1.25-1.25h-.007A1.25 1.25 0 1 0 19.25 6.5m4 10V21c0 1.24-1.01 2.25-2.25 2.25h-6c-1.24 0-2.25-1.01-2.25-2.25v-4.5c0-.976.629-1.801 1.5-2.112V13.5c0-2.068 1.683-3.75 3.75-3.75s3.75 1.682 3.75 3.75v.888a2.25 2.25 0 0 1 1.5 2.112m-7.5-2.25h4.5v-.75c0-1.241-1.01-2.25-2.25-2.25s-2.25 1.009-2.25 2.25zm6 2.25a.75.75 0 0 0-.75-.75h-6a.75.75 0 0 0-.75.75V21c0 .413.337.75.75.75h6c.413 0 .75-.337.75-.75z"/></svg>)}
    />
  );
}
  