// @flow

import React, { useEffect, useState } from 'react';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import { useTranslation } from 'react-i18next';
import Button from '@design-system/component-library/src/components/Button';
// $FlowFixMe
import writeXlsxFile from 'write-excel-file';
import moment from 'moment';

let requestAdminsCancelTokenSource: CancelTokenSource;

export const AdminExport = () => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState();

  useEffect(() => {
    requestAdminsCancelTokenSource = CancelToken.source();
    return () => {
      requestAdminsCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const exportAdmins = async () => {
    setProcessing(true);
    try {
      const adminsReturned: AxiosPromise<> = await axios({
        method: 'GET',
        url: `/api/v1/admin/export`,
        cancelToken: new CancelToken(canceler => {
          requestAdminsCancelTokenSource.current = canceler;
        })
      });
      if (adminsReturned.data) {
        const HEADER_ROW = [
          {
            value: 'Login',
            fontWeight: 'bold'
          },
          {
            value: 'Language',
            fontWeight: 'bold'
          },
          {
            value: '1st name',
            fontWeight: 'bold'
          },
          {
            value: 'Family name',
            fontWeight: 'bold'
          },
          {
            value: 'Email',
            fontWeight: 'bold'
          },
          {
            value: 'Phone number(s)',
            fontWeight: 'bold'
          },
          {
            value: 'Administrative domain',
            fontWeight: 'bold'
          },
          {
            value: 'Administrator type',
            fontWeight: 'bold'
          }
        ];
        const rows = adminsReturned.data.map(admin => [
          {
            type: String,
            value: admin.login
          },
          {
            type: String,
            value: admin.appLanguage
          },
          {
            type: String,
            value: admin.firstName
          },
          {
            type: String,
            value: admin.lastName
          },
          {
            type: String,
            value: admin.email
          },
          {
            type: String,
            value: admin.phoneNumber
          },
          {
            type: String,
            value: admin.ownerAdmtiveDomain
          },
          {
            type: String,
            value: admin.type
          }
        ]);
        const data = [HEADER_ROW, ...rows];
        await writeXlsxFile(data, {
          fileName: `admins_export_${moment().format('YYYYMMDD')}.xlsx`
        });
      }
    } catch (error) {
      console.log(error);
      setProcessing(false);
    }
    setProcessing(false);
  };

  return (
    <div>
      <Button loading={processing} onClick={() => exportAdmins()}>
        {t('adminui.exportAdminButton')}
      </Button>
    </div>
  );
};

export default AdminExport;
