import React from 'react';
import IconContainer from '../IconContainer';

export default function IconKeyRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.189 23.25a5.92 5.92 0 0 1-4.211-1.745c-2.068-2.085-2.31-5.439-.564-7.803a5.95 5.95 0 0 1 7.077-1.941L18.757 1.409a2.2 2.2 0 0 1 1.58-.659h.004a2.2 2.2 0 0 1 1.581.663c.868.876.866 2.3-.005 3.174l-.522.525 1.727 1.742a2.27 2.27 0 0 1-.02 3.154 2.233 2.233 0 0 1-3.135.027L18.24 8.293l-1.041 1.05 1.727 1.741a2.26 2.26 0 0 1 .578 2.168 2.25 2.25 0 0 1-1.577 1.591 2.24 2.24 0 0 1-2.163-.582l-1.716-1.736-2.398 2.415c1.049 2.501.278 5.483-1.916 7.132a5.9 5.9 0 0 1-3.545 1.178m.008-10.454a4.45 4.45 0 0 0-3.575 1.797c-1.311 1.774-1.13 4.292.422 5.855a4.43 4.43 0 0 0 5.788.423c1.758-1.322 2.296-3.786 1.253-5.732a.75.75 0 0 1 .129-.883l3.303-3.327a.75.75 0 0 1 .533-.222h.001c.2 0 .392.081.533.223l2.248 2.274a.727.727 0 0 0 1.224-.337.76.76 0 0 0-.193-.723l-.038-.041-2.215-2.231a.75.75 0 0 1 0-1.057l2.098-2.116a.77.77 0 0 1 1.065 0l2.252 2.271a.724.724 0 0 0 1.012-.019.757.757 0 0 0 .01-1.05l-2.242-2.262a.75.75 0 0 1 .001-1.057l1.048-1.053a.754.754 0 0 0 .002-1.06.72.72 0 0 0-.516-.218h-.001a.73.73 0 0 0-.516.215L9.181 13.198a.75.75 0 0 1-.892.13 4.4 4.4 0 0 0-2.092-.532m-.007 7.088c-1.436 0-2.604-1.175-2.604-2.62s1.168-2.62 2.604-2.62 2.605 1.175 2.605 2.62-1.169 2.62-2.605 2.62m0-3.74c-.608 0-1.103.502-1.103 1.12 0 .617.495 1.12 1.103 1.12.609 0 1.104-.502 1.104-1.12a1.115 1.115 0 0 0-1.104-1.12"/></svg>)}
    />
  );
}
  