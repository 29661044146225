// @flow strict-local

import axios, { type AxiosPromise, type CancelToken } from 'axios';
import {
  createRetrieveCalendarRequest,
  createRetrieveCalendarSuccess,
  createRetrieveCalendarFailure,
  createRetrieveCalendarCancel,
  createRetrieveCalendarsRequest,
  createRetrieveCalendarsSuccess,
  createRetrieveCalendarsCancel,
  createRetrieveCalendarsFailure,
  createCreateCalendarRequest,
  createCreateCalendarCancel,
  createCreateCalendarFailure,
  createCreateCalendarSuccess,
  createUpdateCalendarCancel,
  createUpdateCalendarFailure,
  createUpdateCalendarSuccess,
  createUpdateCalendarRequest,
  createDeleteCalendarRequest,
  createDeleteCalendarSuccess,
  createDeleteCalendarCancel,
  createDeleteCalendarFailure
} from './calendarActions';
import { type ThunkActionT } from '../../../commonTypes';
import type {
  RetrieveCalendarActionT,
  BaseCalendarEntityT,
  RetrieveCalendarsActionT,
  CreateCalendarActionT,
  UpdateCalendarActionT,
  CalendarCreateEntityT,
  CalendarUpdateEntityT,
  DeleteCalendarActionT,
  CalendarSlotUpdateEntityT,
  CalendarSlotCreateEntityT
} from './calendarTypes';

type RetrieveFnT = (string, string, CancelToken) => ThunkActionT<RetrieveCalendarActionT>;

// eslint-disable-next-line arrow-body-style
export const retrieve: RetrieveFnT = (enterpriseId, calendarId, cancelToken) => async dispatch => {
  dispatch(createRetrieveCalendarRequest(enterpriseId, calendarId));
  try {
    const response: AxiosPromise<BaseCalendarEntityT[]> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/calendars/${calendarId}`,
      cancelToken
    });
    // $FlowFixMe
    const { data } = await response;

    dispatch(createRetrieveCalendarSuccess(enterpriseId, calendarId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveCalendarCancel());
    } else {
      dispatch(createRetrieveCalendarFailure(enterpriseId, calendarId, error));
    }
    return undefined;
  }
};

type RetrieveCalendarsFnT = (
  string,
  ?string,
  CancelToken
) => ThunkActionT<RetrieveCalendarsActionT>;

// eslint-disable-next-line arrow-body-style
export const retrieveCalendars: RetrieveCalendarsFnT = (
  enterpriseId,
  calendarName,
  cancelToken
) => async dispatch => {
  dispatch(createRetrieveCalendarsRequest(enterpriseId));
  try {
    const response: AxiosPromise<BaseCalendarEntityT[]> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/calendars`,
      cancelToken,
      params: { calendarName }
    });
    // $FlowFixMe
    const { data } = await response;

    dispatch(createRetrieveCalendarsSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createRetrieveCalendarsCancel());
    } else {
      dispatch(createRetrieveCalendarsFailure(enterpriseId, error));
    }
    return undefined;
  }
};

export type CreateT = (
  string,
  CalendarCreateEntityT,
  CancelToken,
  headers: {}
) => ThunkActionT<CreateCalendarActionT>;

export const create: CreateT = (
  enterpriseId,
  calendarData,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createCreateCalendarRequest(enterpriseId));
  try {
    const response: AxiosPromise<BaseCalendarEntityT> = axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/calendars`,
      cancelToken,
      data: calendarData,
      headers
    });

    const { data } = await response;
    dispatch(createCreateCalendarSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createCreateCalendarCancel(enterpriseId));
    } else {
      dispatch(createCreateCalendarFailure(enterpriseId, error));
    }
    return undefined;
  }
};

export type UpdateT = (
  string,
  string,
  CalendarUpdateEntityT,
  CancelToken,
  headers: {}
) => ThunkActionT<UpdateCalendarActionT>;

export const update: UpdateT = (
  enterpriseId,
  calendarId,
  calendarData,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createUpdateCalendarRequest(enterpriseId, calendarId));
  try {
    const response: AxiosPromise<BaseCalendarEntityT> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/calendars/${calendarId}`,
      cancelToken,
      data: calendarData,
      headers
    });
    const { data } = await response;
    dispatch(createUpdateCalendarSuccess(enterpriseId, calendarId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateCalendarCancel(enterpriseId, calendarId));
    } else {
      dispatch(createUpdateCalendarFailure(enterpriseId, calendarId, error));
    }
    return undefined;
  }
};

export type DeleteT = (
  string,
  string,
  CancelToken,
  headers: {}
) => ThunkActionT<DeleteCalendarActionT, boolean>;

export const remove: DeleteT = (
  enterpriseId,
  calendarId,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createDeleteCalendarRequest(enterpriseId, calendarId));
  try {
    const response: AxiosPromise<BaseCalendarEntityT> = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/calendars/${calendarId}`,
      cancelToken,
      headers
    });
    await response;
    dispatch(createDeleteCalendarSuccess(enterpriseId, calendarId));
    return true;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createDeleteCalendarCancel(enterpriseId, calendarId));
    } else {
      dispatch(createDeleteCalendarFailure(enterpriseId, calendarId, error));
    }
    return false;
  }
};

export type DeleteCalendarSlotT = (
  string,
  string,
  string,
  CancelToken,
  headers: {}
) => AxiosPromise<boolean>;

export const removeCalendarSlot: DeleteCalendarSlotT = (
  enterpriseId,
  calendarId,
  calendarSlotId,
  cancelToken,
  headers
) => async () => {
  try {
    const response = axios({
      method: 'DELETE',
      url: `/api/v1/enterprises/${enterpriseId}/calendars/${calendarId}/calendarslots/${calendarSlotId}`,
      cancelToken,
      headers
    });
    await response;
    return true;
  } catch (error) {
    return false;
  }
};

export type UpdateCalendarSlotT = (
  string,
  string,
  string,
  CalendarSlotUpdateEntityT,
  CancelToken,
  headers: {}
) => AxiosPromise<boolean>;

export const updateCalendarSlot: UpdateCalendarSlotT = (
  enterpriseId,
  calendarId,
  calendarSlotId,
  calendarSlotData,
  cancelToken,
  headers
) => async () => {
  try {
    const response: AxiosPromise<BaseCalendarEntityT> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/calendars/${calendarId}/calendarslots/${calendarSlotId}`,
      cancelToken,
      data: calendarSlotData,
      headers
    });
    return await response;
  } catch (error) {
    return error;
  }
};

export type CreateCalendarSlotT = (
  string,
  string,
  CalendarSlotCreateEntityT,
  CancelToken,
  headers: {}
) => AxiosPromise<boolean>;

export const createCalendarSlot: CreateCalendarSlotT = (
  enterpriseId,
  calendarId,
  calendarSlotData,
  cancelToken,
  headers
) => async () => {
  try {
    const response: AxiosPromise<BaseCalendarEntityT> = axios({
      method: 'POST',
      url: `/api/v1/enterprises/${enterpriseId}/calendars/${calendarId}/calendarslots`,
      cancelToken,
      data: calendarSlotData,
      headers
    });
    return await response;
  } catch (error) {
    return error;
  }
};

const calendarOperations = {
  retrieve,
  retrieveCalendars,
  create,
  update,
  remove,
  removeCalendarSlot,
  updateCalendarSlot,
  createCalendarSlot
};

export default calendarOperations;
