import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoSamsungRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.856 11.072s-1.502-.884-2.385-1.237c0 0-2.827-1.325-3.357-1.944 0 0-1.06-1.06-.442-2.209 0 0 .265-.795 1.414-.795 0 0 1.502.088 1.502 1.325V7.98h5.478V5.418S19.508 1.177 12.705 1h-1.237s-4.152.265-5.566 2.474v.088s-.618.618-.618 2.916V7.98s-.088 1.855.884 3.092c0 0 .53.795 1.855 1.679 0 0 2.739 1.414 4.329 2.297 0 0 1.679.972 1.414 2.385 0 0-.177 1.502-1.679 1.502 0 0-1.414-.088-1.414-1.59v-1.767H4.664v2.562s-.177 4.859 7.245 4.859c0 0 7.068.177 7.421-5.036v-2.032s.265-3.18-2.474-4.859"/></svg>)}
    />
  );
}
  