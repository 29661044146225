// @flow

import { type $AxiosError } from 'axios';

import type {
  SetDepartmentItemT,
  UnsetDepartmentItemT,
  ClearDepartmentItemsT,
  ImportDepartmentItemT,
  AnalyzeImportCancelT,
  AnalyzeImportFailureT,
  AnalyzeImportResetT,
  AnalyzeImportSelectSheetT,
  AnalyzeImportSetNameT,
  AnalyzeImportSuccessT,
  ImportAnalysisPayloadT,
  AnalyzeImportRequestT,
  AnalyzeImportStartedSuccessT,
  AnalyzeImportProcessRequestT,
  ImportUsersProcessT,
  AnalyzeImportProcessSuccessT,
  AnalyzeImportProcessFailureT,
  AnalyzeImportProcessCancelT
} from './userImportUiTypes';

export type CreateSetDepartmentItemFnT = ImportDepartmentItemT => SetDepartmentItemT;
export const createSetDepartmentItem: CreateSetDepartmentItemFnT = importDepartmentItem => ({
  type: 'ring/ui/userImport/SET_DEPARTMENT_ITEM',
  payload: { importDepartmentItem }
});

export type CreateUnsetDepartmentItemFnT = string => UnsetDepartmentItemT;
export const createUnsetDepartmentItem: CreateUnsetDepartmentItemFnT = path => ({
  type: 'ring/ui/userImport/UNSET_DEPARTMENT_ITEM',
  payload: { path }
});

export type CreateClearImportDepartmentsFnT = void => ClearDepartmentItemsT;
export const createClearImportDepartments: CreateClearImportDepartmentsFnT = () => ({
  type: 'ring/ui/userImport/CLEAR_DEPARTMENT_ITEMS',
  payload: {}
});

export const createAnalyzeImportRequest = (
  enterpriseId: string,
  meta: {} = {}
): AnalyzeImportRequestT => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});

export const createAnalyzeImportStartedSuccess = (
  enterpriseId: string,
  uuid: string,
  meta: {} = {}
): AnalyzeImportStartedSuccessT => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_STARTED_SUCCESS',
  error: false,
  meta: { ...meta, enterpriseId },
  payload: uuid
});

export const createAnalyzeImportProcessRequest = (
  enterpriseId: string,
  meta: {} = {}
): AnalyzeImportProcessRequestT => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});
export const createAnalyzeImportProcessSuccess = (
  enterpriseId: string,
  processed: ImportUsersProcessT,
  meta: {} = {}
): AnalyzeImportProcessSuccessT => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_SUCCESS',
  error: false,
  meta: { ...meta, enterpriseId },
  payload: processed
});
export const createAnalyzeImportProcessFailure = (
  enterpriseId: string,
  error: $AxiosError<*>
): AnalyzeImportProcessFailureT => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_FAILURE',
  error: true,
  meta: { enterpriseId },
  payload: error
});
export const createAnalyzeImportProcessCancel = (): AnalyzeImportProcessCancelT => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_PROCESS_CANCEL',
  error: false,
  meta: {},
  payload: {}
});

export type CreateAnalyzeImportSuccessFnT = ImportAnalysisPayloadT => AnalyzeImportSuccessT;
export const createAnalyzeImportSuccess: CreateAnalyzeImportSuccessFnT = data => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_SUCCESS',
  payload: data ?? [],
  error: false,
  meta: {}
});

export type CreateAnalyzeImportFailureFnT = (
  enterpriseId: string,
  error: $AxiosError<*>
) => AnalyzeImportFailureT;
export const createAnalyzeImportFailure: CreateAnalyzeImportFailureFnT = error => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_FAILURE',
  payload: error,
  error: true,
  meta: {}
});

export type CreateAnalyzeImportCancelFnT = void => AnalyzeImportCancelT;
export const createAnalyzeImportCancel: CreateAnalyzeImportCancelFnT = () => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_CANCEL',
  error: false,
  meta: {}
});

export type CreateAnalyzeImportSetNameFnT = string => AnalyzeImportSetNameT;
export const createAnalyzeImportSetName: CreateAnalyzeImportSetNameFnT = name => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_SET_NAME',
  payload: name
});

export type CreateAnalyzeImportResetFnT = void => AnalyzeImportResetT;
export const createAnalyzeImportReset: CreateAnalyzeImportResetFnT = () => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_RESET'
});

export type CreateAnalyzeImportSelectSheetFnT = (number, boolean) => AnalyzeImportSelectSheetT;
export const createAnalyzeImportSelectSheet: CreateAnalyzeImportSelectSheetFnT = (
  index,
  selected
) => ({
  type: 'ring/ui/userImport/ANALYZE_IMPORT_SELECT_SHEET',
  payload: { index, selected }
});
