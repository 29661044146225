// @flow

import classnames from 'classnames';
import React, { type Element, type Node } from 'react';
import { ReactComponent as HomeIcon } from '../assets/home.svg';
import styles from './Breadcrumb.module.scss';

export type SegmentT = { name: string, key: string };

export type PropsT = {
  segments: Array<SegmentT>, // eslint-disable-line react/no-unused-prop-types
  onSegmentSelect: number => *,
  divider: string,
  showHome?: boolean,
  children?: Node // eslint-disable-line react/no-unused-prop-types
};

const Breadcrumb = (props: PropsT): Element<'div'> => {
  // eslint-disable-next-line no-unused-vars
  const { segments, onSegmentSelect, divider, showHome } = props;
  return (
    <div className={classnames(styles.breadcrumb, 'breadcrumb-container')}>
      {segments.length > 0 && (
        <div>
          {showHome && (
            <span className={styles.segment}>
              <span
                className={`${styles.name}`}
                tabIndex={0}
                role="button"
                onClick={() => onSegmentSelect(-1)}
                onKeyPress={() => onSegmentSelect(-1)}
              >
                <HomeIcon />
              </span>
              <span className={styles.divider}>{divider}</span>
            </span>
          )}
          {segments.map((segment, index) => (
            <span className={styles.segment} key={segment.key}>
              {index > 0 && <span className={styles.divider}>{divider}</span>}
              <span
                className={segments.length - 1 === index ? styles.highlight : styles.name}
                tabIndex={0}
                role="button"
                onClick={() => onSegmentSelect(index)}
                onKeyPress={() => onSegmentSelect(index)}
                data-cy={`breadcrumb-${segment.key}`}
              >
                {segment.name}
              </span>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

Breadcrumb.defaultProps = {
  children: null,
  showHome: false,
  onSegmentSelect: () => {} // eslint-disable-line react/default-props-match-prop-types
};

export default Breadcrumb;
