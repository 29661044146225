// @flow

import React, { type Element, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import ContactSelector from '../ContactSelector/ContactSelector';
import type { InternalUserEntityT } from '../../ducks/entities/user/userTypes';
import useFormAutoRegister from '../../scenes/callFlows/callFlowGrid/details/useFormAutoRegister';
import ErrorBoundary from '../Error/ErrorBoundary';
import TextError from '../Error/TextError';
import UserLockedContent from './UserLockedContent';
import { isMobileNumber } from '../../helpers';
import styles from './UserLocked.module.scss';

export type PropsT = {|
  user: InternalUserEntityT,
  handleCloseView: () => void
|};
const getFormDefaults = (user: InternalUserEntityT) => {
  let firstMobileNumber;
  if (user.phoneNumbers) {
    firstMobileNumber = user.phoneNumbers.find(isMobileNumber);
  }
  return {
    phoneNumber: firstMobileNumber || '',
    email: user.emails || ''
  };
};

export const UserLocked = (props: PropsT): Element<'div'> => {
  const { user, handleCloseView } = props;
  const [resetMethod, setResetMethod] = useState('phoneNumber');

  const { t } = useTranslation();

  // form
  const defaultValues = useMemo(() => getFormDefaults(user), [user]);
  const { watch, setValue } = useFormAutoRegister(R.keys(defaultValues), {
    defaultValues
  });
  const values = watch();

  const handleSmsClick = () => {
    setResetMethod('phoneNumber');
    setValue('email', '');
  };

  const handleEmailClick = () => {
    setResetMethod('email');
    setValue('phoneNumber', '');
  };

  const isSmsSelected = resetMethod === 'phoneNumber';
  const isEmailSelected = resetMethod === 'email';

  useEffect(
    () => () => {
      const defaults = getFormDefaults(user);
      setValue('phoneNumber', defaults.phoneNumber);
    },
    [setValue, user]
  );

  return (
    <div className={styles['user-locked']}>
      <ErrorBoundary errorElement={<TextError message={t('userLocked.genericErrorMessage')} />}>
        <div className={styles['top-part']}>
          <LockIcon className={styles['locked-icon']} />
          <div className={styles.title}>{t('userLocked.title')}</div>
          <div className={styles.description}>{t('userLocked.description')}</div>
          <ContactSelector
            isSmsSelected={isSmsSelected}
            isEmailSelected={isEmailSelected}
            handleSmsClick={handleSmsClick}
            handleEmailClick={handleEmailClick}
            handleChangePhoneNumber={event => setValue('phoneNumber', event.target.value)}
            handleChangeEmail={event => setValue('email', event.target.value)}
            phoneNumber={values.phoneNumber}
            email={values.email}
          />
        </div>
        <UserLockedContent
          user={user}
          handleCloseView={handleCloseView}
          isSmsSelected={isSmsSelected}
          isEmailSelected={isEmailSelected}
          phoneNumber={values.phoneNumber}
          email={values.email}
        />
      </ErrorBoundary>
    </div>
  );
};

export default UserLocked;
