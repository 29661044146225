// @flow strict-local

import React, { type Element, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  type CallFlowEntityT,
  selectors as callFlowSelect
} from '../../../ducks/entities/callFlow';
import { type CallFlowTypeT } from '../../../ducks/entities/callFlow/callFlowTypes';
import GenericError from '../../../components/Error/GenericError';
import { pooledBatchRetrieve } from '../../../ducks/entities/callFlow/callFlowOperations';
import { createUpdateEditStatus } from '../../../ducks/ui/callflow/callflowUiActions';
import CallFlowElement from './services/CallFlowElement';

type PropsT = {|
  enterpriseId: string,
  callFlowId?: string,
  callFlowType?: CallFlowTypeT,
  selectedCategory?: string,
  searchTerm?: string,
  loadMore?: (page: number) => Promise<void>
|};

export const ExpandableCallFlowGrid = (
  props: PropsT
): Element<typeof InfiniteScroll | typeof CallFlowElement> | null => {
  // eslint-disable-next-line no-unused-vars
  const { enterpriseId, callFlowId, callFlowType, loadMore, selectedCategory, searchTerm } = props;
  const [pageNum, setPageNum] = useState(1);
  const dispatch = useDispatch();

  const PAGE_SIZE = 5;

  // redux
  const callflows: CallFlowEntityT[] = useSelector(state =>
    callFlowSelect.getCallFlowsByEnterpriseIdAndSearchFilter(
      state,
      enterpriseId,
      searchTerm,
      callFlowType
    )
  ).sort((a, b) => parseInt(a.addressNumber, 10) - parseInt(b.addressNumber, 10));

  const filteredCallFlows = selectedCategory
    ? (callflows || []).filter(cf => cf != null).filter(cf => selectedCategory === cf.type)
    : [];

  const getPagedCallFlows = () => {
    if (filteredCallFlows) {
      return filteredCallFlows.slice(0, Math.min(PAGE_SIZE * pageNum, filteredCallFlows.length));
    }
    return [];
  };
  const filteredAndPagedCallFlows = getPagedCallFlows();

  const errorElement = <GenericError message="error" />;

  if (callFlowId && callFlowType) {
    const data = callflows.filter(({ id, type }) => id === callFlowId && type === callFlowType)[0];
    return data ? (
      <CallFlowElement
        callFlowData={data}
        enterpriseId={enterpriseId}
        hideDescription
        single
        errorElement={errorElement}
      />
    ) : null;
  }

  const loadMissingData = async () => {
    if (filteredAndPagedCallFlows) {
      filteredAndPagedCallFlows.forEach(cf => createUpdateEditStatus(cf.id, false));
      await dispatch(
        pooledBatchRetrieve(
          enterpriseId,
          null,
          filteredAndPagedCallFlows
            .filter(e => {
              // $FlowFixMe
              const metadata = e.__metadata;
              const notReady = !metadata.hasFullData && !metadata.error;
              const alreadyLoading = metadata.isRetrieving || metadata.retrievingTriggered;
              if (notReady && !alreadyLoading) {
                metadata.retrievingTriggered = true;
                return true;
              }
              return false;
            })
            .map(cf => ({
              callFlowId: cf.id,
              callFlowType: cf.type
            })),
          PAGE_SIZE
        )
      );
    }
  };
  loadMissingData();

  return (
    <InfiniteScroll
      hasMore={filteredAndPagedCallFlows.length < filteredCallFlows.length}
      loadMore={setPageNum}
      initialLoad={false}
      pageStart={1}
    >
      {filteredAndPagedCallFlows.map(callflow => (
        <CallFlowElement
          key={`callflowelement_${callflow.id}`}
          callFlowData={callflow}
          enterpriseId={enterpriseId}
          single={false}
          showError={false}
          errorElement={errorElement}
        />
      ))}
    </InfiniteScroll>
  );
};

export default ExpandableCallFlowGrid;
