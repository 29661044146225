// @flow

import React, { type Element, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Feedback from '../components/Feedback';
import { selectors as headerSelectors } from '../ducks/ui/header';
import { isBrowserRunningE2eTests } from '../helpers';
import { getEntraLogin, fetchCsrf } from '../ducks/currentUser/currentUserOperations';
import IdleTimeOutHandler from '../IdleTimeOutHandler';
import Phonebook from '../scenes/Phonebook/Phonebook';
import PhonebookEntry from '../scenes/Phonebook/PhonebookEntry';
import styles from '../main.module.scss';

const PhonebookRoutes = (): Element<'div'> => {
  const dispatch = useDispatch();
  const modalOpen: boolean = useSelector(state => headerSelectors.modalIsOpen(state));
  const currentUser = useSelector(state => state.currentUser);
  const [userCheckDone, setUserCheckDone] = useState(isBrowserRunningE2eTests());

  const checkUser = async () => {
    await dispatch(getEntraLogin());
    await dispatch(fetchCsrf());
    setUserCheckDone(true);
  };

  if (!userCheckDone) {
    checkUser();
  }

  return userCheckDone ? (
    <div>
      <IdleTimeOutHandler />
      <div className={styles['main-content']} style={modalOpen ? { position: 'fixed' } : {}}>
        <div>
          <Switch>
            {((currentUser.featureFlags || []).includes('FEATURE-SHARED-PHONEBOOK-ENABLED') ||
              (currentUser.loginType || []).includes('ENTRA_ID_LOGIN') ||
              isBrowserRunningE2eTests()) && (
              <Route exact path="/phonebook" component={Phonebook} />
            )}
            {((currentUser.featureFlags || []).includes('FEATURE-SHARED-PHONEBOOK-ENABLED') ||
              (currentUser.loginType || []).includes('ENTRA_ID_LOGIN') ||
              isBrowserRunningE2eTests()) && (
              <Route exact path="/phonebook/:phonebookId" component={PhonebookEntry} />
            )}
            <Redirect to="/phonebook/login" />
          </Switch>
        </div>
      </div>
      <Feedback />
    </div>
  ) : (
    <div />
  );
};

export default PhonebookRoutes;
