import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNewMessageLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.193.785a2.83 2.83 0 0 0-3.911 0l-1.06 1.071-8.531 8.539a.47.47 0 0 0-.14.28l-.55 3.744a.48.48 0 0 0 .14.42c.09.097.217.152.35.15h.07l3.741-.541a.47.47 0 0 0 .28-.14l9.601-9.61A2.764 2.764 0 0 0 23.2.791zM13.002 13.479l-2.91.42.42-2.913 8.061-8.069 2.49 2.493zm9.481-9.501-.71.711-2.49-2.483.72-.711a1.79 1.79 0 0 1 2.49 0c.679.69.674 1.799-.01 2.483m-1.48 8.509v9.51a2 2 0 0 1-2 2.002H2a2 2 0 0 1-2-2.002V4.979c0-1.106.896-2.002 2-2.002h9.501a.5.5 0 0 1 0 1.002H2A1 1 0 0 0 1 4.98v17.019A1 1 0 0 0 2 23h17.002a1 1 0 0 0 1-1.001v-9.51a.501.501 0 0 1 1.001-.002"/></svg>)}
    />
  );
}
  