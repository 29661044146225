import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLockOpenLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9H12V6c0-3.3-2.7-6-6-6S0 2.7 0 6v3.5c0 .3.2.5.5.5s.5-.2.5-.5V6c0-2.8 2.2-5 5-5s5 2.2 5 5v3H8c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2m1 13c0 .6-.4 1-1 1H8c-.6 0-1-.4-1-1V11c0-.6.4-1 1-1h14c.6 0 1 .4 1 1zm-7.5-7v3c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-3c0-.3.2-.5.5-.5s.5.2.5.5"/></svg>)}
    />
  );
}
  