import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTabletRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 0H5a2.25 2.25 0 0 0-2.25 2.25v19.5A2.25 2.25 0 0 0 5 24h14a2.25 2.25 0 0 0 2.25-2.25V2.25A2.25 2.25 0 0 0 19 0m.75 21.75a.76.76 0 0 1-.75.75H5a.76.76 0 0 1-.75-.75V18h15.5zm0-5.25H4.25V2.25A.76.76 0 0 1 5 1.5h14a.76.76 0 0 1 .75.75zm-10 3.75a.76.76 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.76.76 0 0 1-.75-.75"/></svg>)}
    />
  );
}
  