// @flow strict-local

/* eslint-disable react/no-unused-prop-types */

import classNames from 'classnames';
import React, { type Element } from 'react';

import { type TableRowItemT } from './TableRow';
import ForwardingRowContents from './ForwardingRowContents';

import styles from './LargeTableRow.module.scss';

export type PropsT = {
  columns: string[],
  rowItem: TableRowItemT
};

const LargeTableRow = ({ columns, rowItem }: PropsT): Element<'div'> => (
  <div
    key={`${rowItem.rowId}-table-row`}
    className={classNames(styles.row, ...(rowItem.rowClasses || []))}
  >
    <div className={styles['user-container']}>
      <div className={styles['name-container']}>
        <div className={styles['user-details']}>
          {rowItem.name}
          <div className={styles['action-cell']}>{rowItem.action}</div>
        </div>
      </div>
      <div className={styles.description}>{rowItem.nameDescription}</div>
    </div>
    <ForwardingRowContents columns={columns} rowItem={rowItem} />
  </div>
);

export default LargeTableRow;
