import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoutLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15 6V3.2c0-.5-.4-1-1-1H4.2c-.5 0-1 .4-1 1v17.5c0 .5.4 1 1 1H14c.5 0 1-.4 1-1V18c0-.3.2-.5.5-.5s.5.2.5.5v2.8c0 1.1-.9 2-2 2H4.2c-1.1 0-2-.9-2-2V3.2c0-1.1.9-2 2-2H14c1.1 0 2 .9 2 2V6c0 .3-.2.5-.5.5S15 6.3 15 6m4.1 9.4 2.9-2.9H9.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H22l-2.9-2.9c-.2-.2-.2-.5 0-.7s.5-.2.7 0l3.8 3.8c.2.2.2.5 0 .7l-3.8 3.7c-.2.2-.5.2-.7 0-.1-.2-.1-.5 0-.7"/></svg>)}
    />
  );
}
  