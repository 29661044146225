// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import { selectors as selectDepartments } from '../../ducks/entities/department';
import Breadcrumb from '../../components/Breadcrumb';
import styles from './DepartmentSearchResult.module.scss';

export type PropsT = {
  path: string[],
  onSelect: () => *
};

export const DepartmentSearchResult = (props: PropsT): Element<'div'> | null => {
  const { onSelect, path } = props;
  const pathWithNames = useSelector(state => selectDepartments.pathWithNames(state, path));
  return pathWithNames && pathWithNames.every(segment => segment.name) ? (
    <div
      onClick={onSelect}
      onKeyPress={onSelect}
      role="button"
      tabIndex={0}
      className={styles.result}
    >
      <Breadcrumb
        {...props}
        divider="/"
        lines={2}
        segments={pathWithNames.map(s => ({ key: s.id, name: s.name || '' }))}
      />
    </div>
  ) : null;
};

export default DepartmentSearchResult;
