// @flow strict-local

import React, { Component, type Element, type Node } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { ExactPropsT } from '../../../../commonTypes';
import { ReactComponent as AcdIcon } from '../../../../assets/callflow/grid/acd.svg';
import { ReactComponent as PlayMusicIvrIcon } from '../../../../assets/callflow/grid/ivr.svg';
import { ReactComponent as WelcomeAttendantIcon } from '../../../../assets/callflow/grid/welcomeAttendant.svg';
import { ReactComponent as SpeedDialIcon } from '../../../../assets/callflow/grid/speedDial.svg';
import { ReactComponent as OcIcon } from '../../../../assets/callflow/grid/oc.svg';
import { ReactComponent as ExtensionGroupIcon } from '../../../../assets/callflow/grid/extgrp.svg';
import styles from '../CallFlowType.module.scss';

type StatePropsT = {||};

type OwnPropsT = {|
  type: string,
  textOnly?: boolean,
  number?: string
|};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT, WithTranslationProps>;

type TypeInfoT = {
  +description: string,
  +Icon: ?Node
};

export class CallFlowType extends Component<PropsT> {
  getTypeInfo(): TypeInfoT {
    const { type, t } = this.props;
    switch (type) {
      case 'PLAY_MUSIC':
        return {
          Icon: <PlayMusicIvrIcon className={styles.image} />,
          description: t('callflows.callflowType.playMusic')
        };
      case 'EXTENSION_GROUP':
        return {
          Icon: <ExtensionGroupIcon className={styles.image} />,
          description: t('callflows.callflowType.extensionGroup')
        };
      case 'ACD_CUSTOMER_SERVICE':
        return {
          Icon: <AcdIcon className={styles.image} />,
          description: t('callflows.callflowType.ACD_CUSTOMER_SERVICE')
        };
      case 'ACD_SWITCHBOARD':
        return {
          Icon: <AcdIcon className={styles.image} />,
          description: t('callflows.callflowType.ACD_SWITCHBOARD')
        };
      case 'OC':
        return {
          Icon: <OcIcon className={styles.image} />,
          description: t('callflows.callflowType.OC')
        };
      case 'SPEED_DIAL':
        return {
          Icon: <SpeedDialIcon className={styles.image} />,
          description: t('callflows.callflowType.speedDial')
        };
      case 'WELCOME_ATTENDANT':
        return {
          Icon: <WelcomeAttendantIcon className={styles.image} />,
          description: t('callflows.callflowType.welcomeAttendant')
        };
      default:
        return {
          description: '?',
          Icon: null
        };
    }
  }

  render(): Element<'div'> | string {
    const { textOnly, number } = this.props;
    const { description, Icon } = this.getTypeInfo();

    return textOnly ? (
      `${description}, ${number || ''}`
    ) : (
      <div className={styles.container}>
        {Icon}
        <div>{description}</div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps),
  withRouter
)(CallFlowType);
