import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileGeneralFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M15.3 7.2h5c.3 0 .5.2.5.5v14.5c0 1.1-.9 2-2 2h-14c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h8.4c.3 0 .5.2.5.5v5c0 1 .7 1.7 1.6 1.7m-8.5 6.3c-.4 0-.8.3-.8.8s.3.7.8.7h10.5c.4 0 .8-.3.8-.8s-.3-.8-.8-.8H6.8zM6 17.2c0-.4.3-.8.8-.8h10.5c.4 0 .8.3.8.8s-.4.8-.9.8H6.8c-.5 0-.8-.3-.8-.8m0 3c0-.4.3-.8.8-.8h10.5c.4 0 .8.3.8.8s-.4.8-.9.8H6.8c-.5 0-.8-.3-.8-.8M19.8 5.9c.1 0 .2-.1.2-.2s0-.2-.1-.3L15.3.7c-.1-.1-.2-.1-.3-.1v4.9c0 .2.2.4.4.4z" clipRule="evenodd"/></svg>)}
    />
  );
}
  