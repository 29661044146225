// @flow
import React from 'react';
import LinkButton from '../../../components/Button/LinkButton';
import styles from './PopUpMenuItem.module.scss';

type PropsT = {|
  id: string,
  label: string,
  onClickAction: () => *
|};

const PopUpMenuItem = (props: PropsT) => {
  const { id, label, onClickAction } = props;

  return (
    <div className={styles['menu-item']}>
      <LinkButton
        id={id}
        className={styles['menu-item-link-button']}
        label={label}
        onClickAction={onClickAction}
      />
    </div>
  );
};

export default PopUpMenuItem;
