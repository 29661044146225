// @flow strict-local

import * as R from 'ramda';
import type { ConfigItemT } from './ducks/config/configTypes';
import type {
  ExternalUserStateEntityT,
  InternalUserStateEntityT,
  UserStateEntityT,
  UserTypeT
} from './ducks/entities/user/userTypes';
import type { FieldToShowT } from './ducks/ui/user';

export function getConfigurationValue<T: $PropertyType<ConfigItemT, 'key'>>(
  configs: ConfigItemT[],
  keyValue: T
): ?$PropertyType<ConfigItemT, 'value'> {
  const entity =
    configs && Array.isArray(configs) && configs.filter(config => config.key === keyValue);
  return entity && entity.length > 0 ? entity[0].value : null;
}

const USER_CONFIG_KEYPAIR_SEPARATOR = ';';
const USER_CONFIG_KEY_VALUE_SEPARATOR = '=';
const USER_CONFIG_EMPTY_VALUE = '@';
const sanitizer = new RegExp(
  `[${USER_CONFIG_KEYPAIR_SEPARATOR}${USER_CONFIG_KEY_VALUE_SEPARATOR}${USER_CONFIG_EMPTY_VALUE}]`,
  'gim'
);

export const serializeIntroductionConfigValue = (configValue: string[]) =>
  JSON.stringify(configValue);

export const deserializeIntroductionConfigValue = (foundConfig: { value: string }) => {
  if (foundConfig) {
    return JSON.parse(foundConfig.value);
  }
  return null;
};

export function sanitizeUserConfigValue(str: ?string) {
  return str ? str.replace(sanitizer, '') : USER_CONFIG_EMPTY_VALUE;
}

export const serializeColumnsToShowConfigValue = (updatedColumnsToShow: FieldToShowT) =>
  JSON.stringify(updatedColumnsToShow || {});

export const deserializeColumnsToShowConfigValue = (configValue: string) => JSON.parse(configValue);

export function serializeUserConfigValue(
  user: InternalUserStateEntityT | ExternalUserStateEntityT
): string {
  return [
    `id${USER_CONFIG_KEY_VALUE_SEPARATOR}${sanitizeUserConfigValue(user.id)}`,
    `personId${USER_CONFIG_KEY_VALUE_SEPARATOR}${sanitizeUserConfigValue(user.personId)}`,
    `enterpriseId${USER_CONFIG_KEY_VALUE_SEPARATOR}${sanitizeUserConfigValue(user.enterpriseId)}`,
    `userType${USER_CONFIG_KEY_VALUE_SEPARATOR}${sanitizeUserConfigValue(user.userType)}`,
    `firstName${USER_CONFIG_KEY_VALUE_SEPARATOR}${sanitizeUserConfigValue(user.firstName)}`,
    `lastName${USER_CONFIG_KEY_VALUE_SEPARATOR}${sanitizeUserConfigValue(user.lastName)}`,
    `urlPhoto${USER_CONFIG_KEY_VALUE_SEPARATOR}${sanitizeUserConfigValue(user.urlPhoto)}`,
    `subtitle${USER_CONFIG_KEY_VALUE_SEPARATOR}${
      user.userType === 'internalUser'
        ? sanitizeUserConfigValue(user.title)
        : sanitizeUserConfigValue(user.company)
    }`,
    `addedAt${USER_CONFIG_KEY_VALUE_SEPARATOR}${Date.now()}`
  ].join(USER_CONFIG_KEYPAIR_SEPARATOR);
}

export const toggleFavoriteUserConfig = (
  userConfig: ConfigItemT[],
  user: UserStateEntityT,
  selected: boolean
): ConfigItemT[] => {
  const type = user.userType === 'internalUser' ? 'internal' : 'external';

  return [
    ...R.reject(
      item => item && item.key === `favouriteUser_${user.enterpriseId}_${type}_${user.id}`,
      userConfig
    ),
    ...(selected
      ? [
          {
            key: `favouriteUser_${user.enterpriseId}_${type}_${user.id}`,
            value: serializeUserConfigValue(user)
          }
        ]
      : [])
  ];
};

export const removeUserConfig = (
  userConfig: ConfigItemT[],
  enterpriseId: string,
  userId: string
): ConfigItemT[] => {
  const shouldRemove = (key: string): boolean => {
    const keyItems = key.split('_', 4);
    if (keyItems && keyItems.length === 4) {
      return keyItems[1] === enterpriseId && keyItems[3] === userId;
    }
    return false;
  };

  return [...R.reject(item => item && shouldRemove(item.key), userConfig)];
};

export const deserializeUserConfigValue = (
  str: string
): {
  id: string,
  personId: string,
  enterpriseId: string,
  userType: UserTypeT,
  firstName: string,
  lastName: string,
  subtitle: string,
  addedAt: number,
  urlPhoto: string
} =>
  // $FlowFixMe
  R.compose(
    // eslint-disable-line
    R.reduce((acc, value) => {
      const keyValue = value.split(USER_CONFIG_KEY_VALUE_SEPARATOR);
      return {
        ...acc,
        [keyValue[0]]: keyValue[1].includes(USER_CONFIG_EMPTY_VALUE) ? '' : keyValue[1]
      };
    }, {}),
    R.split(USER_CONFIG_KEYPAIR_SEPARATOR)
  )(str);

export function sortUsersByProp(
  propKey: string,
  users: Array<InternalUserStateEntityT | ExternalUserStateEntityT>
): UserStateEntityT[] {
  return users.length > 0
    ? R.sortBy(
        // $FlowFixMe: compose missing in types
        R.compose(R.toLower, R.propOr('', propKey))
      )(users)
    : [];
}
