// @flow

import * as R from 'ramda';
import React, { type Element, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import type {
  WelcomeAttendantEntityT,
  StartEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import { updateWelcomeAttendant } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantOperations';
import EditCallflowDetails from '../../../../components/edit/EditCallflowDetails';
import {
  getInitialNextStep,
  getNewStepData,
  getWelcomeAttendantStepOptions
} from '../WelcomeAttendantUtils';
import { ReactComponent as StartIcon } from '../../../../../../assets/callflow/details/start.svg';
import WelcomeAttendantStepField from '../../../../components/edit/children/welcomeAttendantStepField/WelcomeAttendantStepField';
import type { NextStepT } from '../WelcomeAttendantUtils';
import { getCommandNames, validateStepName } from '../../../../../../utils/validationUtils';
import type { WelcomeAttendantUpdateEntityT } from '../../../../../../ducks/entities/callFlow/callFlowTypes';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';

type PropsT = {|
  callflowId: string,
  commandId: string
|};

export type FormT = {
  stepName: string,
  nextStep: ?NextStepT
};

let requestCancelTokenSource: CancelTokenSource;

export const buildWelcomeAttendantStartNodeUpdatePayload = (
  welcomeAttendantData: WelcomeAttendantEntityT,
  formData: FormT,
  commandName: string
) => {
  const targetStepName =
    R.path(['nextStep', 'newStepName'], formData) ||
    R.path(['nextStep', 'stepName'], formData) ||
    R.path(['nextStep', 'name'], formData) ||
    null;
  return {
    commands: {
      ...welcomeAttendantData.commands,
      ...getNewStepData(formData.nextStep),
      [commandName]: {
        ...welcomeAttendantData.commands[commandName],
        nextState: {
          state: targetStepName ? targetStepName.toUpperCase() : null,
          endState: false
        }
      }
    }
  };
};

const EditStartDetails = (props: PropsT): Element<typeof EditCallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const welcomeAttendantData: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { enterpriseId } = welcomeAttendantData;
  const startNode: ?StartEntityT = R.path(['commands', commandId], welcomeAttendantData);
  if (!startNode) {
    return null;
  }

  const stepOptions: NextStepT[] = getWelcomeAttendantStepOptions(welcomeAttendantData);
  const initialFormValues: FormT = {
    stepName: startNode.name,
    nextStep: getInitialNextStep(stepOptions, startNode.nextState ? startNode.nextState.state : '')
  };

  const waStartSchema = yup.object().shape({
    nextStep: validateStepName(
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid'),
      t('callflows.welcomeAttendantGeneric.stepNameValidationErrorUnique'),
      getCommandNames(welcomeAttendantData.commands, startNode.name),
      [],
      ['newStepName']
    )
  });

  // update
  const onSubmit = async (formData: FormT): Promise<WelcomeAttendantEntityT> => {
    const patchPayload: WelcomeAttendantUpdateEntityT = buildWelcomeAttendantStartNodeUpdatePayload(
      welcomeAttendantData,
      formData,
      startNode.name
    );
    return dispatch(
      updateWelcomeAttendant(
        enterpriseId,
        welcomeAttendantData.id,
        patchPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
  };

  return (
    <EditCallflowDetails
      nodeId={welcomeAttendantData.id}
      icon={<StartIcon />}
      title={t('callflows.editWelcomeAttendantStart.title')}
      description={t('callflows.editWelcomeAttendantStart.description')}
      defaultValues={initialFormValues}
      onSaveForm={onSubmit}
      validationSchema={waStartSchema}
    >
      <WelcomeAttendantStepField
        field="nextStep"
        title={t('callflows.editWelcomeAttendantStart.nextStep')}
        stepOptions={stepOptions}
        noEndStep
        noStartStep
        tooltip={{
          title: t('callflows.editWelcomeAttendantStart.tooltipTitle'),
          text: t('callflows.editWelcomeAttendantStart.tooltipText')
        }}
      />
    </EditCallflowDetails>
  );
};

export default EditStartDetails;
