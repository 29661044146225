import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUpdateFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.8 14.5v4c0 .7-.6 1.2-1.2 1.2h-4c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h1.1c-2.9-5.2-1-11.7 4.2-14.6 2.3-1.3 4.9-1.7 7.5-1.1.7.1 1.2.7 1.1 1.4s-.9 1.1-1.6 1h-.2c-4.5-1-8.8 1.9-9.8 6.3-.3 1.5-.2 3.1.3 4.5v-.3c0-.7.6-1.2 1.2-1.2s1.4.5 1.4 1.2m17-9c0-.7-.6-1.2-1.2-1.2h-4c-.7 0-1.2.6-1.2 1.2v4c0 .7.6 1.2 1.2 1.2s1.2-.6 1.2-1.2v-.3c1.6 4.3-.7 9-4.9 10.6q-2.25.75-4.5.3c-.7-.1-1.3.4-1.4 1.1-.1.6.3 1.2.9 1.4.7.2 1.5.2 2.3.2C18.1 22.8 23 18 22.9 12c0-1.8-.5-3.6-1.4-5.2h1.1c.6 0 1.2-.6 1.2-1.3"/></svg>)}
    />
  );
}
  