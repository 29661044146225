import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileZipLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.562 5.854 15.152.442A1.5 1.5 0 0 0 14.086 0H5C3.897 0 3 .897 3 2v20c0 1.103.897 2 2 2h13.995a2 2 0 0 0 1.999-1.999L21 6.914c0-.399-.155-.775-.438-1.06M19.294 6h-3.8a.5.5 0 0 1-.5-.5V1.698zm-.3 17H5c-.551 0-1-.448-1-1V2c0-.551.448-1 1-1h8.994v4.5c0 .827.673 1.5 1.5 1.5H20l-.007 15.001a1 1 0 0 1-.999.999M8.285 19.217h2.189v.779H7.161v-.612l2.134-3.06H7.219v-.78h3.197v.608zm3.21-3.673h.944v4.452h-.944zm3.797 0H13.87v4.452h.944v-1.584h.405q.828 0 1.277-.381.448-.38.448-1.099 0-.692-.421-1.04c-.421-.348-.691-.348-1.231-.348m.484 1.923q-.216.172-.651.172h-.311v-1.321h.43q.386 0 .567.158.182.159.182.49t-.217.501"/></svg>)}
    />
  );
}
  