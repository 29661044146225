import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCloseLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.85 19.15a.48.48 0 0 1 .022.678l-.022.022a.48.48 0 0 1-.678.022l-.022-.022L12 12.71l-7.15 7.14a.48.48 0 0 1-.678.022l-.022-.022a.48.48 0 0 1-.022-.678l.022-.022L11.29 12 4.15 4.85a.496.496 0 0 1 .7-.7L12 11.29l7.15-7.14a.496.496 0 0 1 .7.7L12.71 12z"/></svg>)}
    />
  );
}
  