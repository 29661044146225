// @flow

import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import Toggle from '@design-system/component-library/src/components/Toggle';
import Tooltip from '../../../../../components/Tooltip';
import styles from './ToggleField.module.scss';

type PropsT = {|
  field: string,
  label: string,
  elementId?: string,
  tooltip?: string,
  description?: string,
  disabled?: boolean,
  // eslint-disable-next-line flowtype/no-weak-types
  disabledFn?: any => boolean
|};

export const ToggleField = (props: PropsT): Element<'div'> => {
  const { elementId, field, label, description, tooltip, disabled, disabledFn } = props;
  const { setValue, getValues } = useFormContext();
  const values = getValues();
  const disabledBoolean: boolean =
    disabledFn === undefined ? disabled || false : disabledFn(values);
  return (
    <div>
      <div className={styles.title}>
        {label}
        {tooltip && <Tooltip containerStyle={styles.tooltip}>{tooltip}</Tooltip>}
      </div>
      <Toggle
        value={values[field]}
        data-cy={elementId || 'switch'}
        onToggle={() => {
          setValue(field, !values[field], {
            shouldValidate: true,
            shouldDirty: true
          });
        }}
        disabled={disabled || disabledBoolean || false}
        i18n_toggle_helpText={description}
      />
    </div>
  );
};

export default ToggleField;
