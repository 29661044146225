import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDiamondRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.705 6.608 19.25.5a1.26 1.26 0 0 0-1-.5H5.752c-.393 0-.768.188-1.005.508L.296 6.603A1.52 1.52 0 0 0 .281 8.38l10.712 15.1.001.002a1.22 1.22 0 0 0 1.009.518 1.24 1.24 0 0 0 1.003-.519L23.721 8.384a1.515 1.515 0 0 0-.016-1.776M15.553 8.25 12 20.545 8.448 8.25zm-6.669-1.5L12 2.098l3.117 4.652zm4.521-5.25h4.098l-1.235 4.274zM7.732 5.774 6.497 1.5h4.097zm2.49 14.022L2.046 8.25h4.84zM17.114 8.25h4.863l-8.212 11.59zm.434-1.5 1.249-4.323L21.94 6.75zM5.203 2.426l1.25 4.324H2.045z"/></svg>)}
    />
  );
}
  