// @flow strict-local

import React, { type Element } from 'react';
import classNames from 'classnames';
import styles from './SmallLoadingIndicator.module.scss';

export type PropsT = {
  text?: string
};

export const SmallLoadingIndicator = (props: PropsT): Element<'div'> => (
  <div className={styles.container}>
    <span className={classNames('ea-loading', styles.loader)} aria-label="ladataan" />
    {props.text && <span className={styles.text}>{props.text}</span>}
  </div>
);

export default SmallLoadingIndicator;
