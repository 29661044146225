import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNewMessageRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.25 12.5v9.25A2.25 2.25 0 0 1 19 24H2.25A2.25 2.25 0 0 1 0 21.75V5a2.25 2.25 0 0 1 2.25-2.25h9.25a.75.75 0 0 1 0 1.5H2.25A.76.76 0 0 0 1.5 5v16.75a.76.76 0 0 0 .75.75H19a.76.76 0 0 0 .75-.75V12.5a.75.75 0 0 1 1.5 0M23.135.879l-.027-.027a3.09 3.09 0 0 0-4.26 0l-1.11 1.07-8.5 8.53a.8.8 0 0 0-.21.43l-.53 3.73a.75.75 0 0 0 .74.86h.11l3.73-.53a.8.8 0 0 0 .43-.21l9.6-9.61a3 3 0 0 0 .027-4.243M12.618 13.481l-2.5.36.36-2.5 7.83-7.83 2.14 2.14zm9.434-9.434-.005.005-.54.54-2.14-2.14.54-.54a1.55 1.55 0 0 1 2.14 0c.591.588.594 1.544.005 2.135"/></svg>)}
    />
  );
}
  