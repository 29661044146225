// @flow

import React, { Component, type Element } from 'react';
import { SubmissionError } from 'redux-form';
import { CancelToken } from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { type ContextRouter } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import type { StoreStateT } from '../../../commonTypes';
import { operations as userOps } from '../../../ducks/entities/user/index';
import { operations as configOps } from '../../../ducks/config/index';
import type { UserConfigT } from '../../../ducks/config';
import { goToUserDetails, goToEnterpriseUsers } from '../../../navigationOperations';
import ErrorBoundary from '../../../components/Error/ErrorBoundary';
import GenericError from '../../../components/Error/GenericError';
import type { GoToEnterpriseUsersFnT, GoToUserDetailsFnT } from '../../../navigationOperations';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserStateT } from '../../../ducks/currentUser';
import { actions as notificationActions } from '../../../ducks/ui/notification';
import type { CreateCreateNotificationActionFnT } from '../../../ducks/ui/notification/notificationUiActions';
import TopNavigation from '../../navigation/TopNavigation';
import BaseContainer from '../../BaseContainer/BaseContainer';
import ExternalUserDetails from './ExternalUser/ExternalUserDetails';

type OwnPropsT = {};

type StatePropsT = {
  userConfig: UserConfigT,
  currentUser: CurrentUserStateT
};

type DispatchPropsT = {
  addRecentUserToConfig: typeof configOps.addRecentUserToConfig,
  create: typeof userOps.create,
  goToUserDetails: GoToUserDetailsFnT,
  goToUsers: GoToEnterpriseUsersFnT,
  notify: CreateCreateNotificationActionFnT
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<ContextRouter>,
  ...$Exact<WithTranslationProps>
|};

export class CreateUser extends Component<PropsT> {
  constructor(props: PropsT) {
    super(props);
    this.createExternalUser = this.createExternalUser.bind(this);
    this.handleCreateUserClosed = this.handleCreateUserClosed.bind(this);
  }

  handleCreateUserClosed: () => void;

  handleCreateUserClosed() {
    const {
      match: {
        params: { id: enterpriseId }
      },
      goToUsers
    } = this.props;
    if (enterpriseId) {
      goToUsers(enterpriseId);
    }
  }

  createExternalUser: *;

  async createExternalUser(values: *) {
    const {
      addRecentUserToConfig,
      userConfig,
      create,
      t,
      notify,
      match: {
        params: { id: enterpriseId }
      },
      // eslint-disable-next-line no-shadow
      goToUserDetails,
      currentUser
    } = this.props;

    if (!enterpriseId) {
      throw new SubmissionError({ _error: t('createExternalUsers.failedToCreateMsg') });
    }
    const response = await create(
      values,
      CancelToken.source().token,
      createCsrfHeader(currentUser)
    );

    // $FlowFixMe
    if (!response || !response.id) {
      throw new SubmissionError({ _error: t('createExternalUsers.failedToCreateMsg') });
    }
    addRecentUserToConfig(response, userConfig, createCsrfHeader(currentUser));
    goToUserDetails(enterpriseId, response.id.replace('UserContact-', ''), 'externalUser');
    notify({
      tag: 'external-user-created',
      duration: 15000,
      type: 'info',
      message: t('createExternalUsers.successCreateMsg')
    });
  }

  render(): Element<typeof ErrorBoundary> {
    const { t } = this.props;

    return (
      <BaseContainer header={<TopNavigation />}>
        <ErrorBoundary
          errorElement={<GenericError message={t('createExternalUsers.failedToCreateMsg')} />}
        >
          <ExternalUserDetails
            onSave={this.createExternalUser}
            userId={null}
            onClose={this.handleCreateUserClosed}
            alwaysEdit
          />
        </ErrorBoundary>
      </BaseContainer>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addRecentUserToConfig: configOps.addRecentUserToConfig,
      create: userOps.create,
      goToUserDetails,
      goToUsers: goToEnterpriseUsers,
      notify: notificationActions.createCreateNotificationAction
    },
    dispatch
  );

const mapStateToProps = (state: StoreStateT) => ({
  userConfig: state.config.userConfig,
  currentUser: state.currentUser
});

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(CreateUser);
