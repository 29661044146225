import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMobilePaymentRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 1a6.75 6.75 0 1 0 6.75 6.75A6.76 6.76 0 0 0 17 1m0 12a5.25 5.25 0 1 1 5.25-5.25A5.26 5.26 0 0 1 17 13m-1.71-6H17a.75.75 0 0 1 0 1.5h-1.71a2.23 2.23 0 0 0 3.31 1.15.74.74 0 0 1 1 .23.75.75 0 0 1-.23 1 3.67 3.67 0 0 1-2 .59 3.73 3.73 0 0 1-3.66-3h-.25a.75.75 0 0 1 0-1.5h.25a3.74 3.74 0 0 1 3.66-3 3.67 3.67 0 0 1 2 .59.75.75 0 1 1-.8 1.26A2.24 2.24 0 0 0 15.29 7M15 15.25a.76.76 0 0 0-.75.75v.5H2.75V2.25a.76.76 0 0 1 .75-.75h8.75a.75.75 0 0 0 0-1.5H3.5a2.25 2.25 0 0 0-2.25 2.25v19.5A2.25 2.25 0 0 0 3.5 24h10a2.25 2.25 0 0 0 2.25-2.25V16a.76.76 0 0 0-.75-.75m-1.5 7.25h-10a.76.76 0 0 1-.75-.75V18h11.5v3.75a.76.76 0 0 1-.75.75m-4.08-2.63a.94.94 0 0 1 0 .76.9.9 0 0 1-.54.54.6.6 0 0 1-.19.06H8.3l-.18-.06a.8.8 0 0 1-.18-.09 2 2 0 0 1-.15-.12 1 1 0 0 1-.21-.33.94.94 0 0 1 0-.76q.073-.186.21-.33a1 1 0 0 1 1.09-.21q.092.03.17.09l.16.12q.137.144.21.33"/></svg>)}
    />
  );
}
  