// @flow

import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WelcomeAnnouncementIcon } from '../../../../../assets/callflow/details/welcome-announcement-small.svg';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowAudioProperty from '../../../components/view/children/CallflowAudioProperty';
import AudioFieldConverter from '../../../components/edit/children/audio/AudioFieldConverter';

export type PropsT = {|
  callflowId: string
|};

export const PlayMusicDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId } = props;
  const { t } = useTranslation();

  const playMusicData = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const playMusic = R.path(['ivrProperties', 'playMusic'], playMusicData) || {};

  const callflowAudioFieldConverter = new AudioFieldConverter(
    playMusicData.enterpriseId,
    playMusicData.id,
    'playmusics',
    'PlayMusic'
  );

  return (
    <CallflowDetails
      enterpriseId={playMusicData.enterpriseId}
      callflowId={playMusicData.id}
      elementType={playMusicData.type}
      nodeId="MESSAGE"
      nodeType="PLAY_MUSIC"
      icon={<WelcomeAnnouncementIcon />}
      title={t('callflows.viewPlayMusicDetails.title')}
      active
    >
      <CallflowDetailsColumn id="play-music-column-1">
        <CallflowAudioProperty
          title={t('callflows.viewPlayMusicDetails.musicWait')}
          audioField={callflowAudioFieldConverter.convertToAudioPropertyField(
            'MusicWait',
            '',
            R.path(['musicWait'], playMusic)
          )}
          isActive
        />
        <CallflowProperty
          id="maxDuration"
          title={t('callflows.viewPlayMusicDetails.maxDuration')}
          property={playMusic.maxDuration}
          tooltip={t('callflows.editPlayMusicDetails.maxDurationTooltip')}
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default PlayMusicDetails;
