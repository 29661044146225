// @flow

/* eslint-disable no-use-before-define */

import * as R from 'ramda';
import type {
  RetrieveHistoryCollectionActionT,
  HistoryActionsStateT,
  HistoryActionT
} from './historyActionTypes';

const initialState: HistoryActionsStateT = {
  byId: {},
  allIds: [],
  __metadata: {}
};

const retrieveCollectionReducer = (
  state: HistoryActionsStateT,
  action: RetrieveHistoryCollectionActionT
): HistoryActionsStateT => {
  switch (action.type) {
    case 'ring/entity/history/EMPTY_COLLECTION_REQUEST': {
      return {
        byId: {},
        allIds: [],
        __metadata: {}
      };
    }
    case 'ring/entity/history/RETRIEVE_COLLECTION_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/history/RETRIEVE_COLLECTION_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          ...action.payload.reduce(
            (collection, history) => ({
              ...collection,
              [`${history.enterpriseId}-${history.id}`]: {
                ...history,
                __metadata: {}
              }
            }),
            {}
          )
        },
        allIds: R.union(
          state.allIds,
          action.payload.map(history => `${history.enterpriseId}-${history.id}`)
        ),
        __metadata: {}
      };
    }
    case 'ring/entity/history/RETRIEVE_COLLECTION_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/history/RETRIEVE_COLLECTION_CANCEL': {
      return {
        ...state,
        __metadata: {}
      };
    }
    default:
      return state;
  }
};

const rootReducer = (
  state: HistoryActionsStateT = initialState,
  action: HistoryActionT
): HistoryActionsStateT => {
  switch (action.type) {
    case 'ring/entity/history/RETRIEVE_COLLECTION_REQUEST':
    case 'ring/entity/history/RETRIEVE_COLLECTION_SUCCESS':
    case 'ring/entity/history/RETRIEVE_COLLECTION_FAILURE':
    case 'ring/entity/history/RETRIEVE_COLLECTION_CANCEL':
    case 'ring/entity/history/EMPTY_COLLECTION_REQUEST': {
      return callFlowReducer.retrieveCollectionReducer(state, action);
    }
    default:
      return state;
  }
};

const callFlowReducer = {
  initialState,
  retrieveCollectionReducer,
  rootReducer
};

export default callFlowReducer;
