// @flow strict-local

import React, { type Element } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { ExactPropsT } from '../../../../../commonTypes';
import InfoBox from '../../../../../components/InfoBox';
import Avatar from '../../../../../components/Avatar/Avatar';
import styles from './ForwardingUsername.module.scss';

export type OwnPropsT = {|
  fullName: string,
  editing?: boolean
|};

type StatePropsT = {};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;

export const ForwardingUsername = (props: PropsT): Element<typeof InfoBox> => {
  const { fullName, editing } = props;
  const { t } = useTranslation();
  return (
    <InfoBox extraStyle={styles.infobox}>
      <div>
        <div className={styles.title}>
          {editing
            ? t('forwardingDetails.editingForwarding')
            : t('forwardingDetails.creatingForwarding')}
        </div>
        <div className={styles.name}>
          <Avatar
            className={styles.avatar}
            name={fullName}
            hideAvailability
            color="#0019AF"
            size="small"
          />
          {fullName}
        </div>
      </div>
    </InfoBox>
  );
};

const mapStateToProps = () => ({});

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(ForwardingUsername);
