import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowDownAltFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.002 24c-.472 0-.931-.194-1.258-.534L4.103 16.62a1.247 1.247 0 0 1 .027-1.767 1.25 1.25 0 0 1 1.768.027l4.853 5.002V1.25a1.249 1.249 0 1 1 2.499 0v18.636l4.853-5.002a1.25 1.25 0 1 1 1.795 1.741l-6.639 6.844a1.76 1.76 0 0 1-1.257.531"/></svg>)}
    />
  );
}
  