import React from 'react';
import PropTypes from 'prop-types';

import HeaderDivider from './subcomponents/Divider/Divider';
import Disclaimer from './subcomponents/Disclaimer/Disclaimer';
import FooterLogo from './subcomponents/Logo/Logo';
import Links from './subcomponents/Links/Links';
import SocialLinks from './subcomponents/SocialIcons/SocialIcons';
import { BREAKPOINT_L, classNames } from '../../utils/css';
import { useIdWithFallback } from '../../utils/hooks';
import styles from './Footer.module.scss';

function Footer(
  {
    className = null,
    logo = null,
    socialLinks = null,
    links = null,
    disclaimerLinks = null,
    id = null,
  }
) {
  const htmlId = useIdWithFallback('dsFooter', id);
  const footerClasses = classNames([
    className,
    styles.footer,
  ]);

  return (
    <footer id={htmlId} className={footerClasses}>
      <div className={styles.footer__firstrow}>
        {logo}
        <div className={styles['footer__social-links--upper']}>{socialLinks}</div>
      </div>
      <HeaderDivider />
      {links}
      <div className={styles['footer__social-links--lower']}>{socialLinks}</div>
      {disclaimerLinks}
    </footer>
  );
}

Footer.Logo = FooterLogo;
Footer.SocialLinks = SocialLinks;
Footer.Links = Links;
Footer.DisclaimerLinks = Disclaimer;

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.node,
  socialLinks: PropTypes.node,
  links: PropTypes.node,
  disclaimerLinks: PropTypes.node,
  id: PropTypes.string,
};

export { FooterLogo, SocialLinks, Links,  Disclaimer }
export default Footer;
