import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWhatsappLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.001 24a11.93 11.93 0 0 1-6.4-1.854L1.407 23.49a.498.498 0 0 1-.626-.636l1.355-4.03A11.9 11.9 0 0 1 0 11.999C0 5.383 5.383 0 12 0s12 5.383 12 12-5.383 12-11.999 12m-6.327-2.901c.097 0 .193.028.276.083A10.94 10.94 0 0 0 12.001 23C18.065 23 23 18.065 23 12S18.065 1 12 1 1 5.934 1 11.999c0 2.332.725 4.562 2.096 6.449.095.131.121.3.07.453l-1.122 3.335 3.478-1.114a.5.5 0 0 1 .152-.023m9.078-2.558q-.192 0-.39-.013c-3.35-.227-7.277-3.197-8.576-6.487-.82-2.078-.486-4.063.942-5.591.389-.425 1.098-.634 1.755-.517.539.095.949.394 1.125.819l.351.85c.245.596.508 1.238.561 1.346.17.342.178.67.023.976-.113.226-.188.301-.363.462-.078.071-.189.174-.36.344l-.034.034c.861 1.458 2.166 2.599 3.893 3.416l.109.053c.08.04.111.044.112.045.082-.166.372-.578.585-.899.429-.645 1.014-.435 1.263-.344.332.121 1.944.919 2.107 1.001l.144.069c.266.128.496.238.635.468l.002.003c.282.475.224 1.189-.154 1.864-.766 1.365-2.082 2.101-3.73 2.101M8.113 6.901c-.262 0-.521.086-.652.228-1.475 1.58-1.219 3.343-.744 4.545 1.153 2.92 4.757 5.657 7.713 5.856 1.464.102 2.535-.432 3.178-1.579.209-.373.232-.692.184-.831a4 4 0 0 0-.227-.114l-.156-.076c-.408-.205-1.779-.874-2.003-.956q-.054-.02-.092-.031c-.126.185-.492.727-.722.99-.329.379-.751.444-1.252.194l-.089-.043c-1.936-.916-3.399-2.209-4.349-3.845-.373-.635.067-1.073.211-1.217.184-.184.305-.295.388-.372.111-.102.111-.102.148-.176l-.616-1.492-.35-.846c-.042-.103-.182-.184-.374-.219a1 1 0 0 0-.196-.016m9.702 8.236h.01z"/></svg>)}
    />
  );
}
  