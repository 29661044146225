// @flow

import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import styles from './ImageDropzone.module.scss';
import { ReactComponent as ImageUploadIcon } from '../../assets/public/image_upload.svg';
import { ReactComponent as TrashBinIcon } from '../../assets/trashbin_new.svg';
import type { TranslateT } from '../../commonTypes';

type PropsT = {
  onChange: string => void,
  onDelete: () => void,
  disabled?: boolean,
  initialImageUrl: ?string,
  translate: TranslateT<>
};

type StateT = {
  // eslint-disable-next-line flowtype/no-weak-types
  image: ?any,
  position: {},
  scale: number,
  borderRadius: number,
  width: number,
  height: number,
  // eslint-disable-next-line flowtype/no-weak-types
  editor: ?any,
  fileSizeError: boolean,
  fileFormatError: boolean
};

class OmaRingAvatarEditor extends Component<PropsT, StateT> {
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  constructor(props: PropsT) {
    super(props);
    this.state = {
      image: props.initialImageUrl ?? null,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      borderRadius: 50,
      width: 200,
      height: 200,
      editor: null,
      fileSizeError: false,
      fileFormatError: false
    };
  }

  setScaledImage() {
    if (this.state.editor) {
      const canvasScaled = this.state.editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      this.props.onChange(croppedImg);
    }
  }

  isImageWrongFormat = (files: File[]): boolean => {
    const acceptedFormats = ['image/png', 'image/jpg', 'image/jpeg'];
    return acceptedFormats.some(acceptedFormat => acceptedFormat === files[0].type);
  };

  isImageTooLarge = (files: File[]): boolean => {
    const MAX_IMAGE_FILE_SIZE = 10000000; // 10 Mb
    return files[0].size <= MAX_IMAGE_FILE_SIZE;
  };

  handleNewImage = (e: File[]) => {
    if (!this.isImageTooLarge(e)) {
      this.setState({ fileSizeError: true });
      return;
    }
    if (!this.isImageWrongFormat(e)) {
      this.setState({ fileFormatError: true });
      return;
    }
    this.setState({ fileSizeError: false, fileFormatError: false });
    const url = URL.createObjectURL(e[0]);
    this.setState({ image: url });
    this.props.onChange(url);
  };

  // $FlowFixMe
  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
    this.setScaledImage();
  };

  // $FlowFixMe
  setEditorRef = editor => this.setState({ editor });

  handlePositionChange = (position: {}) => {
    if (this.state.image === this.props.initialImageUrl) return;
    this.setState({ position });
    this.setScaledImage();
  };

  render() {
    return (
      <div className={styles['image-container']} id="avatar-drop" data-cy="avatar-drop">
        <Dropzone onDrop={this.handleNewImage}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                onClick: event =>
                  this.state.image ? event.stopPropagation() : getRootProps.onClick
              })}
            >
              {!this.state.image && (
                <button type="button" className={styles.box}>
                  <div className={styles.box}>
                    <ImageUploadIcon />
                    <br />
                    <div>{this.props.translate('users.uploadAvatarDialog.addImageTitle')}</div>
                  </div>
                </button>
              )}
              {this.state.image && (
                <AvatarEditor
                  id="avatar-editor"
                  ref={this.setEditorRef}
                  scale={parseFloat(this.state.scale)}
                  width={this.state.width}
                  height={this.state.height}
                  position={this.state.position}
                  onPositionChange={this.handlePositionChange}
                  borderRadius={this.state.width / (100 / this.state.borderRadius)}
                  image={this.state.image}
                  className="editor-canvas"
                />
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {this.state.fileSizeError && (
          <div className={styles.error}>
            {this.props.translate('users.uploadAvatarDialog.fileTooLargeError')}
          </div>
        )}
        {this.state.fileFormatError && (
          <div className={styles.error}>
            {this.props.translate('users.uploadAvatarDialog.fileFormatError')}
          </div>
        )}
        {(!this.state.image || this.state.image === this.props.initialImageUrl) &&
          this.props.translate('users.uploadAvatarDialog.instructions')}
        {this.state.image && (
          <div id="avatar-tools-container">
            {this.state.image !== this.props.initialImageUrl && (
              <div id="avatar-zoom-container">
                {this.props.translate('users.uploadAvatarDialog.zoomImage')}
                <div>
                  <input
                    name="scale"
                    type="range"
                    onChange={this.handleScale}
                    min="1"
                    max="2"
                    step="0.01"
                    defaultValue="1"
                  />
                </div>
              </div>
            )}
            <div>
              <Dropzone onDrop={this.handleNewImage}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <div className={styles['button-container']}>
                      <button className={styles.inline} type="button">
                        <ImageUploadIcon width="1em" stroke="#0019af" />
                        <div className={styles.element}>
                          {this.props.translate('users.uploadAvatarDialog.changeImage')}
                        </div>
                      </button>
                    </div>
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
            </div>
            <div className={styles['button-container']}>
              <button className={styles.inline} type="button" onClick={this.props.onDelete}>
                <TrashBinIcon width="1em" stroke="#0019af" />
                <div className={styles.element}>
                  {this.props.translate('users.uploadAvatarDialog.deleteImage')}
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OmaRingAvatarEditor;
