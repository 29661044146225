import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInstagramLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.37 0H6.63A6.64 6.64 0 0 0 0 6.63v10.74A6.64 6.64 0 0 0 6.63 24h10.74A6.64 6.64 0 0 0 24 17.37V6.63A6.64 6.64 0 0 0 17.37 0M23 17.37A5.64 5.64 0 0 1 17.37 23H6.63A5.64 5.64 0 0 1 1 17.37V6.63A5.64 5.64 0 0 1 6.63 1h10.74A5.64 5.64 0 0 1 23 6.63zM12 6.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 5.5-5.5A5.51 5.51 0 0 0 12 6.5m0 10a4.5 4.5 0 1 1 4.5-4.5 4.51 4.51 0 0 1-4.5 4.5M20 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/></svg>)}
    />
  );
}
  