"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShoppingCartItem = void 0;
exports.ShoppingCartItem = ShoppingCartItem;
var ShoppingCartItemAddon_1 = require("./ShoppingCartItemAddon");
var ShoppingCartPaymentOption_1 = require("./ShoppingCartPaymentOption");
var ShoppingCartPrice_1 = require("./ShoppingCartPrice");
function filterAddon(addon) {
    if ((0, ShoppingCartItemAddon_1.isShoppingCartItemAddon)(addon)) {
        return (0, ShoppingCartItemAddon_1.ShoppingCartItemAddon)({
            description: addon.description,
            disclaimer: addon.disclaimer,
            id: addon.id,
            name: addon.name,
            price: addon.price,
            quantity: addon.quantity,
        });
    }
    return addon;
}
function ShoppingCartItem(_a) {
    var _b = _a.addons, addons = _b === void 0 ? [] : _b, _c = _a.description, description = _c === void 0 ? null : _c, _d = _a.disclaimer, disclaimer = _d === void 0 ? [] : _d, id = _a.id, _e = _a.image, image = _e === void 0 ? null : _e, maxQuantity = _a.maxQuantity, name = _a.name, paymentOptions = _a.paymentOptions, price = _a.price, quantity = _a.quantity, url = _a.url;
    return {
        addons: addons.map(filterAddon),
        description: description,
        disclaimer: disclaimer,
        id: id,
        image: image,
        maxQuantity: maxQuantity,
        name: name,
        paymentOptions: paymentOptions.map(function (po) { return (0, ShoppingCartPaymentOption_1.ShoppingCartPaymentOption)({ id: po.id, label: po.label, selected: !!po.selected }); }),
        price: (0, ShoppingCartPrice_1.ShoppingCartPrice)({ amount: price.amount, unit: price.unit }),
        quantity: quantity,
        url: url,
    };
}
var isShoppingCartItem = function (item) {
    if (!item || typeof item !== 'object') {
        return false;
    }
    var itemProps = Object.keys(item);
    return ['id', 'name', 'paymentOptions', 'price', 'quantity'].every(function (key) { return itemProps.includes(key); });
};
exports.isShoppingCartItem = isShoppingCartItem;
exports.default = {};
