import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLockOpenRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.8 8.8h-9.2V6.2C12.5 2.8 9.7 0 6.2 0S0 2.8 0 6.2v3.2c0 .4.3.8.8.8s.8-.3.8-.8V6.2c0-2.6 2.1-4.8 4.8-4.8S11 3.6 11 6.2v2.5H8c-1.2 0-2.2 1-2.2 2.2v10.8C5.8 23 6.8 24 8 24h13.8c1.2 0 2.2-1 2.2-2.2V11c0-1.2-1-2.2-2.2-2.2m.7 13c0 .4-.3.8-.8.8H8c-.4 0-.8-.4-.8-.8V11c0-.4.3-.8.8-.8h13.7c.4 0 .8.3.8.8zM15.8 15v3c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-3c0-.4.3-.8.8-.8s.8.4.8.8"/></svg>)}
    />
  );
}
  