import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCustomerServiceRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.8 8.8q-.75-.75-1.8-.9c-.2-2-1.1-4-2.6-5.4C16.7.9 14.4 0 12 0S7.3.9 5.7 2.5C4.1 3.9 3.2 5.8 3 7.9c-.6.2-1.3.5-1.8.9-.7.7-1.2 1.7-1.2 2.7v3c0 1 .5 2 1.2 2.7.8.7 1.8 1 2.9 1h.8c.4 0 .8-.1 1.1-.4s.5-.7.5-1.1V9.2c0-.4-.2-.8-.5-1.1-.3-.2-.7-.3-1.1-.3h-.4q.3-2.4 2.1-4.2C8.1 2.2 10 1.5 12 1.5s3.9.7 5.3 2.1c1.2 1.1 2 2.6 2.1 4.2H19c-.4 0-.8.1-1.1.4s-.5.7-.5 1.1v7.5c0 .4.2.8.5 1.1s.7.4 1.1.4h.4v.5c0 .6-.2 1.2-.7 1.6-.4.4-1.1.7-1.7.7h-1.7c-.1-.4-.3-.7-.5-1-.4-.5-1-.7-1.6-.7h-1.4c-.6 0-1.2.3-1.6.7-.4.5-.6 1-.6 1.6s.2 1.2.6 1.6c.4.5 1 .7 1.6.7h1.4c.6 0 1.2-.3 1.6-.7.2-.2.3-.5.4-.8H17c1 0 2-.4 2.7-1.1s1.2-1.7 1.2-2.7V18q1.05-.3 1.8-.9c.8-.7 1.2-1.7 1.2-2.7v-3c.1-.9-.4-1.9-1.1-2.6M5 9.8v6.5c0 .3-.2.5-.5.5h-.4c-.7 0-1.4-.3-1.9-.7s-.7-1-.7-1.6v-3c0-.6.3-1.1.7-1.6.5-.4 1.1-.7 1.9-.7h.4c.3 0 .5.3.5.6m8.3 12.7h-1.4c-.1 0-.3-.1-.4-.2-.1-.2-.2-.4-.2-.7s.1-.5.2-.7.3-.2.4-.2h1.4c.1 0 .3.1.4.2.1.2.2.4.2.7s-.1.5-.2.7c-.1.1-.2.2-.4.2m9.2-8c0 .6-.3 1.1-.7 1.6-.5.4-1.1.7-1.9.7h-.4c-.3 0-.5-.2-.5-.5V9.8c0-.3.2-.5.5-.5h.4c.7 0 1.4.3 1.9.7s.7 1 .7 1.6z"/></svg>)}
    />
  );
}
  