import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDevicesLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 15.5v1a3.5 3.5 0 0 1-3.5 3.5H10a.5.5 0 0 1 0-1h10.5a2.5 2.5 0 0 0 2.5-2.5V16h-6.78a3.6 3.6 0 0 1-2.72 1c-1 .059-1.98-.303-2.7-1H10a.5.5 0 0 1 0-1h1a.47.47 0 0 1 .35.15 2.65 2.65 0 0 0 2.15.85 2.57 2.57 0 0 0 2.13-.85A.48.48 0 0 1 16 15h5V6a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9h1.5a.5.5 0 0 1 .5.5M8 10v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2m-7 0v6h6v-6a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m6 8v-1H1v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1"/></svg>)}
    />
  );
}
  