import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHeadphonesFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 17.195h-.005c0 .019.005.036.005.055 0 2.408-1.635 4.425-3.851 5.038a.507.507 0 0 1-.649-.482v-9.111c0-.336.325-.572.649-.482.909.251 1.71.748 2.351 1.402V12c-.016-5.36-4.391-9.735-9.752-9.75-5.358.015-9.733 4.39-9.748 9.752v1.612a5.26 5.26 0 0 1 2.351-1.402.507.507 0 0 1 .649.482v9.111a.507.507 0 0 1-.649.482C2.385 21.675.75 19.658.75 17.25l.005-.055H.75V12C.768 5.814 5.813.768 11.998.75c6.188.018 11.234 5.064 11.252 11.248zM7.5 11.25h-.25a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h.25c1.24 0 2.25-1.009 2.25-2.25v-7.5c0-1.241-1.01-2.25-2.25-2.25m9.25 0h-.25a2.253 2.253 0 0 0-2.25 2.25V21c0 1.241 1.01 2.25 2.25 2.25h.25a.75.75 0 0 0 .75-.75V12a.75.75 0 0 0-.75-.75"/></svg>)}
    />
  );
}
  