import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOmaguruLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.572 1.699A11.1 11.1 0 0 0 12.683 0C6.518-.017 1.507 4.991 1.491 11.185v.02a11.3 11.3 0 0 0 1.542 5.707L.048 23.279a.5.5 0 0 0 .099.57.47.47 0 0 0 .318.14q.105.022.209 0l6.337-2.999c5.302 3.207 12.188 1.489 15.38-3.838s1.483-12.245-3.819-15.453m2.746 14.933c-2.946 4.79-9.199 6.274-13.967 3.314l-.041-.026a.5.5 0 0 0-.478 0l-5.311 2.52 2.527-5.348a.51.51 0 0 0 0-.48A10.25 10.25 0 0 1 5.47 3.998 10.05 10.05 0 0 1 12.643 1c5.621.005 10.174 4.587 10.169 10.234a10.27 10.27 0 0 1-1.533 5.389zm-7.67-1.259c.005.486-.382.884-.866.889s-.88-.384-.885-.87a.877.877 0 0 1 .866-.889h.02a.88.88 0 0 1 .865.87m2.487-5.867a3.49 3.49 0 0 1-3.482 3.498.5.5 0 0 1-.497-.5c0-.276.223-.5.497-.5 1.374 0 2.487-1.119 2.487-2.499s-1.114-2.499-2.487-2.499a2.493 2.493 0 0 0-2.487 2.499c0 .276-.223.5-.497.5a.5.5 0 0 1-.497-.5c0-1.932 1.559-3.498 3.482-3.498s3.481 1.567 3.481 3.499"/></svg>)}
    />
  );
}
  