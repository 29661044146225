// @flow

import type { StartTourT, EndTourT, SetTourStepsT } from './tourTypes';

export type CreateStartTourFnT = () => StartTourT;
export const createStartTourAction: CreateStartTourFnT = () => ({
  type: 'ring/ui/tour/START_TOUR'
});

export type CreateEndTourFnT = () => EndTourT;
export const createEndTourAction: CreateEndTourFnT = () => ({
  type: 'ring/ui/tour/END_TOUR'
});

export type CreateSetStepsFnT = (*[]) => SetTourStepsT;
export const createSetStepsAction: CreateSetStepsFnT = steps => ({
  type: 'ring/ui/tour/SET_STEPS',
  payload: {
    steps
  }
});
