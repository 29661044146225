import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUploadLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.999 18.501v2A3.505 3.505 0 0 1 19.5 24h-15C2.57 24 1 22.431 1 20.501v-2a.5.5 0 0 1 1 0v2A2.503 2.503 0 0 0 4.5 23h15a2.504 2.504 0 0 0 2.499-2.499v-2a.5.5 0 0 1 1 0M5.035 8.172 11.5 1.707V18.5a.5.5 0 0 0 1 0V1.707l6.465 6.465a.5.5 0 0 0 .708 0 .5.5 0 0 0 0-.707L12.355.147a.496.496 0 0 0-.708 0L4.328 7.465a.5.5 0 0 0 .707.707"/></svg>)}
    />
  );
}
  