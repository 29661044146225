import React from 'react';
import IconContainer from '../IconContainer';

export default function IconStarFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.65 10.067-5.628 4.624 2.356 7.008a.98.98 0 0 1-.358 1.113 1 1 0 0 1-1.171-.002l-5.848-4.251-5.853 4.251a1 1 0 0 1-1.165-.001.98.98 0 0 1-.36-1.106l2.357-7.012-5.624-4.619a.99.99 0 0 1-.294-1.103 1 1 0 0 1 .931-.644H8.69l2.379-6.677.004-.009A1 1 0 0 1 12.002 1a1 1 0 0 1 .929.639l2.381 6.687h6.696c.412 0 .786.258.931.643a.99.99 0 0 1-.289 1.098"/></svg>)}
    />
  );
}
  