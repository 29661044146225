import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import IconContainer from '../../Icon/IconContainer';
import IconKeyRegular from '../../Icon/lib/IconKeyRegular';
import { ReactComponent as MobiiliVarmenneIcon } from '../icons/mobiilivarmenne.svg';
import { ReactComponent as BankAuthIcon } from '../icons/bank.svg';

import { classNames } from '../../../utils/css';

import globalStyles from '../../../globals/scss/_typography.scss';
import styles from '../Login.module.scss';

class LoginTypesSwitcher extends Component {
  static propTypes = {
    /** Object with i18n strings */
    translations: PropTypes.object,
    trackEventCallback: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.initSwitchView = this.initSwitchView.bind(this);
  }

  initSwitchView(e) {
    e.preventDefault();

    const { switchView, currentView, trackEventCallback } = this.props;
    const nextView = e.currentTarget.name;
    if (currentView === 'AUTH_USERNAME_PASSWORD' && nextView === 'AUTH_MOBILE_PHONE') {
      trackEventCallback('Mobile login button clicked', '');
    }
    switchView(nextView);
  }

  render() {
    const { enabledLoginTypes, currentView } = this.props;

    const {
      i18n_login_altLoginText,
      i18n_login_elisatunnusMethodButtonText,
      i18n_login_elisatunnusMethodButtonAltText,
      i18n_login_mobiilivarmenneMethodButtonText,
      i18n_login_mobiilivarmenneMethodButtonAltText,
      i18n_login_bankauthMethodButtonText,
      i18n_login_bankauthMethodButtonAltText,
    } = this.props.translations;

    const disclaimerClasses = classNames([globalStyles[`text--s`], styles[`login__switch-text`]]);

    return (
      <>
        {enabledLoginTypes.length > 1 ? (
          <div>
            <p className={disclaimerClasses}>{i18n_login_altLoginText}</p>

            {currentView != 'AUTH_USERNAME_PASSWORD' && enabledLoginTypes.includes('AUTH_USERNAME_PASSWORD') ? (
              <Button
                id="button-select-username-password-authentication"
                size="l"
                block
                color="light"
                className={styles[`login__switch-button`]}
                name="AUTH_USERNAME_PASSWORD"
                onClick={this.initSwitchView}
                aria-label={i18n_login_elisatunnusMethodButtonAltText}
              >
                <IconKeyRegular size="l" />
                {i18n_login_elisatunnusMethodButtonText}
              </Button>
            ) : null}
            {currentView != 'AUTH_MOBILE_PHONE' && enabledLoginTypes.includes('AUTH_MOBILE_PHONE') ? (
              <Button
                id="button-select-mobile-phone-authentication"
                size="l"
                block
                color="light"
                className={styles[`login__switch-button`]}
                name="AUTH_MOBILE_PHONE"
                onClick={this.initSwitchView}
                aria-label={i18n_login_mobiilivarmenneMethodButtonAltText}
              >
                <IconContainer source={MobiiliVarmenneIcon} size="l" />
                {i18n_login_mobiilivarmenneMethodButtonText}
              </Button>
            ) : null}
            {currentView != 'AUTH_STRONG_IDENTIFICATION' && enabledLoginTypes.includes('AUTH_STRONG_IDENTIFICATION') ? (
              <Button
                id="button-select-strong-identification-authentication"
                size="l"
                block
                color="light"
                className={styles[`login__switch-button`]}
                name="AUTH_STRONG_IDENTIFICATION"
                onClick={this.initSwitchView}
                aria-label={i18n_login_bankauthMethodButtonAltText}
              >
                <IconContainer source={BankAuthIcon} size="l" />
                {i18n_login_bankauthMethodButtonText}
              </Button>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
}

export default LoginTypesSwitcher;
