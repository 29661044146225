import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSustainabilityRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.119 20.581a12.7 12.7 0 0 1-6.054 1.669 8.44 8.44 0 0 1-5.985-2.468.749.749 0 1 1 1.057-1.064 6.94 6.94 0 0 0 4.915 2.032 11.2 11.2 0 0 0 5.329-1.475c7.66-4.263 7.43-12.284 6.88-15.88-2.59 1.532-5.237 2.205-8.098 2.065-5.74.001-8.169 2.34-8.192 2.364a1 1 0 0 1-.083.073 7.18 7.18 0 0 0-2.525 7.913l.425-.367a26.4 26.4 0 0 1 9.172-4.633.749.749 0 1 1 .402 1.444 24.9 24.9 0 0 0-8.623 4.347l-3.499 3.02a.747.747 0 0 1-1.059-.077.75.75 0 0 1 .078-1.058l1.894-1.634a8.6 8.6 0 0 1-.628-3.223A8.69 8.69 0 0 1 4.95 6.723c.4-.377 3.168-2.762 9.248-2.762 2.699.124 5.159-.529 7.55-2.008.247-.172.583-.245.919-.181a1.26 1.26 0 0 1 1.018 1.039c.636 3.723 1.169 12.908-7.566 17.77"/></svg>)}
    />
  );
}
  