import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWhatsappFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.383 0 0 5.383 0 11.999c0 2.457.738 4.812 2.136 6.825l-1.355 4.03a.5.5 0 0 0 .626.636l4.194-1.344a11.93 11.93 0 0 0 6.4 1.854C18.617 24 24 18.617 24 12S18.617 0 12 0m6.153 16.256c-.703 1.255-1.871 1.911-3.39 1.911q-.184 0-.376-.013c-3.166-.214-7.022-3.135-8.252-6.25-.541-1.37-.829-3.383.866-5.198.309-.337.879-.498 1.416-.404.41.072.717.289.843.593l.351.849c.246.599.525 1.277.572 1.371.074.149.157.377.024.639-.087.175-.129.213-.283.355-.08.074-.195.179-.371.354-.158.158-.235.25-.112.46.885 1.524 2.256 2.733 4.075 3.594l.103.049c.272.139.38.096.5-.045.162-.186.465-.616.677-.938.268-.401.576-.288.823-.198.291.106 1.83.864 2.066.983l.149.072c.237.114.396.19.476.324l.001.001c.212.356.15.941-.158 1.491"/></svg>)}
    />
  );
}
  