// @flow
import React, { Component, type Element } from 'react';
import classnames from 'classnames';
import initials from 'initials';
import contrast from 'contrast';

export type PropsT = {
  src: ?string,
  name: ?string,
  backgroundColor: string,
  size: number,
  style: *,
  className: string,
  innerClassName?: string,
  radius: number
};

export type StateT = {
  imageStatus: 'LOADING' | 'LOADING_SUCCESSFUL' | 'LOADING_FAILED'
};

class UserAvatar extends Component<PropsT, StateT> {
  static defaultProps = {
    className: '',
    innerClassName: '',
    radius: 0.5,
    style: {},
    src: undefined
  };

  constructor(props: PropsT) {
    super(props);
    this.state = {
      imageStatus: 'LOADING'
    };
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  handleImageLoaded: () => void;

  handleImageLoaded() {
    this.setState({ imageStatus: 'LOADING_SUCCESSFUL' });
  }

  handleImageError: () => void;

  handleImageError() {
    this.setState({ imageStatus: 'LOADING_FAILED' });
  }

  render(): Element<'div'> {
    const {
      src,
      name,
      backgroundColor,
      className,
      style,
      radius,
      size,
      innerClassName
    } = this.props;

    const borderRadius = size * radius;

    const imageStyle = {
      borderRadius,
      width: size || 0,
      height: size || 0,
      display: this.state.imageStatus === 'LOADING_SUCCESSFUL' ? 'flex' : 'hidden'
    };

    const innerStyle = {
      borderRadius,
      borderWidth: 1,
      display: 'flex',
      borderColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      width: size || 0,
      height: size || 0,
      backgroundColor: 'transparent'
    };

    let inner = null;
    const classes = [className, 'UserAvatar'];
    if (!src || this.state.imageStatus === 'LOADING_FAILED') {
      inner =
        initials(name)
          .toUpperCase()
          .substring(0, 2) || '?';
      innerStyle.backgroundColor = backgroundColor;
      classes.push(`UserAvatar--${contrast(innerStyle.backgroundColor)}`);
    } else if (src) {
      inner = (
        <img
          className="UserAvatar--img"
          style={imageStyle}
          src={src}
          alt={name}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageError}
        />
      );
    }

    return (
      <div aria-label={name} className={classes.join(' ')} style={style}>
        <div className={classnames(innerClassName, 'UserAvatar--inner')} style={innerStyle}>
          {inner}
        </div>
      </div>
    );
  }
}

export default UserAvatar;
