// @flow strict-local

import type {
  CallFlowTreeNodeT,
  CoordinatesEntityT
} from '../../../../ducks/entities/callFlow/callFlowTypes';
import { treeHeight } from './CallFlowTreeUtils';

export type CalculateNodePositionsFnT = (
  ?CallFlowTreeNodeT,
  ?CoordinatesEntityT
) => ?CallFlowTreeNodeT;
export const calculateNodePositions: CalculateNodePositionsFnT = (
  currentNode,
  startCoordinate = { x: 0, y: 0 }
) => {
  if (!currentNode) {
    return null;
  }
  const currentCoordinate = {
    x: startCoordinate ? startCoordinate.x : 0,
    y: startCoordinate ? startCoordinate.y : 0
  };

  const vertical = calculateNodePositions(currentNode.verticalNode, {
    x: currentCoordinate.x,
    y: currentCoordinate.y + 1
  });

  let shiftX = 0;
  if (currentNode.horizontalNode) {
    shiftX = currentNode.verticalNode ? currentNode.verticalNode.maxWidth || 0 : 1;
    if (shiftX > 1 && treeHeight(currentNode.horizontalNode) === 1) {
      shiftX = 1;
    }
  }
  const horizontal = calculateNodePositions(currentNode.horizontalNode, {
    x: currentCoordinate.x + Math.max(shiftX, 1),
    y: currentCoordinate.y
  });

  return {
    ...currentNode,
    horizontalNode: horizontal,
    verticalNode: vertical,
    coordinate: currentCoordinate
  };
};

/*
  InitializeNodesWithMaxWidth:
    Tree width is calculated by traveling the tree in post-order: DCEFBGHA

      A - H - G
      |
      B - - - F
      |       |
      C - D   E

    Examples:
    B.maxWidth = max(C.maxWidth, F.maxWidth + additionX), where additionX = C.maxWidth or 1

    However, if horizontal node's height is 1, then it does not need padding, therefore we can set additionX = 1
 */
export type InitializeNodesWithMaxWidthFnT = (?CallFlowTreeNodeT) => ?CallFlowTreeNodeT;
export const initializeNodesWithMaxWidth: InitializeNodesWithMaxWidthFnT = node => {
  if (!node) {
    return null;
  }
  if (!node.verticalNode && !node.horizontalNode) {
    return {
      ...node,
      maxWidth: 1
    };
  }
  const verticalNode = initializeNodesWithMaxWidth(node.verticalNode);
  const horizontalNode = initializeNodesWithMaxWidth(node.horizontalNode);
  let additionX = verticalNode ? verticalNode.maxWidth || 0 : 1;
  if (additionX > 1 && treeHeight(node.horizontalNode) === 1) {
    additionX = 1;
  }

  const verticalNodeMaxWidth = verticalNode ? verticalNode.maxWidth || 0 : 0;
  const horizontalNodeMaxWidth = horizontalNode ? (horizontalNode.maxWidth || 0) + additionX : 0;
  return {
    ...node,
    verticalNode,
    horizontalNode,
    maxWidth: Math.max(verticalNodeMaxWidth, horizontalNodeMaxWidth)
  };
};

export type CalculateCallFlowTreeNodePositionsFnT = (?CallFlowTreeNodeT) => ?CallFlowTreeNodeT;
export const calculateCallFlowTreeNodePositions: CalculateCallFlowTreeNodePositionsFnT = rootNode => {
  const nodesWithMaxWidth = initializeNodesWithMaxWidth(rootNode);
  return calculateNodePositions(nodesWithMaxWidth);
};
