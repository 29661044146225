import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLiveNowLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g clipPath="url(#a)"><path d="M10.248 7.318a.5.5 0 0 1 .497-.004l4 2.25a.5.5 0 0 1 0 .872l-4 2.25A.5.5 0 0 1 10 12.25v-4.5a.5.5 0 0 1 .248-.432"/><path d="M2 2h20c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-9.5v3h4.1c.2 0 .5.2.5.5s-.2.5-.5.5H7.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4v-3H2c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2m20 15c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1H2c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1z" clipRule="evenodd"/></g><defs><clipPath id="a"><path d="M0 0h24v24H0z"/></clipPath></defs></svg>)}
    />
  );
}
  