// @flow

import axios, { type CancelToken, type AxiosPromise } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import type { UpdateAcdActionT, AcdUpdateEntityT, AcdEntityT } from './acdTypes';
import {
  createUpdateAcdCancel,
  createUpdateAcdFailure,
  createUpdateAcdRequest,
  createUpdateAcdSuccess
} from './acdActions';
import type { AcdTypeT } from './acdActions';

export type UpdateT = (
  string,
  AcdTypeT,
  string,
  AcdUpdateEntityT,
  CancelToken,
  headers: {}
) => ThunkActionT<UpdateAcdActionT>;

export const update: UpdateT = (
  enterpriseId,
  acdType,
  acdId,
  acdData,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createUpdateAcdRequest(enterpriseId, acdType, acdId));
  try {
    const response: AxiosPromise<typeof acdData> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/services/acds/${acdId}`,
      cancelToken,
      data: acdData,
      headers
    });

    // $FlowFixMe
    const { data } = await response;
    dispatch(createUpdateAcdSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateAcdCancel(enterpriseId, acdType, acdId));
    } else {
      dispatch(createUpdateAcdFailure(enterpriseId, acdType, acdId, error));
      throw error;
    }
    return undefined;
  }
};

export type GetAcdUpdateProcessT = (
  string,
  AcdTypeT,
  string,
  string,
  CancelToken
) => ThunkActionT<UpdateAcdActionT>;

export const getAcdUpdateProcess: GetAcdUpdateProcessT = (
  enterpriseId,
  acdType,
  acdId,
  uuid,
  cancelToken
) => async dispatch => {
  dispatch(createUpdateAcdRequest(enterpriseId, acdType, acdId));
  try {
    const response: AxiosPromise<AcdEntityT> = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/services/acds/${acdId}/process/${uuid}`,
      cancelToken
    });

    // $FlowFixMe
    const { data } = await response;
    if (data) {
      dispatch(createUpdateAcdSuccess(enterpriseId, data));
    }
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateAcdCancel(enterpriseId, acdType, acdId));
    } else {
      dispatch(createUpdateAcdFailure(enterpriseId, acdType, acdId, error));
      throw error;
    }
    return undefined;
  }
};

export const getOverflowOptions = async (
  enterpriseId: string,
  acdId: string,
  cancelToken: CancelToken
): Promise<AcdEntityT[]> => {
  try {
    const response: AxiosPromise = axios({
      method: 'GET',
      url: `/api/v1/enterprises/${enterpriseId}/services/acds/overflowOptions`,
      cancelToken
    });
    const { data } = await response;
    return (data.results || [])
      .filter(acd => acd.id !== acdId) // remove current acd
      .filter(acd => acd.ringPattern !== 'unisson') // remove unisons
      .map(acd => ({
        ...acd,
        label: (acd.label || '').replace(/^kutsu:/, '') // remove special prefix
      }));
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error;
    }
    return [];
  }
};

const acdOperations = {
  update,
  getOverflowOptions,
  getAcdUpdateProcess
};

export default acdOperations;
