import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDraftLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h9.09a1.55 1.55 0 0 0 1.06-.44l5.41-5.41c.284-.279.443-.662.44-1.06zM4 22V2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v15h-4.5a1.5 1.5 0 0 0-1.5 1.5V23H5a1 1 0 0 1-1-1m11 .3v-3.8a.5.5 0 0 1 .5-.5h3.8z"/></svg>)}
    />
  );
}
  