import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowUpAltLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.998.75c.273 0 .529.108.719.305l6.643 6.848a.499.499 0 1 1-.719.694L12.5 2.267V22.75a.5.5 0 0 1-1 0V2.264l-6.141 6.33a.5.5 0 0 1-.718-.696l6.639-6.844a1 1 0 0 1 .718-.304"/></svg>)}
    />
  );
}
  