import React from 'react';
import IconContainer from '../IconContainer';

export default function IconNetworkFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 19c0 2.206-1.794 4-4 4s-4-1.794-4-4c0-.415.082-.808.2-1.185l-7.108-3.808C7.357 14.91 6.252 15.5 5 15.5c-2.206 0-4-1.794-4-4s1.794-4 4-4c1.334 0 2.51.663 3.237 1.67l6.895-3.201A4 4 0 0 1 15 5c0-2.206 1.794-4 4-4s4 1.794 4 4-1.794 4-4 4a3.99 3.99 0 0 1-3.238-1.67l-6.895 3.202c.079.311.133.632.133.968 0 .415-.082.808-.2 1.185l7.108 3.808C16.643 15.59 17.748 15 19 15c2.206 0 4 1.794 4 4"/></svg>)}
    />
  );
}
  