import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHomeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.452 13.01a.5.5 0 0 1-.36-.15L12.67 2.31a1 1 0 0 0-1.414-.026l-.026.026L.848 12.85a.49.49 0 0 1-.693.007l-.007-.007a.5.5 0 0 1-.003-.706l.003-.003L10.52 1.601a2.003 2.003 0 0 1 2.829-.032l.032.032 10.472 10.54a.5.5 0 0 1 .003.706l-.003.003a.5.5 0 0 1-.401.16m-2.6 7.992v-7.473a.5.5 0 0 0-1 0v7.473a1 1 0 0 1-1 .999H14.85v-3.996a3 3 0 0 0-3.31-2.997 3.097 3.097 0 0 0-2.69 3.137v3.856H4.849a1 1 0 0 1-1-.999v-7.493a.5.5 0 0 0-1 0v7.493a2 2 0 0 0 2 1.998H8.85a1 1 0 0 0 1-.999v-3.856a2.1 2.1 0 0 1 1.79-2.138 2 2 0 0 1 1.55.509c.422.38.662.921.66 1.489v3.996a1 1 0 0 0 1 .999h4.001a2 2 0 0 0 2.001-1.998"/></svg>)}
    />
  );
}
  