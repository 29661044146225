// @flow

/* eslint-disable import/prefer-default-export */

import type { StoreStateT } from '../../../commonTypes';

type MenuIsOpenFnT = StoreStateT => boolean;
export const menuIsOpen: MenuIsOpenFnT = ({ ui: { header } }): boolean =>
  header.helpDropdownOpen === true ||
  header.userDropdownOpen === true ||
  header.usersImportOpen === true;

type ModalIsOpenFnT = StoreStateT => boolean;
export const modalIsOpen: ModalIsOpenFnT = ({ ui: { header } }): boolean =>
  header.feedbackOpen === true || header.onboardingOpen === true || header.changelogOpen === true;
