// @flow
import * as R from 'ramda';
import axios, { type AxiosPromise } from 'axios';
import HTTP from 'http-status-codes';
import type { ThunkActionT } from '../../commonTypes';
import {
  createFeedbackFailure,
  createFeedbackRequest,
  createFeedbackSuccess,
  createFetchCsrfFailure,
  createFetchCsrfRequest,
  createFetchCsrfSuccess,
  createLoginFailure,
  createLoginInvalidAccessRightFailure,
  createLoginMissingRoleAccessRightFailure,
  createLoginRequest,
  createLoginSuccess,
  createLogoutFailure,
  createLogoutRequest,
  createLogoutSuccess,
  createUpdateConfigFailure,
  createUpdateConfigRequest,
  createUpdateConfigSuccess
} from './currentUserActions';
import type {
  CurrentUserPayloadT,
  LoginActionT,
  LogoutActionT,
  CreateFeedbackPostDataT,
  FeedbackActionT,
  UpdateConfigPatchDataT,
  UpdateConfigActionT,
  FetchCsrfActionT,
  GetEntraLoginActionT
} from './currentUserTypes';
import { pushAnalyticsEvent } from '../../utils/analyticsHandler';
import { USER_LOGOUT_SUCCESSFUL } from '../../matomo_constants';

export const getOmaRingSessionCookie = (): ?string => {
  const sessionCookieName = 'RINGUISESSION=';
  const cookies = document.cookie.split(';');
  const sessionCookies = cookies
    .map((cookie: string): string => cookie.trim())
    .filter((cookie: string): boolean => cookie.startsWith(sessionCookieName));
  return sessionCookies.length > 0
    ? sessionCookies[0].substring(sessionCookieName.length)
    : undefined;
};

export type LoginFnT = (
  string,
  string
) => AxiosPromise<
  LoginActionT,
  {
    // eslint-disable-next-line flowtype/space-after-type-colon
    status:
      | 'access_right_error'
      | 'generic_login_error'
      | 'success'
      | 'elisa_ad_platform_missing_error'
      | 'missing_role_access_right_error'
      | 'two_step_authentication'
      | 'otp_authentication'
  }
>;
export const login: LoginFnT = (username, password) => async dispatch => {
  dispatch(createLoginRequest());
  try {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const response: AxiosPromise<typeof formData, CurrentUserPayloadT> = axios({
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
      method: 'POST',
      url: '/api/login',
      data: formData
    });
    const { data } = await response;
    const sessionId = getOmaRingSessionCookie();
    dispatch(
      createLoginSuccess({
        ...data,
        ...(sessionId ? { sessionId } : {})
      })
    );
    return { ...data, status: 'success' };
  } catch (error) {
    const errorMsg = R.path(['response', 'data', 'error'], error) || '';
    const errorStatus = R.path(['response', 'status'], error) || '';
    if (errorStatus === HTTP.PRECONDITION_FAILED) {
      dispatch(createLoginFailure(error));
      if (errorMsg.includes('otpId_')) {
        return { status: 'otp_authentication', username, password, token: errorMsg.split('_')[1] };
      }
      return { status: 'two_step_authentication', username, password, token: errorMsg };
    }

    if (errorMsg && errorMsg.startsWith('ELISA_SSO_INVALID_ACCESS_RIGHTS')) {
      dispatch(createLoginInvalidAccessRightFailure(error));

      return { status: 'access_right_error' };
    }
    if (errorMsg && errorMsg.includes('ACD_MANAGER or SWITCHBOARD')) {
      dispatch(createLoginMissingRoleAccessRightFailure(error));

      return { status: 'missing_role_access_right_error' };
    }
    if (errorMsg && errorMsg.startsWith('ELISA_AD_PLATFORM_MISSING')) {
      return { status: 'elisa_ad_platform_missing_error' };
    }
    dispatch(createLoginFailure(error));
    return { status: 'generic_login_error' };
  }
};

type LogOutFnT = (headers: {}) => ThunkActionT<LogoutActionT>;
export const logout: LogOutFnT = headers => async dispatch => {
  dispatch(createLogoutRequest());
  try {
    await axios({
      method: 'POST',
      url: '/api/logout',
      headers
    });
    pushAnalyticsEvent(USER_LOGOUT_SUCCESSFUL);
    dispatch(createLogoutSuccess());
  } catch (error) {
    dispatch(createLogoutFailure(error));
  }
};

type FetchCsrfFnT = () => ThunkActionT<FetchCsrfActionT>;
export const fetchCsrf: FetchCsrfFnT = () => async dispatch => {
  dispatch(createFetchCsrfRequest());
  try {
    const csrf: AxiosPromise = await axios({
      method: 'GET',
      url: '/api/csrf'
    });
    dispatch(createFetchCsrfSuccess(csrf.data.token));
    return csrf.data.token;
  } catch (error) {
    dispatch(createFetchCsrfFailure(error));
  }
  return undefined;
};

export type SendFeedbackFnT = (
  CreateFeedbackPostDataT,
  headers: {}
) => ThunkActionT<FeedbackActionT>;
export const sendFeedback: SendFeedbackFnT = (feedback, headers) => async dispatch => {
  dispatch(createFeedbackRequest());
  try {
    const response: AxiosPromise<CreateFeedbackPostDataT, {}> = axios({
      method: 'POST',
      url: '/api/v1/feedback',
      data: { ...feedback },
      headers
    });
    await response;
    dispatch(createFeedbackSuccess());
  } catch (error) {
    dispatch(createFeedbackFailure(error));
    return error;
  }
  return undefined;
};

export type UpdateConfigFnT = (
  UpdateConfigPatchDataT,
  headers: {}
) => ThunkActionT<UpdateConfigActionT>;
export const updateConfig: UpdateConfigFnT = (config, headers) => async dispatch => {
  dispatch(createUpdateConfigRequest());
  try {
    const response: AxiosPromise<UpdateConfigPatchDataT, {}> = axios({
      method: 'PATCH',
      url: '/api/v1/admin',
      data: { ...config },
      headers
    });
    await response;
    const { data } = await response;
    dispatch(createUpdateConfigSuccess(data));
  } catch (error) {
    dispatch(createUpdateConfigFailure(error));
    return error;
  }
  return undefined;
};

type GetEntraLoginFnT = () => ThunkActionT<GetEntraLoginActionT>;
export const getEntraLogin: GetEntraLoginFnT = () => async dispatch => {
  try {
    const response: AxiosPromise<{}, CurrentUserPayloadT> = axios({
      method: 'GET',
      url: '/api/v1/entrauser'
    });
    const { data } = await response;
    const sessionId = getOmaRingSessionCookie();
    dispatch(
      createLoginSuccess({
        ...data,
        ...(sessionId ? { sessionId } : {})
      })
    );
  } catch (error) {
    return error;
  }
  return undefined;
};
