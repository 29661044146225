// @flow strict-local

import React, { type Element, useEffect, useState } from 'react';
import * as R from 'ramda';
import Input from '@design-system/component-library/src/components/Input';
import RadioGroup from '@design-system/component-library/src/components/RadioGroup/RadioGroup';
import Radio from '@design-system/component-library/src/components/RadioGroup/Radio';
import { useDispatch } from 'react-redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import { CancelToken } from 'axios';
import { useTranslation } from 'react-i18next';
import Combobox from '@design-system/component-library/src/components/Combobox';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import type { UserListElementT } from '../../../../ducks/entities/user/userTypes';
import { SubstituteSelection } from './SubstituteSelection';
import CenterHorizontally from '../../../../components/CenterHorizontally/CenterHorizontally';
import { getAllForwardingTargets } from '../../../../ducks/entities/directory/directoryOperations';
import type { DirectoryFwdT } from '../../../../ducks/entities/directory/directoryOperations';
import Tooltip from '../../../../components/Tooltip';
import { PHONE_NUMBER_MAX_LENGTH } from '../../../../fieldValidators';

import styles from './ForwardingTarget.module.scss';

type OwnPropsT = {|
  field: string,
  enterpriseId: string,
  substitutes: UserListElementT[],
  enterpriseRule: boolean,
  errorMessage: string,
  filterSearchResults?: DirectoryFwdT => boolean
|};

export type PropsT = OwnPropsT;
let requestDestinationsCancelTokenSource;

export const ForwardingTarget = ({
  field,
  substitutes,
  enterpriseId,
  enterpriseRule,
  errorMessage
}: PropsT): Element<'div' | typeof CenterHorizontally> => {
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<DirectoryFwdT[]>([]);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const dispatch = useDispatch();
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  const addCurrentSelectedValueToSearchResults = async filteredResults => {
    const currentSelectedValue = filteredResults.find(
      result => result.value === values[field].target.value
    );

    if (!currentSelectedValue && values[field].target.value) {
      filteredResults.push({ label: values[field].target.text, value: values[field].target.value });
    }

    setSearchResults(filteredResults);
  };

  const fetchDirectories = async searchText => {
    if (requestDestinationsCancelTokenSource) {
      requestDestinationsCancelTokenSource.cancel();
    }
    requestDestinationsCancelTokenSource = CancelToken.source();
    setShowLoadingSpinner(true);
    const results = await dispatch(
      getAllForwardingTargets(enterpriseId, requestDestinationsCancelTokenSource.token, {
        search: searchText
      })
    );
    if (results) {
      addCurrentSelectedValueToSearchResults(results);
      setShowLoadingSpinner(false);
    }
  };

  useEffect(() => {
    requestDestinationsCancelTokenSource = CancelToken.source();
    const fetchTargets = async () => {
      if (values[field].target.value) {
        await fetchDirectories(values[field].target.value);
      }
    };
    fetchTargets();
    return () => {
      requestDestinationsCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const subtituteSelection = <SubstituteSelection field={field} substitutes={substitutes} />;
  const substituteSelected =
    values[field] &&
    values[field].target.type === 'INTERNAL' &&
    !!substitutes.find(sub => sub.addressNumber === values[field].target.value);

  const parseFieldValue = () => {
    if (values[field] && !values[field].target.type) {
      return 'ENT_VM';
    }
    if (substituteSelected) {
      return 'SUBSTITUTE';
    }
    if (
      values[field] &&
      values[field].target.type === 'INTERNAL' &&
      !substitutes.find(sub => sub.addressNumber === values[field].target.value)
    ) {
      return 'INTERNAL';
    }

    return values[field] ? values[field].target.type : '';
  };

  const radioOnChange = (type: string, value: string = '') => {
    setValue(
      field,
      {
        ...values[field],
        target: {
          type,
          value,
          text: ''
        }
      },
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );
  };

  const fieldValue = parseFieldValue();

  return (
    <div className={styles['forwarding-target-area']}>
      {errorMessage && (
        <div data-cy="forwarding-target-error" className={styles['specific-validation-error']}>
          {errorMessage}
        </div>
      )}
      <RadioGroup value={fieldValue}>
        <Radio
          id="forwarding-details-target-option-1"
          name="target-option"
          onChange={() => radioOnChange('ENT_VM')}
          label={t('forwardingTarget.targetToVoicemail')}
          value="ENT_VM"
        />
        <Radio
          id="forwarding-details-target-option-2"
          name="target-option"
          onChange={() => radioOnChange('ENT_RCPT')}
          label={t('forwardingTarget.targetToRcpt')}
          value="ENT_RCPT"
        />
        <Radio
          id="forwarding-details-target-option-sub"
          name="target-option"
          onChange={() => radioOnChange('INTERNAL', substitutes[0].addressNumber)}
          className={classnames({
            [styles.hidden]: enterpriseRule
          })}
          label={`${t('forwardingTarget.targetToSubstitute')}${
            substitutes && substitutes.length === 1 && substitutes[0].userName
              ? `: ${substitutes[0].userName}`
              : ''
          }`}
          value="SUBSTITUTE"
          disabled={!substitutes || R.isEmpty(substitutes)}
        />
        <Radio
          id="enterprise-forwarding-details-target-option-3"
          name="target-option"
          onChange={() => {
            radioOnChange('INTERNAL', '');
          }}
          label={
            <div className={styles['label-container']}>
              {t('forwardingTarget.targetToInternal')}
              <div className={styles.tooltip}>
                <Tooltip>{t('forwardingTarget.transferInternalTooltip')}</Tooltip>
              </div>
            </div>
          }
          value="INTERNAL"
        />
        <Radio
          id="enterprise-forwarding-details-target-option-4"
          name="target-option"
          onChange={() =>
            radioOnChange('EXTERNAL', values[field].type === 'EXTERNAL' ? values[field].value : '')
          }
          label={t('forwardingTarget.targetToExternal')}
          value="EXTERNAL"
        />
        <Radio
          id="enterprise-forwarding-details-target-option-5"
          name="target-option"
          onChange={() =>
            setValue(
              field,
              {
                ...values[field],
                target: {
                  type: 'REJECTION',
                  value: values[field].target.type === 'REJECTION' ? values[field].target.value : ''
                }
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            )
          }
          label={t('forwardingTarget.targetToRejection')}
          value="REJECTION"
        />
      </RadioGroup>
      {substituteSelected && substitutes && substitutes.length > 1 && subtituteSelection}

      {values[field].target.type === 'INTERNAL' &&
        !substitutes.find(sub => sub.addressNumber === values[field].target.value) && (
          <div>
            {showLoadingSpinner && <LoadingSpinner className={styles['loading-spinner']} />}
            <Combobox
              id="forwarding_search_internal"
              className={styles['search-area']}
              items={searchResults}
              selectedValue={values[field].target.value}
              i18n_combobox_helpText={t('callflows.acdForwardingField.transferInternalInfo')}
              onValueSelect={element => {
                setValue(
                  field,
                  {
                    ...values[field],
                    target: {
                      type: 'INTERNAL',
                      value: element.dataset.value,
                      text: element.innerText
                    }
                  },
                  {
                    shouldValidate: true,
                    shouldDirty: true
                  }
                );
              }}
              onOptionsShow={() => {
                fetchDirectories('');
              }}
              onValueChange={value => {
                setValue(
                  field,
                  {
                    ...values[field],
                    target: {
                      type: 'INTERNAL',
                      value: ''
                    }
                  },
                  {
                    shouldValidate: true,
                    shouldDirty: true
                  }
                );
                fetchDirectories(value);
              }}
            />
          </div>
        )}
      {values[field].target.type === 'EXTERNAL' && (
        <Input
          id="external-target-input"
          onValueChange={e =>
            setValue(
              field,
              {
                ...values[field],
                target: {
                  type: 'EXTERNAL',
                  value: e.currentTarget.value
                }
              },
              {
                shouldValidate: true,
                shouldDirty: true
              }
            )
          }
          defaultValue={values[field].target.value || ''}
          maxlength={PHONE_NUMBER_MAX_LENGTH}
          className={styles['external-target']}
        />
      )}
    </div>
  );
};

export default ForwardingTarget;
