// @flow

import React, { type Element } from 'react';
import * as R from 'ramda';
import { useFormContext } from 'react-hook-form';
import uuid from 'uuid';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { ExactPropsT } from '../../../../../../commonTypes';
import ConfirmButton from '../../../../../../components/Button/ConfirmButton';
import AudioFileSelection from './AudioFileSelection';
import type { IvrAudioT } from './CallflowAudioUtils';
import styles from './MultiAudiosSelection.module.scss';

type OwnPropsT = {
  field: string,
  description: string,
  enterpriseId: string,
  callflowId: string
};

export type PropsT = {|
  ...ExactPropsT<OwnPropsT>
|};

export type AudioFieldT = {
  id: string,
  initial: boolean,
  filename?: string,
  file?: File
};

export type MultiAudioFieldT = {
  [string]: AudioFieldT
};

export function MultiAudiosSelection(props: PropsT): Element<'div'> {
  const { field, enterpriseId, callflowId } = props;
  const { t } = useTranslation();
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();
  const audios: MultiAudioFieldT = values[field];

  const getAudioData = (audioName: string): IvrAudioT => {
    const filename = (audioName || '').split('.')[0];
    return {
      enterpriseId,
      callflowType: 'welcomeattendants',
      callflowId,
      audioType: 'name',
      filename: `${filename}.ogg`
    };
  };
  return (
    <div className={styles.container}>
      <div className={styles['field-section']}>
        {R.values(audios).map((audio, index) => {
          const audioFilename = audio.filename || '';
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`audio_resource_${index}_${audio.id}`}
              className={classnames(styles['audio-container'], {
                [styles['audio-container--empty']]: audio.filename === ''
              })}
            >
              <span className={styles.title}>
                {t('callflows.editWelcomeAttendantMessage.message', { index: index + 1 })}
              </span>

              <AudioFileSelection
                audio={getAudioData(audioFilename)}
                onChange={file => {
                  const filename = file.name;
                  const newValues = {
                    ...values[field],
                    [audio.id]: {
                      ...values[field][audio.id],
                      filename,
                      name: filename,
                      file
                    }
                  };
                  setValue(field, newValues);
                }}
                disabled={false}
                showInitialView={audio.initial}
                audioStyle={styles['audio-field']}
                dismissAction={() => {
                  setValue(field, R.omit([audio.id], values[field]), {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                }}
              />
            </div>
          );
        })}
        <ConfirmButton
          id="add-message-button"
          className={styles['add-button']}
          label={t('callflows.editWelcomeAttendantMessage.addAudioButton')}
          onClickAction={e => {
            e.preventDefault();
            const id = uuid();
            const modifiedAudios = R.assoc(
              id,
              {
                id,
                initial: false
              },
              values[field]
            );
            setValue(field, modifiedAudios, {
              shouldValidate: true,
              shouldDirty: true
            });
          }}
        />
      </div>
    </div>
  );
}

export default MultiAudiosSelection;
