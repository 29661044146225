import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTiktokRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.295 23.995c-1.62 0-3.18-.49-4.5-1.42a7.86 7.86 0 0 1-3.35-6.43c0-4.33 3.52-7.85 7.85-7.85.34 0 .68.02 1.06.08l.61.08v5.54l-.92-.29c-1.68-.53-3.31.78-3.31 2.44 0 .95.53 1.82 1.37 2.26a2.543 2.543 0 0 0 3.74-2.16V-.005h5.3v.71a4.71 4.71 0 0 0 2.14 3.94c.76.5 1.65.76 2.56.76h.71v5.3h-.71c-1.66 0-3.26-.4-4.7-1.17v6.62c0 4.33-3.52 7.85-7.85 7.85zm0-14.3c-3.55 0-6.44 2.89-6.44 6.44 0 2.09 1.03 4.07 2.75 5.27 1.09.76 2.36 1.17 3.69 1.17 3.55 0 6.44-2.89 6.44-6.44v-9.26l1.12.8c1.27.91 2.74 1.45 4.29 1.58v-2.48c-.93-.11-1.83-.43-2.62-.95a6.13 6.13 0 0 1-2.68-4c-.02-.14-.05-.27-.06-.41h-2.51v14.86c-.09 2.17-1.83 3.85-3.97 3.85-.64 0-1.28-.16-1.84-.46a3.97 3.97 0 0 1-2.13-3.51c0-2.27 1.96-4.12 4.23-3.96v-2.47h-.26z"/></svg>)}
    />
  );
}
  