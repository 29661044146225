import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBlockRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0m0 1.5c2.625 0 5.023.975 6.866 2.574L4.074 18.866A10.45 10.45 0 0 1 1.5 12C1.5 6.21 6.21 1.5 12 1.5m0 21c-2.625 0-5.023-.975-6.866-2.574L19.926 5.134A10.45 10.45 0 0 1 22.5 12c0 5.79-4.71 10.5-10.5 10.5"/></svg>)}
    />
  );
}
  