import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRemoveFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 13.2h-15c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h15c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2"/></svg>)}
    />
  );
}
  