// @flow

import * as R from 'ramda';
import type { EnterpriseActionT, EnterpriseStateT } from './enterpriseTypes';

export const initialState: EnterpriseStateT = {
  byId: {},
  allIds: [],
  __metadata: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: EnterpriseStateT = initialState,
  action: EnterpriseActionT
): EnterpriseStateT => {
  switch (action.type) {
    case 'ring/entity/enterprise/GET_ALL_REQUEST': {
      return {
        ...state,
        __metadata: {
          isRetrieving: true
        }
      };
    }
    case 'ring/entity/enterprise/GET_ALL_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          // $FlowFixMe
          ...action.payload.reduce(
            (enterprises, enterprise) => ({
              ...enterprises,
              [enterprise.entID]: enterprise
            }),
            {}
          )
        },
        allIds: R.union(
          state.allIds,
          action.payload.map(enterprise => enterprise.entID)
        ),
        __metadata: {}
      };
    }
    case 'ring/entity/enterprise/GET_ALL_FAILURE': {
      return {
        ...state,
        __metadata: {
          error: action.payload
        }
      };
    }
    case 'ring/entity/enterprise/GET_ALL_CANCEL': {
      return state;
    }
    case 'ring/entity/enterprise/GET_ONE_REQUEST': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            __metadata: {
              isRetrieving: true
            }
          }
        }
      };
    }
    case 'ring/entity/enterprise/GET_ONE_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.entID]: {
            ...action.payload,
            __metadata: {}
          }
        },
        allIds: state.allIds.includes(action.payload.entID)
          ? state.allIds
          : [...state.allIds, action.payload.entID]
      };
    }
    case 'ring/entity/enterprise/GET_ONE_CANCEL': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            __metadata: {}
          }
        }
      };
    }
    case 'ring/entity/enterprise/GET_ONE_FAILURE':
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            __metadata: {
              error: action.payload,
              hasLoadingError: true
            }
          }
        }
      };
    case 'ring/entity/enterprise/PATCH_FAILURE': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            __metadata: {
              error: action.payload
            }
          }
        }
      };
    }
    case 'ring/entity/enterprise/PATCH_REQUEST': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            __metadata: {
              isPutting: true
            }
          }
        }
      };
    }
    case 'ring/entity/enterprise/PATCH_SUCCESS': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state[action.meta.id],
            entID: action.meta.id,
            fullName: action.payload.fullName,
            __metadata: {}
          }
        }
      };
    }
    case 'ring/entity/enterprise/PATCH_CANCEL': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...state.byId[action.meta.id],
            __metadata: {}
          }
        }
      };
    }
    default:
      return state;
  }
};
