import React from 'react';
import IconContainer from '../IconContainer';

export default function IconErrorLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m0 23C5.9 23 1 18.1 1 12S5.9 1 12 1s11 4.9 11 11-4.9 11-11 11m-.5-16v6.5c0 .3.2.5.5.5s.5-.2.5-.5V7c0-.3-.2-.5-.5-.5s-.5.2-.5.5m.5 10.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8.8.3.8.8-.4.8-.8.8"/></svg>)}
    />
  );
}
  