import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileTxtLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.6 5.9 15.2.5c-.3-.3-.7-.5-1.1-.5H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.9c0-.4-.2-.8-.4-1m-1.3.1h-3.8c-.3 0-.5-.2-.5-.5V1.7zM19 23H5c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h9v4.5c0 .8.7 1.5 1.5 1.5H20v15c0 .6-.5 1-1 1M8.5 19.8h-1v-3.7H6.4v-.8h3.4v.8H8.5zm5.6 0H13l-1-1.7-1 1.7h-1l1.5-2.3-1.5-2.2h1l1 1.6 1-1.6h1l-1.4 2.2zm2.3 0h-1v-3.7h-1.2v-.8h3.4v.8h-1.2z"/></svg>)}
    />
  );
}
  