// @flow

import axios, { type CancelToken, type AxiosPromise } from 'axios';
import type { ThunkActionT } from '../../../commonTypes';
import type { WelcomeAttendantUpdateEntityT } from '../callFlow/callFlowTypes';
import {
  createUpdateWelcomeAttendantCancel,
  createUpdateWelcomeAttendantFailure,
  createUpdateWelcomeAttendantRequest,
  createUpdateWelcomeAttendantSuccess
} from './welcomeAttendantActions';
import type {
  UpdateWelcomeAttendantActionT,
  WelcomeAttendantEntityT
} from './welcomeAttendantTypes';

export type UpdateWelcomeAttendantT = (
  string,
  string,
  WelcomeAttendantUpdateEntityT,
  CancelToken,
  headers: {}
) => ThunkActionT<UpdateWelcomeAttendantActionT>;

export const updateWelcomeAttendant: UpdateWelcomeAttendantT = (
  enterpriseId,
  welcomeAttendantId,
  welcomeAttendantUpdateData,
  cancelToken,
  headers
) => async dispatch => {
  dispatch(createUpdateWelcomeAttendantRequest(enterpriseId, welcomeAttendantId));
  try {
    const response: AxiosPromise<WelcomeAttendantEntityT> = axios({
      method: 'PATCH',
      url: `/api/v1/enterprises/${enterpriseId}/services/welcomeattendants/${welcomeAttendantId}`,
      cancelToken,
      data: welcomeAttendantUpdateData,
      headers
    });
    // $FlowFixMe
    const { data } = await response;
    dispatch(createUpdateWelcomeAttendantSuccess(enterpriseId, data));
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(createUpdateWelcomeAttendantCancel(enterpriseId, welcomeAttendantId));
    } else {
      dispatch(createUpdateWelcomeAttendantFailure(enterpriseId, welcomeAttendantId, error));
    }
    return undefined;
  }
};

const welcomeAttendantOperations = {
  updateWelcomeAttendant
};

export default welcomeAttendantOperations;
