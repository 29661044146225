import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCalendarTimeFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.5 17.5c0 .4-.3.8-.8.8H2.2C1 18.2 0 17.2 0 16V4c0-1.2 1-2.2 2.2-2.2h1c.1 0 .2.1.2.2v2.4c.1.3.4.6.7.6s.6-.3.6-.6V.8c.1-.5.4-.8.8-.8s.8.3.8.8v1h4c.1 0 .2.1.2.2v2.4c0 .3.3.6.6.6s.6-.3.6-.6V.8c0-.4.3-.8.8-.8s.8.3.8.8v1h2.5C17 1.8 18 2.8 18 4v4.8c0 .4-.3.8-.8.8s-.8-.3-.8-.8V8h-15v8c0 .4.3.8.8.8h6.5c.5 0 .8.3.8.7m10.7-.7h-2v-2c0-.4-.3-.8-.8-.8s-.8.3-.8.8v2.8c0 .4.3.8.8.8h2.8c.4 0 .8-.3.8-.8s-.3-.8-.8-.8m3.8.7c0 3.6-2.9 6.5-6.5 6.5S11 21.1 11 17.5s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5m-1.5 0c0-2.8-2.2-5-5-5s-5 2.2-5 5 2.2 5 5 5 5-2.2 5-5"/></svg>)}
    />
  );
}
  