import { createContext } from 'react';

const RadioGroupContext = createContext({
  /** @type {null|string} Selected values */
  errorMessage: null,
  setErrorMessage: () => {},

  /** @type {null|boolean} Selected values */
  disabledValue: false,
  setDisabledValue: () => {},

  /** @type {null|string} Selected values */
  selectedValue: null,
  setSelectedValue: () => {}
});

export default RadioGroupContext;