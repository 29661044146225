import React from 'react';
import IconContainer from '../IconContainer';

export default function IconElisaKirja(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M11.8 20.9c-.1 0-.1-.1-.2-.1-1.6-.9-4.1-2.2-9.8-2.3-.4 0-.8-.4-.8-.8V1.8c0-.5.4-.8.8-.8.8 0 7.9 1.1 10 1.9.2.1.3.1.5 0 1.6-.9 4.1-1.8 10-1.9.3 0 .7.3.7.7v15.9c0 .4-.3.7-.8.8-5.7.1-8.2 1.4-9.8 2.3-.1 0-.1.1-.2.1-.1.1-.3.1-.4.1m3.6-2.8c-1.5.5-2.6 1-3.4 1.5-.8-.4-1.9-1-3.4-1.4-1.6-.6-3.6-1-6.3-1V2.3c.2 0 .6.1.9.1.8.2 1.8.3 2.9.5s2.1.4 3.1.6q1.5.3 2.1.6c.5.2 1.1.2 1.6-.1 1.3-.7 3.5-1.6 8.9-1.8v14.9c-2.8.2-4.8.5-6.4 1M1 20.7c0-.4.4-.7.8-.7 4.6 0 8.2 1.6 10 2.3.1.1.3.1.4 0h.1c1.8-.8 5.1-2.3 9.9-2.3.4 0 .7.3.7.7s-.3.7-.7.7c-4.5 0-7.6 1.4-9.3 2.2l-.6.3q-.3.15-.6 0c-.2-.1-.4-.2-.6-.2-1.8-.8-5.1-2.2-9.4-2.2-.3 0-.7-.3-.7-.8M12 5.5c-2.9 0-5.2 2.3-5.2 5.1 0 .5.1.9.1 1.4l.7 2.6c.3 1 1.3 1.6 2.3 1.3.6-.2 1-.8.8-1.4l-.5-2c-.2-.7-.9-1.1-1.5-.9l-.7.2c-.1-.4-.1-.8-.1-1.1 0-2.3 1.9-4.1 4.2-4.1 2.4 0 4.2 1.9 4.2 4.1 0 .4 0 .8-.1 1.1l-.7-.2c-.7-.2-1.3.2-1.5.9l-.5 2c-.2.6.2 1.2.8 1.4 1 .3 2-.3 2.3-1.3l.7-2.6c.1-.4.1-.9.1-1.4-.2-2.8-2.5-5.1-5.4-5.1"/></svg>)}
    />
  );
}
  