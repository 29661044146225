import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGamingFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 7h-15A4.51 4.51 0 0 0 0 11.5v10.42A2.08 2.08 0 0 0 2.08 24h.37c1.36 0 1.55-.25 2.78-2.55.43-.78 1-1.82 1.82-3.21A2.52 2.52 0 0 1 9.21 17h5.58a2.52 2.52 0 0 1 2.16 1.23c.81 1.4 1.39 2.45 1.81 3.23 1.26 2.29 1.4 2.54 2.85 2.54h.27A2.12 2.12 0 0 0 24 21.88V11.5A4.51 4.51 0 0 0 19.5 7m-12 6.12H6.12v1.38a.62.62 0 0 1-1.24 0v-1.38H3.5a.63.63 0 0 1 0-1.25h1.38v-1.371a.62.62 0 0 1 1.24 0v1.37H7.5a.63.63 0 0 1 .62.63.62.62 0 0 1-.62.621m9.75 1.505c-.482 0-.875-.393-.875-.875s.393-.875.875-.875.875.393.875.875-.393.875-.875.875m2.5-2.5c-.482 0-.875-.393-.875-.875s.393-.875.875-.875.875.393.875.875-.393.875-.875.875M5.75.754a.76.76 0 0 1 .75-.75h3a3.26 3.26 0 0 1 3.25 3.25V5.25a.75.75 0 0 1-1.5 0V3.254a1.76 1.76 0 0 0-1.75-1.75h-3a.76.76 0 0 1-.75-.75"/></svg>)}
    />
  );
}
  