// @flow strict-local

import React, { type Element } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import InfoBox from '../../../../../components/InfoBox';
import styles from './ForwardingEnterpriseInfoBox.module.scss';

type PropsT = {||};

// eslint-disable-next-line no-unused-vars
export const ForwardingEnterpriseInfoBox = (props: PropsT): Element<typeof InfoBox> => {
  const { t } = useTranslation();

  return (
    <InfoBox extraStyle={styles['info-box']}>
      <div id="fwd-info-enterprise" className={styles.title}>
        {parse(
          `${t('forwardingDetails.enterpriseForwarding.title1')}<b>${t(
            'forwardingDetails.enterpriseForwarding.title2'
          )}</b>`
        )}
      </div>
    </InfoBox>
  );
};

export default ForwardingEnterpriseInfoBox;
