import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMaintenanceFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m19.527 7.243 3.222-3.222a.506.506 0 0 1 .831.192 6.42 6.42 0 0 1 .04 4.459 6.4 6.4 0 0 1-3.319 3.643 6.38 6.38 0 0 1-4.617.33L5.229 23.104A3.05 3.05 0 0 1 3.063 24a3.062 3.062 0 0 1-3.012-3.636c.116-.639.467-1.213.926-1.673L11.356 8.319a6.44 6.44 0 0 1 .507-4.966A6.4 6.4 0 0 1 15.7.262a6.4 6.4 0 0 1 4.096.161c.344.131.446.57.186.83L16.76 4.475a.25.25 0 0 0-.073.177v2.414c0 .138.112.25.25.25h2.413c.066 0 .13-.027.177-.073"/></svg>)}
    />
  );
}
  