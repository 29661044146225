// @flow
import React, { type Element } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import CallflowDetails from '../../../../components/view/CallflowDetails';
import type {
  InteractionEntityT,
  WelcomeAttendantEntityT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import CallflowDetailsColumn from '../../../../components/view/CallflowDetailsColumn';
import CallflowItem from '../../../../components/view/children/CallflowItem';
import type { PropsT } from '../message/MessageDetails';
import { ReactComponent as DialPadSmall } from '../../../../../../assets/callflow/details/dialpad-small.svg';
import WelcomeAttendantAudio from '../message/WelcomeAttendantAudio';
import type { IvrAudioT } from '../../../../components/edit/children/audio/CallflowAudioUtils';
import CallflowList from '../../../../components/view/children/CallflowList';

export const InteractionDetails = (props: PropsT): Element<typeof CallflowDetails> => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  // redux
  const welcomeAttendant: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  // $FlowFixMe InteractionNode should always exists
  const interactionNode: InteractionEntityT =
    R.path(['commands', commandId], welcomeAttendant) || {};

  const getAudioData = (audioName: string): IvrAudioT => ({
    enterpriseId: welcomeAttendant.enterpriseId,
    callflowType: 'welcomeattendants',
    callflowId: welcomeAttendant.id,
    audioType: 'name',
    filename: audioName
  });

  const createTextPresentation = (text: string): string => {
    if (text === 'END' || text === 'RUN2') {
      return t(`callflows.nodes.${text}`);
    }
    return text;
  };

  const choiceValues = interactionNode.choices.map(
    e =>
      `#${e.choice}, ${createTextPresentation(
        e.nextState && e.nextState.state ? e.nextState.state : ''
      )}`
  );

  const audios = interactionNode.audios || [];
  return (
    <CallflowDetails
      enterpriseId={welcomeAttendant.enterpriseId}
      callflowId={welcomeAttendant.id}
      elementType="WELCOME_ATTENDANT"
      nodeId={interactionNode.name.toUpperCase()}
      nodeType={interactionNode.type}
      icon={<DialPadSmall />}
      title={t('callflows.viewWelcomeAttendantInteraction.title')}
      active
    >
      <CallflowDetailsColumn id="wa-interaction-column-1">
        <CallflowItem
          id={`step-name-${interactionNode.name}`}
          title={t('callflows.welcomeAttendantGeneric.stepName')}
          value={interactionNode.name}
        />
        {!audios ? (
          <CallflowItem
            id={`${welcomeAttendant.id}_audio_not_set`}
            title={t('callflows.viewWelcomeAttendantInteraction.audio')}
            value={t('callflows.valueNotSet')}
          />
        ) : (
          audios.map(audio => (
            <WelcomeAttendantAudio
              key={`welcomeattendant-audio-${audio}`}
              title={t('callflows.viewWelcomeAttendantInteraction.audio')}
              audioPlayData={getAudioData(audio)}
            />
          ))
        )}
        <CallflowList
          id={`welcomeAttendant-dialSelection-${interactionNode.name}`}
          title={t('callflows.viewWelcomeAttendantInteraction.dialSelection')}
          values={choiceValues}
        />

        <CallflowItem
          id={`retryState-${interactionNode.name}`}
          title={t('callflows.viewWelcomeAttendantInteraction.retryState')}
          value={
            interactionNode.retryState && interactionNode.retryState.state
              ? interactionNode.retryState.state
              : t('callflows.valueNotSet')
          }
        />

        <CallflowItem
          id={`retryCount-${interactionNode.name}`}
          title={t('callflows.viewWelcomeAttendantInteraction.retryCount')}
          value={`${interactionNode.retryCount}`}
        />
      </CallflowDetailsColumn>
    </CallflowDetails>
  );
};

export default InteractionDetails;
