// @flow

import classnames from 'classnames';
import React, { type Element } from 'react';
import Truncate from 'react-truncate-markup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import type { ExactPropsT } from '../../commonTypes';
import { ReactComponent as CheckmarkWhitePic } from '../../assets/checkmark-white-large.svg';
import { ReactComponent as CheckmarkBluePic } from '../../assets/checkmark-blue-large.svg';
import styles from './EnterpriseCard.module.scss';

type OwnPropsT = {
  id: string,
  label: ?string,
  isSelected: boolean,
  onSelect: () => *,
  onInspect: () => *,
  className: string
};

type StatePropsT = {};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;

export const EnterpriseCard = ({
  id,
  label,
  isSelected,
  onSelect,
  onInspect,
  className
}: PropsT): Element<'div'> => {
  const { t } = useTranslation();
  return (
    <div
      id={id}
      className={classnames({
        [styles.card]: true,
        [styles.selected]: isSelected,
        [className]: className !== ''
      })}
    >
      <div className={styles.content}>
        {isSelected && (
          <div className={styles['selected-check']}>
            <CheckmarkBluePic />
          </div>
        )}
        <img className={styles.logo} src="/enterprise-icon-card.svg" alt="organisation logo" />
        {label && (
          <Truncate lines={2}>
            <div className={styles.label}>{label}</div>
          </Truncate>
        )}
        <div
          id={`select-enterprise-button-${id}`}
          className={styles.overlay}
          role="button"
          onClick={onSelect}
          onKeyPress={onSelect}
          tabIndex={0}
        >
          <p>
            <span className={styles['hover-check']}>
              <CheckmarkWhitePic />
            </span>
            {isSelected
              ? t('enterprises.enterprise.selectedLabel')
              : t('enterprises.enterprise.selectButtonLabel')}
          </p>
        </div>
      </div>
      <div
        id={`inspect-enterprise-button-${id}`}
        role="button"
        className={styles['inspect-button']}
        onClick={onInspect}
        onKeyPress={onInspect}
        tabIndex={-1}
      >
        <p>{t('enterprises.enterprise.inspectButtonLabel')}</p>
      </div>
    </div>
  );
};

EnterpriseCard.defaultProps = {
  className: '' // eslint-disable-line
};

const mapStateToProps = () => ({});

export default compose(connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps))(EnterpriseCard);
