import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHealthLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.996 10.504a.5.5 0 0 1-.5.5h-4.258a.85.85 0 0 1-.79-.46l-.92-1.9-2.259 5.799a.82.82 0 0 1-.42.46.83.83 0 0 1-.66 0 .87.87 0 0 1-.51-.43L7.93 11.004H4.503a.5.5 0 0 1 0-1h3.498a.89.89 0 0 1 .78.51l1.669 3.329 2.259-5.789a.84.84 0 0 1 .46-.48.9.9 0 0 1 .67 0c.198.081.361.23.46.42l1 2h4.198a.5.5 0 0 1 .5.5zm2.159 1.749L12.36 22.351a.51.51 0 0 1-.72 0L1.845 12.253c-2.459-2.46-2.459-6.448 0-8.908s6.447-2.46 8.906 0L12 4.595l1.249-1.25c2.459-2.46 6.447-2.46 8.906 0s2.46 6.448 0 8.908m.28-6.818a5.297 5.297 0 0 0-8.436-1.38l-1.609 1.6a.48.48 0 0 1-.678.022l-.022-.022-1.599-1.6a5.32 5.32 0 0 0-3.798-1.55 5 5 0 0 0-.84.07 5.299 5.299 0 0 0-2.899 8.998L12 21.281l9.436-9.728a5.3 5.3 0 0 0 .999-6.118"/></svg>)}
    />
  );
}
  