// @flow strict-local

import React, { Component, type Element } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import {
  type CallFlowEntityT,
  selectors as callFlowSelect
} from '../../../ducks/entities/callFlow';
import type { StoreStateT } from '../../../commonTypes';
import Table from '../../../components/Table/Table';
import type { TableHeaderColumnT } from '../../../components/Table/TableHeader';
import type { TableRowItemT } from '../../../components/Table/TableRow';
import LinkButton from '../../../components/Button/LinkButton';
import CallFlowActivity from '../components/CallFlowActivity';
import { goToCallFlow } from '../../../navigationOperations';
import { getPnNumbers } from '../../../ducks/entities/callFlow/callFlowSelectors';
import type { CallFlowTypeT } from '../../../ducks/entities/callFlow/callFlowTypes';
import { getLoadGroupStatus } from '../components/CallFlowActivityUtils';
import type { MapItemToCellFnT } from '../../../components/Table/Table';
import styles from './CallFlowList.module.scss';

type OwnPropsT = {
  enterpriseId: string,
  isSmallTable: boolean,
  selectedCategory?: CallFlowTypeT,
  searchTerm?: string,
  loadMore: (page: number) => Promise<void>
};

type StatePropsT = {
  callflows: CallFlowEntityT[],
  isRetrieving: boolean
};

type DispatchPropsT = {
  goToCallFlow: typeof goToCallFlow
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

export class CallFlowList extends Component<PropsT> {
  constructor(props: PropsT) {
    super(props);
    this.createTableRowItems = this.createTableRowItems.bind(this);
    this.getType = this.getType.bind(this);
    this.getName = this.getName.bind(this);
  }

  getType: CallFlowEntityT => string;

  getType(data: CallFlowEntityT) {
    const { t } = this.props;
    switch (data.type) {
      case 'ACD_CUSTOMER_SERVICE':
      case 'ACD_SWITCHBOARD': {
        let callflowName = t('callflows.type.acd.defaultName');
        if (data.label) {
          // $FlowFixMe: flow null check bug
          callflowName = data.label.startsWith('kutsu:') ? data.label.slice(6) : data.label;
        }
        return callflowName;
      }
      case 'OC':
        return data.label || t('callflows.callflowType.OC');
      case 'PLAY_MUSIC':
        return data.label || t('callflows.type.playMusic.defaultName');
      case 'EXTENSION_GROUP':
        return data.displayName || t('callflows.type.extensionGroup.defaultName');
      case 'SPEED_DIAL':
        return data.label || t('callflows.type.speedDial.defaultName');
      case 'WELCOME_ATTENDANT':
        return data.label || t('callflows.type.welcomeAttendant.defaultName');
      default:
        return undefined;
    }
  }

  getName: CallFlowEntityT => string;

  getName(data: CallFlowEntityT) {
    const { t, isSmallTable } = this.props;
    switch (data.type) {
      case 'ACD_CUSTOMER_SERVICE':
      case 'ACD_SWITCHBOARD':
        return !isSmallTable
          ? t(`callflows.type.${data.type}.title`)
          : t(`callflows.type.${data.type}.smalltitle`, {
              number: CallFlowList.getAllNumbers(data, ', ')
            });
      case 'PLAY_MUSIC':
        return !isSmallTable
          ? t('callflows.type.playMusic.title')
          : t('callflows.type.playMusic.smalltitle', {
              number: CallFlowList.getAllNumbers(data, ', ')
            });
      case 'EXTENSION_GROUP':
        return !isSmallTable
          ? t('callflows.type.extensionGroup.title')
          : t('callflows.type.extensionGroup.smalltitle', {
              number: CallFlowList.getAllNumbers(data, ', ')
            });
      case 'SPEED_DIAL':
        return t('callflows.type.speedDial.title');
      case 'OC':
        return t('callflows.callflowType.OC');
      case 'WELCOME_ATTENDANT':
        return !isSmallTable
          ? t('callflows.type.welcomeAttendant.title')
          : t('callflows.type.welcomeAttendant.smalltitle', {
              number: CallFlowList.getAllNumbers(data, ', ')
            });
      default:
        return undefined;
    }
  }

  static getAllNumbers(callflow: CallFlowEntityT, delimeter: string = ' ') {
    let numbers = getPnNumbers(callflow, delimeter);
    if (numbers) {
      numbers += delimeter;
    }
    numbers += callflow.addressNumber || '';
    return numbers;
  }

  createTableRowItems: () => TableRowItemT[];

  createTableRowItems(): TableRowItemT[] {
    const { callflows, t, selectedCategory } = this.props;
    const filteredCallFlows = (callflows || [])
      .filter(cf => cf != null)
      .filter(cf => selectedCategory === cf.type);

    return filteredCallFlows.map(callflow => ({
      id: callflow.id,
      type: callflow.type,
      rowId: callflow.id,
      name: this.getType(callflow),
      number: CallFlowList.getAllNumbers(callflow),
      activity: <CallFlowActivity translate={t} loadGroupStatus={getLoadGroupStatus(callflow)} />
    }));
  }

  renderSmallTable(): Element<typeof InfiniteScroll> {
    const {
      callflows,
      t,
      goToCallFlow, // eslint-disable-line no-shadow
      loadMore,
      selectedCategory
    } = this.props;

    /* eslint-disable react/no-array-index-key */
    return (
      <InfiniteScroll
        hasMore={false}
        loadMore={loadMore}
        initialLoad={false}
        pageStart={1}
        key={selectedCategory}
      >
        {callflows.map((callflow, index) => (
          <div
            key={`smallRow_${index}`}
            className={styles['small-row']}
            onClick={() => goToCallFlow(callflow.enterpriseId, callflow.id, callflow.type)}
            role="button"
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                goToCallFlow(callflow.enterpriseId, callflow.id, callflow.type);
              }
            }}
          >
            <div className={styles.type}>
              <div>
                <div className={styles['name-container']}>
                  <CallFlowActivity
                    translate={t}
                    loadGroupStatus={getLoadGroupStatus(callflow)}
                    isSmall
                  />
                </div>
                <div className={styles['name-container']}>{this.getType(callflow)}</div>
              </div>
              <LinkButton
                id={`${callflow.id}-linkButton`}
                className={styles.link}
                onClickAction={() =>
                  goToCallFlow(callflow.enterpriseId, callflow.id, callflow.type)
                }
                label={t('callflows.table.linkLabel')}
              />
            </div>
            <div className={styles.name}>{this.getName(callflow)}</div>
          </div>
        ))}
      </InfiniteScroll>
    );
  }

  render() {
    const {
      t,
      isSmallTable,
      enterpriseId,
      goToCallFlow, // eslint-disable-line no-shadow
      loadMore,
      selectedCategory
    } = this.props;

    const itemToCell: MapItemToCellFnT = ({ columnId, size, border = true }, item) => ({
      value: item[columnId] || '',
      size,
      border,
      valueClasses: columnId === 'number' ? [styles['number-cell']] : []
    });
    const tableHeaderColumns: TableHeaderColumnT[] = [
      {
        columnId: 'name',
        text: t('callflows.table.nameHeaderLabel'),
        size: 'medium'
      },
      {
        columnId: 'number',
        text: t('callflows.table.numberHeaderLabel'),
        size: 'medium'
      },
      {
        columnId: 'activity',
        text: t('callflows.table.activityHeaderLabel'),
        size: 'large'
      }
    ];
    return (
      <div className={styles.table}>
        {!isSmallTable ? (
          <Table
            id="callFlowsTable"
            items={this.createTableRowItems()}
            columns={tableHeaderColumns}
            allowLoadMore={false}
            onInfiniteScrollLoadMore={loadMore}
            linkName={t('callflows.table.linkLabel')}
            // $FlowFixMe
            linkFunction={([id, type]) => goToCallFlow(enterpriseId, id, type)}
            key={selectedCategory}
            mapItemToCell={itemToCell}
            borderBottomClass={styles['border-bottom']}
          />
        ) : (
          this.renderSmallTable()
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => {
  const { enterpriseId, searchTerm, selectedCategory } = ownProps;
  return {
    callflows: callFlowSelect
      .getCallFlowsByEnterpriseIdAndSearchFilter(state, enterpriseId, searchTerm, selectedCategory)
      .sort((a, b) => parseInt(a.addressNumber, 10) - parseInt(b.addressNumber, 10)),
    isRetrieving: callFlowSelect.isRetrieving(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToCallFlow
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(CallFlowList);
