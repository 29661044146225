// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import type { LocationEntityT } from '../../../ducks/entities/location/locationTypes';
import Dismiss from '../../../components/Button/Dismiss';
import SiteDetails from './locationDetails/SiteDetails';
import styles from './Sites.module.scss';

export type PropsT = {|
  onClose: () => void,
  onDelete: () => void,
  onUpdate: LocationEntityT => Promise<void>,
  location: ?LocationEntityT,
  newLocation: boolean,
  enterpriseId: string
|};

export const Sites = (props: PropsT) => {
  const { location, onClose, newLocation } = props;
  const { t } = useTranslation();
  const topicTabs = (
    <div>
      <h2>{location ? location.name : ''}</h2>
    </div>
  );
  return (
    <div className={styles['site-container--view']}>
      <Dismiss
        className={styles['dismiss-button']}
        id="dismiss-view-site-modal"
        onClose={onClose}
      />
      {newLocation ? <h2>{t('sites.newLocation')}</h2> : topicTabs}
      {
        // $FlowFixMe
        <SiteDetails {...props} />
      }
    </div>
  );
};

export default Sites;
