// @flow

import React, { type Element } from 'react';
import classNames from 'classnames';
import Popover from '@design-system/component-library/src/components/Popover';
import IconMoreFilled from '@design-system/component-library/src/components/Icon/lib/IconMoreFilled';
import { useTranslation } from 'react-i18next';
import type { CallForwardingT } from '../../ducks/entities/callForwarding/callForwardingTypes';
import { PhoneLargeAndDown, TabletAndUp } from '../../Responsive';
import { getTextRepresentation } from '../../helpers';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import type { TwoPartForwardingTextRepresentationTypeT } from '../../helpers';
import type { DirectoryStateEntityT } from '../../ducks/entities/directory';
import ForwardingPopOverSelector from '../../scenes/callForwardings/components/ForwardingPopOverSelector';

import styles from './Reason.module.scss';

export type PropsT = {|
  userForwarding: CallForwardingT,
  enterpriseId: string,
  showMenuIcon?: boolean,
  user: ?DirectoryStateEntityT,
  forceSmall?: boolean,
  onForwardingActivityChange: (*, *) => Promise<void>,
  onDeleteForwarding: () => void
|};

// PopUp is visible for small screens
const Reason = (props: PropsT): Element<'div'> => {
  const {
    userForwarding,
    showMenuIcon,
    user,
    forceSmall,
    enterpriseId,
    onForwardingActivityChange,
    onDeleteForwarding
  } = props;

  const { t } = useTranslation();
  // $FlowFixMe
  const texts: TwoPartForwardingTextRepresentationTypeT = getTextRepresentation(
    userForwarding,
    'twoPart',
    t
  );

  const valueRef = React.createRef();
  if (forceSmall) {
    return <div className={styles['text-area']}>{texts.conditions}</div>;
  }

  const label = userForwarding.label ? userForwarding.label : t('forwardings.noLabel');

  return (
    <div className={styles['reasons-row']}>
      <TabletAndUp>
        <div ref={valueRef}>
          <span
            className={classNames(styles['text-label'], {
              [styles['text-label--missing']]: !userForwarding.label
            })}
          >
            {label}
          </span>
          <span className={styles['text-area']}>{texts.conditions}</span>
          {texts.timeFilter && (
            <div>
              <span className={styles['text-area']}>
                {`${t('forwardings.activeAt')} ${texts.timeFilter}`}
              </span>
            </div>
          )}
        </div>
        <ArrowIcon style={{ marginTop: '6px', minWidth: '14px' }} />
      </TabletAndUp>
      <PhoneLargeAndDown>
        <div>
          <div className={styles['text-label']}>{label}</div>
          <div className={styles['text-area']}>{texts.conditions}</div>
        </div>
        {showMenuIcon && (
          <Popover
            triggerElement={<IconMoreFilled size="m" />}
            placement="bottom"
            i18n_popover_triggerAriaLabel="Action"
          >
            <ForwardingPopOverSelector
              enterpriseId={enterpriseId}
              activeForwarding={{ forwarding: userForwarding, user }}
              onForwardingActivityChange={onForwardingActivityChange}
              onDeleteForwarding={onDeleteForwarding}
            />
          </Popover>
        )}
      </PhoneLargeAndDown>
    </div>
  );
};

export default Reason;
