// @flow

/* eslint-disable-file, no-unused-vars, no-underscore-dangle */

import * as R from 'ramda';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import Input from '@design-system/component-library/src/components/Input';
import { CancelToken, CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import React, { Component, type Element } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import BaseContainer from '../BaseContainer/BaseContainer';
import type { StoreStateT } from '../../commonTypes';
import delayBooleanTransitionToTrue from '../../components/delayBooleanTransitionToTrue';
import EnterpriseCard from './EnterpriseCard';
import GridModeIcon from '../../components/TableIcons/GridModeIcon';
import TableModeIcon from '../../components/TableIcons/TableModeIcon';
import Option from '../../components/OptionGroup/Option';
import OptionGroup from '../../components/OptionGroup/OptionGroup';
import Table from '../../components/Table/Table';
import { type TableHeaderColumnT } from '../../components/Table/TableHeader';
import { type TableRowItemT } from '../../components/Table/TableRow';
import {
  createDisableFirstTimeViewAction,
  createSelectEnterpriseAction
} from '../../ducks/currentUser/currentUserActions';
import type { CurrentUserT, EnterpriseSimpleT } from '../../ducks/currentUser/currentUserTypes';
import {
  type EnterpriseStateEntityT,
  operations as enterpriseOps,
  selectors as enterpriseSelect
} from '../../ducks/entities/enterprise';
import { type RetrieveEnterpriseCollectionRequestMetaT } from '../../ducks/entities/enterprise/enterpriseTypes';
import {
  createStartTourAction,
  type CreateStartTourFnT,
  createSetStepsAction,
  type CreateSetStepsFnT
} from '../../ducks/ui/tour/tourActions';
import { goToEnterprise } from '../../navigationOperations';
import { INPUT_DEBOUNCE_DURATION } from '../../constants';
import LinkButton from '../../components/Button/LinkButton';
import GenericError from '../../components/Error/GenericError';
import { createCloseUsersImportAction } from '../../ducks/ui/header/headerUiActions';
import { ReactComponent as NoSearchResultsPic } from '../../assets/no-search-results.svg';
import { ReactComponent as CheckMarkPic } from '../../assets/checkmark-dark-grey.svg';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import type { MapItemToCellFnT } from '../../components/Table/Table';
import FluidGrid from '../../components/FluidGrid/FluidGrid';
import FluidGridItem from '../../components/FluidGrid/FluidGridItem';
import TopNavigation from '../navigation/TopNavigation';
import styles from './Enterprises.module.scss';

export const ENTERPRISES_PAGE_SIZE = 2000;

type OwnPropsT = {
  noDebounce?: boolean
};

type StatePropsT = {
  enterprises: EnterpriseStateEntityT[],
  currentUser: CurrentUserT,
  selectedEnterprise: ?EnterpriseSimpleT,
  hasMore: ?boolean,
  hasEnterprises: boolean,
  isLoadingEnterprises: boolean,
  firstTimeView: boolean,
  loadingError: boolean
};

type DispatchPropsT = {
  disableFirstTimeView: () => *,
  selectEnterprise: (string, string) => *,
  getEnterprises: (CancelToken, RetrieveEnterpriseCollectionRequestMetaT) => *,
  startTour: CreateStartTourFnT,
  setTourSteps: CreateSetStepsFnT,
  goToEnterprise: string => *,
  resetSearch: () => *,
  closeUsersImport: () => *,
  retrieveEnterprise: (id: string, cancelToken: CancelToken) => *
};

export type StateT = {
  previousSearch: ?CancelTokenSource,
  viewMode: 'table' | 'grid',
  searchTerm: string,
  selectedEnterpriseName: string,
  readyForResults: boolean,
  innerWidth: number
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};
export class Enterprises extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props);
    this.state = {
      previousSearch: undefined,
      viewMode: 'table',
      searchTerm: '',
      selectedEnterpriseName: '',
      readyForResults: false,
      innerWidth: 0
    };
    this.handleSearchInputChanged = this.props.noDebounce
      ? this.handleSearchInputChanged.bind(this)
      : debounce(this.handleSearchInputChanged.bind(this), INPUT_DEBOUNCE_DURATION);
    this.handleSearchInputCleared = this.handleSearchInputCleared.bind(this);
    this.handleEnterpriseSelected = this.handleEnterpriseSelected.bind(this);
    this.retrieveEnterprises = this.retrieveEnterprises.bind(this);
    this.retrieveMoreEnterprises = this.retrieveMoreEnterprises.bind(this);
    this.retrieveMoreEnterprisesCancelTokenSource = CancelToken.source();
    this.retrieveEnterpriseRequestCancelTokenSource = CancelToken.source();
    this.showTableView = this.showTableView.bind(this);
    this.showGridView = this.showGridView.bind(this);
    this.showTour = this.showTour.bind(this);
    this.handleOnResize = this.handleOnResize.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    const { closeUsersImport } = this.props;
    window.addEventListener('resize', this.handleOnResize);
    this.handleOnResize();
    closeUsersImport();
    this.retrieveEnterprises();
  }

  componentDidUpdate(oldProps: PropsT, oldState: StateT) {
    if (oldState.searchTerm !== this.state.searchTerm) {
      this.retrieveEnterprises();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleOnResize);
    this.cancelCollectionRequests();
    this.retrieveMoreEnterprisesCancelTokenSource.cancel();
    this.props.disableFirstTimeView();
    this.mounted = false;
  }

  mounted: boolean;

  cancelCollectionRequests() {
    if (this.state.previousSearch) {
      this.state.previousSearch.cancel();
    }
  }

  handleOnResize: () => void;

  handleOnResize() {
    const { innerWidth } = window;
    this.setState({ innerWidth });
  }

  showTour: () => void;

  showTour(): void {
    const { t, setTourSteps, startTour, hasEnterprises } = this.props;

    const steps = [
      {
        disableBeacon: true,
        title: t('tours.enterprisesTourSteps.step3.title'),
        content: t('tours.enterprisesTourSteps.step3.text'),
        target: '#enterpriseName'
      }
    ];
    if (hasEnterprises) {
      steps.unshift({
        disableBeacon: true,
        title: t('tours.enterprisesTourSteps.step2.title'),
        content: t('tours.enterprisesTourSteps.step2.text'),
        target: '.enterprise-ui-element:first-child'
      });
    }
    steps.unshift({
      disableBeacon: true,
      title: t('tours.enterprisesTourSteps.step1.title'),
      content: t('tours.enterprisesTourSteps.step1.text'),
      target: '#top-navigation'
    });

    setTourSteps(steps);
    startTour();
  }

  retrieveEnterprises: () => void;

  async retrieveEnterprises() {
    const { selectedEnterprise, retrieveEnterprise } = this.props;
    const { searchTerm } = this.state;
    this.cancelCollectionRequests();
    const newTokenSource = CancelToken.source();

    const params = {};
    if (searchTerm) {
      params.search = `*${searchTerm}*`;
    }
    params.page = 1;
    params.size = ENTERPRISES_PAGE_SIZE;

    this.setState({
      previousSearch: newTokenSource
    });
    const enterprises = await this.props.getEnterprises(newTokenSource.token, params);
    if (this.mounted) {
      this.setState({ readyForResults: true });
      if (
        selectedEnterprise &&
        enterprises &&
        !enterprises.find(enterprise => enterprise.entID === selectedEnterprise.id)
      ) {
        const enterprise = await retrieveEnterprise(
          selectedEnterprise.id,
          this.retrieveEnterpriseRequestCancelTokenSource.token
        );

        if (enterprise && enterprise.fullName) {
          this.setState({
            selectedEnterpriseName:
              enterprise.fullName.length > 0 ? enterprise.fullName : enterprise.name
          });
        }
      } else {
        this.setState({ selectedEnterpriseName: undefined });
      }
    }
  }

  retrieveMoreEnterprisesCancelTokenSource: CancelTokenSource;

  requestCancelTokenSource: CancelTokenSource;

  retrieveEnterpriseRequestCancelTokenSource: CancelTokenSource;

  retrieveMoreEnterprises: number => void;

  retrieveMoreEnterprises(page: number) {
    const { searchTerm } = this.state;
    const { getEnterprises } = this.props;

    const params = {};
    if (searchTerm) {
      params.search = `*${searchTerm}*`;
    }
    params.page = page;
    params.size = ENTERPRISES_PAGE_SIZE;

    getEnterprises(this.retrieveMoreEnterprisesCancelTokenSource.token, params);
  }

  handleSearchInputChanged: (value: string) => void;

  handleSearchInputChanged(value: string) {
    this.setState({ readyForResults: false });
    if (value.length > 0) {
      this.setState({ searchTerm: value });
    } else {
      this.handleSearchInputCleared();
    }
  }

  handleSearchInputCleared: () => void;

  handleSearchInputCleared() {
    this.setState({ searchTerm: undefined, readyForResults: false });
    this.props.resetSearch();
  }

  handleEnterpriseSelected: string => void;

  handleEnterpriseSelected(enterpriseId: string) {
    const { enterprises, retrieveEnterprise, selectEnterprise } = this.props;
    if (
      enterpriseId &&
      enterprises &&
      !enterprises.find(enterprise => enterprise.entID === enterpriseId && enterprise.name)
    ) {
      retrieveEnterprise(enterpriseId, this.retrieveEnterpriseRequestCancelTokenSource.token);
    }

    let fullName = '';
    // $FlowFixMe
    const selectedEnteprise: EnterpriseStateEntityT = R.find(R.propEq('entID', enterpriseId))(
      enterprises
    );
    if (selectedEnteprise) {
      fullName = selectedEnteprise.fullName;
    }
    selectEnterprise(enterpriseId, fullName || '');
  }

  createTableRowItems(): TableRowItemT[] {
    const { enterprises = [], selectedEnterprise } = this.props;
    const selectedEnterpriseId = selectedEnterprise ? selectedEnterprise.id : null;
    return enterprises.map((enterprise, index) => ({
      id: enterprise.entID,
      rowId: `enterprise-${index}`,
      rowClasses: ['enterprise-ui-element', styles['text-row']],
      fullName: enterprise.fullName,
      name: enterprise.name,
      isSelected: selectedEnterpriseId === enterprise.entID,
      icon: <img src="/enterprise-icon-listitem.svg" alt="enterprise-icon" />,
      number: enterprise.pilotNumber ? enterprise.pilotNumber.addressNumber : ''
    }));
  }

  showTableView: () => void;

  showTableView() {
    this.setState({
      viewMode: 'table'
    });
  }

  showGridView: () => void;

  showGridView() {
    this.setState({
      viewMode: 'grid'
    });
  }

  renderEnterpriseGridItems(): Element<*>[] {
    const {
      enterprises,
      goToEnterprise, // eslint-disable-line no-shadow
      selectedEnterprise
    } = this.props;
    const { innerWidth } = this.state;
    return enterprises.map(({ entID, fullName }: EnterpriseStateEntityT): Element<*> => (
      <FluidGridItem laptop={innerWidth > parseInt(styles.laptopMax, 10) ? 4 : 5} key={entID}>
        <EnterpriseCard
          id={`enterprise-${entID}`}
          className="enterprise-ui-element"
          label={fullName}
          isSelected={selectedEnterprise ? selectedEnterprise.id === entID : false}
          onSelect={(): * => {
            this.handleEnterpriseSelected(entID);
          }}
          onInspect={(): * => {
            goToEnterprise(entID);
          }}
        />
      </FluidGridItem>
    ));
  }

  renderEnterprisesGrid(): ?Element<*> {
    const { hasMore, enterprises = [], isLoadingEnterprises } = this.props;
    return (
      <div className={styles.grid}>
        <InfiniteScroll
          hasMore={hasMore === true}
          loadMore={this.retrieveMoreEnterprises}
          useWindow
          pageStart={1}
        >
          <FluidGrid gutter="normal">
            {enterprises.length > 0 && this.renderEnterpriseGridItems()}
            {isLoadingEnterprises && (
              <CenterHorizontally>
                <LoadingSpinner />
              </CenterHorizontally>
            )}
          </FluidGrid>
        </InfiniteScroll>
      </div>
    );
  }

  renderEnterprisesTable(): Element<'div'> {
    const {
      hasMore,
      t,
      goToEnterprise, // eslint-disable-line no-shadow
      isLoadingEnterprises
    } = this.props;

    const tableHeaderColumns: TableHeaderColumnT[] = [
      {
        columnId: 'icon',
        text: '',
        size: 'small',
        border: false
      },
      {
        columnId: 'fullName',
        text: t('enterprises.table.fullNameHeaderLabel'),
        size: 'medium'
      },
      {
        columnId: 'name',
        text: t('enterprises.table.nameHeaderLabel'),
        size: 'medium'
      }
    ];
    const itemToCell: MapItemToCellFnT = ({ columnId, size, border = true }, item) => ({
      value: item[columnId] || '',
      size,
      border,
      valueClasses: columnId === 'icon' ? [styles['icon-cell']] : []
    });
    return (
      <div className={styles.table}>
        <Table
          id="enterpriseTable"
          items={this.createTableRowItems()}
          columns={tableHeaderColumns}
          allowLoadMore={hasMore === true}
          onInfiniteScrollLoadMore={this.retrieveMoreEnterprises}
          rowHoverEnabled
          hoverButton={{
            label: t('enterprises.enterprise.inspectButtonLabel'),
            onClick: rowItem => {
              goToEnterprise(rowItem.id);
            }
          }}
          onSelectRow={rowItem => {
            this.handleEnterpriseSelected(rowItem.id);
          }}
          mapItemToCell={itemToCell}
        />
        {isLoadingEnterprises && (
          <CenterHorizontally>
            <LoadingSpinner />
          </CenterHorizontally>
        )}
      </div>
    );
  }

  renderNoResults(): Element<'div'> {
    const { t } = this.props;
    return (
      <div className={styles['no-search-results-container']}>
        <NoSearchResultsPic />
        <h2>{t('enterprises.noResults.first')}</h2>
        <h2>{t('enterprises.noResults.second')}</h2>
      </div>
    );
  }

  render() {
    const {
      t,
      goToEnterprise, // eslint-disable-line no-shadow
      selectedEnterprise,
      enterprises = [],
      isLoadingEnterprises,
      loadingError
    } = this.props;
    const { viewMode, readyForResults, innerWidth } = this.state;
    const selectedEnterpriseNames = R.merge(
      { fullName: '', name: '' },
      enterprises &&
        enterprises.find(
          enterprise => enterprise.entID === (selectedEnterprise ? selectedEnterprise.id : null)
        )
    );
    let enterpriseName =
      selectedEnterpriseNames.fullName.length > 0
        ? selectedEnterpriseNames.fullName
        : selectedEnterpriseNames.name;
    if (!enterpriseName || enterpriseName.length === 0) {
      enterpriseName = this.state.selectedEnterpriseName;
    }
    let enterpriseListing;
    if (!isLoadingEnterprises && loadingError) {
      enterpriseListing = <GenericError message={t('enterprises.enterprisesListFailedMsg')} />;
    } else if (!isLoadingEnterprises && enterprises.length === 0 && readyForResults) {
      enterpriseListing = this.renderNoResults();
    } else if (viewMode === 'grid' && innerWidth > parseInt(styles.laptopMinWidth, 10)) {
      enterpriseListing = this.renderEnterprisesGrid();
    } else {
      enterpriseListing = this.renderEnterprisesTable();
    }
    return (
      <BaseContainer
        header={
          <TopNavigation
            onClickTutorial={isLoadingEnterprises || enterprises.length < 1 ? null : this.showTour}
            viewName="enterprises"
          />
        }
      >
        <div className={styles.container}>
          {selectedEnterprise && enterpriseName && (
            <>
              {' '}
              <div className={styles['selection-note']}>
                <div className={styles['selected-check']}>
                  <CheckMarkPic />
                </div>
                {t('enterprise.selectedNote', { enterpriseName })}
                <LinkButton
                  id="company-selection-note"
                  onClickAction={() => goToEnterprise(selectedEnterprise.id)}
                  label={t('enterprise.selectedNoteFromHere')}
                />
                .
              </div>
            </>
          )}
          <div className={styles['options-container']}>
            <Field
              id="enterprise-search-field"
              name="searchField"
              placeholder={t('enterprise.searchFieldPlaceholder')}
              onValueChange={e => {
                const {
                  currentTarget: { value: searchTerm = '' }
                } = e;
                // $FlowFixMe  redux-form uses its own Event type
                this.handleSearchInputChanged(searchTerm);
              }}
              onClearInput={this.handleSearchInputCleared}
              className={styles['search-input']}
              maxlength={50}
              component={Input}
              type="search"
              optional
              i18n_input_optionalText=""
              clear
              autoFocus
            />
            <div className={styles.switcher}>
              <OptionGroup
                default={this.state.viewMode}
                onOptionChange={name =>
                  name === 'grid' ? this.showGridView() : this.showTableView()
                }
              >
                <Option name="grid" id="enterprises-grid-mode-select" render={GridModeIcon} />
                <Option name="table" id="enterprises-table-mode-select" render={TableModeIcon} />
              </OptionGroup>
            </div>
          </div>
          <div id="enterprisesContainer" className={styles.enterprises}>
            {enterpriseListing}
          </div>
        </div>
      </BaseContainer>
    );
  }
}

const mapStateToProps = (state: StoreStateT) => {
  const {
    ui: { enterprises },
    currentUser
  } = state;
  return {
    hasEnterprises: enterprises.allIds.length > 0,
    enterprises: enterpriseSelect.enterprisesById(state, enterprises.allIds),
    selectedEnterprise: currentUser.currentEnterprise,
    firstTimeView: currentUser.firstTimeView,
    isLoadingEnterprises: enterpriseSelect.collectionIsLoading(state),
    hasMore: enterprises.__metadata.hasMore,
    loadingError: !!enterprises.__metadata.error,
    currentUser
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetSearch: reset.bind(null, 'enterpriseSearch'),
      disableFirstTimeView: createDisableFirstTimeViewAction,
      selectEnterprise: createSelectEnterpriseAction,
      getEnterprises: enterpriseOps.getEnterprises,
      getEnterprise: enterpriseOps.getEnterprise,
      startTour: createStartTourAction,
      setTourSteps: createSetStepsAction,
      closeUsersImport: createCloseUsersImportAction,
      goToEnterprise,
      retrieveEnterprise: enterpriseOps.getEnterprise
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'enterpriseSearch' }),
  delayBooleanTransitionToTrue({
    delay: 500,
    propName: 'isLoadingEnterprises'
  })
)(Enterprises);
