import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSalasanapankkiLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.55.998 12.05 0H12L2.45.998a.5.5 0 0 0-.45.499v7.175c0 8.572 3.74 12.184 9.77 15.278a.55.55 0 0 0 .46 0c6-3.044 9.77-6.656 9.77-15.268V1.457a.5.5 0 0 0-.45-.459M21 8.632c0 7.983-3.3 11.346-9 14.26-5.7-2.934-9-6.227-9-14.26V1.906l9-.908 9 .908zm-12.5-.15a3.495 3.495 0 0 0 3 3.443v5.039a.5.5 0 0 0 .5.499h2a.5.5 0 1 0 0-.998h-1.5v-1.497h1a.5.5 0 1 0 0-.998h-1v-1.996c1.907-.281 3.224-2.051 2.943-3.954s-2.056-3.217-3.963-2.936A3.486 3.486 0 0 0 8.5 8.532zm6 0c0 1.378-1.119 2.495-2.5 2.495S9.5 9.86 9.5 8.482s1.119-2.495 2.5-2.495 2.5 1.117 2.5 2.495"/></svg>)}
    />
  );
}
  