import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEditRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.688 1.314-.002-.002A4.43 4.43 0 0 0 19.501.01a4.5 4.5 0 0 0-3.16 1.339L2.029 15.661a.75.75 0 0 0-.196.344L.024 23.064a.75.75 0 0 0 .913.913l7.058-1.811a.76.76 0 0 0 .344-.196L22.645 7.665c.847-.828 1.338-1.982 1.346-3.166s-.468-2.345-1.303-3.185M3.623 16.188 16.448 3.364l4.188 4.188-12.82 12.82zm-.663 1.458 3.398 3.391-4.567 1.172zM21.687 6.482l-4.17-4.17a2.98 2.98 0 0 1 1.994-.802c.769-.019 1.557.309 2.115.864.555.558.87 1.328.864 2.113-.005.738-.3 1.45-.803 1.995"/></svg>)}
    />
  );
}
  