import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAccessoryLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15 24H6c-.8 0-1.5-.7-1.5-1.5v-21C4.5.7 5.2 0 6 0h9c1.9 0 3.5 1.6 3.5 3.5v4c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-4C17.5 2.1 16.4 1 15 1H6c-.3 0-.5.2-.5.5v21c0 .3.2.5.5.5h9c1.4 0 2.5-1.1 2.5-2.5v-4c0-.3.2-.5.5-.5s.5.2.5.5v4c0 1.9-1.6 3.5-3.5 3.5M13.5 3c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5s.2.5.5.5h3c.3 0 .5-.2.5-.5m6 11.5v-5c0-.3-.2-.5-.5-.5h-3.5c-1.7 0-3 1.3-3 3s1.3 3 3 3H19c.3 0 .5-.2.5-.5m-1-4.5v4h-3c-1.1 0-2-.9-2-2s.9-2 2-2z"/></svg>)}
    />
  );
}
  