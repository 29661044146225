import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLocationRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 11.25c-2.068 0-3.75-1.682-3.75-3.75S9.932 3.75 12 3.75s3.75 1.682 3.75 3.75-1.683 3.75-3.75 3.75m0-6c-1.241 0-2.25 1.009-2.25 2.25S10.759 9.75 12 9.75c1.24 0 2.25-1.009 2.25-2.25S13.24 5.25 12 5.25m-.002 15.49a1.12 1.12 0 0 1-.902-.453C9.108 17.549 4.5 10.843 4.5 7.5 4.5 3.364 7.864 0 12 0s7.5 3.364 7.5 7.5c0 3.345-4.608 10.05-6.59 12.777a1.13 1.13 0 0 1-.912.463M12 1.5c-3.309 0-6 2.691-6 6 0 2.292 3.165 7.515 6 11.477 2.834-3.959 6-9.182 6-11.477 0-3.309-2.691-6-6-6m11.25 18.75c0-1.808-2.787-2.738-5.126-3.201a.75.75 0 1 0-.291 1.471c2.931.581 3.917 1.432 3.917 1.73 0 .673-3.317 2.25-9.75 2.25s-9.75-1.577-9.75-2.25c0-.298.981-1.146 3.896-1.728a.75.75 0 0 0-.293-1.471C3.525 17.516.75 18.447.75 20.25.75 22.826 6.582 24 12 24s11.25-1.174 11.25-3.75"/></svg>)}
    />
  );
}
  