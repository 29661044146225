// @flow

import React, { type Element } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SmallEfaxTableRow from './SmallEfaxTableRow';
import type { SmallEfaxTableRowItemT } from './SmallEfaxTableRow';

import styles from './SmallEfaxTable.module.scss';

export type PropsT = {
  id: string,
  allowLoadMore?: boolean,
  items: SmallEfaxTableRowItemT[],
  onInfiniteScrollLoadMore?: (page: number) => void,
  showIfEmpty?: Element<*>
};

const getTableRows: * = rows =>
  rows.map((row, index) => (
    <SmallEfaxTableRow
      key={
        `cl_${row.rowId}_${index}` // eslint-disable-line react/no-array-index-key
      }
      onSelect={() => {}}
      rowItem={row}
    />
  ));

const SmallEfaxTable = ({
  id,
  allowLoadMore,
  items = [],
  onInfiniteScrollLoadMore,
  showIfEmpty
}: PropsT): Element<'div'> => {
  if (!items.length && showIfEmpty) {
    return showIfEmpty;
  }
  const rowComponents = getTableRows(items);
  const showRows = rowComponents.length > 0;
  const rowsElement = onInfiniteScrollLoadMore ? (
    <InfiniteScroll
      // This component started to fetch more pages than necessary when hasMore
      // was passed as true unless we know it's false. Hence, allowLoadMore.
      hasMore={allowLoadMore}
      loadMore={onInfiniteScrollLoadMore}
      useWindow
      pageStart={1}
    >
      {rowComponents}
    </InfiniteScroll>
  ) : (
    rowComponents
  );
  return (
    <div className={styles.container}>
      <div id={id}>{showRows && rowsElement}</div>
    </div>
  );
};

SmallEfaxTable.defaultProps = {
  allowLoadMore: false,
  mapItemToCell: undefined,
  onInfiniteScrollLoadMore: undefined,
  showIfEmpty: undefined,
  sort: undefined,
  tableStyle: ''
};

export default SmallEfaxTable;
