// @flow

import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import type { TranslateT } from '../../../commonTypes';
import type { HistoryActionStateEntityT } from '../../../ducks/entities/historyAction/historyActionTypes';
import { ReactComponent as HistoryIcon } from '../../../assets/history.svg';
import styles from './HistoryActionsTable.module.scss';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';

export type PropsT = {
  historyActions: HistoryActionStateEntityT[],
  translate: TranslateT<>,
  allowLoadMore: boolean,
  onInfiniteScrollLoadMore: number => void,
  isRetrieving: boolean,
  lastRequestedPage: number
};

export const HistoryActionsTable = (props: PropsT) => {
  const {
    historyActions = [],
    translate,
    allowLoadMore,
    onInfiniteScrollLoadMore,
    isRetrieving,
    lastRequestedPage
  } = props;

  const infinitelyScrollingTable = (
    <InfiniteScroll
      hasMore={allowLoadMore}
      loadMore={onInfiniteScrollLoadMore}
      useWindow
      pageStart={1}
    >
      <table className={styles['history-actions-table']}>
        <thead>
          <tr>
            <td className={styles['icon-header-column']} />
            <td className={styles['action-header-column']}>
              <span>{translate('history.table.activityDescription')}</span>
            </td>
            <td className={styles['action-timestamp-header-column']}>
              <span>{translate('history.table.activityDateAndTime')}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {historyActions.map(historyAction => (
            <tr key={historyAction.id} id={`history-action-forwarding-${historyAction.id}`}>
              <td className={styles['icon-column']}>
                <HistoryIcon />
              </td>
              <td className={styles['action-column']}>
                <span className={styles['action-text']}>{historyAction.action}</span>
                <span className={styles['userid-text']}>{historyAction.userId}</span>
              </td>
              <td className={styles['action-timestamp-column']}>
                {new Date(parseInt(historyAction.actionTimeStamp, 10)).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
        {isRetrieving && lastRequestedPage > 1 && (
          <tfoot>
            <tr>
              <td colSpan={3}>
                <CenterHorizontally>
                  <LoadingSpinner />
                </CenterHorizontally>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </InfiniteScroll>
  );

  return (
    <>
      {isRetrieving && lastRequestedPage <= 1 ? (
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      ) : (
        infinitelyScrollingTable
      )}
    </>
  );
};

export default HistoryActionsTable;
