// @flow

import React, { type Element } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import Avatar from '../../../components/Avatar/Avatar';
import type { TranslateT } from '../../../commonTypes';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';
import { getAvailability } from '../../../helpers';
import Favourite from '../../../components/Favourite/Favourite';
import TextError from '../../../components/Error/TextError';
import EditIcon from '../../../components/EditIcon/EditIcon';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import Dismiss from '../../../components/Button/Dismiss';
import type { DirectoryAvatarStateEntityT } from '../../../ducks/entities/directory/directoryTypes';

import styles from './Title.module.scss';

export type PropsT = {
  onClose: () => *,
  fullName: string,
  showFixedTitle: boolean,
  showLoadingSpinner: boolean,
  wasUnsuccessFullyUpdated: boolean,
  hasError: boolean,
  dataFetchFailedErrorMessage: string,
  defaultUserName: string,
  title: string,
  isInternalUser: boolean,
  isFavourite: boolean,
  onToggleFavourite: (selected: boolean) => *,
  handleOnEditClick: () => void,
  disableButtons: boolean,
  disableFavouriteButton: boolean,
  user: InternalUserStateEntityT,
  directoryAvatar?: ?DirectoryAvatarStateEntityT,
  translate: TranslateT<>
};

const Title = ({
  onClose,
  fullName,
  showFixedTitle,
  showLoadingSpinner,
  wasUnsuccessFullyUpdated,
  hasError,
  dataFetchFailedErrorMessage,
  defaultUserName,
  title,
  isInternalUser,
  isFavourite,
  onToggleFavourite,
  handleOnEditClick,
  disableButtons,
  disableFavouriteButton,
  user,
  directoryAvatar,
  translate
}: PropsT): Element<'div'> => {
  const availability = getAvailability(user, translate);

  return (
    <div className={styles.top} style={showFixedTitle ? { visibility: 'hidden' } : {}}>
      <div className={styles['buttons-bar']}>
        <Dismiss
          id="close-user-details-button"
          onClose={onClose}
          tabIndex="0"
          dismissStyle={styles['close-button']}
          color="white"
        />
      </div>
      <div className={styles.background} />
      <div className={styles['avatar-container']}>
        {isInternalUser ? (
          <Avatar
            src={directoryAvatar && directoryAvatar.isAvatarCustomized ? user.urlPhoto : null}
            name={fullName}
            availability={availability.icon}
            color="#0019AF"
            shadow
            size="large"
          />
        ) : (
          <Avatar
            src={hasError ? '/unknown_user_avatar.svg' : ''}
            name={fullName}
            hideAvailability
            color="#002447"
            shadow
            size="large"
          />
        )}
      </div>
      {hasError && !wasUnsuccessFullyUpdated ? (
        <TextError message={dataFetchFailedErrorMessage} />
      ) : (
        <div className={styles['user-name-and-title']}>
          <div
            id={isInternalUser ? 'internaluser-fullname' : 'fullname-label'}
            className={styles['user-name']}
          >
            {fullName || defaultUserName}
          </div>
          {isInternalUser ? (
            <div className={styles.title}>{title}</div>
          ) : (
            <div className={styles.company} id="company-label">
              {title}
            </div>
          )}
        </div>
      )}
      {showLoadingSpinner && (
        <CenterHorizontally>
          <LoadingSpinner />
        </CenterHorizontally>
      )}
      <div id="button-area" className={styles['button-area']}>
        <Favourite
          isSelected={isFavourite}
          onToggle={onToggleFavourite}
          disabled={disableButtons || (!isFavourite && disableFavouriteButton)}
        />
        <EditIcon onClick={handleOnEditClick} disabled={disableButtons} />
      </div>
    </div>
  );
};

export default Title;
