// @flow

/* eslint-disable jsx-a11y/label-has-for */

import React, { Component, type Element } from 'react';
import { FormSection, formValueSelector, Field, reduxForm } from 'redux-form';
import { compose, bindActionCreators } from 'redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import Checkbox from '@design-system/component-library/src/components/Checkbox';
import { connect } from 'react-redux';
import type { FormProps } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { selectors as userSelect } from '../../../ducks/entities/user';
import type { StoreStateT } from '../../../commonTypes';
import type {
  DepartmentStateEntityT,
  IdTreeT
} from '../../../ducks/entities/department/departmentTypes';
import { selectors as selectDepartments } from '../../../ducks/entities/department/index';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';
import {
  actionCreators as departmentUiActions,
  selectors as departmentUiSelect
} from '../../../ducks/ui/department/index';
import type { ActiveTabT } from '../../../ducks/ui/department/departmentUiTypes';
import delayBooleanTransitionToTrue from '../../../components/delayBooleanTransitionToTrue';
import DepartmentComboboxItem from './DepartmentComboboxItem';
import { IdTreeCombobox, InputField } from '../../../components/InputComponent/ReduxFormField';
import UserGrid from './UserGrid';
import UserGridItem from './UserGridItem';
import ActionButton from '../../../components/Button/ActionButton';
import CancelButton from '../../../components/Button/CancelButton';
import ConfirmButton from '../../../components/Button/ConfirmButton';
import LinkButton from '../../../components/Button/LinkButton';
import { ReactComponent as NoPeopleIcon } from '../../../assets/department-no-people.svg';
import { ReactComponent as NoSearchResultsPic } from '../../../assets/no-search-results.svg';
import CenterHorizontally from '../../../components/CenterHorizontally/CenterHorizontally';
import AddDepartmentPeopleForm from './AddDepartmentPeopleForm';

import styles from './DepartmentPeopleView.module.scss';

export type OwnPropsT = {
  departmentId: ?string,
  enterpriseId: string,
  noDebounce?: boolean,
  isLoadingUsers: boolean,
  setActiveTab: ActiveTabT => void,
  departmentUsers: InternalUserStateEntityT[]
};

type StatePropsT = {
  department: DepartmentStateEntityT,
  hasUserLoadingError: boolean,
  hasSubDepartments: boolean,
  searchTerm: string,
  selectedUsers: *,
  departmentIdTree: IdTreeT,
  selectedMoveDepartment: { key: string, value: string },
  isMovingUsers: boolean,
  initialValues: {
    modify: {
      nameField: string
    },
    people: {
      searchTerm: string,
      moveDepartment: { key: string, value: string }
    }
  }
};

type DispatchPropsT = {
  selectNone: typeof departmentUiActions.createClearDepartmentUser,
  select: typeof departmentUiActions.createSelectDepartmentUser
};

export type ViewModeT = 'view' | 'edit' | 'addPeople';

type StateT = {|
  dialogHeight: number,
  viewMode: ViewModeT
|};

export type PropsT = {|
  // $FlowFixMe
  ...FormProps,
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>
|};

export class DepartmentPeopleView extends Component<PropsT, StateT> {
  static matchUser(user: InternalUserStateEntityT, searchTerm: string = '') {
    const normalizedSearchTerm = searchTerm.toLocaleLowerCase();

    return (
      normalizedSearchTerm.length < 1 ||
      (user.userName && user.userName.toLocaleLowerCase().indexOf(normalizedSearchTerm) >= 0) ||
      (user.title && user.title.toLocaleLowerCase().indexOf(normalizedSearchTerm) >= 0)
    );
  }

  constructor(props: PropsT) {
    super(props);
    this.state = {
      viewMode: 'view',
      dialogHeight: 0
    };
    this.moveDepartmentUsers = this.moveDepartmentUsers.bind(this);
    this.updateDialogHeight = this.updateDialogHeight.bind(this);
    this.addPeopleToDepartment = this.addPeopleToDepartment.bind(this);
    this.handleAddUsersToEmptyDepartmentSelected = this.handleAddUsersToEmptyDepartmentSelected.bind(
      this
    );
  }

  componentDidMount() {
    const {
      department: { id: departmentId },
      enterpriseId,
      selectNone
    } = this.props;

    selectNone(enterpriseId, departmentId);
    selectNone(enterpriseId, 'addingUsers');
  }

  // eslint-disable-next-line camelcase, no-unused-vars
  UNSAFE_componentWillReceiveProps(nextProps: PropsT, nextContext: *): void {
    const { departmentUsers } = nextProps;
    if (departmentUsers && departmentUsers.length !== this.props.departmentUsers.length) {
      this.updateDialogHeight(departmentUsers);
    }
  }

  updateDialogHeight: (*) => void;

  updateDialogHeight(newDepartmentUsers: ?(InternalUserStateEntityT[]) = null) {
    const { departmentUsers, searchTerm } = this.props;
    const users = newDepartmentUsers || departmentUsers;
    const filteredUsers = users
      ? users.filter(user => DepartmentPeopleView.matchUser(user, searchTerm))
      : [];
    const updatedHeight = (filteredUsers.length / 3) * parseInt(styles.rowHeight, 10);
    this.setState({ dialogHeight: updatedHeight });
  }

  moveDepartmentUsers: () => void;

  moveDepartmentUsers() {
    this.props.setActiveTab('movingPeople');
  }

  addPeopleToDepartment: () => void;

  addPeopleToDepartment() {
    this.props.setActiveTab('addingPeople');
  }

  handleAddUsersToEmptyDepartmentSelected: () => void;

  handleAddUsersToEmptyDepartmentSelected() {
    this.setState({ viewMode: 'addPeople' });
  }

  render(): Element<'div'> {
    const {
      t,
      departmentUsers,
      isLoadingUsers,
      hasUserLoadingError,
      searchTerm,
      selectNone,
      select,
      departmentId,
      enterpriseId,
      selectedUsers,
      isMovingUsers,
      selectedMoveDepartment
    } = this.props;
    const { viewMode } = this.state;
    const showLoadingIndicator = isLoadingUsers;
    const showErrorIndicator = !isLoadingUsers && hasUserLoadingError;
    const showUserGrid = !isLoadingUsers && !hasUserLoadingError;
    const peopleWithoutDepartment = departmentId === undefined;
    const departmentHasUsers = departmentUsers.length > 0;
    const allSelected = departmentUsers.every(({ id }) =>
      selectedUsers.map(usr => usr.extensionId).includes(id)
    );

    const filteredUsers = departmentUsers.filter(user =>
      DepartmentPeopleView.matchUser(user, searchTerm)
    );
    const searchSection = (
      <div className={styles['search-section']}>
        <Field
          id="department-people-search-field"
          label=""
          name="searchTerm"
          className={styles['search-input']}
          placeholder={t('enterprise.departmentDetails.peopleSearchPlaceholder')}
          component={InputField}
          onValueChange={e => this.props.change('people.searchTerm', e.currentTarget.value)}
          maxlength={50}
          optional
          i18n_input_optionalText=""
        />
      </div>
    );
    const userLoadingIndicator = (
      <CenterHorizontally style={{ paddingBottom: '20px' }}>
        <LoadingSpinner id="department-details-loading-indicator" />
      </CenterHorizontally>
    );
    const userErrorIndicator = (
      <div id="department-details-error-indicator">{t('enterprise.departmentsErrorMsg')}</div>
    );

    const noPeopleInDepartmentContent = (
      <div id="department-details-no-people-content" className={styles['no-people-content']}>
        <NoPeopleIcon className={styles.icon} />

        <span>
          <div className={styles['no-people-text']}>
            {t('enterprise.departmentDetails.noPeopleInDepartment.description')}
          </div>
          <LinkButton
            id="department-details-add-people-link"
            className={styles['add-people-link']}
            onClickAction={e => {
              e.stopPropagation();
              this.handleAddUsersToEmptyDepartmentSelected();
            }}
            label={t('enterprise.departmentDetails.noPeopleInDepartment.addPeopleToDepartment')}
          />
        </span>
      </div>
    );

    const noSearchResult = (
      <div
        id="department-details-no-users-search-result"
        className={styles['no-search-results-container']}
      >
        <NoSearchResultsPic />
        <h2>{t('users.searchNoResults.users')}</h2>
      </div>
    );

    const everyoneAlreadyHasDepartment = (
      <div
        id="department-details-no-people-without-department-content"
        className={styles['everyone-already-has-department-content']}
      >
        <NoPeopleIcon className={styles.icon} />
        <span>
          <div className={styles['everyone-already-has-department-text']}>
            {t('enterprise.departmentDetails.everyoneAlreadyHasDepartment')}
          </div>
        </span>
      </div>
    );

    const renderNoUsers = () => {
      if (isLoadingUsers) {
        return <div />;
      }
      return searchTerm && searchTerm.length > 0 ? noSearchResult : everyoneAlreadyHasDepartment;
    };
    const userGrid = (
      <div className={styles['user-grid']}>
        {filteredUsers.length > 0 && viewMode === 'edit' && (
          <div className={styles['select-all-department-users']}>
            <div className="ea-checkbox">
              <Checkbox
                id="select-all-department-users"
                name="select-all-department-users"
                checked={allSelected}
                onChange={e => {
                  const checkAllEl = document.querySelector('#select-all-department-users');
                  departmentUsers.forEach(u => {
                    const el = document.querySelector(`#department-user-checkbox-${u.id}`);
                    // $FlowFixMe
                    if (el && el.checked !== checkAllEl.checked) {
                      // $FlowFixMe
                      el.click();
                    }
                  });
                  return e.currentTarget.checked
                    ? departmentUsers.forEach(user =>
                        select(enterpriseId, departmentId || undefined, {
                          extensionId: user.id,
                          personId: user.personId
                        })
                      )
                    : selectNone(enterpriseId, departmentId || undefined);
                }}
                label={t('enterprise.departmentDetails.selectAll', {
                  selectedUsers: departmentUsers.length
                })}
              />
            </div>
            <span className={styles['selected-users-label']}>
              {t('enterprise.departmentDetails.selectedUsersLabel', {
                selectedUsers: selectedUsers.length
              })}
            </span>
          </div>
        )}
        <UserGrid
          id="department-details-user-grid"
          noUsersMessage={renderNoUsers()}
          dialogHeight={this.state.dialogHeight}
        >
          {filteredUsers.map(user => (
            <UserGridItem
              enterpriseId={enterpriseId}
              key={user.id}
              user={user}
              selected={selectedUsers.map(usr => usr.extensionId).includes(user.id)}
              editMode={viewMode === 'edit'}
            />
          ))}
        </UserGrid>
      </div>
    );

    const emptyDepartmentSelection = {
      key: t('enterprise.departmentDetails.noDepartmentOption'),
      value: null
    };

    const moveContainer = (
      <div className={styles['move-container']}>
        <div className={styles['move-title']}>{t('enterprise.departmentDetails.moveTitle')}</div>
        <div className={styles['move-field']}>
          <Field
            id="department-move-combo"
            name="moveDepartment"
            wrapperStyle={styles['static-dropdown']}
            options={this.props.departmentIdTree}
            label={t('enterprise.departmentDetails.chooseDepartment')}
            // $FlowFixMe
            component={props => (
              <IdTreeCombobox
                {...props}
                hideKey={departmentId}
                renderItem={p => (
                  <DepartmentComboboxItem {...p} emptySelection={emptyDepartmentSelection} />
                )}
              />
            )}
            hasEmptyOption
          />
        </div>
      </div>
    );

    const actionButtons =
      viewMode === 'view' ? (
        <div className={styles['button-container']}>
          {!peopleWithoutDepartment && (
            <ConfirmButton
              id="department-details-add-people"
              className={styles['add-people-button']}
              label={t('enterprise.departmentDetails.noPeopleInDepartment.addPeopleToDepartment')}
              onClickAction={this.handleAddUsersToEmptyDepartmentSelected}
            />
          )}
          <ConfirmButton
            id="department-details-modify-button"
            className={styles['modify-button']}
            label={t('enterprise.departmentDetails.modifyDepartmentUsers')}
            disabled={!departmentHasUsers}
            onClickAction={() => this.setState({ viewMode: 'edit' })}
          />
        </div>
      ) : (
        <div className={styles['button-container']}>
          <ActionButton
            id="department-details-move-button"
            className={styles['move-button']}
            label={
              viewMode === 'edit'
                ? t('enterprise.departmentDetails.confirmMoveButton')
                : t('enterprise.departmentDetails.noPeopleInDepartment.addPeopleSaveButton')
            }
            onClickAction={
              viewMode === 'edit' ? this.moveDepartmentUsers : this.addPeopleToDepartment
            }
            loading={isMovingUsers}
            disabled={selectedUsers.length < 1 || (!selectedMoveDepartment && departmentHasUsers)}
          />
          <CancelButton
            id="department-move-people-cancel-button"
            label={t('enterprise.departmentDetails.cancelButton')}
            onClickAction={() => this.setState({ viewMode: 'view' })}
          />
        </div>
      );
    return (
      <div>
        <div>
          {peopleWithoutDepartment ||
          departmentHasUsers ||
          showLoadingIndicator ||
          showErrorIndicator ? (
            <FormSection name="people">
              {departmentHasUsers && searchSection}
              <div className={styles['user-grid-container']}>
                {showLoadingIndicator && userLoadingIndicator}
                {showErrorIndicator && userErrorIndicator}
                {showUserGrid && userGrid}
              </div>
              {this.state.viewMode === 'edit' && moveContainer}
            </FormSection>
          ) : (
            noPeopleInDepartmentContent
          )}
        </div>
        {this.state.viewMode === 'addPeople' && (
          <AddDepartmentPeopleForm
            enterpriseId={enterpriseId}
            initialSelectedUsers={departmentUsers}
            departmentId={departmentId}
          />
        )}
        {actionButtons}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => {
  const departmentId = ownProps.departmentId || '';
  const department = state.entities.department.byId[departmentId] || {};
  const moveDepartment = { key: '', value: null };
  const selectedUsers = departmentUiSelect.selectedUsersByEnterpriseAndDepartment(
    state,
    ownProps.enterpriseId,
    departmentId === '' ? undefined : departmentId
  );
  const selectFromForm = formValueSelector('departmentDetailsPeople');
  return {
    department,
    initialValues: {
      modify: {
        nameField: department.name
      },
      people: {
        searchTerm: '',
        moveDepartment
      }
    },
    hasUserLoadingError: userSelect.collectionHasError(state),
    searchTerm: selectFromForm(state, 'people.searchTerm'),
    selectedMoveDepartment: selectFromForm(state, 'people.moveDepartment'),
    selectedUsers,
    departmentIdTree: state.entities.department.idTrees[ownProps.enterpriseId],
    isMovingUsers: selectedUsers.some(usr => userSelect.isUpdating(state, usr.id)),
    hasSubDepartments: selectDepartments.departmentHasSubDepartments(
      state,
      ownProps.enterpriseId,
      department.id
    )
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectNone: departmentUiActions.createClearDepartmentUser,
      select: departmentUiActions.createSelectDepartmentUser
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'departmentDetailsPeople',
    enableReinitialize: true,
    destroyOnUnmount: false
  }),
  delayBooleanTransitionToTrue({
    delay: 500,
    propName: 'delayedIsLoadingUsers'
  })
)(DepartmentPeopleView);
