import React from 'react';
import IconContainer from '../IconContainer';

export default function IconTabletLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 0H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2m1 22c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-4h16zm0-5H4V2c0-.6.4-1 1-1h14c.6 0 1 .4 1 1zm-10 3.5c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5s-.2.5-.5.5h-3c-.3 0-.5-.2-.5-.5"/></svg>)}
    />
  );
}
  