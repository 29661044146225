// @flow

import React, { type Element } from 'react';
import { isBrowserRunningE2eTests } from '../../helpers';
import styles from './OnboardingContent.module.scss';

type PropsT = {|
  id: string,
  contentText: string,
  imgSrc: string,
  title?: string
|};

export const OnboardingContent = (props: PropsT): Element<'div'> => {
  const { id, imgSrc, title, contentText } = props;

  return (
    <div id={id} className={styles['top-area']}>
      {!isBrowserRunningE2eTests() && (
        <img className={styles['image-content']} src={imgSrc} alt={id} />
      )}
      <div className={styles['text-content']}>
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.description}>{contentText}</div>
      </div>
    </div>
  );
};

export default OnboardingContent;
