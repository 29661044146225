// @flow

import React, { type Element } from 'react';
import BaseHeader from '@design-system/component-library/src/components/Header';
import HeaderNavigationSection from '@design-system/component-library/src/components/Header/subcomponents/HeaderNavigationSection';
import HeaderLogoAndService from '@design-system/component-library/src/components/Header/subcomponents/HeaderLogoAndService';
import HeaderUserMenu from '@design-system/component-library/src/components/Header/subcomponents/HeaderUserMenu';
import HeaderModels from '@design-system/component-library/src/components/Header/models';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { operations as currentUserOps } from '../../../ducks/currentUser';

import styles from './Header.module.scss';

const Header = (): Element<'div'> => {
  const { t, i18n } = useTranslation();
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();

  const logo = {
    label: 'Elisa',
    onClick: () => {},
    url: '',
    target: '_self'
  };
  const service = {
    identifiers: [t('phonebook.sharedPhonebookTitle')],
    label: '',
    onClick: () => {},
    url: '',
    target: '_self'
  };
  const availableLanguages = Object.keys(i18n.services.resourceStore.data)
    .filter(language => language !== 'aa')
    .map(lang => {
      return {
        abbr: lang,
        name: t(`languageSelector.languages.${lang}`)
      };
    });

  const logout = HeaderModels.Logout({
    onClick: async () => {
      await dispatch(currentUserOps.logout(currentUser));
      window.href = '/phonebook/login';
    },
    title: t('generic.logoutButtonLabel')
  });

  const languageSelect = {
    languages: availableLanguages,
    onLanguageChange: language => {
      const languageCode = language.getAttribute('data-value');
      i18n.changeLanguage(languageCode);
    },
    selectedLanguage: i18n.language ? i18n.language : 'fi'
  };

  return (
    <BaseHeader
      className={styles['header-div']}
      desktopBreakpointOffset={0}
      isLoggedIn={!!currentUser.login}
      languageSelect={languageSelect}
    >
      <HeaderNavigationSection>
        <HeaderLogoAndService logo={logo} service={service} />
      </HeaderNavigationSection>
      {!!currentUser.login && (
        <HeaderUserMenu
          user={{
            fullName: currentUser.login,
            name: currentUser.login,
            email: ''
          }}
          userMenuLinkAccordions={[]}
          logout={logout}
          i18nButtonLabel=""
          i18nLogin=""
          userPronounText=""
        />
      )}
    </BaseHeader>
  );
};
export default Header;
