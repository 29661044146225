// @flow
import React, { useEffect, useState } from 'react';
import { CancelToken, type Canceler } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import type { EnterpriseEntityT } from '../ducks/entities/enterprise';
import { selectors as enterpriseSelectors } from '../ducks/entities/enterprise';
import { getEnterprise } from '../ducks/entities/enterprise/enterpriseOperations';
import { createSelectEnterpriseAction } from '../ducks/currentUser/currentUserActions';

export type EnterpriseResponseT = {|
  enterprise: ?EnterpriseEntityT,
  isLoadingEnterprise?: boolean
|};

const useEnterprise = (
  enterpriseId: string | typeof undefined | null,
  forceFetch?: boolean
): EnterpriseResponseT => {
  // Redux
  const dispatch = useDispatch();
  const enterpriseFromRedux: ?EnterpriseEntityT = useSelector(state =>
    enterpriseSelectors.enterpriseById(state, enterpriseId || '')
  );

  // State
  const [isLoadingEnterprise, setIsLoadingEnterprise] = useState<boolean>(true);
  const [enterprise, setEnterprise] = useState<?EnterpriseEntityT>(undefined);
  const cancelFetch = React.useRef<Canceler>();

  const fetchEnterprise = async () => {
    if (enterpriseId) {
      const hasCompleteEnterprise = enterpriseFromRedux && enterpriseFromRedux.forwardings;
      if (!hasCompleteEnterprise || forceFetch) {
        const res = await dispatch(
          getEnterprise(
            enterpriseId,
            new CancelToken(canceler => {
              cancelFetch.current = canceler;
            })
          )
        );
        setEnterprise(res);
        if (res) {
          dispatch(createSelectEnterpriseAction(enterpriseId, res.fullName));
        }
      } else if (enterpriseFromRedux) {
        setEnterprise(enterpriseFromRedux);
        if (enterpriseFromRedux.fullName) {
          dispatch(createSelectEnterpriseAction(enterpriseId, enterpriseFromRedux.fullName));
        }
      }
    }
    setIsLoadingEnterprise(false);
  };

  useEffect(() => {
    fetchEnterprise();
    return () => cancelFetch.current && cancelFetch.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCompleteEnterprise = enterpriseFromRedux && enterpriseFromRedux.forwardings;
  if (hasCompleteEnterprise && !forceFetch) {
    if (enterpriseFromRedux) {
      return {
        enterprise: enterpriseFromRedux,
        isLoadingEnterprise: false
      };
    }
  }

  return {
    enterprise,
    isLoadingEnterprise
  };
};

export default useEnterprise;
