import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileGeneralRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.8 12.5c-.4 0-.8.3-.8.8s.3.7.8.7h10.5c.4 0 .8-.3.8-.8s-.3-.8-.8-.8H6.8zM6 16.3c0-.4.3-.8.8-.8h10.5c.4 0 .8.3.8.8 0 .4-.3.8-.8.8H6.8c-.5 0-.8-.4-.8-.8m.8 2.3c-.4 0-.8.3-.8.8s.3.8.8.8h10.5c.4 0 .8-.3.8-.8s-.3-.8-.8-.8zM21.3 6.4c0 .1.1.1 0 0 .1.2.2.5.2.7v14.6c0 1.2-1 2.2-2.2 2.2h-14C4 24 3 23 3 21.8V2.2C3 1 4 0 5.2 0h9.1c.2 0 .5.1.7.1.2.1.4.2.5.4L21 5.9c.1.2.2.3.3.5M15.8 6H19l-3.4-3.4v3.2c-.1.1 0 .2.2.2M5.2 22.5h14c.4 0 .7-.3.7-.7V7.5h-4.2c-1 0-1.8-.8-1.8-1.8V1.5H5.2c-.4 0-.7.3-.7.8v19.5c0 .4.3.7.7.7"/></svg>)}
    />
  );
}
  