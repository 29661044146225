import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUpwardLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m19.4 7.2-6-5.6c-.8-.7-2-.7-2.7 0l-6 5.6c-.5.3-.7.9-.7 1.4.1 1.1 1 1.9 2.1 1.9h2.4v11c0 .8.7 1.5 1.5 1.5h4c.8 0 1.5-.7 1.5-1.5v-11H18c.5 0 1.1-.2 1.5-.6.3-.4.5-.9.5-1.4s-.3-1-.6-1.3m-.7 2c-.2.2-.5.3-.7.3h-2.9c-.3 0-.6.3-.6.6v11.4c0 .3-.2.5-.5.5h-4c-.3 0-.5-.2-.5-.5V10.1c0-.3-.3-.6-.6-.6H6.1c-.6 0-1.1-.4-1.1-.9 0-.3.1-.5.3-.7l6-5.6c.4-.4 1-.4 1.4 0l6 5.6c.2.2.3.4.3.7 0 .2-.1.5-.3.6"/></svg>)}
    />
  );
}
  