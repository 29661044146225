import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFreedomeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m21.58 1-9.5-1h-.16l-9.5 1a.75.75 0 0 0-.67.75V8.4c0 8.76 3.79 12.43 9.91 15.52a.76.76 0 0 0 .68 0c6.12-3.09 9.91-6.76 9.91-15.52V1.75a.75.75 0 0 0-.67-.75m-.83 7.4c0 7.87-3.2 11.15-8.75 14-5.55-2.86-8.75-6.14-8.75-14V2.43L12 1.54l8.75.89zm-5.41 2.75a.74.74 0 0 1 .014 1.046l-.014.014a.77.77 0 0 1-.53.22.8.8 0 0 1-.53-.22 3.3 3.3 0 0 0-4.56 0 .75.75 0 0 1-1.06 0 .74.74 0 0 1-.014-1.046l.014-.014a4.71 4.71 0 0 1 6.661-.019zM17 8.83a.75.75 0 0 1 0 1.06.75.75 0 0 1-1.06 0 5.64 5.64 0 0 0-7.94 0 .74.74 0 0 1-1.046.014L6.94 9.89a.75.75 0 0 1 0-1.06 7.13 7.13 0 0 1 10.06 0m-3.875 5.045a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0"/></svg>)}
    />
  );
}
  