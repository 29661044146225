/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

import { classNames } from '../../utils/css';

import Popover from '../Popover';
import IconInformationRegular from '../Icon/lib/IconInformationRegular';
import IconCheckFilled from '../Icon/lib/IconCheckFilled';
import InputError from '../InputError';

import styles from './Checkbox.module.scss';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`ds${this.constructor.name}`);

    this.state = {
      nowChecked: props.checked || false,
    };

    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  onCheckboxClick(_e) {
    // let nowChecked = this.state;
    // this.props.checked = !nowChecked;
    // FIXME use callback to mutate old state value
    this.setState({ nowChecked: !this.state.nowChecked });
    this.onCheckboxChange();
  }

  onCheckboxChange(e) {
    if (e) {
      this.setState({ nowChecked: e.target.checked });
    }
    const { onChange } = this.props;
    onChange(e);
  }

  render() {
    const { inputProps, id, className, label, i18n_checkbox_ariaLabel, children, value, name, checked, disabled, onChange, inputRef, ...otherProps } = this.props;

    const allClasses = classNames([styles.checkbox, disabled ? styles[`checkbox--disabled`] : null, className]);
    const { nowChecked } = this.state;
    const content = label || children;
    // onClick={this.onCheckboxClick}
    return (
      <div id={`${this.htmlId}Container`} key={`${this.htmlId}Container`} className={allClasses} {...otherProps}>
        <input
          type="checkbox"
          ref={inputRef || null}
          id={this.htmlId}
          name={name || this.htmlId}
          value={value || ''}
          onChange={this.onCheckboxChange}
          checked={nowChecked}
          aria-label={label || children ? null : i18n_checkbox_ariaLabel}
          disabled={disabled}
          {...inputProps}
        />
        <label className={styles.checkbox__label} htmlFor={this.htmlId}>
          <IconCheckFilled className={styles.checkbox__icon} />
          {content}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  /**
   * Element's id
   */
  id: PropTypes.string,
  /**
   * Any CSS classes for the component
   */
  className: PropTypes.string,
  /**
   * Component's content. Automatically detected.
   */
  children: PropTypes.node,
  /**
   * Checkbox's label text
   */
  label: PropTypes.string,
  /**
   * Checkbox's aria-label text. Use this when you don't want to use Checkbox with visible label.
   */
  i18n_checkbox_ariaLabel: PropTypes.string,
  /**
   * Checkbox's value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Checkbox's name
   */
  name: PropTypes.string,
  /**
   * The function run after the checkbox is checked or unchecked
   */
  onChange: PropTypes.func,
  /**
   * Whether the checkbox is checked
   */
  checked: PropTypes.bool,
  /**
   * Whether the checkbox is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Ref for the actual input element, not the wrapper
   */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
  /**
   * Additional properties that are passed to the input element. Handy for adding data-attributes.
   */
  inputProps: PropTypes.object,
};

Checkbox.defaultProps = {
  id: null,
  className: null,
  children: null,
  label: '',
  i18n_checkbox_ariaLabel: null,
  value: null,
  name: '',
  onChange: () => {},
  checked: null,
  disabled: null,
  inputRef: null,
  inputProps: null,
};

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);

    this.htmlId = props.id || nextId(`dsCheckboxGroup`);
    this.state = {
      nowChecked: props.checked || false,
    };
  }

  componentDidMount() {
    const { children, toggleController } = this.props;
    if (toggleController) {
      const toggleControllerElement = document.querySelector(`#${toggleController}`);

      // Get id's of all checkboxes in the group
      const ids = [];
      children.forEach((element) => {
        ids.push(element.props.id);
      });

      // Toggle all checkboxes
      const toggleAll = () => {
        ids.forEach((id) => {
          if (id !== toggleController) {
            const el = document.querySelector(`#${id}`);
            el.click();
          }
        });
      };

      ids.forEach((id) => {
        const el = document.querySelector(`#${id}`);

        // Bind eventlistener for all checkboxes to disable toggle controller checkbox if any is unchecked
        if (id !== toggleController) {
          if (!el.unCheck) {
            el.unCheck = el?.addEventListener(
              'change',
              (e) => {
                if (!e.target.checked) {
                  toggleControllerElement.checked = false;
                }
              },
              false
            );
          }
        }

        // Bind toggle all function to toggle controller
        else {
          el?.addEventListener(
            'click',
            () => {
              toggleAll();
            },
            false
          );
        }
      });
    }
  }

  render() {
    const { id, className, label, i18n_checkboxgroup_errorMessage, i18n_checkboxgroup_infoText, i18n_checkboxgroup_optionalText, i18n_checkboxgroup_helpText, optional, disabled, toggleController, children, ...otherProps } = this.props;
    let optionalIndicator = null;
    if (optional === true) {
      optionalIndicator = i18n_checkboxgroup_optionalText;
    }

    let inputHelp = null;
    if (i18n_checkboxgroup_helpText) {
      const HelpAll = require('../InputHelp'); // eslint-disable-line global-require
      const HelpComponent = HelpAll.default ?? HelpAll;
      inputHelp = <HelpComponent className={styles.checkboxgroup__helptext} id={`${this.htmlId}Help`}>{i18n_checkboxgroup_helpText}</HelpComponent>;
    }

    const ErrorAll = require('../InputError'); // eslint-disable-line global-require
    const ErrorComponent = ErrorAll.default ?? ErrorAll;
    const inputError  = <ErrorComponent id={`${this.htmlId}Error`}>{i18n_checkboxgroup_errorMessage}</ErrorComponent>;

    const allClasses = classNames([
      styles[`checkbox-group`],
      i18n_checkboxgroup_errorMessage ? styles[`checkbox-group--error`] : null,
      disabled ? styles[`checkbox-group-disabled`] : null,
      label ? styles[`has-label`] : null,
      className
    ]);

    return (
      <fieldset id={this.htmlId} key={this.htmlId} role="group" className={allClasses} {...otherProps} disabled={disabled}>
        {label || optionalIndicator ? (
          <legend className={styles[`checkbox-group--labelarea-label`]}>
            <span className={styles[`checkbox-group--labelarea-label-content`]}>
              {label}
              {label && optionalIndicator ? ' ' : null}
              {optionalIndicator
                ? (<span className={styles[`checkbox-group--labelarea-optional-indicator`]}>{optionalIndicator}</span>)
                : null
              }
            </span>
            {i18n_checkboxgroup_infoText ? <Popover triggerElement={<IconInformationRegular />} placement="top" i18n_popover_contentText={i18n_checkboxgroup_infoText} /> : null}
          </legend>
        ) : null}
        {children}
        {inputError}
        {inputHelp}
      </fieldset>
    );
  }
}

CheckboxGroup.propTypes = {
  /**
   * Group's id
   */
  id: PropTypes.string,
  /**
   * Any CSS classes for the component
   */
  className: PropTypes.string,
  /**
   * Label text for the checkbox group
   */
  label: PropTypes.string,
  /**
   * Whether field is optional or not. By default all fields are mandatory.
   */
  optional: PropTypes.bool,
  /**
   * Optional field indicator text
   */
  i18n_checkboxgroup_optionalText: PropTypes.string,
  /**
   * Checkbox's error
   */
  i18n_checkboxgroup_errorMessage: PropTypes.string,
  /**
   * Additional information and instructions for the checkbox group
   */
  i18n_checkboxgroup_infoText: PropTypes.string,
  /**
   * Help text for the checkbox group.
   */
  i18n_checkboxgroup_helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Component's content. Automatically detected.
   */
  children: PropTypes.node,
  /**
   * Whether all the Checkboxes in the group are disabled.
   * NOTE: This is done via CSS. We recommend disabling each Checkbox separately.
   */
  disabled: PropTypes.bool,
  /**
   *
   */
  toggleController: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  id: null,
  className: null,
  label: null,
  i18n_checkboxgroup_infoText: null,
  i18n_checkboxgroup_helpText: null,
  i18n_checkboxgroup_optionalText: '(ei pakollinen)',
  i18n_checkboxgroup_errorMessage: null,
  optional: false,
  children: null,
  disabled: null,
  toggleController: null,
};

Checkbox.Group = CheckboxGroup;

export { CheckboxGroup };
export default Checkbox;
