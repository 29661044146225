import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowRightAltFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 11.998c0 .472-.194.931-.534 1.258l-6.846 6.642a1.247 1.247 0 0 1-1.767-.027 1.25 1.25 0 0 1 .027-1.768l5.002-4.853H1.25a1.25 1.25 0 0 1 0-2.5h18.636l-5.002-4.853a1.25 1.25 0 1 1 1.741-1.795l6.844 6.639c.337.326.531.785.531 1.257"/></svg>)}
    />
  );
}
  