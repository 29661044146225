import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoHuaweiRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10.408 3.563S8.645 3.475 7.5 4.973c-1.146 1.587-.265 3.878.264 5.112s3.349 5.905 3.437 5.993.264.088.264 0 .353-6.786.088-8.46c-.264-1.763-1.057-3.878-1.145-4.055M4.327 6.736c-.176 0-1.674 1.498-1.763 2.996s.441 2.468 2.115 3.613c1.674 1.234 5.728 3.525 5.816 3.349s-1.586-3.261-2.908-5.464-3.084-4.494-3.26-4.494m.529 13.572c1.234.529 3.085-.705 3.613-1.058.529-.353 1.41-1.146 1.41-1.146l-6.874.176c0 .001.617 1.411 1.851 2.028m.176-5.817c-1.234-.617-3.79-2.115-3.878-2.115s-.441 1.851.353 3.261 2.379 1.851 3.085 1.939c.793.088 5.464.088 5.464 0-.089-.176-3.79-2.468-5.024-3.085m11.457-9.429c-1.146-1.498-2.908-1.41-2.908-1.41-.088.176-.881 2.291-1.146 3.966s.088 8.372.088 8.46.088.176.264 0 2.908-4.759 3.437-5.993 1.41-3.525.265-5.023m6.345 7.314c-.088 0-2.644 1.498-3.878 2.115s-4.935 2.996-4.935 3.085 4.671.176 5.464 0c.705-.088 2.291-.529 3.085-1.939.705-1.41.352-3.261.264-3.261m-7.315 6.786c.529.353 2.379 1.674 3.613 1.058s1.851-2.027 1.851-2.027l-6.874-.176c0 .088.881.793 1.41 1.145m5.905-9.43c-.088-1.498-1.586-2.996-1.763-2.996s-1.939 2.291-3.261 4.495-2.996 5.2-2.908 5.464 4.054-2.115 5.816-3.349c1.675-1.146 2.292-2.204 2.116-3.614"/></svg>)}
    />
  );
}
  