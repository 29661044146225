// @flow

import { CancelToken, CancelTokenSource } from 'axios';
import React, { Component, type Element } from 'react';
import { bindActionCreators, compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { operations as userOps, selectors as userSelect } from '../../../ducks/entities/user';
import type { KeyValuePairT, StoreStateT } from '../../../commonTypes';
import type { DepartmentStateEntityT } from '../../../ducks/entities/department';
import TabSelector from '../../../components/TabSelector';
import type { InternalUserStateEntityT } from '../../../ducks/entities/user/userTypes';
import DepartmentModifyView from './DepartmentModifyView';
import DepartmentPeopleView from './DepartmentPeopleView';
import DepartmentMovePeople from './DepartmentMovePeople';
import type { ActiveTabT } from '../../../ducks/ui/department/departmentUiTypes';
import { actionCreators as departmentUiActions } from '../../../ducks/ui/department';
import BaseModal from '../../../components/BaseModal';
import DepartmentAddPeople from './DepartmentAddPeople';
import styles from './DepartmentDetails.module.scss';

export type OwnPropsT = {
  departmentId: ?string,
  enterpriseId: string,
  activeTab: ActiveTabT,
  onClose: () => *,
  removeDepartment: *
};

type StatePropsT = {
  department: DepartmentStateEntityT,
  userMoveTargetDepartment: KeyValuePairT<>,
  departmentDeleteUserMoveTargetDepartment: KeyValuePairT<>,
  isLoadingUsers: boolean
};

type DispatchPropsT = {
  retrieveAllDepartmentUsers: typeof userOps.retrieveCompleteCollectionForDepartment,
  select: typeof departmentUiActions.createSelectDepartmentUser
};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  ...$Exact<WithTranslationProps>
|};

type StateT = {|
  activeTab: ActiveTabT,
  movingUsersBeforeDepartmentDelete: boolean,
  departmentUsers: InternalUserStateEntityT[]
|};

export class DepartmentDetails extends Component<PropsT, StateT> {
  static disableBackgroundScrolling() {
    // $FlowFixMe  disable background scrolling
    document.body.style.overflow = 'hidden';
  }

  static restoreBackgroundScrolling() {
    // $FlowFixMe  restore background scrolling
    document.body.style.overflow = 'auto';
  }

  constructor(props: PropsT) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      movingUsersBeforeDepartmentDelete: false,
      departmentUsers: []
    };
    this.setActiveTab = this.setActiveTab.bind(this);
    this.handleOnDeleteDepartment = this.handleOnDeleteDepartment.bind(this);
    this.deleteDepartmentAfterUsersMoved = this.deleteDepartmentAfterUsersMoved.bind(this);
    this.retrieveUsers = this.retrieveUsers.bind(this);
    this.setDepartmentUsers = this.setDepartmentUsers.bind(this);
  }

  setDepartmentUsers: (InternalUserStateEntityT[]) => void;

  setDepartmentUsers(users: InternalUserStateEntityT[]) {
    this.setState({
      departmentUsers: users
    });
  }

  async componentDidMount() {
    const {
      retrieveAllDepartmentUsers,
      enterpriseId,
      department: { id: departmentId }
    } = this.props;

    this.requestCancelTokenSource = CancelToken.source();

    const users = await retrieveAllDepartmentUsers(
      { enterpriseId, departmentId },
      this.requestCancelTokenSource.token
    );
    if (users && users.length > 0) {
      this.setDepartmentUsers(users);
    }

    DepartmentDetails.disableBackgroundScrolling();
  }

  componentWillUnmount() {
    this.requestCancelTokenSource.cancel();

    DepartmentDetails.restoreBackgroundScrolling();
  }

  setActiveTab: ActiveTabT => void;

  setActiveTab(tab: ActiveTabT) {
    this.setState({ activeTab: tab });
  }

  handleOnDeleteDepartment: *;

  async handleOnDeleteDepartment(shouldMoveUsers: boolean): Promise<*> {
    const { department, select, enterpriseId, removeDepartment } = this.props;
    const { departmentUsers } = this.state;
    if (shouldMoveUsers) {
      departmentUsers.forEach(user =>
        select(enterpriseId, department.id, { extensionId: user.id, personId: user.personId })
      );
      this.setState({
        movingUsersBeforeDepartmentDelete: true
      });
      this.setActiveTab('movingPeople');
    } else {
      await removeDepartment(department, departmentUsers, undefined, true);
    }
  }

  deleteDepartmentAfterUsersMoved: *;

  async deleteDepartmentAfterUsersMoved() {
    const { department, removeDepartment } = this.props;
    await removeDepartment(department, [], undefined, false);
  }

  requestCancelTokenSource: CancelTokenSource;

  retrieveUsers: *;

  async retrieveUsers() {
    const {
      enterpriseId,
      retrieveAllDepartmentUsers,
      department: { id: departmentId }
    } = this.props;
    const users = await retrieveAllDepartmentUsers(
      { enterpriseId, departmentId },
      this.requestCancelTokenSource.token
    );
    this.setState({
      departmentUsers: users
    });
  }

  render(): Element<typeof BaseModal> {
    const { onClose, departmentId, department, t, isLoadingUsers } = this.props;
    const { activeTab, departmentUsers } = this.state;
    const showPeople = activeTab === 'people';
    const showModify = activeTab === 'modify';
    const showMovePeople = activeTab === 'movingPeople';
    const showAddPeople = activeTab === 'addingPeople';

    const tabSelector = (
      <TabSelector
        selectedTab={activeTab}
        tabs={[
          {
            tabName: 'modify',
            title: t('enterprise.departmentDetails.modifyTabLabel'),
            onClick: () => this.setActiveTab('modify')
          },
          {
            tabName: 'people',
            title: t('enterprise.departmentDetails.peopleTabLabel'),
            onClick: () => this.setActiveTab('people')
          }
        ]}
      />
    );

    return (
      <BaseModal modalStyles={[styles.modal]} onClose={onClose}>
        <div>
          {departmentId ? (
            <h4 className={styles['section-title']}>{department.name}</h4>
          ) : (
            <h4 className={styles['section-title']}>
              {t('enterprise.departmentDetails.noDepartmentHeader')}
            </h4>
          )}
          {departmentId && !showMovePeople && !showAddPeople && tabSelector}
          {showPeople && (
            <DepartmentPeopleView
              enterpriseId={this.props.enterpriseId}
              departmentId={this.props.department.id}
              setActiveTab={this.setActiveTab}
              isLoadingUsers={isLoadingUsers}
              departmentUsers={departmentUsers}
            />
          )}
          {showModify && (
            <DepartmentModifyView
              setActiveTab={this.setActiveTab}
              department={department}
              enterpriseId={this.props.enterpriseId}
              onDeleteDepartment={this.handleOnDeleteDepartment}
            />
          )}
          {showMovePeople && (
            <DepartmentMovePeople
              setActiveTab={this.setActiveTab}
              enterpriseId={this.props.enterpriseId}
              departmentId={this.props.department.id}
              destinationDepartment={
                /* eslint-disable no-nested-ternary */
                this.state.movingUsersBeforeDepartmentDelete
                  ? this.props.departmentDeleteUserMoveTargetDepartment
                  : this.props.userMoveTargetDepartment
              }
              onDoneMovingUsers={
                this.state.movingUsersBeforeDepartmentDelete
                  ? this.deleteDepartmentAfterUsersMoved
                  : () => {}
              }
              onRetrieveUsers={this.retrieveUsers}
            />
          )}
          {showAddPeople && (
            <DepartmentAddPeople
              setActiveTab={this.setActiveTab}
              enterpriseId={this.props.enterpriseId}
              departmentId={this.props.department.id}
              destinationDepartment={
                /* eslint-disable no-nested-ternary */
                this.state.movingUsersBeforeDepartmentDelete
                  ? this.props.departmentDeleteUserMoveTargetDepartment
                  : { value: this.props.department.id, key: this.props.department.name }
              }
              onDoneMovingUsers={
                this.state.movingUsersBeforeDepartmentDelete
                  ? this.deleteDepartmentAfterUsersMoved
                  : () => {}
              }
              onRetrieveUsers={this.retrieveUsers}
            />
          )}
        </div>
      </BaseModal>
    );
  }
}

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => {
  const departmentId = ownProps.departmentId || '';
  const department = state.entities.department.byId[departmentId] || '';
  return {
    department,
    userMoveTargetDepartment: formValueSelector('departmentDetailsPeople')(
      state,
      'people.moveDepartment'
    ),
    departmentDeleteUserMoveTargetDepartment: formValueSelector('departmentDetails')(
      state,
      'destinationDepartment'
    ),
    isLoadingUsers: userSelect.collectionIsLoading(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retrieveAllDepartmentUsers: userOps.retrieveCompleteCollectionForDepartment,
      select: departmentUiActions.createSelectDepartmentUser
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps)
)(DepartmentDetails);
