// @flow

import React, { type Element } from 'react';
import type { UserListElementT } from '../../ducks/entities/user/userTypes';

export type OwnPropsT = {|
  selectedItems: UserListElementT[],
  renderListEditItem: (*) => *
|};

export type PropsT = OwnPropsT;

const ListEdit = (props: PropsT): Element<'div'> => {
  const { renderListEditItem, selectedItems } = props;
  return <div>{selectedItems.map(item => renderListEditItem(item))}</div>;
};

export default ListEdit;
