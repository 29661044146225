import React from 'react';
import IconContainer from '../IconContainer';

export default function IconServicesRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 11.25H3A2.25 2.25 0 0 1 .75 9V3A2.25 2.25 0 0 1 3 .75h6A2.25 2.25 0 0 1 11.25 3v6A2.25 2.25 0 0 1 9 11.25m-6-9a.76.76 0 0 0-.75.75v6a.76.76 0 0 0 .75.75h6A.76.76 0 0 0 9.75 9V3A.76.76 0 0 0 9 2.25zm18 9h-6A2.25 2.25 0 0 1 12.75 9V3A2.25 2.25 0 0 1 15 .75h6A2.25 2.25 0 0 1 23.25 3v6A2.25 2.25 0 0 1 21 11.25m-6-9a.76.76 0 0 0-.75.75v6a.76.76 0 0 0 .75.75h6a.76.76 0 0 0 .75-.75V3a.76.76 0 0 0-.75-.75zm-6 21H3A2.25 2.25 0 0 1 .75 21v-6A2.25 2.25 0 0 1 3 12.75h6A2.25 2.25 0 0 1 11.25 15v6A2.25 2.25 0 0 1 9 23.25m-6-9a.76.76 0 0 0-.75.75v6a.76.76 0 0 0 .75.75h6a.76.76 0 0 0 .75-.75v-6a.76.76 0 0 0-.75-.75zm18 9h-6A2.25 2.25 0 0 1 12.75 21v-6A2.25 2.25 0 0 1 15 12.75h6A2.25 2.25 0 0 1 23.25 15v6A2.25 2.25 0 0 1 21 23.25m-6-9a.76.76 0 0 0-.75.75v6a.76.76 0 0 0 .75.75h6a.76.76 0 0 0 .75-.75v-6a.76.76 0 0 0-.75-.75z"/></svg>)}
    />
  );
}
  