import React from 'react';
import IconContainer from '../IconContainer';

export default function IconReplyLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14 7h-2.5V4.52a2 2 0 0 0-2-2 1.8 1.8 0 0 0-1.37.64l-5.63 6a2 2 0 0 0 0 2.74l5.62 6a1.91 1.91 0 0 0 3.38-1.4V14H14a7 7 0 0 1 7 7 .5.5 0 0 0 1 0v-6a8 8 0 0 0-8-8m7 10.13A8 8 0 0 0 14 13h-3a.5.5 0 0 0-.5.5v3a1 1 0 0 1-.27.73.9.9 0 0 1-1.273.014q-.035-.035-.067-.074l-5.62-6a1 1 0 0 1 0-1.38l5.63-6a.8.8 0 0 1 .64-.33 1 1 0 0 1 1 1v3a.5.5 0 0 0 .5.5h3a7 7 0 0 1 7 7z"/></svg>)}
    />
  );
}
  