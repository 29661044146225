import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Input from '../../Input';

import globalStyles from '../../../globals/scss/_typography.scss';
import styles from '../Login.module.scss';

import { classNames } from '../../../utils/css';

class Auth2Factor extends Component {
  static propTypes = {
    /** Container classname */
    className: PropTypes.string,
    /** Whether the first input field is automatically focused or not */
    autofocus: PropTypes.bool,
    /** Interface showing error messsage */
    errorMessage: PropTypes.element,
    /** Phone number in the format *******123 */
    censoredMsisdn: PropTypes.string.isRequired,

    /** Object with i18n strings */
    translations: PropTypes.object,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { id, className, autofocus, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, errorMessage, auth2FactorNewCodeClick, censoredMsisdn, wide } = this.props;
    const {
      i18n_login_auth2FactorText,
      i18n_login_auth2FactorPlaceholder,
      i18n_login_auth2FactorLabel,
      i18n_login_auth2FactorDeviceShouldBeRememberedLabel,
      i18n_login_loginButtonLabel,
      i18n_login_header,
      i18n_login_header_usernamePassword,
      i18n_login_auth2FactorNewCodeText,
    } = this.props.translations;

    return (
      <form onSubmit={handleSubmit} className={className}>
        <div id={id} ref={this.myRef}>
          <h3 className={classNames([globalStyles['h3--book'], styles['login__view--title']])}>{i18n_login_header_usernamePassword || i18n_login_header}</h3>

          {errorMessage}

          <p className={styles[`login__2-factor-text`]}>
            {i18n_login_auth2FactorText}
            &nbsp;
            {censoredMsisdn}
          </p>

          <Input
            className={styles.login__input}
            name="authcode"
            onValueChange={handleChange}
            onBlur={handleBlur}
            placeholder={i18n_login_auth2FactorPlaceholder}
            label={i18n_login_auth2FactorLabel || i18n_login_auth2FactorPlaceholder}
            value={values.authcode}
            touched={touched.authcode}
            error={errors.authcode}
            wide
            autoFocus
            optionalText=""
            maxlength="6"
          />
          <Checkbox checked={values.deviceshouldberemembered} disabled={isSubmitting} label={i18n_login_auth2FactorDeviceShouldBeRememberedLabel} name="deviceshouldberemembered" onChange={handleChange} onBlur={handleBlur} />
          <div className={styles[`login__new-2-factor-code`]}>
            <a href="#" onClick={(e) => auth2FactorNewCodeClick(e, values.username)}>
              {i18n_login_auth2FactorNewCodeText}
            </a>
          </div>
          <Button className={styles[`login__submit-button`]} id="button-login-with-2factor" size="l" block type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
            {i18n_login_loginButtonLabel}
          </Button>
        </div>
      </form>
    );
  }
}

export const validate = (values, errors, translations) => {
  if (!values.authcode) {
    errors.authcode = translations.i18n_login_errorAuth2FactorRequired;
  }

  const validCode = /^\d{6}$/;
  if (!validCode.test(values.authcode)) {
    errors.authcode = translations.i18n_login_errorAuth2FactorInvalidCode;
  }
};

export default Auth2Factor;
