import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInvoiceMultipleLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2 2.5c0-.3.2-.5.5-.5h13c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-13C1.7 1 1 1.7 1 2.5v15c0 .3.2.5.5.5s.5-.2.5-.5zm3 2c-.3 0-.5.2-.5.5v15.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5V5c0-.8.7-1.5 1.5-1.5h13.5c.3 0 .5.2.5.5s-.2.5-.5.5zm4 5c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5h-5c-.3 0-.5-.2-.5-.5m7 3c0 .3-.2.5-.5.5h-6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6c.3 0 .5.2.5.5m-2 3c0 .3-.2.5-.5.5h-4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h4c.3 0 .5.2.5.5m5.2 0c0 .4-.3.8-.8.8s-.8-.3-.8-.8.3-.8.8-.8.8.4.8.8m0-3c0 .4-.3.8-.8.8s-.8-.3-.8-.8.3-.8.8-.8.8.4.8.8m0-3c0 .4-.3.8-.8.8s-.8-.3-.8-.8.3-.8.8-.8.8.4.8.8M20.5 6h-13C6.7 6 6 6.7 6 7.5V23c0 .2.1.5.2.6.2.3.5.4.8.4.2 0 .4-.1.6-.2l3.4-2.6 2.4 2.7q.6.45 1.2 0l2.4-2.7 3.4 2.6c.2.2.4.2.6.2.6 0 1-.4 1-1V7.5c0-.8-.7-1.5-1.5-1.5m.5 17-3.7-2.9q-.3-.15-.6 0L14 23l-2.7-2.9c-.1-.1-.2-.1-.3-.1s-.2 0-.3.1L7 23V7.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5z"/></svg>)}
    />
  );
}
  