import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMoneyFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 15V6a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h17a2 2 0 0 0 2-2M4.12 9.12a1 1 0 1 1 0-2 1 1 0 0 1 0 2m6.38 4.63a3.25 3.25 0 1 1 3.25-3.25 3.26 3.26 0 0 1-3.25 3.25m6.38.13a1 1 0 1 1 0-2 1 1 0 0 1 0 2m4.814 6.142H5.5a.75.75 0 0 1 0-1.5h16.194a.784.784 0 0 0 .783-.784V9.5a.75.75 0 0 1 1.5 0v8.238a2.285 2.285 0 0 1-2.283 2.284"/></svg>)}
    />
  );
}
  