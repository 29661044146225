// @flow

import { type StoreStateT } from './commonTypes';

type LoadStateFnT = () => $Shape<StoreStateT> | void;
export const loadState: LoadStateFnT = () => {
  try {
    const serializedState: ?string = localStorage.getItem('state');
    if (serializedState != null) {
      const state: $Shape<StoreStateT> = JSON.parse(serializedState);
      return state;
    }
    return undefined;
  } catch (err) {
    return undefined; // User has disabled localStorage
  }
};

type SaveStateFnT = ($Shape<StoreStateT>) => void;
export const saveState: SaveStateFnT = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    /* User has disabled localStorage */
  }
};
