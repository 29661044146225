// @flow

/* eslint-disable no-underscore-dangle */

import React from 'react';
import { connect } from 'react-redux';

import type { ExactPropsT, KeyValuePairT, StoreStateT } from '../../../commonTypes';
import type { DepartmentStateEntityT } from '../../../ducks/entities/department/index';
import { selectors as selectDepartment } from '../../../ducks/entities/department/index';
import styles from './DepartmentComboboxItem.module.scss';

type OwnPropsT = {
  _value: ?string, // eslint-disable-line react/no-unused-prop-types
  emptySelection: KeyValuePairT<>
};

type StatePropsT = {
  department: ?DepartmentStateEntityT,
  pathWithNames: Array<{ id: string, name: ?string }>
};

export type PropsT = ExactPropsT<OwnPropsT, StatePropsT>;

export const DepartmentComboboxItem = (props: PropsT) => {
  const { pathWithNames, emptySelection } = props;

  const pathElements = pathWithNames.map((segment, index) => (
    <span key={segment.id}>
      {index > 0 && <span>{' > '}</span>}
      <span
        className={`${styles.segment} ${index === pathWithNames.length - 1 ? styles.bold : ''}`}
        key={segment.id}
      >
        {segment.name}
      </span>
    </span>
  ));
  return (
    <span className={styles.container}>
      {pathWithNames.length > 0 ? pathElements : emptySelection.key}
    </span>
  );
};

const mapStateToProps = (state: StoreStateT, ownProps: OwnPropsT) => {
  const department = ownProps._value ? state.entities.department.byId[ownProps._value] : null;
  return {
    department,
    pathWithNames: department ? selectDepartment.pathWithNames(state, department.path) : []
  };
};

export default connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps)(DepartmentComboboxItem);
