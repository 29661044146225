import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCalendarTimeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fillRule="evenodd" d="M1.5 8v8c0 .4.3.8.8.8h6.5c.4 0 .8.3.8.8s-.3.8-.8.8H2.2C1 18.2 0 17.2 0 16V4c0-1.2 1-2.2 2.2-2.2h2.5v-1c.1-.5.4-.8.8-.8s.8.3.8.8v1h5.5v-1c0-.4.3-.8.8-.8s.8.3.8.8v1h2.5C17 1.8 18 2.8 18 4v4.8c0 .4-.3.8-.8.8s-.8-.3-.8-.8V8zm3.3-4.8v1c0 .5.3.8.7.8s.8-.3.8-.8v-1h5.5v1c0 .4.3.8.8.8s.8-.3.8-.8v-1h2.5c.4 0 .8.3.8.8v2.5h-15V4c0-.4.3-.8.8-.8zm13.4 11.6c0-.4-.3-.8-.8-.8s-.8.3-.8.8v2.8c0 .4.3.8.8.8h2.8c.4 0 .8-.3.8-.8s-.3-.8-.8-.8h-2zm-.7 9.2c3.6 0 6.5-2.9 6.5-6.5S21.1 11 17.5 11 11 13.9 11 17.5s2.9 6.5 6.5 6.5m0-1.5c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5"/></svg>)}
    />
  );
}
  