import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRemoveRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.5 12.75h-15a.75.75 0 0 1 0-1.5h15a.75.75 0 0 1 0 1.5"/></svg>)}
    />
  );
}
  