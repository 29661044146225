import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilterFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.2 13.5c0 1.5-1.1 2.8-2.5 3.2v5.9c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-5.9C1.7 16.4.6 15.1.6 13.5s1.1-2.8 2.5-3.2V1.5c0-.4.3-.8.8-.8s.8.3.8.8v8.9c1.5.3 2.5 1.6 2.5 3.1m5.6-10.2V1.5c0-.4-.3-.8-.8-.8s-.8.3-.8.8v1.8C9.8 3.7 8.8 5 8.8 6.5s1.1 2.8 2.5 3.2v12.8c0 .4.3.8.8.8s.8-.3.8-.8V9.7c1.4-.3 2.5-1.6 2.5-3.2s-1.2-2.8-2.6-3.2m10.4 14.2c0-1.5-1.1-2.8-2.5-3.2V1.5c0-.4-.3-.8-.8-.8s-.8.3-.8.8v12.8c-1.4.3-2.5 1.6-2.5 3.2s1.1 2.8 2.5 3.2v1.8c0 .4.3.8.8.8s.8-.3.8-.8v-1.8c1.5-.4 2.5-1.7 2.5-3.2"/></svg>)}
    />
  );
}
  