import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCameraFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.4 13c0 2.4-2 4.4-4.4 4.4s-4.4-2-4.4-4.4 2-4.4 4.4-4.4 4.4 2 4.4 4.4M24 8.3v10.9c-.2 1.2-1.218 1.9-2.5 1.9H2C.9 21-.1 19.9 0 18.7V8.3c0-1 .4-2.2 2.5-2.2h6c.3 0 .5-.3.9-.8 1-1.4 1.6-2.2 2.7-2.2h5c1.4 0 2 1.3 2.6 2.7.1.2.3.3.5.3h1.3c2 0 2.5 1.2 2.5 2.2M4.9 9.9c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1.5 1.1 1.1 1.1 1.1-.5 1.1-1.1M20.6 13c0-3.1-2.5-5.6-5.6-5.6S9.4 9.9 9.4 13s2.5 5.6 5.6 5.6 5.6-2.5 5.6-5.6"/></svg>)}
    />
  );
}
  