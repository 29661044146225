import React from 'react';
import IconContainer from '../IconContainer';

export default function IconGlobeLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0M1.025 12.5h4.989c.031 1.735.219 3.42.549 5H2.488a10.9 10.9 0 0 1-1.463-5m4.989-1H1.025a10.9 10.9 0 0 1 1.463-5h4.075a27 27 0 0 0-.549 5m1.569-5h8.852c.342 1.569.537 3.256.568 5H7.014c.032-1.744.227-3.431.569-5m-.569 6h9.988a26 26 0 0 1-.568 5H7.583a26 26 0 0 1-.569-5m10.988 0h4.972a10.9 10.9 0 0 1-1.463 5h-4.058c.33-1.579.519-3.264.549-5m0-1a27 27 0 0 0-.549-5h4.058a10.9 10.9 0 0 1 1.463 5zm2.852-6h-3.625c-.351-1.379-.81-2.662-1.373-3.789A11.04 11.04 0 0 1 20.854 5.5m-6.378-4.208c.716 1.194 1.292 2.625 1.716 4.208H7.824c.424-1.586 1.001-3.018 1.718-4.213A11 11 0 0 1 12 1c.853 0 1.679.107 2.476.292m-6.311.411C7.6 2.833 7.14 4.118 6.788 5.5H3.146a11.03 11.03 0 0 1 5.019-3.797M3.146 18.5h3.642c.352 1.382.812 2.667 1.377 3.797A11.03 11.03 0 0 1 3.146 18.5m6.396 4.213c-.717-1.195-1.294-2.628-1.718-4.213h8.368c-.423 1.584-.999 3.014-1.715 4.208-.797.185-1.624.292-2.477.292-.846 0-1.666-.105-2.458-.287m6.312-.423c.564-1.128 1.023-2.41 1.375-3.79h3.625a11.04 11.04 0 0 1-5 3.79"/></svg>)}
    />
  );
}
  