import React from 'react';
import IconContainer from '../IconContainer';

export default function IconAddCircleFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m6.5 12.6h-5.9v5.9c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-5.9H5.5c-.3 0-.6-.3-.6-.6s.3-.6.6-.6h5.9V5.5c0-.3.3-.6.6-.6s.6.3.6.6v5.9h5.9c.3 0 .6.3.6.6s-.3.6-.6.6"/></svg>)}
    />
  );
}
  