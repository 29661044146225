// @flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../components/Dialog';
import type { CallForwardingT } from '../../../ducks/entities/callForwarding/callForwardingTypes';
import { getTextRepresentation } from '../../../helpers';
import { updateCallForwarding } from '../../../ducks/entities/callForwarding/callForwardingOperations';
import { createCsrfHeader } from '../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../ducks/currentUser/currentUserTypes';
import styles from './ActivateEnterpriseForwardingDialog.module.scss';

type PropsT = {
  enterpriseId: string,
  forwarding: ?CallForwardingT,
  onClose: (reload: ?boolean) => Promise<void>
};

const ActivateEnterpriseForwardingDialog = (props: PropsT) => {
  const { enterpriseId, forwarding, onClose } = props;

  const { t } = useTranslation();
  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const [isUpdatingForwarding, setIsUpdatingForwarding] = useState<boolean>(false);
  const [hasUpdateError, setHasUpdateError] = useState<boolean>(false);
  const isActive = R.path(['isActive'], forwarding) || false;

  const generateCallForwardingDescription = (fwd: ?$Shape<CallForwardingT>): string => {
    if (fwd) {
      // $FlowFixMe complex type conversions for fwd
      const textRepresentation = getTextRepresentation(fwd, 'long', t).toString();
      return t('forwardings.deleteDialog.enterpriseCallForwardingDescription', {
        description: textRepresentation
      });
    }
    return '';
  };

  const updateForwardingActiveStatus = async (activeStatus: boolean) => {
    setIsUpdatingForwarding(true);

    let result;
    if (forwarding && forwarding.isActive !== activeStatus && forwarding.id) {
      result = await dispatch(
        updateCallForwarding(
          enterpriseId,
          {
            isActive: activeStatus,
            id: forwarding.id
          },
          createCsrfHeader(currentUser)
        )
      );
    }

    if (result && !result.error) {
      setIsUpdatingForwarding(false);
      setHasUpdateError(false);
      onClose(true);
    } else {
      setIsUpdatingForwarding(false);
      setHasUpdateError(true);
    }
  };

  const title = isActive
    ? t('forwardings.activateDialog.deactivate.enterpriseTitle')
    : t('forwardings.activateDialog.activate.enterpriseTitle');
  const description = isActive
    ? t('forwardings.activateDialog.deactivate.enterpriseDescription')
    : t('forwardings.activateDialog.activate.enterpriseDescription');
  return (
    <Dialog
      title={title}
      description={description}
      descriptionClassName={styles['fwd-description']}
      descriptionExtended={generateCallForwardingDescription(forwarding)}
      descriptionExtendedClassName={styles['fwd-description-extended']}
      confirmLabel={t('forwardings.activateDialog.confirm')}
      cancelLabel={t('forwardings.activateDialog.cancel')}
      disabled={false}
      loading={isUpdatingForwarding}
      onCancel={onClose}
      onConfirm={async () => {
        if (forwarding) {
          await updateForwardingActiveStatus(!isActive);
        }
      }}
      onClose={onClose}
      errorMessage={hasUpdateError ? t('forwardings.activateDialog.error') : undefined}
    />
  );
};

export default ActivateEnterpriseForwardingDialog;
