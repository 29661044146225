// @flow strict-local

import React, { type Element } from 'react';
import Truncate from 'react-truncate-markup';
import { useTranslation } from 'react-i18next';
import TrashBinButton from '../../../../../../components/Button/TrashBinButton';

import styles from './UnattachedStepRow.module.scss';

type PropsT = {|
  name: string,
  type: string,
  onRemove?: () => void
|};

export const UnattachedStepRow = (props: PropsT): Element<'div'> => {
  const { name, type, onRemove } = props;

  const { t } = useTranslation();

  const allowedTypes = ['START', 'AUDIO', 'TRANSFER', 'INTERACTION', 'CALENDAR'];
  const typeToShow = allowedTypes.includes(type) ? t(`callflows.unattachedStepRow.${type}`) : '';

  return (
    <div className={styles.row}>
      <span className={styles.name}>
        <Truncate lines={1}>
          <span>{name}</span>
        </Truncate>
      </span>{' '}
      <span className={styles.type}>({typeToShow})</span>
      {onRemove && (
        <TrashBinButton style={styles.remove} id={`remove-step-${name}`} onClose={onRemove} />
      )}
    </div>
  );
};

export default UnattachedStepRow;
