import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWebcamFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C6.2 0 1.5 4.7 1.5 10.5S6.2 21 12 21s10.5-4.7 10.5-10.5S17.8 0 12 0m0 2.6c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1m0 12c-2.3 0-4.1-1.8-4.1-4.1S9.7 6.4 12 6.4s4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1m9.9 8.6c.2.2.1.5-.1.7-.1.1-.2.1-.3.1h-19c-.3 0-.5-.2-.5-.5 0-.1 0-.2.1-.3L5 19.9c4.2 3.2 9.9 3.2 14.1 0z"/></svg>)}
    />
  );
}
  