import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMobileBroadbandLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.691 21H3.31A3.3 3.3 0 0 1 0 17.73V6.27A3.3 3.3 0 0 1 3.31 3h4.38A3.3 3.3 0 0 1 11 6.27v11.46A3.3 3.3 0 0 1 7.691 21M3.31 4A2.3 2.3 0 0 0 1 6.27v11.46A2.3 2.3 0 0 0 3.31 20h4.38A2.3 2.3 0 0 0 10 17.73V6.27A2.3 2.3 0 0 0 7.691 4zm11.231 11.54a5 5 0 0 0 .009-7.071l-.009-.009a.51.51 0 0 0-.71 0 .5.5 0 0 0-.003.707l.003.003a4 4 0 0 1 .003 5.657l-.003.003a.5.5 0 0 0-.003.707l.003.003a.52.52 0 0 0 .71 0m2.9 2.65a8.77 8.77 0 0 0 0-12.38.502.502 0 0 0-.71.71 7.76 7.76 0 0 1 .026 10.974l-.026.026a.51.51 0 0 0 0 .71.5.5 0 0 0 .35.14.5.5 0 0 0 .36-.18m2.901 2.65c4.878-4.884 4.878-12.796 0-17.68a.502.502 0 0 0-.71.71c4.49 4.484 4.495 11.759.011 16.249l-.011.011a.51.51 0 0 0 0 .71.5.5 0 0 0 .37.16.5.5 0 0 0 .34-.16"/></svg>)}
    />
  );
}
  