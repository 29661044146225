import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMaintenanceRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.255 24a3.25 3.25 0 0 1-2.303-.952 3.26 3.26 0 0 1 0-4.606L11.074 8.325a6.55 6.55 0 0 1 .562-4.907A6.52 6.52 0 0 1 15.545.269a6.52 6.52 0 0 1 4.991.537.748.748 0 0 1 .171 1.188l-3.128 3.13v1.3h1.3l3.13-3.129a.752.752 0 0 1 1.189.17 6.55 6.55 0 0 1 .414 5.369 6.5 6.5 0 0 1-3.381 3.711 6.5 6.5 0 0 1-4.552.381L5.559 23.048A3.25 3.25 0 0 1 3.255 24M17.399 1.501c-.479 0-.96.068-1.432.207a5.02 5.02 0 0 0-3.015 2.429 5.06 5.06 0 0 0-.319 4.141.75.75 0 0 1-.176.785L2.013 19.502a1.758 1.758 0 0 0 2.485 2.485L14.94 11.542a.75.75 0 0 1 .785-.175 5.02 5.02 0 0 0 3.867-.18 5.056 5.056 0 0 0 2.691-6.045l-2.564 2.563a.75.75 0 0 1-.53.22h-2.361a.75.75 0 0 1-.75-.75V4.813c0-.199.079-.39.22-.53l2.563-2.564a5 5 0 0 0-1.462-.218"/></svg>)}
    />
  );
}
  