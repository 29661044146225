import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoHonorRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.162 1.5v8.515H7.838V1.5H4.096v21h3.742v-8.744h8.324V22.5h3.742v-21z"/></svg>)}
    />
  );
}
  