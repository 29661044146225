// @flow

import * as R from 'ramda';
import React, { type Element } from 'react';
import { type FieldProps } from 'redux-form';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import type { LocationStateEntityT } from '../../ducks/entities/location';
import type { AddressT } from '../../ducks/entities/location/locationTypes';
import { NO_VALUE_FIELD_ID } from '../../helpers';
import Item from '../Item';

type OwnPropsT = FieldProps & {
  label: string,
  isSectionInEditMode: boolean,
  isSectionDisabled?: boolean,
  wrapperStyle: *,
  id: string,
  locations: LocationStateEntityT[],
  options: ?({ label: string, value: string }[]),
  updateDropdownValue?: (*) => void
};

export type PropsT = OwnPropsT;

const firstVisitingAddess = (locationId: string) =>
  // $FlowFixMe: compose missing in types
  R.compose(
    // eslint-disable-line
    R.head,
    R.filter(R.propEq('type', 'VISITING')),
    R.flatten,
    // $FlowFixMe: making AddressT exact might solve this
    R.pluck('addresses'),
    R.filter(R.propEq('id', locationId))
  );

const createValue = (option, locations) => {
  // $FlowFixMe
  const visitingAddress: ?AddressT = firstVisitingAddess(option.value)(locations);
  return (
    <span>
      {option.label}
      {visitingAddress && visitingAddress.zip && visitingAddress.address1 ? (
        <span>
          <br />
          {visitingAddress.address1},{visitingAddress.zip}
        </span>
      ) : null}
    </span>
  );
};

export const FormSite = ({
  wrapperStyle,
  isSectionInEditMode = false,
  isSectionDisabled = false,
  label,
  options,
  id,
  input,
  locations,
  updateDropdownValue,
  ...rest
}: PropsT): Element<'div'> => (
  <div className={wrapperStyle} id={id}>
    {isSectionInEditMode && (
      <Dropdown
        {...rest}
        label={label}
        selectedValue={input.value.value}
        onValueChange={option => {
          if (updateDropdownValue) {
            updateDropdownValue(option);
          }
        }}
        items={options}
      />
    )}
    {!isSectionInEditMode &&
      input.value &&
      input.value.value !== NO_VALUE_FIELD_ID &&
      input.value.label && (
        <Item
          classNames={['form-input-item']}
          label={label}
          id={`${id}_item`}
          value={createValue(input.value, locations)}
          disabled={isSectionDisabled}
        />
      )}
  </div>
);

export default FormSite;
