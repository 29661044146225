import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFilePdfFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.438 16.463h-.284v1.032h.166q.384 0 .561-.141c.109-.088.162-.214.162-.388q0-.267-.131-.381-.142-.121-.474-.122m4.3 0h-.358v2.609h.26c.832 0 1.219-.42 1.219-1.32 0-.879-.356-1.289-1.121-1.289"/><path d="M20.211 5.721a.25.25 0 0 1-.231.154h-4.484a.375.375 0 0 1-.375-.375V1.014a.25.25 0 0 1 .154-.231.25.25 0 0 1 .272.054l4.609 4.611a.25.25 0 0 1 .055.273M21 7.625l-.007 14.376A2 2 0 0 1 18.994 24H4.999A2 2 0 0 1 3 22V2a2 2 0 0 1 1.999-2h8.372a.5.5 0 0 1 .5.5v5c0 .897.727 1.625 1.624 1.625H20.5a.5.5 0 0 1 .5.5M9.285 16.933c0-.504-.16-.891-.475-1.15-.306-.255-.751-.383-1.323-.383H6.066a.144.144 0 0 0-.145.145v4.451c0 .08.065.145.145.145h.943c.08 0 .145-.064.145-.145v-1.438h.26c.584 0 1.046-.14 1.371-.415.332-.282.5-.689.5-1.21m4.844.795c0-.726-.218-1.302-.647-1.712-.427-.408-1.029-.615-1.792-.615h-1.397a.144.144 0 0 0-.145.145v4.451c0 .08.065.145.145.145h1.26c.821 0 1.462-.209 1.907-.624.443-.418.669-1.02.669-1.79m3.783-2.183a.144.144 0 0 0-.145-.145h-2.551a.144.144 0 0 0-.145.145v4.451c0 .08.064.145.145.145h.929c.08 0 .145-.064.145-.145v-1.615h1.365c.08 0 .145-.064.145-.145v-.771a.144.144 0 0 0-.145-.145H16.29v-.858h1.478c.08 0 .145-.064.145-.145v-.772z"/></svg>)}
    />
  );
}
  