// @flow strict-local

import { type $AxiosError } from 'axios';
import type {
  UpdateExtensionGroupCancelT,
  UpdateExtensionGroupFailureT,
  UpdateExtensionGroupRequestT,
  UpdateExtensionGroupSuccessT
} from '../callFlow/callFlowTypes';

import type { ExtensionGroupEntityT } from './extensionGroupTypes';

type CreateUpdateExtensionGroupRequestFnT = (string, string) => UpdateExtensionGroupRequestT;
export const createUpdateExtensionGroupRequest: CreateUpdateExtensionGroupRequestFnT = (
  enterpriseId,
  extensionGroupId
) => ({
  type: 'ring/entity/extgroup/UPDATE_REQUEST',
  error: false,
  meta: { enterpriseId, extensionGroupId }
});

type CreateUpdateExtensionGroupSuccessFnT = (
  string,
  ExtensionGroupEntityT
) => UpdateExtensionGroupSuccessT;
export const createUpdateExtensionGroupSuccess: CreateUpdateExtensionGroupSuccessFnT = (
  enterpriseId,
  extensionGroup
) => ({
  type: 'ring/entity/extgroup/UPDATE_SUCCESS',
  payload: { ...extensionGroup },
  error: false,
  meta: { enterpriseId, extensionGroupId: extensionGroup.id }
});

type CreateUpdateAcdAgentFailureFnT = (
  string,
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => UpdateExtensionGroupFailureT;
export const createUpdateExtensionGroupFailure: CreateUpdateAcdAgentFailureFnT = (
  enterpriseId,
  extensionGroupId,
  error
) => ({
  type: 'ring/entity/extgroup/UPDATE_FAILURE',
  payload: error,
  error: true,
  meta: { enterpriseId, extensionGroupId }
});

type CreateUpdateExtensionGroupCancelFnT = (string, string) => UpdateExtensionGroupCancelT;
export const createUpdateExtensionGroupCancel: CreateUpdateExtensionGroupCancelFnT = (
  enterpriseId,
  extensionGroupId
) => ({
  type: 'ring/entity/extgroup/UPDATE_CANCEL',
  payload: {},
  error: false,
  meta: { enterpriseId, extensionGroupId }
});
