import React from 'react';
import IconContainer from '../IconContainer';

export default function IconPilvilinnaRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 23.25H21.5a.75.75 0 0 1-.75-.75v-2.25h-1.5v2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-9.884L12 3.974l-2.75 8.643V22.5a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-2.25h-1.5v2.25a.75.75 0 0 1-.75.75H.75a.75.75 0 0 1 0-1.5h1V19.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25h1.5V12.5a.8.8 0 0 1 .035-.228l3.5-11a.75.75 0 0 1 1.43 0l3.5 11a.8.8 0 0 1 .035.228v9.25h1.5V19.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25h1a.75.75 0 0 1 0 1.5M1.5 17.5a2.25 2.25 0 0 1 2.25-2.227.75.75 0 0 0 .75-.75c0-1.395.897-2.624 2.232-3.06a.75.75 0 0 0 .48-.945.747.747 0 0 0-.945-.48 4.72 4.72 0 0 0-3.218 3.8C1.302 14.167 0 15.719 0 17.5a.75.75 0 0 0 1.5 0m22.5 0a3.77 3.77 0 0 0-3.034-3.697 6.81 6.81 0 0 0-3.901-5.484.75.75 0 1 0-.629 1.363 5.3 5.3 0 0 1 3.064 4.803c0 .414.336.75.75.75 1.24 0 2.25 1.017 2.25 2.266A.75.75 0 1 0 24 17.5m-10.75 1V15c0-.689-.561-1.25-1.25-1.25s-1.25.561-1.25 1.25v3.5c0 .414.336.75.75.75h1a.75.75 0 0 0 .75-.75"/></svg>)}
    />
  );
}
  