// @flow

import React, { type Element, useEffect, useState, useRef } from 'react';
import { Table, Button } from '@design-system/component-library';
import { useTranslation } from 'react-i18next';
import Input from '@design-system/component-library/src/components/Input';
import axios, { AxiosPromise, CancelToken, CancelTokenSource } from 'axios';
import HTTP from 'http-status-codes';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import Pagination from '@design-system/component-library/src/components/Pagination';
import Hero from '@design-system/component-library/src/components/Hero';
import Picture from '@design-system/component-library/src/components/Picture';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import IconArrowDownRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowDownRegular';
import IconArrowRightRegular from '@design-system/component-library/src/components/Icon/lib/IconArrowRightRegular';
import { createCsrfHeader } from '../../utils/accessRightUtils';
import CenterHorizontally from '../../components/CenterHorizontally/CenterHorizontally';
import HighlightText from '../users/SearchMatchRow/HighlightText';
import SearchMatchRow from './SearchMatchRow';
import { goTo } from '../../navigationOperations';
import ColumnSelect from './ColumnSelect/ColumnSelect';
import ContactCard from './ContactCard/ContactCard';
import BaseContainer from '../BaseContainer/BaseContainer';
import Header from './Header/Header';
import LinkButton from '../../components/Button/LinkButton';

import styles from './Phonebook.module.scss';

let requestPostSearchCancelTokenSource: CancelTokenSource;
export const Phonebook = (): Element<'div'> => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [phonebooks, setPhonebooks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPhonebook, setSelectedPhonebook] = useState();
  const phonebookResults = useRef([]);
  const [pagedResults, setPagedResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [selectedContact, setSelectedContact] = useState(false);
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();
  const columnSelectorRef = useRef(null);
  const pageSize = 20;

  const [visibleColumns, _setVisibleColumns] = useState([
    'enterprise',
    'lastName',
    'firstName',
    'phoneNumbers',
    'recordType'
  ]);

  // to preserve order on the table
  const availableColumns = [
    'enterprise',
    'recordType',
    'lastName',
    'firstName',
    'title',
    'emails',
    'phoneNumbers',
    'addressNumber',
    'additionalExplanations',
    'additionalInfo',
    'officeReferenceID',
    'costCenter',
    'department',
    'nickName',
    'corporateUserId',
    'accessCtrlSystemPersonId',
    'contactInformation'
  ];

  const setVisibleColumns = (value: string[]) => {
    _setVisibleColumns(availableColumns.filter(c => value.includes(c)));
  };

  const fetchPhonebooks = async () => {
    try {
      const phonebooksReturned: AxiosPromise<> = await axios({
        method: 'GET',
        url: `/api/v1/config/sharedphonebooks`
      });
      if (
        phonebooksReturned &&
        phonebooksReturned.status === HTTP.OK &&
        phonebooksReturned.data &&
        phonebooksReturned.data.length > 0
      ) {
        setPhonebooks(phonebooksReturned.data);
        setSelectedPhonebook(phonebooksReturned.data[0].id.toString());
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    requestPostSearchCancelTokenSource = CancelToken.source();
    fetchPhonebooks();
  }, []);

  const goToPage = (pageNum: number) => {
    const iStart = Math.max((pageNum - 1) * pageSize, 0);
    const iEnd = Math.min(iStart + pageSize, phonebookResults.current.length);
    setPagedResults(phonebookResults.current.slice(iStart, iEnd));
    setTotalCount(phonebookResults.current.length);
    setPage(pageNum);
  };

  const fetchResults = async () => {
    const phonebook = phonebooks.find(pb => pb.id.toString() === selectedPhonebook);

    if (phonebook && searchTerm) {
      if (requestPostSearchCancelTokenSource) {
        requestPostSearchCancelTokenSource.cancel();
        requestPostSearchCancelTokenSource = CancelToken.source();
      }
      const searchData = {
        environment: phonebook.environment,
        enterpriseIds: phonebook.enterpriseIds,
        searchTerm
      };
      try {
        setIsLoading(true);
        const response: AxiosPromise<> = await axios({
          method: 'POST',
          url: `/api/v1/phonebook`,
          cancelToken: requestPostSearchCancelTokenSource.token,
          data: searchData,
          headers: createCsrfHeader(currentUser)
        });
        if (response && response.status === HTTP.OK && response.data) {
          phonebookResults.current = response.data.results;
          goToPage(1);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setSelectedContact(null);
    setTotalCount(0);

    const delayDebounceFn = setTimeout(async () => {
      fetchResults();
    }, 1000);
    if (!searchTerm) {
      setIsLoading(false);
      phonebookResults.current = [];
      setPagedResults([]);
    }
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const phoneBookOptions = () => {
    return phonebooks.map(pb => ({ label: pb.name, value: pb.id.toString() }));
  };

  const isSameEmail = res =>
    res &&
    res.publicInfo &&
    res.publicInfo.emails &&
    currentUser.emails &&
    res.publicInfo.emails.includes(currentUser.emails);

  const formatPhoneNumbers = (numbers: string[]) => {
    return (
      <>
        {numbers.map(number => (
          <>
            <a href={`tel:${number}`}>
              <HighlightText text={number} highlight={searchTerm} />
            </a>
            <span>&nbsp;</span>
          </>
        ))}
      </>
    );
  };

  const formatValue = (raw: string) => {
    const MAX_CHARS = 80;
    let formatted = raw;
    let tooLong = false;
    if (raw && raw.length > MAX_CHARS) {
      formatted = raw.substring(0, MAX_CHARS);
      formatted += ' ... ';
      tooLong = true;
    }

    return (
      <>
        <HighlightText text={formatted} highlight={searchTerm} />
        {tooLong && (
          <LinkButton
            id="formatted"
            onClickAction={() => {}}
            label={t('phonebook.showMoreLabel')}
          />
        )}
      </>
    );
  };

  const getRows = () => {
    return pagedResults
      ? pagedResults.map(res => ({
          enterprise: (
            <div>
              {res.enterpriseFullName}
              <SearchMatchRow
                matchings={res.matchings || {}}
                rowId={res.id || ''}
                searchTerm={searchTerm}
                visibleColumns={
                  visibleColumns.includes('phoneNumbers')
                    ? [...visibleColumns, 'plmnNumbers', 'pstnNumbers']
                    : visibleColumns
                }
              />
            </div>
          ),
          lastName: <HighlightText text={res.publicInfo.lastName} highlight={searchTerm} />,
          firstName: formatValue(res.publicInfo.firstName || res.displayName),
          phoneNumbers: formatPhoneNumbers(
            [...(res.publicInfo.plmnNumbers || []), ...(res.publicInfo.pstnNumbers || [])] || []
          ),
          addressNumber: formatValue(res.publicInfo.addressNumber),
          title: formatValue(res.publicInfo.title),
          email: formatValue((res.publicInfo.emails || []).join(', ')),
          additionalExplanations: formatValue(res.publicInfo.additionalExplanations),
          additionalInfo: formatValue(res.publicInfo.additionalInfo),
          contactInformation: formatValue(res.publicInfo.contactInformation),
          officeReferenceID: formatValue(res.publicInfo.officeReferenceID),
          costCenter: formatValue(res.publicInfo.costCenter),
          department: formatValue(res.publicInfo.department),
          nickName: formatValue(res.publicInfo.nickName),
          corporateUserId: formatValue(res.publicInfo.corporateUserId),
          accessCtrlSystemPersonId: formatValue(res.publicInfo.accessCtrlSystemPersonId),
          recordType: <div>{t(`phonebook.recordTypes.${res.alias}`)}</div>
        }))
      : [];
  };

  const getRowClicks = () => {
    return pagedResults
      ? pagedResults.map(res => {
          if (isSameEmail(res)) {
            return () => {
              dispatch(
                goTo({
                  pathname: `/phonebook/${res.publicInfo.addressNumber}`,
                  state: {
                    directoryUser: res,
                    phonebook: phonebooks.find(pb => pb.id.toString() === selectedPhonebook)
                  }
                })
              );
            };
          }
          return () => {
            if (res.alias === 'IUser') setSelectedContact(res);
          };
        })
      : [];
  };

  const getColumns = () => {
    return visibleColumns
      ? visibleColumns
          .filter(key => {
            return visibleColumns.includes(key);
          })
          .map(column => ({
            ariaLabel: t(`phonebook.fieldLabels.${column}`),
            key: column,
            label: t(`phonebook.fieldLabels.${column}`)
          }))
      : [];
  };

  return (
    <BaseContainer header={<Header />}>
      <div className={styles['content-area']}>
        <>
          {selectedContact && (
            <ContactCard
              selectedContact={JSON.stringify(selectedContact)}
              selectedPhonebook={JSON.stringify(
                phonebooks.find(pb => pb.id.toString() === selectedPhonebook) || {}
              )}
              showEdit={isSameEmail(selectedContact)}
              onClose={() => {
                setSelectedContact(null);
              }}
            />
          )}
        </>
        <div className={styles['phonebook-header']}>
          <Hero
            bgImage={
              <Picture
                sources={[
                  {
                    srcSet: '/phonebook-hero-bg.svg'
                  }
                ]}
                fit="contain"
                position="right center"
              />
            }
            bgColor="white"
            gradientColor="white"
            mobileContentPosition="top"
          >
            <IconArrowRightRegular style={{ visibility: 'hidden' }} />
            <h1>{t('phonebook.sharedPhonebookTitle')}</h1>
          </Hero>
        </div>
        <div
          className={`${styles['phonebook-area']} ${
            selectedContact ? styles['mobile-hidden'] : ''
          }`}
        >
          {phonebooks.length > 1 && (
            <div className={styles['mobile-padded']}>
              <Dropdown
                items={phoneBookOptions()}
                label={t('phonebook.phonebooksLabel')}
                className={styles['phonebook-dropdown']}
                selectedValue={selectedPhonebook}
                onValueChange={option => {
                  setSearchTerm('');
                  setSelectedPhonebook(option.dataset.value);
                }}
              />
            </div>
          )}
          {phonebooks.length > 0 ? (
            <div>
              <div className={styles['mobile-padded']}>
                <Input
                  id="search-input"
                  name="search-input"
                  className={styles['search-input']}
                  label={t('phonebook.searchLabel')}
                  onValueChange={event => setSearchTerm(event.target.value)}
                  maxlength={40}
                  defaultValue={searchTerm}
                  optional
                  i18n_input_optionalText=""
                />
                <>
                  <Button
                    size="l"
                    color="light"
                    className={styles['column-select-button']}
                    icon={showColumnSelector ? <IconArrowDownRegular /> : <IconArrowRightRegular />}
                    onClick={() => setShowColumnSelector(!showColumnSelector)}
                  >
                    <span ref={columnSelectorRef}>{t('users.tableSettings')}</span>
                  </Button>
                  <div className={styles['search-info-label']}>
                    {t('phonebook.searchInfoLabel')}
                  </div>
                  {showColumnSelector && (
                    <ColumnSelect onChange={setVisibleColumns} visibleColumns={visibleColumns} />
                  )}
                </>
              </div>
              {isLoading ? (
                <CenterHorizontally>
                  <LoadingSpinner />
                </CenterHorizontally>
              ) : totalCount && totalCount > 0 ? (
                <>
                  {pagedResults && pagedResults.length > 0 && (
                    <div className={styles['number-of-results-area']}>
                      {t('users.numberOfUsers', {
                        visibleOfResults: `${(page - 1) * pageSize + 1} - ${Math.min(
                          page * pageSize,
                          totalCount
                        )}`,
                        numberOfUsers: totalCount
                      })}
                    </div>
                  )}
                  <Table
                    className={styles['result-table']}
                    id="tableDefaultUsage"
                    tableType="striped"
                    columns={getColumns()}
                    rows={getRows()}
                    rowClicks={getRowClicks()}
                  />
                  {pagedResults && pagedResults.length > 0 && (
                    <div className={styles['mobile-padded']}>
                      <Pagination
                        id="pbPagination"
                        firstPage={1}
                        lastPage={Math.ceil(totalCount / pageSize)}
                        currentPage={page}
                        onPageSelect={option => {
                          goToPage(parseInt(option, 10));
                        }}
                        onNextPageClick={() => {
                          goToPage(page + 1);
                        }}
                        onPreviousPageClick={() => {
                          goToPage(page - 1);
                        }}
                        i18n_pagination_previousText={t('onboarding.previous')}
                        i18n_pagination_nextText={t('onboarding.next')}
                      />
                    </div>
                  )}
                </>
              ) : (
                <h2 className={styles['info-text-large']}>{t('phonebook.noResultsLabel')}</h2>
              )}
            </div>
          ) : (
            <h2 className={styles['info-text-large']}>{t('phonebook.noPhonebookFound')}</h2>
          )}
        </div>
      </div>
    </BaseContainer>
  );
};

export default Phonebook;
