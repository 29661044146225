import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBulletsFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.2 4c0 1.2-1 2.2-2.2 2.2S.8 5.2.8 4s1-2.2 2.2-2.2 2.2 1 2.2 2.2m3.3 1.2h14.2c.7 0 1.3-.5 1.3-1.2s-.6-1.2-1.2-1.2H8.5c-.7 0-1.3.5-1.3 1.2s.6 1.2 1.3 1.2M3 9.8C1.8 9.8.8 10.8.8 12s1 2.2 2.2 2.2 2.2-1 2.2-2.2-1-2.2-2.2-2.2m19.8 1H8.5c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2h14.2c.7 0 1.2-.6 1.2-1.2s-.5-1.2-1.1-1.2M3 17.8c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2-1-2.2-2.2-2.2m19.8 1H8.5c-.7 0-1.2.6-1.2 1.2s.6 1.2 1.2 1.2h14.2c.7 0 1.2-.6 1.2-1.2s-.5-1.2-1.1-1.2"/></svg>)}
    />
  );
}
  