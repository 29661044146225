// @flow strict-local

import reducer from './efaxReducer';
import selectors from './efaxSelectors';
import operations from './efaxOperations';
import type { EfaxEntitiesT, EfaxEntityT, EfaxStateEntityT } from './efaxTypes';

export default reducer.rootReducer;
export { operations, selectors };
export type { EfaxEntitiesT, EfaxStateEntityT, EfaxEntityT };
