import React from 'react';
import IconContainer from '../IconContainer';

export default function IconThumbDownRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 14.78h-1.529a.25.25 0 0 1-.25-.25v-12a.25.25 0 0 1 .25-.25h1.529a.75.75 0 0 0 0-1.5h-1.529a1.75 1.75 0 0 0-1.749 1.75v.78C14.505.56 13.695.48 9.378.53H7.459C4.78.53 3.001 2.09 2.012 5.29v.08L.103 11.72v.07a2.75 2.75 0 0 0 .43 2.37 2.82 2.82 0 0 0 2.199 1.12h4.897c.39-.006.76.172.999.48.232.312.309.714.21 1.09l-.88 3.34a2.46 2.46 0 0 0 1.339 2.83c.312.149.654.227.999.23a2.44 2.44 0 0 0 1.999-1.05l4.927-6.89c.228-.327.6-.525.999-.53h1.739a1.76 1.76 0 0 0 1.729 1.5h1.529a.75.75 0 0 0 0-1.5zm-7.246-.34-4.887 6.93a1 1 0 0 1-1.189.32 1 1 0 0 1-.53-1.12l.88-3.34a2.75 2.75 0 0 0-2.648-3.45H2.731a1.3 1.3 0 0 1-.999-.51c-.23-.318-.3-.724-.19-1.1v-.07l1.909-6.35v-.07C4.23 3.17 5.49 2.02 7.459 2.02h1.939c4.198 0 4.648 0 10.575 3v8.29h-1.749a2.76 2.76 0 0 0-2.22 1.13"/></svg>)}
    />
  );
}
  