import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInboxLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.5s0-.1 0 0v-.2l-3.6-7c-.3-.5-.8-.8-1.4-.8H5c-.5 0-1 .3-1.3.8l-3.6 7v6.2C0 19.3.7 20 1.5 20h21c.8 0 1.5-.7 1.5-1.5zM4.6 5.7c.1-.1.2-.2.4-.2h14c.2 0 .3.1.4.2l3.3 6.3h-6.2c-.3 0-.5.2-.5.5v1c0 .8-.7 1.5-1.5 1.5h-5c-.8 0-1.5-.7-1.5-1.5v-1c0-.3-.2-.5-.5-.5H1.3zM23 18.5c0 .3-.2.5-.5.5h-21c-.3 0-.5-.2-.5-.5V13h6v.5C7 14.9 8.1 16 9.5 16h5c1.4 0 2.5-1.1 2.5-2.5V13h6zM5 10c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5s-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5m1.5-2.5c0-.3.2-.5.5-.5h10c.3 0 .5.2.5.5s-.2.5-.5.5H7c-.3 0-.5-.2-.5-.5"/></svg>)}
    />
  );
}
  