import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCheckRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m8 18.25-.033-.001a.75.75 0 0 1-.531-.255l-3.5-4a.75.75 0 0 1 1.129-.989l2.984 3.41L18.982 5.959a.75.75 0 0 1 1.038 1.083l-11.5 11a.75.75 0 0 1-.52.208"/></svg>)}
    />
  );
}
  