import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowLeftAltRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M.5 12.002c0-.337.139-.665.381-.898l6.847-6.642a.745.745 0 0 1 1.06.016.75.75 0 0 1-.016 1.06L2.885 11.25H22.75a.75.75 0 0 1 0 1.5H2.88l5.888 5.712a.75.75 0 1 1-1.045 1.076L.88 12.9a1.26 1.26 0 0 1-.38-.898"/></svg>)}
    />
  );
}
  