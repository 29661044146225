// @flow
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import type { Canceler } from 'axios';
import { CancelToken } from 'axios';
import { operations as calendarOps } from '../../../ducks/entities/calendar';
import type { CallFlowCalendarEntityT } from '../../../ducks/entities/calendar/calendarTypes';

export type CalendarResponseT = {|
  calendar: ?CallFlowCalendarEntityT,
  isLoadingCalendar: boolean
|};

const useCalendar = (enterpriseId: string, calendarName: string): CalendarResponseT => {
  // State
  const [isLoadingCalendar, setIsLoadingCalendar] = useState<boolean>(true);
  const [calendar, setCalendar] = useState<?CallFlowCalendarEntityT>(undefined);
  const cancelFetchCalendars = React.useRef<Canceler>();
  const mounted = useRef(false);

  // redux
  const dispatch = useDispatch();

  const fetchCalendar = async () => {
    if (enterpriseId && calendarName) {
      const returnValue: {
        results: CallFlowCalendarEntityT[]
      } = await dispatch(
        calendarOps.retrieveCalendars(
          enterpriseId,
          calendarName,
          new CancelToken(canceler => {
            cancelFetchCalendars.current = canceler;
          })
        )
      );
      if (returnValue && returnValue.results && returnValue.results.length === 1) {
        setCalendar(returnValue.results[0]);
      }
    }

    if (mounted.current) {
      setIsLoadingCalendar(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    fetchCalendar();
    return () => {
      if (cancelFetchCalendars.current) {
        cancelFetchCalendars.current();
      }
      mounted.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    calendar,
    isLoadingCalendar
  };
};

export default useCalendar;
