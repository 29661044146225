import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLogoutRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.2 18v2.8c0 1.2-1 2.2-2.2 2.2H4.2C3 23 2 22 2 20.8V3.2C2 2 3 1 4.2 1H14c1.2 0 2.2 1 2.2 2.2V6c0 .4-.3.8-.8.8s-.6-.4-.6-.8V3.2c0-.4-.3-.7-.8-.8H4.2c-.4 0-.7.3-.8.8v17.5c0 .4.3.7.8.8H14c.4 0 .7-.3.8-.8V18c0-.4.3-.8.8-.8s.6.4.6.8M19 7.7c.3-.3.8-.3 1.1 0l3.8 3.7c.3.3.3.8 0 1.1L20 16.3c-.3.3-.8.3-1.1 0s-.3-.8 0-1.1l2.5-2.5H9.5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h11.9L19 8.8c-.3-.3-.3-.8 0-1.1"/></svg>)}
    />
  );
}
  