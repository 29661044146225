// @flow

import * as R from 'ramda';
import React, { type Element } from 'react';
import { type FieldProps } from 'redux-form';
import Dropdown from '@design-system/component-library/src/components/Dropdown';
import Item from '../Item';
import { InputField } from './ReduxFormField';

type OwnPropsT = FieldProps & {
  label: string,
  fieldEditingDisabled: boolean,
  isSectionInEditMode: boolean,
  isSectionDisabled?: boolean,
  forceViewMode?: boolean,
  wrapperStyle: *,
  viewModeOnClick?: *,
  alwaysEdit: boolean,
  id: string,
  options: ?({ label: string, value: string }[]),
  labelClassName?: string,
  updateDropdownValue?: (*) => void
};

export type PropsT = OwnPropsT;

export const FormInput = (properties: PropsT): Element<'div'> => {
  const {
    wrapperStyle,
    fieldEditingDisabled = false,
    isSectionInEditMode = false,
    isSectionDisabled = false,
    forceViewMode = false,
    label,
    options,
    id,
    input,
    viewModeOnClick,
    labelClassName,
    updateDropdownValue,
    ...rest
  } = properties;

  const selectedOption = input.value ? input.value.value : null;
  const selectedOptionLabel = input.value
    ? R.path(['label'], R.find(R.propEq('label', input.value.label))(options || []))
    : null;

  return (
    <div className={wrapperStyle} id={id}>
      {isSectionInEditMode && !forceViewMode ? ( // eslint-disable-line no-nested-ternary
        options != null && options.length > 0 ? (
          <Dropdown
            label={label}
            selectedValue={selectedOption}
            onValueChange={option => {
              if (updateDropdownValue) {
                updateDropdownValue(option);
              }
            }}
            items={options}
          />
        ) : (
          <InputField
            {...rest}
            label={label}
            input={{
              ...input,
              disabled: fieldEditingDisabled || options != null
            }}
          />
        )
      ) : (input.value && input.value.length > 0) ||
        (options && options.length > 0 && selectedOptionLabel) ? (
        <Item
          classNames={['form-input-item']}
          label={label}
          id={id}
          value={options ? selectedOptionLabel : input.value}
          disabled={isSectionDisabled}
          onClick={viewModeOnClick}
          labelClassName={labelClassName}
        />
      ) : null}
    </div>
  );
};

export default FormInput;
