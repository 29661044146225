import React from 'react';
import PropTypes from 'prop-types';

import styles from './Disclaimer.module.scss';

export function CopyRightElement({ text = null }) {
  if (text) {
    return (
      <li className={styles.footer__disclaimer_item}>
        <span className={styles.footer__disclaimer_copyright}>{text}</span>
      </li>
    );
  }
  return null;
}

CopyRightElement.propTypes = {
  text: PropTypes.string,
};
