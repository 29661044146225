import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { INPUT_TYPES, NUMERIC_INPUT_TYPES, READONLY_INPUT_TYPES, REQUIRED_INPUT_TYPES } from '../../utils/elements';
import { KEY_RETURN } from '../../utils/keycodes';
import { classNames } from '../../utils/css';
import { setRefs } from "../../utils/refs";
import { useIdWithFallback } from '../../utils/hooks';
import InputError from '../InputError';

import styles from './Input.module.scss';

const TEXTAREA_LINE_HEIGHT = 24;
const TEXTAREA_VERTICAL_PADDING = 22;
const ARIA_AUTOCOMPLETE_VALUES = ['inline', 'list', 'both', 'none'];

function InputInternal(
  {
    /* eslint-disable react/prop-types */
    ariaActivedescendant = null,
    ariaAutocomplete = null,
    ariaExpanded = null,
    ariaHaspopup = null,
    ariaInvalid = 'false',
    ariaLabel = null,
    ariaOwns = null,
    autocomplete = null,
    autofocus = false,
    children = null,
    className = null,
    clear = false,
    controlled = null,
    defaultValue = null,
    dirName = null,
    disabled = false,
    form = null,
    i18n_input_clearFieldTitle = 'Tyhjennä kenttä',
    i18n_input_errorMessage = '',
    i18n_input_helpText = null,
    i18n_input_hidePasswordTitle = 'Piilota salasana',
    i18n_input_infoText = null,
    i18n_input_optionalText = '(ei pakollinen)',
    i18n_input_showPasswordTitle = 'Näytä salasana',
    icon = null,
    id = null,
    inputmode = null,
    label = null,
    max = null,
    maxlength = null,
    maxrows = 10,
    min = null,
    minlength = null,
    minrows = 1,
    multiple = null,
    name = null,
    onBlur = () => {},
    onClearInput = () => {},
    onClick = () => {},
    onFocus = () => {},
    onKeyDown = () => {},
    onKeyUp = () => {},
    onPasswordToggle = () => {},
    onValueChange = () => {},
    optional = false,
    pattern = null,
    placeholder = null,
    readonly = false,
    role = null,
    rows = null,
    size = null,
    step = null,
    touched = false,
    type = 'text',
    value = '',
    inputRef = null,
    validateOnBlur = false,
    inputProps = null,
    ...otherProps
    /* eslint-enable react/prop-types */
  },
  ref
){
  const internalInputRef = useRef(null);
  const htmlId = useIdWithFallback('dsInput', id);
  const isControlled = controlled || value !== '';
  let currentValue = isControlled ? value || '' : defaultValue || '';
  const [disabledState, setDisabledState] = useState(disabled);
  const [readonlyState, setReadonlyState] = useState(readonly);
  const [errorMessage, setErrorMessage] = useState(i18n_input_errorMessage);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [touchedState, setTouchedState] = useState(touched);
  const [changedState, setChangedState] = useState(false);
  const [currentRows, setCurrentRows] = useState(rows || minrows);
  const [showClearButton, setShowClearButton] = useState(clear ? (!!(defaultValue || value)) : false);

  // Functions visible to outside.
  useImperativeHandle(ref, () => ({
    value: (newValue) => {
      if (newValue) {
        internalInputRef.current.value = newValue;
        currentValue = newValue;
        if (onValueChange) {
          onValueChange(newValue);
        }
      }
      else {
        currentValue = null;
        internalInputRef.current.value = null;
        if (onValueChange) {
          onValueChange(null);
        }
      }
    },

    getValue: () => {
      if(isControlled){
        return currentValue;
      }

      return internalInputRef.current.value;
    },

    // eslint-disable-next-line consistent-return
    focus: (state) => {
      if(state === true) {
        internalInputRef.current.focus();
      }
      else if ( state === false) {
        internalInputRef.current.blur();
      }
      else {
        return document.activeElement === internalInputRef.current;
      }
    },

    // eslint-disable-next-line consistent-return
    touched: (state) => {
      if(state === true || state === false){
        setTouchedState(state);
      }
      else {
        return touched;
      }
    },

    // eslint-disable-next-line consistent-return
    changed: (state) => {
      if(state === true || state === false){
        setChangedState(state);
      }
      else {
        return changedState;
      }
    },

    // eslint-disable-next-line consistent-return
    disabled: (state) => {
      if(state === true || state === false){
        setDisabledState(state);
      }
      else {
        return disabledState;
      }
    },

    // eslint-disable-next-line consistent-return
    readonly: (state) => {
      if(state === true || state === false) {
        setReadonlyState(state);
      }
      else {
        return readonlyState;
      }
    },

    // eslint-disable-next-line consistent-return
    errorMessage: (message) => {
      if(typeof(message) === 'string') {
        setErrorMessage(message);
      }
      else {
        return errorMessage;
      }
    },
  }));

  const handleValueChange = (e) => {
    if (type === 'textarea') {
      const textarea = e.target;
      e.target.style.height = '0px'; // need to set to zero or otherwise calculations on the next line won't work (because the scrollheight is "wrong")
      const rowsNow = Math.floor((textarea.scrollHeight - TEXTAREA_VERTICAL_PADDING) / TEXTAREA_LINE_HEIGHT);
      e.target.style.height = 'auto';

      if(rowsNow >= minrows) {
        setCurrentRows(rowsNow < maxrows ? rowsNow : maxrows);
      }
      else {
        setCurrentRows(minrows);
      }
    }

    if (type === 'select' && multiple) {
      const { options } = e.target;
      const selections = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          selections.push(options[i].value);
        }
      }

      currentValue = selections;
    }

    setTouchedState(true);
    setChangedState(true);

    if (!isControlled){
      currentValue = e.target.value;
    }

    if(e.target.value) {
      setShowClearButton(true);
    }
    else {
      setShowClearButton(false);
    }

    if (onValueChange) {
      onValueChange(e);
    }
  }

  const handlePasswordToggle = (e) => {
    if(!e.key || (e.key && e.key === KEY_RETURN)){
      if(!isControlled){
        internalInputRef.current.setAttribute('type', passwordVisible ? "text" : "password");
      }

      setPasswordVisible(!passwordVisible);
      if(onPasswordToggle) {
        onPasswordToggle(e);
      }
    }
  }

  const handleInputClear = (e) => {
    if(!e.key || (e.key && e.key === KEY_RETURN)){
      if(!isControlled){
        internalInputRef.current.value = '';
        internalInputRef.current.focus();
      }
      currentValue = '';
      internalInputRef.current.value = '';
      setShowClearButton(false);
      onClearInput(e);
    }
  }

  const handleInputClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  }

  const handleInputKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  }

  const handleInputKeyUp = (e) => {
    if (onKeyUp) {
      onKeyUp(e);
    }
  }

  const handleInputFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }
  }

  const handleInputBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  }

  useEffect(() => {
    if (internalInputRef?.current) {
      internalInputRef.current.value = currentValue;
    }
  }, [currentValue]);

  useEffect(() => {
    setErrorMessage(i18n_input_errorMessage);
  }, [i18n_input_errorMessage]);

  useEffect(() => {
    setDisabledState(disabled);
  }, [disabled]);

  useEffect(() => {
    setReadonlyState(readonly);
  }, [readonly]);

  const optionalIndicator = optional ? i18n_input_optionalText : null;

  let inputLabel = null;
  if (label) {
    const LabelAll = require('../Label'); // eslint-disable-line global-require
    const LabelComponent = LabelAll.default ?? LabelAll;
    inputLabel = <LabelComponent id={`${htmlId}Label`} labelFor={`${htmlId}`}>{label}</LabelComponent>;
  }

  let inputInfo = null;
  if (i18n_input_infoText) {
    const PopoverAll = require('../Popover'); // eslint-disable-line global-require
    const PopoverComponent = PopoverAll.default ?? PopoverAll;
    const IconInformationRegularAll = require('../Icon/lib/IconInformationRegular'); // eslint-disable-line global-require
    const IconInformationRegularComponent = IconInformationRegularAll.default ?? IconInformationRegularAll;
    inputInfo = <PopoverComponent triggerElement={<IconInformationRegularComponent />} placement="top">{i18n_input_infoText}</PopoverComponent>
  }

  let inputHelp = null;
  if (i18n_input_helpText) {
    const HelpAll = require('../InputHelp'); // eslint-disable-line global-require
    const HelpComponent = HelpAll.default ?? HelpAll;
    inputHelp = <HelpComponent className={styles.input__helptext} id={`${htmlId}Help`}>{i18n_input_helpText}</HelpComponent>;
  }

  let searchIcon = null;
  if (type === 'search') {
    const IconSearchRegularAll = require('../Icon/lib/IconSearchRegular'); // eslint-disable-line global-require
    const IconSearchRegularComponent = IconSearchRegularAll.default ?? IconSearchRegularAll;
    searchIcon = <div className={styles[`input--inputarea-icon`]}><IconSearchRegularComponent aria-hidden="true" /></div>;
  }

  let inputIcon = null;
  if (icon && type !== 'search' && type !== 'password' && type !== 'textarea') {
    inputIcon = <div className={styles[`input--inputarea-icon`]}>{icon}</div>;
  }

  let inputClear = null;
  if (clear) {
    const IconCloseFilledAll = require('../Icon/lib/IconCloseFilled'); // eslint-disable-line global-require
    const IconCloseFilledComponent = IconCloseFilledAll.default ?? IconCloseFilledAll;
    inputClear = <IconCloseFilledComponent size="s" />;
  }

  let passwordShowIcon = null;
  let passwordHideIcon = null;
  if (type === 'password') {
    const IconViewOffFilledAll = require('../Icon/lib/IconViewOffFilled'); // eslint-disable-line global-require
    const IconViewOffFilledComponent = IconViewOffFilledAll.default ?? IconViewOffFilledAll;
    const IconViewFilledAll = require('../Icon/lib/IconViewFilled'); // eslint-disable-line global-require
    const IconViewFilledComponent = IconViewFilledAll.default ?? IconViewFilledAll;
    passwordHideIcon = <IconViewOffFilledComponent title={i18n_input_hidePasswordTitle} />;
    passwordShowIcon = <IconViewFilledComponent title={i18n_input_showPasswordTitle} />;
  }

  let inputType = type;
  if (type === 'password') {
    if (passwordVisible) {
      inputType = 'text';
    }
  }

  const classes = classNames([
    styles.input,
    styles[`input-${type}`],
    (type !== 'password' && icon) || type === 'search' ? styles[`input-withicon`] : null,
    errorMessage || i18n_input_errorMessage !== '' ? styles[`input-error`] : null,
    disabledState ? styles[`input-disabled`] : null,
    readonlyState && READONLY_INPUT_TYPES.includes(type) ? styles[`input-readonly`] : null,
    className || null
  ]);

  const inputClasses = classNames([
    type === 'textarea' || type === 'select' ? styles[`input--inputarea-${type}`] : styles[`input--inputarea-input`],
    touchedState ? styles[`input--inputarea-${type === 'textarea' || type === 'select' ? type : 'input'}__touched`] : null,
    changedState ? styles[`input--inputarea-${type === 'textarea' || type === 'select' ? type : 'input'}__changed`] : null,
    optional ? styles[`input--inputarea-${type === 'textarea' || type === 'select' ? type : 'input'}__optional`] : null,
    validateOnBlur ? styles['invalid-on-blur'] : null,
  ]);

  let valueProp = null;
  if(isControlled) {
    valueProp = {value: currentValue ? currentValue || '' : value || ''};
  }
  else {
    valueProp = {defaultValue: defaultValue || currentValue || null};
  }

  const setInputRefs = (val) => setRefs(val, inputRef, internalInputRef);

  return (
    <div id={`${htmlId}-wrapper`} key={htmlId} className={classes} role="group" {...otherProps}>
      { inputLabel ?
        <div className={styles['input--labelarea']}>
          {inputLabel} {optionalIndicator} {inputInfo}
        </div>
      : null }

      <div className={styles[`input--inputarea`]}>
        {inputIcon}
        {searchIcon}

        {type === 'select' ? (
          <>
            <select
              aria-activedescendant={ariaActivedescendant}
              aria-describedby={i18n_input_helpText ? `${htmlId}Help` : null}
              aria-errormessage={`${htmlId}Error`}
              aria-expanded={ariaExpanded}
              aria-invalid={ariaInvalid !== 'false' ? ariaInvalid : null}
              aria-label={ariaLabel}
              aria-owns={ariaOwns}
              autoComplete={autocomplete}
              autoFocus={autofocus} // eslint-disable-line jsx-a11y/no-autofocus
              className={inputClasses}
              disabled={disabledState}
              form={form}
              id={htmlId}
              multiple={multiple}
              name={name}
              onChange={handleValueChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onClick={handleInputClick}
              onKeyUp={handleInputKeyUp}
              onKeyDown={handleInputKeyDown}
              ref={setInputRefs}
              required={!optional && REQUIRED_INPUT_TYPES.includes(type) ? !optional : null}
              size={size}
              {...valueProp}
              {...inputProps}
            >
              {children}
            </select>
            <div className={styles[`input--select-chevron`]} />
          </>
        ) : null}
        {type === 'textarea' ? (
          <textarea
            aria-activedescendant={ariaActivedescendant}
            aria-autocomplete={ariaAutocomplete}
            aria-describedby={i18n_input_helpText ? `${htmlId}Help` : null}
            aria-errormessage={`${htmlId}Error`}
            aria-haspopup={ariaHaspopup}
            aria-invalid={ariaInvalid}
            aria-label={ariaLabel}
            aria-owns={ariaOwns}
            autoComplete={autocomplete}
            autoFocus={autofocus} // eslint-disable-line jsx-a11y/no-autofocus
            className={inputClasses}
            disabled={disabledState}
            form={form}
            id={htmlId}
            inputMode={inputmode}
            maxLength={maxlength || null}
            minLength={minlength || null}
            name={name}
            onChange={handleValueChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onClick={handleInputClick}
            onKeyUp={handleInputKeyUp}
            onKeyDown={handleInputKeyDown}
            placeholder={placeholder}
            readOnly={readonlyState && READONLY_INPUT_TYPES.includes(type) ? readonlyState : null}
            ref={setInputRefs}
            required={!optional && REQUIRED_INPUT_TYPES.includes(type) ? !optional : null}
            rows={currentRows}
            {...valueProp}
            {...inputProps}
          />
        ) : null}
        {type !== 'select' && type !== 'textarea' ? (
          <input
            aria-activedescendant={ariaActivedescendant}
            aria-autocomplete={ariaAutocomplete}
            aria-describedby={i18n_input_helpText ? `${htmlId}Help` : null}
            aria-errormessage={`${htmlId}Error`}
            aria-expanded={ariaExpanded}
            aria-haspopup={ariaHaspopup}
            aria-invalid={ariaInvalid}
            aria-label={ariaLabel}
            aria-owns={ariaOwns}
            autoComplete={autocomplete}
            autoFocus={autofocus} // eslint-disable-line jsx-a11y/no-autofocus
            className={inputClasses}
            dirname={type === 'search' || type === 'text' ? dirName : null} // eslint-disable-line react/no-unknown-property
            disabled={disabledState}
            form={form}
            id={htmlId}
            inputMode={inputmode}
            max={NUMERIC_INPUT_TYPES.includes(type) ? max : null}
            maxLength={['text', 'search', 'url', 'tel', 'email', 'password'].includes(type) ? maxlength : null}
            min={NUMERIC_INPUT_TYPES.includes(type) ? min : null}
            minLength={['text', 'search', 'url', 'tel', 'email', 'password'].includes(type) ? minlength : null}
            multiple={type === 'email' || type === 'select' ? multiple : null}
            name={name}
            onChange={handleValueChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onClick={handleInputClick}
            onKeyUp={handleInputKeyUp}
            onKeyDown={handleInputKeyDown}
            pattern={['password', 'text', 'tel'].includes(type) ? pattern : null}
            placeholder={['number', 'password', 'search', 'tel', 'text', 'url', 'email'].includes(type) ? placeholder : null}
            readOnly={readonlyState && READONLY_INPUT_TYPES.includes(type) ? readonlyState : null}
            ref={setInputRefs}
            required={!optional && REQUIRED_INPUT_TYPES.includes(type) ? !optional : null}
            role={role}
            step={NUMERIC_INPUT_TYPES.includes(type) ? step : null}
            type={inputType}
            {...valueProp}
            {...inputProps}
          />
        ) : null}


        {type !== 'password' && inputClear && showClearButton && !disabledState && !readonlyState ? (
          <button
            type="button"
            id={`${htmlId}InputClearButton`}
            className={styles[`input--inputarea-clear`]}
            title={i18n_input_clearFieldTitle}
            onClick={handleInputClear}
            // tabIndex="0"
          >
            {inputClear}
          </button>
        ) : null}
        {type === 'password' && !disabledState && !readonlyState ? (
          <button
            type="button"
            id={`${htmlId}ClearInputPasswordToggleButton`}
            className={styles[`input--inputarea-viewbutton`]}
            onClick={handlePasswordToggle}
            // tabIndex="0"
            aria-label={passwordVisible ? i18n_input_hidePasswordTitle : i18n_input_showPasswordTitle}
          >
            {passwordVisible ? passwordHideIcon : passwordShowIcon}
          </button>
        ) : null}
      </div>
      <InputError className={styles.input__errormessage} id={`${htmlId}Error`}>{errorMessage}</InputError>
      {inputHelp}
    </div>
  );
}

const Input = React.forwardRef(InputInternal);
export default Input;

Input.propTypes = {
  /**
   * aria-activedescendant attribute of the input box
   */
  ariaActivedescendant: PropTypes.string,
  /**
   * aria-autocomplete attribute of the input box
   */
  ariaAutocomplete: PropTypes.oneOf(ARIA_AUTOCOMPLETE_VALUES),
  /**
   * aria-expanded attribute of the input box
   */
  ariaExpanded: PropTypes.bool,
  /**
   * aria-haspopup attribute of the input box
   */
  ariaHaspopup: PropTypes.bool,
  /**
   * aria-invalid attribute of the input box
   */
  ariaInvalid: PropTypes.oneOf(['true', 'false', 'grammar', 'spelling']),
  /**
   * Input's aria-label
   */
  ariaLabel: PropTypes.string,
  /**
   * aria-owns attribute of the input box
   */
  ariaOwns: PropTypes.string,
  /**
   * Hint for form autofill feature
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefautocomplete)
   */
  autocomplete: PropTypes.string,
  /**
   * Automatically focus the form control when the page is loaded
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefautofocus)
   */
  autofocus: PropTypes.bool,
  /**
   * Component's options. Automatically detected.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  /**
   * Class names you want to give to the component
   */
  className: PropTypes.string,
  /**
   * Flag for showing a clear control
   */
  clear: PropTypes.bool,
  /**
   * Forces the component to be controlled of uncontrolled.
   * If not specified, the component tries to deduct this from given props (controlled component has value prop while uncontrolled doesn't).
   */
  controlled: PropTypes.bool,
  /**
   * Default value of the input. Use this to store the original value if needed.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Name of form field to use for sending the element's directionality in form submission
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefdirname)
   */
  dirName: PropTypes.string,
  /**
   * Whether the form control is disabled
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefdisabled)
   */
  disabled: PropTypes.bool,
  /**
   * Associates the control with a form element
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefform)
   */
  form: PropTypes.string,
  /**
   * Title of clear button in field
   */
  i18n_input_clearFieldTitle: PropTypes.string,
  /**
   * Error message for the field
   */
  i18n_input_errorMessage: PropTypes.string,
  /**
   * Always visible help text for the field
   */
  i18n_input_helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Title of hide password button in field
   */
  i18n_input_hidePasswordTitle: PropTypes.string,
  /**
   * Additional information and instructions for the field
   */
  i18n_input_infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optional field indicator text
   */
  i18n_input_optionalText: PropTypes.string,
  /**
   * Title of show password button in field
   */
  i18n_input_showPasswordTitle: PropTypes.string,
  /**
   * Icon for the input field as an Icon component
   */
  icon: PropTypes.node,
  /**
   * Element's id. Input element will get the raw version of this property.
   */
  id: PropTypes.string,
  /**
   * Input's inputmode
   */
  inputmode: PropTypes.oneOf(['none', 'text', 'decimal', 'numeric', 'tel', 'search', 'email', 'url']),
  /**
   * Label text for the input
   */
  label: PropTypes.string,
  /**
   * Maximum value
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmax)
   */
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Maximum length (number of characters) of `value`
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmaxlength)
   */
  maxlength: PropTypes.number,
  /**
   * Maximum number of rows in textarea
   */
  maxrows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Minimum value
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmin)
   */
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Minimum length (number of characters) of `value`
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefminlength)
   */
  minlength: PropTypes.number,
  /**
   * Minimum number of rows in textarea
   */
  minrows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether to allow multiple values
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefmultiple)
   */
  multiple: PropTypes.bool,
  /**
   * Name of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname)
   */
  name: PropTypes.string,
  /**
   * Function that runs when user moves focus out of field
   */
  onBlur: PropTypes.func,
  /**
   * Callback for clear control
   */
  onClearInput: PropTypes.func,
  /**
   * Function that runs when user clicks on field
   */
  onClick: PropTypes.func,
  /**
   * Function that runs when user moves focus into field
   */
  onFocus: PropTypes.func,
  /**
   * Function that runs when user presses down key
   */
  onKeyDown: PropTypes.func,
  /**
   * Function that runs when user presses up key
   */
  onKeyUp: PropTypes.func,
  /**
   * Function that runs when user toggles password's visibility
   */
  onPasswordToggle: PropTypes.func,
  /**
   * Function that runs when user changes field's value
   */
  onValueChange: PropTypes.func,
  /**
   * Whether field is optional or not. By default all fields are mandatory.
   */
  optional: PropTypes.bool,
  /**
   * Regex pattern the `value` must match to be valid
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefpattern)
   */
  pattern: PropTypes.string,
  /**
   * Text that appears in the form control when it has no value set
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefplaceholder)
   */
  placeholder: PropTypes.string,
  /**
   * The value is not editable
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefreadonly)
   */
  readonly: PropTypes.bool,
  /**
   * role of the input box
   */
  role: PropTypes.string,
  /**
   * Initial number of rows in textarea.
   * If you want text area to be always the same size, set minrows and maxrows to same value with each others
   */
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If the control is presented as a scrolling list box (e.g. when multiple is specified), this attribute represents the number of rows in the list that should be visible at one time.
   */
  size: PropTypes.number,
  /**
   * Incremental values that are valid.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefstep)
   */
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether a user has visited the input field or not
   */
  touched: PropTypes.bool,
  /**
   * Type of the input
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdeftype)
   */
  type: PropTypes.oneOf(INPUT_TYPES),
  /**
   * Current value of the form control. Submitted with the form as part of a name/value pair.
   * [More information](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefvalue)
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
   * Ref for the actual input element, not the wrapper
   */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
  /**
   * Additional properties that are passed to the input element
   */
  inputProps: PropTypes.object,
  /**
   * if set to true, the field will not be set to invalid while it has focus
   */
  validateOnBlur: PropTypes.bool
}
