// @flow

import type {
  CloseMenuDropdownActionT,
  CloseHelpMenuDropdownActionT,
  OpenMenuDropdownActionT,
  OpenHelpMenuDropdownActionT,
  OpenUsersImportActionT,
  CloseUsersImportActionT,
  OpenFeedbackActionT,
  CloseFeedbackActionT,
  OpenOnboardingActionT,
  CloseOnboardingActionT,
  OpenNavigationMenuActionT,
  CloseNavigationMenuActionT,
  OpenChangelogActionT,
  CloseChangelogActionT
} from './headerUiTypes';

export const createOpenMenuDropdownAction = (): OpenMenuDropdownActionT => ({
  type: 'ring/ui/header/OPEN_USER_DROPDOWN'
});
export const createCloseMenuDropdownAction = (): CloseMenuDropdownActionT => ({
  type: 'ring/ui/header/CLOSE_USER_DROPDOWN'
});

export const createOpenHelpMenuDropdownAction = (): OpenHelpMenuDropdownActionT => ({
  type: 'ring/ui/header/OPEN_HELP_DROPDOWN'
});
export const createCloseHelpMenuDropdownAction = (): CloseHelpMenuDropdownActionT => ({
  type: 'ring/ui/header/CLOSE_HELP_DROPDOWN'
});

export const createOpenUsersImportAction = (): OpenUsersImportActionT => ({
  type: 'ring/ui/header/OPEN_IMPORT_USERS'
});

export const createCloseUsersImportAction = (): CloseUsersImportActionT => ({
  type: 'ring/ui/header/CLOSE_IMPORT_USERS'
});

export const createOpenFeedbackAction = (): OpenFeedbackActionT => ({
  type: 'ring/ui/header/OPEN_FEEDBACK'
});

export const createCloseFeedbackAction = (): CloseFeedbackActionT => ({
  type: 'ring/ui/header/CLOSE_FEEDBACK'
});

export const createOpenOnboardingAction = (): OpenOnboardingActionT => ({
  type: 'ring/ui/header/OPEN_ONBOARDING'
});

export const createCloseOnboardingAction = (): CloseOnboardingActionT => ({
  type: 'ring/ui/header/CLOSE_ONBOARDING'
});

export const createOpenChangelogAction = (): OpenChangelogActionT => ({
  type: 'ring/ui/header/OPEN_CHANGELOG'
});

export const createCloseChangelogAction = (): CloseChangelogActionT => ({
  type: 'ring/ui/header/CLOSE_CHANGELOG'
});

export const createOpenNavigationMenuAction = (): OpenNavigationMenuActionT => ({
  type: 'ring/ui/header/OPEN_NAVIGATION_MENU'
});

export const createCloseNavigationMenuAction = (): CloseNavigationMenuActionT => ({
  type: 'ring/ui/header/CLOSE_NAVIGATION_MENU'
});
