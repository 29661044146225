// @flow strict-local

import { type $AxiosError } from 'axios';
import type {
  UpdateSpeedDialCancelT,
  UpdateSpeedDialFailureT,
  UpdateSpeedDialRequestT,
  UpdateSpeedDialSuccessT
} from '../callFlow/callFlowTypes';
import type { SpeedDialEntityT } from './speedDialTypes';

type CreateUpdateSpeedDialRequestFnT = (string, string) => UpdateSpeedDialRequestT;
export const createUpdateSpeedDialRequest: CreateUpdateSpeedDialRequestFnT = (
  enterpriseId,
  speedDialId
) => ({
  type: 'ring/entity/speeddial/UPDATE_REQUEST',
  error: false,
  meta: { enterpriseId, speedDialId }
});

type CreateUpdateSpeedDialSuccessFnT = (string, SpeedDialEntityT) => UpdateSpeedDialSuccessT;
export const createUpdateSpeedDialSuccess: CreateUpdateSpeedDialSuccessFnT = (
  enterpriseId,
  speedDial
) => ({
  type: 'ring/entity/speeddial/UPDATE_SUCCESS',
  payload: { ...speedDial },
  error: false,
  meta: { enterpriseId, speedDialId: speedDial.id }
});

type CreateUpdateAcdAgentFailureFnT = (
  string,
  string,
  // $FlowFixMe deprecated-type
  $AxiosError<*>
) => UpdateSpeedDialFailureT;
export const createUpdateSpeedDialFailure: CreateUpdateAcdAgentFailureFnT = (
  enterpriseId,
  speedDialId,
  error
) => ({
  type: 'ring/entity/speeddial/UPDATE_FAILURE',
  payload: error,
  error: true,
  meta: { enterpriseId, speedDialId }
});

type CreateUpdateSpeedDialCancelFnT = (string, string) => UpdateSpeedDialCancelT;
export const createUpdateSpeedDialCancel: CreateUpdateSpeedDialCancelFnT = (
  enterpriseId,
  speedDialId
) => ({
  type: 'ring/entity/speeddial/UPDATE_CANCEL',
  payload: {},
  error: false,
  meta: { enterpriseId, speedDialId }
});
