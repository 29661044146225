// @flow

import { type $AxiosError } from 'axios';
import * as R from 'ramda';
import type {
  CallFlowEntityT,
  RetrieveCallFlowCollectionRequestT,
  RetrieveCallFlowCollectionSuccessT,
  RetrieveCallFlowCollectionFailureT,
  RetrieveCallFlowCollectionCancelT,
  CallFlowTypeT,
  RetrieveCallFlowRequestT,
  RetrieveCallFlowSuccessT,
  RetrieveCallFlowFailureT,
  RetrieveCallFlowCancelT,
  ClearCallFlowsT
} from './callFlowTypes';

type CreateRetrieveCallFlowCollectionRequestFnT = (
  string,
  ?{}
) => RetrieveCallFlowCollectionRequestT;

export const createRetrieveCallFlowCollectionRequest: CreateRetrieveCallFlowCollectionRequestFnT = (
  enterpriseId,
  meta = {}
) => ({
  type: 'ring/entity/callflow/RETRIEVE_COLLECTION_REQUEST',
  error: false,
  meta: { ...meta, enterpriseId }
});

type CreateRetrieveCallFlowCollectionSuccessFnT = (
  string,
  CallFlowEntityT[],
  ?{}
) => RetrieveCallFlowCollectionSuccessT;

export const createRetrieveCallFlowCollectionSuccess: CreateRetrieveCallFlowCollectionSuccessFnT = (
  enterpriseId,
  callflows,
  meta = {}
) => ({
  type: 'ring/entity/callflow/RETRIEVE_COLLECTION_SUCCESS',
  error: false,
  meta: {
    ...meta,
    enterpriseId
  },
  // $FlowFixMe
  payload: R.map(R.assoc('enterpriseId', enterpriseId), callflows)
});

type CreateRetrieveCallFlowCollectionFailureFnT = (
  string,
  $AxiosError<*>,
  ?{}
) => RetrieveCallFlowCollectionFailureT;

export const createRetrieveCallFlowCollectionFailure: CreateRetrieveCallFlowCollectionFailureFnT = (
  enterpriseId,
  error,
  meta = {}
) => ({
  type: 'ring/entity/callflow/RETRIEVE_COLLECTION_FAILURE',
  error: true,
  meta: { ...meta, enterpriseId },
  payload: error
});

type CreateRetrieveCallFlowCollectionCancelFnT = () => RetrieveCallFlowCollectionCancelT;
export const createRetrieveCallFlowCollectionCancel: CreateRetrieveCallFlowCollectionCancelFnT = () => ({
  type: 'ring/entity/callflow/RETRIEVE_COLLECTION_CANCEL',
  error: false,
  meta: {}
});

type CreateRetrieveCallFlowRequestFnT = (
  string,
  string,
  CallFlowTypeT,
  boolean
) => RetrieveCallFlowRequestT;

export const createRetrieveCallFlowRequest: CreateRetrieveCallFlowRequestFnT = (
  enterpriseId,
  callFlowId,
  callFlowType,
  retrying = false
) => ({
  type: 'ring/entity/callflow/RETRIEVE_REQUEST',
  error: false,
  meta: {
    enterpriseId,
    callFlowId,
    callFlowType,
    retrying
  }
});

type CreateRetrieveCallFlowSuccessFnT = (
  string,
  string,
  CallFlowTypeT,
  CallFlowEntityT
) => RetrieveCallFlowSuccessT;

export const createRetrieveCallFlowSuccess: CreateRetrieveCallFlowSuccessFnT = (
  enterpriseId,
  callFlowId,
  callFlowType,
  callflow
) => ({
  type: 'ring/entity/callflow/RETRIEVE_SUCCESS',
  error: false,
  meta: {
    enterpriseId,
    callFlowId,
    callFlowType
  },
  payload: R.assoc('enterpriseId', enterpriseId, callflow)
});

type CreateRetrieveCallFlowFailureFnT = (
  string,
  string,
  CallFlowTypeT,
  $AxiosError<*>,
  ?{}
) => RetrieveCallFlowFailureT;

export const createRetrieveCallFlowFailure: CreateRetrieveCallFlowFailureFnT = (
  enterpriseId,
  callFlowId,
  callFlowType,
  error
) => ({
  type: 'ring/entity/callflow/RETRIEVE_FAILURE',
  error: true,
  meta: { enterpriseId, callFlowId, callFlowType },
  payload: error
});

type CreateRetrieveCallFlowCancelFnT = (string, string, CallFlowTypeT) => RetrieveCallFlowCancelT;
export const createRetrieveCallFlowCancel: CreateRetrieveCallFlowCancelFnT = (
  enterpriseId,
  callFlowId,
  callFlowType
) => ({
  type: 'ring/entity/callflow/RETRIEVE_CANCEL',
  error: false,
  meta: { enterpriseId, callFlowId, callFlowType }
});

type CreateClearCallFlowsCancelFnT = () => ClearCallFlowsT;
export const createClearCallFlows: CreateClearCallFlowsCancelFnT = () => ({
  type: 'ring/entity/callflow/CLEAR'
});
