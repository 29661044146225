import React from 'react';
import IconContainer from '../IconContainer';

export default function IconOnDeviceFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.75 17.75H4.25A.25.25 0 0 0 4 18v4.5A1.5 1.5 0 0 0 5.5 24h11a1.5 1.5 0 0 0 1.5-1.5V18a.25.25 0 0 0-.25-.25m-6.13 3.489a.92.92 0 0 1-.62.261.9.9 0 0 1-.62-.261.93.93 0 0 1-.26-.624.9.9 0 0 1 .26-.624.92.92 0 0 1 1.24 0c.164.167.257.39.26.624a.93.93 0 0 1-.26.624M17.5.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12m3.179 4.705-4.25 4a.626.626 0 0 1-.871-.013l-1.5-1.5a.626.626 0 0 1 .885-.884l1.071 1.071 3.808-3.584a.625.625 0 1 1 .857.91M18 14.25v2a.25.25 0 0 1-.25.25H4.25a.25.25 0 0 1-.25-.25V1.5A1.5 1.5 0 0 1 5.5 0h7.88c.246 0 .342.316.136.451A7.23 7.23 0 0 0 10.25 6.5a7.25 7.25 0 0 0 7.25 7.25c.242 0 .5.165.5.5"/></svg>)}
    />
  );
}
  