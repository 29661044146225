// Please don't create an enum out of them, that prevents tree shaking.
export const KEY_BACKSPACE = 'Backspace';
export const KEY_DELETE = 'Delete';
export const KEY_DOWN = 'ArrowDown';
export const KEY_END = 'End';
export const KEY_ESC = 'Escape';
export const KEY_HOME = 'Home';
export const KEY_LEFT = 'ArrowLeft';
export const KEY_PAGE_DOWN = 'PageDown';
export const KEY_PAGE_UP = 'PageUp';
export const KEY_RETURN = 'Enter';
export const KEY_RIGHT = 'ArrowRight';
export const KEY_SHIFT = 'Shift';
export const KEY_SPACE = ' ';
export const KEY_TAB = 'Tab';
export const KEY_UP = 'ArrowUp';

export default {};
