import React from 'react';
import IconContainer from '../IconContainer';

export default function IconCartLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 18a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m0 4a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 17 22m-7-4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m0 4a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 10 22M23 6.65l-2.4 7.86a2 2 0 0 1-2 1.49H8.58a2 2 0 0 1-2-1.51L3.3 3.35A1.8 1.8 0 0 0 1.56 2H.5a.5.5 0 0 1 0-1h1.06a2.8 2.8 0 0 1 2.71 2.08l3.31 11.14a1 1 0 0 0 1 .78h10.06a1 1 0 0 0 1-.77L21.83 7H7.5a.5.5 0 0 1 0-1h15a.48.48 0 0 1 .4.2c.103.126.14.293.1.45"/></svg>)}
    />
  );
}
  