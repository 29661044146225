// @flow
import React, { type Element } from 'react';
import Input from '@design-system/component-library/src/components/Input';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LinkButton from '../../../../../components/Button/LinkButton';

import styles from './PasswordField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  shouldValidate?: boolean,
  fieldEnabled: boolean,
  setFieldEnabled: () => void
|};

export const PasswordField = (props: PropsT): Element<'div'> => {
  const { field, title, description, shouldValidate, fieldEnabled, setFieldEnabled } = props;

  const { t } = useTranslation();

  // form
  const {
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const values = watch();
  const newPassword = values[field];

  return (
    <div className={styles.container} data-cy="voice-mail-password-change">
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
      <div id="voicemail-password" className={styles.input}>
        <div>
          <Input
            name={field}
            data-cy="voicemail-password-input"
            className={styles['pw-input']}
            onValueChange={e => {
              setValue(field, e.target.value, {
                shouldValidate: shouldValidate === undefined ? true : shouldValidate,
                shouldDirty: true
              });
            }}
            defaultValue={fieldEnabled ? newPassword : ''}
            touched
            type="password"
            disabled={!fieldEnabled}
            maxlength={20}
            i18n_input_errorMessage={
              errors[field] && fieldEnabled && (errors[field].message || 'error')
            }
          />
        </div>
      </div>
      <LinkButton
        id="activate-pw-change-fields"
        onClickAction={e => {
          if (e) {
            e.preventDefault();
          }
          setFieldEnabled();
        }}
        label={
          !fieldEnabled
            ? t('callflows.passwordField.openPassword')
            : t('callflows.passwordField.closePassword')
        }
      />
    </div>
  );
};

export default PasswordField;
