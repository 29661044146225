import React from 'react';
import IconContainer from '../IconContainer';

export default function IconSalasanapankkiFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m21.55.96-9.5-.958a1 1 0 0 0-.101 0L2.45.96a.5.5 0 0 0-.45.498v7.188c0 8.636 3.74 12.257 9.775 15.301a.51.51 0 0 0 .45-.001C18.26 20.903 22 17.281 22 8.645V1.458a.5.5 0 0 0-.45-.498M13.5 13.75a.75.75 0 0 1 0 1.5h-.75v1H14a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75v-4.826a3.755 3.755 0 0 1-3-3.674A3.754 3.754 0 0 1 12 4.75a3.755 3.755 0 0 1 3.75 3.75 3.756 3.756 0 0 1-3 3.674v1.576zm-1.5-3c-1.241 0-2.25-1.009-2.25-2.25S10.759 6.25 12 6.25c1.24 0 2.25 1.009 2.25 2.25s-1.01 2.25-2.25 2.25"/></svg>)}
    />
  );
}
  