import React from 'react';
import IconContainer from '../IconContainer';

export default function IconServicesGridRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.2 8.8C1.5 8.8 0 10.2 0 12s1.5 3.2 3.2 3.2 3.2-1.5 3.2-3.2c.1-1.8-1.4-3.2-3.2-3.2m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8S5 11 5 12s-.8 1.7-1.8 1.8m8.8-5c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2 3.2-1.5 3.2-3.2c0-1.8-1.4-3.2-3.2-3.2m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8c-.1 1-.8 1.7-1.8 1.8m8.8-5c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2S24 13.8 24 12s-1.5-3.2-3.2-3.2m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8c-.1 1-.9 1.7-1.8 1.8M3.2 0C1.5 0 0 1.5 0 3.2s1.5 3.2 3.2 3.2S6.5 5 6.5 3.2C6.5 1.5 5 0 3.2 0m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8S5 2.3 5 3.2C5 4.2 4.2 5 3.2 5M12 0c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2 3.2-1.5 3.2-3.2S13.8 0 12 0m0 5c-1 0-1.8-.8-1.8-1.8S11 1.5 12 1.5s1.8.8 1.8 1.8C13.7 4.2 13 5 12 5m8.8-5c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2S24 5 24 3.2C24 1.5 22.5 0 20.8 0m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8c-.1 1-.9 1.8-1.8 1.8M3.2 17C1.5 17 0 18.5 0 20.2s1.5 3.2 3.2 3.2 3.2-1.5 3.2-3.2C6.5 18.5 5 17 3.2 17m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.9 1.8 1.8c0 1-.8 1.8-1.8 1.8m8.8-5c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2 3.2-1.5 3.2-3.2S13.8 17 12 17m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8c-.1 1-.8 1.8-1.8 1.8m8.8-5c-1.8 0-3.2 1.5-3.2 3.2s1.5 3.2 3.2 3.2S24 22 24 20.2c0-1.7-1.5-3.2-3.2-3.2m0 5c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8c-.1 1-.9 1.8-1.8 1.8"/></svg>)}
    />
  );
}
  