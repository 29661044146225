import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLaptopFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.5 17v.9c0 1.7-1.4 3.1-3 3.1H3.6C1.9 21 .5 19.6.5 18v-1c0-.3.2-.5.5-.5h8.1c.1 0 .2 0 .2.1 1 1.5 3.1 1.8 4.6.8.3-.2.6-.5.8-.8 0-.1.1-.1.2-.1H23c.3 0 .5.2.5.5m-21-1.8h19c.3 0 .5-.2.5-.5V5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v9.7c0 .3.2.5.5.5 0 0 0 .1 0 0"/></svg>)}
    />
  );
}
  