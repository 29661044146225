// @flow

import * as R from 'ramda';
import React, { type Element, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CancelToken, CancelTokenSource } from 'axios';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import type {
  WelcomeAttendantEntityT,
  TransferEntityT,
  RelatedNumberT
} from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import { updateWelcomeAttendant } from '../../../../../../ducks/entities/welcomeAttendant/welcomeAttendantOperations';
import EditCallflowDetails from '../../../../components/edit/EditCallflowDetails';
import ForwardingField from '../../../../components/edit/children/forwardingField/ForwardingField';
import {
  enterpriseVoiceMailAddress,
  filterOwnAddressNumber,
  getForwardingTypeByRelatedNumber
} from '../../../../components/edit/children/forwardingField/ForwardingUtils';
import type { ForwardingPropertyT } from '../../../../components/edit/children/forwardingField/ForwardingUtils';
import { InputField } from '../../../../components/edit/children/InputField';
import { renameCommandName } from '../WelcomeAttendantUtils';
import { validateForwardingField } from '../../../../../../utils/validationUtils';
import { getTransferIcon } from './TransferDetails';
import type { AcdEntityT } from '../../../../../../ducks/entities/acd/acdTypes';
import { createUpdateSelectedStatus } from '../../../../../../ducks/ui/callflow/callflowUiActions';
import type { WelcomeAttendantUpdateEntityT } from '../../../../../../ducks/entities/callFlow/callFlowTypes';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';

type PropsT = {|
  callflowId: string,
  commandId: string
|};

export type FormT = {
  stepName: string,
  targetNumber: ?ForwardingPropertyT
};

export const createExtensionNumber = (formData: FormT) => {
  if (formData.targetNumber) {
    if (formData.targetNumber.type === 'TRANSFER_ENT_VM') {
      return enterpriseVoiceMailAddress;
    }
    return formData.targetNumber.value;
  }
  return '';
};

export const buildWelcomeAttendantTransferNodeUpdatePayload = (
  welcomeAttendantData: WelcomeAttendantEntityT,
  formData: FormT,
  commandName: string
) => ({
  commands: {
    ...welcomeAttendantData.commands,
    [commandName]: {
      ...welcomeAttendantData.commands[commandName],
      extensionNumber: createExtensionNumber(formData),
      endState: true
    }
  }
});

let requestCancelTokenSource: CancelTokenSource;

const EditTransferDetails = (props: PropsT): Element<typeof EditCallflowDetails> | null => {
  const { callflowId, commandId } = props;
  const { t } = useTranslation();
  useEffect(() => {
    requestCancelTokenSource = CancelToken.source();
    return () => {
      requestCancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // redux
  const dispatch = useDispatch();
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);

  const welcomeAttendantData: WelcomeAttendantEntityT = useSelector(
    state => state.entities.callFlow.byId[callflowId]
  );
  const { enterpriseId } = welcomeAttendantData;
  const transferNode: ?TransferEntityT = R.path(['commands', commandId], welcomeAttendantData);
  if (!transferNode) {
    return null;
  }

  const linkedNumber: ?RelatedNumberT =
    welcomeAttendantData.relatedNumbers[transferNode.extensionNumber];
  const linkedLabel: string = linkedNumber && linkedNumber.label ? linkedNumber.label : '';
  const linkedUsername: string = linkedNumber && linkedNumber.userName ? linkedNumber.userName : '';

  const convertToFwdField = (fwdField: TransferEntityT, label: string) => {
    const labelValue = (label || '').replace(/^kutsu:/, '');
    return {
      propertyType: 'forwarding',
      type: getForwardingTypeByRelatedNumber(fwdField.extensionNumber, linkedNumber),
      value: fwdField.extensionNumber || '',
      text: labelValue ? `${labelValue} (${fwdField.extensionNumber})` : ''
    };
  };

  const initialFormValues: FormT = {
    stepName: transferNode.name,
    targetNumber: convertToFwdField(transferNode, linkedLabel || linkedUsername)
  };

  const waTransferSchema = yup.object().shape({
    stepName: yup
      .string()
      .matches(
        /^[A-Za-z0-9_]+$/,
        t('callflows.welcomeAttendantGeneric.stepNameValidationErrorInvalid')
      ),
    targetNumber: validateForwardingField(t('callflows.validation.forwardingError'))
  });

  // update
  const onSubmit = async (formData: FormT): Promise<AcdEntityT> => {
    // Update WelcomeAttendant
    const commandName = transferNode.name.toUpperCase();
    const patchPayload: WelcomeAttendantUpdateEntityT = buildWelcomeAttendantTransferNodeUpdatePayload(
      welcomeAttendantData,
      formData,
      commandName
    );

    const modifiedPayload = renameCommandName(
      patchPayload,
      commandName,
      formData.stepName.toUpperCase()
    );

    const returnValue = await dispatch(
      updateWelcomeAttendant(
        enterpriseId,
        welcomeAttendantData.id,
        modifiedPayload,
        requestCancelTokenSource.token,
        createCsrfHeader(currentUser)
      )
    );
    dispatch(
      createUpdateSelectedStatus(
        welcomeAttendantData.id,
        'TRANSFER',
        formData.stepName.toUpperCase()
      )
    );
    return returnValue;
  };

  return (
    <EditCallflowDetails
      nodeId={welcomeAttendantData.id}
      icon={getTransferIcon(linkedNumber)}
      title={t('callflows.editWelcomeAttendantTransfer.title')}
      description={t('callflows.editWelcomeAttendantTransfer.description')}
      defaultValues={initialFormValues}
      validationSchema={waTransferSchema}
      onSaveForm={onSubmit}
    >
      <InputField
        field="stepName"
        title={t('callflows.welcomeAttendantGeneric.stepName')}
        shouldValidate
        tooltip={t('callflows.editWelcomeAttendantTransfer.stepNameTooltip')}
      />
      <ForwardingField
        field="targetNumber"
        title={t('callflows.editWelcomeAttendantTransfer.targetNumber')}
        filterSearchResults={addressNumber =>
          filterOwnAddressNumber(welcomeAttendantData.addressNumber, addressNumber)
        }
        noEndTransfer
        tooltip={t('callflows.editWelcomeAttendantTransfer.targetNumberTooltip')}
      />
    </EditCallflowDetails>
  );
};

export default EditTransferDetails;
