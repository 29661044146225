(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define("component-library", ["prop-types", "react"], factory);
	else if(typeof exports === 'object')
		exports["component-library"] = factory(require("prop-types"), require("react"));
	else
		root["component-library"] = factory(root["prop-types"], root["react"]);
})(typeof self !== 'undefined' ? self : this, (__WEBPACK_EXTERNAL_MODULE__1949__, __WEBPACK_EXTERNAL_MODULE__9155__) => {
return 