// @flow

import React, { type Element, type Node, type ChildrenArray } from 'react';
import LanguageSelector from './LanguageSelector';
import Footer from './Footer';

import styles from './LoginBase.module.scss';

export type PropsT = {
  children: ChildrenArray<Node> | Node
};

export const LoginBase = ({ children }: PropsT): Element<'div'> => (
  <div className="login-page">
    <div className={styles['page-container']}>
      <div className={styles['content-container']}>
        <div className={styles.title}>Elisa OmaRing</div>
        <div className={styles.language}>
          <LanguageSelector />
        </div>
        {children}
      </div>
    </div>
    <Footer />
  </div>
);

export default LoginBase;
