// @flow

import React, { type Element } from 'react';
import styles from './TextError.module.scss';

type PropsT = {
  message: string
};

const TextError = (props: PropsT): Element<'div'> => (
  <div className={styles.container}>
    <p>{props.message}</p>
  </div>
);

export default TextError;
