// @flow
import React, { type Element } from 'react';
import LoadingSpinner from '@design-system/component-library/src/components/LoadingSpinner';
import styles from './LoadingView.module.scss';
import CenterHorizontally from '../../../../../components/CenterHorizontally/CenterHorizontally';

export type PropsT = {};

// eslint-disable-next-line no-unused-vars
export const LoadingView = (props: PropsT): Element<'div'> => (
  <div className={styles['loading-spinner-container']}>
    <CenterHorizontally>
      <LoadingSpinner />
    </CenterHorizontally>
  </div>
);

export default LoadingView;
