// @flow
import React, { type Element } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import AudioFileSelection from './AudioFileSelection';
import type { AudioFieldT, AudioT } from './CallflowAudioUtils';
import { getAudioPlayData } from './CallflowAudioUtils';

import styles from './AcdAudioField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  activeField?: string,
  description?: string
|};

export const AcdExtensionAudioField = (props: PropsT): Element<'div'> => {
  const { field, title, description, activeField } = props;
  const { t } = useTranslation();
  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  const audioField: AudioFieldT<AudioT> = values[field];
  const extensionAudioData: AudioT = getAudioPlayData(audioField, 'Extension');
  const customAudioFileSelector = (
    <div data-cy={field}>
      <AudioFileSelection
        audio={extensionAudioData}
        onChange={file =>
          setValue(
            field,
            {
              ...values[field],
              fileToImport: file
            },
            {
              shouldValidate: false,
              shouldDirty: true
            }
          )
        }
        disabled={values[activeField]}
        showInitialView
      />
    </div>
  );

  return (
    <div className={title && styles['title-padding']}>
      {title && <div className={classnames(styles['small-title'])}>{title}</div>}
      <div className={styles.description}>
        {description === undefined ? t('callflows.audioField.description') : description}
      </div>
      <div role="group" aria-label="Message type">
        {customAudioFileSelector}
      </div>
    </div>
  );
};

export default AcdExtensionAudioField;
