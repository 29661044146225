import React, { useContext, useState, useImperativeHandle, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIdWithFallback } from '../../utils/hooks';
import { classNames } from '../../utils/css';
import IconArrowDownFilled from '../Icon/lib/IconArrowDownFilled';
import styles from './Accordion.module.scss';

function AccordionItemInternal(
  {
    id = null,
    className = undefined,
    contentClasses = undefined,
    heading = '',
    headingLevel = 'h3',
    headingSize = 'm',
    badge = null,
    badgeType = 'numeric',
    badgeColor = 'secondary',
    defaultOpen = false,
    children = undefined,
    content = undefined,
    onToggle = () => {},
    onClick = () => {},
    onOpen = () => {},
    onClose = () => {},
    onItemClick = () => {},
    onItemOpen = () => {},
    onItemClose = () => {},
    itemRef = null,
    ...otherProps
  },
  ref
){
  const htmlId = useIdWithFallback('dsAccordionItem', id);

  const onClickHandler = (event) => {
    onClick(event);
    onItemClick(event);

    const section = document.getElementById(htmlId);

    if(section.classList.contains(styles['accordion__section--open'])){
      onClose(event);
      onItemClose(event);
    }
    else{
      onOpen(event);
      onItemOpen(event);
    }

    onToggle(htmlId);
  }

  let itemBadge = null;
  if (badge) {
    // If you run this in storybook, you need the default, whereas the built version doesn't.
    //  No idea why (see https://github.com/elisa-design-system/component-library/issues/315).
    const BadgeAll = require('../Badge'); // eslint-disable-line global-require
    const BadgeComponent = BadgeAll.default ?? BadgeAll;
    itemBadge = <BadgeComponent color={badgeColor} type={badgeType} text={badge} />;
  }

  const sectionClasses = classNames([
    styles.accordion__section,
    defaultOpen ? styles[`accordion__section--open`] : null,
    className,
  ]);

  const buttonClasses = classNames([
    styles.accordion__section__heading__button,
    styles[`accordion__section__heading__button--${headingSize}`]
  ]);

  const classesForContent = classNames([
    styles.accordion__section__content,
    contentClasses,
  ]);

  const titleContent = (
    <button
      type="button"
      id={`${htmlId}__sectionHeadingButton`}
      className={buttonClasses}
      aria-expanded={defaultOpen}
      aria-controls={`${htmlId}__sectionContent`}
      onClick={onClickHandler}
    >
      <span className={styles[`accordion__section__heading--title`]}>{heading}</span>
      <span className={styles[`accordion__section__heading--indicators`]}>
        {itemBadge}
        <IconArrowDownFilled />
      </span>
    </button>
  );

  const titleTag = () => React.createElement(headingLevel, { className: styles.accordion__section__heading, id: `${htmlId}__sectionHeading` }, titleContent);

  return (
    <section
      id={htmlId}
      key={htmlId}
      ref={ref || itemRef}
      className={sectionClasses}
      {...otherProps}
    >
      {titleTag()}
      <div
        aria-hidden={!defaultOpen}
        id={`${htmlId}__sectionContent`}
        role="region"
        aria-labelledby={`${htmlId}__sectionHeadingButton`}
        className={classesForContent}
        style={{ height: defaultOpen ? 'unset' : 0, display: defaultOpen ? 'block' : 'none' }}
      >
        <div className={styles[`accordion__section__content-wrapper`]}>{children || content}</div>
      </div>
    </section>
  );
}

const AccordionItem = React.forwardRef(AccordionItemInternal);
export default AccordionItem;

AccordionItem.propTypes = {
  /**
   * Custom id for the component. If none is given, this is automatically generated.
   */
  id: PropTypes.string,
  /**
   * Custom class names for the component.
   */
  className: PropTypes.string,
  /**
   * AccordionItem's heading that is visible when the item is closed.
   */
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /**
   * Heading level
   */
  headingLevel: PropTypes.oneOf(['div', 'h2', 'h3', 'h4']),
  /**
   * Heading size
   */
  headingSize: PropTypes.oneOf(['s', 'm', 'l']),
  /**
   * Header badge's text.
   */
  badge: PropTypes.string,
  /**
   * Header badge's type.
   */
  badgeType: PropTypes.string,
  /**
   * Header badge's color.
   */
  badgeColor: PropTypes.string,
  /**
   * Whether the AccordionItem is open by default when the page loads.
   */
  defaultOpen: PropTypes.bool,
  /**
   * AccordionItem's content. Automatically detected.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  /**
   * Item's content as text or HTML.
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  /**
   * Function that launches when the Accordion is clicked
   */
  onClick: PropTypes.func,
  /**
   * Function that launches when the Accordion opens
   */
  onOpen: PropTypes.func,
  /**
   * Function that launches when the Accordion closes
   */
  onClose: PropTypes.func,
  /**
   * Ref for the AccordionItem
   */
  itemRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.element })]),
};
