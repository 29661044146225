// @flow

import React, { type Element } from 'react';
import Dismiss from './Button/Dismiss';
import ActionButton from './Button/ActionButton';
import CancelButton from './Button/CancelButton';
import ImageDropzone from './ImageUpload/ImageDropzone';

import styles from './Dialog.module.scss';

export type PropsT = {
  onCancel: (*) => *,
  onConfirm: (*) => *,
  onDelete: (*) => *,
  onClose?: (*) => *,
  onFileChange: string => void,
  title: string,
  confirmLabel: string,
  cancelLabel: string,
  disabled: boolean,
  loading: boolean,
  errorMessage?: string,
  initialImageUrl: ?string
};

const UploadDialog = ({
  loading,
  onCancel,
  onConfirm,
  onDelete,
  onClose,
  onFileChange,
  title,
  confirmLabel,
  cancelLabel,
  disabled,
  errorMessage,
  initialImageUrl
}: PropsT): Element<'div'> => (
  <div className={`ea-modal ea-modal--open styleguide-dialog-position ${styles.container}`}>
    <div className="ea-modal__overlay" />
    <div id="upload-dialog-content" className={`ea-modal__content ${styles.content}`}>
      <div className={styles.box}>
        {onClose && <Dismiss id="close-button" onClose={onClose} />}
        <h2 id="dialog-title" className="ea-h3 ea-h3--thick">
          {title}
        </h2>
        <ImageDropzone
          onChange={onFileChange}
          initialImageUrl={initialImageUrl}
          onDelete={onDelete}
        />
        {errorMessage && (
          <div id="dialog-error" className={styles.error}>
            <span className="ea-icon ea-icon--large ea-icon--notification" />
            <span>{errorMessage}</span>
          </div>
        )}
        <div className={styles.buttons}>
          <ActionButton
            id="modal-confirm-button"
            label={confirmLabel}
            onClickAction={onConfirm}
            disabled={disabled}
            loading={loading}
          />
          <CancelButton id="modal-cancel-button" label={cancelLabel} onClickAction={onCancel} />
        </div>
      </div>
    </div>
  </div>
);

export default UploadDialog;
