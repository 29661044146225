import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowUpLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.36 15.18 12.72 8.3A1 1 0 0 0 12 8a1 1 0 0 0-.72.3l-6.64 6.88a.5.5 0 1 0 .669.743q.028-.025.051-.054L12 9l6.64 6.88a.502.502 0 0 0 .72-.7"/></svg>)}
    />
  );
}
  