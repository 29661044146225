import React from 'react';
import IconContainer from '../IconContainer';

export default function IconUpwardFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.999 8.51a1.94 1.94 0 0 1-.537 1.4c-.36.39-.852.588-1.461.588h-2.5v11.006c0 .825-.671 1.496-1.496 1.496H9.996A1.5 1.5 0 0 1 8.5 21.504V10.498H6.071c-1.107 0-2.036-.847-2.069-1.889-.019-.565.186-1.025.644-1.449l5.982-5.625a2.03 2.03 0 0 1 2.74-.003l5.989 5.629c.392.364.621.844.642 1.349"/></svg>)}
    />
  );
}
  