import IconAccessoryFilled from './IconAccessoryFilled';
import IconAccessoryLight from './IconAccessoryLight';
import IconAccessoryRegular from './IconAccessoryRegular';
import IconAddFilled from './IconAddFilled';
import IconAddLight from './IconAddLight';
import IconAddRegular from './IconAddRegular';
import IconAddCircleFilled from './IconAddCircleFilled';
import IconAddCircleLight from './IconAddCircleLight';
import IconAddCircleRegular from './IconAddCircleRegular';
import IconAddressBookFilled from './IconAddressBookFilled';
import IconAddressBookLight from './IconAddressBookLight';
import IconAddressBookRegular from './IconAddressBookRegular';
import IconAnnouncementFilled from './IconAnnouncementFilled';
import IconAnnouncementLight from './IconAnnouncementLight';
import IconAnnouncementRegular from './IconAnnouncementRegular';
import IconArrangeAscFilled from './IconArrangeAscFilled';
import IconArrangeAscLight from './IconArrangeAscLight';
import IconArrangeAscRegular from './IconArrangeAscRegular';
import IconArrangeDescFilled from './IconArrangeDescFilled';
import IconArrangeDescLight from './IconArrangeDescLight';
import IconArrangeDescRegular from './IconArrangeDescRegular';
import IconArrowDownFilled from './IconArrowDownFilled';
import IconArrowDownLight from './IconArrowDownLight';
import IconArrowDownRegular from './IconArrowDownRegular';
import IconArrowDownAltFilled from './IconArrowDownAltFilled';
import IconArrowDownAltLight from './IconArrowDownAltLight';
import IconArrowDownAltRegular from './IconArrowDownAltRegular';
import IconArrowLeftFilled from './IconArrowLeftFilled';
import IconArrowLeftLight from './IconArrowLeftLight';
import IconArrowLeftRegular from './IconArrowLeftRegular';
import IconArrowLeftAltFilled from './IconArrowLeftAltFilled';
import IconArrowLeftAltLight from './IconArrowLeftAltLight';
import IconArrowLeftAltRegular from './IconArrowLeftAltRegular';
import IconArrowRightFilled from './IconArrowRightFilled';
import IconArrowRightLight from './IconArrowRightLight';
import IconArrowRightRegular from './IconArrowRightRegular';
import IconArrowRightAltFilled from './IconArrowRightAltFilled';
import IconArrowRightAltLight from './IconArrowRightAltLight';
import IconArrowRightAltRegular from './IconArrowRightAltRegular';
import IconArrowUpFilled from './IconArrowUpFilled';
import IconArrowUpLight from './IconArrowUpLight';
import IconArrowUpRegular from './IconArrowUpRegular';
import IconArrowUpAltFilled from './IconArrowUpAltFilled';
import IconArrowUpAltLight from './IconArrowUpAltLight';
import IconArrowUpAltRegular from './IconArrowUpAltRegular';
import IconAsterixFilled from './IconAsterixFilled';
import IconAsterixLight from './IconAsterixLight';
import IconAsterixRegular from './IconAsterixRegular';
import IconAttachmentFilled from './IconAttachmentFilled';
import IconAttachmentLight from './IconAttachmentLight';
import IconAttachmentRegular from './IconAttachmentRegular';
import IconAuthorizationFilled from './IconAuthorizationFilled';
import IconAuthorizationLight from './IconAuthorizationLight';
import IconAuthorizationRegular from './IconAuthorizationRegular';
import IconBankFilled from './IconBankFilled';
import IconBankLight from './IconBankLight';
import IconBankRegular from './IconBankRegular';
import IconBlockFilled from './IconBlockFilled';
import IconBlockLight from './IconBlockLight';
import IconBlockRegular from './IconBlockRegular';
import IconBookFilled from './IconBookFilled';
import IconBookLight from './IconBookLight';
import IconBookRegular from './IconBookRegular';
import IconBroadbandFilled from './IconBroadbandFilled';
import IconBroadbandLight from './IconBroadbandLight';
import IconBroadbandRegular from './IconBroadbandRegular';
import IconBulletsFilled from './IconBulletsFilled';
import IconBulletsLight from './IconBulletsLight';
import IconBulletsRegular from './IconBulletsRegular';
import IconCablecardFilled from './IconCablecardFilled';
import IconCablecardLight from './IconCablecardLight';
import IconCablecardRegular from './IconCablecardRegular';
import IconCalendarFilled from './IconCalendarFilled';
import IconCalendarLight from './IconCalendarLight';
import IconCalendarRegular from './IconCalendarRegular';
import IconCalendarTimeFilled from './IconCalendarTimeFilled';
import IconCalendarTimeLight from './IconCalendarTimeLight';
import IconCalendarTimeRegular from './IconCalendarTimeRegular';
import IconCameraFilled from './IconCameraFilled';
import IconCameraLight from './IconCameraLight';
import IconCameraRegular from './IconCameraRegular';
import IconCartFilled from './IconCartFilled';
import IconCartLight from './IconCartLight';
import IconCartRegular from './IconCartRegular';
import IconChatFilled from './IconChatFilled';
import IconChatLight from './IconChatLight';
import IconChatRegular from './IconChatRegular';
import IconCheckFilled from './IconCheckFilled';
import IconCheckLight from './IconCheckLight';
import IconCheckRegular from './IconCheckRegular';
import IconClockFilled from './IconClockFilled';
import IconClockLight from './IconClockLight';
import IconClockRegular from './IconClockRegular';
import IconCloseFilled from './IconCloseFilled';
import IconCloseLight from './IconCloseLight';
import IconCloseRegular from './IconCloseRegular';
import IconContinuousRecordingFilled from './IconContinuousRecordingFilled';
import IconContinuousRecordingLight from './IconContinuousRecordingLight';
import IconContinuousRecordingRegular from './IconContinuousRecordingRegular';
import IconCreditCardFilled from './IconCreditCardFilled';
import IconCreditCardLight from './IconCreditCardLight';
import IconCreditCardRegular from './IconCreditCardRegular';
import IconCustomerServiceFilled from './IconCustomerServiceFilled';
import IconCustomerServiceLight from './IconCustomerServiceLight';
import IconCustomerServiceRegular from './IconCustomerServiceRegular';
import IconDeleteFilled from './IconDeleteFilled';
import IconDeleteLight from './IconDeleteLight';
import IconDeleteRegular from './IconDeleteRegular';
import IconDeliveryFilled from './IconDeliveryFilled';
import IconDeliveryLight from './IconDeliveryLight';
import IconDeliveryRegular from './IconDeliveryRegular';
import IconDevicesFilled from './IconDevicesFilled';
import IconDevicesLight from './IconDevicesLight';
import IconDevicesRegular from './IconDevicesRegular';
import IconDiamondFilled from './IconDiamondFilled';
import IconDiamondLight from './IconDiamondLight';
import IconDiamondRegular from './IconDiamondRegular';
import IconDisplayFilled from './IconDisplayFilled';
import IconDisplayLight from './IconDisplayLight';
import IconDisplayRegular from './IconDisplayRegular';
import IconDisturbanceFilled from './IconDisturbanceFilled';
import IconDisturbanceLight from './IconDisturbanceLight';
import IconDisturbanceRegular from './IconDisturbanceRegular';
import IconDownloadFilled from './IconDownloadFilled';
import IconDownloadLight from './IconDownloadLight';
import IconDownloadRegular from './IconDownloadRegular';
import IconDownloadingFilled from './IconDownloadingFilled';
import IconDownloadingLight from './IconDownloadingLight';
import IconDownloadingRegular from './IconDownloadingRegular';
import IconDraftFilled from './IconDraftFilled';
import IconDraftLight from './IconDraftLight';
import IconDraftRegular from './IconDraftRegular';
import IconDraftSaveFilled from './IconDraftSaveFilled';
import IconDraftSaveLight from './IconDraftSaveLight';
import IconDraftSaveRegular from './IconDraftSaveRegular';
import IconDroneFilled from './IconDroneFilled';
import IconDroneLight from './IconDroneLight';
import IconDroneRegular from './IconDroneRegular';
import IconEditFilled from './IconEditFilled';
import IconEditLight from './IconEditLight';
import IconEditRegular from './IconEditRegular';
import IconElisaKirja from './IconElisaKirja';
import IconElisaViihde from './IconElisaViihde';
import IconEmailFilled from './IconEmailFilled';
import IconEmailLight from './IconEmailLight';
import IconEmailRegular from './IconEmailRegular';
import IconEmailAddressFilled from './IconEmailAddressFilled';
import IconEmailAddressLight from './IconEmailAddressLight';
import IconEmailAddressRegular from './IconEmailAddressRegular';
import IconEmailReadFilled from './IconEmailReadFilled';
import IconEmailReadLight from './IconEmailReadLight';
import IconEmailReadRegular from './IconEmailReadRegular';
import IconEmailSentFilled from './IconEmailSentFilled';
import IconEmailSentLight from './IconEmailSentLight';
import IconEmailSentRegular from './IconEmailSentRegular';
import IconErrorFilled from './IconErrorFilled';
import IconErrorLight from './IconErrorLight';
import IconErrorRegular from './IconErrorRegular';
import IconEuroFilled from './IconEuroFilled';
import IconEuroLight from './IconEuroLight';
import IconEuroRegular from './IconEuroRegular';
import IconExternalLinkFilled from './IconExternalLinkFilled';
import IconExternalLinkLight from './IconExternalLinkLight';
import IconExternalLinkRegular from './IconExternalLinkRegular';
import IconFacebookFilled from './IconFacebookFilled';
import IconFacebookLight from './IconFacebookLight';
import IconFacebookRegular from './IconFacebookRegular';
import IconFileDocFilled from './IconFileDocFilled';
import IconFileDocLight from './IconFileDocLight';
import IconFileDocRegular from './IconFileDocRegular';
import IconFileGeneralFilled from './IconFileGeneralFilled';
import IconFileGeneralLight from './IconFileGeneralLight';
import IconFileGeneralRegular from './IconFileGeneralRegular';
import IconFilePdfFilled from './IconFilePdfFilled';
import IconFilePdfLight from './IconFilePdfLight';
import IconFilePdfRegular from './IconFilePdfRegular';
import IconFileTxtFilled from './IconFileTxtFilled';
import IconFileTxtLight from './IconFileTxtLight';
import IconFileTxtRegular from './IconFileTxtRegular';
import IconFileXlsFilled from './IconFileXlsFilled';
import IconFileXlsLight from './IconFileXlsLight';
import IconFileXlsRegular from './IconFileXlsRegular';
import IconFileZipFilled from './IconFileZipFilled';
import IconFileZipLight from './IconFileZipLight';
import IconFileZipRegular from './IconFileZipRegular';
import IconFilmCameraFilled from './IconFilmCameraFilled';
import IconFilmCameraLight from './IconFilmCameraLight';
import IconFilmCameraRegular from './IconFilmCameraRegular';
import IconFilterFilled from './IconFilterFilled';
import IconFilterLight from './IconFilterLight';
import IconFilterRegular from './IconFilterRegular';
import IconFlagFilled from './IconFlagFilled';
import IconFlagLight from './IconFlagLight';
import IconFlagRegular from './IconFlagRegular';
import IconFolderFilled from './IconFolderFilled';
import IconFolderLight from './IconFolderLight';
import IconFolderRegular from './IconFolderRegular';
import IconFolderOpenFilled from './IconFolderOpenFilled';
import IconFolderOpenLight from './IconFolderOpenLight';
import IconFolderOpenRegular from './IconFolderOpenRegular';
import IconForwardFilled from './IconForwardFilled';
import IconForwardLight from './IconForwardLight';
import IconForwardRegular from './IconForwardRegular';
import IconFreedomeFilled from './IconFreedomeFilled';
import IconFreedomeLight from './IconFreedomeLight';
import IconFreedomeRegular from './IconFreedomeRegular';
import IconGamingFilled from './IconGamingFilled';
import IconGamingLight from './IconGamingLight';
import IconGamingRegular from './IconGamingRegular';
import IconGlobeFilled from './IconGlobeFilled';
import IconGlobeLight from './IconGlobeLight';
import IconGlobeRegular from './IconGlobeRegular';
import IconGroupFilled from './IconGroupFilled';
import IconGroupLight from './IconGroupLight';
import IconGroupRegular from './IconGroupRegular';
import IconGuideFilled from './IconGuideFilled';
import IconGuideLight from './IconGuideLight';
import IconGuideRegular from './IconGuideRegular';
import IconHeadphonesFilled from './IconHeadphonesFilled';
import IconHeadphonesLight from './IconHeadphonesLight';
import IconHeadphonesRegular from './IconHeadphonesRegular';
import IconHealthFilled from './IconHealthFilled';
import IconHealthLight from './IconHealthLight';
import IconHealthRegular from './IconHealthRegular';
import IconHelpFilled from './IconHelpFilled';
import IconHelpLight from './IconHelpLight';
import IconHelpRegular from './IconHelpRegular';
import IconHomeFilled from './IconHomeFilled';
import IconHomeLight from './IconHomeLight';
import IconHomeRegular from './IconHomeRegular';
import IconHomeApplianceFilled from './IconHomeApplianceFilled';
import IconHomeApplianceLight from './IconHomeApplianceLight';
import IconHomeApplianceRegular from './IconHomeApplianceRegular';
import IconHotFilled from './IconHotFilled';
import IconHotLight from './IconHotLight';
import IconHotRegular from './IconHotRegular';
import IconInboxFilled from './IconInboxFilled';
import IconInboxLight from './IconInboxLight';
import IconInboxRegular from './IconInboxRegular';
import IconInformationFilled from './IconInformationFilled';
import IconInformationLight from './IconInformationLight';
import IconInformationRegular from './IconInformationRegular';
import IconInstagramFilled from './IconInstagramFilled';
import IconInstagramLight from './IconInstagramLight';
import IconInstagramRegular from './IconInstagramRegular';
import IconInvestorsFilled from './IconInvestorsFilled';
import IconInvestorsLight from './IconInvestorsLight';
import IconInvestorsRegular from './IconInvestorsRegular';
import IconInvoiceFilled from './IconInvoiceFilled';
import IconInvoiceLight from './IconInvoiceLight';
import IconInvoiceRegular from './IconInvoiceRegular';
import IconInvoiceMultipleFilled from './IconInvoiceMultipleFilled';
import IconInvoiceMultipleLight from './IconInvoiceMultipleLight';
import IconInvoiceMultipleRegular from './IconInvoiceMultipleRegular';
import IconKeyFilled from './IconKeyFilled';
import IconKeyLight from './IconKeyLight';
import IconKeyRegular from './IconKeyRegular';
import IconLaptopFilled from './IconLaptopFilled';
import IconLaptopLight from './IconLaptopLight';
import IconLaptopRegular from './IconLaptopRegular';
import IconLikeFilled from './IconLikeFilled';
import IconLikeLight from './IconLikeLight';
import IconLikeRegular from './IconLikeRegular';
import IconLinkFilled from './IconLinkFilled';
import IconLinkLight from './IconLinkLight';
import IconLinkRegular from './IconLinkRegular';
import IconLinkedinFilled from './IconLinkedinFilled';
import IconLinkedinLight from './IconLinkedinLight';
import IconLinkedinRegular from './IconLinkedinRegular';
import IconLiveNowFilled from './IconLiveNowFilled';
import IconLiveNowLight from './IconLiveNowLight';
import IconLiveNowRegular from './IconLiveNowRegular';
import IconLocationFilled from './IconLocationFilled';
import IconLocationLight from './IconLocationLight';
import IconLocationRegular from './IconLocationRegular';
import IconLocationUnknownFilled from './IconLocationUnknownFilled';
import IconLocationUnknownLight from './IconLocationUnknownLight';
import IconLocationUnknownRegular from './IconLocationUnknownRegular';
import IconLockFilled from './IconLockFilled';
import IconLockLight from './IconLockLight';
import IconLockRegular from './IconLockRegular';
import IconLockOpenFilled from './IconLockOpenFilled';
import IconLockOpenLight from './IconLockOpenLight';
import IconLockOpenRegular from './IconLockOpenRegular';
import IconLogoAppleRegular from './IconLogoAppleRegular';
import IconLogoHonorRegular from './IconLogoHonorRegular';
import IconLogoHuaweiRegular from './IconLogoHuaweiRegular';
import IconLogoMsOfficeRegular from './IconLogoMsOfficeRegular';
import IconLogoOneplusRegular from './IconLogoOneplusRegular';
import IconLogoSamsungRegular from './IconLogoSamsungRegular';
import IconLogoSonyRegular from './IconLogoSonyRegular';
import IconLogoSpotifyRegular from './IconLogoSpotifyRegular';
import IconLogoutFilled from './IconLogoutFilled';
import IconLogoutLight from './IconLogoutLight';
import IconLogoutRegular from './IconLogoutRegular';
import IconMaintenanceFilled from './IconMaintenanceFilled';
import IconMaintenanceLight from './IconMaintenanceLight';
import IconMaintenanceRegular from './IconMaintenanceRegular';
import IconMenuFilled from './IconMenuFilled';
import IconMenuLight from './IconMenuLight';
import IconMenuRegular from './IconMenuRegular';
import IconMessageFilled from './IconMessageFilled';
import IconMessageLight from './IconMessageLight';
import IconMessageRegular from './IconMessageRegular';
import IconMobiilivarmenneRegular from './IconMobiilivarmenneRegular';
import IconMobileBroadbandFilled from './IconMobileBroadbandFilled';
import IconMobileBroadbandLight from './IconMobileBroadbandLight';
import IconMobileBroadbandRegular from './IconMobileBroadbandRegular';
import IconMobilePaymentFilled from './IconMobilePaymentFilled';
import IconMobilePaymentLight from './IconMobilePaymentLight';
import IconMobilePaymentRegular from './IconMobilePaymentRegular';
import IconMoneyFilled from './IconMoneyFilled';
import IconMoneyLight from './IconMoneyLight';
import IconMoneyRegular from './IconMoneyRegular';
import IconMoreFilled from './IconMoreFilled';
import IconMoreLight from './IconMoreLight';
import IconMoreRegular from './IconMoreRegular';
import IconMoreVerticalFilled from './IconMoreVerticalFilled';
import IconMoreVerticalLight from './IconMoreVerticalLight';
import IconMoreVerticalRegular from './IconMoreVerticalRegular';
import IconNetworkFilled from './IconNetworkFilled';
import IconNetworkLight from './IconNetworkLight';
import IconNetworkRegular from './IconNetworkRegular';
import IconNewMessageFilled from './IconNewMessageFilled';
import IconNewMessageLight from './IconNewMessageLight';
import IconNewMessageRegular from './IconNewMessageRegular';
import IconNonWatchableFilled from './IconNonWatchableFilled';
import IconNonWatchableLight from './IconNonWatchableLight';
import IconNonWatchableRegular from './IconNonWatchableRegular';
import IconNotificationFilled from './IconNotificationFilled';
import IconNotificationLight from './IconNotificationLight';
import IconNotificationRegular from './IconNotificationRegular';
import IconOfficeFilled from './IconOfficeFilled';
import IconOfficeLight from './IconOfficeLight';
import IconOfficeRegular from './IconOfficeRegular';
import IconOmaguruFilled from './IconOmaguruFilled';
import IconOmaguruLight from './IconOmaguruLight';
import IconOmaguruRegular from './IconOmaguruRegular';
import IconOnDeviceFilled from './IconOnDeviceFilled';
import IconOnDeviceLight from './IconOnDeviceLight';
import IconOnDeviceRegular from './IconOnDeviceRegular';
import IconPhoneFilled from './IconPhoneFilled';
import IconPhoneLight from './IconPhoneLight';
import IconPhoneRegular from './IconPhoneRegular';
import IconPictureFilled from './IconPictureFilled';
import IconPictureLight from './IconPictureLight';
import IconPictureRegular from './IconPictureRegular';
import IconPictureAddFilled from './IconPictureAddFilled';
import IconPictureAddLight from './IconPictureAddLight';
import IconPictureAddRegular from './IconPictureAddRegular';
import IconPilvilinnaFilled from './IconPilvilinnaFilled';
import IconPilvilinnaLight from './IconPilvilinnaLight';
import IconPilvilinnaRegular from './IconPilvilinnaRegular';
import IconPinFilled from './IconPinFilled';
import IconPinLight from './IconPinLight';
import IconPinRegular from './IconPinRegular';
import IconPinterestFilled from './IconPinterestFilled';
import IconPinterestLight from './IconPinterestLight';
import IconPinterestRegular from './IconPinterestRegular';
import IconPlayFilled from './IconPlayFilled';
import IconPlayLight from './IconPlayLight';
import IconPlayRegular from './IconPlayRegular';
import IconPowerFilled from './IconPowerFilled';
import IconPowerLight from './IconPowerLight';
import IconPowerRegular from './IconPowerRegular';
import IconPrintFilled from './IconPrintFilled';
import IconPrintLight from './IconPrintLight';
import IconPrintRegular from './IconPrintRegular';
import IconPukFilled from './IconPukFilled';
import IconPukLight from './IconPukLight';
import IconPukRegular from './IconPukRegular';
import IconRecordFilled from './IconRecordFilled';
import IconRecordLight from './IconRecordLight';
import IconRecordRegular from './IconRecordRegular';
import IconRecruitmentFilled from './IconRecruitmentFilled';
import IconRecruitmentLight from './IconRecruitmentLight';
import IconRecruitmentRegular from './IconRecruitmentRegular';
import IconRemoveFilled from './IconRemoveFilled';
import IconRemoveLight from './IconRemoveLight';
import IconRemoveRegular from './IconRemoveRegular';
import IconReplyFilled from './IconReplyFilled';
import IconReplyLight from './IconReplyLight';
import IconReplyRegular from './IconReplyRegular';
import IconReplyAllFilled from './IconReplyAllFilled';
import IconReplyAllLight from './IconReplyAllLight';
import IconReplyAllRegular from './IconReplyAllRegular';
import IconRouterFilled from './IconRouterFilled';
import IconRouterLight from './IconRouterLight';
import IconRouterRegular from './IconRouterRegular';
import IconSalasanapankkiFilled from './IconSalasanapankkiFilled';
import IconSalasanapankkiLight from './IconSalasanapankkiLight';
import IconSalasanapankkiRegular from './IconSalasanapankkiRegular';
import IconSearchFilled from './IconSearchFilled';
import IconSearchLight from './IconSearchLight';
import IconSearchRegular from './IconSearchRegular';
import IconSendFilled from './IconSendFilled';
import IconSendLight from './IconSendLight';
import IconSendRegular from './IconSendRegular';
import IconServicesFilled from './IconServicesFilled';
import IconServicesLight from './IconServicesLight';
import IconServicesRegular from './IconServicesRegular';
import IconServicesGridFilled from './IconServicesGridFilled';
import IconServicesGridLight from './IconServicesGridLight';
import IconServicesGridRegular from './IconServicesGridRegular';
import IconSettingsFilled from './IconSettingsFilled';
import IconSettingsLight from './IconSettingsLight';
import IconSettingsRegular from './IconSettingsRegular';
import IconShareFilled from './IconShareFilled';
import IconShareLight from './IconShareLight';
import IconShareRegular from './IconShareRegular';
import IconShopFilled from './IconShopFilled';
import IconShopLight from './IconShopLight';
import IconShopRegular from './IconShopRegular';
import IconSimFilled from './IconSimFilled';
import IconSimLight from './IconSimLight';
import IconSimRegular from './IconSimRegular';
import IconSimAddFilled from './IconSimAddFilled';
import IconSimAddLight from './IconSimAddLight';
import IconSimAddRegular from './IconSimAddRegular';
import IconSmartWatchFilled from './IconSmartWatchFilled';
import IconSmartWatchLight from './IconSmartWatchLight';
import IconSmartWatchRegular from './IconSmartWatchRegular';
import IconSmarthomeFilled from './IconSmarthomeFilled';
import IconSmarthomeLight from './IconSmarthomeLight';
import IconSmarthomeRegular from './IconSmarthomeRegular';
import IconSmartphoneFilled from './IconSmartphoneFilled';
import IconSmartphoneLight from './IconSmartphoneLight';
import IconSmartphoneRegular from './IconSmartphoneRegular';
import IconStarFilled from './IconStarFilled';
import IconStarLight from './IconStarLight';
import IconStarRegular from './IconStarRegular';
import IconSustainabilityFilled from './IconSustainabilityFilled';
import IconSustainabilityLight from './IconSustainabilityLight';
import IconSustainabilityRegular from './IconSustainabilityRegular';
import IconSwitchFilled from './IconSwitchFilled';
import IconSwitchLight from './IconSwitchLight';
import IconSwitchRegular from './IconSwitchRegular';
import IconTabletFilled from './IconTabletFilled';
import IconTabletLight from './IconTabletLight';
import IconTabletRegular from './IconTabletRegular';
import IconThumbDownFilled from './IconThumbDownFilled';
import IconThumbDownLight from './IconThumbDownLight';
import IconThumbDownRegular from './IconThumbDownRegular';
import IconThumbUpFilled from './IconThumbUpFilled';
import IconThumbUpLight from './IconThumbUpLight';
import IconThumbUpRegular from './IconThumbUpRegular';
import IconTickFilled from './IconTickFilled';
import IconTickLight from './IconTickLight';
import IconTickRegular from './IconTickRegular';
import IconTiktokFilled from './IconTiktokFilled';
import IconTiktokLight from './IconTiktokLight';
import IconTiktokRegular from './IconTiktokRegular';
import IconTravelFilled from './IconTravelFilled';
import IconTravelLight from './IconTravelLight';
import IconTravelRegular from './IconTravelRegular';
import IconTurvapakettiFilled from './IconTurvapakettiFilled';
import IconTurvapakettiLight from './IconTurvapakettiLight';
import IconTurvapakettiRegular from './IconTurvapakettiRegular';
import IconTvFilled from './IconTvFilled';
import IconTvLight from './IconTvLight';
import IconTvRegular from './IconTvRegular';
import IconTwitterFilled from './IconTwitterFilled';
import IconTwitterLight from './IconTwitterLight';
import IconTwitterRegular from './IconTwitterRegular';
import IconUpdateFilled from './IconUpdateFilled';
import IconUpdateLight from './IconUpdateLight';
import IconUpdateRegular from './IconUpdateRegular';
import IconUploadFilled from './IconUploadFilled';
import IconUploadLight from './IconUploadLight';
import IconUploadRegular from './IconUploadRegular';
import IconUpwardFilled from './IconUpwardFilled';
import IconUpwardLight from './IconUpwardLight';
import IconUpwardRegular from './IconUpwardRegular';
import IconUserFilled from './IconUserFilled';
import IconUserLight from './IconUserLight';
import IconUserRegular from './IconUserRegular';
import IconViewFilled from './IconViewFilled';
import IconViewLight from './IconViewLight';
import IconViewRegular from './IconViewRegular';
import IconViewOffFilled from './IconViewOffFilled';
import IconViewOffLight from './IconViewOffLight';
import IconViewOffRegular from './IconViewOffRegular';
import IconWarningFilled from './IconWarningFilled';
import IconWarningLight from './IconWarningLight';
import IconWarningRegular from './IconWarningRegular';
import IconWebcamFilled from './IconWebcamFilled';
import IconWebcamLight from './IconWebcamLight';
import IconWebcamRegular from './IconWebcamRegular';
import IconWebsiteFilled from './IconWebsiteFilled';
import IconWebsiteLight from './IconWebsiteLight';
import IconWebsiteRegular from './IconWebsiteRegular';
import IconWhatsappFilled from './IconWhatsappFilled';
import IconWhatsappLight from './IconWhatsappLight';
import IconWhatsappRegular from './IconWhatsappRegular';
import IconWifiFilled from './IconWifiFilled';
import IconWifiLight from './IconWifiLight';
import IconWifiRegular from './IconWifiRegular';
import IconWifiOffFilled from './IconWifiOffFilled';
import IconWifiOffLight from './IconWifiOffLight';
import IconWifiOffRegular from './IconWifiOffRegular';
import IconYoutubeFilled from './IconYoutubeFilled';
import IconYoutubeLight from './IconYoutubeLight';
import IconYoutubeRegular from './IconYoutubeRegular';

export default {
  'accessory--filled': IconAccessoryFilled,
  'accessory--light': IconAccessoryLight,
  'accessory--regular': IconAccessoryRegular,
  'add--filled': IconAddFilled,
  'add--light': IconAddLight,
  'add--regular': IconAddRegular,
  'add-circle--filled': IconAddCircleFilled,
  'add-circle--light': IconAddCircleLight,
  'add-circle--regular': IconAddCircleRegular,
  'address-book--filled': IconAddressBookFilled,
  'address-book--light': IconAddressBookLight,
  'address-book--regular': IconAddressBookRegular,
  'announcement--filled': IconAnnouncementFilled,
  'announcement--light': IconAnnouncementLight,
  'announcement--regular': IconAnnouncementRegular,
  'arrange-asc--filled': IconArrangeAscFilled,
  'arrange-asc--light': IconArrangeAscLight,
  'arrange-asc--regular': IconArrangeAscRegular,
  'arrange-desc--filled': IconArrangeDescFilled,
  'arrange-desc--light': IconArrangeDescLight,
  'arrange-desc--regular': IconArrangeDescRegular,
  'arrow-down--filled': IconArrowDownFilled,
  'arrow-down--light': IconArrowDownLight,
  'arrow-down--regular': IconArrowDownRegular,
  'arrow-down-alt--filled': IconArrowDownAltFilled,
  'arrow-down-alt--light': IconArrowDownAltLight,
  'arrow-down-alt--regular': IconArrowDownAltRegular,
  'arrow-left--filled': IconArrowLeftFilled,
  'arrow-left--light': IconArrowLeftLight,
  'arrow-left--regular': IconArrowLeftRegular,
  'arrow-left-alt--filled': IconArrowLeftAltFilled,
  'arrow-left-alt--light': IconArrowLeftAltLight,
  'arrow-left-alt--regular': IconArrowLeftAltRegular,
  'arrow-right--filled': IconArrowRightFilled,
  'arrow-right--light': IconArrowRightLight,
  'arrow-right--regular': IconArrowRightRegular,
  'arrow-right-alt--filled': IconArrowRightAltFilled,
  'arrow-right-alt--light': IconArrowRightAltLight,
  'arrow-right-alt--regular': IconArrowRightAltRegular,
  'arrow-up--filled': IconArrowUpFilled,
  'arrow-up--light': IconArrowUpLight,
  'arrow-up--regular': IconArrowUpRegular,
  'arrow-up-alt--filled': IconArrowUpAltFilled,
  'arrow-up-alt--light': IconArrowUpAltLight,
  'arrow-up-alt--regular': IconArrowUpAltRegular,
  'asterix--filled': IconAsterixFilled,
  'asterix--light': IconAsterixLight,
  'asterix--regular': IconAsterixRegular,
  'attachment--filled': IconAttachmentFilled,
  'attachment--light': IconAttachmentLight,
  'attachment--regular': IconAttachmentRegular,
  'authorization--filled': IconAuthorizationFilled,
  'authorization--light': IconAuthorizationLight,
  'authorization--regular': IconAuthorizationRegular,
  'bank--filled': IconBankFilled,
  'bank--light': IconBankLight,
  'bank--regular': IconBankRegular,
  'block--filled': IconBlockFilled,
  'block--light': IconBlockLight,
  'block--regular': IconBlockRegular,
  'book--filled': IconBookFilled,
  'book--light': IconBookLight,
  'book--regular': IconBookRegular,
  'broadband--filled': IconBroadbandFilled,
  'broadband--light': IconBroadbandLight,
  'broadband--regular': IconBroadbandRegular,
  'bullets--filled': IconBulletsFilled,
  'bullets--light': IconBulletsLight,
  'bullets--regular': IconBulletsRegular,
  'cablecard--filled': IconCablecardFilled,
  'cablecard--light': IconCablecardLight,
  'cablecard--regular': IconCablecardRegular,
  'calendar--filled': IconCalendarFilled,
  'calendar--light': IconCalendarLight,
  'calendar--regular': IconCalendarRegular,
  'calendar-time--filled': IconCalendarTimeFilled,
  'calendar-time--light': IconCalendarTimeLight,
  'calendar-time--regular': IconCalendarTimeRegular,
  'camera--filled': IconCameraFilled,
  'camera--light': IconCameraLight,
  'camera--regular': IconCameraRegular,
  'cart--filled': IconCartFilled,
  'cart--light': IconCartLight,
  'cart--regular': IconCartRegular,
  'chat--filled': IconChatFilled,
  'chat--light': IconChatLight,
  'chat--regular': IconChatRegular,
  'check--filled': IconCheckFilled,
  'check--light': IconCheckLight,
  'check--regular': IconCheckRegular,
  'clock--filled': IconClockFilled,
  'clock--light': IconClockLight,
  'clock--regular': IconClockRegular,
  'close--filled': IconCloseFilled,
  'close--light': IconCloseLight,
  'close--regular': IconCloseRegular,
  'continuous-recording--filled': IconContinuousRecordingFilled,
  'continuous-recording--light': IconContinuousRecordingLight,
  'continuous-recording--regular': IconContinuousRecordingRegular,
  'credit-card--filled': IconCreditCardFilled,
  'credit-card--light': IconCreditCardLight,
  'credit-card--regular': IconCreditCardRegular,
  'customer-service--filled': IconCustomerServiceFilled,
  'customer-service--light': IconCustomerServiceLight,
  'customer-service--regular': IconCustomerServiceRegular,
  'delete--filled': IconDeleteFilled,
  'delete--light': IconDeleteLight,
  'delete--regular': IconDeleteRegular,
  'delivery--filled': IconDeliveryFilled,
  'delivery--light': IconDeliveryLight,
  'delivery--regular': IconDeliveryRegular,
  'devices--filled': IconDevicesFilled,
  'devices--light': IconDevicesLight,
  'devices--regular': IconDevicesRegular,
  'diamond--filled': IconDiamondFilled,
  'diamond--light': IconDiamondLight,
  'diamond--regular': IconDiamondRegular,
  'display--filled': IconDisplayFilled,
  'display--light': IconDisplayLight,
  'display--regular': IconDisplayRegular,
  'disturbance--filled': IconDisturbanceFilled,
  'disturbance--light': IconDisturbanceLight,
  'disturbance--regular': IconDisturbanceRegular,
  'download--filled': IconDownloadFilled,
  'download--light': IconDownloadLight,
  'download--regular': IconDownloadRegular,
  'downloading--filled': IconDownloadingFilled,
  'downloading--light': IconDownloadingLight,
  'downloading--regular': IconDownloadingRegular,
  'draft--filled': IconDraftFilled,
  'draft--light': IconDraftLight,
  'draft--regular': IconDraftRegular,
  'draft-save--filled': IconDraftSaveFilled,
  'draft-save--light': IconDraftSaveLight,
  'draft-save--regular': IconDraftSaveRegular,
  'drone--filled': IconDroneFilled,
  'drone--light': IconDroneLight,
  'drone--regular': IconDroneRegular,
  'edit--filled': IconEditFilled,
  'edit--light': IconEditLight,
  'edit--regular': IconEditRegular,
  'elisa-kirja': IconElisaKirja,
  'elisa-viihde': IconElisaViihde,
  'email--filled': IconEmailFilled,
  'email--light': IconEmailLight,
  'email--regular': IconEmailRegular,
  'email-address--filled': IconEmailAddressFilled,
  'email-address--light': IconEmailAddressLight,
  'email-address--regular': IconEmailAddressRegular,
  'email-read--filled': IconEmailReadFilled,
  'email-read--light': IconEmailReadLight,
  'email-read--regular': IconEmailReadRegular,
  'email-sent--filled': IconEmailSentFilled,
  'email-sent--light': IconEmailSentLight,
  'email-sent--regular': IconEmailSentRegular,
  'error--filled': IconErrorFilled,
  'error--light': IconErrorLight,
  'error--regular': IconErrorRegular,
  'euro--filled': IconEuroFilled,
  'euro--light': IconEuroLight,
  'euro--regular': IconEuroRegular,
  'external-link--filled': IconExternalLinkFilled,
  'external-link--light': IconExternalLinkLight,
  'external-link--regular': IconExternalLinkRegular,
  'facebook--filled': IconFacebookFilled,
  'facebook--light': IconFacebookLight,
  'facebook--regular': IconFacebookRegular,
  'file-doc--filled': IconFileDocFilled,
  'file-doc--light': IconFileDocLight,
  'file-doc--regular': IconFileDocRegular,
  'file-general--filled': IconFileGeneralFilled,
  'file-general--light': IconFileGeneralLight,
  'file-general--regular': IconFileGeneralRegular,
  'file-pdf--filled': IconFilePdfFilled,
  'file-pdf--light': IconFilePdfLight,
  'file-pdf--regular': IconFilePdfRegular,
  'file-txt--filled': IconFileTxtFilled,
  'file-txt--light': IconFileTxtLight,
  'file-txt--regular': IconFileTxtRegular,
  'file-xls--filled': IconFileXlsFilled,
  'file-xls--light': IconFileXlsLight,
  'file-xls--regular': IconFileXlsRegular,
  'file-zip--filled': IconFileZipFilled,
  'file-zip--light': IconFileZipLight,
  'file-zip--regular': IconFileZipRegular,
  'film-camera--filled': IconFilmCameraFilled,
  'film-camera--light': IconFilmCameraLight,
  'film-camera--regular': IconFilmCameraRegular,
  'filter--filled': IconFilterFilled,
  'filter--light': IconFilterLight,
  'filter--regular': IconFilterRegular,
  'flag--filled': IconFlagFilled,
  'flag--light': IconFlagLight,
  'flag--regular': IconFlagRegular,
  'folder--filled': IconFolderFilled,
  'folder--light': IconFolderLight,
  'folder--regular': IconFolderRegular,
  'folder-open--filled': IconFolderOpenFilled,
  'folder-open--light': IconFolderOpenLight,
  'folder-open--regular': IconFolderOpenRegular,
  'forward--filled': IconForwardFilled,
  'forward--light': IconForwardLight,
  'forward--regular': IconForwardRegular,
  'freedome--filled': IconFreedomeFilled,
  'freedome--light': IconFreedomeLight,
  'freedome--regular': IconFreedomeRegular,
  'gaming--filled': IconGamingFilled,
  'gaming--light': IconGamingLight,
  'gaming--regular': IconGamingRegular,
  'globe--filled': IconGlobeFilled,
  'globe--light': IconGlobeLight,
  'globe--regular': IconGlobeRegular,
  'group--filled': IconGroupFilled,
  'group--light': IconGroupLight,
  'group--regular': IconGroupRegular,
  'guide--filled': IconGuideFilled,
  'guide--light': IconGuideLight,
  'guide--regular': IconGuideRegular,
  'headphones--filled': IconHeadphonesFilled,
  'headphones--light': IconHeadphonesLight,
  'headphones--regular': IconHeadphonesRegular,
  'health--filled': IconHealthFilled,
  'health--light': IconHealthLight,
  'health--regular': IconHealthRegular,
  'help--filled': IconHelpFilled,
  'help--light': IconHelpLight,
  'help--regular': IconHelpRegular,
  'home--filled': IconHomeFilled,
  'home--light': IconHomeLight,
  'home--regular': IconHomeRegular,
  'home-appliance--filled': IconHomeApplianceFilled,
  'home-appliance--light': IconHomeApplianceLight,
  'home-appliance--regular': IconHomeApplianceRegular,
  'hot--filled': IconHotFilled,
  'hot--light': IconHotLight,
  'hot--regular': IconHotRegular,
  'inbox--filled': IconInboxFilled,
  'inbox--light': IconInboxLight,
  'inbox--regular': IconInboxRegular,
  'information--filled': IconInformationFilled,
  'information--light': IconInformationLight,
  'information--regular': IconInformationRegular,
  'instagram--filled': IconInstagramFilled,
  'instagram--light': IconInstagramLight,
  'instagram--regular': IconInstagramRegular,
  'investors--filled': IconInvestorsFilled,
  'investors--light': IconInvestorsLight,
  'investors--regular': IconInvestorsRegular,
  'invoice--filled': IconInvoiceFilled,
  'invoice--light': IconInvoiceLight,
  'invoice--regular': IconInvoiceRegular,
  'invoice-multiple--filled': IconInvoiceMultipleFilled,
  'invoice-multiple--light': IconInvoiceMultipleLight,
  'invoice-multiple--regular': IconInvoiceMultipleRegular,
  'key--filled': IconKeyFilled,
  'key--light': IconKeyLight,
  'key--regular': IconKeyRegular,
  'laptop--filled': IconLaptopFilled,
  'laptop--light': IconLaptopLight,
  'laptop--regular': IconLaptopRegular,
  'like--filled': IconLikeFilled,
  'like--light': IconLikeLight,
  'like--regular': IconLikeRegular,
  'link--filled': IconLinkFilled,
  'link--light': IconLinkLight,
  'link--regular': IconLinkRegular,
  'linkedin--filled': IconLinkedinFilled,
  'linkedin--light': IconLinkedinLight,
  'linkedin--regular': IconLinkedinRegular,
  'live-now--filled': IconLiveNowFilled,
  'live-now--light': IconLiveNowLight,
  'live-now--regular': IconLiveNowRegular,
  'location--filled': IconLocationFilled,
  'location--light': IconLocationLight,
  'location--regular': IconLocationRegular,
  'location-unknown--filled': IconLocationUnknownFilled,
  'location-unknown--light': IconLocationUnknownLight,
  'location-unknown--regular': IconLocationUnknownRegular,
  'lock--filled': IconLockFilled,
  'lock--light': IconLockLight,
  'lock--regular': IconLockRegular,
  'lock-open--filled': IconLockOpenFilled,
  'lock-open--light': IconLockOpenLight,
  'lock-open--regular': IconLockOpenRegular,
  'logo-apple--regular': IconLogoAppleRegular,
  'logo-honor--regular': IconLogoHonorRegular,
  'logo-huawei--regular': IconLogoHuaweiRegular,
  'logo-ms-office--regular': IconLogoMsOfficeRegular,
  'logo-oneplus--regular': IconLogoOneplusRegular,
  'logo-samsung--regular': IconLogoSamsungRegular,
  'logo-sony--regular': IconLogoSonyRegular,
  'logo-spotify--regular': IconLogoSpotifyRegular,
  'logout--filled': IconLogoutFilled,
  'logout--light': IconLogoutLight,
  'logout--regular': IconLogoutRegular,
  'maintenance--filled': IconMaintenanceFilled,
  'maintenance--light': IconMaintenanceLight,
  'maintenance--regular': IconMaintenanceRegular,
  'menu--filled': IconMenuFilled,
  'menu--light': IconMenuLight,
  'menu--regular': IconMenuRegular,
  'message--filled': IconMessageFilled,
  'message--light': IconMessageLight,
  'message--regular': IconMessageRegular,
  'mobiilivarmenne--regular': IconMobiilivarmenneRegular,
  'mobile-broadband--filled': IconMobileBroadbandFilled,
  'mobile-broadband--light': IconMobileBroadbandLight,
  'mobile-broadband--regular': IconMobileBroadbandRegular,
  'mobile-payment--filled': IconMobilePaymentFilled,
  'mobile-payment--light': IconMobilePaymentLight,
  'mobile-payment--regular': IconMobilePaymentRegular,
  'money--filled': IconMoneyFilled,
  'money--light': IconMoneyLight,
  'money--regular': IconMoneyRegular,
  'more--filled': IconMoreFilled,
  'more--light': IconMoreLight,
  'more--regular': IconMoreRegular,
  'more-vertical--filled': IconMoreVerticalFilled,
  'more-vertical--light': IconMoreVerticalLight,
  'more-vertical--regular': IconMoreVerticalRegular,
  'network--filled': IconNetworkFilled,
  'network--light': IconNetworkLight,
  'network--regular': IconNetworkRegular,
  'new-message--filled': IconNewMessageFilled,
  'new-message--light': IconNewMessageLight,
  'new-message--regular': IconNewMessageRegular,
  'non-watchable--filled': IconNonWatchableFilled,
  'non-watchable--light': IconNonWatchableLight,
  'non-watchable--regular': IconNonWatchableRegular,
  'notification--filled': IconNotificationFilled,
  'notification--light': IconNotificationLight,
  'notification--regular': IconNotificationRegular,
  'office--filled': IconOfficeFilled,
  'office--light': IconOfficeLight,
  'office--regular': IconOfficeRegular,
  'omaguru--filled': IconOmaguruFilled,
  'omaguru--light': IconOmaguruLight,
  'omaguru--regular': IconOmaguruRegular,
  'on-device--filled': IconOnDeviceFilled,
  'on-device--light': IconOnDeviceLight,
  'on-device--regular': IconOnDeviceRegular,
  'phone--filled': IconPhoneFilled,
  'phone--light': IconPhoneLight,
  'phone--regular': IconPhoneRegular,
  'picture--filled': IconPictureFilled,
  'picture--light': IconPictureLight,
  'picture--regular': IconPictureRegular,
  'picture-add--filled': IconPictureAddFilled,
  'picture-add--light': IconPictureAddLight,
  'picture-add--regular': IconPictureAddRegular,
  'pilvilinna--filled': IconPilvilinnaFilled,
  'pilvilinna--light': IconPilvilinnaLight,
  'pilvilinna--regular': IconPilvilinnaRegular,
  'pin--filled': IconPinFilled,
  'pin--light': IconPinLight,
  'pin--regular': IconPinRegular,
  'pinterest--filled': IconPinterestFilled,
  'pinterest--light': IconPinterestLight,
  'pinterest--regular': IconPinterestRegular,
  'play--filled': IconPlayFilled,
  'play--light': IconPlayLight,
  'play--regular': IconPlayRegular,
  'power--filled': IconPowerFilled,
  'power--light': IconPowerLight,
  'power--regular': IconPowerRegular,
  'print--filled': IconPrintFilled,
  'print--light': IconPrintLight,
  'print--regular': IconPrintRegular,
  'puk--filled': IconPukFilled,
  'puk--light': IconPukLight,
  'puk--regular': IconPukRegular,
  'record--filled': IconRecordFilled,
  'record--light': IconRecordLight,
  'record--regular': IconRecordRegular,
  'recruitment--filled': IconRecruitmentFilled,
  'recruitment--light': IconRecruitmentLight,
  'recruitment--regular': IconRecruitmentRegular,
  'remove--filled': IconRemoveFilled,
  'remove--light': IconRemoveLight,
  'remove--regular': IconRemoveRegular,
  'reply--filled': IconReplyFilled,
  'reply--light': IconReplyLight,
  'reply--regular': IconReplyRegular,
  'reply-all--filled': IconReplyAllFilled,
  'reply-all--light': IconReplyAllLight,
  'reply-all--regular': IconReplyAllRegular,
  'router--filled': IconRouterFilled,
  'router--light': IconRouterLight,
  'router--regular': IconRouterRegular,
  'salasanapankki--filled': IconSalasanapankkiFilled,
  'salasanapankki--light': IconSalasanapankkiLight,
  'salasanapankki--regular': IconSalasanapankkiRegular,
  'search--filled': IconSearchFilled,
  'search--light': IconSearchLight,
  'search--regular': IconSearchRegular,
  'send--filled': IconSendFilled,
  'send--light': IconSendLight,
  'send--regular': IconSendRegular,
  'services--filled': IconServicesFilled,
  'services--light': IconServicesLight,
  'services--regular': IconServicesRegular,
  'services-grid--filled': IconServicesGridFilled,
  'services-grid--light': IconServicesGridLight,
  'services-grid--regular': IconServicesGridRegular,
  'settings--filled': IconSettingsFilled,
  'settings--light': IconSettingsLight,
  'settings--regular': IconSettingsRegular,
  'share--filled': IconShareFilled,
  'share--light': IconShareLight,
  'share--regular': IconShareRegular,
  'shop--filled': IconShopFilled,
  'shop--light': IconShopLight,
  'shop--regular': IconShopRegular,
  'sim--filled': IconSimFilled,
  'sim--light': IconSimLight,
  'sim--regular': IconSimRegular,
  'sim-add--filled': IconSimAddFilled,
  'sim-add--light': IconSimAddLight,
  'sim-add--regular': IconSimAddRegular,
  'smart-watch--filled': IconSmartWatchFilled,
  'smart-watch--light': IconSmartWatchLight,
  'smart-watch--regular': IconSmartWatchRegular,
  'smarthome--filled': IconSmarthomeFilled,
  'smarthome--light': IconSmarthomeLight,
  'smarthome--regular': IconSmarthomeRegular,
  'smartphone--filled': IconSmartphoneFilled,
  'smartphone--light': IconSmartphoneLight,
  'smartphone--regular': IconSmartphoneRegular,
  'star--filled': IconStarFilled,
  'star--light': IconStarLight,
  'star--regular': IconStarRegular,
  'sustainability--filled': IconSustainabilityFilled,
  'sustainability--light': IconSustainabilityLight,
  'sustainability--regular': IconSustainabilityRegular,
  'switch--filled': IconSwitchFilled,
  'switch--light': IconSwitchLight,
  'switch--regular': IconSwitchRegular,
  'tablet--filled': IconTabletFilled,
  'tablet--light': IconTabletLight,
  'tablet--regular': IconTabletRegular,
  'thumb-down--filled': IconThumbDownFilled,
  'thumb-down--light': IconThumbDownLight,
  'thumb-down--regular': IconThumbDownRegular,
  'thumb-up--filled': IconThumbUpFilled,
  'thumb-up--light': IconThumbUpLight,
  'thumb-up--regular': IconThumbUpRegular,
  'tick--filled': IconTickFilled,
  'tick--light': IconTickLight,
  'tick--regular': IconTickRegular,
  'tiktok--filled': IconTiktokFilled,
  'tiktok--light': IconTiktokLight,
  'tiktok--regular': IconTiktokRegular,
  'travel--filled': IconTravelFilled,
  'travel--light': IconTravelLight,
  'travel--regular': IconTravelRegular,
  'turvapaketti--filled': IconTurvapakettiFilled,
  'turvapaketti--light': IconTurvapakettiLight,
  'turvapaketti--regular': IconTurvapakettiRegular,
  'tv--filled': IconTvFilled,
  'tv--light': IconTvLight,
  'tv--regular': IconTvRegular,
  'twitter--filled': IconTwitterFilled,
  'twitter--light': IconTwitterLight,
  'twitter--regular': IconTwitterRegular,
  'update--filled': IconUpdateFilled,
  'update--light': IconUpdateLight,
  'update--regular': IconUpdateRegular,
  'upload--filled': IconUploadFilled,
  'upload--light': IconUploadLight,
  'upload--regular': IconUploadRegular,
  'upward--filled': IconUpwardFilled,
  'upward--light': IconUpwardLight,
  'upward--regular': IconUpwardRegular,
  'user--filled': IconUserFilled,
  'user--light': IconUserLight,
  'user--regular': IconUserRegular,
  'view--filled': IconViewFilled,
  'view--light': IconViewLight,
  'view--regular': IconViewRegular,
  'view-off--filled': IconViewOffFilled,
  'view-off--light': IconViewOffLight,
  'view-off--regular': IconViewOffRegular,
  'warning--filled': IconWarningFilled,
  'warning--light': IconWarningLight,
  'warning--regular': IconWarningRegular,
  'webcam--filled': IconWebcamFilled,
  'webcam--light': IconWebcamLight,
  'webcam--regular': IconWebcamRegular,
  'website--filled': IconWebsiteFilled,
  'website--light': IconWebsiteLight,
  'website--regular': IconWebsiteRegular,
  'whatsapp--filled': IconWhatsappFilled,
  'whatsapp--light': IconWhatsappLight,
  'whatsapp--regular': IconWhatsappRegular,
  'wifi--filled': IconWifiFilled,
  'wifi--light': IconWifiLight,
  'wifi--regular': IconWifiRegular,
  'wifi-off--filled': IconWifiOffFilled,
  'wifi-off--light': IconWifiOffLight,
  'wifi-off--regular': IconWifiOffRegular,
  'youtube--filled': IconYoutubeFilled,
  'youtube--light': IconYoutubeLight,
  'youtube--regular': IconYoutubeRegular,
}