import React from 'react';
import IconContainer from '../IconContainer';

export default function IconDownloadingLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12m0-23C5.935 1 1 5.935 1 12s4.935 11 11 11 11-4.935 11-11S18.065 1 12 1m2.75 15.25h-5.5c-.827 0-1.5-.673-1.5-1.5v-5.5c0-.827.673-1.5 1.5-1.5h5.5c.827 0 1.5.673 1.5 1.5v5.5c0 .827-.673 1.5-1.5 1.5m-5.5-7.5a.5.5 0 0 0-.5.5v5.5a.5.5 0 0 0 .5.5h5.5c.275 0 .5-.225.5-.5v-5.5a.5.5 0 0 0-.5-.5z"/></svg>)}
    />
  );
}
  