import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEditLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m22.771 1.231-.002-.001A4.2 4.2 0 0 0 19.762 0a4.25 4.25 0 0 0-2.984 1.266L1.956 16.088a.5.5 0 0 0-.131.229L.016 23.376a.5.5 0 0 0 .608.608l7.058-1.811a.5.5 0 0 0 .229-.131L22.729 7.226A4.25 4.25 0 0 0 24 4.237a4.24 4.24 0 0 0-1.229-3.006M3.019 16.438 16.708 2.75l4.542 4.542L7.565 20.975zm-.442.971 4.019 4.011-5.402 1.386zM22.026 6.514l-.07.07-4.542-4.542.074-.074A3.25 3.25 0 0 1 19.77 1h.021c.846 0 1.672.341 2.271.938.602.604.944 1.439.938 2.292a3.24 3.24 0 0 1-.974 2.284"/></svg>)}
    />
  );
}
  