// @flow strict-local

import axios, { CancelToken, type Canceler, CancelTokenSource } from 'axios';
import React, { type Element, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { type GroupMemberT } from '../../../../../../ducks/entities/callFlow/commonCallFlowTypes';
import {
  operations as userOps,
  selectors as userSelect
} from '../../../../../../ducks/entities/user';
import Members from './Members/Members';
import { type InternalUserStateEntityT } from '../../../../../../ducks/entities/user/userTypes';
import { update as acdUpdate } from '../../../../../../ducks/entities/acd/acdOperations';
import { updateExtensionGroup } from '../../../../../../ducks/entities/extensionGroup/extensionGroupOperations';
import { selectors as callFlowSelect } from '../../../../../../ducks/entities/callFlow';
import { createCsrfHeader } from '../../../../../../utils/accessRightUtils';
import type { CurrentUserT } from '../../../../../../ducks/currentUser/currentUserTypes';

import styles from './MemberList.module.scss';

export type PropsT = {|
  id: string,
  title: string,
  members: GroupMemberT[],
  enterpriseId: string,
  key?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  active?: boolean,
  serviceType: 'group' | 'ACDGroup',
  callFlowId: string,
  acdType?: 'ACD_SWITCHBOARD' | 'ACD_CUSTOMER_SERVICE',
  mockCanceler?: * // flowlint-line deprecated-type:off
|};
let patchCancelTokenSource: CancelTokenSource;
export const MemberList = (props: PropsT): Element<'div'> => {
  const {
    members,
    enterpriseId,
    serviceType,
    mockCanceler,
    id,
    key,
    title,
    callFlowId,
    acdType
  } = props;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const isUpdating: boolean = useSelector(state => callFlowSelect.isUpdating(state));
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const { t } = useTranslation();
  // Redux
  const dispatch = useDispatch();
  const users: InternalUserStateEntityT[] = useSelector(state =>
    userSelect.internalByIdsAndEnterpriseId(
      state,
      (members || []).map(member => member.id),
      enterpriseId
    )
  );

  const cancelRequest = React.useRef<?Canceler>();

  const cleanMemberList = async usersFetched => {
    if (members.length > 0 && usersFetched.results.length > 0) {
      const memberUsers = members.map(member =>
        usersFetched.results.find(user => user.id === member.id)
      );

      if (memberUsers.includes(undefined) || memberUsers.find(user => user && !user.personId)) {
        const patchPayload = {
          memberIds: memberUsers
            .filter(member => member && member.personId)
            .map(member => member && member.id)
        };
        if (acdType) {
          dispatch(
            // $FlowFixMe: undefined are filtered out
            acdUpdate(
              enterpriseId,
              acdType,
              callFlowId,
              patchPayload,
              patchCancelTokenSource.token,
              createCsrfHeader(currentUser)
            )
          );
        } else {
          dispatch(
            updateExtensionGroup(
              enterpriseId,
              callFlowId,
              // $FlowFixMe
              patchPayload,
              patchCancelTokenSource.token,
              createCsrfHeader(currentUser)
            )
          );
        }
      }
    }
  };

  const handleRetrieveUsers = async () => {
    patchCancelTokenSource = CancelToken.source();
    setHasError(false);
    setIsLoading(true);
    try {
      const usersFetched = await dispatch(
        userOps.retrieveCollectionByIds(
          enterpriseId,
          serviceType,
          new CancelToken(canceler => {
            cancelRequest.current = mockCanceler || canceler;
          }),
          members.map(member => member.id)
        )
      );

      if (!isUpdating) {
        await cleanMemberList(usersFetched);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return; // skip state updates if request is cancelled when component unmounts
      }
      setHasError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (members.length > 0) {
      handleRetrieveUsers();
    }

    return () => {
      if (cancelRequest.current) {
        cancelRequest.current();
      }
      if (patchCancelTokenSource) {
        patchCancelTokenSource.cancel();
      }
    };
  }, []); // eslint-disable-line

  return (
    <div className={styles.detail} key={key} id={id} data-cy={id}>
      <div className={styles.header}>{title}</div>
      <div className={styles.value}>
        <Members
          members={members}
          users={users}
          noUsersText={t('callflows.memberList.noUsers')}
          loadingText={t('callflows.memberList.loading')}
          errorText={t('callflows.memberList.error')}
          errorButtonText={t('callflows.memberList.errorButton')}
          isLoading={isLoading}
          showLoadingIndicator={isLoading}
          hasError={hasError}
          onRetry={handleRetrieveUsers}
        />
      </div>
    </div>
  );
};

export default MemberList;
