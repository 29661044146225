import React from 'react';
import IconContainer from '../IconContainer';

export default function IconMessageRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.604 23.251c-.448 0-.891-.18-1.217-.52a1.71 1.71 0 0 1-.297-1.94l1.613-3.389A9.33 9.33 0 0 1 0 10.944C0 5.319 5.383.75 12 .75s12 4.569 12 10.186-5.383 10.186-12 10.186h-.023c-1.162 0-2.319-.145-3.443-.431l-5.221 2.402a1.7 1.7 0 0 1-.709.158M12 2.25c-5.79 0-10.5 3.896-10.5 8.686a7.82 7.82 0 0 0 2.613 5.736.75.75 0 0 1 .179.883l-1.854 3.893a.21.21 0 0 0 .029.245.185.185 0 0 0 .212.04l5.472-2.518a.74.74 0 0 1 .513-.041c1.078.297 2.193.447 3.314.447h.02c5.792 0 10.501-3.896 10.501-8.686S17.79 2.25 12 2.25m6.25 10.25a.75.75 0 0 0-.75-.75h-11a.75.75 0 0 0 0 1.5h11a.75.75 0 0 0 .75-.75m-3-4a.75.75 0 0 0-.75-.75h-8a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 .75-.75"/></svg>)}
    />
  );
}
  