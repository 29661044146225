// @flow

import { CancelToken } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { operations as userOps } from '../../../ducks/entities/user';
import type { KeyValuePairT } from '../../../commonTypes';
import type { InternalUserUpdateEntityT } from '../../../ducks/entities/user/userTypes';
import type { ActiveTabT } from '../../../ducks/ui/department/departmentUiTypes';
import { selectors as departmentUiSelect } from '../../../ducks/ui/department/index';
import MoveUserIndicator from './MoveUserIndicator';
import { fetchExtensionIdsWithAddressNumbers } from '../../../helpers';
import type { DirectoryStateEntityT } from '../../../ducks/entities/directory';
import { createCsrfHeader } from '../../../utils/accessRightUtils';

import styles from './DepartmentMovePeople.module.scss';

type PropsT = {|
  enterpriseId: string,
  departmentId: string,
  setActiveTab: ActiveTabT => void,
  destinationDepartment: KeyValuePairT<>,
  onDoneMovingUsers: () => void,
  onRetrieveUsers: () => void
|};

export const DepartmentAddPeople = (props: PropsT) => {
  const {
    enterpriseId,
    departmentId,
    setActiveTab,
    destinationDepartment,
    onDoneMovingUsers,
    onRetrieveUsers
  } = props;

  // redux
  const dispatch = useDispatch();
  // $FlowFixMe: Only Directories here
  const selectedUsers: DirectoryStateEntityT[] = useSelector(state =>
    departmentUiSelect.selectedDirectoriesByEnterpriseAndDepartment(
      state,
      enterpriseId,
      departmentId
    )
  );
  const currentUser = useSelector(state => state.currentUser);
  const cancelAddressNumberRequest = CancelToken.source();
  const cancelUpdateUserRequest = CancelToken.source();
  const [isAddingUsersToDepartment, setIsAddingUsersToDepartment] = useState(false);
  const transferFailedUsers = useRef([]);
  const successfullyAddedPeople = useRef(0);
  const totalNumberOfPeopleToBeAdded = selectedUsers.length;
  const [addStatus, setAddStatus] = useState();

  const setAddedUserStatus = () => {
    if (transferFailedUsers.current.length > 0) {
      setAddStatus('failed');
    } else {
      setAddStatus('success');
    }
  };

  const fetchExtensionIds = async (selectedDirectories: DirectoryStateEntityT[]) => {
    let extensionIds = [];
    if (selectedDirectories.length > 0) {
      extensionIds = await fetchExtensionIdsWithAddressNumbers(
        enterpriseId,
        selectedDirectories.map(user => user.publicInfo.addressNumber),
        cancelAddressNumberRequest.token,
        createCsrfHeader(currentUser)
      );
      if (extensionIds.length === 0 || extensionIds.includes('')) {
        transferFailedUsers.current = selectedDirectories;
        setAddStatus('failed');
      }
    }
    return extensionIds;
  };

  const addUserWithExtensionId = async (
    extensionId: string,
    directory: DirectoryStateEntityT
  ): Promise<void> => {
    const payload: InternalUserUpdateEntityT = {
      id: extensionId,
      personId: directory ? directory.internalAddressId : '',
      enterpriseId,
      userType: 'internalUser',
      departmentId: destinationDepartment.value
    };

    const result = await dispatch(
      userOps.update(payload, cancelUpdateUserRequest.token, createCsrfHeader(currentUser))
    );

    if (result) {
      successfullyAddedPeople.current += 1;
    } else {
      transferFailedUsers.current = [...transferFailedUsers.current, directory];
    }
  };

  const addUsersToDepartment = async (usersToBeAdded: DirectoryStateEntityT[]): Promise<void> => {
    transferFailedUsers.current = [];
    setIsAddingUsersToDepartment(true);
    const extensionIds = await fetchExtensionIds(usersToBeAdded);
    if (extensionIds.length > 0 && !extensionIds.includes('')) {
      await Promise.all(
        extensionIds.map((extensionId, index) =>
          addUserWithExtensionId(extensionId, selectedUsers[index])
        )
      );
    }
    onRetrieveUsers();
    setIsAddingUsersToDepartment(false);
    setAddedUserStatus();
  };

  useEffect(() => {
    addUsersToDepartment(selectedUsers);
    return () => {
      cancelAddressNumberRequest.cancel();
      cancelUpdateUserRequest.cancel();
      onRetrieveUsers();
      onDoneMovingUsers();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tryAgainAddingPeople = () => {
    addUsersToDepartment(transferFailedUsers.current);
  };

  const closeAddingPeople = () => {
    setAddStatus(undefined);
    setActiveTab('people');
  };

  const cancelAddingUsers = () => {
    cancelAddressNumberRequest.cancel();
    cancelUpdateUserRequest.cancel();
    closeAddingPeople();
  };

  return (
    <div className={styles['move-people-container']} id="department-move-section">
      <MoveUserIndicator
        isMovingUsers={isAddingUsersToDepartment}
        moveStatus={addStatus}
        successfullyMovedPeople={successfullyAddedPeople.current}
        totalNumberOfPeopleToBeMoved={totalNumberOfPeopleToBeAdded}
        movePeopleSuccessfulAction={closeAddingPeople}
        movePeopleTryAgainAction={tryAgainAddingPeople}
        cancelMovingUsers={cancelAddingUsers}
      />
    </div>
  );
};

export default DepartmentAddPeople;
