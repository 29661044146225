import React from 'react';
import IconContainer from '../IconContainer';

export default function IconViewLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.828 19.498c-4.325 0-8.639-3.089-11.256-5.999a2.25 2.25 0 0 1 0-2.999c2.647-2.939 7.051-6.079 11.426-5.999h.15c4.335 0 8.659 3.089 11.276 5.999a2.24 2.24 0 0 1 0 2.999c-2.647 2.999-6.991 6.089-11.436 5.999zm0-13.997c-3.995 0-8.06 2.919-10.527 5.679a1.22 1.22 0 0 0 0 1.64c2.517 2.789 6.632 5.779 10.697 5.679 4.095.08 8.19-2.889 10.687-5.679a1.22 1.22 0 0 0 0-1.64c-2.477-2.759-6.522-5.679-10.537-5.679zm.17 10.748A4.247 4.247 0 0 1 7.753 12a4.257 4.257 0 0 1 4.245-4.249A4.247 4.247 0 0 1 16.243 12a4.257 4.257 0 0 1-4.245 4.249m0-7.498c-1.793 0-3.246 1.455-3.246 3.249s1.453 3.249 3.246 3.249A3.257 3.257 0 0 0 15.244 12a3.237 3.237 0 0 0-3.226-3.249z"/></svg>)}
    />
  );
}
  