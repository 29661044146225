import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBlockFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0m0 2.5c2.2 0 4.2.7 5.8 2L4.5 17.8c-1.2-1.6-2-3.6-2-5.8 0-5.2 4.3-9.5 9.5-9.5m0 19c-2.2 0-4.2-.7-5.8-2L19.5 6.2c1.2 1.6 2 3.6 2 5.8 0 5.2-4.3 9.5-9.5 9.5"/></svg>)}
    />
  );
}
  