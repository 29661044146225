import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailReadLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.356 8.344-9.803-7.293a2.435 2.435 0 0 0-3.088 0L.662 8.334a1.9 1.9 0 0 0-.649 1.661v11.504A2 2 0 0 0 2.012 23.5h19.986a2 2 0 0 0 1.999-2.001V9.995a2.1 2.1 0 0 0-.641-1.651M11.075 1.832a1.44 1.44 0 0 1 1.859 0l9.593 7.163-9.593 7.183a1.45 1.45 0 0 1-1.869 0L1.641 8.855zm11.921 19.667a1 1 0 0 1-.999 1H2.011a1 1 0 0 1-.999-1V9.645l9.413 7.293a2.456 2.456 0 0 0 3.108 0l9.463-7.063z"/></svg>)}
    />
  );
}
  