import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWarningRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22.179 23.25H1.82a1.8 1.8 0 0 1-1.557-.9 1.87 1.87 0 0 1-.022-1.874l10.181-18.78A1.79 1.79 0 0 1 12.005.75h.004a1.79 1.79 0 0 1 1.579.943l.003.006 10.174 18.788a1.87 1.87 0 0 1-.029 1.864 1.8 1.8 0 0 1-1.557.899m-10.173-21a.3.3 0 0 0-.269.168L1.555 21.198a.39.39 0 0 0 0 .392c.035.06.117.16.265.16h20.359a.31.31 0 0 0 .265-.16.39.39 0 0 0 .006-.382L12.274 2.416a.3.3 0 0 0-.268-.166m.744 13v-6.5a.75.75 0 1 0-1.5 0v6.5a.75.75 0 0 0 1.5 0M12 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2"/></svg>)}
    />
  );
}
  