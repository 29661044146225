import React from 'react';
import IconContainer from '../IconContainer';

export default function IconRecruitmentFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m23.634 21.866-5.489-5.487a10 10 0 0 0 1.373-2.384 9.93 9.93 0 0 0-.07-7.651A9.93 9.93 0 0 0 13.99.983a9.92 9.92 0 0 0-7.649.07 9.93 9.93 0 0 0-5.36 5.459 9.93 9.93 0 0 0 .07 7.652c1.616 3.801 5.322 6.087 9.213 6.086a9.9 9.9 0 0 0 3.894-.796 10 10 0 0 0 2.216-1.31l5.491 5.49a1.25 1.25 0 0 0 1.769-1.768M10.251 3.25c1.94 0 3.519 1.57 3.519 3.5s-1.579 3.5-3.519 3.5-3.519-1.57-3.519-3.5 1.579-3.5 3.519-3.5m4.656 12.29a.5.5 0 0 1-.409.21H6.003a.503.503 0 0 1-.475-.664 4.96 4.96 0 0 1 3.075-3.059 5 5 0 0 1 3.821.217 4.95 4.95 0 0 1 2.547 2.841.5.5 0 0 1-.064.455"/></svg>)}
    />
  );
}
  