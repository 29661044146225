import React from 'react';
import IconContainer from '../IconContainer';

export default function IconHomeRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.25 13a.74.74 0 0 1-.533-.223L12.504 2.444a.74.74 0 0 0-1.021.014l-10.2 10.319a.749.749 0 1 1-1.066-1.055L10.431 1.389a2.25 2.25 0 0 1 3.125-.015l10.228 10.348A.75.75 0 0 1 23.25 13m-2 8v-7.477a.75.75 0 0 0-1.5 0V21c0 .413-.337.75-.75.75h-3.75V18c0-.916-.39-1.795-1.069-2.41a3.27 3.27 0 0 0-2.514-.823c-1.636.164-2.917 1.647-2.917 3.376v3.607H5a.75.75 0 0 1-.75-.75v-7.5a.75.75 0 0 0-1.5 0V21c0 1.24 1.009 2.25 2.25 2.25h4c.689 0 1.25-.561 1.25-1.25v-3.857c0-.968.688-1.795 1.567-1.884a1.74 1.74 0 0 1 1.357.443c.366.331.576.805.576 1.298v4c0 .689.561 1.25 1.25 1.25h4c1.24 0 2.25-1.01 2.25-2.25"/></svg>)}
    />
  );
}
  