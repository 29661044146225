// @flow strict-local

import React, { type Element } from 'react';
import classNames from 'classnames';
import ActionButton from '../Button/ActionButton';
import styles from './ErrorWithRetry.module.scss';

export type PropsT = {|
  text: string,
  buttonText: string,
  onRetry: () => *, // flowlint-line deprecated-type:off
  className?: string
|};

export const ErrorWithRetry = (props: PropsT): Element<'div'> => (
  <div className={classNames(styles.container, props.className)}>
    <div className={styles.text}>{props.text}</div>
    <ActionButton
      id="retry-button"
      onClickAction={props.onRetry}
      label={props.buttonText}
      type="button"
    />
  </div>
);

export default ErrorWithRetry;
