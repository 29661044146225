// @flow

import React, { useEffect, useState } from 'react';
import Table from '@design-system/component-library/src/components/Table';
import { useTranslation } from 'react-i18next';
import Button from '@design-system/component-library/src/components/Button';
import axios, { CancelToken } from 'axios';
import parse from 'html-react-parser';
// $FlowFixMe
import writeXlsxFile from 'write-excel-file';
import moment from 'moment';
import styles from './AcdSupervisors.module.scss';

export type PropsT = {|
  enterpriseId: string
|};

const AcdSupervisors = ({ enterpriseId }: PropsT) => {
  const { t } = useTranslation();
  const [acds, setAcds] = useState([]);
  const [acdSupervisors, setAcdSupervisors] = useState([]);
  const [exporting, setExporting] = useState(false);
  const cancelGetAcdsRequest = CancelToken.source();

  const fetchData = async (url, setData) => {
    try {
      const { data } = await axios.get(url, {
        cancelToken: cancelGetAcdsRequest.token,
        params: { size: 99999 }
      });
      setData(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(`/api/v1/enterprises/${enterpriseId}/services/acds`, setAcds);
    fetchData(`/api/v1/enterprises/${enterpriseId}/acdsupervisors`, setAcdSupervisors);
    return () => cancelGetAcdsRequest.cancel();
  }, [enterpriseId]); // eslint-disable-line react-hooks/exhaustive-deps

  const findACDSupervisors = addressNumber => {
    return acdSupervisors.filter(
      acdSupervisor =>
        !acdSupervisor.iUser.isAcdSupervisorAccessRestricted ||
        acdSupervisor.restrictedACDGroups.some(acdGroup => acdGroup.addressNumber === addressNumber)
    );
  };

  const getACDSupervisorsForXLSX = addressNumber => {
    const foundAcdSupervisors = findACDSupervisors(addressNumber);
    return acdSupervisors.length > 0 && acdSupervisors.length === foundAcdSupervisors.length
      ? t('callflows.acdSupervisors.allAcdSupervisors')
      : foundAcdSupervisors
          .map(acdSupervisor => `${acdSupervisor.iUserName} | ${acdSupervisor.addressNumber}`)
          .join('\n');
  };

  const getACDSupervisors = addressNumber => {
    const foundAcdSupervisors = findACDSupervisors(addressNumber);
    return acdSupervisors.length > 0 && acdSupervisors.length === foundAcdSupervisors.length
      ? t('callflows.acdSupervisors.allAcdSupervisors')
      : parse(
          foundAcdSupervisors
            .map(acdSupervisor => `${acdSupervisor.iUserName} | ${acdSupervisor.addressNumber}`)
            .join('<br />')
        );
  };

  const findACDs = acdSupervisor => {
    return acds.filter(
      acd =>
        !acdSupervisor.iUser.isAcdSupervisorAccessRestricted ||
        acdSupervisor.restrictedACDGroups.some(
          acdGroup => acdGroup.addressNumber === acd.addressNumber
        )
    );
  };

  const getACDs = acdSupervisor => {
    const foundAcds = findACDs(acdSupervisor);
    return acds.length > 0 && acds.length === foundAcds.length
      ? t('callflows.acdSupervisors.allAcds')
      : parse(foundAcds.map(acd => `${acd.label} | ${acd.addressNumber}`).join('<br />'));
  };

  const getACDsForXLSX = acdSupervisor => {
    const foundAcds = findACDs(acdSupervisor);
    return acds.length > 0 && acds.length === foundAcds.length
      ? t('callflows.acdSupervisors.allAcds')
      : foundAcds.map(acd => `${acd.label} | ${acd.addressNumber}`).join('\n');
  };

  const getAcdsRows = () =>
    acds.map(acd => ({
      acdname: acd.label,
      phonenumber: acd.pstnNumbers.map(number =>
        parse(`<a href="tel:${number}">${number}</a>&nbsp;`)
      ),
      extension: acd.addressNumber,
      supervisors: getACDSupervisors(acd.addressNumber)
    }));

  const getAcdSupervisorsRows = () =>
    acdSupervisors.map(acdSupervisor => ({
      acdname: getACDs(acdSupervisor),
      email: acdSupervisor.iUser.emails
        ? acdSupervisor.iUser.emails
            .split(';')
            .map(email => parse(`<a href="mailto:${email}">${email}</a>&nbsp;`))
        : '',
      extension: acdSupervisor.addressNumber,
      supervisor: acdSupervisor.iUserName
    }));

  const exportAcdSupervisors = async () => {
    setExporting(true);
    const HEADER_ROWS_ACD = [
      {
        value: t('callflows.acdSupervisors.acdname'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.phonenumber'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.extension'),
        fontWeight: 'bold'
      },
      {
        value: t('callflows.acdSupervisors.supervisors'),
        fontWeight: 'bold',
        width: 20
      }
    ];
    const rowsAcd = acds.map(acd => [
      {
        type: String,
        value: acd.label
      },
      {
        type: String,
        value: acd.pstnNumbers.map(number => `${number}`).join(', ')
      },
      {
        type: String,
        value: acd.addressNumber
      },
      {
        type: String,
        value: getACDSupervisorsForXLSX(acd.addressNumber)
      }
    ]);
    const HEADER_ROWS_USER = [
      {
        value: t('callflows.acdSupervisors.supervisors'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.email'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.extension'),
        fontWeight: 'bold'
      },
      {
        value: t('callflows.acdSupervisors.acdname'),
        fontWeight: 'bold',
        width: 20
      }
    ];
    const rowsUser = acdSupervisors.map(acdSupervisor => [
      {
        type: String,
        value: acdSupervisor.iUserName
      },
      {
        type: String,
        value: acdSupervisor.iUser.emails
      },
      {
        type: String,
        value: acdSupervisor.addressNumber
      },
      {
        type: String,
        value: getACDsForXLSX(acdSupervisor)
      }
    ]);
    const HEADER_ROWS_SINGLE = [
      {
        value: t('callflows.acdSupervisors.supervisors'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.email'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.personExtension'),
        fontWeight: 'bold'
      },
      {
        value: t('callflows.acdSupervisors.acdname'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.acdPhonenumber'),
        fontWeight: 'bold',
        width: 20
      },
      {
        value: t('callflows.acdSupervisors.acdExtension'),
        fontWeight: 'bold',
        width: 20
      }
    ];
    const rowsSingle = acdSupervisors.flatMap(acdSupervisor => {
      const foundAcds = findACDs(acdSupervisor);
      return foundAcds.map(acd => [
        {
          type: String,
          value: acdSupervisor.iUserName
        },
        {
          type: String,
          value: acdSupervisor.iUser.emails
        },
        {
          type: String,
          value: acdSupervisor.addressNumber
        },
        {
          type: String,
          value: acd.label
        },
        {
          type: String,
          value: acd.pstnNumbers.map(number => `${number}`).join(', ')
        },
        {
          type: String,
          value: acd.addressNumber
        }
      ]);
    });
    const data = [
      [HEADER_ROWS_ACD, ...rowsAcd],
      [HEADER_ROWS_USER, ...rowsUser],
      [HEADER_ROWS_SINGLE, ...rowsSingle]
    ];
    await writeXlsxFile(data, {
      columns: [HEADER_ROWS_ACD, HEADER_ROWS_USER, HEADER_ROWS_SINGLE],
      sheets: [
        t('callflows.acdSupervisors.acdTitle'),
        t('callflows.acdSupervisors.userTitle'),
        t('callflows.acdSupervisors.singleTitle')
      ],
      fileName: `acdsupervisors_export_${moment().format('YYYYMMDD')}.xlsx`
    });

    setExporting(false);
  };

  return (
    <div className={styles.container}>
      <Button loading={exporting} onClick={() => exportAcdSupervisors()}>
        {t('callflows.acdSupervisors.exportACDSupervisorsButton')}
      </Button>
      <h4 className={styles.title} data-cy="calendars-title">
        {t('callflows.acdSupervisors.acdTitle')}
      </h4>
      <Table
        id="acdTable"
        useRowHeader
        hover
        columns={[
          { key: 'acdname', label: t('callflows.acdSupervisors.acdname') },
          { key: 'phonenumber', label: t('callflows.acdSupervisors.phonenumber') },
          { key: 'extension', label: t('callflows.acdSupervisors.extension') },
          { key: 'supervisors', label: t('callflows.acdSupervisors.supervisors') }
        ]}
        rows={getAcdsRows()}
      />
      <h4 className={styles.title} data-cy="calendars-title">
        {t('callflows.acdSupervisors.userTitle')}
      </h4>
      <Table
        id="acdSupervisorsTable"
        useRowHeader
        hover
        columns={[
          { key: 'supervisor', label: t('callflows.acdSupervisors.supervisors') },
          { key: 'email', label: t('callflows.acdSupervisors.email') },
          { key: 'extension', label: t('callflows.acdSupervisors.extension') },
          { key: 'acdname', label: t('callflows.acdSupervisors.acdname') }
        ]}
        rows={getAcdSupervisorsRows()}
      />
    </div>
  );
};

export default AcdSupervisors;
