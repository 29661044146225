import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFlagFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.25.75v22.5a.75.75 0 0 1-1.5 0V.75a.75.75 0 0 1 1.5 0M20.5 4H13V2.5c0-.827-.673-1.5-1.5-1.5H5a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h4v1.5c0 .827.673 1.5 1.5 1.5h10c.827 0 1.5-.673 1.5-1.5v-10c0-.827-.673-1.5-1.5-1.5"/></svg>)}
    />
  );
}
  