import React from 'react';
import IconContainer from '../IconContainer';

export default function IconArrowLeftFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.504 20.25c-.311 0-.624-.116-.865-.351l-6.856-6.643a1.76 1.76 0 0 1-.533-1.257c0-.472.194-.931.532-1.259l6.853-6.639a1.244 1.244 0 0 1 1.762.031 1.25 1.25 0 0 1-.031 1.767l-6.295 6.1 6.299 6.103c.495.479.509 1.271.031 1.767a1.25 1.25 0 0 1-.897.381"/></svg>)}
    />
  );
}
  