// @flow
import * as R from 'ramda';
import type {
  AudioEntityT,
  CalendarEntityT,
  ChoseTypeT,
  CommandEntityT,
  InteractionEntityT,
  WelcomeAttendantEntityT
} from '../../../../ducks/entities/welcomeAttendant/welcomeAttendantTypes';
import type { CallFlowTreeNodeT } from '../../../../ducks/entities/callFlow';

export class WelcomeAttendantUtils {
  static hasCyclicReference(currentNode: ?CommandEntityT, currentNodeParents: string[]) {
    return (
      currentNode &&
      currentNode.name &&
      currentNodeParents &&
      currentNodeParents.indexOf(currentNode.name) > -1
    );
  }

  static convertInteraction(
    data: ?WelcomeAttendantEntityT,
    currentNode: InteractionEntityT,
    currentNodeParents: string[],
    serviceId: string
  ): CallFlowTreeNodeT {
    // eslint-disable-next-line prefer-destructuring
    const choices: ChoseTypeT[] = currentNode.choices;

    const options: CallFlowTreeNodeT[] = choices.map(choice => {
      const stepAction = data
        ? WelcomeAttendantUtils.buildStepsPattern(data, choice.nextState.state, [
            ...currentNodeParents,
            currentNode.name
          ])
        : null;

      return {
        id: `${serviceId}_${currentNode.name}-STEP-${choice.choice}`,
        name: currentNode.name,
        // $FlowFixMe type: 'STEP_${choice.choice}' should be within type definitions
        type: `WELCOME_ATTENDANT_STEP_${choice.choice}`,
        description: `${currentNode.name}-STEP-${choice.choice}` || '---',
        horizontalNode: stepAction,
        verticalNode: null
      };
    });

    for (let i = 0; i < options.length - 1; i++) {
      options[i].verticalNode = options[i + 1];
    }

    const choiceRepetition = currentNode.retryState
      ? WelcomeAttendantUtils.buildStepsPattern(data, currentNode.retryState.state, [
          ...currentNodeParents,
          currentNode.name
        ])
      : null;

    return {
      id: `${serviceId}_${currentNode.name}`,
      name: currentNode.name,
      type: 'WELCOME_ATTENDANT_CHOICE',
      description: currentNode.name || '---',
      horizontalNode: choiceRepetition,
      verticalNode: options.length > 0 ? options[0] : null
    };
  }

  static convertCalendar(
    data: ?WelcomeAttendantEntityT,
    currentNode: CalendarEntityT,
    currentNodeParents: string[],
    serviceId: string
  ) {
    return {
      id: `${serviceId}_${currentNode.name}`,
      name: currentNode.name,
      type: 'WELCOME_ATTENDANT_CALENDAR',
      description: currentNode.name,
      horizontalNode: currentNode.withinPeriodSate
        ? WelcomeAttendantUtils.buildStepsPattern(data, currentNode.withinPeriodSate.state, [
            ...currentNodeParents,
            currentNode.name
          ])
        : null,
      verticalNode: currentNode.outOfPeriodState
        ? WelcomeAttendantUtils.buildStepsPattern(data, currentNode.outOfPeriodState.state, [
            ...currentNodeParents,
            currentNode.name
          ])
        : null
    };
  }

  static convertAudio(
    data: ?WelcomeAttendantEntityT,
    currentNode: AudioEntityT,
    currentNodeParents: string[],
    serviceId: string
  ): CallFlowTreeNodeT {
    const nextStep = WelcomeAttendantUtils.buildStepsPattern(data, currentNode.nextState.state, [
      ...currentNodeParents,
      currentNode.name
    ]);
    return {
      id: `${serviceId}_${currentNode.name}`,
      name: currentNode.name,
      type: 'WELCOME_ATTENDANT_MESSAGE',
      description: currentNode.name,
      horizontalNode: nextStep,
      verticalNode: null
    };
  }

  static buildCallFlowModuleByType(
    data: ?WelcomeAttendantEntityT,
    currentNode: CommandEntityT,
    currentNodeParents: string[],
    serviceId: string
  ): ?CallFlowTreeNodeT {
    switch (currentNode.type) {
      case 'START':
        return {
          id: `${serviceId}_${currentNode.name}`,
          name: currentNode.name,
          type: 'WELCOME_ATTENDANT_START',
          description: 'START',
          horizontalNode: WelcomeAttendantUtils.buildStepsPattern(
            data,
            currentNode.nextState.state,
            [...currentNodeParents, currentNode.name]
          ),
          verticalNode: null
        };
      case 'TRANSFER':
        return {
          id: `${serviceId}_${currentNode.name}`,
          name: currentNode.name,
          type: 'WELCOME_ATTENDANT_TRANSFER',
          description: `${currentNode.name} ${currentNode.extensionNumber}`,
          horizontalNode: null,
          verticalNode: null
        };
      case 'AUDIO':
        return WelcomeAttendantUtils.convertAudio(data, currentNode, currentNodeParents, serviceId);
      case 'CALENDAR':
        return WelcomeAttendantUtils.convertCalendar(
          data,
          currentNode,
          currentNodeParents,
          serviceId
        );
      case 'INTERACTION':
        return WelcomeAttendantUtils.convertInteraction(
          data,
          currentNode,
          currentNodeParents,
          serviceId
        );
      default:
        return null;
    }
  }

  static buildStepsPattern(
    data: ?WelcomeAttendantEntityT,
    currentStateName: ?string,
    currentNodeParents: string[]
  ): ?CallFlowTreeNodeT {
    const serviceId = data ? data.id : '0';
    if (currentStateName === 'END') {
      return {
        id: `${serviceId}_END_${currentNodeParents.join('-')}`,
        name: 'END',
        type: 'WELCOME_ATTENDANT_END',
        description: ''
      };
    }
    if (currentStateName === 'RUN2') {
      return {
        id: `${serviceId}_START_${currentNodeParents.join('-')}`,
        name: 'RUN2',
        type: 'WELCOME_ATTENDANT_START',
        description: ''
      };
    }
    const currentNode: ?CommandEntityT = currentStateName
      ? R.path(['commands', currentStateName], data)
      : null;
    if (!currentNode || !currentNode.type) {
      return {
        id: `${serviceId}_ERROR_${currentNodeParents.join('-')}`,
        name: 'ERROR',
        type: 'WELCOME_ATTENDANT_ERROR',
        description: ''
      };
    }
    if (WelcomeAttendantUtils.hasCyclicReference(currentNode, currentNodeParents)) {
      return WelcomeAttendantUtils.buildCallFlowModuleByType(
        null,
        currentNode,
        currentNodeParents,
        serviceId
      );
    }
    return WelcomeAttendantUtils.buildCallFlowModuleByType(
      data,
      currentNode,
      currentNodeParents,
      serviceId
    );
  }

  welcomeAttendantTreeNodeIds: string[];

  linkedNodeNames: Set<string>;

  constructor() {
    this.welcomeAttendantTreeNodeIds = [];
    this.linkedNodeNames = new Set();
    this.createCallFlowTree = this.createCallFlowTree.bind(this);
  }

  removeDuplicateNodesFromTree(currentNode: ?CallFlowTreeNodeT): ?CallFlowTreeNodeT {
    if (!currentNode) {
      return null;
    }
    if (this.welcomeAttendantTreeNodeIds.indexOf(currentNode.id) !== -1) {
      return {
        ...currentNode,
        horizontalNode: null,
        verticalNode: null
      };
    }
    if (currentNode.name) {
      this.linkedNodeNames.add(currentNode.name);
    }
    this.welcomeAttendantTreeNodeIds = [...this.welcomeAttendantTreeNodeIds, currentNode.id];

    return {
      ...currentNode,
      horizontalNode: this.removeDuplicateNodesFromTree(currentNode.horizontalNode),
      verticalNode: this.removeDuplicateNodesFromTree(currentNode.verticalNode)
    };
  }

  createCallFlowTree: WelcomeAttendantEntityT => ?CallFlowTreeNodeT;

  createCallFlowTree(data: WelcomeAttendantEntityT): ?CallFlowTreeNodeT {
    const rootNode = WelcomeAttendantUtils.buildStepsPattern(data, 'run2', []);
    return this.removeDuplicateNodesFromTree(rootNode);
  }
}

export default WelcomeAttendantUtils;
