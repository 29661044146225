// @flow strict-local
import React, { useEffect } from 'react';
import type { Canceler } from 'axios';
import { useDispatch } from 'react-redux';
import { CancelToken } from 'axios';
import { MAX_PAGE_SIZE, USERS_PAGE_SIZE } from '../../constants';
import directoryOperations from '../../ducks/entities/directory/directoryOperations';
import userOperations from '../../ducks/entities/user/userOperations';
import efaxOperations from '../../ducks/entities/efax/efaxOperations';

export type CategoryT = 'ALL' | 'INTERNAL_CONTACTS' | 'EXTERNAL_CONTACTS';

type UseUsersSearchResponseT = {
  searchUsers: (
    search: string,
    sort: string,
    category: string,
    pageToSearch: number
  ) => Promise<void>
};

const useUsersSearch = (
  currentEnterpriseId: string,
  sortTerm: string,
  selectedCategory: { key: CategoryT, value: string }
): UseUsersSearchResponseT => {
  const cancelUsersSearchRequest = React.useRef<Canceler>();
  // redux
  const dispatch = useDispatch();

  const searchUsers = async (
    search: string,
    sort: string,
    category: string,
    pageToSearch: number
  ) => {
    let type = 'users';
    if (category === 'EXTERNAL_CONTACTS') {
      type = 'shared';
    } else if (category === 'ALL') {
      type = 'all';
    } else if (category === 'SERVICES') {
      type = 'servicesFiltered';
    } else if (category === 'EFAXES') {
      const params = {
        sort,
        size: USERS_PAGE_SIZE,
        page: pageToSearch,
        search
      };
      await dispatch(
        efaxOperations.searchEfax(
          currentEnterpriseId,
          new CancelToken(canceler => {
            cancelUsersSearchRequest.current = canceler;
          }),
          params
        )
      );
      return;
    }
    const params = {
      type,
      sort,
      size: category === 'SERVICES' ? MAX_PAGE_SIZE : USERS_PAGE_SIZE,
      page: pageToSearch,
      search
    };
    if (currentEnterpriseId) {
      if (pageToSearch === 1 && cancelUsersSearchRequest.current) {
        cancelUsersSearchRequest.current();
      }
      const { results: users } = await dispatch(
        directoryOperations.searchDirectory(
          currentEnterpriseId,
          new CancelToken(canceler => {
            cancelUsersSearchRequest.current = canceler;
          }),
          params
        )
      );
      if (users) {
        users.forEach(
          user =>
            user.alias === 'IUser' &&
            dispatch(
              userOperations.retrieveAvatar(
                currentEnterpriseId,
                user.internalAddressId,
                new CancelToken(canceler => {
                  cancelUsersSearchRequest.current = canceler;
                })
              )
            )
        );
      }
    }
  };

  useEffect(() => {
    if (currentEnterpriseId && selectedCategory) {
      searchUsers('', sortTerm, selectedCategory.key, 1);
    }
    return () => cancelUsersSearchRequest.current && cancelUsersSearchRequest.current();
  }, [currentEnterpriseId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    searchUsers
  };
};

export default useUsersSearch;
