// @flow strict-local

import * as R from 'ramda';
import React, { type Element, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AgentsIcon } from '../../../../../assets/callflow/details/agents-small.svg';
import MemberList from '../../../components/view/children/MemberList/MemberList';
import type { AcdEntityT } from '../../../../../ducks/entities/acd/acdTypes';
import CallflowDetails from '../../../components/view/CallflowDetails';
import CallflowDetailsColumn from '../../../components/view/CallflowDetailsColumn';
import CallflowProperty from '../../../components/view/children/CallflowProperty';
import CallflowItem from '../../../components/view/children/CallflowItem';
import { getIntegerFieldLimits } from '../../../../../utils/validationUtils';
import { acdAgentSchema } from './EditAcdAgent';
import { getAgentStateByPresence } from '../../../components/edit/CallflowPropertyUtils';
import { AgentStateByPresence } from '../../../../enterprises/EnterpriseSettings/EnterpriseSettings';
import type { CurrentUserT } from '../../../../../ducks/currentUser/currentUserTypes';

export type PropsT = {|
  callflowId: string
|};

export const AcdAgentDetails = (props: PropsT): Element<typeof CallflowDetails> | null => {
  const { callflowId } = props;

  const { t } = useTranslation();
  const acdData: AcdEntityT = useSelector(state => state.entities.callFlow.byId[callflowId]);
  const acdCallCenter = R.path(['servicesSettings', 'acdCallCenter'], acdData) || {};
  const currentUser: CurrentUserT = useSelector(state => state.currentUser);
  const [agentStateByPresence, setAgentStateByPresence] = useState(0);

  const fetchAgentStateByPresence = async () => {
    setAgentStateByPresence(await getAgentStateByPresence(acdData.enterpriseId));
  };

  fetchAgentStateByPresence();

  if (acdData) {
    return (
      <CallflowDetails
        enterpriseId={acdData.enterpriseId}
        callflowId={acdData.id}
        elementType={acdData.type}
        nodeId={`acd_agents_${acdData.id}`}
        nodeType="ACD_AGENTS"
        icon={<AgentsIcon />}
        title={t('callflows.viewAcdAgentDetails.title')}
        active
      >
        <CallflowDetailsColumn id="acd-agents-column-1">
          <CallflowItem
            id="ringPattern"
            title={t('callflows.viewAcdAgentDetails.ringPattern')}
            value={t(`callflows.ringPattern.${acdData.ringPattern}`)}
          />
          <MemberList
            title={t('callflows.viewAcdAgentDetails.groupMembers', {
              numberOfPeople: acdData.members ? acdData.members.length : ''
            })}
            members={acdData.members || []}
            enterpriseId={acdData.enterpriseId}
            serviceType="ACDGroup"
            id="groupMembers"
            callFlowId={acdData.id}
            acdType={acdData.type}
          />
          <CallflowProperty
            id="agentStateByPresence"
            title=""
            property={
              agentStateByPresence && agentStateByPresence !== AgentStateByPresence.NotInUse
                ? t(`enterpriseSettings.agentStateByPresence.${agentStateByPresence}`)
                : ''
            }
            basicValue
          />
        </CallflowDetailsColumn>
        <CallflowDetailsColumn id="acd-agents-column-2">
          {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') ? (
            <CallflowProperty
              id="preferLastOperator"
              title={t('callflows.viewAcdAgentDetails.preferLastOperator')}
              property={acdCallCenter.preferLastOperator}
              basicValue
              tooltip={t('callflows.viewAcdAgentDetails.preferLastOperatorTooltip')}
            />
          ) : null}
          {((currentUser && currentUser.featureFlags) || []).includes('FEATURE-GENERIC-API') &&
          acdCallCenter.preferLastOperator ? (
            <CallflowProperty
              id="preferLastOperatorRetention"
              title={t('callflows.viewAcdAgentDetails.preferLastOperatorRetention')}
              property={acdCallCenter.preferLastOperatorRetention}
              basicValue
              postMsg={t('callflows.viewAcdAgentDetails.preferLastOperatorRetentionPostMsg')}
            />
          ) : null}
          <CallflowProperty
            id="ringingTimeout"
            title={t('callflows.viewAcdAgentDetails.ringingTimeout')}
            property={acdCallCenter.ringingTimeout}
            tooltip={t(
              'callflows.viewAcdAgentDetails.ringingTimeoutTooltip',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(acdAgentSchema.fields.ringingTimeout)
            )}
            basicValue
          />
          <CallflowItem
            id="syndicalPause"
            title={t('callflows.viewAcdAgentDetails.syndicalPause')}
            value={t('callflows.viewAcdAgentDetails.syndicalPauseValue', {
              value: acdData.syndicalPause
            })}
            tooltip={t(
              'callflows.viewAcdAgentDetails.syndicalPauseTooltip',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(acdAgentSchema.fields.syndicalPause)
            )}
          />
          <CallflowProperty
            id="timeUnreachable"
            title={t('callflows.viewAcdAgentDetails.timeUnreachable')}
            property={acdCallCenter.timeUnreachable}
            tooltip={t(
              'callflows.viewAcdAgentDetails.timeUnreachableTooltip',
              // $FlowFixMe: TODO: fix
              getIntegerFieldLimits(acdAgentSchema.fields.timeUnreachable)
            )}
            basicValue
          />
        </CallflowDetailsColumn>
      </CallflowDetails>
    );
  }
  return null;
};

export default AcdAgentDetails;
