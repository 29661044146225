import React from 'react';
import IconContainer from '../IconContainer';

export default function IconThumbDownFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 3.5V14c0 .3-.2.5-.5.5H18q-.75 0-1.2.6L11.9 22c-.7 1-2.1 1.2-3.1.5C8 22 7.7 21 8 20.1l.9-3.2c.1-.5 0-.9-.3-1.3s-.7-.6-1.2-.6H2.5c-.8 0-1.5-.4-2-1s-.6-1.4-.4-2.2v-.1L2 5.4v-.1C3 2.2 4.7.7 7.2.7h3.2c2.9 0 4.1.2 8.3 2.3.2.2.3.3.3.5M23.5 1h-1.7c-.8 0-1.5.7-1.5 1.5v12c0 .8.7 1.5 1.5 1.5h1.7c.3 0 .5-.2.5-.5v-14c0-.3-.2-.5-.5-.5"/></svg>)}
    />
  );
}
  