// @flow strict-local
// eslint-disable-next-line import/no-named-default
import { default as axios, CancelToken, AxiosPromise } from 'axios';
import * as R from 'ramda';
import React, { type Element, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type Match } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { goToForwardingDetailsUpdate } from '../../navigationOperations';
import { operations as enterpriseOps } from '../../ducks/entities/enterprise';
import Notifications from '../../components/Notifications/Notifications';
import BaseContainer from '../BaseContainer/BaseContainer';
import CallForwardingContentTabs from './CallForwardingContentTabs';
import { TabletAndDown } from '../../Responsive';
import { operations as userOps } from '../../ducks/entities/user';
import DeleteForwardingDialog from './components/DeleteForwardingDialog';
import ActivateEnterpriseForwardingDialog from './enterpriseForwardings/ActivateEnterpriseForwardingDialog';
import useForwardingActions from './ForwardingDetails/form/useForwardingActions';
import useForwardingListTour from './useForwardingListTour';
import callForwardingOperations from '../../ducks/entities/callForwarding/callForwardingOperations';
import type {
  CallForwardingsTabT,
  PopUpDataT
} from '../../ducks/ui/callForwardings/callForwardingsUiTypes';
import useEnterprise from '../useEnterprise';
import TopNavigation from '../navigation/TopNavigation';
import styles from './CallForwardings.module.scss';

export type PropsT = {
  match: Match
};

const CallForwardings = (props: PropsT): Element<typeof BaseContainer> => {
  // Redux
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // state
  const enterpriseId: string = props.match.params.id || '';
  const { enterprise } = useEnterprise(enterpriseId);

  const popUp: ?PopUpDataT = useSelector(state =>
    R.path(['ui', 'callForwardings', 'popup'], state)
  );
  const activeForwarding = popUp ? { forwarding: popUp.forwarding, user: popUp.user } : undefined;
  const activeTab: CallForwardingsTabT =
    useSelector(state => state.ui.callForwardings.activeTab) || 'users';
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [activationDialogOpen, setActivationDialogOpen] = useState<boolean>(false);

  const [startTour] = useForwardingListTour();
  const { updateCallForwardingActiveState } = useForwardingActions({
    enterpriseId,
    action: 'modify'
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateForwardingData = async (reload: ?boolean, user) => {
    if (reload) {
      const userCancelToken = CancelToken.source();
      // $FlowFixMe unclear type
      const addressNumber: ?string = user
        ? R.path(['publicInfo', 'addressNumber'], user)
        : R.path(['user', 'publicInfo', 'addressNumber'], activeForwarding);

      if (addressNumber) {
        await dispatch(
          callForwardingOperations.retrieveUserForwardingsByAddressNumber(
            enterpriseId,
            addressNumber,
            userCancelToken.token
          )
        );
        const userIdResponse: AxiosPromise<{ extensionId: string }> = await axios({
          method: 'GET',
          url: `/api/v1/enterprises/${enterpriseId}/userIds/byAddressNumber/${addressNumber}`,
          cancelToken: userCancelToken.token
        });
        await dispatch(
          userOps.retrieveForwardings(
            enterpriseId,
            userIdResponse.data.extensionId,
            userCancelToken.token
          )
        );
      } else {
        await dispatch(enterpriseOps.getEnterprise(enterpriseId, userCancelToken.token));
      }
    }
  };

  const closeDialog = async (reload: ?boolean) => {
    setDeleteDialogOpen(false);
    setActivationDialogOpen(false);
    await updateForwardingData(reload);
  };

  const onForwardingActivityChange = async (forwarding, user) => {
    if (forwarding && activeTab === 'users') {
      await updateCallForwardingActiveState({
        isActive: !forwarding.isActive,
        id: forwarding.id
      });
      await updateForwardingData(true, user);
    } else {
      setActivationDialogOpen(true);
    }
  };

  return (
    <BaseContainer
      header={<TopNavigation onClickTutorial={() => startTour()} viewName="callforwarding" />}
    >
      <div id="call-forwardings-page" className={styles['call-forwardings-page']}>
        <Notifications
          tags={['callforwarding-deleted', 'callforwarding-success', 'callforwarding-fail']}
        />
        {deleteDialogOpen && activeForwarding && (
          <DeleteForwardingDialog
            onClose={closeDialog}
            activeForwarding={activeForwarding}
            enterpriseId={enterpriseId}
            forwardingType={activeTab === 'users' ? 'user' : 'enterprise'}
          />
        )}
        {activationDialogOpen && activeForwarding && (
          <ActivateEnterpriseForwardingDialog
            forwarding={activeForwarding.forwarding}
            onClose={closeDialog}
            enterpriseId={enterpriseId}
          />
        )}

        <div className={styles['content-container']}>
          <TabletAndDown>
            <div className={styles.title}>{t('forwardings.title')}</div>
          </TabletAndDown>
          <CallForwardingContentTabs
            enterprise={enterprise}
            goToForwardingDetailsUpdate={goToForwardingDetailsUpdate}
            onDeleteForwarding={() => {
              setDeleteDialogOpen(true);
            }}
            onForwardingActivityChange={onForwardingActivityChange}
          />
        </div>
      </div>
    </BaseContainer>
  );
};

export default CallForwardings;
