// @flow strict-local

/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, type Element } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, type FormProps } from 'redux-form';
import type { ContextRouter } from 'react-router-dom';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import LoginBase from '../login/LoginBase';
import { goToLogin } from '../../navigationOperations';
import styles from './UserPasswordResetDone.module.scss';

type OwnPropsT = {||};

type StatePropsT = {||};

type DispatchPropsT = {|
  goToLogin: () => typeof goToLogin
|};

export type PropsT = {|
  ...$Exact<OwnPropsT>,
  ...$Exact<StatePropsT>,
  ...$Exact<DispatchPropsT>,
  // $FlowFixMe
  ...FormProps,
  ...$Exact<ContextRouter>,
  ...$Exact<WithTranslationProps>
|};

// eslint-disable-next-line react/prefer-stateless-function
export class UserPasswordResetDone extends Component<PropsT> {
  render(): Element<typeof LoginBase> | null {
    if (!this.props.location.state) {
      this.props.goToLogin();
      return null;
    }

    const {
      location: {
        // $FlowFixMe
        state: { environment }
      },
      t
    } = this.props;

    const linkToMyIstra = (
      <a href={t(`password.user.passwordResetDone.links.${environment}`)} id="link-to-myistra">
        {t('password.user.passwordResetDone.linkText')}.
      </a>
    );
    return (
      <LoginBase>
        <div>
          <div className={styles['success-image-content']}>
            <img className={styles['success-image']} src="/icon-thumb.svg" alt="success" />
          </div>
          <h3 className={styles['success-title']} id="login-page-title">
            {t('password.user.passwordResetDone.title')}
          </h3>
          <p className={styles['success-description']}>
            {t('password.user.passwordResetDone.description')} {linkToMyIstra}
          </p>
        </div>
      </LoginBase>
    );
  }
}

const mapStateToProps = () => ({
  initialValues: { identifier: '' }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToLogin
    },
    dispatch
  );

export default compose(
  withTranslation(),
  connect<PropsT, OwnPropsT, _, _, _, _>(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'missingPassword', enableReinitialize: true })
)(UserPasswordResetDone);
