import React from 'react';
import IconContainer from '../IconContainer';

export default function IconEmailAddressRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.278 5.638C17.77.524 10.755-.785 5.638 2.722.523 6.231-.785 13.247 2.723 18.361a11.18 11.18 0 0 0 7.216 4.697c.695.13 1.393.193 2.085.193 2.248 0 4.443-.676 6.338-1.976a.748.748 0 1 0-.848-1.236 9.7 9.7 0 0 1-7.301 1.544 9.7 9.7 0 0 1-6.254-4.07C.92 13.081 2.053 7 6.487 3.96 10.918.92 17 2.053 20.042 6.486a9.7 9.7 0 0 1 1.708 5.506v.912a2.445 2.445 0 0 1-2.443 2.443 2.445 2.445 0 0 1-2.442-2.443v-.912a4.86 4.86 0 0 0-4.855-4.855 4.86 4.86 0 0 0-4.855 4.855 4.86 4.86 0 0 0 4.855 4.855c1.601 0 3.011-.788 3.896-1.986.682 1.181 1.943 1.986 3.402 1.986a3.947 3.947 0 0 0 3.943-3.943v-.913a11.2 11.2 0 0 0-1.973-6.353m-9.269 9.71a3.36 3.36 0 0 1-3.355-3.355 3.36 3.36 0 0 1 3.355-3.355 3.36 3.36 0 0 1 3.355 3.355 3.36 3.36 0 0 1-3.355 3.355"/></svg>)}
    />
  );
}
  