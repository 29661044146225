// @flow strict-local

/* eslint-disable react/no-unused-prop-types */

import classNames from 'classnames';
import React, { type Element } from 'react';
import { type TableRowItemT } from './TableRow';
import ForwardingRowContents from './ForwardingRowContents';
import styles from './MediumTableRow.module.scss';

export type PropsT = {
  columns: string[],
  rowItem: TableRowItemT
};

const MediumTableRow = ({ columns, rowItem }: PropsT): Element<'div'> => (
  <div className={classNames(styles.row, ...(rowItem.rowClasses || []))}>
    <div className={styles['title-row']}>
      <div className={styles['user-details']}>
        <div>{rowItem.avatar}</div>
        <div>{rowItem.name}</div>
      </div>
      <div className={styles['action-cell']}>{rowItem.action}</div>
    </div>
    <ForwardingRowContents columns={columns} rowItem={rowItem} />
  </div>
);

export default MediumTableRow;
