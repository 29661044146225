// @flow

import React from 'react';
import classNames from 'classnames';
import styles from './GridModeIcon.module.scss';

export type PropsT = {
  active?: boolean,
  disabled?: boolean
};

export const GridModeIcon = ({ active, disabled }: PropsT) => (
  <div
    className={classNames({
      [styles.icon]: true,
      [styles.active]: active,
      [styles.disabled]: disabled
    })}
  />
);

GridModeIcon.defaultProps = {
  active: false
};

export default GridModeIcon;
