import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInstagramFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.366 0H6.633A6.64 6.64 0 0 0 0 6.633v10.733A6.64 6.64 0 0 0 6.633 24h10.733A6.64 6.64 0 0 0 24 17.366V6.633A6.64 6.64 0 0 0 17.366 0M12 17.25A5.256 5.256 0 0 1 6.75 12 5.256 5.256 0 0 1 12 6.75 5.256 5.256 0 0 1 17.25 12 5.256 5.256 0 0 1 12 17.25m7-11a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5"/></svg>)}
    />
  );
}
  