import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBookRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.875 9.88a.763.763 0 0 1 .801-.694c1.891.135 3.762.51 5.562 1.115a.75.75 0 0 1-.477 1.422 21 21 0 0 0-5.191-1.041.75.75 0 0 1-.695-.802m6.364 4.709a22.5 22.5 0 0 0-5.563-1.115c-.398-.014-.771.282-.801.694a.75.75 0 0 0 .695.802c1.766.126 3.512.477 5.191 1.041a.75.75 0 0 0 .478-1.422M15 11.762a.7.7 0 0 0 .239-.039 21 21 0 0 1 5.191-1.041.749.749 0 1 0-.108-1.496c-1.89.135-3.761.51-5.562 1.115a.75.75 0 0 0 .24 1.461m0 4.288q.12 0 .239-.039a21 21 0 0 1 5.191-1.041c.413-.029.724-.389.694-.802s-.389-.715-.802-.694a22.5 22.5 0 0 0-5.562 1.115.75.75 0 0 0 .24 1.461m9-14.019V19.07a1.28 1.28 0 0 1-1.24 1.28c-7.918.107-10.163 2.606-10.185 2.632-.01.011-.024.015-.034.026a.8.8 0 0 1-.208.156l-.03.016c-.018.008-.032.021-.051.028a.8.8 0 0 1-.252.042c-.04 0-.076-.019-.115-.025-.048-.008-.097-.009-.143-.026l-.018-.01c-.036-.014-.067-.036-.101-.056a.8.8 0 0 1-.146-.112c-.016-.016-.038-.023-.053-.041-.049-.056-2.316-2.524-10.173-2.631A1.285 1.285 0 0 1 0 19.064V2.031c0-.338.137-.669.375-.908A1.3 1.3 0 0 1 1.29.75C7.823.837 10.851 2.51 12 3.394 13.149 2.51 16.178.836 22.711.75h.017c.335 0 .661.136.898.375.238.238.374.568.374.906M1.5 18.854c5.298.102 8.249 1.251 9.75 2.139V4.714c-.637-.547-3.209-2.333-9.75-2.46zm21-16.6c-6.526.127-9.102 1.906-9.75 2.462v16.276c1.501-.887 4.451-2.035 9.75-2.139z"/></svg>)}
    />
  );
}
  