import React from 'react';
import IconContainer from '../IconContainer';

export default function IconFileZipFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.246 16.463h-.284v1.032h.166q.383 0 .562-.141.16-.129.161-.388 0-.267-.133-.381-.14-.121-.472-.122"/><path d="M20.5 7.125h-5.005A1.625 1.625 0 0 1 13.871 5.5v-5a.5.5 0 0 0-.5-.5H4.999A2 2 0 0 0 3 2v20a2 2 0 0 0 1.999 2h13.995a2 2 0 0 0 1.999-1.999L21 7.625a.5.5 0 0 0-.5-.5m-9.877 12.871c0 .08-.065.145-.145.145H7.166a.144.144 0 0 1-.145-.145v-.612q0-.045.026-.083l1.975-2.832H7.224a.144.144 0 0 1-.145-.145v-.779c0-.08.065-.145.145-.145h3.196c.08 0 .145.064.145.145v.608a.15.15 0 0 1-.026.083l-1.972 2.836h1.911c.08 0 .145.064.145.145zm1.963 0c0 .08-.064.145-.145.145h-.943a.144.144 0 0 1-.145-.145v-4.451c0-.08.065-.145.145-.145h.943c.08 0 .145.064.145.145zm4.007-1.853c-.325.275-.787.415-1.371.415h-.26v1.438c0 .08-.064.145-.145.145h-.944a.144.144 0 0 1-.145-.145v-4.451c0-.08.064-.145.145-.145h1.422c.572 0 1.018.128 1.324.382.313.26.473.647.473 1.15 0 .522-.168.929-.499 1.211m3.618-12.422a.25.25 0 0 1-.231.154h-4.484a.375.375 0 0 1-.375-.375V1.014a.25.25 0 0 1 .154-.231.25.25 0 0 1 .272.054l4.609 4.611a.25.25 0 0 1 .055.273"/></svg>)}
    />
  );
}
  