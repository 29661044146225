// @flow
import React, { type Element } from 'react';
import Button from '@design-system/component-library/src/components/Button';
import classnames from 'classnames';
import styles from './CancelButton.module.scss';

export type PropsT = {
  id: string,
  className?: string,
  label: string,
  onClickAction: () => *
};

const CancelButton = (props: PropsT): Element<'Button'> => {
  const { id, className, label, onClickAction } = props;
  return (
    <Button
      id={id}
      type="button"
      value="Cancel"
      className={classnames(styles['cancel-button'], className)}
      color="link"
      size="l"
      onClick={onClickAction}
    >
      {label}
    </Button>
  );
};

CancelButton.defaultProps = {
  className: ''
};

export default CancelButton;
