import React from 'react';
import {useId} from 'react-id-generator';
import PropTypes from 'prop-types';
import LinkList from './LinkList';
import Accordion from '../../../Accordion';
import ImitationAccordionLink from './ImitationAccordionLink';
import IconArrowDownFilled from '../../../Icon/lib/IconArrowDownFilled';
import { classNames } from '../../../../utils/css';
import styles from './Links.module.scss';

const containsOnlyHeaderLink = (subcategory) => subcategory.url && !subcategory.pages;
const hasTitleLink = (subcategory) => !!subcategory.url;

/*
   Megastack a single column in footer links. One column can have multiple link groups.
   On mobile screen widths, all columns are collapsed into single column.
   This is a bit problematic, because now the data structure defines the number of columns.
   If you need to have 3 columns on tablet, you need to change the number of megastacks with
   javascript.
*/

function Megastack({ item, handleClick }) {
  const ids = useId(item.length, 'footerList');

  const handleAccordionToggle = (e) => {
    const classes = e.currentTarget.classList;
    classes.toggle(styles['footer__accordion-item--open']);
  }

  return (
    item.map((subcategory, index) => {
      const id = ids[index];
      return (
        <div key={subcategory.title + index} className={styles.footer__item}>
          {hasTitleLink(subcategory) ? (
            <a className={styles.footer__title_link} href={subcategory.url} onClick={handleClick}>
              <h2 id={id} className={styles.footer__h2}>
                {subcategory.title}
              </h2>
            </a>
          ) : (
            <h2 id={id} className={classNames([styles.footer__h2, styles['footer__accordion-item']])} onClick={handleAccordionToggle}>
              {subcategory.title}
              <IconArrowDownFilled/>
            </h2>
          )}

          {subcategory.pages ?
            <LinkList
              ariaLabelledBy={id}
              links={subcategory.pages}
              handleClick={handleClick}
            /> : null
          }
        </div>
      );
    })
  );
}

Megastack.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      pages: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};

export default Megastack;
