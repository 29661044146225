import React from 'react';
import IconContainer from '../IconContainer';

export default function IconInformationLight(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.75 16.5a.5.5 0 0 1-.5.5h-.75a2 2 0 0 1-2-2v-3.75a.25.25 0 0 0-.25-.25h-.75a.5.5 0 0 1 0-1h.75c.69 0 1.25.56 1.25 1.25V15a1 1 0 0 0 1 1h.75a.5.5 0 0 1 .5.5m-4-9.38a.88.88 0 1 1 .88.88h-.01a.87.87 0 0 1-.87-.87zM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12m-1 0c0-6.075-4.925-11-11-11S1 5.925 1 12s4.925 11 11 11 11-4.925 11-11"/></svg>)}
    />
  );
}
  