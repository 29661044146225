import React from 'react';
import IconContainer from '../IconContainer';

export default function IconBroadbandFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.416 23.75a1.25 1.25 0 0 1-1.235-1.45c.046-.285.066-.503.066-.707a4.85 4.85 0 0 0-4.845-4.844c-.199 0-.416.021-.705.066a1.25 1.25 0 1 1-.393-2.469 7 7 0 0 1 1.098-.098c4.05 0 7.345 3.294 7.345 7.344 0 .339-.031.69-.099 1.105a1.25 1.25 0 0 1-1.232 1.053m8.284-1.14c.028-.322.048-.647.048-.978 0-7.931-6.453-14.383-14.385-14.383-.329 0-.652.02-.972.048a1.25 1.25 0 0 0 .218 2.49c.249-.02.499-.037.754-.037 6.553 0 11.885 5.331 11.885 11.883 0 .256-.016.507-.038.757a1.249 1.249 0 1 0 2.49.22m7.018-.033q.03-.462.032-.933C23.75 9.847 14.15.249 2.351.249c-.312 0-.62.014-.927.033A1.25 1.25 0 0 0 .252 1.606a1.24 1.24 0 0 0 1.323 1.172c.258-.016.515-.028.776-.028 10.421 0 18.899 8.476 18.899 18.895 0 .262-.012.521-.028.778a1.251 1.251 0 0 0 2.496.154"/></svg>)}
    />
  );
}
  