import React from 'react';
import IconContainer from '../IconContainer';

export default function IconLockFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 9h-.7V6.3C18.3 2.8 15.5 0 12 0S5.8 2.8 5.8 6.3V9H5c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2m-6.4 9c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-3c0-.3.3-.6.6-.6s.6.3.6.6zm4.2-9H7.2V6.3c0-2.6 2.1-4.8 4.8-4.8s4.8 2.1 4.8 4.8z"/></svg>)}
    />
  );
}
  