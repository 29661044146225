// @flow

import type {
  SelectUserActionT,
  SelectUsersActionT,
  DeselectUserActionT,
  ClearUserActionT,
  SelectedUserT,
  SelectedUsersT
} from './departmentUiTypes';

export const NO_DEPARTMENT = 'noDepartment';

export const createSelectDepartmentUser = (
  enterpriseId: string,
  departmentId: string = NO_DEPARTMENT,
  selectedUser: SelectedUserT
): SelectUserActionT => ({
  type: 'ring/ui/department/SELECT_USER',
  payload: { enterpriseId, departmentId, selectedUser }
});

export const createSelectDepartmentUsers = (
  enterpriseId: string,
  departmentId: string = NO_DEPARTMENT,
  selectedUsers: SelectedUsersT
): SelectUsersActionT => ({
  type: 'ring/ui/department/SELECT_USERS',
  payload: { enterpriseId, departmentId, selectedUsers }
});

export const createDeselectDepartmentUser = (
  enterpriseId: string,
  departmentId: string = NO_DEPARTMENT,
  selectedUser: SelectedUserT
): DeselectUserActionT => ({
  type: 'ring/ui/department/DESELECT_USER',
  payload: { enterpriseId, departmentId, selectedUser }
});

export const createClearDepartmentUser = (
  enterpriseId: string,
  departmentId: string = NO_DEPARTMENT
): ClearUserActionT => ({
  type: 'ring/ui/department/CLEAR_USERS',
  payload: { enterpriseId, departmentId }
});
