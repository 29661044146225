import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWifiFilled(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.75 19.5c0 1.517-1.233 2.75-2.75 2.75s-2.75-1.233-2.75-2.75 1.233-2.75 2.75-2.75 2.75 1.233 2.75 2.75m8.798-14.337C16.853-.39 7.137-.388.446 5.171a1.249 1.249 0 1 0 1.597 1.922 15.6 15.6 0 0 1 19.91-.007 1.248 1.248 0 1 0 1.595-1.923M6.732 13.222a1.25 1.25 0 0 0 1.48 2.016 6.37 6.37 0 0 1 7.574.002 1.25 1.25 0 0 0 1.48-2.016 8.87 8.87 0 0 0-10.534-.002M3.568 9.21a1.25 1.25 0 0 0 1.562 1.952c3.983-3.188 9.762-3.188 13.74-.002a1.25 1.25 0 1 0 1.563-1.952C15.55 5.3 8.456 5.298 3.568 9.21"/></svg>)}
    />
  );
}
  