import React from 'react';
import IconContainer from '../IconContainer';

export default function IconWebcamRegular(props) {
  return (
    <IconContainer
      {...props}
      source={(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.56 18.685c2.398-1.926 3.94-4.877 3.94-8.185C22.5 4.71 17.79 0 12 0S1.5 4.71 1.5 10.5c0 3.308 1.541 6.259 3.939 8.185l-3.508 4.076A.751.751 0 0 0 2.5 24h19a.75.75 0 0 0 .569-1.24zM3 10.5c0-4.962 4.038-9 9-9 4.963 0 9 4.038 9 9 0 4.963-4.037 9-9 9-4.962 0-9-4.037-9-9m1.135 12 2.549-2.961C8.245 20.461 10.059 21 12 21s3.755-.539 5.316-1.461l2.549 2.961zM12 15.25c2.619 0 4.75-2.131 4.75-4.75S14.619 5.75 12 5.75 7.25 7.881 7.25 10.5s2.131 4.75 4.75 4.75m0-8c1.792 0 3.25 1.458 3.25 3.25s-1.458 3.25-3.25 3.25-3.25-1.458-3.25-3.25S10.208 7.25 12 7.25m-1.125-3.625c0-.62.505-1.125 1.125-1.125s1.125.505 1.125 1.125S12.62 4.75 12 4.75s-1.125-.505-1.125-1.125"/></svg>)}
    />
  );
}
  